import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IGetDOCPackagesQueryModel } from './models';

export const getDOCPackagesQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IGetDOCPackagesQueryModel>> =>
  axios.get(`api/packages`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });
