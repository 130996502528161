import { useCallback, useMemo } from 'react';

import { formatMomentDate, parseDate } from '../../../utils/dateHelpers';

type UseDatePickerProps = {
  onChange?: (value: string) => void;
  value: string;
  inputFormat?: string;
  outputFormat?: string;
};

export const useDatePicker = ({
  onChange,
  value,
  inputFormat,
  outputFormat,
}: UseDatePickerProps) => {
  const onChangeHandler = useCallback(
    (date: moment.Moment | string) => {
      onChange &&
        onChange(date && parseDate(date, outputFormat || inputFormat));
    },
    [inputFormat, onChange, outputFormat],
  );

  const currentValue = useMemo(
    () => (value ? formatMomentDate(value, inputFormat) : null),
    [inputFormat, value],
  );

  return { onChangeHandler, currentValue };
};
