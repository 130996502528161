/* eslint-disable no-console */
import { Configuration, LogLevel, SilentRequest } from '@azure/msal-browser';

import config from './config';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_OFFICE_SIGNUPORSIGNIN',
  },
  authorities: {
    signUpSignIn: {
      authority: `${config.b2c.authority}/B2C_1A_OFFICE_SIGNUPORSIGNIN`,
    },
  },
  authorityDomain: config.b2c.authorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: config.b2c.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    postLogoutRedirectUri: '',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

export const loginRequest: SilentRequest = {
  scopes: ['offline_access', 'openid', 'email'],
  forceRefresh: false,
};

export const scopesDictionary: Record<string, string[]> = {
  [config.airAmbulanceApi.baseUrl]: [config.airAmbulanceApi.scope],
  [config.peopleManagementApi.baseUrl]: [config.peopleManagementApi.scope],
  [config.kidnapAndExtortionApi.baseUrl]: [config.kidnapAndExtortionApi.scope],
  [config.securityResponseApi.baseUrl]: [config.securityResponseApi.scope],
  [config.dutyOfCareApi.baseUrl]: [config.dutyOfCareApi.scope],
  [config.paymentApi.baseUrl]: [config.paymentApi.scope],
  [config.reportsApi.baseUrl]: [config.reportsApi.scope],
  [config.travelLicenseApi.baseUrl]: [config.travelLicenseApi.scope],
  [config.organizationSagaApi.baseUrl]: [
    config.airAmbulanceApi.scope,
    config.kidnapAndExtortionApi.scope,
    config.dutyOfCareApi.scope,
    config.securityResponseApi.scope,
    config.travelLicenseApi.scope,
  ],
  [config.membershipSagaApi.baseUrl]: [
    config.airAmbulanceApi.scope,
    config.kidnapAndExtortionApi.scope,
    config.dutyOfCareApi.scope,
    config.securityResponseApi.scope,
  ],
};
