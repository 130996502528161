import { createSelector } from 'reselect';

import { IOrganizationSubscription } from 'models/interfaces/Subscription/OrganizationSubscription';
import { ISubscriptionSummaryModel } from 'models/interfaces/Subscription/SubscriptionSummaryModel';

import { ISubscriptionsState } from '.';
import { RootState } from '../../index';
import { OrganizationSubscriptionStatus } from '../../../../models/enums/OrganizationSubscriptionStatus';
import { aaSubscriptionDatesSelector } from '../airAmbulance/selectors';
import { keSubscriptionDatesSelector } from '../kidnapAndExtortion/selectors';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { ProductType } from '../../../../models/ProductType';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { srSubscriptionDatesSelector } from '../securityResponse/selectors';
import { docSubscriptionDatesSelector } from '../dutyOfCare/selectors';
import { tlSubscriptionDatesSelector } from '../travelLicense/selectors';

export const subscriptionsSelector = (state: RootState) => state.subscriptions;

export const subscriptionsListSelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): IOrganizationSubscription[] =>
    subscriptionState.subscriptions,
);

export const subscriptionsAvailableSelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): boolean =>
    !!subscriptionState.subscriptions?.length,
);

export const activeSubscriptionsListSelector = createSelector(
  subscriptionsListSelector,
  (subscriptions: IOrganizationSubscription[]): IOrganizationSubscription[] =>
    subscriptions?.filter(
      (subscription) =>
        subscription.status === OrganizationSubscriptionStatus.Active ||
        subscription.status === OrganizationSubscriptionStatus.New,
    ),
);

export const expiredSubscriptionsListSelector = createSelector(
  subscriptionsListSelector,
  (subscriptions: IOrganizationSubscription[]): IOrganizationSubscription[] =>
    subscriptions?.filter(
      (subscription) =>
        subscription.status === OrganizationSubscriptionStatus.Expired,
    ),
);

export const draftSubscriptionsListSelector = createSelector(
  subscriptionsListSelector,
  (subscriptions: IOrganizationSubscription[]): IOrganizationSubscription[] =>
    subscriptions?.filter(
      (subscription) =>
        subscription.status === OrganizationSubscriptionStatus.Draft,
    ),
);

export const subscriptionsGroupsListSelector = createSelector(
  activeSubscriptionsListSelector,
  expiredSubscriptionsListSelector,
  draftSubscriptionsListSelector,
  (
    activeSubscriptions: IOrganizationSubscription[],
    expiredSubscriptions: IOrganizationSubscription[],
    draftSubscriptions: IOrganizationSubscription[],
  ): Partial<
    Record<OrganizationSubscriptionStatus, IOrganizationSubscription[]>
  > => ({
    [OrganizationSubscriptionStatus.Active]: activeSubscriptions,
    [OrganizationSubscriptionStatus.Expired]: expiredSubscriptions,
    [OrganizationSubscriptionStatus.Draft]: draftSubscriptions,
  }),
);

export const subscriptionSummarySelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): ISubscriptionSummaryModel =>
    subscriptionState.subscriptionSummary,
);

export const subscriptionsSummarySelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): ISubscriptionSummaryModel[] =>
    subscriptionState.subscriptionsSummary,
);

export const addedSubscriptionsSelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): IOrganizationSubscription[] =>
    subscriptionState.newAddedSubscriptions,
);

export const deletedSubscriptionsIdsSelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: ISubscriptionsState): string[] =>
    subscriptionState.deletedSubscriptionsIds,
);

export const subscriptionDatesSelector = (productType: ProductType) =>
  createSelector(
    aaSubscriptionDatesSelector,
    keSubscriptionDatesSelector,
    srSubscriptionDatesSelector,
    docSubscriptionDatesSelector,
    tlSubscriptionDatesSelector,
    (
      aaSubscriptionDates: ISubscriptionDates,
      keSubscriptionDates: ISubscriptionDates,
      srSubscriptionDates: ISubscriptionDates,
      docSubscriptionDates: ISubscriptionDates,
      tlSubscriptionDates: ISubscriptionDates,
    ): ISubscriptionDates => {
      switch (productType) {
        case ProductType.AirAmbulance:
          return aaSubscriptionDates;
        case ProductType.KidnapAndExtortion:
          return keSubscriptionDates;
        case ProductType.SecurityResponse:
          return srSubscriptionDates;
        case ProductType.DutyOfCare:
          return docSubscriptionDates;
        case ProductType.TravelLicense:
          return tlSubscriptionDates;
        default:
          return null;
      }
    },
  );

export const subscriptionExtensionSummarySelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: ISubscriptionsState,
  ): IOrganizationSubscriptionExtensionSummaryModel =>
    subscriptionState.subscriptionExtensionSummary,
);
