import { OrganizationType } from '../../../../models/enums/OrganizationType';
import { Wrapper } from './organizationNameRenderer.styles';
import { ICellRenderer } from '../cellRenderers.models';
import OrganizationTypeIcon from '../../../UI/OrganizationTypeIcon/OrganizationTypeIcon';
import TableCell from '../TableCell/TableCell';
import EmptyCell from '../EmptyCell/EmptyCell';

export interface IOrganizationNameRenderer extends ICellRenderer {
  organizationType: OrganizationType;
  organizationName: string;
}

export const OrganizationNameRenderer = ({
  organizationName,
  options,
  organizationType,
}: IOrganizationNameRenderer) => {
  if (!organizationName) {
    return (
      <EmptyCell bold={options?.textBold} alignment={options?.alignment} />
    );
  }
  return (
    <TableCell bold={options?.textBold} alignment={options?.alignment}>
      <Wrapper>
        <OrganizationTypeIcon organizationType={organizationType} />
        {organizationName}
      </Wrapper>
    </TableCell>
  );
};
