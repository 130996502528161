export enum MenuActions {
  Remove = 'remove',
  DoNotRenew = 'do-not-renew',
  ResendWelcomeEmail = 'resend-welcome-email',
  Renew = 'renew',
}

export type MemberOverviewMemberTablePermissions = {
  canRemoveSecondaryMemberPermissions?: string[];
  canChangeToBeRenewedPermissions?: string[];
  canResendWelcomeEmailPermissions?: string[];
  canViewActionColumnPermissions?: string[];
};
