import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { docSubscriptionPricingSchema } from '../../../Forms/DutyOfCare/DOCSubscriptionPackageOptionsForm/docSubscriptionPricingForm.validation';
import { DOCSubscriptionFormFields } from './dutyOfCareSubscriptionForm.models';

export const docSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [DOCSubscriptionFormFields.PackageOptions]: docSubscriptionPricingSchema,
  },
  required: [],
};

export const validateAddDOCSubscriptionForm = (
  data: IDOCOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, docSubscriptionSchema);
