import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IMembershipPlanModel } from '../../../../models/interfaces/Membership/MembershipPlanModel';

import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';

export interface IMembershipPlansQueryResponse {
  plans: IMembershipPlanModel[];
}

export const getRegularAAPlansQuery = (
  primaryMemberBirthDate?: string,
  membershipEffectiveDate?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IMembershipPlansQueryResponse>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'primaryMemberBirthDate',
      value: primaryMemberBirthDate,
    },
    {
      name: 'membershipEffectiveDate',
      value: membershipEffectiveDate,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(`api/plans${params}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancellationToken,
  });
};
