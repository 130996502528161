import { useTranslation } from 'react-i18next';

import { useBaseFormInput } from '../../../../../hooks/useBaseFormInput';

import { DiscountType } from '../../../../../models/enums/DiscountType';

import {
  Wrapper,
  DiscountInput,
  StyledTabOptions,
} from './membershipPlanDiscount.styles';

interface IMembershipPlanDiscount {
  disabled?: boolean;
  onChangeDiscountType: (discountType: DiscountType) => void;
  onChangeDiscountValue: (value: number) => void;
  value: { discountType: string; discountValue: number };
}

const MembershipPlanDiscount = ({
  disabled,
  onChangeDiscountType,
  onChangeDiscountValue,
  value,
}: IMembershipPlanDiscount) => {
  const { t } = useTranslation();

  const changeValueHandler = (currentValue: string) => {
    onChangeDiscountValue(Number.parseFloat(currentValue));
  };

  const { inputValue, onChangeHandler } = useBaseFormInput<string>({
    input: {
      value: value.discountValue.toString(),
      onChange: changeValueHandler,
    },
  });

  return (
    <Wrapper>
      <DiscountInput
        defaultIcon={
          value.discountType === DiscountType.Amount ? 'dollar' : 'percentage'
        }
        labelAnimation
        disabled={disabled}
        allowNegative={false}
        max={value.discountType === DiscountType.Amount ? null : 100}
        min={0}
        decimalScale={value.discountType === DiscountType.Amount ? 2 : 0}
        label={t('fields.discount.label')}
        onChangeValue={onChangeHandler}
        value={inputValue}
      />

      <StyledTabOptions
        disabled={disabled}
        onChange={(discountType: string) =>
          onChangeDiscountType(discountType as DiscountType)
        }
        options={[
          {
            value: DiscountType.Amount,
            label: t(`enums.discount-mode.${DiscountType.Amount}`),
          },
          {
            value: DiscountType.Percentage,
            label: t(`enums.discount-mode.${DiscountType.Percentage}`),
          },
        ]}
        value={value.discountType}
      />
    </Wrapper>
  );
};

export default MembershipPlanDiscount;
