import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { SectionSubTitle } from '../../Typography/Typography';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Title = styled(SectionSubTitle)`
  font-weight: bold;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type OptionProps = {
  checked?: boolean;
};

export const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;

  ${({ checked, theme }) =>
    checked
      ? css`
          font-weight: bold;
          color: ${theme.color.black};
          fill: ${theme.color.black};
        `
      : css`
          color: ${theme.color.dark2};
          fill: ${theme.color.dark2};
        `}
`;

export const OptionLabel = styled.div`
  font: 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  margin-left: 0.5rem;
`;
