import { IValidationErrorResponse } from '../../../models/ValidationErrorResponse';

type MembershipsErrors = {
  secondaryMembers: string[];
  primaryMember: string;
};

export type ErrorsType = {
  memberships: MembershipsErrors[];
};

export const getNumberOfFormErrors = (errors: ErrorsType) =>
  (errors?.memberships || []).reduce((acc, membershipErr) => {
    if (!membershipErr) {
      return acc;
    }

    if (membershipErr.secondaryMembers) {
      acc = acc + membershipErr.secondaryMembers.length;
    }
    if (membershipErr.primaryMember) {
      acc = acc + 1;
    }

    return acc;
  }, 0);

export const needShowAnalyseModal = (
  err: IValidationErrorResponse,
  errCodes: string[],
) =>
  err.ValidationErrors?.length === 1 &&
  errCodes.includes(err.ValidationErrors[0].ErrorCode);
