import { AnySchema } from 'ajv';

import { DateFilterType } from '../../../../../../../models/enums/RenewalFilters/DateFilterType';

import { validateFormData } from '../../../../../../../utils/validations/validateFormData';

import { DateFilterFields, DateFilterModel } from './dateFilter.models';
import { dateRangeFilterSchema } from './DateFilterTypes/DatesRangeFilter/datesRangeFilter.validation';
import { monthsRangeFilterSchema } from './DateFilterTypes/MonthsRangeFilter/monthsRangeFilter.validation';
import { singleDateFilterSchema } from './DateFilterTypes/SingleDateFilter/singleDateFilter.validation';

export const dateFilterValidationSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [DateFilterFields.Value]: {
      type: 'string',
    },
  },
  dependencies: {
    [DateFilterFields.Value]: {
      allOf: [
        {
          if: {
            properties: {
              [DateFilterFields.Value]: {
                enum: [DateFilterType.DatesRange],
              },
            },
          },
          then: {
            properties: {
              [DateFilterFields.AdditionalValues]: dateRangeFilterSchema,
            },
          },
        },
        {
          if: {
            properties: {
              [DateFilterFields.Value]: {
                enum: [DateFilterType.MonthsRange],
              },
            },
          },
          then: {
            properties: {
              [DateFilterFields.AdditionalValues]: monthsRangeFilterSchema,
            },
          },
        },
        {
          if: {
            properties: {
              [DateFilterFields.Value]: {
                enum: [DateFilterType.FixedPeriod],
              },
            },
          },
          then: {
            properties: {
              [DateFilterFields.AdditionalValues]: singleDateFilterSchema,
            },
          },
        },
      ],
    },
  },
  required: [DateFilterFields.Value],
};

export const validateDateFilter = (data: DateFilterModel) =>
  validateFormData(data, null, dateFilterValidationSchema);
