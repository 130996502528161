import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { DutyOfCareMembershipOptions } from '../../../../../../../models/enums/DutyOfCareMembershipOptions';

import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { DOCMembershipOrganizationDetailsFormFields } from '../DOCMembershipDetailsForm/docMembershipDetailsForm.models';

export const useDOCMembershipOptions = () => {
  const {
    input: { onChange: sendWelcomeEmailChange },
  }: FieldRenderProps<boolean> = useField(
    DutyOfCareMembershipOptions.SendWelcomeEmail,
  );

  const {
    input: { onChange: autoRenewalChange },
  }: FieldRenderProps<boolean> = useField(
    DutyOfCareMembershipOptions.AutoRenewal,
  );

  const {
    input: { value: selectedOrganization },
  }: FieldRenderProps<IOrganizationSearch> = useField(
    DOCMembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { value: subscriptionType },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  useEffect(() => {
    if (!subscriptionType || !selectedOrganization) {
      sendWelcomeEmailChange(null);
      autoRenewalChange(null);
      return;
    }

    const subscriptionIndex = selectedOrganization.subscriptions.findIndex(
      (x) => x.id === subscriptionType,
    );

    if (subscriptionIndex === -1) {
      sendWelcomeEmailChange(null);
      autoRenewalChange(null);
      return;
    }

    sendWelcomeEmailChange(
      selectedOrganization.subscriptions[subscriptionIndex].sendWelcomeEmail,
    );
    autoRenewalChange(
      selectedOrganization.subscriptions[subscriptionIndex]
        .autoRenewalMemberships,
    );
  }, [
    autoRenewalChange,
    selectedOrganization,
    sendWelcomeEmailChange,
    subscriptionType,
  ]);
};
