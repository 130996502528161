import { useTranslation } from 'react-i18next';

import { canAddSubscriptionsPermissions } from '../../../../../config/accessPolicies/accessPolicies';

import { IOrganizationSubscription } from '../../../../../models/interfaces/Subscription/OrganizationSubscription';

import { OrganizationSubscriptionStatus } from '../../../../../models/enums/OrganizationSubscriptionStatus';

import { SectionSubTitle } from '../../../../../styledComponents/Typography/Typography';
import Button from '../../../../../styledComponents/UI/Button/Button';

import {
  Header,
  StyledGroupedSubscriptionsList,
} from './contractOverviewSubscriptions.styles';

interface IContractOverviewSubscriptionsProps {
  data: Partial<
    Record<OrganizationSubscriptionStatus, IOrganizationSubscription[]>
  >;
  onSubscriptionClick?: (subscription: IOrganizationSubscription) => void;
  onAddSubscription?: () => void;
}

const ContractOverviewSubscriptions = ({
  data,
  onAddSubscription,
  onSubscriptionClick,
}: IContractOverviewSubscriptionsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Header>
        <SectionSubTitle>{t('headers.subscriptions')}</SectionSubTitle>
        <Button
          permissions={canAddSubscriptionsPermissions}
          styleType="link"
          onClick={onAddSubscription}
        >
          {t('buttons.add-subscription')}
        </Button>
      </Header>
      <StyledGroupedSubscriptionsList
        subscriptions={data}
        onItemClick={onSubscriptionClick}
        propertiesWidth={{
          productType: 6.625,
          effectiveDateFrom: 9.375,
          effectiveDateTo: 9.375,
          capacity: 8.125,
          amount: 6.875,
        }}
      />
    </div>
  );
};

export default ContractOverviewSubscriptions;
