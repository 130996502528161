import { createSelector } from '@reduxjs/toolkit';

import { IRenewalFiltersState } from '.';

import { RootState } from '../index';

export const renewalFilterSelector = (state: RootState) => state.renewalFilter;

export const renewalContractsSelector = createSelector(
  renewalFilterSelector,
  (renewalFilterState: IRenewalFiltersState) =>
    renewalFilterState.renewalsContracts,
);

export const renewalSubscriptionsSelector = createSelector(
  renewalFilterSelector,
  (renewalFilterState: IRenewalFiltersState) =>
    renewalFilterState.renewalsSubscriptions,
);
