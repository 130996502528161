/* eslint-disable sort-keys */
// FOR color naming
// https://chir.ag/projects/name-that-color/

import { createTheme } from '@mui/material/styles';

export const THEME = {
  color: {
    primary: '#003057',
    secondary1: '#34657f',
    secondary2: '#b9d3dc',
    core_black: '#000000',
    black: '#212721',
    dark1: '#505759',
    white: '#ffffff',
    light1: '#ececec',
    light2: '#e9e9e9',
    light3: '#fcfeff',
    dark2: '#afb5b7',
    main_background: '#e5e5e5',
    background2: '#fbfbfb',
    input_border: '#cdd1d2',
    success: '#009c19',
    error: '#cc0018',
    lighter_blue: '#4c8eb1',
    tooltip: '#c4c4c4',
    hover: '#f6f9fb',
    success2: '#69b575',
    green1: '#219653',
    alabaster: '#FAFAFA',
    logoutHover: '#B06161',
    warning: '#F28E19',
    dark3: '#D8E0E3',
    dark_blue: '#002F56',
  },

  font: {
    gotham: `Gotham, 'Segoe UI', sans-serif`,
    gothamXNarr: `GothamXNarr, 'Segoe UI', sans-serif`,
    openSans: `'Open Sans', sans-serif`,
  },
};

export const muiTheme = createTheme({
  // add some custom theme ...
  // for more info see: https://mui.com/system/styled/#using-the-theme
  // palette: {
  //   background: {
  //     default: THEME.color.white,
  //   },
  // },
});
