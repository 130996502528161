import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import arrayMutators from 'final-form-arrays';

import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import { focusOnErrorDecorator } from '../../../../utils/focusOnErrorDecorator';

import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import WarningsConfirmationModal from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { useEditKESubscriptionForm } from './editKESubscriptionForm.hooks';
import SubscriptionItemForm from '../../../../styledComponents/SubscriptionFormPage/SubscriptionItemForm/SubscriptionItemForm';
import Spinner from '../../../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  PageRootColumns,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import FormSubmitButtons from '../../../../styledComponents/UI/FormSubmitButtons/FormSubmitButtons';
import { SelectContractForm } from '../../../../styledComponents/Forms/SelectContractForm/SelectContractForm';
import SubscriptionSummaryForm from '../../../../styledComponents/FormSummary/SubscriptionSummary/SubscriptionSummary';
import SummaryLoader from '../SummaryLoader/SummaryLoader';
import { getContractType } from '../../../../utils/contractHelpers';

interface IEditKESubscriptionFormProps {
  onContractChanged?: (value: string) => void;
}

const EditKESubscriptionForm = ({
  onContractChanged,
}: IEditKESubscriptionFormProps) => {
  const { t } = useTranslation();

  const {
    validateForm,
    handleSubmit,
    initData,
    organizationContracts,
    subscriptionEditing,
    dataLoading,
    updateOrganizationSubscription,
    onAddNewContractHandler,
  } = useEditKESubscriptionForm();

  return (
    <WarningsConfirmationProvider
      submitFunction={updateOrganizationSubscription}
    >
      <WarningsConfirmationModal />
      <Form
        decorators={[focusOnErrorDecorator]}
        initialValues={initData}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ values, ...renderProps }) => (
          <FormErrorActivator>
            <Spinner isVisible={dataLoading} size="10%">
              <form>
                <PageRootColumns>
                  <LeftContainer>
                    <SelectContractForm
                      isClearable
                      contracts={organizationContracts}
                      disabled={subscriptionEditing}
                      onAddNewContract={onAddNewContractHandler}
                      onContractChanged={onContractChanged}
                      message={
                        values.contractId &&
                        values.contractId !== initData.contractId &&
                        t('phrase.subscription-date-changed')
                      }
                      setDefaultValue={false}
                    />
                    <SubscriptionItemForm
                      collapsible={false}
                      contractType={getContractType(
                        values?.contractId,
                        organizationContracts,
                      )}
                      isEditMode
                      disabled={subscriptionEditing}
                    />
                    <SummaryLoader values={values} />
                  </LeftContainer>
                  <RightContainer>
                    <SubscriptionSummaryForm
                      title={t('headers.summary')}
                      subscriptionType={values.productType}
                      disabled={subscriptionEditing}
                      isPriceOverridden={values.overwriteAmount}
                    />
                    <FormSubmitButtons
                      onlyDraft={
                        organizationContracts.find(
                          (element) => element.id === values.contractId,
                        )?.isDraft
                      }
                      onlyDraftTooltip={t(
                        'phrase.only-draft-because-contract-is-draft',
                      )}
                      handleSubmit={renderProps.handleSubmit}
                      canSaveDraft
                      isProcessing={subscriptionEditing}
                      saveLabel={t('buttons.create')}
                    />
                  </RightContainer>
                </PageRootColumns>
              </form>
            </Spinner>
          </FormErrorActivator>
        )}
      </Form>
    </WarningsConfirmationProvider>
  );
};

export default EditKESubscriptionForm;
