import { DutyOfCareSubscriptionPackageType } from '../../../../models/enums/DutyOfCareSubscriptionPackageType';

export enum DOCMembersListFilters {
  Search = 'search',
  Package = 'package',
  RenewStatus = 'renewStatus',
  SubscriptionId = 'subscriptionId',
  OversubscribedOnly = 'oversubscribedOnly',
}

export type DOCMembersFiltersProps = {
  availableFilters: DOCMembersListFilters[];
  packages?: DutyOfCareSubscriptionPackageType[];
};
