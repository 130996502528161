import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_LOADING_PROCESSING,
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_ADDING_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  createSubscriptions,
  getSubscriptions,
  getSubscriptionsSummary,
} from './api';

import {
  ICreateNewSubscriptionPayload,
  IDeleteProductSubscriptionByProductTypePayload,
  IGetSubscriptionsResponse,
  ILoadDatesForNewSubscriptionByProductTypePayload,
  ILoadDatesForNewSubscriptionPayload,
  ILoadSubscriptionExtensionSummaryByProductTypePayload,
  IEditSubscriptionRenewalStatusByProductTypePayload,
} from './models';
import {
  IEditAASubscriptionRenewalStatusPayload,
  ILoadSubscriptionExtensionSummary,
  ILoadSubscriptionsPayload,
  ILoadSubscriptionsSummary,
} from '../airAmbulance/models';
import { updateSubscriptionList } from './utils';
import { IGetSubscriptionsSummaryModel } from '../../../../models/interfaces/Subscription/GetSubscriptionsSummaryModel';
import {
  addNewAddedSubscriptions,
  setSubscriptions,
  setSubscriptionsSummary,
} from '../../../redux-slices/subscriptions/common';
import { ProductType } from '../../../../models/ProductType';
import {
  deleteProductAASubscription,
  editAASubscriptionRenewalStatus,
  loadAASubscriptionExtensionSummary,
  loadDatesForNewAASubscription,
} from '../airAmbulance';
import {
  deleteProductKESubscription,
  editKESubscriptionRenewalStatus,
  loadDatesForNewKESubscription,
  loadKESubscriptionExtensionSummary,
} from '../kidnapAndExtortion';
import {
  deleteProductSRSubscription,
  editSRSubscriptionRenewalStatus,
  loadDatesForNewSRSubscription,
  loadSRSubscriptionExtensionSummary,
} from '../securityResponse';
import {
  deleteProductDOCSubscription,
  editDoCSubscriptionRenewalStatus,
  loadDatesForNewDOCSubscription,
} from '../dutyOfCare';
import {
  deleteProductTLSubscription,
  editTLSubscriptionRenewalStatus,
  loadDatesForNewTLSubscription,
} from '../travelLicense';
import { IEditKESubscriptionRenewalStatusPayload } from '../kidnapAndExtortion/models';
import { IEditDoCSubscriptionRenewalStatusPayload } from '../dutyOfCare/models';
import { IEditSRSubscriptionRenewalStatusPayload } from '../securityResponse/models';
import { IEditTLSubscriptionRenewalStatusPayload } from '../travelLicense/models';

const subscriptionsSlice = createSliceSaga({
  name: 'subscriptions-saga',
  caseSagas: {
    *createNewSubscriptions({
      payload: { data, error, organizationId, success, cancellationToken },
    }: PayloadAction<ICreateNewSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_ADDING_PROCESSING));

        const response = yield* call(
          createSubscriptions,
          organizationId,
          data,
          cancellationToken,
        );
        yield put(addNewAddedSubscriptions(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_ADDING_PROCESSING));
      }
    },
    *loadSubscriptions({
      payload: {
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadSubscriptionsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_LOADING_PROCESSING));
        const response: AxiosResponse<IGetSubscriptionsResponse> = yield* call(
          getSubscriptions,
          organizationId,
          contractId,
          cancellationToken,
        );

        const currentSubscriptions = yield* call(
          updateSubscriptionList,
          response.data.subscriptions,
        );

        yield put(setSubscriptions(currentSubscriptions));

        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_LOADING_PROCESSING));
      }
    },
    *loadSubscriptionsSummary({
      payload: { data, error, organizationId, success, cancellationToken },
    }: PayloadAction<ILoadSubscriptionsSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));

        const filteredData: IGetSubscriptionsSummaryModel = {
          subscriptions: data?.subscriptions,
        };
        const response = yield* call(
          getSubscriptionsSummary,
          organizationId,
          filteredData,
          cancellationToken,
        );
        yield put(setSubscriptionsSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadDatesForNewSubscription({
      payload,
    }: PayloadAction<ILoadDatesForNewSubscriptionPayload>) {
      yield put(loadDatesForNewAASubscription(payload));
      yield put(loadDatesForNewKESubscription(payload));
      yield put(loadDatesForNewSRSubscription(payload));
      yield put(loadDatesForNewDOCSubscription(payload));
      yield put(loadDatesForNewTLSubscription(payload));
    },
    *loadDatesForSubscriptionByProductType({
      payload: { productType, ...restPayload },
    }: PayloadAction<ILoadDatesForNewSubscriptionByProductTypePayload>) {
      switch (productType) {
        case ProductType.AirAmbulance:
          yield put(loadDatesForNewAASubscription(restPayload));
          return;
        case ProductType.KidnapAndExtortion:
          yield put(loadDatesForNewKESubscription(restPayload));
          return;
        case ProductType.SecurityResponse:
          yield put(loadDatesForNewSRSubscription(restPayload));
          return;
        case ProductType.DutyOfCare:
          yield put(loadDatesForNewDOCSubscription(restPayload));
          return;
        case ProductType.TravelLicense:
          yield put(loadDatesForNewTLSubscription(restPayload));
      }
    },
    *deleteProductSubscriptionByProductType({
      payload: { productType, ...restPayload },
    }: PayloadAction<IDeleteProductSubscriptionByProductTypePayload>) {
      switch (productType) {
        case ProductType.AirAmbulance:
          yield put(deleteProductAASubscription(restPayload));
          return;
        case ProductType.KidnapAndExtortion:
          yield put(deleteProductKESubscription(restPayload));
          return;
        case ProductType.SecurityResponse:
          yield put(deleteProductSRSubscription(restPayload));
          return;
        case ProductType.DutyOfCare:
          yield put(deleteProductDOCSubscription(restPayload));
          return;
        case ProductType.TravelLicense:
          yield put(deleteProductTLSubscription(restPayload));
      }
    },
    *loadSubscriptionExtensionSummaryByProductType({
      payload,
    }: PayloadAction<ILoadSubscriptionExtensionSummaryByProductTypePayload>) {
      switch (payload.productType) {
        case ProductType.AirAmbulance:
          yield put(
            loadAASubscriptionExtensionSummary(
              payload as ILoadSubscriptionExtensionSummary,
            ),
          );
          return;
        case ProductType.KidnapAndExtortion:
          yield put(
            loadKESubscriptionExtensionSummary(
              payload as ILoadSubscriptionExtensionSummary,
            ),
          );
          return;
        case ProductType.SecurityResponse:
          yield put(
            loadSRSubscriptionExtensionSummary(
              payload as ILoadSubscriptionExtensionSummary,
            ),
          );
      }
    },
    *editSubscriptionRenewalStatusByProductType({
      payload: { productType, ...restPayload },
    }: PayloadAction<IEditSubscriptionRenewalStatusByProductTypePayload>) {
      switch (productType) {
        case ProductType.AirAmbulance:
          yield put(
            editAASubscriptionRenewalStatus(
              restPayload as IEditAASubscriptionRenewalStatusPayload,
            ),
          );
          return;
        case ProductType.KidnapAndExtortion:
          yield put(
            editKESubscriptionRenewalStatus(
              restPayload as IEditKESubscriptionRenewalStatusPayload,
            ),
          );
          return;
        case ProductType.DutyOfCare:
          yield put(
            editDoCSubscriptionRenewalStatus(
              restPayload as IEditDoCSubscriptionRenewalStatusPayload,
            ),
          );
          return;
        case ProductType.SecurityResponse:
          yield put(
            editSRSubscriptionRenewalStatus(
              restPayload as IEditSRSubscriptionRenewalStatusPayload,
            ),
          );
          return;
        case ProductType.TravelLicense:
          yield put(
            editTLSubscriptionRenewalStatus(
              restPayload as IEditTLSubscriptionRenewalStatusPayload,
            ),
          );
          return;
        default:
          return null;
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default subscriptionsSlice.saga;
export const {
  createNewSubscriptions,
  loadSubscriptions,
  loadSubscriptionsSummary,
  loadDatesForNewSubscription,
  loadDatesForSubscriptionByProductType,
  deleteProductSubscriptionByProductType,
  loadSubscriptionExtensionSummaryByProductType,
  editSubscriptionRenewalStatusByProductType,
} = subscriptionsSlice.actions;
export const { actions } = subscriptionsSlice;
