import { Modal } from '../../../../styledComponents/Modal/Modal';
import { REPLACE_CREDIT_CARD_MODAL_NAME } from './replaceCreditCardModal.const';
import FormErrorProvider from '../../../../styledComponents/FormErrorProvider/FormErrorProvider';
import ReplaceCreditCardModalForm from './ReplaceCreditCardModalForm/ReplaceCreditCardModalForm';

interface IReplaceCreditCardModalProps {
  onCreditCardReplaced?: () => void;
}

const ReplaceCreditCardModal = ({
  onCreditCardReplaced,
}: IReplaceCreditCardModalProps) => (
  <FormErrorProvider>
    <Modal name={REPLACE_CREDIT_CARD_MODAL_NAME}>
      <ReplaceCreditCardModalForm onReplacedCreditCard={onCreditCardReplaced} />
    </Modal>
  </FormErrorProvider>
);

export default ReplaceCreditCardModal;
