import { getIn } from 'final-form';
import { useTranslation } from 'react-i18next';

import { IApiValidationWarning } from '../../../../models/ValidationApiWarning';
import { formatDate } from '../../../../utils/dateHelpers';
import { preparePathFromResponse } from '../../../../utils/pathHelpers';
import { isEmpty } from '../../../../utils/textHelpers';
import { StandardText } from '../../../Typography/Typography';
import { checkIfPropertyIsDateByName } from '../../warningsConfirmation.utils';

import { Property, Wrapper } from './modalWarning.styles';

type ModalWarningProps = {
  warning: IApiValidationWarning;
  data: any;
};

const ModalWarning = ({ warning, data }: ModalWarningProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StandardText>
        {t(`api-warnings.descriptions.${warning.Code}`)}
      </StandardText>
      {data && !isEmpty(warning.PropertyName) && (
        <Property>
          <StandardText>
            {t(`api-warnings.property.${warning.Code}`)}
          </StandardText>

          <StandardText>
            {checkIfPropertyIsDateByName(warning.PropertyName)
              ? formatDate(
                  getIn(data, preparePathFromResponse(warning.PropertyName)),
                )
              : getIn(data, preparePathFromResponse(warning.PropertyName))}
          </StandardText>
        </Property>
      )}
    </Wrapper>
  );
};

export default ModalWarning;
