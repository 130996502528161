import {
  IMemberOverviewActiveMemberSummaryProps,
  MemberOverviewActiveMemberSummaryByProductType,
} from './memberOverviewActiveMemberSummary.utils';

const MemberOverviewActiveMemberSummary = ({
  productType,
  ...props
}: IMemberOverviewActiveMemberSummaryProps) => {
  const Content = MemberOverviewActiveMemberSummaryByProductType[productType];
  return <>{Content && <Content {...props} productType={productType} />}</>;
};

export default MemberOverviewActiveMemberSummary;
