import { useTranslation } from 'react-i18next';

import { Wrapper } from '../Common/memberOverviewMembers.styles';
import MemberOverviewExpiredMemberSummary from './MemberOverviewExpiredMemberSummary/MemberOverviewExpiredMemberSummary';
import ItemsList from '../../../styledComponents/UI/ItemsList/ItemsList';
import MemberOverviewExpiredMemberDetails from './MemberOverviewExpiredMemberDetails/MemberOverviewExpiredMemberDetails';
import { useMemberOverviewExpiredMembers } from './memberOverviewExpiredMembers.hooks';
import CollapseContent from '../../../styledComponents/UI/CollapseContent/CollapseContent';

const MemberOverviewExpiredMembers = () => {
  const { t } = useTranslation();

  const { expiredPersonMemberships } = useMemberOverviewExpiredMembers();

  if (expiredPersonMemberships.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <CollapseContent
        title={t('headers.expired')}
        isHidden={!expiredPersonMemberships?.length}
      >
        <ItemsList
          itemSummary={(data) => (
            <MemberOverviewExpiredMemberSummary
              data={data}
              productType={data.productSubscription}
            />
          )}
          items={expiredPersonMemberships}
          itemDetails={(data) => (
            <MemberOverviewExpiredMemberDetails
              productType={data.productSubscription}
              membershipId={data.membershipId}
              canBeRemoved={data.metadata.canDelete}
            />
          )}
        />
      </CollapseContent>
    </Wrapper>
  );
};

export default MemberOverviewExpiredMembers;
