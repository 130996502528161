import { AirAmbulanceMembershipOptions } from '../../../../../models/enums/AirAmbulanceMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const EDIT_AA_MEMBERSHIP_OPTIONS_MODAL =
  'EDIT_AA_MEMBERSHIP_OPTIONS_MODAL';

export const aaMembershipOptionsList: IFormCheckboxGroupOptionProps[] = [
  {
    name: AirAmbulanceMembershipOptions.SendWelcomeEmail,
    optionDisabled: true,
  },
  {
    name: AirAmbulanceMembershipOptions.EmailOptOut,
  },
];
