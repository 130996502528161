import { useTranslation } from 'react-i18next';

import {
  DetailsContainer,
  Detail,
  DetailLabel,
  DetailValue,
  Title,
  Wrapper,
  Header,
  StyledButton,
} from '../../../../styledComponents/Overview/OverviewDetails/OverviewDetails';
import { formatDate } from '../../../../utils/dateHelpers';
import { isPropertyEmpty } from '../../../../utils/propertyHelpers';

type OverviewDetailsData = {
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  organizationContractName?: string;
};

type OverviewDetailsProps = {
  data: OverviewDetailsData;
  onRenewClick?: () => void;
  canBeRenewed?: boolean;
  canRenewMembershipPermissions?: string[];
};

const MemberOverviewDetails = ({
  data,
  onRenewClick,
  canBeRenewed,
  canRenewMembershipPermissions,
}: OverviewDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Title>{t('headers.details')}</Title>
        {onRenewClick && (
          <StyledButton
            permissions={canRenewMembershipPermissions}
            styleType="link"
            onClick={onRenewClick}
            disabled={!isPropertyEmpty(canBeRenewed) ? !canBeRenewed : false}
          >
            {t('buttons.renew')}
          </StyledButton>
        )}
      </Header>
      <DetailsContainer>
        <Detail>
          <DetailLabel>{`${t('phrase.effective-date')}:`}</DetailLabel>
          <DetailValue>
            {data && formatDate(data.effectiveDateFrom)}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('phrase.expiration-date')}:`} </DetailLabel>
          <DetailValue>{data && formatDate(data.effectiveDateTo)}</DetailValue>
        </Detail>
        {data && data.organizationContractName && (
          <Detail>
            <DetailLabel>{`${t('phrase.related-contract')}:`} </DetailLabel>
            <DetailValue>{data && data.organizationContractName}</DetailValue>
          </Detail>
        )}
      </DetailsContainer>
    </Wrapper>
  );
};
export default MemberOverviewDetails;
