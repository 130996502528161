import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { SubscriptionRenewPricing } from '../../Common/SubscriptionRenewPricing/SubscriptionPricing';

import { useKERenewSubscriptionPricing } from './keRenewSubscriptionPricing.hooks';

type KERenewSubscriptionPricingMode = CustomComponent & {
  productType?: ProductType;
};

export const KERenewSubscriptionPricing = ({
  disabled,
  productType,
}: KERenewSubscriptionPricingMode) => {
  const {
    membershipPlansPreviousPrices,
    membershipPlansPrices,
    subscriptionRenewalSuggestionPlans,
    loadCapacityBySuggestions,
    suggestionsCapacity,
  } = useKERenewSubscriptionPricing();

  return (
    <SubscriptionRenewPricing
      disabled={disabled}
      onTransferAllEligibleMembershipsChange={loadCapacityBySuggestions}
      previousMembershipPlansPrices={membershipPlansPreviousPrices}
      regularMembershipPlansPrices={membershipPlansPrices}
      subscriptionRenewalSuggestionPlans={subscriptionRenewalSuggestionPlans}
      defaultPlansData={suggestionsCapacity}
      productType={productType}
    />
  );
};
