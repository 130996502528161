import styled from '@emotion/styled';

export const HeaderTitleWrapper = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  text-align: center;
  text-decoration: none;
  margin: 0;
  font: 700 1.5rem/3.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const IconContainer = styled.div`
  margin-right: 0.625rem;
`;
