import { useMemo } from 'react';
import { Params, useSearchParams } from 'react-router-dom';

export const useRouteParams = <
  T extends string | Record<string, string | undefined> = string,
>(): Readonly<[T] extends [string] ? Params<T> : Partial<T>> => {
  const [searchParams] = useSearchParams();

  const preparedParams = useMemo(() => {
    const result = {};
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      result[param] = value;
    }
    return result as Readonly<[T] extends [string] ? Params<T> : Partial<T>>;
  }, [searchParams]);
  return preparedParams;
};
