import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import ErrorImage from '../../assets/images/403.png';
import ErrorPage from '../../styledComponents/ErrorPage/ErrorPage';
import { useAccessForbiddenPage } from './accessForbiddenPage.hooks';

const AccessForbiddenPage = ({ resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();

  const { goToHomePage } = useAccessForbiddenPage(resetErrorBoundary);

  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.access-forbidden')}
      description={[t('phrase.access-forbidden-description')]}
      buttonLabel={t('buttons.go-to-home-page')}
      onClick={goToHomePage}
    />
  );
};

export default AccessForbiddenPage;
