import TableSortLabel from '@mui/material/TableSortLabel';

import { ReactComponent as SortIcon } from '../../../assets/icons/sort-icon.svg';
import { IMaterialColumn } from '../materialTable.models';
import { HeaderTableCell } from '../TableHeader/tableHeader.styles';
import {
  useMaterialTableToolsValues,
  useMaterialTableToolsActions,
} from './materialTableTools.hooks';

interface IMaterialTableSortProps {
  column: IMaterialColumn;
}

const MaterialTableSort = ({ column }: IMaterialTableSortProps) => {
  const {
    sort: { order, orderBy },
  } = useMaterialTableToolsValues();
  const {
    sort: { handleRequestSort },
  } = useMaterialTableToolsActions();

  return (
    <HeaderTableCell
      align={column.alignment}
      sortDirection={orderBy === column.field ? order : false}
    >
      {column.sortable ? (
        <TableSortLabel
          IconComponent={SortIcon}
          active={orderBy === column.field}
          direction={orderBy === column.field ? order : 'asc'}
          onClick={() => handleRequestSort(column.field)}
        >
          {column.title}
        </TableSortLabel>
      ) : (
        column.title
      )}
    </HeaderTableCell>
  );
};

export default MaterialTableSort;
