import { useTranslation } from 'react-i18next';

import {
  Header,
  StyledButton,
} from '../../../../styledComponents/Overview/OverviewDetails/OverviewDetails';
import { SectionTitle } from '../../../../styledComponents/Typography/Typography';
import Button from '../../../../styledComponents/UI/Button/Button';
import { HeaderActionsContainer } from './promoCodeOverviewDetailsHeader.styles';
import { ReactComponent as BasketIcon } from '../../../../assets/icons/basket-icon.svg';
import {
  canCloneAAPromoCodePermissions,
  canDeleteAAPromoCodePermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import MainTooltip from '../../../../styledComponents/UI/MainTooltip/MainTooltip';

interface PromoCodeOverviewDetailsHeaderProps {
  onCloneClick?: () => void;
  onDeleteClick?: () => void;
  canBeDeleted?: boolean;
}

const PromoCodeOverviewDetailsHeader = ({
  onCloneClick,
  onDeleteClick,
  canBeDeleted,
}: PromoCodeOverviewDetailsHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Header>
      <SectionTitle>{t('headers.details')}</SectionTitle>
      <HeaderActionsContainer>
        <StyledButton
          permissions={canCloneAAPromoCodePermissions}
          styleType="link"
          onClick={onCloneClick}
        >
          {t('buttons.clone')}
        </StyledButton>
        {canBeDeleted && (
          <MainTooltip arrow title={t('phrase.remove-promo-code')}>
            <Button
              permissions={canDeleteAAPromoCodePermissions}
              onClick={onDeleteClick}
              styleType="icon"
            >
              <BasketIcon />
            </Button>
          </MainTooltip>
        )}
      </HeaderActionsContainer>
    </Header>
  );
};

export default PromoCodeOverviewDetailsHeader;
