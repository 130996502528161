import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export interface IKEPricingState {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

const initialState: IKEPricingState = {
  membershipPlansPrices: [],
};

const kePricingSlice = createSlice({
  name: 'kePricing',
  initialState,
  reducers: {
    setKEMembershipPlansPrices: (
      state,
      { payload }: PayloadAction<IMembershipPlanPriceModel[]>,
    ) => {
      state.membershipPlansPrices = payload;
    },
    clearKEMembershipPlansPrices: (state) => {
      state.membershipPlansPrices = initialState.membershipPlansPrices;
    },
  },
});

export const { setKEMembershipPlansPrices, clearKEMembershipPlansPrices } =
  kePricingSlice.actions;
export default kePricingSlice.reducer;
export const pricingReducerName = kePricingSlice.name;
