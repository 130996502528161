import { SpinnerMode } from '../../Spinner/spinner.models';
import { ButtonStyleType } from './Button.types';

export const getSpinnerMode = (
  buttonStyleType: ButtonStyleType,
): SpinnerMode => {
  switch (buttonStyleType) {
    case 'light-border':
    case 'light1':
      return 'dark';
    default:
      return 'light';
  }
};
