import { useTranslation } from 'react-i18next';

import { CanExportSubscriptionsRenewalsPermissions } from '../../../../config/accessPolicies/accessPolicies';

import { IRenewalsFiltersModel } from '../../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';
import RenewalFilters from '../../Common/RenewalsFilters/RenewalFilters';
import { useRenewalsSubscriptionsFilters } from './renewalsSubscriptionsFilters.hooks';
import {
  fieldsList,
  staticFilters,
  topFilters,
} from './renewalsSubscriptionsFilters.utils';

type RenewalsSubscriptionsFiltersProps = {
  onFilterChanged?: (filters: IRenewalsFiltersModel) => void;
  onExportClick?: (filters: IRenewalsFiltersModel) => void;
  isExporting?: boolean;
};

const RenewalsSubscriptionsFilters = ({
  onFilterChanged,
  onExportClick,
  isExporting,
}: RenewalsSubscriptionsFiltersProps) => {
  const { t } = useTranslation();

  const { handleFilterChanged, handleExportClick } =
    useRenewalsSubscriptionsFilters(onFilterChanged, onExportClick);

  return (
    <RenewalFilters
      filtersListLabel={t('phrase.show-all-subscriptions-which')}
      filtersDefinitions={fieldsList}
      staticFilters={staticFilters}
      topFilters={topFilters}
      onFilterChanged={handleFilterChanged}
      onExportClick={handleExportClick}
      isExporting={isExporting}
      exportPermissions={CanExportSubscriptionsRenewalsPermissions}
    />
  );
};

export default RenewalsSubscriptionsFilters;
