export interface AppConfig {
  paymentApi: {
    baseUrl: string;
    stripePublishableKey: string;
    scope: string;
  };
  airAmbulanceApi: {
    baseUrl: string;
    scope: string;
  };
  kidnapAndExtortionApi: {
    baseUrl: string;
    scope: string;
  };
  securityResponseApi: {
    baseUrl: string;
    scope: string;
  };
  dutyOfCareApi: {
    baseUrl: string;
    scope: string;
  };
  organizationSagaApi: {
    baseUrl: string;
  };
  membershipSagaApi: {
    baseUrl: string;
  };
  reportsApi: {
    baseUrl: string;
    scope: string;
  };
  applicationinsights: {
    connectionString: string;
    applicationName: string;
  };
  environment: string;
  googleApiKey: string;
  b2c: {
    authority: string;
    authorityDomain: string;
    clientId: string;
  };
  peopleManagementApi: {
    baseUrl: string;
    scope: string;
  };
  travelLicenseApi: {
    baseUrl: string;
    scope: string;
  };
  contact: {
    phone: string;
  };
}

const config: AppConfig = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  b2c: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
  },
  reportsApi: {
    baseUrl: process.env.REACT_APP_REPORTS_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/reports-office-api_u`,
  },
  peopleManagementApi: {
    baseUrl: process.env.REACT_APP_PEOPLE_MANAGEMENT_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/people-management-office-api_u`,
  },
  kidnapAndExtortionApi: {
    baseUrl: process.env.REACT_APP_KIDNAP_AND_EXTORTION_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/kidnap-and-ransom-office-api_u`,
  },
  securityResponseApi: {
    baseUrl: process.env.REACT_APP_SECURITY_RESPONSE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/security-response-office-api_u`,
  },
  dutyOfCareApi: {
    baseUrl: process.env.REACT_APP_DUTY_OF_CARE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/duty-of-care-office-api_u`,
  },
  airAmbulanceApi: {
    baseUrl: process.env.REACT_APP_AIR_AMBULANCE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/air-ambulance-office-api_u`,
  },
  paymentApi: {
    baseUrl: process.env.REACT_APP_PAYMENT_API_BASE_URL,
    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/paymentsbackend-public-api/payment-public-api_u`,
  },
  travelLicenseApi: {
    baseUrl: process.env.REACT_APP_TRAVEL_LICENSE_API_BASE_URL,
    scope: `${process.env.REACT_APP_B2C_SCOPE_DOMAIN}/membership-management-office-api/travel-license-office-api_u`,
  },
  organizationSagaApi: {
    baseUrl: process.env.REACT_APP_ORGANIZATION_SAGA_API_BASE_URL,
  },
  membershipSagaApi: {
    baseUrl: process.env.REACT_APP_MEMBERSHIP_SAGA_API_BASE_URL,
  },
  applicationinsights: {
    connectionString:
      process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
    applicationName: process.env.REACT_APP_APPLICATIONINSIGHTS_APPLICATION_NAME,
  },
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  contact: {
    phone: '703.566.9463',
  },
};

export default config;
