import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';

import { SmallModal } from '../../../../../styledComponents/ModalCommon/ModalCommon';
import { withErrorProvider } from '../../../../../hoc/withErrorProvider';
import ResendWelcomeEmailForm from '../../../../../styledComponents/Forms/ResendWelcomeEmailForm/ResendWelcomeEmailForm';
import { useResendDOCWelcomeEmailModalForm } from './resendDOCWelcomeEmailModal.hooks';
import { RESEND_DOC_WELCOME_EMAIL_MODAL_NAME } from './resendDOCWelcomeEmailModal.const';

type ResendDOCWelcomeEmailModalProps = {
  id?: string;
};

const ResendDOCWelcomeEmailModal = ({
  id,
}: ResendDOCWelcomeEmailModalProps) => {
  const { t } = useTranslation();
  const {
    submitHandler,
    onCancelHandler,
    welcomeEmailResending,
    initState,
    validateForm,
  } = useResendDOCWelcomeEmailModalForm({ id });
  return (
    <SmallModal name={RESEND_DOC_WELCOME_EMAIL_MODAL_NAME + id}>
      <Form
        keepDirtyOnReinitialize
        onSubmit={submitHandler}
        initialValues={initState}
        validate={validateForm}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            mode="small"
            handleSubmit={renderProps.handleSubmit}
            onCancel={onCancelHandler}
            title={t('headers.resend-welcome-email')}
            isProcessing={welcomeEmailResending}
          >
            <ResendWelcomeEmailForm disabled={welcomeEmailResending} />
          </ModalFormPage>
        )}
      </Form>
    </SmallModal>
  );
};

export default withErrorProvider(ResendDOCWelcomeEmailModal);
