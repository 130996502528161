import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';

import { ProductType } from '../../../models/ProductType';
import { useAAEditSubscriptionRenewPage } from './aaEditSubscriptionRenewPage.hooks';
import AAEditSubscriptionRenewPageForm from './AAEditSubscriptionRenewPageForm/AAEditSubscriptionRenewPageForm';
import AASubscriptionAnalyseModal from './AAEditSubscriptionAnalyseModal/AAEditSubscriptionAnalyseModal';

export const AASubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    dataLoading,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  } = useAAEditSubscriptionRenewPage();

  if (dataLoading) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <AASubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.edit-renew-subscription', {
              productType: ProductType.AirAmbulance,
              organizationName: organization?.name,
            })}
          />
          <AAEditSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
