import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import CorpPointOfContactForm from '../../../../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/CorpPointOfContactForm';

import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import ModalFormPage from '../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useEditContactModal } from './editContactModal.hooks';
import {
  EditContactModalReadOnlyFields,
  EDIT_CONTACT_MODAL_NAME,
} from './editContactModal.const';
import { withErrorProvider } from '../../../../../../hoc/withErrorProvider';
import FormErrorActivator from '../../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

const EditContactModal = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    initState,
    onCancelHandler,
    organizationUpdating,
    validateForm,
    rolesLoading,
    rolesList,
  } = useEditContactModal();

  return (
    <Modal
      disabled={organizationUpdating || rolesLoading}
      name={EDIT_CONTACT_MODAL_NAME}
    >
      <Form
        initialValues={initState}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ ...renderProps }) => (
          <FormErrorActivator>
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={organizationUpdating}
              onCancel={onCancelHandler}
              title={t('headers.editing-contact')}
            >
              <CorpPointOfContactForm
                disabled={organizationUpdating}
                readOnlyFieldsArray={EditContactModalReadOnlyFields}
                rolesList={rolesList}
              />
            </ModalFormPage>
          </FormErrorActivator>
        )}
      </Form>
    </Modal>
  );
};

export default withErrorProvider(EditContactModal);
