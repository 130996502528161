/* eslint-disable no-undefined */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { cloneDeep } from 'lodash';

import { IMembershipPlanPriceModel } from '../../../../../models/MembershipPlanPriceModel';
import {
  MembershipPlanFormActionableContext,
  MembershipPlanFormInfoContext,
} from '../MembershipPlanFormProvider';
import { IMembershipPlanPreviousPriceModel } from './useMembershipPlanPrices.models';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { DiscountType } from '../../../../../models/enums/DiscountType';
import { isPropertyEmpty } from '../../../../../utils/propertyHelpers';
import { PricingMode } from '../../../../../models/enums/PricingMode';
import {
  calculateDiscountPricing,
  findPlanPriceById,
  getMembershipPlanArrayIndex,
  getPlanCapacity,
  updatePricesByCollection,
} from './useMembershipPlanPrices.utils';

export function useMembershipPlanPrices(
  membershipPlansPrices: IMembershipPlanPriceModel[],
  previousMembershipPlansPrices: IMembershipPlanPreviousPriceModel[],
  defaultPricingMode: PricingMode = PricingMode.Regular,
  useDefaultPlansData?: boolean,
  defaultPlansData?: Record<string, { capacity: number; price: number }>,
  name = 'pricing',
  usePreviousMembershipPlansPrices?: boolean,
  onValueChanged?: (value: IOrganizationSubscriptionPricingModel) => void,
  createInitValues = true,
) {
  const {
    input: { onChange: onInputChange, value: inputValue },
  }: FieldRenderProps<IOrganizationSubscriptionPricingModel> = useField(name);

  const prepareDefaultValues = useCallback(
    (
      prices: (IMembershipPlanPriceModel & { capacity?: number })[],
    ): IOrganizationSubscriptionPricingModel => ({
      discountValue: defaultPricingMode === PricingMode.Discount ? 0 : null,
      discountType:
        defaultPricingMode === PricingMode.Discount
          ? DiscountType.Amount
          : null,
      pricingMode: defaultPricingMode,
      membershipPlans: prices.map((row) => ({
        price: row.price,
        membershipPlanId: row.membershipPlanId,
        capacity: row.capacity || 0,
      })),
    }),
    [defaultPricingMode],
  );

  const updateSelectedData = useCallback(
    ({
      membershipPlans,
      discountType,
      discountValue,
      pricingMode,
    }: IOrganizationSubscriptionPricingModel) => {
      const newValue = {
        discountType:
          discountType === undefined ? inputValue.discountType : discountType,
        discountValue:
          discountValue === undefined
            ? inputValue.discountValue
            : discountValue,
        pricingMode: isPropertyEmpty(pricingMode)
          ? inputValue.pricingMode
          : pricingMode,
        membershipPlans: isPropertyEmpty(membershipPlans)
          ? inputValue.membershipPlans
          : membershipPlans,
      };
      onInputChange(newValue);
      onValueChanged?.(newValue);
    },
    [
      inputValue.discountType,
      inputValue.discountValue,
      inputValue.membershipPlans,
      inputValue.pricingMode,
      onInputChange,
      onValueChanged,
    ],
  );

  const pricingModeChangeHandler = useCallback(
    (currentPricingMode: PricingMode) => {
      if (!inputValue.membershipPlans) {
        return;
      }
      let membershipPlans = [];
      if (currentPricingMode === PricingMode.KeepPreviousPrice) {
        membershipPlans = updatePricesByCollection(
          inputValue.membershipPlans,
          previousMembershipPlansPrices,
        );
      } else {
        membershipPlans = updatePricesByCollection(
          inputValue.membershipPlans,
          membershipPlansPrices,
        );
      }

      const discountType =
        currentPricingMode === PricingMode.Discount
          ? DiscountType.Amount
          : null;
      const discountValue =
        currentPricingMode === PricingMode.Discount ? 0 : null;

      updateSelectedData({
        membershipPlans,
        pricingMode: currentPricingMode,
        discountValue,
        discountType,
      });
    },
    [
      inputValue.membershipPlans,
      membershipPlansPrices,
      previousMembershipPlansPrices,
      updateSelectedData,
    ],
  );
  const canLoadInitData =
    createInitValues &&
    !!membershipPlansPrices &&
    membershipPlansPrices.length > 0 &&
    (!useDefaultPlansData || (useDefaultPlansData && !!defaultPlansData)) &&
    (!usePreviousMembershipPlansPrices ||
      (usePreviousMembershipPlansPrices && !!previousMembershipPlansPrices));

  useEffect(() => {
    if (inputValue || !canLoadInitData) {
      return;
    }

    let membershipPlans: (IMembershipPlanPriceModel & { capacity?: number })[] =
      membershipPlansPrices;

    if (useDefaultPlansData) {
      membershipPlans = membershipPlans.map((plan) => ({
        ...plan,
        ...defaultPlansData[plan.membershipPlanId],
      }));
    }

    if (defaultPricingMode === PricingMode.KeepPreviousPrice) {
      membershipPlans = membershipPlans.map((plan) => ({
        ...plan,
        price: findPlanPriceById(
          previousMembershipPlansPrices,
          plan.membershipPlanId,
        ),
      }));
    }

    updateSelectedData(prepareDefaultValues(membershipPlans));
  }, [
    canLoadInitData,
    defaultPlansData,
    defaultPricingMode,
    inputValue,
    membershipPlansPrices,
    prepareDefaultValues,
    previousMembershipPlansPrices,
    updateSelectedData,
    useDefaultPlansData,
  ]);

  const changeNumberOfSeats = useCallback(
    (value: number, row: IMembershipPlanPriceModel) => {
      const membershipPlans = cloneDeep(inputValue.membershipPlans);

      const currentSelectedPlanIndex = getMembershipPlanArrayIndex(
        inputValue.membershipPlans,
        row.membershipPlanId,
      );

      if (
        currentSelectedPlanIndex === -1 ||
        currentSelectedPlanIndex === null
      ) {
        membershipPlans.push({
          price: row.price,
          membershipPlanId: row.membershipPlanId,
          capacity: value,
        });
      } else {
        membershipPlans[currentSelectedPlanIndex].capacity = value;
      }

      updateSelectedData({ membershipPlans });
    },
    [inputValue.membershipPlans, updateSelectedData],
  );

  const changeMembershipPlanPrice = useCallback(
    (price: number, row: IMembershipPlanPriceModel) => {
      const indexToUpdate = getMembershipPlanArrayIndex(
        inputValue.membershipPlans,
        row.membershipPlanId,
      );

      if (indexToUpdate === -1) {
        return;
      }

      const membershipPlans = cloneDeep(inputValue.membershipPlans);
      membershipPlans[indexToUpdate].price = price || 0;

      updateSelectedData({ membershipPlans });
    },
    [inputValue, updateSelectedData],
  );

  const changeDiscountValue = useCallback(
    (discountValue: number) => {
      updateSelectedData({
        membershipPlans: calculateDiscountPricing(
          inputValue.membershipPlans,
          membershipPlansPrices,
          inputValue.discountType,
          discountValue,
        ),
        discountValue,
      });
    },
    [
      inputValue.discountType,
      inputValue.membershipPlans,
      membershipPlansPrices,
      updateSelectedData,
    ],
  );

  const changeDiscountType = useCallback(
    (discountType: DiscountType) => {
      updateSelectedData({
        membershipPlans: calculateDiscountPricing(
          inputValue.membershipPlans,
          membershipPlansPrices,
          inputValue.discountType,
          0,
        ),
        discountType,
        discountValue: 0,
      });
    },
    [
      updateSelectedData,
      inputValue.membershipPlans,
      inputValue.discountType,
      membershipPlansPrices,
    ],
  );

  const changePricingMode = useCallback(
    (value: PricingMode) => {
      pricingModeChangeHandler(value);
    },
    [pricingModeChangeHandler],
  );

  const mergedMembershipPlans = useMemo(() => {
    if (!inputValue || !membershipPlansPrices) {
      return null;
    }

    return membershipPlansPrices
      ?.map((plan) => ({
        ...plan,
        ...inputValue?.membershipPlans[
          getMembershipPlanArrayIndex(
            inputValue.membershipPlans,
            plan.membershipPlanId,
          )
        ],
      }))
      ?.filter((plan) => plan?.price !== 0);
  }, [inputValue, membershipPlansPrices]);

  const getSelectedPlanCapacity = useCallback(
    (id: string) => getPlanCapacity(inputValue.membershipPlans, id),
    [inputValue],
  );

  return {
    changeNumberOfSeats,
    changeMembershipPlanPrice,
    changeDiscountValue,
    changeDiscountType,
    changePricingMode,
    getSelectedPlanCapacity,
    state: {
      ...inputValue,
      membershipPlans: mergedMembershipPlans,
      supportPreviousPrices: usePreviousMembershipPlansPrices,
    },
    membershipPlansPrices,
  };
}

export function useMembershipPlanPricesActions() {
  const {
    changeDiscountType,
    changeDiscountValue,
    changeMembershipPlanPrice,
    changeNumberOfSeats,
    changePricingMode,
    getSelectedPlanCapacity,
  } = useContext(MembershipPlanFormActionableContext);

  return {
    changeDiscountType,
    changeDiscountValue,
    changeMembershipPlanPrice,
    changeNumberOfSeats,
    changePricingMode,
    getSelectedPlanCapacity,
  };
}

export function useMembershipPlanPricesValues() {
  const { state } = useContext(MembershipPlanFormInfoContext);

  return { state };
}
