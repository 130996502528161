import { useState } from 'react';
import { useField } from 'react-final-form';
import { geocodeByAddress } from 'react-google-places-autocomplete';

import { Dictionary } from '../../../../models/Dictionary';
import { prepareAddressObject } from '../../../../utils/googlePlacesAutocompleteHelpers';

export const useFormGooglePlacesAutocomplete = (fields: Dictionary<string>) => {
  const [isLoading, setIsLoading] = useState(false);

  const city = useField(fields.city);
  const country = useField(fields.country);
  const stateRegion = useField(fields.stateRegion);
  const zipCode = useField(fields.zipCode);
  const addressLine1 = useField(fields.addressLine1);
  const addressLine2 = useField(fields.addressLine2);
  const longitude = useField(fields.longitude);
  const latitude = useField(fields.latitude);

  const onChange = (value: any) => {
    setIsLoading(true);

    geocodeByAddress(value.label).then((result) => {
      setIsLoading(false);
      if (!result || result.length === 0) {
        return;
      }

      const address = prepareAddressObject(result[0]);

      addressLine2.input.onChange('');
      country.input.onChange(address.country);
      city.input.onChange(address.city);
      stateRegion.input.onChange(address.stateRegion);
      zipCode.input.onChange(address.zipCode);
      addressLine1.input.onChange(address.addressLine1);
      longitude.input.onChange(address.longitude);
      latitude.input.onChange(address.latitude);
    });
  };

  return { onChange, isLoading };
};
