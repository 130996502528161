import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';

export const ProductSubscriptionBackgroundColors: Record<
  ProductTypeShortcut,
  string
> = {
  [ProductTypeShortcut.AirAmbulance]: '#D8E0E3',
  [ProductTypeShortcut.KidnapAndExtortion]: '#D7E8D7',
  [ProductTypeShortcut.DutyOfCare]: '#F6DAC5',
  [ProductTypeShortcut.SecurityResponse]: '#DCD8E3',
  [ProductTypeShortcut.TravelLicense]: '#CFE5E3',
};
export const ProductSubscriptionSubColors: Record<ProductTypeShortcut, string> =
  {
    [ProductTypeShortcut.AirAmbulance]: '#829AAB',
    [ProductTypeShortcut.KidnapAndExtortion]: '#678B67',
    [ProductTypeShortcut.DutyOfCare]: '#B98E72',
    [ProductTypeShortcut.SecurityResponse]: '#9081AB',
    [ProductTypeShortcut.TravelLicense]: '#74A59F',
  };

export const ProductSubscription = {
  circle: '#F28E19',
};
