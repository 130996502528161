import { CustomComponent } from '../../../models/types/CustomComponent';
import { OrganizationType } from '../../../models/enums/OrganizationType';
import { getOrganizationTypeIcon } from './organizationTypeIcon.utils';

type OrganizationTypeIconProps = CustomComponent & {
  organizationType: OrganizationType;
};

const OrganizationTypeIcon = ({
  organizationType,
}: OrganizationTypeIconProps) => (
  <>{getOrganizationTypeIcon(organizationType)}</>
);

export default OrganizationTypeIcon;
