import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISubscriptionSummaryModel } from 'models/interfaces/Subscription/SubscriptionSummaryModel';

import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';

import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';

export interface ISubscriptionsState {
  subscriptionSummary: ISubscriptionSummaryModel;
  subscriptionsSummary: ISubscriptionSummaryModel[];
  subscriptions: IOrganizationSubscription[];
  newAddedSubscriptions: IOrganizationSubscription[];
  deletedSubscriptionsIds: string[];
  subscriptionExtensionSummary: IOrganizationSubscriptionExtensionSummaryModel;
}

const initialState: ISubscriptionsState = {
  subscriptions: [],
  subscriptionSummary: null,
  subscriptionsSummary: null,
  newAddedSubscriptions: [],
  deletedSubscriptionsIds: [],
  subscriptionExtensionSummary: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions: (
      state,
      { payload }: PayloadAction<IOrganizationSubscription[]>,
    ) => {
      state.subscriptions = payload;
    },
    addNewAddedSubscription: (
      state,
      { payload }: PayloadAction<IOrganizationSubscription>,
    ) => {
      state.newAddedSubscriptions.push(payload);
    },
    addNewAddedSubscriptions: (
      state,
      { payload }: PayloadAction<IOrganizationSubscription[]>,
    ) => {
      state.newAddedSubscriptions = [
        ...state.newAddedSubscriptions,
        ...payload,
      ];
    },
    setDeletedSubscriptionId: (state, { payload }: PayloadAction<string[]>) => {
      state.deletedSubscriptionsIds = [
        ...state.deletedSubscriptionsIds,
        ...payload,
      ];
    },
    removeFromNewAddedSubscription: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.newAddedSubscriptions = state.newAddedSubscriptions.filter(
        (subscription) => !payload.includes(subscription.id),
      );
    },
    removeFromDeletedSubscriptionId: (
      state,
      { payload }: PayloadAction<string[]>,
    ) => {
      state.deletedSubscriptionsIds = state.deletedSubscriptionsIds.filter(
        (id) => !payload.includes(id),
      );
    },
    addSubscription: (
      state,
      { payload }: PayloadAction<IOrganizationSubscription>,
    ) => {
      state.subscriptions.push(payload);
    },
    deleteSubscription: (state, { payload }: PayloadAction<string>) => {
      state.subscriptions = state.subscriptions.filter((x) => x.id !== payload);
    },
    updateSubscription: (
      state,
      { payload }: PayloadAction<IOrganizationSubscription>,
    ) => {
      const subscriptionIndex = state.subscriptions.findIndex(
        (subscription) => subscription.id === payload.id,
      );

      if (subscriptionIndex !== -1) {
        state.subscriptions[subscriptionIndex] = payload;
      }
    },
    clearSubscriptionsList: (state) => {
      state.subscriptions = initialState.subscriptions;
    },
    setSubscriptionSummary: (
      state,
      { payload }: PayloadAction<ISubscriptionSummaryModel>,
    ) => {
      state.subscriptionSummary = payload;
    },
    setSubscriptionsSummary: (
      state,
      { payload }: PayloadAction<ISubscriptionSummaryModel[]>,
    ) => {
      state.subscriptionsSummary = payload;
    },
    setSubscriptionExtensionSummary: (
      state,
      {
        payload,
      }: PayloadAction<IOrganizationSubscriptionExtensionSummaryModel>,
    ) => {
      state.subscriptionExtensionSummary = payload;
    },
    clearSubscriptionExtensionHelperData: (state) => {
      state.subscriptionExtensionSummary = null;
    },
    clearSubscriptionSummary: (state) => {
      state.subscriptionSummary = null;
    },
    clearSubscriptionsSummary: (state) => {
      state.subscriptionsSummary = null;
    },
    clearSubscriptionHelperData: (state) => {
      state.subscriptionSummary = null;
      state.subscriptionsSummary = null;
    },
    clearNewAddedSubscription: (state) => {
      state.newAddedSubscriptions = [];
    },
    clearDeletedSubscriptionId: (state) => {
      state.deletedSubscriptionsIds = [];
    },
    clearSubscriptionExtensionSummary: (state) => {
      state.subscriptionExtensionSummary =
        initialState.subscriptionExtensionSummary;
    },
  },
});

export const {
  addSubscription,
  clearSubscriptionHelperData,
  clearSubscriptionSummary,
  setSubscriptionSummary,
  setSubscriptions,
  updateSubscription,
  clearSubscriptionsList,
  deleteSubscription,
  clearNewAddedSubscription,
  addNewAddedSubscription,
  removeFromDeletedSubscriptionId,
  removeFromNewAddedSubscription,
  clearDeletedSubscriptionId,
  setDeletedSubscriptionId,
  addNewAddedSubscriptions,
  clearSubscriptionsSummary,
  setSubscriptionsSummary,
  setSubscriptionExtensionSummary,
  clearSubscriptionExtensionSummary,
  clearSubscriptionExtensionHelperData,
} = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
export const subscriptionsReducerName = subscriptionsSlice.name;
