import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { SelectOption } from '../../MainSelect/mainSelect.types';

type UseFormSelect = {
  onChange: (event: any) => void;
  value?: any;
  promiseOptions?: () => Promise<any>;
  options: any;
  setDefaultValue?: boolean;
  labelAnimation?: boolean;
  placeholder?: ReactNode;
  name?: string;
  onItemChanged?: (value: string[] | string) => void;
  isMulti: boolean;
};

export const useFormSelect = ({
  onChange,
  promiseOptions,
  options,
  setDefaultValue,
  labelAnimation,
  placeholder,
  name,
  onItemChanged,
  isMulti,
}: UseFormSelect) => {
  const { t } = useTranslation();

  const onChangeHandler = useCallback(
    (option: SelectOption) => {
      if (Array.isArray(option)) {
        onChange?.(!option?.length ? null : option?.map((x) => x.value));
        onItemChanged?.(!option?.length ? null : option?.map((x) => x.value));
        return;
      }
      onChange(option?.value);
      onItemChanged?.(option?.value);
    },
    [onChange, onItemChanged],
  );

  useEffect(() => {
    if (!promiseOptions && !options) {
      onChange(null);
      onItemChanged?.(null);
    }
  }, [options, onChange, promiseOptions, onItemChanged]);

  useEffect(() => {
    if (
      !setDefaultValue ||
      !options ||
      options.length !== 1 ||
      !options[0].value
    ) {
      return;
    }

    setTimeout(() => onChangeHandler(isMulti ? options : options[0]), 0);
  }, [isMulti, onChange, options, setDefaultValue, onChangeHandler]);

  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ''
        : placeholder || t(`fields.${getPropertyName(name)}.placeholder`),
    [labelAnimation, name, placeholder, t],
  );

  return { preparedPlaceholder, onChangeHandler };
};
