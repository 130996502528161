import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove-icon.svg';
import {
  promiseOptions,
  shouldFieldBeDisabled,
} from './corpPointOfContactForm.utils';
import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../../utils/getFormGroupPath';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../../utils/phoneNumberHelpers';
import FormSwitch from '../../../UI/Form/FormSwitch/FormSwitch';
import {
  CorpPointOfContactFieldsModel,
  ContactFormName,
} from './corpPointOfContact.models';
import { FormNumberInput } from '../../../UI/Form/FormNumberInput/FormNumberInput';
import {
  FormDeleteButton,
  FormEmptySpace,
  FormNumber,
  FormWrapper,
} from '../../../Form/Form';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../../../UI/Form/FormInput/FormInput';
import { CustomForm } from '../../../../models/types/CustomForm';
import FormSearchPerson from '../../../UI/Form/FormSearchPerson/FormSearchPerson';
import { PersonFields } from '../../../../hooks/usePersonFields';
import { FormEmptyField } from '../../../UI/Form/FormEmptyField/FormEmptyField';
import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import { MultiInputType } from '../../../../models/enums/MultiInputType';
import { SelectOption } from '../../../UI/MainSelect/mainSelect.types';
interface ICorpPointOfContactFormProps extends CustomForm {
  contactNumber?: number;
  readOnlyFieldsArray?: string[];
  onRemoveContact?: () => void;
  rolesList?: SelectOption[];
}

const CorpPointOfContactForm = ({
  contactNumber,
  disabled,
  name,
  className,
  onRemoveContact,
  readOnlyFieldsArray = [],
  rolesList,
}: ICorpPointOfContactFormProps) => {
  const { t } = useTranslation();
  const {
    input: { value: personId },
  } = useField(getFormGroupPath(name, PersonFields.PersonId));

  return (
    <FormWrapper className={className}>
      {onRemoveContact && (
        <FormDeleteButton
          disabled={disabled}
          onClick={onRemoveContact}
          styleType="icon"
        >
          <RemoveIcon />
        </FormDeleteButton>
      )}
      <FormRow>
        <FormColumn>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormNumber>{`${contactNumber}.`}</FormNumber>}
              <Field
                component={FormSearchPerson}
                promiseOptions={promiseOptions}
                disabled={
                  disabled ||
                  shouldFieldBeDisabled(
                    readOnlyFieldsArray,
                    CorpPointOfContactFieldsModel.emailAddress,
                  )
                }
                group={name}
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.emailAddress,
                  groupName: name,
                })}
              />
            </FormRow>
            <Field
              component={FormInput}
              disabled={
                disabled ||
                shouldFieldBeDisabled(
                  readOnlyFieldsArray,
                  CorpPointOfContactFieldsModel.jobTitle,
                )
              }
              {...getFieldPropertiesForGroup({
                inputId: ContactFormName,
                name: CorpPointOfContactFieldsModel.jobTitle,
                groupName: name,
              })}
            />
          </FormRow>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormEmptySpace />}
              <Field
                component={FormInput}
                disabled={
                  disabled ||
                  personId ||
                  shouldFieldBeDisabled(
                    readOnlyFieldsArray,
                    CorpPointOfContactFieldsModel.firstName,
                  )
                }
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.firstName,
                  groupName: name,
                })}
              />
            </FormRow>
            <Field
              component={FormInput}
              disabled={
                disabled ||
                personId ||
                shouldFieldBeDisabled(
                  readOnlyFieldsArray,
                  CorpPointOfContactFieldsModel.lastName,
                )
              }
              {...getFieldPropertiesForGroup({
                inputId: ContactFormName,
                name: CorpPointOfContactFieldsModel.lastName,
                groupName: name,
              })}
            />
          </FormRow>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormEmptySpace />}
              <Field
                component={FormNumberInput}
                disabled={
                  disabled ||
                  shouldFieldBeDisabled(
                    readOnlyFieldsArray,
                    CorpPointOfContactFieldsModel.mobilePhone,
                  )
                }
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.mobilePhone,
                  groupName: name,
                })}
                parseValue={parsePhoneNumber}
                formatValue={formatPhoneNumber}
                maxLength={PHONE_NUMBER_MAX_LENGTH}
              />
            </FormRow>
            <Field
              component={FormNumberInput}
              disabled={
                disabled ||
                shouldFieldBeDisabled(
                  readOnlyFieldsArray,
                  CorpPointOfContactFieldsModel.workPhone,
                )
              }
              {...getFieldPropertiesForGroup({
                inputId: ContactFormName,
                name: CorpPointOfContactFieldsModel.workPhone,
                groupName: name,
              })}
              parseValue={parsePhoneNumber}
              formatValue={formatPhoneNumber}
              maxLength={PHONE_NUMBER_MAX_LENGTH}
            />
          </FormRow>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormEmptySpace />}
              <Field
                isMulti
                multiInputType={MultiInputType.InputSelect}
                component={FormSelect}
                options={rolesList}
                disabled={
                  disabled ||
                  shouldFieldBeDisabled(
                    readOnlyFieldsArray,
                    CorpPointOfContactFieldsModel.roles,
                  )
                }
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.roles,
                  groupName: name,
                })}
                label={t('fields.pocRoles.label')}
              />
            </FormRow>
            <FormRow>
              <Field
                component={FormSwitch}
                disabled={disabled}
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.isOrganizationAdministrator,
                  groupName: name,
                })}
                type="checkbox"
              />
            </FormRow>
          </FormRow>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormEmptySpace />}
              <Field
                component={FormInput}
                disabled={
                  disabled ||
                  shouldFieldBeDisabled(
                    readOnlyFieldsArray,
                    CorpPointOfContactFieldsModel.internalNotes,
                  )
                }
                {...getFieldPropertiesForGroup({
                  inputId: ContactFormName,
                  name: CorpPointOfContactFieldsModel.internalNotes,
                  groupName: name,
                })}
                label={t('fields.notesInternal.label')}
              />
            </FormRow>
            <FormEmptyField />
          </FormRow>
          <FormEmptyField />
        </FormColumn>
      </FormRow>
    </FormWrapper>
  );
};

export default CorpPointOfContactForm;
