import { useCallback } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { ISearchPerson } from '../models/SearchPerson';
import { parseDate } from '../utils/dateHelpers';
import { getFormGroupPath } from '../utils/getFormGroupPath';
import { isPropertyEmpty } from '../utils/propertyHelpers';

export enum PersonFields {
  PersonId = 'personId',
  GlobalPersonId = 'globalPersonId',
  EmailAddress = 'emailAddress',
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phoneNumber',
  Gender = 'gender',
  Prefix = 'prefix',
  SearchedPerson = 'searchedPerson',
  ResidenceAddress = 'residenceAddress',
  DateOfBirth = 'dateOfBirth',
  CorrespondenceAddressSameAsResidenceAddress = 'correspondenceAddressSameAsResidenceAddress',
  CorrespondenceAddress = 'correspondenceAddress',
}

export type Fields = keyof typeof PersonFields;

type UsePersonFieldsProps = {
  group?: string;
  fields: Partial<Record<Fields, string>>;
};

export const usePersonFields = ({
  fields = {},
  group,
}: UsePersonFieldsProps) => {
  const personId = useField(
    getFormGroupPath(group, fields.PersonId || PersonFields.PersonId),
  );
  const globalPersonId = useField(
    getFormGroupPath(group, fields.PersonId || PersonFields.GlobalPersonId),
  );
  const emailAddress = useField(
    getFormGroupPath(group, fields.EmailAddress || PersonFields.EmailAddress),
  );
  const firstName = useField(
    getFormGroupPath(group, fields.FirstName || PersonFields.FirstName),
  );
  const lastName = useField(
    getFormGroupPath(group, fields.LastName || PersonFields.LastName),
  );
  const phone = useField(
    getFormGroupPath(group, fields.Phone || PersonFields.Phone),
  );
  const gender = useField(
    getFormGroupPath(group, fields.Gender || PersonFields.Gender),
  );
  const prefix = useField(
    getFormGroupPath(group, fields.Prefix || PersonFields.Prefix),
  );
  const searchedPerson = useField(
    getFormGroupPath(
      group,
      fields.SearchedPerson || PersonFields.SearchedPerson,
    ),
  );
  const correspondenceAddressSameAsResidenceAddress = useField(
    getFormGroupPath(
      group,
      fields.CorrespondenceAddressSameAsResidenceAddress ||
        PersonFields.CorrespondenceAddressSameAsResidenceAddress,
    ),
  );
  const residenceAddress = useField(
    getFormGroupPath(
      group,
      fields.ResidenceAddress || PersonFields.ResidenceAddress,
    ),
  );
  const correspondenceAddress = useField(
    getFormGroupPath(
      group,
      fields.CorrespondenceAddress || PersonFields.CorrespondenceAddress,
    ),
  );
  const dateOfBirth = useField(
    getFormGroupPath(group, fields.DateOfBirth || PersonFields.DateOfBirth),
  );
  const clearField = useCallback(
    (fieldValue: FieldRenderProps<any, HTMLElement, any>, value: any = null) =>
      !isPropertyEmpty(fieldValue.input.value) &&
      fieldValue.input.onChange(value),
    [],
  );

  const clearFields = useCallback(() => {
    clearField(personId);
    clearField(globalPersonId);
    clearField(firstName);
    clearField(lastName);
    clearField(phone);
    clearField(gender);
    clearField(prefix);
    clearField(searchedPerson);
    clearField(residenceAddress);
    clearField(dateOfBirth);
    clearField(residenceAddress);
    clearField(correspondenceAddress);
    clearField(correspondenceAddressSameAsResidenceAddress, true);
  }, [
    clearField,
    personId,
    globalPersonId,
    firstName,
    lastName,
    phone,
    gender,
    prefix,
    searchedPerson,
    residenceAddress,
    dateOfBirth,
    correspondenceAddress,
    correspondenceAddressSameAsResidenceAddress,
  ]);

  const changeField = useCallback(
    (fieldValue: FieldRenderProps<any, HTMLElement, any>, value: any) =>
      !isPropertyEmpty(value) && fieldValue.input.onChange(value),
    [],
  );
  const changeFields = useCallback(
    (value: ISearchPerson) => {
      if (value === null) {
        clearFields();
        return;
      }
      changeField(personId, value.personId);
      changeField(globalPersonId, value.globalPersonId);
      changeField(emailAddress, value.contactEmailAddress);
      changeField(firstName, value.firstName);
      changeField(lastName, value.lastName);
      changeField(phone, value.phone);
      changeField(gender, value.gender);
      changeField(prefix, value.prefix);
      changeField(
        dateOfBirth,
        !isPropertyEmpty(value.dateOfBirth)
          ? parseDate(value.dateOfBirth)
          : null,
      );
      changeField(phone, value.dateOfBirth);
      changeField(residenceAddress, value.residenceAddress);
      changeField(correspondenceAddress, value.correspondenceAddress);
      changeField(searchedPerson, value);
      changeField(
        correspondenceAddressSameAsResidenceAddress,
        value.correspondenceAddressSameAsResidenceAddress,
      );
    },
    [
      changeField,
      personId,
      globalPersonId,
      emailAddress,
      firstName,
      lastName,
      phone,
      gender,
      prefix,
      dateOfBirth,
      residenceAddress,
      correspondenceAddress,
      searchedPerson,
      correspondenceAddressSameAsResidenceAddress,
      clearFields,
    ],
  );

  return { changeFields, clearFields };
};
