import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../../utils/validations/validateFormData';
import { DOCMembershipOrganizationDetailsFormFields } from './DOCMembershipDetailsForm/docMembershipDetailsForm.models';
import { ICreateDOCMembershipPageModel } from '../../../../../../models/interfaces/Membership/DutyOfCare/CreateDOCMembershipPageModel';

export const docMembershipDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [DOCMembershipOrganizationDetailsFormFields.EffectiveDate]: {
      errorMessage: t('validation.effective-date-later-expiration-date'),
      type: 'string',
      format: 'date',
      formatMaximum: {
        $data: `1/${DOCMembershipOrganizationDetailsFormFields.ExpirationDate}`,
      },
    },
  },
  required: [
    DOCMembershipOrganizationDetailsFormFields.EffectiveDate,
    DOCMembershipOrganizationDetailsFormFields.Organization,
    DOCMembershipOrganizationDetailsFormFields.SubscriptionType,
  ],
};

export const validateDOCMembershipDetailsForm = (
  data: ICreateDOCMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, docMembershipDetailsSchema);
