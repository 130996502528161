import { useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { IAddress } from '../../../models/Address';
import { compareAddresses } from '../../../utils/addressHelpers';
import { getFormGroupPath } from '../../../utils/getFormGroupPath';
import { isEmpty } from '../../../utils/textHelpers';
import { FamilyMemberFields } from './familyMember.models';
import { FamilyMemberBasicDetailsFields } from './FamilyMemberBasicDetails/familyMemberBasicDetails.models';

type UseFamilyMemberType = {
  name: string;
  residenceAddress?: IAddress;
};

export const useFamilyMember = ({
  name,
  residenceAddress,
}: UseFamilyMemberType) => {
  const {
    input: { value: addressSameAsPrimaryMemberResidenceAddress },
  }: FieldRenderProps<boolean> = useField(
    getFormGroupPath(
      name,
      FamilyMemberFields.AddressSameAsPrimaryMemberResidenceAddress,
    ),
  );

  const {
    input: { value: personId },
  } = useField(getFormGroupPath(name, FamilyMemberBasicDetailsFields.PersonId));

  const {
    input: { value: residenceAddressValue },
  }: FieldRenderProps<IAddress> = useField(
    getFormGroupPath(name, FamilyMemberFields.ResidenceAddress),
  );

  const addressIsSame = useMemo(() => {
    if (!residenceAddress) {
      return null;
    }
    if (!residenceAddressValue) {
      return false;
    }
    return compareAddresses(residenceAddress, residenceAddressValue);
  }, [residenceAddress, residenceAddressValue]);

  const showResidenceAddress =
    !addressSameAsPrimaryMemberResidenceAddress ||
    (personId && !isEmpty(residenceAddressValue as any));

  return {
    personId,
    addressIsSame,
    showResidenceAddress,
  };
};
