import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../hooks/useProcessing';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import {
  ClientOverviewGeneralPageParams,
  ContractOverviewPageParams,
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { IRenewalsFiltersModel } from '../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';
import { IRenewalSubscriptionRecord } from '../../../models/interfaces/RenewalFilters/RenewalSubscriptionsRecord';
import { ProductShortcutToProductType } from '../../../models/records/ProductShortcutToProductType';
import { updateModalState } from '../../../store/redux-slices/modals';
import {
  RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING,
  RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { renewalSubscriptionsSelector } from '../../../store/redux-slices/renewalFilter/selectors';
import {
  exportRenewalSubscriptions,
  loadRenewalSubscriptions,
} from '../../../store/saga-slices/renewalFilter';
import {
  IExportRenewalSubscriptionsPayload,
  ILoadRenewalSubscriptionsPayload,
} from '../../../store/saga-slices/renewalFilter/models';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from '../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.const';
import { EditRenewalSubscriptionStatusModalDetails } from '../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.models';
import { useTablesSort } from '../Common/RenewalsTables/renewalsTables.hooks';
import { initFilter } from './renewalsSubscriptionsPage.const';

export const useRenewalsSubscriptionsPage = () => {
  const navigate = useNavigateSearch();

  const LoadSubscriptions = useActions(loadRenewalSubscriptions);
  const ExportRenewalSubscriptions = useActions(exportRenewalSubscriptions);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const dataLoading = useProcessing(RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING);
  const dataExporting = useProcessing(RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING);

  const data = useSelector(renewalSubscriptionsSelector);
  const [currentFilters, setCurrentFilters] =
    useState<IRenewalsFiltersModel>(initFilter);

  const { order, orderBy } = useTablesSort();

  const loadSubscriptionsHandler = useCallback(() => {
    const filters = currentFilters;
    if (order && orderBy) {
      filters.order = order;
      filters.orderBy = orderBy;
    }
    const payload: ILoadRenewalSubscriptionsPayload = {
      cancellationToken,
      filters,
    };

    LoadSubscriptions(payload);
  }, [LoadSubscriptions, cancellationToken, currentFilters, order, orderBy]);

  useEffect(() => {
    loadSubscriptionsHandler();
  }, [loadSubscriptionsHandler]);

  const filterChangedHandler = useCallback((filters?: IRenewalsFiltersModel) => {
    setCurrentFilters(filters);
  }, []);

  const onEditRenewalStatusHandler = (row: IRenewalSubscriptionRecord) => {
    const details: EditRenewalSubscriptionStatusModalDetails = {
      subscriptionId: row.subscriptionId,
      organizationId: row.organizationId,
      renewalStatus: row.renewalStatus,
      contractName: row.contractName,
      organizationName: row.organizationName,
      expirationDate: row.expirationDate,
      productType: ProductShortcutToProductType[row.productAbbreviation],
    };
    UpdateModalState([EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME, details]);
  };

  const onCellClickHandler = (
    row: IRenewalSubscriptionRecord,
    key: keyof IRenewalSubscriptionRecord,
  ) => {
    switch (key) {
      case 'renewalStatus': {
        onEditRenewalStatusHandler(row);
        return;
      }
      case 'organizationName': {
        navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
          params: {
            [ClientOverviewGeneralPageParams.OrganizationId]:
              row?.organizationId,
          },
        });
        return;
      }
      case 'contractName': {
        if (!row?.contractId) {
          return;
        }
        navigate(RoutePath.ClientOverviewContractOverviewPageFullPath, {
          params: {
            [ContractOverviewPageParams.OrganizationId]: row?.organizationId,
            [ContractOverviewPageParams.ContractId]: row?.contractId,
          },
        });
      }
    }
  };

  const onRowClickHandler = (row: IRenewalSubscriptionRecord) => {
    navigate(RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath, {
      params: {
        [ProductSubscriptionOverviewPageParams.OrganizationId]:
          row?.organizationId,
        [ProductSubscriptionOverviewPageParams.SubscriptionId]:
          row?.subscriptionId,
        [ProductSubscriptionOverviewPageParams.SubscriptionType]:
          ProductShortcutToProductType[row?.productAbbreviation],
      },
    });
  };

  const exportRenewalSubscriptionsHandler = useCallback(() => {
    const filters = currentFilters;
    if (order && orderBy) {
      filters.order = order;
      filters.orderBy = orderBy;
    }
    const payload: IExportRenewalSubscriptionsPayload = {
      cancellationToken,
      filters,
    };

    ExportRenewalSubscriptions(payload);
  }, [
    ExportRenewalSubscriptions,
    cancellationToken,
    currentFilters,
    order,
    orderBy,
  ]);

  return {
    loadSubscriptionsHandler,
    dataLoading,
    data,
    filterChangedHandler,
    onEditRenewalStatusHandler,
    onCellClickHandler,
    onRowClickHandler,
    exportRenewalSubscriptionsHandler,
    dataExporting,
  };
};
