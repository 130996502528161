import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useApiResponseFail } from '../../../../hooks/useApiResponseFail';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { IValidationErrorResponse } from '../../../../models/ValidationErrorResponse';
import { IWarningRatification } from '../../../../models/interfaces/WarningRatification';
import { organizationContractsSelector } from '../../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../../store/redux-slices/modals';
import {
  organizationNameSelector,
  organizationSelector,
} from '../../../../store/redux-slices/organizations/selectors';
import {
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { ProductSubscriptionRenewPageParamsType } from '../../../../models/types/RoutePath';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { AddContractModalFormDetails } from '../../../../styledComponents/Modals/AddContractModal/AddContractModalForm/addContractModalForm.models';
import { ADD_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/AddContractModal/addContractModal.const';
import { useFormSubmitMessage } from '../../../../hooks/useFormSubmitMessage';
import { updateProductTLSubscription } from '../../../../store/saga-slices/subscriptions/travelLicense';
import { editingTLSubscriptionDataSelector } from '../../../../store/redux-slices/subscriptions/travelLicense/selectors';
import { IUpdateTLSubscriptionPayload } from '../../../../store/saga-slices/subscriptions/travelLicense/models';
import { validateTLEditSubscriptionForm } from './tlSubscriptionRenewPageForm.validation';
import { useFormErrorsActions } from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';

export const useTLEditSubscriptionRenewForm = () => {
  const navigate = useNavigateSearch();

  const { organizationId, subscriptionId, productType } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const alert = useAlert();

  const {
    prepareSubscriptionSubmitFailMessage,
    prepareSubscriptionSubmitSuccessMessage,
  } = useFormSubmitMessage();

  const UpdateModalState = useActions(updateModalState);

  const UpdateProductSubscription = useActions(updateProductTLSubscription);

  const { validateErrors } = useFormErrorsActions();

  const subscriptionData = useSelector(editingTLSubscriptionDataSelector);

  const isSubscriptionUpdating = useProcessing(SUBSCRIPTION_EDITING_PROCESSING);

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const organizationName = useSelector(organizationNameSelector);
  const { handleResponse } = useApiResponseFail();

  const organization = useSelector(organizationSelector);
  const organizationContracts = useSelector(organizationContractsSelector);

  const handleSubmit = (values: IEditTLOrganizationSubscriptionModel) => {
    updateOrganizationSubscription(values);
  };

  const updateOrganizationSubscription = useCallback(
    (
      values: IEditTLOrganizationSubscriptionModel,
      ratifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: IEditTLOrganizationSubscriptionModel = {
        ...values,
        ratifications,
      };
      const createNewSubscriptionPayload: IUpdateTLSubscriptionPayload = {
        organizationId,
        subscriptionId,
        data: extendedValues,
        error: (err: IValidationErrorResponse) => {
          handleResponse({
            err,
            values,
            ratifications,
            alertErrorMessage: prepareSubscriptionSubmitFailMessage(
              organizationName,
              values?.isDraft,
            ),
          });
        },
        success: () => {
          navigate(
            RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
            {
              params: {
                [ProductSubscriptionOverviewPageParams.OrganizationId]:
                  organizationId,
                [ProductSubscriptionOverviewPageParams.SubscriptionId]:
                  subscriptionId,
                [ProductSubscriptionOverviewPageParams.SubscriptionType]:
                  productType,
              },
            },
          );
          alert.success(
            prepareSubscriptionSubmitSuccessMessage(
              organizationName,
              values?.isDraft,
            ),
          );
        },
      };
      UpdateProductSubscription(createNewSubscriptionPayload);
    },
    [
      UpdateProductSubscription,
      alert,
      handleResponse,
      navigate,
      organizationId,
      organizationName,
      prepareSubscriptionSubmitFailMessage,
      prepareSubscriptionSubmitSuccessMessage,
      productType,
      subscriptionId,
    ],
  );

  const onAddNewContractHandler = () => {
    const details: AddContractModalFormDetails = {
      organizationId,
    };
    UpdateModalState([ADD_CONTRACT_MODAL_NAME, details]);
  };

  const validateForm = (data: IEditTLOrganizationSubscriptionModel) =>
    validateTLEditSubscriptionForm(data, validateErrors(data));

  return {
    handleSubmit,
    organization,
    canChangeContract: subscriptionData?.metadata?.canChangeContract,
    isSubscriptionUpdating,
    organizationContracts,
    isSuggestionDataLoading,
    initState: subscriptionData,
    UpdateModalState,
    updateOrganizationSubscription,
    productType,
    onAddNewContractHandler,
    validateForm,
  };
};
