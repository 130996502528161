import { CustomForm } from '../../../models/types/CustomForm';

export enum FormAddressInformationsFields {
  CorrespondenceAddressSameAsResidenceAddress = 'correspondenceAddressSameAsResidenceAddress',
  ResidenceAddress = 'residenceAddress',
  CorrespondenceAddress = 'correspondenceAddress',
}

export type FormAddressInformationsFieldsType =
  keyof typeof FormAddressInformationsFields;

export interface IFormAddressInformationProps extends CustomForm {
  isSubRowSpace?: boolean;
  title?: string;
  fields?: Record<FormAddressInformationsFieldsType, string>;
}

export const formAddressInformationsInitState = (
  fields: Record<
    FormAddressInformationsFieldsType,
    string
  > = FormAddressInformationsFields,
) => ({
  [fields.CorrespondenceAddressSameAsResidenceAddress]: true,
  [fields.ResidenceAddress]: {},
  [fields.CorrespondenceAddress]: {},
});
