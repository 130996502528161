import axios, { AxiosError } from 'axios';

import { PCA } from '../common/auth/initAuth';
import { loginRequest, scopesDictionary } from '../config/authConfig';

import config from '../config/config';
import { Log } from '../utils/logger';
import { handlerApiError } from '../store/saga-slices/error';
import { store } from '../index';

// import { handlerApiError } from '../store/saga-slices/error';

(function () {
  axios.defaults.baseURL = config.peopleManagementApi.baseUrl;

  axios.interceptors.request.use(async (axiosConfig) => {
    const account = PCA.getActiveAccount();
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await PCA.acquireTokenSilent({
      ...loginRequest,
      scopes: [
        ...loginRequest.scopes,
        ...scopesDictionary[axiosConfig.baseURL],
      ],
      account,
    }).catch((error) => {
      Log.errorException(error);
      PCA.logoutRedirect();
    });

    if (response) {
      const bearer = `Bearer ${response.accessToken}`;
      axiosConfig.headers.Authorization = bearer;
      axiosConfig.headers['Content-Type'] = 'application/json';
    }

    return axiosConfig;
  });

  axios.interceptors.response.use(
    (value) => value,
    (error: AxiosError) => {
      store.dispatch(handlerApiError({ error }));
      throw error;
    },
  );
})();
