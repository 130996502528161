import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import SRMembersList from '../../../styledComponents/ProductMembersList/SRMembersList/SRMembersList';
import { srPageFilters } from './membersSecurityResponse.const';
import { useMembersSecurityResponse } from './membersSecurityResponse.hooks';

const MembersSecurityResponsePage = () => {
  const {
    membersLoading,
    pageListMembers,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    srMembershipPricing,
  } = useMembersSecurityResponse();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <SRMembersList
        tableProps={{
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          isLoading: membersLoading,
          data: pageListMembers,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: srPageFilters,
          standardPricing: srMembershipPricing,
        }}
      />
    </>
  );
};

export default MembersSecurityResponsePage;
