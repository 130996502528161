import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEditKEOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/EditKEOrganizationSubscriptionModel';

import { IKEOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionOverview';
import { IKESubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KESubscriptionRenewalSuggestionModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMemberModel } from '../../../../models/IRenewOrganizationSubscriptionMemberModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';

export interface IKESubscriptionsState {
  currentSubscriptionOverview: IKEOrganizationSubscriptionOverview;
  subscriptionDates: ISubscriptionDates;
  currentSubscriptionEditing: IEditKEOrganizationSubscriptionModel;
  subscriptionRenewalSuggestion: IKESubscriptionRenewalSuggestionModel;
  subscriptionRenewalAnalyse: IRenewOrganizationSubscriptionAnalyseModel;
}

const initialState: IKESubscriptionsState = {
  currentSubscriptionOverview: null,
  subscriptionDates: null,
  currentSubscriptionEditing: null,
  subscriptionRenewalSuggestion: null,
  subscriptionRenewalAnalyse: {
    memberships: [],
    analysisResultForDate: null,
    totalMemberships: { membershipsCount: 0, withErrors: 0 },
  },
};

const keSubscriptionsSlice = createSlice({
  name: 'keSubscriptions',
  initialState,
  reducers: {
    setKESubscriptionOverview: (
      state,
      { payload }: PayloadAction<IKEOrganizationSubscriptionOverview>,
    ) => {
      const subscription: IKEOrganizationSubscriptionOverview = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
      };
      state.currentSubscriptionOverview = subscription;
    },
    setKESubscriptionDates: (
      state,
      { payload }: PayloadAction<ISubscriptionDates>,
    ) => {
      state.subscriptionDates = payload;
    },
    setKECurrentEditingSubscription: (
      state,
      { payload }: PayloadAction<IEditKEOrganizationSubscriptionModel>,
    ) => {
      state.currentSubscriptionEditing = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        overwrittenAmount: payload.overwrittenAmount?.toString(),
      };
    },
    setKESubscriptionRenewalSuggestion(
      state,
      { payload }: PayloadAction<IKESubscriptionRenewalSuggestionModel>,
    ) {
      state.subscriptionRenewalSuggestion = payload;
    },
    setKESubscriptionRenewalAnalyse(
      state,
      { payload }: PayloadAction<IRenewOrganizationSubscriptionAnalyseModel>,
    ) {
      const mapMembers = (
        members: IRenewOrganizationSubscriptionMemberModel[],
      ) =>
        members.map((member, index) => ({
          ...member,
          dateOfBirth: parseDate(member.dateOfBirth),
        }));

      const subscriptionAnalyse = {
        analysisResultForDate: parseDate(payload.analysisResultForDate),
        memberships: payload.memberships.map((membership) => ({
          ...membership,
          primaryMember: {
            ...membership.primaryMember,
            dateOfBirth: parseDate(membership.primaryMember.dateOfBirth),
          },
          secondaryMembers: mapMembers(membership.secondaryMembers),
        })),
        totalMemberships: payload.memberships.reduce(
          (acc, { primaryMember, secondaryMembers }) => {
            const members = [primaryMember, ...secondaryMembers];
            acc.membershipsCount = acc.membershipsCount + 1;
            const hasErrors =
              members.filter(
                ({ canBeRenewed, toBeRenewed }) => !canBeRenewed && toBeRenewed,
              ).length > 0;
            acc.withErrors = acc.withErrors + (hasErrors ? 1 : 0);
            return acc;
          },
          { membershipsCount: 0, withErrors: 0 },
        ),
      };
      state.subscriptionRenewalAnalyse = subscriptionAnalyse;
    },
    clearKESubscriptionRenewalAnalyse: (state) => {
      state.subscriptionRenewalAnalyse =
        initialState.subscriptionRenewalAnalyse;
    },
    clearKESubscriptionOverview: (state) => {
      state.currentSubscriptionOverview =
        initialState.currentSubscriptionOverview;
    },
    clearKESubscriptionHelperData: (state) => {
      state.subscriptionDates = null;
      state.subscriptionRenewalSuggestion = null;
    },
    clearKECurrentSubscriptionEditing: (state) => {
      state.currentSubscriptionEditing =
        initialState.currentSubscriptionEditing;
    },
  },
});

export const {
  clearKESubscriptionOverview,
  setKESubscriptionOverview,
  clearKESubscriptionHelperData,
  setKESubscriptionDates,
  setKECurrentEditingSubscription,
  clearKECurrentSubscriptionEditing,
  setKESubscriptionRenewalSuggestion,
  clearKESubscriptionRenewalAnalyse,
  setKESubscriptionRenewalAnalyse,
} = keSubscriptionsSlice.actions;
export default keSubscriptionsSlice.reducer;
export const subscriptionsReducerName = keSubscriptionsSlice.name;
