import { useTranslation } from 'react-i18next';

import { Field } from 'react-final-form';

import ExtendSubscriptionMembershipPlanFormTable from '../ExtendSubscriptionMembershipPlanFormTable/ExtendSubscriptionMembershipPlanFormTable';
import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import { Title, Wrapper } from './extendSubscriptionExtensionsForm.styles';
import { FormColumn } from '../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { ProductType } from '../../../../../models/ProductType';
import { ExtendSubscriptionExtensionsFormFields } from './extendSubscriptionExtensionsForm.models';

interface IExtendSubscriptionExtensionsFormProps {
  className?: string;
  disabled?: boolean;
  plans: IOrganizationSubscriptionOverviewMembershipPlan[];
  productType?: ProductType;
  columnsLabels?: Partial<
    Record<keyof IOrganizationSubscriptionOverviewMembershipPlan, string>
  >;
}

const ExtendSubscriptionExtensionsForm = ({
  className,
  disabled,
  plans,
  productType,
  columnsLabels,
}: IExtendSubscriptionExtensionsFormProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Title>{t('headers.capacity')}</Title>
      <FormColumn>
        <Field
          component={ExtendSubscriptionMembershipPlanFormTable}
          disabled={disabled}
          name={ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges}
          subscriptionPlans={plans}
          productType={productType}
          columnsLabels={columnsLabels}
        />
      </FormColumn>
    </Wrapper>
  );
};

export default ExtendSubscriptionExtensionsForm;
