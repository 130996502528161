import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';

import { IEmployee } from '../../models/Employee';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-icon-secondary.svg';
import { ReactComponent as BasketIcon } from '../../assets/icons/basket-icon.svg';
import { IPointOfContact } from '../../models/PointOfContact';
import Button from '../UI/Button/Button';
import { TableCellAction } from '../MaterialTable/CellRenderers/cellRenderers.styles';
import { Header, StyledNoteIcon, StyledSubTitle } from './contactTable.styles';
import MaterialTable from '../MaterialTable/MaterialTable';
import { IMaterialColumn } from '../MaterialTable/materialTable.models';
import { CustomComponent } from '../../models/types/CustomComponent';
import TableCell from '../MaterialTable/CellRenderers/TableCell/TableCell';

interface IContactTableProps extends CustomComponent {
  additionalColumns?: IMaterialColumn[];
  isPaginationHidden?: boolean;
  items: IPointOfContact[];
  maxItems?: number;
  minItems?: number;
  onAddContact?: () => void;
  onDeleteContact?: (id: string) => void;
  onEditContact?: (id: string) => void;
  title?: string;
  canAddContactPermissions?: string[];
  canDeleteContactPermissions?: string[];
  canEditContactPermissions?: string[];
}

const ContactsTable = ({
  title,
  className,
  items,
  isPaginationHidden = false,
  onAddContact,
  onEditContact,
  maxItems,
  onDeleteContact,
  additionalColumns = [],
  minItems,
  canAddContactPermissions = [],
  canDeleteContactPermissions = [],
  canEditContactPermissions = [],
}: IContactTableProps) => {
  const { t } = useTranslation();

  const canDelete = !minItems || minItems < items.length;

  const canViewActionColumn = useMemo(
    () => [...canDeleteContactPermissions, ...canEditContactPermissions],
    [canDeleteContactPermissions, canEditContactPermissions],
  );

  const cellRenderer =
    (key: keyof IPointOfContact | keyof IEmployee) =>
    (data: IPointOfContact) => {
      switch (key) {
        case 'internalNotes':
          return (
            <TableCell alignment="center">
              <TableCellAction>
                <StyledNoteIcon />
              </TableCellAction>
            </TableCell>
          );
      }
    };

  const actionsRenderer = (data: IPointOfContact) => (
    <TableCell alignment="center">
      <TableCellAction permissions={canEditContactPermissions}>
        <PenIcon onClick={() => onEditContact?.(data.id)} />
      </TableCellAction>
      {canDelete && (
        <TableCellAction permissions={canDeleteContactPermissions}>
          <BasketIcon onClick={() => onDeleteContact?.(data.id)} />
        </TableCellAction>
      )}
    </TableCell>
  );

  return (
    <div className={className}>
      <Header>
        {title && <StyledSubTitle>{title}</StyledSubTitle>}
        <Button
          permissions={canAddContactPermissions}
          disabled={items.length >= maxItems}
          onClick={onAddContact}
          styleType="link"
        >
          {t('buttons.add-contact')}
        </Button>
      </Header>

      {items.length > 0 && (
        <MaterialTable
          columns={[
            {
              title: t('grid-columns.jobTitle'),
              field: 'jobTitle',
              minWidth: '7rem',
            },
            {
              title: t('grid-columns.name'),
              propertySelector: (row: IPointOfContact) =>
                `${row.person?.firstName ?? ''} ${row.person?.lastName ?? ''}`,
              minWidth: '14.75rem',
            },
            {
              title: t('grid-columns.mobile-phone'),
              propertySelector: (row: IPointOfContact) => row.mobilePhone,
              minWidth: '14rem',
            },
            {
              title: t('grid-columns.work-phone'),
              propertySelector: (row: IPointOfContact) => row.workPhone,
              minWidth: '14rem',
            },
            {
              title: t('grid-columns.email'),
              propertySelector: (row: IPointOfContact) => row.workEmail,
              minWidth: '19.5em',
            },
            ...additionalColumns,
            {
              title: t('grid-columns.notes'),
              field: 'notes',
              cellRenderer: cellRenderer('internalNotes'),
              tooltip: (value: IPointOfContact) =>
                value.internalNotes || t('phrase.no-notes'),
              alignment: 'center',
              minWidth: '4rem',
            },
            {
              title: t('grid-columns.actions'),
              cellRenderer: actionsRenderer,
              alignment: 'center',
              permissions: canViewActionColumn,
            },
          ]}
          data={items}
          isPaginationHidden={isPaginationHidden}
        />
      )}
    </div>
  );
};

export default ContactsTable;
