import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { withWarningsConfirmationProvider } from '../../../../hoc/withWarningsConfirmationProvider';

import FormErrorActivator from '../../../FormErrorProvider/FormErrorActivator/FormErrorActivator';
import ModalFormPage from '../../../ModalFormPage/ModalFormPage';
import { useAddContractModalForm } from './addContractModalForm.hooks';
import { StyledContractForm } from './addContractModalForm.styles';
import WarningController from './WarningController';

interface IAddContractModalFormProps {
  onContractAdded?: () => void;
  onCancel?: () => void;
}

const AddContractModalForm = ({
  onContractAdded,
  onCancel,
}: IAddContractModalFormProps) => {
  const { t } = useTranslation();

  const {
    contractAdding,
    handleSubmit,
    onCloseHandler,
    validateForm,
    needShowOverlapMessage,
  } = useAddContractModalForm({ onContractAdded, onCancel });

  return (
    <Form
      initialValues={{}}
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ ...renderProps }) => (
        <FormErrorActivator>
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            isProcessing={contractAdding}
            onCancel={onCloseHandler}
            title={t('headers.add-contract')}
            canSaveDraft
            submitButtonLabel={t('buttons.add')}
          >
            <WarningController />
            <StyledContractForm
              overlapMessageVisible={needShowOverlapMessage}
              disabled={contractAdding}
            />
          </ModalFormPage>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default withWarningsConfirmationProvider(AddContractModalForm);
