import {
  SystemGroupPermissions,
  TravelLicensePermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canAddTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanCreateSubscription,
  ),
];

export const canRemoveTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanDeleteSubscription,
  ),
];

export const canEditTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanUpdateSubscription,
  ),
];

export const canAddSeatsTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanUpdateSubscription,
  ),
];

export const canViewTLSubscriptionDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanViewSubscriptionDetails,
  ),
];

export const canUpdateTLSubscriptionsDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanUpdateSubscription,
  ),
];

export const canRenewTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanCreateSubscription,
  ),
];

export const canExtendsTLSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.TravelLicense,
    TravelLicensePermissions.CanUpdateSubscription,
  ),
];
