import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SectionTitle as Title } from '../Typography/Typography';
import { FieldLabel, FieldWrapper } from '../UI/Field/Field';
import { FieldsDeleteButton } from '../UI/FieldsDeleteButton/FieldsDeleteButton';

export const StyledForm = styled.form`
  padding: 2.5rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FormSectionTitle = styled.h4`
  color: ${({ theme }) => theme.color.dark1};
  font: 700 normal 0.875rem/1rem ${({ theme }) => theme.font.gotham};
  letter-spacing: 0.013rem;
  text-align: left;
  margin-bottom: 1.5rem;
`;

export type Props = {
  loading?: boolean;
};

export const SectionWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  position: relative;
`;

export const BasicFormWrapper = styled.div`
  width: 100%;
`;

export const FormWrapper = styled(BasicFormWrapper)`
  margin-top: 2rem;
  width: 100%;
  position: relative;
`;

export const SectionTitle = styled(Title)`
  margin-bottom: 2.5rem;
`;

export const SecondSectionTitle = styled(Title)`
  margin-bottom: 1.5rem;
`;

type FormFieldWrapperType = {
  error?: boolean;
};

export const FormFieldWrapper = styled.div<FormFieldWrapperType>`
  width: 100%;
  ${({ error, theme }) =>
    error &&
    css`
      ${FieldWrapper} > :not(:placeholder-shown) ~ ${FieldLabel},
      ${FieldWrapper} > ${FieldLabel},
      ${FieldLabel} {
        color: ${theme.color.error};
      }
    `}
`;

export const FormFieldWithMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FormNumber = styled.div`
  color: ${({ theme }) => theme.color.secondary2};
  width: 0;
`;

export const FormEmptySpace = styled.div`
  width: 0;
`;

export const FormDeleteButton = styled(FieldsDeleteButton)`
  position: absolute;
  right: -1.375rem;
  top: 0;
`;
