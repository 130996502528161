import { createSelector } from 'reselect';

import { IDOCMembersState } from '.';
import { MemberType } from '../../../../models/enums/MemberType';

import { RootState } from '../../index';

export const docMembershipSelector = (state: RootState) => state.docMembership;

export const membershipSummarySelector = createSelector(
  docMembershipSelector,
  (members: IDOCMembersState) => members.summary,
);

export const pageListDOCMembersSelector = createSelector(
  docMembershipSelector,
  (members: IDOCMembersState) => members.members,
);

export const docMembershipDatesSelector = createSelector(
  docMembershipSelector,
  (members: IDOCMembersState) => members.newMembershipDates,
);

export const docMembershipDetailsByIdSelector = (membershipId: string) =>
  createSelector(
    docMembershipSelector,
    (members: IDOCMembersState) =>
      members.membershipDetails &&
      members.membershipDetails[membershipId] && {
        ...members.membershipDetails[membershipId],
        members: [
          {
            ...members.membershipDetails[membershipId]?.member,
            toBeRenewed: members.membershipDetails[membershipId]?.autoRenewal,
            memberType: MemberType.Primary,
          },
        ],
      },
  );
