import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { ISRMembersState } from '.';

export const srMembershipSelector = (state: RootState) => state.srMembership;

export const membershipSummarySelector = createSelector(
  srMembershipSelector,
  (members: ISRMembersState) => members.summary,
);

export const pageListSRMembersSelector = createSelector(
  srMembershipSelector,
  (members: ISRMembersState) => members.members,
);

export const srMembershipDatesSelector = createSelector(
  srMembershipSelector,
  (members: ISRMembersState) => members.newMembershipDates,
);

export const srMembershipDetailsByIdSelector = (membershipId: string) =>
  createSelector(
    srMembershipSelector,
    (members: ISRMembersState) =>
      members.membershipDetails && members.membershipDetails[membershipId],
  );
