import { ChangeEvent, useCallback, useEffect } from 'react';
import { FieldInputProps } from 'react-final-form';

type UseFormSwitchType = { input: FieldInputProps<boolean, HTMLElement> };

export const useFormSwitch = ({
  input: { onChange, checked },
}: UseFormSwitchType) => {
  useEffect(() => {
    if (!checked) {
      onChange(false);
    }
  }, [checked, onChange]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    [onChange],
  );

  return { onChangeHandler, checked };
};
