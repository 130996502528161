import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const PromoCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
  align-items: center;
`;

type PromoCodeTextProps = {
  isClickable?: boolean;
};

export const PromoCodeText = styled.div<PromoCodeTextProps>`
  font-size: 0.875rem;
  line-height: 2rem;
  font-size: ${({ theme }) => theme.font.gotham};
  align-items: center;
  gap: 1.875rem;
  ${({ isClickable }) =>
    isClickable &&
    css`
      font-weight: bold;
    `}
`;
