import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import { FormSectionTitle, SectionWrapper } from '../../Form/Form';
import FormAddress from '../FormAddress/FormAddress';
import {
  FormAddressInformationsFields,
  IFormAddressInformationProps,
} from './formAddressInformations.models';
import { StyledFormCheckbox, Wrapper } from './formAddressInformations.styles';

const FormAddressInformations = ({
  className,
  disabled,
  isSubRowSpace,
  fields = FormAddressInformationsFields,
}: IFormAddressInformationProps) => {
  const { t } = useTranslation();
  const formState = useFormState();
  const value =
    formState.values[fields.CorrespondenceAddressSameAsResidenceAddress];

  return (
    <Wrapper className={className}>
      <SectionWrapper>
        <FormSectionTitle>{t('headers.residence-address')}</FormSectionTitle>
        <FormAddress
          disabled={disabled}
          isGoogleSearchHidden
          isSubRowSpace={isSubRowSpace}
          name={fields.ResidenceAddress}
        />
      </SectionWrapper>
      <Field
        component={StyledFormCheckbox}
        disabled={disabled}
        label={t('fields.billingAddressSameAsResidenceAddress.label')}
        inputId="member-address-information-correspondence-address-same-as-billing-checkbox"
        name={fields.CorrespondenceAddressSameAsResidenceAddress}
        type="checkbox"
      />
      {!value && (
        <SectionWrapper>
          <FormSectionTitle>
            {t('headers.billing-address-title')}
          </FormSectionTitle>
          <FormAddress
            disabled={disabled}
            isGoogleSearchHidden
            isSubRowSpace={isSubRowSpace}
            name={fields.CorrespondenceAddress}
          />
        </SectionWrapper>
      )}
    </Wrapper>
  );
};

export default FormAddressInformations;
