import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICreditCard } from '../../../models/CreditCardModel';

export interface IPaymentState {
  creditCards: ICreditCard[];
  creditCardsLoaded?: boolean;
}

const initialState: IPaymentState = {
  creditCards: null,
  creditCardsLoaded: false,
};

const paymentSlice = createSlice({
  initialState,
  name: 'payments',
  reducers: {
    setCreditCards: (state, { payload }: PayloadAction<ICreditCard[]>) => {
      state.creditCards = payload;
    },
    setCreditCardsLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.creditCardsLoaded = payload;
    },
    clearCreditCardsLoaded: (state) => {
      state.creditCardsLoaded = initialState.creditCardsLoaded;
    },
    clearCreditCards: (state) => {
      state.creditCards = initialState.creditCards;
    },
    clearCreditCardsData: (state) => {
      state.creditCards = initialState.creditCards;
      state.creditCardsLoaded = initialState.creditCardsLoaded;
    },
  },
});

export const {
  setCreditCards,
  clearCreditCards,
  clearCreditCardsLoaded,
  setCreditCardsLoaded,
  clearCreditCardsData,
} = paymentSlice.actions;
export default paymentSlice.reducer;
export const paymentReducerName = paymentSlice.name;
