import styled from '@emotion/styled';

import { ModalFormSectionTitle } from '../../Typography/Typography';
import FormCheckbox from '../../UI/Form/FormCheckbox/FormCheckbox';
import FormMessage from '../../UI/Form/FormMessage/FormMessage';

export const StyledFormMessage = styled(FormMessage)`
  margin-top: 1.5rem;
`;

export const Title = styled(ModalFormSectionTitle)`
  margin-bottom: 1rem;
`;

export const ResidenceAddress = styled.div`
  margin-top: 2rem;
`;

export const ResidenceAddressCheckbox = styled(FormCheckbox)`
  margin-top: 1.5rem;
`;
