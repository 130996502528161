/* eslint-disable react/destructuring-assignment */

import { useTranslation } from 'react-i18next';

import { useExtendDOCSubscriptionPackageFormTable } from './extendDOCSubscriptionPackageFormTable.hooks';
import { NameRenderer } from '../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { ProductType } from '../../../../models/ProductType';
import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import { prepareTableData } from './extendDOCSubscriptionPackageFormTable.utils';
import { DOCPackageTableRow } from './extendDOCSubscriptionPackageFormTable.models';
import { ChangesColumnRenderer } from './ChangesColumnRenderer/ChangesColumnRenderer';
import { CustomForm } from '../../../../models/types/CustomForm';
import { ErrorLabel } from '../../../../styledComponents/UI/Field/Field';
import { StyledMaterialTable } from './extendDOCSubscriptionPackageFormTable.styles';

interface IExtendSubscriptionPackageFormTableProps extends CustomForm {
  disabled?: boolean;
  packageData?: IDOCOrganizationSubscriptionExtensionData;
  productType?: ProductType;
}

const ExtendSubscriptionPackageFormTable = ({
  disabled,
  packageData,
  productType,
  name,
}: IExtendSubscriptionPackageFormTableProps) => {
  const { t } = useTranslation();
  const { currentValue, errors } = useExtendDOCSubscriptionPackageFormTable({
    productType,
    name,
    packageData,
  });

  const cellRenderer =
    (key: keyof DOCPackageTableRow | any, options?: ICellOptions) =>
    (data: DOCPackageTableRow) => {
      switch (key) {
        case 'name': {
          return <NameRenderer options={options} value={data.name} />;
        }
        case 'changes':
          return (
            <ChangesColumnRenderer
              columnType={data.columnType}
              options={options}
              disabled={disabled}
              data={packageData}
              name={name}
            />
          );
      }
    };

  return (
    <div>
      <StyledMaterialTable
        columns={[
          {
            field: 'name',
            cellRenderer: cellRenderer('name', {
              textBold: true,
              disabled,
            }),
          },
          {
            title: t('grid-columns.current-values'),
            field: 'currentValues',
          },
          {
            title: t('grid-columns.changes'),
            alignment: 'center',
            cellRenderer: cellRenderer('changes', {
              textBold: true,
              disabled,
              alignment: 'center',
            }),
          },
          {
            title: t('grid-columns.new-values'),
            field: 'newValues',
          },
        ]}
        data={prepareTableData(packageData, currentValue)}
        isPaginationHidden
      />
      {errors &&
        errors.map((error) => <ErrorLabel key={error}>{error}</ErrorLabel>)}
    </div>
  );
};

export default ExtendSubscriptionPackageFormTable;
