import { Outlet } from 'react-router';

import TabView from '../TabView/TabView';
import { ITabNavigation, ITabNavigationItem } from '../tabNavigation.models';
import { CustomComponent } from '../../../../models/types/CustomComponent';

type TabNavigationContentProps = ITabNavigation &
  CustomComponent & {
    value: string;
  };

const TabNavigationContent = ({
  routeMode,
  value,
  tabs,
  children,
}: TabNavigationContentProps) => {
  if (routeMode) {
    return <div>{children || <Outlet />}</div>;
  }
  return (
    <>
      {tabs.map((tab: ITabNavigationItem) => (
        <TabView currentTab={value} key={tab.label} value={tab.label}>
          {tab.component}
        </TabView>
      ))}
    </>
  );
};

export default TabNavigationContent;
