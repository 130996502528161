import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { ProductSubscriptionRenewPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import { organizationSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import { clearTLSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/travelLicense';
import { tlSubscriptionRenewalSuggestionSelector } from '../../../store/redux-slices/subscriptions/travelLicense/selectors';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';
import {
  loadDatesForNewTLSubscription,
  loadTLSubscriptionRenewalSuggestion,
} from '../../../store/saga-slices/subscriptions/travelLicense';
import { ILoadTLSubscriptionRenewalSuggestionPayload } from '../../../store/saga-slices/subscriptions/travelLicense/models';

export const useSubscriptionTLRenew = () => {
  const { organizationId, subscriptionId } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const LoadOrganization = useActions(loadOrganization);
  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewTLSubscription);
  const ClearTLSubscriptionHelperData = useActions(
    clearTLSubscriptionHelperData,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);

  const LoadSubscriptionRenewalSuggestion = useActions(
    loadTLSubscriptionRenewalSuggestion,
  );

  const subscriptionRenewalSuggestion = useSelector(
    tlSubscriptionRenewalSuggestionSelector,
  );

  const isOrganizationDataLoading = useProcessing(
    ORGANIZATION_LOADING_PROCESSING,
  );

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const organization = useSelector(organizationSelector);

  const loadSubscriptionRenewalSuggestionHandler = useCallback(() => {
    const payload: ILoadTLSubscriptionRenewalSuggestionPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionRenewalSuggestion(payload);
  }, [LoadSubscriptionRenewalSuggestion, organizationId, subscriptionId]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    LoadOrganizationContracts({ organizationId });
    LoadOrganization(organizationId);

    loadSubscriptionRenewalSuggestionHandler();
  }, [
    LoadOrganization,
    LoadOrganizationContracts,
    LoadSubscriptionRenewalSuggestion,
    organizationId,
    subscriptionId,
    loadSubscriptionRenewalSuggestionHandler,
  ]);

  useEffect(
    () => () => {
      ClearTLSubscriptionHelperData();
      ClearOrganizationContracts();
      ClearSubscriptionHelperData();
    },
    [
      ClearOrganizationContracts,
      ClearTLSubscriptionHelperData,
      ClearSubscriptionHelperData,
    ],
  );

  const needShowSpinner =
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading);

  return {
    organization,
    organizationId,
    subscriptionId,
    loadSubscriptionRenewalSuggestionHandler,
    loadDatesForNewSubscriptionHandler,
    needShowSpinner,
  };
};
