import styled from '@emotion/styled';

import { StyledForm } from '../../../styledComponents/Form/Form';

import Button from '../../../styledComponents/UI/Button/Button';
import OrganizationTypeForm from '../../../styledComponents/Forms/OrganizationTypeForm/OrganizationTypeForm';

export const ClientForm = styled(StyledForm)`
  max-width: 80rem;
  min-width: 63rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 2.75rem;
  margin-bottom: 5rem;
  padding: 0.5rem 1rem;
  width: 17.5rem;
  text-transform: uppercase;
`;

export const StyledOrganizationTypeForm = styled(OrganizationTypeForm)`
  margin-top: 0;
`;
