import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../../styledComponents/ConfirmModal/ConfirmModal';
import { DELETE_PROMO_CODE_MODAL_NAME } from './deletePromoCodeModal.consts';
import { Description, PromoCodeName } from './deletePromoCodeModal.styles';
import { useDeletePromoCode } from './deletePromoCodeModal.hooks';

const DeletePromoCodeModal = () => {
  const { t } = useTranslation();
  const { onBackHandler, isRemoving, deletePromoCodeHandler, promoCodeName } =
    useDeletePromoCode();
  return (
    <ConfirmModal
      name={DELETE_PROMO_CODE_MODAL_NAME}
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: isRemoving,
        },
        {
          action: deletePromoCodeHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: isRemoving,
        },
      ]}
      title={t('headers.delete-promo-code')}
    >
      <Description>{t('phrase.confirm-remove-promo-code')}</Description>
      <PromoCodeName>{promoCodeName}</PromoCodeName>
    </ConfirmModal>
  );
};

export default DeletePromoCodeModal;
