import axios, { AxiosResponse } from 'axios';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { IPeopleSearchResult } from '../../people/api';

export interface IStandardPricingQueryResponse {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

export const searchPersonPeopleManagement = (
  email: string,
): Promise<AxiosResponse<IPeopleSearchResult>> =>
  axios.get(`/api/people/search?email=${encodeURIComponent(email)}`);

export const getCountries = (): Promise<AxiosResponse<string[]>> =>
  axios.get(`/api/countries`);
