import { IContractItem } from '../models/interfaces/ContractItem';
import { IOrganizationContractDetailsModel } from '../models/interfaces/OrganizationContractDetailsModel';
import { IUpdateOrganizationContractModel } from '../models/interfaces/UpdateOrganizationContractModel';
import { isPropertyEmpty } from './propertyHelpers';

export const contractDetailsModelToUpdateContractModel = (
  contractDetails: IOrganizationContractDetailsModel,
): IUpdateOrganizationContractModel =>
  contractDetails && {
    contractType: contractDetails.contractType,
    expirationDate: contractDetails.expirationDate,
    name: contractDetails.name,
    startDate: contractDetails.startDate,
    isDraft: contractDetails.isDraft,
    isMultiYearContract: contractDetails.isMultiYearContract,
  };

export const getContractType = (
  currentContractId: string,
  organizationContracts: IContractItem[],
) => {
  const index = organizationContracts?.findIndex(
    (x) => x.id === currentContractId,
  );

  return !isPropertyEmpty(index) && index !== -1
    ? organizationContracts[index].contractType
    : null;
};
