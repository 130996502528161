import { t } from 'i18next';

export const getTranslationsFromEnum = (
  e: { [x: string]: string },
  enumName?: string,
  options?: string[],
): string[] =>
  Object.values(e)
    .filter((type) => options.includes(type))
    .map((type) => t(`enums.${enumName}.${type}`));
