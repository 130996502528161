import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { clearCreditCardsData } from '../../../../../store/redux-slices/payment/index';

import { useFormErrorsValues } from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { ICreatePaymentModel } from '../../../../../models/CreatePaymentModel';
import { RoutePath } from '../../../../../models/enums/RoutePath';
import { clearAAMembershipSummary } from '../../../../../store/redux-slices/membership/airAmbulance';
import {
  LOAD_CREDIT_CARDS_PROCESSING,
  MEMBERSHIP_RETAIL_CREATING_PROCESSING,
  PAYMENT_CAPTURE_PROCESSING,
} from '../../../../../store/redux-slices/processes/constants';
import {
  createRetailAAMembership,
  loadRetailAAMembershipSummary,
} from '../../../../../store/saga-slices/membership/airAmbulance';
import {
  ICreateRetailAAMembershipPayload,
  ILoadRetailAAMembershipSummaryPayload,
} from '../../../../../store/saga-slices/membership/airAmbulance/models';
import {
  clearDataPayment,
  loadCreditCards,
} from '../../../../../store/saga-slices/payment';
import { useApiResponseFail } from '../../../../../hooks/useApiResponseFail';
import { IWarningRatification } from '../../../../../models/interfaces/WarningRatification';
import { PaymentSummaryFields } from '../AddMemberPaymentSummaryForm/addMemberPaymentSummaryForm.models';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { useApiWarningValues } from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';
import { IHandlePaymentModel } from './addMemberPaymentForm.models';
import {
  createdMembershipIdSelector,
  membershipSelector,
  createdMembershipAutoRenewFlagSelector,
  primaryMemberNameSelector,
  primaryMemberIdSelector,
  createdMembershipOverviewAmountSelector,
} from '../../../../../store/redux-slices/membership/common/selectors';
import { ICreateAAMembershipPageModel } from '../../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { ICreateRetailMembershipSagaResult } from '../../../../../models/interfaces/ICreateRetailMembershipSagaResult';
import { creditCardsLoadedSelector } from '../../../../../store/redux-slices/payment/selectors';

export const useAddMemberPayment = () => {
  const CreateRetailMembership = useActions(createRetailAAMembership);
  const ClearDataPayment = useActions(clearDataPayment);
  const LoadCreditCards = useActions(loadCreditCards);
  const cancellationToken = useCancellationToken();

  const alert = useAlert();

  const { t } = useTranslation();

  const navigate = useNavigateSearch();

  const { errors } = useFormErrorsValues();
  const { ratifications } = useApiWarningValues();

  const { handleResponse } = useApiResponseFail();

  const createdMembershipId = useSelector(createdMembershipIdSelector);
  const membership = useSelector(membershipSelector);
  const isAutoRenew = useSelector(createdMembershipAutoRenewFlagSelector);
  const overviewAmountData = useSelector(
    createdMembershipOverviewAmountSelector,
  );
  const creditCardLoaded = useSelector(creditCardsLoadedSelector);

  const primaryMemberName = useSelector(primaryMemberNameSelector);
  const personId = useSelector(primaryMemberIdSelector);

  const ClearMembershipSummary = useActions(clearAAMembershipSummary);
  const ClearCreditCards = useActions(clearCreditCardsData);

  const LoadRetailMembershipSummary = useActions(loadRetailAAMembershipSummary);

  const stripe = useStripeProvider();

  const paymentProcessing = useProcessing([
    PAYMENT_CAPTURE_PROCESSING,
    MEMBERSHIP_RETAIL_CREATING_PROCESSING,
  ]);

  const dataLoading = useProcessing(LOAD_CREDIT_CARDS_PROCESSING);

  useEffect(() => {
    if (!personId) return;
    const payload = {
      personId,
    };
    LoadCreditCards(payload);
  }, [LoadCreditCards, personId]);

  useEffect(() => () => ClearCreditCards(), [ClearCreditCards]);

  useEffect(() => {
    const payload: ILoadRetailAAMembershipSummaryPayload = {
      membershipPlanId: (membership as ICreateAAMembershipPageModel)
        ?.membershipPlanId?.membershipPlanId,
      promoCode: (membership as ICreateAAMembershipPageModel)?.promoCode,
      effectiveDate: membership?.effectiveDate,
      autoRenewal: membership?.autoRenewal,
    };

    LoadRetailMembershipSummary(payload);
  }, [LoadRetailMembershipSummary, membership]);

  useEffect(
    () => () => {
      ClearDataPayment();
      ClearMembershipSummary();
    },
    [ClearDataPayment, ClearMembershipSummary],
  );

  const successResponseHandler = useCallback(() => {
    alert.success(
      t('alerts.add-member-success', {
        personName: primaryMemberName,
      }),
    );
    navigate(RoutePath.MembersPage);
  }, [alert, navigate, primaryMemberName, t]);

  const paymentHandler = useCallback(
    (values: IHandlePaymentModel) => {
      if (values.existingCreditCardId) {
        stripe.confirmCardPayment(
          values.paymentIntentInfo,
          values.existingCreditCardId,
          successResponseHandler,
          handleResponse,
        );

        return;
      }

      stripe.payWithStripe(
        values.paymentIntentInfo,
        values,
        successResponseHandler,
        handleResponse,
      );
    },
    [handleResponse, stripe, successResponseHandler],
  );

  const createRetailMembershipHandler = useCallback(
    (
      values: ICreatePaymentModel,
      newRatifications: IWarningRatification[] = [],
    ) => {
      const payload: ICreateRetailAAMembershipPayload = {
        success: (data: ICreateRetailMembershipSagaResult) => {
          if (data.isPaymentCaptureRequired) {
            paymentHandler({
              ...values,
              paymentIntentInfo: data.paymentIntent,
            });
            return;
          } else if (data.isCreditCardRequired) {
            stripe.submitCardWithStripe(
              data.cardSetupIntent,
              values,
              successResponseHandler,
              handleResponse,
            );
            return;
          }
          successResponseHandler();
        },
        error: (err) => {
          handleResponse({
            err,
            values,
            alertErrorMessage: t('alerts.card-payment-failed'),
          });
        },
        handlePayment: !values.alreadyPaid,
        setAlreadyPayed: values.alreadyPaid,
        cancellationToken,
      };
      CreateRetailMembership(payload);
    },
    [
      CreateRetailMembership,
      cancellationToken,
      handleResponse,
      paymentHandler,
      stripe,
      successResponseHandler,
      t,
    ],
  );

  const handleSubmit = useCallback(
    (values: ICreatePaymentModel) => {
      createRetailMembershipHandler(values, ratifications);
    },
    [createRetailMembershipHandler, ratifications],
  );

  const validateForm = (data: ICreatePaymentModel) =>
    !data.existingCreditCardId && errors;

  const initValues = {
    ...overviewAmountData,
    [PaymentSummaryFields.IsAutoRenew]: isAutoRenew,
  };

  const showPaymentSection = !personId || creditCardLoaded;

  return {
    creditCardLoaded,
    createdMembershipId,
    handleSubmit,
    paymentProcessing,
    validateForm,
    paymentHandler,
    initValues,
    dataLoading,
    showPaymentSection,
  };
};
