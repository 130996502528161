import { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounce } from './useDebounce';

type UseBaseFormInput<T> = {
  input: {
    onChange: (event: React.ChangeEvent<T> | any) => void;
    value: T;
  };
  parse?: (value: T) => T;
};

export const useBaseFormInput = <T>({ input, parse }: UseBaseFormInput<T>) => {
  const { debounce } = useDebounce();
  const internalActions = useRef(false);

  const [inputValue, setInputValue] = useState<T>(input.value);

  const onChangeHandler = useCallback(
    (value: T) => {
      internalActions.current = true;
      const parsedValue = parse ? parse(value) : value;
      setInputValue(parsedValue);
      debounce(() => {
        input.onChange(parsedValue);
        internalActions.current = false;
      }, 300);
    },
    [debounce, input, parse],
  );
  useEffect(() => {
    if (!internalActions.current) {
      setInputValue(input.value);
    }
  }, [input.value]);
  return {
    onChangeHandler,
    inputValue,
  };
};
