import { OrganizationStatus } from '../../../models/OrganizationStatus';
import { OrganizationType } from '../../../models/enums/OrganizationType';
import {
  ClientOverviewHeaderWrapper,
  ClientOverviewHeaderTitleContainer,
  ClientOverviewHeaderTitle,
  ClientOverviewHeaderTitleIcon,
} from './clientOverviewHeader.styles';
import OrganizationTypeIcon from '../../../styledComponents/UI/OrganizationTypeIcon/OrganizationTypeIcon';
import { Status } from '../../../styledComponents/UI/Status/Status';

interface IClientOverviewHeaderProps {
  status?: OrganizationStatus;
  title?: string;
  type?: OrganizationType;
}

const ClientOverviewHeader = ({
  status,
  title,
  type,
}: IClientOverviewHeaderProps) => (
  <ClientOverviewHeaderWrapper>
    <ClientOverviewHeaderTitleContainer>
      <ClientOverviewHeaderTitleIcon>
        <OrganizationTypeIcon organizationType={type} />
      </ClientOverviewHeaderTitleIcon>
      <ClientOverviewHeaderTitle>{title}</ClientOverviewHeaderTitle>
    </ClientOverviewHeaderTitleContainer>
    {status && <Status status={status} />}
  </ClientOverviewHeaderWrapper>
);

export default ClientOverviewHeader;
