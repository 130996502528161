export const convertStringToBoolean = (value: string) => {
  if (!value) {
    return value;
  }

  return value === 'true';
};

export const isStringBoolean = (value: string) =>
  value === 'true' || value === 'false';
