/* eslint-disable react/no-array-index-key */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { IMaterialColumn } from '../materialTable.models';

import MaterialTableSort from '../MaterialTableTools/MaterialTableSort';
import { HeaderTableCell } from './tableHeader.styles';

type TableHeaderProps = {
  columns: IMaterialColumn[];
};

const TableHeader = ({ columns }: TableHeaderProps) => (
  <TableHead>
    <TableRow>
      {columns.map((column, index) =>
        column.sortable ? (
          <MaterialTableSort column={column} key={index} />
        ) : (
          <HeaderTableCell align={column.alignment} key={index}>
            {column.title}
          </HeaderTableCell>
        ),
      )}
    </TableRow>
  </TableHead>
);

export default TableHeader;
