import { useEffect } from 'react';
import { useField } from 'react-final-form';

import { OrganizationContractType } from '../../../../models/enums/ContractType';
import { KidnapAndExtortionSubscriptionOptions } from '../../../../models/enums/KidnapAndExtortionSubscriptionOptions';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';

type UseKidnapAndExtortionSubscriptionForm = {
  contractType?: OrganizationContractType;
  name?: string;
};

export const useKidnapAndExtortionSubscriptionForm = ({
  contractType,
  name,
}: UseKidnapAndExtortionSubscriptionForm) => {
  const {
    input: { onChange: autoRenewChange },
  } = useField(
    getFormGroupPath(name, KidnapAndExtortionSubscriptionOptions.AutoRenewal),
  );
  useEffect(() => {
    contractType !== OrganizationContractType.Multiple &&
      autoRenewChange(false);
  }, [autoRenewChange, contractType]);
};
