import { useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { MemberType } from '../../../../models/enums/MemberType';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';
import { getOptionsFromArray } from '../../../../utils/getOptionsFromArray';

import {
  FamilyMemberBasicDetailsFields,
  MemberTypeForAge,
  MemberTypeAgeRange,
} from './familyMemberBasicDetails.models';

type UseFamilyMemberBasicDetailsType = {
  name?: string;
  memberTypesAgeRanges?: Record<MemberTypeForAge, MemberTypeAgeRange>;
  memberTypes?: MemberType[];
};

export const useFamilyMemberBasicDetails = ({
  name,
  memberTypesAgeRanges,
  memberTypes,
}: UseFamilyMemberBasicDetailsType) => {
  const {
    input: { value: formDateOfBirth },
  }: FieldRenderProps<string> = useField(
    getFormGroupPath(name, FamilyMemberBasicDetailsFields.DateOfBirth),
  );

  const {
    input: { value: dependence },
  }: FieldRenderProps<MemberType> = useField(
    getFormGroupPath(name, FamilyMemberBasicDetailsFields.Dependence),
  );

  const {
    input: { value: personId },
  } = useField(getFormGroupPath(name, FamilyMemberBasicDetailsFields.PersonId));

  const ageRange = useMemo(() => {
    if (!memberTypesAgeRanges) {
      return { max: null, min: null };
    }
    switch (dependence) {
      case MemberType.Dependent:
        return {
          min: memberTypesAgeRanges[MemberType.Dependent].minAge,
          max: memberTypesAgeRanges[MemberType.Dependent].maxAge,
        };
      case MemberType.Spouse:
        return {
          min: memberTypesAgeRanges[MemberType.Spouse].minAge,
          max: memberTypesAgeRanges[MemberType.Spouse].maxAge,
        };
      default:
        return { max: null, min: null };
    }
  }, [dependence, memberTypesAgeRanges]);

  const memberTypeOptions = useMemo(() => {
    if (!memberTypes) {
      return;
    }

    return getOptionsFromArray(memberTypes, 'member-type');
  }, [memberTypes]);

  return {
    memberTypeOptions,
    ageRange,
    formDateOfBirth,
    memberLoaded: !!personId,
    dependence,
  };
};
