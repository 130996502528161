import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PointOfContactRoles } from '../../../models/enums/PointOfContactRoles';
import { GlobalGuardianPointOfContactRoles } from '../../../models/enums/GlobalGuardianPointOfContactRoles';

export interface IRolesState {
  ggpoc: GlobalGuardianPointOfContactRoles[];
  poc: PointOfContactRoles[];
}

const initialState: IRolesState = {
  poc: [],
  ggpoc: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, { payload }: PayloadAction<PointOfContactRoles[]>) => {
      state.poc = payload;
    },
    setGGRoles: (
      state,
      { payload }: PayloadAction<GlobalGuardianPointOfContactRoles[]>,
    ) => {
      state.ggpoc = payload;
    },
    clearRoles: (state) => {
      state.poc = initialState.poc;
    },
    clearGGRoles: (state) => {
      state.ggpoc = initialState.ggpoc;
    },
  },
});

export const { setRoles, setGGRoles, clearRoles, clearGGRoles } =
  rolesSlice.actions;
export default rolesSlice.reducer;
export const personReducerName = rolesSlice.name;
