import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { getSubscriptionFinanceReports, getRetailFinanceReports } from './api';
import { updateProcessState } from '../../../redux-slices/processes';

import {
  SUBSCRIPTION_FINANCE_REPORTS_PROCESSING,
  RETAIL_FINANCE_REPORTS_PROCESSING,
} from '../../../redux-slices/processes/constants';

import { Log } from '../../../../utils/logger';
import downloadFile from '../../../../utils/downloadFile';

import {
  IGetFinanceReportsPayload,
  IGetRawDataReportPayload,
  ReportsFilenamePrefixEnum,
} from './models';
import { CUSTOM_DATE_MONTH_YEAR_FORMAT } from '../../../../utils/dateHelpers';
import { generateFilenameWithDate } from '../../../../utils/generateFilenameWithDate';
import { ProductType } from '../../../../models/ProductType';
import { downloadAARawDataReport } from '../airAmbulance';
import { downloadKERawDataReport } from '../kidnapAndExtortion';
import { downloadSRRawDataReport } from '../securityResponse';
import { downloadDOCRawDataReport } from '../dutyOfCare';

const reportsSlice = createSliceSaga({
  caseSagas: {
    *downloadSubscriptionFinanceReport({
      payload: {
        success,
        error,
        data: { date },
      },
    }: PayloadAction<IGetFinanceReportsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_FINANCE_REPORTS_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.SubscriptionFinanceReport,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
          date,
        );
        const response = yield* call(getSubscriptionFinanceReports, date);
        yield downloadFile(response.data, name);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_FINANCE_REPORTS_PROCESSING));
      }
    },
    *downloadRetailFinanceReport({
      payload: {
        success,
        error,
        data: { date },
      },
    }: PayloadAction<IGetFinanceReportsPayload>) {
      try {
        yield put(updateProcessState(RETAIL_FINANCE_REPORTS_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.RetailFinanceReport,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
          date,
        );
        const response = yield* call(getRetailFinanceReports, date);
        yield downloadFile(response.data, name);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RETAIL_FINANCE_REPORTS_PROCESSING));
      }
    },
    *downloadRawDataReport({
      payload: { productType, ...restPayload },
    }: PayloadAction<IGetRawDataReportPayload>) {
      switch (productType) {
        case ProductType.AirAmbulance:
          yield put(downloadAARawDataReport(restPayload));
          return;
        case ProductType.KidnapAndExtortion:
          yield put(downloadKERawDataReport(restPayload));
          return;
        case ProductType.SecurityResponse:
          yield put(downloadSRRawDataReport(restPayload));
          return;
        case ProductType.DutyOfCare:
          yield put(downloadDOCRawDataReport(restPayload));
      }
    },
  },
  name: 'reports-saga',
});

export default reportsSlice.saga;
export const {
  downloadSubscriptionFinanceReport,
  downloadRetailFinanceReport,
  downloadRawDataReport,
} = reportsSlice.actions;
export const { actions } = reportsSlice;
