import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import FormAddress from '../FormAddress/FormAddress';
import { CustomForm } from '../../../models/types/CustomForm';
import { FormSectionTitle, SectionWrapper } from '../../Form/Form';
import { SectionTitle } from '../../Typography/Typography';
import {
  StyledFormCheckBox,
  Wrapper,
} from './corporationInformationsForm.styles';
import CorporationNameForm from './CorporationNameForm/CorporationNameForm';
import { CorporationInformationsFormFields } from './corporationInformationsForm.models';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';

interface ICorporationInformationsFormProps extends CustomForm {
  title?: string;
}

const CorporationInformationsForm = ({
  className,
  disabled,
  title,
}: ICorporationInformationsFormProps) => {
  const { t } = useTranslation();
  const formState = useFormState();
  const value =
    formState.values[
      CorporationInformationsFormFields.CorrespondenceAddressSameAsBilling
    ];

  return (
    <Wrapper className={className}>
      {title && <SectionTitle>{title}</SectionTitle>}

      <CorporationNameForm />

      <SectionWrapper>
        <FormSectionTitle>
          {t('headers.billing-address-title')}
        </FormSectionTitle>
        <FormAddress
          disabled={disabled}
          name={CorporationInformationsFormFields.BillingAddress}
        />
      </SectionWrapper>

      <Field
        component={StyledFormCheckBox}
        disabled={disabled}
        inputId="corporation--name-checkbox"
        {...getFieldPropertiesForGroup({
          inputId: 'corporation-informations',
          name: CorporationInformationsFormFields.CorrespondenceAddressSameAsBilling,
        })}
        type="checkbox"
      />

      {!value && (
        <SectionWrapper>
          <FormSectionTitle>
            {t('headers.correspondence-address-title')}
          </FormSectionTitle>
          <FormAddress
            disabled={disabled}
            name={CorporationInformationsFormFields.CorrespondenceAddress}
          />
        </SectionWrapper>
      )}
    </Wrapper>
  );
};

export default CorporationInformationsForm;
