import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../models/types/CustomForm';
import { SectionWrapper } from '../../../../../styledComponents/Form/Form';

import { FormColumn } from '../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import FormDatePicker from '../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { FormRow } from '../../../../../styledComponents/UI/Form/FormRow/FormRow';
import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';

import {
  EditPromoCodeDatesFields,
  EditPromoCodeDatesFormName,
} from './editPromoCodeDatesForm.models';

const EditPromoCodeDatesForm = ({ name, className }: CustomForm) => (
  <SectionWrapper className={className}>
    <FormColumn>
      <FormRow isSubRow>
        <Field
          component={FormDatePicker}
          {...getFieldPropertiesForGroup({
            name: EditPromoCodeDatesFields.ValidFrom,
            groupName: name,
            inputId: EditPromoCodeDatesFormName,
          })}
        />
        <Field
          component={FormDatePicker}
          {...getFieldPropertiesForGroup({
            name: EditPromoCodeDatesFields.ValidTo,
            groupName: name,
            inputId: EditPromoCodeDatesFormName,
          })}
        />
      </FormRow>
    </FormColumn>
  </SectionWrapper>
);

export default EditPromoCodeDatesForm;
