import styled from '@emotion/styled/macro';

export const Header = styled.div`
  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  width: 36.125rem;
  margin-top: 2.5rem;
`;
