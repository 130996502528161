import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../../utils/validations/validateFormData';
import { ICreateKEMembershipPageModel } from '../../../../../../models/interfaces/Membership/KidnapAndExtortion/CreateKEMembershipPageModel';
import { validateFamilyMemberFormSchema } from '../../../../../../styledComponents/Forms/FamilyMember/familyMember.validation';
import { MembershipFamilyMembersFormFields } from '../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';
import { KEMembershipOrganizationDetailsFormFields } from './KEMembershipDetailsForm/keMembershipDetailsForm.models';

export const keMembershipDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [KEMembershipOrganizationDetailsFormFields.EffectiveDate]: {
      errorMessage: t('validation.effective-date-later-expiration-date'),
      type: 'string',
      format: 'date',
      formatMaximum: {
        $data: `1/${KEMembershipOrganizationDetailsFormFields.ExpirationDate}`,
      },
    },
    [MembershipFamilyMembersFormFields.SecondaryMembers]: {
      type: 'array',
      items: validateFamilyMemberFormSchema,
    },
  },
  required: [
    KEMembershipOrganizationDetailsFormFields.MembershipPlanId,
    KEMembershipOrganizationDetailsFormFields.EffectiveDate,
    KEMembershipOrganizationDetailsFormFields.Organization,
    KEMembershipOrganizationDetailsFormFields.SubscriptionType,
  ],
};

export const validateKEMembershipDetailsForm = (
  data: ICreateKEMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, keMembershipDetailsSchema);
