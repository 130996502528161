import styled from '@emotion/styled/macro';

import GroupedSubscriptionsList from '../../../../../styledComponents/SubscriptionsList/GroupedSubscriptionsList';
import { CardListItemWrapper } from '../../../../../styledComponents/UI/CardListItem/cardListItem.styles';

export const Header = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledGroupedSubscriptionsList = styled(GroupedSubscriptionsList)`
  ${CardListItemWrapper} {
    min-width: 10rem;
  }
`;
