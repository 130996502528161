import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type Props = {
  labelOff: string;
  labelOn: string;
  isChecked: boolean;
};

const toggleLabelCommon = css`
  align-items: center;
  border-radius: 0.188rem;
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.938rem;
  pointer-events: none;
  transition: all 0.25s ease;
  width: 50%;
  z-index: 3;
`;

export const ToggleLabel = styled.label<Props>`
  border: 1px solid #b9d3dc;
  box-sizing: border-box;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font: normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  padding: 0.125rem;
  position: relative;

  &:before {
    ${toggleLabelCommon}
    background: ${({ isChecked, theme }) =>
      isChecked ? theme.color.secondary1 : theme.color.white};
    color: ${({ isChecked, theme }) =>
      isChecked ? theme.color.white : theme.color.secondary1};
    content: '${({ labelOn }) => labelOn}';
    font-weight: ${({ isChecked }) => (isChecked ? '700' : '400')};
  }
  &:after {
    ${toggleLabelCommon}
    background: ${({ isChecked, theme }) =>
      isChecked ? theme.color.white : theme.color.secondary1};
    color: ${({ isChecked, theme }) =>
      isChecked ? theme.color.secondary1 : theme.color.white};
    content: '${({ labelOff }) => labelOff}';
    font-weight: ${({ isChecked }) => (isChecked ? '400' : '700')};
  }
`;

export const ToggleCheckbox = styled.input`
  cursor: pointer;
  opacity: 0;
  width: 0;
`;

ToggleCheckbox.defaultProps = {
  type: 'checkbox',
};
