import { FormApi } from 'final-form';
import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ICreateOrganizationPointOfContact } from '../../../../../../models/CreateOrganizationPointOfContact';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import { organizationIdSelector } from '../../../../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_EDITING_PROCESSING,
  ROLES_LOADING_PROCESSING,
} from '../../../../../../store/redux-slices/processes/constants';
import {
  addOrganizationContact,
  IOrganizationContactPayload,
} from '../../../../../../store/saga-slices/organizations';
import { useFormErrorsActions } from '../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateOrganizationPointOfContactForm } from '../../../../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/corpPointOfContactForm.validation';
import { ADD_POINT_OF_CONTACT_MODAL } from './addPointOfContactModal.const';
import { loadRoles } from '../../../../../../store/saga-slices/roles';
import { clearRoles } from '../../../../../../store/redux-slices/roles';
import { useCancellationToken } from '../../../../../../hooks/useTokenCancellation';
import { rolesOptionsSelector } from '../../../../../../store/redux-slices/roles/selectors';
import { ILoadRolesPayload } from '../../../../../../store/saga-slices/roles/models';

export const useAddingContactModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();

  const AddOrganizationContact = useActions(addOrganizationContact);
  const UpdateModalState = useActions(updateModalState);
  const LoadRoles = useActions(loadRoles);
  const ClearRoles = useActions(clearRoles);

  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);

  const organizationId = useSelector(organizationIdSelector);

  const cancellationToken = useCancellationToken();

  const { addErrors, validateErrors } = useFormErrorsActions();

  useEffect(() => {
    const payload: ILoadRolesPayload = {
      cancellationToken,
    };
    LoadRoles(payload);
    return () => {
      ClearRoles();
    };
  }, [LoadRoles, cancellationToken, ClearRoles]);

  const rolesLoading = useProcessing(ROLES_LOADING_PROCESSING);
  const rolesList = useSelector(rolesOptionsSelector);

  const handleSubmit = (
    values: ICreateOrganizationPointOfContact,
    form: FormApi<any, any>,
  ) => {
    if (form.getState().invalid) {
      return;
    }

    const payload: IOrganizationContactPayload = {
      contact: values,
      organizationId,
      success: () => {
        alert.success(t('alerts.poc-create-success'));
        UpdateModalState(ADD_POINT_OF_CONTACT_MODAL);
      },
      error: (err) => {
        addErrors(err.ValidationErrors, values);
        alert.error(t('alerts.poc-create-fail'));
      },
    };

    AddOrganizationContact(payload);
  };

  const onCancelHandler = useCallback(() => {
    UpdateModalState(ADD_POINT_OF_CONTACT_MODAL);
  }, [UpdateModalState]);

  const validateForm = (values: ICreateOrganizationPointOfContact) =>
    validateOrganizationPointOfContactForm(values, validateErrors(values));

  return {
    handleSubmit,
    organizationUpdating,
    validateForm,
    onCancelHandler,
    rolesLoading,
    rolesList,
  };
};
