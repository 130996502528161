import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import { ProductType } from '../../../models/ProductType';
import { useSubscriptionSRRenew } from './srSubscriptionRenewPage.hooks';
import SRSubscriptionAnalyseModal from './SRSubscriptionAnalyseModal/SRSubscriptionAnalyseModal';
import SRSubscriptionRenewPageForm from './SRSubscriptionRenewPageForm/SRSubscriptionRenewPageForm';

export const SRSubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    isOrganizationDataLoading,
    isPricingDataLoading,
    isSuggestionDataLoading,
    loadSubscriptionRenewalSuggestionHandler,
    subscriptionRenewalSuggestion,
    loadDatesForNewSubscriptionHandler,
  } = useSubscriptionSRRenew();

  if (
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading) ||
    isPricingDataLoading
  ) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <SRSubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.renew-subscription', {
              productType: ProductType.SecurityResponse,
              organizationName: organization?.name,
            })}
          />
          <SRSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
