/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */

import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { useNavigateSearch } from '../../hooks/useNavigateSearch';

import { RoutePath } from '../../models/enums/RoutePath';
import { Log } from '../../utils/logger';

const USER_CANCELLATION_ERROR = 'AADB2C90091';

const CallbackPage = () => {
  const navigate = useNavigateSearch();
  const { instance } = useMsal();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(() => {
        navigate(RoutePath.Home);
      })
      .catch((e) => {
        const errorCode = e?.errorMessage?.split(':')[0];
        navigate(RoutePath.Home);
        if (errorCode === USER_CANCELLATION_ERROR) {
          return;
        }
        Log.errorException(e);
        console.error(e);
        instance.logoutRedirect();
      });
  }, [instance, navigate]);

  return <></>;
};

export default CallbackPage;
