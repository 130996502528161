import { createSelector } from 'reselect';

import { IOrganizationPointOfContact } from 'models/OrganizationPointOfContact';

import { IGGPointOfContact } from 'models/GGPointOfContact';

import { RootState } from '../index';
import { IOrganizations } from '.';
import { IOrganizationList } from '../../../models/OrganizationList';
import { IOrganization } from '../../../models/Organization';
import { IAAOrganizationList } from '../../../models/AAOrganizationList';
import { OrganizationType } from '../../../models/enums/OrganizationType';

export const organizationsSelector = (state: RootState) => state.organizations;

export const organizationsListSelector = createSelector(
  organizationsSelector,
  (organizations: IOrganizations): IOrganizationList[] => organizations.items,
);

export const airAmbulanceOrganizationsListSelector = createSelector(
  organizationsSelector,
  (organizations: IOrganizations): IAAOrganizationList[] =>
    organizations.airAmbulanceOrganizations,
);

export const organizationsTotalCountSelector = createSelector(
  organizationsSelector,
  (organizations: IOrganizations): number => organizations.itemsCount,
);

export const organizationSelector = createSelector(
  organizationsSelector,
  (organizations: IOrganizations): IOrganization =>
    organizations.currentOrganization,
);

export const organizationNameSelector = createSelector(
  organizationSelector,
  (organization: IOrganization): string => organization && organization.name,
);

export const organizationTypeSelector = createSelector(
  organizationSelector,
  (organization: IOrganization): OrganizationType =>
    organization && organization.organizationType,
);

export const organizationIdSelector = createSelector(
  organizationSelector,
  (organization: IOrganization): string => organization && organization.id,
);

export const organizationPointOfContactsSelector = createSelector(
  organizationSelector,
  (organization: IOrganization): IOrganizationPointOfContact[] =>
    organization && organization.organizationPointOfContacts,
);

export const organizationGGPointOfContactsSelector = createSelector(
  organizationSelector,
  (organization: IOrganization): IGGPointOfContact[] =>
    organization && organization.ggPointOfContacts,
);

export const organizationPointOfContactByIdSelector = (contactId: string) =>
  createSelector(
    organizationPointOfContactsSelector,
    (contacts: IOrganizationPointOfContact[]): IOrganizationPointOfContact =>
      contactId &&
      contacts &&
      contacts.length > 0 &&
      contacts.find((x) => x.id === contactId),
  );

export const organizationGGPointOfContactByIdSelector = (contactId: string) =>
  createSelector(
    organizationGGPointOfContactsSelector,
    (contacts: IGGPointOfContact[]): IGGPointOfContact =>
      contactId &&
      contacts &&
      contacts.length > 0 &&
      contacts.find((x) => x.id === contactId),
  );

export const organizationBillingAddressSelector = createSelector(
  organizationSelector,
  (organization: IOrganization) => organization && organization.billingAddress,
);

export const organizationCorrespondenceAddressSelector = createSelector(
  organizationSelector,
  (organization: IOrganization) =>
    organization && organization.correspondenceAddress,
);
