import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICreatePromoCodeSuggestion } from '../../../models/interfaces/CreatePromoCodeSuggestion';

import { IPageListModel } from '../../../models/PageListModel';
import { IPromoCodeItem } from '../../../models/PromoCodeItem';
import { IPromoCodeOverview } from '../../../models/PromoCodeOverview';

export interface IPromoCodesState {
  promoCodes: IPageListModel<IPromoCodeItem>;
  promoCode: IPromoCodeOverview;
  promoCodeSuggestion: ICreatePromoCodeSuggestion;
}

const initialState: IPromoCodesState = {
  promoCodes: null,
  promoCode: null,
  promoCodeSuggestion: null,
};

const promoCodesSlice = createSlice({
  name: 'promo-codes',
  initialState,
  reducers: {
    setPromoCodes: (
      state,
      { payload }: PayloadAction<IPageListModel<IPromoCodeItem>>,
    ) => {
      state.promoCodes = payload;
    },
    setPromoCode: (state, { payload }: PayloadAction<IPromoCodeOverview>) => {
      state.promoCode = payload;
    },
    setPromoCodeSuggestion: (
      state,
      { payload }: PayloadAction<ICreatePromoCodeSuggestion>,
    ) => {
      state.promoCodeSuggestion = payload;
    },
    clearPromoCodes: (state) => {
      state.promoCodes = initialState.promoCodes;
    },
    clearPromoCode: (state) => {
      state.promoCode = initialState.promoCode;
    },
    clearPromoCodeSuggestion: (state) => {
      state.promoCodeSuggestion = initialState.promoCodeSuggestion;
    },
  },
});

export const {
  setPromoCodes,
  clearPromoCodes,
  clearPromoCode,
  setPromoCode,
  clearPromoCodeSuggestion,
  setPromoCodeSuggestion,
} = promoCodesSlice.actions;
export default promoCodesSlice.reducer;
export const promoCodesReducerName = promoCodesSlice.name;
