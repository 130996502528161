import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../../../styledComponents/Typography/Typography';
import ItemsList from '../../../styledComponents/UI/ItemsList/ItemsList';
import { Header, Wrapper } from '../Common/memberOverviewMembers.styles';
import MemberOverviewActiveMemberDetails from './MemberOverviewActiveMemberDetails/MemberOverviewActiveMemberDetails';
import Button from '../../../styledComponents/UI/Button/Button';
import { useMemberOverviewActiveMembers } from './memberOverviewActiveMembers.hooks';
import MemberOverviewActiveMemberSummary from './MemberOverviewActiveMemberSummary/MemberOverviewActiveMemberSummary';
import { canAddMembershipPermissions } from '../../../config/accessPolicies/accessPolicies';
import CollapseContent from '../../../styledComponents/UI/CollapseContent/CollapseContent';

interface IMemberOverviewActiveMembersProps {
  onAddMembershipClick?: () => void;
}

const MemberOverviewActiveMembers = ({
  onAddMembershipClick,
}: IMemberOverviewActiveMembersProps) => {
  const { t } = useTranslation();

  const { activePersonMemberships } = useMemberOverviewActiveMembers();

  return (
    <Wrapper>
      <Header>
        <SectionTitle>{t('headers.active-memberships')}</SectionTitle>
        <Button
          styleType="lighter-blue"
          permissions={canAddMembershipPermissions}
          onClick={onAddMembershipClick}
        >
          {t('buttons.add-membership')}
        </Button>
      </Header>

      <CollapseContent
        title={t('headers.active')}
        isHidden={!activePersonMemberships?.length}
        defaultIsOpen
      >
        <ItemsList
          itemSummary={(data) => (
            <MemberOverviewActiveMemberSummary
              data={data}
              productType={data.productSubscription}
            />
          )}
          items={activePersonMemberships}
          itemDetails={(data) => (
            <MemberOverviewActiveMemberDetails
              productType={data.productSubscription}
              membershipId={data.membershipId}
              canBeRemoved={data.metadata.canDelete}
            />
          )}
        />
      </CollapseContent>
    </Wrapper>
  );
};

export default MemberOverviewActiveMembers;
