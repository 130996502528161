import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { ISRSubscriptionsState } from '.';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { ISROrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOverview';
import { IEditSROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/EditSROrganizationSubscriptionModel';
import { ISRSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SRSubscriptionRenewalSuggestionModel';

export const srSubscriptionsSelector = (state: RootState) =>
  state.srSubscriptions;

export const srSubscriptionOverviewSelector = createSelector(
  srSubscriptionsSelector,
  (
    subscriptionState: ISRSubscriptionsState,
  ): ISROrganizationSubscriptionOverview =>
    subscriptionState.currentSubscriptionOverview,
);

export const srSubscriptionDatesSelector = createSelector(
  srSubscriptionsSelector,
  (subscriptionState: ISRSubscriptionsState): ISubscriptionDates =>
    subscriptionState.subscriptionDates,
);

export const editingSRSubscriptionDataSelector = createSelector(
  srSubscriptionsSelector,
  (
    subscriptionState: ISRSubscriptionsState,
  ): IEditSROrganizationSubscriptionModel =>
    subscriptionState.currentSubscriptionEditing,
);

export const srSubscriptionRenewalSuggestionSelector = createSelector(
  srSubscriptionsSelector,
  (
    subscriptionState: ISRSubscriptionsState,
  ): ISRSubscriptionRenewalSuggestionModel =>
    subscriptionState.subscriptionRenewalSuggestion,
);

export const srSubscriptionRenewalSuggestionPlansSelector = createSelector(
  srSubscriptionRenewalSuggestionSelector,
  (
    subscriptionRenewalSuggestion: ISRSubscriptionRenewalSuggestionModel,
  ): IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[] =>
    subscriptionRenewalSuggestion && subscriptionRenewalSuggestion.plans,
);

export const srSubscriptionRenewalAnalyseSelector = createSelector(
  srSubscriptionsSelector,
  (
    subscriptionState: ISRSubscriptionsState,
  ): IRenewOrganizationSubscriptionAnalyseModel =>
    subscriptionState.subscriptionRenewalAnalyse,
);
