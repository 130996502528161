import { FieldRenderProps } from 'react-final-form';

import { PricingRenderer } from '../../CellRenderers';
import { ICellOptions } from '../../cellRenderers.models';
import { StyledFormFieldWrapper } from '../../cellRenderers.styles';
import { IPricingRenderer } from '../../PricingRenderer/PricingRenderer';
import TableCell from '../../TableCell/TableCell';

export interface IFormPricingRenderer
  extends FieldRenderProps<number>,
    IPricingRenderer {
  options?: ICellOptions;
}

export const FormPricingRenderer = ({
  options,
  input,
  meta: { error, touched },
  ...props
}: IFormPricingRenderer) => (
  <TableCell {...options}>
    <StyledFormFieldWrapper>
      <PricingRenderer
        {...props}
        isError={touched && !!error}
        onChange={(value) => input.onChange(+value)}
        options={options}
        price={input.value}
      />
    </StyledFormFieldWrapper>
  </TableCell>
);
