import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import GGPointOfContactForm from './GGPointOfContactForm/GGPointOfContactForm';
import { IEmployee } from '../../../models/Employee';
import { SectionTitle } from '../../Typography/Typography';
import { StyledButton, Wrapper } from './ggPointOfContactsForm.styles';
import { CustomForm } from '../../../models/types/CustomForm';
import { SelectOption } from '../../UI/MainSelect/mainSelect.types';

interface IPointOfContactProps extends CustomForm {
  employeeList?: IEmployee[];
  ggRolesList?: SelectOption[];
  title?: string;
}

const GGPointOfContactsForm = ({
  className,
  disabled,
  name,
  employeeList,
  ggRolesList,
  title,
}: IPointOfContactProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            {fields.map((fieldName, index) => (
              <div key={fieldName}>
                <GGPointOfContactForm
                  contactNumber={index + 1}
                  disabled={disabled}
                  name={fieldName}
                  items={employeeList}
                  ggRolesList={ggRolesList}
                  onRemoveContact={
                    index > 0 ? () => fields.remove(index) : null
                  }
                />
              </div>
            ))}
            <StyledButton
              disabled={disabled}
              onClick={() => fields.push({})}
              styleType="link"
            >
              {t('buttons.add-contact')}
            </StyledButton>
          </div>
        )}
      </FieldArray>
    </Wrapper>
  );
};

export default GGPointOfContactsForm;
