import { GoogleAddressComponentType } from '../models/GoogleAddressComponentType';
import { createEmptyAddress, IAddress } from '../models/Address';

export const prepareAddressObject = (
  googleAddress: google.maps.GeocoderResult,
) => {
  const address: IAddress = createEmptyAddress();

  if (
    !googleAddress ||
    !googleAddress.address_components ||
    googleAddress.address_components.length === 0
  ) {
    return address;
  }

  googleAddress.address_components.forEach((element) => {
    switch (element.types[0] as GoogleAddressComponentType) {
      case 'country':
        address.country = element.long_name;
        break;
      case 'administrative_area_level_1':
        address.stateRegion = element.long_name;
        break;
      case 'locality':
        address.city = element.long_name;
        break;
      case 'postal_code':
        address.zipCode += element.long_name.replaceAll('-', '');
        break;
      case 'route':
        address.addressLine1 =
          element.long_name +
          (address.addressLine1 === '' ? '' : ', ') +
          address.addressLine1;
        break;
      case 'street_number':
        address.addressLine1 +=
          (address.addressLine1 === '' ? '' : ', ') + element.long_name;
        break;
    }
  });

  if (googleAddress.geometry) {
    address.latitude = googleAddress.geometry.location.lat();
    address.longitude = googleAddress.geometry.location.lng();
  }

  return address;
};
