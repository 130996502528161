import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../../styledComponents/ConfirmModal/ConfirmModal';
import { DELETE_CREDIT_CARD_MODAL_NAME } from './deleteCreditCardModal.const';
import { useDeleteCreditCardModal } from './deleteCreditCardModal.hooks';
import { StyledCreditCardTypeNumber } from './deleteCreditCardModal.styles';
import { Description } from '../Common/memberOverviewModals.styles';

type DeleteCreditCardModalProps = {
  onCreditCardDeleted?: () => void;
};

const DeleteCreditCardModal = ({
  onCreditCardDeleted,
}: DeleteCreditCardModalProps) => {
  const { t } = useTranslation();
  const { creditCard, onBackHandler, isDeleting, deleteCreditCardHandler } =
    useDeleteCreditCardModal({
      onCreditCardDeleted,
    });
  return (
    <ConfirmModal
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: isDeleting,
        },
        {
          action: deleteCreditCardHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: isDeleting,
        },
      ]}
      disabled={isDeleting}
      name={DELETE_CREDIT_CARD_MODAL_NAME}
      title={t('headers.delete-credit-card')}
    >
      <Description>
        {t('phrase.action-cannot-be-undone-confirm-delete')}
      </Description>
      <StyledCreditCardTypeNumber
        isBoldNumber
        creditCardType={creditCard?.creditCardType}
        creditCardNumber={creditCard?.creditCardNumber}
      />
    </ConfirmModal>
  );
};

export default DeleteCreditCardModal;
