import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLeftContainer,
  StyledRightContainer,
  DetailsWrapper,
  StyledDeleteButton,
} from '../../Common/memberOverviewMembers.styles';

import {
  canEditDOCMembershipsOptionsPermissions,
  canRemoveDOCMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { ProductType } from '../../../../models/ProductType';

import { DutyOfCareMembershipOptions } from '../../../../models/enums/DutyOfCareMembershipOptions';
import { useDOCMembershipDetails } from '../Common/docMembershipOverview.hooks';
import { docOverviewTablePermissions } from '../Common/docMembershipOverview.const';
import DOCMembershipMemberTable from '../DOCMembershipMemberTable/DOCMembershipMemberTable';
import DeleteDOCMembershipModal from '../Modals/DeleteDOCMembershipModal/DeleteDOCMembershipModal';
import EditDOCMembershipOptionsModal from '../Modals/EditDOCMembershipOptionsModal/EditDOCMembershipOptionsModal';
import ResendDOCWelcomeEmailModal from '../Modals/ResendDOCWelcomeEmailModal/ResendDOCWelcomeEmailModal';

interface IDOCMembershipActiveDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const DOCMembershipActiveDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: IDOCMembershipActiveDetailsProps) => {
  const { t } = useTranslation();

  const {
    dataLoading,
    membershipDetails,
    changeRenewFlagHandler,
    resendWelcomeEmailHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useDOCMembershipDetails(membershipId, productType);

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }
  return (
    <>
      <DeleteDOCMembershipModal id={membershipId} />
      <EditDOCMembershipOptionsModal id={membershipId} />
      <ResendDOCWelcomeEmailModal id={membershipId} />
      <DetailsWrapper>
        <StyledLeftContainer>
          <DOCMembershipMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            onResendEmailClick={resendWelcomeEmailHandler}
            onRowClick={onRowClickHandler}
            isExpiredMembershipsTable={false}
            permissions={docOverviewTablePermissions}
          />
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditDOCMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: DutyOfCareMembershipOptions.SendWelcomeEmail },
              { value: DutyOfCareMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            canBeRenewed={false}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveDOCMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default DOCMembershipActiveDetails;
