import { useCallback } from 'react';

import { useFilters } from '../../../hooks/useFilters';
import { SelectOption } from '../../../models/SelectOption';
import { PromoCodesListFiltersType } from './promoCodesFilters.models';

export const usePromoCodesFilters = () => {
  const { addFilter, filters } = useFilters<PromoCodesListFiltersType>();

  const onFilterChangeHandler = useCallback(
    (evt: any, filterType: PromoCodesListFiltersType) => {
      switch (filterType) {
        case 'search':
          addFilter('search', evt.target.value);
          return;
        case 'status':
          addFilter(
            'status',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case 'type':
          addFilter('type', evt ? evt.map((x: SelectOption) => x.value) : null);
          return;
        case 'product':
          addFilter(
            'product',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
      }
    },
    [addFilter],
  );

  return {
    filters,
    onFilterChangeHandler,
  };
};
