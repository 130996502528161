import { ProductType } from '../ProductType';

export enum BasicProductOptions {
  General = 'General',
}

export type AvailableProduct = BasicProductOptions | ProductType;

export type AvailableProductsType = Partial<Record<AvailableProduct, string>>;

export type AvailableProductPermissionsType = Partial<
  Record<AvailableProduct, string[]>
>;
