import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { personExpiredMembershipsSelector } from '../../../store/redux-slices/people/selectors';
import { clearMembershipsDetails } from '../../../store/saga-slices/membership/common';

export const useMemberOverviewExpiredMembers = () => {
  const ClearMembershipDetails = useActions(clearMembershipsDetails);

  const expiredPersonMemberships = useSelector(
    personExpiredMembershipsSelector,
  );

  useEffect(() => () => ClearMembershipDetails(), [ClearMembershipDetails]);

  return { expiredPersonMemberships };
};
