import { ToolsWrapper } from '../../../SimplePage/Tools';
import MembersGeneralListFilters from '../MembersGeneralListFilters/MembersGeneralListFilters';

const MembersGeneralListTools = () => (
  <ToolsWrapper>
    <MembersGeneralListFilters />
  </ToolsWrapper>
);

export default MembersGeneralListTools;
