import { t } from 'i18next';

import { OrganizationContractType } from '../../../../models/enums/ContractType';

import { SubscriptionOptionsType } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';
import { SecurityResponseSubscriptionOptions } from '../../../../models/enums/SecurityResponseSubscriptionOptions';

export const srSubscriptionOptions = (
  contractType?: OrganizationContractType,
): SubscriptionOptionsType[] =>
  contractType === OrganizationContractType.Multiple
    ? [
        {
          name: SecurityResponseSubscriptionOptions.AutoRenewal,
          label: t('fields.autoRenewSubscription.label'),
        },
      ]
    : [];
