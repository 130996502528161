import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { Description } from '../../../pages/MemberOverviewPage/Modals/Common/memberOverviewModals.styles';
import { DELETE_CONTRACT_MODAL_NAME } from './deleteContractModal.const';
import { useDeleteContractModal } from './deleteContractModal.hooks';
import { StyledName } from './deleteContractModal.styles';

interface IDeleteContractModalProps {
  onContractDeleted?: () => void;
}

const DeleteContractModal = ({
  onContractDeleted,
}: IDeleteContractModalProps) => {
  const { t } = useTranslation();
  const { onBackHandler, contractName, handleSubmit, isProcessing } =
    useDeleteContractModal({
      onContractDeleted,
    });

  return (
    <ConfirmModal
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: isProcessing,
        },
        {
          action: handleSubmit,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing,
        },
      ]}
      name={DELETE_CONTRACT_MODAL_NAME}
      disabled={isProcessing}
      title={t('headers.delete-contract')}
    >
      <Description>{t('phrase.confirm-delete-contract')}</Description>
      <StyledName>{contractName}</StyledName>
      <Description>{t('phrase.together-with-all-subscription')}</Description>
    </ConfirmModal>
  );
};

export default DeleteContractModal;
