/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';

import { IRenewalContractRecord } from '../../../../models/interfaces/RenewalFilters/RenewalContractRecord';

import { CustomComponent } from '../../../../models/types/CustomComponent';
import { RenewalContractsResult } from '../../../../models/types/RenewalFilters/RenewalContractsResult';

import CollapseContent from '../../../../styledComponents/UI/CollapseContent/CollapseContent';
import { getFormattedDate } from '../../../../utils/dateHelpers';
import NotFoundData from '../../Common/RenewalsTables/NotFoundData/NotFoundData';
import { TablesWrapper } from '../../Common/RenewalsTables/renewalsTables.styles';
import RenewalsContractsTable from './RenewalsContractsTable/RenewalsContractsTable';

type RenewalsContractsTablesProps = CustomComponent & {
  data: RenewalContractsResult;
  dataLoading?: boolean;
  onCellClick?: (
    row: IRenewalContractRecord,
    key?: keyof IRenewalContractRecord,
  ) => void;
  onRowClick?: (row: IRenewalContractRecord) => void;
};

const defaultNumberOfOpenItems = 3;

const RenewalsContractsTables = ({
  data,
  dataLoading,
  className,
  onCellClick,
  onRowClick,
}: RenewalsContractsTablesProps) => {
  const { t } = useTranslation();

  if (!data?.length) {
    return (
      <TablesWrapper className={className}>
        <NotFoundData label={t('phrase.no-contracts-found')} />
      </TablesWrapper>
    );
  }

  return (
    <TablesWrapper className={className}>
      {data?.map((record, index) => (
        <CollapseContent
          key={`${record.month}/${record.year}${index}`}
          title={getFormattedDate(record.month, record.year)}
          defaultIsOpen={index < defaultNumberOfOpenItems}
        >
          <RenewalsContractsTable
            dataLoading={dataLoading}
            data={record.records}
            onCellClick={onCellClick}
            onRowClick={onRowClick}
          />
        </CollapseContent>
      ))}
    </TablesWrapper>
  );
};

export default RenewalsContractsTables;
