import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import RenewSubscriptionItemForm from '../../RenewSubscriptionItemForm/RenewSubscriptionItemForm';
import { RenewSubscriptionItemBaseProps } from '../../RenewSubscriptionItemForm/renewSubscriptionItemForm.models';
import { Wrapper } from './airAmbulanceRenewSubscription.styles';
import { AARenewSubscriptionDetails } from './AARenewSubscriptionDetails/AARenewSubscriptionDetails';
import { AARenewSubscriptionPricing } from './AARenewSubscriptionPricing/AARenewSubscriptionPricing';
import { AASubscriptionOptions } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';

const AirAmbulanceRenewSubscriptionForm = ({
  disabled,
  className,
}: RenewSubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const productType = ProductType.AirAmbulance;
  return (
    <RenewSubscriptionItemForm collapsible={false} productType={productType}>
      <Wrapper className={className}>
        <AARenewSubscriptionDetails disabled={disabled} />
        <AARenewSubscriptionPricing
          disabled={disabled}
          productType={productType}
        />
        <SubscriptionOptions
          subscriptionOptions={AASubscriptionOptions}
          layout="column"
          disabled={disabled}
          title={t('headers.options')}
        />
      </Wrapper>
    </RenewSubscriptionItemForm>
  );
};

export default AirAmbulanceRenewSubscriptionForm;
