import Tab from '@mui/material/Tab';

import { SyntheticEvent } from 'react';

import { ITabNavigation, ITabNavigationItem } from '../tabNavigation.models';
import { StyledTabs } from './tabNavigationHeader.styles';

type TabNavigationHeaderProps = ITabNavigation & {
  handleValueChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => void;
  value: string;
};

const TabNavigationHeader = ({
  className,
  handleValueChange,
  tabs,
  value,
}: TabNavigationHeaderProps) => (
  <div className={className}>
    <StyledTabs onChange={handleValueChange} textColor="primary" value={value}>
      {tabs.map((tab: ITabNavigationItem) => (
        <Tab
          key={tab.label + tab.url}
          label={tab.label}
          value={tab.url || tab.label}
        />
      ))}
    </StyledTabs>
  </div>
);

export default TabNavigationHeader;
