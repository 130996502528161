import { AvailableQueryParams } from '../../../../../styledComponents/MaterialTable/materialTable.models';
import { DOCMembersListFilters } from '../../../../../styledComponents/ProductMembersList/DOCMembersList/DOCMembersFilters/docMembersFilters.models';

export const pageFilters = [
  DOCMembersListFilters.Search,
  DOCMembersListFilters.Package,
  DOCMembersListFilters.RenewStatus,
  DOCMembersListFilters.SubscriptionId,
  DOCMembersListFilters.OversubscribedOnly,
];

export const RequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];
