import { IAddress } from '../../../models/Address';

import { prepareAddressForView } from '../../../utils/addressHelpers';
import { Value } from '../OverviewProperty/overviewProperty.styles';
import { Wrapper } from './overviewAddress.styles';

interface IOverviewAddressProps {
  address: IAddress;
  className?: string;
}

const OverviewAddress = ({ address, className }: IOverviewAddressProps) => {
  const addressLines = address && prepareAddressForView(address);
  return (
    <Wrapper className={className}>
      {addressLines
        ? addressLines.map(
            (addressPart, index) =>
              addressPart && (
                // eslint-disable-next-line react/no-array-index-key
                <Value key={index}>
                  {addressPart + (index !== addressLines.length - 1 ? ',' : '')}
                </Value>
              ),
          )
        : '-'}
    </Wrapper>
  );
};

export default OverviewAddress;
