import { TLPricingTierDefinition } from '../../../../../models/interfaces/Subscription/TravelLicense/TLPricingDefinition';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { PriceValue, SeatsAmountValue } from './tlPricingTooltip.styles';

type TLPricingRowProps = CustomComponent & {
  data: TLPricingTierDefinition;
};

const TLPricingRow = ({ data }: TLPricingRowProps) => (
  <>
    <SeatsAmountValue>{`${data.minSeats} - ${data.maxSeats}`}</SeatsAmountValue>
    <PriceValue>{`$${data.price}`}</PriceValue>
  </>
);

export default TLPricingRow;
