import { useTranslation } from 'react-i18next';

import { KidnapAndExtortionSubscriptionOptions } from '../../../../../../models/enums/KidnapAndExtortionSubscriptionOptions';

import OverviewOptions from '../../../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLine,
  Wrapper,
} from './keSubscriptionOverviewInformation.styles';
import {
  canRenewKESubscriptionPermissions,
  canUpdateKESubscriptionsDetailsPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';
import KEOverviewDetails from './KEOverviewDetails/KEOverviewDetails';
import { IKEOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionOverview';
import { useKESubscriptionOverviewInformation } from './keSubscriptionOverviewInformation.hooks';

interface IProductSubscriptionOverviewInformationProps {
  onEditOptionsClick?: () => void;
  onEditRenewalStatus?: () => void;
  subscriptionOverview: IKEOrganizationSubscriptionOverview;
  canEditOptions?: boolean;
  canRenew?: boolean;
}
const KEPSubscriptionOverviewInformation = ({
  onEditOptionsClick,
  onEditRenewalStatus,
  subscriptionOverview,
  canEditOptions,
  canRenew,
}: IProductSubscriptionOverviewInformationProps) => {
  const { t } = useTranslation();
  const { renewClickHandler } = useKESubscriptionOverviewInformation();

  return (
    <Wrapper>
      <KEOverviewDetails
        data={subscriptionOverview}
        canRenewMembershipPermissions={canRenewKESubscriptionPermissions}
        onRenewClick={canRenew && renewClickHandler}
        onEditRenewalStatus={onEditRenewalStatus}
      />
      <StyledLine />
      <OverviewOptions
        options={[
          {
            value: KidnapAndExtortionSubscriptionOptions.AutoRenewal,
            label: t('phrase.auto-renew-product-subscription'),
          },
        ]}
        data={subscriptionOverview}
        onEditOptionsClick={canEditOptions && onEditOptionsClick}
        canEditOptionsPermissions={canUpdateKESubscriptionsDetailsPermissions}
      />
    </Wrapper>
  );
};

export default KEPSubscriptionOverviewInformation;
