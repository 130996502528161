import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { RoutePath } from '../../../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import { organizationSelector } from '../../../../../../store/redux-slices/organizations/selectors';
import { ORGANIZATION_EDITING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import {
  deleteOrganization,
  IDeleteOrganizationPayload,
} from '../../../../../../store/saga-slices/organizations';
import { DELETE_ORGANIZATION_MODAL_NAME } from './deleteOrganizationModal.const';

export const useDeleteOrganizationModal = () => {
  const alert = useAlert();
  const navigate = useNavigateSearch();
  const { t } = useTranslation();
  const organization = useSelector(organizationSelector);
  const UpdateModalState = useActions(updateModalState);
  const DeleteOrganization = useActions(deleteOrganization);
  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);

  const deleteOrganizationHandler = () => {
    const payload: IDeleteOrganizationPayload = {
      organizationId: organization.id,
      success: () => {
        alert.success(t('alerts.organization-delete-success'));
        UpdateModalState(DELETE_ORGANIZATION_MODAL_NAME);
        navigate(RoutePath.CorporateClientsPage);
      },
      error: (err) => {
        alert.error(t('alerts.organization-delete-fail'));
      },
    };

    DeleteOrganization(payload);
  };

  const onCancelHandler = () =>
    UpdateModalState(DELETE_ORGANIZATION_MODAL_NAME);

  return {
    deleteOrganizationHandler,
    organizationUpdating,
    onCancelHandler,
    organization,
  };
};
