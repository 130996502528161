import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';

import { ICreatePrimaryMemberModel } from '../../../../models/CreatePrimaryMemberModel';
import { ProductType } from '../../../../models/ProductType';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';

export const analyzePrimaryMember = (
  data: ICreatePrimaryMemberModel,
  productType?: ProductType,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ProductType[]>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'productType',
      value: productType,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.post(`api/memberships/primarymemberanalysis${params}`, data, {
    baseURL: config.membershipSagaApi.baseUrl,
    signal: cancellationToken,
  });
};
