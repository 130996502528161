import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ExpirationNotificationEnum } from '../../../../../../../models/ExpirationNotificationEnum';
import { ReactComponent as IconDateExpired } from '../../../../../../../assets/icons/date-expired-icon.svg';
import { transientOptions } from '../../../../../../../utils/transientOptions';

type WrapperProps = {
  isExpired?: boolean;
};

export const DateExpiredWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  min-width: 8rem;
  ${({ isExpired, theme }) =>
    isExpired &&
    css`
      color: ${theme.color.dark2};
    `}
`;

type ClockIconProps = {
  $expirationNotification?: ExpirationNotificationEnum;
};

export const ClockIcon = styled(
  IconDateExpired,
  transientOptions,
)<ClockIconProps>`
  fill: ${({ theme }) => theme.color.dark2};

  ${({ $expirationNotification, theme }) => {
    switch ($expirationNotification) {
      case ExpirationNotificationEnum.ExpiresWithin30Days:
        return css`
          fill: ${theme.color.error};
        `;
      case ExpirationNotificationEnum.ExpiresWithin60Days:
        return css`
          fill: ${theme.color.warning};
        `;
      default:
        return css`
          // display: none;
        `;
    }
  }}
`;
