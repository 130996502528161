import { useTabNavigation } from './tabNavigation.hooks';
import { ITabNavigation } from './tabNavigation.models';
import TabNavigationContent from './TabNavigationContent/TabNavigationContent';
import TabNavigationHeader from './TabNavigationHeader/TabNavigationHeader';

const TabNavigation = ({
  className,
  routeMode,
  tabs,
  children,
}: ITabNavigation) => {
  const { handleValueChange, value, filteredTab } = useTabNavigation({
    routeMode,
    inputTabs: tabs,
  });
  return (
    <>
      <TabNavigationHeader
        handleValueChange={handleValueChange}
        className={className}
        tabs={filteredTab}
        value={value}
      />
      <TabNavigationContent
        value={value}
        tabs={filteredTab}
        routeMode={routeMode}
      >
        {children}
      </TabNavigationContent>
    </>
  );
};

export default TabNavigation;
