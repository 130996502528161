import {
  canAddDOCMemberPermissions,
  canAddSeatsDOCSubscriptionPermissions,
  canEditDOCSubscriptionPermissions,
  canRemoveDOCSubscriptionPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

export const docSubscriptionOverviewPermissions: ProductSubscriptionActionsPermissions =
  {
    canAddMemberPermissions: canAddDOCMemberPermissions,
    canAddSeatsSubscriptionPermissions: canAddSeatsDOCSubscriptionPermissions,
    canEditSubscriptionPermissions: canEditDOCSubscriptionPermissions,
    canRemoveSubscriptionPermissions: canRemoveDOCSubscriptionPermissions,
  };
