import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING,
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_DELETE_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
  SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  deleteKESubscriptionRequest,
  editKESubscriptionRenewalStatusRequest,
  extendKESubscriptionRequest,
  getDatesForNewKESubscription,
  getKESubscription,
  getKESubscriptionExtensionSummary,
  getKESubscriptionRenewalAnalyseRequest,
  getKESubscriptionRenewalSuggestion,
  getKESubscriptionSuggestion,
  getKESubscriptionSummary,
  renewKESubscriptionRequest,
  sendKESubscriptionRenewalMembershipsRequest,
  updateKESubscriptionDetails,
  updateKESubscriptionRequest,
} from './api';

import {
  IDeleteKESubscriptionPayload,
  IEditKESubscriptionOptionsPayload,
  IEditKESubscriptionRenewalStatusPayload,
  IExtendKESubscriptionPayload,
  ILoadDatesForNewKESubscriptionPayload,
  ILoadEditingKESubscriptionPayload,
  ILoadKESubscriptionExtensionSummary,
  ILoadKESubscriptionOverviewPayload,
  ILoadKESubscriptionRenewalAnalysePayload,
  ILoadKESubscriptionRenewalSuggestionPayload,
  ILoadKESubscriptionSummary,
  IRenewKESubscriptionPayload,
  ISendKESubscriptionRenewalMembershipsRequest,
  IUpdateKESubscriptionPayload,
} from './models';
import { IKEOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionOverview';
import {
  clearKESubscriptionRenewalAnalyse,
  setKECurrentEditingSubscription,
  setKESubscriptionDates,
  setKESubscriptionOverview,
  setKESubscriptionRenewalAnalyse,
  setKESubscriptionRenewalSuggestion,
} from '../../../redux-slices/subscriptions/kidnapAndExtortion';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import {
  setDeletedSubscriptionId,
  setSubscriptionExtensionSummary,
  setSubscriptionSummary,
  updateSubscription,
} from '../../../redux-slices/subscriptions/common';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IKESubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KESubscriptionRenewalSuggestionModel';
import { updateRenewalStatusSubscriptions } from '../../../redux-slices/renewalFilter';

const keSubscriptionsSlice = createSliceSaga({
  name: 'ke-subscriptions-saga',
  caseSagas: {
    *loadKESubscriptionOverview({
      payload: { error, organizationId, subscriptionId, success },
    }: PayloadAction<ILoadKESubscriptionOverviewPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
        const response: AxiosResponse<IKEOrganizationSubscriptionOverview> =
          yield* call(getKESubscription, organizationId, subscriptionId);

        yield put(setKESubscriptionOverview(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
      }
    },

    *loadDatesForNewKESubscription({
      payload: { error, organizationId, contractId, success },
    }: PayloadAction<ILoadDatesForNewKESubscriptionPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING),
        );
        const response: AxiosResponse<ISubscriptionDates> = yield* call(
          getDatesForNewKESubscription,
          organizationId,
          contractId,
        );

        const parsedDates: ISubscriptionDates = {
          ...response.data,
          effectiveDateFrom: parseDate(response.data.effectiveDateFrom),
          effectiveDateTo: parseDate(response.data.effectiveDateTo),
        };
        yield put(setKESubscriptionDates(parsedDates));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING),
        );
      }
    },
    *loadEditingKESubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadEditingKESubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
        const response = yield* call(
          getKESubscriptionSuggestion,
          organizationId,
          subscriptionId,
          cancellationToken,
        );

        yield put(setKECurrentEditingSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
      }
    },
    *loadKESubscriptionRenewalSuggestion({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadKESubscriptionRenewalSuggestionPayload>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IKESubscriptionRenewalSuggestionModel> =
          yield* call(
            getKESubscriptionRenewalSuggestion,
            organizationId,
            subscriptionId,
          );

        yield put(setKESubscriptionRenewalSuggestion(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
      }
    },
    *loadKESubscriptionSummary({
      payload: { data, error, organizationId, success },
    }: PayloadAction<ILoadKESubscriptionSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<ISubscriptionSummaryModel> = yield* call(
          getKESubscriptionSummary,
          organizationId,
          data,
        );
        const summaryResponse = {
          ...response.data,
        };
        yield put(setSubscriptionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *updateProductKESubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        data,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateKESubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
        yield call(
          updateKESubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
      }
    },
    *deleteProductKESubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteKESubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
        yield* call(
          deleteKESubscriptionRequest,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield putResolve(setDeletedSubscriptionId([subscriptionId]));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
      }
    },
    *editKESubscriptionOptions({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IEditKESubscriptionOptionsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          updateKESubscriptionDetails,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *extendKESubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IExtendKESubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          extendKESubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *loadKESubscriptionExtensionSummary({
      payload: {
        data,
        error,
        organizationId,
        subscriptionId,
        productType,
        success,
      },
    }: PayloadAction<ILoadKESubscriptionExtensionSummary>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IOrganizationSubscriptionExtensionSummaryModel> =
          yield* call(
            getKESubscriptionExtensionSummary,
            organizationId,
            subscriptionId,
            data,
          );
        const summaryResponse = {
          ...response.data,
          subscriptionType: productType,
        };
        yield put(setSubscriptionExtensionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
      }
    },
    *renewKESubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IRenewKESubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
        const response = yield* call(
          renewKESubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(updateSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
      }
    },
    *loadKESubscriptionRenewalAnalyse({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadKESubscriptionRenewalAnalysePayload>) {
      try {
        yield put(clearKESubscriptionRenewalAnalyse());
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
        const response: AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel> =
          yield* call(
            getKESubscriptionRenewalAnalyseRequest,
            organizationId,
            subscriptionId,
          );
        yield put(setKESubscriptionRenewalAnalyse(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
      }
    },
    *sendKESubscriptionRenewalMemberships({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<ISendKESubscriptionRenewalMembershipsRequest>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
        const response = yield* call(
          sendKESubscriptionRenewalMembershipsRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(clearKESubscriptionRenewalAnalyse());
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
      }
    },
    *editKESubscriptionRenewalStatus({
      payload: {
        renewalStatus,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditKESubscriptionRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editKESubscriptionRenewalStatusRequest,
          organizationId,
          subscriptionId,
          renewalStatus,
          cancellationToken,
        );

        yield put(
          updateRenewalStatusSubscriptions({
            organizationId,
            subscriptionId,
            renewalStatus,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default keSubscriptionsSlice.saga;
export const {
  loadKESubscriptionOverview,
  loadDatesForNewKESubscription,
  loadEditingKESubscription,
  loadKESubscriptionSummary,
  updateProductKESubscription,
  deleteProductKESubscription,
  editKESubscriptionOptions,
  extendKESubscription,
  loadKESubscriptionExtensionSummary,
  loadKESubscriptionRenewalSuggestion,
  renewKESubscription,
  loadKESubscriptionRenewalAnalyse,
  sendKESubscriptionRenewalMemberships,
  editKESubscriptionRenewalStatus,
} = keSubscriptionsSlice.actions;
export const { actions } = keSubscriptionsSlice;
