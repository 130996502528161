import { Form } from 'react-final-form';

import { useTranslation, Trans } from 'react-i18next';

import arrayMutators from 'final-form-arrays';

import { FormApi } from 'final-form';

import Spinner from '../../Spinner/Spinner';

import { StandardText } from '../../Typography/Typography';

import { SUBSCRIPTION_RENEW_ANALYSE_MODAL } from './subscriptionAnalyse.consts';
import { SubscriptionAnalyseTable } from './Table/SubscriptionAnalyseTable';
import {
  Title,
  BoldText,
  CancelButton,
  ErrorText,
  InfoContainer,
  ContentWrapper,
  ModalFooter,
  SaveButton,
  SuccessText,
} from './subscriptionAnalyseModal.styles';
import { Modal } from '../../Modal/Modal';

import { validateForm } from './subscriptionAnalyse.validation';
import { ErrorsType, getNumberOfFormErrors } from './subscriptionAnalyse.utils';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { SubscriptionMembershipFormValues } from './subscriptionAnalyse.types';
type SubscriptionAnalyseModalProps = {
  isSubscriptionAnalyseLoading?: boolean;
  isSubscriptionMembershipInProgress?: boolean;
  handleSubmit?: (
    values: SubscriptionMembershipFormValues,
    form: FormApi<any, any>,
  ) => void;
  initialValues?: IRenewOrganizationSubscriptionAnalyseModel;
  handleCancel?: () => void;
  totalMemberships?: {
    membershipsCount: number;
    withErrors: number;
  };
};

export const SubscriptionAnalyseModal = ({
  handleCancel,
  handleSubmit,
  initialValues,
  isSubscriptionAnalyseLoading,
  isSubscriptionMembershipInProgress,
  totalMemberships: { membershipsCount, withErrors },
}: SubscriptionAnalyseModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      disabled={isSubscriptionAnalyseLoading}
      name={SUBSCRIPTION_RENEW_ANALYSE_MODAL}
    >
      {isSubscriptionAnalyseLoading ? (
        <Spinner size="30%" />
      ) : (
        <Form
          initialValues={initialValues}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          validate={validateForm}
        >
          {({ ...renderProps }) => {
            const { analysisResultForDate } = renderProps.values;
            const isInvalid = renderProps.invalid;

            const numberOfFormErrors = getNumberOfFormErrors(
              renderProps?.errors as ErrorsType,
            );

            return (
              <ContentWrapper>
                <Title>{t('headers.subscription-analyse-title')}</Title>
                <StandardText>
                  <Trans
                    i18nKey="phrase.analyse-modal"
                    values={{
                      membershipsCount,
                      withErrors,
                      analysisResultForDate,
                    }}
                    components={{
                      success: <SuccessText />,
                      error: <ErrorText />,
                      bold: <BoldText />,
                    }}
                  />
                </StandardText>
                <SubscriptionAnalyseTable />
                <ModalFooter>
                  <InfoContainer>
                    {t('phrase.errors-left', {
                      errorsLeft: numberOfFormErrors,
                    })}
                  </InfoContainer>
                  <CancelButton
                    disabled={isSubscriptionAnalyseLoading}
                    onClick={handleCancel}
                  >
                    {t('buttons.cancel')}
                  </CancelButton>
                  <SaveButton
                    isLoading={
                      isSubscriptionAnalyseLoading ||
                      isSubscriptionMembershipInProgress
                    }
                    onClick={renderProps.handleSubmit}
                    type="submit"
                    disabled={isInvalid || isSubscriptionMembershipInProgress}
                  >
                    {t('buttons.save')}
                  </SaveButton>
                </ModalFooter>
              </ContentWrapper>
            );
          }}
        </Form>
      )}
    </Modal>
  );
};
