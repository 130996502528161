import { useCallback, useContext, useState } from 'react';

import { IWarningRatification } from '../../models/interfaces/WarningRatification';
import { updateModalState } from '../../store/redux-slices/modals';
import { useActions } from '../../hooks/useActions';
import {
  WarningsConfirmationActionableContext,
  WarningsConfirmationInfoContext,
} from './WarningsConfirmationProvider';
import { IValidationErrorResponse } from '../../models/ValidationErrorResponse';

import { useModalDetails } from '../../hooks/useModalDetails';
import { WARNINGS_CONFIRMATION_MODAL_NAME } from './WarningsConfirmationModal/warningsConfirmationModal.const';
import {
  CustomWarningModal,
  WarningModalData,
  WarningsModalProps,
} from './warningsConfirmation.models';
import { prepareConfirmedWarnings } from './warningsConfirmation.utils';

export function useApiResponseWarnings() {
  const UpdateModalState = useActions(updateModalState);
  const { customModals } = useApiWarningValues();

  const handleResponseWarning = (
    err: IValidationErrorResponse,
    values: any,
  ) => {
    if (areWarnings(err)) {
      return false;
    }

    if (handleCustomModals(err, values)) {
      return true;
    }

    UpdateModalState([
      WARNINGS_CONFIRMATION_MODAL_NAME,
      { warnings: err.ValidationWarnings, data: values } as WarningModalData,
    ]);

    return true;
  };

  const handleCustomModals = (err: IValidationErrorResponse, values: any) => {
    for (const customModal of customModals) {
      const modalIndex = err.ValidationWarnings.findIndex(
        (x) => x.Code === customModal.errorCode,
      );
      if (modalIndex !== -1) {
        UpdateModalState([
          customModal.modalName,
          {
            warning: err.ValidationWarnings[modalIndex],
            data: values,
          } as WarningModalData,
        ]);
        return true;
      }
    }

    return false;
  };

  const areWarnings = (err: IValidationErrorResponse) =>
    !err?.ValidationWarnings?.length;

  return { handleResponseWarning };
}

export function useApiWarning(customWarningsModals: CustomWarningModal[] = []) {
  const [ratifications, setRatifications] = useState<IWarningRatification[]>(
    [],
  );

  const [customModals, setCustomModals] =
    useState<CustomWarningModal[]>(customWarningsModals);

  const addRatification = useCallback(
    (ratification: IWarningRatification): IWarningRatification[] => {
      const currentRatifications = [...ratifications, ratification];
      setRatifications(currentRatifications);

      return currentRatifications;
    },
    [ratifications],
  );

  const addRatifications = useCallback(
    (newRatifications: IWarningRatification[]): IWarningRatification[] => {
      const currentRatifications = [...ratifications, ...newRatifications];
      setRatifications(currentRatifications);

      return currentRatifications;
    },
    [ratifications],
  );

  const clearRatifications = useCallback(() => {
    setRatifications([]);
  }, []);

  return {
    ratifications,
    setRatifications,
    customModals,
    setCustomModals,
    addRatification,
    addRatifications,
    clearRatifications,
  };
}

export function useApiWarningActions() {
  const {
    setRatifications,
    submitFunction,
    addRatification,
    addRatifications,
    clearRatifications,
  } = useContext(WarningsConfirmationActionableContext);

  return {
    setRatifications,
    submitFunction,
    addRatification,
    addRatifications,
    clearRatifications,
  };
}

export function useApiWarningValues() {
  const { ratifications, customModals } = useContext(
    WarningsConfirmationInfoContext,
  );

  return { ratifications, customModals };
}

export const useWarningsConfirmationModal = ({
  onWarningsConfirm,
  onCancel,
}: WarningsModalProps) => {
  const UpdateModalState = useActions(updateModalState);
  const { warnings, data }: WarningModalData = useModalDetails(
    WARNINGS_CONFIRMATION_MODAL_NAME,
  );

  const { addRatifications, submitFunction } = useApiWarningActions();

  const checkIfPropertyIsDateByName = useCallback(
    (propertyName: string) => propertyName.toLowerCase().includes('date'),
    [],
  );

  const confirmActionHandler = () => {
    const preparedWarnings = prepareConfirmedWarnings(warnings);
    addRatifications?.(preparedWarnings);
    UpdateModalState([WARNINGS_CONFIRMATION_MODAL_NAME]);
    submitFunction && submitFunction(data, preparedWarnings);
    onWarningsConfirm && onWarningsConfirm(data, preparedWarnings);
  };

  const cancelActionHandler = () => {
    UpdateModalState(WARNINGS_CONFIRMATION_MODAL_NAME);
    onCancel?.(data);
  };

  return {
    checkIfPropertyIsDateByName,
    addRatifications,
    submitFunction,
    warnings,
    data,
    UpdateModalState,
    confirmActionHandler,
    cancelActionHandler,
  };
};
