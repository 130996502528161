import { t } from 'i18next';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { PersonRoles } from '../../../../models/enums/PersonRoles';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { personRolesSelector } from '../../../../store/redux-slices/people/selectors';
import { deletePerson } from '../../../../store/saga-slices/people';
import { IDeletePersonPayload } from '../../../../store/saga-slices/people/models';
import { DELETE_PERSON_MODAL_NAME } from './deletePersonModal.const';

export const useDeletePersonModal = () => {
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const navigate = useNavigate();
  const DeletePerson = useActions(deletePerson);
  const personRoles = useSelector(personRolesSelector);

  const shouldShowWarningMessage = personRoles.includes(
    PersonRoles.GGPointOfContact,
  );

  const UpdateModalState = useActions(updateModalState);
  const { personName, personId }: { personName: string; personId: string } =
    useModalDetails(DELETE_PERSON_MODAL_NAME);

  const onBackHandler = () => {
    UpdateModalState(DELETE_PERSON_MODAL_NAME);
  };

  const onDeleteHandler = useCallback(() => {
    const payload: IDeletePersonPayload = {
      personId,
      cancellationToken,
      success: () => {
        alert.success(`${t('alerts.delete-person-success')}`);
        UpdateModalState(DELETE_PERSON_MODAL_NAME);
        navigate(RoutePath.MembersPage);
      },
      error: () => alert.error(`${t('alerts.delete-person-fail')}`),
    };
    DeletePerson(payload);
  }, [
    DeletePerson,
    UpdateModalState,
    alert,
    cancellationToken,
    navigate,
    personId,
  ]);

  return {
    onBackHandler,
    onDeleteHandler,
    personName,
    shouldShowWarningMessage,
  };
};
