import { useCallback, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../utils/getPropertyName';

type UseBaseFormInput = {
  onBlur?: () => void;
  input: FieldInputProps<string, HTMLElement>;
  labelAnimation?: boolean;
  placeholder?: string;
  label?: string;
};

export const useFormField = ({
  onBlur,
  input,
  labelAnimation,
  placeholder,
  label,
}: UseBaseFormInput) => {
  const { t } = useTranslation();

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur();
      input.onBlur(event);
    },
    [onBlur, input],
  );
  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ' '
        : placeholder || t(`fields.${getPropertyName(input.name)}.placeholder`),
    [input.name, labelAnimation, placeholder, t],
  );

  const preparedLabel = useMemo(
    () => label || t(`fields.${getPropertyName(input.name)}.label`),
    [input.name, label, t],
  );

  return {
    onBlurHandler,
    preparedPlaceholder,
    preparedLabel,
  };
};
