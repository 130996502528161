import { DateFilterType } from '../../../../../../models/enums/RenewalFilters/DateFilterType';
import { CustomFilter, FilterDefinition } from '../../renewalFilters.models';
import { FilterType } from '../filterForm.models';
import { DateFilterAdditionalValuesModel } from './DateFilter/dateFilter.models';
import {
  getDateFilterApiModel,
  getDateFilterTag,
} from './DateFilter/dateFilter.utils';
import { FiltersOptions } from './filtersTypes.models';
import {
  SelectCategoryFilterAdditionalValuesModel,
  SelectCategoryFilterOptionsModel,
} from './SelectCategoryFilter/selectCategoryFilter.models';
import {
  getSelectCategoryFilterApiModel,
  getSelectCategoryFilterTag,
} from './SelectCategoryFilter/selectCategoryFilter.utils';
import { SelectFilterOptionsModel } from './SelectFilter/selectFilter.models';
import {
  getSelectFilterApiModel,
  getSelectFilterTag,
} from './SelectFilter/selectFilter.utils';

export const getFilterTagLabels = (
  filter: CustomFilter,
  filterDefinition: FilterDefinition<FiltersOptions>,
) => {
  switch (filter.field.filterType) {
    case FilterType.Date:
      return getDateFilterTag(
        filter.value as DateFilterType,
        filter.additionalValues as DateFilterAdditionalValuesModel,
      );
    case FilterType.Select:
      return getSelectFilterTag(
        filter as CustomFilter<void>,
        filterDefinition as FilterDefinition<SelectFilterOptionsModel>,
      );
    case FilterType.SelectCategory:
      return getSelectCategoryFilterTag(
        filter as CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
        filterDefinition as FilterDefinition<SelectCategoryFilterOptionsModel>,
      );
    default:
      return filter.value as string | string[];
  }
};

export const getFilterApiModel = (
  filter: CustomFilter,
  filterDefinition: FilterDefinition<FiltersOptions>,
) => {
  switch (filter.field.filterType) {
    case FilterType.Date:
      return getDateFilterApiModel(
        filter as CustomFilter<DateFilterAdditionalValuesModel>,
      );
    case FilterType.Select:
      return getSelectFilterApiModel(filter as CustomFilter<void>);
    case FilterType.SelectCategory:
      return getSelectCategoryFilterApiModel(
        filter as CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
        filterDefinition as FilterDefinition<SelectCategoryFilterOptionsModel>,
      );
    default:
      return null;
  }
};
