import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { changeToNextStep } from '../../wizard';
import { updateProcessState } from '../../../redux-slices/processes';
import {
  PRIMARY_MEMBER_LOADING_PROCESSING,
  PRIMARY_MEMBER_VALIDATING_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  ILoadMembershipRenewalSuggestionsPayload,
  ILoadPrimaryMemberPayload,
  ISubmitCreateMembershipPayload,
  ISubmitPrimaryMemberPayload,
} from './models';
import { preparePrimaryMemberData } from './utils';
import { analyzePrimaryMember } from './api';
import { searchPersonToCreatePrimaryMemberModel } from '../../../../utils/personHelpers';
import { getPersonItemRequest } from '../../people/api';
import { resetWizard } from '../../../redux-slices/wizard';
import {
  clearCreateMemberData,
  setAvailableProductTypes,
  setPrimaryMember,
} from '../../../redux-slices/membership/common';
import { ProductType } from '../../../../models/ProductType';
import {
  loadAAMembershipRenewalSuggestions,
  submitCreateAAMembership,
} from '../airAmbulance';
import { submitCreateKEMembership } from '../kidnapAndExtortion';
import { clearAAMembershipDetails } from '../../../redux-slices/membership/airAmbulance';
import { clearKEMembershipDetails } from '../../../redux-slices/membership/kidnapAndExtortion';
import { clearSRMembershipDetails } from '../../../redux-slices/membership/securityResponse';
import { submitCreateSRMembership } from '../securityResponse';
import { submitCreateDOCMembership } from '../dutyOfCare';
import { clearDOCMembershipDetails } from '../../../redux-slices/membership/dutyOfCare';

const membershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateMembership({
      payload,
    }: PayloadAction<ISubmitCreateMembershipPayload>) {
      switch (payload.data.productType) {
        case ProductType.AirAmbulance:
          yield put(submitCreateAAMembership(payload));
          return;
        case ProductType.KidnapAndExtortion:
          yield put(submitCreateKEMembership(payload));
          return;
        case ProductType.SecurityResponse:
          yield put(submitCreateSRMembership(payload));
          return;
        case ProductType.DutyOfCare:
          yield put(submitCreateDOCMembership(payload));
      }
    },
    *loadMembershipRenewalSuggestions({
      payload: { productType, ...restPayload },
    }: PayloadAction<ILoadMembershipRenewalSuggestionsPayload>) {
      switch (productType) {
        case ProductType.AirAmbulance:
          yield put(loadAAMembershipRenewalSuggestions(restPayload));
      }
    },
    *submitCreatePrimaryMember({
      payload: { data, productType, success, error, cancellationToken },
    }: PayloadAction<ISubmitPrimaryMemberPayload>) {
      try {
        yield put(updateProcessState(PRIMARY_MEMBER_VALIDATING_PROCESSING));
        const analyzeData = yield* call(preparePrimaryMemberData, data);
        const response = yield* call(
          analyzePrimaryMember,
          analyzeData,
          productType,
          cancellationToken,
        );
        yield put(setPrimaryMember(data));
        yield put(setAvailableProductTypes(response.data));
        yield put(changeToNextStep());
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PRIMARY_MEMBER_VALIDATING_PROCESSING));
      }
    },
    *loadPrimaryMember({
      payload: { personId, success, error, cancellationToken },
    }: PayloadAction<ILoadPrimaryMemberPayload>) {
      try {
        yield put(updateProcessState(PRIMARY_MEMBER_LOADING_PROCESSING));
        const response = yield* call(
          getPersonItemRequest,
          personId,
          cancellationToken,
        );

        yield put(
          setPrimaryMember(
            searchPersonToCreatePrimaryMemberModel(response.data),
          ),
        );
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PRIMARY_MEMBER_LOADING_PROCESSING));
      }
    },
    *clearCreateMemberProcess() {
      yield put(clearCreateMemberData());
      yield put(resetWizard());
    },
    *clearMembershipsDetails() {
      yield put(clearAAMembershipDetails());
      yield put(clearKEMembershipDetails());
      yield put(clearSRMembershipDetails());
      yield put(clearDOCMembershipDetails());
    },
  },
  name: 'members-saga',
  sagaType: SagaType.TakeLatest,
});

export default membershipSlice.saga;
export const {
  submitCreatePrimaryMember,
  clearCreateMemberProcess,
  loadPrimaryMember,
  submitCreateMembership,
  loadMembershipRenewalSuggestions,
  clearMembershipsDetails,
} = membershipSlice.actions;
export const { actions } = membershipSlice;
