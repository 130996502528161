import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { validateFormData } from '../validateFormData';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { ICreatePromoCodeModel } from '../../../models/CreatePromoCodeModel';
import { currentDate } from '../../dateHelpers';
import { PromoCodeType } from '../../../models/enums/PromoCodeType';

export const validatePromoCodeSchema: AnySchema | any = {
  type: 'object',
  properties: {
    value: {
      type: 'string',
    },
    product: {
      type: 'string',
    },
    validFrom: {
      type: 'string',
      format: 'date',
      formatMaximum: { $data: '1/validTo' },
      formatMinimum: currentDate,
      errorMessage: {
        formatMinimum: t(
          'validation.date-later-valid-to-date-and-earlier-now-date',
        ),
        formatMaximum: t(
          'validation.date-later-valid-to-date-and-earlier-now-date',
        ),
        format: t('validation.invalid-date'),
      },
    },
    validTo: {
      type: 'string',
      format: 'date',
      formatMinimum: currentDate,
      errorMessage: {
        formatMinimum: t('validation.date-earlier-now-date'),
        format: t('validation.invalid-date'),
      },
    },
  },
  if: {
    properties: {
      type: { enum: [PromoCodeType.Group, PromoCodeType.Agent] },
    },
  },
  then: {
    required: ['owner'],
  },
  required: ['value', 'product', 'type'],
};

export const validatePromoCodeForm = (
  data: ICreatePromoCodeModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, validatePromoCodeSchema);
