import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';
import { isPropertyEmpty } from '../../../../utils/propertyHelpers';

type UseFormCheckboxProps = {
  input: FieldInputProps<boolean, HTMLElement>;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

export const useFormCheckbox = ({
  input,
  onChange,
  label,
}: UseFormCheckboxProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isPropertyEmpty(input.checked)) {
      input.onChange(false);
    }
  }, [input]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      input.onChange(e);
      onChange && onChange(e);
    },
    [input, onChange],
  );

  const currentLabel = useMemo(
    () => label || t(`fields.${getPropertyName(input.name)}.label`),
    [input.name, label, t],
  );

  return { onChangeHandler, currentLabel };
};
