import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { ICreateOrganizationSubscriptionsModel } from '../../../models/interfaces/Subscription/CreateOrganizationSubscriptionsModel';
import { contractFormSchema } from '../../../styledComponents/Forms/SelectContractForm/selectContractForm.validation';
import { subscriptionSummarySchema } from '../../../styledComponents/FormSummary/SubscriptionSummary/subscriptionSummary.validation';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { subscriptionItemSchema } from '../../../styledComponents/SubscriptionFormPage/SubscriptionItemForm/subscriptionItemForm.validation';
import { SubscriptionsFormFields } from '../../../styledComponents/SubscriptionFormPage/SubscriptionsForm/subscriptionsForm.models';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionSummarySchema.properties,
    [SubscriptionsFormFields.Subscriptions]: {
      type: 'array',
      items: subscriptionItemSchema,
    },
  },
  dependencies: {
    ...subscriptionSummarySchema.dependencies,
  },
  required: [
    ...contractFormSchema.required,
    ...subscriptionSummarySchema.required,
  ],
};

export const validateAddSubscriptionForm = (
  data: ICreateOrganizationSubscriptionsModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
