import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove-icon.svg';
import {
  FormDeleteButton,
  FormNumber,
  FormSectionTitle,
} from '../../../Form/Form';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../../UI/Form/FormInput/FormInput';
import FormDatePicker from '../../../UI/Form/FormDatePicker/FormDatePicker';
import {
  ContractDetailsFormFields,
  ContractInformationFormName,
} from './contractDetailsForm.models';
import { CustomForm } from '../../../../models/types/CustomForm';
import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { contractTypeOptions } from './contractDetailsForm.utils';
import { StyledSectionWrapper } from './contractDetailsForm.styles';

interface IContractDetailsFormProps extends CustomForm {
  contractNumber?: number;
  onRemoveContract?: () => void;
  titleVisible?: boolean;
  editableFields?: Partial<Record<ContractDetailsFormFields, boolean>>;
}

const ContractDetailsForm = ({
  contractNumber,
  disabled,
  name,
  onRemoveContract,
  className,
  titleVisible = true,
  editableFields,
}: IContractDetailsFormProps) => {
  const { t } = useTranslation();
  return (
    <StyledSectionWrapper className={className}>
      {titleVisible && (
        <FormSectionTitle>{t('headers.details')}</FormSectionTitle>
      )}
      <FormColumn>
        <FormRow isSubRow>
          <FormRow isSubRow>
            {contractNumber && <FormNumber>{`${contractNumber}.`}</FormNumber>}
            <Field
              component={FormInput}
              disabled={disabled || (editableFields && !editableFields?.name)}
              label={t('fields.contractName.label')}
              {...getFieldPropertiesForGroup({
                inputId: ContractInformationFormName,
                name: ContractDetailsFormFields.Name,
                groupName: name,
              })}
            />
          </FormRow>
          <Field
            component={FormSelect}
            options={contractTypeOptions}
            disabled={
              disabled || (editableFields && !editableFields?.contractType)
            }
            {...getFieldPropertiesForGroup({
              inputId: ContractInformationFormName,
              name: ContractDetailsFormFields.ContractType,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow subRowAdjustWidth>
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={
                disabled || (editableFields && !editableFields?.startDate)
              }
              {...getFieldPropertiesForGroup({
                inputId: ContractInformationFormName,
                name: ContractDetailsFormFields.StartDate,
                groupName: name,
              })}
              label={t('fields.startDate.label')}
            />
            <Field
              component={FormDatePicker}
              disabled={
                disabled || (editableFields && !editableFields?.expirationDate)
              }
              {...getFieldPropertiesForGroup({
                inputId: ContractInformationFormName,
                name: ContractDetailsFormFields.ExpirationDate,
                groupName: name,
              })}
            />
          </FormRow>
        </FormRow>
        {onRemoveContract && (
          <FormDeleteButton
            disabled={disabled}
            onClick={onRemoveContract}
            styleType="icon"
          >
            <RemoveIcon />
          </FormDeleteButton>
        )}
      </FormColumn>
    </StyledSectionWrapper>
  );
};

export default ContractDetailsForm;
