import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { contractFormSchema } from '../../../styledComponents/Forms/SelectContractForm/selectContractForm.validation';
import { subscriptionSummarySchema } from '../../../styledComponents/FormSummary/SubscriptionSummary/subscriptionSummary.validation';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { subscriptionItemSchema } from '../../../styledComponents/SubscriptionFormPage/SubscriptionItemForm/subscriptionItemForm.validation';
import { EditOrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/EditOrganizationSubscriptionModel';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionSummarySchema.properties,
    ...subscriptionItemSchema.properties,
    ...contractFormSchema.properties,
  },
  dependencies: {
    ...subscriptionSummarySchema.dependencies,
    ...subscriptionItemSchema.dependencies,
  },
  required: [
    ...contractFormSchema.required,
    ...subscriptionSummarySchema.required,
  ],
};

export const validateEditProductSubscriptionForm = (
  data: EditOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
