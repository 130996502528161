import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { TableHeadCell } from './subscriptionAnalyse.styles';
import { SubscriptionAnalyseTableRow } from './SubscriptionAnalyseTableRow';

export const SubscriptionAnalyseTable = () => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ maxHeight: 560 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableHeadCell />
            <TableHeadCell>{t('grid-columns.name')}</TableHeadCell>
            <TableHeadCell>{t('grid-columns.date-of-birth')}</TableHeadCell>
            <TableHeadCell>{t('grid-columns.plan')}</TableHeadCell>
            <TableHeadCell>{t('grid-columns.member-type')}</TableHeadCell>
            <TableHeadCell>{t('grid-columns.renew')}</TableHeadCell>
            <TableHeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="memberships">
            {({ fields }) =>
              fields.map((membership) => (
                <Field
                  component={SubscriptionAnalyseTableRow}
                  fieldName={membership}
                  key={membership}
                  name={membership}
                />
              ))
            }
          </FieldArray>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
