/* eslint-disable react/destructuring-assignment */
import { t } from 'i18next';

import { IDOCSubscriptionPackageOptionsHistoryModel } from '../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import TableCell from '../../../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';
import { StyledButton } from './docSubscriptionOverviewTableRenderers.styles';

export const docOverSubscribedRenderer =
  (
    action?: (data: IDOCSubscriptionPackageOptionsHistoryModel) => void,
    permissions?: string[],
    isOversubscribed?: boolean,
  ) =>
  (data: IDOCSubscriptionPackageOptionsHistoryModel) => {
    if (!isOversubscribed) {
      return null;
    }
    return (
      <TableCell alignment="right">
        <StyledButton
          permissions={permissions}
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
            action?.(data);
          }}
          styleType="link"
        >
          {`${t('buttons.see-oversubscribed')}`}
        </StyledButton>
      </TableCell>
    );
  };
