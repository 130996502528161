import axios, { AxiosResponse } from 'axios';

import { IRenewalsFiltersModel } from '../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';
import { RenewalContractsResult } from '../../../models/types/RenewalFilters/RenewalContractsResult';
import { RenewalSubscriptionsResult } from '../../../models/types/RenewalFilters/RenewalSubscriptionsResult';

interface IRenewalsFilterContractsResponse {
  result: RenewalContractsResult;
}

interface IRenewalsFilterSubscriptionsResponse {
  result: RenewalSubscriptionsResult;
}

export const getFilteredContracts = (
  filters: IRenewalsFiltersModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IRenewalsFilterContractsResponse>> =>
  axios.post(`api/contracts/renewals`, filters, {
    signal: cancellationToken,
  });

export const getFilteredSubscriptions = (
  filters: IRenewalsFiltersModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IRenewalsFilterSubscriptionsResponse>> =>
  axios.post(`api/subscriptions/renewals`, filters, {
    signal: cancellationToken,
  });

export const exportContracts = (
  filters: IRenewalsFiltersModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<any>> =>
  axios.post(`api/contracts/renewals/export`, filters, {
    signal: cancellationToken,
    headers: { accept: 'text/csv' },
  });

export const exportSubscriptions = (
  filters: IRenewalsFiltersModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<any>> =>
  axios.post(`api/subscriptions/renewals/export`, filters, {
    signal: cancellationToken,
    headers: { accept: 'text/csv' },
  });
