import { useTranslation } from 'react-i18next';

import { canAddAAMemberPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';
import { Header, HeaderRightContainer, StyledButton } from './contracts.styles';

type PromoCodeHeaderProps = {
  onAddContract?: () => void;
};

const ContractsHeader = ({ onAddContract }: PromoCodeHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Header>
      <SectionTitle>{t('headers.contracts')}</SectionTitle>
      <HeaderRightContainer>
        <StyledButton
          permissions={canAddAAMemberPermissions}
          onClick={onAddContract}
          styleType="lighter-blue"
        >
          {t('buttons.add-contract')}
        </StyledButton>
      </HeaderRightContainer>
    </Header>
  );
};

export default ContractsHeader;
