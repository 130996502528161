import styled from '@emotion/styled/macro';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.color.dark1};
  font-size: 0.875rem;
  font-weight: bold;
`;
