import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';

import RenewSubscriptionItemForm from '../../RenewSubscriptionItemForm/RenewSubscriptionItemForm';

import { RenewSubscriptionItemBaseProps } from '../../RenewSubscriptionItemForm/renewSubscriptionItemForm.models';
import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { TLSubscriptionOptions } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';
import { Wrapper } from './dutyOfCareRenewSubscriptionForm.styles';
import { DOCRenewSubscriptionDetails } from './DOCRenewSubscriptionDetails/DOCRenewSubscriptionDetails';
import DOCRenewSubscriptionPricing from './DOCRenewSubscriptionPricing/DOCRenewSubscriptionPricing';

const DutyOfCareRenewSubscriptionForm = ({
  name,
  disabled,
  className,
}: RenewSubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const productType = ProductType.DutyOfCare;
  return (
    <RenewSubscriptionItemForm collapsible={false} productType={productType}>
      <Wrapper className={className}>
        <DOCRenewSubscriptionDetails disabled={disabled} />
        <DOCRenewSubscriptionPricing name={name} disabled={disabled} />
        <SubscriptionOptions
          subscriptionOptions={TLSubscriptionOptions}
          layout="column"
          disabled={disabled}
          title={t('headers.options')}
        />
      </Wrapper>
    </RenewSubscriptionItemForm>
  );
};

export default DutyOfCareRenewSubscriptionForm;
