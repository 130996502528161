import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';

import { ProductType } from '../../../models/ProductType';

import { useKEEditSubscriptionRenewPage } from './keEditSubscriptionRenewPage.hooks';
import KEEditSubscriptionRenewPageForm from './KESubscriptionRenewPageForm/KESubscriptionRenewPageForm';
import KEEditSubscriptionAnalyseModal from './KEEditSubscriptionAnalyseModal/KEEditSubscriptionAnalyseModal';

export const KESubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    dataLoading,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  } = useKEEditSubscriptionRenewPage();

  if (dataLoading) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <KEEditSubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.edit-renew-subscription', {
              productType: ProductType.KidnapAndExtortion,
              organizationName: organization?.name,
            })}
          />
          <KEEditSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
