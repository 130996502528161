import styled from '@emotion/styled';

import Button from '../../../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(2, auto);
  margin-bottom: 3rem;
  gap: 2.75rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  grid-row: 1/3;
  grid-column: 4;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1rem;
`;

export const StyledButton = styled(Button)`
  width: 6.625rem;
`;
