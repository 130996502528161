import { ReactNode } from 'react';

import { Alignment } from '../../models/Alignment';
import { CustomPermissions } from '../../models/types/CustomPermissions';

export enum AvailableQueryParams {
  Order = 'order',
  OrderBy = 'orderBy',
  PageSize = 'pageSize',
  PageNumber = 'pageNumber',
}

export interface IMaterialColumn extends CustomPermissions {
  alignment?: Alignment;
  cellRenderer?: (rowData: any) => any;
  field?: string;
  propertySelector?: (value: any) => any;
  sortable?: boolean;
  title?: string;
  tooltip?: (value: any) => ReactNode | string;
  width?: string;
  minWidth?: string;
  onClick?: (data: any, key?: any) => void;
}

export interface IMaterialTableProps {
  defaultColumnSort?: string;
  className?: string;
  columns: IMaterialColumn[];
  data?: any[];
  dataLoading?: boolean;
  isPaginationHidden?: boolean;
  itemsCount?: number;
  summaryRow?: any;
  onRowClick?: (x: any) => void;
  onSummaryRowClick?: () => void;
  canClickRowPermissions?: string[];
  showNotFoundComponent?: boolean;
}
