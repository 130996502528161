import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import ArrowDown from '../../assets/icons/Arrow-Down.svg';
import ArrowUp from '../../assets/icons/Arrow-Up.svg';

type Props = {
  isOpen?: boolean;
};

export const CollapseIndicator = styled('div', {
  shouldForwardProp: isPropValid,
})`
  width: 1rem;
  height: 1rem;
  transition: all 0.2s ease;
  ${({ isOpen }: Props) =>
    isOpen
      ? `background: url(${ArrowUp}) 50% no-repeat;`
      : `background: url(${ArrowDown}) 50% no-repeat;`};
`;
