import { ErrorBoundary } from 'react-error-boundary';

import { CustomComponent } from '../../models/types/CustomComponent';
import SomethingWentWrongModal from '../Modals/SomethingWentWrongModal/SomethingWentWrongModal';
import ActionErrorHandler from './ActionErrorHandler/ActionErrorHandler';
import FallbackComponent from './FallbackComponent/FallbackComponent';

const ErrorHandler = ({ children }: CustomComponent) => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <ActionErrorHandler />
    <SomethingWentWrongModal />
    {children}
  </ErrorBoundary>
);

export default ErrorHandler;
