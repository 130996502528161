import { useTranslation } from 'react-i18next';

import { MembershipItemBaseProps } from '../../MembershipItemForm/membershipItemForm.models';
import MembershipFamilyMembersForm from '../MembershipCommon/MembershipFamilyMembersForm/MembershipFamilyMembersForm';
import KEMembershipDetailsForm from './KEMembershipDetailsForm/KEMembershipDetailsForm';
import KEMembershipOptionsForm from './KEMembershipOptionsForm/KEMembershipOptionsForm';
import { useKidnapAndExtortionMembership } from './kidnapAndExtortionMembership.hooks';
import { checkFamilyMembersFormVisible } from './kidnapAndExtortionMembership.utils';

type KidnapAndExtortionMembershipProps = MembershipItemBaseProps;

const KidnapAndExtortionMembership = ({
  isProcessing,
  disabled,
  values,
  isRenewalMode,
}: KidnapAndExtortionMembershipProps) => {
  const { t } = useTranslation();

  const { selectedPlan } = useKidnapAndExtortionMembership();
  const isDisabled = disabled || isProcessing;
  return (
    <div>
      <KEMembershipDetailsForm disabled={isDisabled} />
      <KEMembershipOptionsForm
        title={t('headers.options')}
        disabled={isDisabled}
      />
      {checkFamilyMembersFormVisible(values) && (
        <MembershipFamilyMembersForm
          isRenewalMode={isRenewalMode}
          title={t('headers.family-members')}
          disabled={isDisabled}
          maxMembersPerPlan={selectedPlan.maxMembersPerPlan}
          membershipPlanType={selectedPlan.membershipPlanType}
        />
      )}
    </div>
  );
};

export default KidnapAndExtortionMembership;
