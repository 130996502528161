import { Collapse } from '@mui/material';
import { ReactNode, useState } from 'react';

import { Wrapper, ItemSummary, ItemDetails } from './itemList.styles';
import { CollapseIndicator } from '../../CollapseIndicator';

type ItemListProps<T> = {
  onItemClick?: (data: T) => void;
  data?: T;
  itemSummary?: ReactNode;
  itemDetails?: ReactNode;
};

const ItemList = <T,>({
  onItemClick,
  data,
  itemSummary,
  itemDetails,
}: ItemListProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper isOpen={isOpen}>
      <ItemSummary isOpen={isOpen} onClick={() => setIsOpen((state) => !state)}>
        {itemSummary}
        {itemDetails && <CollapseIndicator isOpen={isOpen} />}
      </ItemSummary>
      {itemDetails && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <ItemDetails>{itemDetails}</ItemDetails>
        </Collapse>
      )}
    </Wrapper>
  );
};

export default ItemList;
