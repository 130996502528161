import { ICellRenderer } from '../cellRenderers.models';
import EmptyCell from '../EmptyCell/EmptyCell';
import TableCell from '../TableCell/TableCell';
import { NameWrapper } from './nameRenderer.styles';

export const NameRenderer = ({ value, options, className }: ICellRenderer) => {
  if (!value) {
    return <EmptyCell className={className} />;
  }
  return (
    <TableCell className={className}>
      <NameWrapper disabled={options?.disabled} bold={options?.textBold}>
        {value}
      </NameWrapper>
    </TableCell>
  );
};
