import { t } from 'i18next';

import { DutyOfCareSubscriptionPackageType } from '../../../../models/enums/DutyOfCareSubscriptionPackageType';

import { IChangeSubscriptionPackageModel } from '../../../../models/interfaces/Subscription/DutyOfCare/ChangeDOCSubscriptionCapacityModel ';
import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import { getOptionsFromEnum } from '../../../../utils/getOptionsFromEnum';
import {
  DOCPackageTableRow,
  TableFormColumns,
} from './extendDOCSubscriptionPackageFormTable.models';

export const prepareTableData = (
  data: IDOCOrganizationSubscriptionExtensionData,
  newValues?: IChangeSubscriptionPackageModel,
): DOCPackageTableRow[] => [
  {
    name: t('fields.packageType.label'),
    columnType: TableFormColumns.packageType,
    currentValues: data.packageType,
    newValues: newValues?.packageType || '-',
  },
  {
    name: t('fields.seatsAmount.label'),
    columnType: TableFormColumns.seatsAmount,
    currentValues: data.firmWide
      ? t('fields.firmWide.label')
      : `${+data.totalMembershipsInUse || 0}/${data.seatsAmount || 0}`,
    newValues: data.firmWide
      ? t('fields.firmWide.label')
      : ((+newValues.seatsAmount || 0) + +data.seatsAmount || 0).toString(),
  },
  {
    name: t('fields.totalPrice.label'),
    columnType: TableFormColumns.totalPrice,
    currentValues: `$${data.totalPrice || 0}`,
    newValues: `$${(+newValues.totalPrice || 0) + (+data.totalPrice || 0)}`,
  },
];

export const packagesOptions = getOptionsFromEnum(
  DutyOfCareSubscriptionPackageType,
  'duty-of-care-subscription-packages',
);
