import { CustomForm } from '../../../../../../models/types/CustomForm';
import EditMemberModal from '../../../../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import PrimaryMemberDetailsForm from '../PrimaryMemberDetailsForm/PrimaryMemberDetailsForm';
import { usePrimaryMemberDetailsSection } from './primaryMemberDetailsSection.hooks';

const PrimaryMemberDetailsSection = (props: CustomForm) => {
  const { onEditMemberHandler, onEditedMemberHandler } =
    usePrimaryMemberDetailsSection();
  return (
    <>
      <EditMemberModal onMemberEdited={onEditedMemberHandler} />
      <PrimaryMemberDetailsForm {...props} onEditMember={onEditMemberHandler} />
    </>
  );
};

export default PrimaryMemberDetailsSection;
