import { ISearchPerson } from '../../../../models/SearchPerson';
import { searchPersonPeopleManagement } from '../../../../store/saga-slices/optionsSearch/common/api';
import { ISearchInputOption } from '../../../UI/SearchInput/searchInput.models';

export const shouldFieldBeDisabled = (
  fieldsArray: string[],
  fieldName: string,
) => {
  if (fieldsArray && fieldsArray.length < 1 && fieldName) {
    return;
  }

  if (fieldsArray.includes(fieldName)) {
    return true;
  }
};

export const promiseOptions = (inputValue: string) =>
  searchPersonPeopleManagement(inputValue).then((x) =>
    x.data.people.map(
      (person) =>
        ({
          label: person.contactEmailAddress,
          value: person,
        } as ISearchInputOption<ISearchPerson>),
    ),
  );
