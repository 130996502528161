import { useSelector } from 'react-redux';

import { useProcessing } from 'hooks/useProcessing';

import { SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import SummaryContainer from '../../../../styledComponents/SummaryContainer/SummaryContainer';
import { ProductType } from '../../../../models/ProductType';
import { docSubscriptionExtensionSummarySelector } from '../../../../store/redux-slices/subscriptions/dutyOfCare/selectors';
import ExtendDOCSubscriptionSummaryContent from './ExtendDOCSubscriptionSummaryContent/ExtendDOCSubscriptionSummaryContent';

interface IExtendDOCSubscriptionSummaryFormProps {
  disabled?: boolean;
  title?: string;
  isPriceOverridden?: boolean;
}

const ExtendDOCSubscriptionSummaryForm = ({
  disabled,
  title,
  isPriceOverridden,
}: IExtendDOCSubscriptionSummaryFormProps) => {
  const summaryData = useSelector(docSubscriptionExtensionSummarySelector);
  const summaryIsLoading = useProcessing(
    SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  );

  return (
    <SummaryContainer
      content={() =>
        summaryData && (
          <ExtendDOCSubscriptionSummaryContent
            isPriceOverridden={isPriceOverridden}
            disabled={disabled}
            productType={ProductType.DutyOfCare}
            subtotalValue={summaryData.calculatedAmount}
            summaryData={summaryData}
          />
        )
      }
      data={{ totalAmount: summaryData ? summaryData.totalAmount : 0 }}
      disabled={disabled}
      isLoading={summaryIsLoading}
      title={title}
      canOverwriteAmount={false}
    />
  );
};

export default ExtendDOCSubscriptionSummaryForm;
