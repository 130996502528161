import { useTranslation } from 'react-i18next';

import { FieldArray } from 'react-final-form-arrays';

import { useMemo } from 'react';

import {
  Header,
  StyledButton,
  Wrapper,
} from './extendTLSubscriptionExtensionsForm.styles';
import { ProductType } from '../../../../models/ProductType';
import { FormColumn } from '../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { ITLOrganizationSubscriptionExtensionSuggestionData } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionData';
import { ExtendTLSubscriptionExtensionsFormFields } from './extendTLSubscriptionExtensionsForm.models';
import TLSubscriptionPricingTableForm from '../ExtendTLSubscriptionPackageFormTable/ExtendTLSubscriptionPackageFormTable';
import TLPricingTooltip from '../../../../styledComponents/Forms/TravelLicense/TLSubscriptionPricingForm/TLPricingTooltip/TLPricingTooltip';
import InfoComponent from '../../../../styledComponents/UI/InfoComponent/InfoComponent';
import { SectionTitle } from '../../../../styledComponents/Typography/Typography';
import { TLSubscriptionDefaultValue } from './extendTLSubscriptionExtensionsForm.const';
import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';

interface IExtendTLSubscriptionExtensionsFormProps {
  className?: string;
  disabled?: boolean;
  productType?: ProductType;
  data?: ITLOrganizationSubscriptionExtensionSuggestionData;
}

const ExtendTLSubscriptionExtensionsForm = ({
  className,
  disabled,
  productType,
  data,
}: IExtendTLSubscriptionExtensionsFormProps) => {
  const { t } = useTranslation();

  const defaultValue = useMemo(
    (): IChangeTLSubscriptionPricingTierModel[] =>
      data?.pricingTiers?.map((item) => ({
        price: item.price,
        seatsAmount: 0,
        id: item.id,
      })),
    [data],
  );

  return (
    <Wrapper className={className}>
      <Header>
        <SectionTitle>{t('headers.capacity')}</SectionTitle>
        <InfoComponent
          tooltipPlacement="left-end"
          tooltip={<TLPricingTooltip />}
        />
      </Header>
      <FormColumn>
        <FieldArray
          defaultValue={defaultValue}
          name={
            ExtendTLSubscriptionExtensionsFormFields.ChangeSubscriptionPricingTierModel
          }
        >
          {({ fields, meta: { error, touched } }) => (
            <div>
              <TLSubscriptionPricingTableForm
                fieldsData={fields.map((x, index) => ({ name: x, index }))}
                onDelete={(id) => fields.remove(id)}
                errors={error}
                touched={touched}
                pricingData={data?.pricingTiers}
                newValues={fields.value}
              />
              <StyledButton
                disabled={disabled}
                onClick={() => fields.push(TLSubscriptionDefaultValue)}
                styleType="link"
              >
                {t('buttons.add-new-tier')}
              </StyledButton>
            </div>
          )}
        </FieldArray>
      </FormColumn>
    </Wrapper>
  );
};

export default ExtendTLSubscriptionExtensionsForm;
