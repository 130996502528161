import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';
import { EditProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadTLSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/travelLicense';
import { ILoadTLSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/travelLicense/models';

type SummaryLoaderProps = {
  values: IEditTLOrganizationSubscriptionModel;
};

const TLSummaryLoader = ({ values }: SummaryLoaderProps) => {
  const { organizationId } = useParams<EditProductSubscriptionPageParamsType>();

  const LoadSubscriptionSummary = useActions(loadTLSubscriptionSummary);
  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values?.pricingTiers) {
      return;
    }

    const payload: ILoadTLSubscriptionSummary = {
      data: { pricingTiers: values?.pricingTiers },
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, debounce, organizationId, values?.pricingTiers]);

  return <></>;
};

export default TLSummaryLoader;
