import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { clearDOCPackages } from '../../../../store/redux-slices/packages/dutyOfCare';
import { docPackagesSelector } from '../../../../store/redux-slices/packages/dutyOfCare/selectors';
import { DOC_PACKAGES_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { loadDOCPackages } from '../../../../store/saga-slices/packages/dutyOfCare';
import { ILoadDOCPackagesPayload } from '../../../../store/saga-slices/packages/dutyOfCare/models';

import { getFormGroupPath } from '../../../../utils/getFormGroupPath';
import { DOCSubscriptionPricingFormFields } from './docSubscriptionPricingForm.models';

export const useDOCSubscriptionPricingForm = (name: string) => {
  const cancellationToken = useCancellationToken();
  const LoadDOCPackages = useActions(loadDOCPackages);
  const ClearDOCPackages = useActions(clearDOCPackages);
  const packages = useSelector(docPackagesSelector);
  const isLoading = useProcessing(DOC_PACKAGES_LOADING_PROCESSING);

  useEffect(() => {
    const payload: ILoadDOCPackagesPayload = {
      cancellationToken,
    };
    LoadDOCPackages(payload);

    return () => ClearDOCPackages();
  }, [ClearDOCPackages, LoadDOCPackages, cancellationToken]);

  const {
    input: { value: firmWideValue },
  } = useField(
    getFormGroupPath(name, DOCSubscriptionPricingFormFields.FirmWide),
  );
  const {
    input: { onChange: onChangeSeats },
  } = useField(
    getFormGroupPath(name, DOCSubscriptionPricingFormFields.SeatsAmount),
  );

  useEffect(() => {
    if (firmWideValue) {
      onChangeSeats(null);
    }
  }, [firmWideValue, onChangeSeats]);
  return {
    seatsDisabled: firmWideValue,
    packages,
    isLoading,
  };
};
