import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';

interface IValidateSubscriptionOptionsForm {
  adminRenewalEmail: boolean;
  membershipCardsIncludeOrganizationLogo: boolean;
  membershipCardsIncludeOrganizationName: boolean;
  membershipCardsOrganizationName: string;
  membershipOwnerRenewalEmail: boolean;
  printMembershipCards: boolean;
}

export const subscriptionOptionsSchema: AnySchema | any = {
  required: [],
  type: 'object',
};

export const validateSubscriptionOptionsForm = (
  data: IValidateSubscriptionOptionsForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionOptionsSchema);
