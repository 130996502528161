import styled from '@emotion/styled/macro';

import { NameRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { EditableStatusRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/EditableStatusRenderer/EditableStatusRenderer';

export const StyledNameRenderer = styled(NameRenderer)`
  width: 15rem;
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const StyledNameRendererLong = styled(NameRenderer)`
  width: 15rem;

  @media (min-width: 1950px) {
    width: 25rem;
  }
  @media (min-width: 2000px) {
    width: 30rem;
  }
  @media (min-width: 2100px) {
    width: 34rem;
  }
  @media (min-width: 2200px) {
    width: 36rem;
  }
  @media (min-width: 2400px) {
    width: 100%;
  }
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const StyledEditableStatusRenderer = styled(EditableStatusRenderer)`
  width: 100%;
`;
