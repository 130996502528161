import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IStandardPricingQueryResponse } from '../common/models';

export const getKEStandardPricingQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IStandardPricingQueryResponse>> =>
  axios.get(`api/pricing/standard`, {
    baseURL: config.kidnapAndExtortionApi.baseUrl,
    signal: cancellationToken,
  });

export const getKERegularPricingQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IStandardPricingQueryResponse>> =>
  axios.get(`api/pricing/regular`, {
    baseURL: config.kidnapAndExtortionApi.baseUrl,
    signal: cancellationToken,
  });
