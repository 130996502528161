import { CustomComponent } from '../../../../models/types/CustomComponent';
import { StyledStandardText, Wrapper } from './charsNumber.styles';

type CharsNumberProps = CustomComponent & {
  currentNumber: number;
  maxCount: number;
};

const CharsNumber = ({
  currentNumber,
  maxCount,
  className,
}: CharsNumberProps) => (
  <Wrapper className={className}>
    <StyledStandardText>
      {currentNumber}
      {maxCount && `/${maxCount}`}
    </StyledStandardText>
  </Wrapper>
);

export default CharsNumber;
