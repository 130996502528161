import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { useActions } from '../../../../hooks/useActions';
import { SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import {
  useApiResponseError,
  useFormErrors,
} from '../../../FormErrorProvider/FormErrorProvider.hooks';
import { useProcessing } from '../../../../hooks/useProcessing';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { validateRenewalStatusForm } from '../../../Forms/RenewalStatusForm/renewalStatusForm.validation';
import { RenewalStatusFormModel } from '../../../Forms/RenewalStatusForm/renewalStatusForm.models';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from '../editRenewalSubscriptionStatusModal.const';
import { EditRenewalSubscriptionStatusOverview } from '../EditRenewalSubscriptionStatusModalOverview/editRenewalSubscriptionStatusModalOverview.models';
import { editSubscriptionRenewalStatusByProductType } from '../../../../store/saga-slices/subscriptions/common';
import { IEditSubscriptionRenewalStatusByProductTypePayload } from '../../../../store/saga-slices/subscriptions/common/models';
import { EditRenewalSubscriptionStatusModalDetails } from '../editRenewalSubscriptionStatusModal.models';

type UseEditRenewalSubscriptionStatusProps = {
  onRenewalStatusEdited?: () => void;
};

export const useEditSubscriptionRenewalStatus = ({
  onRenewalStatusEdited,
}: UseEditRenewalSubscriptionStatusProps) => {
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const EditSubscriptionRenewalStatusByProductType = useActions(
    editSubscriptionRenewalStatusByProductType,
  );

  const isProcessing = useProcessing(
    SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
  );

  const {
    subscriptionId,
    organizationId,
    renewalStatus,
    contractName,
    expirationDate,
    organizationName,
    productType,
  }: EditRenewalSubscriptionStatusModalDetails = useModalDetails(
    EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME,
  );

  const { validateErrors } = useFormErrors();

  const { handleResponseError } = useApiResponseError();

  const closeModalHandler = useCallback(() => {
    UpdateModalState(EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME);
  }, [UpdateModalState]);

  const handleSubmit = useCallback(
    (values: RenewalStatusFormModel) => {
      const payload: IEditSubscriptionRenewalStatusByProductTypePayload = {
        organizationId,
        subscriptionId,
        renewalStatus: values.newRenewalStatus,
        cancellationToken,
        productType,
        success: () => {
          alert.success(t('alerts.renewal-status-updated-success'));
          closeModalHandler();
          onRenewalStatusEdited?.();
        },
        error: (err: any) => {
          handleResponseError(err, values);
          alert.error(t('alerts.renewal-status-updated-fail'));
        },
      };
      EditSubscriptionRenewalStatusByProductType(payload);
    },
    [
      organizationId,
      subscriptionId,
      cancellationToken,
      productType,
      EditSubscriptionRenewalStatusByProductType,
      alert,
      t,
      closeModalHandler,
      onRenewalStatusEdited,
      handleResponseError,
    ],
  );

  const validateData = useCallback(
    (data: RenewalStatusFormModel) =>
      validateRenewalStatusForm(data, validateErrors(data)),
    [validateErrors],
  );

  const initialValues: RenewalStatusFormModel = {
    newRenewalStatus: renewalStatus,
  };

  const overviewData: EditRenewalSubscriptionStatusOverview = {
    contractName,
    expirationDate,
    organizationName,
    productType,
  };

  return {
    closeModalHandler,
    handleSubmit,
    initialValues,
    organizationName,
    contractName,
    expirationDate,
    isProcessing,
    validateData,
    overviewData,
  };
};
