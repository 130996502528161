import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import AddMemberPaymentSummaryForm from '../AddMemberPaymentSummaryForm/AddMemberPaymentSummaryForm';

import { useAddMemberPayment } from './addMemberPaymentForm.hooks';
import {
  StyledAddMemberWizardPage,
  Title,
  RightContainer,
  LeftContainer,
  StyledFormSubmitButton,
} from './addMemberPaymentForm.styles';
import PaymentSection from '../PaymentSection/PaymentSection';
import FormErrorActivator from '../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { AddMemberWizardPageContainersKeys } from '../../AddMemberWizardPage/addMemberWizardPage.models';
import RepaymentNotPaymentModal from '../../Modals/RepaymentNotPaymentModal/RepaymentNotPossibleModal';
import WarningsConfirmationModal from '../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import Spinner from '../../../../../styledComponents/Spinner/Spinner';

const AddMemberPaymentForm = () => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    paymentProcessing,
    validateForm,
    paymentHandler,
    initValues,
    dataLoading,
    showPaymentSection,
  } = useAddMemberPayment();

  return (
    <>
      <WarningsConfirmationModal onWarningsConfirm={paymentHandler} />
      <RepaymentNotPaymentModal onWarningsConfirm={paymentHandler} />
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
        initialValues={initValues}
      >
        {({ values, valid, ...renderProps }) => (
          <FormErrorActivator>
            <form onSubmit={renderProps.handleSubmit}>
              <StyledAddMemberWizardPage disabled={paymentProcessing}>
                <LeftContainer
                  key={AddMemberWizardPageContainersKeys.LeftContainer}
                >
                  <Spinner size="30%" isVisible={dataLoading}>
                    <Title>{t('headers.payment')}</Title>
                    {showPaymentSection && (
                      <PaymentSection disabled={paymentProcessing} />
                    )}
                  </Spinner>
                </LeftContainer>
                <RightContainer
                  key={AddMemberWizardPageContainersKeys.RightContainer}
                >
                  <AddMemberPaymentSummaryForm
                    disabled={paymentProcessing}
                    title={t('headers.summary')}
                    isPriceOverridden={values.overwriteAmount}
                  />
                  <StyledFormSubmitButton
                    isLoading={paymentProcessing}
                    disabled={!valid}
                    type="submit"
                  >
                    {t('buttons.confirm').toLocaleUpperCase()}
                  </StyledFormSubmitButton>
                </RightContainer>
              </StyledAddMemberWizardPage>
            </form>
          </FormErrorActivator>
        )}
      </Form>
    </>
  );
};

export default AddMemberPaymentForm;
