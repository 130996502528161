import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { IMemberListItem } from '../../../../../models/MemberListItem';
import { IPerson } from '../../../../../models/Person';
import { RoutePath } from '../../../../../models/enums/RoutePath';
import {
  clearAAMembers,
  updateAAMembersList,
} from '../../../../../store/redux-slices/membership/airAmbulance';
import { pageListAAMembersSelector } from '../../../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { MEMBERS_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { loadAAMembers } from '../../../../../store/saga-slices/membership/airAmbulance';
import { ILoadAAMembersPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { ILoadSubscriptionsPayload } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { EDIT_MEMBER_MODAL_NAME } from '../../../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { textContainsWords } from '../../../../../utils/textHelpers';
import { clearSubscriptionsList } from '../../../../../store/redux-slices/subscriptions/common';
import { loadSubscriptions } from '../../../../../store/saga-slices/subscriptions/common';
import { aaLoadStandardPricing } from '../../../../../store/saga-slices/pricing/airAmbulance';
import { clearAAMembershipPlansPrices } from '../../../../../store/redux-slices/pricing/airAmbulance';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { MemberOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { aaMembershipPlansPricesSelector } from '../../../../../store/redux-slices/pricing/airAmbulance/selectors';
import { RequiredQueryParams } from './aaMembers.const';

export const useAAMembers = () => {
  const LoadMembers = useActions(loadAAMembers);
  const ClearMembers = useActions(clearAAMembers);
  const location = useLocation();
  const navigate = useNavigateSearch();
  const pageListMembers = useSelector(pageListAAMembersSelector);
  const aaMembershipPricing = useSelector(aaMembershipPlansPricesSelector);

  const membersLoading = useProcessing(MEMBERS_LOADING_PROCESSING);
  const LoadStandardPricing = useActions(aaLoadStandardPricing);
  const LoadSubscriptions = useActions(loadSubscriptions);
  const ClearSubscriptionsList = useActions(clearSubscriptionsList);
  const ClearStandardPricing = useActions(clearAAMembershipPlansPrices);

  const UpdateModalState = useActions(updateModalState);
  const UpdateMembersList = useActions(updateAAMembersList);

  const { id } = useParams();

  useEffect(() => {
    const payload: ILoadSubscriptionsPayload = {
      organizationId: id,
    };
    LoadSubscriptions(payload);
  }, [LoadSubscriptions, id]);

  useEffect(() => {
    LoadStandardPricing();
  }, [LoadStandardPricing]);

  useEffect(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadAAMembersPayload = {
      params,
      organizationId: id,
    };

    LoadMembers(payload);
  }, [LoadMembers, id, location.search]);

  useEffect(
    () => () => {
      ClearMembers();
      ClearStandardPricing();
      ClearSubscriptionsList();
    },
    [ClearMembers, ClearStandardPricing, ClearSubscriptionsList],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberListItem) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, { params });
    },
    [navigate],
  );

  const rowEditHandler = useCallback(
    (personId: string) => {
      UpdateModalState([EDIT_MEMBER_MODAL_NAME, personId]);
    },
    [UpdateModalState],
  );

  const onMemberEditedHandler = useCallback(
    (person: IPerson) => {
      UpdateMembersList(person);
    },
    [UpdateMembersList],
  );

  return {
    pageListMembers,
    membersLoading,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    aaMembershipPricing,
  };
};
