import { SimplePageLine } from '../SimplePage/SimplePageLine';
import PromoCodesTable, {
  IPromoCodesTableProps,
} from './PromoCodesTable/PromoCodesTable';
import PromoCodesTools from './PromoCodesTools/PromoCodesTools';

type IProductMembersListProps = IPromoCodesTableProps;

const PromoCodesListPage = (props: IProductMembersListProps) => (
  <>
    <PromoCodesTools />
    <SimplePageLine />
    <PromoCodesTable {...props} />
  </>
);

export default PromoCodesListPage;
