import { CustomForm } from '../../../models/types/CustomForm';

export interface IMemberAddressInformationFormProps extends CustomForm {
  isSubRowSpace?: boolean;
}

export enum MemberAddressInformationsFields {
  CorrespondenceAddressSameAsResidenceAddress = 'correspondenceAddressSameAsResidenceAddress',
  ResidenceAddress = 'residenceAddress',
  CorrespondenceAddress = 'correspondenceAddress',
}
