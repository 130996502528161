import { useTranslation } from 'react-i18next';

import { ReactComponent as ReportsIcon } from '../../../assets/icons/report-icon.svg';
import { CustomComponent } from '../../../models/types/CustomComponent';
import Button from '../Button/Button';
import { ButtonContent, Wrapper } from './ReportsGenerateButton.styles';

type ReportsGenerateButtonProps = CustomComponent & {
  onClick?: (event: any) => void;
};

const ReportsGenerateButton = ({
  onClick,
  className,
}: ReportsGenerateButtonProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Button
        className={className}
        type="submit"
        styleType="link"
        onClick={onClick}
      >
        <ButtonContent>
          <ReportsIcon />
          {t('buttons.generate')}
        </ButtonContent>
      </Button>
    </Wrapper>
  );
};

export default ReportsGenerateButton;
