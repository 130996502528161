import { useTranslation } from 'react-i18next';

import { useExtendAASubscriptionPage } from './extendAASubscriptionPage.hooks';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { ProductType } from '../../../models/ProductType';
import ExtendAASubscriptionForm from './ExtendAASubscriptionForm/ExtendAASubscriptionForm';
import { ExtendSubscriptionExtensionsFormFields } from '../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/extendSubscriptionExtensionsForm.models';

const ExtendAASubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName } = useExtendAASubscriptionPage();
  return (
    <FormErrorProvider
      initErrorGroups={[
        ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges,
      ]}
    >
      <>
        <Header
          title={t('headers.edit-seats-for-subscription', {
            organizationName,
            productType: ProductType.AirAmbulance,
          })}
        />
        <ExtendAASubscriptionForm />
      </>
    </FormErrorProvider>
  );
};

export default ExtendAASubscriptionPage;
