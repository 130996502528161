import { ReactNode } from 'react';

import { Field } from 'react-final-form';

import {
  OverwriteAmountCheckbox,
  Title,
  Wrapper,
} from './summaryContainer.styles';
import SummaryContainerTotalPrice from './SummaryContainerTotalPrice/SummaryContainerTotalPrice';
import { checkboxContentRenderer } from './summaryContainer.utils';
import { useSummaryContainer } from './summaryContainer.hooks';
import Spinner from '../Spinner/Spinner';
import { SummaryContainerFields } from './summaryContainer.models';

export interface ISummaryFormData {
  totalAmount?: number;
}

interface ISummaryFormProps {
  content?: (overwriteAmount?: boolean) => ReactNode;
  canOverwriteAmount?: boolean;
  children?: ReactNode;
  data?: ISummaryFormData;
  disabled?: boolean;
  isLoading?: boolean;
  title: string;
  overwrittenPrice?: number;
  overwriteAmount?: boolean;
}

const SummaryContainer = ({
  content,
  children,
  data,
  disabled,
  isLoading,
  title,
  canOverwriteAmount = true,
  overwriteAmount,
  overwrittenPrice,
}: ISummaryFormProps) => {
  const { currentTotalPrice, overwrittenTotalPrice, overwriteTotalAmount } =
    useSummaryContainer(data, canOverwriteAmount);

  return (
    <div>
      <Spinner isVisible={isLoading} size="40%">
        <Wrapper>
          <Title>{title}</Title>
          {content && content(overwriteAmount)}
          <SummaryContainerTotalPrice
            currentTotalPrice={overwrittenPrice || currentTotalPrice}
            overwrittenPrice={
              overwriteAmount ? currentTotalPrice : overwrittenTotalPrice
            }
            overwriteAmount={overwriteAmount || overwriteTotalAmount}
          />
          {canOverwriteAmount && (
            <Field
              checkboxContentRenderer={checkboxContentRenderer}
              component={OverwriteAmountCheckbox}
              disabled={disabled}
              inputId="summaryForm.summaryOverrideSubtotal"
              name={SummaryContainerFields.OverwriteAmount}
              type="checkbox"
            />
          )}
          {children}
        </Wrapper>
      </Spinner>
    </div>
  );
};

export default SummaryContainer;
