import { useParams } from 'react-router-dom';

import NavigationPath from '../../../../styledComponents/NavigationPath/NavigationPath';
import { OverviewFullTabPage } from '../../../../styledComponents/OverviewPage/OverviewPage';
import {
  LeftContainer,
  PageOverviewRootColumns,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import ContractOverviewDetails from './ContractOverviewDetails/ContractOverviewDetails';
import { ContractOverviewActions } from './ContractOverviewActions/ContractOverviewActions';
import ContractOverviewNotes from './ContractOverviewNotes/ContractOverviewNotes';
import { Header } from './contractOverviewTabPage.styles';
import { getNavigationPaths } from './contractOverviewTabPage.utils';
import ContractOverviewSubscriptions from './ContractOverviewSubscriptions/ContractOverviewSubscriptions';
import EditContractNotesModal from './Modals/EditContractNotesModal/EditContractNotesModal';
import { useContractOverview } from './contractOverviewTabPage.hooks';
import AddContractNotesModal from './Modals/AddContractNotesModal/AddContractNotesModal';
import DeleteContractModal from '../../../../styledComponents/Modals/DeleteContractModal/DeleteContractModal';
import EditContractDetailsModal from '../../../../styledComponents/Modals/EditContractDetailsModal/EditContractDetailsModal';
import Spinner from '../../../../styledComponents/Spinner/Spinner';
import EditRenewalContractStatusModal from '../../../../styledComponents/Modals/EditRenewalContractStatusModal/EditRenewalContractStatusModal';

const ClientOverviewTabPage = () => {
  const { id } = useParams();
  const {
    editContractNotesHandler,
    editRenewalStatusHandler,
    addContractNotesHandler,
    deleteContractHandler,
    editContractDetailsHandler,
    addSubscriptionHandler,
    showSubscriptionHandler,
    contractDetails,
    dataLoading,
    subscriptions,
    loadOrganizationContractDetailsHandler,
    contractDeletedHandler,
  } = useContractOverview();

  if (dataLoading) {
    return <Spinner />;
  }

  return (
    <>
      <EditContractNotesModal
        onNotesEdited={loadOrganizationContractDetailsHandler}
      />
      <AddContractNotesModal
        onNotesAdded={loadOrganizationContractDetailsHandler}
      />
      <EditRenewalContractStatusModal
        onRenewalStatusEdited={loadOrganizationContractDetailsHandler}
      />
      <DeleteContractModal onContractDeleted={contractDeletedHandler} />
      <EditContractDetailsModal
        onContractEdited={loadOrganizationContractDetailsHandler}
      />
      <OverviewFullTabPage>
        <Header>
          <NavigationPath
            paths={getNavigationPaths(id, contractDetails?.name)}
          />
          <ContractOverviewActions
            onContractDelete={deleteContractHandler}
            onContractEdit={editContractDetailsHandler}
            canDeleteContract={contractDetails?.metadata?.canBeDeleted}
          />
        </Header>
        <PageOverviewRootColumns>
          <LeftContainer>
            <ContractOverviewNotes
              notes={contractDetails?.notes}
              onEditContractNotes={editContractNotesHandler}
              onAddContractNotes={addContractNotesHandler}
            />
            <ContractOverviewSubscriptions
              data={subscriptions}
              onAddSubscription={addSubscriptionHandler}
              onSubscriptionClick={showSubscriptionHandler}
            />
          </LeftContainer>
          <RightContainer>
            <ContractOverviewDetails
              data={contractDetails}
              onEditRenewalStatus={editRenewalStatusHandler}
            />
          </RightContainer>
        </PageOverviewRootColumns>
      </OverviewFullTabPage>
    </>
  );
};

export default ClientOverviewTabPage;
