import {
  canChangeKEToBeRenewedPermissions,
  canManageKEMembershipsPermissions,
  canRemoveKESecondaryMemberPermissions,
  canResendKEWelcomeEmailPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { MemberOverviewMemberTablePermissions } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.models';

export const canViewActionColumnPermissions = [
  ...canManageKEMembershipsPermissions,
  ...canResendKEWelcomeEmailPermissions,
];

export const keOverviewTablePermissions: MemberOverviewMemberTablePermissions =
  {
    canViewActionColumnPermissions,
    canChangeToBeRenewedPermissions: canChangeKEToBeRenewedPermissions,
    canRemoveSecondaryMemberPermissions: canRemoveKESecondaryMemberPermissions,
    canResendWelcomeEmailPermissions: canResendKEWelcomeEmailPermissions,
  };
