import { useParams } from 'react-router';

import { EditProductSubscriptionRenewPageParamsType } from '../../models/types/RoutePath';
import ProductViewContent from '../../styledComponents/ProductViewContent/ProductViewContent';
import {
  EditRenewSubscriptionPages,
  EditRenewSubscriptionViewsPermissions,
} from './editSubscriptionRenewPage.utils';

const EditSubscriptionRenewPage = () => {
  const { productType } =
    useParams<EditProductSubscriptionRenewPageParamsType>();

  return (
    <ProductViewContent
      productType={productType}
      viewsPages={EditRenewSubscriptionPages}
      viewsPermissions={EditRenewSubscriptionViewsPermissions}
    />
  );
};

export default EditSubscriptionRenewPage;
