import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import MembershipPlanFormProvider from '../../../../styledComponents/UI/Form/MembershipPlanForm/MembershipPlanFormProvider';
import {
  SectionSubTitle,
  SectionTitleDescription,
} from '../../../../styledComponents/Typography/Typography';
import { Header, Wrapper } from './addPromoCodePricingForm.styles';
import AddPromoCodePlansForm from '../AddPromoCodePlansForm/AddPromoCodePlansForm';
import { FormColumn } from '../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { aaMembershipPlansPricesSelector } from '../../../../store/redux-slices/pricing/airAmbulance/selectors';

type Props = {
  isDisabled: boolean;
};

export const AddPromoCodePricingForm = ({ isDisabled }: Props) => {
  const { t } = useTranslation();
  const membershipPlansPrices = useSelector(aaMembershipPlansPricesSelector);

  return (
    <MembershipPlanFormProvider
      regularMembershipPlansPrices={membershipPlansPrices}
    >
      <Wrapper>
        <Header>
          <SectionSubTitle>{t('headers.retail-pricing')}</SectionSubTitle>
          <SectionTitleDescription>
            {t('phrase.this-pricing-defines-retail-prices')}
          </SectionTitleDescription>
        </Header>
        <FormColumn>
          <Field
            component={AddPromoCodePlansForm}
            disabled={isDisabled}
            name="promoCodePlans"
          />
        </FormColumn>
      </Wrapper>
    </MembershipPlanFormProvider>
  );
};
