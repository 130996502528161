import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import {
  RenewalStatusFormFields,
  RenewalStatusFormModel,
} from './renewalStatusForm.models';

export const RenewalStatusSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [RenewalStatusFormFields.NewRenewalStatus]: {
      type: 'string',
    },
  },
  required: [],
};

export const validateRenewalStatusForm = (
  data: RenewalStatusFormModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, RenewalStatusSchema);
