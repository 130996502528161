import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING,
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
  SUBSCRIPTION_DELETE_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  extendAASubscriptionRequest,
  getDatesForNewAASubscription,
  getAASubscription,
  getAASubscriptionExtensionSummary,
  getAASubscriptionRenewalSuggestion,
  getAASubscriptionRenewalAnalyseRequest,
  getAASubscriptionSummary,
  renewAASubscriptionRequest,
  updateAASubscriptionDetails,
  sendAASubscriptionRenewalMembershipsRequest,
  deleteAASubscriptionRequest,
  updateAASubscriptionRequest,
  getAASubscriptionSuggestion,
  editAASubscriptionRenewalStatusRequest,
} from './api';

import {
  setAASubscription,
  setAASubscriptionDates,
  setAASubscriptionRenewalSuggestion,
  setAASubscriptionRenewalAnalyse,
  clearAASubscriptionRenewalAnalyse,
  setAACurrentEditingSubscription,
} from '../../../redux-slices/subscriptions/airAmbulance';

import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';
import { IAAOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { IAASubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AASubscriptionRenewalSuggestionModel';

import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import {
  clearSubscriptionExtensionHelperData,
  setDeletedSubscriptionId,
  setSubscriptionExtensionSummary,
  setSubscriptionSummary,
  updateSubscription,
} from '../../../redux-slices/subscriptions/common';
import {
  IDeleteProductSubscriptionPayload,
  IEditSubscriptionDetailsPayload,
  IExtendSubscriptionPayload,
  ILoadDatesForNewAASubscriptionPayload,
  ILoadEditingAASubscriptionPayload,
  ILoadSubscriptionExtensionSummary,
  ILoadSubscriptionOverviewPayload,
  ILoadAASubscriptionRenewalAnalysePayload,
  ILoadAASubscriptionRenewalSuggestionPayload,
  ILoadAASubscriptionSummary,
  IRenewAASubscriptionPayload,
  ISendAASubscriptionRenewalMembershipsRequest,
  IUpdateAASubscriptionPayload,
  IEditAASubscriptionRenewalStatusPayload,
} from './models';
import { updateRenewalStatusSubscriptions } from '../../../redux-slices/renewalFilter';

const aaSubscriptionsSlice = createSliceSaga({
  name: 'aa-subscriptions-saga',
  caseSagas: {
    *loadAASubscriptionSummary({
      payload: { data, error, organizationId, success },
    }: PayloadAction<ILoadAASubscriptionSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<ISubscriptionSummaryModel> = yield* call(
          getAASubscriptionSummary,
          organizationId,
          data,
        );
        const summaryResponse = {
          ...response.data,
        };
        yield put(setSubscriptionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadDatesForNewAASubscription({
      payload: { error, organizationId, contractId, success },
    }: PayloadAction<ILoadDatesForNewAASubscriptionPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING),
        );
        const response: AxiosResponse<ISubscriptionDates> = yield* call(
          getDatesForNewAASubscription,
          organizationId,
          contractId,
        );

        const parsedDates: ISubscriptionDates = {
          ...response.data,
          effectiveDateFrom: parseDate(response.data.effectiveDateFrom),
          effectiveDateTo: parseDate(response.data.effectiveDateTo),
        };
        yield put(setAASubscriptionDates(parsedDates));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING),
        );
      }
    },
    *loadAASubscriptionOverview({
      payload: { error, organizationId, subscriptionId, success },
    }: PayloadAction<ILoadSubscriptionOverviewPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
        const response: AxiosResponse<IAAOrganizationSubscriptionOverview> =
          yield* call(getAASubscription, organizationId, subscriptionId);

        yield put(setAASubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
      }
    },
    *loadEditingAASubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        subscriptionType,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadEditingAASubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
        const response = yield* call(
          getAASubscriptionSuggestion,
          organizationId,
          subscriptionId,
          cancellationToken,
        );

        yield put(setAACurrentEditingSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
      }
    },
    *editAASubscriptionDetails({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IEditSubscriptionDetailsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          updateAASubscriptionDetails,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *extendAASubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IExtendSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          extendAASubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(clearSubscriptionExtensionHelperData());
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *loadAASubscriptionExtensionSummary({
      payload: {
        data,
        error,
        organizationId,
        subscriptionId,
        productType,
        success,
      },
    }: PayloadAction<ILoadSubscriptionExtensionSummary>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IOrganizationSubscriptionExtensionSummaryModel> =
          yield* call(
            getAASubscriptionExtensionSummary,
            organizationId,
            subscriptionId,
            data,
          );
        const summaryResponse = {
          ...response.data,
          productType,
        };
        yield put(setSubscriptionExtensionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
      }
    },
    *loadAASubscriptionRenewalSuggestion({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadAASubscriptionRenewalSuggestionPayload>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IAASubscriptionRenewalSuggestionModel> =
          yield* call(
            getAASubscriptionRenewalSuggestion,
            organizationId,
            subscriptionId,
          );

        yield put(setAASubscriptionRenewalSuggestion(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
      }
    },
    *renewAASubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IRenewAASubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
        const response = yield* call(
          renewAASubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(updateSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
      }
    },
    *loadAASubscriptionRenewalAnalyse({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadAASubscriptionRenewalAnalysePayload>) {
      try {
        yield put(clearAASubscriptionRenewalAnalyse());
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
        const response: AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel> =
          yield* call(
            getAASubscriptionRenewalAnalyseRequest,
            organizationId,
            subscriptionId,
          );
        yield put(setAASubscriptionRenewalAnalyse(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
      }
    },
    *sendAASubscriptionRenewalMemberships({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<ISendAASubscriptionRenewalMembershipsRequest>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
        const response = yield* call(
          sendAASubscriptionRenewalMembershipsRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(clearAASubscriptionRenewalAnalyse());
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
      }
    },
    *deleteProductAASubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteProductSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
        yield* call(
          deleteAASubscriptionRequest,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield putResolve(setDeletedSubscriptionId([subscriptionId]));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
      }
    },
    *updateProductAASubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        data,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateAASubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
        yield call(
          updateAASubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
      }
    },
    *editAASubscriptionRenewalStatus({
      payload: {
        renewalStatus,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditAASubscriptionRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editAASubscriptionRenewalStatusRequest,
          organizationId,
          subscriptionId,
          renewalStatus,
          cancellationToken,
        );

        yield put(
          updateRenewalStatusSubscriptions({
            organizationId,
            subscriptionId,
            renewalStatus,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default aaSubscriptionsSlice.saga;
export const {
  editAASubscriptionDetails,
  extendAASubscription,
  loadDatesForNewAASubscription,
  loadAASubscriptionExtensionSummary,
  loadAASubscriptionOverview,
  loadAASubscriptionSummary,
  loadAASubscriptionRenewalSuggestion,
  loadAASubscriptionRenewalAnalyse,
  renewAASubscription,
  sendAASubscriptionRenewalMemberships,
  deleteProductAASubscription,
  updateProductAASubscription,
  loadEditingAASubscription,
  editAASubscriptionRenewalStatus,
} = aaSubscriptionsSlice.actions;
export const { actions } = aaSubscriptionsSlice;
