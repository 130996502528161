import { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { t } from 'i18next';
import { transparentize } from 'polished';

import { CustomComponent } from '../../../models/types/CustomComponent';

import { SectionSubTitle } from '../../Typography/Typography';
import Button from '../../UI/Button/Button';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`;

export const Title = styled(SectionSubTitle)`
  font-weight: bold;
`;

export const StyledButton = styled(Button)`
  padding: 0 0.75rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type OptionProps = {
  checked?: boolean;
};

export const Detail = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const DetailLabel = styled.div`
  font: 400 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark2};
  margin-right: 0.5rem;
`;

type DetailValueProps = {
  onClick?: (evt: MouseEvent) => void;
};

export const DetailValue = styled.div<DetailValueProps>`
  font: 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};

  ${({ onClick }) =>
    !!onClick &&
    css`
      font-weight: bold;
      cursor: pointer;
    `}
`;

export const DetailValueBold = styled(DetailValue)`
  font-weight: bold;
`;

export const DetailBreak = styled.div`
  height: 1rem;
`;

export const AutoCreatedLabel = styled((props: CustomComponent) => (
  <div {...props}>{`${t('phrase.auto-created')}`}</div>
))<CustomComponent>`
  font: normal bold 0.75rem/1rem ${({ theme }) => theme.font.gotham};
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: ${({ theme }) => theme.color.light1};
  background-color: ${({ theme }) => transparentize(0.6, theme.color.primary)};
  margin-left: 1rem;
  white-space: nowrap;
  align-self: center;
  justify-content: flex-end;
`;
