import { Icon, Label, Wrapper } from './TabOption.styles';

export interface ITabOptionProps {
  icon?: JSX.Element;
  label: string;
  value: string;
  isActive?: boolean;
  onClick?: (value: string) => void;
  disabled?: boolean;
  readonly?: boolean;
}

const TabOption = ({
  icon,
  label,
  value,
  onClick,
  ...props
}: ITabOptionProps) => (
  <Wrapper
    {...props}
    onClick={() => (!props.disabled && !props.readonly ? onClick(value) : {})}
  >
    {icon && <Icon {...props}>{icon}</Icon>}
    <Label {...props}>{label}</Label>
  </Wrapper>
);

export default TabOption;
