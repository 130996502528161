import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks/useActions';
import { promoCodeOverviewSelector } from '../../store/redux-slices/promoCodes/selectors';
import {
  changePromoCodeStatus,
  loadPromoCodeOverview,
} from '../../store/saga-slices/promoCodes';
import {
  IChangePromoCodeStatusPayload,
  ILoadPromoCodeOverviewPayload,
} from '../../store/saga-slices/promoCodes/models';
import { useProcessing } from '../../hooks/useProcessing';
import { PROMO_CODE_OVERVIEW_LOADING_PROCESSING } from '../../store/redux-slices/processes/constants';
import { clearPromoCode } from '../../store/redux-slices/promoCodes';
import { useCancellationToken } from '../../hooks/useTokenCancellation';
import { updateModalState } from '../../store/redux-slices/modals';
import { DELETE_PROMO_CODE_MODAL_NAME } from './Modals/DeletePromoCodeModal/deletePromoCodeModal.consts';
import { EDIT_PROMO_CODE_DATES_MODAL_NAME } from './Modals/EditPromoCodeDatesModal/editPromoCodeDatesModal.const';
import { EditPromoCodeDatesModalData } from './Modals/EditPromoCodeDatesModal/editPromoCodeDatesModal.models';
import { parseDate } from '../../utils/dateHelpers';
import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import {
  ClientOverviewGeneralPageParams,
  RoutePath,
} from '../../models/enums/RoutePath';

export const usePromoCodeOverview = () => {
  const navigate = useNavigateSearch();

  const LoadPromoCodeOverview = useActions(loadPromoCodeOverview);
  const ClearPromoCode = useActions(clearPromoCode);
  const ChangePromoCodeStatus = useActions(changePromoCodeStatus);
  const promoCodeOverviewData = useSelector(promoCodeOverviewSelector);
  const dataLoading = useProcessing(PROMO_CODE_OVERVIEW_LOADING_PROCESSING);
  const cancellationToken = useCancellationToken();

  const { promoCodeId } = useParams();
  const UpdateModalState = useActions(updateModalState);

  const loadPromoCodeHandler = useCallback(() => {
    const payload: ILoadPromoCodeOverviewPayload = {
      id: promoCodeId,
    };

    LoadPromoCodeOverview(payload);
  }, [LoadPromoCodeOverview, promoCodeId]);

  useEffect(() => {
    loadPromoCodeHandler();
  }, [loadPromoCodeHandler]);

  const changePromoCodeStatusHandler = useCallback(
    (value: boolean) => {
      const payload: IChangePromoCodeStatusPayload = {
        promoCodeId,
        isEnabled: value,
        cancellationToken,
        success: loadPromoCodeHandler,
      };
      ChangePromoCodeStatus(payload);
    },
    [
      ChangePromoCodeStatus,
      cancellationToken,
      loadPromoCodeHandler,
      promoCodeId,
    ],
  );

  const canBeDeleted = promoCodeOverviewData?.canBeDeleted;

  const deletePromoCode = () => {
    UpdateModalState([
      DELETE_PROMO_CODE_MODAL_NAME,
      { promoCodeId, promoCodeName: promoCodeOverviewData?.name },
    ]);
  };

  const editPromoCodeDatesHandler = () => {
    const data: EditPromoCodeDatesModalData = {
      validFrom: parseDate(promoCodeOverviewData?.validFrom),
      validTo: parseDate(promoCodeOverviewData?.validTo),
      promoCodeId: promoCodeOverviewData?.id,
    };
    UpdateModalState([EDIT_PROMO_CODE_DATES_MODAL_NAME, data]);
  };

  const onClickOwnerNameHandler = () => {
    navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
      params: {
        [ClientOverviewGeneralPageParams.OrganizationId]:
          promoCodeOverviewData?.ownerId,
      },
    });
  };

  useEffect(() => () => ClearPromoCode(), [ClearPromoCode]);

  return {
    promoCodeOverviewData,
    dataLoading,
    changePromoCodeStatusHandler,
    deletePromoCode,
    canBeDeleted,
    editPromoCodeDatesHandler,
    loadPromoCodeHandler,
    onClickOwnerNameHandler,
  };
};
