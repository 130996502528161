import { Dictionary } from '../models/Dictionary';
import { RoutePath } from '../models/enums/RoutePath';

export const prepareRoutePath = (
  params: Dictionary<string>,
  ...paths: RoutePath[]
) => {
  if (!paths || paths.length === 0) {
    return '';
  }

  let resultPath = paths.join('/');

  Object.keys(params).forEach((param) => {
    resultPath = resultPath.replace(`:${param}`, params[param]);
  });

  return resultPath;
};
