import axios, { AxiosResponse } from 'axios';

import { IOrganizationGGPointOfContactModel } from 'models/CreateOrganizationGGPointOfContact';

import { IOrganizationList } from '../../../models/OrganizationList';
import { ICreateOrganizationModel } from '../../../models/CreateOrganizationModel';
import { IOrganization } from '../../../models/Organization';
import { IAAOrganizationList } from '../../../models/AAOrganizationList';
import { IEditOrganizationModel } from '../../../models/EditOrganizationModel';
import { ICreateOrganizationPointOfContact } from '../../../models/CreateOrganizationPointOfContact';
import { IOrganizationSearch } from '../../../models/interfaces/SearchOrganization';

import config from '../../../config/config';
import { ICreateOrganizationResultModel } from '../../../models/interfaces/CreateOrganizationResultModel';
import { IEditOrganizationNotesModel } from '../../../models/EditOrganizationNotesModel';
export interface IPagedResponse<T> {
  items: T[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface IOrganizationSearchResult {
  organizations: IOrganizationSearch[];
}

export const getOrganizations = (
  params: string,
): Promise<AxiosResponse<IPagedResponse<IOrganizationList>>> =>
  axios.get(`/api/organizations?${params && `&${params}`}`);

export const addOrganization = (
  organization: ICreateOrganizationModel,
): Promise<AxiosResponse<ICreateOrganizationResultModel>> =>
  axios.post(`/api/organizations`, organization);

export const getOrganization = (
  id: string,
): Promise<AxiosResponse<IOrganization>> =>
  axios.get(`/api/organizations/${id}`);

export const getAAOrganizations = (
  params: string,
): Promise<AxiosResponse<IPagedResponse<IAAOrganizationList>>> =>
  axios.get(`/api/organizations?${params && `&${params}`}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
  });

export const updateOrganization = (
  id: string,
  organization: IEditOrganizationModel,
): Promise<AxiosResponse> =>
  axios.put(`/api/organizations/${id}`, organization);

export const updateOrganizationNotes = (
  id: string,
  model: IEditOrganizationNotesModel,
): Promise<AxiosResponse> => axios.put(`/api/organizations/${id}/notes`, model);

export const createOrganizationPointOfContact = (
  organizationId: string,
  organizationPointOfContact: ICreateOrganizationPointOfContact,
): Promise<AxiosResponse> =>
  axios.post(
    `/api/organizations/${organizationId}/pointOfContact`,
    organizationPointOfContact,
  );

export const updateOrganizationPointOfContact = (
  contactId: string,
  organizationId: string,
  organizationPointOfContact: ICreateOrganizationPointOfContact,
): Promise<AxiosResponse> =>
  axios.put(
    `/api/organizations/${organizationId}/pointOfContact/${contactId}`,
    organizationPointOfContact,
  );

export const createOrganizationGGPointOfContact = (
  organizationId: string,
  organizationGGPointOfContact: IOrganizationGGPointOfContactModel,
): Promise<AxiosResponse> =>
  axios.post(
    `/api/organizations/${organizationId}/ggPointOfContact`,
    organizationGGPointOfContact,
  );

export const updateOrganizationGGPointOfContact = (
  ggPointOfContactId: string,
  organizationId: string,
  organizationGGPointOfContact: IOrganizationGGPointOfContactModel,
): Promise<AxiosResponse> =>
  axios.put(
    `/api/organizations/${organizationId}/ggPointOfContact/${ggPointOfContactId}`,
    organizationGGPointOfContact,
  );

export const deleteOrganization = (
  organizationId: string,
): Promise<AxiosResponse> =>
  axios.delete(`/api/organizations/${organizationId}`);

export const deleteOrganizationPointOfContact = (
  organizationId: string,
  contactId: string,
): Promise<AxiosResponse> =>
  axios.delete(
    `/api/organizations/${organizationId}/pointOfContact/${contactId}`,
  );

export const deleteOrganizationGGPointOfContact = (
  organizationId: string,
  contactId: string,
): Promise<AxiosResponse> =>
  axios.delete(
    `/api/organizations/${organizationId}/ggPointOfContact/${contactId}`,
  );
