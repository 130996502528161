import styled from '@emotion/styled';

import Button from '../Button/Button';

export const FormSubmitButton = styled(Button)`
  margin-bottom: 5rem;
  padding: 0.5rem 3.1875rem;
  font-weight: 700;
  line-height: 2rem;
  font-size: 1rem;
  letter-spacing: 0.03125rem;
  border-radius: 0.25rem;
  width: 100%;
  height: 3rem;
`;
