import { SubscriptionAnalyseModal } from '../../../../styledComponents/Modals/SubscriptionAnalyseModal/SubscriptionAnalyseModal';
import { useKESubscriptionAnalyseModal } from './keSubscriptionAnalyseModal.hooks';

type KESubscriptionAnalyseModalProps = {
  onAnalyseSuccess?: () => void;
};

const KESubscriptionAnalyseModal = ({
  onAnalyseSuccess,
}: KESubscriptionAnalyseModalProps) => {
  const {
    isSubscriptionAnalyseLoading,
    isSubscriptionMembershipInProgress,
    handleSubmit,
    initialValues,
    handleCancel,
    totalMemberships,
  } = useKESubscriptionAnalyseModal(onAnalyseSuccess);
  return (
    <SubscriptionAnalyseModal
      isSubscriptionAnalyseLoading={isSubscriptionAnalyseLoading}
      isSubscriptionMembershipInProgress={isSubscriptionMembershipInProgress}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      totalMemberships={totalMemberships}
    />
  );
};

export default KESubscriptionAnalyseModal;
