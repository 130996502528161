import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { withErrorProvider } from '../../../../hoc/withErrorProvider';

import { SmallModal } from '../../../../styledComponents/ModalCommon/ModalCommon';
import ModalFormPage from '../../../../styledComponents/ModalFormPage/ModalFormPage';
import EditPromoCodeDatesForm from './EditPromoCodeDatesForm/EditPromoCodeDatesForm';

import { EDIT_PROMO_CODE_DATES_MODAL_NAME } from './editPromoCodeDatesModal.const';
import { useEditPromoCodeDatesModal } from './editPromoCodeDatesModal.hooks';

interface IEditPromoCodeDatesModalProps {
  onModalCancel?: () => void;
  onPromoCodeDatesEdited?: () => void;
}

const EditPromoCodeDatesModal = ({
  onModalCancel,
  onPromoCodeDatesEdited,
}: IEditPromoCodeDatesModalProps) => {
  const { t } = useTranslation();

  const {
    initData,
    editPromoCodeDatesHandler,
    isProcessing,
    onCloseHandler,
    validateForm,
  } = useEditPromoCodeDatesModal({ onPromoCodeDatesEdited });

  return (
    <SmallModal
      disabled={isProcessing}
      name={EDIT_PROMO_CODE_DATES_MODAL_NAME}
      onModalClose={onModalCancel}
    >
      <Form
        initialValues={initData}
        validate={validateForm}
        keepDirtyOnReinitialize
        onSubmit={editPromoCodeDatesHandler}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            mode="small"
            handleSubmit={renderProps.handleSubmit}
            isProcessing={isProcessing}
            onCancel={onCloseHandler}
            title={t('headers.edit-promo-code')}
            submitButtonLabel={t('buttons.confirm').toUpperCase()}
          >
            <EditPromoCodeDatesForm />
          </ModalFormPage>
        )}
      </Form>
    </SmallModal>
  );
};

export default withErrorProvider(EditPromoCodeDatesModal);
