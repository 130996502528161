import {
  canChangeSRToBeRenewedPermissions,
  canManageSRMembershipsPermissions,
  canRemoveSRSecondaryMemberPermissions,
  canResendSRWelcomeEmailPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { MemberOverviewMemberTablePermissions } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.models';

export const canViewActionColumnPermissions = [
  ...canManageSRMembershipsPermissions,
  ...canResendSRWelcomeEmailPermissions,
];

export const srOverviewTablePermissions: MemberOverviewMemberTablePermissions =
  {
    canViewActionColumnPermissions,
    canChangeToBeRenewedPermissions: canChangeSRToBeRenewedPermissions,
    canRemoveSecondaryMemberPermissions: canRemoveSRSecondaryMemberPermissions,
    canResendWelcomeEmailPermissions: canResendSRWelcomeEmailPermissions,
  };
