import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../../../../../../styledComponents/Form/Form';

import FormMessage from '../../../../../../../styledComponents/UI/Form/FormMessage/FormMessage';
import FormValueInfo from '../../../../../../../styledComponents/UI/Form/FormValueInfo/FormValueInfo';

export const StyledFormMessage = styled(FormMessage)`
  margin-top: 0.5rem;
`;

export const StyledFormValueInfo = styled(FormValueInfo)`
  width: 9rem;
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
  margin-top: 0;
`;
