import { useTranslation } from 'react-i18next';

import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { SubscriptionPricingFormFields } from '../../../styledComponents/SubscriptionFormPage/FormSections/AirAmbulanceSubscription/SubscriptionPricingForm/subscriptionPricingForm.models';
import { useEditKESubscriptionPage } from './editKESubscriptionPage.hooks';
import EditKESubscriptionForm from './EditKESubscriptionForm/EditKESubscriptionForm';

const EditKESubscriptionPage = () => {
  const { t } = useTranslation();
  const {
    organizationName,
    subscriptionType,
    loadDatesForNewSubscriptionHandler,
  } = useEditKESubscriptionPage();

  return (
    <FormErrorProvider
      initErrorGroups={[SubscriptionPricingFormFields.Pricing]}
    >
      <AddContractModal />
      <Header
        title={t('headers.edit-product-subscription', {
          organizationName,
          subscriptionType,
        })}
      />

      <EditKESubscriptionForm
        onContractChanged={loadDatesForNewSubscriptionHandler}
      />
    </FormErrorProvider>
  );
};

export default EditKESubscriptionPage;
