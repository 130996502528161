import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { addressSchema } from '../FormAddress/formAddress.validation';
import {
  FormAddressInformationsFields,
  FormAddressInformationsFieldsType,
} from './formAddressInformations.models';

export const formAddressInformationsSchema = (
  fields: Record<
    FormAddressInformationsFieldsType,
    string
  > = FormAddressInformationsFields,
): AnySchema | any => ({
  type: 'object',
  properties: {
    [fields.ResidenceAddress]: addressSchema,
  },
  dependencies: {
    [fields.CorrespondenceAddressSameAsResidenceAddress]: {
      if: {
        properties: {
          [fields.CorrespondenceAddressSameAsResidenceAddress]: {
            enum: [false],
          },
        },
      },
      then: {
        properties: {
          [fields.CorrespondenceAddress]: addressSchema,
        },
      },
    },
  },
  required: [],
});

export const validateAddressInformationsForm = (
  data: any,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, formAddressInformationsSchema());
