import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useApiResponseError } from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';

import { updateModalState } from '../../../../../store/redux-slices/modals';
import { CREDIT_CARD_REPLACE_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { replaceExistingCreditCard } from '../../../../../store/saga-slices/payment';
import { IReplaceExistingCreditCardPayload } from '../../../../../store/saga-slices/payment/model';
import { REPLACE_CREDIT_CARD_MODAL_NAME } from '../replaceCreditCardModal.const';
import { ICardSetupIntentModel } from '../../../../../models/ICardSetupIntentModel';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { ICreditCardInfo } from '../../../../../models/ICreditCardInfo';

type UseReplaceCreditCardModalForm = {
  onReplacedCreditCard?: () => void;
};

export const useReplaceCreditCardModalForm = ({
  onReplacedCreditCard,
}: UseReplaceCreditCardModalForm) => {
  const UpdateModalState = useActions(updateModalState);
  const ReplaceCreditCard = useActions(replaceExistingCreditCard);
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const creditCardReplacing = useProcessing(CREDIT_CARD_REPLACE_PROCESSING);

  const { handleResponseError } = useApiResponseError();

  const stripe = useStripeProvider();

  const { personId, creditCardId }: { personId: string; creditCardId: string } =
    useModalDetails(REPLACE_CREDIT_CARD_MODAL_NAME);

  const submitHandler = (values: ICreditCardInfo) => {
    const payload: IReplaceExistingCreditCardPayload = {
      cancellationToken,
      creditCardId,
      personId,
      success: async (setupIntent: ICardSetupIntentModel) => {
        stripe.submitCardWithStripe(
          setupIntent,
          values,
          () => {
            alert.success(t('phrase.credit-card-was-replaced-successfully'));
            UpdateModalState(REPLACE_CREDIT_CARD_MODAL_NAME);
            onReplacedCreditCard?.();
          },
          () =>
            alert.error(t('phrase.credit-card-was-not-replaced-successfully')),
        );
      },
      error: (err) => {
        alert.error(t('phrase.credit-card-was-not-replaced-successfully'));
        handleResponseError(err);
      },
    };

    ReplaceCreditCard(payload);
  };

  const onCancelHandler = () => {
    UpdateModalState(CREDIT_CARD_REPLACE_PROCESSING);
  };

  return { onCancelHandler, submitHandler, creditCardReplacing };
};
