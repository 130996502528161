import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { ProductType } from '../../../models/ProductType';
import { ProductSubscriptionRenewPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import { organizationSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  AA_PRICING_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import {
  clearAACurrentSubscriptionEditing,
  clearAASubscriptionHelperData,
} from '../../../store/redux-slices/subscriptions/airAmbulance';
import {
  aaSubscriptionRenewalSuggestionSelector,
  editingAASubscriptionDataSelector,
} from '../../../store/redux-slices/subscriptions/airAmbulance/selectors';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { aaLoadStandardPricing } from '../../../store/saga-slices/pricing/airAmbulance';
import {
  loadAASubscriptionRenewalSuggestion,
  loadDatesForNewAASubscription,
  loadEditingAASubscription,
} from '../../../store/saga-slices/subscriptions/airAmbulance';
import {
  ILoadEditingAASubscriptionPayload,
  ILoadAASubscriptionRenewalSuggestionPayload,
} from '../../../store/saga-slices/subscriptions/airAmbulance/models';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';

export const useAAEditSubscriptionRenewPage = () => {
  const { organizationId, subscriptionId, productType } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const LoadOrganization = useActions(loadOrganization);
  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewAASubscription);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadEditingSubscription = useActions(loadEditingAASubscription);
  const LoadStandardPricing = useActions(aaLoadStandardPricing);
  const ClearAASubscriptionHelperData = useActions(
    clearAASubscriptionHelperData,
  );
  const subscriptionData = useSelector(editingAASubscriptionDataSelector);

  const ClearCurrentSubscriptionEditing = useActions(
    clearAACurrentSubscriptionEditing,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);
  const LoadSubscriptionRenewalSuggestion = useActions(
    loadAASubscriptionRenewalSuggestion,
  );

  const subscriptionRenewalSuggestion = useSelector(
    aaSubscriptionRenewalSuggestionSelector,
  );

  const isOrganizationDataLoading = useProcessing(
    ORGANIZATION_LOADING_PROCESSING,
  );

  const isEditingDataLoading = useProcessing(
    SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  );

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const isPricingDataLoading = useProcessing(AA_PRICING_LOADING_PROCESSING);
  const organization = useSelector(organizationSelector);

  const loadSubscriptionRenewalSuggestionHandler = useCallback(() => {
    const payload: ILoadAASubscriptionRenewalSuggestionPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionRenewalSuggestion(payload);
  }, [LoadSubscriptionRenewalSuggestion, organizationId, subscriptionId]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    const payload: ILoadEditingAASubscriptionPayload = {
      organizationId,
      subscriptionId,
      subscriptionType: productType,
    };

    LoadEditingSubscription(payload);
  }, [LoadEditingSubscription, organizationId, productType, subscriptionId]);

  useEffect(() => {
    LoadOrganizationContracts({ organizationId });
    LoadOrganization(organizationId);
    loadSubscriptionRenewalSuggestionHandler();

    LoadStandardPricing({ productType: ProductType.AirAmbulance });
  }, [
    LoadOrganization,
    LoadOrganizationContracts,
    organizationId,
    subscriptionId,
    LoadStandardPricing,
    loadSubscriptionRenewalSuggestionHandler,
  ]);

  useEffect(
    () => () => {
      ClearAASubscriptionHelperData();
      ClearOrganizationContracts();
      ClearSubscriptionHelperData();
      ClearCurrentSubscriptionEditing();
    },
    [
      ClearOrganizationContracts,
      ClearAASubscriptionHelperData,
      ClearSubscriptionHelperData,
      ClearCurrentSubscriptionEditing,
    ],
  );

  const dataLoading =
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionData ||
    (!subscriptionData && isEditingDataLoading) ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading) ||
    isPricingDataLoading;

  return {
    dataLoading,
    organization,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  };
};
