import { useTranslation } from 'react-i18next';

import { StyledReportsSubtitle } from '../../ReportsPage.styles';
import RawDataReportsForm from './RawDataReportsForm/RawDataReportsForm';

const RawDataTabPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledReportsSubtitle>
        {t('headers.generate-report')}
      </StyledReportsSubtitle>
      <RawDataReportsForm />
    </div>
  );
};

export default RawDataTabPage;
