import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../hooks/useActions';
import { useProcessing } from '../../hooks/useProcessing';
import { organizationSelector } from '../../store/redux-slices/organizations/selectors';
import { ORGANIZATION_LOADING_PROCESSING } from '../../store/redux-slices/processes/constants';
import { loadOrganization } from '../../store/saga-slices/organizations';
import { tabsNavigationArray } from './clientOverviewPage.models';

export const useClientOverviewPage = () => {
  const { id } = useParams();
  const LoadOrganization = useActions(loadOrganization);
  const organizationIsLoading = useProcessing(ORGANIZATION_LOADING_PROCESSING);
  const organization = useSelector(organizationSelector);

  const tabPages = useMemo(
    () => tabsNavigationArray(organization?.organizationType),
    [organization?.organizationType],
  );

  useEffect(() => {
    LoadOrganization(id);
  }, [LoadOrganization, id]);

  return {
    organizationIsLoading,
    organization,
    tabPages,
  };
};
