import { useEffect, useMemo } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { ProductType } from '../../../../models/ProductType';

import { subscriptionDatesSelector } from '../../../../store/redux-slices/subscriptions/common/selectors';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';
import { prepareProductsOptions } from '../../../../utils/productsHelpers';
import { BasicSubscriptionFormFields } from './basicSubscriptionForm.models';

type UseBasicSubscriptionFormProps = {
  name?: string;
  availableProducts?: ProductType[];
};

export const useBasicSubscriptionForm = ({
  name,
  availableProducts,
}: UseBasicSubscriptionFormProps) => {
  const {
    input: { value: productType },
  } = useField(getFormGroupPath(name, BasicSubscriptionFormFields.ProductType));

  const productList = useMemo(() => {
    if (productType && availableProducts) {
      return prepareProductsOptions([...availableProducts, productType]);
    }
    if (productType) {
      return prepareProductsOptions([productType]);
    }

    return prepareProductsOptions(availableProducts);
  }, [availableProducts, productType]);

  const subscriptionDates = useSelector(subscriptionDatesSelector(productType));

  const {
    input: { onChange: onEffectiveDateFromChange },
  } = useField(
    getFormGroupPath(name, BasicSubscriptionFormFields.EffectiveDateFrom),
  );
  const {
    input: { onChange: onEffectiveDateToChange },
  } = useField(
    getFormGroupPath(name, BasicSubscriptionFormFields.EffectiveDateTo),
  );

  useEffect(() => {
    if (
      !subscriptionDates?.effectiveDateFrom ||
      !subscriptionDates?.effectiveDateTo
    ) {
      return;
    }
    onEffectiveDateFromChange(subscriptionDates?.effectiveDateFrom);
    onEffectiveDateToChange(subscriptionDates?.effectiveDateTo);
  }, [onEffectiveDateFromChange, onEffectiveDateToChange, subscriptionDates]);

  return {
    productList,
    contractDateRangeIsFull: subscriptionDates?.contractDateRangeIsFull,
  };
};
