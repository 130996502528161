import { AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { SR_PRICING_LOADING_PROCESSING } from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  ILoadStandardPricingPayload,
  IStandardPricingQueryResponse,
} from '../common/models';
import { getSRStandardPricingQuery } from './api';
import { setSRMembershipPlansPrices } from '../../../redux-slices/pricing/securityResponse';

const srPricingSlice = createSliceSaga({
  name: 'sr-pricing-saga',
  caseSagas: {
    *srLoadStandardPricing({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadStandardPricingPayload>) {
      try {
        yield put(updateProcessState(SR_PRICING_LOADING_PROCESSING));

        const response: AxiosResponse<IStandardPricingQueryResponse> =
          yield* call(getSRStandardPricingQuery, cancellationToken);

        yield put(
          setSRMembershipPlansPrices(response.data.membershipPlansPrices),
        );

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(SR_PRICING_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default srPricingSlice.saga;
export const { srLoadStandardPricing } = srPricingSlice.actions;
export const { actions } = srPricingSlice;
