import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../Form/Form';
import FormCheckbox from '../../UI/Form/FormCheckbox/FormCheckbox';

export const StyledFormCheckBox = styled(FormCheckbox)`
  margin-top: 2rem;
`;

export const Wrapper = styled(SectionWrapper)`
  margin-top: 0;
`;
