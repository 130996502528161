import { Form, Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { StyledReportsForm } from './financeReportsForm.styles';
import { useReportsFormHooks } from './financeReportsForm.hooks';
import ReportsGenerateButton from '../../../../../styledComponents/UI/ReportsGenerateButton/ReportsGenerateButton';
import { validateFinanceReportSchemaForm } from './financeReportsForm.validation';
import { FinanceReportFields } from './financeReportsForm.models';
import {
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_SHORT_DATE_MASK,
} from '../../../../../utils/dateHelpers';

const ReportsForm = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useReportsFormHooks();

  return (
    <div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateFinanceReportSchemaForm}
      >
        {({ dirty, values, ...renderProps }) => (
          <StyledReportsForm onSubmit={renderProps.handleSubmit}>
            <Field
              label={t('fields.month.label')}
              component={FormDatePicker}
              name={FinanceReportFields.Date}
              views={['month', 'year']}
              formatMask={DEFAULT_SHORT_DATE_FORMAT}
              mask={DEFAULT_SHORT_DATE_MASK}
            />
            <ReportsGenerateButton />
          </StyledReportsForm>
        )}
      </Form>
    </div>
  );
};

export default ReportsForm;
