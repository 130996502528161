import styled from '@emotion/styled/macro';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    border-bottom: 0.0625rem solid ${({ theme }) => theme.color.light1};
  }

  .MuiTab-textColorPrimary {
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.font.gotham};
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 2rem;
    letter-spacing: 0.0125rem;
    text-transform: capitalize;
    padding: 0.75rem 1.5rem;
    margin: 0;
  }

  .Mui-selected {
    color: ${({ theme }) => theme.color.lighter_blue} !important;
  }

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.color.lighter_blue};
    height: 0.125rem !important;
  }
`;
