import { MembershipPlanPeriodsTermType } from '../../../../../models/enums/MembershipPlanPeriodsTermType';
import { MembershipPlanPeriodsType } from '../../../../../models/enums/MembershipPlanPeriodsType';

export const isAutoRenewal = (currentOffer: MembershipPlanPeriodsType) => {
  switch (currentOffer) {
    case MembershipPlanPeriodsType.Days_30:
    case MembershipPlanPeriodsType.Days_14:
      return false;
    case MembershipPlanPeriodsType.Annual:
      return true;
    default:
      return true;
  }
};

export const mapToTermType = (
  currentOffer?: MembershipPlanPeriodsType | null | undefined | unknown,
) => {
  switch (currentOffer) {
    case MembershipPlanPeriodsType.Days_30:
      return MembershipPlanPeriodsTermType.Days_30;
    case MembershipPlanPeriodsType.Days_14:
      return MembershipPlanPeriodsTermType.Days_14;
    case MembershipPlanPeriodsType.Annual:
      return MembershipPlanPeriodsTermType.Annual;
    default:
      return MembershipPlanPeriodsTermType.Annual;
  }
};
