import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormSelect } from '../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { getFieldPropertiesForGroup } from '../../../../../../../utils/getFormGroupPath';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';
import { useMembershipRetailDetails } from './aaMembershipRetailsDetailsForm.hooks';
import { FormColumn } from '../../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../../styledComponents/UI/Form/FormRow/FormRow';
import { FormInput } from '../../../../../../../styledComponents/UI/Form/FormInput/FormInput';
import FormDatePicker from '../../../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import FormValue from '../../../../../../../styledComponents/UI/Form/FormValue/FormValue';
import {
  MembershipRetailDetailsFormFields,
  MembershipRetailDetailsFormName,
} from './aaMembershipRetailsDetailsForm.models';
import { SectionWrapper } from '../../../../../../../styledComponents/Form/Form';
import { CustomForm } from '../../../../../../../models/types/CustomForm';

const MembershipRetailsDetailsForm = ({ className, disabled }: CustomForm) => {
  const { t } = useTranslation();
  const { plansPromiseOptions, isRenewMembership, isDateChangeable } =
    useMembershipRetailDetails();

  return (
    <SectionWrapper className={className}>
      <FormColumn>
        {isRenewMembership && (
          <Field
            component={FormValue}
            name={MembershipRetailDetailsFormFields.RenewedMembershipId}
          />
        )}
        <FormRow isSubRow>
          <Field
            component={FormSelect}
            disabled={disabled}
            isLabelHidden
            {...getFieldPropertiesForGroup({
              inputId: MembershipRetailDetailsFormName,
              name: MembershipRetailDetailsFormFields.MembershipPlanId,
            })}
            promiseOptions={plansPromiseOptions}
            placeholder={t('fields.membershipPlanId.placeholder')}
            valueIdSelector={(value: IOrganizationSubscriptionPlanModel) =>
              value.membershipPlanId
            }
          />
          <Field
            name={MembershipRetailDetailsFormFields.SearchedPromoCode}
            component={FormValue}
          />
          <Field
            component={FormInput}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: MembershipRetailDetailsFormName,
              name: MembershipRetailDetailsFormFields.PromoCode,
            })}
            changeValueAfterBlur
          />
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormDatePicker}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: MembershipRetailDetailsFormName,
              name: MembershipRetailDetailsFormFields.EffectiveDate,
            })}
          />
          <Field
            component={FormDatePicker}
            disabled={disabled}
            readOnly={isDateChangeable}
            {...getFieldPropertiesForGroup({
              inputId: MembershipRetailDetailsFormName,
              name: MembershipRetailDetailsFormFields.ExpirationDate,
            })}
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default MembershipRetailsDetailsForm;
