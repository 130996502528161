import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export enum KEMembersListFilters {
  Search = 'search',
  Plan = 'plan',
  MemberType = 'memberType',
  RenewStatus = 'renewStatus',
  SubscriptionId = 'subscriptionId',
  OversubscribedOnly = 'oversubscribedOnly',
}

export type KEMembersFiltersProps = {
  availableFilters: KEMembersListFilters[];
  standardPricing?: IMembershipPlanPriceModel[];
};
