import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';

import { useModalDetails } from '../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { IOrganizationGGPointOfContactModel } from '../../../../../../models/CreateOrganizationGGPointOfContact';
import { employeeListSelector } from '../../../../../../store/redux-slices/employees/selectors';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import {
  organizationGGPointOfContactByIdSelector,
  organizationIdSelector,
} from '../../../../../../store/redux-slices/organizations/selectors';
import {
  EMPLOYEES_LOADING_PROCESSING,
  GG_ROLES_LOADING_PROCESSING,
  ORGANIZATION_EDITING_PROCESSING,
} from '../../../../../../store/redux-slices/processes/constants';
import { loadEmployees } from '../../../../../../store/saga-slices/employees';
import {
  editGGContact,
  IGGContactPayload,
} from '../../../../../../store/saga-slices/organizations';
import { validateGGPointOfContactForm } from '../../../../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactForm/ggPointOfContact.validation';
import { EDIT_GG_POINT_OF_CONTACT_MODAL_NAME } from './editGGPointOfContactModal.const';
import { EditGGPointOfContactModalDetails } from './editGGPointOfContactModal.models';
import { ggRolesOptionsSelector } from '../../../../../../store/redux-slices/roles/selectors';
import { ILoadGGRolesPayload } from '../../../../../../store/saga-slices/roles/models';
import { useCancellationToken } from '../../../../../../hooks/useTokenCancellation';
import { clearGGRoles } from '../../../../../../store/redux-slices/roles';
import { loadGGRoles } from '../../../../../../store/saga-slices/roles';

export const useEditGGPointOfContact = () => {
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);
  const LoadEmployees = useActions(loadEmployees);
  const EditGGContact = useActions(editGGContact);
  const LoadGGRoles = useActions(loadGGRoles);
  const ClearGGRoles = useActions(clearGGRoles);

  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);
  const employeesLoading = useProcessing(EMPLOYEES_LOADING_PROCESSING);

  const cancellationToken = useCancellationToken();

  const { contactId }: EditGGPointOfContactModalDetails = useModalDetails(
    EDIT_GG_POINT_OF_CONTACT_MODAL_NAME,
  );
  const employeeList = useSelector(employeeListSelector);
  const organizationId = useSelector(organizationIdSelector);
  const ggPointOfContact = useSelector(
    organizationGGPointOfContactByIdSelector(contactId),
  );

  const modalShowHandler = useCallback(() => {
    LoadEmployees();
  }, [LoadEmployees]);

  useEffect(() => {
    const payload: ILoadGGRolesPayload = {
      cancellationToken,
    };
    LoadGGRoles(payload);
    return () => {
      ClearGGRoles();
    };
  }, [LoadGGRoles, cancellationToken, ClearGGRoles]);

  const rolesGGLoading = useProcessing(GG_ROLES_LOADING_PROCESSING);
  const rolesGGList = useSelector(ggRolesOptionsSelector);

  const handleSubmit = (values: IOrganizationGGPointOfContactModel) => {
    const payload: IGGContactPayload = {
      contact: values,
      organizationId,
      contactId,
      success: () => {
        alert.success(t('alerts.poc-edit-success'));

        UpdateModalState(EDIT_GG_POINT_OF_CONTACT_MODAL_NAME);
      },
      error: (err) => {
        alert.error(t('alerts.poc-edit-fail'));
      },
    };

    EditGGContact(payload);
  };

  const validateForm = (values: IOrganizationGGPointOfContactModel) =>
    validateGGPointOfContactForm(values);

  const onCancelHandler = () =>
    UpdateModalState(EDIT_GG_POINT_OF_CONTACT_MODAL_NAME);
  const initState = ggPointOfContact && {
    employeeId: ggPointOfContact.employeeId,
    internalNotes: ggPointOfContact.internalNotes,
    roles: ggPointOfContact.roles,
  };

  return {
    handleSubmit,
    modalShowHandler,
    organizationUpdating,
    initState,
    validateForm,
    onCancelHandler,
    employeeList,
    employeesLoading,
    rolesGGLoading,
    rolesGGList,
  };
};
