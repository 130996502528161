import { useEffect } from 'react';

import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { usePermissions } from '../../hooks/usePermissions';
import { RoutePath } from '../../models/enums/RoutePath';
import { ProductType } from '../../models/ProductType';
import { AvailableProductComponentType } from '../../models/types/AvailableProductComponentType';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';

type ProductViewContentProps = {
  productType: ProductType;
  viewsPermissions?: AvailableProductPermissionsType;
  viewsPages: AvailableProductComponentType;
};

const ProductViewContent = ({
  productType,
  viewsPermissions,
  viewsPages,
}: ProductViewContentProps) => {
  const navigate = useNavigateSearch();

  const { hasPermissions } = usePermissions(viewsPermissions[productType]);

  useEffect(() => {
    if (!hasPermissions) {
      navigate(RoutePath.Home);
    }
  }, [hasPermissions, navigate]);

  const Content = viewsPages[productType];
  return <>{Content && <Content />}</>;
};

export default ProductViewContent;
