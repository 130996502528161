import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;
  min-height: 11rem;
  min-width: 12rem;
`;

export const Title = styled.div`
  font-size: normal normal 0.875rem/1.25rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
`;

export const TitleColumn = styled.div`
  font: normal normal 0.75rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => transparentize(0.5, theme.color.white)};
  padding-bottom: 0.25rem;
`;

export const PriceTitle = styled(TitleColumn)`
  text-align: left;
  padding-left: 2rem;
`;

export const SeatsAmountTitle = styled(TitleColumn)`
  text-align: right;
`;

export const Value = styled.div`
  font: normal normal 0.75rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};

  &:not(:nth-last-of-type(-n + 2)) {
    border-bottom: 1px solid
      ${({ theme }) => transparentize(0.8, theme.color.white)};
  }
`;

export const PriceValue = styled(Value)`
  text-align: left;
  padding-left: 2rem;
  min-width: 6rem;
`;

export const SeatsAmountValue = styled(Value)`
  text-align: right;
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;
