import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Button from '../UI/Button/Button';
import { ConfirmModalButtonType } from './confirmModal.models';

export const Header = styled.h2`
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 1.5rem;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0 1rem;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2.5rem;
  gap: 1.5rem;
`;

type StyledButtonProps = {
  buttonType?: ConfirmModalButtonType;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  min-width: 9.375rem;
  padding: 0.5rem 1rem;
  ${({ buttonType }) => {
    switch (buttonType) {
      case 'done':
        return css`
          margin-right: 1.5rem;
        `;
    }
  }}
`;
