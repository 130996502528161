import styled from '@emotion/styled/macro';

import { css } from '@emotion/react';

type WrapperProps = {
  layout?: 'horizontal' | 'vertical';
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  gap: 0.5rem;
  ${({ layout }) =>
    layout === 'vertical'
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
          > && {
            margin-bottom: 0;
          }
        `}
`;

export const Title = styled.div`
  font: 700 0.75rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark2};
  letter-spacing: 0.013rem;
  white-space: nowrap;
`;

type ValueType = {
  bold?: boolean;
};

export const Value = styled.div<ValueType>`
  font: normal normal 0.875rem/2rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
  ${({ bold, theme }) =>
    bold &&
    css`
      font: normal 500 1.125rem/2rem ${theme.font.gotham};
      color: ${theme.color.black};
    `}
`;
