import styled from '@emotion/styled/macro';

import MaterialTable from '../../../../../../styledComponents/MaterialTable/MaterialTable';
import { StyledTableCell } from '../../../../../../styledComponents/MaterialTable/TableContent/tableContent.styles';

export const StyledMaterialTable = styled(MaterialTable)`
  ${StyledTableCell} {
    width: 9rem;
  }
`;

export const Total = styled.div`
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
`;

export const Seats = styled.div`
  font: normal bold 1.125rem/1.25rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;
