import MonthsRangeFilter from './MonthsRangeFilter/MonthsRangeFilter';
import DatesRangeFilter from './DatesRangeFilter/DatesRangeFilter';
import SingleDateFilter from './SingleDateFilter/SingleDateFilter';
import { CustomForm } from '../../../../../../../../models/types/CustomForm';
import { FilterDefinition } from '../../../../renewalFilters.models';
import { DateFilterType } from '../../../../../../../../models/enums/RenewalFilters/DateFilterType';

type DateFilterTypeProp = CustomForm & {
  type: DateFilterType;
  filterDefinition: FilterDefinition<void>;
};
const DateFilterTypes = ({ type, ...props }: DateFilterTypeProp) => {
  switch (type) {
    case DateFilterType.MonthsRange:
      return <MonthsRangeFilter {...props} />;
    case DateFilterType.DatesRange:
      return <DatesRangeFilter {...props} />;
    case DateFilterType.FixedPeriod:
      return <SingleDateFilter {...props} />;
    default:
      return null;
  }
};

export default DateFilterTypes;
