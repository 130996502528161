import { IOrganizationSubscription } from 'models/interfaces/Subscription/OrganizationSubscription';

import SubscriptionsListItem from './SubscriptionsListItem/SubscriptionsListItem';
import { CustomComponent } from '../../models/types/CustomComponent';
import { Wrapper } from './subscriptionList.styles';
import { SubscriptionsListViewDetailsPermissions } from './subscriptionList.const';

interface ISubscriptionsListProps extends CustomComponent {
  items: IOrganizationSubscription[];
  onItemClick?: (subscription?: IOrganizationSubscription) => void;
  propertiesWidth?: Partial<Record<keyof IOrganizationSubscription, number>>;
}

const SubscriptionsList = ({
  className,
  items,
  onItemClick,
  propertiesWidth,
}: ISubscriptionsListProps) => (
  <Wrapper className={className}>
    {items &&
      items.map((item: IOrganizationSubscription) => (
        <SubscriptionsListItem
          productViewSubscriptionPermissions={
            SubscriptionsListViewDetailsPermissions
          }
          propertiesWidth={propertiesWidth}
          data={item}
          key={item.id}
          onItemClick={onItemClick}
        />
      ))}
  </Wrapper>
);

export default SubscriptionsList;
