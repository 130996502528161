import { useTranslation } from 'react-i18next';

import OverviewOptions from '../../../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLine,
  Wrapper,
} from './docSubscriptionOverviewInformation.styles';

import {
  canRenewDOCSubscriptionPermissions,
  canUpdateDOCSubscriptionsDetailsPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';

import { IDOCOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { DutyOfCareSubscriptionOptions } from '../../../../../../models/enums/DutyOfCareSubscriptionOptions';
import { useDOCSubscriptionOverviewInformation } from './docSubscriptionOverviewInformation.hooks';
import DOCOverviewDetails from './DOCOverviewDetails/DOCOverviewDetails';

interface IProductSubscriptionOverviewInformationProps {
  onEditOptionsClick?: () => void;
  onEditRenewalStatus?: () => void;
  subscriptionOverview: IDOCOrganizationSubscriptionOverview;
  canEditOptions?: boolean;
  canRenew?: boolean;
}
const DOCSubscriptionOverviewInformation = ({
  onEditOptionsClick,
  onEditRenewalStatus,
  subscriptionOverview,
  canEditOptions,
  canRenew,
}: IProductSubscriptionOverviewInformationProps) => {
  const { t } = useTranslation();
  const { renewClickHandler } = useDOCSubscriptionOverviewInformation();

  return (
    <Wrapper>
      <DOCOverviewDetails
        data={subscriptionOverview}
        canRenewMembershipPermissions={canRenewDOCSubscriptionPermissions}
        onRenewClick={canRenew && renewClickHandler}
        onEditRenewalStatus={onEditRenewalStatus}
      />
      <StyledLine />
      <OverviewOptions
        options={[
          {
            value: DutyOfCareSubscriptionOptions.AutoRenewal,
            label: t('phrase.auto-renew-product-subscription'),
          },
        ]}
        data={subscriptionOverview}
        onEditOptionsClick={canEditOptions && onEditOptionsClick}
        canEditOptionsPermissions={canUpdateDOCSubscriptionsDetailsPermissions}
      />
    </Wrapper>
  );
};

export default DOCSubscriptionOverviewInformation;
