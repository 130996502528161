import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useApiResponseFail } from '../../../../hooks/useApiResponseFail';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { IValidationErrorResponse } from '../../../../models/ValidationErrorResponse';
import { IWarningRatification } from '../../../../models/interfaces/WarningRatification';
import { organizationContractsSelector } from '../../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { organizationSelector } from '../../../../store/redux-slices/organizations/selectors';
import {
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { parseDate } from '../../../../utils/dateHelpers';
import { ProductSubscriptionRenewPageParamsType } from '../../../../models/types/RoutePath';
import { ERRORS_FOR_SHOW_ANALYSE_MODAL } from './keSubscriptionRenewPageForm.const';
import { RenewKESubscriptionModel } from '../../../../models/types/KidnapAndExtortion/RenewKESubscriptionModel';
import { renewKESubscription } from '../../../../store/saga-slices/subscriptions/kidnapAndExtortion';
import { keSubscriptionRenewalSuggestionSelector } from '../../../../store/redux-slices/subscriptions/kidnapAndExtortion/selectors';
import { IRenewKESubscriptionPayload } from '../../../../store/saga-slices/subscriptions/kidnapAndExtortion/models';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { SUBSCRIPTION_RENEW_ANALYSE_MODAL } from '../../../../styledComponents/Modals/SubscriptionAnalyseModal/subscriptionAnalyse.consts';
import { AddContractModalFormDetails } from '../../../../styledComponents/Modals/AddContractModal/AddContractModalForm/addContractModalForm.models';
import { ADD_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/AddContractModal/addContractModal.const';
import { needShowAnalyseModal } from '../../../../styledComponents/Modals/SubscriptionAnalyseModal/subscriptionAnalyse.utils';

export const useKESubscriptionRenewForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigateSearch();

  const { organizationId, subscriptionId, productType } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);

  const RenewSubscription = useActions(renewKESubscription);

  const isSubscriptionUpdating = useProcessing(SUBSCRIPTION_RENEW_PROCESSING);

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const { handleResponse } = useApiResponseFail();

  const organization = useSelector(organizationSelector);
  const organizationContracts = useSelector(organizationContractsSelector);
  const subscriptionRenewalSuggestion = useSelector(
    keSubscriptionRenewalSuggestionSelector,
  );

  const initState = subscriptionRenewalSuggestion && {
    effectiveDateFrom: parseDate(
      subscriptionRenewalSuggestion.effectiveDateFrom,
    ),
    effectiveDateTo: parseDate(subscriptionRenewalSuggestion.effectiveDateTo),
    autoRenewal: subscriptionRenewalSuggestion.autoRenewal,
  };

  const renewSubscriptionHandler = useCallback(
    (
      values: RenewKESubscriptionModel,
      ratifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: RenewKESubscriptionModel = {
        ...values,
        ratifications,
      };

      const payload: IRenewKESubscriptionPayload = {
        data: extendedValues,
        organizationId,
        subscriptionId,
        success: (data: IOrganizationSubscription) => {
          navigate(
            RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
            {
              params: {
                [ProductSubscriptionOverviewPageParams.OrganizationId]:
                  organizationId,
                [ProductSubscriptionOverviewPageParams.SubscriptionId]: data.id,
                [ProductSubscriptionOverviewPageParams.SubscriptionType]:
                  productType,
              },
            },
          );
          alert.success(t('alerts.subscription-renew-success'));
        },
        error: (err: IValidationErrorResponse) => {
          if (needShowAnalyseModal(err, ERRORS_FOR_SHOW_ANALYSE_MODAL)) {
            UpdateModalState(SUBSCRIPTION_RENEW_ANALYSE_MODAL);
            return;
          }

          handleResponse({
            err,
            values,
            ratifications,
            skipErrors: ERRORS_FOR_SHOW_ANALYSE_MODAL,
            alertErrorMessage: t('alerts.subscription-renew-fail'),
          });
        },
      };
      RenewSubscription(payload);
    },
    [
      RenewSubscription,
      UpdateModalState,
      alert,
      handleResponse,
      navigate,
      organizationId,
      productType,
      subscriptionId,
      t,
    ],
  );

  const handleSubmit = (values: RenewKESubscriptionModel) => {
    renewSubscriptionHandler(values);
  };

  const onAddNewContractHandler = () => {
    const details: AddContractModalFormDetails = {
      organizationId,
    };
    UpdateModalState([ADD_CONTRACT_MODAL_NAME, details]);
  };

  return {
    handleSubmit,
    organization,
    isSubscriptionUpdating,
    organizationContracts,
    canChangeContract:
      subscriptionRenewalSuggestion?.metadata?.canChangeContract,
    isSuggestionDataLoading,
    initState,
    UpdateModalState,
    renewSubscriptionHandler,
    productType,
    onAddNewContractHandler,
  };
};
