import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useActions } from '../../hooks/useActions';
import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { useRouteParams } from '../../hooks/useRouteParams';
import { useCancellationToken } from '../../hooks/useTokenCancellation';
import { RoutePath } from '../../models/enums/RoutePath';
import {
  clearCreateMemberProcess,
  loadMembershipRenewalSuggestions,
  loadPrimaryMember,
} from '../../store/saga-slices/membership/common';
import {
  ILoadMembershipRenewalSuggestionsPayload,
  ILoadPrimaryMemberPayload,
} from '../../store/saga-slices/membership/common/models';
import { AddMemberAvailableSearchParamsType } from '../../models/types/RoutePath';

export const useAddMember = () => {
  const navigate = useNavigateSearch();
  const location = useLocation();
  const ClearCreateMemberProcess = useActions(clearCreateMemberProcess);
  const LoadMembershipRenewalSuggestions = useActions(
    loadMembershipRenewalSuggestions,
  );

  const cancellationToken = useCancellationToken();

  const LoadPrimaryMember = useActions(loadPrimaryMember);

  useEffect(() => {
    if (location.pathname === RoutePath.AddMember) {
      ClearCreateMemberProcess();
    }
  }, [ClearCreateMemberProcess, location]);

  const { membershipId, personId, productType } =
    useRouteParams<AddMemberAvailableSearchParamsType>();

  useEffect(() => {
    if (!membershipId || !productType) {
      return;
    }
    const payload: ILoadMembershipRenewalSuggestionsPayload = {
      membershipId,
      productType,
    };
    LoadMembershipRenewalSuggestions(payload);
  }, [LoadMembershipRenewalSuggestions, membershipId, productType]);

  useEffect(() => {
    if (!personId || !!membershipId) {
      return;
    }
    const payload: ILoadPrimaryMemberPayload = {
      personId,
      cancellationToken,
    };
    LoadPrimaryMember(payload);
  }, [LoadPrimaryMember, cancellationToken, membershipId, personId]);

  useEffect(() => () => ClearCreateMemberProcess(), [ClearCreateMemberProcess]);

  useEffect(() => {
    if (!location.pathname.includes(RoutePath.Wizard)) {
      navigate(RoutePath.Wizard, { redirectSearchParams: true });
    }
  }, [location, navigate]);
};
