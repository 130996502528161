import { useTranslation } from 'react-i18next';

import SimplePageHeader from '../../styledComponents/SimplePage/SimplePageHeader/SimplePageHeader';
import { tabsNavigation } from './ReportsPage.models';
import Spinner from '../../styledComponents/Spinner/Spinner';
import { useProcessing } from '../../hooks/useProcessing';
import {
  AA_RAW_DATA_REPORTS_PROCESSING,
  KE_RAW_DATA_REPORTS_PROCESSING,
  SUBSCRIPTION_FINANCE_REPORTS_PROCESSING,
  RETAIL_FINANCE_REPORTS_PROCESSING,
  AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING,
  AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING,
  SR_RAW_DATA_REPORTS_PROCESSING,
  DOC_RAW_DATA_REPORTS_PROCESSING,
} from '../../store/redux-slices/processes/constants';
import { PageSection } from '../../styledComponents/Page/Page';
import TabNavigation from '../../styledComponents/UI/TabNavigation/TabNavigation';

import ReportsRoutes from './reportsPage.routes';

const ReportsPage = () => {
  const { t } = useTranslation();

  const creatingReport = useProcessing([
    AA_RAW_DATA_REPORTS_PROCESSING,
    KE_RAW_DATA_REPORTS_PROCESSING,
    SR_RAW_DATA_REPORTS_PROCESSING,
    DOC_RAW_DATA_REPORTS_PROCESSING,
    SUBSCRIPTION_FINANCE_REPORTS_PROCESSING,
    RETAIL_FINANCE_REPORTS_PROCESSING,
    AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING,
    AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING,
  ]);

  return (
    <div>
      <SimplePageHeader title={t('headers.reports')} />
      <PageSection>
        <Spinner isVisible={creatingReport} size="20%">
          <TabNavigation routeMode tabs={tabsNavigation}>
            <ReportsRoutes />
          </TabNavigation>
        </Spinner>
      </PageSection>
    </div>
  );
};

export default ReportsPage;
