import { t } from 'i18next';
import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { SubscriptionDetailsFormFields } from './subscriptionDetailsForm.models';

interface IValidateSubscriptionDetailsForm {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  promoCode: string;
  subscriptionType: string;
}

export const subscriptionDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [SubscriptionDetailsFormFields.PromoCode]: {
      errorMessage: t('validation.should-not-be-shorter'),
      type: 'string',
      maxLength: 30,
    },
  },
  required: [SubscriptionDetailsFormFields.PromoCode],
};

export const validateSubscriptionDetailsForm = (
  data: IValidateSubscriptionDetailsForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionDetailsSchema);
