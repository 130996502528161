import { components, InputProps } from 'react-select';

type Props = InputProps & {
  selectProps: {
    name: string;
  };
};

export const Input = (props: Props) => {
  const {
    selectProps: { name },
  } = props;
  return <components.Input name={name} {...props} />;
};
