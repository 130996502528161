import { Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { FormWrapper } from '../../../Form/Form';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../../UI/Form/FormInput/FormInput';
import { CustomForm } from '../../../../models/types/CustomForm';
import { FormEmptyField } from '../../../UI/Form/FormEmptyField/FormEmptyField';
import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { CorporationNameFields } from './corporationNameForm.model';

const CorporationNameForm = ({ disabled, name }: CustomForm) => {
  const { t } = useTranslation();
  return (
    <FormWrapper>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          label={t('fields.corporationName.label')}
          placeholder={t('fields.corporationName.placeholder')}
          {...getFieldPropertiesForGroup({
            inputId: 'corporation-form',
            name: CorporationNameFields.Name,
            groupName: name,
          })}
        />
        <FormEmptyField />
      </FormRow>
    </FormWrapper>
  );
};

export default CorporationNameForm;
