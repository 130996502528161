import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../../models/types/CustomForm';
import { FormSectionTitle } from '../../../../styledComponents/Form/Form';
import { FormInput } from '../../../../styledComponents/UI/Form/FormInput/FormInput';
import { getFormGroupId } from '../../../../utils/getFormGroupPath';
import {
  AdditionalInformationFormFields,
  AdditionalInformationFormName,
} from './additionalInformation.models';

const AdditionalInformation = ({ disabled }: CustomForm) => {
  const { t } = useTranslation();
  return (
    <div>
      <FormSectionTitle>
        {t('headers.additional-information-title')}
      </FormSectionTitle>

      <Field
        component={FormInput}
        disabled={disabled}
        inputId={getFormGroupId(
          AdditionalInformationFormName,
          AdditionalInformationFormFields.Notes,
        )}
        name={AdditionalInformationFormFields.Notes}
      />
    </div>
  );
};

export default AdditionalInformation;
