import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';

import { CustomFieldLabel } from '../../../../models/types/CustomFieldLabel';
import { StyledTextField } from './searchInputTextField.styles';

type SearchInputTextFieldProps = AutocompleteRenderInputParams &
  CustomFieldLabel & {
    placeholder?: string;
    onChange?: (values: any) => void;
    isError?: boolean;
    onBlur?: () => void;
  };

const SearchInputTextField = ({
  placeholder,
  onChange,
  isLabelHidden,
  label,
  labelAnimation,
  disabled,
  onBlur,
  isError,
  ...props
}: SearchInputTextFieldProps) => (
  <StyledTextField
    {...props}
    disabled={disabled}
    $isError={isError}
    InputProps={{
      ...props.InputProps,
      onChange,
      onBlur,
    }}
    inputProps={{
      ...props.inputProps,
      placeholder,
      form: {
        autocomplete: 'off',
      },
    }}
    label={label}
  />
);

export default SearchInputTextField;
