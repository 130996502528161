import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IKEOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { subscriptionPricingSchema } from './SubscriptionPricingForm/subscriptionPricingForm.validation';

export const keSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionPricingSchema.properties,
  },
  required: [...subscriptionPricingSchema.required],
};

export const validateAddKESubscriptionForm = (
  data: IKEOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, keSubscriptionSchema);
