import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { loadSRSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/securityResponse';
import { ILoadSRSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/securityResponse/models';

type SRRenewSubscriptionSummaryLoaderProps = CustomComponent & {
  pricing: IOrganizationSubscriptionPricingModel;
};

export const SRRenewSubscriptionSummaryLoader = ({
  pricing,
}: SRRenewSubscriptionSummaryLoaderProps) => {
  const { organizationId } = useParams();

  const LoadSubscriptionSummary = useActions(loadSRSubscriptionSummary);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!pricing) {
      return;
    }

    const payload: ILoadSRSubscriptionSummary = {
      data: pricing.membershipPlans,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, debounce, organizationId, pricing]);

  return <></>;
};
