import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';

import { ReactComponent as NoSubscriptionsFoundIcon } from '../../../../../assets/icons/no-subscriptions-found.svg';

import { RoutePath } from '../../../../../models/enums/RoutePath';

import { prepareRoutePath } from '../../../../../utils/routePathHelpers';
import { canAddAASubscriptionPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import {
  StyledButton,
  SubscriptionsNotFoundWrapper,
  TitleNotFound,
} from './productSubscriptionsNotFound.styles';

interface IProductSubscriptionsNotFoundProps {
  id: string;
  title: string;
}

const ProductSubscriptionsNotFound = ({
  id,
  title,
}: IProductSubscriptionsNotFoundProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SubscriptionsNotFoundWrapper>
      <NoSubscriptionsFoundIcon />
      <TitleNotFound>{title}</TitleNotFound>
      <StyledButton
        permissions={canAddAASubscriptionPermissions}
        onClick={() => {
          navigate(prepareRoutePath({ id }, RoutePath.AddProductSubscription));
        }}
        styleType="lighter-blue"
      >
        {t('buttons.add-subscription')}
      </StyledButton>
    </SubscriptionsNotFoundWrapper>
  );
};

export default ProductSubscriptionsNotFound;
