import MemberOverviewProfileDetails from './MemberOverviewProfileDetails/MemberOverviewProfileDetails';
import MemberOverviewActiveMembers from './MemberOverviewActiveMembers/MemberOverviewActiveMembers';
import { useMemberOverview } from './memberOverviewPage.hooks';
import Spinner from '../../styledComponents/Spinner/Spinner';
import { PageDetailsContentWrapper } from '../../styledComponents/PageDetails/pageDetails.styles';
import MemberOverviewExpiredMembers from './MemberOverviewExpiredMembers/MemberOverviewExpiredMembers';
import EditMemberModal from '../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import ReplaceCreditCardModal from './Modals/ReplaceCreditCardModal/ReplaceCreditCardModal';
import AddCreditCardModal from './Modals/AddCreditCardModal/AddCreditCardModal';
import DeleteCreditCardModal from './Modals/DeleteCreditCardModal/DeleteCreditCardModal';
import DeletePersonModal from './Modals/DeletePersonModal/DeletePersonModal';
import { Line } from '../../styledComponents/UI/Line/Line';
import MemberOverviewHeader from './MemberOverviewPageHeader/MemberOverviewPageHeader';

const MemberOverviewPage = () => {
  const {
    person,
    personLoading,
    addMembershipHandler,
    headerTitle,
    editPersonHandler,
    loadPersonHandler,
    replaceCreditCardHandler,
    addCreditCardHandler,
    deleteCreditCardHandler,
    loadCreditCardsHandler,
    deletePersonHandler,
  } = useMemberOverview();

  return (
    <>
      <EditMemberModal onMemberEdited={loadPersonHandler} />
      <ReplaceCreditCardModal onCreditCardReplaced={loadCreditCardsHandler} />
      <AddCreditCardModal onCreditCardAdded={loadCreditCardsHandler} />
      <DeleteCreditCardModal onCreditCardDeleted={loadCreditCardsHandler} />
      <DeletePersonModal />
      {personLoading ? (
        <Spinner size="20%" />
      ) : (
        <>
          <MemberOverviewHeader
            status={person.memberStatus}
            title={headerTitle}
            memberNumber={person.memberNumber}
          />
          <PageDetailsContentWrapper>
            <MemberOverviewProfileDetails
              onReplaceCreditCard={replaceCreditCardHandler}
              onDeleteCreditCard={deleteCreditCardHandler}
              onAddCreditCard={addCreditCardHandler}
              onEdit={editPersonHandler}
              onPersonDelete={deletePersonHandler}
            />
            <Line />
            <MemberOverviewActiveMembers
              onAddMembershipClick={addMembershipHandler}
            />
            <MemberOverviewExpiredMembers />
          </PageDetailsContentWrapper>
        </>
      )}
    </>
  );
};

export default MemberOverviewPage;
