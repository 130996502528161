/* eslint-disable no-undefined */
import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { StyledButtonProps } from './Button.types';

type ButtonWrapperProps = StyledButtonProps & {
  withTooltip?: boolean;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  font-family: ${({ theme }) => theme.font.gotham};
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
  letter-spacing: 0.03125rem;
  height: 3rem;
  padding: 0.375rem 0.75rem;

  ${({ styleType, theme }) => {
    switch (styleType) {
      case 'standard':
        return css`
          background-color: ${theme.color.primary};
          color: ${theme.color.white};
        `;
      case 'link':
        return css`
          background: none;
          color: ${theme.color.secondary1};
          height: auto;
          padding: 0;
          border-radius: 0;
          letter-spacing: 0.0125rem;

          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
      case 'lighter-blue':
        return css`
          background-color: ${theme.color.lighter_blue};
          color: ${theme.color.white};
        `;
      case 'lighter-blue-border':
        return css`
          background: none;
          color: ${theme.color.secondary1};
        `;
      case 'lighter-border':
        return css`
          background-color: ${theme.color.white};
          color: ${theme.color.lighter_blue};
          border: 2px solid ${theme.color.lighter_blue};
        `;
      case 'light1':
        return css`
          background-color: ${theme.color.light1};
          color: ${theme.color.secondary1};
        `;
      case 'light-border':
        return css`
          background: none;
          color: ${theme.color.secondary1};
          border: 2px solid ${theme.color.secondary1};
        `;
      case 'delete':
        return css`
          background: none;
          color: ${theme.color.error};

          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
      case 'background-delete':
        return css`
          background: ${theme.color.error};
          color: ${theme.color.white};
        `;
      case 'standard-rounded':
        return css`
          width: 100%;
          background-color: ${theme.color.primary};
          color: ${theme.color.white};
        `;
      case 'icon':
        return css`
          background: none;
          color: none;
          font-weight: bold;
          height: auto;
          padding: 0;
          &.disabled {
            & > {
              stroke: ${theme.color.dark2};
            }
          }
        `;
    }
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
    `}

    ${({ disabled, withTooltip }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      ${withTooltip &&
      css`
        pointer-events: none;
      `}
    `}
`;
