import { useCallback, useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../../hooks/useActions';
import { useRouteParams } from '../../../../../../../hooks/useRouteParams';

import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';
import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { loadKEMembershipSummary } from '../../../../../../../store/saga-slices/membership/kidnapAndExtortion';
import { ILoadKEMembershipSummaryPayload } from '../../../../../../../store/saga-slices/membership/kidnapAndExtortion/models';
import { formatDate, parseDate } from '../../../../../../../utils/dateHelpers';
import { KEMembershipOrganizationDetailsFormFields } from './keMembershipDetailsForm.models';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { primaryMemberSelector } from '../../../../../../../store/redux-slices/membership/common/selectors';
import { getKESubscriptionPlans } from '../../../../../../../store/saga-slices/subscriptions/kidnapAndExtortion/api';
import { searchKEOrganization } from '../../../../../../../store/saga-slices/optionsSearch/kidnapAndExtortion/api';
import { getDatesForNewKEMemberships } from '../../../../../../../store/saga-slices/membership/kidnapAndExtortion/api';
import { clearMembershipSummary } from '../../../../../../../store/redux-slices/membership/common';
import { ICreateSecondaryMemberModel } from '../../../../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { MembershipFamilyMembersFormFields } from '../../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';

export function useKEMembershipDetails() {
  const primaryMember = useSelector(primaryMemberSelector);
  const cancellationToken = useCancellationToken();

  const {
    organizationId: initOrganizationId,
    subscriptionId: initSubscriptionId,
  } = useRouteParams();

  const LoadMembershipSummary = useActions(loadKEMembershipSummary);
  const ClearMembershipSummary = useActions(clearMembershipSummary);

  const {
    input: {
      value: selectedOrganization,
      onChange: selectedOrganizationChange,
    },
  }: FieldRenderProps<IOrganizationSearch> = useField(
    KEMembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { onChange: secondaryMembersChange },
  }: FieldRenderProps<ICreateSecondaryMemberModel[]> = useField(
    MembershipFamilyMembersFormFields.SecondaryMembers,
  );

  const {
    input: { value: selectedPlan, onChange: selectedPlanChange },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    KEMembershipOrganizationDetailsFormFields.MembershipPlanId,
  );

  const {
    input: { value: subscriptionType, onChange: subscriptionTypeChange },
  }: FieldRenderProps<string> = useField(
    KEMembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  const {
    input: { onChange: expirationDateChange },
  }: FieldRenderProps<string> = useField(
    KEMembershipOrganizationDetailsFormFields.ExpirationDate,
  );

  const {
    input: { value: effectiveDate, onChange: effectiveDateChange },
  }: FieldRenderProps<string> = useField(
    KEMembershipOrganizationDetailsFormFields.EffectiveDate,
  );
  const loadOrganizationById = useCallback(
    (organizationId: string) => {
      searchKEOrganization(null, organizationId, cancellationToken).then(
        ({ data: { organizations } }) => {
          selectedOrganizationChange(
            organizations.length > 0 ? organizations[0] : null,
          );
        },
      );
    },
    [cancellationToken, selectedOrganizationChange],
  );

  const clearFields = useCallback(() => {
    selectedOrganizationChange(null);
    selectedPlanChange(null);
    subscriptionTypeChange(null);
    expirationDateChange(null);
    effectiveDateChange(null);
    secondaryMembersChange(null);
  }, [
    expirationDateChange,
    effectiveDateChange,
    selectedOrganizationChange,
    selectedPlanChange,
    subscriptionTypeChange,
    secondaryMembersChange,
  ]);

  useEffect(() => {
    if (initOrganizationId) {
      loadOrganizationById(initOrganizationId);
    }
    return () => clearFields();
  }, [initOrganizationId, loadOrganizationById, clearFields]);

  const organizationsPromiseOptions = useCallback(
    (inputValue: string) =>
      searchKEOrganization(inputValue, null, cancellationToken).then((x) =>
        x.data.organizations.map((organization) => ({
          label: organization.name,
          value: organization,
        })),
      ),
    [cancellationToken],
  );

  const loadDates = useCallback(() => {
    getDatesForNewKEMemberships(subscriptionType, cancellationToken).then(
      (response) => {
        effectiveDateChange(parseDate(response.data.effectiveDate));
        expirationDateChange(parseDate(response.data.expirationDate));
      },
    );
  }, [
    cancellationToken,
    effectiveDateChange,
    expirationDateChange,
    subscriptionType,
  ]);

  useEffect(() => {
    effectiveDateChange(null);
    expirationDateChange(null);

    if (subscriptionType) {
      loadDates();
    }
  }, [effectiveDateChange, expirationDateChange, loadDates, subscriptionType]);

  useEffect(() => {
    if (
      !selectedPlan ||
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      ClearMembershipSummary();
      return;
    }
    const payload: ILoadKEMembershipSummaryPayload = {
      membershipPlanId: selectedPlan.membershipPlanId,
      organizationId: selectedOrganization.organizationId,
      subscriptionId: subscriptionType,
      cancellationToken,
    };

    LoadMembershipSummary(payload);
  }, [
    ClearMembershipSummary,
    LoadMembershipSummary,
    cancellationToken,
    selectedOrganization.organizationId,
    selectedOrganization.subscriptions,
    selectedPlan,
    subscriptionType,
  ]);

  useEffect(() => {
    if (
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      subscriptionTypeChange(null);
      selectedPlanChange(null);
    }
  }, [
    selectedOrganization,
    selectedPlanChange,
    subscriptionType,
    subscriptionTypeChange,
  ]);

  const organizationSubscriptionPlansPromiseOptions = useCallback(async () => {
    if (
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      return Promise.resolve([]);
    }

    const { data } = await getKESubscriptionPlans(
      selectedOrganization.organizationId,
      subscriptionType,
      primaryMember?.dateOfBirth,
      effectiveDate,
    );
    return data.plans.map((plan) => ({
      label: plan.membershipPlanName,
      value: plan,
    }));
  }, [
    selectedOrganization.organizationId,
    selectedOrganization.subscriptions,
    subscriptionType,
    primaryMember?.dateOfBirth,
    effectiveDate,
  ]);

  const subscriptionsOptions = useMemo(
    () =>
      selectedOrganization
        ? selectedOrganization.subscriptions.map((subscription) => ({
            label: `${formatDate(subscription.effectiveDateFrom)}-${formatDate(
              subscription.effectiveDateTo,
            )}`,
            value: subscription.id,
          }))
        : [],
    [selectedOrganization],
  );

  useEffect(() => {
    if (initSubscriptionId && subscriptionsOptions) {
      subscriptionTypeChange(initSubscriptionId);
    }
  }, [subscriptionsOptions, initSubscriptionId, subscriptionTypeChange]);

  return {
    selectedPlan,
    organizationsPromiseOptions,
    organizationSubscriptionPlansPromiseOptions,
    subscriptionsOptions,
  };
}
