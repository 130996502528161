import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export enum AAMembersListFilters {
  Search = 'search',
  Plan = 'plan',
  MemberType = 'memberType',
  RenewStatus = 'renewStatus',
  SubscriptionId = 'subscriptionId',
  OversubscribedOnly = 'oversubscribedOnly',
}

export type AAMembersFiltersProps = {
  availableFilters: AAMembersListFilters[];
  standardPricing?: IMembershipPlanPriceModel[];
};
