import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import {
  getAARawDataReports,
  getAAEnrollmentReport,
  getAAAttritionReport,
  getAARetailStatisticsReport,
} from './api';
import { updateProcessState } from '../../../redux-slices/processes';

import {
  AA_RAW_DATA_REPORTS_PROCESSING,
  AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING,
  AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING,
  AA_RETAIL_STATISTICS_REPORT_DOWNLOAD_PROCESSING,
} from '../../../redux-slices/processes/constants';

import { Log } from '../../../../utils/logger';
import downloadFile from '../../../../utils/downloadFile';

import { ICallbackSaga } from '../../../../models/ICallbackSaga';
import {
  IDownloadAttritionReportPayload,
  IDownloadEnrollmentReportPayload,
  IDownloadRetailStatisticsReportPayload,
  ReportsFilenamePrefixEnum,
} from '../common/models';
import { CUSTOM_DATE_MONTH_YEAR_FORMAT } from '../../../../utils/dateHelpers';
import { generateFilenameWithDate } from '../../../../utils/generateFilenameWithDate';

const aaReportsSlice = createSliceSaga({
  caseSagas: {
    *downloadAARawDataReport({ payload }: PayloadAction<ICallbackSaga>) {
      try {
        yield put(updateProcessState(AA_RAW_DATA_REPORTS_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.RawDataReport,
        );
        const response = yield* call(getAARawDataReports);
        yield downloadFile(response.data, name);
        payload?.success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        payload?.error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(AA_RAW_DATA_REPORTS_PROCESSING));
      }
    },
    *downloadAAEnrollmentReport({
      payload: {
        success,
        error,
        data: { date },
      },
    }: PayloadAction<IDownloadEnrollmentReportPayload>) {
      try {
        yield put(updateProcessState(AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.EnrollmentReport,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
          date,
        );
        const response = yield* call(getAAEnrollmentReport, date);
        yield downloadFile(response.data, name);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING));
      }
    },
    *downloadAAAttritionReport({
      payload: {
        success,
        error,
        data: { date },
      },
    }: PayloadAction<IDownloadAttritionReportPayload>) {
      try {
        yield put(updateProcessState(AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.AttritionReport,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
          date,
        );
        const response = yield* call(getAAAttritionReport, date);
        yield downloadFile(response.data, name);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING));
      }
    },
    *downloadAARetailStatisticsReport({
      payload: {
        success,
        error,
        data: { date },
      },
    }: PayloadAction<IDownloadRetailStatisticsReportPayload>) {
      try {
        yield put(
          updateProcessState(AA_RETAIL_STATISTICS_REPORT_DOWNLOAD_PROCESSING),
        );
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.RetailStatistics,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
          date,
        );
        const response = yield* call(getAARetailStatisticsReport, date);
        yield downloadFile(response.data, name);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(AA_RETAIL_STATISTICS_REPORT_DOWNLOAD_PROCESSING),
        );
      }
    },
  },
  name: 'reports-saga',
});

export default aaReportsSlice.saga;
export const {
  downloadAARawDataReport,
  downloadAAEnrollmentReport,
  downloadAAAttritionReport,
  downloadAARetailStatisticsReport,
} = aaReportsSlice.actions;
export const { actions } = aaReportsSlice;
