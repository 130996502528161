import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove-icon.svg';
import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import { IEmployee } from '../../../../models/Employee';
import {
  FormDeleteButton,
  FormEmptySpace,
  FormNumber,
  FormWrapper,
} from '../../../Form/Form';
import { CustomForm } from '../../../../models/types/CustomForm';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import {
  GgPointOfContactFormFields,
  GgPointOfContactFormName,
} from './ggPointOfContactForm.models';
import { SelectOption } from '../../../UI/MainSelect/mainSelect.types';
import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { MultiInputType } from '../../../../models/enums/MultiInputType';
import { FormInput } from '../../../UI/Form/FormInput/FormInput';
import { FormEmptyField } from '../../../UI/Form/FormEmptyField/FormEmptyField';

interface IPointOfContactFormProps extends CustomForm {
  contactNumber?: number;
  items?: IEmployee[];
  ggRolesList?: SelectOption[];
  onRemoveContact?: () => void;
}

const GGPointOfContactForm = ({
  contactNumber,
  disabled,
  name,
  items,
  ggRolesList,
  onRemoveContact,
}: IPointOfContactFormProps) => {
  const { t } = useTranslation();

  const employeeOptions = useMemo(
    () =>
      items &&
      items.map((x) => ({
        label: `${x.firstName} ${x.lastName}`,
        value: x.id,
      })),
    [items],
  );
  return (
    <FormWrapper>
      <FormRow>
        <FormColumn>
          <FormRow>
            <FormRow isSubRow>
              {contactNumber && <FormNumber>{`${contactNumber}.`}</FormNumber>}
              <Field
                component={FormSelect}
                disabled={disabled}
                label={t('fields.selectGGEmp.label')}
                {...getFieldPropertiesForGroup({
                  inputId: GgPointOfContactFormName,
                  name: GgPointOfContactFormFields.EmployeeId,
                  groupName: name,
                })}
                options={employeeOptions}
              />
              <FormRow>
                <Field
                  isMulti
                  multiInputType={MultiInputType.InputSelect}
                  component={FormSelect}
                  options={ggRolesList}
                  disabled={disabled}
                  {...getFieldPropertiesForGroup({
                    inputId: GgPointOfContactFormName,
                    name: GgPointOfContactFormFields.roles,
                    groupName: name,
                  })}
                  label={t('fields.ggPocRoles.label')}
                />
              </FormRow>
            </FormRow>
          </FormRow>
          <FormRow>
            <FormRow isSubRow>
              <FormEmptySpace />
              <Field
                component={FormInput}
                disabled={disabled}
                {...getFieldPropertiesForGroup({
                  inputId: GgPointOfContactFormName,
                  name: GgPointOfContactFormFields.InternalNotes,
                  groupName: name,
                })}
              />
              <FormEmptyField />
            </FormRow>
          </FormRow>
        </FormColumn>
      </FormRow>
      {onRemoveContact && (
        <FormDeleteButton
          disabled={disabled}
          onClick={onRemoveContact}
          styleType="icon"
        >
          <RemoveIcon />
        </FormDeleteButton>
      )}
    </FormWrapper>
  );
};

export default GGPointOfContactForm;
