import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { usePaymentSection } from './paymentSection.hooks';
import PaymentCardDetailsForm from '../PaymentCardDetailsForm/PaymentCardDetailsForm';
import { StyledTitle, Wrapper } from './paymentSection.styles';
import PaymentGroup from './PaymentGroup';

const PaymentSection = ({ disabled }: any) => {
  const { t } = useTranslation();
  const {
    currentPaymentType,
    onChangePaymentTypeHandler,
    onlyCardDetailsForm,
  } = usePaymentSection();

  const content = useMemo(
    () =>
      onlyCardDetailsForm ? (
        <>
          <StyledTitle>{t('phrase.add-new-credit-card')}</StyledTitle>
          <PaymentCardDetailsForm disabled={disabled} />
        </>
      ) : (
        <PaymentGroup
          disabled={disabled}
          checked={currentPaymentType}
          onChange={onChangePaymentTypeHandler}
        />
      ),
    [
      currentPaymentType,
      disabled,
      onChangePaymentTypeHandler,
      onlyCardDetailsForm,
      t,
    ],
  );

  return <Wrapper>{content}</Wrapper>;
};

export default PaymentSection;
