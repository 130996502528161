import styled from '@emotion/styled/macro';

import { SectionSubTitle } from '../../../styledComponents/Typography/Typography';
import MaterialTable from '../../../styledComponents/MaterialTable/MaterialTable';

export const TableTitle = styled(SectionSubTitle)`
  margin-bottom: 2rem;
`;

export const StyledMaterialTable = styled(MaterialTable)`
  background-color: transparent;
`;
