import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAAOrganizationList } from 'models/AAOrganizationList';

import { IOrganization } from '../../../models/Organization';
import { IOrganizationList } from '../../../models/OrganizationList';

export interface IOrganizations {
  airAmbulanceOrganizations: IAAOrganizationList[];
  currentOrganization: IOrganization;
  items: IOrganizationList[];
  itemsCount: number;
}

const initialState: IOrganizations = {
  items: [],
  itemsCount: 0,
  currentOrganization: null,
  airAmbulanceOrganizations: [],
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations: (
      state,
      { payload }: PayloadAction<IOrganizationList[]>,
    ) => {
      state.items = payload;
    },
    setOrganizationsTotalCount: (state, { payload }: PayloadAction<number>) => {
      state.itemsCount = payload;
    },
    setCurrentOrganization: (
      state,
      { payload }: PayloadAction<IOrganization>,
    ) => {
      state.currentOrganization = payload;
    },
    setAAOrganizations: (
      state,
      { payload }: PayloadAction<IAAOrganizationList[]>,
    ) => {
      state.airAmbulanceOrganizations = payload;
    },
    clearCurrentOrganization: (state) => {
      state.currentOrganization = initialState.currentOrganization;
    },
  },
});

export const {
  setAAOrganizations,
  setCurrentOrganization,
  setOrganizations,
  setOrganizationsTotalCount,
  clearCurrentOrganization,
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
export const organizationsReducerName = organizationsSlice.name;
