import styled from '@emotion/styled';

export const StyledText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.color.black};
  font-size: 0.875rem !important;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
