import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  useApiResponseError,
  useFormErrorsValues,
} from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';

import { updateModalState } from '../../../../../store/redux-slices/modals';
import { CREDIT_CARD_ADD_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { addCreditCard } from '../../../../../store/saga-slices/payment';
import { IAddCreditCardPayload } from '../../../../../store/saga-slices/payment/model';

import { ADD_CREDIT_CARD_MODAL_NAME } from '../addCreditCardModal.const';

import { ICardSetupIntentModel } from '../../../../../models/ICardSetupIntentModel';
import useStripeProvider from '../../../../../hooks/useStripeProvider';
import { ICreditCardInfo } from '../../../../../models/ICreditCardInfo';

type UseAddCreditCardModalForm = {
  onAddedCreditCard?: () => void;
};

export const useAddCreditCardModalForm = ({
  onAddedCreditCard,
}: UseAddCreditCardModalForm) => {
  const UpdateModalState = useActions(updateModalState);
  const AddCreditCard = useActions(addCreditCard);
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const creditCardAdding = useProcessing(CREDIT_CARD_ADD_PROCESSING);

  const { handleResponseError } = useApiResponseError();

  const { errors } = useFormErrorsValues();

  const { personId }: { personId: string } = useModalDetails(
    ADD_CREDIT_CARD_MODAL_NAME,
  );

  const stripe = useStripeProvider();

  const submitHandler = (creditCardInfo: ICreditCardInfo) => {
    const payload: IAddCreditCardPayload = {
      cancellationToken,
      personId,
      success: async (setupIntent: ICardSetupIntentModel) => {
        stripe.submitCardWithStripe(
          setupIntent,
          creditCardInfo,
          () => {
            alert.success(t('phrase.credit-card-was-added-successfully'));
            UpdateModalState(ADD_CREDIT_CARD_MODAL_NAME);
            onAddedCreditCard && onAddedCreditCard();
          },
          () => alert.error(t('phrase.credit-card-was-not-added-successfully')),
        );
      },
      error: (err) => {
        alert.error(t('phrase.credit-card-was-not-added-successfully'));
        handleResponseError(err);
      },
    };

    AddCreditCard(payload);
  };

  const onCancelHandler = () => {
    UpdateModalState(ADD_CREDIT_CARD_MODAL_NAME);
  };

  const validateErrors = () => errors;

  return { onCancelHandler, submitHandler, creditCardAdding, validateErrors };
};
