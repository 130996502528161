/* eslint-disable react/no-array-index-key */
import { forwardRef } from 'react';

import { ShortcutProductType } from '../../../../models/enums/ShortcutProductType';

import { TableCellTooltip } from '../../TableContent/tableContent.styles';

import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';
import {
  SubscriptionsShortcut,
  SubscriptionsShortcuts,
  SubscriptionsWrapper,
} from './productsRenderer.styles';
import { prepareProductsTooltip } from './productsRenderer.utils';

interface IProductsRenderer extends ICellRenderer {
  data?: ShortcutProductType[];
}

export const ProductsRenderer = forwardRef(
  ({ options, data, ...props }: IProductsRenderer, ref) => {
    let content = null;

    if (data) {
      content = (
        <TableCellTooltip title={prepareProductsTooltip(data)}>
          <SubscriptionsShortcuts>
            {data?.map((productType, key) => (
              <SubscriptionsShortcut key={key} productType={productType}>
                {productType}
              </SubscriptionsShortcut>
            ))}
          </SubscriptionsShortcuts>
        </TableCellTooltip>
      );
    }

    return (
      <TableCell
        {...props}
        ref={ref as any}
        bold={options?.textBold}
        alignment={options?.alignment}
      >
        <SubscriptionsWrapper>{content}</SubscriptionsWrapper>
      </TableCell>
    );
  },
);
