import styled from '@emotion/styled/macro';

import { SectionTitle, StandardText } from '../../Typography/Typography';
import Button from '../../UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Circle = styled.div`
  width: 5.375rem;
  height: 5.375rem;
  border-radius: 2.687rem;
  background-color: ${({ theme }) => theme.color.hover};
`;

export const Title = styled(SectionTitle)`
  margin: 1rem 0rem 1rem;
  color: ${({ theme }) => theme.color.primary};
`;

export const StyledStandardText = styled(StandardText)`
  font: 325 normal 1rem/1rem ${({ theme }) => theme.font.gotham};
  margin-bottom: 0.5rem;
`;

export const StyledButton = styled(Button)`
  margin: 1rem 0rem 1rem;
`;

export const InformationText = styled.h4`
  margin-bottom: 2rem;
  font: 325 0.812rem/0.812rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.dark1};
`;

export const Image = styled.img`
  height: 15rem;
  width: 27.5rem;
`;
