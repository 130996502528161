import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import arrayMutators from 'final-form-arrays';

import FormErrorActivator from '../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import { focusOnErrorDecorator } from '../../../utils/focusOnErrorDecorator';

import { useAddProductSubscriptionForm } from './addProductSubscriptionForm.hooks';
import WarningsConfirmationProvider from '../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import WarningsConfirmationModal from '../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import SubscriptionsForm from '../../../styledComponents/SubscriptionFormPage/SubscriptionsForm/SubscriptionsForm';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  PageRootColumns,
  RightContainer,
} from '../../../styledComponents/Page/Page';
import { SelectContractForm } from '../../../styledComponents/Forms/SelectContractForm/SelectContractForm';
import FormSubmitButtons from '../../../styledComponents/UI/FormSubmitButtons/FormSubmitButtons';
import SubscriptionsSummaryForm from '../../../styledComponents/FormSummary/SubscriptionsSummary/SubscriptionsSummary';
import { getContractType } from '../../../utils/contractHelpers';

interface IAddProductSubscriptionFormProps {
  onContractChanged?: (value: string) => void;
}

const AddProductSubscriptionForm = ({
  onContractChanged,
}: IAddProductSubscriptionFormProps) => {
  const { t } = useTranslation();

  const {
    validateForm,
    handleSubmit,
    initData,
    organizationContracts,
    subscriptionAdding,
    dataLoading,
    createOrganizationSubscription,
    onAddContractHandler,
  } = useAddProductSubscriptionForm();

  return (
    <WarningsConfirmationProvider
      submitFunction={createOrganizationSubscription}
    >
      <WarningsConfirmationModal />
      <Form
        decorators={[focusOnErrorDecorator]}
        initialValues={initData}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ values, ...renderProps }) => (
          <FormErrorActivator>
            <Spinner isVisible={dataLoading} size="10%">
              <form>
                <PageRootColumns>
                  <LeftContainer>
                    <SelectContractForm
                      isClearable
                      contracts={organizationContracts}
                      disabled={subscriptionAdding}
                      onAddNewContract={onAddContractHandler}
                      onContractChanged={onContractChanged}
                      setDefaultValue={false}
                      message={
                        values.contractId &&
                        values.contractId !== initData.contractId &&
                        t('phrase.subscription-date-changed')
                      }
                    />
                    <SubscriptionsForm
                      contractType={getContractType(
                        values?.contractId,
                        organizationContracts,
                      )}
                      disabled={subscriptionAdding}
                    />
                  </LeftContainer>
                  <RightContainer>
                    <SubscriptionsSummaryForm
                      title={t('headers.summary')}
                      disabled={subscriptionAdding}
                      subscriptionsOverwrittenAmount={values.subscriptions}
                    />
                    <FormSubmitButtons
                      onlyDraft={
                        organizationContracts.find(
                          (element) => element.id === values.contractId,
                        )?.isDraft
                      }
                      onlyDraftTooltip={t(
                        'phrase.only-draft-because-contract-is-draft',
                      )}
                      handleSubmit={renderProps.handleSubmit}
                      canSaveDraft
                      isProcessing={subscriptionAdding}
                      saveLabel={t('buttons.create')}
                    />
                  </RightContainer>
                </PageRootColumns>
              </form>
            </Spinner>
          </FormErrorActivator>
        )}
      </Form>
    </WarningsConfirmationProvider>
  );
};

export default AddProductSubscriptionForm;
