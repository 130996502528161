import Select from 'react-select';

import { useTranslation } from 'react-i18next';

import { MenuList } from './components/MenuList';

import { ValueContainer } from './components/ValueContainer/ValueContainer';
import { OptionLabel } from './components/OptionLabel';
import { useMainSelect } from './mainSelect.hooks';

import { customStyles } from './mainSelect.styles';
import { DropdownIndicator } from './components/DropdownIndicator';
import { MainSelectPropType } from './mainSelect.types';
import { FieldWrapper } from '../Field/Field';
import { Input } from './components/Input';

export const MainSelect = <T,>({
  optionIcon: isIconVisible = true,
  valueIdSelector,
  promiseOptions,
  onScrollClose = true,
  isLoading,
  formatOptionLabel,
  ...props
}: MainSelectPropType<T>) => {
  const { t } = useTranslation();

  const { isMulti, options, currentValue, className } = props;

  const {
    isLoading: internalLoading,
    valueToRender,
    selectOptions,
    handleOnscrollClose,
  } = useMainSelect({
    onScrollClose,
    valueIdSelector,
    promiseOptions,
    currentValue,
    options,
    isMulti,
  });

  const components = {
    ValueContainer,
    MenuList,
    DropdownIndicator,
    Input,
  };
  return (
    <FieldWrapper>
      <Select
        defaultAddButtonText={t('buttons.add-new-option')}
        {...props}
        className={className}
        menuPosition="fixed"
        classNamePrefix="mainSelect"
        menuPortalTarget={document.body}
        styles={customStyles as any}
        closeMenuOnScroll={handleOnscrollClose as any}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        captureMenuScroll
        menuShouldScrollIntoView
        isLoading={internalLoading || isLoading}
        options={selectOptions}
        components={components as any}
        formatOptionLabel={(data) =>
          formatOptionLabel?.(data) ||
          OptionLabel({
            data,
            disabled: props.isDisabled,
            isIconVisible,
            currentValue,
          })
        }
        value={valueToRender}
      />
    </FieldWrapper>
  );
};
