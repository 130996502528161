import styled from '@emotion/styled/macro';

import { Modal } from '../Modal/Modal';
import { ModalContainer } from '../Modal/modal.styles';
import { SectionTitle } from '../Typography/Typography';
import Button from '../UI/Button/Button';

export const SimpleModal = styled(Modal)`
  max-width: 31.25rem;
  text-align: center;
  ${ModalContainer} {
    padding: 2.5rem;
  }
`;

export const SmallModal = styled(Modal)`
  max-width: 31.25rem;
`;

export const ModalTitle = styled(SectionTitle)`
  margin-bottom: 1.5rem;
`;

export const ModalButton = styled(Button)`
  margin-top: 2.5rem;
`;

export const SimpleModalButton = styled(Button)`
  padding: 0.375rem 2rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  gap: 1.5rem;
`;
