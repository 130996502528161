import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  canEditMemberPermissions,
  canRemoveMemberPermissions,
  canViewCreditCardPermissions,
} from '../../../config/accessPolicies/accessPolicies';

import { ICreditCard } from '../../../models/CreditCardModel';
import { firstCreditCardSelector } from '../../../store/redux-slices/payment/selectors';
import { personSelector } from '../../../store/redux-slices/people/selectors';
import MemberOverviewProfileBasicDetails from './MemberOverviewProfileBasicDetails/MemberOverviewProfileBasicDetails';
import MemberOverviewProfileCardDetails from './MemberOverviewProfileCardDetails/MemberOverviewProfileCardDetails';
import {
  ActionsContainer,
  DataContainer,
  StyledButton,
  Wrapper,
} from './memberOverviewProfileDetails.styles';

interface IMemberOverviewProfileDetailsProps {
  onEdit?: () => void;
  onReplaceCreditCard?: (creditCardId: string) => void;
  onAddCreditCard?: () => void;
  onDeleteCreditCard?: (creditCard: ICreditCard) => void;
  onPersonDelete?: () => void;
}

const MemberOverviewProfileDetails = ({
  onEdit,
  onAddCreditCard,
  onReplaceCreditCard,
  onDeleteCreditCard,
  onPersonDelete,
}: IMemberOverviewProfileDetailsProps) => {
  const { t } = useTranslation();
  const person = useSelector(personSelector);
  const firstCreditCard = useSelector(firstCreditCardSelector);
  return (
    <Wrapper>
      <DataContainer>
        <MemberOverviewProfileBasicDetails person={person} />
        <MemberOverviewProfileCardDetails
          permissions={canViewCreditCardPermissions}
          onReplaceCreditCard={onReplaceCreditCard}
          onAddCreditCard={onAddCreditCard}
          onDeleteCreditCard={onDeleteCreditCard}
          creditCard={firstCreditCard}
          canRemoveCreditCard={!person?.metadata?.isCreditCardRequired}
        />
      </DataContainer>
      <ActionsContainer>
        <StyledButton
          permissions={canEditMemberPermissions}
          onClick={onEdit}
          styleType="light1"
        >
          {t('buttons.edit')}
        </StyledButton>
        {person?.metadata?.canBeDeleted && (
          <StyledButton
            permissions={canRemoveMemberPermissions}
            onClick={onPersonDelete}
            styleType="delete"
          >
            {t('buttons.remove')}
          </StyledButton>
        )}
      </ActionsContainer>
    </Wrapper>
  );
};

export default MemberOverviewProfileDetails;
