import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { ISubscriptionMembershipPlan } from '../../../../models/interfaces/CreateSubscriptionMembershipPlan';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { IOrganizationSubscriptionPlansModel } from '../common/models';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMembershipsModel } from '../../../../models/IRenewOrganizationSubscriptionMembershipsModel';
import { ISROrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOverview';
import { IEditSROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/EditSROrganizationSubscriptionModel';
import { ISROrganizationSubscriptionOptionsModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOptionsModel';
import { ICreateSROrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/CreateSROrganizationSubscriptionExtensionModel';
import { ISRSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SRSubscriptionRenewalSuggestionModel';
import { RenewSRSubscriptionModel } from '../../../../models/types/SecurityResponse/RenewSRSubscriptionModel';
import { RenewalStatus } from '../../../../models/enums/RenewalStatus';

export const getSRSubscription = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<ISROrganizationSubscriptionOverview>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const getDatesForNewSRSubscription = (
  organizationId: string,
  contractId: string,
): Promise<AxiosResponse<ISubscriptionDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'contractId',
      value: contractId,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/newDates${params}`,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );
};

export const getSRSubscriptionSuggestion = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IEditSROrganizationSubscriptionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/suggestion`,
    {
      baseURL: config.securityResponseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getSRSubscriptionSummary = (
  organizationId: string,
  data: ISubscriptionMembershipPlan[],
): Promise<AxiosResponse<ISubscriptionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/summary`,
    { membershipPlans: data },
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const updateSRSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IEditSROrganizationSubscriptionModel,
): Promise<AxiosResponse> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const deleteSRSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.securityResponseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const updateSRSubscriptionDetails = (
  organizationId: string,
  subscriptionId: string,
  subscriptionDetails: ISROrganizationSubscriptionOptionsModel,
): Promise<AxiosResponse<ISROrganizationSubscriptionOverview>> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/details`,
    subscriptionDetails,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const extendSRSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  subscriptionExtension: ICreateSROrganizationSubscriptionExtensionModel,
): Promise<AxiosResponse<ISROrganizationSubscriptionOverview>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update`,
    subscriptionExtension,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const getSRSubscriptionExtensionSummary = (
  organizationId: string,
  subscriptionId: string,
  data: IChangeSubscriptionPlanCapacityModel[],
): Promise<AxiosResponse<IOrganizationSubscriptionExtensionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update/summary`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const getSRSubscriptionPlans = (
  organizationId: string,
  subscriptionId: string,
  primaryMemberBirthDate?: string,
  membershipEffectiveDate?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscriptionPlansModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'primaryMemberBirthDate',
      value: primaryMemberBirthDate,
    },
    {
      name: 'membershipEffectiveDate',
      value: membershipEffectiveDate,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/plans${params}`,
    {
      baseURL: config.securityResponseApi.baseUrl,
      signal: cancellationToken,
    },
  );
};

export const getSRSubscriptionRenewalSuggestion = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<ISRSubscriptionRenewalSuggestionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalsuggestion`,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const renewSRSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: RenewSRSubscriptionModel,
): Promise<AxiosResponse<IOrganizationSubscription>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renew`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const getSRSubscriptionRenewalAnalyseRequest = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/renewalanalysis`,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const sendSRSubscriptionRenewalMembershipsRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IRenewOrganizationSubscriptionMembershipsModel,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionMembershipsModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/toberenewed`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const editSRSubscriptionRenewalStatusRequest = (
  organizationId: string,
  subscriptionId: string,
  renewalStatus: RenewalStatus,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(
    `/api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalstatus`,
    { renewalStatus },
    {
      signal: cancellationToken,
      baseURL: config.securityResponseApi.baseUrl,
    },
  );
