import { useTranslation } from 'react-i18next';

import { Status } from '../../../../../models/enums/Status';
import ConfirmModal from '../../../../../styledComponents/ConfirmModal/ConfirmModal';
import { DELETE_AA_MEMBERSHIP_MODAL_NAME } from './deleteAAMembershipModal.const';
import {
  Description,
  Name,
  StyledStatusMessageWithIcon,
} from '../../../Modals/Common/memberOverviewModals.styles';
import { useDeleteAAMembershipModal } from './deleteAAMembershipModal.hooks';

type DeleteAAMembershipModalProps = {
  id?: string;
};

const DeleteAAMembershipModal = ({ id }: DeleteAAMembershipModalProps) => {
  const { t } = useTranslation();
  const {
    onBackHandler,
    productType,
    personName,
    isDeleting,
    deleteMembershipHandler,
    personMembershipIsActive,
  } = useDeleteAAMembershipModal({ id });
  return (
    <>
      <ConfirmModal
        buttons={[
          {
            action: onBackHandler,
            type: 'back',
            styleType: 'light1',
            disabled: isDeleting,
          },
          {
            action: deleteMembershipHandler,
            type: 'delete',
            styleType: 'background-delete',
            isProcessing: isDeleting,
          },
        ]}
        name={DELETE_AA_MEMBERSHIP_MODAL_NAME + id}
        disabled={isDeleting}
        title={t('headers.delete-membership')}
      >
        <Description>{t('phrase.confirm-remove-membership')}</Description>
        <Name>
          {t('phrase.confirm-remove-membership-product', {
            name: personName,
            product: productType,
          })}
        </Name>
        {personMembershipIsActive && (
          <StyledStatusMessageWithIcon
            status={Status.WARNING}
            message={t('phrase.membership-will-affect-reports')}
          />
        )}
      </ConfirmModal>
    </>
  );
};

export default DeleteAAMembershipModal;
