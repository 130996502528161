import { ReactNode } from 'react';

import { SelectOption } from '../../../../../../../models/SelectOption';
import { CustomFilter } from '../../../renewalFilters.models';

export type SelectCategoryFilterAdditionalValuesModel = {
  categoryValues?: SelectOption<string>[] | string[];
};

export enum SelectCategoryFilterFields {
  Value = 'value',
  AdditionalValues = 'additionalValues',
  CategoryValues = 'categoryValues',
}

export type SelectCategoryFilterModel =
  CustomFilter<SelectCategoryFilterAdditionalValuesModel>;

export type SelectCategoryFilterOptionsModel = {
  valueRenderer?: (label: string) => ReactNode;
  options?: SelectOption[];
  promiseOptions?: () => Promise<SelectOption<SelectOption<string>>[]>;
  categoryPromiseOptions?: (
    category?: string,
  ) => () => Promise<SelectOption<SelectOption<string>>[]>;
  categoryValuesLabel?: string;
  categoryOptions?: SelectOption[];
  valueTagLabelPath?: string;
};
