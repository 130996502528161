import { Dictionary } from '../models/Dictionary';

export const removeBlankProperties = (data: Dictionary<any> | any[]): any => {
  const newData = { ...data };
  removeProperties(newData);
  return newData;
};

function removeProperties(x: any): void {
  if (Array.isArray(x)) {
    removePropertiesFromArray(x);
  } else if (typeof x === 'object' && x !== null) {
    removePropertiesFromObject(x);
  }
}

function removePropertiesFromArray(arr: any[]): void {
  arr.forEach((x) => {
    removeProperties(x);
  });
}

function removePropertiesFromObject(obj: any): void {
  const dataArray = Object.keys(obj);
  dataArray.forEach((key) => {
    if (
      (typeof obj[key] === 'string' && obj[key].trim() === '') ||
      obj[key] === '' ||
      obj[key] === null
    ) {
      delete obj[key];
    } else {
      removeProperties(obj[key]);
    }
  });
}
