import { CustomForm } from '../../../../../models/types/CustomForm';

import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';

import { Wrapper } from './tlRenewSubscriptionDetails.styles';
import { SubscriptionRenewDetailsCommonForm } from '../../Common/SubscriptionRenewDetailsCommonForm/SubscriptionRenewDetailsCommonForm';
import { ProductType } from '../../../../../models/ProductType';

export const TLRenewSubscriptionDetails = ({ disabled }: CustomForm) => (
  <Wrapper>
    <FormColumn>
      <SubscriptionRenewDetailsCommonForm
        disabled={disabled}
        productType={ProductType.DutyOfCare}
      />
    </FormColumn>
  </Wrapper>
);
