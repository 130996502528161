import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';

interface IValidateSubscriptionContractForm {
  contractId: string;
}

export const contractFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    contractId: {
      type: 'string',
    },
  },
  required: [],
};

export const validateContractForm = (
  data: IValidateSubscriptionContractForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, contractFormSchema);
