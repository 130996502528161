import styled from '@emotion/styled';

import { SectionTitle } from '../Typography/Typography';

import FormCheckbox from '../UI/Form/FormCheckbox/FormCheckbox';

export const Wrapper = styled.div`
  padding: 2.5rem;
  border: 0.063rem solid ${({ theme }) => theme.color.light1};
  border-radius: 0.5rem;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 1.5rem;
`;

export const OverwriteAmountCheckbox = styled(FormCheckbox)`
  width: 100%;
  height: 3rem;
  font-size: 0.875rem;
  flex-direction: row;
  align-items: center;
`;
