import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type HeaderWrapperProps = {
  bottomLineIsVisible: boolean;
};

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  ${({ bottomLineIsVisible, theme }) =>
    css`
      align-items: center;
      display: flex;
      padding: 0 0.75rem;
      min-height: 6rem;
      background: ${theme.color.white};

      ${bottomLineIsVisible &&
      css`
        border-bottom: 0.063rem solid ${theme.color.light1};
      `}
    `}
`;
