export enum CorporationInformationsFormFields {
  CorrespondenceAddressSameAsBilling = 'correspondenceAddressSameAsBilling',
  BillingAddress = 'billingAddress',
  CorrespondenceAddress = 'correspondenceAddress',
}

export type CorporationInformationsFormFieldsType =
  keyof typeof CorporationInformationsFormFields;

export const corporationInformationsFormFieldsInitState = (
  fields: Record<
    CorporationInformationsFormFieldsType,
    string
  > = CorporationInformationsFormFields,
) => ({
  [fields.CorrespondenceAddressSameAsBilling]: true,
  [fields.BillingAddress]: {},
  [fields.CorrespondenceAddress]: {},
});
