import { useParams } from 'react-router';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IWarningRatification } from '../../../../../../../models/interfaces/WarningRatification';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useActions } from '../../../../../../../hooks/useActions';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { SUBSCRIPTION_DETAILS_EDITING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { organizationNameSelector } from '../../../../../../../store/redux-slices/organizations/selectors';
import { useFormErrorsActions } from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useApiResponseFail } from '../../../../../../../hooks/useApiResponseFail';
import { editSRSubscriptionOptions } from '../../../../../../../store/saga-slices/subscriptions/securityResponse';
import { srSubscriptionOverviewSelector } from '../../../../../../../store/redux-slices/subscriptions/securityResponse/selectors';
import { ISROrganizationSubscriptionOptionsModel } from '../../../../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOptionsModel';
import { IEditSRSubscriptionOptionsPayload } from '../../../../../../../store/saga-slices/subscriptions/securityResponse/models';
import { EDITING_SR_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './srSubscriptionEditOptionsModal.const';
import { SecurityResponseSubscriptionOptions } from '../../../../../../../models/enums/SecurityResponseSubscriptionOptions';

type UseSRSubscriptionEditDetailsModalType = {
  onModalSuccess: () => void;
};

export const useSRSubscriptionEditDetailsModal = ({
  onModalSuccess,
}: UseSRSubscriptionEditDetailsModalType) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const EditSubscriptionDetails = useActions(editSRSubscriptionOptions);

  const subscriptionEditing = useProcessing(
    SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  );

  const subscriptionOverview = useSelector(srSubscriptionOverviewSelector);
  const organizationName = useSelector(organizationNameSelector);

  const { id: organizationId, subscriptionId } = useParams();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponse } = useApiResponseFail();

  const handleSubmit = (values: ISROrganizationSubscriptionOptionsModel) => {
    editOrganizationSubscription(values);
  };

  const editOrganizationSubscription = (
    values: ISROrganizationSubscriptionOptionsModel,
    ratifications: IWarningRatification[] = [],
  ) => {
    const payload: IEditSRSubscriptionOptionsPayload = {
      organizationId,
      subscriptionId,
      data: values,
      success: () => {
        alert.success(
          t('alerts.subscription-edit-success', {
            value: organizationName,
          }),
        );
        onModalSuccess && onModalSuccess();
        UpdateModalState(EDITING_SR_SUBSCRIPTION_OPTIONS_MODAL_NAME);
      },
      error: (err) => {
        handleResponse({
          err,
          values,
          ratifications,
          alertErrorMessage: t('alerts.subscription-edit-fail', {
            value: organizationName,
          }),
        });
      },
    };
    EditSubscriptionDetails(payload);
  };

  const handleWarningsConfirm = (
    data: ISROrganizationSubscriptionOptionsModel,
    warnings: IWarningRatification[],
  ) => {
    UpdateModalState([EDITING_SR_SUBSCRIPTION_OPTIONS_MODAL_NAME, { data }]);
    editOrganizationSubscription(data, warnings);
  };

  const onCancelHandler = () =>
    UpdateModalState(EDITING_SR_SUBSCRIPTION_OPTIONS_MODAL_NAME);

  const initData = subscriptionOverview && {
    [SecurityResponseSubscriptionOptions.AutoRenewal]:
      subscriptionOverview.autoRenewal,
  };

  return {
    handleWarningsConfirm,
    handleSubmit,
    validateErrors,
    UpdateModalState,
    subscriptionEditing,
    initData,
    onCancelHandler,
  };
};
