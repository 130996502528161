import { ProductType } from '../../../../models/ProductType';
import AAMembershipExpiredDetails from '../../AAMemberOverview/AAMembershipExpiredDetails/AAMembershipExpiredDetails';
import DOCMembershipExpiredDetails from '../../DOCMemberOverview/DOCMembershipExpiredDetails/DOCMembershipExpiredDetails';
import KEMembershipExpiredDetails from '../../KEMemberOverview/KEMembershipExpiredDetails/KEMembershipExpiredDetails';
import SRMembershipExpiredDetails from '../../SRMemberOverview/SRMembershipExpiredDetails/SRMembershipExpiredDetails';

export interface IMemberOverviewExpiredMemberDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

export const MemberOverviewExpiredMemberDetailsByProductType: Partial<
  Record<
    ProductType,
    (props: IMemberOverviewExpiredMemberDetailsProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AAMembershipExpiredDetails,
  [ProductType.DutyOfCare]: DOCMembershipExpiredDetails,
  [ProductType.KidnapAndExtortion]: KEMembershipExpiredDetails,
  [ProductType.SecurityResponse]: SRMembershipExpiredDetails,
  [ProductType.TravelLicense]: null,
};
