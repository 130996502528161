import styled from '@emotion/styled';

export const TotalPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const TotalPriceTitle = styled.h4`
  color: ${({ theme }) => theme.color.black};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0;
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TotalPriceValue = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0;
`;

export const OverwrittenTotalPriceValue = styled.p`
  font-size: 0.825rem;
  line-height: 2rem;
  margin: 0;
  text-decoration: line-through;
`;
