import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IAAOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { subscriptionDetailsSchema } from './SubscriptionDetailsForm/subscriptionDetailsForm.validation';
import { subscriptionPricingSchema } from './SubscriptionPricingForm/subscriptionPricingForm.validation';

export const aaSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionDetailsSchema.properties,
    ...subscriptionPricingSchema.properties,
  },
  required: [
    ...subscriptionDetailsSchema.required,
    ...subscriptionPricingSchema.required,
  ],
};

export const validateAddAASubscriptionForm = (
  data: IAAOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, aaSubscriptionSchema);
