import { Field } from 'react-final-form';

import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormWrapper } from '../../Form/Form';
import { FormAddressFields, FormAddressName } from './formAddress.models';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import { FormEmptyField } from '../../UI/Form/FormEmptyField/FormEmptyField';
import { useAddressForm } from './formAddress.hooks';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import { CustomForm } from '../../../models/types/CustomForm';
import { FormGooglePlacesAutocomplete } from '../../UI/Form/FormGooglePlacesAutocomplete/FormGooglePlacesAutocomplete';
import FormValue from '../../UI/Form/FormValue/FormValue';

interface IFormAddressProps extends CustomForm {
  isGoogleSearchHidden?: boolean;
  isSubRowSpace?: boolean;
}

const FormAddress = ({
  className,
  disabled,
  isGoogleSearchHidden,
  isSubRowSpace,
  name,
}: IFormAddressProps) => {
  const { countriesPromiseOptions } = useAddressForm({ name });
  return (
    <FormWrapper className={className}>
      <FormColumn>
        {!isGoogleSearchHidden && (
          <FormRow>
            <FormGooglePlacesAutocomplete
              disabled={disabled}
              fields={{
                city: `${name}.city`,
                addressLine1: `${name}.addressLine1`,
                addressLine2: `${name}.addressLine2`,
                country: `${name}.country`,
                zipCode: `${name}.zipCode`,
                stateRegion: `${name}.stateRegion`,
                longitude: `${name}.longitude`,
                latitude: `${name}.latitude`,
              }}
            />
            <FormEmptyField />
          </FormRow>
        )}
        <FormRow isSubRow={isSubRowSpace}>
          <Field
            component={FormInput}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: FormAddressName,
              name: FormAddressFields.AddressLine1,
              groupName: name,
            })}
          />
          <Field
            component={FormInput}
            disabled={disabled}
            name={`${name}.addressLine2`}
            {...getFieldPropertiesForGroup({
              inputId: FormAddressName,
              name: FormAddressFields.AddressLine2,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow isSubRow={isSubRowSpace}>
          <FormRow isSubRow>
            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormAddressName,
                name: FormAddressFields.ZipCode,
                groupName: name,
              })}
            />

            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormAddressName,
                name: FormAddressFields.City,
                groupName: name,
              })}
            />
          </FormRow>
          <FormRow isSubRow>
            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormAddressName,
                name: FormAddressFields.StateRegion,
                groupName: name,
              })}
            />
            <Field
              component={FormSelect}
              promiseOptions={countriesPromiseOptions}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormAddressName,
                name: FormAddressFields.Country,
                groupName: name,
              })}
            />
          </FormRow>
        </FormRow>
        <Field component={FormValue} name={`${name}.longitude`} />
        <Field component={FormValue} name={`${name}.latitude`} />
      </FormColumn>
    </FormWrapper>
  );
};

export default FormAddress;
