import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOrganizationSearchResult } from '../../saga-slices/organizations/api';

export interface IOptionsSearch {
  searchOrganizationsList?: IOrganizationSearchResult;
}

const initialState: IOptionsSearch = {
  searchOrganizationsList: null,
};

const optionsSearchSlice = createSlice({
  initialState,
  name: 'options-search',
  reducers: {
    setOrganizationsList: (
      state,
      { payload }: PayloadAction<IOrganizationSearchResult>,
    ) => {
      state.searchOrganizationsList = payload;
    },
  },
});

export const { setOrganizationsList } = optionsSearchSlice.actions;
export default optionsSearchSlice.reducer;
export const optionsSearchReducerName = optionsSearchSlice.name;
