import { useAlert } from 'react-alert';

import { useTranslation } from 'react-i18next';

import { useActions } from '../../../hooks/useActions';
import { useModalDetails } from '../../../hooks/useModalDetails';
import { updateModalState } from '../../../store/redux-slices/modals';
import { DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME } from './deleteSubscriptionModal.const';

import { useProcessing } from '../../../hooks/useProcessing';
import { SUBSCRIPTION_DELETE_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { RoutePath } from '../../../models/enums/RoutePath';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import { deleteProductSubscriptionByProductType } from '../../../store/saga-slices/subscriptions/common';
import { IDeleteProductSubscriptionByProductTypePayload } from '../../../store/saga-slices/subscriptions/common/models';
import { DeleteSubscriptionModalDetails } from './deleteSubscriptionModal.model';

type UseDeleteProductSubscriptionModalProps = {
  onProductSubscriptionDeleted?: () => void;
};

export const useDeleteProductSubscriptionModal = ({
  onProductSubscriptionDeleted,
}: UseDeleteProductSubscriptionModalProps = {}) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigateSearch();
  const cancellationToken = useCancellationToken();

  const {
    name,
    organizationId,
    subscriptionId,
    productType,
  }: DeleteSubscriptionModalDetails = useModalDetails(
    DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME,
  );

  const DeleteProductSubscription = useActions(
    deleteProductSubscriptionByProductType,
  );
  const UpdateModalState = useActions(updateModalState);

  const isProcessing = useProcessing(SUBSCRIPTION_DELETE_PROCESSING);

  const onBackHandler = () => {
    UpdateModalState(DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME);
  };

  const deleteProductSubscriptionHandler = () => {
    const payload: IDeleteProductSubscriptionByProductTypePayload = {
      organizationId,
      subscriptionId,
      productType,
      success: () => {
        navigate(RoutePath.ClientOverviewProductSubscriptionListPageFullPath, {
          params: { id: organizationId },
        });
        UpdateModalState(DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME);
        alert.success(t('phrase.subscription-has-been-deleted'));
        onProductSubscriptionDeleted?.();
      },
      error: () => alert.error(t('phrase.subscription-cannot-be-deleted')),
      cancellationToken,
    };

    DeleteProductSubscription(payload);
  };

  return {
    name,
    onBackHandler,
    deleteProductSubscriptionHandler,
    isProcessing,
  };
};
