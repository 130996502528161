import { useTranslation } from 'react-i18next';

import { FractionRenderer } from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';

interface ISeatsAmountRenderer {
  seatsAmount?: number;
  seatsInUse: number;
  firmWide?: boolean;
  options?: ICellOptions;
}

export const SeatsAmountRenderer = ({
  seatsInUse,
  options,
  firmWide,
  seatsAmount,
}: ISeatsAmountRenderer) => {
  const { t } = useTranslation();
  return (
    <FractionRenderer
      maxValue={firmWide ? t('fields.firmWide.label') : seatsAmount}
      options={options}
      value={firmWide && seatsInUse === 0 ? '-' : seatsInUse}
    />
  );
};
