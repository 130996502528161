import { InputHTMLAttributes } from 'react';

import { Alignment } from '../../../models/Alignment';
import { CustomField } from '../../../models/types/CustomField';
import { CustomFieldLabel } from '../../../models/types/CustomFieldLabel';
import { FieldLabel, FieldWrapper } from '../Field/Field';
import {
  BaseTextarea,
  ClearButton,
  StyledCharsNumber,
  Wrapper,
} from './textarea.styles';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear-icon.svg';
import { isEmpty } from '../../../utils/textHelpers';

export interface ITextareaProps
  extends InputHTMLAttributes<HTMLTextAreaElement>,
    CustomField,
    CustomFieldLabel {
  alignment?: Alignment;
  charsBeforeValue?: string;
  onlyNumber?: boolean;
  name?: string;
  placeholder?: string;
  id?: string;
  rows?: number;
  onClear?: () => void;
}

export const Textarea = ({
  alignment = 'left',
  className,
  disabled,
  isLabelHidden,
  labelAnimation,
  label,
  rows = 4,
  onClear,
  ...props
}: ITextareaProps) => (
  <Wrapper>
    <FieldWrapper className={className}>
      <BaseTextarea
        {...props}
        rows={rows}
        onChange={props.onChange}
        disabled={disabled}
        value={props.value}
      />
      {!isEmpty(props.value as string) && (
        <ClearButton styleType="icon" onClick={onClear}>
          <ClearIcon />
        </ClearButton>
      )}
      <StyledCharsNumber
        currentNumber={(props.value as string)?.length}
        maxCount={props.maxLength}
      />
      {!isLabelHidden && (
        <FieldLabel disabled={disabled} autoLabelAnimation={labelAnimation}>
          {label}
        </FieldLabel>
      )}
    </FieldWrapper>
  </Wrapper>
);
