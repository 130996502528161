import styled from '@emotion/styled/macro';

import { Line } from '../../../../styledComponents/UI/Line/Line';

export const StyledLine = styled(Line)`
  margin: 2.25rem 0;
`;

export const DetailBreak = styled.div`
  height: 1rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
