import { useEffect } from 'react';

import { useActions } from '../../../../../../hooks/useActions';
import {
  IWizardAvailableStep,
  updateAvailableSteps,
  WizardStepsCn,
} from '../../../../../../store/redux-slices/wizard';

export const useDutyOfCareMembership = () => {
  const changeAvailableStep = useActions(updateAvailableSteps);

  useEffect(() => {
    const steps: IWizardAvailableStep[] = [
      {
        canonicalName: WizardStepsCn.PaymentPage,
        isAvailable: false,
      },
    ];

    changeAvailableStep(steps);
  }, [changeAvailableStep]);
};
