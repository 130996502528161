import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { ICreateTLOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/TravelLicense/CreateTLOrganizationSubscriptionExtensionModel';
import { extendTLSubscriptionExtensionsFormSchema } from '../ExtendTLSubscriptionExtensionsForm/extendTLSubscriptionExtensionsForm.validation';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...extendTLSubscriptionExtensionsFormSchema.properties,
  },
  dependencies: {},
  required: [...extendTLSubscriptionExtensionsFormSchema.required],
};

export const validateExtendTLSubscriptionForm = (
  data: ICreateTLOrganizationSubscriptionExtensionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
