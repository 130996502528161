import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  canAddCreditCardPermissions,
  canViewCreditCardPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { CustomComponent } from '../../../../../models/types/CustomComponent';

import { firstCreditCardSelector } from '../../../../../store/redux-slices/payment/selectors';
import Radio from '../../../../../styledComponents/UI/Radio/Radio';
import {
  RadioContainer,
  RadioContent,
  Wrapper,
} from '../../../../../styledComponents/UI/RadioGroupContent/radioGroupContent.styles';
import PaymentCardDetailsForm from '../PaymentCardDetailsForm/PaymentCardDetailsForm';
import PaymentExistingCardForm from '../PaymentUseExistingCardForm/PaymentUseExistingCardForm';
import { PaymentType } from './paymentSection.models';

type PaymentGroupProps = CustomComponent & {
  onChange?: (paymentType: PaymentType) => void;
  checked?: PaymentType;
};

const PaymentGroup = ({ checked, disabled, onChange }: PaymentGroupProps) => {
  const { t } = useTranslation();
  const firstCreditCard = useSelector(firstCreditCardSelector);
  const { checkUserPermissions } = usePermissions();

  const isExistingCreditCardChecked =
    checked === PaymentType.UseExistingCreditCard;

  return (
    <Wrapper>
      {checkUserPermissions(canViewCreditCardPermissions) && (
        <RadioContainer>
          <Radio
            disabled={disabled || firstCreditCard?.isExpired}
            label={t(`phrase.use-existing-credit-card`)}
            onChange={() => onChange(PaymentType.UseExistingCreditCard)}
            checked={isExistingCreditCardChecked}
          />
          <RadioContent>
            <PaymentExistingCardForm
              disabled={!isExistingCreditCardChecked || disabled}
            />
          </RadioContent>
        </RadioContainer>
      )}
      {checkUserPermissions(canAddCreditCardPermissions) && (
        <RadioContainer>
          <Radio
            disabled={disabled}
            label={t(`phrase.add-new-credit-card`)}
            onChange={() => onChange(PaymentType.PayWithDifferentCard)}
            checked={checked === PaymentType.PayWithDifferentCard}
          />
          <RadioContent isHidden={checked !== PaymentType.PayWithDifferentCard}>
            <PaymentCardDetailsForm disabled={disabled} />
          </RadioContent>
        </RadioContainer>
      )}
    </Wrapper>
  );
};

export default PaymentGroup;
