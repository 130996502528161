import { useNavigate } from 'react-router';

import { useTranslation } from 'react-i18next';

import ProductPage from '../../styledComponents/ProductPage/ProductPage';
import { RoutePath } from '../../models/enums/RoutePath';
import { canAddOrganizationPermissions } from '../../config/accessPolicies/accessPolicies';
import { ClientsPagesPermissions, Views } from './corporateClientPage.const';
import CorporateClientsRoutes from './corporateClientPage.routes';

const CorporateClientsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ProductPage
      title={t('headers.corporate-clients-title')}
      buttonTitle={t('buttons.add-client')}
      onButtonClick={() => navigate(RoutePath.AddClient)}
      actionPermissions={canAddOrganizationPermissions}
      pagesPermissions={ClientsPagesPermissions}
      views={Views}
    >
      <CorporateClientsRoutes />
    </ProductPage>
  );
};

export default CorporateClientsPage;
