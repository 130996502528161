import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import Button from '../UI/Button/Button';
import { useCopyToClipboard } from './copyToClipboardButton.hooks';

interface ICopyToClipboardButtonProps {
  className?: string;
  onValueCopied?: (value: string) => void;
  value?: string;
}

const CopyToClipboardButton = ({
  className,
  onValueCopied,
  value,
}: ICopyToClipboardButtonProps) => {
  const { copyToClipboard } = useCopyToClipboard({ value, onValueCopied });

  return (
    <Button className={className} onClick={copyToClipboard} styleType="icon">
      <CopyIcon />
    </Button>
  );
};

export default CopyToClipboardButton;
