import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RouteHistory, RoutesHistoryState } from './models';

const initState: RoutesHistoryState = {
  routes: [],
};

const routesHistorySlice = createSlice({
  name: 'routesHistory',
  initialState: initState,
  reducers: {
    setRouteHistory: (state, action: PayloadAction<RouteHistory>) => {
      const { routes } = state;
      if (routes.length === 0) {
        routes.push(action.payload);
      } else if (
        routes[0].pathName + routes[0].search !==
        action.payload.pathName + action.payload.search
      ) {
        routes.unshift(action.payload);
      }
    },
    removeCurrentRouteHistory: (
      state,
      { payload = 1 }: PayloadAction<number>,
    ) => {
      state.routes.splice(0, payload);
    },
    removePreviousRouteHistory: (
      state,
      { payload = 1 }: PayloadAction<number>,
    ) => {
      state.routes.splice(1, payload);
    },
    removeRouteAfterCurrentPath: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      const index = state.routes.findIndex(
        (x) => x.pathName + x.search === payload,
      );
      state.routes.splice(0, index);
    },
  },
});

export const {
  setRouteHistory,
  removeCurrentRouteHistory,
  removeRouteAfterCurrentPath,
  removePreviousRouteHistory,
} = routesHistorySlice.actions;
export default routesHistorySlice.reducer;
export const routesHistoryReducerName = routesHistorySlice.name;
