import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ChangeEvent } from 'react';

import {
  Header,
  TransferAllEligibleMembershipsCheckbox,
} from './subscriptionPricing.styles';

import {
  SectionSubTitle,
  SectionTitleDescription,
} from '../../../../Typography/Typography';
import MembershipPlanFormProvider from '../../../../UI/Form/MembershipPlanForm/MembershipPlanFormProvider';
import SubscriptionMembershipRenewPlan from '../SubscriptionMembershipRenewPlan/SubscriptionMembershipRenewPlan';
import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';
import { BasicFormWrapper } from '../../../../Form/Form';
import { SubscriptionPricingFormFields } from './subscriptionPricing.models';
import { PricingMode } from '../../../../../models/enums/PricingMode';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { ProductType } from '../../../../../models/ProductType';
import { IMembershipPlanPriceModel } from '../../../../../models/MembershipPlanPriceModel';
import { IMembershipPlanPreviousPriceModel } from '../../../../UI/Form/MembershipPlanForm/useMembershipPlanPrices/useMembershipPlanPrices.models';

export type SubscriptionPricingProps = CustomComponent & {
  onValueChange?: (value: IOrganizationSubscriptionPricingModel) => void;
  defaultPlansData?: Record<string, { capacity: number; price: number }>;
  onTransferAllEligibleMembershipsChange?: (
    transferAllEligibleMemberships: ChangeEvent<HTMLInputElement>,
  ) => void;
  subscriptionRenewalSuggestionPlans?: IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[];
  productType?: ProductType;
  regularMembershipPlansPrices?: IMembershipPlanPriceModel[];
  previousMembershipPlansPrices?: IMembershipPlanPreviousPriceModel[];
  title?: string;
};

export const SubscriptionRenewPricing = ({
  disabled,
  onValueChange,
  defaultPlansData,
  onTransferAllEligibleMembershipsChange,
  subscriptionRenewalSuggestionPlans,
  productType,
  previousMembershipPlansPrices,
  regularMembershipPlansPrices,
  title,
}: SubscriptionPricingProps) => {
  const { t } = useTranslation();

  return (
    <MembershipPlanFormProvider
      regularMembershipPlansPrices={regularMembershipPlansPrices}
      previousMembershipPlansPrices={previousMembershipPlansPrices}
      defaultPricingMode={PricingMode.KeepPreviousPrice}
      useDefaultPlansData
      defaultPlansData={defaultPlansData}
      usePreviousMembershipPlansPrices
      onValueChanged={onValueChange}
    >
      <BasicFormWrapper>
        <Header>
          <SectionSubTitle>
            {title || t('headers.pricing-and-current-capacity')}
          </SectionSubTitle>
          <SectionTitleDescription>
            {t('phrase.this-pricing-defines-retail-prices')}
          </SectionTitleDescription>
          <Field
            component={TransferAllEligibleMembershipsCheckbox}
            onChange={onTransferAllEligibleMembershipsChange}
            disabled={disabled}
            inputId="subscription-transfer-all-eligible-memberships"
            name={SubscriptionPricingFormFields.TransferAllEligibleMemberships}
            type="checkbox"
          />
        </Header>
        <FormColumn>
          <Field
            component={SubscriptionMembershipRenewPlan}
            disabled={disabled}
            membershipPlansPrices={regularMembershipPlansPrices}
            subscriptionRenewalSuggestionPlans={
              subscriptionRenewalSuggestionPlans
            }
            name={SubscriptionPricingFormFields.Pricing}
            subscriptionType={productType}
          />
        </FormColumn>
      </BasicFormWrapper>
    </MembershipPlanFormProvider>
  );
};
