import { ReactElement } from 'react';

import { CustomFieldLabel } from '../../../../models/types/CustomFieldLabel';
import { FieldLabel } from '../../Field/Field';

type Props = {
  children: ReactElement & ReactElement[];
  selectProps: CustomFieldLabel & {
    menuIsOpen?: boolean;
    isDisabled?: boolean;
    value: string | string[] | any;
    name: string;
  };
};

export const ValueContainer = ({
  children,
  selectProps: {
    menuIsOpen,
    label,
    isDisabled,
    labelAnimation,
    isLabelHidden,
    value,
    name,
  },
}: Props) => {
  if (!isLabelHidden) {
    return (
      <>
        <FieldLabel
          disabled={isDisabled}
          labelActive={!labelAnimation || value || menuIsOpen}
        >
          {label}
        </FieldLabel>
        {children}
      </>
    );
  }

  return <>{children}</>;
};
