/* eslint-disable react/no-array-index-key */
import { useTranslation } from 'react-i18next';

import { IRenewalSubscriptionRecord } from '../../../../models/interfaces/RenewalFilters/RenewalSubscriptionsRecord';

import { CustomComponent } from '../../../../models/types/CustomComponent';
import { RenewalSubscriptionsResult } from '../../../../models/types/RenewalFilters/RenewalSubscriptionsResult';

import CollapseContent from '../../../../styledComponents/UI/CollapseContent/CollapseContent';
import { getFormattedDate } from '../../../../utils/dateHelpers';
import NotFoundData from '../../Common/RenewalsTables/NotFoundData/NotFoundData';
import { TablesWrapper } from '../../Common/RenewalsTables/renewalsTables.styles';
import RenewalsSubscriptionsTable from './RenewalsSubscriptionsTable/RenewalsSubscriptionsTable';

type RenewalsSubscriptionsTablesProps = CustomComponent & {
  data: RenewalSubscriptionsResult;
  dataLoading?: boolean;
  onCellClick?: (
    row: IRenewalSubscriptionRecord,
    key?: keyof IRenewalSubscriptionRecord,
  ) => void;
  onRowClick?: (row: IRenewalSubscriptionRecord) => void;
};

const defaultNumberOfOpenItems = 3;

const RenewalsSubscriptionsTables = ({
  data,
  className,
  dataLoading,
  onCellClick,
  onRowClick,
}: RenewalsSubscriptionsTablesProps) => {
  const { t } = useTranslation();

  if (!data?.length) {
    return (
      <TablesWrapper className={className}>
        <NotFoundData label={t('phrase.no-subscriptions-found')} />
      </TablesWrapper>
    );
  }

  return (
    <TablesWrapper className={className}>
      {data?.map((record, index) => (
        <CollapseContent
          key={`${record.month}/${record.year}${index}`}
          title={getFormattedDate(record.month, record.year)}
          defaultIsOpen={index < defaultNumberOfOpenItems}
        >
          <RenewalsSubscriptionsTable
            dataLoading={dataLoading}
            data={record.records}
            onCellClick={onCellClick}
            onRowClick={onRowClick}
          />
        </CollapseContent>
      ))}
    </TablesWrapper>
  );
};

export default RenewalsSubscriptionsTables;
