import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type DefaultType = {
  isActive?: boolean;
  disabled?: boolean;
  readonly?: boolean;
};

export const Wrapper = styled.div<DefaultType>`
  width: 100%;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ isActive, disabled, theme }) => {
    if (disabled && isActive) {
      return css`
        background-color: ${theme.color.dark2};
      `;
    }

    if (isActive) {
      return css`
        background-color: ${theme.color.secondary1};
        opacity: 1;
      `;
    }
  }}
`;

export const Icon = styled.span<DefaultType>`
  font-size: 0.875rem;
  margin-right: 0.625rem;

  ${({ isActive, disabled, theme }) => {
    if (isActive && !disabled)
      return css`
        stroke: ${theme.color.white};
        path {
          stroke: ${theme.color.secondary1};
          fill: ${theme.color.white};
          opacity: 1;
        }
      `;

    if (!isActive && disabled)
      return css`
        path {
          fill: ${theme.color.dark2};
        }
      `;

    if (isActive && disabled)
      return css`
        path {
          fill: ${theme.color.white};
          stroke: ${theme.color.dark2};
        }
      `;
  }}

  ${({ readonly, isActive }) =>
    readonly &&
    !isActive &&
    css`
      path {
        opacity: 0.5;
      }
    `}
`;

export const Label = styled.span<DefaultType>`
  color: ${({ theme }) => theme.color.secondary1};
  font-size: 0.875rem;
  line-height: 0;
  opacity: 1;

  ${({ isActive, disabled, readonly, theme }) => {
    if (!isActive && disabled)
      return css`
        color: ${theme.color.dark2};
      `;
    if (isActive)
      return css`
        color: ${theme.color.white};
      `;

    if (!isActive && readonly)
      return css`
        opacity: 0.5;
      `;
  }}
`;
