import { ICreatePromoCodeModel } from '../../../models/CreatePromoCodeModel';
import { ICreatePromoCodePageModel } from '../../../models/CreatePromoCodePageModel';

export const preparePromoCodeData = (
  data: ICreatePromoCodePageModel,
): ICreatePromoCodeModel => ({
  value: data.value,
  ownerId: data.owner?.organizationId,
  promoCodeType: data.type,
  pricing: data.pricing,
  validFrom: data.validFrom,
  validTo: data.validTo,
  commissionPercentage: data.commissionPercentage,
  commissionValue: data.commissionValue,
  commissionType: data.commissionType,
});
