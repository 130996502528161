import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Button from '../../UI/Button/Button';

export const Title = styled.h3`
  font: 700 normal 1.125rem/1.5rem ${({ theme }) => theme.font.gotham};
  margin: 1rem 0;
`;

export const ErrorText = styled.span`
  font: 700 normal 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.error};
`;
export const SuccessText = styled.span`
  font: 700 normal 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.green1};
`;
export const BoldText = styled.span`
  font: 700 normal 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.5rem;
  gap: 1.5rem;
`;

export const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  font: 700 normal 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
  text-align: right;
`;

const buttonStyles = css`
  padding: 0.5rem 1rem;
  width: 9.375rem;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.03125rem;
  font-weight: 700;
  height: 3rem;
`;

export const SaveButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`;

export const CancelButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.color.light1};
  color: ${({ theme }) => theme.color.secondary1};
`;
