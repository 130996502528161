import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { personActiveMembershipsSelector } from '../../../store/redux-slices/people/selectors';
import { clearMembershipsDetails } from '../../../store/saga-slices/membership/common';

export const useMemberOverviewActiveMembers = () => {
  const ClearMembershipsDetails = useActions(clearMembershipsDetails);

  const activePersonMemberships = useSelector(personActiveMembershipsSelector);

  useEffect(
    () => () => {
      ClearMembershipsDetails();
    },
    [ClearMembershipsDetails],
  );

  return { activePersonMemberships };
};
