import { CustomForm } from '../../../models/types/CustomForm';
import { FormDeleteButton, FormNumber, FormWrapper } from '../../Form/Form';
import ContractForm from '../ContractForm/ContractForm';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove-icon.svg';
import { FormRow } from '../../UI/Form/FormRow/FormRow';

interface IContractsItemFormProps extends CustomForm {
  contractNumber?: number;
  onRemoveContract?: () => void;
}

const ContractsItemForm = ({
  contractNumber,
  onRemoveContract,
  disabled,
  name,
}: IContractsItemFormProps) => (
  <FormWrapper>
    <FormRow isSubRow>
      {contractNumber && <FormNumber>{`${contractNumber}.`}</FormNumber>}
      <ContractForm name={name} detailsTitleVisible={false} />
    </FormRow>
    {onRemoveContract && (
      <FormDeleteButton
        disabled={disabled}
        onClick={onRemoveContract}
        styleType="icon"
      >
        <RemoveIcon />
      </FormDeleteButton>
    )}
  </FormWrapper>
);

export default ContractsItemForm;
