import { useTranslation } from 'react-i18next';

import { AirAmbulanceSubscriptionOptions } from '../../../../../../models/enums/AirAmbulanceSubscriptionOptions';
import { IAAOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';

import OverviewOptions from '../../../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLine,
  Wrapper,
} from './aASubscriptionOverviewInformation.styles';
import OverviewDetails from './AAOverviewDetails/AAOverviewDetails';
import {
  canRenewAASubscriptionPermissions,
  canUpdateAASubscriptionsDetailsPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';
import { useAASubscriptionOverviewInformation } from './aASubscriptionOverviewInformation.hooks';

interface IProductSubscriptionOverviewInformationProps {
  onEditOptionsClick?: () => void;
  onEditRenewalStatus?: () => void;
  subscriptionOverview: IAAOrganizationSubscriptionOverview;
  canEditOptions?: boolean;
  canRenew?: boolean;
}
const ProductSubscriptionOverviewInformation = ({
  onEditOptionsClick,
  onEditRenewalStatus,
  subscriptionOverview,
  canEditOptions,
  canRenew,
}: IProductSubscriptionOverviewInformationProps) => {
  const { t } = useTranslation();
  const { renewClickHandler } = useAASubscriptionOverviewInformation();

  return (
    <Wrapper>
      <OverviewDetails
        data={subscriptionOverview}
        canRenewMembershipPermissions={canRenewAASubscriptionPermissions}
        onRenewClick={canRenew && renewClickHandler}
        onEditRenewalStatus={onEditRenewalStatus}
      />
      <StyledLine />
      <OverviewOptions
        options={[
          {
            value: AirAmbulanceSubscriptionOptions.AutoRenewal,
            label: t('phrase.auto-renew-product-subscription'),
          },
        ]}
        data={subscriptionOverview}
        onEditOptionsClick={canEditOptions && onEditOptionsClick}
        canEditOptionsPermissions={canUpdateAASubscriptionsDetailsPermissions}
      />
    </Wrapper>
  );
};

export default ProductSubscriptionOverviewInformation;
