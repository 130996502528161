import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';

import { ProductType } from '../../../models/ProductType';
import { useSREditSubscriptionRenewPage } from './srSubscriptionRenewPage.hooks';
import SREditSubscriptionAnalyseModal from './SREEditSubscriptionAnalyseModal/SREditSubscriptionAnalyseModal';
import SREditSubscriptionRenewPageForm from './SRSubscriptionRenewPageForm/SRSubscriptionRenewPageForm';

export const SRSubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    dataLoading,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  } = useSREditSubscriptionRenewPage();

  if (dataLoading) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <SREditSubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.edit-renew-subscription', {
              productType: ProductType.SecurityResponse,
              organizationName: organization?.name,
            })}
          />
          <SREditSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
