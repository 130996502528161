import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError } from '../../../models/types/ApiError';

export interface IErrorsState {
  error: ApiError;
}

const initState: IErrorsState = {
  error: null,
};

const errorsSlice = createSlice({
  name: 'errors',
  initialState: initState,
  reducers: {
    setError: (state, { payload }: PayloadAction<ApiError>) => {
      state.error = payload;
    },
    resetError: () => initState,
  },
});

export const { setError, resetError } = errorsSlice.actions;
export default errorsSlice.reducer;
