import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { DOC_PACKAGES_LOADING_PROCESSING } from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import { getDOCPackagesQuery } from './api';
import { ILoadDOCPackagesPayload } from './models';
import { setDOCPackages } from '../../../redux-slices/packages/dutyOfCare';

const docPackagesSlice = createSliceSaga({
  name: 'doc-pricing-saga',
  caseSagas: {
    *loadDOCPackages({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadDOCPackagesPayload>) {
      try {
        yield put(updateProcessState(DOC_PACKAGES_LOADING_PROCESSING));

        const response = yield* call(getDOCPackagesQuery, cancellationToken);

        yield put(setDOCPackages(response.data.availablePackages));

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(DOC_PACKAGES_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default docPackagesSlice.saga;
export const { loadDOCPackages } = docPackagesSlice.actions;
export const { actions } = docPackagesSlice;
