import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import CorpPointOfContactForm from './CorpPointOfContactForm/CorpPointOfContactForm';
import { CustomForm } from '../../../models/types/CustomForm';
import { SmallBoldSectionTitle } from '../../Typography/Typography';
import {
  StyledButton,
  StyledLine,
  Wrapper,
} from './corpPointOfContactsForm.styles';
import { SelectOption } from '../../UI/MainSelect/mainSelect.types';

interface IPointOfContactProps extends CustomForm {
  title?: string;
  rolesList?: SelectOption[];
}

const CorpPointOfContacts = ({
  name,
  className,
  disabled,
  title,
  rolesList,
}: IPointOfContactProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      {title && <SmallBoldSectionTitle>{title}</SmallBoldSectionTitle>}
      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            {fields.map((fieldName, index) => (
              <div key={fieldName}>
                {index > 0 && <StyledLine />}
                <CorpPointOfContactForm
                  contactNumber={index + 1}
                  disabled={disabled}
                  name={fieldName}
                  rolesList={rolesList}
                  onRemoveContact={() => fields.remove(index)}
                />
              </div>
            ))}
            <StyledButton
              disabled={disabled}
              onClick={() =>
                fields.push({ isOrganizationAdministrator: false })
              }
              styleType="link"
            >
              {t('buttons.add-contact')}
            </StyledButton>
          </div>
        )}
      </FieldArray>
    </Wrapper>
  );
};

export default CorpPointOfContacts;
