import { OrganizationContractType } from '../../../models/enums/ContractType';
import { ProductType } from '../../../models/ProductType';
import Spinner from '../../Spinner/Spinner';
import { SectionTitle } from '../../Typography/Typography';
import BasicSubscriptionForm from '../FormSections/BasicSubscriptionForm/BasicSubscriptionForm';
import { useSubscriptionItemForm } from './subscriptionItemForm.hooks';
import { SubscriptionItemBaseProps } from './subscriptionItemForm.models';
import { Content, StyledCollapseItem } from './subscriptionItemForm.styles';
import {
  getSubscriptionTitle,
  SubscriptionTypeContent,
} from './subscriptionItemForm.utils';

type SubscriptionItemFormProps = SubscriptionItemBaseProps & {
  number?: number;
  collapsible?: boolean;
  contractType?: OrganizationContractType;
  availableProducts?: ProductType[];
};

const SubscriptionItemForm = ({
  number,
  name,
  disabled,
  collapsible = true,
  isEditMode = false,
  contractType,
  availableProducts,
}: SubscriptionItemFormProps) => {
  const { subscriptionType, spinnerVisible, productTypeChangedHandler } =
    useSubscriptionItemForm({ name, isEditMode });

  const CurrentComponent = SubscriptionTypeContent[subscriptionType];

  let content = CurrentComponent && (
    <CurrentComponent
      contractType={contractType}
      disabled={disabled}
      name={name}
      isEditMode={isEditMode}
    />
  );

  if (spinnerVisible) {
    content = <Spinner />;
  }

  return (
    <StyledCollapseItem
      defaultOpen
      collapseButtonHidden={!collapsible}
      disabled={disabled}
      title={
        <SectionTitle>
          {getSubscriptionTitle(subscriptionType, number)}
        </SectionTitle>
      }
    >
      <Content>
        <BasicSubscriptionForm
          disabled={disabled}
          name={name}
          isEditMode={isEditMode}
          onProductTypeChanged={productTypeChangedHandler}
          availableProducts={availableProducts}
        />
        {content}
      </Content>
    </StyledCollapseItem>
  );
};

export default SubscriptionItemForm;
