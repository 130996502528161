import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';
import { ValidationCustomKeywords } from '../../../../utils/validations/customKeywords';
import { validateFormData } from '../../../../utils/validations/validateFormData';

import { TableFormColumns } from './TLSubscriptionPricingTable/tlSubscriptionPricingTable.models';

export const tlSubscriptionPricingFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [TableFormColumns.seatsAmount]: {
      type: 'number',
      minimum: 1,
    },
    [TableFormColumns.price]: {
      type: 'number',
      [ValidationCustomKeywords.UniqueArrayNumberItem]: true,
    },
  },
  required: [TableFormColumns.seatsAmount, TableFormColumns.price],
};

export const validateTLSubscriptionPricingForm = (
  data: ITLOrganizationSubscriptionPricingTierModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, tlSubscriptionPricingFormSchema);
