/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import * as _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';

import { isPropertyEmpty } from '../utils/propertyHelpers';
import {
  convertStringToBoolean,
  isStringBoolean,
} from '../utils/booleanHelpers';

export function useFilters<T extends string>() {
  const [filters, setFilters] = useState<Record<T, any>>({} as any);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params: any = queryString.parse(searchParams.toString());
    Object.keys(params).forEach((key) => {
      if (isStringBoolean(params[key])) {
        params[key] = convertStringToBoolean(params[key]);
      }
    });
    setFilters(params);
  }, []);

  const addFilter = useCallback(
    (name: T, value: any) => {
      setFilters((prevFilters) => ({ ...prevFilters, ...{ [name]: value } }));
    },
    [setFilters],
  );

  const updateQueryString = useCallback(
    _.debounce(
      (currentFilters) => {
        if (Object.keys(currentFilters).length === 0) {
          return;
        }

        Object.entries(currentFilters).forEach(([key, value]) => {
          if (
            isPropertyEmpty(value) ||
            (isArray(value) && value.length === 0)
          ) {
            searchParams.delete(key);
            return;
          }

          if (isArray(value)) {
            value.forEach((x, index) => {
              if (index === 0) {
                searchParams.set(key, x);
                return;
              }
              searchParams.append(key, x);
            });
            return;
          }

          searchParams.set(key, value as string);
        });

        setSearchParams(searchParams);
      },
      500,
      {
        leading: false,
        trailing: true,
      },
    ),
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    updateQueryString(filters);
  }, [filters]);

  return { filters, addFilter };
}
