import { createSelector } from 'reselect';

import { IMembershipPlanPriceModel } from 'models/MembershipPlanPriceModel';

import { RootState } from '../../index';
import { IKEPricingState } from './index';

export const pricingSelector = (state: RootState) => state.kePricing;

export const keMembershipPlansPricesSelector = createSelector(
  pricingSelector,
  (pricing: IKEPricingState): IMembershipPlanPriceModel[] =>
    pricing.membershipPlansPrices,
);
