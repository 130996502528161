import { ReactElement, ReactNode } from 'react';

import { FieldLabel } from '../../../Field/Field';

import { Container, SearchWrapper } from './valueContainer.styles';
import { MultiSelectContainer } from './MultiSelectContainer';
import { MultiInputType } from '../../../../../models/enums/MultiInputType';

type Props = {
  children: ReactElement & ReactElement[];
  selectProps: {
    placeholder?: string;
    menuIsOpen?: boolean;
    label?: string;
    isDisabled?: boolean;
    isLabelHidden?: boolean;
    labelAnimation?: boolean;
    currentValue: string | string[] | any;
    multiInputType: MultiInputType;
    valueRenderer?: (value: any) => ReactNode;
  };
};

export const ValueContainer = ({
  children,
  selectProps: {
    menuIsOpen,
    placeholder,
    label,
    isDisabled,
    labelAnimation,
    isLabelHidden,
    currentValue,
    multiInputType = MultiInputType.Default,
    valueRenderer,
  },
}: Props) => {
  const [values] = children;

  const searchInput =
    children.length > 0 ? children[children.length - 1] : null;

  const isArray = Array.isArray(values);
  return (
    <>
      {!isLabelHidden && (
        <FieldLabel
          disabled={isDisabled}
          labelActive={!labelAnimation || currentValue || menuIsOpen}
        >
          {label}
        </FieldLabel>
      )}
      <Container isArray={isArray}>
        {isArray ? (
          <MultiSelectContainer
            placeholder={placeholder}
            values={values}
            type={multiInputType}
            valueRenderer={valueRenderer}
          />
        ) : (
          values
        )}
        <SearchWrapper>{searchInput}</SearchWrapper>
      </Container>
    </>
  );
};
