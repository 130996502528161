/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IRenewalSubscriptionRecord } from '../../../../../models/interfaces/RenewalFilters/RenewalSubscriptionsRecord';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import MaterialTable from '../../../../../styledComponents/MaterialTable/MaterialTable';
import { renewalSubscriptionsTableCellRenderer as cellRenderer } from './renewalsSubscriptionsTable.utils';

type RenewalsSubscriptionTableProps = CustomComponent & {
  data: IRenewalSubscriptionRecord[];
  dataLoading?: boolean;
  onCellClick?: (
    row: IRenewalSubscriptionRecord,
    key?: keyof IRenewalSubscriptionRecord,
  ) => void;
  onRowClick?: (row: IRenewalSubscriptionRecord) => void;
};

const RenewalsSubscriptionsTable = ({
  data,
  dataLoading,
  onRowClick,
  onCellClick,
}: RenewalsSubscriptionTableProps) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      columns={[
        {
          title: t('grid-columns.organization'),
          field: 'organizationName',
          alignment: 'left',
          sortable: true,
          cellRenderer: cellRenderer('organizationName', {
            textBold: true,
          }),
          onClick: onCellClick,
        },
        {
          title: t('grid-columns.renewal-status'),
          alignment: 'left',
          sortable: true,
          field: 'renewalStatus',
          cellRenderer: cellRenderer('renewalStatus'),
          onClick: onCellClick,
        },
        {
          title: t('grid-columns.expiration-date'),
          alignment: 'left',
          sortable: true,
          field: 'expirationDate',
          cellRenderer: cellRenderer('expirationDate'),
        },
        {
          title: t('grid-columns.product'),
          alignment: 'left',
          field: 'productsAbbreviation',
          cellRenderer: cellRenderer('productAbbreviation'),
        },
        {
          title: t('grid-columns.contract-name'),
          alignment: 'left',
          field: 'contractName',
          cellRenderer: cellRenderer('contractName', { textBold: true }),
          onClick: onCellClick,
        },
        {
          title: t('grid-columns.role-am'),
          alignment: 'left',
          field: 'accountManagers',
          cellRenderer: cellRenderer('accountManagers'),
        },
        {
          title: t('grid-columns.role-bd'),
          alignment: 'left',
          field: 'bdRepresentatives',
          cellRenderer: cellRenderer('bdRepresentatives'),
        },
      ]}
      data={data}
      dataLoading={dataLoading}
      onRowClick={onRowClick}
      isPaginationHidden
    />
  );
};

export default RenewalsSubscriptionsTable;
