import { useCallback, useState } from 'react';

import { useComponentIsMounted } from '../../../hooks/useComponentIsMounted';
import { useDebounce } from '../../../hooks/useDebounce';
import { ISearchInputOption } from './searchInput.models';

type UseSearchInput<T> = {
  onChange?: (option: ISearchInputOption<T> | string) => void;
  promiseOptions?: (text: string) => Promise<ISearchInputOption<T>[]>;
};

export const useSearchInput = <T>({
  onChange,
  promiseOptions,
}: UseSearchInput<T>) => {
  const [options, setOptions] = useState<ISearchInputOption<T>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();

  const isMounted = useComponentIsMounted();

  const onChangeInput = useCallback(
    (values: any) => {
      debounce(() => {
        onChange(values.target.value);
        setIsLoading(true);
        promiseOptions(values.target.value).then((x) => {
          if (isMounted()) {
            setOptions(x);
            setIsLoading(false);
          }
        });
      }, 500);
    },
    [debounce, isMounted, onChange, promiseOptions],
  );

  const onChangeOption = (
    evt: React.SyntheticEvent<Element, Event>,
    currentValue: ISearchInputOption<T> | string | any,
  ) => {
    onChange?.(currentValue);
  };

  const onBlurHandler = () => {
    setOptions([]);
  };

  return {
    onBlurHandler,
    onChangeInput,
    options,
    isLoading,
    onChangeOption,
  };
};
