import styled from '@emotion/styled';

type WrapperProps = {
  isOpen?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  border: 0.063rem solid
    ${({ theme, isOpen }) =>
      isOpen ? theme.color.lighter_blue : theme.color.light1};
  border-radius: 0.5rem;
  min-width: 68rem;
  box-shadow: none;
  ${({ isOpen, theme }) =>
    isOpen &&
    `
    background-color: ${theme.color.light3};
  `}
`;
type ItemSummaryProps = {
  isOpen?: boolean;
};
export const ItemSummary = styled.div<ItemSummaryProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 1.5rem 1.5rem;

  &:hover {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: ${({ theme }) => theme.color.hover};
    cursor: pointer;
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
      border-bottom: 0.063rem solid ${theme.color.light1};
    `}
`;

export const ItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 2rem 1.75rem 1.75rem;
`;
