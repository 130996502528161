import { t } from 'i18next';

import { TableTooltip } from '../../materialTable.styles';
import { IProductMembershipInfo } from '../../../../models/IProductMembershipInfo';

export const prepareProductsTooltip = (products: IProductMembershipInfo[]) =>
  products?.map((item: IProductMembershipInfo, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TableTooltip key={index}>
      {`${t(`enums.shortcut-to-product-type.${item.product}`)}`}
    </TableTooltip>
  ));
