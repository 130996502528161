import {
  MemberOverviewExpiredMemberSummaryByProductType,
  IMemberOverviewExpiredMemberSummaryProps,
} from './memberOverviewExpiredMemberSummary.utils';

const MemberOverviewExpiredMemberSummary = ({
  productType,
  ...props
}: IMemberOverviewExpiredMemberSummaryProps) => {
  const Content = MemberOverviewExpiredMemberSummaryByProductType[productType];
  return <>{Content && <Content {...props} productType={productType} />}</>;
};

export default MemberOverviewExpiredMemberSummary;
