import { Route, Routes } from 'react-router';

import {
  canViewAAMembersPermissions,
  canViewDOCMembersPermissions,
  canViewGeneralMembersPermissions,
  canViewKEMembersPermissions,
  canViewSRMembersPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../../../models/enums/RoutePath';
import AuthRoute from '../../../../styledComponents/AuthRoute/AuthRoute';
import AAMembers from './AAMembers/AAMembers';
import DOCMembers from './DOCMembers/DOCMembers';
import GeneralMembers from './GeneralMembers/GeneralMembers';
import KEMembers from './KEMembers/KEMembers';
import SRMembers from './SRMembers/SRMembers';

const MembersTabPageRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.ProductGeneralPage}
      element={
        <AuthRoute permissions={canViewGeneralMembersPermissions}>
          <GeneralMembers />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductAirAmbulancePage}
      element={
        <AuthRoute permissions={canViewAAMembersPermissions}>
          <AAMembers />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductKidnapAndExtortionPage}
      element={
        <AuthRoute permissions={canViewKEMembersPermissions}>
          <KEMembers />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductSecurityResponsePage}
      element={
        <AuthRoute permissions={canViewSRMembersPermissions}>
          <SRMembers />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductDutyOfCarePage}
      element={
        <AuthRoute permissions={canViewDOCMembersPermissions}>
          <DOCMembers />
        </AuthRoute>
      }
    />
  </Routes>
);

export default MembersTabPageRoutes;
