import { useCallback } from 'react';

import {
  canCloneAAPromoCodePermissions,
  canDeleteAAPromoCodePermissions,
  canEnableAAPromoCodePermissions,
} from '../../../config/accessPolicies/accessPolicies';

import { MenuOptionsRenderer } from '../../MaterialTable/CellActionsRenderers/CellActionsRenderers';

import { MenuOption } from '../../Menu/Menu';
import { MenuActions } from './promoCodes.models';

export const useTableActions = () => {
  const renderMenuOptions = useCallback(
    (data: any): MenuOption[] => [
      {
        canonicalName: MenuActions.Enable,
        permissions: canEnableAAPromoCodePermissions,
      },
      {
        canonicalName: MenuActions.Delete,
        permissions: canDeleteAAPromoCodePermissions,
      },
      {
        canonicalName: MenuActions.Clone,
        permissions: canCloneAAPromoCodePermissions,
      },
    ],
    [],
  );
  const handleMenuOptionClick = useCallback(
    (option: MenuActions | string, data: any) => {
      switch (option) {
        case MenuActions.Enable:
          // eslint-disable-next-line no-console

          break;
        case MenuActions.Delete:
          // eslint-disable-next-line no-console

          break;
        case MenuActions.Clone:
          // eslint-disable-next-line no-console

          break;
      }
    },
    [],
  );

  const actionCellRenderer = useCallback(
    (data: any) => (
      <MenuOptionsRenderer
        options={renderMenuOptions(data)}
        onElementClick={(option) => handleMenuOptionClick(option, data)}
      />
    ),
    [handleMenuOptionClick, renderMenuOptions],
  );

  return { actionCellRenderer };
};
