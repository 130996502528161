import { css } from '@emotion/react';

import styled from '@emotion/styled/macro';

import NumericInput from '../../../../../styledComponents/UI/NumericInput/NumericInput';
import { StyledInput } from '../../../../../styledComponents/UI/NumericInput/numericInput.styles';

type NumericInputType = {
  isError?: boolean;
};

export const StyledNumericInput = styled(NumericInput)<NumericInputType>`
  ${StyledInput} {
    ${({ isError, theme }) =>
      isError &&
      css`
        color: ${theme.color.error};
      `}
  }
`;

type NewAmountType = {
  isError?: boolean;
};

export const NewAmount = styled.div<NewAmountType>`
  ${({ isError, theme }) =>
    isError &&
    css`
      color: ${theme.color.error};
    `}
`;
