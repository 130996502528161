import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DutyOfCareSubscriptionPackageType } from '../../../../models/enums/DutyOfCareSubscriptionPackageType';

export interface IDOCPackagesState {
  packages: DutyOfCareSubscriptionPackageType[];
}

const initialState: IDOCPackagesState = {
  packages: [],
};

const docPricingSlice = createSlice({
  name: 'docPricing',
  initialState,
  reducers: {
    setDOCPackages: (
      state,
      { payload }: PayloadAction<DutyOfCareSubscriptionPackageType[]>,
    ) => {
      state.packages = payload;
    },
    clearDOCPackages: (state) => {
      state.packages = initialState.packages;
    },
  },
});

export const { clearDOCPackages, setDOCPackages } = docPricingSlice.actions;
export default docPricingSlice.reducer;
export const pricingReducerName = docPricingSlice.name;
