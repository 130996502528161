import { AnySchema } from 'ajv';

import { ICreatePrimaryMemberModel } from '../../../../../models/CreatePrimaryMemberModel';
import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { memberAddressInformationSchema } from '../../../../../styledComponents/Forms/MemberAddressInformationForm/memberAddressInformation.validation';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { validatePrimaryMemberDetailsSchema } from '../AddMemberPrimaryMemberPageForms/PrimaryMemberDetailsForm/primaryMemberDetailsForm.validation';

export const primaryMemberDetailsSchema: AnySchema | any = {
  type: 'object',
  ...memberAddressInformationSchema,
  properties: {
    ...validatePrimaryMemberDetailsSchema.properties,
    ...memberAddressInformationSchema.properties,
  },
  required: [
    ...validatePrimaryMemberDetailsSchema.required,
    ...memberAddressInformationSchema.required,
  ],
};

export const validatePrimaryMemberDetailsForm = (
  data: ICreatePrimaryMemberModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, primaryMemberDetailsSchema);
