import { useTranslation } from 'react-i18next';

import { canEnableAAPromoCodePermissions } from '../../../config/accessPolicies/accessPolicies';
import { IPromoCodeOverview } from '../../../models/PromoCodeOverview';
import { Card } from '../../../styledComponents/UI/Card/Card';
import PromoCodeOverviewDetails from './PromoCodeOverviewDetails/PromoCodeOverviewDetails';
import { StyledSwitch } from './promoCodeOverviewDetailsCard.styles';
import PromoCodeOverviewDetailsHeader from './PromoCodeOverviewDetailsHeader/PromoCodeOverviewDetailsHeader';

interface PromoCodeOverviewDetailsCardProps {
  data: IPromoCodeOverview;
  onCloneClick?: () => void;
  onDeleteClick?: () => void;
  onChangeStatus?: (value: boolean) => void;
  canBeDeleted?: boolean;
  onEditDates?: () => void;
  onOwnerNameClick?: () => void;
}

const PromoCodeOverviewDetailsCard = ({
  data,
  onCloneClick,
  onDeleteClick,
  onChangeStatus,
  canBeDeleted,
  onEditDates,
  onOwnerNameClick,
}: PromoCodeOverviewDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <PromoCodeOverviewDetailsHeader
        onCloneClick={onCloneClick}
        onDeleteClick={onDeleteClick}
        canBeDeleted={canBeDeleted}
      />
      <PromoCodeOverviewDetails
        onEditDates={onEditDates}
        onOwnerNameClick={onOwnerNameClick}
        data={data}
      />
      <StyledSwitch
        permissions={canEnableAAPromoCodePermissions}
        label={t('phrase.enable-promo-code')}
        onChange={() => onChangeStatus && onChangeStatus(!data.isEnabled)}
        checked={data.isEnabled}
      />
    </Card>
  );
};

export default PromoCodeOverviewDetailsCard;
