import {
  canAddAAMemberPermissions,
  canAddSeatsAASubscriptionPermissions,
  canEditAASubscriptionPermissions,
  canRemoveAASubscriptionPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

export const aaSubscriptionOverviewPermissions: ProductSubscriptionActionsPermissions =
  {
    canAddMemberPermissions: canAddAAMemberPermissions,
    canAddSeatsSubscriptionPermissions: canAddSeatsAASubscriptionPermissions,
    canEditSubscriptionPermissions: canEditAASubscriptionPermissions,
    canRemoveSubscriptionPermissions: canRemoveAASubscriptionPermissions,
  };
