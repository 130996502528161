import styled from '@emotion/styled';

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const SearchInputContainer = styled.div`
  height: 3rem;
  min-width: 10rem;
  max-width: 25rem;
  flex-grow: 3;
`;

export const InputContainer = styled.div`
  height: 3rem;
  min-width: 10rem;
  flex-grow: 1;
  max-width: 15rem;
`;
