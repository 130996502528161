import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RoutePath } from '../../../../../models/enums/RoutePath';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { INavigationPath } from '../../../../../styledComponents/NavigationPath/NavigationPath';

export const useSubscriptionOverviewNavigate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();

  const navigationPaths: INavigationPath[] = useMemo(
    () => [
      {
        label: t('phrase.active-subscriptions'),
        url: `${RoutePath.ClientOverview.replace(':id', id)}/${
          RoutePath.ClientOverviewProductSubscriptionListPage
        }`,
      },
      { label: t(`enums.productType.${subscriptionType}`) },
    ],
    [id, subscriptionType, t],
  );

  return { navigationPaths };
};
