import { useTranslation } from 'react-i18next';

import { PromoCodeType } from '../../../../models/enums/PromoCodeType';
import { ICellRenderer } from '../../../MaterialTable/CellRenderers/cellRenderers.models';
import EmptyCell from '../../../MaterialTable/CellRenderers/EmptyCell/EmptyCell';
import TableCell from '../../../MaterialTable/CellRenderers/TableCell/TableCell';
import MainTooltip from '../../../UI/MainTooltip/MainTooltip';
import {
  IconType,
  NameContainer,
  NameValue,
} from './promoCodesCellRenderers.styles';

type PromoCodeNameRendererType = ICellRenderer & {
  promoCodeType: PromoCodeType;
};

export const PromoCodeNameRenderer = ({
  value,
  options,
  promoCodeType,
}: PromoCodeNameRendererType) => {
  const { t } = useTranslation();
  if (!value) {
    return <EmptyCell alignment={options?.alignment} />;
  }

  return (
    <TableCell alignment={options?.alignment}>
      <NameContainer>
        <MainTooltip
          arrow
          placement="top"
          title={t(`enums.promo-code-type.${promoCodeType}`)}
        >
          <IconType type={promoCodeType} />
        </MainTooltip>
        <NameValue>{value}</NameValue>
      </NameContainer>
    </TableCell>
  );
};
