import { useEffect, useMemo } from 'react';
import { useField } from 'react-final-form';

import { isPropertyEmpty } from '../../utils/propertyHelpers';

import { ISummaryFormData } from './SummaryContainer';

export const useSummaryContainer = (
  data: ISummaryFormData,
  canOverwriteAmount: boolean,
) => {
  const {
    input: { value: overwriteAmount, onChange: onOverwriteAmountChange },
  } = useField('overwriteAmount');

  const {
    input: { value: overwrittenAmount },
  } = useField('overwrittenAmount');

  const currentTotalPrice = useMemo(
    () =>
      data ? (overwriteAmount ? overwrittenAmount || 0 : data.totalAmount) : 0,
    [data, overwriteAmount, overwrittenAmount],
  );

  useEffect(() => {
    canOverwriteAmount && onOverwriteAmountChange(false);
  }, [canOverwriteAmount, onOverwriteAmountChange]);

  const overwrittenTotalPrice = useMemo(
    () =>
      data && overwriteAmount && !isPropertyEmpty(data.totalAmount)
        ? data.totalAmount
        : 0,
    [data, overwriteAmount],
  );

  return {
    currentTotalPrice,
    overwrittenTotalPrice,
    overwriteTotalAmount: overwriteAmount,
  };
};
