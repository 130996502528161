import { t } from 'i18next';

import { PromoCodeType } from '../../../../models/enums/PromoCodeType';
import { ProductType } from '../../../../models/ProductType';
import { searchAAOrganization } from '../../../../store/saga-slices/optionsSearch/airAmbulance/api';

export const promoCodeTypeOptions = Object.values(PromoCodeType)
  .filter((x) => x !== PromoCodeType.Corporation)
  .map((type) => ({
    label: t(`enums.promo-code-type.${type}`),
    value: type,
  }));

export const promoCodeProductOptions = Object.values(ProductType)
  .filter((x) => x === ProductType.AirAmbulance)
  .map((type) => ({
    label: t(`enums.productType.${type}`),
    value: type,
  }));

export const organizationsPromiseOptions = (inputValue: string) =>
  searchAAOrganization(inputValue).then((x) =>
    x.data.organizations.map((organization) => ({
      label: organization.name,
      value: organization,
    })),
  );
