import { useSelector } from 'react-redux';

import { useFieldArray } from 'react-final-form-arrays';

import { useProcessing } from '../../../hooks/useProcessing';

import { SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { SubscriptionsFormFields } from '../../SubscriptionFormPage/SubscriptionsForm/subscriptionsForm.models';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';
import { SubscriptionsOverwriteAmount } from './subscriptionsSummary.models';
import { subscriptionsSummarySelector } from '../../../store/redux-slices/subscriptions/common/selectors';

export const useSubscriptionsSummary = (
  subscriptionsOverwrittenAmount?: SubscriptionsOverwriteAmount[],
) => {
  const summaryData = useSelector(subscriptionsSummarySelector);

  const { fields } = useFieldArray(SubscriptionsFormFields.Subscriptions);

  const summaryIsLoading = useProcessing(
    SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  );

  const onRemoveHandler = (index: number) => {
    fields.remove(index);
  };

  const isPriceOverridden = subscriptionsOverwrittenAmount?.some(
    (x) => x.overwriteAmount,
  );

  const totalPrice = summaryData?.reduce((acc, current) => {
    acc += +current.amount;

    return acc;
  }, 0);

  const overwrittenTotalPrice = subscriptionsOverwrittenAmount?.reduce(
    (acc, current, index) => {
      if (!summaryData || summaryData.length <= index) {
        return acc;
      }
      if (current.overwriteAmount) {
        acc += !isPropertyEmpty(current.overwrittenAmount)
          ? +current.overwrittenAmount
          : 0;
        return acc;
      }

      acc += +summaryData[index].amount;

      return acc;
    },
    0,
  );

  return {
    onRemoveHandler,
    summaryData,
    summaryIsLoading,
    fields,
    isPriceOverridden,
    overwrittenTotalPrice,
    totalPrice,
  };
};
