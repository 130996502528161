import axios from 'axios';

import config from '../../../../config/config';

const MainUrl = 'api/reports';

export const getKERawDataReports = () =>
  axios.get(`${MainUrl}/personMembership`, {
    baseURL: config.kidnapAndExtortionApi.baseUrl,
    headers: { accept: 'text/csv' },
  });
