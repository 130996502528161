/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'react-final-form';

import { CustomForm } from '../../../../models/types/CustomForm';
import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';
import { useTLSubscriptionPricingTable } from './extendTLSubscriptionPackageFormTable.hooks';
import { ICellOptions } from '../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { FormPricingRenderer } from '../../../../styledComponents/MaterialTable/CellRenderers/Form/FormPricingRenderer/FormPricingRenderer';
import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import {
  TableDataType,
  TableFormColumns,
  TableFormName,
} from './extendTLSubscriptionPackageFormTable.models';
import { FormNumericRenderer } from '../../../../styledComponents/MaterialTable/CellRenderers/Form/FormNumericRenderer/FormNumericRenderer';
import { DeleteRowAction } from '../../../../styledComponents/MaterialTable/CellActionsRenderers/CellActionsRenderers';
import {
  ErrorsWrapper,
  ErrorWrapper,
  StyledErrorLabel,
  Wrapper,
} from './extendTLSubscriptionPackageFormTable.styles';
import MaterialTable from '../../../../styledComponents/MaterialTable/MaterialTable';
import { prepareTableData } from './extendTLSubscriptionPackageFormTable.utils';
import { ITLSubscriptionPricingTierOverviewModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';
import { InfoStatus } from '../../../../styledComponents/UI/InfoStatus';
import { Status } from '../../../../models/enums/Status';
import TableCell from '../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

type TLSubscriptionPricingFormProps = CustomForm & {
  pricingData: ITLSubscriptionPricingTierOverviewModel[];
  onDelete?: (id: number) => void;
  errors?: ITLOrganizationSubscriptionPricingTierModel[];
  touched?: boolean;
  fieldsData: TableDataType[];
  newValues: IChangeTLSubscriptionPricingTierModel[];
};

const TLSubscriptionPricingTableForm = ({
  className,
  disabled,
  pricingData,
  onDelete,
  errors,
  touched,
  fieldsData,
  name,
  newValues,
}: TLSubscriptionPricingFormProps) => {
  const { t } = useTranslation();
  const { preparedErrors } = useTLSubscriptionPricingTable({
    errors,
  });
  const cellRenderer = useCallback(
    (
        key: keyof IChangeTLSubscriptionPricingTierModel,
        options?: ICellOptions,
      ) =>
      ({ name: fieldName, currentSeatsAmount }: TableDataType) => {
        switch (key) {
          case 'price': {
            return (
              <Field
                component={FormPricingRenderer}
                options={options}
                minValue={0}
                allowNegative
                readonly={currentSeatsAmount > 0}
                {...getFieldPropertiesForGroup({
                  inputId: TableFormName,
                  groupName: fieldName,
                  name: TableFormColumns.price,
                })}
              />
            );
          }
          case 'seatsAmount': {
            return (
              <FormNumericRenderer
                options={options}
                disabled={disabled}
                minValue={-currentSeatsAmount}
                showError={false}
                {...getFieldPropertiesForGroup({
                  inputId: TableFormName,
                  groupName: fieldName,
                  name: TableFormColumns.seatsAmount,
                })}
              />
            );
          }
        }
      },
    [disabled],
  );

  const actionsRenderer = ({ index, currentSeatsAmount }: TableDataType) => (
    <TableCell alignment="center">
      <DeleteRowAction
        disabled={currentSeatsAmount > 0}
        data={index}
        onClick={onDelete}
      />
    </TableCell>
  );

  return (
    <Wrapper>
      <MaterialTable
        className={className}
        columns={[
          {
            title: t('grid-columns.price-per-license'),
            cellRenderer: cellRenderer('price', {
              disabled,
            }),
          },
          {
            title: t('grid-columns.current-amount'),
            field: 'currentSeatsAmount',
            alignment: 'center',
          },
          {
            title: t('grid-columns.changes'),
            alignment: 'center',
            cellRenderer: cellRenderer('seatsAmount', {
              disabled,
              alignment: 'center',
            }),
          },
          {
            title: t('grid-columns.new-amount'),
            field: 'newSeatsAmount',
            alignment: 'center',
          },
          {
            title: t('grid-columns.actions'),
            alignment: 'center',
            cellRenderer: actionsRenderer,
          },
        ]}
        data={prepareTableData(pricingData, newValues, fieldsData)}
        showNotFoundComponent={false}
        dataLoading={false}
        isPaginationHidden
      />
      {touched && preparedErrors && (
        <ErrorsWrapper>
          {preparedErrors.map((error, index) => (
            <ErrorWrapper key={error + index}>
              <InfoStatus status={Status.ERROR} />
              <StyledErrorLabel>{error}</StyledErrorLabel>
            </ErrorWrapper>
          ))}
        </ErrorsWrapper>
      )}
    </Wrapper>
  );
};

export default TLSubscriptionPricingTableForm;
