import { t } from 'i18next';

import { AirAmbulanceSubscriptionOptions } from '../../../../models/enums/AirAmbulanceSubscriptionOptions';

import { OrganizationContractType } from '../../../../models/enums/ContractType';

import { SubscriptionOptionsType } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';

export const subscriptionOptions = (
  contractType?: OrganizationContractType,
): SubscriptionOptionsType[] =>
  contractType === OrganizationContractType.Multiple
    ? [
        {
          name: AirAmbulanceSubscriptionOptions.AutoRenewal,
          label: t('fields.autoRenewSubscription.label'),
        },
      ]
    : [];
