import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { AvailableStatus } from '../../../models/enums/AvailableStatus';

import { GeneralPersonStatus } from '../../../models/enums/GeneralPersonStatus';
import { OrganizationSubscriptionStatus } from '../../../models/enums/OrganizationSubscriptionStatus';
import { RenewalStatus } from '../../../models/enums/RenewalStatus';
import { OrganizationContractStatus } from '../../../models/OrganizationContractStatus';
import { RenewStatus } from '../../../models/RenewStatus';
import { DotBefore } from '../Dot/Dot';
import { StatusType } from './status.models';
import { OrganizationStatus } from '../../../models/OrganizationStatus';
import { PromoCodeStatus } from '../../../models/enums/PromoCodeStatus';

type StatusTitleProps = {
  status: StatusType;
};

export const StatusTitle = styled(DotBefore)<StatusTitleProps>`
  font: normal 400 0.875rem/0.875rem ${({ theme }) => theme.font.gotham};
  text-transform: lowercase;
  margin-left: 1rem;
  white-space: nowrap;

  ${({ status, theme }) => {
    switch (status) {
      case GeneralPersonStatus.Active:
      case AvailableStatus.Active:
      case RenewStatus.Yes:
      case OrganizationContractStatus.Active:
      case OrganizationSubscriptionStatus.Active:
      case RenewalStatus.Renewed:
      case OrganizationStatus.Active:
      case PromoCodeStatus.Enabled:
        return css`
          color: ${theme.color.success};
          position: relative;
          &::before {
            background-color: ${theme.color.success};
          }
        `;
      case GeneralPersonStatus.Inactive:
      case AvailableStatus.Inactive:
      case RenewStatus.No:
      case OrganizationStatus.Inactive:
      case PromoCodeStatus.Disabled:
        return css`
          color: ${theme.color.error};
          position: relative;
          &::before {
            background-color: ${theme.color.error};
          }
        `;
      case GeneralPersonStatus.New:
      case AvailableStatus.New:
      case OrganizationContractStatus.New:
      case OrganizationSubscriptionStatus.New:
      case RenewalStatus.InProgress:
      case OrganizationStatus.New:
        return css`
          color: ${theme.color.lighter_blue};
          position: relative;
          &::before {
            background-color: ${theme.color.lighter_blue};
          }
        `;
      case OrganizationContractStatus.Draft:
      case OrganizationSubscriptionStatus.Draft:
        return css`
          color: ${theme.color.dark1};
          position: relative;
          &::before {
            background-color: ${theme.color.dark1};
          }
        `;
      case AvailableStatus.Expired:
      case OrganizationContractStatus.Expired:
      case OrganizationSubscriptionStatus.Expired:
      case RenewalStatus.Lost:
      case OrganizationStatus.Expired:
      case PromoCodeStatus.Expired:
        return css`
          color: ${theme.color.dark2};
          position: relative;
          &::before {
            background-color: ${theme.color.dark2};
          }
        `;
      case RenewalStatus.TBD:
        return css`
          color: ${theme.color.dark1};
          position: relative;
          &::before {
            background-color: ${theme.color.dark1};
          }
          text-transform: uppercase;
        `;
    }
  }}
`;
