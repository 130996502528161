import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { IPerson } from '../../../../models/Person';
import { personToMemberListItem } from '../../../../utils/personHelpers';
import { Dictionary } from '../../../../models/Dictionary';
import { IAAMembershipModel } from '../../../../models/interfaces/Membership/AirAmbulance/AAMembershipModel';

export interface IAAMembersState {
  summary?: IAddMembershipSummaryModel;
  newMembershipDates: IMembershipDates;
  members?: IPageListModel<IMemberListItem>;
  membershipDetails: Dictionary<IAAMembershipModel>;
}

const initialState: IAAMembersState = {
  summary: null,
  membershipDetails: {},
  newMembershipDates: null,
  members: null,
};

const aaMembershipSlice = createSlice({
  initialState,
  name: 'aaMembership',
  reducers: {
    setAAMembershipSummary: (
      state,
      { payload }: PayloadAction<IAddMembershipSummaryModel>,
    ) => {
      state.summary = payload;
    },
    setAAMembershipDates: (
      state,
      { payload }: PayloadAction<IMembershipDates>,
    ) => {
      state.newMembershipDates = {
        effectiveDate: parseDate(payload.effectiveDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setAAMembers: (
      state,
      { payload }: PayloadAction<IPageListModel<IMemberListItem>>,
    ) => {
      state.members = payload;
    },
    updateAAMembersList: (state, { payload }: PayloadAction<IPerson>) => {
      const currentPersonIndex = state.members?.items.findIndex(
        (member) => member.personId === payload.personId,
      );

      if (currentPersonIndex !== -1) {
        state.members.items[currentPersonIndex] = personToMemberListItem(
          payload,
          state.members.items[currentPersonIndex],
        );
      }
    },
    setAAMembershipDetails: (
      state,
      { payload }: PayloadAction<IAAMembershipModel>,
    ) => {
      state.membershipDetails[payload.id] = payload;
    },
    clearAAMembershipDates: (state) => {
      state.newMembershipDates = initialState.newMembershipDates;
    },
    clearAACreateMemberData: () => initialState,
    clearAAMembershipSummary: (state) => {
      state.summary = initialState.summary;
    },
    clearAAMembershipDetails: (state) => {
      state.membershipDetails = initialState.membershipDetails;
    },
    clearAAMembers: (state) => {
      state.members = initialState.members;
    },
  },
});

export const {
  setAAMembershipSummary,
  clearAACreateMemberData,
  clearAAMembershipSummary,
  clearAAMembershipDates,
  setAAMembershipDates,
  setAAMembers,
  updateAAMembersList,
  clearAAMembers,
  clearAAMembershipDetails,
  setAAMembershipDetails,
} = aaMembershipSlice.actions;
export default aaMembershipSlice.reducer;
export const membersReducerName = aaMembershipSlice.name;
