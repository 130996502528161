import styled from '@emotion/styled';

import Button from '../../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;
