import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDOCOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionExtensionSummaryModel';

import { IDOCOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { IDOCSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCSubscriptionRenewalSuggestionModel';
import { IEditDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/EditDOCOrganizationSubscriptionModel';

import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMemberModel } from '../../../../models/IRenewOrganizationSubscriptionMemberModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';

export interface IDOCSubscriptionsState {
  currentSubscriptionOverview: IDOCOrganizationSubscriptionOverview;
  subscriptionDates: ISubscriptionDates;
  currentSubscriptionEditing: IEditDOCOrganizationSubscriptionModel;
  subscriptionRenewalSuggestion: IDOCSubscriptionRenewalSuggestionModel;
  subscriptionRenewalAnalyse: IRenewOrganizationSubscriptionAnalyseModel;
  subscriptionExtensionSummary: IDOCOrganizationSubscriptionExtensionSummaryModel;
}

const initialState: IDOCSubscriptionsState = {
  currentSubscriptionOverview: null,
  subscriptionDates: null,
  currentSubscriptionEditing: null,
  subscriptionRenewalSuggestion: null,
  subscriptionRenewalAnalyse: {
    memberships: [],
    analysisResultForDate: null,
    totalMemberships: { membershipsCount: 0, withErrors: 0 },
  },
  subscriptionExtensionSummary: null,
};

const docSubscriptionsSlice = createSlice({
  name: 'docSubscriptions',
  initialState,
  reducers: {
    setDOCSubscriptionOverview: (
      state,
      { payload }: PayloadAction<IDOCOrganizationSubscriptionOverview>,
    ) => {
      const packages = payload.packageOptionsHistory.map((x) => ({
        ...x,
        editDate: parseDate(x.editDate),
      }));
      const subscription: IDOCOrganizationSubscriptionOverview = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        currentPackage: packages?.find(
          (packageOption) => packageOption.isCurrent,
        ),
        packageOptionsHistory: packages?.filter(
          (packageOption) => !packageOption.isCurrent,
        ),
      };
      state.currentSubscriptionOverview = subscription;
    },
    setDOCSubscriptionDates: (
      state,
      { payload }: PayloadAction<ISubscriptionDates>,
    ) => {
      state.subscriptionDates = payload;
    },
    setDOCCurrentEditingSubscription: (
      state,
      { payload }: PayloadAction<IEditDOCOrganizationSubscriptionModel>,
    ) => {
      state.currentSubscriptionEditing = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        overwrittenAmount: payload.overwrittenAmount?.toString(),
      };
    },
    setDOCSubscriptionRenewalSuggestion(
      state,
      { payload }: PayloadAction<IDOCSubscriptionRenewalSuggestionModel>,
    ) {
      state.subscriptionRenewalSuggestion = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
      };
    },
    setDOCSubscriptionRenewalAnalyse(
      state,
      { payload }: PayloadAction<IRenewOrganizationSubscriptionAnalyseModel>,
    ) {
      const mapMembers = (
        members: IRenewOrganizationSubscriptionMemberModel[],
      ) =>
        members.map((member, index) => ({
          ...member,
          dateOfBirth: parseDate(member.dateOfBirth),
        }));

      const subscriptionAnalyse = {
        analysisResultForDate: parseDate(payload.analysisResultForDate),
        memberships: payload.memberships.map((membership) => ({
          ...membership,
          primaryMember: {
            ...membership.primaryMember,
            dateOfBirth: parseDate(membership.primaryMember.dateOfBirth),
          },
          secondaryMembers: mapMembers(membership.secondaryMembers),
        })),
        totalMemberships: payload.memberships.reduce(
          (acc, { primaryMember, secondaryMembers }) => {
            const members = [primaryMember, ...secondaryMembers];
            acc.membershipsCount = acc.membershipsCount + 1;
            const hasErrors =
              members.filter(
                ({ canBeRenewed, toBeRenewed }) => !canBeRenewed && toBeRenewed,
              ).length > 0;
            acc.withErrors = acc.withErrors + (hasErrors ? 1 : 0);
            return acc;
          },
          { membershipsCount: 0, withErrors: 0 },
        ),
      };
      state.subscriptionRenewalAnalyse = subscriptionAnalyse;
    },
    setDOCSubscriptionExtensionSummary: (
      state,
      {
        payload,
      }: PayloadAction<IDOCOrganizationSubscriptionExtensionSummaryModel>,
    ) => {
      state.subscriptionExtensionSummary = payload;
    },
    clearDOCSubscriptionExtensionHelperData: (state) => {
      state.subscriptionExtensionSummary = null;
    },
    clearDOCSubscriptionRenewalAnalyse: (state) => {
      state.subscriptionRenewalAnalyse =
        initialState.subscriptionRenewalAnalyse;
    },
    clearDOCSubscriptionOverview: (state) => {
      state.currentSubscriptionOverview =
        initialState.currentSubscriptionOverview;
    },
    clearDOCSubscriptionHelperData: (state) => {
      state.subscriptionDates = null;
      state.subscriptionRenewalSuggestion = null;
    },
    clearDOCCurrentSubscriptionEditing: (state) => {
      state.currentSubscriptionEditing =
        initialState.currentSubscriptionEditing;
    },
  },
});

export const {
  clearDOCSubscriptionOverview,
  setDOCSubscriptionOverview,
  clearDOCSubscriptionHelperData,
  setDOCSubscriptionDates,
  setDOCCurrentEditingSubscription,
  clearDOCCurrentSubscriptionEditing,
  setDOCSubscriptionRenewalSuggestion,
  clearDOCSubscriptionRenewalAnalyse,
  setDOCSubscriptionRenewalAnalyse,
  clearDOCSubscriptionExtensionHelperData,
  setDOCSubscriptionExtensionSummary,
} = docSubscriptionsSlice.actions;
export default docSubscriptionsSlice.reducer;
export const subscriptionsReducerName = docSubscriptionsSlice.name;
