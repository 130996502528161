import { OrganizationType } from '../../../../models/enums/OrganizationType';
import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';
import { OrganizationStatus } from '../../../../models/OrganizationStatus';
import { SelectOption } from '../../../../styledComponents/UI/MainSelect/mainSelect.types';
import {
  getOptionsFromEnum,
  getOptionsFromEnumValues,
} from '../../../../utils/getOptionsFromEnum';
import { StyledStatus } from './corporateClientsGeneralFilters.styles';

export const productOptions = getOptionsFromEnumValues(
  Object.values(ProductTypeShortcut),
  'shortcut-to-product-type',
);

export const organizationTypeOptions = getOptionsFromEnum(
  OrganizationType,
  'organization-type',
);

export const organizationStatusOptions = getOptionsFromEnum(
  OrganizationStatus,
  'organization-status',
  [OrganizationStatus.Expired],
);

export const formatOptionLabel = ({ value }: SelectOption) => (
  <StyledStatus enumName="organization-status" status={value} />
);
