import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useActions } from '../../../../../../hooks/useActions';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../models/OrganizationSubscriptionPlanModel';
import {
  IWizardAvailableStep,
  updateAvailableSteps,
  WizardStepsCn,
} from '../../../../../../store/redux-slices/wizard';
import { SRMembershipOrganizationDetailsFormFields } from './SRMembershipDetailsForm/srMembershipDetailsForm.models';

export const useSecurityResponseMembership = () => {
  const changeAvailableStep = useActions(updateAvailableSteps);
  const {
    input: { value: selectedPlan },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    SRMembershipOrganizationDetailsFormFields.MembershipPlanId,
  );

  useEffect(() => {
    const steps: IWizardAvailableStep[] = [
      {
        canonicalName: WizardStepsCn.PaymentPage,
        isAvailable: false,
      },
    ];

    changeAvailableStep(steps);
  }, [changeAvailableStep]);

  return { selectedPlan };
};
