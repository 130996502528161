import { t } from 'i18next';

import { ProductType } from '../../../models/ProductType';

export const getSubscriptionTitle = (productType: ProductType, number = 0) =>
  `${t('phrase.product-subscription-item-title', {
    context: productType ? 'filled' : 'empty',
    count: number,
    productType,
  })}`;
