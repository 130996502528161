import { useCallback, useMemo } from 'react';

import { useActions } from '../../../../../hooks/useActions';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { RetailReportType } from '../../../../../models/enums/RetailReportType';
import { IGetRetailDataReport } from '../../../../../models/interfaces/GetRetailDataReport';
import {
  downloadAAAttritionReport,
  downloadAAEnrollmentReport,
  downloadAARetailStatisticsReport,
} from '../../../../../store/saga-slices/reports/airAmbulance';

import {
  IDownloadAttritionReportPayload,
  IDownloadEnrollmentReportPayload,
  IDownloadRetailStatisticsReportPayload,
} from '../../../../../store/saga-slices/reports/common/models';
import {
  RetailReportsPermissions,
  RetailReportTypeOptions,
} from './retailDataReportsForm.utils';

export const useRetailDataReports = () => {
  const cancellationToken = useCancellationToken();
  const { checkUserPermissions } = usePermissions();

  const DownloadEnrollmentReport = useActions(downloadAAEnrollmentReport);
  const DownloadAttritionReport = useActions(downloadAAAttritionReport);
  const DownloadRetailStatisticsReport = useActions(
    downloadAARetailStatisticsReport,
  );

  const DownloadEnrollmentReportHandler = useCallback(
    (date: string) => {
      const payload: IDownloadEnrollmentReportPayload = {
        data: {
          date,
        },
        cancellationToken,
      };

      DownloadEnrollmentReport(payload);
    },
    [DownloadEnrollmentReport, cancellationToken],
  );

  const DownloadAttritionReportHandler = useCallback(
    (date: string) => {
      const payload: IDownloadAttritionReportPayload = {
        data: {
          date,
        },
        cancellationToken,
      };

      DownloadAttritionReport(payload);
    },
    [DownloadAttritionReport, cancellationToken],
  );

  const DownloadRetailStatisticsReportHandler = useCallback(
    (date: string) => {
      const payload: IDownloadRetailStatisticsReportPayload = {
        data: {
          date,
        },
        cancellationToken,
      };

      DownloadRetailStatisticsReport(payload);
    },
    [cancellationToken, DownloadRetailStatisticsReport],
  );

  const generateRetailDataReportFile = useCallback(
    (values: IGetRetailDataReport) => {
      switch (values.reportType) {
        case RetailReportType.Enrollments:
          DownloadEnrollmentReportHandler(values.date);
          return;
        case RetailReportType.Attritions:
          DownloadAttritionReportHandler(values.date);
          return;
        case RetailReportType.RetailStatistics:
          DownloadRetailStatisticsReportHandler(values.date);
      }
    },
    [
      DownloadEnrollmentReportHandler,
      DownloadAttritionReportHandler,
      DownloadRetailStatisticsReportHandler,
    ],
  );

  const filteredRetailReportTypeOptions = useMemo(
    () =>
      RetailReportTypeOptions.filter((x) =>
        checkUserPermissions(RetailReportsPermissions[x.value]),
      ),
    [checkUserPermissions],
  );

  return {
    generateRetailDataReportFile,
    filteredRetailReportTypeOptions,
  };
};
