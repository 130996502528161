import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled/macro';
import TableCell from '@mui/material/TableCell';
import { TableRow, TableBody } from '@mui/material';

import { SubscriptionMember } from '../../subscriptionAnalyse.enums';

type Props = {
  canBeRenewed: boolean;
};

type StyledCellProps = {
  isOpen: boolean;
  rowType: SubscriptionMember;
};

type StyledRowProps = {
  isOpen: boolean;
};

export const EmptyStatusCell = styled.div`
  width: 1.25rem;
`;

export const EmptyCollapseCell = styled.div`
  width: 1rem;
`;

export const CollapsibleCell = styled(TableCell, {
  shouldForwardProp: isPropValid,
})<StyledRowProps>`
  padding: 0;
  border-bottom: ${({ isOpen }) => (isOpen ? '' : 'unset')};
`;

export const StyledTableCell = styled(TableCell)`
  letter-spacing: 0em;
  text-align: left;
  font: 400 normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
`;

export const NameTableCell = styled(TableCell)`
  letter-spacing: 0em;
  text-align: left;
  font: 700 normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
`;

export const DateTableCell = styled(TableCell, {
  shouldForwardProp: isPropValid,
})<Props>`
  letter-spacing: 0em;
  text-align: left;
  font: 400 normal 0.875rem/1rem ${({ theme }) => theme.font.gotham};

  color: ${({ theme, canBeRenewed }) =>
    canBeRenewed ? theme.color.dark1 : theme.color.error};
`;
export const StyledCell = styled(TableCell, {
  shouldForwardProp: isPropValid,
})<StyledCellProps>`
  border-bottom: ${({ isOpen, rowType }) =>
    isOpen && rowType === SubscriptionMember.SECONDARY_MEMBER ? 'unset' : ''};
`;

export const StyledRow = styled(TableRow, {
  shouldForwardProp: isPropValid,
})<StyledRowProps>`
  background: ${({ isOpen, theme }) =>
    isOpen ? theme.color.alabaster : theme.color.white};
  transition: all 0.25s ease;
`;

export const StyledTableBody = styled(TableBody)`
  transition: all 0.25s ease;
  tr {
    &:nth-last-of-type(1) {
      td {
        border-bottom: unset;
      }
    }
  }
`;
