import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { ITLPricingState } from './index';
import { TLPricingTierDefinition } from '../../../../models/interfaces/Subscription/TravelLicense/TLPricingDefinition';

export const tlPricingSelector = (state: RootState) => state.tlPricing;

export const tlPricingTiersSelector = createSelector(
  tlPricingSelector,
  (pricing: ITLPricingState): TLPricingTierDefinition[] => pricing.pricingTiers,
);
