import { useTranslation } from 'react-i18next';

import { CommissionType } from '../../../../models/enums/CommissionType';

import { IPromoCodeOverview } from '../../../../models/PromoCodeOverview';
import { ProductType } from '../../../../models/ProductType';
import {
  DetailsContainer,
  Detail,
  DetailLabel,
  DetailValue,
} from '../../../../styledComponents/Overview/OverviewDetails/OverviewDetails';
import Button from '../../../../styledComponents/UI/Button/Button';
import { formatDate } from '../../../../utils/dateHelpers';
import { usePromoCodeOverviewDetails } from './promoCodeOverviewDetails.hooks';
import {
  DetailBreak,
  DetailsWrapper,
  StyledLine,
} from './promoCodeOverviewDetails.styles';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil-icon.svg';
import { canEditAAPromoCodeDatesPermissions } from '../../../../config/accessPolicies/accessPolicies';

interface PromoCodeOverviewDetailsProps {
  data: IPromoCodeOverview;
  onEditDates?: () => void;
  onOwnerNameClick?: () => void;
}

const PromoCodeOverviewDetails = ({
  data,
  onEditDates,
  onOwnerNameClick,
}: PromoCodeOverviewDetailsProps) => {
  const { t } = useTranslation();
  const { currentCommissionValue } = usePromoCodeOverviewDetails({
    commissionPercentage: data?.commissionPercentage,
    commissionType: data?.commissionType,
    commissionValue: data?.commissionValue,
  });
  return (
    <>
      <DetailsContainer>
        <Detail>
          <DetailLabel>{`${t('properties.owner')}:`}</DetailLabel>
          <DetailValue onClick={onOwnerNameClick}>
            {data?.ownerName}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.product')}:`} </DetailLabel>
          <DetailValue>
            {t(`enums.productType.${ProductType.AirAmbulance}`)}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.type')}:`} </DetailLabel>
          <DetailValue>
            {t(`enums.promo-code-type.${data?.promoCodeType}`)}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.paidBy')}:`} </DetailLabel>
          <DetailValue>
            {t(`enums.paid-by-type.${data?.paidByType}`)}
          </DetailValue>
        </Detail>
        <DetailBreak />
        <Detail>
          <DetailLabel>{`${t('properties.used')}:`} </DetailLabel>
          <DetailValue>
            {t(`phrase.used-times`, { count: data?.usage })}
          </DetailValue>
        </Detail>
        {data?.commissionType !== CommissionType.None && (
          <>
            <DetailBreak />
            <Detail>
              <DetailLabel>{`${t('properties.commission')}:`} </DetailLabel>
              <DetailValue>{currentCommissionValue}</DetailValue>
            </Detail>
          </>
        )}
      </DetailsContainer>
      <StyledLine />
      <DetailsWrapper>
        <DetailsContainer>
          <Detail>
            <DetailLabel>{`${t('properties.createdOn')}:`}</DetailLabel>
            <DetailValue>{data && formatDate(data.createdAt)}</DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>{`${t('properties.validFrom')}:`} </DetailLabel>
            <DetailValue>
              {data?.validFrom ? formatDate(data.validFrom) : '-'}
            </DetailValue>
          </Detail>
          <Detail>
            <DetailLabel>{`${t('properties.validTo')}:`} </DetailLabel>
            <DetailValue>
              {data?.validTo ? formatDate(data.validTo) : '-'}
            </DetailValue>
          </Detail>
        </DetailsContainer>
        <Button
          permissions={canEditAAPromoCodeDatesPermissions}
          onClick={onEditDates}
          styleType="icon"
        >
          <PencilIcon />
        </Button>
      </DetailsWrapper>
    </>
  );
};

export default PromoCodeOverviewDetails;
