import { css, Theme } from '@emotion/react';

import { GlobalFonts } from './styledComponents/Typography/Fonts';

export const globalStyles = (theme: Theme) => css`
  ${GlobalFonts}

  body {
    margin: 0;
    font-family: ${theme.font.gotham};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 70rem;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  button {
    margin: 0;
    padding: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  legend {
    float: none;
    width: initial;
    padding: initial;
    margin-bottom: initial;
    font-size: initial;
    line-height: initial;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
`;
