import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormSelect } from 'styledComponents/UI/Form/FormSelect/FormSelect';
import FormDatePicker from 'styledComponents/UI/Form/FormDatePicker/FormDatePicker';

import { IOrganizationSearch } from '../../../../models/interfaces/SearchOrganization';
import {
  SectionTitle,
  SectionWrapper,
} from '../../../../styledComponents/Form/Form';
import { FormColumn } from '../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../styledComponents/UI/Form/FormRow/FormRow';
import { useAddPromoCodeDetails } from './addPromoCodeDetails.hooks';
import {
  organizationsPromiseOptions,
  promoCodeProductOptions,
  promoCodeTypeOptions,
} from './addPromoCodeDetails.utils';
import { FormInput } from '../../../../styledComponents/UI/Form/FormInput/FormInput';
import { FormSelectAsync } from '../../../../styledComponents/UI/Form/FormSelectAsync/FormSelectAsync';

interface IAddPromoCodeDetailsFormProps {
  isDisabled?: boolean;
}

const AddPromoCodeDetailsForm = ({
  isDisabled,
}: IAddPromoCodeDetailsFormProps) => {
  const { t } = useTranslation();
  const { ownerDisabled } = useAddPromoCodeDetails();
  return (
    <SectionWrapper>
      <SectionTitle>{t('headers.details')}</SectionTitle>
      <FormColumn>
        <FormRow>
          <FormRow isSubRow>
            <Field
              component={FormInput}
              disabled={isDisabled}
              name="value"
              label={t('fields.promoCodeName.label')}
              labelAnimation
              inputId="promo-code-name-input"
            />
            <Field
              component={FormSelect}
              disabled={isDisabled}
              options={promoCodeTypeOptions}
              name="type"
              inputId="promo-code-type-input"
            />
          </FormRow>
          <FormRow isSubRow>
            <Field
              component={FormSelectAsync}
              isClearable
              disabled={isDisabled || ownerDisabled}
              inputId="promo-code-owner-input"
              labelSelector={(value: IOrganizationSearch) => value.name}
              name="owner"
              promiseOptions={organizationsPromiseOptions}
            />
          </FormRow>
        </FormRow>
        <FormRow>
          <FormRow isSubRow>
            <Field
              component={FormSelect}
              disabled={isDisabled}
              options={promoCodeProductOptions}
              inputId="promo-code-product-input"
              name="product"
              setDefaultValue
            />
          </FormRow>
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={isDisabled}
              inputId="promo-code-valid-from-input"
              name="validFrom"
            />
            <Field
              component={FormDatePicker}
              disabled={isDisabled}
              inputId="promo-code-valid-to-input"
              name="validTo"
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default AddPromoCodeDetailsForm;
