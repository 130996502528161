import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../styledComponents/ConfirmModal/ConfirmModal';
import { useAddClientModal } from './addClientModal.hooks';
import { ADD_CLIENT_MODAL_NAME } from './addClientModal.consts';
import { StyledText } from './addClientModal.styles';

const AddClientModal = () => {
  const { t } = useTranslation();

  const { buttonsDefinition, corporationName, onModalClose } =
    useAddClientModal();

  return (
    <ConfirmModal
      buttons={buttonsDefinition}
      name={ADD_CLIENT_MODAL_NAME}
      title={t('headers.add-organization-modal')}
      onModalClose={onModalClose}
    >
      <StyledText>
        {corporationName} {t('phrase.add-client-was-added')}
      </StyledText>
    </ConfirmModal>
  );
};

export default AddClientModal;
