import { t } from 'i18next';

import { IOrganizationPointOfContact } from '../../../../models/OrganizationPointOfContact';
import { AdminCell, StyledTableCell } from './overviewTabPage.styles';
import { IGGPointOfContact } from '../../../../models/GGPointOfContact';
import { getTranslationsFromEnum } from '../../../../utils/getTranslationsFromEnum';
import { PointOfContactRoles } from '../../../../models/enums/PointOfContactRoles';
import { GlobalGuardianPointOfContactRoles } from '../../../../models/enums/GlobalGuardianPointOfContactRoles';
import { SingleItemWithNumberRenderer } from '../../../../styledComponents/MaterialTable/CellRenderers/SingleItemWithNumberRenderer/SingleItemWithNumberRenderer';

export const adminCellRenderer = (data: IOrganizationPointOfContact) =>
  data.isOrganizationAdministrator && (
    <StyledTableCell>
      <AdminCell>{`${t('phrase.admin')}`}</AdminCell>
    </StyledTableCell>
  );

export const pocRolesRenderer = (data: IOrganizationPointOfContact) =>
  data.roles && (
    <SingleItemWithNumberRenderer
      items={getTranslationsFromEnum(
        PointOfContactRoles,
        'point-of-contact-roles',
        data.roles,
      )}
    />
  );

export const ggPocRolesRenderer = (data: IGGPointOfContact) =>
  data.roles && (
    <SingleItemWithNumberRenderer
      items={getTranslationsFromEnum(
        GlobalGuardianPointOfContactRoles,
        'gg-point-of-contact-roles',
        data.roles,
      )}
    />
  );
