import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { ICreateSecondaryMemberModel } from '../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { FamilyMemberFields } from './familyMember.models';
import { familyMemberAddressSchema } from './familyMemberAddress.validation';
import { FamilyMemberBasicDetailsFields } from './FamilyMemberBasicDetails/familyMemberBasicDetails.models';
import { validateFamilyMemberBasicDetailsSchema } from './FamilyMemberBasicDetails/familyMemberBasicDetails.validation';

export const validateFamilyMemberFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...validateFamilyMemberBasicDetailsSchema.properties,
  },
  allOf: [
    {
      if: {
        properties: {
          [FamilyMemberFields.AddressSameAsPrimaryMemberResidenceAddress]: {
            enum: [false],
          },
          [FamilyMemberBasicDetailsFields.PersonId]: { enum: [false] },
        },
      },
      then: {
        properties: {
          [FamilyMemberFields.ResidenceAddress]: familyMemberAddressSchema,
        },
      },
    },
    {
      if: {
        properties: {
          [FamilyMemberBasicDetailsFields.PersonId]: { enum: [false] },
        },
      },
      then: {
        required: [...validateFamilyMemberBasicDetailsSchema.required],
      },
    },
  ],
  required: [FamilyMemberBasicDetailsFields.Dependence],
};

export const validateFamilyMemberForm = (
  data: ICreateSecondaryMemberModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, validateFamilyMemberFormSchema);
