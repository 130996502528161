import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { UPDATE_MEMBERSHIP_OPTIONS_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { EDIT_KE_MEMBERSHIP_OPTIONS_MODAL } from './editKEMembershipOptionsModal.const';
import { IUpdateKEMembershipOptionsPayload } from '../../../../../store/saga-slices/membership/kidnapAndExtortion/models';
import { IUpdateKEMembershipOptionsModel } from '../../../../../models/interfaces/Membership/KidnapAndExtortion/UpdateKEMembershipOptionsModel';
import { updateKEMembershipOptions } from '../../../../../store/saga-slices/membership/kidnapAndExtortion';
import { EditKEMembershipOptionsModalDetails } from './editKEMembershipOptionsModal.models';

type UseEditKEMembershipOptionsModalProps = {
  id?: string;
  onMembershipOptionsUpdated?: () => void;
};

export const useEditKEMembershipOptionsModal = ({
  id,
  onMembershipOptionsUpdated,
}: UseEditKEMembershipOptionsModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const UpdateMembershipOptions = useActions(updateKEMembershipOptions);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const isProcessing = useProcessing(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING);

  const {
    membershipOptions,
    membershipId,
  }: EditKEMembershipOptionsModalDetails = useModalDetails(
    EDIT_KE_MEMBERSHIP_OPTIONS_MODAL + id,
  );

  const updateMembershipOptionsHandler = useCallback(
    (values: IUpdateKEMembershipOptionsModel) => {
      const payload: IUpdateKEMembershipOptionsPayload = {
        membershipId,
        options: values,
        cancellationToken,
        success: () => {
          UpdateModalState(EDIT_KE_MEMBERSHIP_OPTIONS_MODAL + id);
          onMembershipOptionsUpdated?.();
          alert.success(t('alerts.membership-options-update-success'));
        },
        error: () => alert.error(t('alerts.membership-options-update-fail')),
      };

      UpdateMembershipOptions(payload);
    },
    [
      UpdateMembershipOptions,
      UpdateModalState,
      alert,
      cancellationToken,
      id,
      membershipId,
      onMembershipOptionsUpdated,
      t,
    ],
  );

  const onCloseHandler = () => {
    UpdateModalState(EDIT_KE_MEMBERSHIP_OPTIONS_MODAL + id);
  };
  return {
    updateMembershipOptionsHandler,
    membershipOptions,
    isProcessing,
    onCloseHandler,
  };
};
