import { useTranslation } from 'react-i18next';

import { IPersonMembershipItem } from '../../../../models/PersonMembershipItem';

import { formatDate } from '../../../../utils/dateHelpers';
import MembershipRelation from '../../../../styledComponents/MembershipRelation/MembershipRelation';

import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';
import {
  StyledOverviewProperty,
  SummaryWrapper,
  Wrapper,
} from '../../Common/MembershipSummary/membershipSummary.styles';
import Price from '../../../../styledComponents/UI/Price/Price';

interface IKEMembershipExpiredSummaryProps {
  data: IPersonMembershipItem;
}

const KEMembershipExpiredSummary = ({
  data,
}: IKEMembershipExpiredSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SummaryWrapper>
        <StyledOverviewProperty
          title={t('properties.product-subscription')}
          value={ProductTypeShortcut[data.productSubscription]}
          bold
          width="6rem"
        />
        <StyledOverviewProperty
          title={t('properties.membership-plan')}
          value={data.membershipPlan}
          width="12rem"
        />
        <StyledOverviewProperty
          title={t('properties.membership-relation')}
          width="12rem"
        >
          <MembershipRelation
            organizationName={data.organizationName}
            organizationId={data.organizationId}
            isRetail={data.isRetail}
          />
        </StyledOverviewProperty>
        <StyledOverviewProperty title={t('properties.price')} width="6rem">
          <Price price={data.displayPrice} />
        </StyledOverviewProperty>
        <StyledOverviewProperty title={t('properties.expiredOn')}>
          {formatDate(data.expirationDate)}
        </StyledOverviewProperty>
      </SummaryWrapper>
    </Wrapper>
  );
};

export default KEMembershipExpiredSummary;
