import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFormErrorsActions } from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../hooks/useActions';
import { useApiResponseFail } from '../../../../hooks/useApiResponseFail';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { IWarningRatification } from '../../../../models/interfaces/WarningRatification';
import { organizationContractsSelector } from '../../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { organizationNameSelector } from '../../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_CONTRACTS_LOADING_PROCESSING,
  ORGANIZATION_LOADING_PROCESSING,
  SR_PRICING_LOADING_PROCESSING,
  SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { IUpdateAASubscriptionPayload } from '../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { IValidationErrorResponse } from '../../../../models/ValidationErrorResponse';
import { EditProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { BasicSubscriptionFormFields } from '../../../../styledComponents/SubscriptionFormPage/FormSections/BasicSubscriptionForm/basicSubscriptionForm.models';
import { validateEditProductSubscriptionForm } from '../../Common/editProductSubscriptionForm.validation';
import { ADD_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/AddContractModal/addContractModal.const';
import { AddContractModalFormDetails } from '../../../../styledComponents/Modals/AddContractModal/AddContractModalForm/addContractModalForm.models';
import { updateProductSRSubscription } from '../../../../store/saga-slices/subscriptions/securityResponse';
import { editingSRSubscriptionDataSelector } from '../../../../store/redux-slices/subscriptions/securityResponse/selectors';
import { IEditSROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/EditSROrganizationSubscriptionModel';

export const useEditSRSubscriptionForm = () => {
  const navigate = useNavigateSearch();
  const alert = useAlert();

  const { organizationId, subscriptionId, subscriptionType } =
    useParams<EditProductSubscriptionPageParamsType>();
  const { t } = useTranslation();

  const UpdateProductSubscription = useActions(updateProductSRSubscription);
  const UpdateModalState = useActions(updateModalState);

  const organizationName = useSelector(organizationNameSelector);
  const organizationContracts = useSelector(organizationContractsSelector);
  const subscriptionData = useSelector(editingSRSubscriptionDataSelector);

  const subscriptionEditing = useProcessing(SUBSCRIPTION_EDITING_PROCESSING);

  const dataLoading = useProcessing([
    ORGANIZATION_CONTRACTS_LOADING_PROCESSING,
    SR_PRICING_LOADING_PROCESSING,
    SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING,
    ORGANIZATION_LOADING_PROCESSING,
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  ]);

  const { validateErrors } = useFormErrorsActions();

  const { handleResponse } = useApiResponseFail();

  const handleSubmit = (values: IEditSROrganizationSubscriptionModel) => {
    updateOrganizationSubscription(values);
  };

  const updateOrganizationSubscription = useCallback(
    (
      values: IEditSROrganizationSubscriptionModel,
      ratifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: IEditSROrganizationSubscriptionModel = {
        ...values,
        ratifications,
      };
      const createNewSubscriptionPayload: IUpdateAASubscriptionPayload = {
        organizationId,
        subscriptionId,
        data: extendedValues,
        error: (err: IValidationErrorResponse) => {
          handleResponse({
            err,
            values,
            ratifications,
            alertErrorMessage: t(
              `alerts.${
                values.isDraft ? 'draft-' : ''
              }subscription-update-fail`,
              {
                value: organizationName,
              },
            ),
          });
        },
        success: () => {
          navigate(
            RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
            {
              params: {
                [ProductSubscriptionOverviewPageParams.OrganizationId]:
                  organizationId,
                [ProductSubscriptionOverviewPageParams.SubscriptionId]:
                  subscriptionId,
                [ProductSubscriptionOverviewPageParams.SubscriptionType]:
                  subscriptionType,
              },
            },
          );
          alert.success(
            t(
              `alerts.${
                values.isDraft ? 'draft-' : ''
              }subscription-update-success`,
              {
                value: organizationName,
              },
            ),
          );
        },
      };
      UpdateProductSubscription(createNewSubscriptionPayload);
    },
    [
      UpdateProductSubscription,
      alert,
      handleResponse,
      navigate,
      organizationId,
      organizationName,
      subscriptionId,
      subscriptionType,
      t,
    ],
  );

  const validateForm = (data: IEditSROrganizationSubscriptionModel) =>
    validateEditProductSubscriptionForm(data, validateErrors(data));

  const initData = {
    [BasicSubscriptionFormFields.ProductType]: subscriptionType,
    ...subscriptionData,
  };

  const onAddNewContractHandler = () => {
    const details: AddContractModalFormDetails = {
      organizationId,
    };
    UpdateModalState([ADD_CONTRACT_MODAL_NAME, details]);
  };

  return {
    validateForm,
    handleSubmit,
    UpdateModalState,
    organizationContracts,
    subscriptionEditing,
    dataLoading,
    updateOrganizationSubscription,
    initData,
    onAddNewContractHandler,
  };
};
