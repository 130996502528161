export enum PrimaryMemberDetailsFormFields {
  PersonId = 'personId',
  EmailAddress = 'emailAddress',
  DateOfBirth = 'dateOfBirth',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  PersonPrefix = 'personPrefix',
  Gender = 'gender',
}
