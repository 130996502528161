import { ToolsWrapper } from '../../SimplePage/Tools';
import PromoCodesFilters from '../PromoCodesFilters/PromoCodesFilters';

const PromoCodesTools = () => (
  <ToolsWrapper>
    <PromoCodesFilters />
  </ToolsWrapper>
);

export default PromoCodesTools;
