import { useParams } from 'react-router-dom';

import { useNavigateSearch } from '../../../../../../hooks/useNavigateSearch';
import {
  ProductSubscriptionRenewPageParams,
  RoutePath,
} from '../../../../../../models/enums/RoutePath';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../../../models/types/RoutePath';

export const useAASubscriptionOverviewInformation = () => {
  const { id, subscriptionId, subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();

  const navigate = useNavigateSearch();

  const renewClickHandler = () => {
    navigate(RoutePath.ClientSubscriptionRenewPage, {
      params: {
        [ProductSubscriptionRenewPageParams.OrganizationId]: id,
        [ProductSubscriptionRenewPageParams.SubscriptionId]: subscriptionId,
        [ProductSubscriptionRenewPageParams.ProductType]: subscriptionType,
      },
    });
  };

  return {
    renewClickHandler,
  };
};
