import { useCallback, MouseEvent } from 'react';

import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import {
  PromoCodeOverviewPageParams,
  RoutePath,
} from '../../../models/enums/RoutePath';

export const usePromoCodeValue = (promoCodeId?: string) => {
  const navigate = useNavigateSearch();

  const onClickHandler = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      navigate(RoutePath.PromoCodeOverview, {
        params: {
          [PromoCodeOverviewPageParams.PromoCodeId]: promoCodeId,
        },
      });
    },
    [navigate, promoCodeId],
  );

  return { onClickHandler };
};
