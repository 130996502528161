import { CanViewOrganizationContractsPermissions } from '../../../../config/accessPolicies/accessPolicies';
import AddContractModal from '../../../../styledComponents/Modals/AddContractModal/AddContractModal';
import DeleteContractModal from '../../../../styledComponents/Modals/DeleteContractModal/DeleteContractModal';
import EditContractDetailsModal from '../../../../styledComponents/Modals/EditContractDetailsModal/EditContractDetailsModal';
import { OverviewTabPage } from '../../../../styledComponents/OverviewPage/OverviewPage';
import ContractsHeader from './ContractsHeader/ContractsHeader';
import ContractNotFound from './ContractsNotFound/ContractsNotFound';
import ContractsTable from './ContractsTable/ContractsTable';
import { useContractsTabPage } from './contractsTabPage.hooks';

const ContractsTabPage = () => {
  const {
    contractsIsLoading,
    contractsList,
    addContractHandler,
    rowClickHandler,
    deleteContractHandler,
    editContractDetailsHandler,
    loadOrganizationContractsListHandler,
  } = useContractsTabPage();

  let content = (
    <OverviewTabPage>
      <ContractsHeader onAddContract={addContractHandler} />
      <ContractsTable
        onRowClick={rowClickHandler}
        onRowDelete={deleteContractHandler}
        onRowEdit={editContractDetailsHandler}
        data={contractsList}
        isLoading={contractsIsLoading}
        canViewDetailsPermissions={CanViewOrganizationContractsPermissions}
      />
    </OverviewTabPage>
  );

  if (contractsList && contractsList.length === 0 && !contractsIsLoading) {
    content = <ContractNotFound onAddContract={addContractHandler} />;
  }

  return (
    <>
      <AddContractModal
        onContractAdded={loadOrganizationContractsListHandler}
      />
      <DeleteContractModal
        onContractDeleted={loadOrganizationContractsListHandler}
      />
      <EditContractDetailsModal
        onContractEdited={loadOrganizationContractsListHandler}
      />
      {content}
    </>
  );
};

export default ContractsTabPage;
