import EditRenewalSubscriptionStatusModal from '../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import RenewalsSubscriptionsFilters from './RenewalsSubscriptionsFilters/RenewalsSubscriptionsFilters';
import { useRenewalsSubscriptionsPage } from './renewalsSubscriptionsPage.hooks';
import RenewalsSubscriptionsTables from './RenewalsSubscriptionsTables/RenewalsSubscriptionsTables';

const RenewalsSubscriptionsPage = () => {
  const {
    data,
    dataLoading,
    filterChangedHandler,
    onRowClickHandler,
    onCellClickHandler,
    exportRenewalSubscriptionsHandler,
    dataExporting,
  } = useRenewalsSubscriptionsPage();
  return (
    <>
      <EditRenewalSubscriptionStatusModal />
      <div>
        <RenewalsSubscriptionsFilters
          onExportClick={exportRenewalSubscriptionsHandler}
          isExporting={dataExporting}
          onFilterChanged={filterChangedHandler}
        />
        <Spinner size="10rem" isVisible={dataLoading}>
          <RenewalsSubscriptionsTables
            data={data}
            onRowClick={onRowClickHandler}
            onCellClick={onCellClickHandler}
          />
        </Spinner>
      </div>
    </>
  );
};

export default RenewalsSubscriptionsPage;
