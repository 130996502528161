import { AnySchema } from 'ajv';

import { ICreatePrimaryMemberModel } from '../../../models/CreatePrimaryMemberModel';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { addressSchema } from '../FormAddress/formAddress.validation';
import { MemberAddressInformationsFields } from './MemberAddressInformationForm.models';

export const memberAddressInformationSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [MemberAddressInformationsFields.ResidenceAddress]: addressSchema,
  },
  if: {
    properties: {
      [MemberAddressInformationsFields.CorrespondenceAddressSameAsResidenceAddress]:
        { enum: [false] },
    },
  },
  then: {
    properties: {
      [MemberAddressInformationsFields.CorrespondenceAddress]: addressSchema,
    },
  },
  required: [],
};

export const validateMemberAddressInformationForm = (
  data: ICreatePrimaryMemberModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, memberAddressInformationSchema);
