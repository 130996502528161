import AddMemberMembershipForm from './AddMemberMembershipForm/AddMemberMembershipForm';
import FormErrorProvider from '../../../../styledComponents/FormErrorProvider/FormErrorProvider';
import {
  MEMBERSHIP_ALREADY_RENEWED_WARNING_CODE,
  REPAYMENT_NOT_POSSIBLE_MODAL,
} from '../Modals/RepaymentNotPaymentModal/repaymentNotPossibleModal.const';
import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';

const AddMemberMembershipPage = () => (
  <FormErrorProvider>
    <WarningsConfirmationProvider
      customWarningsModals={[
        {
          errorCode: MEMBERSHIP_ALREADY_RENEWED_WARNING_CODE,
          modalName: REPAYMENT_NOT_POSSIBLE_MODAL,
        },
      ]}
    >
      <AddMemberMembershipForm />
    </WarningsConfirmationProvider>
  </FormErrorProvider>
);

export default AddMemberMembershipPage;
