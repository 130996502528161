export enum NumericInputControlsChar {
  Decrement = 'decrement',
  Increment = 'increment',
}

export const NumericInputControlsChars: Record<
  NumericInputControlsChar,
  string
> = {
  [NumericInputControlsChar.Decrement]: '-',
  [NumericInputControlsChar.Increment]: '+',
};
