import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { Alignment } from '../../../models/Alignment';

import { CustomField } from '../../../models/types/CustomField';
import BaseInput from '../BaseInput/BaseInput';
import { useBaseNumberInput } from './baseNumberInput.hooks';
export interface IBaseNumberInputProps extends NumberFormatProps, CustomField {
  onChangeValue?: (value: string) => void;
  alignment?: Alignment;
}

const BaseNumberInput = ({
  min,
  max,
  onChangeValue,
  value,
  defaultValue,
  onBlur,
  ...props
}: IBaseNumberInputProps) => {
  const { isAllowed, onChangeHandler, currentValue, onBlurHandler } =
    useBaseNumberInput({
      min,
      max,
      onChangeValue,
      value,
      defaultValue,
      onBlur,
    });
  return (
    <NumberFormat
      customInput={BaseInput}
      isNumericString
      {...props}
      onBlur={onBlurHandler as any}
      defaultValue={100}
      value={currentValue}
      onValueChange={onChangeHandler}
      isAllowed={isAllowed}
    />
  );
};

export default BaseNumberInput;
