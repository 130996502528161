import { useTranslation } from 'react-i18next';

export interface IEnumProps {
  value?: string;
  enumName?: string;
}

export const Enum = ({ value, enumName = 'status' }: IEnumProps) => {
  const { t } = useTranslation();

  return <>{t(`enums.${enumName}.${value}`)}</>;
};
