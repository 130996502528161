import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import NotesForm from '../../../../../../../styledComponents/Forms/NotesForm/NotesForm';
import ModalFormPage from '../../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useEditContractNotes } from './editContractNotesModalForm.hooks';

interface IEditContractModalFormProps {
  onNotesEdited?: () => void;
}

const EditContractModalForm = ({
  onNotesEdited,
}: IEditContractModalFormProps) => {
  const { t } = useTranslation();
  const { closeModalHandler, handleSubmit, isProcessing, notes, validateData } =
    useEditContractNotes({ onNotesEdited });
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={{ notes }}
      onSubmit={handleSubmit}
      validate={validateData}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          title={t('headers.edit-notes')}
          onCancel={closeModalHandler}
          isProcessing={isProcessing}
        >
          <NotesForm titleHidden disabled={isProcessing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default EditContractModalForm;
