import styled from '@emotion/styled';

import { StyledForm } from '../../../../../styledComponents/Form/Form';

export const StyledReportsForm = styled(StyledForm)`
  display: grid;
  grid-template-columns: 18.5rem 9.4375rem;
  grid-column-gap: 2rem;
  padding: 0 2.5rem 2rem 0;
`;
