import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import { Wrapper } from './subscriptionsSummaryContent.styles';
import SubscriptionsSummaryItem from './SubscriptionsSummaryItem/SubscriptionsSummaryItem';

interface ISubscriptionSummaryContentProps extends CustomComponent {
  data: ISubscriptionSummaryModel[];
  className?: string;
  fields: any;
  onRemove?: (index: number) => void;
}

const SubscriptionsSummaryContent = ({
  data,
  className,
  fields,
  onRemove,
  disabled,
}: ISubscriptionSummaryContentProps) => (
  <Wrapper className={className}>
    <div>
      {fields.map((option: string, index: number) => (
        <SubscriptionsSummaryItem
          data={data}
          index={index}
          name={option}
          key={option}
          onRemove={onRemove}
          disabled={disabled}
        />
      ))}
    </div>
  </Wrapper>
);

export default SubscriptionsSummaryContent;
