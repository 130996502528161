/* eslint-disable react/no-array-index-key */
import { t } from 'i18next';
import { Field, useField } from 'react-final-form';

import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { FormSelect } from '../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { FilterDefinition } from '../../../renewalFilters.models';
import { FilterInputContainer, FilterRow } from '../../filtersForm.styles';

import { DateFilterFields } from './dateFilter.models';
import { dateTypeFieldsOptions } from './dateFilter.utils';
import DateFilterTypes from './DateFilterTypes/DateFilterTypes';

type DateFilterProp = CustomComponent & {
  filterDefinition: FilterDefinition<void>;
};

const DateFilter = ({ filterDefinition }: DateFilterProp) => {
  const {
    input: { value },
  } = useField(DateFilterFields.Value);
  const {
    input: { onChange },
  } = useField(DateFilterFields.AdditionalValues);
  return (
    <FilterRow>
      <FilterInputContainer>
        <Field
          component={FormSelect}
          options={dateTypeFieldsOptions}
          label={t(`filters.${filterDefinition.field}`)}
          name={DateFilterFields.Value}
          onItemChanged={() => {
            onChange({});
          }}
        />
      </FilterInputContainer>
      <DateFilterTypes
        filterDefinition={filterDefinition}
        name={DateFilterFields.AdditionalValues}
        type={value}
      />
    </FilterRow>
  );
};

export default DateFilter;
