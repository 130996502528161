import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
type WrapperType = {
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  min-width: 15rem;
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.color.secondary2};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  padding: 0.2rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.color.dark2};
    `}
`;
