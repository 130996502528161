import { ICallbackSaga } from '../../../../models/ICallbackSaga';
import { ICancellationTokenSaga } from '../../../../models/interfaces/CancellationTokenSaga';
import { IGetRetailReport } from '../../../../models/interfaces/GetEnrollmentReport';
import { IGetFinanceReport } from '../../../../models/interfaces/GetFinanceReport';
import { ProductType } from '../../../../models/ProductType';

export interface IGetRawDataReportPayload extends ICallbackSaga {
  productType?: ProductType;
}

export interface IGetFinanceReportsPayload extends ICallbackSaga {
  data: IGetFinanceReport;
}

export interface IDownloadEnrollmentReportPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  data: IGetRetailReport;
}

export interface IDownloadAttritionReportPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  data: IGetRetailReport;
}
export interface IDownloadRetailStatisticsReportPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  data: IGetRetailReport;
}

export enum ReportsFilenamePrefixEnum {
  RawDataReport = 'RawDataReport_',
  SubscriptionFinanceReport = 'SubscriptionFinance_',
  RetailFinanceReport = 'RetailFinance_',
  EnrollmentReport = 'Enrollment_',
  AttritionReport = 'Attrition_',
  RetailStatistics = 'RetailStatistics_',
}
