import MembershipPlanForm, {
  IMemberShipPlanFormProps,
} from '../../../../UI/Form/MembershipPlanForm/membershipPlanForm';
const SRSubscriptionCreateMembershipPlanForm = ({
  disabled,
  subscriptionRenewalSuggestionPlans,
  ...props
}: IMemberShipPlanFormProps) => (
  <MembershipPlanForm
    {...props}
    disabled={disabled}
    additionalData={subscriptionRenewalSuggestionPlans}
  />
);

export default SRSubscriptionCreateMembershipPlanForm;
