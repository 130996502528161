import SummaryContainer from '../../SummaryContainer/SummaryContainer';
import SubscriptionsSummaryContent from './SubscriptionsSummaryContent/SubscriptionsSummaryContent';
import { useSubscriptionsSummary } from './subscriptionsSummary.hooks';
import { SubscriptionsOverwriteAmount } from './subscriptionsSummary.models';

interface ISubscriptionSummaryFormProps {
  disabled?: boolean;
  title?: string;
  subscriptionsOverwrittenAmount?: SubscriptionsOverwriteAmount[];
}

const SubscriptionSummaryForm = ({
  disabled,
  title,
  subscriptionsOverwrittenAmount,
}: ISubscriptionSummaryFormProps) => {
  const {
    totalPrice,
    onRemoveHandler,
    summaryIsLoading,
    summaryData,
    fields,
    isPriceOverridden,
    overwrittenTotalPrice,
  } = useSubscriptionsSummary(subscriptionsOverwrittenAmount);

  return (
    <SummaryContainer
      content={() =>
        summaryData && (
          <SubscriptionsSummaryContent
            fields={fields}
            data={summaryData}
            disabled={disabled}
            onRemove={onRemoveHandler}
          />
        )
      }
      data={{ totalAmount: totalPrice }}
      disabled={disabled}
      isLoading={summaryIsLoading}
      title={title}
      overwriteAmount={isPriceOverridden}
      overwrittenPrice={overwrittenTotalPrice}
      canOverwriteAmount={false}
    />
  );
};

export default SubscriptionSummaryForm;
