import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import {
  DefaultExpandContentId,
  NotExpandedHeight,
} from './expandContent.const';

type ContentType = {
  isExpanded?: boolean;
};

export const ExpandContentWrapper = styled.div<ContentType>`
  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      overflow: hidden;
      max-height: ${NotExpandedHeight}px;
    `}
`;

ExpandContentWrapper.defaultProps = { id: DefaultExpandContentId };
