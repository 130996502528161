import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { useSubscriptionRenew } from './aASubscriptionRenewPage.hooks';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import AASubscriptionRenewPageForm from './AASubscriptionRenewPageForm/AASubscriptionRenewPageForm';
import { ProductType } from '../../../models/ProductType';
import AASubscriptionAnalyseModal from './AASubscriptionAnalyseModal/AASubscriptionAnalyseModal';

export const AASubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    isOrganizationDataLoading,
    isPricingDataLoading,
    isSuggestionDataLoading,
    loadSubscriptionRenewalSuggestionHandler,
    subscriptionRenewalSuggestion,
    loadDatesForNewSubscriptionHandler,
  } = useSubscriptionRenew();

  if (
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading) ||
    isPricingDataLoading
  ) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <AASubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.renew-subscription', {
              productType: ProductType.AirAmbulance,
              organizationName: organization?.name,
            })}
          />
          <AASubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
