import { ISubscriptionSummaryModel } from '../../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import SummaryItem from '../../../../SummaryContainer/SummaryItem/SummaryItem';
import { useSubscriptionsSummaryItem } from './subscriptionsSummaryItem.hooks';

type SubscriptionsSummaryItemProps = CustomComponent & {
  name: string;
  index: number;
  data: ISubscriptionSummaryModel[];
  onRemove?: (index: number) => void;
};

const SubscriptionsSummaryItem = ({
  data,
  index,
  name,
  className,
  disabled,
  onRemove,
}: SubscriptionsSummaryItemProps) => {
  const { currentData, isPriceOverridden, subTotalValue, subscriptionValues } =
    useSubscriptionsSummaryItem({ name, data, index });
  if (!subscriptionValues?.productType) {
    return null;
  }
  return (
    <SummaryItem
      data={currentData}
      name={name}
      className={className}
      disabled={disabled}
      index={index}
      isPriceOverridden={isPriceOverridden}
      onRemove={onRemove}
      subscriptionType={subscriptionValues?.productType}
      subtotalValue={subTotalValue}
    />
  );
};

export default SubscriptionsSummaryItem;
