import { useMemo } from 'react';

import { IWizardStep } from '../../../store/redux-slices/wizard';
import { StepperList, StepperWrapper } from './wizardStepper.styles';
import WizardStep from './WizardStep/WizardStep';
import { useWizardStepper } from './wizardStepper.hooks';
import { CustomComponent } from '../../../models/types/CustomComponent';

const WizardStepper = ({ className, disabled }: CustomComponent) => {
  const {
    activeStepCn,
    checkIfStepShouldDisabled,
    stepsDefinitions,
    ChangeStepByCn,
  } = useWizardStepper();

  const steps = useMemo(
    () =>
      stepsDefinitions.map((stepDef: IWizardStep, index) => (
        <WizardStep
          key={stepDef.canonicalName}
          step={stepDef}
          isLineVisible={index > 0}
          isActive={stepDef.canonicalName === activeStepCn}
          isDisabled={checkIfStepShouldDisabled(stepDef, index) || disabled}
          isAvailable={stepDef.isAvailable}
          onClick={ChangeStepByCn}
        />
      )),
    [
      stepsDefinitions,
      activeStepCn,
      checkIfStepShouldDisabled,
      disabled,
      ChangeStepByCn,
    ],
  );

  return (
    <StepperWrapper className={className}>
      <StepperList>{steps}</StepperList>
    </StepperWrapper>
  );
};

export default WizardStepper;
