import { useTranslation } from 'react-i18next';

import { getFormGroupPath } from '../../../../utils/getFormGroupPath';

import DOCSubscriptionPackageOptionsForm from '../../../Forms/DutyOfCare/DOCSubscriptionPackageOptionsForm/DOCSubscriptionPricingForm';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { SubscriptionItemBaseProps } from '../../SubscriptionItemForm/subscriptionItemForm.models';
import { useDutyOfCareSubscriptionForm } from './dutyOfCareSubscriptionForm.hooks';
import { DOCSubscriptionFormFields } from './dutyOfCareSubscriptionForm.models';
import { Wrapper } from './dutyOfCareSubscriptionForm.styles';
import { docSubscriptionOptions } from './dutyOfCareSubscriptionForm.utils';

const DutyOfCareSubscriptionForm = ({
  name,
  disabled,
  className,
  contractType,
}: SubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  useDutyOfCareSubscriptionForm({ name, contractType });
  const options = docSubscriptionOptions(contractType);
  return (
    <Wrapper className={className}>
      <DOCSubscriptionPackageOptionsForm
        title={t('headers.pricing-and-current-capacity')}
        {...getFormGroupPath}
        name={getFormGroupPath(name, DOCSubscriptionFormFields.PackageOptions)}
        disabled={disabled}
      />
      {options?.length !== 0 && (
        <SubscriptionOptions
          disabled={disabled}
          name={name}
          title={t('headers.options')}
          subscriptionOptions={options}
          layout="column"
        />
      )}
    </Wrapper>
  );
};

export default DutyOfCareSubscriptionForm;
