import styled from '@emotion/styled/macro';

import { StyledTableRow } from '../TableContent/tableContent.styles';

export const StyledSummaryTableRow = styled(StyledTableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;
