import FormErrorProvider from '../../../../styledComponents/FormErrorProvider/FormErrorProvider';
import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import AddMemberPrimaryMemberForm from './AddMemberPrimaryMemberForm/AddMemberPrimaryMemberForm';

const AddMemberPrimaryMemberPage = () => (
  <WarningsConfirmationProvider>
    <FormErrorProvider>
      <AddMemberPrimaryMemberForm />
    </FormErrorProvider>
  </WarningsConfirmationProvider>
);

export default AddMemberPrimaryMemberPage;
