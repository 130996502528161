import { useTranslation } from 'react-i18next';

import { StyledReportsSubtitle } from '../../ReportsPage.styles';
import RetailDataReportsForm from './RetailDataReportsForm/RetailDataReportsForm';

const RetailDataTabPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledReportsSubtitle>{t('headers.retail-data')}</StyledReportsSubtitle>
      <RetailDataReportsForm />
    </div>
  );
};

export default RetailDataTabPage;
