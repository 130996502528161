import { Route, Routes } from 'react-router';

import AddProductSubscriptionPage from './pages/AddProductSubscriptionPage/AddProductSubscriptionPage';
import SubscriptionRenewPage from './pages/SubscriptionRenewPage/SubscriptionRenewPage';

import { RoutePath } from './models/enums/RoutePath';
import CallbackPage from './pages/CallbackPage/CallbackPage';
import HomePage from './pages/HomePage/HomePage';
import AddClientPage from './pages/AddClientPage/AddClientPage';
import CorporateClientsPage from './pages/CorporateClientsPage/CorporateClientPage';
import Redirect from './styledComponents/Shared/Redirect';
import ClientOverviewPage from './pages/ClientOverviewPage/ClientOverviewPage';
import ExtendProductSubscriptionPage from './pages/ExtendProductSubscriptionPage/ExtendProductSubscriptionPage';
import AddMemberPage from './pages/AddMemberPage/AddMemberPage';
import MemberOverviewPage from './pages/MemberOverviewPage/MemberOverviewPage';
import MembersPage from './pages/MembersPage/MembersPage';
import PromoCodesPage from './pages/PromoCodesPage/PromoCodesPage';
import PromoCodeOverviewPage from './pages/PromoCodeOverviewPage/PromoCodeOverviewPage';
import AddPromoCodePage from './pages/AddPromoCodePage/AddPromoCodePage';
import ReportsPage from './pages/ReportsPage/ReportsPage';
import LogoutCallbackPage from './pages/LogoutCallbackPage/LogoutCallbackPage';
import AuthRoute from './styledComponents/AuthRoute/AuthRoute';
import {
  canAddOrganizationPermissions,
  canViewReportsPermissions,
  canViewMembersPagePermissions,
  canViewOrganizationsPermissions,
  canViewAAPromoCodesPermissions,
  canViewOrganizationDetailsPagePermissions,
  canViewMemberDetailsPermissions,
  canViewAAPromoCodeDetailsPermissions,
  canAddAAPromoCodePermissions,
  canExtendsSubscriptionPermissions,
  canAddSubscriptionsPermissions,
  canEditSubscriptionPermissions,
  canRenewSubscriptionPermissions,
  canAddMembershipPermissions,
  CanViewRenewalsPagePermissions,
} from './config/accessPolicies/accessPolicies';
import EditProductSubscriptionPage from './pages/EditProductSubscriptionPage/EditProductSubscriptionPage';
import EditSubscriptionRenewPage from './pages/EditSubscriptionRenewPage/EditSubscriptionRenewPage';
import RenewalsPage from './pages/RenewalsPage/RenewalsPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

const AppRoutes = () => (
  <Routes>
    <Route path={RoutePath.Home} element={<HomePage />} />
    <Route path={RoutePath.Callback} element={<CallbackPage />} />
    <Route path={RoutePath.LogoutCallback} element={<LogoutCallbackPage />} />

    <Route
      path={`${RoutePath.AddMember}/*`}
      element={
        <AuthRoute permissions={canAddMembershipPermissions}>
          <AddMemberPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.Reports}/*`}
      element={
        <AuthRoute permissions={canViewReportsPermissions}>
          <ReportsPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.CorporateClientsPage}/*`}
      element={
        <AuthRoute permissions={canViewOrganizationsPermissions}>
          <CorporateClientsPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.AddClient}
      element={
        <AuthRoute permissions={canAddOrganizationPermissions}>
          <AddClientPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.AddProductSubscription}
      element={
        <AuthRoute permissions={canAddSubscriptionsPermissions}>
          <AddProductSubscriptionPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.EditProductSubscription}
      element={
        <AuthRoute permissions={canEditSubscriptionPermissions}>
          <EditProductSubscriptionPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ExtendProductSubscription}
      element={
        <AuthRoute permissions={canExtendsSubscriptionPermissions}>
          <ExtendProductSubscriptionPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.ClientOverview}/*`}
      element={
        <AuthRoute permissions={canViewOrganizationDetailsPagePermissions}>
          <ClientOverviewPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.MemberOverviewPage}
      element={
        <AuthRoute permissions={canViewMemberDetailsPermissions}>
          <MemberOverviewPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.MembersPage}/*`}
      element={
        <AuthRoute permissions={canViewMembersPagePermissions}>
          <MembersPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.PromoCodeOverview}
      element={
        <AuthRoute permissions={canViewAAPromoCodeDetailsPermissions}>
          <PromoCodeOverviewPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.PromoCodes}
      element={
        <AuthRoute permissions={canViewAAPromoCodesPermissions}>
          <PromoCodesPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.AddPromoCode}
      element={
        <AuthRoute permissions={canAddAAPromoCodePermissions}>
          <AddPromoCodePage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ClientSubscriptionRenewPage}
      element={
        <AuthRoute permissions={canRenewSubscriptionPermissions}>
          <SubscriptionRenewPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.EditClientSubscriptionRenewPage}
      element={
        <AuthRoute permissions={canEditSubscriptionPermissions}>
          <EditSubscriptionRenewPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.Renewals}/*`}
      element={
        <AuthRoute permissions={CanViewRenewalsPagePermissions}>
          <RenewalsPage />
        </AuthRoute>
      }
    />
    <Route path={RoutePath.NotFoundPage} element={<NotFoundPage />} />
    <Route path="*" element={<Redirect to={RoutePath.Home} />} />
  </Routes>
);

export default AppRoutes;
