import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IPageListModel } from '../../../../models/PageListModel';
import { IPerson } from '../../../../models/Person';
import { personToDOCMemberListItem } from '../../../../utils/personHelpers';
import { Dictionary } from '../../../../models/Dictionary';
import { IDOCMembershipModel } from '../../../../models/interfaces/Membership/DutyOfCare/DOCMembershipModel';
import { IDOCMemberListItem } from '../../../../models/interfaces/Membership/DutyOfCare/IDOCMemberListItem';

export interface IDOCMembersState {
  summary?: IAddMembershipSummaryModel;
  newMembershipDates: IMembershipDates;
  members?: IPageListModel<IDOCMemberListItem>;
  membershipDetails: Dictionary<IDOCMembershipModel>;
}

const initialState: IDOCMembersState = {
  summary: null,
  newMembershipDates: null,
  members: null,
  membershipDetails: {},
};

const docMembershipSlice = createSlice({
  initialState,
  name: 'docMembership',
  reducers: {
    setDOCMembershipSummary: (
      state,
      { payload }: PayloadAction<IAddMembershipSummaryModel>,
    ) => {
      state.summary = payload;
    },
    setDOCMembershipDates: (
      state,
      { payload }: PayloadAction<IMembershipDates>,
    ) => {
      state.newMembershipDates = {
        effectiveDate: parseDate(payload.effectiveDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setDOCMembers: (
      state,
      { payload }: PayloadAction<IPageListModel<IDOCMemberListItem>>,
    ) => {
      state.members = payload;
    },
    updateDOCMembersList: (state, { payload }: PayloadAction<IPerson>) => {
      const currentPersonIndex = state.members?.items.findIndex(
        (member) => member.personId === payload.personId,
      );

      if (currentPersonIndex !== -1) {
        state.members.items[currentPersonIndex] = personToDOCMemberListItem(
          payload,
          state.members.items[currentPersonIndex],
        );
      }
    },
    setDOCMembershipDetails: (
      state,
      { payload }: PayloadAction<IDOCMembershipModel>,
    ) => {
      state.membershipDetails[payload.id] = payload;
    },
    clearDOCMembershipDates: (state) => {
      state.newMembershipDates = initialState.newMembershipDates;
    },
    clearDOCCreateMemberData: () => initialState,
    clearDOCMembershipSummary: (state) => {
      state.summary = initialState.summary;
    },
    clearDOCMembershipDetails: (state) => {
      state.membershipDetails = initialState.membershipDetails;
    },
    clearDOCMembers: (state) => {
      state.members = initialState.members;
    },
  },
});

export const {
  clearDOCCreateMemberData,
  clearDOCMembers,
  clearDOCMembershipDates,
  clearDOCMembershipSummary,
  setDOCMembers,
  setDOCMembershipDates,
  setDOCMembershipSummary,
  updateDOCMembersList,
  clearDOCMembershipDetails,
  setDOCMembershipDetails,
} = docMembershipSlice.actions;
export default docMembershipSlice.reducer;
export const membersReducerName = docMembershipSlice.name;
