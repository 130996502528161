import {
  canViewAAOrganizationsPermissions,
  canViewGeneralOrganizationsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import {
  AvailableProductPermissionsType,
  AvailableProductsType,
} from '../../models/types/AvailableProductViewsPermissionsType';

export const ClientsPagesPermissions: AvailableProductPermissionsType = {
  General: canViewGeneralOrganizationsPermissions,
  AirAmbulance: canViewAAOrganizationsPermissions,
};

export const Views: AvailableProductsType = {
  General: RoutePath.ProductGeneralPage,
  AirAmbulance: RoutePath.ProductAirAmbulancePage,
};
