import Spinner from '../../../../styledComponents/Spinner/Spinner';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import {
  LeftContainer,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import WizardStepper from '../../../../styledComponents/Wizard/WizardStepper/WizardStepper';
import { useAddMemberWizardPage } from './addMemberWizardPage.hooks';
import { StyledPageRootColumns } from './addMemberWizardPage.styles';
import { findChildComponent } from '../../../../utils/reactChildrenHelpers';
import { AddMemberWizardPageContainersKeys } from './addMemberWizardPage.models';

interface IAddMemberWizardPageProps extends CustomComponent {
  isDirty?: boolean;
  isLoading?: boolean;
}

const AddMemberWizardPage = ({
  children,
  className,
  isDirty,
  isLoading,
  disabled,
}: IAddMemberWizardPageProps) => {
  const { isDataLoading } = useAddMemberWizardPage({ isDirty });

  return (
    <Spinner size="20%" isVisible={isLoading || isDataLoading}>
      <StyledPageRootColumns className={className}>
        <LeftContainer>
          <WizardStepper disabled={disabled} />
          {findChildComponent(
            children,
            AddMemberWizardPageContainersKeys.LeftContainer,
          )}
        </LeftContainer>
        <RightContainer>
          {findChildComponent(
            children,
            AddMemberWizardPageContainersKeys.RightContainer,
          )}
        </RightContainer>
      </StyledPageRootColumns>
    </Spinner>
  );
};

export default AddMemberWizardPage;
