import axios from 'axios';

import config from '../../../../config/config';

import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';

const MainUrl = 'api/reports';

export const getAARawDataReports = () =>
  axios.get(`${MainUrl}/personMembership`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    headers: { accept: 'text/csv' },
  });

export const getAAEnrollmentReport = (date: string) => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'month',
      value: date,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(`${MainUrl}/enrollment${params}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    headers: { accept: 'text/csv' },
  });
};

export const getAAAttritionReport = (date: string) => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'month',
      value: date,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(`${MainUrl}/attrition${params}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    headers: { accept: 'text/csv' },
  });
};

export const getAARetailStatisticsReport = (date: string) => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'month',
      value: date,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(`${MainUrl}/retailstatistics${params}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    headers: { accept: 'text/csv' },
  });
};
