import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps } from 'react-router';

import Button from '../../styledComponents/UI/Button/Button';
import { useHomePage } from './homePage.hooks';
import { Title, Wrapper } from './homePage.styles';

const HomePage: FC<RouteProps> = () => {
  const { isAuth, onLoginHandler, onLogoutHandler, userName } = useHomePage();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>
        {isAuth
          ? t('phrase.welcome', { name: userName })
          : t('phrase.please-login')}
      </Title>
      {!isAuth ? (
        <Button onClick={onLoginHandler}>{t('buttons.login')}</Button>
      ) : (
        <Button onClick={onLogoutHandler}>{t('buttons.logout')}</Button>
      )}
    </Wrapper>
  );
};

export default HomePage;
