import { useTranslation } from 'react-i18next';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { SubscriptionItemBaseProps } from '../../SubscriptionItemForm/subscriptionItemForm.models';
import { useSecurityResponseSubscriptionForm } from './securityResponseSubscriptionForm.hooks';
import { Wrapper } from './securityResponseSubscriptionForm.styles';
import { srSubscriptionOptions } from './securityResponseSubscriptionForm.utils';
import SRSubscriptionPricingForm from './SRSubscriptionPricingForm/SRSubscriptionPricingForm';

const SecurityResponseSubscriptionForm = ({
  name,
  disabled,
  className,
  isEditMode,
  contractType,
}: SubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  useSecurityResponseSubscriptionForm({ name, contractType });
  const options = srSubscriptionOptions(contractType);
  return (
    <Wrapper className={className}>
      <SRSubscriptionPricingForm
        name={name}
        disabled={disabled}
        isEditMode={isEditMode}
      />
      {options?.length !== 0 && (
        <SubscriptionOptions
          disabled={disabled}
          name={name}
          title={t('headers.options')}
          subscriptionOptions={options}
          layout="column"
        />
      )}
    </Wrapper>
  );
};

export default SecurityResponseSubscriptionForm;
