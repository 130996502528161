import { createSelector } from 'reselect';

import { IPeopleState } from '.';
import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';
import { IPerson } from '../../../models/Person';
import { IPersonMembershipItem } from '../../../models/PersonMembershipItem';
import { RootState } from '../index';

export const peopleSelector = (state: RootState) => state.people;

export const personSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) => people?.person,
);

export const personRolesSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) => people.personRoles,
);

export const personNameSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) =>
    `${people?.person.firstName} ${people?.person.lastName}`,
);
export const personEmailSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) => people?.person.contactEmailAddress,
);

export const updatePersonSelector = createSelector(
  personSelector,
  (person: IPerson): IUpdatePersonModel =>
    person
      ? {
          personId: person.personId,
          contactEmailAddress: person.contactEmailAddress,
          phone: person.phone,
          dateOfBirth: person.dateOfBirth,
          firstName: person.firstName,
          lastName: person.lastName,
          gender: person.gender,
          prefix: person.prefix,
          metadata: person.metadata,
          correspondenceAddress: person.correspondenceAddress,
          correspondenceAddressIsSameAsResidenceAddress:
            person.correspondenceAddressIsSameAsResidenceAddress,
          residenceAddress: person.residenceAddress,
        }
      : null,
);

export const personMembershipsSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) => people.personMemberships,
);

export const personMembershipIsActiveByMembershipIdSelector = (
  membershipId: string,
) =>
  createSelector(
    personMembershipsSelector,
    (memberships: IPersonMembershipItem[]) => {
      const currentMembershipIndex = memberships.findIndex(
        (membership) => membership.membershipId === membershipId,
      );
      if (currentMembershipIndex === -1) {
        return null;
      }

      return !memberships[currentMembershipIndex].isFutureMembership;
    },
  );

export const personActiveMembershipsSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) =>
    people.personMemberships?.filter((item) => item.isExpired !== true),
);

export const personExpiredMembershipsSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) =>
    people.personMemberships?.filter((item) => item.isExpired === true),
);

export const personGeneralListSelector = createSelector(
  peopleSelector,
  (people: IPeopleState) => people.personList,
);
