import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

type UseFormSelectAsync = {
  onChange: (event: any) => void;
  value?: any;
  promiseOptions?: () => Promise<any>;
  options?: any;
  setDefaultValue?: boolean;
  labelAnimation?: boolean;
  placeholder?: ReactNode;
  name?: string;
  onOptionSelected?: () => void;
};

export const useFormSelectAsync = ({
  labelAnimation,
  placeholder,
  name,
  onChange,
  onOptionSelected,
}: UseFormSelectAsync) => {
  const { t } = useTranslation();
  const preparedPlaceholder = useMemo(
    () =>
      labelAnimation
        ? ''
        : placeholder || t(`fields.${getPropertyName(name)}.placeholder`),
    [labelAnimation, name, placeholder, t],
  );

  const onChangeHandler = (option: any) => {
    onChange(option?.value);
    onOptionSelected && onOptionSelected();
  };

  return { preparedPlaceholder, onChangeHandler };
};
