import { AnySchema } from 'ajv';

import { ICreateOrganizationPointOfContact } from '../../../../models/CreateOrganizationPointOfContact';
import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { CorpPointOfContactFieldsModel } from './corpPointOfContact.models';

export const organizationPointOfContactSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [CorpPointOfContactFieldsModel.firstName]: {
      type: 'string',
      maxLength: 50,
    },
    [CorpPointOfContactFieldsModel.lastName]: {
      type: 'string',
      maxLength: 50,
    },
    [CorpPointOfContactFieldsModel.jobTitle]: {
      type: 'string',
      maxLength: 20,
    },
    [CorpPointOfContactFieldsModel.emailAddress]: {
      type: 'string',
      format: 'email',
    },
    [CorpPointOfContactFieldsModel.mobilePhone]: {
      type: 'string',
      format: 'phone',
    },
    [CorpPointOfContactFieldsModel.workPhone]: {
      type: 'string',
      format: 'phone',
    },
    [CorpPointOfContactFieldsModel.internalNotes]: {
      type: 'string',
      maxLength: 200,
    },
    [CorpPointOfContactFieldsModel.roles]: {
      type: 'array',
      items: { type: 'string' },
    },
  },
  if: {
    properties: {
      [CorpPointOfContactFieldsModel.isOrganizationAdministrator]: {
        enum: [true],
      },
    },
  },
  then: {
    required: [CorpPointOfContactFieldsModel.emailAddress],
  },
  else: {
    anyOf: [
      {
        required: [CorpPointOfContactFieldsModel.mobilePhone],
      },
      {
        required: [CorpPointOfContactFieldsModel.workPhone],
      },
      {
        required: [CorpPointOfContactFieldsModel.emailAddress],
      },
    ],
  },
  required: [
    CorpPointOfContactFieldsModel.firstName,
    CorpPointOfContactFieldsModel.lastName,
  ],
};

export const validateOrganizationPointOfContactForm = (
  data: ICreateOrganizationPointOfContact,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, organizationPointOfContactSchema);
