import { createSelector } from 'reselect';

import { IEmployee } from 'models/Employee';

import { RootState } from '../index';
import { IEmployeesState } from '.';

export const employeesSelector = (state: RootState) => state.employees;

export const employeeListSelector = createSelector(
  employeesSelector,
  (people: IEmployeesState): IEmployee[] => people.items,
);
