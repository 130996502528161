import { t } from 'i18next';

import { SelectOption } from '../../../../models/SelectOption';
import { FilterType } from './FiltersForm/filterForm.models';
import {
  FilterAdditionalValuesModel,
  FiltersOptions,
} from './FiltersForm/FilterTypes/filtersTypes.models';

export type CustomFilterField = {
  fieldName: string;
  filterType: FilterType;
};

export type CustomFilterValue = string | string[] | SelectOption<string>;

export type CustomFilter<T = FilterAdditionalValuesModel> = {
  id?: string;
  field: CustomFilterField;
  value: CustomFilterValue;
  additionalValues?: T;
};

export type FilterWithDefinition = {
  filter: CustomFilter;
  filterDefinition: FilterDefinition<FiltersOptions>;
};

export const allFilterValue = 'all';

export const AllOptionsFilter: SelectOption = {
  label: t(`filters.${allFilterValue}`),
  value: allFilterValue,
};

export type FilterDefinition<T = FiltersOptions> = {
  field: string;
  filterType: FilterType;
  filterOptions?: T;
};

export enum RenewalFiltersStaticFilters {
  HideExpired = 'hideExpired',
}
