import { useEffect } from 'react';

import { useAppIsReady } from './useAppIsReady';

import { useNavigateBack } from './useNavigateBack';

export const useClickBackButtonBrowser = (
  listener: (evt: any) => void = null,
) => {
  const { goBack } = useNavigateBack();
  const isReady = useAppIsReady();

  useEffect(() => {
    const handler = (evt: any) => {
      if (!isReady) {
        return;
      }

      evt.preventDefault();
      evt.stopPropagation();
      goBack(3);
      listener && listener(evt);
    };
    window.addEventListener('popstate', handler);
    return () => window.removeEventListener('popstate', handler);
  }, [goBack, listener, isReady]);
};
