import styled from '@emotion/styled';

import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 2rem;
`;
