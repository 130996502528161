import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { ITLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { tlSubscriptionPricingFormSchema } from '../../../Forms/TravelLicense/TLSubscriptionPricingForm/tlSubscriptionPricingForm.validation';
import { TLRenewSubscriptionPricingTiersFormFields } from './travelLicenseRenewSubscriptionForm.models';

export const tlRenewSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [TLRenewSubscriptionPricingTiersFormFields.PricingTiers]: {
      type: 'array',
      items: tlSubscriptionPricingFormSchema,
    },
  },
  required: [],
};

export const validateRenewTLSubscriptionForm = (
  data: ITLOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, tlRenewSubscriptionSchema);
