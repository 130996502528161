import { useForm, useFormState } from 'react-final-form';

import { ContractOptionsFormFields } from './contractOptions.models';
import { ICreateOrganizationContractModel } from '../../../../models/CreateOrganizationContractModel';

export const useContractOptionsForm = () => {
  const { change } = useForm();
  const formState = useFormState();

  function getYearDiff(startDate: Date, endDate: Date): number {
    const msInYear = 365 * 24 * 60 * 60 * 1000;

    return Math.abs(Number(endDate) - Number(startDate)) / msInYear;
  }

  const onDateChange = () => {
    const values = formState.values as ICreateOrganizationContractModel;
    if (values.startDate && values.expirationDate) {
      const diff = getYearDiff(
        new Date(values.startDate),
        new Date(values.expirationDate),
      );
      diff > 2
        ? change(ContractOptionsFormFields.IsMultiYearContract, true)
        : change(ContractOptionsFormFields.IsMultiYearContract, false);
    }
  };

  return {
    onDateChange,
  };
};
