import { Field } from 'react-final-form';

import { OverwriteAmountInput } from './checkboxContentInput.styles';

interface ICheckboxContentInputProps {
  disabled?: boolean;
  inputId?: string;
  name?: string;
}
export const CheckboxContentInput = ({
  disabled,
  inputId,
  name,
}: ICheckboxContentInputProps) => (
  <Field
    prefix="$"
    component={OverwriteAmountInput}
    disabled={disabled}
    inputId={inputId}
    name={name}
  />
);
