import { useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useActions } from '../../../../../../../hooks/useActions';
import { useRouteParams } from '../../../../../../../hooks/useRouteParams';
import { MembershipType } from '../../../../../../../models/MembershipType';
import {
  updateAvailableSteps,
  IWizardAvailableStep,
  WizardStepsCn,
} from '../../../../../../../store/redux-slices/wizard';
import { getFormGroupPath } from '../../../../../../../utils/getFormGroupPath';
import { MembershipTypeFormFields } from './aaMembershipType.models';

export const useMembershipTypeForm = (fieldName: string) => {
  const {
    input: { value: memberType },
  }: FieldRenderProps<MembershipType> = useField(
    getFormGroupPath(fieldName, MembershipTypeFormFields.MembershipType),
  );
  const changeAvailableStep = useActions(updateAvailableSteps);
  const { membershipId } = useRouteParams();

  useEffect(() => {
    const steps: IWizardAvailableStep[] = [
      {
        canonicalName: WizardStepsCn.PaymentPage,
        isAvailable: memberType === MembershipType.Retail,
      },
    ];

    changeAvailableStep(steps);
  }, [changeAvailableStep, memberType]);

  const memberTypeReadOnly = useMemo(() => !!membershipId, [membershipId]);

  return {
    memberTypeReadOnly,
  };
};
