import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../hooks/useActions';
import { updateModalState } from '../../store/redux-slices/modals';
import { modalsSelector } from '../../store/redux-slices/modals/selectors';

type UseModalProps = {
  name: string;
  onModalClose?: () => void;
};

export const useModal = ({ name, onModalClose }: UseModalProps) => {
  const modalsState = useSelector(modalsSelector)[name]?.isOpen;
  const changeModalState = useActions(updateModalState);

  const closeModalHandler = useCallback(() => {
    changeModalState(name);
    onModalClose && onModalClose();
  }, [changeModalState, name, onModalClose]);

  return { closeModalHandler, modalsState };
};
