import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import {
  AddMemberAvailableSearchParams,
  EditProductSubscriptionPageParams,
  EditProductSubscriptionRenewPageParams,
  ExtendProductSubscriptionPageParams,
  OrganizationMembersListPageParams,
  RoutePath,
} from '../../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { ILoadSubscriptionOverviewPayload } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { organizationNameSelector } from '../../../../../store/redux-slices/organizations/selectors';
import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import { AAMembersListFilters } from '../../../../../styledComponents/ProductMembersList/AAMembersList/AAMembersFilters/aaMembersFilters.models';
import { MemberType } from '../../../../../models/enums/MemberType';
import { ProductType } from '../../../../../models/ProductType';
import { loadKESubscriptionOverview } from '../../../../../store/saga-slices/subscriptions/kidnapAndExtortion';
import { clearKESubscriptionOverview } from '../../../../../store/redux-slices/subscriptions/kidnapAndExtortion';
import { keSubscriptionOverviewSelector } from '../../../../../store/redux-slices/subscriptions/kidnapAndExtortion/selectors';
import { DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME } from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/deleteSubscriptionModal.const';
import { DeleteSubscriptionModalDetails } from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/deleteSubscriptionModal.model';
import { EDITING_KE_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './Modals/KESubscriptionEditOptionsModal/keSubscriptionEditOptionsModal.const';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { EditRenewalSubscriptionStatusModalDetails } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.models';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.const';

export const useKESubscriptionOverviewTabPage = () => {
  const { id, subscriptionId } = useParams();
  const navigate = useNavigateSearch();

  const LoadSubscriptionOverview = useActions(loadKESubscriptionOverview);
  const ClearCurrentSubscription = useActions(clearKESubscriptionOverview);

  const subscriptionOverviewLoading = useProcessing(
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  );
  const subscriptionOverview = useSelector(keSubscriptionOverviewSelector);

  const loadSubscriptionOverviewHandler = useCallback(() => {
    const payload: ILoadSubscriptionOverviewPayload = {
      organizationId: id,
      subscriptionId,
    };
    LoadSubscriptionOverview(payload);
  }, [LoadSubscriptionOverview, id, subscriptionId]);

  useEffect(() => {
    loadSubscriptionOverviewHandler();
  }, [loadSubscriptionOverviewHandler]);

  useEffect(
    () => () => {
      ClearCurrentSubscription();
    },
    [ClearCurrentSubscription],
  );

  return {
    id,
    subscriptionId,
    navigate,
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  };
};

export const useKESubscriptionOverviewActions = () => {
  const { t } = useTranslation();
  const { id, subscriptionId, subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();
  const organizationName = useSelector(organizationNameSelector);
  const UpdateModalState = useActions(updateModalState);
  const navigate = useNavigateSearch();
  const subscriptionOverview = useSelector(keSubscriptionOverviewSelector);

  const deleteProductSubscriptionHandler = () => {
    const details: DeleteSubscriptionModalDetails = {
      name: t('phrase.kidnap-and-extortion-for', {
        value: organizationName,
      }),
      organizationId: id,
      subscriptionId,
      productType: ProductType.KidnapAndExtortion,
    };

    UpdateModalState([DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME, details]);
  };

  const addMemberHandler = () => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.OrganizationId]: id,
        [AddMemberAvailableSearchParams.SubscriptionId]: subscriptionId,
        [AddMemberAvailableSearchParams.ProductType]:
          ProductType.KidnapAndExtortion,
      },
    });
  };

  const editSubscriptionHandler = () => {
    if (subscriptionOverview?.isRenewation) {
      navigate(RoutePath.EditClientSubscriptionRenewPage, {
        params: {
          [EditProductSubscriptionRenewPageParams.OrganizationId]: id,
          [EditProductSubscriptionRenewPageParams.SubscriptionId]:
            subscriptionId,
          [EditProductSubscriptionRenewPageParams.ProductType]:
            ProductType.KidnapAndExtortion,
        },
      });
      return;
    }

    navigate(RoutePath.EditProductSubscription, {
      params: {
        [EditProductSubscriptionPageParams.OrganizationId]: id,
        [EditProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [EditProductSubscriptionPageParams.SubscriptionType]:
          ProductType.KidnapAndExtortion,
      },
    });
  };

  const extendProductSubscriptionHandler = () => {
    navigate(RoutePath.ExtendProductSubscription, {
      params: {
        [ExtendProductSubscriptionPageParams.OrganizationId]: id,
        [ExtendProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [ExtendProductSubscriptionPageParams.ProductType]:
          ProductType.KidnapAndExtortion,
      },
    });
  };

  const onRowClickHandler = (
    data?: IOrganizationSubscriptionOverviewMembershipPlan,
  ) => {
    const searchParams = {
      [AAMembersListFilters.SubscriptionId]: subscriptionId,
      [AAMembersListFilters.MemberType]: MemberType.Primary,
    };

    if (data) {
      searchParams[AAMembersListFilters.Plan] = data.membershipPlanName;
    }
    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const onSeeOversubscribedMembersHandler = (
    data?: IOrganizationSubscriptionOverviewMembershipPlan,
  ) => {
    const searchParams = {
      [AAMembersListFilters.SubscriptionId]: subscriptionId,
      [AAMembersListFilters.OversubscribedOnly]: 'true',
      [AAMembersListFilters.MemberType]: MemberType.Primary,
    };

    if (data) {
      searchParams[AAMembersListFilters.Plan] = data.membershipPlanName;
    }

    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const showEditSubscriptionDetailsModal = () => {
    UpdateModalState([
      EDITING_KE_SUBSCRIPTION_OPTIONS_MODAL_NAME,
      { data: subscriptionOverview },
    ]);
  };

  const editRenewalStatusHandler = () => {
    const details: EditRenewalSubscriptionStatusModalDetails = {
      organizationId: id,
      subscriptionId,
      renewalStatus: subscriptionOverview?.renewalStatus,
      contractName: subscriptionOverview?.organizationContractName,
      organizationName,
      expirationDate: subscriptionOverview?.effectiveDateTo,
      productType: subscriptionType,
    };

    UpdateModalState([EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME, details]);
  };

  return {
    extendProductSubscriptionHandler,
    addMemberHandler,
    onRowClickHandler,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    deleteProductSubscriptionHandler,
    showEditSubscriptionDetailsModal,
    editRenewalStatusHandler,
  };
};
