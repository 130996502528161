import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { ExtendProductSubscriptionPageParamsType } from '../../../models/types/RoutePath';
import { clearCurrentOrganization } from '../../../store/redux-slices/organizations';
import { organizationNameSelector } from '../../../store/redux-slices/organizations/selectors';
import { clearSubscriptionExtensionSummary } from '../../../store/redux-slices/subscriptions/common';
import { clearKESubscriptionOverview } from '../../../store/redux-slices/subscriptions/kidnapAndExtortion';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { loadKESubscriptionOverview } from '../../../store/saga-slices/subscriptions/kidnapAndExtortion';
import { ILoadKESubscriptionOverviewPayload } from '../../../store/saga-slices/subscriptions/kidnapAndExtortion/models';

export const useExtendKESubscriptionPage = () => {
  const { organizationId, subscriptionId } =
    useParams<ExtendProductSubscriptionPageParamsType>();

  const organizationName = useSelector(organizationNameSelector);

  const LoadOrganization = useActions(loadOrganization);
  const LoadSubscriptionOverview = useActions(loadKESubscriptionOverview);
  const ClearCurrentOrganization = useActions(clearCurrentOrganization);
  const ClearCurrentSubscription = useActions(clearKESubscriptionOverview);
  const ClearSubscriptionExtensionSummary = useActions(
    clearSubscriptionExtensionSummary,
  );

  useEffect(() => {
    LoadOrganization(organizationId);
    const loadSubscriptionOverviewPayload: ILoadKESubscriptionOverviewPayload =
      {
        organizationId,
        subscriptionId,
      };
    LoadSubscriptionOverview(loadSubscriptionOverviewPayload);
  }, [
    LoadOrganization,
    LoadSubscriptionOverview,
    organizationId,
    subscriptionId,
  ]);

  useEffect(
    () => () => {
      ClearCurrentOrganization();
      ClearCurrentSubscription();
      ClearSubscriptionExtensionSummary();
    },
    [
      ClearCurrentOrganization,
      ClearCurrentSubscription,
      ClearSubscriptionExtensionSummary,
    ],
  );

  return { organizationName, organizationId, subscriptionId };
};
