import styled from '@emotion/styled/macro';

import Button from '../../../../../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageIcon = styled.div`
  margin-right: 0.5rem;
`;

export const StyledButton = styled(Button)`
  font: normal normal 0.75rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.lighter_blue};
  padding-left: 0.2rem;
`;
