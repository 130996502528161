import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ORGANIZATION_CONTRACT_ADDING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import { ADD_CONTRACT_NOTES_MODAL_NAME } from './addContractNotesModal.const';
import AddContractModalForm from './AddContractNotesModalForm/AddContractNotesModalForm';

interface IAddContractNotesModalProps {
  onNotesAdded?: () => void;
}

const AddContractNotesModal = ({
  onNotesAdded,
}: IAddContractNotesModalProps) => {
  const isProcessing = useProcessing(ORGANIZATION_CONTRACT_ADDING_PROCESSING);
  return (
    <Modal name={ADD_CONTRACT_NOTES_MODAL_NAME} disabled={isProcessing}>
      <AddContractModalForm onNotesAdded={onNotesAdded} />
    </Modal>
  );
};

export default AddContractNotesModal;
