import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';

import SubscriptionMembershipPlanForm from '../SubscriptionMembershipPlanForm/SubscriptionMembershipPlanForm';
import MembershipPlanFormProvider from '../../../../UI/Form/MembershipPlanForm/MembershipPlanFormProvider';
import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';
import { BasicFormWrapper } from '../../../../Form/Form';
import { SectionSubTitle } from '../../../../Typography/Typography';
import { Description } from './subscriptionPricingForm.styles';
import {
  SubscriptionPricingFormFields,
  SubscriptionPricingFormName,
} from './subscriptionPricingForm.models';
import { useSubscriptionPricingForm } from './subscriptionPricingForm.hooks';
import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../../../utils/getFormGroupPath';
import { SubscriptionItemBaseProps } from '../../../SubscriptionItemForm/subscriptionItemForm.models';
import { PricingMode } from '../../../../../models/enums/PricingMode';

const SubscriptionPricingForm = ({
  className,
  disabled,
  name,
  isEditMode,
}: SubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const { membershipPlansPrices, subscriptionType } =
    useSubscriptionPricingForm({ name, isEditMode });

  return (
    <MembershipPlanFormProvider
      regularMembershipPlansPrices={membershipPlansPrices}
      defaultPricingMode={PricingMode.Regular}
      name={getFormGroupPath(name, SubscriptionPricingFormFields.Pricing)}
      createInitValues={!isEditMode}
    >
      <BasicFormWrapper className={className}>
        <SectionSubTitle>
          {t('headers.retail-pricing-and-current-capacity')}
        </SectionSubTitle>
        <Description>
          {t('phrase.this-pricing-defines-retail-prices')}
        </Description>
        <FormColumn>
          <Field
            component={SubscriptionMembershipPlanForm}
            disabled={disabled || !subscriptionType}
            membershipPlansPrices={membershipPlansPrices}
            {...getFieldPropertiesForGroup({
              inputId: SubscriptionPricingFormName,
              groupName: name,
              name: SubscriptionPricingFormFields.Pricing,
            })}
            subscriptionType={subscriptionType}
          />
        </FormColumn>
      </BasicFormWrapper>
    </MembershipPlanFormProvider>
  );
};

export default SubscriptionPricingForm;
