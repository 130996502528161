/* eslint-disable no-undefined */
import { applyMiddleware, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { PersistConfig } from 'redux-persist/es/types';

import { composeWithDevTools } from '@redux-devtools/extension';

import rootSaga from './saga-slices';
import rootReducer from './redux-slices';
import { routesHistoryReducerName } from './redux-slices/routesHistory';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage: sessionStorage,
  whitelist: [routesHistoryReducerName],
};

export const configureStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware /* loggerMiddleware, thunkMiddleware*/];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer /* monitorReducersEnhancer*/];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const reducers = (state: any, action: any) => {
    if (action.type === 'LOGOUT') {
      sessionStorage.removeItem('persist:root');
      state = undefined;
    }
    return persistedReducer(state, action);
  };

  const store = createStore(reducers, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./redux-slices', () => store.replaceReducer(reducers));
  }

  sagaMiddleware.run(rootSaga);

  return store;
};
