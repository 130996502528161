import { FocusEventHandler, useCallback } from 'react';
import { NumberFormatValues, SourceInfo } from 'react-number-format';

import { isNumberInRange } from '../../../utils/numberHelpers';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';
import { isEmpty } from '../../../utils/textHelpers';

type UseBaseNumberInputProps = {
  min?: string | number;
  max?: string | number;
  onChangeValue?: (value: string) => void;
  value: string | number;
  defaultValue: string | number;
  onBlur: FocusEventHandler<HTMLInputElement>;
};

export const useBaseNumberInput = ({
  max,
  min,
  onChangeValue,
  value,
  defaultValue,
  onBlur,
}: UseBaseNumberInputProps) => {
  const onChangeHandler = useCallback(
    (values: NumberFormatValues, sourceInfo: SourceInfo) => {
      onChangeValue?.(values.value);
    },
    [onChangeValue],
  );
  const isAllowed = useCallback(
    (values: NumberFormatValues) =>
      isNumberInRange(values.floatValue, max, min),
    [max, min],
  );

  const onBlurHandler = useCallback(
    (evt: FocusEventHandler<HTMLInputElement>) => {
      isEmpty(value?.toString()) &&
        !isPropertyEmpty(defaultValue) &&
        onChangeValue?.(defaultValue?.toString());
      onBlur?.(evt as any);
    },
    [defaultValue, onBlur, onChangeValue, value],
  );

  const currentValue = !isPropertyEmpty(value) ? value : '';

  return { isAllowed, onChangeHandler, currentValue, onBlurHandler };
};
