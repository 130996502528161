import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../Form/Form';
import Button from '../../UI/Button/Button';

export const Wrapper = styled(SectionWrapper)`
  margin-top: 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 1.25rem;
`;
