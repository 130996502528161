import React, { ReactNode } from 'react';

export const findChildComponent = (
  children: ReactNode,
  componentName: string,
) => {
  const childArray = React.Children.toArray(children);

  const childComponentIndex = childArray.findIndex(
    (child: any) => child && child.key === `.$${componentName}`,
  );

  return (
    childComponentIndex !== -1 &&
    React.cloneElement(childArray[childComponentIndex] as any)
  );
};
