import { SimplePageLine } from '../../SimplePage/SimplePageLine';
import AAMembersTable, {
  IAAMembersTableProps,
} from './AAMembersTable/AAMembersTable';
import AAMembersTools, {
  AAMembersToolsProps,
} from './AAMembersTools/AAMembersTools';

type IAAMembersListProps = {
  tableProps: IAAMembersTableProps;
  toolsProps: AAMembersToolsProps;
};

const AAMembersList = ({ tableProps, toolsProps }: IAAMembersListProps) => (
  <>
    <AAMembersTools {...toolsProps} />
    <SimplePageLine />
    <AAMembersTable {...tableProps} />
  </>
);

export default AAMembersList;
