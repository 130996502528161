import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { membershipSummarySchema } from '../../AddMemberSummaryForm/addMemberSummaryForm.validation';
import { aaMembershipDetailsSchema } from '../MembershipSectionsForms/AirAmbulanceMembership/airAmbulanceMembership.validation';
import { keMembershipDetailsSchema } from '../MembershipSectionsForms/KidnapAndExtortionMembership/kidnapAndExtortionMembership.validation';
import { ProductType } from '../../../../../models/ProductType';
import { BasicMembershipFormFields } from '../MembershipSectionsForms/BasicMembershipForm/basicMembershipForm.models';
import { CreateMembershipPageModel } from '../../../../../models/types/CreateMembershipPageModel';
import { srMembershipDetailsSchema } from '../MembershipSectionsForms/SecurityResponseMembership/securityResponseMembership.validation';
import { docMembershipDetailsSchema } from '../MembershipSectionsForms/DutyOfCareMembership/dutyOfCareMembership.validations';

export const membershipsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...membershipSummarySchema.properties,
  },

  dependencies: {
    [BasicMembershipFormFields.ProductType]: {
      allOf: [
        {
          if: {
            properties: {
              [BasicMembershipFormFields.ProductType]: {
                enum: [ProductType.KidnapAndExtortion],
              },
            },
          },
          then: {
            ...keMembershipDetailsSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicMembershipFormFields.ProductType]: {
                enum: [ProductType.AirAmbulance],
              },
            },
          },
          then: {
            ...aaMembershipDetailsSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicMembershipFormFields.ProductType]: {
                enum: [ProductType.SecurityResponse],
              },
            },
          },
          then: {
            ...srMembershipDetailsSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicMembershipFormFields.ProductType]: {
                enum: [ProductType.DutyOfCare],
              },
            },
          },
          then: {
            ...docMembershipDetailsSchema,
          },
        },
      ],
    },
    ...membershipSummarySchema.dependencies,
  },
  required: [],
};

export const validateMembershipsForm = (
  data: CreateMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, membershipsFormSchema);
