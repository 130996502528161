import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useActions } from '../../hooks/useActions';
import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { useProcessing } from '../../hooks/useProcessing';
import { IPromoCodeItem } from '../../models/PromoCodeItem';
import {
  RoutePath,
  ClientOverviewGeneralPageParams,
} from '../../models/enums/RoutePath';
import { PROMO_CODES_LOADING_PROCESSING } from '../../store/redux-slices/processes/constants';
import { clearPromoCodes } from '../../store/redux-slices/promoCodes';
import { promoCodesListSelector } from '../../store/redux-slices/promoCodes/selectors';
import { loadPromoCodes } from '../../store/saga-slices/promoCodes';
import { ILoadPromoCodesPayload } from '../../store/saga-slices/promoCodes/models';
import { textContainsWords } from '../../utils/textHelpers';
import { RequiredQueryParams } from './promoCodesPage.models';

export const usePromoCodes = () => {
  const LoadPromoCodes = useActions(loadPromoCodes);
  const ClearPromoCodes = useActions(clearPromoCodes);
  const promoCodesList = useSelector(promoCodesListSelector);
  const dataLoading = useProcessing(PROMO_CODES_LOADING_PROCESSING);
  const location = useLocation();
  const navigate = useNavigateSearch();

  useEffect(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadPromoCodesPayload = {
      params,
    };

    LoadPromoCodes(payload);
  }, [LoadPromoCodes, location.search]);

  useEffect(() => () => ClearPromoCodes(), [ClearPromoCodes]);

  const rowClickHandler = (row: IPromoCodeItem) => {
    navigate(RoutePath.PromoCodeOverview, { params: { promoCodeId: row.id } });
  };

  const onAddPromoCodeHandler = useCallback(() => {
    navigate(RoutePath.AddPromoCode);
  }, [navigate]);

  const onCellClickHandler = (
    row: IPromoCodeItem,
    key: keyof IPromoCodeItem,
  ) => {
    switch (key) {
      case 'ownerName':
        if (!row?.ownerId) {
          return;
        }
        navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
          params: {
            [ClientOverviewGeneralPageParams.OrganizationId]: row.ownerId,
          },
        });
    }
  };

  return {
    promoCodesList,
    dataLoading,
    rowClickHandler,
    onAddPromoCodeHandler,
    onCellClickHandler,
  };
};
