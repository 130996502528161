import { ReactNode } from 'react';

import MainTooltip from '../../../../../UI/MainTooltip/MainTooltip';
import { DateWrapper } from './dateOfBirthCell.styles';

type DateOfBirthCellProps = {
  value: string;
  tooltipShowing?: boolean;
  tooltip?: ReactNode;
};

const DateOfBirthCell = ({
  tooltip,
  tooltipShowing,
  value,
}: DateOfBirthCellProps) => (
  <DateWrapper>
    <MainTooltip title={tooltip} isShowing={tooltipShowing}>
      <>{value}</>
    </MainTooltip>
  </DateWrapper>
);

export default DateOfBirthCell;
