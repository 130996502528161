import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { KidnapAndExtortionMembershipOptions } from '../../../../../../../models/enums/KidnapAndExtortionMembershipOptions';

import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { KEMembershipOrganizationDetailsFormFields } from '../KEMembershipDetailsForm/keMembershipDetailsForm.models';

export const useKEMembershipOptions = () => {
  const {
    input: { onChange: sendWelcomeEmailChange },
  }: FieldRenderProps<boolean> = useField(
    KidnapAndExtortionMembershipOptions.SendWelcomeEmail,
  );

  const {
    input: { onChange: autoRenewalChange },
  }: FieldRenderProps<boolean> = useField(
    KidnapAndExtortionMembershipOptions.AutoRenewal,
  );

  const {
    input: { value: selectedOrganization },
  }: FieldRenderProps<IOrganizationSearch> = useField(
    KEMembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { value: subscriptionType },
  }: FieldRenderProps<string> = useField(
    KEMembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  useEffect(() => {
    if (!subscriptionType || !selectedOrganization) {
      sendWelcomeEmailChange(null);
      autoRenewalChange(null);
      return;
    }

    const subscriptionIndex = selectedOrganization.subscriptions.findIndex(
      (x) => x.id === subscriptionType,
    );

    if (subscriptionIndex === -1) {
      sendWelcomeEmailChange(null);
      autoRenewalChange(null);
      return;
    }

    sendWelcomeEmailChange(
      selectedOrganization.subscriptions[subscriptionIndex].sendWelcomeEmail,
    );
    autoRenewalChange(
      selectedOrganization.subscriptions[subscriptionIndex]
        .autoRenewalMemberships,
    );
  }, [
    autoRenewalChange,
    selectedOrganization,
    sendWelcomeEmailChange,
    subscriptionType,
  ]);
};
