import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { ICreditCard } from '../../../../models/CreditCardModel';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { CREDIT_CARD_DELETE_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { deleteCreditCard } from '../../../../store/saga-slices/payment';
import { IDeleteCreditCardPayload } from '../../../../store/saga-slices/payment/model';
import { DELETE_CREDIT_CARD_MODAL_NAME } from './deleteCreditCardModal.const';

type UseDeleteCreditCardModalProps = {
  onCreditCardDeleted?: () => void;
};

export const useDeleteCreditCardModal = ({
  onCreditCardDeleted,
}: UseDeleteCreditCardModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const cancellationToken = useCancellationToken();

  const UpdateModalState = useActions(updateModalState);
  const DeleteCreditCard = useActions(deleteCreditCard);

  const {
    creditCard,
    personId,
  }: { creditCard: ICreditCard; personId: string } = useModalDetails(
    DELETE_CREDIT_CARD_MODAL_NAME,
  );

  const isDeleting = useProcessing(CREDIT_CARD_DELETE_PROCESSING);

  const onBackHandler = () => {
    UpdateModalState(DELETE_CREDIT_CARD_MODAL_NAME);
  };

  const deleteCreditCardHandler = () => {
    const payload: IDeleteCreditCardPayload = {
      creditCardId: creditCard?.creditCardId,
      personId,
      cancellationToken,
      error: () =>
        alert.error(t('phrase.credit-card-not-removed-successfully')),
      success: () => {
        UpdateModalState(DELETE_CREDIT_CARD_MODAL_NAME);
        onCreditCardDeleted?.();
        alert.success(t('phrase.credit-card-was-removed-successfully'));
      },
    };
    DeleteCreditCard(payload);
  };

  return {
    onBackHandler,
    creditCard,
    deleteCreditCardHandler,
    isDeleting,
  };
};
