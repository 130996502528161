import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';

export function prepareUpdatePersonData(
  data: IUpdatePersonModel,
): IUpdatePersonModel {
  return {
    ...data,
    correspondenceAddress: !data.correspondenceAddressIsSameAsResidenceAddress
      ? data.correspondenceAddress
      : null,
  };
}
