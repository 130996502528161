import { AnySchema } from 'ajv';

import { ICreateMemberModel } from '../../../../models/CreateMemberModel';
import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { FamilyMemberBasicDetailsFields } from './familyMemberBasicDetails.models';

export const validateFamilyMemberBasicDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [FamilyMemberBasicDetailsFields.FirstName]: {
      type: 'string',
    },
    [FamilyMemberBasicDetailsFields.LastName]: {
      type: 'string',
    },
    [FamilyMemberBasicDetailsFields.EmailAddress]: {
      type: 'string',
      format: 'email',
    },
    [FamilyMemberBasicDetailsFields.PhoneNumber]: {
      type: 'string',
      format: 'phone',
    },
    [FamilyMemberBasicDetailsFields.Gender]: {
      type: 'string',
    },
    [FamilyMemberBasicDetailsFields.Dependence]: {
      type: 'string',
    },
  },
  required: [
    FamilyMemberBasicDetailsFields.DateOfBirth,
    FamilyMemberBasicDetailsFields.FirstName,
    FamilyMemberBasicDetailsFields.LastName,
    FamilyMemberBasicDetailsFields.Dependence,
  ],
};

export const ValidateFamilyMemberBasicDetailsForm = (
  data: ICreateMemberModel,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(
    data,
    additionalErrors,
    validateFamilyMemberBasicDetailsSchema,
  );
