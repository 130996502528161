import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ICreateOrganizationModel } from '../../../../../../models/CreateOrganizationModel';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import { organizationSelector } from '../../../../../../store/redux-slices/organizations/selectors';
import { ORGANIZATION_EDITING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import {
  editOrganization,
  IEditOrganizationPayload,
} from '../../../../../../store/saga-slices/organizations';
import { useFormErrors } from '../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateOrganizationInformationsForm } from '../../../../../../styledComponents/Forms/CorporationInformationsForm/corporationInformationsForm.validation';
import { EDIT_CLIENT_DETAILS_MODAL } from './editClientDetailsModal.const';

export const useEditClientDetailsModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const UpdateModalState = useActions(updateModalState);
  const EditOrganization = useActions(editOrganization);

  const organization = useSelector(organizationSelector);
  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);

  const { addErrors, validateErrors } = useFormErrors();

  const handleSubmit = (values: ICreateOrganizationModel) => {
    const payload: IEditOrganizationPayload = {
      organization: values,
      organizationId: organization.id,
      success: () => {
        UpdateModalState(EDIT_CLIENT_DETAILS_MODAL);

        alert.success(
          t('alerts.organization-edit-success', { value: values.name }),
        );
      },
      error: (err) => {
        addErrors(err.ValidationErrors, values);

        alert.error(t('alerts.organization-edit-fail'));
      },
    };

    EditOrganization(payload);
  };

  const validateForm = () => (values: ICreateOrganizationModel) =>
    validateOrganizationInformationsForm(values, validateErrors(values));

  const onCancelHandler = () => UpdateModalState(EDIT_CLIENT_DETAILS_MODAL);
  const initState = {
    name: organization.name,
    billingAddress: organization.billingAddress,
    organizationType: organization.organizationType,
    correspondenceAddressSameAsBilling:
      organization.correspondenceAddressSameAsBilling,
    correspondenceAddress: !organization.correspondenceAddressSameAsBilling
      ? organization.correspondenceAddress
      : null,
  };

  return {
    validateForm,
    handleSubmit,
    organizationUpdating,
    onCancelHandler,
    initState,
    canChangeOrganizationType:
      organization?.actionsConsent?.canChangeOrganizationType,
  };
};
