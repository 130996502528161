import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import { ProductType } from '../../../models/ProductType';
import { useSubscriptionTLRenew } from './tlSubscriptionRenewPage.hooks';
import TLSubscriptionRenewPageForm from './TLSubscriptionRenewPageForm/TLSubscriptionRenewPageForm';

export const TLSubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const { organization, loadDatesForNewSubscriptionHandler, needShowSpinner } =
    useSubscriptionTLRenew();

  if (needShowSpinner) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.renew-subscription', {
              productType: ProductType.TravelLicense,
              organizationName: organization?.name,
            })}
          />
          <TLSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
