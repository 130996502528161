import { useProcessing } from '../../../hooks/useProcessing';
import { SmallModal } from '../../ModalCommon/ModalCommon';
import EditRenewalContractStatusModalForm from './EditRenewalContractStatusModalForm/EditRenewalContractStatusModalForm';
import { EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME } from './editRenewalContractStatusModal.const';
import { CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING } from '../../../store/redux-slices/processes/constants';

interface IEditRenewalContractStatusModalProps {
  onRenewalStatusEdited?: () => void;
}

const EditRenewalContractStatusModal = ({
  onRenewalStatusEdited,
}: IEditRenewalContractStatusModalProps) => {
  const isProcessing = useProcessing(
    CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING,
  );
  return (
    <SmallModal
      name={EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME}
      disabled={isProcessing}
    >
      <EditRenewalContractStatusModalForm
        onRenewalStatusEdited={onRenewalStatusEdited}
      />
    </SmallModal>
  );
};

export default EditRenewalContractStatusModal;
