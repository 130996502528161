import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import { SectionWrapper } from '../../Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import {
  RenewalStatusFormFields,
  renewalStatusFormName,
} from './renewalStatusForm.models';
import {
  formatOptionLabel,
  renewalStatusOptions,
} from './renewalStatusForm.utils';

interface IRenewalStatusFormProps extends CustomForm {
  label?: string;
  data?: object;
}

const RenewalStatusForm = ({
  disabled,
  name,
  label,
}: IRenewalStatusFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionWrapper>
        <FormColumn>
          <FormRow>
            <Field
              component={FormSelect}
              disabled={disabled}
              label={label || t('fields.renewalStatus.label')}
              {...getFieldPropertiesForGroup({
                inputId: renewalStatusFormName,
                name: RenewalStatusFormFields.NewRenewalStatus,
                groupName: name,
              })}
              options={renewalStatusOptions}
              formatOptionLabel={formatOptionLabel}
            />
          </FormRow>
        </FormColumn>
      </SectionWrapper>
    </>
  );
};

export default RenewalStatusForm;
