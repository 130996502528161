import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { OrganizationContractType } from '../../../models/enums/ContractType';

import { CustomForm } from '../../../models/types/CustomForm';
import SubscriptionItemForm from '../SubscriptionItemForm/SubscriptionItemForm';
import { useSubscriptionsForm } from './subscriptionsForm.hooks';
import { SubscriptionsFormFields } from './subscriptionsForm.models';
import { StyledButton, SubscriptionsWrapper } from './subscriptionsForm.styles';

type SubscriptionsFormProps = CustomForm & {
  contractType?: OrganizationContractType;
};

const SubscriptionsForm = ({
  className,
  disabled,
  contractType,
}: SubscriptionsFormProps) => {
  const { t } = useTranslation();
  const { filteredProductList } = useSubscriptionsForm();
  return (
    <div className={className}>
      <FieldArray name={SubscriptionsFormFields.Subscriptions}>
        {({ fields }) => (
          <div>
            <SubscriptionsWrapper>
              {fields.map((fieldName, index) => (
                <div key={fieldName}>
                  <SubscriptionItemForm
                    name={fieldName}
                    number={index + 1}
                    disabled={disabled}
                    contractType={contractType}
                    availableProducts={filteredProductList}
                  />
                </div>
              ))}
            </SubscriptionsWrapper>
            <StyledButton
              disabled={!filteredProductList?.length || disabled}
              onClick={() => fields.push({})}
              styleType="link"
            >
              {t('buttons.add-another-subscription')}
            </StyledButton>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default SubscriptionsForm;
