/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undefined */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Order } from '../../../models/Order';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';
import {
  firstLetterToUppercase,
  textContainsWords,
} from '../../../utils/textHelpers';
import { AvailableQueryParams } from '../materialTable.models';
import { DefaultRequiredQueryParams } from './materialTableTools.const';
import {
  MemberTableToolsActionableContext,
  MemberTableToolsInfoContext,
} from './MaterialTableToolsProvider';

type UseMaterialTableSortType = {
  defaultOrder?: Order;
  defaultOrderBy?: string;
};

export const useMaterialTableSort = ({
  defaultOrder,
  defaultOrderBy,
}: UseMaterialTableSortType) => {
  const [order, setOrder] = useState<Order>(
    defaultOrder || defaultOrderBy ? 'asc' : null,
  );
  const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);

  const handleRequestSort = (property: string) => {
    const currentOrder: Order =
      orderBy?.toLowerCase() === property?.toLowerCase() &&
      order?.toLowerCase() === 'asc'
        ? 'desc'
        : 'asc';

    setOrder(currentOrder);
    setOrderBy(property);
  };

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    handleRequestSort,
  };
};

type UseMaterialTablePaginationType = {
  defaultPageSize?: number;
  defaultPageNumber?: number;
  itemsCount?: number;
};

export const useMaterialTablePagination = ({
  defaultPageNumber,
  itemsCount,
  defaultPageSize,
}: UseMaterialTablePaginationType) => {
  const [numberPerPage, setNumberPerPage] = useState<number>(defaultPageSize);

  const [pageNumber, setPageNumber] = useState<number>(defaultPageNumber);

  useEffect(() => {
    if (itemsCount > 0 && pageNumber > itemsCount / numberPerPage) {
      pageChangeHandler(0);
    }
  }, [itemsCount, numberPerPage, pageNumber]);

  const currentPage = useMemo(
    () =>
      !pageNumber || pageNumber > itemsCount / numberPerPage ? 0 : pageNumber,
    [itemsCount, numberPerPage, pageNumber],
  );

  const rowsPerPageChangeHandler = (value: string) => {
    setNumberPerPage(Number.parseInt(value, 10));
    pageChangeHandler(0);
  };

  const pageChangeHandler = (newPage: number) => {
    setPageNumber(newPage);
  };

  return {
    numberPerPage,
    pageNumber,
    setNumberPerPage,
    setPageNumber,
    rowsPerPageChangeHandler,
    pageChangeHandler,
    currentPage,
  };
};

export function useMaterialTableToolsActions() {
  const { sort, pagination } = useContext(MemberTableToolsActionableContext);

  return { sort, pagination };
}

export function useMaterialTableToolsValues() {
  const { sort, pagination } = useContext(MemberTableToolsInfoContext);

  return { sort, pagination };
}

type UseMaterialTableToolsType = {
  defaultColumnSort?: string;
};

export const useMaterialTableTools = ({
  defaultColumnSort,
}: UseMaterialTableToolsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    pagination: { setNumberPerPage, setPageNumber },
    sort: { setOrder, setOrderBy },
  } = useMaterialTableToolsActions();

  const {
    sort: { orderBy, order },
    pagination: { numberPerPage, pageNumber },
  } = useMaterialTableToolsValues();

  useEffect(() => {
    if (setNumberPerPage && setPageNumber) {
      setPageNumber(
        Number.parseInt(
          searchParams.get(AvailableQueryParams.PageNumber),
          10,
        ) || 0,
      );
      setNumberPerPage(
        Number.parseInt(searchParams.get(AvailableQueryParams.PageSize), 10) ||
          50,
      );
    }
  }, [setNumberPerPage, setPageNumber]);

  useEffect(() => {
    const currentOrder = searchParams.get(AvailableQueryParams.Order);

    setOrder((currentOrder as Order) || (defaultColumnSort ? 'asc' : null));
    const currentOrderBy = searchParams.get(AvailableQueryParams.OrderBy);

    setOrderBy(currentOrderBy || defaultColumnSort);
  }, [defaultColumnSort, searchParams, setOrder, setOrderBy]);

  const updateSearchParams = useCallback(
    (
      param: AvailableQueryParams,
      paramValue: string | number,
      searchParamsInternal: URLSearchParams,
    ) => {
      !isPropertyEmpty(paramValue) &&
        searchParamsInternal.set(
          param,
          firstLetterToUppercase(paramValue.toString()),
        );
    },
    [],
  );

  const allDataRequiredToUpdate = useMemo(
    () => !!setNumberPerPage && !!setPageNumber && !!defaultColumnSort,
    [defaultColumnSort, setNumberPerPage, setPageNumber],
  );

  const allParamsFilled = useMemo(
    () =>
      !isPropertyEmpty(order) &&
      !isPropertyEmpty(orderBy) &&
      !isPropertyEmpty(numberPerPage) &&
      !isPropertyEmpty(pageNumber),
    [numberPerPage, order, orderBy, pageNumber],
  );

  const shouldParamsUpdate = useMemo(
    () =>
      !isPropertyEmpty(order) ||
      !isPropertyEmpty(orderBy) ||
      !isPropertyEmpty(numberPerPage) ||
      !isPropertyEmpty(pageNumber),
    [numberPerPage, order, orderBy, pageNumber],
  );

  useEffect(() => {
    if (allDataRequiredToUpdate && !allParamsFilled) {
      return;
    }

    if (!shouldParamsUpdate) {
      return;
    }
    const internalSearchParams = new URLSearchParams(window.location.search);

    updateSearchParams(AvailableQueryParams.Order, order, internalSearchParams);
    updateSearchParams(
      AvailableQueryParams.OrderBy,
      orderBy,
      internalSearchParams,
    );
    updateSearchParams(
      AvailableQueryParams.PageSize,
      numberPerPage,
      internalSearchParams,
    );
    updateSearchParams(
      AvailableQueryParams.PageNumber,
      pageNumber,
      internalSearchParams,
    );

    setSearchParams(internalSearchParams);
  }, [
    orderBy,
    order,
    numberPerPage,
    pageNumber,
    setSearchParams,
    updateSearchParams,
    shouldParamsUpdate,
    allDataRequiredToUpdate,
    allParamsFilled,
  ]);
};

export const useMaterialTableParams = (
  requiredQueryParams: AvailableQueryParams[] = DefaultRequiredQueryParams,
) => {
  const location = useLocation();

  const tableSearchParams = useMemo(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, requiredQueryParams)) {
      return null;
    }

    return params;
  }, [location.search, requiredQueryParams]);

  return tableSearchParams;
};
