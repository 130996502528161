import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  font-size: 0.875rem;
  align-items: center;
`;

type Props = {
  isSummary?: boolean;
};

export const Value = styled.div<Props>`
  color: ${({ theme }) => theme.color.black};
  ${({ isSummary }) =>
    isSummary &&
    css`
      font-size: 1.125rem;
      font-weight: bold;
    `}
`;

export const Separator = styled.div<Props>`
  color: ${({ theme, isSummary }) =>
    isSummary ? theme.color.black : theme.color.dark2};
`;

export const MaxValue = styled.div<Props>`
  color: ${({ theme, isSummary }) =>
    isSummary ? theme.color.black : theme.color.dark2};
  white-space: nowrap;
`;
