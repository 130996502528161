import { CustomComponent } from '../../../models/types/CustomComponent';
import { MainSelectPropType } from '../MainSelect/mainSelect.types';
import {
  Wrapper,
  Description,
  StyledMainSelect,
} from './descriptionSelect.styles';

interface IDescriptionSelectProps<T>
  extends CustomComponent,
    MainSelectPropType<T> {
  description: string;
}

const DescriptionSelect = <T,>({
  className,
  description,
  ...props
}: IDescriptionSelectProps<T>) => (
  <Wrapper className={className}>
    <Description>{description}</Description>
    <StyledMainSelect {...props} optionIcon={false} />
  </Wrapper>
);

export default DescriptionSelect;
