import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';

import { DefaultRowsPerPageOptions } from './materialTableTools.const';

import {
  useMaterialTableToolsValues,
  useMaterialTableToolsActions,
} from './materialTableTools.hooks';

interface IMaterialTablePaginationProps {
  itemsCount: number;
}

const MaterialTablePagination = ({
  itemsCount,
}: IMaterialTablePaginationProps) => {
  const { t } = useTranslation();

  const {
    pagination: { numberPerPage, currentPage },
  } = useMaterialTableToolsValues();

  const {
    pagination: { rowsPerPageChangeHandler, pageChangeHandler },
  } = useMaterialTableToolsActions();

  return (
    <TablePagination
      component="div"
      count={itemsCount || 0}
      labelRowsPerPage={t('phrase.rows-per-page')}
      onPageChange={(evt, newPage) => pageChangeHandler(newPage)}
      onRowsPerPageChange={(evt) => rowsPerPageChangeHandler(evt.target.value)}
      page={currentPage}
      rowsPerPage={numberPerPage || DefaultRowsPerPageOptions[0]}
      rowsPerPageOptions={DefaultRowsPerPageOptions}
    />
  );
};

export default MaterialTablePagination;
