import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { IPromoCodeInfo } from '../../../../models/PromoCodeInfo';
import {
  QueryParamItem,
  prepareQueryParams,
} from '../../../../utils/validations/queryParamsHelpers';
import { IOrganizationSearchResult } from '../../organizations/api';
import { IPeopleSearchResult } from '../../people/api';

export interface IStandardPricingQueryResponse {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

export const searchSROrganization = (
  organizationName?: string,
  organizationId?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSearchResult>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'organizationName',
      value: organizationName,
    },
    {
      name: 'organizationId',
      value: organizationId,
    },
  ];
  const params = prepareQueryParams({ queryParams });

  return axios.get(`/api/organizations/search${params}`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });
};

export const searchSRPerson = (
  email: string,
): Promise<AxiosResponse<IPeopleSearchResult>> =>
  axios.get(`/api/people/search?email=${encodeURIComponent(email)}`, {
    baseURL: config.securityResponseApi.baseUrl,
  });

export const searchSRPromoCodesInfo = (
  value: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IPromoCodeInfo>> =>
  axios.get(`/api/promocodes/${value}/info`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });

export const getSRCountries = (): Promise<AxiosResponse<string[]>> =>
  axios.get(`/api/countries`, {
    baseURL: config.securityResponseApi.baseUrl,
  });
