import { useTranslation } from 'react-i18next';

import { useFormSubmit } from '../../../hooks/useFormSubmit';
import { CustomFormSubmit } from '../../../models/types/CustomFormSubmit';
import { Wrapper, StyledFormSubmitButton } from './formSubmitButtons.styles';

type FormSubmitButtonsProps = CustomFormSubmit & {
  isProcessing?: boolean;
  saveLabel?: string;
  onlyDraftTooltip?: string;
  onlyDraft?: boolean;
};

const FormSubmitButtons = ({
  handleSubmit,
  canSaveDraft,
  isProcessing,
  saveLabel,
  onlyDraftTooltip,
  onlyDraft,
}: FormSubmitButtonsProps) => {
  const { t } = useTranslation();
  const { submitDraft, submitSave, isDraft } = useFormSubmit({
    handleSubmit,
    canSaveDraft,
  });

  return (
    <Wrapper>
      <StyledFormSubmitButton
        isLoading={!isDraft && isProcessing}
        disabled={(isDraft && isProcessing) || onlyDraft}
        onClick={submitSave}
        tooltip={onlyDraft && onlyDraftTooltip}
      >
        {saveLabel || t('buttons.save')}
      </StyledFormSubmitButton>
      {canSaveDraft && (
        <StyledFormSubmitButton
          isLoading={isDraft && isProcessing}
          disabled={!isDraft && isProcessing}
          styleType="light-border"
          onClick={submitDraft}
        >
          {t('buttons.save-draft')}
        </StyledFormSubmitButton>
      )}
    </Wrapper>
  );
};

export default FormSubmitButtons;
