import styled from '@emotion/styled/macro';

import FormCheckbox from '../../UI/Form/FormCheckbox/FormCheckbox';

export const StyledFormCheckbox = styled(FormCheckbox)`
  margin-top: 2rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
