/* eslint-disable react/no-array-index-key */
import { forwardRef } from 'react';

import { TableCellTooltip } from '../../TableContent/tableContent.styles';

import { ICellRenderer } from '../cellRenderers.models';
import {
  Circle,
  SubscriptionsShortcut,
  SubscriptionsShortcuts,
  SubscriptionsWrapper,
} from './productsOverviewRenderer.styles';
import { prepareProductsTooltip } from './productsOverviewRenderer.utils';
import { IProductMembershipInfo } from '../../../../models/IProductMembershipInfo';
import TableCell from '../TableCell/TableCell';

interface IProductsOverviewRenderer extends ICellRenderer {
  data?: IProductMembershipInfo[];
}

export const ProductsOverviewRenderer = forwardRef(
  ({ options, data, ...props }: IProductsOverviewRenderer, ref) => {
    let content = null;
    if (data) {
      content = (
        <TableCellTooltip title={prepareProductsTooltip(data)}>
          <SubscriptionsShortcuts>
            {data.map((item: any, key: any) => (
              <SubscriptionsShortcut key={key} productType={item.product}>
                {item.product}
                {item.isOverSubscribed && <Circle />}
              </SubscriptionsShortcut>
            ))}
          </SubscriptionsShortcuts>
        </TableCellTooltip>
      );
    }

    return (
      <TableCell
        {...props}
        ref={ref as any}
        bold={options?.textBold}
        alignment={options?.alignment}
      >
        <SubscriptionsWrapper>{content}</SubscriptionsWrapper>
      </TableCell>
    );
  },
);
