import { RenewStatus } from '../../../../models/RenewStatus';
import { Status } from '../../../UI/Status/Status';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';

interface IRenewStatusRenderer extends ICellRenderer {
  status?: boolean;
}

export const RenewStatusRenderer = ({
  status,
  options,
}: IRenewStatusRenderer) => {
  const currentStatus: RenewStatus = status ? RenewStatus.Yes : RenewStatus.No;

  return (
    <TableCell bold={options?.textBold} alignment={options?.alignment}>
      <Status status={currentStatus} enumName="renew-status" />
    </TableCell>
  );
};
