import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { putResolve } from '@redux-saga/core/effects';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  DELETE_MEMBERSHIP_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
  MEMBERSHIP_CREATING_PROCESSING,
  MEMBERSHIP_SUMMARY_LOADING_PROCESSING,
  MEMBERS_LOADING_PROCESSING,
  NEW_MEMBERSHIP_DATES_LOAD_PROCESSING,
  RESEND_WELCOME_EMAIL_PROCESSING,
  SECONDARY_MEMBER_ADDING_PROCESSING,
  SECONDARY_MEMBER_DELETE_PROCESSING,
  UPDATE_MEMBERSHIP_OPTIONS_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  getDOCExistingMembershipSummary,
  getDOCMembershipSummary,
  createDOCOrganizationMembership,
  getDatesForNewDOCMemberships,
  getDOCMembershipDetails,
  putDOCMemberToBeRenewed,
  updateDOCMembershipOptionsRequest,
  addDOCSecondaryMemberRequest,
  deleteDOCSecondaryMemberRequest,
  postResendDOCWelcomeEmail,
  deleteDOCMembershipRequest,
  getDOCMembershipsMembers,
} from './api';
import { Log } from '../../../../utils/logger';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';

import { prepareCreateDOCOrganizationMembershipRequestData } from '../common/utils';
import {
  setMembership,
  setMembershipSummary,
} from '../../../redux-slices/membership/common';
import {
  IAddSecondaryDOCMemberPayload,
  IChangeDOCMemberRenewFlagPayload,
  IDeleteDOCMembershipPayload,
  IDeleteSecondaryDOCMemberPayload,
  ILoadExistingDOCMembershipSummaryPayload,
  ILoadDOCMembershipDetailsPayload,
  ILoadDOCMembershipSummaryPayload,
  ILoadDOCMembersPayload,
  ILoadNewDOCMembershipDatesPayload,
  IResendDOCWelcomeEmail,
  ISubmitCreateDOCMembershipPayload,
  IUpdateDOCMembershipOptionsPayload,
} from './models';
import {
  setDOCMembers,
  setDOCMembershipDates,
  setDOCMembershipDetails,
  setDOCMembershipSummary,
} from '../../../redux-slices/membership/dutyOfCare';
import { removePersonMembership } from '../../../redux-slices/people';

const docMembershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateDOCMembership({
      payload: { data, success, error },
    }: PayloadAction<ISubmitCreateDOCMembershipPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
        yield putResolve(setMembership(data));

        const {
          data: requestData,
          organizationId,
          subscriptionId,
        } = yield* call(prepareCreateDOCOrganizationMembershipRequestData);
        yield call(
          createDOCOrganizationMembership,
          organizationId,
          subscriptionId,
          requestData,
        );

        success?.();
      } catch (err: any) {
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
      }
    },
    *loadDOCMembershipSummary({
      payload: {
        organizationId,
        subscriptionId,
        success,
        error,
        cancellationToken,
      },
    }: PayloadAction<ILoadDOCMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getDOCMembershipSummary,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield put(setMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadExistingDOCMembershipSummary({
      payload: { membershipId, success, error },
    }: PayloadAction<ILoadExistingDOCMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getDOCExistingMembershipSummary,
          membershipId,
        );
        yield put(setDOCMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadNewDOCMembershipDates({
      payload: { subscriptionId, cancellationToken, success, error },
    }: PayloadAction<ILoadNewDOCMembershipDatesPayload>) {
      try {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
        const response = yield* call(
          getDatesForNewDOCMemberships,
          subscriptionId,
          cancellationToken,
        );
        yield put(setDOCMembershipDates(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
      }
    },
    *loadDOCMembershipDetails({
      payload: { success, error, membershipId },
    }: PayloadAction<ILoadDOCMembershipDetailsPayload>) {
      try {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
        const response = yield* call(getDOCMembershipDetails, membershipId);
        yield put(setDOCMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
      }
    },
    *changeDOCMemberRenewFlag({
      payload: { membershipId, memberId, toBeRenewed, success, error },
    }: PayloadAction<IChangeDOCMemberRenewFlagPayload>) {
      try {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
        yield call(
          putDOCMemberToBeRenewed,
          membershipId,
          memberId,
          toBeRenewed,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
      }
    },
    *updateDOCMembershipOptions({
      payload: { membershipId, cancellationToken, options, success, error },
    }: PayloadAction<IUpdateDOCMembershipOptionsPayload>) {
      try {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
        const response = yield* call(
          updateDOCMembershipOptionsRequest,
          membershipId,
          options,
          cancellationToken,
        );
        yield put(setDOCMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
      }
    },
    *addSecondaryDOCMember({
      payload: { success, error, data, membershipId },
    }: PayloadAction<IAddSecondaryDOCMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
        yield call(addDOCSecondaryMemberRequest, membershipId, data);

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
      }
    },
    *deleteSecondaryDOCMember({
      payload: { membershipId, memberId, success, error, cancellationToken },
    }: PayloadAction<IDeleteSecondaryDOCMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
        yield call(
          deleteDOCSecondaryMemberRequest,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
      }
    },
    *resendDOCWelcomeEmail({
      payload: { cancellationToken, membershipId, data, success, error },
    }: PayloadAction<IResendDOCWelcomeEmail>) {
      try {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
        yield call(
          postResendDOCWelcomeEmail,
          membershipId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
      }
    },
    *deleteDOCMembership({
      payload: { membershipId, cancellationToken, isActive, success, error },
    }: PayloadAction<IDeleteDOCMembershipPayload>) {
      try {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
        yield call(
          deleteDOCMembershipRequest,
          membershipId,
          isActive,
          cancellationToken,
        );

        yield put(removePersonMembership({ membershipId }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
      }
    },
    *loadDOCMembers({
      payload: { success, error, params, organizationId },
    }: PayloadAction<ILoadDOCMembersPayload>) {
      try {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
        const response = yield* call(
          getDOCMembershipsMembers,
          organizationId,
          params,
        );

        yield put(setDOCMembers(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
      }
    },
  },
  name: 'doc-members-saga',
  sagaType: SagaType.TakeLatest,
});

export default docMembershipSlice.saga;
export const {
  submitCreateDOCMembership,
  loadExistingDOCMembershipSummary,
  loadDOCMembershipSummary,
  loadNewDOCMembershipDates,
  loadDOCMembershipDetails,
  changeDOCMemberRenewFlag,
  updateDOCMembershipOptions,
  addSecondaryDOCMember,
  deleteSecondaryDOCMember,
  resendDOCWelcomeEmail,
  deleteDOCMembership,
  loadDOCMembers,
} = docMembershipSlice.actions;
export const { actions } = docMembershipSlice;
