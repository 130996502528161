import Spinner from '../../../../../styledComponents/Spinner/Spinner';
import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';
import { Line } from '../../../../../styledComponents/UI/Line/Line';
import BasicMembershipForm from '../MembershipSectionsForms/BasicMembershipForm/BasicMembershipForm';
import { useMembershipItemForm } from './membershipItemForm.hooks';
import { MembershipItemBaseProps } from './membershipItemForm.models';
import { Content, StyledCollapseItem } from './membershipItemForm.styles';
import {
  getMembershipTitle,
  MembershipTypeContent,
} from './membershipItemForm.utils';

type SubscriptionItemFormProps = MembershipItemBaseProps & {
  number?: number;
  collapsible?: boolean;
  disabledProductType?: boolean;
};

const AddMembershipItemForm = ({
  number,
  name,
  disabled,
  collapsible = true,
  values,
  disabledProductType,
  isProcessing,
  isRenewalMode,
}: SubscriptionItemFormProps) => {
  const { productType, productTypeChangedHandler, spinnerVisible } =
    useMembershipItemForm(name);

  const CurrentComponent = MembershipTypeContent[productType];

  let content = CurrentComponent && (
    <>
      <Line />
      <CurrentComponent
        disabled={disabled}
        name={name}
        values={values}
        isProcessing={isProcessing}
        isRenewalMode={isRenewalMode}
      />
    </>
  );

  if (spinnerVisible && !isRenewalMode) {
    content = <Spinner />;
  }
  return (
    <StyledCollapseItem
      defaultOpen
      collapseButtonHidden={!collapsible}
      disabled={disabled || isProcessing}
      title={
        <SectionTitle>{getMembershipTitle(productType, number)}</SectionTitle>
      }
    >
      <Content>
        <BasicMembershipForm
          disabled={disabled || disabledProductType || isProcessing}
          name={name}
          onProductTypeChanged={productTypeChangedHandler}
        />
        {content}
      </Content>
    </StyledCollapseItem>
  );
};

export default AddMembershipItemForm;
