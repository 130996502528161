import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  DetailsWrapper,
  StyledDeleteButton,
  StyledLeftContainer,
  StyledRightContainer,
} from '../../Common/memberOverviewMembers.styles';
import {
  canEditAAMembershipsOptionsPermissions,
  canRemoveAAMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { AirAmbulanceMembershipOptions } from '../../../../models/enums/AirAmbulanceMembershipOptions';
import {
  useAAMembershipDetails,
  useAAMembershipRenew,
} from '../Common/aaMembershipOverview.hooks';
import { ProductType } from '../../../../models/ProductType';
import DeleteAAMembershipModal from '../Modals/DeleteAAMembershipModal/DeleteAAMembershipModal';
import { aaOverviewTablePermissions } from '../Common/aaMembershipOverview.const';
import EditAAMembershipOptionsModal from '../Modals/EditAAMembershipOptionsModal/EditAAMembershipOptionsModal';

interface AAMembershipExpiredDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const AAMembershipExpiredDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: AAMembershipExpiredDetailsProps) => {
  const { t } = useTranslation();
  const {
    dataLoading,
    membershipDetails,
    changeRenewFlagHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useAAMembershipDetails(membershipId, productType);

  const { renewMembershipHandler } = useAAMembershipRenew({
    membershipId,
    productType,
  });

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }

  return (
    <>
      <EditAAMembershipOptionsModal id={membershipId} />
      <DetailsWrapper>
        <DeleteAAMembershipModal id={membershipId} />
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            isExpiredMembershipsTable
            onRowClick={onRowClickHandler}
            permissions={aaOverviewTablePermissions}
          />
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditAAMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: AirAmbulanceMembershipOptions.SendWelcomeEmail },
              { value: AirAmbulanceMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            onRenewClick={renewMembershipHandler}
            canBeRenewed={membershipDetails?.metadata?.canRenew}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveAAMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default AAMembershipExpiredDetails;
