import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

import SearchInputTextField from './SearchInputTextField/SearchInputTextField';
import { useSearchInput } from './searchInput.hooks';
import { FieldWrapper } from '../Field/Field';
import { ISearchInputProps } from './searchInput.models';

const SearchInput = <T,>({
  className,
  disabled,
  onChange,
  placeholder,
  promiseOptions,
  isLabelHidden,
  label,
  labelAnimation,
  value,
  isError,
  onBlur,
}: ISearchInputProps<T>) => {
  const { isLoading, onBlurHandler, onChangeInput, onChangeOption, options } =
    useSearchInput({ onChange, promiseOptions });
  return (
    <FieldWrapper className={className}>
      <Autocomplete
        freeSolo
        disabled={disabled}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        loading={isLoading}
        onBlur={onBlurHandler}
        onChange={onChangeOption}
        options={options}
        autoHighlight
        renderInput={(params: AutocompleteRenderInputParams) => (
          <SearchInputTextField
            {...params}
            disabled={disabled}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={onChangeInput}
            isLabelHidden={isLabelHidden}
            label={label}
            labelAnimation={labelAnimation}
            isError={isError}
          />
        )}
        value={value}
      />
    </FieldWrapper>
  );
};

export default SearchInput;
