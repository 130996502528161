export const removeEmptyObjectFromArrays = (
  data: any,
  ...omittingProperties: string[]
) => {
  let result = {};

  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    result = clearArray(data, omittingProperties);
  } else if (typeof data === 'object' && data !== null) {
    result = exploreObject(data, omittingProperties);
  }
  return result;
};

const clearArray = (data: any, omittingProperties: string[] = []): any => {
  const dataFiltered = data.filter(
    (x: any) =>
      JSON.stringify(x) !== '{}' &&
      !(
        Object.keys(x).length === 1 &&
        omittingProperties.includes(Object.keys(x)[0])
      ),
  );
  const result: any[] = [];

  dataFiltered.forEach((element: any) => {
    if (typeof element === 'object') {
      result.push(exploreObject(element, omittingProperties));
    } else {
      result.push(element);
    }
  });

  return result;
};

const exploreObject = (data: any, omittingProperties: string[] = []) => {
  const dataArray = Object.keys(data);
  const result = {} as any;
  dataArray.forEach((key: any) => {
    if (Array.isArray(data[key])) {
      result[key] = clearArray(data[key], omittingProperties);
    } else {
      result[key] =
        typeof data[key] === 'object' ? { ...data[key] } : data[key];
    }
  });
  return result;
};
