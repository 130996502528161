import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as CheckMark } from '../../../assets/icons/Tick.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CheckboxContainer = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
`;

export const NativeCheckbox = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1;
  margin: 0;
`;
type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
};

export const VisibleCheckbox = styled.div<CheckboxProps>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.input_border};
  border-radius: 0.25em;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.2s ease;
  fill: ${({ theme }) => theme.color.white};

  background-color: ${({ theme, checked }) =>
    checked && theme.color.secondary1};

  ${({ disabled, checked, theme }) =>
    disabled &&
    css`
      border: 1px solid ${theme.color.dark2};
      background-color: ${theme.color.dark2};
      ${!checked &&
      css`
        background-color: ${theme.color.white};
        border: 1px solid ${theme.color.secondary2};
        fill: ${theme.color.dark2};
      `};
    `}
`;

export const StyledCheckMark = styled(CheckMark)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

type CheckboxLabelProps = {
  disabled?: boolean;
  checked?: boolean;
};

export const Label = styled.label<CheckboxLabelProps>`
  margin-left: 0.5rem;
  color: ${({ theme, checked }) =>
    checked ? theme.color.black : theme.color.dark1};
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}
`;
