import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call } from 'typed-redux-saga/macro';
import { put } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../redux-slices/processes';
import { EMPLOYEES_LOADING_PROCESSING } from '../../redux-slices/processes/constants';

import { Log } from '../../../utils/logger';
import { setEmployees } from '../../redux-slices/employees';
import { getEmployeeList, IEmployeesResponse } from './api';

const employeesSlice = createSliceSaga({
  caseSagas: {
    *loadEmployees() {
      try {
        yield put(updateProcessState(EMPLOYEES_LOADING_PROCESSING));
        const response: AxiosResponse<IEmployeesResponse> = yield* call(
          getEmployeeList,
        );
        yield put(setEmployees(response.data.items));
      } catch (err: any) {
        Log.error(err);
      } finally {
        yield put(updateProcessState(EMPLOYEES_LOADING_PROCESSING));
      }
    },
  },
  name: 'employees-saga',
  sagaType: SagaType.TakeLatest,
});

export default employeesSlice.saga;
export const { loadEmployees } = employeesSlice.actions;
export const { actions } = employeesSlice;
