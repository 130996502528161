import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { ProductType } from '../../../../../models/ProductType';
import { subscriptionDatesSelector } from '../../../../../store/redux-slices/subscriptions/common/selectors';
import { getFormGroupPath } from '../../../../../utils/getFormGroupPath';
import { SubscriptionRenewDetailsCommonFormFields } from './subscriptionRenewDetailsCommonForm.models';

type UseSubscriptionRenewDetailsCommonType = {
  productType?: ProductType;
  name?: string;
};

export const useSubscriptionRenewDetailsCommon = ({
  name,
  productType,
}: UseSubscriptionRenewDetailsCommonType) => {
  const subscriptionDates = useSelector(subscriptionDatesSelector(productType));

  const {
    input: { onChange: onEffectiveDateFromChange },
  } = useField(
    getFormGroupPath(
      name,
      SubscriptionRenewDetailsCommonFormFields.EffectiveDateFrom,
    ),
  );
  const {
    input: { onChange: onEffectiveDateToChange },
  } = useField(
    getFormGroupPath(
      name,
      SubscriptionRenewDetailsCommonFormFields.EffectiveDateTo,
    ),
  );

  useEffect(() => {
    if (!subscriptionDates) {
      return;
    }
    onEffectiveDateFromChange(subscriptionDates?.effectiveDateFrom);
    onEffectiveDateToChange(subscriptionDates?.effectiveDateTo);
  }, [onEffectiveDateFromChange, onEffectiveDateToChange, subscriptionDates]);
};
