/* eslint-disable react/destructuring-assignment */
import { IRenewalSubscriptionRecord } from '../../../../../models/interfaces/RenewalFilters/RenewalSubscriptionsRecord';
import {
  DateRenderer,
  ProductsRenderer,
} from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { SingleItemWithNumberRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/SingleItemWithNumberRenderer/SingleItemWithNumberRenderer';
import {
  StyledEditableStatusRenderer,
  StyledNameRenderer,
  StyledNameRendererLong,
} from './renewalsSubscriptionsTable.styles';

export const renewalSubscriptionsTableCellRenderer =
  (key: keyof IRenewalSubscriptionRecord, options?: ICellOptions) =>
  (dataRow: IRenewalSubscriptionRecord) => {
    switch (key) {
      case 'organizationName':
        return <StyledNameRenderer value={dataRow[key]} options={options} />;
      case 'contractName':
        return (
          <StyledNameRendererLong value={dataRow[key]} options={options} />
        );
      case 'productAbbreviation':
        return <ProductsRenderer data={[dataRow[key]]} options={options} />;
      case 'accountManagers':
        return (
          <SingleItemWithNumberRenderer
            items={dataRow[key]}
            options={options}
          />
        );
      case 'bdRepresentatives':
        return (
          <SingleItemWithNumberRenderer
            items={dataRow[key]}
            options={options}
          />
        );
      case 'expirationDate':
        return <DateRenderer date={dataRow[key]} options={options} />;
      case 'renewalStatus':
        return (
          <StyledEditableStatusRenderer
            enumName="renewal-status"
            status={dataRow[key]}
            options={options}
          />
        );
      default:
        return null;
    }
  };
