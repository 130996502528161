import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IDOCOrganizationSearch } from '../../../../models/interfaces/Organization/DutyOfCare/DOCOrganizationSearch';
import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { IPromoCodeInfo } from '../../../../models/PromoCodeInfo';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { IPeopleSearchResult } from '../../people/api';

export interface IStandardPricingQueryResponse {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

export interface IOrganizationSearchResult {
  organizations: IDOCOrganizationSearch[];
}

export const searchDOCOrganization = (
  organizationName?: string,
  organizationId?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSearchResult>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'organizationName',
      value: organizationName,
    },
    {
      name: 'organizationId',
      value: organizationId,
    },
  ];
  const params = prepareQueryParams({ queryParams });

  return axios.get(`/api/organizations/search${params}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });
};

export const searchDOCPerson = (
  email: string,
): Promise<AxiosResponse<IPeopleSearchResult>> =>
  axios.get(`/api/people/search?email=${encodeURIComponent(email)}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });

export const searchDOCPromoCodesInfo = (
  value: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IPromoCodeInfo>> =>
  axios.get(`/api/promocodes/${value}/info`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });

export const getDOCCountries = (): Promise<AxiosResponse<string[]>> =>
  axios.get(`/api/countries`, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });
