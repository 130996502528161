export const MembershipRetailDetailsFormName = 'membership-retail-details-form';

export enum MembershipRetailDetailsFormFields {
  RenewedMembershipId = 'renewedMembershipId',
  MembershipPlanId = 'membershipPlanId',
  PromoCode = 'promoCode',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
  SearchedPromoCode = 'searchedPromoCode',
}
