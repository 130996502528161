import { useCallback } from 'react';
import { GlobalGuardianPointOfContactRoles } from '../../../../models/enums/GlobalGuardianPointOfContactRoles';
import { RenewalStatus } from '../../../../models/enums/RenewalStatus';
import { IDateFilter } from '../../../../models/interfaces/RenewalFilters/DateFilters';
import { IRenewalsFiltersModel } from '../../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';
import { ISelectCategoryFilter } from '../../../../models/interfaces/RenewalFilters/SelectCategoryFilter';
import { ISelectFilter } from '../../../../models/interfaces/RenewalFilters/SelectFilter';
import { ProductType } from '../../../../models/ProductType';
import { RenewalApiFilters } from '../../../../models/types/RenewalFilters/RenewalApiFilter';
import { RenewalStaticFilter } from '../../Common/RenewalsFilters/StaticFilters/renewalStaticFilters.models';
import { RenewalContractsFields } from './renewalsContractsFilters.utils';
import { RenewalTopFilter } from '../../Common/RenewalsFilters/TopFilters/renewalTopFilters.models';

export const useRenewalsContractsFilters = (
  onFilterChanged?: (filters: IRenewalsFiltersModel) => void,
  onExportClick?: (filters: IRenewalsFiltersModel) => void,
) => {
  const prepareFilter = (
    filters: Record<
      RenewalContractsFields | RenewalStaticFilter | RenewalTopFilter,
      RenewalApiFilters
    >,
  ) => ({
    expirationDateFilters: filters.expirationDate as IDateFilter[],
    productFilters: (filters.product as ISelectFilter[]).map((x) => ({
      selectedProducts: x.selectedItems as ProductType[],
    })),
    renewalStatusFilters: (filters.renewalStatus as ISelectFilter[]).map(
      (x) => ({
        selectedRenewalStatuses: x.selectedItems as RenewalStatus[],
      }),
    ),
    gGPocRolePersonFilters: (filters.ggPocRole as ISelectCategoryFilter[]).map(
      (x) => ({
        selectedGGPocRole:
          x.selectedCategory as GlobalGuardianPointOfContactRoles,
        selectedPersons: x.selectedItems,
      }),
    ),
    hideExpired: filters.hideExpired as boolean,
    searchFilter: filters.searchFilter as string,
  });

  const handleFilterChanged = useCallback(
    (
      filters: Record<
        RenewalContractsFields | RenewalStaticFilter | RenewalTopFilter,
        RenewalApiFilters
      >,
    ) => {
      onFilterChanged?.(prepareFilter(filters));
    },
    [onFilterChanged],
  );

  const handleExportClick = (
    filters: Record<
      RenewalContractsFields | RenewalStaticFilter | RenewalTopFilter,
      RenewalApiFilters
    >,
  ) => {
    onExportClick?.(prepareFilter(filters));
  };

  return { handleFilterChanged, handleExportClick };
};
