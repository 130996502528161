import {
  SystemGroupPermissions,
  KidnapAndExtortionPermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canAddKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanCreateSubscription,
  ),
];

export const canRemoveKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanDeleteSubscription,
  ),
];

export const canEditKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanUpdateSubscription,
  ),
];

export const canAddSeatsKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanUpdateSubscription,
  ),
];

export const canViewKESubscriptionDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanViewSubscriptionDetails,
  ),
];

export const canUpdateKESubscriptionsDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanUpdateSubscription,
  ),
];

export const canRenewKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanCreateSubscription,
  ),
];

export const canExtendsKESubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanUpdateSubscription,
  ),
];

export const canRemoveKEMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanDeleteMembership,
  ),
];

export const canRenewKEMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanCreateMembership,
  ),
];

export const canResendKEWelcomeEmailPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanResendWelcomeEmail,
  ),
];

export const canManageKEMembershipsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanManageMemberships,
  ),
];

export const canEditKEMembershipsOptionsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanManageMemberships,
  ),
];

export const canChangeKEToBeRenewedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanManageMemberships,
  ),
];

export const canSetKEMembershipAlreadyPayedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanSetMembershipAlreadyPayed,
  ),
];

export const canAddKEMembershipFamilyMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanManageMemberships,
  ),
];

export const canAddKEMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanCreateMembership,
  ),
];

export const canViewKEMembersPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanSearchMembers,
  ),
];

export const canGenerateKERawDataReportPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanGenerateMembershipReport,
  ),
];
export const canAddKEMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanCreateMembership,
  ),
];

export const canRemoveKESecondaryMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.KidnapAndExtortion,
    KidnapAndExtortionPermissions.CanManageMemberships,
  ),
];
