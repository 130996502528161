import { MenuProps } from '@mui/material/Menu';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomPermissions } from '../../models/types/CustomPermissions';
import { useMenu } from './menu.hooks';
import { StyledMaterialMenuItem, StyledMenu } from './menu.styles';

export type MenuOption = CustomPermissions & {
  label?: string;
  canonicalName: string;
  disabled?: boolean;
};

type MenuPropsType = MenuProps & {
  options?: MenuOption[];
  anchorEl: Element;
  onElementClick?: (option: string, evt?: MouseEvent<HTMLElement>) => void;
};

const Menu = ({
  anchorEl,
  onElementClick,
  options = [],
  open,
  onClose,
}: MenuPropsType) => {
  const { t } = useTranslation();
  const { filteredOptions } = useMenu(options);
  return (
    <StyledMenu
      id="lock-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'lock-button',
        role: 'listbox',
      }}
    >
      {filteredOptions.map(
        (option) =>
          option.canonicalName && (
            <StyledMaterialMenuItem
              key={option.canonicalName}
              disabled={option.disabled}
              onClick={(evt) =>
                onElementClick && onElementClick(option.canonicalName, evt)
              }
            >
              {option.label
                ? option.label
                : t(`enums.actions.${option.canonicalName}`)}
            </StyledMaterialMenuItem>
          ),
      )}
    </StyledMenu>
  );
};

export default Menu;
