import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react';

import { Order } from '../../../models/Order';

import {
  useMaterialTablePagination,
  useMaterialTableSort,
} from './materialTableTools.hooks';

type MemberTableToolsInfoState = {
  sort: {
    orderBy: string;
    order: Order;
  };
  pagination: {
    numberPerPage: number;
    pageNumber: number;
    currentPage: number;
  };
};

type MemberTableToolsActionableState = {
  sort: {
    setOrder: Dispatch<SetStateAction<Order>>;
    setOrderBy: Dispatch<SetStateAction<string>>;
    handleRequestSort: (property: string) => void;
  };
  pagination: {
    setNumberPerPage: Dispatch<SetStateAction<number>>;
    setPageNumber: Dispatch<SetStateAction<number>>;
    rowsPerPageChangeHandler: (value: string) => void;
    pageChangeHandler: (newPage: number) => void;
  };
};

export const MemberTableToolsInfoContext =
  createContext<MemberTableToolsInfoState>({
    sort: null,
    pagination: null,
  });

export const MemberTableToolsActionableContext =
  createContext<MemberTableToolsActionableState>({
    pagination: null,
    sort: null,
  });

type Props = {
  children: ReactNode;
  itemsCount?: number;
  defaultColumnSort?: string;
  isPagination?: boolean;
};

const MemberTableToolsProvider = ({
  children,
  itemsCount,
  defaultColumnSort,
  isPagination,
}: Props) => {
  const { order, orderBy, setOrder, setOrderBy, handleRequestSort } =
    useMaterialTableSort({ defaultOrderBy: defaultColumnSort });

  const {
    numberPerPage,
    pageNumber,
    currentPage,
    setNumberPerPage,
    setPageNumber,
    pageChangeHandler,
    rowsPerPageChangeHandler,
  } = useMaterialTablePagination({ itemsCount });

  const actionableValue = {
    sort: {
      setOrder,
      setOrderBy,
      handleRequestSort,
    },
    pagination: isPagination && {
      setNumberPerPage,
      setPageNumber,
      rowsPerPageChangeHandler,
      pageChangeHandler,
    },
  };

  const infoValue = useMemo(
    () => ({
      sort: {
        orderBy,
        order,
      },
      pagination: isPagination && {
        numberPerPage,
        pageNumber,
        currentPage,
      },
    }),
    [orderBy, order, isPagination, numberPerPage, pageNumber, currentPage],
  );

  return (
    <MemberTableToolsInfoContext.Provider value={infoValue}>
      <MemberTableToolsActionableContext.Provider value={actionableValue}>
        {children}
      </MemberTableToolsActionableContext.Provider>
    </MemberTableToolsInfoContext.Provider>
  );
};

export default MemberTableToolsProvider;
