import { useTranslation } from 'react-i18next';

import { MembershipItemBaseProps } from '../../MembershipItemForm/membershipItemForm.models';
import DOCMembershipDetailsForm from './DOCMembershipDetailsForm/DOCMembershipDetailsForm';
import DOCMembershipOptionsForm from './DOCMembershipOptionsForm/DOCMembershipOptionsForm';
import { useDutyOfCareMembership } from './dutyOfCareMembership.hooks';
import { DOCSummaryLoader } from './DOCMembershipSummaryLoader/DOCMembershipSummaryLoader';

type DutyOfCareMembershipProps = MembershipItemBaseProps;

const DutyOfCareMembership = ({
  isProcessing,
  disabled,
}: DutyOfCareMembershipProps) => {
  const { t } = useTranslation();

  useDutyOfCareMembership();
  const isDisabled = disabled || isProcessing;
  return (
    <div>
      <DOCMembershipDetailsForm disabled={isDisabled} />
      <DOCMembershipOptionsForm
        title={t('headers.options')}
        disabled={isDisabled}
      />
      <DOCSummaryLoader />
    </div>
  );
};

export default DutyOfCareMembership;
