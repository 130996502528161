import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import i18next from '../../../i18n/i18n';
import { RoutePath } from '../../../models/enums/RoutePath';
import { clearState } from '../../globalActions';

export interface IWizardValidStep {
  canonicalName: WizardStepsCn;
  isValid: boolean;
}

export interface IWizardAvailableStep {
  canonicalName: WizardStepsCn;
  isAvailable: boolean;
}

export interface IWizard {
  activeStep: WizardStepsCn;
  steps: IWizardStep[];
}

export interface IWizardStep {
  canonicalName: WizardStepsCn;
  caption: string;
  isAuth: boolean;
  isAvailable: boolean;
  isValid: boolean;
  order: number;
  url: string;
}

export enum WizardStepsCn {
  MembershipPage = 'membership-page',
  PaymentPage = 'payment-page',
  PrimaryMemberPage = 'primary-member-page',
}

const initState: IWizard = {
  steps: [
    {
      order: 0,
      caption: i18next.t('wizard-steps.primary-member-page'),
      canonicalName: WizardStepsCn.PrimaryMemberPage,
      url: RoutePath.PrimaryMemberPage,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 1,
      caption: i18next.t('wizard-steps.membership-page'),
      canonicalName: WizardStepsCn.MembershipPage,
      url: RoutePath.MembershipPage,
      isValid: false,
      isAvailable: true,
      isAuth: false,
    },
    {
      order: 2,
      caption: i18next.t('wizard-steps.payment-page'),
      canonicalName: WizardStepsCn.PaymentPage,
      url: RoutePath.PaymentPage,
      isValid: false,
      isAvailable: false,
      isAuth: false,
    },
  ],
  activeStep: WizardStepsCn.PrimaryMemberPage,
};

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initState,
  reducers: {
    updateActiveStep: (state, action: PayloadAction<WizardStepsCn>) => {
      state.activeStep = action.payload;
    },
    updateValidSteps: (state, action: PayloadAction<IWizardValidStep[]>) => {
      action.payload.forEach((step) => {
        const index = state.steps.findIndex(
          (stateStep) => stateStep.canonicalName === step.canonicalName,
        );
        state.steps[index] = { ...state.steps[index], isValid: step.isValid };
      });
    },
    updateValidStep: (state, action: PayloadAction<IWizardValidStep>) => {
      const index = state.steps.findIndex(
        (step) => step.canonicalName === action.payload.canonicalName,
      );
      state.steps[index] = {
        ...state.steps[index],
        isValid: action.payload.isValid,
      };
    },
    updateAvailableSteps: (
      state,
      action: PayloadAction<IWizardAvailableStep[]>,
    ) => {
      action.payload.forEach((step) => {
        const index = state.steps.findIndex(
          (stateStep) => stateStep.canonicalName === step.canonicalName,
        );
        state.steps[index].isAvailable = step.isAvailable;
      });
    },
    resetWizard: (state) => {
      state.activeStep = initState.activeStep;
      state.steps.forEach((step) => {
        step.isValid = false;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearState, () => initState);
  },
});

export const {
  resetWizard,
  updateActiveStep,
  updateAvailableSteps,
  updateValidStep,
  updateValidSteps,
} = wizardSlice.actions;
export default wizardSlice.reducer;
export const wizardReducerName = wizardSlice.name;
