import aaSubscriptions from './airAmbulance';
import keSubscriptions from './kidnapAndExtortion';
import srSubscriptions from './securityResponse';
import docSubscriptions from './dutyOfCare';
import tlSubscriptions from './travelLicense';
import subscriptions from './common';

export const subscriptionsReducers = {
  aaSubscriptions,
  keSubscriptions,
  srSubscriptions,
  docSubscriptions,
  tlSubscriptions,
  subscriptions,
};
