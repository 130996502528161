import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { MemberType } from '../../../../models/enums/MemberType';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { ADD_AA_FAMILY_MEMBER_MODAL_NAME } from '../Modals/AddAAFamilyMemberModal/addAAFamilyMemberModal.const';
import { AddAAFamilyMemberModalDetails } from '../Modals/AddAAFamilyMemberModal/addAAFamilyMemberModal.models';

type UseAAMembershipActiveDetailsProps = {
  membershipId?: string;
  canAddMemberTypes?: MemberType[];
};

export const useAAMembershipActiveDetails = ({
  canAddMemberTypes,
  membershipId,
}: UseAAMembershipActiveDetailsProps) => {
  const UpdateModalState = useActions(updateModalState);

  const addFamilyMemberHandler = useCallback(() => {
    const details: AddAAFamilyMemberModalDetails = {
      membershipId,
      canAddMemberTypes,
    };
    UpdateModalState([ADD_AA_FAMILY_MEMBER_MODAL_NAME + membershipId, details]);
  }, [UpdateModalState, canAddMemberTypes, membershipId]);

  return {
    addFamilyMemberHandler,
  };
};
