import { useMemo } from 'react';

import { usePermissions } from '../../hooks/usePermissions';
import { MenuOption } from './Menu';

export const useMenu = (options: MenuOption[]) => {
  const { checkUserPermissions } = usePermissions();

  const filteredOptions = useMemo(
    () => options.filter((option) => checkUserPermissions(option.permissions)),
    [checkUserPermissions, options],
  );

  return { filteredOptions };
};
