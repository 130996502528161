import { t } from 'i18next';

import { OrganizationContractType } from '../../../../models/enums/ContractType';

import { SubscriptionOptionsType } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';
import { DutyOfCareSubscriptionOptions } from '../../../../models/enums/DutyOfCareSubscriptionOptions';

export const docSubscriptionOptions = (
  contractType?: OrganizationContractType,
): SubscriptionOptionsType[] =>
  contractType === OrganizationContractType.Multiple
    ? [
        {
          name: DutyOfCareSubscriptionOptions.AutoRenewal,
          label: t('fields.autoRenewSubscription.label'),
        },
      ]
    : [];
