import { DateFilterSingleType } from '../../../../../../../models/enums/RenewalFilters/DateFilterSingleType';
import { CustomFilter } from '../../../renewalFilters.models';

export enum DateFilterFields {
  Value = 'value',
  AdditionalValues = 'additionalValues',
  From = 'from',
  To = 'to',
  Dates = 'dates',
}

export type DateFilterAdditionalValuesModel = {
  from?: string;
  to?: string;
  dates?: DateFilterSingleType[];
};

export type DateFilterModel = CustomFilter<DateFilterAdditionalValuesModel>;
