import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import { CustomForm } from '../../../../models/types/CustomForm';
import { ICellOptions } from '../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';

export const TableFormName = 'extend-doc-subscription';

export enum TableFormColumns {
  packageType = 'packageType',
  seatsAmount = 'seatsAmount',
  totalPrice = 'totalPrice',
}

export type DOCPackageTableRow = {
  name?: string;
  currentValues?: string;
  newValues?: string;
  columnType: TableFormColumns;
};

export type ChangesColumnRendererProps = CustomForm & {
  columnType: TableFormColumns;
  options?: ICellOptions;
  data?: IDOCOrganizationSubscriptionExtensionData;
};
