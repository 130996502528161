import styled from '@emotion/styled';

import { FieldWrapper } from '../../../UI/Field/Field';

import { MainSelect } from '../../../UI/MainSelect/MainSelect';
import TableCell from '../TableCell/TableCell';

export const StyledMainSelect = styled(MainSelect)`
  .mainSelect__control {
    border: none;
  }
`;

export const StyledTableCell = styled(TableCell)`
  ${FieldWrapper} {
    min-width: 7.875rem;
    width: auto;
    height: auto;
  }
`;
