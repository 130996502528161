/* eslint-disable react/no-array-index-key */
import { forwardRef } from 'react';

import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';

import { IProductSubscriptionInfo } from '../../../../models/IProductSubscriptionInfo';

import { TableCellTooltip } from '../../TableContent/tableContent.styles';

import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';
import {
  Circle,
  SubscriptionsShortcut,
  SubscriptionsShortcuts,
  SubscriptionsWrapper,
} from './productsSubscriptionInfoRenderer.styles';
import { prepareProductsSubscriptionInfoTooltip } from './productsSubscriptionInfoRenderer.utils';

interface IProductsSubscriptionInfoRenderer extends ICellRenderer {
  data?: IProductSubscriptionInfo[];
}

export const ProductsSubscriptionInfoRenderer = forwardRef(
  ({ options, data, ...props }: IProductsSubscriptionInfoRenderer, ref) => {
    let content = null;

    if (data) {
      content = (
        <TableCellTooltip title={prepareProductsSubscriptionInfoTooltip(data)}>
          <SubscriptionsShortcuts>
            {data?.map((subscription, key) => (
              <SubscriptionsShortcut
                key={key}
                productType={ProductTypeShortcut[subscription.productType]}
              >
                {ProductTypeShortcut[subscription.productType]}
                {subscription.isOversubscribed && <Circle />}
              </SubscriptionsShortcut>
            ))}
          </SubscriptionsShortcuts>
        </TableCellTooltip>
      );
    }

    return (
      <TableCell
        {...props}
        ref={ref as any}
        bold={options?.textBold}
        alignment={options?.alignment}
      >
        <SubscriptionsWrapper>{content}</SubscriptionsWrapper>
      </TableCell>
    );
  },
);
