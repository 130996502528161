import { t } from 'i18next';

import { DutyOfCareMembershipOptions } from '../../../../../../../models/enums/DutyOfCareMembershipOptions';

import { IFormCheckboxGroupOptionProps } from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const DOCMembershipOptions: IFormCheckboxGroupOptionProps[] = [
  {
    name: DutyOfCareMembershipOptions.SendWelcomeEmail,
  },
  {
    name: DutyOfCareMembershipOptions.AutoRenewal,
    label: t('fields.renewWithSubscription.label'),
  },
];
