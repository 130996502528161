import { Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../utils/getFormGroupPath';
import { IAddress } from '../../../models/Address';
import {
  MemberTypeAgeRange,
  MemberTypeForAge,
} from './FamilyMemberBasicDetails/familyMemberBasicDetails.models';
import FamilyMemberBasicDetailsForm from './FamilyMemberBasicDetails/FamilyMemberBasicDetails';
import FamilyMemberFormMessage from './FamilyMemberMessage/FamilyMemberMessage';
import {
  ResidenceAddress,
  ResidenceAddressCheckbox,
  StyledFormMessage,
  Title,
} from './familyMember.styles';
import { useFamilyMember } from './familyMember.hooks';
import { FamilyMemberFields } from './familyMember.models';
import { MemberType } from '../../../models/enums/MemberType';
import { SectionWrapper } from '../../Form/Form';
import FormAddress from '../FormAddress/FormAddress';
import { CustomForm } from '../../../models/types/CustomForm';

interface IFamilyMemberFormProps extends CustomForm {
  onRemoveContact?: () => void;
  residenceAddress?: IAddress;
  titlesVisible?: boolean;
  dateOfBirth?: string;
  memberTypesAgeRanges?: Record<MemberTypeForAge, MemberTypeAgeRange>;
  onEditMember?: (memberId: string) => void;
  memberTypes?: MemberType[];
}

const FamilyMemberForm = ({
  className,
  disabled,
  name,
  onRemoveContact,
  residenceAddress,
  titlesVisible,
  dateOfBirth,
  memberTypesAgeRanges,
  onEditMember,
  memberTypes,
}: IFamilyMemberFormProps) => {
  const { t } = useTranslation();

  const { personId, addressIsSame, showResidenceAddress } = useFamilyMember({
    name,
    residenceAddress,
  });

  return (
    <SectionWrapper className={className}>
      {titlesVisible && <Title>{t('headers.details')}</Title>}
      <FamilyMemberBasicDetailsForm
        name={name}
        onRemoveContact={onRemoveContact}
        disabled={disabled}
        dateOfBirth={dateOfBirth}
        memberTypesAgeRanges={memberTypesAgeRanges}
        memberTypes={memberTypes}
      />
      {!personId && (
        <Field
          component={ResidenceAddressCheckbox}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: 'family-member-form',
            name: FamilyMemberFields.AddressSameAsPrimaryMemberResidenceAddress,
            groupName: name,
          })}
          type="checkbox"
        />
      )}
      {showResidenceAddress && (
        <ResidenceAddress>
          {titlesVisible && <Title>{t('headers.residence-address')}</Title>}
          <FormAddress
            disabled={disabled || !!personId}
            isGoogleSearchHidden
            isSubRowSpace
            name={getFormGroupPath(name, FamilyMemberFields.ResidenceAddress)}
          />
        </ResidenceAddress>
      )}
      {personId !== null && personId !== '' && (
        <StyledFormMessage
          message={
            <FamilyMemberFormMessage
              onEditMember={onEditMember ? () => onEditMember(personId) : null}
              addressIsSame={addressIsSame}
            />
          }
          status={addressIsSame ? 'success' : 'warning'}
        />
      )}
    </SectionWrapper>
  );
};

export default FamilyMemberForm;
