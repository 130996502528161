import { useCallback } from 'react';

import { useFilters } from '../../../../hooks/useFilters';
import { SelectOption } from '../../../../models/SelectOption';
import { CorporateClientFiltersType } from './corporateClientGeneralFilters.models';

export const useCorporateClientGeneralFilters = () => {
  const { addFilter, filters } = useFilters<CorporateClientFiltersType>();

  const onFilterChangeHandler = useCallback(
    (evt: any, filterType: CorporateClientFiltersType) => {
      switch (filterType) {
        case 'search':
          addFilter('search', evt.target.value);
          return;
        case 'status':
          addFilter(
            'status',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case 'product':
          addFilter(
            'product',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case 'type':
          addFilter('type', evt ? evt.map((x: SelectOption) => x.value) : null);
      }
    },
    [addFilter],
  );

  return {
    onFilterChangeHandler,
    filters,
  };
};
