import { CustomForm } from '../../../../../../../models/types/CustomForm';
import {
  FormSectionTitle,
  SectionWrapper,
} from '../../../../../../../styledComponents/Form/Form';

import FormCheckboxGroup from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import { FormColumn } from '../../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { SRMembershipOptions } from './srMembershipOptionsForm.const';
import { useSRMembershipOptions } from './srMembershipOptionsForm.hooks';

interface IMembershipOptionsFormProps extends CustomForm {
  title?: string;
}

const SRMembershipOptionsForm = ({
  className,
  disabled,
  title,
}: IMembershipOptionsFormProps) => {
  useSRMembershipOptions();
  return (
    <SectionWrapper className={className}>
      {title && <FormSectionTitle>{title}</FormSectionTitle>}
      <FormColumn>
        <FormCheckboxGroup
          layout="column"
          disabled={disabled}
          options={SRMembershipOptions}
        />
      </FormColumn>
    </SectionWrapper>
  );
};

export default SRMembershipOptionsForm;
