import { ShortcutProductType } from '../enums/ShortcutProductType';
import { ProductType } from '../ProductType';

export const ProductTypeToShortcut: Partial<
  Record<ProductType, ShortcutProductType>
> = {
  [ProductType.AirAmbulance]: ShortcutProductType.AA,
  [ProductType.DutyOfCare]: ShortcutProductType.DoC,
  [ProductType.KidnapAndExtortion]: ShortcutProductType.KE,
  [ProductType.SecurityResponse]: ShortcutProductType.SR,
  [ProductType.TravelLicense]: ShortcutProductType.TL,
};
