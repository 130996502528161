import { Route, Routes } from 'react-router';

import {
  CanViewContractsRenewalsPagePermissions,
  CanViewSubscriptionsRenewalsPagePermissions,
} from '../../config/accessPolicies/accessPolicies';

import { RoutePath } from '../../models/enums/RoutePath';
import AuthRoute from '../../styledComponents/AuthRoute/AuthRoute';

import RenewalsContractsPage from './RenewalsContractsPage/RenewalsContractsPage';
import RenewalsSubscriptionsPage from './RenewalsSubscriptionsPage/RenewalsSubscriptionsPage';

const RenewalsPageRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.RenewalsContracts}
      element={
        <AuthRoute permissions={CanViewContractsRenewalsPagePermissions}>
          <RenewalsContractsPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.RenewalsSubscriptions}
      element={
        <AuthRoute permissions={CanViewSubscriptionsRenewalsPagePermissions}>
          <RenewalsSubscriptionsPage />
        </AuthRoute>
      }
    />
  </Routes>
);

export default RenewalsPageRoutes;
