import { createSelector } from 'reselect';

import { isPropertyEmpty } from '../../../utils/propertyHelpers';

import { RootState } from '../index';
import { blackListPaths, mainPagesPaths } from './const';
import { RouteHistory, RoutesHistoryState } from './models';

export const routesHistorySelector = (state: RootState) => state.routesHistory;

export const currentRouteSelector = createSelector(
  routesHistorySelector,
  (routesHistory: RoutesHistoryState): string => {
    if (routesHistory.routes.length > 0) {
      return routesHistory.routes[0].pathName + routesHistory.routes[0].search;
    }
    return null;
  },
);

export const availableListRoutesSelector = createSelector(
  routesHistorySelector,
  currentRouteSelector,
  (routesHistory: RoutesHistoryState): RouteHistory[] => {
    const routes = routesHistory.routes.filter(
      (x) => !blackListPaths.includes(x.pathName),
    );

    return routes;
  },
);

export const lastRouteSelector = (indexOffset = 1) =>
  createSelector(
    availableListRoutesSelector,
    currentRouteSelector,
    (filteredRoutes: RouteHistory[], currentPath: string): string => {
      const routes = filteredRoutes.filter(
        (x) => x.pathName + x.search !== currentPath,
      );
      if (routes.length < 1 || routes.length < indexOffset) {
        return null;
      }

      return routes[indexOffset - 1].pathName + routes[indexOffset - 1].search;
    },
  );

export const lastMainPageRouteSelector = createSelector(
  availableListRoutesSelector,
  currentRouteSelector,
  (filteredRoutes: RouteHistory[], currentPath: string): string => {
    const currentRegex = mainPagesPaths.findIndex((x) =>
      currentPath.match(new RegExp(x)),
    );

    const regex = mainPagesPaths[currentRegex];

    let currentRoute = null;

    for (let index = 0; index < filteredRoutes.length; index++) {
      const routeItem = filteredRoutes[index];
      if (!routeItem.pathName.match(`${regex}`)) {
        currentRoute = routeItem.pathName + routeItem.search;
        break;
      }
    }

    return currentRoute;
  },
);

export const isLastMainPageRouteSelector = createSelector(
  lastMainPageRouteSelector,
  (route: string): boolean => !isPropertyEmpty(route),
);
