import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'typed-redux-saga/macro';
import { putResolve } from 'redux-saga/effects';

import { Log } from '../../../utils/logger';
import {
  loadAirAmbulancePermissions,
  loadDutyOfCarePermissions,
  loadKidnapAndExtortionPermissions,
  loadPaymentPermissions,
  loadPeopleManagementPermissions,
  loadReportsPermissions,
  loadSecurityResponsePermissions,
  loadTravelLicensePermissions,
} from './utils';
import { ICallbackSaga } from '../../../models/ICallbackSaga';
import { ICancellationTokenSaga } from '../../../models/interfaces/CancellationTokenSaga';
import {
  setUserPermissions,
  setUserPermissionsLoaded,
} from '../../redux-slices/auth';

const authSlice = createSliceSaga({
  caseSagas: {
    *loadUserPermissions({
      payload: { success, error, cancellationToken } = {},
    }: PayloadAction<ICallbackSaga & ICancellationTokenSaga>) {
      try {
        const airAmbulancePermissions: any = yield* call(
          loadAirAmbulancePermissions,
          cancellationToken,
        );
        const kidnapAndExtortionPermissions: any = yield* call(
          loadKidnapAndExtortionPermissions,
          cancellationToken,
        );

        const securityResponsePermissions: any = yield* call(
          loadSecurityResponsePermissions,
          cancellationToken,
        );

        const dutyOfCarePermissions: any = yield* call(
          loadDutyOfCarePermissions,
          cancellationToken,
        );

        const travelLicensePermissions: any = yield* call(
          loadTravelLicensePermissions,
          cancellationToken,
        );

        const peopleManagementPermissions: any = yield* call(
          loadPeopleManagementPermissions,
          cancellationToken,
        );

        const paymentPermissions: any = yield* call(
          loadPaymentPermissions,
          cancellationToken,
        );

        const reportsPermissions: any = yield* call(
          loadReportsPermissions,
          cancellationToken,
        );

        yield putResolve(
          setUserPermissions([
            ...airAmbulancePermissions,
            ...kidnapAndExtortionPermissions,
            ...securityResponsePermissions,
            ...dutyOfCarePermissions,
            ...travelLicensePermissions,
            ...peopleManagementPermissions,
            ...paymentPermissions,
            ...reportsPermissions,
          ]),
        );
        yield putResolve(setUserPermissionsLoaded(true));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      }
    },
  },
  name: 'plans-saga',
  sagaType: SagaType.TakeLatest,
});

export default authSlice.saga;
export const { loadUserPermissions } = authSlice.actions;
export const { actions } = authSlice;
