import { AvailableQueryParams } from '../../../../../styledComponents/MaterialTable/materialTable.models';
import { SRMembersListFilters } from '../../../../../styledComponents/ProductMembersList/SRMembersList/SRMembersFilters/srMembersFilters.models';

export const pageFilters = [
  SRMembersListFilters.Search,
  SRMembersListFilters.Plan,
  SRMembersListFilters.MemberType,
  SRMembersListFilters.RenewStatus,
  SRMembersListFilters.SubscriptionId,
  SRMembersListFilters.OversubscribedOnly,
];

export const RequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];
