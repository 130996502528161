/* eslint-disable react/destructuring-assignment */
import { t } from 'i18next';

import { Total, Seats } from './tlSubscriptionOverviewPricingTable.styles';

export const getSummaryRow = (totalSeatsAmount: number) => {
  if (!totalSeatsAmount) {
    return null;
  }

  return {
    price: <Total>{`${t('phrase.total')}`}</Total>,
    seatsAmount: <Seats>{totalSeatsAmount}</Seats>,
  };
};
