import { AnySchema } from 'ajv';

import { FieldErrorMessage } from 'models/FieldErrorMessage';

import { IGetRetailDataReport } from '../../../../../models/interfaces/GetRetailDataReport';

import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { RetailDataReportFields } from './retailDataReportsForm.models';

export const reportsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [RetailDataReportFields.Date]: {
      type: 'string',
    },
    [RetailDataReportFields.ReportType]: {
      type: 'string',
    },
  },
  required: [RetailDataReportFields.Date, RetailDataReportFields.ReportType],
};

export const validateRetailReportSchemaForm = (
  data: IGetRetailDataReport,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, reportsSchema);
