import styled from '@emotion/styled/macro';

export const FiltersWrapper = styled.div`
  padding: 2.5rem 2.5rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.light1};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
