import { useCallback, MouseEvent, forwardRef } from 'react';

import { Alignment } from '../../../../models/Alignment';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import { TableCellWrapper } from './tableCell.styles';

export type TableCellProps = CustomComponent & {
  bold?: boolean;
  alignment?: Alignment;
  minWidth?: boolean;
  onClick?: () => void;
};

const TableCell = forwardRef(
  ({ children, onClick, bold, className, ...props }: TableCellProps, ref) => {
    const onClickHandler = useCallback(
      (evt: MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();
        onClick?.();
      },
      [onClick],
    );

    return (
      <TableCellWrapper
        ref={ref as any}
        bold={!!onClick || bold}
        {...props}
        isClickable={!!onClick}
        onClick={!!onClick ? onClickHandler : null}
        className={className}
      >
        {children}
      </TableCellWrapper>
    );
  },
);

export default TableCell;
