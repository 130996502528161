import { FieldInputProps, useField } from 'react-final-form';

import {
  PersonFields,
  Fields,
  usePersonFields,
} from '../../../../hooks/usePersonFields';
import { ISearchPerson } from '../../../../models/SearchPerson';
import { searchPersonPeopleManagement } from '../../../../store/saga-slices/optionsSearch/common/api';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';
import { ISearchInputOption } from '../../SearchInput/searchInput.models';

type UseFormSearchPerson = {
  group?: string;
  fields: Partial<Record<Fields, string>>;
  input: FieldInputProps<string, HTMLElement>;
};

export const useFormSearchPerson = ({
  fields,
  group,
  input,
}: UseFormSearchPerson) => {
  const { clearFields, changeFields } = usePersonFields({ fields, group });

  const {
    input: { value: personId },
  } = useField(
    getFormGroupPath(group, fields.PersonId || PersonFields.PersonId),
  );

  const internalPromiseOptions = (inputValue: string) =>
    searchPersonPeopleManagement(inputValue).then((x) =>
      x.data.people.map(
        (person) =>
          ({
            label: person.contactEmailAddress,
            value: person,
          } as ISearchInputOption<ISearchPerson>),
      ),
    );

  const onChange = (inputValue: ISearchInputOption<ISearchPerson> | string) => {
    const { onChange: onInputChange } = input;

    if (inputValue === null) {
      clearFields();
      onInputChange(null);
      return;
    }

    if (typeof inputValue === 'string') {
      if (!!personId) {
        clearFields();
      }
      onInputChange(inputValue);
      return;
    }

    changeFields(inputValue.value);
  };
  return { onChange, internalPromiseOptions };
};
