import { Field } from 'react-final-form';

import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { BasicFormWrapper } from '../../../Form/Form';

import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import {
  DOCSubscriptionPricingFormFields,
  DOCSubscriptionPricingFormName,
} from './docSubscriptionPricingForm.models';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { FormNumberInputIcon } from '../../../UI/Form/FormNumberInputIcon/FormNumberInputIcon';
import FormSwitch from '../../../UI/Form/FormSwitch/FormSwitch';
import { useDOCSubscriptionPricingForm } from './docSubscriptionPricingForm.hooks';
import { StyledSectionSubTitle } from './docSubscriptionPricingForm.styles';
import { CustomForm } from '../../../../models/types/CustomForm';
import { preparePackagesOptions } from './docSubscriptionPricingForm.utils';

type DOCSubscriptionPackageOptionsFormProps = CustomForm & {
  title?: string;
};

const DOCSubscriptionPackageOptionsForm = ({
  className,
  disabled,
  name,
  title,
  children,
}: DOCSubscriptionPackageOptionsFormProps) => {
  const { seatsDisabled, packages, isLoading } =
    useDOCSubscriptionPricingForm(name);

  return (
    <BasicFormWrapper className={className}>
      {title && <StyledSectionSubTitle>{title}</StyledSectionSubTitle>}
      {children}
      <FormColumn>
        <FormRow isSubRow subRowAdjustWidth>
          <Field
            component={FormSelect}
            disabled={disabled}
            isLoading={isLoading}
            {...getFieldPropertiesForGroup({
              inputId: DOCSubscriptionPricingFormName,
              groupName: name,
              name: DOCSubscriptionPricingFormFields.PackageType,
            })}
            options={preparePackagesOptions(packages)}
          />
          <Field
            component={FormNumberInputIcon}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: DOCSubscriptionPricingFormName,
              groupName: name,
              name: DOCSubscriptionPricingFormFields.TotalPrice,
            })}
            iconPosition="start"
            defaultIcon="dollar"
            min={0}
            allowNegative={false}
            decimalScale={2}
          />
        </FormRow>
        <FormRow isSubRow>
          <FormRow>
            <Field
              component={FormSwitch}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: DOCSubscriptionPricingFormName,
                name: DOCSubscriptionPricingFormFields.FirmWide,
                groupName: name,
              })}
              type="checkbox"
            />
          </FormRow>
          <Field
            component={FormNumberInputIcon}
            disabled={disabled || seatsDisabled}
            {...getFieldPropertiesForGroup({
              inputId: DOCSubscriptionPricingFormName,
              groupName: name,
              name: DOCSubscriptionPricingFormFields.SeatsAmount,
            })}
            min={0}
            decimalScale={0}
            allowNegative={false}
          />
        </FormRow>
      </FormColumn>
    </BasicFormWrapper>
  );
};

export default DOCSubscriptionPackageOptionsForm;
