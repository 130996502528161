import { Gender as GenderModel } from '../../../models/Gender';
import { Prefix } from '../../../models/Prefix';
import { getOptionsFromEnum } from '../../../utils/getOptionsFromEnum';

export const FormMemberDetailsName = 'member';

export enum FormMemberDetailsFields {
  EmailAddress = 'emailAddress',
  PersonPrefix = 'personPrefix',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
}

export type FormMemberDetailsFieldsType = keyof typeof FormMemberDetailsFields;

export const MemberTitleOptions = getOptionsFromEnum(Prefix, 'prefix');
export const genderOptions = getOptionsFromEnum(GenderModel, 'gender');
