import { FormApi } from 'final-form';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import { ICreateAAOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/CreateAAOrganizationSubscriptionExtensionModel';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { organizationNameSelector } from '../../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { aaSubscriptionOverviewSelector } from '../../../../store/redux-slices/subscriptions/airAmbulance/selectors';

import { extendAASubscription } from '../../../../store/saga-slices/subscriptions/airAmbulance';
import { IExtendSubscriptionPayload } from '../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { ExtendProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';

export const useExtendAASubscriptionForm = () => {
  const { organizationId, subscriptionId, productType } =
    useParams<ExtendProductSubscriptionPageParamsType>();
  const navigate = useNavigateSearch();
  const alert = useAlert();

  const { t } = useTranslation();

  const organizationName = useSelector(organizationNameSelector);
  const subscriptionOverview = useSelector(aaSubscriptionOverviewSelector);

  const dataLoading = useProcessing([
    ORGANIZATION_LOADING_PROCESSING,
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  ]);

  const subscriptionEditing = useProcessing(
    SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  );
  const summaryLoading = useProcessing(
    SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  );

  const { validateErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError({ withAlert: true });

  const ExtendSubscription = useActions(extendAASubscription);

  const handleSubmit = (
    values: ICreateAAOrganizationSubscriptionExtensionModel,
    form: FormApi<any, any>,
  ) => {
    const payload: IExtendSubscriptionPayload = {
      data: values,
      organizationId,
      subscriptionId,
      success: () => {
        navigate(
          RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
          {
            params: {
              [ProductSubscriptionOverviewPageParams.OrganizationId]:
                organizationId,
              [ProductSubscriptionOverviewPageParams.SubscriptionId]:
                subscriptionId,
              [ProductSubscriptionOverviewPageParams.SubscriptionType]:
                productType,
            },
          },
        );
        alert.success(
          t('alerts.subscription-extension-added', { organizationName }),
        );
      },
      error: (err: any) => {
        handleResponseError(err, values);
      },
    };
    ExtendSubscription(payload);
  };

  const checkIfCanSubmit = useCallback(
    (values: IChangeSubscriptionPlanCapacityModel[]) => {
      if (!values || !summaryLoading) {
        return true;
      }

      return values.some((element) => {
        const currentPlan = subscriptionOverview.plans.find(
          (x) => x.membershipPlanId === element.membershipPlanId,
        );
        return (
          currentPlan.membershipsInUse - currentPlan.capacity <=
          element.capacityChange
        );
      });
    },
    [subscriptionOverview, summaryLoading],
  );

  return {
    handleSubmit,
    checkIfCanSubmit,
    validateErrors,
    subscriptionEditing,
    dataLoading,
    subscriptionOverview,
    productType,
  };
};
