import { Outlet } from 'react-router-dom';
import { RouteProps } from 'react-router';

import WizardStepper from './WizardStepper/WizardStepper';
import { useWizardWrapper } from './wizardWrapper.hooks';
import { Wrapper } from './wizardWrapper.styles';
import Redirect from '../Shared/Redirect';

interface IWizardWrapperProps extends RouteProps {
  isStepperVisible?: boolean;
}

const WizardWrapper = ({ isStepperVisible }: IWizardWrapperProps) => {
  const { routesMatch, state, wizardPath } = useWizardWrapper();

  return (
    <Wrapper>
      {isStepperVisible && <WizardStepper />}
      {routesMatch && <Outlet />}
      {!routesMatch && <Redirect state={state} to={wizardPath} />}
    </Wrapper>
  );
};

export default WizardWrapper;
