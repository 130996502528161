import { usePermissions } from '../hooks/usePermissions';

type WithPermissionsProps = {
  permissions?: string[];
};

export const withPermissions =
  <P extends object>(
    Component: React.ComponentType<P>,
  ): React.FC<P & WithPermissionsProps> =>
  ({ permissions, ...props }: WithPermissionsProps) => {
    const { hasPermissions } = usePermissions(permissions);

    if (!hasPermissions) {
      return null;
    }

    return <Component {...(props as P)} />;
  };
