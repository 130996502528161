import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { IPerson } from '../../../models/Person';
import { RoutePath } from '../../../models/enums/RoutePath';
import { updateModalState } from '../../../store/redux-slices/modals';
import { MEMBERS_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { EDIT_MEMBER_MODAL_NAME } from '../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { textContainsWords } from '../../../utils/textHelpers';
import { MemberOverviewPageParamsType } from '../../../models/types/RoutePath';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { loadDOCMembers } from '../../../store/saga-slices/membership/dutyOfCare';
import {
  clearDOCMembers,
  updateDOCMembersList,
} from '../../../store/redux-slices/membership/dutyOfCare';
import { pageListDOCMembersSelector } from '../../../store/redux-slices/membership/dutyOfCare/selectors';
import { RequiredQueryParams } from './membersDutyOfCarePage.models';
import { ILoadDOCMembersPayload } from '../../../store/saga-slices/membership/dutyOfCare/models';
import { IDOCMemberListItem } from '../../../models/interfaces/Membership/DutyOfCare/IDOCMemberListItem';
import { loadDOCPackages } from '../../../store/saga-slices/packages/dutyOfCare';
import { ILoadDOCPackagesPayload } from '../../../store/saga-slices/packages/dutyOfCare/models';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import { clearDOCPackages } from '../../../store/redux-slices/packages/dutyOfCare';
import { docPackagesSelector } from '../../../store/redux-slices/packages/dutyOfCare/selectors';

export const useMembersDutyOfCare = () => {
  const cancellationToken = useCancellationToken();
  const LoadMembers = useActions(loadDOCMembers);
  const ClearMembers = useActions(clearDOCMembers);
  const ClearPackages = useActions(clearDOCPackages);
  const LoadPackages = useActions(loadDOCPackages);
  const location = useLocation();
  const navigate = useNavigateSearch();
  const docPackages = useSelector(docPackagesSelector);
  const pageListMembers = useSelector(pageListDOCMembersSelector);

  const membersLoading = useProcessing(MEMBERS_LOADING_PROCESSING);

  const UpdateModalState = useActions(updateModalState);
  const UpdateMembersList = useActions(updateDOCMembersList);

  const loadMembersHandler = useCallback(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadDOCMembersPayload = {
      params,
    };

    LoadMembers(payload);
  }, [LoadMembers, location.search]);

  useEffect(() => {
    const payload: ILoadDOCPackagesPayload = {
      cancellationToken,
    };
    LoadPackages(payload);
  }, [LoadPackages, cancellationToken]);

  useEffect(() => loadMembersHandler(), [loadMembersHandler]);

  useEffect(
    () => () => {
      ClearMembers();
      ClearPackages();
    },
    [ClearMembers, ClearPackages],
  );
  useEffect(() => () => ClearMembers(), [ClearMembers]);

  const onRowClickHandler = useCallback(
    (row: IDOCMemberListItem) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, { params });
    },
    [navigate],
  );

  const rowEditHandler = useCallback(
    (id: string) => {
      UpdateModalState([EDIT_MEMBER_MODAL_NAME, id]);
    },
    [UpdateModalState],
  );

  const onMemberEditedHandler = useCallback(
    (person: IPerson) => {
      UpdateMembersList(person);
    },
    [UpdateMembersList],
  );

  return {
    membersLoading,
    pageListMembers,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    docPackages,
  };
};
