import { useCallback } from 'react';

import { IGetFinanceReportsPayload } from '../../../../../store/saga-slices/reports/common/models';

import {
  canGenerateMembershipRetailFinanceReport,
  canGenerateSubscriptionFinanceReport,
} from '../../../../../config/accessPolicies/accessPolicies';
import { useActions } from '../../../../../hooks/useActions';
import { usePermissions } from '../../../../../hooks/usePermissions';
import {
  downloadSubscriptionFinanceReport,
  downloadRetailFinanceReport,
} from '../../../../../store/saga-slices/reports/common';
import { IGetFinanceReport } from '../../../../../models/interfaces/GetFinanceReport';

export const useReportsFormHooks = () => {
  const { hasPermissions: canDownloadRetailReport } = usePermissions(
    canGenerateMembershipRetailFinanceReport,
  );

  const { hasPermissions: canDownloadSubscriptionReport } = usePermissions(
    canGenerateSubscriptionFinanceReport,
  );
  const DownloadSubscriptionFinanceReport = useActions(
    downloadSubscriptionFinanceReport,
  );
  const DownloadRetailFinanceReport = useActions(downloadRetailFinanceReport);

  const handleSubmit = useCallback(
    (values: IGetFinanceReport) => {
      const payload: IGetFinanceReportsPayload = {
        data: values,
      };

      canDownloadSubscriptionReport &&
        DownloadSubscriptionFinanceReport(payload);
      canDownloadRetailReport && DownloadRetailFinanceReport(payload);
    },
    [
      canDownloadRetailReport,
      DownloadSubscriptionFinanceReport,
      canDownloadSubscriptionReport,
      DownloadRetailFinanceReport,
    ],
  );

  return { handleSubmit };
};
