import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import { ICreatePromoCodeModel } from '../../../models/CreatePromoCodeModel';
import { ICreatePromoCodeSuggestion } from '../../../models/interfaces/CreatePromoCodeSuggestion';
import { IEditPromoCodeValidDatesModel } from '../../../models/interfaces/EditPromoCodeValidDatesModel';
import { IPageListModel } from '../../../models/PageListModel';
import { IPromoCodeItem } from '../../../models/PromoCodeItem';
import { IPromoCodeOverview } from '../../../models/PromoCodeOverview';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../utils/validations/queryParamsHelpers';

const MainUrl = 'api/promocodes';

export const getPromoCodes = (
  cancellationToken: AbortSignal,
  organizationId?: string,
  params?: string,
): Promise<AxiosResponse<IPageListModel<IPromoCodeItem>>> => {
  const queryParams: QueryParamItem[] = [
    { name: 'organizationId', value: organizationId },
  ];

  const currentParams = prepareQueryParams({
    initQueryString: params,
    queryParams,
  });
  return axios.get(`${MainUrl}${currentParams}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
  });
};

export const GetPromoCodeOverview = (
  id: string,
): Promise<AxiosResponse<IPromoCodeOverview>> =>
  axios.get(`${MainUrl}/${id}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
  });

export const createPromoCode = (
  data: ICreatePromoCodeModel,
): Promise<AxiosResponse<string>> =>
  axios.post(MainUrl, data, {
    baseURL: config.airAmbulanceApi.baseUrl,
  });

export const enablePromoCode = (
  promoCodeId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.put(`${MainUrl}/${promoCodeId}/enable`, null, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancelToken,
  });

export const disablePromoCode = (
  promoCodeId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.put(`${MainUrl}/${promoCodeId}/disable`, null, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancelToken,
  });

export const removePromoCode = (
  promoCodeId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.delete(`${MainUrl}/${promoCodeId}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancelToken,
  });

export const getCreatePromoCodeSuggestion = (
  organizationId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<ICreatePromoCodeSuggestion>> => {
  const currentParams = prepareQueryParams({
    queryParams: [{ name: 'organizationId', value: organizationId }],
  });

  return axios.get(`${MainUrl}/createsuggestion${currentParams}`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancelToken,
  });
};

export const editValidDates = (
  promoCodeId: string,
  data: IEditPromoCodeValidDatesModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ICreatePromoCodeSuggestion>> =>
  axios.put(`${MainUrl}/${promoCodeId}/dates`, data, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancellationToken,
  });
