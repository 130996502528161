import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { editOrganizationContractNotes } from '../../../../../../../store/saga-slices/contracts';
import { IEditOrganizationContractNotesPayload } from '../../../../../../../store/saga-slices/contracts/models';
import {
  useApiResponseError,
  useFormErrors,
} from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateNotesForm } from '../../../../../../../styledComponents/Forms/NotesForm/notesForm.validation';
import { EDIT_CONTRACT_NOTES_MODAL_NAME } from '../editContractNotesModal.const';
import { EditOrganizationContractNotesModalDetails } from './editContractNotesModalForm.models';

interface IUseEditContractNotesProps {
  onNotesEdited?: () => void;
}

export const useEditContractNotes = ({
  onNotesEdited,
}: IUseEditContractNotesProps) => {
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const EditOrganizationContractNotes = useActions(
    editOrganizationContractNotes,
  );

  const isProcessing = useProcessing(
    ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING,
  );

  const {
    contractId,
    organizationId,
    notes,
  }: EditOrganizationContractNotesModalDetails = useModalDetails(
    EDIT_CONTRACT_NOTES_MODAL_NAME,
  );

  const { validateErrors, addErrors } = useFormErrors();

  const { handleResponseError } = useApiResponseError({
    externalAddErrors: addErrors,
  });

  const closeModalHandler = useCallback(() => {
    UpdateModalState(EDIT_CONTRACT_NOTES_MODAL_NAME);
  }, [UpdateModalState]);

  const handleSubmit = useCallback(
    (values: { notes: string }) => {
      const payload: IEditOrganizationContractNotesPayload = {
        organizationId,
        contractId,
        notes: values.notes,
        cancellationToken,
        success: () => {
          alert.success(t('alerts.contract-notes-updated-success'));
          closeModalHandler();
          onNotesEdited?.();
        },
        error: (err: any) => {
          handleResponseError(err, values);
          alert.error(t('alerts.contract-notes-updated-fail'));
        },
      };
      EditOrganizationContractNotes(payload);
    },
    [
      EditOrganizationContractNotes,
      alert,
      cancellationToken,
      closeModalHandler,
      contractId,
      handleResponseError,
      onNotesEdited,
      organizationId,
      t,
    ],
  );

  const validateData = useCallback(
    (data: IEditOrganizationContractNotesPayload) =>
      validateNotesForm(data, validateErrors(data)),
    [validateErrors],
  );

  return {
    closeModalHandler,
    handleSubmit,
    notes,
    isProcessing,
    validateData,
  };
};
