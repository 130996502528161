import { t } from 'i18next';

import { formatDate } from '../../../../utils/dateHelpers';
import {
  Detail,
  DetailLabel,
  DetailValue,
} from '../../../Overview/OverviewDetails/OverviewDetails';
import { StyledDetailsContainer } from './editRenewalContractStatusModalOverview.styles';

interface IRenewalContractStatusOverviewProps {
  organizationName?: string;
  contractName: string;
  expirationDate?: string;
}

export const EditRenewalContractStatusModalOverview = ({
  organizationName,
  contractName,
  expirationDate,
}: IRenewalContractStatusOverviewProps) => (
  <StyledDetailsContainer>
    <Detail>
      <DetailLabel>{`${t('properties.organization')}: `}</DetailLabel>
      <DetailValue>{organizationName}</DetailValue>
    </Detail>
    <Detail>
      <DetailLabel>{`${t('properties.contract-name')}: `} </DetailLabel>
      <DetailValue>{contractName}</DetailValue>
    </Detail>
    <Detail>
      <DetailLabel>{`${t('properties.expirationDate')}: `} </DetailLabel>
      <DetailValue>{formatDate(expirationDate)}</DetailValue>
    </Detail>
  </StyledDetailsContainer>
);
