import { useLocation } from 'react-router-dom';

import { withPermissions } from '../../../hoc/withPermissions';
import { SideBarNavigate } from '../sidebar.const';
import { Description, StyledNavLink, Wrapper } from './navItem.styles';
import { isMatchingRoute } from './navItem.utils';

export interface NavItemProps {
  image: string;
  to: string;
  state?: any;
  description?: string;
  additionalRoutes?: string[];
}

const NavItem = ({
  image,
  description,
  additionalRoutes,
  ...props
}: NavItemProps) => {
  const location = useLocation();
  const match = isMatchingRoute(additionalRoutes, location.pathname);
  return (
    <Wrapper>
      <StyledNavLink
        state={{ from: SideBarNavigate }}
        isActive={!!match}
        {...props}
      >
        <img alt="icon" src={image} />
        {description && <Description>{description}</Description>}
      </StyledNavLink>
    </Wrapper>
  );
};

export default withPermissions(NavItem);
