import { AvailableQueryParams } from '../../../../../styledComponents/MaterialTable/materialTable.models';
import { AAMembersListFilters } from '../../../../../styledComponents/ProductMembersList/AAMembersList/AAMembersFilters/aaMembersFilters.models';

export const pageFilters = [
  AAMembersListFilters.Search,
  AAMembersListFilters.Plan,
  AAMembersListFilters.MemberType,
  AAMembersListFilters.RenewStatus,
  AAMembersListFilters.SubscriptionId,
  AAMembersListFilters.OversubscribedOnly,
];

export const RequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];
