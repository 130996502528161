import { useCallback, useState } from 'react';

type UseExpandContentProps = {
  defaultExpand?: boolean;
};

export const useExpandContent = ({
  defaultExpand = false,
}: UseExpandContentProps = {}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpand);

  const expandContent = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const hideContent = useCallback(() => {
    setIsExpanded(false);
  }, []);

  return {
    expandContent,
    hideContent,
    isExpanded,
  };
};
