import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactNode } from 'react';

import { ReactComponent as FamilyIcon } from '../../../assets/icons/family-icon.svg';
import { ReactComponent as CorpIcon } from '../../../assets/icons/corporation-icon.svg';
import { ReactComponent as GroupIcon } from '../../../assets/icons/group-type-icon.svg';
import { ReactComponent as AgentIcon } from '../../../assets/icons/agent-type-icon.svg';

import { Wrapper } from './organizationTypeForm.styles';
import { CustomForm } from '../../../models/types/CustomForm';
import FormTabOptions from '../../UI/Form/FormTabOptions/FormTabOptions';
import { OrganizationTypeFormFields } from './organizationTypeForm.models';
import { OrganizationType } from '../../../models/enums/OrganizationType';
import MainTooltip from '../../UI/MainTooltip/MainTooltip';

type OrganizationTypeFormProps = CustomForm & {
  tooltip?: ReactNode;
  tooltipIsShowing?: boolean;
};

const OrganizationTypeForm = ({
  className,
  disabled,
  tooltip,
  tooltipIsShowing = false,
}: OrganizationTypeFormProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      <MainTooltip title={tooltip} isShowing={tooltipIsShowing}>
        <Field
          component={FormTabOptions}
          disabled={disabled}
          name={OrganizationTypeFormFields.OrganizationType}
          options={[
            {
              value: OrganizationType.Corporation,
              label: t('fields.typeCorporation.label'),
              icon: <CorpIcon />,
            },
            {
              value: OrganizationType.FamilyOffice,
              label: t('fields.typeFamily.label'),
              icon: <FamilyIcon />,
            },
            {
              value: OrganizationType.Agent,
              label: t('fields.typeAgent.label'),
              icon: <AgentIcon />,
            },
            {
              value: OrganizationType.Group,
              label: t('fields.typeGroup.label'),
              icon: <GroupIcon />,
            },
          ]}
        />
      </MainTooltip>
    </Wrapper>
  );
};

export default OrganizationTypeForm;
