/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback } from 'react';
import { FieldInputProps } from 'react-final-form';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { ProductType } from '../../../../../models/ProductType';
import { loadSubscriptionExtensionSummaryByProductType } from '../../../../../store/saga-slices/subscriptions/common';
import { ILoadSubscriptionExtensionSummaryByProductTypePayload } from '../../../../../store/saga-slices/subscriptions/common/models';
import { isPropertyEmpty } from '../../../../../utils/propertyHelpers';

type UseExtendSubscriptionMembershipPlanFormTableType = {
  input: FieldInputProps<IChangeSubscriptionPlanCapacityModel[], HTMLElement>;
  productType?: ProductType;
};

export const useExtendSubscriptionMembershipPlanFormTable = ({
  input,
  productType,
}: UseExtendSubscriptionMembershipPlanFormTableType) => {
  const LoadSubscriptionExtensionSummary = useActions(
    loadSubscriptionExtensionSummaryByProductType,
  );
  const { organizationId, subscriptionId } = useParams();
  const { debounce } = useDebounce();

  useEffect(() => {
    if (isPropertyEmpty(input.value)) {
      input.onChange([]);
    }
  }, []);

  useEffect(() => {
    if (!input.value) {
      return;
    }

    debounce(() => {
      const payload: ILoadSubscriptionExtensionSummaryByProductTypePayload = {
        data: input.value,
        organizationId,
        subscriptionId,
        productType,
      };

      LoadSubscriptionExtensionSummary(payload);
    }, 500);
  }, [
    input.value,
    LoadSubscriptionExtensionSummary,
    debounce,
    organizationId,
    subscriptionId,
    productType,
  ]);

  const getCurrentSelectedPlanIndex = useCallback(
    (id: string) =>
      input.value
        ? input.value.findIndex((x) => x.membershipPlanId === id)
        : null,
    [input.value],
  );

  const getSelectedPlanCapacity = useCallback(
    (id: string) => {
      const currentPlan = getCurrentSelectedPlanIndex(id);
      return currentPlan === null || currentPlan === -1
        ? 0
        : input.value[currentPlan].capacityChange;
    },
    [getCurrentSelectedPlanIndex, input.value],
  );

  const canRemoveSeats = useCallback(
    (
      membershipPlanId: string,
      currentCapacity: number,
      usedCapacity: number,
    ) => {
      const selectedPlanCapacity = getSelectedPlanCapacity(membershipPlanId);
      return (
        !selectedPlanCapacity ||
        usedCapacity - currentCapacity <= selectedPlanCapacity
      );
    },
    [getSelectedPlanCapacity],
  );

  const changeNumberOfSeats = useCallback(
    (value: number, row: IOrganizationSubscriptionOverviewMembershipPlan) => {
      let membershipPlans = [...input.value];

      if (value === 0) {
        membershipPlans = membershipPlans.filter(
          (x) => x.membershipPlanId !== row.membershipPlanId,
        );
        input.onChange(membershipPlans);
        return;
      }

      const currentSelectedPlanIndex = getCurrentSelectedPlanIndex(
        row.membershipPlanId,
      );

      if (
        currentSelectedPlanIndex === -1 ||
        currentSelectedPlanIndex === null
      ) {
        membershipPlans.push({
          membershipPlanId: row.membershipPlanId,
          capacityChange: value,
        });
      } else {
        membershipPlans[currentSelectedPlanIndex].capacityChange = value;
      }

      input.onChange(membershipPlans);
    },
    [input, getCurrentSelectedPlanIndex],
  );

  return {
    canRemoveSeats,
    changeNumberOfSeats,
    getSelectedPlanCapacity,
  };
};
