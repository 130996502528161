/* eslint-disable react/no-array-index-key */
import { t } from 'i18next';
import { useMemo } from 'react';
import { Field, useField } from 'react-final-form';

import { MultiInputType } from '../../../../../../../models/enums/MultiInputType';

import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { FormSelect } from '../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { getFormGroupPath } from '../../../../../../../utils/getFormGroupPath';
import { FilterDefinition } from '../../../renewalFilters.models';
import { FilterInputContainer, FilterRow } from '../../filtersForm.styles';
import {
  SelectCategoryFilterFields,
  SelectCategoryFilterOptionsModel,
} from './selectCategoryFilter.models';

type SelectFilterProp = CustomComponent & {
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>;
};

const SelectFilter = ({ filterDefinition }: SelectFilterProp) => {
  const {
    input: { value },
  } = useField(SelectCategoryFilterFields.Value);
  const {
    input: { onChange },
  } = useField(SelectCategoryFilterFields.AdditionalValues);
  const categoryPromise = useMemo(
    () =>
      filterDefinition?.filterOptions?.categoryPromiseOptions?.(value.value),
    [filterDefinition?.filterOptions, value],
  );
  return (
    <FilterRow>
      <FilterInputContainer>
        <Field
          component={FormSelect}
          options={filterDefinition.filterOptions?.options}
          label={t(`filters.${filterDefinition.field}`)}
          name={SelectCategoryFilterFields.Value}
          valueRenderer={filterDefinition?.filterOptions?.valueRenderer}
          promiseOptions={filterDefinition?.filterOptions?.promiseOptions}
          onItemChanged={() => {
            onChange({});
          }}
        />
      </FilterInputContainer>
      {!!value && (
        <FilterInputContainer>
          <Field
            component={FormSelect}
            isMulti
            multiInputType={MultiInputType.InputSelect}
            options={filterDefinition.filterOptions?.categoryOptions}
            label={t(`filters.${filterDefinition.field}`)}
            name={getFormGroupPath(
              SelectCategoryFilterFields.AdditionalValues,
              SelectCategoryFilterFields.CategoryValues,
            )}
            valueRenderer={filterDefinition?.filterOptions?.valueRenderer}
            promiseOptions={categoryPromise}
          />
        </FilterInputContainer>
      )}
    </FilterRow>
  );
};

export default SelectFilter;
