import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setRouteHistory } from '../store/redux-slices/routesHistory';
import { useActions } from './useActions';

export const useRouteHistory = () => {
  const SetRouteHistory = useActions(setRouteHistory);
  const location = useLocation();
  useEffect(() => {
    SetRouteHistory({ pathName: location.pathname, search: location.search });
  }, [SetRouteHistory, location]);
};
