import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';

import Spinner from '../Spinner/Spinner';
import SummaryRow from './SummaryRow/SummaryRow';
import TableContent from './TableContent/TableContent';
import { StyledPaper, StyledTable, Wrapper } from './materialTable.styles';
import TableHeader from './TableHeader/TableHeader';
import MemberTableToolsProvider from './MaterialTableTools/MaterialTableToolsProvider';
import { useMaterialTableTools } from './MaterialTableTools/materialTableTools.hooks';
import MaterialTablePagination from './MaterialTableTools/MaterialTablePagination';
import { IMaterialTableProps } from './materialTable.models';
import { useMaterialTable } from './materialTable.hooks';

const MaterialTable = ({
  columns,
  data = [],
  className,
  isPaginationHidden,
  itemsCount,
  dataLoading,
  summaryRow,
  onRowClick,
  defaultColumnSort,
  canClickRowPermissions,
  onSummaryRowClick,
  showNotFoundComponent = true,
}: IMaterialTableProps) => {
  useMaterialTableTools({ defaultColumnSort });
  const { filteredColumns, canClickRow } = useMaterialTable({
    columns,
    canClickRowPermissions,
  });

  return (
    <Wrapper className={className}>
      <Spinner isVisible={dataLoading} size="6rem">
        <TableContainer component={StyledPaper}>
          <StyledTable>
            <TableHeader columns={filteredColumns} />
            <TableBody>
              <TableContent
                showNotFoundComponent={showNotFoundComponent}
                columns={filteredColumns}
                data={data}
                onRowClick={canClickRow && onRowClick}
              />
              {summaryRow && (
                <SummaryRow
                  onRowClick={canClickRow && onSummaryRowClick}
                  columns={filteredColumns}
                  summaryRow={summaryRow}
                />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        {!isPaginationHidden && (
          <MaterialTablePagination
            itemsCount={itemsCount ? itemsCount : data.length}
          />
        )}
      </Spinner>
    </Wrapper>
  );
};

const MaterialTableWrapper = ({
  itemsCount,
  isPaginationHidden,
  ...props
}: IMaterialTableProps) => (
  <MemberTableToolsProvider
    itemsCount={itemsCount}
    isPagination={!isPaginationHidden}
  >
    <MaterialTable
      itemsCount={itemsCount}
      isPaginationHidden={isPaginationHidden}
      {...props}
    />
  </MemberTableToolsProvider>
);

export default MaterialTableWrapper;
