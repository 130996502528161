import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '../../Typography/Typography';
import { StyledButton } from './contractsForm.styles';
import { CustomForm } from '../../../models/types/CustomForm';
import ContractsItemForm from './ContractsItem';

interface IContractsFormProps extends CustomForm {
  title?: string;
}

const ContractsForm = ({
  className,
  disabled,
  title,
  name,
}: IContractsFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <FieldArray name={name}>
        {({ fields }) => (
          <div>
            {fields.map((fieldName, index) => (
              <div key={fieldName}>
                <ContractsItemForm
                  contractNumber={index + 1}
                  disabled={disabled}
                  name={fieldName}
                  onRemoveContract={() => fields.remove(index)}
                />
              </div>
            ))}
            <StyledButton
              disabled={fields.length === 5 || disabled}
              onClick={() => fields.push({})}
              styleType="link"
            >
              {t('buttons.add-contract')}
            </StyledButton>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default ContractsForm;
