import { useTranslation } from 'react-i18next';

import { MembershipItemBaseProps } from '../../MembershipItemForm/membershipItemForm.models';
import MembershipFamilyMembersForm from '../MembershipCommon/MembershipFamilyMembersForm/MembershipFamilyMembersForm';
import { useSecurityResponseMembership } from './securityResponseMembership.hooks';
import { checkFamilyMembersFormVisible } from './securityResponseMembership.utils';
import SRMembershipDetailsForm from './SRMembershipDetailsForm/SRMembershipDetailsForm';
import SRMembershipOptionsForm from './SRMembershipOptionsForm/SRMembershipOptionsForm';

type SecurityResponseMembershipProps = MembershipItemBaseProps;

const SecurityResponseMembership = ({
  isProcessing,
  values,
  disabled,
  isRenewalMode,
}: SecurityResponseMembershipProps) => {
  const { t } = useTranslation();

  const { selectedPlan } = useSecurityResponseMembership();
  const isDisabled = disabled || isProcessing;
  return (
    <div>
      <SRMembershipDetailsForm disabled={isDisabled} />
      <SRMembershipOptionsForm
        title={t('headers.options')}
        disabled={isDisabled}
      />
      {checkFamilyMembersFormVisible(values) && (
        <MembershipFamilyMembersForm
          isRenewalMode={isRenewalMode}
          title={t('headers.family-members')}
          disabled={isDisabled}
          maxMembersPerPlan={selectedPlan.maxMembersPerPlan}
          membershipPlanType={selectedPlan.membershipPlanType}
        />
      )}
    </div>
  );
};

export default SecurityResponseMembership;
