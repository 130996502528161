import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow-icon.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const StyledLeftArrow = styled(LeftArrow)`
  stroke: ${({ theme }) => theme.color.primary};

  &:hover {
    stroke: ${({ theme }) => theme.color.dark1};
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.dark2};
  font-size: 0.875rem;
`;

export const NavigationPathPart = styled.div`
  display: flex;
`;

export const Separator = styled.div`
  margin: 0 0.5rem;
`;

type PathTextType = {
  isActive?: boolean;
};
export const PathText = styled.div<PathTextType>`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.dark1};
  }
  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.black};
      font-size: 1.25rem;
      font-weight: bold;

      &:hover {
        color: ${theme.color.black};
      }
    `}
`;
