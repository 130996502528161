import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../hooks/useActions';
import { updateModalState } from '../../../store/redux-slices/modals';
import { modalDetailsSelector } from '../../../store/redux-slices/modals/selectors';
import { ADD_CLIENT_MODAL_NAME } from './addClientModal.consts';
import {
  AddPromoCodeAvailableSearchParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { ICreateOrganizationResultModel } from '../../../models/interfaces/CreateOrganizationResultModel';
import { ConfirmModalButton } from '../../../styledComponents/ConfirmModal/confirmModal.models';

export const useAddClientModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigateSearch();
  const UpdateModalState = useActions(updateModalState);
  const {
    canOwnSubscription,
    corporationName,
    organizationId,
  }: ICreateOrganizationResultModel & { corporationName: string } = useSelector(
    modalDetailsSelector(ADD_CLIENT_MODAL_NAME),
  );

  const clientOverviewHandler = useCallback(() => {
    UpdateModalState(ADD_CLIENT_MODAL_NAME);
    navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
      params: {
        id: organizationId,
      },
    });
  }, [UpdateModalState, organizationId, navigate]);

  const addSubscriptionHandler = useCallback(() => {
    UpdateModalState(ADD_CLIENT_MODAL_NAME);
    navigate(RoutePath.ClientOverviewProductSubscriptionListPageFullPath, {
      params: {
        id: organizationId,
      },
    });
  }, [UpdateModalState, organizationId, navigate]);

  const addPromoCodeHandler = useCallback(() => {
    UpdateModalState(ADD_CLIENT_MODAL_NAME);
    navigate(RoutePath.AddPromoCode, {
      searchParams: {
        [AddPromoCodeAvailableSearchParams.OrganizationId]: organizationId,
      },
    });
  }, [UpdateModalState, organizationId, navigate]);

  const buttonsDefinition = useMemo((): ConfirmModalButton[] => {
    const defaultButtons: ConfirmModalButton[] = [
      {
        action: clientOverviewHandler,
        type: 'done',
        styleType: 'light1',
      },
    ];
    if (canOwnSubscription) {
      return [
        ...defaultButtons,
        {
          action: addSubscriptionHandler,
          type: 'continue',
          styleType: 'standard',
          label: t('buttons.add-subscription-continue'),
        },
      ];
    }
    return [
      ...defaultButtons,
      {
        action: addPromoCodeHandler,
        type: 'continue',
        styleType: 'standard',
        label: t('buttons.add-promocode'),
      },
    ];
  }, [
    addPromoCodeHandler,
    addSubscriptionHandler,
    canOwnSubscription,
    clientOverviewHandler,
    t,
  ]);

  const onModalClose = useCallback(() => {
    navigate(RoutePath.CorporateClientsPage);
  }, [navigate]);

  return {
    corporationName,
    buttonsDefinition,
    onModalClose,
  };
};
