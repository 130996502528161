import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { ISROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { srSubscriptionPricingSchema } from './SRSubscriptionPricingForm/srSubscriptionPricingForm.validation';

export const srSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...srSubscriptionPricingSchema.properties,
  },
  required: [...srSubscriptionPricingSchema.required],
};

export const validateAddSRSubscriptionForm = (
  data: ISROrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, srSubscriptionSchema);
