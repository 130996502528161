import styled from '@emotion/styled';

import { Status } from '../../models/enums/Status';

import SuccessIcon from '../../assets/icons/success-icon.svg';
import ErrorIcon from '../../assets/icons/error-icon.svg';
import WarningIcon from '../../assets/icons/warning-icon.svg';

const ICON = {
  [Status.SUCCESS]: `url(${SuccessIcon}) 50% no-repeat;`,
  [Status.ERROR]: `url(${ErrorIcon}) 50% no-repeat;`,
  [Status.WARNING]: `url(${WarningIcon}) 50% no-repeat;`,
};

type Props = {
  status: Status;
};

export const InfoStatus = styled.div<Props>`
  background: ${({ status = Status.SUCCESS }) => ICON[status]};
  height: 1.25rem;
  transition: all 0.25s ease;
  width: 1.25rem;
`;
