import { isPropertyEmpty } from '../../../../utils/propertyHelpers';

import { StandardText } from '../../../Typography/Typography';
import { IPlanSummaryItemModel } from '../summaryItem.models';
import { Price, Wrapper } from './summaryItemOption.styles';

interface ISubscriptionSummaryPlanOptionProps {
  item: IPlanSummaryItemModel;
  priceOverridden?: boolean;
}

const SubscriptionSummaryPlanOption = ({
  item,
  priceOverridden,
}: ISubscriptionSummaryPlanOptionProps) => (
  <Wrapper>
    <StandardText>{`${
      !isPropertyEmpty(item.capacity) ? `${item.capacity}x ` : ''
    }${item.name}`}</StandardText>
    <Price overridden={priceOverridden}>${item.amount}</Price>
  </Wrapper>
);

export default SubscriptionSummaryPlanOption;
