import { useTranslation } from 'react-i18next';

import { MainSelect } from '../../../../styledComponents/UI/MainSelect/MainSelect';

import {
  FiltersWrapper,
  InputContainer,
  SearchInputContainer,
} from '../../../../styledComponents/SimplePage/Filters';
import { useCorporateClientsAirAmbulanceFilters } from './corporateClientsAirAmbulanceFilters.hooks';
import IconInput from '../../../../styledComponents/UI/IconInput/IconInput';
import { organizationTypeOptions } from './corporateClientsAirAmbulanceFilters.utils';

const CorporateClientAirAmbulanceFilters = () => {
  const { t } = useTranslation();
  const { filters, onFilterChangeHandler } =
    useCorporateClientsAirAmbulanceFilters();

  return (
    <FiltersWrapper>
      <SearchInputContainer>
        <IconInput
          defaultIcon="search"
          onChange={(evt) => onFilterChangeHandler(evt, 'search')}
          placeholder={t('filters.search-by')}
          value={filters.search}
        />
      </SearchInputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.type}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'type')}
          options={organizationTypeOptions}
          placeholder={t('filters.type')}
        />
      </InputContainer>
    </FiltersWrapper>
  );
};

export default CorporateClientAirAmbulanceFilters;
