import { useTranslation } from 'react-i18next';

import { IMemberInfoModel } from '../../../models/MemberInfoModel';
import {
  useMemberTable,
  useMenuOptions,
} from './memberOverviewMemberTable.hooks';
import { MemberOverviewMemberTablePermissions } from './memberOverviewMemberTable.models';
import {
  StyledMaterialTable,
  TableTitle,
} from './memberOverviewMemberTable.styles';

interface IMemberOverviewMemberTableProps {
  data?: IMemberInfoModel[];
  onRenewFlagChange?: (memberId: string, toBeRenewed: boolean) => void;
  onDeleteMember?: (member: IMemberInfoModel) => void;
  dataLoading?: boolean;
  isExpiredMembershipsTable?: boolean;
  onResendEmailClick?: (personId: string) => void;
  onRowClick?: (row: IMemberInfoModel) => void;
  permissions?: MemberOverviewMemberTablePermissions;
}

const MemberOverviewMemberTable = ({
  data,
  onRenewFlagChange,
  onDeleteMember,
  dataLoading,
  isExpiredMembershipsTable,
  onResendEmailClick,
  onRowClick,
  permissions,
}: IMemberOverviewMemberTableProps) => {
  const { t } = useTranslation();

  const { cellRenderer } = useMemberTable();
  const { actionCellRenderer } = useMenuOptions({
    onRenewFlagChange,
    isExpiredMembershipsTable,
    onResendEmailClick,
    onDeleteMember,
    permissions,
  });

  return (
    <div>
      <TableTitle>{t('headers.members')}</TableTitle>
      <StyledMaterialTable
        columns={[
          {
            title: t('grid-columns.member-name'),
            field: 'memberName',
            cellRenderer: cellRenderer('memberName', {
              textBold: true,
            }),
          },
          {
            title: t('grid-columns.date-of-birth'),
            field: 'dateOfBirth',
            alignment: 'center',
            cellRenderer: cellRenderer('dateOfBirth', { alignment: 'center' }),
          },
          {
            title: t('grid-columns.member-type'),
            field: 'memberType',
            propertySelector: (value) =>
              t(`enums.member-type.${value.memberType}`),
          },
          {
            title: t('grid-columns.renew'),
            field: 'toBeRenewed',
            cellRenderer: cellRenderer('toBeRenewed'),
          },
          {
            cellRenderer: actionCellRenderer,
            permissions: permissions?.canViewActionColumnPermissions,
          },
        ]}
        data={data || []}
        isPaginationHidden
        onRowClick={onRowClick}
        dataLoading={dataLoading}
      />
    </div>
  );
};

export default MemberOverviewMemberTable;
