import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { withPermissions } from '../../../hoc/withPermissions';

import { FormFieldWrapper } from '../../Form/Form';

export const TableCellAction = withPermissions(styled.div`
  &:hover {
    cursor: pointer;
  }
`);

export const TableCellLink = styled(NavLink)`
  text-decoration: none;
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  width: auto;
`;
