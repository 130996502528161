import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, putResolve } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, select } from 'typed-redux-saga/macro';

import { ICreatePaymentModel } from '../../../models/CreatePaymentModel';
import { ICardSetupIntentModel } from '../../../models/ICardSetupIntentModel';
import { IPaymentIntentModel } from '../../../models/IPaymentIntentModel';
import { Log } from '../../../utils/logger';
import { clearCreatedMembershipId } from '../../redux-slices/membership/common';
import { createdMembershipIdSelector } from '../../redux-slices/membership/common/selectors';
import {
  setCreditCards,
  setCreditCardsLoaded,
} from '../../redux-slices/payment';
import { updateProcessState } from '../../redux-slices/processes';
import {
  PAYMENT_PROCESSING,
  LOAD_CREDIT_CARDS_PROCESSING,
  CREDIT_CARD_REPLACE_PROCESSING,
  CREDIT_CARD_ADD_PROCESSING,
  CREDIT_CARD_DELETE_PROCESSING,
  PAYMENT_CAPTURE_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  addNewCreditCard,
  capturePaymentRequest,
  deleteCreditCardRequest,
  getCreditCards,
  getPaymentIntent,
  replaceCreditCard,
  setAlreadyPayed,
} from './api';
import {
  ILoadCreditCards,
  ICreditCardsResponse,
  IReplaceExistingCreditCardPayload,
  IAddCreditCardPayload,
  IMarkAlreadyPaymentPayload,
  IDeleteCreditCardPayload,
  ICapturePaymentProcessPayload,
  ILoadPaymentIntent,
} from './model';
import { addOrReplaceCreditCard } from './utils';

const paymentSlice = createSliceSaga({
  caseSagas: {
    *loadCreditCards({
      payload: { success, error, personId },
    }: PayloadAction<ILoadCreditCards>) {
      try {
        yield put(updateProcessState(LOAD_CREDIT_CARDS_PROCESSING));
        const response: AxiosResponse<ICreditCardsResponse> = yield* call(
          getCreditCards,
          personId,
        );
        yield putResolve(setCreditCardsLoaded(true));
        yield putResolve(setCreditCards(response.data.creditCards));
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(LOAD_CREDIT_CARDS_PROCESSING));
      }
    },
    *markAlreadyPayment({
      payload: { success, error, cancellationToken },
    }: PayloadAction<IMarkAlreadyPaymentPayload>) {
      try {
        yield put(updateProcessState(PAYMENT_PROCESSING));
        const membershipId = yield* select(createdMembershipIdSelector);
        const response = yield* call(setAlreadyPayed, membershipId);

        if (response.data.isCreditCardRequired) {
          const setupCardResponse = yield* call(addOrReplaceCreditCard, {
            personId: response.data.primaryMemberId,
            cancellationToken,
          });
          success?.(setupCardResponse);
          return;
        }

        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PAYMENT_PROCESSING));
      }
    },
    *loadPaymentIntent({
      payload: { success, error, paymentId },
    }: PayloadAction<ILoadPaymentIntent>) {
      try {
        yield put(updateProcessState(PAYMENT_CAPTURE_PROCESSING));
        const response: AxiosResponse<IPaymentIntentModel> = yield* call(
          getPaymentIntent,
          paymentId,
        );
        success?.(response.data);
      } catch (err: any) {
        Log.error(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(PAYMENT_CAPTURE_PROCESSING));
      }
    },
    *capturePaymentProcess({
      payload: { creditCardInfo, paymentId, success, error },
    }: PayloadAction<ICapturePaymentProcessPayload>) {
      try {
        yield put(updateProcessState(PAYMENT_CAPTURE_PROCESSING));

        let requestData: ICreatePaymentModel | any = creditCardInfo;

        if (creditCardInfo.existingCreditCardId) {
          requestData = {
            ratifications: creditCardInfo.ratifications,
            existingCreditCardId: creditCardInfo.existingCreditCardId,
          };
          yield call(capturePaymentRequest, paymentId, requestData);
        }
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PAYMENT_CAPTURE_PROCESSING));
      }
    },
    *replaceExistingCreditCard({
      payload: { personId, cancellationToken, creditCardId, success, error },
    }: PayloadAction<IReplaceExistingCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_REPLACE_PROCESSING));
        const response: AxiosResponse<ICardSetupIntentModel> = yield* call(
          replaceCreditCard,
          personId,
          creditCardId,
          cancellationToken,
        );
        success?.(response.data);
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_REPLACE_PROCESSING));
      }
    },
    *addCreditCard({
      payload: { cancellationToken, personId, success, error },
    }: PayloadAction<IAddCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_ADD_PROCESSING));
        const response: AxiosResponse<ICardSetupIntentModel> = yield* call(
          addNewCreditCard,
          personId,
          cancellationToken,
        );
        success?.(response.data);
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_ADD_PROCESSING));
      }
    },
    *deleteCreditCard({
      payload: { cancellationToken, creditCardId, personId, success, error },
    }: PayloadAction<IDeleteCreditCardPayload>) {
      try {
        yield put(updateProcessState(CREDIT_CARD_DELETE_PROCESSING));
        yield call(
          deleteCreditCardRequest,
          personId,
          creditCardId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(CREDIT_CARD_DELETE_PROCESSING));
      }
    },
    *clearDataPayment() {
      yield put(clearCreatedMembershipId());
    },
  },
  name: 'employees-saga',
  sagaType: SagaType.TakeLatest,
});

export default paymentSlice.saga;
export const {
  markAlreadyPayment,
  clearDataPayment,
  loadCreditCards,
  loadPaymentIntent,
  addCreditCard,
  replaceExistingCreditCard,
  deleteCreditCard,
  capturePaymentProcess,
} = paymentSlice.actions;
export const { actions } = paymentSlice;
