import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { ProductType } from '../../../models/ProductType';
import { ProductSubscriptionRenewPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import { organizationSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  KE_PRICING_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import { clearKESubscriptionHelperData } from '../../../store/redux-slices/subscriptions/kidnapAndExtortion';
import { keSubscriptionRenewalSuggestionSelector } from '../../../store/redux-slices/subscriptions/kidnapAndExtortion/selectors';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { keLoadStandardPricing } from '../../../store/saga-slices/pricing/kidnapAndExtortion';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';
import {
  loadDatesForNewKESubscription,
  loadKESubscriptionRenewalSuggestion,
} from '../../../store/saga-slices/subscriptions/kidnapAndExtortion';
import { ILoadKESubscriptionRenewalSuggestionPayload } from '../../../store/saga-slices/subscriptions/kidnapAndExtortion/models';

export const useSubscriptionKERenew = () => {
  const { organizationId, subscriptionId } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const LoadOrganization = useActions(loadOrganization);
  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadStandardPricing = useActions(keLoadStandardPricing);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewKESubscription);
  const ClearKESubscriptionHelperData = useActions(
    clearKESubscriptionHelperData,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);

  const LoadSubscriptionRenewalSuggestion = useActions(
    loadKESubscriptionRenewalSuggestion,
  );

  const subscriptionRenewalSuggestion = useSelector(
    keSubscriptionRenewalSuggestionSelector,
  );

  const isOrganizationDataLoading = useProcessing(
    ORGANIZATION_LOADING_PROCESSING,
  );

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const isPricingDataLoading = useProcessing(KE_PRICING_LOADING_PROCESSING);
  const organization = useSelector(organizationSelector);

  const loadSubscriptionRenewalSuggestionHandler = useCallback(() => {
    const payload: ILoadKESubscriptionRenewalSuggestionPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionRenewalSuggestion(payload);
  }, [LoadSubscriptionRenewalSuggestion, organizationId, subscriptionId]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    LoadOrganizationContracts({ organizationId });
    LoadOrganization(organizationId);

    loadSubscriptionRenewalSuggestionHandler();
    LoadStandardPricing({ productType: ProductType.KidnapAndExtortion });
  }, [
    LoadOrganization,
    LoadOrganizationContracts,
    LoadSubscriptionRenewalSuggestion,
    organizationId,
    subscriptionId,
    LoadStandardPricing,
    loadSubscriptionRenewalSuggestionHandler,
  ]);

  useEffect(
    () => () => {
      ClearKESubscriptionHelperData();
      ClearOrganizationContracts();
      ClearSubscriptionHelperData();
    },
    [
      ClearOrganizationContracts,
      ClearKESubscriptionHelperData,
      ClearSubscriptionHelperData,
    ],
  );

  return {
    isPricingDataLoading,
    isOrganizationDataLoading,
    isSuggestionDataLoading,
    organization,
    organizationId,
    subscriptionId,
    loadSubscriptionRenewalSuggestionHandler,
    subscriptionRenewalSuggestion,
    loadDatesForNewSubscriptionHandler,
  };
};
