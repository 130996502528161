import { useEffect } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING,
  PRIMARY_MEMBER_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { resetToInvalidAfterActiveStep } from '../../../../store/saga-slices/wizard';

type UseAddMemberWizardPageProps = {
  isDirty?: boolean;
};

export const useAddMemberWizardPage = ({
  isDirty,
}: UseAddMemberWizardPageProps) => {
  const isDataLoading = useProcessing([
    LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING,
    PRIMARY_MEMBER_LOADING_PROCESSING,
  ]);
  const ResetValidAfterActiveStep = useActions(resetToInvalidAfterActiveStep);

  useEffect(() => {
    if (isDirty) {
      ResetValidAfterActiveStep();
    }
  }, [ResetValidAfterActiveStep, isDirty]);

  return {
    isDataLoading,
  };
};
