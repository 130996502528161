import styled from '@emotion/styled';

export const SectionTitle = styled.h2`
  letter-spacing: 0rem;
  margin: 0;
  font: 700 normal 1.25rem/2rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.black};
`;

export const SectionSubTitle = styled.h3`
  letter-spacing: 0.013rem;
  margin: 0;
  font: 500 1.125rem/2rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.black};
`;

export const SectionSubTitleBold = styled.h3`
  letter-spacing: 0.013rem;
  font: 700 1.125rem/2rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.black};
`;

export const SmallBoldSectionTitle = styled.h4`
  margin: 0;
  font: 700 0.875rem/1rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.dark1};
`;

export const ModalFormSectionTitle = styled.h4`
  margin: 0;
  font: 700 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.dark1};
`;

export const StandardText = styled.p`
  letter-spacing: 0.013rem;
  font: 400 normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const CellText = styled.p`
  font: 400 normal 0.875rem/0.875rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.dark1};
`;

export const SectionTitleDescription = styled.h4`
  font: 400 normal 0.825rem/1.5rem ${({ theme }) => theme.font.gotham};
  text-align: left;
`;

export const FormTitle = styled.h4`
  font: bold 1.125rem/1.5rem ${({ theme }) => theme.font.gotham};
  text-align: left;
`;

export const ErrorLabel = styled.label`
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  font: 0.75rem/1.5rem ${({ theme }) => theme.font.openSans};
  color: ${({ theme }) => theme.color.error};
`;
