import { Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import FormDatePicker from '../../../UI/Form/FormDatePicker/FormDatePicker';

import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { CustomForm } from '../../../../models/types/CustomForm';
import { BasicFormWrapper, SecondSectionTitle } from '../../../Form/Form';
import {
  BasicSubscriptionFormFields,
  BasicSubscriptionFormName,
} from './basicSubscriptionForm.models';
import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { useBasicSubscriptionForm } from './basicSubscriptionForm.hooks';
import { ProductType } from '../../../../models/ProductType';
import { Status } from '../../../../models/enums/Status';
import { StyledStatusMessageWithIcon } from './basicSubscriptionForm.styles';

interface IBasicSubscriptionFormProps extends CustomForm {
  title?: string;
  isEditMode?: boolean;
  onProductTypeChanged?: (productType: ProductType) => void;
  availableProducts: ProductType[];
}

const BasicSubscriptionForm = ({
  className,
  disabled,
  title,
  name,
  isEditMode,
  onProductTypeChanged,
  readOnly,
  availableProducts,
}: IBasicSubscriptionFormProps) => {
  const { t } = useTranslation();
  const { productList, contractDateRangeIsFull } = useBasicSubscriptionForm({
    name,
    availableProducts,
  });

  return (
    <BasicFormWrapper className={className}>
      {title && <SecondSectionTitle>{title}</SecondSectionTitle>}
      <FormColumn>
        <FormRow>
          <Field
            component={FormSelect}
            disabled={disabled || isEditMode}
            {...getFieldPropertiesForGroup({
              inputId: BasicSubscriptionFormName,
              groupName: name,
              name: BasicSubscriptionFormFields.ProductType,
            })}
            label={t('fields.subscriptionType.label')}
            options={productList}
            onItemChanged={onProductTypeChanged}
          />
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormDatePicker}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: BasicSubscriptionFormName,
              groupName: name,
              name: BasicSubscriptionFormFields.EffectiveDateFrom,
            })}
          />

          <Field
            component={FormDatePicker}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: BasicSubscriptionFormName,
              groupName: name,
              name: BasicSubscriptionFormFields.EffectiveDateTo,
            })}
          />
        </FormRow>
      </FormColumn>
      {contractDateRangeIsFull && (
        <StyledStatusMessageWithIcon status={Status.ERROR}>
          {t('api-validation.ContractDateRangeIsFull')}
        </StyledStatusMessageWithIcon>
      )}
    </BasicFormWrapper>
  );
};

export default BasicSubscriptionForm;
