import { IFormCheckboxGroupOptionProps } from '../../../UI/Form/FormCheckboxGroup/formCheckboxGroup.models';
import { ContractOptionsFormFields } from './contractOptions.models';

export const contractOptions = (
  editableFields: Partial<Record<ContractOptionsFormFields, boolean>>,
): IFormCheckboxGroupOptionProps[] => [
  {
    name: ContractOptionsFormFields.IsMultiYearContract,
    optionDisabled: editableFields && !editableFields?.isMultiYearContract,
  },
];
