import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { withErrorProvider } from '../../../../../../hoc/withErrorProvider';

import GGPointOfContactFormModal from '../../../../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactForm/GGPointOfContactFormModal';

import ModalFormPage from '../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { ADD_GG_CONTACT_MODAL_NAME } from './addGGContactModal.const';
import { useAddGGContactModal } from './addGGContactModal.hooks';
import { SmallModal } from '../../../../../../styledComponents/ModalCommon/ModalCommon';

const ModalAddGGContact = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    organizationUpdating,
    validateForm,
    employeesList,
    employeesLoading,
    closeModalHandler,
    modalShowHandler,
    rolesGGLoading,
    rolesGGList,
  } = useAddGGContactModal();

  return (
    <SmallModal
      disabled={organizationUpdating}
      isLoading={employeesLoading || rolesGGLoading}
      name={ADD_GG_CONTACT_MODAL_NAME}
      onModalShow={modalShowHandler}
    >
      <Form initialValues={{}} onSubmit={handleSubmit} validate={validateForm}>
        {({ ...renderProps }) => (
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            isProcessing={organizationUpdating}
            onCancel={closeModalHandler}
            title={t('headers.add-new-gg-point-of-contact')}
            mode="small"
          >
            <GGPointOfContactFormModal
              disabled={organizationUpdating}
              items={employeesList}
              roles={rolesGGList}
            />
          </ModalFormPage>
        )}
      </Form>
    </SmallModal>
  );
};

export default withErrorProvider(ModalAddGGContact);
