import { createSelector } from 'reselect';

import { RootState } from '../index';
import { IAuthState } from './index';
export const authSelector = (state: RootState) => state.auth;

export const userPermissionsSelector = createSelector(
  authSelector,
  (authState: IAuthState): string[] => authState.userPermissions,
);

export const userPermissionsLoadedSelector = createSelector(
  authSelector,
  (authState: IAuthState): boolean => authState.permissionLoaded,
);
