import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ICreateOrganizationPointOfContact } from '../../../../../../models/CreateOrganizationPointOfContact';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import {
  organizationIdSelector,
  organizationPointOfContactByIdSelector,
} from '../../../../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_EDITING_PROCESSING,
  ROLES_LOADING_PROCESSING,
} from '../../../../../../store/redux-slices/processes/constants';
import {
  editOrganizationContact,
  IOrganizationContactPayload,
} from '../../../../../../store/saga-slices/organizations';
import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateOrganizationPointOfContactForm } from '../../../../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/corpPointOfContactForm.validation';
import { parsePhoneNumber } from '../../../../../../utils/phoneNumberHelpers';
import { EDIT_CONTACT_MODAL_NAME } from './editContactModal.const';
import { EditContactModalDetails } from './editContactModal.models';
import { loadRoles } from '../../../../../../store/saga-slices/roles';
import { clearRoles } from '../../../../../../store/redux-slices/roles';
import { useCancellationToken } from '../../../../../../hooks/useTokenCancellation';
import { rolesOptionsSelector } from '../../../../../../store/redux-slices/roles/selectors';
import { ILoadRolesPayload } from '../../../../../../store/saga-slices/roles/models';

export const useEditContactModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const UpdateModalState = useActions(updateModalState);
  const LoadRoles = useActions(loadRoles);
  const ClearRoles = useActions(clearRoles);

  const EditOrganizationContact = useActions(editOrganizationContact);

  const { contactId }: EditContactModalDetails = useModalDetails(
    EDIT_CONTACT_MODAL_NAME,
  );
  const organizationId = useSelector(organizationIdSelector);
  const pointOfContact = useSelector(
    organizationPointOfContactByIdSelector(contactId),
  );
  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);

  const cancellationToken = useCancellationToken();

  const { handleResponseError } = useApiResponseError();
  const { validateErrors } = useFormErrorsActions();

  useEffect(() => {
    const payload: ILoadRolesPayload = {
      cancellationToken,
    };
    LoadRoles(payload);
    return () => {
      ClearRoles();
    };
  }, [LoadRoles, cancellationToken, ClearRoles]);

  const rolesLoading = useProcessing(ROLES_LOADING_PROCESSING);
  const rolesList = useSelector(rolesOptionsSelector);

  const handleSubmit = (values: ICreateOrganizationPointOfContact) => {
    const payload: IOrganizationContactPayload = {
      contact: values,
      organizationId,
      contactId,
      success: () => {
        alert.success(t('alerts.poc-edit-success'));

        UpdateModalState(EDIT_CONTACT_MODAL_NAME);
      },
      error: (err) => {
        handleResponseError(err, values);

        alert.error(t('alerts.poc-edit-fail'));
      },
    };

    EditOrganizationContact(payload);
  };

  const validateForm = (values: ICreateOrganizationPointOfContact) =>
    validateOrganizationPointOfContactForm(values, validateErrors(values));

  const onCancelHandler = () => UpdateModalState(EDIT_CONTACT_MODAL_NAME);

  const initState = pointOfContact && {
    firstName: pointOfContact.person?.firstName,
    lastName: pointOfContact.person?.lastName,
    workPhone: parsePhoneNumber(pointOfContact.workPhone),
    emailAddress: pointOfContact.workEmail,
    mobilePhone: parsePhoneNumber(pointOfContact.mobilePhone),
    internalNotes: pointOfContact.internalNotes,
    jobTitle: pointOfContact.jobTitle,
    isOrganizationAdministrator: pointOfContact.isOrganizationAdministrator,
    roles: pointOfContact.roles,
  };

  return {
    handleSubmit,
    initState,
    onCancelHandler,
    validateForm,
    organizationUpdating,
    rolesLoading,
    rolesList,
  };
};
