import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  IExportRenewalContractsPayload,
  IExportRenewalSubscriptionsPayload,
  ILoadRenewalContractsPayload,
  ILoadRenewalSubscriptionsPayload,
  RenewalFilenamePrefix,
} from './models';
import {
  RENEWAL_CONTRACTS_EXPORT_PROCESSING,
  RENEWAL_CONTRACTS_LOADING_PROCESSING,
  RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING,
  RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  exportContracts,
  exportSubscriptions,
  getFilteredContracts,
  getFilteredSubscriptions,
} from './api';
import {
  setRenewalsContracts,
  setRenewalsSubscriptions,
} from '../../redux-slices/renewalFilter';
import { Log } from '../../../utils/logger';
import { generateFilenameWithDate } from '../../../utils/generateFilenameWithDate';
import { CUSTOM_DATE_MONTH_YEAR_FORMAT } from '../../../utils/dateHelpers';
import downloadFile from '../../../utils/downloadFile';

const renewalFiltersSlice = createSliceSaga({
  name: 'renewal-filters-saga',
  caseSagas: {
    *loadRenewalContracts({
      payload: { filters, success, error, cancellationToken },
    }: PayloadAction<ILoadRenewalContractsPayload>) {
      try {
        yield put(updateProcessState(RENEWAL_CONTRACTS_LOADING_PROCESSING));
        const { data } = yield* call(
          getFilteredContracts,
          filters,
          cancellationToken,
        );
        yield put(setRenewalsContracts(data.result));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RENEWAL_CONTRACTS_LOADING_PROCESSING));
      }
    },
    *loadRenewalSubscriptions({
      payload: { filters, success, error, cancellationToken },
    }: PayloadAction<ILoadRenewalSubscriptionsPayload>) {
      try {
        yield put(updateProcessState(RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING));
        const { data } = yield* call(
          getFilteredSubscriptions,
          filters,
          cancellationToken,
        );
        yield put(setRenewalsSubscriptions(data.result));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING));
      }
    },
    *exportRenewalContracts({
      payload: { filters, success, error, cancellationToken },
    }: PayloadAction<IExportRenewalContractsPayload>) {
      try {
        yield put(updateProcessState(RENEWAL_CONTRACTS_EXPORT_PROCESSING));
        const name = generateFilenameWithDate(
          RenewalFilenamePrefix.RenewalContracts,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
        );
        const response = yield* call(
          exportContracts,
          filters,
          cancellationToken,
        );
        yield downloadFile(response.data, name);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RENEWAL_CONTRACTS_EXPORT_PROCESSING));
      }
    },
    *exportRenewalSubscriptions({
      payload: { filters, success, error, cancellationToken },
    }: PayloadAction<IExportRenewalSubscriptionsPayload>) {
      try {
        yield put(updateProcessState(RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING));
        const name = generateFilenameWithDate(
          RenewalFilenamePrefix.RenewalSubscriptions,
          CUSTOM_DATE_MONTH_YEAR_FORMAT,
        );
        const response = yield* call(
          exportSubscriptions,
          filters,
          cancellationToken,
        );
        yield downloadFile(response.data, name);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default renewalFiltersSlice.saga;
export const {
  loadRenewalContracts,
  loadRenewalSubscriptions,
  exportRenewalContracts,
  exportRenewalSubscriptions,
} = renewalFiltersSlice.actions;
export const { actions } = renewalFiltersSlice;
