import EditRenewalContractStatusModal from '../../../styledComponents/Modals/EditRenewalContractStatusModal/EditRenewalContractStatusModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import RenewalsContractsFilters from './RenewalsContractsFilters/RenewalsContractsFilters';
import { useRenewalsContractsPage } from './renewalsContractsPage.hooks';
import RenewalsContractsTables from './RenewalsContractsTables/RenewalsContractsTables';

const RenewalsContractsPage = () => {
  const {
    data,
    dataLoading,
    filterChangedHandler,
    onCellClickHandler,
    onRowClickHandler,
    loadContractsHandler,
    exportRenewalContractsHandler,
    dataExporting,
  } = useRenewalsContractsPage();
  return (
    <>
      <EditRenewalContractStatusModal
        onRenewalStatusEdited={loadContractsHandler}
      />
      <div>
        <RenewalsContractsFilters
          onFilterChanged={filterChangedHandler}
          onExportClick={exportRenewalContractsHandler}
          isExporting={dataExporting}
        />
        <Spinner size="10rem" isVisible={dataLoading}>
          <RenewalsContractsTables
            data={data}
            onRowClick={onRowClickHandler}
            onCellClick={onCellClickHandler}
          />
        </Spinner>
      </div>
    </>
  );
};

export default RenewalsContractsPage;
