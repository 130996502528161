import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../../../../styledComponents/ConfirmModal/ConfirmModal';
import {
  Description,
  Name,
} from '../../../../../MemberOverviewPage/Modals/Common/memberOverviewModals.styles';
import { DELETE_ORGANIZATION_MODAL_NAME } from './deleteOrganizationModal.const';
import { useDeleteOrganizationModal } from './deleteOrganizationModal.hooks';

const DeleteOrganizationModal = () => {
  const { t } = useTranslation();

  const {
    deleteOrganizationHandler,
    organizationUpdating,
    onCancelHandler,
    organization,
  } = useDeleteOrganizationModal();
  return (
    <ConfirmModal
      buttons={[
        {
          action: onCancelHandler,
          type: 'back',
          styleType: 'light1',
        },
        {
          action: deleteOrganizationHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: organizationUpdating,
        },
      ]}
      disabled={organizationUpdating}
      name={DELETE_ORGANIZATION_MODAL_NAME}
      title={t('headers.deleting-organization')}
    >
      <Description>{t('phrase.this-action-cannot-be-undone')}</Description>
      <Description>
        {t('phrase.please-confirm-delete-organization')}
      </Description>
      <Name>{organization.name}</Name>
    </ConfirmModal>
  );
};

export default DeleteOrganizationModal;
