import styled from '@emotion/styled/macro';

import { FormSubmitButton } from '../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';

export const PageRoot = styled.section`
  margin: 2.5rem;
`;

export const StyledSubmitButton = styled(FormSubmitButton)`
  width: 12.25rem;
  margin-top: 2.5rem;
`;
