import {
  canGenerateAARetailAttritionReport,
  canGenerateAARetailEnrollmentReport,
  canGenerateAARetailStatisticsReport,
} from '../../../../../config/accessPolicies/accessPolicies';
import { RetailReportType } from '../../../../../models/enums/RetailReportType';
import { getOptionsFromEnum } from '../../../../../utils/getOptionsFromEnum';

export const RetailReportTypeOptions = getOptionsFromEnum(
  RetailReportType,
  'retail-report-type',
);

export const RetailReportsPermissions = {
  [RetailReportType.Enrollments]: canGenerateAARetailEnrollmentReport,
  [RetailReportType.Attritions]: canGenerateAARetailAttritionReport,
  [RetailReportType.RetailStatistics]: canGenerateAARetailStatisticsReport,
};
