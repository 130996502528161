import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove-icon.svg';
import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { FormSelect } from '../../../UI/Form/FormSelect/FormSelect';
import { IEmployee } from '../../../../models/Employee';
import { FormDeleteButton, FormNumber, FormWrapper } from '../../../Form/Form';
import { CustomForm } from '../../../../models/types/CustomForm';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import {
  GgPointOfContactFormFields,
  GgPointOfContactFormName,
} from './ggPointOfContactForm.models';
import { SelectOption } from '../../../UI/MainSelect/mainSelect.types';
import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import NotesForm from '../../NotesForm/NotesForm';
import { MultiInputType } from '../../../../models/enums/MultiInputType';

interface IPointOfContactFormProps extends CustomForm {
  contactNumber?: number;
  items?: IEmployee[];
  roles?: SelectOption[];
  onRemoveContact?: () => void;
}

const GGPointOfContactFormModal = ({
  contactNumber,
  disabled,
  name,
  items,
  roles,
  onRemoveContact,
}: IPointOfContactFormProps) => {
  const { t } = useTranslation();

  const employeeOptions = useMemo(
    () =>
      items &&
      items.map((x) => ({
        label: `${x.firstName} ${x.lastName}`,
        value: x.id,
      })),
    [items],
  );
  return (
    <FormWrapper>
      <FormRow>
        <FormColumn>
          <FormRow>
            {contactNumber && <FormNumber>{`${contactNumber}.`}</FormNumber>}
            <Field
              component={FormSelect}
              disabled={disabled}
              label={t('fields.selectGGEmp.label')}
              {...getFieldPropertiesForGroup({
                inputId: GgPointOfContactFormName,
                name: GgPointOfContactFormFields.EmployeeId,
                groupName: name,
              })}
              options={employeeOptions}
            />
          </FormRow>
          <FormRow>
            <Field
              isMulti
              multiInputType={MultiInputType.InputSelect}
              component={FormSelect}
              options={roles}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: GgPointOfContactFormName,
                name: GgPointOfContactFormFields.roles,
                groupName: name,
              })}
              label={t('fields.ggPocRoles.label')}
            />
          </FormRow>
          <FormRow>
            <NotesForm
              titleHidden
              disabled={disabled}
              label="Notes"
              inputId={GgPointOfContactFormName}
              notesFieldName={GgPointOfContactFormFields.InternalNotes}
            />
          </FormRow>
        </FormColumn>
      </FormRow>
      {onRemoveContact && (
        <FormDeleteButton
          disabled={disabled}
          onClick={onRemoveContact}
          styleType="icon"
        >
          <RemoveIcon />
        </FormDeleteButton>
      )}
    </FormWrapper>
  );
};

export default GGPointOfContactFormModal;
