import { t } from 'i18next';

import { SecurityResponseMembershipOptions } from '../../../../../../../models/enums/SecurityResponseMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const SRMembershipOptions: IFormCheckboxGroupOptionProps[] = [
  {
    name: SecurityResponseMembershipOptions.SendWelcomeEmail,
  },
  {
    name: SecurityResponseMembershipOptions.AutoRenewal,
    label: t('fields.renewWithSubscription.label'),
  },
];
