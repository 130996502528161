import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../models/types/CustomForm';

import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../../../../UI/Form/FormInput/FormInput';
import { FormRow } from '../../../../UI/Form/FormRow/FormRow';
import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';
import { AARenewSubscriptionDetailsFormFields } from './aaRenewSubscriptionDetails.models';

import { Wrapper } from './aaRenewSubscriptionDetails.styles';
import { SubscriptionRenewDetailsCommonForm } from '../../Common/SubscriptionRenewDetailsCommonForm/SubscriptionRenewDetailsCommonForm';
import { ProductType } from '../../../../../models/ProductType';
import { SubscriptionRenewDetailsCommonFormName } from '../../Common/SubscriptionRenewDetailsCommonForm/subscriptionRenewDetailsCommonForm.models';

export const AARenewSubscriptionDetails = ({ disabled }: CustomForm) => (
  <Wrapper>
    <FormColumn>
      <SubscriptionRenewDetailsCommonForm
        productType={ProductType.AirAmbulance}
        disabled={disabled}
      />
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: SubscriptionRenewDetailsCommonFormName,
            name: AARenewSubscriptionDetailsFormFields.PromoCode,
          })}
        />
      </FormRow>
    </FormColumn>
  </Wrapper>
);
