import { IMemberInfoModel } from '../../../models/MemberInfoModel';
import { MemberType } from '../../../models/enums/MemberType';
import { MenuOption } from '../../../styledComponents/Menu/Menu';
import {
  MemberOverviewMemberTablePermissions,
  MenuActions,
} from './memberOverviewMemberTable.models';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';

type RenderMenuOptionsForActiveMembershipsType = {
  data: IMemberInfoModel;
  permissions?: MemberOverviewMemberTablePermissions;
};

export const renderMenuOptionsForActiveMemberships = ({
  data,
  permissions,
}: RenderMenuOptionsForActiveMembershipsType): MenuOption[] => [
  {
    canonicalName: MenuActions.Remove,
    disabled: data.memberType === MemberType.Primary,
    permissions: permissions?.canRemoveSecondaryMemberPermissions,
  },
  {
    canonicalName: data.toBeRenewed
      ? MenuActions.DoNotRenew
      : MenuActions.Renew,
    permissions: permissions?.canChangeToBeRenewedPermissions,
  },
  {
    canonicalName: isPropertyEmpty(data.memberType)
      ? MenuActions.ResendWelcomeEmail
      : data.memberType === MemberType.Primary &&
        MenuActions.ResendWelcomeEmail,
    permissions: permissions?.canResendWelcomeEmailPermissions,
  },
];

type RenderMenuOptionsForExpiredMembershipsType = {
  data: IMemberInfoModel;
  permissions?: MemberOverviewMemberTablePermissions;
};
export const renderMenuOptionsForExpiredMemberships = ({
  data,
  permissions,
}: RenderMenuOptionsForExpiredMembershipsType): MenuOption[] => [
  {
    canonicalName: MenuActions.Remove,
    disabled: true,
    permissions: permissions?.canRemoveSecondaryMemberPermissions,
  },
  {
    canonicalName: data.toBeRenewed
      ? MenuActions.DoNotRenew
      : MenuActions.Renew,
    permissions: permissions?.canChangeToBeRenewedPermissions,
  },
];
