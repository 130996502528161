import { BackButtonWrapper, StyledLeftArrow } from './BackButton.style';

interface ButtonProps {
  className?: string;
  onClick?: (event: any) => void;
}

const BackButton = ({ className, onClick }: ButtonProps) => (
  <BackButtonWrapper className={className} onClick={onClick}>
    <StyledLeftArrow />
  </BackButtonWrapper>
);

export default BackButton;
