import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../models/ProductType';

import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { ExtendSubscriptionExtensionsFormFields } from '../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/extendSubscriptionExtensionsForm.models';
import ExtendDOCSubscriptionForm from './ExtendDOCSubscriptionForm/ExtendDOCSubscriptionForm';
import { useExtendDOCSubscriptionPage } from './extendDOCSubscriptionPage.hooks';

const ExtendDOCSubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName } = useExtendDOCSubscriptionPage();
  return (
    <FormErrorProvider
      initErrorGroups={[
        ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges,
      ]}
    >
      <>
        <Header
          title={t('headers.extend-subscription', {
            organizationName,
            subscriptionName: t(`enums.productType.${ProductType.DutyOfCare}`),
          })}
        />
        <ExtendDOCSubscriptionForm />
      </>
    </FormErrorProvider>
  );
};

export default ExtendDOCSubscriptionPage;
