export const getCountOfItemsToShow = (values: any[], possibleWidth: number) => {
  if (!values.length) {
    return null;
  }

  const resultArray: any[] = [values[0]];
  for (let i = 1; i < values.length; i++) {
    if ([...resultArray, values[i]].join(', ').length < possibleWidth) {
      resultArray.push(values[i]);
    }
  }

  return resultArray.join(', ');
};
