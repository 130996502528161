import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { AxiosResponse } from 'axios';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  createContract,
  deleteContract,
  editContractNotes,
  editContractRenewalStatusRequest,
  getContract,
  getEditableFields,
  getOrganizationContracts,
  getOrganizationContractsList,
  updateContract,
} from './api';
import {
  CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING,
  ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING,
  ORGANIZATION_CONTRACTS_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_ADDING_PROCESSING,
  ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_DELETING_PROCESSING,
  ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_NOTES_ADDING_PROCESSING,
  ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING,
  ORGANIZATION_CONTRACT_UPDATING_PROCESSING,
} from '../../redux-slices/processes/constants';
import { Log } from '../../../utils/logger';
import {
  addContract,
  setContractDetails,
  setContractEditableFields,
  setEditContractData,
  setOrganizationContracts,
  setOrganizationContractsList,
} from '../../redux-slices/contracts';
import {
  IAddOrganizationContractNotesPayload,
  ICreateOrganizationContractPayload,
  IDeleteOrganizationContractPayload,
  IDeleteOrganizationContractResponse,
  IEditOrganizationContractNotesPayload,
  IEditContractRenewalStatusPayload,
  ILoadContractEditableFieldsPayload,
  ILoadOrganizationContractPayload,
  ILoadOrganizationContractsPayload,
  IOrganizationContractsEditableFieldsResponse,
  IUpdateOrganizationContractPayload,
} from './models';
import { setDeletedSubscriptionId } from '../../redux-slices/subscriptions/common';

const contractsSlice = createSliceSaga({
  name: 'contracts-saga',
  caseSagas: {
    *loadOrganizationContracts({
      payload: { error, organizationId, success, cancellationToken },
    }: PayloadAction<ILoadOrganizationContractsPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACTS_LOADING_PROCESSING),
        );
        const { data } = yield* call(
          getOrganizationContracts,
          organizationId,
          cancellationToken,
        );
        yield put(setOrganizationContracts(data.contracts));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.();
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACTS_LOADING_PROCESSING),
        );
      }
    },
    *loadOrganizationContractsList({
      payload: { error, organizationId, params, success, cancellationToken },
    }: PayloadAction<ILoadOrganizationContractsPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING),
        );
        const { data } = yield* call(
          getOrganizationContractsList,
          organizationId,
          params,
          cancellationToken,
        );
        yield put(setOrganizationContractsList(data.contracts));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.();
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING),
        );
      }
    },
    *createOrganizationContract({
      payload: { contract, error, organizationId, success, cancellationToken },
    }: PayloadAction<ICreateOrganizationContractPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_CONTRACT_ADDING_PROCESSING));
        const { data } = yield* call(
          createContract,
          organizationId,
          contract,
          cancellationToken,
        );
        yield put(addContract(data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_CONTRACT_ADDING_PROCESSING));
      }
    },
    *loadOrganizationContract({
      payload: {
        contractId,
        error,
        organizationId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadOrganizationContractPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_CONTRACT_LOADING_PROCESSING));
        const { data } = yield* call(
          getContract,
          organizationId,
          contractId,
          cancellationToken,
        );
        yield put(setContractDetails(data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_CONTRACT_LOADING_PROCESSING));
      }
    },
    *loadOrganizationContractDataForModal({
      payload: {
        contractId,
        error,
        organizationId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadOrganizationContractPayload>) {
      try {
        yield put(
          updateProcessState(
            ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING,
          ),
        );
        const { data } = yield* call(
          getContract,
          organizationId,
          contractId,
          cancellationToken,
        );
        yield put(setEditContractData(data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING,
          ),
        );
      }
    },
    *updateOrganizationContract({
      payload: {
        contract,
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateOrganizationContractPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_UPDATING_PROCESSING),
        );
        yield call(
          updateContract,
          organizationId,
          contractId,
          contract,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_UPDATING_PROCESSING),
        );
      }
    },
    *addOrganizationContractNotes({
      payload: {
        notes,
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IAddOrganizationContractNotesPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_NOTES_ADDING_PROCESSING),
        );
        yield* call(
          editContractNotes,
          organizationId,
          contractId,
          notes,
          cancellationToken,
        );

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_NOTES_ADDING_PROCESSING),
        );
      }
    },
    *editOrganizationContractNotes({
      payload: {
        notes,
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditOrganizationContractNotesPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING),
        );
        yield call(
          editContractNotes,
          organizationId,
          contractId,
          notes,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING),
        );
      }
    },
    *editContractRenewalStatus({
      payload: {
        newRenewalStatus,
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditContractRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editContractRenewalStatusRequest,
          organizationId,
          contractId,
          newRenewalStatus,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
    *loadContractEditableFields({
      payload: {
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadContractEditableFieldsPayload>) {
      try {
        yield put(
          updateProcessState(
            ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IOrganizationContractsEditableFieldsResponse> =
          yield* call(
            getEditableFields,
            organizationId,
            contractId,
            cancellationToken,
          );

        yield put(setContractEditableFields(response.data?.editableFields));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING,
          ),
        );
      }
    },
    *deleteOrganizationContract({
      payload: {
        error,
        organizationId,
        contractId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteOrganizationContractPayload>) {
      try {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_DELETING_PROCESSING),
        );
        const { data }: AxiosResponse<IDeleteOrganizationContractResponse> =
          yield* call(
            deleteContract,
            organizationId,
            contractId,
            cancellationToken,
          );

        if (!!data.deletedSubscriptions?.length) {
          yield putResolve(
            setDeletedSubscriptionId([...data.deletedSubscriptions]),
          );
        }
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(ORGANIZATION_CONTRACT_DELETING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default contractsSlice.saga;
export const {
  createOrganizationContract,
  loadOrganizationContracts,
  loadOrganizationContractsList,
  addOrganizationContractNotes,
  editOrganizationContractNotes,
  editContractRenewalStatus,
  deleteOrganizationContract,
  updateOrganizationContract,
  loadOrganizationContract,
  loadContractEditableFields,
  loadOrganizationContractDataForModal,
} = contractsSlice.actions;
export const { actions } = contractsSlice;
