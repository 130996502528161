export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  stateRegion: string;
  zipCode: string;
}

export const createEmptyAddress = (): IAddress => ({
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  country: '',
  latitude: null,
  longitude: null,
  stateRegion: '',
  zipCode: '',
});
