import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  useApiResponseError,
  useFormErrors,
} from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { ICreateSecondaryMemberModel } from '../../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { modalDetailsSelector } from '../../../../../store/redux-slices/modals/selectors';
import { SECONDARY_MEMBER_ADDING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { addSecondaryAAMember } from '../../../../../store/saga-slices/membership/airAmbulance';
import { IAddSecondaryAAMemberPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { includeRemovedProperties } from '../../../../../utils/formInitValuesHelpers';
import { familyMembersAddressInformationsInitState } from '../../../../../styledComponents/Forms/FamilyMember/familyMember.consts';
import { validateFamilyMemberForm } from '../../../../../styledComponents/Forms/FamilyMember/familyMember.validation';
import { ADD_AA_FAMILY_MEMBER_MODAL_NAME } from './addAAFamilyMemberModal.const';
import { AddAAFamilyMemberModalDetails } from './addAAFamilyMemberModal.models';

type UseAddAAFamilyMemberModalType = {
  onFamilyMemberAdded?: () => void;
  id?: string;
};

export const useAddAAFamilyMemberModal = ({
  onFamilyMemberAdded,
  id,
}: UseAddAAFamilyMemberModalType) => {
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);
  const secondaryMemberAdding = useProcessing(
    SECONDARY_MEMBER_ADDING_PROCESSING,
  );
  const alert = useAlert();
  const AddSecondaryMember = useActions(addSecondaryAAMember);

  const onCloseHandler = useCallback(() => {
    UpdateModalState(ADD_AA_FAMILY_MEMBER_MODAL_NAME + id);
  }, [UpdateModalState, id]);

  const details: AddAAFamilyMemberModalDetails = useSelector(
    modalDetailsSelector(ADD_AA_FAMILY_MEMBER_MODAL_NAME + id),
  );

  const { validateErrors, addErrors } = useFormErrors();

  const { handleResponseError } = useApiResponseError({
    withAlert: true,
    externalAddErrors: addErrors,
  });

  const initState = useMemo(
    () => ({
      ...familyMembersAddressInformationsInitState,
    }),
    [],
  );

  const validateForm = useCallback(
    (data: ICreateSecondaryMemberModel) => {
      const currentData = includeRemovedProperties(initState, data);
      return validateFamilyMemberForm(currentData, validateErrors(currentData));
    },
    [validateErrors, initState],
  );

  const handleSubmit = (values: ICreateSecondaryMemberModel) => {
    const payload: IAddSecondaryAAMemberPayload = {
      membershipId: details.membershipId,
      data: values,
      success: () => {
        UpdateModalState(ADD_AA_FAMILY_MEMBER_MODAL_NAME + id);
        onFamilyMemberAdded && onFamilyMemberAdded();
        alert.success(t('alerts.add-secondary-member-success'));
      },
      error: (err) => {
        if (handleResponseError(err, values)) return;

        alert.error(t(`alerts.add-secondary-member-fail`));
      },
    };

    AddSecondaryMember(payload);
  };

  return {
    onCloseHandler,
    validateErrors,
    handleSubmit,
    secondaryMemberAdding,
    validateForm,
    canAddMemberTypes: details?.canAddMemberTypes,
  };
};
