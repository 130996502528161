import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useRouteParams } from '../../../../../../hooks/useRouteParams';
import { MembershipType } from '../../../../../../models/MembershipType';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../models/OrganizationSubscriptionPlanModel';
import { MembershipOrganizationDetailsFormFields } from './AAMembershipOrganizationDetailsForm/aaMembershipOrganizationDetailsForm.models';
import { MembershipTypeFormFields } from './AAMembershipTypeForm/aaMembershipType.models';

export const useAirAmbulanceMembership = () => {
  const {
    input: { value: selectedPlan },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    MembershipOrganizationDetailsFormFields.MembershipPlanId,
  );

  const {
    input: { value: membershipType, onChange: membershipTypeChange },
  }: FieldRenderProps<string> = useField(
    MembershipTypeFormFields.MembershipType,
  );

  const { organizationId } = useRouteParams();

  useEffect(() => {
    if (membershipType) {
      return;
    }
    membershipTypeChange(
      organizationId ? MembershipType.Organization : MembershipType.Retail,
    );
  }, [membershipType, membershipTypeChange, organizationId]);
  return { selectedPlan };
};
