import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg';
import MainTooltip from '../../../UI/MainTooltip/MainTooltip';
import { Wrapper } from './subscriptionCapacity.styles';

interface ISubscriptionCapacityProps {
  value: string;
  isOversubscribed?: boolean;
}

const SubscriptionCapacity = ({
  value,
  isOversubscribed,
}: ISubscriptionCapacityProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div>{value}</div>
      {isOversubscribed && (
        <MainTooltip title={t('phrase.oversubscribed')}>
          <WarningIcon />
        </MainTooltip>
      )}
    </Wrapper>
  );
};
export default SubscriptionCapacity;
