import { useTranslation } from 'react-i18next';

import { OrganizationSubscriptionStatus } from '../../models/enums/OrganizationSubscriptionStatus';
import { IOrganizationSubscription } from '../../models/interfaces/Subscription/OrganizationSubscription';
import CollapseContent from '../UI/CollapseContent/CollapseContent';

import { GroupedContentWrapper } from './subscriptionList.styles';
import SubscriptionsList from './SubscriptionsList';
type GroupedSubscriptionsListProps = {
  subscriptions: Partial<
    Record<OrganizationSubscriptionStatus, IOrganizationSubscription[]>
  >;
  onItemClick?: (subscription: IOrganizationSubscription) => void;
  propertiesWidth?: Partial<Record<keyof IOrganizationSubscription, number>>;
};

const GroupedSubscriptionsList = ({
  subscriptions,
  onItemClick,
  propertiesWidth,
}: GroupedSubscriptionsListProps) => {
  const { t } = useTranslation();
  return (
    <GroupedContentWrapper>
      {!!subscriptions[OrganizationSubscriptionStatus.Draft].length && (
        <CollapseContent title={t('headers.draft')}>
          <SubscriptionsList
            items={subscriptions.Draft}
            onItemClick={onItemClick}
            propertiesWidth={propertiesWidth}
          />
        </CollapseContent>
      )}
      {!!subscriptions[OrganizationSubscriptionStatus.Active].length && (
        <CollapseContent title={t('headers.active')} defaultIsOpen>
          <SubscriptionsList
            items={subscriptions.Active}
            onItemClick={onItemClick}
            propertiesWidth={propertiesWidth}
          />
        </CollapseContent>
      )}
      {!!subscriptions[OrganizationSubscriptionStatus.Expired].length && (
        <CollapseContent title={t('headers.expired')}>
          <SubscriptionsList
            items={subscriptions.Expired}
            onItemClick={onItemClick}
            propertiesWidth={propertiesWidth}
          />
        </CollapseContent>
      )}
    </GroupedContentWrapper>
  );
};

export default GroupedSubscriptionsList;
