import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useMenuList } from './menuList.hooks';
import { AddButton, SelectAllButton } from './menuList.styles';

type Props = {
  children: ReactNode | ReactElement;
  selectProps: any;
  isMulti?: boolean;
  selectOption: (value: string) => void;
  addActionPermissions?: string[];
  setValue: (values: any) => void;
  options: any[];
};

export const MenuList = ({
  children,
  selectProps: {
    onAddButtonClick,
    value,
    addNewButtonLabel,
    defaultAddButtonText,
    addActionPermissions,
    isMulti,
    isCheckAllOption,
    options,
  },
  ...props
}: Props) => {
  const buttonCLickHandler = () => {
    props.selectOption(value);
    onAddButtonClick();
  };
  const { t } = useTranslation();
  const { setValue } = props;
  const { isNotAll, allClickHandler } = useMenuList({
    children,
    options,
    setValue,
  });

  return (
    <>
      {isMulti && isCheckAllOption && (
        <SelectAllButton
          checked={!isNotAll}
          onClick={allClickHandler}
          type="button"
        >
          {t('filters.check-all')}
        </SelectAllButton>
      )}
      {children}
      {onAddButtonClick && (
        <AddButton
          permissions={addActionPermissions}
          onClick={buttonCLickHandler}
          type="button"
        >
          {addNewButtonLabel || defaultAddButtonText}
        </AddButton>
      )}
    </>
  );
};
