import { Field } from 'react-final-form';

import { getFieldPropertiesForGroup } from '../../../../utils/getFormGroupPath';
import { Wrapper, StyledFormCheckbox } from './formCheckboxGroup.styles';
import { IFormCheckboxGroupProps } from './formCheckboxGroup.models';

const FormCheckboxGroup = ({
  className,
  disabled,
  namePrefix,
  options,
  layout = 'row',
}: IFormCheckboxGroupProps) => (
  <Wrapper layout={layout} className={className}>
    {options?.map(
      ({ checkboxContentRenderer = null, name, optionDisabled, label }) => (
        <Field
          layout={layout}
          checkboxContentRenderer={checkboxContentRenderer}
          component={StyledFormCheckbox}
          disabled={optionDisabled || disabled}
          key={name}
          label={label}
          {...getFieldPropertiesForGroup({
            groupName: namePrefix,
            name,
            inputId: 'checkbox',
          })}
          type="checkbox"
          defaultValue={false}
        />
      ),
    )}
  </Wrapper>
);

export default FormCheckboxGroup;
