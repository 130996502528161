import { KidnapAndExtortionMembershipOptions } from '../../../../../models/enums/KidnapAndExtortionMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const EDIT_KE_MEMBERSHIP_OPTIONS_MODAL =
  'EDIT_KE_MEMBERSHIP_OPTIONS_MODAL';

export const keMembershipOptionsList: IFormCheckboxGroupOptionProps[] = [
  {
    name: KidnapAndExtortionMembershipOptions.SendWelcomeEmail,
    optionDisabled: true,
  },
  {
    name: KidnapAndExtortionMembershipOptions.EmailOptOut,
  },
];
