import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ICardSetupIntentModel } from '../models/ICardSetupIntentModel';
import { ICreditCardInfo } from '../models/ICreditCardInfo';

import { IPaymentIntentModel } from '../models/IPaymentIntentModel';
import { updateProcessState } from '../store/redux-slices/processes';
import { PAYMENT_CAPTURE_PROCESSING } from '../store/redux-slices/processes/constants';
import { useActions } from './useActions';

export default function useStripeProvider() {
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation();

  const UpdateProcessState = useActions(updateProcessState);

  const payWithStripe = useCallback(
    async (
      paymentIntentInfo: IPaymentIntentModel,
      creditCardInfo: ICreditCardInfo,
      successCallback: any,
      failCallback: any,
    ) => {
      UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe.confirmCardPayment(
          paymentIntentInfo.clientSecret,
          {
            setup_future_usage: 'off_session',
            payment_method: {
              card: elements.getElement('cardNumber'),
              billing_details: {
                name: creditCardInfo.cardHolderName,
              },
            },
          },
        );

        if (result.error) {
          failCallback({
            alertErrorMessage: t('alerts.card-payment-failed'),
          });
        } else {
          successCallback();
        }
      } finally {
        UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      }
    },
    [UpdateProcessState, elements, stripe, t],
  );

  const confirmCardPayment = useCallback(
    async (
      paymentIntentInfo: IPaymentIntentModel,
      cardId: string,
      successCallback: () => void,
      failCallback: (data: { alertErrorMessage: string }) => void,
    ) => {
      UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe.confirmCardPayment(
          paymentIntentInfo.clientSecret,
          {
            setup_future_usage: 'off_session',
            payment_method: cardId,
          },
        );

        if (result.error) {
          failCallback({
            alertErrorMessage: t('alerts.card-payment-failed'),
          });
        } else {
          successCallback();
        }
      } finally {
        UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      }
    },
    [UpdateProcessState, elements, stripe, t],
  );

  const submitCardWithStripe = useCallback(
    async (
      paymentIntentInfo: ICardSetupIntentModel,
      creditCardInfo: ICreditCardInfo,
      successCallback: any,
      failCallback: any,
    ) => {
      UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe.confirmCardSetup(
          paymentIntentInfo.clientSecret,
          {
            payment_method: {
              card: elements.getElement('cardNumber'),
              billing_details: {
                name: creditCardInfo.cardHolderName,
              },
            },
          },
        );

        if (result.error) {
          failCallback({
            alertErrorMessage: t(
              'phrase.credit-card-was-not-added-successfully',
            ),
          });
        } else {
          successCallback();
        }
      } finally {
        UpdateProcessState(PAYMENT_CAPTURE_PROCESSING);
      }
    },
    [UpdateProcessState, elements, stripe, t],
  );

  return { payWithStripe, submitCardWithStripe, confirmCardPayment };
}
