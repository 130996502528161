import { useTranslation } from 'react-i18next';

import FormErrorProvider from '../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import AddPromoCodeForm from './AddPromoCodeForm/AddPromoCodeForm';
import { useAddPromoCodePage } from './addPromoCodePage.hooks';

const AddPromoCodePage = () => {
  const { t } = useTranslation();
  useAddPromoCodePage();

  return (
    <FormErrorProvider>
      <Header title={t('headers.add-new-promo-code')} />
      <AddPromoCodeForm />
    </FormErrorProvider>
  );
};

export default AddPromoCodePage;
