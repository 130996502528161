import { useCallback } from 'react';

type UseOverviewOptionsProps<T> = {
  data: T;
  options: { value: string; label?: string }[];
  onEditOptionsClick?: (options: any) => void;
};

export const useOverviewOptions = <T>({
  data,
  onEditOptionsClick,
  options,
}: UseOverviewOptionsProps<T>) => {
  const onEditOptionsClickHandler = useCallback(() => {
    const optionsObject: any = {};
    options.forEach(
      (option) => (optionsObject[option.value] = data[option.value]),
    );
    onEditOptionsClick?.(optionsObject);
  }, [data, onEditOptionsClick, options]);

  return { onEditOptionsClickHandler };
};
