import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFilters } from '../../../../hooks/useFilters';
import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { SelectOption } from '../../../../models/SelectOption';

import { subscriptionsListSelector } from '../../../../store/redux-slices/subscriptions/common/selectors';
import { formatDate } from '../../../../utils/dateHelpers';
import { AAMembersListFilters } from './aaMembersFilters.models';

type UseAAMembersFiltersProps = {
  standardPricing?: IMembershipPlanPriceModel[];
};

export const useAAMembersFilters = ({
  standardPricing,
}: UseAAMembersFiltersProps) => {
  const subscriptionsList = useSelector(subscriptionsListSelector);

  const { addFilter, filters } = useFilters<AAMembersListFilters>();

  const onFilterChangeHandler = useCallback(
    (evt: any, filterType: AAMembersListFilters) => {
      switch (filterType) {
        case AAMembersListFilters.Search:
          addFilter(AAMembersListFilters.Search, evt.target.value);
          return;
        case AAMembersListFilters.Plan:
          addFilter(
            AAMembersListFilters.Plan,
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case AAMembersListFilters.MemberType:
          addFilter(
            AAMembersListFilters.MemberType,
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case AAMembersListFilters.RenewStatus:
          addFilter(AAMembersListFilters.RenewStatus, evt ? evt.value : null);
          return;
        case AAMembersListFilters.SubscriptionId:
          addFilter(
            AAMembersListFilters.SubscriptionId,
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case AAMembersListFilters.OversubscribedOnly:
          addFilter(
            AAMembersListFilters.OversubscribedOnly,
            evt.target.checked,
          );
      }
    },
    [addFilter],
  );

  const plansOptions = useMemo(
    () =>
      standardPricing?.map((plan) => ({
        label: plan.membershipPlanName,
        value: plan.membershipPlanName,
      })),
    [standardPricing],
  );
  const subscriptionsListOptions = useMemo(
    () =>
      subscriptionsList?.map((subscription) => ({
        label: `AA ${formatDate(subscription.effectiveDateFrom)} - ${formatDate(
          subscription.effectiveDateTo,
        )}`,
        value: subscription.id,
      })),
    [subscriptionsList],
  );

  return {
    plansOptions,
    filters,
    onFilterChangeHandler,
    subscriptionsListOptions,
  };
};
