import { useTranslation } from 'react-i18next';

import OverviewOptions from '../../../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLine,
  Wrapper,
} from './srSubscriptionOverviewInformation.styles';
import {
  canRenewSRSubscriptionPermissions,
  canUpdateSRSubscriptionsDetailsPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';
import SROverviewDetails from './SROverviewDetails/SROverviewDetails';
import { useSRSubscriptionOverviewInformation } from './srSubscriptionOverviewInformation.hooks';
import { ISROrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOverview';
import { SecurityResponseSubscriptionOptions } from '../../../../../../models/enums/SecurityResponseSubscriptionOptions';

interface IProductSubscriptionOverviewInformationProps {
  onEditOptionsClick?: () => void;
  onEditRenewalStatus?: () => void;
  subscriptionOverview: ISROrganizationSubscriptionOverview;
  canEditOptions?: boolean;
  canRenew?: boolean;
}
const SRSubscriptionOverviewInformation = ({
  onEditOptionsClick,
  onEditRenewalStatus,
  subscriptionOverview,
  canEditOptions,
  canRenew,
}: IProductSubscriptionOverviewInformationProps) => {
  const { t } = useTranslation();
  const { renewClickHandler } = useSRSubscriptionOverviewInformation();

  return (
    <Wrapper>
      <SROverviewDetails
        data={subscriptionOverview}
        canRenewMembershipPermissions={canRenewSRSubscriptionPermissions}
        onRenewClick={canRenew && renewClickHandler}
        onEditRenewalStatus={onEditRenewalStatus}
      />
      <StyledLine />
      <OverviewOptions
        options={[
          {
            value: SecurityResponseSubscriptionOptions.AutoRenewal,
            label: t('phrase.auto-renew-product-subscription'),
          },
        ]}
        data={subscriptionOverview}
        onEditOptionsClick={canEditOptions && onEditOptionsClick}
        canEditOptionsPermissions={canUpdateSRSubscriptionsDetailsPermissions}
      />
    </Wrapper>
  );
};

export default SRSubscriptionOverviewInformation;
