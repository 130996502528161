import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { RoutePath } from '../../models/enums/RoutePath';

import { Log } from '../../utils/logger';

export const useNotFoundPage = () => {
  const navigate = useNavigateSearch();
  const { pathname, state } = useLocation();

  const goToHomePage = () => {
    navigate(RoutePath.Home);
  };

  useEffect(() => {
    Log.warning(`Page (${pathname}) not found. ${state}`);
  }, [pathname, state]);

  return { goToHomePage };
};
