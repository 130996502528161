import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { subscriptionRenewDetailsCommonFormSchema } from '../../Common/SubscriptionRenewDetailsCommonForm/subscriptionRenewDetailsCommonForm.validation';

interface IValidateSubscriptionDetailsForm {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  promoCode: string;
  subscriptionType: string;
}

export const keRenewSubscriptionDetailsSchema: AnySchema | any = {
  ...subscriptionRenewDetailsCommonFormSchema,
};

export const validateKERenewSubscriptionDetailsForm = (
  data: IValidateSubscriptionDetailsForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, keRenewSubscriptionDetailsSchema);
