import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { IOrganizationSubscriptionPlansModel } from '../common/models';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMembershipsModel } from '../../../../models/IRenewOrganizationSubscriptionMembershipsModel';
import { IDOCOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { IEditDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/EditDOCOrganizationSubscriptionModel';
import { IDOCOrganizationSubscriptionOptionsModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOptionsModel';
import { ICreateDOCOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/CreateDOCOrganizationSubscriptionExtensionModel';
import { IDOCSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCSubscriptionRenewalSuggestionModel';
import { RenewDOCSubscriptionModel } from '../../../../models/types/DutyOfCare/RenewDOCSubscriptionModel';
import { IDOCOrganizationSubscriptionPackageOptionsModel } from '../../../../models/interfaces/Subscription/DutyOfCare/OrganizationSubscriptionPackageOptionsModel';
import { IDOCOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionExtensionSummaryModel';
import { IChangeSubscriptionPackageModel } from '../../../../models/interfaces/Subscription/DutyOfCare/ChangeDOCSubscriptionCapacityModel ';
import { RenewalStatus } from '../../../../models/enums/RenewalStatus';

export const getDOCSubscription = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IDOCOrganizationSubscriptionOverview>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const getDatesForNewDOCSubscription = (
  organizationId: string,
  contractId: string,
): Promise<AxiosResponse<ISubscriptionDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'contractId',
      value: contractId,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/newDates${params}`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );
};

export const getDOCSubscriptionSuggestion = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IEditDOCOrganizationSubscriptionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/suggestion`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getDOCSubscriptionSummary = (
  organizationId: string,
  data: IDOCOrganizationSubscriptionPackageOptionsModel,
): Promise<AxiosResponse<ISubscriptionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/summary`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const updateDOCSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IEditDOCOrganizationSubscriptionModel,
): Promise<AxiosResponse> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const deleteDOCSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const updateDOCSubscriptionDetails = (
  organizationId: string,
  subscriptionId: string,
  subscriptionDetails: IDOCOrganizationSubscriptionOptionsModel,
): Promise<AxiosResponse<IDOCOrganizationSubscriptionOverview>> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/details`,
    subscriptionDetails,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const extendDOCSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  subscriptionExtension: ICreateDOCOrganizationSubscriptionExtensionModel,
): Promise<AxiosResponse<IDOCOrganizationSubscriptionOverview>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/packageoptions`,
    subscriptionExtension,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const getDOCSubscriptionExtensionSummary = (
  organizationId: string,
  subscriptionId: string,
  data: IChangeSubscriptionPackageModel,
): Promise<AxiosResponse<IDOCOrganizationSubscriptionExtensionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update/summary`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const getDOCSubscriptionPlans = (
  organizationId: string,
  subscriptionId: string,
  primaryMemberBirthDate?: string,
  membershipEffectiveDate?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscriptionPlansModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'primaryMemberBirthDate',
      value: primaryMemberBirthDate,
    },
    {
      name: 'membershipEffectiveDate',
      value: membershipEffectiveDate,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/plans${params}`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
      signal: cancellationToken,
    },
  );
};

export const getDOCSubscriptionRenewalSuggestion = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IDOCSubscriptionRenewalSuggestionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalsuggestion`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const renewDOCSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: RenewDOCSubscriptionModel,
): Promise<AxiosResponse<IOrganizationSubscription>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renew`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const getDOCSubscriptionRenewalAnalyseRequest = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/renewalanalysis`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const sendDOCSubscriptionRenewalMembershipsRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IRenewOrganizationSubscriptionMembershipsModel,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionMembershipsModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/toberenewed`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const editDoCSubscriptionRenewalStatusRequest = (
  organizationId: string,
  subscriptionId: string,
  renewalStatus: RenewalStatus,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(
    `/api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalstatus`,
    { renewalStatus },
    {
      signal: cancellationToken,
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );
