import { ReactNode } from 'react';

import { SelectOption } from '../../../../../../../models/SelectOption';
import { CustomFilter } from '../../../renewalFilters.models';

export enum SelectFilterFields {
  Value = 'value',
}

export type SelectFilterModel = CustomFilter;

export type SelectFilterOptionsModel = {
  valueRenderer?: (label: string) => ReactNode;
  options?: SelectOption[];
  valueTagLabelPath?: string;
  checkAllButton?: boolean;
};
