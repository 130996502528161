import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';

import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  AddMemberAvailableSearchParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
} from '../../../../store/redux-slices/processes/constants';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { IMemberInfoModel } from '../../../../models/MemberInfoModel';
import { personNameSelector } from '../../../../store/redux-slices/people/selectors';
import { ProductType } from '../../../../models/ProductType';
import { MemberOverviewPageParamsType } from '../../../../models/types/RoutePath';
import {
  changeDOCMemberRenewFlag,
  loadDOCMembershipDetails,
} from '../../../../store/saga-slices/membership/dutyOfCare';
import {
  IChangeDOCMemberRenewFlagPayload,
  ILoadDOCMembershipDetailsPayload,
} from '../../../../store/saga-slices/membership/dutyOfCare/models';
import { DELETE_DOC_MEMBERSHIP_MODAL_NAME } from '../Modals/DeleteDOCMembershipModal/deleteDOCMembershipModal.const';
import { EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL } from '../Modals/EditDOCMembershipOptionsModal/editDOCMembershipOptionsModal.const';
import { IDOCMembershipOptions } from '../../../../models/interfaces/Membership/DutyOfCare/DOCMembershipModel';
import { EditDOCMembershipOptionsModalDetails } from '../Modals/EditDOCMembershipOptionsModal/editDOCMembershipOptionsModal.models';
import { ResendDOCWelcomeEmailModalDetails } from '../Modals/ResendDOCWelcomeEmailModal/resendDOCWelcomeEmailModal.models';
import { RESEND_DOC_WELCOME_EMAIL_MODAL_NAME } from '../Modals/ResendDOCWelcomeEmailModal/resendDOCWelcomeEmailModal.const';
import { docMembershipDetailsByIdSelector } from '../../../../store/redux-slices/membership/dutyOfCare/selectors';

export const useDOCMembershipDetails = (
  membershipId?: string,
  productType?: ProductType,
) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigateSearch();

  const LoadMembershipDetails = useActions(loadDOCMembershipDetails);
  const ChangeMemberRenewFlag = useActions(changeDOCMemberRenewFlag);
  const UpdateModalState = useActions(updateModalState);

  const dataLoading = useProcessing([
    LOAD_MEMBERSHIP_DETAILS + membershipId,
    CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
  ]);
  const membershipDetails = useSelector(
    docMembershipDetailsByIdSelector(membershipId),
  );
  const personName = useSelector(personNameSelector);

  const canBeRenewed = membershipDetails?.metadata?.canRenew;

  const getMembershipDetails = useCallback(() => {
    const payload: ILoadDOCMembershipDetailsPayload = {
      membershipId,
    };
    LoadMembershipDetails(payload);
  }, [LoadMembershipDetails, membershipId]);

  useEffect(() => {
    if (membershipDetails) {
      return;
    }
    getMembershipDetails();
  }, [getMembershipDetails, membershipDetails]);

  const deleteMembershipHandler = useCallback(() => {
    UpdateModalState([
      DELETE_DOC_MEMBERSHIP_MODAL_NAME + membershipId,
      {
        membershipId,
        productType,
        personName,
      },
    ]);
  }, [UpdateModalState, membershipId, personName, productType]);

  const changeRenewFlagHandler = useCallback(
    (memberId: string, toBeRenewed: boolean) => {
      const payload: IChangeDOCMemberRenewFlagPayload = {
        membershipId,
        memberId,
        toBeRenewed,
        success: () => {
          getMembershipDetails();

          alert.success(t('alerts.renew-flag-updated'));
        },
      };
      ChangeMemberRenewFlag(payload);
    },
    [ChangeMemberRenewFlag, alert, getMembershipDetails, membershipId, t],
  );

  const resendWelcomeEmailHandler = useCallback(
    (personId: string) => {
      const details: ResendDOCWelcomeEmailModalDetails = {
        membershipId,
        personId,
      };
      UpdateModalState([
        RESEND_DOC_WELCOME_EMAIL_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberInfoModel) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, {
        params,
      });
    },
    [navigate],
  );

  const onEditMembershipOptionsHandler = useCallback(
    (membershipOptions: IDOCMembershipOptions) => {
      const details: EditDOCMembershipOptionsModalDetails = {
        membershipId,
        membershipOptions,
      };
      UpdateModalState([
        EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  return {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    canBeRenewed,
    resendWelcomeEmailHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  };
};

type UseMemberOverviewMemberRenew = {
  membershipId?: string;
  productType?: ProductType;
};

export const useDOCMembershipRenew = ({
  membershipId,
  productType,
}: UseMemberOverviewMemberRenew) => {
  const navigate = useNavigateSearch();
  const renewMembershipHandler = useCallback(() => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.MembershipId]: membershipId,
        [AddMemberAvailableSearchParams.ProductType]: productType,
      },
    });
  }, [membershipId, navigate, productType]);

  return { renewMembershipHandler };
};
