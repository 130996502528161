import styled from '@emotion/styled/macro';
import isPropValid from '@emotion/is-prop-valid';
import { transparentize } from 'polished';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';

import { THEME } from '../../../config/theme';

export const Wrapper = styled.li`
  width: 100%;
`;

type NavLinkType = {
  isActive?: boolean;
};

const ActiveNavLink = css`
  position: relative;
  background-color: ${transparentize(0.8, THEME.color.secondary2)};
  &:after {
    content: '';
    height: 100%;
    width: 0.25rem;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${THEME.color.secondary2};
  }
`;

export const StyledNavLink = styled(NavLink, {
  shouldForwardProp: isPropValid,
})<NavLinkType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625rem 0.5rem;
  text-decoration: none;
  gap: 0.875rem;
  ${({ isActive }) => isActive && ActiveNavLink}
  &[aria-current] {
    ${ActiveNavLink}
  }
`;

export const Description = styled.p`
  font: normal normal 0.813rem/1.313rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  white-space: nowrap;
`;
