import { Modal } from '../../Modal/Modal';
import { ADD_CONTRACT_MODAL_NAME } from './addContractModal.const';

import AddContractModalForm from './AddContractModalForm/AddContractModalForm';
import { useProcessing } from '../../../hooks/useProcessing';
import { ORGANIZATION_CONTRACT_ADDING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import FormErrorProvider from '../../FormErrorProvider/FormErrorProvider';

interface IAddContractModalProps {
  onContractAdded?: () => void;
  onModalCancel?: () => void;
}

const AddContractModal = ({
  onModalCancel,
  onContractAdded,
}: IAddContractModalProps) => {
  const contractAdding = useProcessing(ORGANIZATION_CONTRACT_ADDING_PROCESSING);

  return (
    <FormErrorProvider>
      <Modal
        disabled={contractAdding}
        name={ADD_CONTRACT_MODAL_NAME}
        onModalClose={onModalCancel}
      >
        <AddContractModalForm
          onContractAdded={onContractAdded}
          onCancel={onModalCancel}
        />
      </Modal>
    </FormErrorProvider>
  );
};

export default AddContractModal;
