import { all } from 'redux-saga/effects';

import wizardSaga from './wizard/index';
import personSaga from './people/index';
import organizationsSaga from './organizations';
import employeesSaga from './employees';
import contractsSaga from './contracts';
import membershipSagasRoot from './membership';
import paymentSaga from './payment';
import promoCodesSaga from './promoCodes';
import errorSlice from './error';
import routesHistorySlice from './routesHistory';
import authSlice from './auth';
import rolesSaga from './roles';
import subscriptionsSagasRoot from './subscriptions';
import pricingSagasRoot from './pricing';
import reportsSagasRoot from './reports';
import packagesSagasRoot from './packages';
import renewalFilterSagasRoot from './renewalFilter';

export default function* rootSaga() {
  yield all([
    wizardSaga(),
    personSaga(),
    organizationsSaga(),
    employeesSaga(),
    contractsSaga(),
    paymentSaga(),
    promoCodesSaga(),
    rolesSaga(),
    errorSlice(),
    routesHistorySlice(),
    authSlice(),
    renewalFilterSagasRoot(),
    ...membershipSagasRoot,
    ...subscriptionsSagasRoot,
    ...pricingSagasRoot,
    ...reportsSagasRoot,
    ...packagesSagasRoot,
  ]);
}
