import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { CreateMembershipModel } from '../../../../models/types/CreateMembershipModel';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { ICreateSecondaryMemberModel } from '../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import {
  IResendWelcomeEmailData,
  IResendWelcomeEmailModel,
} from '../../../../models/interfaces/ResendWelcomeEmailModel';
import { MEMBERSHIP_ACTIVE_RATIFICATION_PARAM } from './const';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { ISRMembershipModel } from '../../../../models/interfaces/Membership/SecurityResponse/SRMembershipModel';
import { IUpdateSRMembershipOptionsModel } from '../../../../models/interfaces/Membership/SecurityResponse/UpdateSRMembershipOptionsModel';

export const createSROrganizationMembership = (
  organizationId: string,
  subscriptionId: string,
  data: CreateMembershipModel,
): Promise<AxiosResponse<string>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const analyzeSRCreateMembership = (
  data: CreateMembershipModel,
): Promise<AxiosResponse> =>
  axios.post(`api/memberships/newmembershipanalysis`, data, {
    baseURL: config.securityResponseApi.baseUrl,
  });

export const getSRMembershipSummary = (
  organizationId: string,
  subscriptionId: string,
  membershipPlanId: string,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/membershipPlans/${membershipPlanId}/summary`,
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const getDatesForNewSRMemberships = (
  subscriptionId?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IMembershipDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'subscriptionId',
      value: subscriptionId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`api/memberships/newmembershipdates${params}`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });
};

export const getSRRetailMembershipPlanSummary = (
  membershipPlanId: string,
  promoCode: string,
  effectiveDate: string,
  autoRenewal?: boolean,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'membershipPlanId',
      value: membershipPlanId,
    },
    {
      name: 'promoCode',
      value: promoCode,
    },
    {
      name: 'effectiveDate',
      value: effectiveDate,
    },
    {
      name: 'autoRenewal',
      value: autoRenewal,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`api/memberships/summary${params}`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });
};

export const getSRExistingMembershipSummary = (
  membershipId: string,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> =>
  axios.get(`api/memberships/${membershipId}/summary`, {
    baseURL: config.securityResponseApi.baseUrl,
  });

export const getSRMembershipDetails = (
  membershipId: string,
): Promise<AxiosResponse<ISRMembershipModel>> =>
  axios.get(`api/memberships/${membershipId}`, {
    baseURL: config.securityResponseApi.baseUrl,
  });

export const putSRMemberToBeRenewed = (
  membershipId: string,
  memberId: string,
  toBeRenewed: boolean,
): Promise<AxiosResponse> =>
  axios.put(
    `api/memberships/${membershipId}/members/${memberId}`,
    { toBeRenewed },
    {
      baseURL: config.securityResponseApi.baseUrl,
    },
  );

export const updateSRMembershipOptionsRequest = (
  membershipId: string,
  options: IUpdateSRMembershipOptionsModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ISRMembershipModel>> =>
  axios.put(`api/memberships/${membershipId}/options`, options, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });

export const addSRSecondaryMemberRequest = (
  membershipId: string,
  data: ICreateSecondaryMemberModel,
): Promise<AxiosResponse> =>
  axios.post(`api/memberships/${membershipId}/members`, data, {
    baseURL: config.securityResponseApi.baseUrl,
  });

export const deleteSRSecondaryMemberRequest = (
  membershipId: string,
  memberId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`api/memberships/${membershipId}/secondarymember/${memberId}`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });

export const postResendSRWelcomeEmail = (
  membershipId: string,
  memberId: string,
  data: IResendWelcomeEmailData,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IResendWelcomeEmailModel>> =>
  axios.post(
    `api/memberships/${membershipId}/members/${memberId}/welcomeEmail`,
    data,
    {
      baseURL: config.securityResponseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const deleteSRMembershipRequest = (
  membershipId: string,
  isActive: boolean,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/memberships/${membershipId}${
      isActive ? MEMBERSHIP_ACTIVE_RATIFICATION_PARAM : ''
    }`,
    {
      baseURL: config.securityResponseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getSRMembershipsMembers = (
  organizationId: string,
  params?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IPageListModel<IMemberListItem>>> => {
  const queryParams: QueryParamItem[] = [
    { name: 'organizationId', value: organizationId },
  ];

  const currentParams = prepareQueryParams({
    initQueryString: params,
    queryParams,
  });

  return axios.get(`api/members${currentParams}`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });
};
