import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEmployee } from '../../../models/Employee';

export interface IEmployeesState {
  items: IEmployee[];
}

const initialState: IEmployeesState = {
  items: [],
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEmployees: (state, { payload }: PayloadAction<IEmployee[]>) => {
      state.items = payload;
    },
  },
});

export const { setEmployees } = employeesSlice.actions;
export default employeesSlice.reducer;
export const personReducerName = employeesSlice.name;
