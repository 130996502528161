import { ReactNode } from 'react';

import { AvailableStatus } from '../../../models/enums/AvailableStatus';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { HeaderWrapper } from '../../Header/Header';
import BackButton from '../../UI/BackButton/BackButton';
import { Status } from '../../UI/Status/Status';
import { useHeaderPageDetails } from './headerPageDetails.hooks';

import {
  HeaderTitle,
  HeaderTitleWrapper,
  IconContainer,
} from './headerPageDetails.styles';

type Props = {
  title?: ReactNode;
  icon?: ReactNode;
  defaultUrl?: string;
  bottomLineIsHidden?: boolean;
  children?: ReactNode;
  status?: AvailableStatus;
  statusComponent?: ReactNode;
  isBackButtonHidden?: boolean;
} & CustomComponent;

const HeaderPageDetails = ({
  title = '',
  icon,
  defaultUrl: url,
  bottomLineIsHidden,
  status,
  statusComponent,
  isBackButtonHidden,
  children,
}: Props) => {
  const { backButtonNavigate, isBackButtonVisible } = useHeaderPageDetails(url);

  return (
    <HeaderWrapper bottomLineIsVisible={!bottomLineIsHidden}>
      {!isBackButtonHidden && isBackButtonVisible && (
        <BackButton onClick={backButtonNavigate} />
      )}
      <HeaderTitleWrapper>
        {icon && <IconContainer>{icon}</IconContainer>}
        {children || <HeaderTitle>{title}</HeaderTitle>}
      </HeaderTitleWrapper>
      {statusComponent || (status && <Status status={status} />)}
    </HeaderWrapper>
  );
};

export default HeaderPageDetails;
