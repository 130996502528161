import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { KESubscriptionOptions } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';
import RenewSubscriptionItemForm from '../../RenewSubscriptionItemForm/RenewSubscriptionItemForm';
import { RenewSubscriptionItemBaseProps } from '../../RenewSubscriptionItemForm/renewSubscriptionItemForm.models';
import { Wrapper } from './kidnapAndExtortionRenewSubscriptionForm.styles';
import { KERenewSubscriptionDetails } from './KERenewSubscriptionDetails/KERenewSubscriptionDetails';
import { KERenewSubscriptionPricing } from './KERenewSubscriptionPricing/KERenewSubscriptionPricing';

const KidnapAndExtortionRenewSubscriptionForm = ({
  name,
  disabled,
  className,
  isEditMode,
}: RenewSubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const productType = ProductType.KidnapAndExtortion;
  return (
    <RenewSubscriptionItemForm collapsible={false} productType={productType}>
      <Wrapper className={className}>
        <KERenewSubscriptionDetails disabled={disabled} />
        <KERenewSubscriptionPricing
          disabled={disabled}
          productType={productType}
        />
        <SubscriptionOptions
          subscriptionOptions={KESubscriptionOptions}
          layout="column"
          disabled={disabled}
          title={t('headers.options')}
        />
      </Wrapper>
    </RenewSubscriptionItemForm>
  );
};

export default KidnapAndExtortionRenewSubscriptionForm;
