import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../Form/Form';

import Button from '../../UI/Button/Button';
import { Line } from '../../UI/Line/Line';

export const StyledLine = styled(Line)`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  margin-top: 1.25rem;
`;

export const Wrapper = styled(SectionWrapper)`
  margin-top: 0;
`;
