import { useEffect, useMemo } from 'react';

import TabOption, { ITabOptionProps } from './TabOption/TabOption';
import { Wrapper } from './tabOptions.styles';

export interface ITabOptionsProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (value: string) => void;
  options: ITabOptionProps[];
  value?: string;
}

const TabOptions = ({
  className,
  defaultValue,
  disabled,
  onChange,
  options,
  value,
  readonly,
}: ITabOptionsProps) => {
  useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
  });

  const renderOptions = useMemo(
    () =>
      options.map((option) => (
        <TabOption
          {...option}
          key={option.value}
          disabled={disabled}
          isActive={option.value === value}
          readonly={readonly}
          onClick={onChange}
        />
      )),
    [disabled, onChange, options, readonly, value],
  );
  return <Wrapper className={className}>{renderOptions}</Wrapper>;
};

export default TabOptions;
