import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call } from 'typed-redux-saga/macro';
import { put } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  ROLES_LOADING_PROCESSING,
  GG_ROLES_LOADING_PROCESSING,
} from '../../redux-slices/processes/constants';

import { Log } from '../../../utils/logger';
import { setRoles, setGGRoles } from '../../redux-slices/roles';
import { getGGRolesList, getRolesList } from './api';
import { GlobalGuardianPointOfContactRoles } from '../../../models/enums/GlobalGuardianPointOfContactRoles';
import { PointOfContactRoles } from '../../../models/enums/PointOfContactRoles';
import { ILoadGGRolesPayload, ILoadRolesPayload } from './models';

const rolesSlice = createSliceSaga({
  caseSagas: {
    *loadRoles({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadRolesPayload>) {
      try {
        yield put(updateProcessState(ROLES_LOADING_PROCESSING));
        const response: AxiosResponse<PointOfContactRoles[]> = yield* call(
          getRolesList,
          cancellationToken,
        );
        yield put(setRoles(response.data));
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(ROLES_LOADING_PROCESSING));
      }
    },
    *loadGGRoles({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadGGRolesPayload>) {
      try {
        yield put(updateProcessState(GG_ROLES_LOADING_PROCESSING));
        const response: AxiosResponse<GlobalGuardianPointOfContactRoles[]> =
          yield* call(getGGRolesList, cancellationToken);
        yield put(setGGRoles(response.data));
        success?.();
      } catch (err: any) {
        Log.error(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(GG_ROLES_LOADING_PROCESSING));
      }
    },
  },
  name: 'roles-saga',
  sagaType: SagaType.TakeLatest,
});

export default rolesSlice.saga;
export const { loadRoles, loadGGRoles } = rolesSlice.actions;
export const { actions } = rolesSlice;
