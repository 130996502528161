import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import TableRow from '@mui/material/TableRow';
import { transparentize } from 'polished';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import TableCell from '@mui/material/TableCell';

import { transientOptions } from '../../../utils/transientOptions';

type StyledTableRowProps = {
  $isClickable?: boolean;
};

export const StyledTableRow = styled(
  TableRow,
  transientOptions,
)<StyledTableRowProps>`
  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.color.secondary2)};
  }
  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const TableRowNoContent = styled(
  TableRow,
  transientOptions,
)<StyledTableRowProps>`
  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.8, theme.color.secondary2)};
  }
  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const TableCellTooltip = styled((props: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement="top"
    classes={{ popper: props.className }}
  />
))`
  & .${tooltipClasses.tooltip} {
    padding: 1rem;
    background-color: ${({ theme }) => theme.color.dark1};
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.color.dark1};
  }
`;

type StyledTableCellProps = {
  $isClickable?: boolean;
};

export const StyledTableCell = styled(
  TableCell,
  transientOptions,
)<StyledTableCellProps>`
  color: ${({ theme }) => theme.color.dark1};
  border-color: ${({ theme }) => theme.color.light1};
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  ${({ $isClickable, theme }) =>
    $isClickable &&
    css`
      cursor: pointer;
      font-weight: 700;
      &:hover {
        background-color: ${transparentize(0.8, theme.color.secondary2)}
  `}
`;
