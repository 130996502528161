import * as airAmbulancePolices from './airAmbulance/aaAccessPolicies';
import * as kidnapAndExtortionPolices from './kidnapAndExtortion/keAccessPolicies';
import * as securityResponsePolices from './securityResponse/srAccessPolicies';
import * as peopleManagementResponsePolices from './peopleManagement/peopleManagementAccessPolicies';
import * as reportsPolices from './reports/reportsAccessPolicies';
import * as dutyOfCarePolices from './dutyOfCare/docAccessPolicies';
import * as travelLicensePolices from './travelLicense/tlAccessPolicies';

export * from './kidnapAndExtortion/keAccessPolicies';
export * from './airAmbulance/aaAccessPolicies';
export * from './securityResponse/srAccessPolicies';
export * from './peopleManagement/peopleManagementAccessPolicies';
export * from './payment/paymentAccessPolicies';
export * from './reports/reportsAccessPolicies';
export * from './dutyOfCare/docAccessPolicies';
export * from './travelLicense/tlAccessPolicies';

export const canViewOrganizationsPermissions: string[] = [
  ...peopleManagementResponsePolices.canViewGeneralOrganizationsPermissions,
  ...airAmbulancePolices.canViewAAOrganizationsPermissions,
];
export const canViewMembersPagePermissions: string[] = [
  ...airAmbulancePolices.canViewAAMembersPermissions,
  ...peopleManagementResponsePolices.canViewGeneralMembersPermissions,
  ...kidnapAndExtortionPolices.canViewKEMembersPermissions,
  ...securityResponsePolices.canViewSRMembersPermissions,
  ...dutyOfCarePolices.canViewDOCMembersPermissions,
];

export const canViewOrganizationDetailsPagePermissions: string[] = [
  ...peopleManagementResponsePolices.canViewOrganizationDetailsPermissions,
  ...peopleManagementResponsePolices.canViewSubscriptionsPermissions,
  ...canViewMembersPagePermissions,
];

export const canViewRawDataReportPermissions: string[] = [
  ...airAmbulancePolices.canGenerateAARawDataReportPermissions,
  ...kidnapAndExtortionPolices.canGenerateKERawDataReportPermissions,
  ...securityResponsePolices.canGenerateSRRawDataReportPermissions,
  ...dutyOfCarePolices.canGenerateDOCRawDataReportPermissions,
];

export const canGenerateRetailReportsPermissions: string[] = [
  ...airAmbulancePolices.canGenerateAARetailEnrollmentReport,
  ...airAmbulancePolices.canGenerateAARetailAttritionReport,
  ...airAmbulancePolices.canGenerateAARetailStatisticsReport,
];

export const canViewFinancialReportPermissions: string[] = [
  ...reportsPolices.canGenerateMembershipRetailFinanceReport,
  ...reportsPolices.canGenerateSubscriptionFinanceReport,
];

export const canViewReportsPermissions: string[] = [
  ...canViewRawDataReportPermissions,
  ...canViewFinancialReportPermissions,
  ...canViewRawDataReportPermissions,
];

export const canAddSubscriptionsPermissions: string[] = [
  ...airAmbulancePolices.canAddAASubscriptionPermissions,
  ...kidnapAndExtortionPolices.canAddKESubscriptionPermissions,
  ...securityResponsePolices.canAddSRSubscriptionPermissions,
  ...dutyOfCarePolices.canAddDOCSubscriptionPermissions,
  ...travelLicensePolices.canAddTLSubscriptionPermissions,
];

export const canEditSubscriptionPermissions: string[] = [
  ...airAmbulancePolices.canEditAASubscriptionPermissions,
  ...kidnapAndExtortionPolices.canEditKESubscriptionPermissions,
  ...securityResponsePolices.canEditSRSubscriptionPermissions,
  ...dutyOfCarePolices.canEditDOCSubscriptionPermissions,
  ...travelLicensePolices.canEditTLSubscriptionPermissions,
];

export const canViewSubscriptionsDetailsPermissions: string[] = [
  ...airAmbulancePolices.canViewAASubscriptionDetailsPermissions,
  ...kidnapAndExtortionPolices.canViewKESubscriptionDetailsPermissions,
  ...securityResponsePolices.canViewSRSubscriptionDetailsPermissions,
  ...dutyOfCarePolices.canViewDOCSubscriptionDetailsPermissions,
  ...travelLicensePolices.canViewTLSubscriptionDetailsPermissions,
];

export const canRenewSubscriptionPermissions: string[] = [
  ...airAmbulancePolices.canRenewAASubscriptionPermissions,
  ...kidnapAndExtortionPolices.canRenewKESubscriptionPermissions,
  ...securityResponsePolices.canRenewSRSubscriptionPermissions,
  ...dutyOfCarePolices.canRenewDOCSubscriptionPermissions,
  ...travelLicensePolices.canRenewTLSubscriptionPermissions,
];

export const canExtendsSubscriptionPermissions: string[] = [
  ...airAmbulancePolices.canExtendsAASubscriptionPermissions,
  ...kidnapAndExtortionPolices.canExtendsKESubscriptionPermissions,
  ...securityResponsePolices.canExtendsSRSubscriptionPermissions,
  ...dutyOfCarePolices.canExtendsDOCSubscriptionPermissions,
  ...travelLicensePolices.canExtendsTLSubscriptionPermissions,
];

export const canAddMembershipPermissions: string[] = [
  ...airAmbulancePolices.canAddAAMembershipPermissions,
  ...kidnapAndExtortionPolices.canAddKEMembershipPermissions,
  ...securityResponsePolices.canAddSRMembershipPermissions,
  ...dutyOfCarePolices.canAddDOCMembershipPermissions,
];
