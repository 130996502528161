import { useTranslation } from 'react-i18next';

import { useFormSubmit } from '../../hooks/useFormSubmit';
import { IModalFormPageProps } from './modalFormPage.models';

import {
  ButtonsContainer,
  Form,
  FormContent,
  FormContentWrapper,
  StyledButton,
  Wrapper,
  Title,
} from './modalFormPage.styles';

const ModalFormPage = ({
  children,
  className,
  handleSubmit,
  isProcessing,
  onCancel,
  title,
  submitButtonLabel,
  mode = 'normal',
  canSaveDraft,
}: IModalFormPageProps) => {
  const { t } = useTranslation();
  const { submitDraft, submitSave } = useFormSubmit({
    handleSubmit,
    canSaveDraft,
  });

  return (
    <Wrapper className={className}>
      {title && <Title>{title}</Title>}
      <Form>
        <FormContent>
          <FormContentWrapper mode={mode}>{children}</FormContentWrapper>
        </FormContent>
      </Form>
      <ButtonsContainer>
        <StyledButton
          styleType="light1"
          disabled={isProcessing}
          onClick={onCancel}
        >
          {t('buttons.cancel')}
        </StyledButton>
        {canSaveDraft && (
          <StyledButton
            styleType="light-border"
            disabled={isProcessing}
            onClick={submitDraft}
            type="submit"
          >
            {t('buttons.save-draft')}
          </StyledButton>
        )}
        <StyledButton
          styleType="standard-rounded"
          isLoading={isProcessing}
          onClick={submitSave}
          type="submit"
        >
          {submitButtonLabel || t('buttons.save')}
        </StyledButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

export default ModalFormPage;
