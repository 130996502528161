import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../models/ProductType';

import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { ExtendSubscriptionExtensionsFormFields } from '../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/extendSubscriptionExtensionsForm.models';
import ExtendSRSubscriptionForm from './ExtendSRSubscriptionForm/ExtendSRSubscriptionForm';
import { useExtendSRSubscriptionPage } from './extendSRSubscriptionPage.hooks';

const ExtendSRSubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName } = useExtendSRSubscriptionPage();
  return (
    <FormErrorProvider
      initErrorGroups={[
        ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges,
      ]}
    >
      <>
        <Header
          title={t('headers.edit-seats-for-subscription', {
            organizationName,
            productType: ProductType.SecurityResponse,
          })}
        />
        <ExtendSRSubscriptionForm />
      </>
    </FormErrorProvider>
  );
};

export default ExtendSRSubscriptionPage;
