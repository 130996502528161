import { useTranslation } from 'react-i18next';

import { ICreditCard } from '../../../../models/CreditCardModel';
import OverviewProperty from '../../../../styledComponents/Overview/OverviewProperty/OverviewProperty';
import { SectionSubTitle } from '../../../../styledComponents/Typography/Typography';
import Button from '../../../../styledComponents/UI/Button/Button';
import MainTooltip from '../../../../styledComponents/UI/MainTooltip/MainTooltip';
import { formatDate } from '../../../../utils/creditCardHelpers';
import {
  Wrapper,
  DetailsContent,
  TitleWrapper,
  ActionsContainer,
  AddCreditCardButton,
} from './memberOverviewProfileCardDetails.styles';

import { ReactComponent as PenIcon } from '../../../../assets/icons/pen-icon-secondary.svg';
import { ReactComponent as BasketIcon } from '../../../../assets/icons/basket-icon.svg';
import CreditCardTypeNumber from '../../../../styledComponents/CreditCard/CreditCardTypeNumber/CreditCardTypeNumber';
import {
  canAddCreditCardPermissions,
  canDeleteCreditCardPermissions,
  canReplaceCreditCardPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { withPermissions } from '../../../../hoc/withPermissions';

type MemberOverviewProfileCardDetailsType = {
  creditCard: ICreditCard;
  onReplaceCreditCard?: (creditCardId: string) => void;
  onAddCreditCard?: () => void;
  onDeleteCreditCard?: (creditCardId: ICreditCard) => void;
  canRemoveCreditCard?: boolean;
};

const MemberOverviewProfileCardDetails = ({
  creditCard,
  onReplaceCreditCard,
  onAddCreditCard,
  onDeleteCreditCard,
  canRemoveCreditCard,
}: MemberOverviewProfileCardDetailsType) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TitleWrapper>
        <SectionSubTitle>{t('headers.credit-card-details')}</SectionSubTitle>
        {!creditCard && (
          <AddCreditCardButton
            permissions={canAddCreditCardPermissions}
            styleType="link"
            onClick={onAddCreditCard}
          >
            {t('buttons.add-credit-card')}
          </AddCreditCardButton>
        )}
      </TitleWrapper>
      {!!creditCard && (
        <DetailsContent>
          <OverviewProperty
            title={t('properties.cardHolderName')}
            value={creditCard?.cardHolderName}
          />
          <OverviewProperty
            title={t('properties.cardNumber')}
            value={
              <CreditCardTypeNumber
                creditCardType={creditCard?.creditCardType}
                creditCardNumber={creditCard?.creditCardNumber}
              />
            }
          />
          <OverviewProperty
            title={t('properties.expirationDate')}
            value={`${formatDate(creditCard?.expirationDate)}`}
          />
          <ActionsContainer>
            <MainTooltip arrow title={t('phrase.replace-credit-card')}>
              <Button
                permissions={canReplaceCreditCardPermissions}
                onClick={() => onReplaceCreditCard?.(creditCard?.creditCardId)}
                styleType="icon"
              >
                <PenIcon />
              </Button>
            </MainTooltip>
            {canRemoveCreditCard && (
              <MainTooltip arrow title={t('phrase.remove-credit-card')}>
                <Button
                  permissions={canDeleteCreditCardPermissions}
                  onClick={() => onDeleteCreditCard?.(creditCard)}
                  styleType="icon"
                >
                  <BasketIcon />
                </Button>
              </MainTooltip>
            )}
          </ActionsContainer>
        </DetailsContent>
      )}
    </Wrapper>
  );
};

export default withPermissions(MemberOverviewProfileCardDetails);
