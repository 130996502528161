import { useTranslation } from 'react-i18next';

export const useFormSubmitMessage = () => {
  const { t } = useTranslation();

  const prepareSubmitMessage = (
    messageName: string,
    value: string,
    isDraft: boolean,
  ) =>
    t(`alerts.${isDraft ? 'draft-' : ''}${messageName}`, {
      value,
    });

  const prepareSubscriptionSubmitSuccessMessage = (
    value: string,
    isDraft: boolean,
  ) => prepareSubmitMessage('subscription-update-success', value, isDraft);

  const prepareSubscriptionSubmitFailMessage = (
    value: string,
    isDraft: boolean,
  ) => prepareSubmitMessage('subscription-update-fail', value, isDraft);

  const renewSubscriptionSuccessMessage = t(
    `alerts.subscription-renew-success`,
  );

  const renewSubscriptionFailMessage = t(`alerts.subscription-renew-fail`);

  return {
    prepareSubmitMessage,
    prepareSubscriptionSubmitSuccessMessage,
    prepareSubscriptionSubmitFailMessage,
    renewSubscriptionSuccessMessage,
    renewSubscriptionFailMessage,
  };
};
