/* eslint-disable react/destructuring-assignment */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PricingMode } from '../../../../models/enums/PricingMode';
import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { ProductType } from '../../../../models/ProductType';

import { PricingRenderer } from '../../../MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../MaterialTable/CellRenderers/cellRenderers.models';
import TableCell from '../../../MaterialTable/CellRenderers/TableCell/TableCell';
import { IMaterialColumn } from '../../../MaterialTable/materialTable.models';
import NumericInput from '../../NumericInput/NumericInput';
import { DefaultColumn } from './membershipPlanForm.models';
import {
  useMembershipPlanPricesValues,
  useMembershipPlanPricesActions,
} from './useMembershipPlanPrices/useMembershipPlanPrices';

export function useMembershipPlanForm() {
  const {
    state: { supportPreviousPrices },
  } = useMembershipPlanPricesValues();

  const availablePricingMode = useMemo(
    (): PricingMode[] =>
      Object.values(PricingMode).filter(
        (x) =>
          (!supportPreviousPrices && x !== PricingMode.KeepPreviousPrice) ||
          supportPreviousPrices,
      ),
    [supportPreviousPrices],
  );

  return { availablePricingMode };
}

interface IMembershipPlanTable<T> {
  subscriptionType?: ProductType;
  additionalData?: T[];
  useDefaultColumns?: boolean;
  notUsedDefaultColumns?: DefaultColumn[];
  columns?: IMaterialColumn[];
  disabled?: boolean;
}

export function useMembershipPlanTable<T>({
  useDefaultColumns,
  additionalData,
  columns,
  disabled,
  notUsedDefaultColumns = [],
}: IMembershipPlanTable<T>) {
  const { t } = useTranslation();

  const {
    state: { membershipPlans, pricingMode, supportPreviousPrices },
  } = useMembershipPlanPricesValues();

  const {
    changeMembershipPlanPrice,
    changeNumberOfSeats,
    getSelectedPlanCapacity,
  } = useMembershipPlanPricesActions();

  const cellRenderer = useCallback(
    (key: keyof IMembershipPlanPriceModel | any, options?: ICellOptions) =>
      (data: IMembershipPlanPriceModel) => {
        switch (key) {
          case DefaultColumn.Price: {
            return (
              <PricingRenderer
                onChange={(value) => changeMembershipPlanPrice(value, data)}
                options={options}
                price={data.price}
                readonly={pricingMode !== PricingMode.Custom}
              />
            );
          }
          case DefaultColumn.Seats: {
            return (
              <TableCell alignment={options?.alignment}>
                <NumericInput
                  disabled={options.disabled || disabled}
                  minValue={data.minSeats || 0}
                  onChange={(value) => {
                    changeNumberOfSeats(value, data);
                  }}
                  value={getSelectedPlanCapacity(data.membershipPlanId)}
                />
              </TableCell>
            );
          }
        }
      },
    [
      changeMembershipPlanPrice,
      changeNumberOfSeats,
      disabled,
      getSelectedPlanCapacity,
      pricingMode,
    ],
  );

  const columnsToRender = useMemo((): IMaterialColumn[] => {
    if (!useDefaultColumns) {
      return columns;
    }

    return (
      [
        {
          title: t('grid-columns.membership-plan'),
          field: DefaultColumn.MembershipPlanName,
        },
        ...(columns || []),
        {
          title: t('grid-columns.price'),
          alignment: 'center',
          field: DefaultColumn.Price,
          cellRenderer: cellRenderer(DefaultColumn.Price, {
            disabled,
            alignment: 'center',
          }),
        },
        {
          title: t('grid-columns.add-seats'),
          alignment: 'center',
          field: DefaultColumn.Seats,
          cellRenderer: cellRenderer(DefaultColumn.Seats, {
            textBold: true,
            alignment: 'center',
            disabled,
          }),
        },
      ] as IMaterialColumn[]
    ).filter(
      (column) =>
        !notUsedDefaultColumns.includes(column.field as DefaultColumn),
    );
  }, [
    useDefaultColumns,
    t,
    columns,
    cellRenderer,
    disabled,
    notUsedDefaultColumns,
  ]);

  const data = useMemo(() => {
    if (!additionalData || !membershipPlans) {
      return membershipPlans;
    }

    return membershipPlans.map((plan) => {
      const item = additionalData.find(
        (additionalPlanData: any) =>
          additionalPlanData.membershipPlanId === plan.membershipPlanId,
      );
      return { ...item, ...plan };
    });
  }, [additionalData, membershipPlans]);

  return {
    data,
    columnsToRender,
    supportPreviousPrices,
  };
}
