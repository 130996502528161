import { IMaterialColumn } from '../materialTable.models';
import { TableCellTooltip } from './tableContent.styles';

export const getCellComponent = (column: IMaterialColumn, row: any) => {
  let child = null;

  if (column.cellRenderer) {
    child = column.cellRenderer(row);
  } else if (column.propertySelector) {
    child = column.propertySelector(row);
  } else {
    child = row[column.field];
  }

  if (column.tooltip) {
    return (
      <TableCellTooltip arrow placement="top" title={column.tooltip(row)}>
        {child}
      </TableCellTooltip>
    );
  }

  return child;
};
