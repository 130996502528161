import styled from '@emotion/styled/macro';

import { SectionTitle } from '../../../../styledComponents/Form/Form';
import { FormInputIcon } from '../../../../styledComponents/UI/Form/FormIInputIcon/FormInputIcon';
import { FormNumberInputIcon } from '../../../../styledComponents/UI/Form/FormNumberInputIcon/FormNumberInputIcon';

import FormRadioGroupContent from '../../../../styledComponents/UI/Form/FormRadioGroupContent/FormRadioGroupContent';
import { Label } from '../../../../styledComponents/UI/Radio/radio.styles';
import { RadioContent } from '../../../../styledComponents/UI/RadioGroupContent/radioGroupContent.styles';

export const StyledFormRadioGroupContent = styled(FormRadioGroupContent)`
  gap: 1rem;

  ${Label} {
    font-weight: 400;
  }

  ${RadioContent} {
    margin-top: 1rem;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 1em;
`;

export const StyledFormInputIcon = styled(FormInputIcon)`
  width: 10.125rem;
`;

export const StyledFormNumberInputIcon = styled(FormNumberInputIcon)`
  width: 10.125rem;
`;
