import { useCallback, useEffect, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  creditCardSelector,
  firstCreditCardSelector,
} from '../../../../../store/redux-slices/payment/selectors';

import { PaymentExistingCardFields } from '../PaymentUseExistingCardForm/paymentUseExistingCardForm.models';
import { PaymentType } from './paymentSection.models';

export const usePaymentSection = () => {
  const [currentPaymentType, setCurrentPaymentType] =
    useState<PaymentType>(null);

  const creditCard = useSelector(creditCardSelector);
  const firstCreditCard = useSelector(firstCreditCardSelector);

  const {
    input: { onChange: existingCreditCardIdChange },
  }: FieldRenderProps<string> = useField(
    PaymentExistingCardFields.ExistingCreditCardId,
  );

  const onChangePaymentTypeHandler = useCallback(
    (id: PaymentType) => {
      setCurrentPaymentType(id);
      if (id === PaymentType.UseExistingCreditCard) {
        existingCreditCardIdChange(firstCreditCard?.creditCardId);
        return;
      }

      existingCreditCardIdChange(null);
    },
    [existingCreditCardIdChange, firstCreditCard?.creditCardId],
  );

  useEffect(() => {
    if (firstCreditCard && !firstCreditCard?.isExpired) {
      setCurrentPaymentType(PaymentType.UseExistingCreditCard);
      existingCreditCardIdChange(firstCreditCard?.creditCardId);
      return;
    }

    setCurrentPaymentType(PaymentType.PayWithDifferentCard);
    existingCreditCardIdChange(null);
  }, [existingCreditCardIdChange, creditCard, firstCreditCard]);

  const onlyCardDetailsForm = !firstCreditCard;

  return {
    onlyCardDetailsForm,
    currentPaymentType,
    onChangePaymentTypeHandler,
  };
};
