import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../../utils/validations/validateFormData';
import { validateFamilyMemberFormSchema } from '../../../../../../styledComponents/Forms/FamilyMember/familyMember.validation';
import { MembershipFamilyMembersFormFields } from '../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';
import { SRMembershipOrganizationDetailsFormFields } from './SRMembershipDetailsForm/srMembershipDetailsForm.models';
import { ICreateSRMembershipPageModel } from '../../../../../../models/interfaces/Membership/SecurityResponse/CreateSRMembershipPageModel';

export const srMembershipDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [SRMembershipOrganizationDetailsFormFields.EffectiveDate]: {
      errorMessage: t('validation.effective-date-later-expiration-date'),
      type: 'string',
      format: 'date',
      formatMaximum: {
        $data: `1/${SRMembershipOrganizationDetailsFormFields.ExpirationDate}`,
      },
    },
    [MembershipFamilyMembersFormFields.SecondaryMembers]: {
      type: 'array',
      items: validateFamilyMemberFormSchema,
    },
  },
  required: [
    SRMembershipOrganizationDetailsFormFields.MembershipPlanId,
    SRMembershipOrganizationDetailsFormFields.EffectiveDate,
    SRMembershipOrganizationDetailsFormFields.Organization,
    SRMembershipOrganizationDetailsFormFields.SubscriptionType,
  ],
};

export const validateSRMembershipDetailsForm = (
  data: ICreateSRMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, srMembershipDetailsSchema);
