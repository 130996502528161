import { MouseEvent, useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

type UseCopyToClipboard = {
  onValueCopied?: (value: string) => void;
  value?: string;
};

export const useCopyToClipboard = ({
  value,
  onValueCopied,
}: UseCopyToClipboard) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const copyToClipboard = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      navigator.clipboard.writeText(value).then(() => {
        alert.success(t('phrase.copied-to-clipboard'));
        onValueCopied && onValueCopied(value);
      });
    },
    [alert, onValueCopied, t, value],
  );

  return { copyToClipboard };
};
