import { useCallback } from 'react';

import { CustomComponent } from '../../../models/types/CustomComponent';
import { IMaterialColumn } from '../materialTable.models';
import { StyledTableCell } from './tableContent.styles';
import { getCellComponent } from './tableContent.utils';

type TableCellProps = CustomComponent & {
  column: IMaterialColumn;
  row: any;
};

const CustomTableCell = ({ column, row }: TableCellProps) => {
  const onClickHandler = useCallback(
    (evt: any) => {
      evt.stopPropagation();
      evt.preventDefault();
      column.onClick?.(row, column.field);
    },
    [column, row],
  );

  return (
    <StyledTableCell
      align={column.alignment}
      sx={{ width: column.width, minWidth: column.minWidth }}
      $isClickable={!!column.onClick}
      onClick={!!column.onClick ? onClickHandler : null}
    >
      {getCellComponent(column, row)}
    </StyledTableCell>
  );
};

export default CustomTableCell;
