import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.813rem;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type RadioContentType = {
  isHidden?: boolean;
};

export const RadioContent = styled.div<RadioContentType>`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-left: 1.875rem;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;
