import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useActions } from '../../../../../../../hooks/useActions';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { IDOCOrganizationSearch } from '../../../../../../../models/interfaces/Organization/DutyOfCare/DOCOrganizationSearch';
import { clearMembershipSummary } from '../../../../../../../store/redux-slices/membership/common';
import { loadDOCMembershipSummary } from '../../../../../../../store/saga-slices/membership/dutyOfCare';
import { ILoadDOCMembershipSummaryPayload } from '../../../../../../../store/saga-slices/membership/dutyOfCare/models';
import { DOCMembershipOrganizationDetailsFormFields } from '../DOCMembershipDetailsForm/docMembershipDetailsForm.models';

export const DOCSummaryLoader = () => {
  const LoadMembershipSummary = useActions(loadDOCMembershipSummary);
  const ClearMembershipSummary = useActions(clearMembershipSummary);

  const cancellationToken = useCancellationToken();

  const {
    input: { value: selectedOrganization },
  }: FieldRenderProps<IDOCOrganizationSearch> = useField(
    DOCMembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { value: subscriptionType },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  useEffect(() => {
    if (
      !selectedOrganization?.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      ClearMembershipSummary();
      return;
    }

    const payload: ILoadDOCMembershipSummaryPayload = {
      organizationId: selectedOrganization.organizationId,
      subscriptionId: subscriptionType,
      cancellationToken,
    };

    LoadMembershipSummary(payload);
  }, [
    ClearMembershipSummary,
    LoadMembershipSummary,
    cancellationToken,
    selectedOrganization.organizationId,
    selectedOrganization.subscriptions,
    subscriptionType,
  ]);

  return <></>;
};
