import { useTranslation } from 'react-i18next';

import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import RenewalsPageRoutes from './renewalsPage.routes';
import { StyledTabNavigation } from './renewalsPage.styles';
import { renewalsTabsNavigationArray } from './renewalsPage.utils';

const RenewalsPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Header
        isBackButtonHidden
        bottomLineIsHidden
        title={t('headers.renewals')}
      />
      <StyledTabNavigation routeMode tabs={renewalsTabsNavigationArray}>
        <RenewalsPageRoutes />
      </StyledTabNavigation>
    </div>
  );
};

export default RenewalsPage;
