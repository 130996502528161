import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import MemberAddressInformationForm from '../../../../../styledComponents/Forms/MemberAddressInformationForm/MemberAddressInformationForm';
import AddMemberWizardPage from '../../AddMemberWizardPage/AddMemberWizardPage';

import FormErrorActivator from '../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { focusOnErrorDecorator } from '../../../../../utils/focusOnErrorDecorator';
import PrimaryMemberDetailsSection from '../AddMemberPrimaryMemberPageForms/PrimaryMemberDetailsSection/PrimaryMemberDetailsSection';
import MemberSubscriptionsList from '../../MemberSubscriptionsList/MemberSubscriptionsList';
import { AddMemberWizardPageContainersKeys } from '../../AddMemberWizardPage/addMemberWizardPage.models';
import WarningsConfirmationModal from '../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { SectionTitle } from '../../../../../styledComponents/Form/Form';
import { StyledFormSubmitButton } from './addMemberPrimaryMemberForm.styles';
import { useAddMemberPrimaryMember } from './addMemberPrimaryMemberForm.hooks';

const AddMemberPrimaryMemberForm = () => {
  const { t } = useTranslation();

  const {
    validateForm,
    isValidating,
    handleSubmit,
    fieldsReadOnly,
    selectPrimaryMember,
    initialData,
  } = useAddMemberPrimaryMember();

  return (
    <>
      <WarningsConfirmationModal
        message={t('phrase.member-has-incorrect-data')}
        onWarningsConfirm={selectPrimaryMember}
      />
      <Form
        decorators={[focusOnErrorDecorator]}
        initialValues={initialData}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ dirty, values, ...renderProps }) => (
          <FormErrorActivator>
            <form onSubmit={renderProps.handleSubmit}>
              <AddMemberWizardPage isDirty={dirty} disabled={isValidating}>
                <div key={AddMemberWizardPageContainersKeys.LeftContainer}>
                  <SectionTitle>
                    {t('headers.primary-member-details')}
                  </SectionTitle>
                  <PrimaryMemberDetailsSection
                    disabled={isValidating}
                    readOnly={fieldsReadOnly}
                  />
                  <MemberAddressInformationForm
                    isSubRowSpace
                    disabled={
                      !!values.personId || fieldsReadOnly || isValidating
                    }
                  />
                </div>
                <div key={AddMemberWizardPageContainersKeys.RightContainer}>
                  <StyledFormSubmitButton
                    type="submit"
                    isLoading={isValidating}
                  >
                    {t('buttons.continue').toLocaleUpperCase()}
                  </StyledFormSubmitButton>
                  {!!values.personId && (
                    <MemberSubscriptionsList personId={values.personId} />
                  )}
                </div>
              </AddMemberWizardPage>
            </form>
          </FormErrorActivator>
        )}
      </Form>
    </>
  );
};

export default AddMemberPrimaryMemberForm;
