import { combineReducers } from 'redux';

import wizard from './wizard';
import processes from './processes';
import organizations from './organizations';
import modals from './modals';
import people from './people';
import employees from './employees';
import { pricingReducers } from './pricing';
import contracts from './contracts';
import { membershipReducers } from './membership';
import optionsSearch from './optionsSearch';
import promoCodes from './promoCodes';
import payments from './payment';
import errors from './errors';
import routesHistory from './routesHistory';
import auth from './auth';
import roles from './roles';
import renewalFilter from './renewalFilter';
import { subscriptionsReducers } from './subscriptions';
import { packagesReducers } from './packages';

const rootReducer = combineReducers({
  contracts,
  employees,
  modals,
  optionsSearch,
  organizations,
  people,
  processes,
  wizard,
  promoCodes,
  payments,
  errors,
  routesHistory,
  auth,
  roles,
  renewalFilter,
  ...membershipReducers,
  ...subscriptionsReducers,
  ...pricingReducers,
  ...packagesReducers,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
