import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Alignment } from '../../../models/Alignment';
import { CustomComponent } from '../../../models/types/CustomComponent';
import BaseInput from '../BaseInput/BaseInput';
import { FieldLabel, fieldLabelActiveStyle } from '../Field/Field';

import { DefaultInputIcons, IconPosition } from './iconInput.models';

export type InputIconWrapperProps = {
  isError?: boolean;
  iconPosition?: IconPosition;
};

export const InputIconWrapper = styled.div<InputIconWrapperProps>`
  position: relative;
  background: none;
  border: 0.0625rem solid
    ${({ theme, isError }) =>
      isError ? theme.color.error : theme.color.input_border};
  padding: 0.6875rem 1rem;
  color: ${({ theme }) => theme.color.dark1};
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: 3rem;
  ${({ iconPosition }) =>
    iconPosition === 'start' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const StyledInputWrapper = styled.div`
  margin-left: 1rem;
`;

type StyledInputType = {
  disabled?: boolean;
  readOnly?: boolean;
  alignment?: Alignment;
  defaultIcon?: DefaultInputIcons;
};

export const StyledInput = styled(BaseInput)<StyledInputType>`
  color: ${({ theme, defaultIcon, disabled }) =>
    disabled
      ? theme.color.dark2
      : defaultIcon === 'search'
      ? theme.color.dark1
      : theme.color.black};
  border: none;
  background: none;
  -webkit-text-fill-color: ${({ theme, defaultIcon, disabled }) =>
    disabled
      ? theme.color.dark2
      : defaultIcon === 'search'
      ? theme.color.dark1
      : theme.color.black};
  padding: 0;
`;

export const Icon = styled.i<CustomComponent>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.dark2 : theme.color.black};
  font: normal normal 0.875rem/0.875rem ${({ theme }) => theme.font.gotham};
`;

export const StyledFieldLabel = styled(FieldLabel)`
  ${({ autoLabelAnimation, ...props }) =>
    autoLabelAnimation &&
    css`
      ${InputIconWrapper}:focus-within > &,
      ${InputIconWrapper}:not(:focus-within) > :not(:placeholder-shown) ~ & {
        ${fieldLabelActiveStyle(props)}
      }
    `}
`;
