import { t } from 'i18next';

import { SubscriptionOptionsType } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';
import { TravelLicenseSubscriptionOptions } from '../../../../models/enums/TravelLicenseSubscriptionOptions';

export const tlSubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: TravelLicenseSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];
