import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { MemberType } from '../../../../models/enums/MemberType';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { ADD_KE_FAMILY_MEMBER_MODAL_NAME } from '../Modals/AddKEFamilyMemberModal/addKEFamilyMemberModal.const';
import { AddKEFamilyMemberModalDetails } from '../Modals/AddKEFamilyMemberModal/addKEFamilyMemberModal.models';

type UseKEMembershipActiveDetailsProps = {
  membershipId?: string;
  canAddMemberTypes?: MemberType[];
};

export const useKEMembershipActiveDetails = ({
  canAddMemberTypes,
  membershipId,
}: UseKEMembershipActiveDetailsProps) => {
  const UpdateModalState = useActions(updateModalState);

  const addFamilyMemberHandler = useCallback(() => {
    const details: AddKEFamilyMemberModalDetails = {
      canAddMemberTypes,
      membershipId,
    };
    UpdateModalState([ADD_KE_FAMILY_MEMBER_MODAL_NAME + membershipId, details]);
  }, [UpdateModalState, canAddMemberTypes, membershipId]);

  return {
    addFamilyMemberHandler,
  };
};
