import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { RESEND_WELCOME_EMAIL_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { resendAAWelcomeEmail } from '../../../../../store/saga-slices/membership/airAmbulance';
import { IResendAAWelcomeEmail } from '../../../../../store/saga-slices/membership/airAmbulance/models';

import { personEmailSelector } from '../../../../../store/redux-slices/people/selectors';
import { useFormErrorsActions } from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { RESEND_AA_WELCOME_EMAIL_MODAL_NAME } from './resendAAWelcomeEmailModal.const';
import { IResendWelcomeEmailData } from '../../../../../models/interfaces/ResendWelcomeEmailModel';
import { ResendWelcomeEmailFormFields } from '../../../../../styledComponents/Forms/ResendWelcomeEmailForm/resendWelcomeEmailForm.models';
import { validateResendWelcomeEmailForm } from '../../../../../styledComponents/Forms/ResendWelcomeEmailForm/resendWelcomeEmailForm.validation';
import { ResendAAWelcomeEmailModalDetails } from './resendAAWelcomeEmailModal.models';

type UseResendAAWelcomeEmailModalFormProps = {
  id?: string;
};

export const useResendAAWelcomeEmailModalForm = ({
  id,
}: UseResendAAWelcomeEmailModalFormProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { validateErrors } = useFormErrorsActions();
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();
  const ResendWelcomeEmail = useActions(resendAAWelcomeEmail);
  const welcomeEmailResending = useProcessing(RESEND_WELCOME_EMAIL_PROCESSING);
  const personEmail = useSelector(personEmailSelector);

  const initState = personEmail
    ? {
        [ResendWelcomeEmailFormFields.emailAddress]: personEmail,
      }
    : {
        [ResendWelcomeEmailFormFields.emailAddress]: '',
      };

  const { membershipId, personId }: ResendAAWelcomeEmailModalDetails =
    useModalDetails(RESEND_AA_WELCOME_EMAIL_MODAL_NAME + id);

  const submitHandler = useCallback(
    (values: IResendWelcomeEmailData) => {
      const payload: IResendAAWelcomeEmail = {
        cancellationToken,
        membershipId,
        personId,
        data: values,
        success: () => {
          UpdateModalState(RESEND_AA_WELCOME_EMAIL_MODAL_NAME + id);
          alert.success(t('alerts.resend-welcome-email-success'));
        },
        error: () => alert.error(t('alerts.resend-welcome-email-error')),
      };
      ResendWelcomeEmail(payload);
    },
    [
      ResendWelcomeEmail,
      UpdateModalState,
      alert,
      cancellationToken,
      id,
      membershipId,
      personId,
      t,
    ],
  );

  const validateForm = (data: IResendWelcomeEmailData) =>
    validateResendWelcomeEmailForm(data, validateErrors(data));

  const onCancelHandler = useCallback(() => {
    UpdateModalState(RESEND_AA_WELCOME_EMAIL_MODAL_NAME + id);
  }, [UpdateModalState, id]);

  return {
    submitHandler,
    onCancelHandler,
    welcomeEmailResending,
    initState,
    validateForm,
  };
};
