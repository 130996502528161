import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { contractFormSchema } from '../../../../styledComponents/Forms/SelectContractForm/selectContractForm.validation';
import { subscriptionOptionsSchema } from '../../../../styledComponents/Forms/SubscriptionOptions/subscriptionOptions.validation';
import { subscriptionSummarySchema } from '../../../../styledComponents/FormSummary/SubscriptionSummary/subscriptionSummary.validation';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { RenewDOCSubscriptionModel } from '../../../../models/types/DutyOfCare/RenewDOCSubscriptionModel';
import { docRenewSubscriptionDetailsSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/DutyOfCareRenewSubscription/DOCRenewSubscriptionDetails/docRenewSubscriptionDetails.validation';
import { docRenewSubscriptionSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/DutyOfCareRenewSubscription/dutyOfCareRenewSubscriptionForm.validation';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...docRenewSubscriptionDetailsSchema.properties,
    ...subscriptionOptionsSchema.properties,
    ...subscriptionSummarySchema.properties,
    ...docRenewSubscriptionSchema.properties,
  },
  dependencies: {
    ...subscriptionOptionsSchema.dependencies,
    ...subscriptionSummarySchema.dependencies,
  },
  required: [
    ...contractFormSchema.required,
    ...docRenewSubscriptionDetailsSchema.required,
    ...subscriptionOptionsSchema.required,
    ...subscriptionSummarySchema.required,
    ...docRenewSubscriptionSchema.required,
  ],
};

export const validateDOCRenewSubscriptionForm = (
  data: RenewDOCSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
