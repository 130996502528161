/* eslint-disable react/no-array-index-key */
import { ReactNode } from 'react';

import { ProductType } from '../../../../models/ProductType';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import SubscriptionSummaryItemHeader from '../SummaryItemHeader/SummaryItemHeader';
import SubscriptionSummaryPlanOption from '../SummaryItemOption/SummaryItemOption';
import { IPlanSummaryItemModel } from '../summaryItem.models';

type SubscriptionSummaryItemContentProps = CustomComponent & {
  index?: number;
  data: IPlanSummaryItemModel[];
  onRemove?: (index: number) => void;
  subscriptionType?: ProductType;
  isPriceOverridden?: boolean;
  title?: ReactNode;
};

const SubscriptionSummaryItemContent = ({
  data,
  className,
  index,
  isPriceOverridden,
  onRemove,
  subscriptionType,
  title,
}: SubscriptionSummaryItemContentProps) => (
  <div className={className}>
    <SubscriptionSummaryItemHeader
      index={index}
      onRemove={onRemove}
      subscriptionType={subscriptionType}
      title={title}
    />
    {data?.map((option, optionIndex) => (
      <SubscriptionSummaryPlanOption
        item={option}
        key={option.name + optionIndex}
        priceOverridden={isPriceOverridden}
      />
    ))}
  </div>
);

export default SubscriptionSummaryItemContent;
