import { putResolve, select, call } from 'typed-redux-saga/macro';

import { OrganizationSubscriptionStatus } from '../../../../models/enums/OrganizationSubscriptionStatus';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import {
  removeFromDeletedSubscriptionId,
  removeFromNewAddedSubscription,
} from '../../../redux-slices/subscriptions/common';
import {
  addedSubscriptionsSelector,
  deletedSubscriptionsIdsSelector,
} from '../../../redux-slices/subscriptions/common/selectors';

export function* updateSubscriptionList(
  subscriptions: IOrganizationSubscription[],
) {
  let currentSubscriptions: any = [...subscriptions];
  currentSubscriptions = yield* call(
    addMissingToSubscriptionList,
    currentSubscriptions,
  );
  currentSubscriptions = yield* call(
    deleteRemovedFromSubscriptionList,
    currentSubscriptions,
  );

  return currentSubscriptions;
}

function* addMissingToSubscriptionList(
  subscriptions: IOrganizationSubscription[],
) {
  const currentSubscriptions = [...subscriptions];
  const addedSubscriptions = yield* select(addedSubscriptionsSelector);

  const addedSubscriptionsForRemove: string[] = [];

  [...addedSubscriptions].reverse().forEach((addedSubscription) => {
    if (
      addedSubscriptions &&
      currentSubscriptions.findIndex((x) => x.id === addedSubscription.id) ===
        -1
    ) {
      currentSubscriptions.unshift({
        ...addedSubscription,
        status: addedSubscription.isDraft
          ? OrganizationSubscriptionStatus.Draft
          : OrganizationSubscriptionStatus.New,
      });
    } else {
      addedSubscriptionsForRemove.push(addedSubscription.id);
    }
  });

  if (addedSubscriptionsForRemove?.length) {
    yield putResolve(
      removeFromNewAddedSubscription(addedSubscriptionsForRemove),
    );
  }

  return currentSubscriptions;
}

function* deleteRemovedFromSubscriptionList(
  subscriptions: IOrganizationSubscription[],
) {
  let currentSubscriptions = [...subscriptions];

  const deletedSubscriptionsIds = yield* select(
    deletedSubscriptionsIdsSelector,
  );

  const deletedSubscriptionsForRemove: string[] = [];

  [...deletedSubscriptionsIds].reverse().forEach((id) => {
    if (currentSubscriptions.findIndex((x) => x.id === id) !== -1) {
      currentSubscriptions = currentSubscriptions.filter((x) => x.id !== id);
    } else {
      deletedSubscriptionsForRemove.push(id);
    }
  });

  if (deletedSubscriptionsForRemove?.length) {
    yield putResolve(
      removeFromDeletedSubscriptionId(deletedSubscriptionsForRemove),
    );
  }

  return currentSubscriptions;
}
