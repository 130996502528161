import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import KEMembersList from '../../../../../styledComponents/ProductMembersList/KEMembersList/KEMembersList';
import { pageFilters } from './keMembers.const';
import { useKEMembers } from './keMembers.hooks';

const KEMembers = () => {
  const {
    pageListMembers,
    membersLoading,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    keMembershipPricing,
  } = useKEMembers();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <KEMembersList
        tableProps={{
          data: pageListMembers,
          isLoading: membersLoading,
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: pageFilters,
          standardPricing: keMembershipPricing,
        }}
      />
    </>
  );
};

export default KEMembers;
