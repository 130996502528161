import { formatDate } from '../../../../utils/dateHelpers';
import { ICellRenderer } from '../cellRenderers.models';
import EmptyCell from '../EmptyCell/EmptyCell';
import TableCell from '../TableCell/TableCell';
import { DateWrapper, Text } from './dateRenderer.styles';

export interface IDateRenderer extends ICellRenderer {
  date?: string;
  description?: string;
}

export const DateRenderer = ({
  date,
  description,
  options,
  className,
}: IDateRenderer) => {
  if (!date) {
    return (
      <EmptyCell
        bold={options?.textBold}
        alignment={options?.alignment}
        className={className}
      />
    );
  }
  return (
    <TableCell
      bold={options?.textBold}
      alignment={options?.alignment}
      className={className}
    >
      <DateWrapper>{formatDate(date)}</DateWrapper>
      {description && <Text>{description}</Text>}
    </TableCell>
  );
};
