import { AnySchema } from 'ajv';

import { validateFormData } from '../../../../../../../utils/validations/validateFormData';
import {
  SelectCategoryFilterFields,
  SelectCategoryFilterModel,
} from './selectCategoryFilter.models';

export const selectCategoryFilterValidationSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [SelectCategoryFilterFields.AdditionalValues]: {
      type: 'object',
      properties: {},
      required: [SelectCategoryFilterFields.CategoryValues],
    },
  },
  required: [SelectCategoryFilterFields.Value],
};

export const validateSelectCategoryFilter = (data: SelectCategoryFilterModel) =>
  validateFormData(data, null, selectCategoryFilterValidationSchema);
