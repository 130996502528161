/* eslint-disable no-undefined */
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Input, IInputProps } from '../Input/Input';
import { transientOptions } from '../../../utils/transientOptions';
import BaseInput from '../BaseInput/BaseInput';

type StyledInputType = IInputProps & {
  $minValue?: number;
};

export const Wrapper = styled.div`
  white-space: nowrap;
  display: flex;
`;

export const StyledInput = styled(Input, transientOptions)<StyledInputType>`
  height: 2rem;
  width: 5rem;

  ${BaseInput} {
    border: none;
    border-radius: unset;
    color: ${({ theme }) => theme.color.light1};
    -webkit-text-fill-color: ${({ theme }) => theme.color.light1};
    border-bottom: 0.063rem solid ${({ theme }) => theme.color.secondary1};
    background: transparent;
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: bold;
    ${({ $minValue, value, theme }) =>
      ((($minValue === null || $minValue === undefined) && +value !== 0) ||
        +value > $minValue) &&
      css`
        color: ${theme.color.black};
        -webkit-text-fill-color: ${theme.color.black};
      `}

    ${({ theme, disabled }) =>
      disabled &&
      css`
        color: ${theme.color.dark2};
        -webkit-text-fill-color: ${theme.color.dark2};
      `}

    &:focus {
      outline-style: none;
    }
  }
`;
