import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { IconNumberInputProps } from '../../IconInput/iconInput.models';
import IconNumberInput from '../../IconInput/IconNumberInput';
import { useBaseFormInput } from '../../../../hooks/useBaseFormInput';
import { useFormField } from '../../../../hooks/useFormField';

export interface IFormNumberInputIconProps
  extends FieldRenderProps<string>,
    IconNumberInputProps {}

export const FormNumberInputIcon = ({
  label,
  input,
  meta: { touched, error },
  inputType,
  labelAnimation = true,
  ...props
}: IFormNumberInputIconProps) => {
  const { preparedLabel, preparedPlaceholder } = useFormField({
    label,
    input,
    labelAnimation,
  });
  const { inputValue, onChangeHandler } = useBaseFormInput({ input });
  return (
    <FormFieldWrapper error={touched && error}>
      <IconNumberInput
        {...props}
        onChangeValue={onChangeHandler}
        isError={error && touched}
        placeholder={preparedPlaceholder}
        labelAnimation={labelAnimation}
        type={inputType}
        label={preparedLabel}
        value={inputValue}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
