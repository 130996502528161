import styled from '@emotion/styled/macro';

import Button from '../../UI/Button/Button';

export const StyledButton = styled(Button)`
  margin-top: 2.75rem;
`;

export const SubscriptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;
