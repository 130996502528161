/* eslint-disable no-undefined */
import { FocusEvent } from 'react';

import Button from '../Button/Button';
import {
  NumericInputControlsChar,
  NumericInputControlsChars,
} from './numericInput.const';
import { useNumericInput } from './numericInput.hooks';
import { StyledInput, Wrapper } from './numericInput.styles';

export interface INumericInputProps {
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  maxValue?: number;
  minValue?: number;
  onChange: (value: number) => void;
  value: number;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const NumericInput = ({
  className,
  disabled,
  inputClassName,
  maxValue,
  minValue,
  onChange,
  value,
  onBlur,
}: INumericInputProps) => {
  const {
    incrementValueHandler,
    decrementValueHandler,
    charBeforeNumber,
    inputChangeHandler,
    incrementButtonDisabled,
    decrementButtonDisabled,
  } = useNumericInput({ maxValue, minValue, value, onChange });

  return (
    <Wrapper className={className}>
      <Button
        styleType="icon"
        disabled={decrementButtonDisabled || disabled}
        onClick={decrementValueHandler}
      >
        {NumericInputControlsChars[NumericInputControlsChar.Decrement]}
      </Button>
      <StyledInput
        className={inputClassName}
        disabled={disabled}
        $minValue={minValue}
        onlyNumber
        charsBeforeValue={charBeforeNumber}
        alignment="center"
        onChange={(evt) => inputChangeHandler(evt.target.value as any)}
        value={value && value.toString()}
        onBlur={onBlur}
      />
      <Button
        styleType="icon"
        disabled={incrementButtonDisabled || disabled}
        onClick={incrementValueHandler}
      >
        {`${NumericInputControlsChars[NumericInputControlsChar.Increment]}`}
      </Button>
    </Wrapper>
  );
};

export default NumericInput;
