import MainTooltip from '../../MainTooltip/MainTooltip';
import { ValueStatus } from './formValueInfo.models';
import {
  ContentContainer,
  EmptyValue,
  StyledFieldLabel,
  Value,
  ValueContainer,
  Wrapper,
  StyledSpinner,
} from './formValueInfo.styles';

interface IFormValueInfoProps {
  status?: ValueStatus;
  label?: string;
  tooltip?: string;
  value?: string;
  onlyLabel?: boolean;
  className?: string;
  isBoldValue?: boolean;
  isLoading?: boolean;
}

const FormValueInfo = ({
  status = 'default',
  label,
  tooltip,
  value,
  onlyLabel,
  className,
  isBoldValue,
  isLoading,
}: IFormValueInfoProps) => {
  let valueContent = <EmptyValue>-</EmptyValue>;

  if (value) {
    valueContent = (
      <Value isBold={isBoldValue} status={status}>
        {value}
      </Value>
    );
  }

  if (isLoading) {
    valueContent = <StyledSpinner size="1.375rem" />;
  }

  return (
    <Wrapper className={className}>
      <StyledFieldLabel labelActive>{label}</StyledFieldLabel>
      <ContentContainer>
        <MainTooltip
          arrow
          isShowing={!!tooltip && status === 'error'}
          placement="top"
          title={tooltip}
        >
          {!onlyLabel && <ValueContainer>{valueContent}</ValueContainer>}
        </MainTooltip>
      </ContentContainer>
    </Wrapper>
  );
};

export default FormValueInfo;
