import { RenewalStatus } from '../../../models/enums/RenewalStatus';

export enum RenewalStatusFormFields {
  NewRenewalStatus = 'newRenewalStatus',
}
export const renewalStatusFormName = 'renewalStatus-form';

export type RenewalStatusFormModel = {
  newRenewalStatus: RenewalStatus;
};
