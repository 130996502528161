import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../../../models/types/CustomForm';
import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../../../utils/getFormGroupPath';

import { BasicFormWrapper } from '../../../../Form/Form';
import DOCSubscriptionPackageOptionsForm from '../../../../Forms/DutyOfCare/DOCSubscriptionPackageOptionsForm/DOCSubscriptionPricingForm';
import { SectionSubTitle } from '../../../../Typography/Typography';
import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';
import {
  DOCRenewSubscriptionPricingFormFields,
  DOCRenewSubscriptionPricingFormName,
} from './docRenewSubscriptionPricing.models';
import {
  Header,
  TransferAllEligibleMembershipsCheckbox,
} from './docRenewSubscriptionPricing.styles';

const DOCRenewSubscriptionPricing = ({ name, disabled }: CustomForm) => {
  const { t } = useTranslation();
  return (
    <BasicFormWrapper>
      <Header>
        <SectionSubTitle>
          {t('headers.pricing-and-current-capacity')}
        </SectionSubTitle>
        <Field
          component={TransferAllEligibleMembershipsCheckbox}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: DOCRenewSubscriptionPricingFormName,
            groupName: name,
            name: DOCRenewSubscriptionPricingFormFields.TransferAllEligibleMemberships,
          })}
          type="checkbox"
        />
      </Header>
      <FormColumn>
        <DOCSubscriptionPackageOptionsForm
          disabled={disabled}
          name={getFormGroupPath(
            name,
            DOCRenewSubscriptionPricingFormFields.PackageOptions,
          )}
        />
      </FormColumn>
    </BasicFormWrapper>
  );
};

export default DOCRenewSubscriptionPricing;
