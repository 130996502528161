import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { putResolve } from '@redux-saga/core/effects';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  DELETE_MEMBERSHIP_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
  MEMBERSHIP_CREATING_PROCESSING,
  MEMBERSHIP_SUMMARY_LOADING_PROCESSING,
  MEMBERS_LOADING_PROCESSING,
  NEW_MEMBERSHIP_DATES_LOAD_PROCESSING,
  RESEND_WELCOME_EMAIL_PROCESSING,
  SECONDARY_MEMBER_ADDING_PROCESSING,
  SECONDARY_MEMBER_DELETE_PROCESSING,
  UPDATE_MEMBERSHIP_OPTIONS_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  getSRExistingMembershipSummary,
  getSRMembershipSummary,
  createSROrganizationMembership,
  getDatesForNewSRMemberships,
  getSRMembershipDetails,
  putSRMemberToBeRenewed,
  updateSRMembershipOptionsRequest,
  addSRSecondaryMemberRequest,
  deleteSRSecondaryMemberRequest,
  postResendSRWelcomeEmail,
  deleteSRMembershipRequest,
  getSRMembershipsMembers,
} from './api';
import { Log } from '../../../../utils/logger';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';

import { prepareCreateSROrganizationMembershipRequestData } from '../common/utils';
import {
  setMembership,
  setMembershipSummary,
} from '../../../redux-slices/membership/common';
import {
  IAddSecondarySRMemberPayload,
  IChangeSRMemberRenewFlagPayload,
  IDeleteSRMembershipPayload,
  IDeleteSecondarySRMemberPayload,
  ILoadExistingSRMembershipSummaryPayload,
  ILoadSRMembershipDetailsPayload,
  ILoadSRMembershipSummaryPayload,
  ILoadSRMembersPayload,
  ILoadNewSRMembershipDatesPayload,
  IResendSRWelcomeEmail,
  ISubmitCreateSRMembershipPayload,
  IUpdateSRMembershipOptionsPayload,
} from './models';
import {
  setSRMembers,
  setSRMembershipDates,
  setSRMembershipDetails,
  setSRMembershipSummary,
} from '../../../redux-slices/membership/securityResponse';
import { removePersonMembership } from '../../../redux-slices/people';

const srMembershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateSRMembership({
      payload: { data, success, error },
    }: PayloadAction<ISubmitCreateSRMembershipPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
        yield putResolve(setMembership(data));

        const {
          data: requestData,
          organizationId,
          subscriptionId,
        } = yield* call(prepareCreateSROrganizationMembershipRequestData);
        yield call(
          createSROrganizationMembership,
          organizationId,
          subscriptionId,
          requestData,
        );

        success?.();
      } catch (err: any) {
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
      }
    },
    *loadSRMembershipSummary({
      payload: {
        organizationId,
        subscriptionId,
        membershipPlanId,
        success,
        error,
        cancellationToken,
      },
    }: PayloadAction<ILoadSRMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getSRMembershipSummary,
          organizationId,
          subscriptionId,
          membershipPlanId,
          cancellationToken,
        );
        yield put(setMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadExistingSRMembershipSummary({
      payload: { membershipId, success, error },
    }: PayloadAction<ILoadExistingSRMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getSRExistingMembershipSummary,
          membershipId,
        );
        yield put(setSRMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadNewSRMembershipDates({
      payload: { subscriptionId, cancellationToken, success, error },
    }: PayloadAction<ILoadNewSRMembershipDatesPayload>) {
      try {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
        const response = yield* call(
          getDatesForNewSRMemberships,
          subscriptionId,
          cancellationToken,
        );
        yield put(setSRMembershipDates(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
      }
    },
    *loadSRMembershipDetails({
      payload: { success, error, membershipId },
    }: PayloadAction<ILoadSRMembershipDetailsPayload>) {
      try {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
        const response = yield* call(getSRMembershipDetails, membershipId);
        yield put(setSRMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
      }
    },
    *changeSRMemberRenewFlag({
      payload: { membershipId, memberId, toBeRenewed, success, error },
    }: PayloadAction<IChangeSRMemberRenewFlagPayload>) {
      try {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
        yield call(putSRMemberToBeRenewed, membershipId, memberId, toBeRenewed);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
      }
    },
    *updateSRMembershipOptions({
      payload: { membershipId, cancellationToken, options, success, error },
    }: PayloadAction<IUpdateSRMembershipOptionsPayload>) {
      try {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
        const response = yield* call(
          updateSRMembershipOptionsRequest,
          membershipId,
          options,
          cancellationToken,
        );
        yield put(setSRMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
      }
    },
    *addSecondarySRMember({
      payload: { success, error, data, membershipId },
    }: PayloadAction<IAddSecondarySRMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
        yield call(addSRSecondaryMemberRequest, membershipId, data);

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
      }
    },
    *deleteSecondarySRMember({
      payload: { membershipId, memberId, success, error, cancellationToken },
    }: PayloadAction<IDeleteSecondarySRMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
        yield call(
          deleteSRSecondaryMemberRequest,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
      }
    },
    *resendSRWelcomeEmail({
      payload: {
        cancellationToken,
        membershipId,
        personId,
        data,
        success,
        error,
      },
    }: PayloadAction<IResendSRWelcomeEmail>) {
      try {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
        yield call(
          postResendSRWelcomeEmail,
          membershipId,
          personId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
      }
    },
    *deleteSRMembership({
      payload: { membershipId, cancellationToken, isActive, success, error },
    }: PayloadAction<IDeleteSRMembershipPayload>) {
      try {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
        yield call(
          deleteSRMembershipRequest,
          membershipId,
          isActive,
          cancellationToken,
        );

        yield put(removePersonMembership({ membershipId }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
      }
    },
    *loadSRMembers({
      payload: { success, error, params, organizationId },
    }: PayloadAction<ILoadSRMembersPayload>) {
      try {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
        const response = yield* call(
          getSRMembershipsMembers,
          organizationId,
          params,
        );

        yield put(setSRMembers(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
      }
    },
  },
  name: 'sr-members-saga',
  sagaType: SagaType.TakeLatest,
});

export default srMembershipSlice.saga;
export const {
  submitCreateSRMembership,
  loadExistingSRMembershipSummary,
  loadSRMembershipSummary,
  loadNewSRMembershipDates,
  loadSRMembershipDetails,
  changeSRMemberRenewFlag,
  updateSRMembershipOptions,
  addSecondarySRMember,
  deleteSecondarySRMember,
  resendSRWelcomeEmail,
  deleteSRMembership,
  loadSRMembers,
} = srMembershipSlice.actions;
export const { actions } = srMembershipSlice;
