import DeleteProductSubscriptionModal from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/DeleteSubscriptionModal';
import EditRenewalSubscriptionStatusModal from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';
import { RightContainer } from '../../../../../styledComponents/Page/Page';
import Spinner from '../../../../../styledComponents/Spinner/Spinner';
import ProductSubscriptionOverviewTemplatePage from '../ProductSubscriptionOverviewTemplatePage/ProductSubscriptionOverviewTemplatePage';
import TLSubscriptionEditOptionsModal from './Modals/TLSubscriptionEditOptionsModal/TLSubscriptionEditOptionsModal';
import { tlSubscriptionOverviewPermissions } from './tlSubscriptionOverview.const';
import {
  useTLSubscriptionOverviewActions,
  useTLSubscriptionOverviewTabPage,
} from './tlSubscriptionOverview.hooks';
import { StyledLeftContainer } from './tlSubscriptionOverview.styles';
import TLSubscriptionOverviewInformation from './TLSubscriptionOverviewInformation/TLSubscriptionOverviewInformation';
import TLSubscriptionOverviewPricingTable from './TLSubscriptionOverviewPricingTable/TLSubscriptionOverviewPricingTable';

const TLSubscriptionOverview = () => {
  const {
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  } = useTLSubscriptionOverviewTabPage();

  const {
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    showEditSubscriptionDetailsModal,
    editSubscriptionHandler,
    editRenewalStatusHandler,
  } = useTLSubscriptionOverviewActions();

  return (
    <>
      <TLSubscriptionEditOptionsModal
        onModalSuccess={() => loadSubscriptionOverviewHandler()}
      />
      <EditRenewalSubscriptionStatusModal
        onRenewalStatusEdited={loadSubscriptionOverviewHandler}
      />
      <DeleteProductSubscriptionModal />
      <Spinner
        isVisible={subscriptionOverviewLoading && !!subscriptionOverview}
        size="20%"
      >
        <ProductSubscriptionOverviewTemplatePage
          canExtend={subscriptionOverview?.metadata?.canAddExtension}
          canDelete={subscriptionOverview?.metadata?.canDelete}
          onDeleteProductSubscription={deleteProductSubscriptionHandler}
          onExtendProductSubscription={extendProductSubscriptionHandler}
          onEditSubscription={editSubscriptionHandler}
          isLoading={subscriptionOverviewLoading && !subscriptionOverview}
          canEditSubscription={subscriptionOverview?.metadata?.canEdit}
          permissions={tlSubscriptionOverviewPermissions}
        >
          <StyledLeftContainer>
            <TLSubscriptionOverviewPricingTable
              pricingData={subscriptionOverview?.pricingTiers}
              totalSeatsAmount={subscriptionOverview?.totalCapacity}
            />
          </StyledLeftContainer>
          <RightContainer>
            <TLSubscriptionOverviewInformation
              onEditOptionsClick={showEditSubscriptionDetailsModal}
              subscriptionOverview={subscriptionOverview}
              canEditOptions={!!subscriptionOverview?.metadata?.canEditOptions}
              canRenew={!!subscriptionOverview?.metadata?.canRenew}
              onEditRenewalStatus={editRenewalStatusHandler}
            />
          </RightContainer>
        </ProductSubscriptionOverviewTemplatePage>
      </Spinner>
    </>
  );
};

export default TLSubscriptionOverview;
