import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';
import { ExtendProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadTLSubscriptionExtensionSummary } from '../../../../store/saga-slices/subscriptions/travelLicense';
import { ILoadTLSubscriptionExtensionSummary } from '../../../../store/saga-slices/subscriptions/travelLicense/models';

type ExtendTLSubscriptionSummaryLoaderProps = {
  values?: IChangeTLSubscriptionPricingTierModel[];
};

const ExtendTLSubscriptionSummaryLoader = ({
  values,
}: ExtendTLSubscriptionSummaryLoaderProps) => {
  const LoadSubscriptionExtensionSummary = useActions(
    loadTLSubscriptionExtensionSummary,
  );
  const { organizationId, subscriptionId } =
    useParams<ExtendProductSubscriptionPageParamsType>();

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values) {
      return;
    }

    debounce(() => {
      const payload: ILoadTLSubscriptionExtensionSummary = {
        data: values,
        organizationId,
        subscriptionId,
      };

      LoadSubscriptionExtensionSummary(payload);
    }, 500);
  }, [
    LoadSubscriptionExtensionSummary,
    debounce,
    organizationId,
    subscriptionId,
    values,
  ]);
  return <></>;
};

export default ExtendTLSubscriptionSummaryLoader;
