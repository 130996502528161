import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormSelect } from '../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import {
  CustomFilter,
  CustomFilterField,
  FilterDefinition,
} from '../renewalFilters.models';
import { findFilterDefinition } from '../renewalFilters.utils';

import { CustomFiltersFields } from './filterForm.models';
import { useFiltersForm } from './filtersForm.hooks';
import {
  AddFilterButton,
  ButtonContainer,
  FilterInputContainer,
  FilterRow,
} from './filtersForm.styles';
import { validateFilter } from './filtersForm.validation';
import { FiltersOptions } from './FilterTypes/filtersTypes.models';
import FilterTypes from './FilterTypes/FilterTypes';

type RenewalsContractsFiltersProps = {
  filtersDefinitions: FilterDefinition<FiltersOptions>[];
  onAddFilter?: (filter: CustomFilter) => void;
};

const RenewalsContractsFilters = ({
  filtersDefinitions,
  onAddFilter,
}: RenewalsContractsFiltersProps) => {
  const { t } = useTranslation();
  const { fieldsOptions, handleSubmitFilter } = useFiltersForm({
    filtersDefinitions,
    onAddFilter,
  });

  return (
    <Form onSubmit={handleSubmitFilter} validate={validateFilter}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <FilterRow>
            <FilterInputContainer>
              <Field
                component={FormSelect}
                options={fieldsOptions}
                valueIdSelector={(value: CustomFilterField) => value.fieldName}
                name={CustomFiltersFields.Field}
                label={t('filters.filterBy')}
              />
            </FilterInputContainer>
            <FilterTypes
              filterDefinition={findFilterDefinition(
                filtersDefinitions,
                values?.field?.fieldName,
              )}
            />
            <ButtonContainer>
              <AddFilterButton type="submit" />
            </ButtonContainer>
          </FilterRow>
        </form>
      )}
    </Form>
  );
};

export default RenewalsContractsFilters;
