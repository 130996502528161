import styled from '@emotion/styled/macro';

export type DotType = {
  color?: string;
  size?: string;
};

export const Dot = styled.div<DotType>`
  width: ${({ size }) => size || '0.5rem'};
  height: ${({ size }) => size || '0.5rem'};
  border-radius: 50%;
  background-color: ${({ color, theme }) => color || theme.color.dark2};
`;

export const DotBefore = styled.div<DotType>`
  &::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    left: -1rem;
    top: 0.2rem;
    bottom: 0;
    border-radius: 50%;
    background-color: ${({ color, theme }) => color || theme.color.dark2};
  }
`;
