import { ProductType } from '../../../../models/ProductType';
import AAMembershipActiveDetails from '../../AAMemberOverview/AAMembershipActiveDetails/AAMembershipActiveDetails';
import DOCMembershipActiveDetails from '../../DOCMemberOverview/DOCMembershipActiveDetails/DOCMembershipActiveDetails';
import KEMembershipActiveDetails from '../../KEMemberOverview/KEMembershipActiveDetails/KEMembershipActiveDetails';
import SRMembershipActiveDetails from '../../SRMemberOverview/SRMembershipActiveDetails/SRMembershipActiveDetails';

export interface IMemberOverviewActiveMemberDetailProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

export const MemberOverviewActiveMemberDetailsByProductType: Partial<
  Record<
    ProductType,
    (props: IMemberOverviewActiveMemberDetailProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AAMembershipActiveDetails,
  [ProductType.DutyOfCare]: DOCMembershipActiveDetails,
  [ProductType.KidnapAndExtortion]: KEMembershipActiveDetails,
  [ProductType.SecurityResponse]: SRMembershipActiveDetails,
  [ProductType.TravelLicense]: null,
};
