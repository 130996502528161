import { useClickBackButtonBrowser } from '../../hooks/useClickBackButtonBrowser';
import { useRouteHistory } from '../../hooks/useRouteHistory';
import { useUserAuth } from '../../hooks/useUserAuth';
import { CustomComponent } from '../../models/types/CustomComponent';
import Spinner from '../Spinner/Spinner';

const AppInit = ({ children }: CustomComponent) => {
  useRouteHistory();
  useClickBackButtonBrowser();
  const { authLoading } = useUserAuth();

  if (authLoading) {
    return <Spinner size="10%" />;
  }
  return <>{children}</>;
};

export default AppInit;
