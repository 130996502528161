import { OverviewTabPage } from '../../../../styledComponents/OverviewPage/OverviewPage';
import MembersHeader from './MembersHeader/MembersHeader';
import MembersRoutes from './members.routes';

const MembersTabPage = () => (
  <OverviewTabPage>
    <MembersHeader />
    <MembersRoutes />
  </OverviewTabPage>
);

export default MembersTabPage;
