import { AnySchema } from 'ajv';

import { ICreateAAMembershipPageModel } from '../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { SummaryContainerFields } from '../../../../styledComponents/SummaryContainer/summaryContainer.models';
import { validateFormData } from '../../../../utils/validations/validateFormData';

export const membershipSummarySchema: AnySchema | any = {
  type: 'object',
  properties: {
    [SummaryContainerFields.OverwriteAmount]: {
      type: 'boolean',
    },
  },
  dependencies: {
    [SummaryContainerFields.OverwriteAmount]: {
      if: {
        properties: {
          [SummaryContainerFields.OverwriteAmount]: { enum: [true] },
        },
      },
      then: {
        required: [SummaryContainerFields.OverwrittenAmount],
      },
    },
  },
  required: [],
};

export const validateMembershipSummaryForm = (
  data: ICreateAAMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, membershipSummarySchema);
