import { t } from 'i18next';

import Button from '../../../UI/Button/Button';
import TableCell from '../TableCell/TableCell';

export const overSubscribedSummaryRenderer = (
  isOversubscribed: boolean,
  action?: () => void,
  permissions?: string[],
) => {
  if (!isOversubscribed) {
    return null;
  }
  return (
    <TableCell alignment="right">
      <Button
        permissions={permissions}
        onClick={(evt: MouseEvent) => {
          evt.stopPropagation();
          action?.();
        }}
        styleType="link"
      >
        {`${t('buttons.see-oversubscribed')}`}
      </Button>
    </TableCell>
  );
};
