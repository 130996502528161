import { useTranslation } from 'react-i18next';

import { useSummaryContainerTotalPrice } from './summaryContainerTotalPrice.hooks';

import {
  OverwrittenTotalPriceValue,
  TotalPriceContainer,
  TotalPriceTitle,
  TotalPriceValue,
  TotalPriceWrapper,
} from './SummaryContainerTotalPrice.styles';

interface ISummaryContainerTotalPriceProps {
  currentTotalPrice?: string;
  overwrittenPrice?: number;
  overwriteAmount?: boolean;
}

const SummaryContainerTotalPrice = ({
  currentTotalPrice,
  overwrittenPrice,
  overwriteAmount,
}: ISummaryContainerTotalPriceProps) => {
  const { t } = useTranslation();
  const { totalPriceContent } =
    useSummaryContainerTotalPrice(currentTotalPrice);

  return (
    <TotalPriceWrapper>
      <TotalPriceTitle>
        {t('headers.summaryTotal').toUpperCase()}
      </TotalPriceTitle>
      <TotalPriceContainer>
        <TotalPriceValue>{totalPriceContent}</TotalPriceValue>
        {overwriteAmount && (
          <OverwrittenTotalPriceValue>
            ${overwrittenPrice}
          </OverwrittenTotalPriceValue>
        )}
      </TotalPriceContainer>
    </TotalPriceWrapper>
  );
};

export default SummaryContainerTotalPrice;
