import styled from '@emotion/styled';

import { FieldsDeleteButton } from '../../../UI/FieldsDeleteButton/FieldsDeleteButton';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';

export const StyledFormRow = styled(FormRow)`
  justify-content: space-between;
`;

export const RemoveButton = styled(FieldsDeleteButton)`
  align-self: flex-start;
`;
