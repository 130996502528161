import { t } from 'i18next';

import { Gender as GenderModel } from '../../../../models/Gender';

import { MemberType } from '../../../../models/enums/MemberType';
import { CustomForm } from '../../../../models/types/CustomForm';
export interface IFamilyMemberBasicDetailsFormProps extends CustomForm {
  onRemoveContact?: () => void;
  dateOfBirth?: string;
  memberTypesAgeRanges?: Record<MemberTypeForAge, MemberTypeAgeRange>;
  memberTypes?: MemberType[];
}

export type MemberTypeForAge = MemberType.Spouse | MemberType.Dependent;

export type MemberTypeAgeRange = {
  minAge: number;
  maxAge: number;
};

export const FamilyMemberBasicDetailsFormName =
  'family-member-basic-details-form';

export enum FamilyMemberBasicDetailsFields {
  PersonId = 'personId',
  SearchPerson = 'searchedPerson',
  EmailAddress = 'emailAddress',
  DateOfBirth = 'dateOfBirth',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  Gender = 'gender',
  Dependence = 'dependence',
}

export const GenderOptions = Object.values(GenderModel).map((x) => ({
  label: t(`enums.gender.${x}`),
  value: x,
}));
