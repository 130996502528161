import { useTranslation } from 'react-i18next';

import { RenewalApiFilters } from '../../../../models/types/RenewalFilters/RenewalApiFilter';

import Button from '../../../../styledComponents/UI/Button/Button';
import ExportButton from '../Tools/ExportButton/ExportButton';
import FiltersForm from './FiltersForm/FiltersForm';
import { FiltersOptions } from './FiltersForm/FilterTypes/filtersTypes.models';
import FiltersList from './FiltersList/FiltersList';
import { useRenewalFilters } from './renewalFilters.hooks';
import { FilterDefinition } from './renewalFilters.models';
import {
  ButtonsContainer,
  FiltersWrapper,
  TopContainer,
} from './renewalsFilters.styles';
import RenewalStaticFilters from './StaticFilters/RenewalStaticFilters';
import { RenewalStaticFilter } from './StaticFilters/renewalStaticFilters.models';
import RenewalTopFilters from './TopFilters/RenewalTopFilters';
import { RenewalTopFilter } from './TopFilters/renewalTopFilters.models';

type RenewalFiltersProps = {
  filtersDefinitions: FilterDefinition<FiltersOptions>[];
  filtersListLabel: string;
  onFilterChanged?: (filters: Record<string, RenewalApiFilters>) => void;
  onExportClick?: (filters: Record<string, RenewalApiFilters>) => void;
  staticFilters?: RenewalStaticFilter[];
  topFilters?: RenewalTopFilter[];
  isExporting?: boolean;
  exportPermissions?: string[];
};

const RenewalFilters = ({
  filtersDefinitions,
  filtersListLabel,
  onFilterChanged,
  staticFilters,
  topFilters,
  onExportClick,
  isExporting,
  exportPermissions,
}: RenewalFiltersProps) => {
  const { t } = useTranslation();
  const {
    onAddFilterHandler,
    onRemoveFilterHandler,
    filtersWithDefinitions,
    searchFilters,
    onStaticFilterChangeHandler,
    onTopFilterChangeHandler,
    handleExportClick,
  } = useRenewalFilters({
    filtersDefinitions,
    onFilterChanged,
    staticFilters,
    topFilters,
    onExportClick,
  });
  return (
    <FiltersWrapper>
      <RenewalTopFilters
        onFilterChangeHandler={onTopFilterChangeHandler}
        filters={searchFilters}
        availableFilters={topFilters}
      />
      <TopContainer>
        <FiltersForm
          onAddFilter={onAddFilterHandler}
          filtersDefinitions={filtersDefinitions}
        />
        <ButtonsContainer>
          <ExportButton
            permissions={exportPermissions}
            isLoading={isExporting}
            onClick={handleExportClick}
          />
        </ButtonsContainer>
      </TopContainer>

      <FiltersList
        filtersWithDefinitions={filtersWithDefinitions}
        label={filtersListLabel}
        onRemoveFilter={onRemoveFilterHandler}
      />
      <RenewalStaticFilters
        onFilterChangeHandler={onStaticFilterChangeHandler}
        filters={searchFilters}
        availableFilters={staticFilters}
      />
    </FiltersWrapper>
  );
};

export default RenewalFilters;
