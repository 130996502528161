import { t } from 'i18next';

import { KidnapAndExtortionSubscriptionOptions } from '../../../../models/enums/KidnapAndExtortionSubscriptionOptions';

import { OrganizationContractType } from '../../../../models/enums/ContractType';

import { SubscriptionOptionsType } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';

export const subscriptionOptions = (
  contractType?: OrganizationContractType,
): SubscriptionOptionsType[] =>
  contractType === OrganizationContractType.Multiple
    ? [
        {
          name: KidnapAndExtortionSubscriptionOptions.AutoRenewal,
          label: t('fields.autoRenewSubscription.label'),
        },
      ]
    : [];
