import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';

import {
  IMemberAddressInformationFormProps,
  MemberAddressInformationsFields,
} from './MemberAddressInformationForm.models';
import FormAddress from '../FormAddress/FormAddress';
import { FormSectionTitle, SectionWrapper } from '../../Form/Form';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import { StyledFormCheckbox } from './memberAddressInformation.styles';

const MemberAddressInformationForm = ({
  className,
  disabled,
  isSubRowSpace,
}: IMemberAddressInformationFormProps) => {
  const { t } = useTranslation();
  const formState = useFormState();
  const value = formState.values.correspondenceAddressSameAsResidenceAddress;

  return (
    <SectionWrapper className={className}>
      <FormSectionTitle>{t('headers.residence-address')}</FormSectionTitle>

      <FormAddress
        disabled={disabled}
        isGoogleSearchHidden
        isSubRowSpace={isSubRowSpace}
        name={MemberAddressInformationsFields.ResidenceAddress}
      />

      <Field
        component={StyledFormCheckbox}
        disabled={disabled}
        {...getFieldPropertiesForGroup({
          inputId: 'member-address-information',
          name: MemberAddressInformationsFields.CorrespondenceAddressSameAsResidenceAddress,
        })}
        type="checkbox"
        label={t('fields.billingAddressSameAsResidenceAddress.label')}
      />
      {!value && (
        <SectionWrapper>
          <FormSectionTitle>
            {t('headers.correspondence-address-title')}
          </FormSectionTitle>
          <FormAddress
            disabled={disabled}
            isGoogleSearchHidden
            isSubRowSpace={isSubRowSpace}
            name={MemberAddressInformationsFields.CorrespondenceAddress}
          />
        </SectionWrapper>
      )}
    </SectionWrapper>
  );
};

export default MemberAddressInformationForm;
