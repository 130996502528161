import AsyncSelect from 'react-select/async';
import { Props as SelectProps } from 'react-select';

import { FieldWrapper } from '../Field/Field';
import { CustomFieldLabel } from '../../../models/types/CustomFieldLabel';
import { customStyles } from '../MainSelect/mainSelect.styles';
import { DropdownIndicator } from '../MainSelect/components/DropdownIndicator';
import { ValueContainer } from './components/ValueContainer';
import { Input } from './components/Input';

export interface IMainSelectAsyncOption<T> extends CustomFieldLabel {
  label: string;
  value: T;
}

export interface IMainSelectAsyncProps<T>
  extends SelectProps<T>,
    CustomFieldLabel {
  promiseOptions?: (text: string) => Promise<ReadonlyArray<T>>;
  isError?: string;
}

const MainSelectAsync = <T,>({
  className,
  onChange,
  promiseOptions,
  ...props
}: IMainSelectAsyncProps<T>) => {
  const components: any = {
    ValueContainer,
    DropdownIndicator,
    Input,
  };

  return (
    <FieldWrapper>
      <AsyncSelect
        cacheOptions
        {...props}
        styles={customStyles as any}
        className={className}
        classNamePrefix="main-select-async"
        loadOptions={promiseOptions}
        components={components}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};
export default MainSelectAsync;
