import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_DELETE_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';

import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import {
  setDeletedSubscriptionId,
  setSubscriptionSummary,
  updateSubscription,
} from '../../../redux-slices/subscriptions/common';

import {
  IDeleteTLSubscriptionPayload,
  IEditTLSubscriptionOptionsPayload,
  IEditTLSubscriptionRenewalStatusPayload,
  IExtendTLSubscriptionPayload,
  ILoadDatesForNewTLSubscriptionPayload,
  ILoadEditingTLSubscriptionPayload,
  ILoadTLSubscriptionExtensionSummary,
  ILoadTLSubscriptionOverviewPayload,
  ILoadTLSubscriptionRenewalSuggestionPayload,
  ILoadTLSubscriptionSummary,
  IRenewTLSubscriptionPayload,
  IUpdateTLSubscriptionPayload,
} from './models';
import { ITLOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import {
  setTLCurrentEditingSubscription,
  setTLSubscriptionDates,
  setTLSubscriptionExtensionSummary,
  setTLSubscriptionOverview,
  setTLSubscriptionRenewalSuggestion,
} from '../../../redux-slices/subscriptions/travelLicense';
import {
  deleteTLSubscriptionRequest,
  editTLSubscriptionRenewalStatusRequest,
  extendTLSubscriptionRequest,
  getDatesForNewTLSubscription,
  getTLSubscription,
  getTLSubscriptionExtensionSummary,
  getTLSubscriptionRenewalSuggestion,
  getTLSubscriptionSuggestion,
  getTLSubscriptionSummary,
  renewTLSubscriptionRequest,
  updateTLSubscriptionDetails,
  updateTLSubscriptionRequest,
} from './api';
import { ITLSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLSubscriptionRenewalSuggestionModel';
import { ITLOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionSummaryModel';
import { updateRenewalStatusSubscriptions } from '../../../redux-slices/renewalFilter';

const tlSubscriptionsSlice = createSliceSaga({
  name: 'tl-subscriptions-saga',
  caseSagas: {
    *loadTLSubscriptionOverview({
      payload: { error, organizationId, subscriptionId, params, success },
    }: PayloadAction<ILoadTLSubscriptionOverviewPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
        const response: AxiosResponse<ITLOrganizationSubscriptionOverview> =
          yield* call(
            getTLSubscription,
            organizationId,
            subscriptionId,
            params,
          );

        yield put(setTLSubscriptionOverview(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
      }
    },

    *loadDatesForNewTLSubscription({
      payload: { error, organizationId, contractId, success },
    }: PayloadAction<ILoadDatesForNewTLSubscriptionPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING),
        );
        const response: AxiosResponse<ISubscriptionDates> = yield* call(
          getDatesForNewTLSubscription,
          organizationId,
          contractId,
        );

        const parsedDates: ISubscriptionDates = {
          ...response.data,
          effectiveDateFrom: parseDate(response.data.effectiveDateFrom),
          effectiveDateTo: parseDate(response.data.effectiveDateTo),
        };
        yield put(setTLSubscriptionDates(parsedDates));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING),
        );
      }
    },
    *loadEditingTLSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadEditingTLSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
        const response = yield* call(
          getTLSubscriptionSuggestion,
          organizationId,
          subscriptionId,
          cancellationToken,
        );

        yield put(setTLCurrentEditingSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
      }
    },
    *loadTLSubscriptionRenewalSuggestion({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadTLSubscriptionRenewalSuggestionPayload>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<ITLSubscriptionRenewalSuggestionModel> =
          yield* call(
            getTLSubscriptionRenewalSuggestion,
            organizationId,
            subscriptionId,
          );

        yield put(setTLSubscriptionRenewalSuggestion(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
      }
    },
    *loadTLSubscriptionSummary({
      payload: { data, error, organizationId, success },
    }: PayloadAction<ILoadTLSubscriptionSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<ISubscriptionSummaryModel> = yield* call(
          getTLSubscriptionSummary,
          organizationId,
          data,
        );
        const summaryResponse = {
          ...response.data,
        };
        yield put(setSubscriptionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *updateProductTLSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        data,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateTLSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
        yield call(
          updateTLSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
      }
    },
    *deleteProductTLSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteTLSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
        yield* call(
          deleteTLSubscriptionRequest,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield putResolve(setDeletedSubscriptionId([subscriptionId]));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
      }
    },
    *editTLSubscriptionOptions({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IEditTLSubscriptionOptionsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          updateTLSubscriptionDetails,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *extendTLSubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IExtendTLSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          extendTLSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *loadTLSubscriptionExtensionSummary({
      payload: {
        data,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadTLSubscriptionExtensionSummary>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<ITLOrganizationSubscriptionExtensionSummaryModel> =
          yield* call(
            getTLSubscriptionExtensionSummary,
            organizationId,
            subscriptionId,
            data,
            cancellationToken,
          );

        yield put(setTLSubscriptionExtensionSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
      }
    },
    *renewTLSubscription({
      payload: {
        data,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IRenewTLSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
        const response = yield* call(
          renewTLSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        yield put(updateSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
      }
    },
    *editTLSubscriptionRenewalStatus({
      payload: {
        renewalStatus,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditTLSubscriptionRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editTLSubscriptionRenewalStatusRequest,
          organizationId,
          subscriptionId,
          renewalStatus,
          cancellationToken,
        );

        yield put(
          updateRenewalStatusSubscriptions({
            organizationId,
            subscriptionId,
            renewalStatus,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default tlSubscriptionsSlice.saga;
export const {
  deleteProductTLSubscription,
  editTLSubscriptionOptions,
  loadDatesForNewTLSubscription,
  loadEditingTLSubscription,
  loadTLSubscriptionOverview,
  loadTLSubscriptionSummary,
  updateProductTLSubscription,
  renewTLSubscription,
  loadTLSubscriptionRenewalSuggestion,
  extendTLSubscription,
  loadTLSubscriptionExtensionSummary,
  editTLSubscriptionRenewalStatus,
} = tlSubscriptionsSlice.actions;
export const { actions } = tlSubscriptionsSlice;
