import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { userPermissionsLoadedSelector } from '../store/redux-slices/auth/selectors';
import {
  USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING,
  USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING,
  USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING,
  USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING,
  USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING,
  USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING,
  USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING,
} from '../store/redux-slices/processes/constants';
import { loadUserPermissions } from '../store/saga-slices/auth';
import { useActions } from './useActions';
import { useProcessing } from './useProcessing';

export const useUserAuth = () => {
  const { inProgress } = useMsal();
  const isAuth = useIsAuthenticated();

  const LoadUserPermissions = useActions(loadUserPermissions);
  const isPermissionsLoading = useProcessing([
    USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING,
    USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING,
    USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING,
    USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING,
    USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING,
    USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING,
    USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING,
  ]);
  const permissionsLoaded = useSelector(userPermissionsLoadedSelector);

  const authLoading =
    (isAuth && (isPermissionsLoading || !permissionsLoaded)) ||
    inProgress === InteractionStatus.Startup;

  useEffect(() => {
    if (!isAuth) {
      return;
    }
    LoadUserPermissions();
  }, [LoadUserPermissions, isAuth]);

  return { authLoading };
};
