import styled from '@emotion/styled';

import { FormTitle } from '../../../../../styledComponents/Typography/Typography';
import { FormSubmitButton } from '../../../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';

import AddMemberWizardPage from '../../AddMemberWizardPage/AddMemberWizardPage';

export const StyledAddMemberWizardPage = styled(AddMemberWizardPage)`
  display: flex;
`;

export const Title = styled(FormTitle)`
  margin-bottom: 1.5rem;
`;

export const LeftContainer = styled.div`
  flex: 1;
`;

export const RightContainer = styled.div`
  width: 26.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
`;

export const StyledFormSubmitButton = styled(FormSubmitButton)`
  margin-top: 2.75rem;
`;
