import PromoCodeItemStatus from '../../../UI/PromoCodeStatus/PromoCodeStatus';
import { ICellRenderer } from '../cellRenderers.models';
import { PromoCodeStatus } from '../../../../models/enums/PromoCodeStatus';
import TableCell from '../TableCell/TableCell';

export interface IPromoCodeStatusRenderer extends ICellRenderer {
  status: PromoCodeStatus;
}

export const PromoCodeStatusRenderer = ({
  status,
  options,
}: IPromoCodeStatusRenderer) => (
  <TableCell alignment={options.alignment}>
    <PromoCodeItemStatus status={status} />
  </TableCell>
);
