import styled from '@emotion/styled/macro';

import CollapseItem from '../../UI/CollapseItem/CollapseItem';

export const StyledCollapseItem = styled(CollapseItem)`
  gap: 1.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
