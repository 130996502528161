import styled from '@emotion/styled';

import Menu from '../../Menu/Menu';
import Button from '../../UI/Button/Button';

export const StyledMenu = styled(Menu)`
  min-width: 14rem;
`;

export const StyledButton = styled(Button)`
  z-index: 50;
`;
