import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga/macro';
import { AxiosResponse } from 'axios';

import { IOrganizationGGPointOfContactModel } from 'models/CreateOrganizationGGPointOfContact';

import {
  getOrganizations,
  IPagedResponse,
  addOrganization,
  getOrganization,
  getAAOrganizations,
  updateOrganization,
  createOrganizationPointOfContact,
  updateOrganizationPointOfContact,
  createOrganizationGGPointOfContact,
  updateOrganizationGGPointOfContact,
  deleteOrganization as deleteOrganizationRequest,
  deleteOrganizationPointOfContact as deleteOrganizationPointOfContactRequest,
  deleteOrganizationGGPointOfContact as deleteOrganizationGGPointOfContactRequest,
  updateOrganizationNotes,
} from './api';
import {
  setOrganizationsTotalCount,
  setOrganizations,
  setCurrentOrganization,
  setAAOrganizations,
} from '../../redux-slices/organizations';
import { updateProcessState } from '../../redux-slices/processes';
import {
  ORGANIZATIONS_LOADING_PROCESSING,
  ORGANIZATION_ADDING_PROCESSING,
  ORGANIZATION_EDITING_PROCESSING,
  ORGANIZATION_LOADING_PROCESSING,
  ORGANIZATION_NOTES_ADDING_PROCESSING,
  ORGANIZATION_NOTES_EDITING_PROCESSING,
} from '../../redux-slices/processes/constants';
import { ICreateOrganizationModel } from '../../../models/CreateOrganizationModel';
import { IEditOrganizationModel } from '../../../models/EditOrganizationModel';
import { ICallbackSaga } from '../../../models/ICallbackSaga';
import { Log } from '../../../utils/logger';
import { IOrganization } from '../../../models/Organization';
import { IOrganizationList } from '../../../models/OrganizationList';
import { IAAOrganizationList } from '../../../models/AAOrganizationList';
import { ICreateOrganizationPointOfContact } from '../../../models/CreateOrganizationPointOfContact';
import {
  IAddOrganizationNotesModelPayload,
  IEditOrganizationNotesModelPayload,
} from './models';

interface ILoadOrganizationsPayload {
  params: string;
}

export interface ICreateNewOrganizationPayload extends ICallbackSaga {
  organization: ICreateOrganizationModel;
}

export interface IEditOrganizationPayload extends ICallbackSaga {
  organization: IEditOrganizationModel;
  organizationId: string;
}

export interface IDeleteOrganizationPayload extends ICallbackSaga {
  organizationId: string;
}

export interface IDeletePointOfContactPayload extends ICallbackSaga {
  contactId: string;
  organizationId: string;
}

export interface IOrganizationContactPayload extends ICallbackSaga {
  contact: ICreateOrganizationPointOfContact;
  contactId?: string;
  organizationId: string;
}

export interface IGGContactPayload extends ICallbackSaga {
  contact: IOrganizationGGPointOfContactModel;
  contactId?: string;
  organizationId: string;
}

function* loadOrganizationById(id: string) {
  const { data }: AxiosResponse<IOrganization> = yield* call(
    getOrganization,
    id,
  );
  yield put(setCurrentOrganization(data));
}

const organizationsSlice = createSliceSaga({
  name: 'organizations-saga',
  caseSagas: {
    *loadOrganizations({
      payload: { params },
    }: PayloadAction<ILoadOrganizationsPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATIONS_LOADING_PROCESSING));
        const { data }: AxiosResponse<IPagedResponse<IOrganizationList>> =
          yield* call(getOrganizations, params);
        yield put(setOrganizations(data.items));
        yield put(setOrganizationsTotalCount(data.totalCount));
      } catch (err: any) {
        Log.errorException(err);
      } finally {
        yield put(updateProcessState(ORGANIZATIONS_LOADING_PROCESSING));
      }
    },
    *loadAAOrganizations({
      payload: { params },
    }: PayloadAction<ILoadOrganizationsPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATIONS_LOADING_PROCESSING));
        const { data }: AxiosResponse<IPagedResponse<IAAOrganizationList>> =
          yield* call(getAAOrganizations, params);
        yield put(setAAOrganizations(data.items));
        yield put(setOrganizationsTotalCount(data.totalCount));
      } catch (err: any) {
        Log.errorException(err);
      } finally {
        yield put(updateProcessState(ORGANIZATIONS_LOADING_PROCESSING));
      }
    },
    *createNewOrganization({
      payload: { error, organization, success },
    }: PayloadAction<ICreateNewOrganizationPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_ADDING_PROCESSING));
        const response = yield* call(addOrganization, organization);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_ADDING_PROCESSING));
      }
    },
    *loadOrganization({ payload }: PayloadAction<string>) {
      try {
        yield put(updateProcessState(ORGANIZATION_LOADING_PROCESSING));
        yield call(loadOrganizationById, payload);
      } catch (err: any) {
        Log.errorException(err);
      } finally {
        yield put(updateProcessState(ORGANIZATION_LOADING_PROCESSING));
      }
    },
    *editOrganization({
      payload: { error, organization, organizationId, success },
    }: PayloadAction<IEditOrganizationPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(updateOrganization, organizationId, organization);
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *editOrganizationContact({
      payload: { contact, contactId, error, organizationId, success },
    }: PayloadAction<IOrganizationContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(
          updateOrganizationPointOfContact,
          contactId,
          organizationId,
          contact,
        );
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *addOrganizationContact({
      payload: { contact, error, organizationId, success },
    }: PayloadAction<IOrganizationContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(createOrganizationPointOfContact, organizationId, contact);
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *editGGContact({
      payload: {
        contact,
        contactId: personContactId,
        error,
        organizationId,
        success,
      },
    }: PayloadAction<IGGContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(
          updateOrganizationGGPointOfContact,
          personContactId,
          organizationId,
          contact,
        );
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *addGGContact({
      payload: { contact, error, organizationId, success },
    }: PayloadAction<IGGContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(createOrganizationGGPointOfContact, organizationId, contact);
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *deleteOrganization({
      payload: { error, organizationId, success },
    }: PayloadAction<IDeleteOrganizationPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(deleteOrganizationRequest, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.();
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *deleteOrganizationPointOfContact({
      payload: { contactId, error, organizationId, success },
    }: PayloadAction<IDeletePointOfContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(
          deleteOrganizationPointOfContactRequest,
          organizationId,
          contactId,
        );
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.();
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *deleteOrganizationGGPointOfContact({
      payload: { contactId, error, organizationId, success },
    }: PayloadAction<IDeletePointOfContactPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
        yield call(
          deleteOrganizationGGPointOfContactRequest,
          organizationId,
          contactId,
        );
        yield call(loadOrganizationById, organizationId);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.();
      } finally {
        yield put(updateProcessState(ORGANIZATION_EDITING_PROCESSING));
      }
    },
    *addOrganizationNotes({
      payload: { notes, error, organizationId, success, cancellationToken },
    }: PayloadAction<IAddOrganizationNotesModelPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_NOTES_ADDING_PROCESSING));
        yield* call(
          updateOrganizationNotes,
          organizationId,
          { notes },
          cancellationToken,
        );

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_NOTES_ADDING_PROCESSING));
      }
    },
    *editOrganizationNotes({
      payload: { notes, error, organizationId, success, cancellationToken },
    }: PayloadAction<IEditOrganizationNotesModelPayload>) {
      try {
        yield put(updateProcessState(ORGANIZATION_NOTES_EDITING_PROCESSING));
        yield call(
          updateOrganizationNotes,
          organizationId,
          { notes },
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(ORGANIZATION_NOTES_EDITING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default organizationsSlice.saga;
export const {
  addGGContact,
  addOrganizationContact,
  createNewOrganization,
  deleteOrganization,
  deleteOrganizationGGPointOfContact,
  deleteOrganizationPointOfContact,
  editGGContact,
  editOrganization,
  editOrganizationContact,
  loadAAOrganizations,
  loadOrganization,
  loadOrganizations,
  editOrganizationNotes,
  addOrganizationNotes,
} = organizationsSlice.actions;
export const { actions } = organizationsSlice;
