import FormErrorProvider from '../../FormErrorProvider/FormErrorProvider';
import { IPerson } from '../../../models/Person';
import { Modal } from '../../Modal/Modal';
import { EDIT_MEMBER_MODAL_NAME } from './editMemberModal.const';
import { useEditMemberModal } from './editMemberModal.hooks';
import EditMemberModalForm from './EditMemberModalForm/EditMemberModalForm';

interface IEditMemberModalProps {
  onMemberEdited?: (person: IPerson) => void;
  onModalCancel?: () => void;
}

const EditMemberModal = ({
  onMemberEdited,
  onModalCancel,
}: IEditMemberModalProps) => {
  const { loadPersonData, personLoading } = useEditMemberModal();

  return (
    <FormErrorProvider>
      <Modal
        name={EDIT_MEMBER_MODAL_NAME}
        onModalShow={loadPersonData}
        onModalClose={onModalCancel}
        isLoading={personLoading}
      >
        <EditMemberModalForm onMemberEdited={onMemberEdited} />
      </Modal>
    </FormErrorProvider>
  );
};

export default EditMemberModal;
