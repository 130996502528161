import { AlertTemplateProps } from 'react-alert';
import { Trans } from 'react-i18next';

import { CloseButton, Wrapper } from './alertTemplate.styles';

export type IAlertTemplateProps = AlertTemplateProps;

const AlertTemplate = ({
  close,
  message,
  options,
  style,
}: IAlertTemplateProps) => (
  <Wrapper type={options.type} style={style}>
    <CloseButton onClick={close} />
    <Trans>{message}</Trans>
  </Wrapper>
);

export default AlertTemplate;
