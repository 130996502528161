import { FilterDefinition } from '../../renewalFilters.models';
import { FilterType } from '../filterForm.models';
import DateFilter from './DateFilter/DateFilter';
import { FiltersOptions } from './filtersTypes.models';
import SelectCategoryFilter from './SelectCategoryFilter/SelectCategoryFilter';
import { SelectCategoryFilterOptionsModel } from './SelectCategoryFilter/selectCategoryFilter.models';
import SelectFilter from './SelectFilter/SelectFilter';
import { SelectFilterOptionsModel } from './SelectFilter/selectFilter.models';

type FilterTypeProp = {
  filterDefinition: FilterDefinition<FiltersOptions>;
};
const FilterTypes = ({ filterDefinition }: FilterTypeProp) => {
  switch (filterDefinition?.filterType) {
    case FilterType.Date:
      return (
        <DateFilter
          filterDefinition={filterDefinition as FilterDefinition<void>}
        />
      );
    case FilterType.Select:
      return (
        <SelectFilter
          filterDefinition={
            filterDefinition as FilterDefinition<SelectFilterOptionsModel>
          }
        />
      );
    case FilterType.SelectCategory:
      return (
        <SelectCategoryFilter
          filterDefinition={
            filterDefinition as FilterDefinition<SelectCategoryFilterOptionsModel>
          }
        />
      );
    default:
      return null;
  }
};

export default FilterTypes;
