import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import DOCMembersList from '../../../styledComponents/ProductMembersList/DOCMembersList/DOCMembersList';
import { docPageFilters } from './membersDutyOfCarePage.const';
import { useMembersDutyOfCare } from './membersDutyOfCarePage.hooks';

const MembersDutyOfCarePage = () => {
  const {
    membersLoading,
    pageListMembers,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    docPackages,
  } = useMembersDutyOfCare();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <DOCMembersList
        tableProps={{
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          isLoading: membersLoading,
          data: pageListMembers,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: docPageFilters,
          packages: docPackages,
        }}
      />
    </>
  );
};

export default MembersDutyOfCarePage;
