import {
  SystemGroupPermissions,
  PaymentPermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canViewCreditCardPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Payment,
    PaymentPermissions.CanViewPersonCreditCards,
  ),
];

export const canAddCreditCardPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Payment,
    PaymentPermissions.CanAddPersonCreditCard,
  ),
];
export const canReplaceCreditCardPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Payment,
    PaymentPermissions.CanReplacePersonCreditCard,
  ),
];
export const canDeleteCreditCardPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Payment,
    PaymentPermissions.CanDeletePersonCreditCard,
  ),
];
