import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';

import { OrganizationType } from '../../../models/enums/OrganizationType';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { EditProductSubscriptionPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import {
  organizationNameSelector,
  organizationTypeSelector,
} from '../../../store/redux-slices/organizations/selectors';
import {
  clearAACurrentSubscription,
  clearAACurrentSubscriptionEditing,
  clearAASubscriptionHelperData,
} from '../../../store/redux-slices/subscriptions/airAmbulance';
import { aaSubscriptionOverviewSelector } from '../../../store/redux-slices/subscriptions/airAmbulance/selectors';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { ILoadOrganizationContractsPayload } from '../../../store/saga-slices/contracts/models';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import {
  loadDatesForNewAASubscription,
  loadEditingAASubscription,
  loadAASubscriptionOverview,
} from '../../../store/saga-slices/subscriptions/airAmbulance';
import {
  ILoadEditingAASubscriptionPayload,
  ILoadSubscriptionOverviewPayload,
} from '../../../store/saga-slices/subscriptions/airAmbulance/models';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';

export const useEditAASubscriptionPage = () => {
  const navigate = useNavigateSearch();

  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewAASubscription);
  const LoadSubscriptionOverview = useActions(loadAASubscriptionOverview);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadOrganization = useActions(loadOrganization);
  const LoadEditingSubscription = useActions(loadEditingAASubscription);
  const ClearAASubscriptionHelperData = useActions(
    clearAASubscriptionHelperData,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);
  const ClearSubscriptionOverview = useActions(clearAACurrentSubscription);
  const ClearCurrentSubscriptionEditing = useActions(
    clearAACurrentSubscriptionEditing,
  );

  const organizationName = useSelector(organizationNameSelector);
  const organizationType = useSelector(organizationTypeSelector);
  const subscriptionOverview = useSelector(aaSubscriptionOverviewSelector);

  const { organizationId, subscriptionId, subscriptionType } =
    useParams<EditProductSubscriptionPageParamsType>();

  useEffect(() => {
    const payload: ILoadSubscriptionOverviewPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionOverview(payload);
  }, [LoadSubscriptionOverview, organizationId, subscriptionId]);

  useEffect(() => {
    if (subscriptionOverview && !subscriptionOverview.isDraft) {
      navigate(
        RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
        {
          params: {
            [ProductSubscriptionOverviewPageParams.OrganizationId]:
              organizationId,
            [ProductSubscriptionOverviewPageParams.SubscriptionId]:
              subscriptionId,
            [ProductSubscriptionOverviewPageParams.SubscriptionType]:
              subscriptionType,
          },
        },
      );
    }
  }, [
    navigate,
    organizationId,
    subscriptionId,
    subscriptionOverview,
    subscriptionType,
  ]);

  useEffect(() => {
    const payload: ILoadEditingAASubscriptionPayload = {
      organizationId,
      subscriptionId,
      subscriptionType,
    };

    LoadEditingSubscription(payload);
  }, [
    LoadEditingSubscription,
    organizationId,
    subscriptionId,
    subscriptionType,
  ]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    if (
      organizationType === OrganizationType.Agent ||
      organizationType === OrganizationType.Group
    ) {
      navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
        params: { id: organizationId },
      });
    }
  }, [navigate, organizationId, organizationType]);

  useEffect(() => {
    const loadOrganizationContractsPayload: ILoadOrganizationContractsPayload =
      {
        organizationId,
      };

    LoadOrganizationContracts(loadOrganizationContractsPayload);
    LoadOrganization(organizationId);
  }, [
    LoadDatesForNewSubscription,
    LoadOrganization,
    LoadOrganizationContracts,
    organizationId,
  ]);

  useEffect(
    () => () => {
      ClearOrganizationContracts();
      ClearAASubscriptionHelperData();
      ClearSubscriptionOverview();
      ClearCurrentSubscriptionEditing();
      ClearSubscriptionHelperData();
    },
    [
      ClearAASubscriptionHelperData,
      ClearOrganizationContracts,
      ClearSubscriptionOverview,
      ClearCurrentSubscriptionEditing,
      ClearSubscriptionHelperData,
    ],
  );

  return {
    organizationName,
    organizationId,
    loadDatesForNewSubscriptionHandler,
    subscriptionType,
  };
};
