import {
  canEditAASubscriptionPermissions,
  canEditDOCSubscriptionPermissions,
  canEditKESubscriptionPermissions,
  canEditSRSubscriptionPermissions,
  canEditTLSubscriptionPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../models/ProductType';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';
import { AASubscriptionRenewPage } from './AAEditSubscriptionRenewPage/AAEditSubscriptionRenewPage';
import { DOCSubscriptionRenewPage } from './DOCEditSubscriptionRenewPage/DOCEditSubscriptionRenewPage';
import { KESubscriptionRenewPage } from './KEEditSubscriptionRenewPage/KESubscriptionRenewPage';
import { SRSubscriptionRenewPage } from './SREditSubscriptionRenewPage/SRSubscriptionRenewPage';
import { TLSubscriptionRenewPage } from './TLEditSubscriptionRenewPage/TLEditSubscriptionRenewPage';

export const EditRenewSubscriptionPages: Partial<
  Record<ProductType, () => JSX.Element>
> = {
  [ProductType.AirAmbulance]: AASubscriptionRenewPage,
  [ProductType.DutyOfCare]: DOCSubscriptionRenewPage,
  [ProductType.KidnapAndExtortion]: KESubscriptionRenewPage,
  [ProductType.SecurityResponse]: SRSubscriptionRenewPage,
  [ProductType.TravelLicense]: TLSubscriptionRenewPage,
};

export const EditRenewSubscriptionViewsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canEditAASubscriptionPermissions,
    KidnapAndExtortion: canEditKESubscriptionPermissions,
    SecurityResponse: canEditSRSubscriptionPermissions,
    DutyOfCare: canEditDOCSubscriptionPermissions,
    TravelLicense: canEditTLSubscriptionPermissions,
  };
