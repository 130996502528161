import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { useFormField } from '../../../../hooks/useFormField';
import { useBaseFormInput } from '../../../../hooks/useBaseFormInput';
import { StyledNumericInput } from './formNumericInput.styles';
import { INumericInputProps } from '../../NumericInput/NumericInput';

export interface IFormNumberInput
  extends FieldRenderProps<string>,
    INumericInputProps {
  showError?: boolean;
  onBlur?: () => void;
}

export const FormNumericInput = ({
  className,
  disabled,
  input,
  inputId,
  label,
  meta,
  labelAnimation = true,
  onBlur,
  maxLength,
  showError = true,
  ...props
}: IFormNumberInput) => {
  const { onBlurHandler } = useFormField({
    input,
    labelAnimation,
    placeholder: props.placeholder,
    label,
    onBlur,
  });

  const { inputValue, onChangeHandler } = useBaseFormInput({ input });

  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledNumericInput
        {...props}
        error={meta.error && meta.touched}
        disabled={disabled}
        onChange={onChangeHandler as any}
        onBlur={onBlurHandler}
        value={+inputValue}
      />
      {showError && meta.touched && meta.error && (
        <ErrorLabel>{meta.error}</ErrorLabel>
      )}
    </FormFieldWrapper>
  );
};
