import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../hooks/useProcessing';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import {
  organizationGGPointOfContactByIdSelector,
  organizationSelector,
} from '../../../../../../store/redux-slices/organizations/selectors';
import { ORGANIZATION_EDITING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import {
  deleteOrganizationGGPointOfContact,
  IDeletePointOfContactPayload,
} from '../../../../../../store/saga-slices/organizations';
import { DELETE_GG_POINT_OF_CONTACT_MODAL_NAME } from './deleteGGPointOfContactModal.consts';
import { DeleteGGPointOfContactModalDetails } from './deleteGGPointOfContactModal.models';

export const useDeleteGGPointOfContactModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const organization = useSelector(organizationSelector);
  const UpdateModalState = useActions(updateModalState);

  const DeleteOrganizationGGPointOfContact = useActions(
    deleteOrganizationGGPointOfContact,
  );
  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);

  const { contactId }: DeleteGGPointOfContactModalDetails = useModalDetails(
    DELETE_GG_POINT_OF_CONTACT_MODAL_NAME,
  );
  const pointOfContact = useSelector(
    organizationGGPointOfContactByIdSelector(contactId),
  );

  const deleteOrganizationPointOfContactHandler = () => {
    const payload: IDeletePointOfContactPayload = {
      organizationId: organization.id,
      contactId: pointOfContact.id,
      success: () => {
        alert.success(t('alerts.poc-delete-success'));

        UpdateModalState(DELETE_GG_POINT_OF_CONTACT_MODAL_NAME);
      },
      error: (err) => {
        alert.error(t('alerts.poc-delete-fail'));
      },
    };

    DeleteOrganizationGGPointOfContact(payload);
  };

  const onCancelHandler = () =>
    UpdateModalState(DELETE_GG_POINT_OF_CONTACT_MODAL_NAME);

  return {
    deleteOrganizationPointOfContactHandler,
    organizationUpdating,
    pointOfContact,
    onCancelHandler,
  };
};
