import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormErrorActivator from '../../../FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { IPerson } from '../../../../models/Person';
import FormAddressInformation from '../../../Forms/FormAddressInformations/FormAddressInformations';
import FormMemberDetails from '../../../Forms/FormMemberDetails/FormMemberDetails';
import ModalFormPage from '../../../ModalFormPage/ModalFormPage';
import { useEditMemberModalForm } from './editMemberModalForm.hooks';
import {
  FormAddressInformationsFields,
  FormMemberDetailsFields,
} from './editMemberModalForm.models';

interface IEditMemberModalFormProps {
  onMemberEdited?: (person: IPerson) => void;
  onModalCancel?: () => void;
}

const EditMemberModalForm = ({ onMemberEdited }: IEditMemberModalFormProps) => {
  const { t } = useTranslation();
  const {
    submitHandler,
    dataProcessing,
    formInitState,
    validateForm,
    onCancelHandler,
    personName,
  } = useEditMemberModalForm({
    onMemberEdited,
  });
  return (
    <Form
      initialValues={formInitState}
      keepDirtyOnReinitialize
      onSubmit={submitHandler}
      validate={validateForm}
    >
      {({ ...renderProps }) => (
        <FormErrorActivator>
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            title={t('headers.edit-person-details', { name: personName })}
            isProcessing={dataProcessing}
            onCancel={onCancelHandler}
          >
            <FormMemberDetails
              disabled={dataProcessing}
              fields={FormMemberDetailsFields}
            />
            <FormAddressInformation
              fields={FormAddressInformationsFields}
              disabled={dataProcessing}
            />
          </ModalFormPage>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default EditMemberModalForm;
