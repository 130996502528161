import { useCallback } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { AddPromoCodeCommissionFormFields } from './addPromoCodeCommissionForm.models';

export const useAddPromoCodeCommissionForm = () => {
  const {
    input: { onChange: commissionPercentageOnChange },
  }: FieldRenderProps<string> = useField(
    AddPromoCodeCommissionFormFields.CommissionPercentage,
  );

  const {
    input: { onChange: commissionValueOnChange },
  }: FieldRenderProps<string> = useField(
    AddPromoCodeCommissionFormFields.CommissionValue,
  );

  const onChangeCommissionTypeHandler = useCallback(() => {
    commissionPercentageOnChange(null);
    commissionValueOnChange(null);
  }, [commissionPercentageOnChange, commissionValueOnChange]);

  return { onChangeCommissionTypeHandler };
};
