import { useTranslation } from 'react-i18next';

import { Title, Wrapper } from './extendDOCSubscriptionExtensionsForm.styles';
import { ProductType } from '../../../../models/ProductType';
import { FormColumn } from '../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import ExtendSubscriptionMembershipPlanFormTable from '../ExtendDOCSubscriptionPackageFormTable/ExtendDOCSubscriptionPackageFormTable';
import { ExtendDOCSubscriptionExtensionsFormFields } from './extendDOCSubscriptionExtensionsForm.models';

interface IExtendDOCSubscriptionExtensionsFormProps {
  className?: string;
  disabled?: boolean;
  productType?: ProductType;
  data?: IDOCOrganizationSubscriptionExtensionData;
}

const ExtendDOCSubscriptionExtensionsForm = ({
  className,
  disabled,
  productType,
  data,
}: IExtendDOCSubscriptionExtensionsFormProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      <Title>{t('headers.capacity')}</Title>
      <FormColumn>
        <ExtendSubscriptionMembershipPlanFormTable
          disabled={disabled}
          name={
            ExtendDOCSubscriptionExtensionsFormFields.ChangeSubscriptionPackageModel
          }
          packageData={data}
          productType={productType}
        />
      </FormColumn>
    </Wrapper>
  );
};

export default ExtendDOCSubscriptionExtensionsForm;
