import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useEditGGPointOfContact } from './editGGPointOfContactModal.hooks';
import { EDIT_GG_POINT_OF_CONTACT_MODAL_NAME } from './editGGPointOfContactModal.const';
import GGPointOfContactFormModal from '../../../../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactForm/GGPointOfContactFormModal';
import { SmallModal } from '../../../../../../styledComponents/ModalCommon/ModalCommon';
import FormErrorActivator from '../../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

const EditGGPointOfContactModal = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    initState,
    modalShowHandler,
    organizationUpdating,
    validateForm,
    onCancelHandler,
    employeeList,
    employeesLoading,
    rolesGGLoading,
    rolesGGList,
  } = useEditGGPointOfContact();
  return (
    <SmallModal
      disabled={organizationUpdating}
      isLoading={employeesLoading || rolesGGLoading}
      name={EDIT_GG_POINT_OF_CONTACT_MODAL_NAME}
      onModalShow={modalShowHandler}
    >
      <Form
        initialValues={initState}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ ...renderProps }) => (
          <FormErrorActivator>
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={organizationUpdating}
              onCancel={onCancelHandler}
              title={t('headers.edit-gg-point-of-contact')}
              mode="small"
            >
              <GGPointOfContactFormModal
                disabled={organizationUpdating}
                items={employeeList}
                roles={rolesGGList}
              />
            </ModalFormPage>
          </FormErrorActivator>
        )}
      </Form>
    </SmallModal>
  );
};

export default EditGGPointOfContactModal;
