import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { SubscriptionPricingFormFields } from './subscriptionPricing.models';

interface IValidateSubscriptionPricingForm {
  membershipPlans: any[];
}

export const subscriptionPricingSchema: AnySchema | any = {
  properties: {
    [SubscriptionPricingFormFields.Pricing]: {
      errorMessage: t('api-validation.AtLeastOnePlanMustBeAdded'),
      type: 'object',
    },
  },
  required: [],
  type: 'object',
};

export const validateSubscriptionPricingForm = (
  data: IValidateSubscriptionPricingForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionPricingSchema);
