import { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { FormFieldWrapper } from '../../../Form/Form';
import DatePicker, { DatePickerProps } from '../../DatePicker/DatePicker';
import { ErrorLabel } from '../../Field/Field';

interface IFormDatePickerProps
  extends FieldRenderProps<string>,
    DatePickerProps {
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  isLabelHidden?: boolean;
  mask?: string;
  formatMask?: string;
  readOnly?: boolean;
}

const FormDatePicker = ({
  className,
  datePickerAdditionalClassName,
  input,
  isLabelHidden,
  mask,
  formatMask,
  meta: { error, touched },
  ...props
}: IFormDatePickerProps) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (date: string) => {
      input.onChange(date);
    },
    [input],
  );

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <DatePicker
        {...props}
        format={formatMask}
        mask={mask}
        onChange={onChange}
        value={input.value}
        onClose={() => input.onBlur()}
        onBlur={() => input.onBlur()}
        isError={touched && error}
        label={props.label || t(`fields.${getPropertyName(input.name)}.label`)}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};

export default FormDatePicker;
