export type QueryParamItem = {
  name: string;
  value: string | boolean;
};

export type QueryParamsProps = {
  initQueryString?: string;
  queryParams?: QueryParamItem[];
};

export function prepareQueryParams({
  initQueryString = '',
  queryParams = [],
}: QueryParamsProps) {
  const preparedQueryString = queryParams
    .filter((item) => item.value)
    .map((item) => `${item.name}=${item.value}`)
    .join('&');

  const queryString = [initQueryString, preparedQueryString]
    .filter((x) => x)
    .join('&');

  return queryString && `?${queryString}`;
}
