import {
  canAddAAMembershipPermissions,
  canAddDOCMembershipPermissions,
  canAddKEMembershipPermissions,
  canAddSRMembershipPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';
import { AvailableProductPermissionsType } from '../../../../../../models/types/AvailableProductViewsPermissionsType';

export const AddMembershipPagePermissions: AvailableProductPermissionsType = {
  AirAmbulance: canAddAAMembershipPermissions,
  KidnapAndExtortion: canAddKEMembershipPermissions,
  SecurityResponse: canAddSRMembershipPermissions,
  DutyOfCare: canAddDOCMembershipPermissions,
};
