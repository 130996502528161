import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Status } from '../../models/enums/Status';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

type StatusTextProps = {
  status?: Status;
  isBold?: boolean;
};

export const IconWrapper = styled.div`
  align-self: flex-start;
  margin-top: 0.125rem;
`;

export const StatusText = styled.p<StatusTextProps>`
  text-align: left;
  color: ${({ theme }) => theme.color.dark1};
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 700;
    `}

  ${({ status, theme }) => {
    switch (status) {
      case 'error':
        return css`
          color: ${theme.color.error};
        `;
      case 'success':
        return css`
          color: ${theme.color.success};
        `;
      case 'warning':
        return css`
          color: ${theme.color.warning};
        `;
    }
  }}
`;
