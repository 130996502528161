import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING,
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_DELETE_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
  SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';

import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import {
  setDeletedSubscriptionId,
  setSubscriptionExtensionSummary,
  setSubscriptionSummary,
  updateSubscription,
} from '../../../redux-slices/subscriptions/common';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import {
  IDeleteSRSubscriptionPayload,
  IEditSRSubscriptionOptionsPayload,
  IEditSRSubscriptionRenewalStatusPayload,
  IExtendSRSubscriptionPayload,
  ILoadDatesForNewSRSubscriptionPayload,
  ILoadEditingSRSubscriptionPayload,
  ILoadSRSubscriptionExtensionSummary,
  ILoadSRSubscriptionOverviewPayload,
  ILoadSRSubscriptionRenewalAnalysePayload,
  ILoadSRSubscriptionRenewalSuggestionPayload,
  ILoadSRSubscriptionSummary,
  IRenewSRSubscriptionPayload,
  ISendSRSubscriptionRenewalMembershipsRequest,
  IUpdateSRSubscriptionPayload,
} from './models';
import {
  deleteSRSubscriptionRequest,
  editSRSubscriptionRenewalStatusRequest,
  extendSRSubscriptionRequest,
  getDatesForNewSRSubscription,
  getSRSubscription,
  getSRSubscriptionExtensionSummary,
  getSRSubscriptionRenewalAnalyseRequest,
  getSRSubscriptionRenewalSuggestion,
  getSRSubscriptionSuggestion,
  getSRSubscriptionSummary,
  renewSRSubscriptionRequest,
  sendSRSubscriptionRenewalMembershipsRequest,
  updateSRSubscriptionDetails,
  updateSRSubscriptionRequest,
} from './api';
import { ISROrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOverview';
import { ISRSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SRSubscriptionRenewalSuggestionModel';
import {
  clearSRSubscriptionRenewalAnalyse,
  setSRCurrentEditingSubscription,
  setSRSubscriptionDates,
  setSRSubscriptionOverview,
  setSRSubscriptionRenewalAnalyse,
  setSRSubscriptionRenewalSuggestion,
} from '../../../redux-slices/subscriptions/securityResponse';
import { updateRenewalStatusSubscriptions } from '../../../redux-slices/renewalFilter';

const srSubscriptionsSlice = createSliceSaga({
  name: 'sr-subscriptions-saga',
  caseSagas: {
    *loadSRSubscriptionOverview({
      payload: { error, organizationId, subscriptionId, success },
    }: PayloadAction<ILoadSRSubscriptionOverviewPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
        const response: AxiosResponse<ISROrganizationSubscriptionOverview> =
          yield* call(getSRSubscription, organizationId, subscriptionId);

        yield put(setSRSubscriptionOverview(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
      }
    },

    *loadDatesForNewSRSubscription({
      payload: { error, organizationId, contractId, success },
    }: PayloadAction<ILoadDatesForNewSRSubscriptionPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING),
        );
        const response: AxiosResponse<ISubscriptionDates> = yield* call(
          getDatesForNewSRSubscription,
          organizationId,
          contractId,
        );

        const parsedDates: ISubscriptionDates = {
          ...response.data,
          effectiveDateFrom: parseDate(response.data.effectiveDateFrom),
          effectiveDateTo: parseDate(response.data.effectiveDateTo),
        };
        yield put(setSRSubscriptionDates(parsedDates));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING),
        );
      }
    },
    *loadEditingSRSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadEditingSRSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
        const response = yield* call(
          getSRSubscriptionSuggestion,
          organizationId,
          subscriptionId,
          cancellationToken,
        );

        yield put(setSRCurrentEditingSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
      }
    },
    *loadSRSubscriptionRenewalSuggestion({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadSRSubscriptionRenewalSuggestionPayload>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<ISRSubscriptionRenewalSuggestionModel> =
          yield* call(
            getSRSubscriptionRenewalSuggestion,
            organizationId,
            subscriptionId,
          );

        yield put(setSRSubscriptionRenewalSuggestion(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
      }
    },
    *loadSRSubscriptionSummary({
      payload: { data, error, organizationId, success },
    }: PayloadAction<ILoadSRSubscriptionSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<ISubscriptionSummaryModel> = yield* call(
          getSRSubscriptionSummary,
          organizationId,
          data,
        );
        const summaryResponse = {
          ...response.data,
        };
        yield put(setSubscriptionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *updateProductSRSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        data,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateSRSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
        yield call(
          updateSRSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
      }
    },
    *deleteProductSRSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteSRSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
        yield* call(
          deleteSRSubscriptionRequest,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield putResolve(setDeletedSubscriptionId([subscriptionId]));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
      }
    },
    *editSRSubscriptionOptions({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IEditSRSubscriptionOptionsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          updateSRSubscriptionDetails,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *extendSRSubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IExtendSRSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          extendSRSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *loadSRSubscriptionExtensionSummary({
      payload: {
        data,
        error,
        organizationId,
        subscriptionId,
        productType,
        success,
      },
    }: PayloadAction<ILoadSRSubscriptionExtensionSummary>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IOrganizationSubscriptionExtensionSummaryModel> =
          yield* call(
            getSRSubscriptionExtensionSummary,
            organizationId,
            subscriptionId,
            data,
          );
        const summaryResponse = {
          ...response.data,
          subscriptionType: productType,
        };
        yield put(setSubscriptionExtensionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
      }
    },
    *renewSRSubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IRenewSRSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
        const response = yield* call(
          renewSRSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(updateSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
      }
    },
    *loadSRSubscriptionRenewalAnalyse({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadSRSubscriptionRenewalAnalysePayload>) {
      try {
        yield put(clearSRSubscriptionRenewalAnalyse());
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
        const response: AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel> =
          yield* call(
            getSRSubscriptionRenewalAnalyseRequest,
            organizationId,
            subscriptionId,
          );
        yield put(setSRSubscriptionRenewalAnalyse(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
      }
    },
    *sendSRSubscriptionRenewalMemberships({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<ISendSRSubscriptionRenewalMembershipsRequest>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
        const response = yield* call(
          sendSRSubscriptionRenewalMembershipsRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(clearSRSubscriptionRenewalAnalyse());
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
      }
    },
    *editSRSubscriptionRenewalStatus({
      payload: {
        renewalStatus,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditSRSubscriptionRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editSRSubscriptionRenewalStatusRequest,
          organizationId,
          subscriptionId,
          renewalStatus,
          cancellationToken,
        );

        yield put(
          updateRenewalStatusSubscriptions({
            organizationId,
            subscriptionId,
            renewalStatus,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default srSubscriptionsSlice.saga;
export const {
  loadSRSubscriptionOverview,
  loadDatesForNewSRSubscription,
  loadEditingSRSubscription,
  loadSRSubscriptionSummary,
  updateProductSRSubscription,
  deleteProductSRSubscription,
  editSRSubscriptionOptions,
  extendSRSubscription,
  loadSRSubscriptionExtensionSummary,
  loadSRSubscriptionRenewalSuggestion,
  renewSRSubscription,
  loadSRSubscriptionRenewalAnalyse,
  sendSRSubscriptionRenewalMemberships,
  editSRSubscriptionRenewalStatus,
} = srSubscriptionsSlice.actions;
export const { actions } = srSubscriptionsSlice;
