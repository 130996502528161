import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { MaxCountOfChars } from './notesForm.const';
import { INotesForm, NotesFormFields } from './notesForm.models';

export const notesSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [NotesFormFields.Notes]: {
      type: 'string',
      maxLength: MaxCountOfChars,
    },
  },
  required: [],
};

export const validateNotesForm = (
  data: INotesForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, notesSchema);
