import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import { withPermissions } from '../../../../hoc/withPermissions';
import CheckIcon from '../../../../assets/icons/check-icon.svg';

type SelectAllButtonProps = {
  checked: boolean;
};

export const SelectAllButton = styled.button<SelectAllButtonProps>`
  background: transparent;
  border: none;
  color: ${({ theme, checked }) =>
    checked ? theme.color.lighter_blue : theme.color.dark1};
  font: ${({ theme, checked }) =>
    checked
      ? `700 normal 0.875rem/1.5rem ${theme.font.gotham}`
      : `400 normal 0.875rem/1.5rem ${theme.font.gotham}`};
  outline: none;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &:after {
    content: '';
    background: url(${CheckIcon}) 50% no-repeat;
    height: 1rem;
    margin: 0;
    opacity: ${({ checked }) => (checked ? '1' : '0')};
    transition: all 0.2s ease;
    width: 1rem;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

const AddButtonWithPermission = styled('button', {
  shouldForwardProp: isPropValid,
})`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.secondary1};
  font: 700 normal 1rem/2rem ${({ theme }) => theme.font.gotham};
  outline: none;
  padding: 0 1rem;
  text-align: left;
  width: 100%;
`;

export const AddButton = withPermissions(AddButtonWithPermission);
