import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IEditSROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/EditSROrganizationSubscriptionModel';
import { EditProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadSRSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/securityResponse';
import { ILoadSRSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/securityResponse/models';

type SummaryLoaderProps = {
  values: IEditSROrganizationSubscriptionModel;
};

const SRSummaryLoader = ({ values }: SummaryLoaderProps) => {
  const { organizationId } = useParams<EditProductSubscriptionPageParamsType>();

  const LoadSubscriptionSummary = useActions(loadSRSubscriptionSummary);
  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values?.pricing?.membershipPlans) {
      return;
    }

    const payload: ILoadSRSubscriptionSummary = {
      data: values?.pricing?.membershipPlans,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [
    LoadSubscriptionSummary,
    debounce,
    organizationId,
    values?.pricing?.membershipPlans,
  ]);

  return <></>;
};

export default SRSummaryLoader;
