import { CustomComponent } from '../../../models/types/CustomComponent';
import Button from '../Button/Button';
import { IStatusProps, Status } from '../Status/Status';
import {
  ChildPencilIcon,
  RenewalStatusContainer,
} from './editableStatus.styles';

export type EditableStatusProps = CustomComponent &
  IStatusProps & {
    onClick?: () => void;
  };

export const EditableStatus = ({
  status,
  className,
  enumName,
  onClick,
  disabled,
}: EditableStatusProps) => (
  <Button
    disabled={disabled}
    className={className}
    onClick={onClick}
    styleType="icon"
  >
    <RenewalStatusContainer>
      <Status enumName={enumName} status={status} />
      <ChildPencilIcon />
    </RenewalStatusContainer>
  </Button>
);
