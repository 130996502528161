import { useTranslation } from 'react-i18next';

import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import Spinner from '../../../styledComponents/Spinner/Spinner';
import { ProductType } from '../../../models/ProductType';
import { useSubscriptionDOCRenew } from './docSubscriptionRenewPage.hooks';
import DOCSubscriptionRenewPageForm from './DOCSubscriptionRenewPageForm/DOCSubscriptionRenewPageForm';
import DOCSubscriptionAnalyseModal from './DOCSubscriptionAnalyseModal/DOCSubscriptionAnalyseModal';

export const DOCSubscriptionRenewPage = () => {
  const { t } = useTranslation();
  const {
    organization,
    loadSubscriptionRenewalSuggestionHandler,
    loadDatesForNewSubscriptionHandler,
    needShowSpinner,
  } = useSubscriptionDOCRenew();

  if (needShowSpinner) {
    return <Spinner />;
  }

  return (
    <>
      <AddContractModal />
      <DOCSubscriptionAnalyseModal
        onAnalyseSuccess={loadSubscriptionRenewalSuggestionHandler}
      />
      <FormErrorProvider>
        <>
          <Header
            title={t('headers.renew-subscription', {
              productType: ProductType.DutyOfCare,
              organizationName: organization?.name,
            })}
          />
          <DOCSubscriptionRenewPageForm
            onContractChanged={loadDatesForNewSubscriptionHandler}
          />
        </>
      </FormErrorProvider>
    </>
  );
};
