import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import arrayMutators from 'final-form-arrays';

import { SelectContractForm } from '../../../../styledComponents/Forms/SelectContractForm/SelectContractForm';
import {
  LeftContainer,
  RightContainer,
  PageRootColumns,
} from '../../../../styledComponents/Page/Page';
import SubscriptionSummaryForm from '../../../../styledComponents/FormSummary/SubscriptionSummary/SubscriptionSummary';
import Spinner from '../../../../styledComponents/Spinner/Spinner';
import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import WarningsConfirmationModal from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { ProductType } from '../../../../models/ProductType';
import FormSubmitButtons from '../../../../styledComponents/UI/FormSubmitButtons/FormSubmitButtons';
import { useTLEditSubscriptionRenewForm } from './tlSubscriptionRenewPageForm.hooks';
import TravelLicenseRenewSubscriptionForm from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/TravelLicenseRenewSubscription/TravelLicenseRenewSubscriptionForm';
import { TLRenewSubscriptionSummaryLoader } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/TravelLicenseRenewSubscription/TLRenewSubscriptionFormSummaryLoader/TLRenewSubscriptionFormSummaryLoader';

interface ITLEditSubscriptionRenewPageFormProps {
  onContractChanged?: (value: string) => void;
}

const TLEditSubscriptionRenewPageForm = ({
  onContractChanged,
}: ITLEditSubscriptionRenewPageFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    isSubscriptionUpdating,
    organizationContracts,
    initState,
    isSuggestionDataLoading,
    updateOrganizationSubscription,
    canChangeContract,
    onAddNewContractHandler,
    validateForm,
  } = useTLEditSubscriptionRenewForm();

  return (
    <WarningsConfirmationProvider
      submitFunction={updateOrganizationSubscription}
    >
      <WarningsConfirmationModal />
      <Spinner size="20%" isVisible={isSuggestionDataLoading}>
        <Form
          initialValues={{
            ...initState,
            transferAllEligibleMemberships: true,
          }}
          mutators={{
            ...arrayMutators,
          }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {({ values, ...renderProps }) => (
            <FormErrorActivator>
              <form onSubmit={renderProps.handleSubmit}>
                <PageRootColumns>
                  <LeftContainer>
                    <SelectContractForm
                      contracts={organizationContracts}
                      disabled={isSubscriptionUpdating}
                      canChange={canChangeContract}
                      canNotChangeTooltip={t(
                        'phrase.can-not-change-contract-earlier-six-months',
                      )}
                      setDefaultValue={false}
                      onContractChanged={onContractChanged}
                      isClearable
                      onAddNewContract={onAddNewContractHandler}
                    />
                    <TravelLicenseRenewSubscriptionForm
                      disabled={isSubscriptionUpdating}
                    />
                  </LeftContainer>
                  <RightContainer>
                    <TLRenewSubscriptionSummaryLoader data={values} />
                    <SubscriptionSummaryForm
                      title={t('headers.summary')}
                      subscriptionType={ProductType.TravelLicense}
                      disabled={isSubscriptionUpdating}
                      isPriceOverridden={values.overwriteAmount}
                    />
                    <FormSubmitButtons
                      handleSubmit={renderProps.handleSubmit}
                      canSaveDraft
                      isProcessing={isSubscriptionUpdating}
                      saveLabel={t('buttons.create')}
                    />
                  </RightContainer>
                </PageRootColumns>
              </form>
            </FormErrorActivator>
          )}
        </Form>
      </Spinner>
    </WarningsConfirmationProvider>
  );
};

export default TLEditSubscriptionRenewPageForm;
