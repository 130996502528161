import { SimplePageLine } from '../../SimplePage/SimplePageLine';
import DOCMembersTable, {
  IDOCMembersTableProps,
} from './DOCMembersTable/DOCMembersTable';
import DOCMembersTools, {
  DOCMembersToolsProps,
} from './DOCMembersTools/DOCMembersTools';

type IDOCMembersListProps = {
  tableProps: IDOCMembersTableProps;
  toolsProps: DOCMembersToolsProps;
};

const DOCMembersList = ({ tableProps, toolsProps }: IDOCMembersListProps) => (
  <>
    <DOCMembersTools {...toolsProps} />
    <SimplePageLine />
    <DOCMembersTable {...tableProps} />
  </>
);

export default DOCMembersList;
