import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { contractFormSchema } from '../../../../styledComponents/Forms/SelectContractForm/selectContractForm.validation';
import { subscriptionOptionsSchema } from '../../../../styledComponents/Forms/SubscriptionOptions/subscriptionOptions.validation';
import { subscriptionSummarySchema } from '../../../../styledComponents/FormSummary/SubscriptionSummary/subscriptionSummary.validation';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { tlRenewSubscriptionDetailsSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/TravelLicenseRenewSubscription/TLRenewSubscriptionDetails/tlRenewSubscriptionDetails.validation';
import { tlRenewSubscriptionSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/TravelLicenseRenewSubscription/travelLicenseRenewSubscriptionForm.validation';
import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...tlRenewSubscriptionDetailsSchema.properties,
    ...subscriptionOptionsSchema.properties,
    ...subscriptionSummarySchema.properties,
    ...tlRenewSubscriptionSchema.properties,
  },
  dependencies: {
    ...subscriptionOptionsSchema.dependencies,
    ...subscriptionSummarySchema.dependencies,
  },
  required: [
    ...contractFormSchema.required,
    ...tlRenewSubscriptionSchema.required,
    ...subscriptionOptionsSchema.required,
    ...subscriptionSummarySchema.required,
  ],
};

export const validateTLEditSubscriptionForm = (
  data: IEditTLOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
