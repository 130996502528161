import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';
import { DefaultInputIcons } from './iconInput.models';
import { Icon } from './iconInput.styles';

export const findIcon = (iconType: DefaultInputIcons, disabled?: boolean) => {
  switch (iconType) {
    case 'search':
      return <SearchIcon />;
    case 'dollar':
      return <Icon disabled={disabled}>$</Icon>;
    case 'percentage':
      return <Icon disabled={disabled}>%</Icon>;
    default:
      return null;
  }
};
