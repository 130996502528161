import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IStandardPricingQueryResponse } from '../common/models';

export const getAAStandardPricingQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IStandardPricingQueryResponse>> =>
  axios.get(`api/pricing/standard`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancellationToken,
  });

export const getAARegularPricingQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IStandardPricingQueryResponse>> =>
  axios.get(`api/pricing/regular`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancellationToken,
  });
