import aaSubscriptionsSaga from './airAmbulance';
import keSubscriptionsSaga from './kidnapAndExtortion';
import srSubscriptionsSaga from './securityResponse';
import docSubscriptionsSaga from './dutyOfCare';
import tlSubscriptionsSaga from './travelLicense';
import subscriptionsSaga from './common';

const subscriptionsSagasRoot = [
  aaSubscriptionsSaga(),
  keSubscriptionsSaga(),
  srSubscriptionsSaga(),
  docSubscriptionsSaga(),
  tlSubscriptionsSaga(),
  subscriptionsSaga(),
];
export default subscriptionsSagasRoot;
