import { canViewAAPromoCodeDetailsPermissions } from '../../../../config/accessPolicies/accessPolicies';
import { OverviewTabPage } from '../../../../styledComponents/OverviewPage/OverviewPage';
import PromoCodesListPage from '../../../../styledComponents/PromoCodesListPage/PromoCodesListPage';
import PromoCodesHeader from './PromoCodesHeader/PromoCodesHeader';
import { usePromoCodesPage } from './promoCodesTabPage.hooks';

const PromoCodesTabPage = () => {
  const {
    dataLoading,
    promoCodesList,
    rowClickHandler,
    onAddPromoCodeHandler,
  } = usePromoCodesPage();

  return (
    <OverviewTabPage>
      <PromoCodesHeader onAddPromoCode={onAddPromoCodeHandler} />
      <PromoCodesListPage
        data={promoCodesList}
        isLoading={dataLoading}
        onRowClick={rowClickHandler}
        canViewDetailsPermissions={canViewAAPromoCodeDetailsPermissions}
      />
    </OverviewTabPage>
  );
};

export default PromoCodesTabPage;
