import { t } from 'i18next';

import { ProductType } from '../models/ProductType';

export const prepareProductsOptions = (
  products: ProductType[],
): { label: string; value: string }[] =>
  products?.map((product) => ({
    label: t(`enums.productType.${product}`),
    value: product,
  }));
