import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 5rem;
`;

export const Text = styled.p`
  letter-spacing: 0.013rem;
  font: 700 1.125rem/2rem ${({ theme }) => theme.font.gotham};
  text-align: left;
  color: ${({ theme }) => theme.color.black};
`;
