import { ChangeEvent, useEffect, useState } from 'react';
import { useField } from 'react-final-form';

import { IRenewOrganizationSubscriptionMemberModel } from '../../../../models/IRenewOrganizationSubscriptionMemberModel';
import { IRenewOrganizationSubscriptionAnalyseMembershipModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseMembershipModel';
import { SubscriptionMember } from '../subscriptionAnalyse.enums';

import { mapToStatus } from './subscriptionAnalyse.utils';

type Props = {
  fieldName: string;
};

const useHandleChange = (
  formValue: IRenewOrganizationSubscriptionAnalyseMembershipModel,
  onChange: (
    value: IRenewOrganizationSubscriptionAnalyseMembershipModel,
  ) => void,
) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    fieldIndex: number,
    fieldType: SubscriptionMember,
  ) => {
    const { checked } = e.target;

    const value = {
      ...formValue,
      primaryMember: {
        ...formValue.primaryMember,
        toBeRenewed:
          fieldType === SubscriptionMember.PRIMARY_MEMBER
            ? checked
            : formValue.primaryMember.toBeRenewed,
      },
    };

    const secondary = ({
      secondaryMembers,
    }: {
      secondaryMembers: IRenewOrganizationSubscriptionMemberModel[];
    }) => {
      if (fieldType === SubscriptionMember.PRIMARY_MEMBER && !checked) {
        return secondaryMembers.map((member) => ({
          ...member,
          toBeRenewed: false,
        }));
      }
      const members: IRenewOrganizationSubscriptionMemberModel[] =
        secondaryMembers.map((member, index: number) => ({
          ...member,
          toBeRenewed: index === fieldIndex ? checked : member.toBeRenewed,
        }));

      const isSecondarySelected = members.some(
        ({ toBeRenewed }) => toBeRenewed,
      );

      if (isSecondarySelected) {
        value.primaryMember.toBeRenewed = true;
      }
      return members;
    };

    value.secondaryMembers = secondary(formValue);

    onChange(value);
  };

  return { handleChange };
};

export const useSubscriptionRow = ({ fieldName }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const {
    input: { value, onChange },
  } = useField(fieldName);

  const { handleChange } = useHandleChange(value, onChange);

  const toggle = () => {
    isOpen ? setOpen(false) : setOpen(true);
  };

  const { secondaryMembers, primaryMember, membershipPlanName } = value;

  const isCollapsible = !!secondaryMembers.length;

  const members: IRenewOrganizationSubscriptionMemberModel[] = [
    primaryMember,
    ...secondaryMembers,
  ];
  const areMembersQualifiedForRenewal = members.every(
    ({ canBeRenewed, toBeRenewed }) => canBeRenewed || !toBeRenewed,
  );

  const infoStatus = mapToStatus(areMembersQualifiedForRenewal);

  useEffect(() => {
    if (!areMembersQualifiedForRenewal) {
      setOpen(true);
    }
  }, [areMembersQualifiedForRenewal]);

  return {
    toggle,
    isOpen,
    isCollapsible,
    secondaryMembers,
    primaryMember,
    membershipPlanName,
    infoStatus,
    handleChange,
  };
};
