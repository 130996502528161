/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IAAOrganizationList } from '../../../../models/AAOrganizationList';
import { ICellOptions } from '../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { PageSection } from '../../../../styledComponents/Page/Page';
import MaterialTable from '../../../../styledComponents/MaterialTable/MaterialTable';
import {
  OrganizationNameRenderer,
  ExpiredDateRenderer,
  FractionRenderer,
} from '../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import TableCell from '../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

interface ICorporateClientsAirAmbulanceTableProps {
  data: IAAOrganizationList[];
  dataLoading?: boolean;
  totalCount?: number;
  onRowClick?: (row: IAAOrganizationList) => void;
  canViewDetailsPermissions?: string[];
}

const CorporateClientsAirAmbulanceTable = ({
  data,
  dataLoading,
  onRowClick,
  totalCount,
  canViewDetailsPermissions,
}: ICorporateClientsAirAmbulanceTableProps) => {
  const { t } = useTranslation();
  const cellRenderer =
    (key: keyof IAAOrganizationList, options?: ICellOptions) =>
    (rowData: IAAOrganizationList) => {
      switch (key) {
        case 'name':
          return (
            <div>
              <OrganizationNameRenderer
                organizationName={rowData.name}
                organizationType={rowData.organizationType}
                options={options}
              />
            </div>
          );
        case 'subscriptionEndDate':
          return <ExpiredDateRenderer date={rowData[key]} />;
        case 'activeMemberships':
          return (
            <FractionRenderer
              maxValue={rowData[key]}
              options={options}
              value={rowData.countOfMemberships}
            />
          );
        default:
          return (
            <TableCell bold={options && options.textBold}>
              {rowData[key]}
            </TableCell>
          );
      }
    };
  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.name'),
            field: 'name',
            cellRenderer: cellRenderer('name', { textBold: true }),
            sortable: true,
          },
          {
            title: t('grid-columns.tenant-group-code'),
            field: 'externalTenantCode',
            cellRenderer: cellRenderer('externalTenantCode'),
          },
          {
            title: t('grid-columns.internal-tenant-group-code'),
            field: 'internalTenantCode',
            cellRenderer: cellRenderer('internalTenantCode'),
          },
          {
            title: t('grid-columns.subscription-end-date'),
            field: 'subscriptionEndDate',
            cellRenderer: cellRenderer('subscriptionEndDate'),
            sortable: true,
          },
          {
            title: t('grid-columns.active-memberships'),
            alignment: 'right',
            field: 'activeMemberships',
            cellRenderer: cellRenderer('activeMemberships', {
              alignment: 'right',
            }),
          },
        ]}
        data={data}
        dataLoading={dataLoading}
        itemsCount={totalCount}
        canClickRowPermissions={canViewDetailsPermissions}
        onRowClick={onRowClick}
      />
    </PageSection>
  );
};

export default CorporateClientsAirAmbulanceTable;
