import { CustomForm } from '../../../../../../../models/types/CustomForm';
import {
  FormSectionTitle,
  SectionWrapper,
} from '../../../../../../../styledComponents/Form/Form';

import FormCheckboxGroup from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import { FormColumn } from '../../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';

import { useMembershipOptions } from './aaMembershipOptions.hooks';

interface IMembershipOptionsFormProps extends CustomForm {
  title?: string;
}

const MembershipOptionsForm = ({
  className,
  disabled,
  title,
}: IMembershipOptionsFormProps) => {
  const { membershipOptions } = useMembershipOptions();
  return (
    <SectionWrapper className={className}>
      {membershipOptions && title && (
        <FormSectionTitle>{title}</FormSectionTitle>
      )}
      <FormColumn>
        <FormCheckboxGroup
          layout="column"
          disabled={disabled}
          options={membershipOptions}
        />
      </FormColumn>
    </SectionWrapper>
  );
};

export default MembershipOptionsForm;
