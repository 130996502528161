import MainTooltip from '../../../UI/MainTooltip/MainTooltip';
import { ICellRenderer } from '../cellRenderers.models';
import {
  LongText,
  TitleWrapper,
  Wrapper,
  Text,
  StyledTableCell,
} from './singleItemWithNumberRenderer.styles';

export type SingleItemWithNumberRendererType = ICellRenderer & {
  items: string[];
};

export const SingleItemWithNumberRenderer = ({
  items,
  options,
  className,
}: SingleItemWithNumberRendererType) => {
  const titleComponent = <TitleWrapper>{items?.join(', ')}</TitleWrapper>;
  return (
    <StyledTableCell className={className} alignment="left">
      {items?.length ? (
        <MainTooltip title={titleComponent} arrow isShowing width="none">
          <Wrapper disabled={options?.disabled} bold={options?.textBold}>
            <LongText> {items[0]} </LongText>
            {items?.length > 1 && <Text>{`+${items.length - 1}`}</Text>}
          </Wrapper>
        </MainTooltip>
      ) : (
        <LongText>-</LongText>
      )}
    </StyledTableCell>
  );
};
