import axios, { AxiosResponse } from 'axios';

import { GlobalGuardianPointOfContactRoles } from '../../../models/enums/GlobalGuardianPointOfContactRoles';
import { PointOfContactRoles } from '../../../models/enums/PointOfContactRoles';

export const getRolesList = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<PointOfContactRoles[]>> =>
  axios.get(`/api/roles/poc`, {
    signal: cancelToken,
  });

export const getGGRolesList = (
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<GlobalGuardianPointOfContactRoles[]>> =>
  axios.get(`/api/roles/ggpoc`, {
    signal: cancelToken,
  });
