import { useState } from 'react';

import { Collapse } from '@mui/material';

import { CustomComponent } from '../../../models/types/CustomComponent';
import {
  CollapseWrapper,
  Header,
  Title,
  CollapseIndicator,
} from './collapseContent.styles';

type ICollapseContent = CustomComponent & {
  title: string;
  defaultIsOpen?: boolean;
  isHidden?: boolean;
};

const CollapseContent = ({
  children,
  title,
  defaultIsOpen = false,
  isHidden,
}: ICollapseContent) => {
  const [isOpen, setOpen] = useState(defaultIsOpen);

  if (isHidden) {
    return null;
  }
  const clickHandler = () => setOpen((state) => !state);

  return (
    <CollapseWrapper>
      <Header>
        <CollapseIndicator onClick={clickHandler} isOpen={isOpen} />
        <Title onClick={clickHandler}>{title}</Title>
      </Header>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </CollapseWrapper>
  );
};

export default CollapseContent;
