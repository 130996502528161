import styled from '@emotion/styled';

import { PageSection } from '../Page/Page';
import Button from '../UI/Button/Button';

export const OverviewHeader = styled(PageSection)`
  margin-bottom: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OverviewHeaderRightContainer = styled.div`
  display: flex;
`;

export const OverviewButton = styled(Button)`
  margin-right: 1.5rem;
`;
