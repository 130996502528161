import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFilters } from '../../../../hooks/useFilters';
import { DutyOfCareSubscriptionPackageType } from '../../../../models/enums/DutyOfCareSubscriptionPackageType';
import { SelectOption } from '../../../../models/SelectOption';

import { subscriptionsListSelector } from '../../../../store/redux-slices/subscriptions/common/selectors';
import { formatDate } from '../../../../utils/dateHelpers';
import { DOCMembersListFilters } from './docMembersFilters.models';

type UseDOCMembersFiltersProps = {
  packages?: DutyOfCareSubscriptionPackageType[];
};

export const useDOCMembersFilters = ({
  packages,
}: UseDOCMembersFiltersProps) => {
  const subscriptionsList = useSelector(subscriptionsListSelector);

  const { addFilter, filters } = useFilters<DOCMembersListFilters>();

  const onFilterChangeHandler = useCallback(
    (evt: any, filterType: DOCMembersListFilters) => {
      switch (filterType) {
        case DOCMembersListFilters.Search:
          addFilter(DOCMembersListFilters.Search, evt.target.value);
          return;
        case DOCMembersListFilters.Package:
          addFilter(
            DOCMembersListFilters.Package,
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case DOCMembersListFilters.RenewStatus:
          addFilter(DOCMembersListFilters.RenewStatus, evt ? evt.value : null);
          return;
        case DOCMembersListFilters.SubscriptionId:
          addFilter(
            DOCMembersListFilters.SubscriptionId,
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case DOCMembersListFilters.OversubscribedOnly:
          addFilter(
            DOCMembersListFilters.OversubscribedOnly,
            evt.target.checked,
          );
      }
    },
    [addFilter],
  );

  const packagesOptions = useMemo(
    () =>
      packages?.map((packageName) => ({
        label: packageName,
        value: packageName,
      })),
    [packages],
  );
  const subscriptionsListOptions = useMemo(
    () =>
      subscriptionsList?.map((subscription) => ({
        label: `DOC ${formatDate(
          subscription.effectiveDateFrom,
        )} - ${formatDate(subscription.effectiveDateTo)}`,
        value: subscription.id,
      })),
    [subscriptionsList],
  );

  return {
    packagesOptions,
    filters,
    onFilterChangeHandler,
    subscriptionsListOptions,
  };
};
