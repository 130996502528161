import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../../styledComponents/UI/Checkbox/Checkbox';

import {
  RenewalStaticFiltersProps,
  RenewalStaticFilter,
} from './renewalStaticFilters.models';
import { FiltersWrapper } from './renewalStaticFilters.styles';

const RenewalStaticFilters = ({
  availableFilters,
  filters,
  onFilterChangeHandler,
}: RenewalStaticFiltersProps) => {
  const { t } = useTranslation();
  if (!availableFilters?.length) {
    return null;
  }

  const filtersComponents = availableFilters?.map((filter) => {
    switch (filter) {
      case RenewalStaticFilter.HideExpired:
        return (
          <Checkbox
            id={RenewalStaticFilter.HideExpired}
            key={RenewalStaticFilter.HideExpired}
            checked={!!filters.hideExpired}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              onFilterChangeHandler(evt, RenewalStaticFilter.HideExpired)
            }
            label={t('filters.hideExpired')}
          />
        );
      default:
        return null;
    }
  });

  return <FiltersWrapper>{filtersComponents}</FiltersWrapper>;
};

export default RenewalStaticFilters;
