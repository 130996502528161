import { t } from 'i18next';

import { IProductSubscriptionInfo } from '../../../../models/IProductSubscriptionInfo';
import { TableTooltip } from '../../materialTable.styles';

export const prepareProductsSubscriptionInfoTooltip = (
  products: IProductSubscriptionInfo[],
) =>
  products?.map((x: IProductSubscriptionInfo) => (
    <TableTooltip key={x.productType}>
      {x.isOversubscribed
        ? t('phrase.subscription-oversubscribed', {
            type: x.productType,
          }).toString()
        : t(`enums.productType.${x.productType}`).toString()}
    </TableTooltip>
  ));
