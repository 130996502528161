import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga/macro';
import axios, { AxiosError } from 'axios';

import {
  AcceptedStatusCodes,
  AccessForbiddenCodes,
  NotFoundCodes,
} from './models';
import { setError } from '../../redux-slices/errors';
import { Log } from '../../../utils/logger';
import { ApiErrorType } from '../../../models/enums/ApiErrorType';

interface IHandleResponseErrorPayload {
  error?: AxiosError;
}

const errorSlice = createSliceSaga({
  caseSagas: {
    *handlerApiError({
      payload: { error },
    }: PayloadAction<IHandleResponseErrorPayload>) {
      if (axios.isCancel(error)) {
        return;
      }

      if (NotFoundCodes.includes(error?.response?.status)) {
        Log.error(`Not found error: ${error.message}`);
        yield put(
          setError({
            message: error.message,
            type: ApiErrorType.NotFound,
          }),
        );
        return;
      }

      if (AccessForbiddenCodes.includes(error?.response?.status)) {
        Log.error(`Access forbidden error: ${error.message}`);
        yield put(
          setError({
            message: error.message,
            type: ApiErrorType.AccessForbidden,
          }),
        );
        return;
      }

      if (!AcceptedStatusCodes.includes(error?.response?.status)) {
        Log.error(`Something went wrong for the error: ${error.message}`);
        yield put(
          setError({ message: error.message, type: ApiErrorType.Internal }),
        );
      }
    },
  },
  name: 'error-saga',
  sagaType: SagaType.TakeLatest,
});

export default errorSlice.saga;
export const { handlerApiError } = errorSlice.actions;
export const { actions } = errorSlice;
