import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { StandardText } from '../../Typography/Typography';

export const CreditCardTypeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding-top: 0.5rem;
`;

type CreditCardNumberProps = {
  isBold?: boolean;
};

export const CreditCardNumber = styled(StandardText)<CreditCardNumberProps>`
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `};
  line-height: 0.875rem;
`;
