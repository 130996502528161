import { SystemGroupPermissions } from '../models/enums/UserPermissions';
import { Permissions } from '../models/types/UserPermissions';

export const userHasPermissions = (
  userPermissions: string[],
  requiredPermission: string[],
) =>
  !requiredPermission?.length ||
  requiredPermission.some((x) => userPermissions?.includes(x));

export const getGroupPermission = (
  group: SystemGroupPermissions,
  permission: Permissions,
) => `${group}.${permission}`;
