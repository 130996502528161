import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { concatenateParamsIntoString } from 'utils/textHelpers';

import { useActions } from '../../hooks/useActions';
import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { useProcessing } from '../../hooks/useProcessing';
import { updateModalState } from '../../store/redux-slices/modals';
import {
  AddMemberAvailableSearchParams,
  RoutePath,
} from '../../models/enums/RoutePath';
import { clearCreditCards } from '../../store/redux-slices/payment';
import {
  clearPersonData,
  clearPersonMemberships,
  clearPersonRoles,
} from '../../store/redux-slices/people';
import { personSelector } from '../../store/redux-slices/people/selectors';
import {
  LOAD_CREDIT_CARDS_PROCESSING,
  PERSON_LOADING_PROCESSING,
  PERSON_MEMBERSHIPS_LOADING_PROCESSING,
} from '../../store/redux-slices/processes/constants';
import { loadCreditCards } from '../../store/saga-slices/payment';
import {
  loadPerson,
  loadPersonMemberships,
  loadPersonRoles,
} from '../../store/saga-slices/people';
import {
  ILoadPersonMembershipsPayload,
  ILoadPersonPayload,
} from '../../store/saga-slices/people/models';
import { EDIT_MEMBER_MODAL_NAME } from '../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { REPLACE_CREDIT_CARD_MODAL_NAME } from './Modals/ReplaceCreditCardModal/replaceCreditCardModal.const';
import { ADD_CREDIT_CARD_MODAL_NAME } from './Modals/AddCreditCardModal/addCreditCardModal.const';
import { DELETE_CREDIT_CARD_MODAL_NAME } from './Modals/DeleteCreditCardModal/deleteCreditCardModal.const';
import { ICreditCard } from '../../models/CreditCardModel';
import { DELETE_PERSON_MODAL_NAME } from './Modals/DeletePersonModal/deletePersonModal.const';
import { usePermissions } from '../../hooks/usePermissions';
import { canViewCreditCardPermissions } from '../../config/accessPolicies/accessPolicies';
import { MemberOverviewPageParamsType } from '../../models/types/RoutePath';

export const useMemberOverview = () => {
  const LoadPerson = useActions(loadPerson);
  const LoadPersonRoles = useActions(loadPersonRoles);
  const LoadPersonMemberships = useActions(loadPersonMemberships);
  const ClearPersonMemberships = useActions(clearPersonMemberships);
  const ClearPersonRoles = useActions(clearPersonRoles);
  const LoadCreditCards = useActions(loadCreditCards);
  const ClearPersonData = useActions(clearPersonData);
  const ClearCreditCards = useActions(clearCreditCards);
  const UpdateModalState = useActions(updateModalState);

  const { hasPermissions: canLoadCreditCards } = usePermissions(
    canViewCreditCardPermissions,
  );

  const { memberId } = useParams<MemberOverviewPageParamsType>();
  const navigate = useNavigateSearch();

  const person = useSelector(personSelector);
  const personName = `${person?.firstName} ${person?.lastName}`;

  const headerTitle = useMemo(
    () =>
      concatenateParamsIntoString(
        person?.prefix,
        person?.firstName,
        person?.lastName,
      ),
    [person?.firstName, person?.lastName, person?.prefix],
  );

  const personLoading = useProcessing([
    PERSON_LOADING_PROCESSING,
    PERSON_MEMBERSHIPS_LOADING_PROCESSING,
    LOAD_CREDIT_CARDS_PROCESSING,
  ]);

  const loadPersonRolesHandler = useCallback(() => {
    const payload = {
      personId: memberId,
    };
    LoadPersonRoles(payload);
  }, [LoadPersonRoles, memberId]);

  const loadPersonHandler = useCallback(() => {
    const personPayload: ILoadPersonPayload = {
      personId: memberId,
      error: () => navigate(RoutePath.MembersPage),
    };
    LoadPerson(personPayload);
  }, [LoadPerson, memberId, navigate]);

  const loadCreditCardsHandler = useCallback(() => {
    const payload = {
      personId: memberId,
    };

    LoadCreditCards(payload);
  }, [LoadCreditCards, memberId]);

  useEffect(() => {
    loadPersonRolesHandler();
  }, [loadPersonRolesHandler]);

  useEffect(() => {
    loadPersonHandler();

    const personMembershipsPayload: ILoadPersonMembershipsPayload = {
      personId: memberId,
    };

    LoadPersonMemberships(personMembershipsPayload);
  }, [LoadPersonMemberships, loadPersonHandler, memberId]);

  useEffect(() => {
    canLoadCreditCards && loadCreditCardsHandler();
  }, [canLoadCreditCards, loadCreditCardsHandler]);

  useEffect(
    () => () => {
      ClearPersonData();
      ClearCreditCards();
      ClearPersonMemberships();
      ClearPersonRoles();
    },
    [
      ClearPersonData,
      ClearCreditCards,
      ClearPersonMemberships,
      ClearPersonRoles,
    ],
  );

  const addMembershipHandler = useCallback(() => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.PersonId]: memberId,
      },
    });
  }, [memberId, navigate]);

  const editPersonHandler = () => {
    UpdateModalState([EDIT_MEMBER_MODAL_NAME, person?.personId]);
  };

  const replaceCreditCardHandler = (creditCardId: string) => {
    UpdateModalState([
      REPLACE_CREDIT_CARD_MODAL_NAME,
      {
        personId: memberId,
        creditCardId,
      },
    ]);
  };

  const addCreditCardHandler = () => {
    UpdateModalState([ADD_CREDIT_CARD_MODAL_NAME, { personId: memberId }]);
  };

  const deleteCreditCardHandler = (creditCard: ICreditCard) => {
    UpdateModalState([
      DELETE_CREDIT_CARD_MODAL_NAME,
      { creditCard, personId: memberId },
    ]);
  };

  const deletePersonHandler = () => {
    UpdateModalState([
      DELETE_PERSON_MODAL_NAME,
      {
        personName,
        personId: memberId,
      },
    ]);
  };

  return {
    person,
    personLoading: personLoading || !person,
    addMembershipHandler,
    headerTitle,
    editPersonHandler,
    loadPersonHandler,
    replaceCreditCardHandler,
    addCreditCardHandler,
    deleteCreditCardHandler,
    loadCreditCardsHandler,
    deletePersonHandler,
  };
};
