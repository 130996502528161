import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { FormFieldWrapper } from '../../../Form/Form';
import Spinner from '../../../Spinner/Spinner';
import { FieldLabel } from '../../Field/Field';
import { ValueStatus } from './formValueInfo.models';

type ValueProps = {
  status: ValueStatus;
  isBold: boolean;
};

export const Wrapper = styled(FormFieldWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 3rem;
`;
export const ContentContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const ValueContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.black};
`;

export const StyledSpinner = styled(Spinner)`
  height: auto;
`;

export const EmptyValue = styled.div`
  color: ${({ theme }) => theme.color.dark2};
  font-weight: bold;
`;

export const Value = styled.div<ValueProps>`
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}

  ${({ status, theme }) => {
    switch (status) {
      case 'error':
        return css`
          color: ${theme.color.error};
        `;
      case 'success':
        return css`
          color: ${theme.color.success};
        `;
    }
  }}
`;

export const StyledFieldLabel = styled(FieldLabel)`
  left: 0;
  white-space: nowrap;
`;
