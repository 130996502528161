/* eslint-disable react/no-array-index-key */
import { IRadioGroupProps } from './radioGroup.models';
import { StyledRadio, Wrapper } from './radioGroup.styles';

const RadioGroup = ({
  className,
  disabled,
  layout = 'horizontal',
  name,
  onChange,
  options,
  value,
}: IRadioGroupProps) => (
  <Wrapper layout={layout} disabled={disabled} className={className}>
    {options &&
      options.map((x, key) => (
        <StyledRadio
          disabled={disabled}
          key={x.value + key}
          id={x.value + key}
          label={x.label}
          name={name}
          onChange={() => onChange(x.value)}
          checked={x.value === value}
        />
      ))}
  </Wrapper>
);

export default RadioGroup;
