import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IUpdatePersonModel } from '../../../../models/interfaces/UpdatePersonModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';

import { formAddressInformationsSchema } from '../../../Forms/FormAddressInformations/formAddressInformations.validation';
import { memberDetailsSchema } from '../../../Forms/FormMemberDetails/formMemberDetails.validation';
import {
  FormAddressInformationsFields,
  FormMemberDetailsFields,
} from './editMemberModalForm.models';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...memberDetailsSchema(FormMemberDetailsFields).properties,
    ...formAddressInformationsSchema(FormAddressInformationsFields).properties,
  },
  dependencies: {
    ...formAddressInformationsSchema(FormAddressInformationsFields)
      .dependencies,
    ...memberDetailsSchema(FormMemberDetailsFields).dependencies,
  },
  required: [...memberDetailsSchema(FormMemberDetailsFields).required],
};

export const validateEditMemberForm = (
  data: IUpdatePersonModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
