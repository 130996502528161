import { useEffect } from 'react';
import { useField } from 'react-final-form';

import { ContractDetailsFormFields } from '../../../Forms/ContractForm/ContractDetailsForm/contractDetailsForm.models';
import { useApiWarningActions } from '../../../WarningsConfirmation/warningsConfirmation.hooks';

const WarningController = () => {
  const {
    meta: { dirtySinceLastSubmit: contractTypeDirtySinceLastSubmit },
  } = useField(ContractDetailsFormFields.ContractType);

  const {
    meta: { dirtySinceLastSubmit: expirationDateDirtySinceLastSubmit },
  } = useField(ContractDetailsFormFields.ExpirationDate);

  const {
    meta: { dirtySinceLastSubmit: startDateDirtySinceLastSubmit },
  } = useField(ContractDetailsFormFields.StartDate);

  const { clearRatifications, setRatifications } = useApiWarningActions();

  useEffect(() => {
    if (
      contractTypeDirtySinceLastSubmit ||
      expirationDateDirtySinceLastSubmit ||
      startDateDirtySinceLastSubmit
    ) {
      clearRatifications();
    }
  }, [
    clearRatifications,
    contractTypeDirtySinceLastSubmit,
    expirationDateDirtySinceLastSubmit,
    setRatifications,
    startDateDirtySinceLastSubmit,
  ]);
  return <></>;
};

export default WarningController;
