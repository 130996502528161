import { useTranslation } from 'react-i18next';

import { StatusEnumName, StatusType } from './status.models';
import { StatusTitle } from './status.styles';
import { CustomComponent } from '../../../models/types/CustomComponent';

export interface IStatusProps extends CustomComponent {
  status?: StatusType;
  enumName?: StatusEnumName;
}

export const Status = ({
  className,
  status,
  enumName = 'status',
}: IStatusProps) => {
  const { t } = useTranslation();

  return (
    <StatusTitle status={status} className={className}>
      {t(`enums.${enumName}.${status}`)}
    </StatusTitle>
  );
};
