import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';
import {
  DefaultExpandContentId,
  NotExpandedHeight,
} from './expandContent.const';

type ExpandContentButtonProps = {
  isExpanded?: boolean;
  hideContent: () => void;
  expandContent: () => void;
  expandContentId?: string;
};

const ExpandContentButton = ({
  hideContent,
  expandContent,
  isExpanded,
  expandContentId = DefaultExpandContentId,
}: ExpandContentButtonProps) => {
  const { t } = useTranslation();
  const [needShowButton, setNeedShowButton] = useState(false);

  useEffect(() => {
    const height =
      document.getElementById(expandContentId)?.children[0]?.clientHeight;
    setNeedShowButton(height >= NotExpandedHeight + 10);
  }, [expandContentId]);

  if (!needShowButton) {
    return null;
  }

  return (
    <Button styleType="link" onClick={isExpanded ? hideContent : expandContent}>
      {t(`buttons.${isExpanded ? 'hide' : 'expand'}`)}
    </Button>
  );
};

export default ExpandContentButton;
