import { Collapse } from '@mui/material';
import { ReactNode, useState } from 'react';

import { CustomComponent } from '../../../models/types/CustomComponent';
import { CollapseIndicator } from '../CollapseIndicator';
import {
  CollapseItemWrapper,
  Header,
  StyledButton,
} from './collapseItem.styles';

type CollapseItemProps = CustomComponent & {
  title: ReactNode;
  defaultOpen?: boolean;
  collapseButtonHidden?: boolean;
};

const CollapseItem = ({
  title,
  children,
  className,
  defaultOpen,
  collapseButtonHidden = false,
  disabled,
}: CollapseItemProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <CollapseItemWrapper className={className}>
      <Header>
        {title}
        {!collapseButtonHidden && (
          <StyledButton
            onClick={() => setIsOpen((state) => !state)}
            styleType="icon"
            disabled={disabled}
          >
            <CollapseIndicator isOpen={isOpen} />
          </StyledButton>
        )}
      </Header>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </CollapseItemWrapper>
  );
};

export default CollapseItem;
