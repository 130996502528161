export enum FormMemberDetailsFields {
  EmailAddress = 'contactEmailAddress',
  PersonPrefix = 'prefix',
  FirstName = 'firstName',
  LastName = 'lastName',
  PhoneNumber = 'phone',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
}

export enum FormAddressInformationsFields {
  CorrespondenceAddressSameAsResidenceAddress = 'correspondenceAddressIsSameAsResidenceAddress',
  ResidenceAddress = 'residenceAddress',
  CorrespondenceAddress = 'correspondenceAddress',
}
