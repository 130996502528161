import styled from '@emotion/styled/macro';

import { OverviewTabPage } from '../../../../styledComponents/OverviewPage/OverviewPage';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledOverviewTabPage = styled(OverviewTabPage)`
  margin: 0 2.5rem;
`;
