import { t } from 'i18next';

import { ITabNavigationItem } from '../../styledComponents/UI/TabNavigation/tabNavigation.models';
import { RoutePath } from '../../models/enums/RoutePath';
import {
  canViewMembersPagePermissions,
  CanViewOrganizationContractsPermissions,
  canViewOrganizationDetailsPermissions,
  canViewAAPromoCodesPermissions,
  canViewSubscriptionsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { OrganizationType } from '../../models/enums/OrganizationType';

export const tabsNavigationArray: (
  organizationType: OrganizationType,
) => Array<ITabNavigationItem> = (organizationType: OrganizationType) => [
  {
    label: t('tabsNavigation.overview'),
    url: RoutePath.ClientOverviewGeneralPage,
    permissions: canViewOrganizationDetailsPermissions,
  },
  {
    label: t('tabsNavigation.product-subscriptions'),
    url: RoutePath.ClientOverviewProductSubscriptionListPage,
    permissions: canViewSubscriptionsPermissions,
    notAvailable:
      organizationType === OrganizationType.Agent ||
      organizationType === OrganizationType.Group,
  },
  {
    label:
      organizationType === OrganizationType.Agent ||
      organizationType === OrganizationType.Group
        ? t('tabsNavigation.associated-members')
        : t('tabsNavigation.members'),
    url: `${RoutePath.ClientOverviewMembersPage}`,
    permissions: canViewMembersPagePermissions,
  },
  {
    label: t('tabsNavigation.promo-codes'),
    url: RoutePath.ClientOverviewPromoCodesPage,
    permissions: canViewAAPromoCodesPermissions,
  },
  {
    label: t('tabsNavigation.contracts'),
    url: RoutePath.ClientOverviewContractsPage,
    permissions: CanViewOrganizationContractsPermissions,
  },
];
