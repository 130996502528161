import { useEffect } from 'react';
import { useField } from 'react-final-form';

import { OrganizationContractType } from '../../../../models/enums/ContractType';
import { SecurityResponseSubscriptionOptions } from '../../../../models/enums/SecurityResponseSubscriptionOptions';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';

type UseSecurityResponseSubscriptionForm = {
  contractType?: OrganizationContractType;
  name?: string;
};

export const useSecurityResponseSubscriptionForm = ({
  contractType,
  name,
}: UseSecurityResponseSubscriptionForm) => {
  const {
    input: { onChange: autoRenewChange },
  } = useField(
    getFormGroupPath(name, SecurityResponseSubscriptionOptions.AutoRenewal),
  );
  useEffect(() => {
    contractType !== OrganizationContractType.Multiple &&
      autoRenewChange(false);
  }, [autoRenewChange, contractType]);
};
