import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../models/ProductType';

import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { ExtendSubscriptionExtensionsFormFields } from '../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/extendSubscriptionExtensionsForm.models';
import ExtendTLSubscriptionForm from './ExtendTLSubscriptionForm/ExtendTLSubscriptionForm';
import { useExtendTLSubscriptionPage } from './extendTLSubscriptionPage.hooks';

const ExtendTLSubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName } = useExtendTLSubscriptionPage();
  return (
    <FormErrorProvider
      initErrorGroups={[
        ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges,
      ]}
    >
      <>
        <Header
          title={t('headers.extend-subscription', {
            organizationName,
            subscriptionName: t(
              `enums.productType.${ProductType.TravelLicense}`,
            ),
          })}
        />
        <ExtendTLSubscriptionForm />
      </>
    </FormErrorProvider>
  );
};

export default ExtendTLSubscriptionPage;
