import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../hooks/useActions';
import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import { useRouteParams } from '../../hooks/useRouteParams';
import { OrganizationType } from '../../models/enums/OrganizationType';
import {
  AddSubscriptionAvailableSearchParams,
  RoutePath,
} from '../../models/enums/RoutePath';
import { clearOrganizationContracts } from '../../store/redux-slices/contracts';
import {
  organizationNameSelector,
  organizationTypeSelector,
} from '../../store/redux-slices/organizations/selectors';
import { clearAASubscriptionHelperData } from '../../store/redux-slices/subscriptions/airAmbulance';
import { clearSubscriptionHelperData } from '../../store/redux-slices/subscriptions/common';
import { loadOrganizationContracts } from '../../store/saga-slices/contracts';
import { ILoadOrganizationContractsPayload } from '../../store/saga-slices/contracts/models';
import { loadOrganization } from '../../store/saga-slices/organizations';
import { loadDatesForNewSubscription } from '../../store/saga-slices/subscriptions/common';
import { ILoadDatesForNewSubscriptionPayload } from '../../store/saga-slices/subscriptions/common/models';

export const useAddProductSubscription = () => {
  const navigate = useNavigateSearch();

  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewSubscription);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadOrganization = useActions(loadOrganization);
  const ClearAASubscriptionHelperData = useActions(
    clearAASubscriptionHelperData,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);

  const organizationName = useSelector(organizationNameSelector);
  const organizationType = useSelector(organizationTypeSelector);

  const { id: organizationId } = useParams();
  const { contractId } = useRouteParams<AddSubscriptionAvailableSearchParams>();

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    loadDatesForNewSubscriptionHandler(contractId, true);
  }, [contractId, loadDatesForNewSubscriptionHandler]);

  useEffect(() => {
    if (
      organizationType === OrganizationType.Agent ||
      organizationType === OrganizationType.Group
    ) {
      navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
        params: { id: organizationId },
      });
    }
  }, [navigate, organizationId, organizationType]);

  useEffect(() => {
    const loadOrganizationContractsPayload: ILoadOrganizationContractsPayload =
      {
        organizationId,
      };

    LoadOrganizationContracts(loadOrganizationContractsPayload);
    LoadOrganization(organizationId);
  }, [
    LoadDatesForNewSubscription,
    LoadOrganization,
    LoadOrganizationContracts,
    organizationId,
  ]);

  useEffect(
    () => () => {
      ClearOrganizationContracts();
      ClearAASubscriptionHelperData();
      ClearSubscriptionHelperData();
    },
    [
      ClearAASubscriptionHelperData,
      ClearOrganizationContracts,
      ClearSubscriptionHelperData,
    ],
  );

  return {
    organizationName,
    organizationId,
    loadDatesForNewSubscriptionHandler,
  };
};
