import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { ProductType } from '../../../models/ProductType';
import { CreateOrganizationSubscriptionsType } from '../../../models/types/CreateOrganizationSubscriptionsType';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { aaSubscriptionSchema } from '../FormSections/AirAmbulanceSubscription/airAmbulanceSubscriptionForm.validation';
import { BasicSubscriptionFormFields } from '../FormSections/BasicSubscriptionForm/basicSubscriptionForm.models';
import { subscriptionBasicSchema } from '../FormSections/BasicSubscriptionForm/basicSubscriptionForm.validation';
import { docSubscriptionSchema } from '../FormSections/DutyOfCareSubscription/dutyOfCareSubscriptionForm.validations';
import { keSubscriptionSchema } from '../FormSections/KidnapAndExtortionSubscription/kidnapAndExtortionSubscriptionForm.validation';
import { srSubscriptionSchema } from '../FormSections/SecurityResponseSubscription/securityResponseSubscriptionForm.validation';
import { tlSubscriptionFormSchema } from '../FormSections/TravelLicenseSubscription/travelLicenseSubscriptionForm.validation';

export const subscriptionItemSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionBasicSchema.properties,
  },
  dependencies: {
    [BasicSubscriptionFormFields.ProductType]: {
      allOf: [
        {
          if: {
            properties: {
              [BasicSubscriptionFormFields.ProductType]: {
                enum: [ProductType.KidnapAndExtortion],
              },
            },
          },
          then: {
            ...keSubscriptionSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicSubscriptionFormFields.ProductType]: {
                enum: [ProductType.AirAmbulance],
              },
            },
          },
          then: {
            ...aaSubscriptionSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicSubscriptionFormFields.ProductType]: {
                enum: [ProductType.SecurityResponse],
              },
            },
          },
          then: {
            ...srSubscriptionSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicSubscriptionFormFields.ProductType]: {
                enum: [ProductType.DutyOfCare],
              },
            },
          },
          then: {
            ...docSubscriptionSchema,
          },
        },
        {
          if: {
            properties: {
              [BasicSubscriptionFormFields.ProductType]: {
                enum: [ProductType.TravelLicense],
              },
            },
          },
          then: {
            ...tlSubscriptionFormSchema,
          },
        },
      ],
    },
  },
  required: [...subscriptionBasicSchema.required],
};

export const validateAddSubscriptionItemForm = (
  data: CreateOrganizationSubscriptionsType,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionItemSchema);
