import { createContext, useMemo } from 'react';

import { useApiWarning } from './warningsConfirmation.hooks';
import {
  WarningsConfirmationActionableState,
  WarningsConfirmationInfoState,
  WarningsConfirmationProviderProps,
} from './warningsConfirmation.models';

export const WarningsConfirmationInfoContext =
  createContext<WarningsConfirmationInfoState>({
    ratifications: [],
    customModals: [],
  });

export const WarningsConfirmationActionableContext =
  createContext<WarningsConfirmationActionableState>({
    setRatifications: () => {},
    addRatification: null,
    setCustomModals: () => {},
    submitFunction: null,
    addRatifications: null,
    clearRatifications: null,
  });

const WarningsConfirmationProvider = ({
  children,
  submitFunction,
  customWarningsModals,
}: WarningsConfirmationProviderProps) => {
  const {
    ratifications,
    customModals,
    setRatifications,
    setCustomModals,
    addRatification,
    addRatifications,
    clearRatifications,
  } = useApiWarning(customWarningsModals);

  const actionableValue = {
    setRatifications,
    submitFunction,
    setCustomModals,
    addRatification,
    addRatifications,
    clearRatifications,
  };

  const infoValue = useMemo(
    () => ({
      ratifications,
      customModals,
    }),
    [ratifications, customModals],
  );

  return (
    <WarningsConfirmationInfoContext.Provider value={infoValue}>
      <WarningsConfirmationActionableContext.Provider value={actionableValue}>
        {children}
      </WarningsConfirmationActionableContext.Provider>
    </WarningsConfirmationInfoContext.Provider>
  );
};

export default WarningsConfirmationProvider;
