import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import arrayMutators from 'final-form-arrays';

import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import Spinner from '../../../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  PageRootColumns,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import { FormSubmitButton } from '../../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';
import { useExtendTLSubscriptionForm } from './extendTLSubscriptionForm.hooks';
import ExtendTLSubscriptionExtensionsForm from '../ExtendTLSubscriptionExtensionsForm/ExtendTLSubscriptionExtensionsForm';
import ExtendTLSubscriptionSummaryLoader from '../ExtendTLSubscriptionSummaryLoader/ExtendTLSubscriptionSummaryLoader';
import ExtendTLSubscriptionSummaryForm from '../ExtendTlSubscriptionSummary/ExtendTLSubscriptionSummary';

const ExtendTLSubscriptionForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    subscriptionEditing,
    dataLoading,
    productType,
    subscriptionData,
    validateForm,
  } = useExtendTLSubscriptionForm();

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      validate={validateForm}
    >
      {({ values, ...renderProps }) => (
        <FormErrorActivator>
          <Spinner isVisible={dataLoading} size="40%">
            <form onSubmit={renderProps.handleSubmit}>
              <PageRootColumns>
                <LeftContainer>
                  <ExtendTLSubscriptionExtensionsForm
                    disabled={subscriptionEditing}
                    data={subscriptionData}
                    productType={productType}
                  />
                </LeftContainer>
                <RightContainer>
                  <ExtendTLSubscriptionSummaryLoader
                    values={values.changeSubscriptionPricingTierModel}
                  />
                  <ExtendTLSubscriptionSummaryForm
                    title={t('headers.summary')}
                    disabled={subscriptionEditing}
                    isPriceOverridden={values.overwriteAmount}
                  />
                  <FormSubmitButton
                    isLoading={subscriptionEditing}
                    type="submit"
                  >
                    {t('buttons.save-subscription')}
                  </FormSubmitButton>
                </RightContainer>
              </PageRootColumns>
            </form>
          </Spinner>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default ExtendTLSubscriptionForm;
