import { useTranslation } from 'react-i18next';

import { ISubscriptionPackageCapacityChangeModel } from '../../../../../models/interfaces/Subscription/DutyOfCare/DOCSubscriptionPackageCapacityChangeModel';
import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import SubscriptionSummaryItemHeader from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemHeader/SummaryItemHeader';
import { Price } from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemOption/summaryItemOption.styles';
import { StandardText } from '../../../../../styledComponents/Typography/Typography';
import {
  ItemOptionWrapper,
  ItemWrapper,
} from './extendDOCSubscriptionSummaryItemContent.styles';

type SubscriptionSummaryItemContentProps = CustomComponent & {
  data: ISubscriptionPackageCapacityChangeModel;
  productType?: ProductType;
  isPriceOverridden?: boolean;
};

const ExtendDOCSubscriptionSummaryItemContent = ({
  data,
  className,
  isPriceOverridden,
  productType,
}: SubscriptionSummaryItemContentProps) => {
  const { t } = useTranslation();
  return (
    <ItemWrapper className={className}>
      <SubscriptionSummaryItemHeader subscriptionType={productType} />
      <ItemOptionWrapper>
        <StandardText>
          {t(`enums.duty-of-care-subscription-packages.${data?.packageType}`)}
        </StandardText>
        <Price overridden={isPriceOverridden}>${data?.totalPrice}</Price>
      </ItemOptionWrapper>
    </ItemWrapper>
  );
};

export default ExtendDOCSubscriptionSummaryItemContent;
