import axios, { AxiosResponse } from 'axios';

import { IPersonAgeModel } from '../../../models/PersonAgeModel';
import { ISearchPerson } from '../../../models/SearchPerson';

import config from '../../../config/config';
import { IPerson } from '../../../models/Person';
import { IPersonMembershipItem } from '../../../models/PersonMembershipItem';
import { IUpdatePersonModel } from '../../../models/interfaces/UpdatePersonModel';
import { MembershipStatusFilter } from '../../../models/enums/MembershipStatusFilter';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../utils/validations/queryParamsHelpers';
import { IGeneralPersonModel } from '../../../models/interfaces/GeneralPersonModel';
import { IPageListModel } from '../../../models/PageListModel';

export interface IPeopleResponse {
  items: IPerson[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface IPeopleSearchResult {
  people: ISearchPerson[];
}
export interface IPersonMembershipsResult {
  personMemberships: IPersonMembershipItem[];
}

export const MainUrl = '/api/people';

export const getPerson = (personId: string): Promise<AxiosResponse<IPerson>> =>
  axios.get(`${MainUrl}/${personId}`);

export const getAge = (
  dateOfBirth: string,
  baseDate?: string,
): Promise<AxiosResponse<IPersonAgeModel>> => {
  let params = '';

  if (dateOfBirth) {
    params += `dateOfBirth=${dateOfBirth}`;
  }

  if (baseDate) {
    params += `&baseDate=${baseDate}`;
  }

  return axios.get(`${MainUrl}/age?${params}`);
};

export const getPersonMemberships = (
  personId: string,
  cancelToken?: AbortSignal,
  membershipStatusFilter: MembershipStatusFilter = MembershipStatusFilter.All,
): Promise<AxiosResponse<IPersonMembershipsResult>> => {
  const queryParams: QueryParamItem[] = [
    { name: 'filter', value: membershipStatusFilter },
  ];

  const currentParams = prepareQueryParams({
    queryParams,
  });

  return axios.get(`${MainUrl}/${personId}/memberships${currentParams}`, {
    signal: cancelToken,
  });
};

export const updatePerson = (
  personId: string,
  data: IUpdatePersonModel,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IPerson>> =>
  axios.put(`${MainUrl}/${personId}`, data, {
    signal: cancelToken,
  });

export const getPersonItemRequest = (
  personId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<ISearchPerson>> =>
  axios.get(`${MainUrl}/${personId}`, {
    signal: cancelToken,
  });

export const deletePersonRequest = (
  personId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`${MainUrl}/${personId}`, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });

export const getPersonRoles = (
  personId: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<any>> =>
  axios.get(`${MainUrl}/${personId}/roles`, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });

export const getPersonList = (
  params?: string,
  organizationId?: string,
  cancelToken?: AbortSignal,
): Promise<AxiosResponse<IPageListModel<IGeneralPersonModel>>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'organizationId',
      value: organizationId,
    },
  ];
  const currentParams = prepareQueryParams({
    initQueryString: params,
    queryParams,
  });

  return axios.get(`${MainUrl}${currentParams}`, {
    signal: cancelToken,
    baseURL: config.peopleManagementApi.baseUrl,
  });
};
