import { useEffect } from 'react';
import { useField } from 'react-final-form';

import { AirAmbulanceSubscriptionOptions } from '../../../../models/enums/AirAmbulanceSubscriptionOptions';
import { OrganizationContractType } from '../../../../models/enums/ContractType';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';

type UseAirAmbulanceSubscriptionForm = {
  contractType?: OrganizationContractType;
  name?: string;
};

export const useAirAmbulanceSubscriptionForm = ({
  contractType,
  name,
}: UseAirAmbulanceSubscriptionForm) => {
  const {
    input: { onChange: autoRenewChange },
  } = useField(
    getFormGroupPath(name, AirAmbulanceSubscriptionOptions.AutoRenewal),
  );

  useEffect(() => {
    contractType !== OrganizationContractType.Multiple &&
      autoRenewChange(false);
  }, [autoRenewChange, contractType]);
};
