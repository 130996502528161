import { SimplePageLine } from '../../SimplePage/SimplePageLine';
import KEMembersTable, {
  IKEMembersTableProps,
} from './KEMembersTable/KEMembersTable';
import KEMembersTools, {
  KEMembersToolsProps,
} from './KEMembersTools/KEMembersTools';

type IKEMembersListProps = {
  tableProps: IKEMembersTableProps;
  toolsProps: KEMembersToolsProps;
};

const KEMembersList = ({ tableProps, toolsProps }: IKEMembersListProps) => (
  <>
    <KEMembersTools {...toolsProps} />
    <SimplePageLine />
    <KEMembersTable {...tableProps} />
  </>
);

export default KEMembersList;
