import { StyledLine, Wrapper } from './summaryItem.styles';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { ProductType } from '../../../models/ProductType';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';
import SummaryItemContent from './SummaryItemContent/SummaryItemContent';
import SummaryItemSubtotal from './SummaryItemSubtotal/SummaryItemSubtotal';
import { ISummaryItemDataModel } from './summaryItem.models';

type SummaryItemProps = CustomComponent & {
  name?: string;
  index?: number;
  data: ISummaryItemDataModel;
  onRemove?: (index: number) => void;
  subscriptionType?: ProductType;
  isPriceOverridden?: boolean;
  subtotalValue?: number;
  title?: string;
};

const SummaryItem = ({
  name,
  index,
  data,
  onRemove,
  disabled,
  subscriptionType,
  isPriceOverridden,
  subtotalValue,
  className,
  title,
}: SummaryItemProps) => (
  <Wrapper className={className}>
    {!isPropertyEmpty(index) && index > 0 && <StyledLine />}
    <SummaryItemContent
      data={data?.capacitySummary}
      title={title}
      index={index}
      isPriceOverridden={isPriceOverridden}
      onRemove={onRemove}
      subscriptionType={subscriptionType}
    />
    <SummaryItemSubtotal
      disabled={disabled}
      isPriceOverridden={isPriceOverridden}
      name={name}
      subtotalValue={subtotalValue}
    />
  </Wrapper>
);

export default SummaryItem;
