import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { canAddMembershipPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProductRoute } from '../../../../../hooks/useProductRoute';
import {
  AddMemberAvailableSearchParams,
  RoutePath,
} from '../../../../../models/enums/RoutePath';
import { SelectOption } from '../../../../../models/SelectOption';
import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';
import DescriptionSelect from '../../../../../styledComponents/UI/DescriptionSelect/DescriptionSelect';
import { MembersPagesPermissions, Views } from './membersHeader.models';
import {
  Header,
  HeaderRightContainer,
  StyledButton,
} from './membersHeader.styles';

const MembersHeader = () => {
  const navigate = useNavigateSearch();
  const { t } = useTranslation();
  const { id } = useParams();
  const { viewChangeHandler, currentView, productOptions } = useProductRoute({
    pagesPermissions: MembersPagesPermissions,
    views: Views,
  });

  return (
    <Header>
      <SectionTitle>{t('headers.members')}</SectionTitle>
      <HeaderRightContainer>
        <StyledButton
          permissions={canAddMembershipPermissions}
          onClick={() =>
            navigate(RoutePath.AddMemberWizardPrimaryMember, {
              searchParams: {
                [AddMemberAvailableSearchParams.OrganizationId]: id,
              },
            })
          }
          styleType="lighter-blue"
        >
          {t('buttons.add-member')}
        </StyledButton>
        <DescriptionSelect
          currentValue={currentView}
          description={t('phrase.view')}
          onChange={(option: SelectOption | any) =>
            viewChangeHandler(option.value)
          }
          options={productOptions}
          placeholder={t('phrase.select-product')}
        />
      </HeaderRightContainer>
    </Header>
  );
};

export default MembersHeader;
