import { useTranslation } from 'react-i18next';

import ErrorImage from '../../assets/images/404.png';
import ErrorPage from '../../styledComponents/ErrorPage/ErrorPage';
import { useNotFoundPage } from './notFoundPage.hooks';

const NotFoundPage = () => {
  const { t } = useTranslation();

  const { goToHomePage } = useNotFoundPage();

  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.page-not-found')}
      description={[t('phrase.not-found-description')]}
      buttonLabel={t('buttons.go-to-home-page')}
      onClick={goToHomePage}
    />
  );
};

export default NotFoundPage;
