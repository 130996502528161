import { isPropertyEmpty } from './propertyHelpers';
import { isEmpty } from './textHelpers';

export const getMinMaxValue = (
  value: number | string,
  max?: number | string,
  min?: number | string,
) => {
  if (!value) {
    return value;
  }
  if (!isPropertyEmpty(max) && Number(value) > max) {
    return max;
  }
  if (!isPropertyEmpty(min) && Number(value) < min) {
    return min;
  }
  return value;
};

export const isNumberInRange = (
  value: number | string,
  max?: number | string,
  min?: number | string,
) =>
  isEmpty(value?.toString()) ||
  (((!isPropertyEmpty(min) && value >= min) || isPropertyEmpty(min)) &&
    ((!isPropertyEmpty(max) && value <= max) || isPropertyEmpty(max)));
