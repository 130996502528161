import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const CUSTOM_DATE_MONTH_YEAR_FORMAT = 'yyyyMMMM';
export const CUSTOM_DATE_FORMAT = 'MM-DD-yyyy';
export const DEFAULT_DATE_PARSE = 'YYYY-MM-DD';
export const DEFAULT_SHORT_DATE_FORMAT = 'MM/YYYY';

export const DEFAULT_DATE_MASK = '__/__/____';
export const DEFAULT_SHORT_DATE_MASK = '__/____';

export const formatMomentDate = (
  date: string,
  format: string = DEFAULT_DATE_PARSE.toUpperCase(),
) => moment(date, format, false);

export const formatDate = (date: string) =>
  date ? moment(date).format(DEFAULT_DATE_FORMAT.toUpperCase()) : date;

export const customDateFormat = (date: string, format: string) =>
  moment(date).format(format);

export const parseDate = (
  date: string | moment.Moment,
  format: string = DEFAULT_DATE_PARSE,
) => (date ? moment(date, format, false).format(format) : date) as string;

export const currentDate = moment().format('YYYY-MM-DD');

export const dateIsValid = (date: string) => moment(date).isValid();

export const getOldestDate = (dates: string[]) => {
  if (!dates || dates.length === 0) {
    return '';
  }

  const sortedDates = [...dates].sort(
    (a, b) => moment(a).valueOf() - moment(b).valueOf(),
  );

  return sortedDates[0];
};

export const parseExpirationDateTwoWeeks = (dateString: string): string => {
  const parsedDate = moment(dateString);
  const newDate = parsedDate.add(13, 'days');
  return newDate.format('YYYY-MM-DD');
};

export const parseExpirationDateOneMonth = (dateString: string): string => {
  const parsedDate = moment(dateString);
  const newDate = parsedDate.add(29, 'days');
  return newDate.format('YYYY-MM-DD');
};

export const getAge = (date: string): number =>
  date ? moment().diff(date, 'years') : null;

export const getFormattedDate = (month: string, year: string) => {
  const date = moment(`${month}-${year}`, 'MM-YYYY');

  return `${date.format('MMMM')} ${date.format('YYYY')}`;
};
