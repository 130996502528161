import { ChangeEvent, useCallback } from 'react';
import { FieldInputProps } from 'react-final-form';

import { useBaseFormInput } from '../../../../hooks/useBaseFormInput';

type UseFormInput = {
  input: FieldInputProps<string, HTMLElement>;
  parse?: (value: string) => string;
  format?: (value: string) => string;
};

export const useFormInput = ({ input, parse, format }: UseFormInput) => {
  const { inputValue, onChangeHandler } = useBaseFormInput({ input, parse });

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChangeHandler(e.target.value);
    },
    [onChangeHandler],
  );

  return {
    onChangeInput,
    inputValue: format ? format(inputValue) : inputValue,
  };
};
