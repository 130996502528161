import { useSelector } from 'react-redux';

import { useProcessing } from '../../../../hooks/useProcessing';
import { MEMBERSHIP_SUMMARY_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import SummaryContainer from '../../../../styledComponents/SummaryContainer/SummaryContainer';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import { membershipSummarySelector } from '../../../../store/redux-slices/membership/common/selectors';
import SummaryItem from '../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItem';

interface IAddMemberSummaryFormProps extends CustomComponent {
  title: string;
  isPriceOverridden?: boolean;
}

const AddMemberSummaryForm = ({
  disabled,
  title,
  children,
  isPriceOverridden,
}: IAddMemberSummaryFormProps) => {
  const summaryData = useSelector(membershipSummarySelector);
  const summaryIsLoading = useProcessing(MEMBERSHIP_SUMMARY_LOADING_PROCESSING);

  return (
    <SummaryContainer
      content={() =>
        summaryData && (
          <SummaryItem
            isPriceOverridden={isPriceOverridden}
            disabled={disabled}
            title={summaryData.summaryTitle}
            subtotalValue={summaryData.total}
            data={{
              capacitySummary: summaryData.items.map((x) => ({
                name: x.name,
                amount: x.amount,
              })),
            }}
          />
        )
      }
      data={{ totalAmount: summaryData ? summaryData.total : 0 }}
      disabled={disabled}
      isLoading={summaryIsLoading}
      title={title}
      canOverwriteAmount={false}
    >
      {children}
    </SummaryContainer>
  );
};

export default AddMemberSummaryForm;
