/* eslint-disable react/destructuring-assignment */
import { FieldRenderProps } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { ErrorLabel } from '../../../../../styledComponents/Typography/Typography';
import {
  NewAmount,
  StyledNumericInput,
} from './extendSubscriptionMembershipPlanFormTable.styles';
import { useExtendSubscriptionMembershipPlanFormTable } from './extendSubscriptionMembershipPlanFormTable.hooks';
import MaterialTable from '../../../../../styledComponents/MaterialTable/MaterialTable';
import {
  PricingRenderer,
  FractionRenderer,
} from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import MainTooltip from '../../../../../styledComponents/UI/MainTooltip/MainTooltip';
import { ProductType } from '../../../../../models/ProductType';
import TableCell from '../../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

interface IExtendSubscriptionMembershipPlanFormTableProps
  extends FieldRenderProps<IChangeSubscriptionPlanCapacityModel[]> {
  disabled?: boolean;
  subscriptionPlans?: IOrganizationSubscriptionOverviewMembershipPlan[];
  productType?: ProductType;
  columnsLabels?: Partial<
    Record<keyof IOrganizationSubscriptionOverviewMembershipPlan, string>
  >;
}

const ExtendSubscriptionMembershipPlanFormTable = ({
  disabled,
  input,
  meta: { error },
  subscriptionPlans,
  productType,
  columnsLabels,
}: IExtendSubscriptionMembershipPlanFormTableProps) => {
  const { t } = useTranslation();
  const { canRemoveSeats, changeNumberOfSeats, getSelectedPlanCapacity } =
    useExtendSubscriptionMembershipPlanFormTable({ input, productType });

  const cellRenderer =
    (
      key: keyof IOrganizationSubscriptionOverviewMembershipPlan | any,
      options?: ICellOptions,
    ) =>
    (data: IOrganizationSubscriptionOverviewMembershipPlan) => {
      switch (key) {
        case 'price': {
          return (
            <PricingRenderer options={options} price={data.price} readonly />
          );
        }
        case 'changes': {
          const canRemoveSeatsTmp = canRemoveSeats(
            data.membershipPlanId,
            data.capacity,
            data.membershipsInUse,
          );

          return (
            <TableCell {...options}>
              <MainTooltip
                arrow
                isShowing={!canRemoveSeatsTmp}
                open
                placement="top"
                title={t('phrase.not-possible-remove-seats')}
              >
                <StyledNumericInput
                  disabled={options.disabled || disabled}
                  isError={!canRemoveSeatsTmp}
                  minValue={data.capacity && -data.capacity}
                  onChange={(value) => {
                    changeNumberOfSeats(value, data);
                  }}
                  value={getSelectedPlanCapacity(data.membershipPlanId)}
                />
              </MainTooltip>
            </TableCell>
          );
        }
        case 'capacity': {
          return (
            <FractionRenderer
              maxValue={data.capacity}
              options={options}
              value={data.membershipsInUse}
            />
          );
        }
        case 'newAmount': {
          const selectedPlanCapacity = getSelectedPlanCapacity(
            data.membershipPlanId,
          );
          return (
            <NewAmount
              isError={
                !canRemoveSeats(
                  data.membershipPlanId,
                  data.capacity,
                  data.membershipsInUse,
                )
              }
            >
              {data.capacity || selectedPlanCapacity
                ? data.capacity + selectedPlanCapacity
                : '-'}
            </NewAmount>
          );
        }
      }
    };

  return (
    <div>
      <MaterialTable
        columns={[
          {
            title:
              columnsLabels?.membershipPlanName ||
              t('grid-columns.membership-plan'),
            field: 'membershipPlanName',
          },
          {
            title: columnsLabels?.price || t('grid-columns.price'),
            alignment: 'center',
            cellRenderer: cellRenderer('price', {
              disabled,
              alignment: 'center',
            }),
          },
          {
            title: columnsLabels?.capacity || t('grid-columns.current-amount'),
            alignment: 'center',
            cellRenderer: cellRenderer('capacity', {
              textBold: true,
              disabled,
              alignment: 'center',
            }),
          },
          {
            title: t('grid-columns.changes'),
            alignment: 'center',
            cellRenderer: cellRenderer('changes', {
              textBold: true,
              alignment: 'center',
              disabled,
            }),
          },
          {
            title: t('grid-columns.new-amount'),
            alignment: 'center',
            cellRenderer: cellRenderer('newAmount', {
              textBold: true,
              disabled,
            }),
          },
        ]}
        data={subscriptionPlans}
        isPaginationHidden
      />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </div>
  );
};

export default ExtendSubscriptionMembershipPlanFormTable;
