import { Field } from 'react-final-form';

import { FormColumn } from '../../../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../UI/Form/FormRow/FormRow';
import { FormInput } from '../../../../UI/Form/FormInput/FormInput';
import { CustomForm } from '../../../../../models/types/CustomForm';
import { BasicFormWrapper, SecondSectionTitle } from '../../../../Form/Form';
import {
  SubscriptionDetailsFormFields,
  SubscriptionDetailsFormName,
} from './subscriptionDetailsForm.models';
import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';

interface ISubscriptionDetailsFormProps extends CustomForm {
  title?: string;
}

const SubscriptionDetailsForm = ({
  className,
  disabled,
  title,
  name,
}: ISubscriptionDetailsFormProps) => (
  <BasicFormWrapper className={className}>
    {title && <SecondSectionTitle>{title}</SecondSectionTitle>}
    <FormColumn>
      <FormRow>
        <Field
          component={FormInput}
          disabled={disabled}
          {...getFieldPropertiesForGroup({
            inputId: SubscriptionDetailsFormName,
            groupName: name,
            name: SubscriptionDetailsFormFields.PromoCode,
          })}
        />
      </FormRow>
    </FormColumn>
  </BasicFormWrapper>
);

export default SubscriptionDetailsForm;
