import { t } from 'i18next';

import { ProductType } from '../../../models/ProductType';
import AirAmbulanceSubscriptionForm from '../FormSections/AirAmbulanceSubscription/AirAmbulanceSubscriptionForm';
import DutyOfCareSubscriptionForm from '../FormSections/DutyOfCareSubscription/DutyOfCareSubscriptionForm';

import KidnapAndExtortionSubscriptionForm from '../FormSections/KidnapAndExtortionSubscription/KidnapAndExtortionSubscriptionForm';
import SecurityResponseSubscriptionForm from '../FormSections/SecurityResponseSubscription/SecurityResponseSubscriptionForm';
import TravelLicenseSubscriptionForm from '../FormSections/TravelLicenseSubscription/TravelLicenseSubscriptionForm';
import { SubscriptionItemBaseProps } from './subscriptionItemForm.models';

export const getSubscriptionTitle = (productType: ProductType, number = 0) =>
  `${t('phrase.product-subscription-item-title', {
    context: productType ? 'filled' : 'empty',
    count: number,
    productType,
  })}`;

export const SubscriptionTypeContent: Partial<
  Record<
    ProductType,
    ({ name, disabled, isEditMode }: SubscriptionItemBaseProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AirAmbulanceSubscriptionForm,
  [ProductType.DutyOfCare]: DutyOfCareSubscriptionForm,
  [ProductType.KidnapAndExtortion]: KidnapAndExtortionSubscriptionForm,
  [ProductType.SecurityResponse]: SecurityResponseSubscriptionForm,
  [ProductType.TravelLicense]: TravelLicenseSubscriptionForm,
};
