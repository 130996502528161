import { AnySchema } from 'ajv';

import { IAddress } from '../../../models/Address';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { FormAddressFields } from '../FormAddress/formAddress.models';
import { addressSchema } from '../FormAddress/formAddress.validation';
import { FamilyMemberFields } from './familyMember.models';

export const familyMemberAddressSchema: AnySchema | any = {
  type: 'object',
  properties: {
    ...addressSchema.properties,
  },
  if: {
    properties: {
      [FamilyMemberFields.AddressSameAsPrimaryMemberResidenceAddress]: {
        enum: [false],
      },
    },
  },
  then: {
    required: [
      FormAddressFields.AddressLine1,
      FormAddressFields.ZipCode,
      FormAddressFields.City,
      FormAddressFields.Country,
    ],
  },
  required: [],
};

export const validatePrimaryMemberAddressForm = (
  data: IAddress,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, familyMemberAddressSchema);
