import axios, { AxiosResponse } from 'axios';

import { IEmployee } from '../../../models/Employee';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../utils/validations/queryParamsHelpers';

export interface IEmployeesResponse {
  items: IEmployee[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export const getEmployeeList = (
  ggPocRole?: string,
): Promise<AxiosResponse<IEmployeesResponse>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'ggPocRole',
      value: ggPocRole,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`/api/employees${params}`);
};
