import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';

import {
  AvailableProduct,
  AvailableProductPermissionsType,
  AvailableProductsType,
  BasicProductOptions,
} from '../models/types/AvailableProductViewsPermissionsType';

import { usePermissions } from './usePermissions';

type UseProductRouteType = {
  defaultPage?: AvailableProduct;
  pagesPermissions?: AvailableProductPermissionsType;
  views: AvailableProductsType;
};

export const useProductRoute = ({
  defaultPage,
  pagesPermissions,
  views,
}: UseProductRouteType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkUserPermissions } = usePermissions();

  const pagesList = useMemo(
    () =>
      Object.keys(views).filter(
        (x) => !pagesPermissions || checkUserPermissions(pagesPermissions[x]),
      ),
    [checkUserPermissions, pagesPermissions, views],
  );

  const location = useLocation();

  const availablePage = useMemo(() => {
    if (!pagesPermissions) {
      return views[defaultPage || BasicProductOptions.General];
    }
    if (defaultPage && checkUserPermissions(pagesPermissions[defaultPage])) {
      return views[defaultPage];
    }
    if (pagesList.length > 0) {
      return views[pagesList[0]];
    }
  }, [checkUserPermissions, defaultPage, pagesList, pagesPermissions, views]);

  const [currentView, setCurrentView] = useState(availablePage);

  useEffect(() => {
    if (!pagesList.length) {
      return;
    }
    const pathParts = location.pathname.split('/');
    const pathLastElement = pathParts[pathParts.length - 1];
    const needLoad = Object.keys(views).includes(pathLastElement);
    if (needLoad) {
      setCurrentView(pathLastElement);
      return;
    }

    navigate(currentView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const viewChangeHandler = (product: string) => {
    const pathToRedirect = views[product];
    setCurrentView(pathToRedirect);
    navigate(pathToRedirect);
  };

  const productOptions = useMemo(
    () =>
      pagesList.map((product) => ({
        label: t(`enums.productType.${product}`),
        value: views[product],
      })),
    [pagesList, t, views],
  );

  return {
    viewChangeHandler,
    currentView,
    productOptions,
  };
};
