import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { FormApi } from 'final-form';

import { useActions } from '../../../../hooks/useActions';
import { modalStateSelector } from '../../../../store/redux-slices/modals/selectors';
import {
  loadAASubscriptionRenewalAnalyse,
  sendAASubscriptionRenewalMemberships,
} from '../../../../store/saga-slices/subscriptions/airAmbulance';
import {
  ILoadAASubscriptionRenewalAnalysePayload,
  ISendAASubscriptionRenewalMembershipsRequest,
} from '../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { aaSubscriptionRenewalAnalyseSelector } from '../../../../store/redux-slices/subscriptions/airAmbulance/selectors';
import {
  SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { useProcessing } from '../../../../hooks/useProcessing';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { ProductSubscriptionRenewPageParamsType } from '../../../../models/types/RoutePath';
import { SUBSCRIPTION_RENEW_ANALYSE_MODAL } from '../../../../styledComponents/Modals/SubscriptionAnalyseModal/subscriptionAnalyse.consts';
import { SubscriptionMembershipFormValues } from '../../../../styledComponents/Modals/SubscriptionAnalyseModal/subscriptionAnalyse.types';

export const useAAEditSubscriptionAnalyseModal = (
  onAnalyseSuccess?: () => void,
) => {
  const { organizationId, subscriptionId } =
    useParams<ProductSubscriptionRenewPageParamsType>();
  const UpdateModalState = useActions(updateModalState);

  const LoadSubscriptionRenewalAnalyse = useActions(
    loadAASubscriptionRenewalAnalyse,
  );

  const SendSubscriptionRenewalMemberships = useActions(
    sendAASubscriptionRenewalMemberships,
  );

  const isSubscriptionAnalyseLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  );

  const isSubscriptionMembershipInProgress = useProcessing(
    SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
  );

  const isModalOpen = useSelector(
    modalStateSelector(SUBSCRIPTION_RENEW_ANALYSE_MODAL),
  );

  const subscriptionAnalyse = useSelector(aaSubscriptionRenewalAnalyseSelector);

  useEffect(() => {
    const payload: ILoadAASubscriptionRenewalAnalysePayload = {
      organizationId,
      subscriptionId,
    };

    isModalOpen && LoadSubscriptionRenewalAnalyse(payload);
  }, [
    LoadSubscriptionRenewalAnalyse,
    isModalOpen,
    organizationId,
    subscriptionId,
  ]);

  const handleSubmit = (
    values: SubscriptionMembershipFormValues,
    form: FormApi<any, any>,
  ) => {
    if (form.getState().invalid) {
      return;
    }

    const memberships = values.memberships.map(
      ({ membershipId, primaryMember, secondaryMembers }) => ({
        membershipId,
        members: [primaryMember, ...secondaryMembers].map(
          ({ memberId, toBeRenewed }) => ({ memberId, toBeRenewed }),
        ),
      }),
    );
    const payload: ISendAASubscriptionRenewalMembershipsRequest = {
      organizationId,
      subscriptionId,
      data: { memberships },
      success: () => {
        onAnalyseSuccess && onAnalyseSuccess();
        UpdateModalState(SUBSCRIPTION_RENEW_ANALYSE_MODAL);
      },
    };

    SendSubscriptionRenewalMemberships(payload);
  };

  return {
    handleSubmit,
    initialValues: subscriptionAnalyse,
    totalMemberships: subscriptionAnalyse.totalMemberships,
    handleCancel: () => UpdateModalState(SUBSCRIPTION_RENEW_ANALYSE_MODAL),
    isSubscriptionAnalyseLoading,
    isSubscriptionMembershipInProgress,
  };
};
