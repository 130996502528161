import { useMemo } from 'react';

import Radio from '../Radio/Radio';
import { useRadioGroupContent } from './radioGroupContent.hooks';
import { RadioGroupContentProps } from './radioGroupContent.models';
import {
  RadioContainer,
  RadioContent,
  Wrapper,
} from './radioGroupContent.styles';

const RadioGroupContent = ({
  items,
  checked,
  disabled,
  onChange,
  className,
}: RadioGroupContentProps) => {
  const { isChecked, shouldContentVisible, filteredItems } =
    useRadioGroupContent({ checked, items });

  const itemsComponents = useMemo(
    () =>
      filteredItems.map(
        ({
          id,
          label,
          contentRenderer,
          disabled: itemDisabled,
          contentHiddenIfNoActive,
        }) => (
          <RadioContainer key={id}>
            <Radio
              disabled={disabled || itemDisabled}
              label={label}
              onChange={() => onChange(id)}
              checked={isChecked(id)}
            />
            {shouldContentVisible(id, contentHiddenIfNoActive) &&
              contentRenderer && (
                <RadioContent>
                  {contentRenderer({
                    checked: isChecked(id),
                    disabled,
                  })}
                </RadioContent>
              )}
          </RadioContainer>
        ),
      ),
    [disabled, filteredItems, isChecked, onChange, shouldContentVisible],
  );

  return <Wrapper className={className}>{itemsComponents}</Wrapper>;
};

export default RadioGroupContent;
