import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';

import { prefixOptions, genderOptions } from './primaryMemberDetailsForm.utils';
import { FormSelect } from '../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../../../../utils/phoneNumberHelpers';
import { FormFieldWithMessage } from '../../../../../../styledComponents/Form/Form';
import FormMessage from '../../../../../../styledComponents/UI/Form/FormMessage/FormMessage';
import MemberExistsMessage from './Messages/MemberExistsMessage';
import { CustomForm } from '../../../../../../models/types/CustomForm';
import { usePrimaryMemberDetailsForm } from './primaryMemberDetailsForm.hooks';
import { FormNumberInput } from '../../../../../../styledComponents/UI/Form/FormNumberInput/FormNumberInput';
import { FormColumn } from '../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../styledComponents/UI/Form/FormRow/FormRow';
import { FormInput } from '../../../../../../styledComponents/UI/Form/FormInput/FormInput';
import FormSearchEmail from '../../../../../../styledComponents/UI/Form/FormSearchPerson/FormSearchPerson';
import FormAgeInfo from '../../../../../../styledComponents/UI/Form/FormAgeInfo/FormAgeInfo';
import { PrimaryMemberDetailsFormFields } from './primaryMemberDetailsForm.models';

interface IPrimaryMemberDetailsFormProps extends CustomForm {
  onEditMember?: () => void;
}

const PrimaryMemberDetailsForm = ({
  className,
  disabled,
  readOnly,
  onEditMember,
}: IPrimaryMemberDetailsFormProps) => {
  const { t } = useTranslation();
  const { birthOfDate, memberLoaded } = usePrimaryMemberDetailsForm();

  const fieldDisabled = memberLoaded || disabled || readOnly;
  return (
    <div className={className}>
      <FormColumn>
        <FormRow isSubRow>
          <FormFieldWithMessage>
            <Field
              component={FormSearchEmail}
              fields={{ Prefix: PrimaryMemberDetailsFormFields.PersonPrefix }}
              disabled={disabled || readOnly}
              inputId={`primary-member--${PrimaryMemberDetailsFormFields.EmailAddress}-input`}
              name={PrimaryMemberDetailsFormFields.EmailAddress}
            />
            {memberLoaded && (
              <FormMessage
                message={
                  <MemberExistsMessage
                    disabled={disabled}
                    onEditMember={onEditMember}
                  />
                }
              />
            )}
          </FormFieldWithMessage>
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={fieldDisabled}
              inputId={`primary-member--${PrimaryMemberDetailsFormFields.DateOfBirth}-input`}
              name={PrimaryMemberDetailsFormFields.DateOfBirth}
            />

            <FormAgeInfo date={birthOfDate.input.value} />
          </FormRow>
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormInput}
            disabled={fieldDisabled}
            inputId={`primary-member--${PrimaryMemberDetailsFormFields.FirstName}-input`}
            name={PrimaryMemberDetailsFormFields.FirstName}
          />

          <Field
            component={FormInput}
            disabled={fieldDisabled}
            inputId={`primary-member--${PrimaryMemberDetailsFormFields.LastName}-input`}
            name={PrimaryMemberDetailsFormFields.LastName}
          />
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormNumberInput}
            disabled={fieldDisabled}
            inputId={`primary-member--${PrimaryMemberDetailsFormFields.PhoneNumber}-input`}
            name={PrimaryMemberDetailsFormFields.PhoneNumber}
            parseValue={parsePhoneNumber}
            formatValue={formatPhoneNumber}
            maxLength={PHONE_NUMBER_MAX_LENGTH}
          />
          <FormRow isSubRow>
            <Field
              component={FormSelect}
              disabled={fieldDisabled}
              isClearable
              label={t('fields.personPrefix.label')}
              inputId={`primary-member--${PrimaryMemberDetailsFormFields.PersonPrefix}-input`}
              name={PrimaryMemberDetailsFormFields.PersonPrefix}
              options={prefixOptions}
            />
            <Field
              component={FormSelect}
              disabled={fieldDisabled}
              isClearable
              label={t('fields.gender.label')}
              inputId={`primary-member--${PrimaryMemberDetailsFormFields.Gender}-input`}
              name={PrimaryMemberDetailsFormFields.Gender}
              options={genderOptions}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    </div>
  );
};

export default PrimaryMemberDetailsForm;
