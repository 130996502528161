import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGeneralPersonModel } from '../../../models/interfaces/GeneralPersonModel';
import { IPageListModel } from '../../../models/PageListModel';

import { IPerson } from '../../../models/Person';
import { IPersonMembershipItem } from '../../../models/PersonMembershipItem';

export interface IPeopleState {
  person: IPerson;
  personMemberships: IPersonMembershipItem[];
  personRoles: string[];
  personList: IPageListModel<IGeneralPersonModel>;
}

const initialState: IPeopleState = {
  person: null,
  personMemberships: [],
  personRoles: [],
  personList: null,
};

interface IDeletePersonMembershipPayload {
  membershipId: string;
}

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    setPerson: (state, { payload }: PayloadAction<IPerson>) => {
      state.person = payload;
    },
    setPersonRoles: (state, { payload }: PayloadAction<string[]>) => {
      state.personRoles = payload;
    },
    setPersonMemberships: (
      state,
      { payload }: PayloadAction<IPersonMembershipItem[]>,
    ) => {
      state.personMemberships = payload;
    },
    setPersonList: (
      state,
      { payload }: PayloadAction<IPageListModel<IGeneralPersonModel>>,
    ) => {
      state.personList = payload;
    },
    clearPersonMemberships: (state) => {
      state.personMemberships = initialState.personMemberships;
    },
    clearPerson: (state) => {
      state.person = initialState.person;
    },
    clearPersonRoles: (state) => {
      state.personRoles = initialState.personRoles;
    },
    clearPersonList: (state) => {
      state.personList = initialState.personList;
    },
    removePersonMembership: (
      state,
      { payload }: PayloadAction<IDeletePersonMembershipPayload>,
    ) => {
      state.personMemberships = state.personMemberships.filter(
        (membership) => membership.membershipId !== payload.membershipId,
      );
    },
    clearPersonData: (state) => {
      state = initialState;
    },
  },
});

export const {
  setPerson,
  clearPerson,
  setPersonRoles,
  setPersonMemberships,
  clearPersonData,
  clearPersonRoles,
  clearPersonMemberships,
  removePersonMembership,
  setPersonList,
  clearPersonList,
} = peopleSlice.actions;
export default peopleSlice.reducer;
export const personReducerName = peopleSlice.name;
