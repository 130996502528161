import { FieldRenderProps } from 'react-final-form';

import { ISubscriptionMembershipPlan } from '../../../../models/interfaces/CreateSubscriptionMembershipPlan';
import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

import MemberPlanPriceTypes from './MemberPlanPriceTypes/MemberPlanPriceTypes';
import { ErrorLabel, Table, Wrapper } from './membershipPlanForm.styles';
import {
  useMembershipPlanForm,
  useMembershipPlanTable,
} from './membershipPlanForm.hooks';
import { DefaultColumn } from './membershipPlanForm.models';
import { IMaterialColumn } from '../../../MaterialTable/materialTable.models';
import { PricingMode } from '../../../../models/enums/PricingMode';
import { ProductType } from '../../../../models/ProductType';

export interface IMemberShipPlanFormProps<T = any>
  extends FieldRenderProps<ISubscriptionMembershipPlan[]> {
  disabled?: boolean;
  dataLoading?: boolean;
  membershipPlansPrices?: IMembershipPlanPriceModel[];
  onPricingModeChange?: (pricingMode: PricingMode) => void;
  subscriptionType?: ProductType;
  columns?: IMaterialColumn[];
  additionalData?: T;
  useDefaultColumns?: boolean;
  notUsedDefaultColumns?: DefaultColumn[];
}

const MembershipPlanForm = <T extends any[]>({
  disabled,
  dataLoading,
  meta: { error },
  onPricingModeChange,
  subscriptionType,
  columns,
  useDefaultColumns = true,
  notUsedDefaultColumns,
  additionalData,
}: IMemberShipPlanFormProps<T>) => {
  const { availablePricingMode } = useMembershipPlanForm();

  const { columnsToRender, data } = useMembershipPlanTable({
    subscriptionType,
    useDefaultColumns,
    additionalData,
    columns,
    disabled,
    notUsedDefaultColumns,
  });

  return (
    <Wrapper>
      <MemberPlanPriceTypes
        isDisabled={disabled}
        availablePricingModes={availablePricingMode}
        onPlanPricingModeChange={onPricingModeChange}
      />
      <Table
        columns={columnsToRender}
        data={data}
        isPaginationHidden
        dataLoading={dataLoading}
      />
      {error && !Array.isArray(error) && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  );
};

export default MembershipPlanForm;
