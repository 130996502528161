/* eslint-disable react/destructuring-assignment */
import { useCallback } from 'react';

import { IMemberInfoModel } from '../../../models/MemberInfoModel';
import { MemberType } from '../../../models/enums/MemberType';
import { MenuOptionsRenderer } from '../../../styledComponents/MaterialTable/CellActionsRenderers/CellActionsRenderers';
import {
  NameRenderer,
  DateRenderer,
  RenewStatusRenderer,
} from '../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import {
  MemberOverviewMemberTablePermissions,
  MenuActions,
} from './memberOverviewMemberTable.models';
import {
  renderMenuOptionsForActiveMemberships,
  renderMenuOptionsForExpiredMemberships,
} from './memberOverviewMemberTable.utils';

type UseMenuOptionsType = {
  onRenewFlagChange?: (memberId: string, toBeRenewed: boolean) => void;
  onDeleteMember?: (member: IMemberInfoModel) => void;
  isExpiredMembershipsTable?: boolean;
  onResendEmailClick?: (memberId: string) => void;
  permissions?: MemberOverviewMemberTablePermissions;
};

export const useMenuOptions = ({
  onRenewFlagChange,
  onDeleteMember,
  isExpiredMembershipsTable,
  onResendEmailClick,
  permissions,
}: UseMenuOptionsType) => {
  const handleMenuOptionClick = useCallback(
    (option: MenuActions | string, data: IMemberInfoModel) => {
      switch (option) {
        case MenuActions.Remove:
          onDeleteMember?.(data);
          break;
        case MenuActions.DoNotRenew:
          onRenewFlagChange?.(data.memberId, false);
          break;
        case MenuActions.ResendWelcomeEmail:
          onResendEmailClick?.(data.personId);
          break;
        case MenuActions.Renew:
          onRenewFlagChange?.(data.memberId, true);
          break;
      }
    },
    [onDeleteMember, onRenewFlagChange, onResendEmailClick],
  );

  const actionCellRenderer = useCallback(
    (data: IMemberInfoModel) => (
      <MenuOptionsRenderer
        options={
          !isExpiredMembershipsTable
            ? renderMenuOptionsForActiveMemberships({ data, permissions })
            : renderMenuOptionsForExpiredMemberships({ data, permissions })
        }
        onElementClick={(option) => handleMenuOptionClick(option, data)}
      />
    ),
    [handleMenuOptionClick, isExpiredMembershipsTable, permissions],
  );

  return { actionCellRenderer };
};

export const useMemberTable = () => {
  const cellRenderer = useCallback(
    (key: keyof IMemberInfoModel | any, options?: ICellOptions) =>
      (rowData: any) => {
        switch (key) {
          case 'dateOfBirth': {
            return <DateRenderer date={rowData[key]} options={options} />;
          }
          case 'toBeRenewed': {
            return (
              <RenewStatusRenderer status={rowData[key]} options={options} />
            );
          }
          case 'memberName': {
            return (
              <NameRenderer
                value={`${rowData?.firstName} ${rowData?.lastName}`}
                options={{
                  ...options,
                  disabled: rowData.memberType === MemberType.Primary,
                }}
              />
            );
          }
        }
      },
    [],
  );

  return {
    cellRenderer,
  };
};
