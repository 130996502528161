import { FieldRenderProps } from 'react-final-form';

import { CustomField } from '../../../../models/types/CustomField';
import { isEmpty } from '../../../../utils/textHelpers';
import {
  StyledFieldLabel,
  Value,
  ValueWrapper,
  Wrapper,
} from './formValue.styles';

interface IFormValueProps extends FieldRenderProps<string>, CustomField {
  label?: string;
  externalValue?: string;
  contentVisible?: boolean;
  valueRenderer?: (value: string) => string;
}

const FormValue = ({
  label,
  className,
  externalValue,
  contentVisible = false,
  input,
  valueRenderer,
}: IFormValueProps) => {
  if (!contentVisible) {
    return <></>;
  }

  let content = externalValue || '-';

  if (!isEmpty(input.value)) {
    content = valueRenderer ? valueRenderer(input.value) : input.value;
  }

  return (
    <Wrapper className={className}>
      <StyledFieldLabel labelActive>{label}</StyledFieldLabel>
      <ValueWrapper>
        <Value>{content}</Value>
      </ValueWrapper>
    </Wrapper>
  );
};

export default FormValue;
