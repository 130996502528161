import { isPropertyEmpty } from '../../../utils/propertyHelpers';

type PriceType = {
  price: number;
};
const Price = ({ price }: PriceType) => (
  <>
    {!isPropertyEmpty(price)
      ? `$${new Intl.NumberFormat('en-US').format(price)}`
      : '-'}
  </>
);

export default Price;
