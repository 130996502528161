import { ReactNode } from 'react';

import { CustomComponent } from '../../../models/types/CustomComponent';
import MainTooltip from '../../UI/MainTooltip/MainTooltip';

import { Title, Wrapper, Value } from './overviewProperty.styles';

type OverviewPropertyProps = CustomComponent & {
  title?: string;
  value?: string | ReactNode;
  layout?: 'horizontal' | 'vertical';
  children?: ReactNode;
  tooltip?: ReactNode;
  bold?: boolean;
};

const OverviewProperty = ({
  title,
  value,
  layout = 'vertical',
  children,
  tooltip,
  bold,
  className,
}: OverviewPropertyProps) => (
  <Wrapper className={className} layout={layout}>
    <Title>{title}</Title>
    <MainTooltip arrow placement="top" isShowing={!!tooltip} title={tooltip}>
      <Value bold={bold}>{value || children || '-'}</Value>
    </MainTooltip>
  </Wrapper>
);

export default OverviewProperty;
