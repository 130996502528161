import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';

import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  AddMemberAvailableSearchParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
} from '../../../../store/redux-slices/processes/constants';
import {
  changeAAMemberRenewFlag,
  loadAAMembershipDetails,
} from '../../../../store/saga-slices/membership/airAmbulance';
import {
  IChangeAAMemberRenewFlagPayload,
  ILoadAAMembershipDetailsPayload,
} from '../../../../store/saga-slices/membership/airAmbulance/models';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { IMemberInfoModel } from '../../../../models/MemberInfoModel';
import { personNameSelector } from '../../../../store/redux-slices/people/selectors';
import { IAAMembershipOptions } from '../../../../models/interfaces/Membership/AirAmbulance/AAMembershipModel';
import { ProductType } from '../../../../models/ProductType';
import { aaMembershipDetailsByIdSelector } from '../../../../store/redux-slices/membership/airAmbulance/selectors';
import { EDIT_AA_MEMBERSHIP_OPTIONS_MODAL } from '../Modals/EditAAMembershipOptionsModal/editAAMembershipOptionsModal.const';
import { DELETE_AA_FAMILY_MEMBER_MODAL_NAME } from '../Modals/DeleteAAFamilyMemberModal/deleteAAFamilyMemberModal.const';
import { RESEND_AA_WELCOME_EMAIL_MODAL_NAME } from '../Modals/ResendAAWelcomeEmailModal/resendAAWelcomeEmailModal.const';
import { DeleteAAFamilyMemberModalDetails } from '../Modals/DeleteAAFamilyMemberModal/deleteAAFamilyMemberModal.models';
import { EditAAMembershipOptionsModalDetails } from '../Modals/EditAAMembershipOptionsModal/editAAMembershipOptionsModal.models';
import { ResendAAWelcomeEmailModalDetails } from '../Modals/ResendAAWelcomeEmailModal/resendAAWelcomeEmailModal.models';
import { DELETE_AA_MEMBERSHIP_MODAL_NAME } from '../Modals/DeleteAAMembershipModal/deleteAAMembershipModal.const';
import { MemberOverviewPageParamsType } from '../../../../models/types/RoutePath';

export const useAAMembershipDetails = (
  membershipId?: string,
  productType?: ProductType,
) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigateSearch();

  const LoadMembershipDetails = useActions(loadAAMembershipDetails);
  const ChangeMemberRenewFlag = useActions(changeAAMemberRenewFlag);
  const UpdateModalState = useActions(updateModalState);

  const dataLoading = useProcessing([
    LOAD_MEMBERSHIP_DETAILS + membershipId,
    CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
  ]);
  const membershipDetails = useSelector(
    aaMembershipDetailsByIdSelector(membershipId),
  );
  const personName = useSelector(personNameSelector);

  const canBeRenewed = membershipDetails?.metadata?.canRenew;

  const getMembershipDetails = useCallback(() => {
    const payload: ILoadAAMembershipDetailsPayload = {
      membershipId,
    };
    LoadMembershipDetails(payload);
  }, [LoadMembershipDetails, membershipId]);

  useEffect(() => {
    if (membershipDetails) {
      return;
    }
    getMembershipDetails();
  }, [getMembershipDetails, membershipDetails]);

  const deleteFamilyMemberHandler = useCallback(
    (member: IMemberInfoModel) => {
      const details: DeleteAAFamilyMemberModalDetails = {
        member,
        membershipId,
      };

      UpdateModalState([
        DELETE_AA_FAMILY_MEMBER_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const deleteMembershipHandler = useCallback(() => {
    UpdateModalState([
      DELETE_AA_MEMBERSHIP_MODAL_NAME + membershipId,
      {
        membershipId,
        productType,
        personName,
      },
    ]);
  }, [UpdateModalState, membershipId, personName, productType]);

  const changeRenewFlagHandler = useCallback(
    (memberId: string, toBeRenewed: boolean) => {
      const payload: IChangeAAMemberRenewFlagPayload = {
        membershipId,
        memberId,
        toBeRenewed,
        success: () => {
          getMembershipDetails();

          alert.success(t('alerts.renew-flag-updated'));
        },
      };
      ChangeMemberRenewFlag(payload);
    },
    [ChangeMemberRenewFlag, alert, getMembershipDetails, membershipId, t],
  );

  const resendWelcomeEmailHandler = useCallback(
    (personId: string) => {
      const details: ResendAAWelcomeEmailModalDetails = {
        membershipId,
        personId,
      };
      UpdateModalState([
        RESEND_AA_WELCOME_EMAIL_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberInfoModel) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, {
        params,
      });
    },
    [navigate],
  );

  const onEditMembershipOptionsHandler = useCallback(
    (membershipOptions: IAAMembershipOptions) => {
      const details: EditAAMembershipOptionsModalDetails = {
        membershipId,
        membershipOptions,
      };
      UpdateModalState([
        EDIT_AA_MEMBERSHIP_OPTIONS_MODAL + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  return {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    deleteFamilyMemberHandler,
    canBeRenewed,
    resendWelcomeEmailHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  };
};

type UseMemberOverviewMemberRenew = {
  membershipId?: string;
  productType?: ProductType;
};

export const useAAMembershipRenew = ({
  membershipId,
  productType,
}: UseMemberOverviewMemberRenew) => {
  const navigate = useNavigateSearch();
  const renewMembershipHandler = useCallback(() => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.MembershipId]: membershipId,
        [AddMemberAvailableSearchParams.ProductType]: productType,
      },
    });
  }, [membershipId, navigate, productType]);

  return { renewMembershipHandler };
};
