import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLeftContainer,
  StyledRightContainer,
  DetailsWrapper,
  StyledDeleteButton,
} from '../../Common/memberOverviewMembers.styles';
import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  canAddSRMembershipFamilyMemberPermissions,
  canEditSRMembershipsOptionsPermissions,
  canRemoveSRMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { ProductType } from '../../../../models/ProductType';
import { StyledButton } from './srMembershipActiveDetails.styles';
import { useSRMembershipActiveDetails } from './srMembershipActiveDetails.hooks';
import { useSRMembershipDetails } from '../Common/srMembershipOverview.hooks';
import AddSRFamilyMemberModal from '../Modals/AddSRFamilyMemberModal/AddSRFamilyMemberModal';
import DeleteSRFamilyMemberModal from '../Modals/DeleteSRFamilyMemberModal/DeleteSRFamilyMemberModal';
import ResendSRWelcomeEmailModal from '../Modals/ResendSRWelcomeEmailModal/ResendSRWelcomeEmailModal';
import DeleteSRMembershipModal from '../Modals/DeleteSRMembershipModal/DeleteSRMembershipModal';
import EditSRMembershipOptionsModal from '../Modals/EditSRMembershipOptionsModal/EditSRMembershipOptionsModal';
import { srOverviewTablePermissions } from '../Common/srMembershipOverview.const';
import { SecurityResponseMembershipOptions } from '../../../../models/enums/SecurityResponseMembershipOptions';

interface ISRMembershipActiveDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const SRMembershipActiveDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: ISRMembershipActiveDetailsProps) => {
  const { t } = useTranslation();

  const {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    resendWelcomeEmailHandler,
    deleteFamilyMemberHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useSRMembershipDetails(membershipId, productType);

  const { addFamilyMemberHandler } = useSRMembershipActiveDetails({
    membershipId,
    canAddMemberTypes: membershipDetails?.metadata?.canAddMemberTypes,
  });

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }
  return (
    <>
      <AddSRFamilyMemberModal
        id={membershipId}
        onFamilyMemberAdded={getMembershipDetails}
      />
      <DeleteSRFamilyMemberModal
        id={membershipId}
        onFamilyMemberRemoved={getMembershipDetails}
      />
      <EditSRMembershipOptionsModal id={membershipId} />
      <DeleteSRMembershipModal id={membershipId} />
      <ResendSRWelcomeEmailModal id={membershipId} />
      <DetailsWrapper>
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            onResendEmailClick={resendWelcomeEmailHandler}
            onDeleteMember={deleteFamilyMemberHandler}
            onRowClick={onRowClickHandler}
            isExpiredMembershipsTable={false}
            permissions={srOverviewTablePermissions}
          />
          {membershipDetails?.metadata?.canAddMemberTypes.length > 0 && (
            <StyledButton
              permissions={canAddSRMembershipFamilyMemberPermissions}
              onClick={addFamilyMemberHandler}
              styleType="link"
            >
              {t('buttons.add-family-member')}
            </StyledButton>
          )}
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditSRMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: SecurityResponseMembershipOptions.SendWelcomeEmail },
              { value: SecurityResponseMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            canBeRenewed={false}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveSRMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default SRMembershipActiveDetails;
