import { useTranslation } from 'react-i18next';

import { canViewDOCMembersPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import DeleteProductSubscriptionModal from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/DeleteSubscriptionModal';
import EditRenewalSubscriptionStatusModal from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';
import { RightContainer } from '../../../../../styledComponents/Page/Page';
import CollapseContent from '../../../../../styledComponents/UI/CollapseContent/CollapseContent';
import ProductSubscriptionOverviewTemplatePage from '../ProductSubscriptionOverviewTemplatePage/ProductSubscriptionOverviewTemplatePage';
import { docSubscriptionOverviewPermissions } from './docSubscriptionOverview.const';
import {
  useDOCSubscriptionOverviewActions,
  useDOCSubscriptionOverviewTabPage,
} from './docSubscriptionOverview.hooks';
import { StyledLeftContainer } from './docSubscriptionOverview.styles';
import DOCSubscriptionOverviewHistoryPackagesTable from './DOCSubscriptionOverviewHistoryPackagesTable/DOCSubscriptionOverviewHistoryPackagesTable';
import DOCSubscriptionOverviewInformation from './DOCSubscriptionOverviewInformation/DOCSubscriptionOverviewInformation';
import DOCSubscriptionOverviewPackagesTable from './DOCSubscriptionOverviewPackagesTable/DOCSubscriptionOverviewPackagesTable';
import DOCSubscriptionEditOptionsModal from './Modals/DOCSubscriptionEditOptionsModal/DOCSubscriptionEditOptionsModal';

const DOCSubscriptionOverview = () => {
  const { t } = useTranslation();

  const {
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
    currentPackages,
    historyPackages,
  } = useDOCSubscriptionOverviewTabPage();

  const {
    addMemberHandler,
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    onRowClickHandler,
    showEditSubscriptionDetailsModal,
    editSubscriptionHandler,
    onSeeOversubscribedMembersHandler,
    editRenewalStatusHandler,
  } = useDOCSubscriptionOverviewActions();

  return (
    <>
      <DOCSubscriptionEditOptionsModal
        onModalSuccess={() => loadSubscriptionOverviewHandler()}
      />
      <DeleteProductSubscriptionModal />
      <EditRenewalSubscriptionStatusModal
        onRenewalStatusEdited={loadSubscriptionOverviewHandler}
      />
      <ProductSubscriptionOverviewTemplatePage
        canAddMember={subscriptionOverview?.metadata?.canAddMember}
        canExtend={subscriptionOverview?.metadata?.canAddExtension}
        canDelete={subscriptionOverview?.metadata?.canDelete}
        onDeleteProductSubscription={deleteProductSubscriptionHandler}
        onAddMember={addMemberHandler}
        onExtendProductSubscription={extendProductSubscriptionHandler}
        onEditSubscription={editSubscriptionHandler}
        isLoading={subscriptionOverviewLoading}
        canEditSubscription={subscriptionOverview?.metadata?.canEdit}
        permissions={docSubscriptionOverviewPermissions}
      >
        <StyledLeftContainer>
          <DOCSubscriptionOverviewPackagesTable
            packagesOptions={currentPackages}
            seatsInUse={subscriptionOverview?.totalMembershipsInUse}
            onRowClick={onRowClickHandler}
            canClickRowPermissions={canViewDOCMembersPermissions}
            onSeeOversubscriptionClick={onSeeOversubscribedMembersHandler}
            isOversubscribed={subscriptionOverview?.totalIsOversubscribed}
          />
          {!!historyPackages?.length && (
            <CollapseContent title={t('headers.history-log')}>
              <DOCSubscriptionOverviewHistoryPackagesTable
                packageOptionsHistory={historyPackages}
              />
            </CollapseContent>
          )}
        </StyledLeftContainer>
        <RightContainer>
          <DOCSubscriptionOverviewInformation
            onEditOptionsClick={showEditSubscriptionDetailsModal}
            subscriptionOverview={subscriptionOverview}
            canEditOptions={!!subscriptionOverview?.metadata?.canEditOptions}
            canRenew={!!subscriptionOverview?.metadata?.canRenew}
            onEditRenewalStatus={editRenewalStatusHandler}
          />
        </RightContainer>
      </ProductSubscriptionOverviewTemplatePage>
    </>
  );
};

export default DOCSubscriptionOverview;
