import { createSelector } from 'reselect';

import { IErrorsState } from '.';
import { ApiError } from '../../../models/types/ApiError';
import { RootState } from '../index';

export const errorsStateSelector = (state: RootState) => state.errors;

export const errorSelector = createSelector(
  errorsStateSelector,
  (errorsState: IErrorsState): ApiError => errorsState.error,
);
