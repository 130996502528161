export const LOGIN_PROCESSING = 'LOGIN_PROCESSING';
export const ORGANIZATIONS_LOADING_PROCESSING =
  'ORGANIZATIONS_LOADING_PROCESSING';
export const ORGANIZATION_LOADING_PROCESSING =
  'ORGANIZATION_LOADING_PROCESSING';
export const ORGANIZATION_ADDING_PROCESSING = 'ORGANIZATION_ADDING_PROCESSING';
export const ORGANIZATION_EDITING_PROCESSING =
  'ORGANIZATION_EDITING_PROCESSING';
export const ORGANIZATION_NOTES_ADDING_PROCESSING =
  'ORGANIZATION_NOTES_ADDING_PROCESSING';
export const ORGANIZATION_NOTES_EDITING_PROCESSING =
  'ORGANIZATION_NOTES_EDITING_PROCESSING ';
export const EMPLOYEES_LOADING_PROCESSING = 'EMPLOYEES_LOADING_PROCESSING';
export const ROLES_LOADING_PROCESSING = 'ROLES_LOADING_PROCESSING';
export const GG_ROLES_LOADING_PROCESSING = 'GG_ROLES_LOADING_PROCESSING';
export const AA_PRICING_LOADING_PROCESSING = 'AA_PRICING_LOADING_PROCESSING ';
export const KE_PRICING_LOADING_PROCESSING = 'KE_PRICING_LOADING_PROCESSING ';
export const SR_PRICING_LOADING_PROCESSING = 'SR_PRICING_LOADING_PROCESSING ';
export const TL_PRICING_LOADING_PROCESSING = 'TL_PRICING_LOADING_PROCESSING ';
export const DOC_PRICING_LOADING_PROCESSING = 'DOC_PRICING_LOADING_PROCESSING ';
export const DOC_PACKAGES_LOADING_PROCESSING =
  'DOC_PACKAGES_LOADING_PROCESSING ';
export const ORGANIZATION_CONTRACTS_LOADING_PROCESSING =
  'ORGANIZATION_CONTRACTS_LOADING_PROCESSING';
export const ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING =
  'ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING';
export const SUBSCRIPTION_ADDING_PROCESSING = 'SUBSCRIPTION_ADDING_PROCESSING';
export const SUBSCRIPTIONS_LOADING_PROCESSING =
  'SUBSCRIPTIONS_LOADING_PROCESSING';
export const SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING =
  'SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING';
export const SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING =
  'SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING';
export const SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING =
  'SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING';
export const SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING =
  'SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING';
export const SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING =
  'SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING';
export const SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING =
  'SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING';
export const SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING =
  'SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING';
export const SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING =
  'SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING';
export const SUBSCRIPTION_EDITING_LOADING_PROCESSING =
  'SUBSCRIPTION_EDITING_LOADING_PROCESSING';
export const SUBSCRIPTION_DETAILS_EDITING_PROCESSING =
  'SUBSCRIPTION_DETAILS_EDITING_PROCESSING';
export const SUBSCRIPTION_EDITING_PROCESSING =
  'SUBSCRIPTION_EDITING_PROCESSING';
export const SUBSCRIPTION_KE_EDITING_PROCESSING =
  'SUBSCRIPTION_KE_EDITING_PROCESSING';
export const ORGANIZATION_CONTRACT_ADDING_PROCESSING =
  'ORGANIZATION_CONTRACT_ADDING_PROCESSING';
export const ORGANIZATION_CONTRACT_UPDATING_PROCESSING =
  'ORGANIZATION_CONTRACT_UPDATING_PROCESSING';
export const ORGANIZATION_CONTRACT_DELETING_PROCESSING =
  'ORGANIZATION_CONTRACT_DELETING_PROCESSING';
export const ORGANIZATION_CONTRACT_LOADING_PROCESSING =
  'ORGANIZATION_CONTRACT_LOADING_PROCESSING';
export const ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING =
  'ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING';
export const ORGANIZATION_CONTRACT_NOTES_ADDING_PROCESSING =
  'ORGANIZATION_CONTRACT_NOTES_ADDING_PROCESSING';
export const ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING =
  'ORGANIZATION_CONTRACT_NOTES_EDITING_PROCESSING ';
export const CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING =
  'CONTRACT_RENEWAL_STATUS_EDITING_PROCESSING';
export const SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING =
  'SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING';
export const ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING =
  'ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING ';
export const ORGANIZATIONS_SEARCH_PROCESSING =
  'ORGANIZATIONS_SEARCH_PROCESSING';
export const MEMBERSHIP_CREATING_PROCESSING = 'MEMBERSHIP_CREATING_PROCESSING';
export const MEMBERSHIP_PROMO_CODE_LOADING = 'MEMBERSHIP_PROMO_CODE_LOADING';
export const MEMBERSHIP_SUMMARY_LOADING_PROCESSING =
  'MEMBERSHIP_SUMMARY_LOADING_PROCESSING';
export const PRIMARY_MEMBER_VALIDATING_PROCESSING =
  'PRIMARY_MEMBER_VALIDATING_PROCESSING';
export const MEMBERSHIP_RETAIL_CREATING_PROCESSING =
  'MEMBERSHIP_RETAIL_CREATING_PROCESSING';
export const SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING =
  'SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING';
export const SUBSCRIPTION_RENEW_PROCESSING = 'SUBSCRIPTION_RENEW_PROCESSING';
export const SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING =
  'SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING';
export const SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING =
  'SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING';
export const SUBSCRIPTION_DELETE_PROCESSING = 'SUBSCRIPTION_DELETE_PROCESSING';
export const MEMBERS_LOADING_PROCESSING = 'MEMBERS_LOADING_PROCESSING';
export const PERSON_LOADING_PROCESSING = 'PERSON_LOADING_PROCESSING';
export const PRIMARY_MEMBER_LOADING_PROCESSING =
  'PRIMARY_MEMBER_LOADING_PROCESSING';
export const PERSON_ROLES_LOADING_PROCESSING =
  'PERSON_ROLES_LOADING_PROCESSING';
export const PERSON_MEMBERSHIPS_LOADING_PROCESSING =
  'PERSON_MEMBERSHIPS_LOADING_PROCESSING';
export const PERSON_UPDATE_PROCESSING = 'PERSON_UPDATE_PROCESSING';
export const PERSON_DELETE_PROCESSING = 'PERSON_DELETE_PROCESSING';
export const PERSON_LIST_LOAD_PROCESSING = 'PERSON_LIST_LOAD_PROCESSING';
export const SECONDARY_MEMBER_ADDING_PROCESSING =
  'SECONDARY_MEMBER_ADDING_PROCESSING';
export const PAYMENT_BEGINNING_PROCESSING = 'PAYMENT_BEGINNING_PROCESSING';
export const PAYMENT_PROCESSING = 'PAYMENT_PROCESSING';
export const PAYMENT_CAPTURE_PROCESSING = 'PAYMENT_CAPTURE_PROCESSING';
export const LOAD_CREDIT_CARDS_PROCESSING = 'LOAD_CREDIT_CARDS_PROCESSING';
export const LOAD_MEMBERSHIP_DETAILS = 'LOAD_MEMBERSHIP_DETAILS';
export const LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING =
  'LOAD_MEMBERSHIP_SUGGESTIONS';
export const CHANGE_MEMBER_RENEW_FLAG_PROCESSING =
  'CHANGE_MEMBER_RENEW_FLAG__PROCESSING';
export const SECONDARY_MEMBER_DELETE_PROCESSING =
  'SECONDARY_MEMBER_DELETE_PROCESSING';
export const PROMO_CODES_LOADING_PROCESSING = 'PROMO_CODES_LOADING_PROCESSING';
export const RENEWAL_CONTRACTS_LOADING_PROCESSING =
  'RENEWAL_CONTRACTS_LOADING_PROCESSING';
export const RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING =
  'RENEWAL_SUBSCRIPTIONS_LOADING_PROCESSING';
export const RENEWAL_CONTRACTS_EXPORT_PROCESSING =
  'RENEWAL_CONTRACTS_EXPORT_PROCESSING';
export const RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING =
  'RENEWAL_SUBSCRIPTIONS_EXPORT_PROCESSING';
export const PROMO_CODE_OVERVIEW_LOADING_PROCESSING =
  'PROMO_CODE_OVERVIEW_LOADING_PROCESSING';
export const PROMO_CODE_ADDING_PROCESSING = 'PROMO_CODE_ADDING_PROCESSING';
export const PROMO_CODE_DELETING_PROCESSING = 'PROMO_CODE_DELETING_PROCESSING';
export const PROMO_CODE_CHANGE_STATUS_PROCESSING =
  'PROMO_CODE_CHANGE_STATUS_PROCESSING';
export const PROMO_CODE_SUGGESTION_LOAD_PROCESSING =
  'PROMO_CODE_SUGGESTION_LOAD_PROCESSING';
export const PROMO_CODE_DATE_EDIT_PROCESSING =
  'PROMO_CODE_DATE_EDIT_PROCESSING';
export const SUBSCRIPTION_FINANCE_REPORTS_PROCESSING =
  'SUBSCRIPTION_FINANCE_REPORTS_PROCESSING';
export const RETAIL_FINANCE_REPORTS_PROCESSING =
  'RETAIL_FINANCE_REPORTS_PROCESSING';
export const AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING =
  'AA_ENROLLMENT_REPORT_DOWNLOAD_PROCESSING';
export const AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING =
  'AA_ATTRITION_REPORT_DOWNLOAD_PROCESSING';
export const AA_RETAIL_STATISTICS_REPORT_DOWNLOAD_PROCESSING =
  'AA_RETAIL_STATISTICS_REPORT_DOWNLOAD_PROCESSING';
export const AA_RAW_DATA_REPORTS_PROCESSING = 'AA_RAW_DATA_REPORTS_PROCESSING';
export const KE_RAW_DATA_REPORTS_PROCESSING = 'KE_RAW_DATA_REPORTS_PROCESSING';
export const SR_RAW_DATA_REPORTS_PROCESSING = 'SR_RAW_DATA_REPORTS_PROCESSING';
export const DOC_RAW_DATA_REPORTS_PROCESSING =
  'DOC_RAW_DATA_REPORTS_PROCESSING';

export const CREDIT_CARD_REPLACE_PROCESSING = 'CREDIT_CARD_REPLACE_PROCESSING';
export const CREDIT_CARD_ADD_PROCESSING = 'CREDIT_CARD_ADD_PROCESSING';
export const CREDIT_CARD_DELETE_PROCESSING = 'CREDIT_CARD_DELETE_PROCESSING';

export const RESEND_WELCOME_EMAIL_PROCESSING =
  'RESEND_WELCOME_EMAIL_PROCESSING';

export const DELETE_MEMBERSHIP_PROCESSING = 'DELETE_MEMBERSHIP_PROCESSING';
export const UPDATE_MEMBERSHIP_OPTIONS_PROCESSING =
  'UPDATE_MEMBERSHIP_OPTIONS_PROCESSING';
export const NEW_MEMBERSHIP_DATES_LOAD_PROCESSING =
  'NEW_MEMBERSHIP_DATES_LOAD_PROCESSING';

export const USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING =
  'USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING';
export const USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING =
  'USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING';
export const USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING =
  'USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING';
export const USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING =
  'USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING';
export const USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING =
  'USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING';
export const USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING =
  'USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING';
export const USER_PERMISSIONS_REPORTS_LOAD_PROCESSING =
  'USER_PERMISSIONS_REPORTS_LOAD_PROCESSING';
export const USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING =
  'USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING';
