import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import OrganizationType from '../../../../../../styledComponents/Forms/OrganizationTypeForm/OrganizationTypeForm';
import CorporationInformationsForm from '../../../../../../styledComponents/Forms/CorporationInformationsForm/CorporationInformationsForm';

import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import ModalFormPage from '../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useEditClientDetailsModal } from './editClientDetailsModal.hooks';
import { EDIT_CLIENT_DETAILS_MODAL } from './editClientDetailsModal.const';

const EditClientDetailsModal = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    onCancelHandler,
    organizationUpdating,
    validateForm,
    canChangeOrganizationType,
    initState,
  } = useEditClientDetailsModal();

  return (
    <Modal disabled={organizationUpdating} name={EDIT_CLIENT_DETAILS_MODAL}>
      <Form
        initialValues={initState}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            isProcessing={organizationUpdating}
            onCancel={onCancelHandler}
            title={t('headers.editing-corporate-client-details')}
          >
            <OrganizationType
              tooltip={t('phrase.cannot-change-organization-type')}
              tooltipIsShowing={!canChangeOrganizationType}
              disabled={organizationUpdating || !canChangeOrganizationType}
            />
            <CorporationInformationsForm disabled={organizationUpdating} />
          </ModalFormPage>
        )}
      </Form>
    </Modal>
  );
};

export default EditClientDetailsModal;
