import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useFormErrorsActions } from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { SelectContractForm } from '../../../../styledComponents/Forms/SelectContractForm/SelectContractForm';
import {
  LeftContainer,
  RightContainer,
  PageRootColumns,
} from '../../../../styledComponents/Page/Page';
import SubscriptionSummaryForm from '../../../../styledComponents/FormSummary/SubscriptionSummary/SubscriptionSummary';
import Spinner from '../../../../styledComponents/Spinner/Spinner';
import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import WarningsConfirmationModal from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { ProductType } from '../../../../models/ProductType';
import FormSubmitButtons from '../../../../styledComponents/UI/FormSubmitButtons/FormSubmitButtons';
import { useSREditSubscriptionRenewForm } from './srSubscriptionRenewPageForm.hooks';
import { validateSREditRenewSubscriptionForm } from './srSubscriptionRenewPageForm.validation';
import { SRRenewSubscriptionSummaryLoader } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/SecurityResponseRenewSubscription/SRRenewSubscriptionFormSummaryLoader/SRRenewSubscriptionFormSummaryLoader';
import SecurityResponseRenewSubscriptionForm from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/SecurityResponseRenewSubscription/SecurityResponseRenewSubscriptionForm';

interface ISREditSubscriptionRenewPageFormProps {
  onContractChanged?: (value: string) => void;
}

const SREditSubscriptionRenewPageForm = ({
  onContractChanged,
}: ISREditSubscriptionRenewPageFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    isSubscriptionUpdating,
    organizationContracts,
    initState,
    isSuggestionDataLoading,
    updateOrganizationSubscription,
    canChangeContract,
    onAddNewContractHandler,
  } = useSREditSubscriptionRenewForm();

  const { validateErrors } = useFormErrorsActions();

  return (
    <WarningsConfirmationProvider
      submitFunction={updateOrganizationSubscription}
    >
      <WarningsConfirmationModal />
      <Spinner size="20%" isVisible={isSuggestionDataLoading}>
        <Form
          initialValues={{
            ...initState,
            transferAllEligibleMemberships: true,
          }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          validate={(validate) =>
            validateSREditRenewSubscriptionForm(
              validate,
              validateErrors(validate),
            )
          }
        >
          {({ values, ...renderProps }) => (
            <FormErrorActivator>
              <form onSubmit={renderProps.handleSubmit}>
                <PageRootColumns>
                  <LeftContainer>
                    <SelectContractForm
                      contracts={organizationContracts}
                      disabled={isSubscriptionUpdating}
                      canChange={canChangeContract}
                      canNotChangeTooltip={t(
                        'phrase.can-not-change-contract-earlier-six-months',
                      )}
                      setDefaultValue={false}
                      onContractChanged={onContractChanged}
                      isClearable
                      onAddNewContract={onAddNewContractHandler}
                    />
                    <SecurityResponseRenewSubscriptionForm
                      disabled={isSubscriptionUpdating}
                    />
                  </LeftContainer>
                  <RightContainer>
                    <SRRenewSubscriptionSummaryLoader
                      pricing={values?.pricing}
                    />
                    <SubscriptionSummaryForm
                      title={t('headers.summary')}
                      subscriptionType={ProductType.SecurityResponse}
                      disabled={isSubscriptionUpdating}
                      isPriceOverridden={values.overwriteAmount}
                    />
                    <FormSubmitButtons
                      handleSubmit={renderProps.handleSubmit}
                      canSaveDraft
                      isProcessing={isSubscriptionUpdating}
                      saveLabel={t('buttons.create')}
                    />
                  </RightContainer>
                </PageRootColumns>
              </form>
            </FormErrorActivator>
          )}
        </Form>
      </Spinner>
    </WarningsConfirmationProvider>
  );
};

export default SREditSubscriptionRenewPageForm;
