import { FieldRenderProps } from 'react-final-form';

import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import {
  SelectRenderer,
  SelectRendererProps,
} from '../../../../../../styledComponents/MaterialTable/CellRenderers/SelectRenderer/SelectRenderer';
import TableCell from '../../../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

export interface IFormSelectRenderer
  extends FieldRenderProps<string>,
    SelectRendererProps<string> {
  options?: ICellOptions;
}

export const FormSelectRenderer = ({
  options,
  input,
  ...props
}: IFormSelectRenderer) => (
  <TableCell {...options}>
    <SelectRenderer
      {...props}
      onChange={input.onChange}
      options={options}
      currentValue={input.value}
    />
  </TableCell>
);
