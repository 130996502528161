import { DiscountType } from '../../../../../models/enums/DiscountType';
import { ISubscriptionMembershipPlan } from '../../../../../models/interfaces/CreateSubscriptionMembershipPlan';
import { IMembershipPlanPriceModel } from '../../../../../models/MembershipPlanPriceModel';
import { IMembershipPlanPreviousPriceModel } from './useMembershipPlanPrices.models';

export const getMembershipPlanArrayIndex = (
  collection:
    | ISubscriptionMembershipPlan[]
    | IMembershipPlanPriceModel[]
    | IMembershipPlanPreviousPriceModel[],
  membershipPlanId: string,
) =>
  collection
    ? collection.findIndex((x) => x.membershipPlanId === membershipPlanId)
    : null;

export const getPlanCapacity = (
  collection: ISubscriptionMembershipPlan[],
  membershipPlanId: string,
) => {
  const currentPlan = getMembershipPlanArrayIndex(collection, membershipPlanId);
  return currentPlan === null || currentPlan === -1
    ? 0
    : collection[currentPlan].capacity;
};

export const findPlanPriceById = (
  collection:
    | ISubscriptionMembershipPlan[]
    | IMembershipPlanPriceModel[]
    | IMembershipPlanPreviousPriceModel[],
  membershipPlanId: string,
) => {
  const currentPlanIndex = getMembershipPlanArrayIndex(
    collection,
    membershipPlanId,
  );
  return currentPlanIndex === null || currentPlanIndex === -1
    ? 0
    : collection[currentPlanIndex].price;
};

export const updatePricesByCollection = (
  collection: ISubscriptionMembershipPlan[],
  prices: IMembershipPlanPriceModel[] | IMembershipPlanPreviousPriceModel[],
) =>
  collection.map((plan) => ({
    ...plan,
    price: findPlanPriceById(prices, plan.membershipPlanId),
  }));

export const calculatePriceByDiscount = (
  price: number,
  discountValue: number,
  discountType: DiscountType,
) => {
  let currentValue = 0;
  if (discountType === DiscountType.Amount) {
    currentValue = price - discountValue;
  } else {
    currentValue = price - (price * discountValue) / 100;
    currentValue = Math.ceil(currentValue);
  }

  return currentValue >= 0 ? currentValue : 0;
};

export const calculateDiscountPricing = (
  membershipPlans: ISubscriptionMembershipPlan[],
  regularPlans: IMembershipPlanPriceModel[],
  discountType: DiscountType,
  discountValue: number,
) => {
  const currentMembershipPlans: ISubscriptionMembershipPlan[] = [];

  membershipPlans.forEach((x: ISubscriptionMembershipPlan) => {
    const oldPrice = findPlanPriceById(regularPlans, x.membershipPlanId);
    currentMembershipPlans.push({
      ...x,
      price: calculatePriceByDiscount(oldPrice, discountValue, discountType),
    });
  });

  return currentMembershipPlans;
};
