import aaMembershipSaga from './airAmbulance';
import membershipSaga from './common';
import keMembershipSaga from './kidnapAndExtortion';
import srMembershipSaga from './securityResponse';
import docMembershipSaga from './dutyOfCare';

const membershipSagasRoot = [
  aaMembershipSaga(),
  membershipSaga(),
  keMembershipSaga(),
  srMembershipSaga(),
  docMembershipSaga(),
];
export default membershipSagasRoot;
