import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';

import { userPermissionsSelector } from '../store/redux-slices/auth/selectors';
import { userHasPermissions } from '../utils/permissionsHelpers';

export const usePermissions = (permissions?: string[]) => {
  const userPermissions = useSelector(userPermissionsSelector);

  const checkUserPermissions = useCallback(
    (requiredPermissions: string[]) =>
      userHasPermissions(userPermissions, requiredPermissions),
    [userPermissions],
  );
  const hasPermissions = useMemo(
    () => userHasPermissions(userPermissions, permissions),
    [permissions, userPermissions],
  );

  return { hasPermissions, checkUserPermissions };
};
