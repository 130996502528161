import { useSelector } from 'react-redux';

import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { organizationSelector } from '../../../../store/redux-slices/organizations/selectors';
import { ADD_POINT_OF_CONTACT_MODAL } from './Modals/AddPointOfContactModal/addPointOfContactModal.const';
import { DELETE_GG_POINT_OF_CONTACT_MODAL_NAME } from './Modals/DeleteGGPointOfContactModal/deleteGGPointOfContactModal.consts';
import { DELETE_ORGANIZATION_POINT_OF_CONTACT_MODAL_NAME } from './Modals/DeleteOrganizationPointOfContactModal/deleteOrganizationPointOfContactModal.consts';

import { ADD_GG_CONTACT_MODAL_NAME } from './Modals/AddGGContactModal/addGGContactModal.const';
import { DeleteGGPointOfContactModalDetails } from './Modals/DeleteGGPointOfContactModal/deleteGGPointOfContactModal.models';
import { DELETE_ORGANIZATION_MODAL_NAME } from './Modals/DeleteOrganizationModal/deleteOrganizationModal.const';
import { DeleteOrganizationPointOfContactModalDetails } from './Modals/DeleteOrganizationPointOfContactModal/deleteOrganizationPointOfContactModal.models';
import { EDIT_CLIENT_DETAILS_MODAL } from './Modals/EditingClientDetailsModal/editClientDetailsModal.const';
import { EDIT_CONTACT_MODAL_NAME } from './Modals/EditingContactModal/editContactModal.const';
import { EditContactModalDetails } from './Modals/EditingContactModal/editContactModal.models';
import { EDIT_GG_POINT_OF_CONTACT_MODAL_NAME } from './Modals/EditingGGPointOfContactModal/editGGPointOfContactModal.const';
import { EditGGPointOfContactModalDetails } from './Modals/EditingGGPointOfContactModal/editGGPointOfContactModal.models';
import { AddOrganizationNotesModalDetails } from './Modals/AddNotesModal/AddNotesModalForm/addNotesModalForm.models';
import { ADD_ORG_NOTES_MODAL_NAME } from './Modals/AddNotesModal/addNotesModal.const';
import { EditOrganizationNotesModalDetails } from './Modals/EditNotesModal/EditNotesModalForm/editNotesModalForm.models';
import { loadOrganization } from '../../../../store/saga-slices/organizations';
import { EDIT_ORG_NOTES_MODAL_NAME } from './Modals/EditNotesModal/editNotesModal.const';

export const useOverviewTabPage = () => {
  const LoadOrganization = useActions(loadOrganization);
  const organization = useSelector(organizationSelector);
  const UpdateModalState = useActions(updateModalState);

  const addOrganizationContact = () => {
    UpdateModalState(ADD_POINT_OF_CONTACT_MODAL);
  };

  const editOrganizationPointOfContactHandler = (id: string) => {
    const details: EditContactModalDetails = {
      contactId: id,
    };
    UpdateModalState([EDIT_CONTACT_MODAL_NAME, details]);
  };

  const deleteOrganizationPointOfContact = (id: string) => {
    const details: DeleteOrganizationPointOfContactModalDetails = {
      contactId: id,
    };
    UpdateModalState([
      DELETE_ORGANIZATION_POINT_OF_CONTACT_MODAL_NAME,
      details,
    ]);
  };

  const addGGContact = () => {
    UpdateModalState(ADD_GG_CONTACT_MODAL_NAME);
  };

  const editGGPointOfContactHandler = (id: string) => {
    const details: EditGGPointOfContactModalDetails = {
      contactId: id,
    };
    UpdateModalState([EDIT_GG_POINT_OF_CONTACT_MODAL_NAME, details]);
  };

  const deleteGGPointOfContact = (id: string) => {
    const details: DeleteGGPointOfContactModalDetails = {
      contactId: id,
    };
    UpdateModalState([DELETE_GG_POINT_OF_CONTACT_MODAL_NAME, details]);
  };

  const deleteOrganizationHandler = () => {
    UpdateModalState(DELETE_ORGANIZATION_MODAL_NAME);
  };

  const editClientDetailsHandler = () => {
    UpdateModalState(EDIT_CLIENT_DETAILS_MODAL);
  };

  const onEditOrganizationNotes = () => {
    const details: EditOrganizationNotesModalDetails = {
      organizationId: organization.id,
      notes: organization?.notes,
    };
    UpdateModalState([EDIT_ORG_NOTES_MODAL_NAME, details]);
  };

  const onAddOrganizationNotes = () => {
    const details: AddOrganizationNotesModalDetails = {
      organizationId: organization.id,
    };
    UpdateModalState([ADD_ORG_NOTES_MODAL_NAME, details]);
  };

  const loadOrganizationHandler = useCallback(() => {
    LoadOrganization(organization.id);
  }, [LoadOrganization, organization.id]);

  return {
    deleteGGPointOfContact,
    editGGPointOfContactHandler,
    addGGContact,
    deleteOrganizationPointOfContact,
    editOrganizationPointOfContactHandler,
    addOrganizationContact,
    organization,
    deleteOrganizationHandler,
    editClientDetailsHandler,
    onEditOrganizationNotes,
    onAddOrganizationNotes,
    loadOrganizationHandler,
  };
};
