import styled from '@emotion/styled';

import { Card } from '../../../../../../styledComponents/UI/Card/Card';
import { Line } from '../../../../../../styledComponents/UI/Line/Line';

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export const StyledLine = styled(Line)`
  margin: 2rem 0;
`;
