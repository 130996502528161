import { css } from '@emotion/react';

export const GlobalFonts = css`
  @font-face {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamBook.otf');
  }

  @font-face {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamMedium.otf');
  }

  @font-face {
    font-family: 'Gotham';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamBold.otf');
  }

  @font-face {
    font-family: 'Gotham';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamLight.otf');
  }

  @font-face {
    font-family: 'GothamXNarr';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamXNarrBook.otf');
  }

  @font-face {
    font-family: 'GothamXNarr';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamXNarrMedium.otf');
  }

  @font-face {
    font-family: 'GothamXNarr';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamXNarrBold.otf');
  }

  @font-face {
    font-family: 'GothamXNarr';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/gothamXNarrLight.otf');
  }
`;
