import styled from '@emotion/styled/macro';

import { SectionSubTitleBold } from '../../../../../styledComponents/Typography/Typography';
import Button from '../../../../../styledComponents/UI/Button/Button';

export const SubscriptionsNotFoundWrapper = styled.div`
  padding: 3rem 2.5rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleNotFound = styled(SectionSubTitleBold)`
  margin: 2rem 0 2.5rem 0;
`;

export const StyledButton = styled(Button)`
  padding: 0.75rem 1rem;
`;
