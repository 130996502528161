/* eslint-disable react/no-array-index-key */
import { t } from 'i18next';
import { Field } from 'react-final-form';

import { MultiInputType } from '../../../../../../../models/enums/MultiInputType';

import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { FormSelect } from '../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { FilterDefinition } from '../../../renewalFilters.models';
import { FilterInputContainer, FilterRow } from '../../filtersForm.styles';
import {
  SelectFilterFields,
  SelectFilterOptionsModel,
} from './selectFilter.models';

type SelectFilterProp = CustomComponent & {
  filterDefinition: FilterDefinition<SelectFilterOptionsModel>;
};

const SelectFilter = ({ filterDefinition }: SelectFilterProp) => (
  <FilterRow>
    <FilterInputContainer>
      <Field
        component={FormSelect}
        isMulti
        isCheckAllOption={filterDefinition.filterOptions?.checkAllButton}
        multiInputType={MultiInputType.InputSelect}
        options={filterDefinition.filterOptions?.options}
        label={t(`filters.${filterDefinition.field}`)}
        name={SelectFilterFields.Value}
        valueRenderer={filterDefinition?.filterOptions?.valueRenderer}
      />
    </FilterInputContainer>
  </FilterRow>
);

export default SelectFilter;
