import { AvailableQueryParams } from '../materialTable.models';

export const DefaultRowsPerPageOptions = [10, 20, 50, 100];

export const DefaultRequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];
