import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormDatePicker from '../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { FormSelect } from '../../../../../styledComponents/UI/Form/FormSelect/FormSelect';

import ReportsGenerateButton from '../../../../../styledComponents/UI/ReportsGenerateButton/ReportsGenerateButton';
import {
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_SHORT_DATE_MASK,
} from '../../../../../utils/dateHelpers';
import { useRetailDataReports } from './retailDataReportsForm.hooks';
import { RetailDataReportFields } from './retailDataReportsForm.models';
import { Wrapper } from './retailDataReportsForm.styles';
import { validateRetailReportSchemaForm } from './retailDataReportsForm.validation';

const RetailDataReportsForm = () => {
  const { t } = useTranslation();
  const { generateRetailDataReportFile, filteredRetailReportTypeOptions } =
    useRetailDataReports();

  return (
    <div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={generateRetailDataReportFile}
        validate={validateRetailReportSchemaForm}
      >
        {({ dirty, values, ...renderProps }) => (
          <Wrapper onSubmit={renderProps.handleSubmit}>
            <Field
              component={FormSelect}
              name={RetailDataReportFields.ReportType}
              inputId={`${RetailDataReportFields.ReportType}-input`}
              options={filteredRetailReportTypeOptions}
            />
            <Field
              label={t('fields.month.label')}
              component={FormDatePicker}
              name={RetailDataReportFields.Date}
              views={['month', 'year']}
              formatMask={DEFAULT_SHORT_DATE_FORMAT}
              mask={DEFAULT_SHORT_DATE_MASK}
              inputId={`${RetailDataReportFields.Date}-input`}
            />
            <ReportsGenerateButton />
          </Wrapper>
        )}
      </Form>
    </div>
  );
};

export default RetailDataReportsForm;
