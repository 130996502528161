import { useTranslation } from 'react-i18next';

import { MainSelect } from '../../../../styledComponents/UI/MainSelect/MainSelect';
import {
  FiltersWrapper,
  InputContainer,
  SearchInputContainer,
} from '../../../../styledComponents/SimplePage/Filters';
import { useCorporateClientGeneralFilters } from './corporateClientGeneralFilters.hooks';
import IconInput from '../../../../styledComponents/UI/IconInput/IconInput';
import {
  formatOptionLabel,
  organizationStatusOptions,
  organizationTypeOptions,
  productOptions,
} from './corporateClientsGeneralFilters.utils';

const CorporateClientGeneralFilters = () => {
  const { t } = useTranslation();
  const { filters, onFilterChangeHandler } = useCorporateClientGeneralFilters();

  return (
    <FiltersWrapper>
      <SearchInputContainer>
        <IconInput
          defaultIcon="search"
          onChange={(evt) => onFilterChangeHandler(evt, 'search')}
          placeholder={t('filters.search-by')}
          value={filters.search}
        />
      </SearchInputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.status}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'status')}
          options={organizationStatusOptions}
          placeholder={t('filters.status')}
          formatOptionLabel={formatOptionLabel}
        />
      </InputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.product}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'product')}
          options={productOptions}
          placeholder={t('filters.product')}
        />
      </InputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.type}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'type')}
          options={organizationTypeOptions}
          placeholder={t('filters.type')}
        />
      </InputContainer>
    </FiltersWrapper>
  );
};

export default CorporateClientGeneralFilters;
