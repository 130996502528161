import { createSelector } from 'reselect';

import { IPromoCodesState } from '.';
import { PromoCodeType } from '../../../models/enums/PromoCodeType';
import { ICreatePromoCodeSuggestion } from '../../../models/interfaces/CreatePromoCodeSuggestion';
import { IPageListModel } from '../../../models/PageListModel';
import { IPromoCodeItem } from '../../../models/PromoCodeItem';
import { IPromoCodeOverview } from '../../../models/PromoCodeOverview';
import { IOrganizationSearch } from '../../../models/interfaces/SearchOrganization';

import { RootState } from '../index';

export const promoCodesSelector = (state: RootState) => state.promoCodes;

export const promoCodesListSelector = createSelector(
  promoCodesSelector,
  (promoCodes: IPromoCodesState): IPageListModel<IPromoCodeItem> =>
    promoCodes.promoCodes,
);

export const promoCodeOverviewSelector = createSelector(
  promoCodesSelector,
  (promoCodes: IPromoCodesState): IPromoCodeOverview => promoCodes.promoCode,
);

export const promoCodeSuggestionSelector = createSelector(
  promoCodesSelector,
  (promoCodes: IPromoCodesState): ICreatePromoCodeSuggestion =>
    promoCodes.promoCodeSuggestion,
);

export const promoCodeCreateInitSelector = createSelector(
  promoCodeSuggestionSelector,
  (
    promoCodeSuggestion: ICreatePromoCodeSuggestion,
  ): { owner: IOrganizationSearch; type: PromoCodeType } =>
    promoCodeSuggestion && {
      owner: {
        name: promoCodeSuggestion.ownerName,
        organizationId: promoCodeSuggestion.ownerId,
        subscriptions: [],
      },
      type: promoCodeSuggestion.promoCodeType,
    },
);
