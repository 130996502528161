import { Route, Routes } from 'react-router';

import { RoutePath } from '../../models/enums/RoutePath';
import WizardWrapper from '../../styledComponents/Wizard/WizardWrapper';
import AddMemberMembershipPage from './AddMemberWizardPages/AddMemberMembershipPage/AddMemberMembershipPage';
import AddMemberPaymentPage from './AddMemberWizardPages/AddMemberPaymentPage/AddMemberPaymentPage';
import AddMemberPrimaryMemberPage from './AddMemberWizardPages/AddMemberPrimaryMemberPage/AddMemberPrimaryMemberPage';
const AddMemberRoutes = () => (
  <Routes>
    <Route path={RoutePath.Wizard} element={<WizardWrapper />}>
      <Route
        path={RoutePath.PrimaryMemberPage}
        element={<AddMemberPrimaryMemberPage />}
      />
      <Route
        path={RoutePath.MembershipPage}
        element={<AddMemberMembershipPage />}
      />
      <Route path={RoutePath.PaymentPage} element={<AddMemberPaymentPage />} />
    </Route>
  </Routes>
);

export default AddMemberRoutes;
