import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../hooks/useActions';
import { useModalDetails } from '../../../hooks/useModalDetails';
import { useProcessing } from '../../../hooks/useProcessing';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../store/redux-slices/modals';
import { ORGANIZATION_CONTRACT_DELETING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { deleteOrganizationContract } from '../../../store/saga-slices/contracts';
import { IDeleteOrganizationContractPayload } from '../../../store/saga-slices/contracts/models';
import { DELETE_CONTRACT_MODAL_NAME } from './deleteContractModal.const';
import { DeleteContractModalDetails } from './deleteContractModal.models';

interface IUseDeleteContractModalProps {
  onContractDeleted?: () => void;
}

export const useDeleteContractModal = ({
  onContractDeleted,
}: IUseDeleteContractModalProps) => {
  const cancellationToken = useCancellationToken();
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);
  const DeleteOrganizationContract = useActions(deleteOrganizationContract);

  const {
    contractName,
    contractId,
    organizationId,
  }: DeleteContractModalDetails = useModalDetails(DELETE_CONTRACT_MODAL_NAME);

  const isProcessing = useProcessing(ORGANIZATION_CONTRACT_DELETING_PROCESSING);

  const onBackHandler = useCallback(() => {
    UpdateModalState(DELETE_CONTRACT_MODAL_NAME);
  }, [UpdateModalState]);

  const handleSubmit = useCallback(() => {
    const payload: IDeleteOrganizationContractPayload = {
      organizationId,
      contractId,
      cancellationToken,
      success: () => {
        alert.success(t('alerts.contract-deleted-success'));
        onBackHandler();
        onContractDeleted?.();
      },
      error: () => {
        alert.error(t('alerts.contract-deleted-fail'));
      },
    };
    DeleteOrganizationContract(payload);
  }, [
    DeleteOrganizationContract,
    alert,
    cancellationToken,
    contractId,
    onBackHandler,
    onContractDeleted,
    organizationId,
    t,
  ]);

  return {
    onBackHandler,
    contractName,
    handleSubmit,
    isProcessing,
  };
};
