import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../ModalFormPage/ModalFormPage';
import { withErrorProvider } from '../../../../hoc/withErrorProvider';
import { useEditContractRenewalStatus } from './editRenewalContractStatusModalForm.hooks';
import { EditRenewalContractStatusModalOverview } from '../EditRenewalContractStatusModalOverview/EditRenewalContractStatusModalOverview';
import RenewalStatusForm from '../../../Forms/RenewalStatusForm/renewalStatusForm';

type EditRenewalContractStatusModalFormProps = {
  onRenewalStatusEdited?: () => void;
};

const EditRenewalContractStatusModalForm = ({
  onRenewalStatusEdited,
}: EditRenewalContractStatusModalFormProps) => {
  const { t } = useTranslation();
  const {
    closeModalHandler,
    handleSubmit,
    isProcessing,
    initialValues,
    organizationName,
    contractName,
    expirationDate,
    validateData,
  } = useEditContractRenewalStatus({ onRenewalStatusEdited });
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateData}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          title={t('headers.contract-renewal-status')}
          onCancel={closeModalHandler}
          isProcessing={isProcessing}
          mode="small"
        >
          <EditRenewalContractStatusModalOverview
            organizationName={organizationName}
            contractName={contractName}
            expirationDate={expirationDate}
          />
          <RenewalStatusForm disabled={isProcessing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withErrorProvider(EditRenewalContractStatusModalForm);
