import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../hooks/useProcessing';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import {
  ClientOverviewGeneralPageParams,
  ContractOverviewPageParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { IRenewalContractRecord } from '../../../models/interfaces/RenewalFilters/RenewalContractRecord';
import { IRenewalsFiltersModel } from '../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';
import { updateModalState } from '../../../store/redux-slices/modals';
import {
  RENEWAL_CONTRACTS_EXPORT_PROCESSING,
  RENEWAL_CONTRACTS_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { renewalContractsSelector } from '../../../store/redux-slices/renewalFilter/selectors';
import {
  exportRenewalContracts,
  loadRenewalContracts,
} from '../../../store/saga-slices/renewalFilter';
import {
  IExportRenewalContractsPayload,
  ILoadRenewalContractsPayload,
} from '../../../store/saga-slices/renewalFilter/models';
import { EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME } from '../../../styledComponents/Modals/EditRenewalContractStatusModal/editRenewalContractStatusModal.const';
import { EditRenewalContractStatusModalDetails } from '../../../styledComponents/Modals/EditRenewalContractStatusModal/editRenewalContractStatusModal.models';
import { useTablesSort } from '../Common/RenewalsTables/renewalsTables.hooks';
import { initFilter } from './renewalContractsPage.const';

export const useRenewalsContractsPage = () => {
  const navigate = useNavigateSearch();

  const LoadContracts = useActions(loadRenewalContracts);
  const ExportRenewalContracts = useActions(exportRenewalContracts);
  const cancellationToken = useCancellationToken();

  const UpdateModalState = useActions(updateModalState);

  const dataLoading = useProcessing(RENEWAL_CONTRACTS_LOADING_PROCESSING);
  const dataExporting = useProcessing(RENEWAL_CONTRACTS_EXPORT_PROCESSING);

  const data = useSelector(renewalContractsSelector);
  const [currentFilters, setCurrentFilters] =
    useState<IRenewalsFiltersModel>(initFilter);

  const { order, orderBy } = useTablesSort();

  const loadContractsHandler = useCallback(() => {
    const filters = currentFilters;
    if (order && orderBy) {
      filters.order = order;
      filters.orderBy = orderBy;
    }
    const payload: ILoadRenewalContractsPayload = {
      cancellationToken,
      filters,
    };

    LoadContracts(payload);
  }, [LoadContracts, cancellationToken, currentFilters, order, orderBy]);

  useEffect(() => {
    loadContractsHandler();
  }, [loadContractsHandler]);

  const filterChangedHandler = useCallback(
    (filters?: IRenewalsFiltersModel) => {
      setCurrentFilters(filters);
    },
    [],
  );

  const onEditRenewalStatusHandler = (row: IRenewalContractRecord) => {
    const details: EditRenewalContractStatusModalDetails = {
      contractId: row.contractId,
      organizationId: row.organizationId,
      renewalStatus: row.renewalStatus,
      contractName: row.contractName,
      organizationName: row.organizationName,
      expirationDate: row.expirationDate,
    };
    UpdateModalState([EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME, details]);
  };

  const onCellClickHandler = (
    row: IRenewalContractRecord,
    key: keyof IRenewalContractRecord,
  ) => {
    switch (key) {
      case 'renewalStatus': {
        onEditRenewalStatusHandler(row);
        return;
      }
      case 'organizationName': {
        navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
          params: {
            [ClientOverviewGeneralPageParams.OrganizationId]:
              row?.organizationId,
          },
        });
      }
    }
  };

  const onRowClickHandler = (row: IRenewalContractRecord) => {
    navigate(RoutePath.ClientOverviewContractOverviewPageFullPath, {
      params: {
        [ContractOverviewPageParams.OrganizationId]: row?.organizationId,
        [ContractOverviewPageParams.ContractId]: row?.contractId,
      },
    });
  };

  const exportRenewalContractsHandler = useCallback(
    (selectedCurrentFilters: IRenewalsFiltersModel) => {
      const filters = selectedCurrentFilters;
      if (order && orderBy) {
        filters.order = order;
        filters.orderBy = orderBy;
      }
      const payload: IExportRenewalContractsPayload = {
        cancellationToken,
        filters,
      };

      ExportRenewalContracts(payload);
    },
    [ExportRenewalContracts, cancellationToken, order, orderBy],
  );

  return {
    loadContractsHandler,
    dataLoading,
    data,
    filterChangedHandler,
    onCellClickHandler,
    onRowClickHandler,
    exportRenewalContractsHandler,
    dataExporting,
  };
};
