import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { StyledTextarea } from './formTextarea.styles';
import { useFormTextarea } from './formTextarea.hooks';
import { useFormField } from '../../../../hooks/useFormField';
import { ITextareaProps } from '../../Textarea/Textarea';

export interface IFormTextareaProps
  extends FieldRenderProps<string>,
    ITextareaProps {
  onBlur?: () => void;
}

export const FormTextarea = ({
  className,
  disabled,
  input,
  inputId,
  label,
  meta,
  labelAnimation = true,
  onBlur,
  maxCharsCount,
  ...props
}: IFormTextareaProps) => {
  const { onBlurHandler, preparedPlaceholder, preparedLabel } = useFormField({
    input,
    onBlur,
    labelAnimation,
    placeholder: props.placeholder,
    label,
  });

  const { onChangeInput, inputValue, onClearInput } = useFormTextarea({
    input,
    format: props.formatValue,
    parse: props.parseValue,
  });

  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledTextarea
        {...props}
        onClear={onClearInput}
        name={input.name}
        error={meta.error && meta.touched}
        disabled={disabled}
        placeholder={preparedPlaceholder}
        {...input}
        labelAnimation={labelAnimation}
        onChange={onChangeInput}
        onBlur={onBlurHandler as any}
        label={preparedLabel}
        value={inputValue}
        maxLength={props.maxLength}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
