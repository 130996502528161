import { useTranslation } from 'react-i18next';

import { OnChange } from 'react-final-form-listeners';

import { CustomForm } from '../../../../models/types/CustomForm';
import { FormSectionTitle, SectionWrapper } from '../../../Form/Form';
import FormCheckboxGroup from '../../../UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import { ContractOptionsFormFields } from './contractOptions.models';
import { contractOptions } from './contractOptionsForm.utils';
import { useContractOptionsForm } from './contractOptionsForm.hooks';

type ContractOptionsFormProps = CustomForm & {
  editableFields?: Partial<Record<ContractOptionsFormFields, boolean>>;
};

const ContractOptionsForm = ({
  disabled,
  className,
  editableFields,
  name,
}: ContractOptionsFormProps) => {
  const { t } = useTranslation();
  const { onDateChange } = useContractOptionsForm();

  return (
    <SectionWrapper className={className}>
      <FormSectionTitle>{t('headers.options')}</FormSectionTitle>
      <FormColumn>
        <OnChange name="expirationDate">{onDateChange}</OnChange>
        <OnChange name="startDate">{onDateChange}</OnChange>
        <FormCheckboxGroup
          disabled={disabled}
          namePrefix={name}
          options={contractOptions(editableFields)}
        />
      </FormColumn>
    </SectionWrapper>
  );
};

export default ContractOptionsForm;
