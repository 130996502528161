import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { IInputProps } from '../../Input/Input';
import { StyledFormInput } from './formInput.styles';
import { useFormInput } from './formInput.hooks';
import { useFormField } from '../../../../hooks/useFormField';

export interface IFormInputProps extends FieldRenderProps<string>, IInputProps {
  onBlur?: () => void;
}

export const FormInput = ({
  className,
  disabled,
  input,
  inputId,
  label,
  meta,
  labelAnimation = true,
  onBlur,
  ...props
}: IFormInputProps) => {
  const { onBlurHandler, preparedPlaceholder, preparedLabel } = useFormField({
    input,
    onBlur,
    labelAnimation,
    placeholder: props.placeholder,
    label,
  });

  const { onChangeInput, inputValue } = useFormInput({
    input,
    format: props.formatValue,
    parse: props.parseValue,
  });

  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledFormInput
        {...props}
        name={input.name}
        error={meta.error && meta.touched}
        disabled={disabled}
        placeholder={preparedPlaceholder}
        {...input}
        labelAnimation={labelAnimation}
        onChange={onChangeInput}
        onBlur={onBlurHandler}
        label={preparedLabel}
        value={inputValue}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
