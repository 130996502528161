import CorporateClientGeneralTools from './CorporateClientGeneralTools/CorporateClientGeneralTools';
import { SimplePageLine } from '../../../styledComponents/SimplePage/SimplePageLine';
import CorporateClientGeneralTable from './CorporateClientGeneralTable/CorporateClientGeneralTable';
import { useCorporateClientsGeneralPage } from './corporateClientsGeneralPage.hooks';
import { canViewOrganizationDetailsPagePermissions } from '../../../config/accessPolicies/accessPolicies';

const CorporateClientsGeneralPage = () => {
  const { dataLoading, onRowClick, organizations, organizationsTotalCount } =
    useCorporateClientsGeneralPage();
  return (
    <div>
      <CorporateClientGeneralTools />
      <SimplePageLine />
      <CorporateClientGeneralTable
        data={organizations}
        dataLoading={dataLoading}
        totalCount={organizationsTotalCount}
        onRowClick={onRowClick}
        canViewDetailsPermissions={canViewOrganizationDetailsPagePermissions}
      />
    </div>
  );
};

export default CorporateClientsGeneralPage;
