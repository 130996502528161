import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';

import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import { RoutePath } from '../../../../models/enums/RoutePath';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
} from '../../../../store/redux-slices/processes/constants';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { IMemberInfoModel } from '../../../../models/MemberInfoModel';
import { personNameSelector } from '../../../../store/redux-slices/people/selectors';
import {
  changeKEMemberRenewFlag,
  loadKEMembershipDetails,
} from '../../../../store/saga-slices/membership/kidnapAndExtortion';
import { keMembershipDetailsByIdSelector } from '../../../../store/redux-slices/membership/kidnapAndExtortion/selectors';
import {
  IChangeKEMemberRenewFlagPayload,
  ILoadKEMembershipDetailsPayload,
} from '../../../../store/saga-slices/membership/kidnapAndExtortion/models';
import { IKEMembershipOptions } from '../../../../models/interfaces/Membership/KidnapAndExtortion/KEMembershipModel';
import { EDIT_KE_MEMBERSHIP_OPTIONS_MODAL } from '../Modals/EditKEMembershipOptionsModal/editKEMembershipOptionsModal.const';
import { RESEND_KE_WELCOME_EMAIL_MODAL_NAME } from '../Modals/ResendKEWelcomeEmailModal/resendKEWelcomeEmailModal.const';
import { DELETE_KE_FAMILY_MEMBER_MODAL_NAME } from '../Modals/DeleteKEFamilyMemberModal/deleteKEFamilyMemberModal.const';
import { DeleteKEFamilyMemberModalDetails } from '../Modals/DeleteKEFamilyMemberModal/deleteKEFamilyMemberModal.models';
import { EditKEMembershipOptionsModalDetails } from '../Modals/EditKEMembershipOptionsModal/editKEMembershipOptionsModal.models';
import { ResendKEWelcomeEmailModalDetails } from '../Modals/ResendKEWelcomeEmailModal/resendKEWelcomeEmailModal.models';
import { DeleteKEMembershipModalDetails } from '../Modals/DeleteKEMembershipModal/deleteKEMembershipModal.models';
import { ProductType } from '../../../../models/ProductType';
import { DELETE_KE_MEMBERSHIP_MODAL_NAME } from '../Modals/DeleteKEMembershipModal/deleteKEMembershipModal.const';
import { MemberOverviewPageParamsType } from '../../../../models/types/RoutePath';

export const useKEMembershipDetails = (
  membershipId?: string,
  productType?: ProductType,
) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigateSearch();

  const LoadMembershipDetails = useActions(loadKEMembershipDetails);
  const ChangeMemberRenewFlag = useActions(changeKEMemberRenewFlag);
  const UpdateModalState = useActions(updateModalState);

  const dataLoading = useProcessing([
    LOAD_MEMBERSHIP_DETAILS + membershipId,
    CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
  ]);
  const membershipDetails = useSelector(
    keMembershipDetailsByIdSelector(membershipId),
  );
  const personName = useSelector(personNameSelector);

  const canBeRenewed = membershipDetails?.metadata?.canRenew;

  const getMembershipDetails = useCallback(() => {
    const payload: ILoadKEMembershipDetailsPayload = {
      membershipId,
    };
    LoadMembershipDetails(payload);
  }, [LoadMembershipDetails, membershipId]);

  useEffect(() => {
    if (membershipDetails) {
      return;
    }
    getMembershipDetails();
  }, [getMembershipDetails, membershipDetails]);

  const deleteFamilyMemberHandler = useCallback(
    (member: IMemberInfoModel) => {
      const details: DeleteKEFamilyMemberModalDetails = {
        member,
        membershipId,
      };
      UpdateModalState([
        DELETE_KE_FAMILY_MEMBER_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const deleteMembershipHandler = useCallback(() => {
    const details: DeleteKEMembershipModalDetails = {
      membershipId,
      productType,
      personName,
    };
    UpdateModalState([DELETE_KE_MEMBERSHIP_MODAL_NAME + membershipId, details]);
  }, [UpdateModalState, membershipId, personName, productType]);

  const changeRenewFlagHandler = useCallback(
    (memberId: string, toBeRenewed: boolean) => {
      const payload: IChangeKEMemberRenewFlagPayload = {
        membershipId,
        memberId,
        toBeRenewed,
        success: () => {
          getMembershipDetails();

          alert.success(t('alerts.renew-flag-updated'));
        },
      };
      ChangeMemberRenewFlag(payload);
    },
    [ChangeMemberRenewFlag, alert, getMembershipDetails, membershipId, t],
  );

  const resendWelcomeEmailHandler = useCallback(
    (personId: string) => {
      const details: ResendKEWelcomeEmailModalDetails = {
        membershipId,
        personId,
      };
      UpdateModalState([
        RESEND_KE_WELCOME_EMAIL_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberInfoModel) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, {
        params,
      });
    },
    [navigate],
  );

  const onEditMembershipOptionsHandler = useCallback(
    (membershipOptions: IKEMembershipOptions) => {
      const details: EditKEMembershipOptionsModalDetails = {
        membershipId,
        membershipOptions,
      };
      UpdateModalState([
        EDIT_KE_MEMBERSHIP_OPTIONS_MODAL + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  return {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    deleteFamilyMemberHandler,
    canBeRenewed,
    resendWelcomeEmailHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  };
};
