import styled from '@emotion/styled';

import { SectionTitle } from '../../Typography/Typography';
import FormMessage from '../../UI/Form/FormMessage/FormMessage';

export const Wrapper = styled.div`
  padding: 0 40px;
  width: 100%;
`;

export const ContractSectionTitle = styled(SectionTitle)`
  margin-bottom: 1rem;
`;

export const StyledFormMessage = styled(FormMessage)`
  margin-top: 1rem;
`;
