import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLeftContainer,
  StyledRightContainer,
  DetailsWrapper,
  StyledDeleteButton,
} from '../../Common/memberOverviewMembers.styles';
import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  canAddKEMembershipFamilyMemberPermissions,
  canEditKEMembershipsOptionsPermissions,
  canRemoveKEMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { ProductType } from '../../../../models/ProductType';
import { useKEMembershipDetails } from '../Common/keMembershipOverview.hooks';
import { useKEMembershipActiveDetails } from './keMembershipActiveDetails.hooks';
import { StyledButton } from './keMembershipActiveDetails.styles';
import AddKEFamilyMemberModal from '../Modals/AddKEFamilyMemberModal/AddKEFamilyMemberModal';
import EditKEMembershipOptionsModal from '../Modals/EditKEMembershipOptionsModal/EditKEMembershipOptionsModal';
import DeleteKEFamilyMemberModal from '../Modals/DeleteKEFamilyMemberModal/DeleteKEFamilyMemberModal';
import ResendKEWelcomeEmailModal from '../Modals/ResendKEWelcomeEmailModal/ResendKEWelcomeEmailModal';
import DeleteKEMembershipModal from '../Modals/DeleteKEMembershipModal/DeleteKEMembershipModal';
import { keOverviewTablePermissions } from '../Common/keMembershipOverview.const';
import { KidnapAndExtortionMembershipOptions } from '../../../../models/enums/KidnapAndExtortionMembershipOptions';

interface IKEMembershipActiveDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const KEMembershipActiveDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: IKEMembershipActiveDetailsProps) => {
  const { t } = useTranslation();

  const {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    resendWelcomeEmailHandler,
    deleteFamilyMemberHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useKEMembershipDetails(membershipId, productType);

  const { addFamilyMemberHandler } = useKEMembershipActiveDetails({
    membershipId,
    canAddMemberTypes: membershipDetails?.metadata?.canAddMemberTypes,
  });

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }
  return (
    <>
      <AddKEFamilyMemberModal
        id={membershipId}
        onFamilyMemberAdded={getMembershipDetails}
      />
      <DeleteKEFamilyMemberModal
        id={membershipId}
        onFamilyMemberRemoved={getMembershipDetails}
      />
      <EditKEMembershipOptionsModal id={membershipId} />
      <DeleteKEMembershipModal id={membershipId} />
      <ResendKEWelcomeEmailModal id={membershipId} />
      <DetailsWrapper>
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            onResendEmailClick={resendWelcomeEmailHandler}
            onDeleteMember={deleteFamilyMemberHandler}
            onRowClick={onRowClickHandler}
            isExpiredMembershipsTable={false}
            permissions={keOverviewTablePermissions}
          />
          {membershipDetails?.metadata?.canAddMemberTypes.length > 0 && (
            <StyledButton
              permissions={canAddKEMembershipFamilyMemberPermissions}
              onClick={addFamilyMemberHandler}
              styleType="link"
            >
              {t('buttons.add-family-member')}
            </StyledButton>
          )}
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditKEMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: KidnapAndExtortionMembershipOptions.SendWelcomeEmail },
              { value: KidnapAndExtortionMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            canBeRenewed={false}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveKEMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default KEMembershipActiveDetails;
