import { SimplePageLine } from '../../SimplePage/SimplePageLine';

import MembersGeneralListTable, {
  IMembersGeneralListTableProps,
} from './MembersGeneralListTable/MembersGeneralListTable';
import MembersGeneralListTools from './MembersGeneralListTools/MembersGeneralListTools';

type IGeneralMembersListProps = {
  tableProps: IMembersGeneralListTableProps;
};

const GeneralMembersList = ({ tableProps }: IGeneralMembersListProps) => (
  <>
    <MembersGeneralListTools />
    <SimplePageLine />
    <MembersGeneralListTable {...tableProps} />
  </>
);

export default GeneralMembersList;
