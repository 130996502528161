/* eslint-disable react/no-array-index-key */
import TableCell from '@mui/material/TableCell';

import { IMaterialColumn } from '../materialTable.models';
import { StyledSummaryTableRow } from './summaryRow.styles';

type SummaryRowProps = {
  columns: IMaterialColumn[];
  summaryRow?: any;
  onRowClick?: () => void;
};

const SummaryRow = ({ columns, summaryRow, onRowClick }: SummaryRowProps) => (
  <StyledSummaryTableRow $isClickable={!!onRowClick} onClick={onRowClick}>
    {columns.map((column, index) => (
      <TableCell align={column.alignment} key={column.title + index}>
        {summaryRow[column.field]}
      </TableCell>
    ))}
  </StyledSummaryTableRow>
);

export default SummaryRow;
