import styled from '@emotion/styled/macro';

import { FormFieldWrapper } from '../../../Form/Form';
import { FieldLabel } from '../../Field/Field';

export const Wrapper = styled(FormFieldWrapper)`
  width: 8.2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 3rem;
`;

export const Value = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.black};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledFieldLabel = styled(FieldLabel)`
  left: 0;
  white-space: nowrap;
`;
