import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SectionWrapper } from '../../../../../styledComponents/Form/Form';
import { SmallModal } from '../../../../../styledComponents/ModalCommon/ModalCommon';
import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';
import FormCheckboxGroup from '../../../../../styledComponents/UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import {
  EDIT_SR_MEMBERSHIP_OPTIONS_MODAL,
  srMembershipOptionsList,
} from './editSRMembershipOptionsModal.const';
import { useEditSRMembershipOptionsModal } from './editSRMembershipOptionsModal.hooks';

interface IEditSRMembershipOptionsModalProps {
  onModalCancel?: () => void;
  onMembershipOptionsUpdated?: () => void;
  id?: string;
}

const EditSRMembershipOptionsModal = ({
  onModalCancel,
  onMembershipOptionsUpdated,
  id,
}: IEditSRMembershipOptionsModalProps) => {
  const { t } = useTranslation();
  const {
    updateMembershipOptionsHandler,
    membershipOptions,
    isProcessing,
    onCloseHandler,
  } = useEditSRMembershipOptionsModal({ id });

  return (
    <SmallModal
      disabled={isProcessing}
      name={EDIT_SR_MEMBERSHIP_OPTIONS_MODAL + id}
      onModalClose={onModalCancel}
    >
      <Form
        keepDirtyOnReinitialize
        onSubmit={updateMembershipOptionsHandler}
        initialValues={membershipOptions}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            mode="small"
            handleSubmit={renderProps.handleSubmit}
            isProcessing={isProcessing}
            onCancel={onCloseHandler}
            title={t('headers.edit-options')}
          >
            <SectionWrapper>
              <FormCheckboxGroup
                layout="column"
                options={srMembershipOptionsList}
              />
            </SectionWrapper>
          </ModalFormPage>
        )}
      </Form>
    </SmallModal>
  );
};

export default EditSRMembershipOptionsModal;
