import NotFoundImg from '../../../../../assets/images/contracts-not-found.png';
import { Wrapper, Text } from './notFoundData.styles';

type NotFoundDataProps = {
  label?: string;
};

const NotFoundData = ({ label }: NotFoundDataProps) => (
  <Wrapper>
    <img src={NotFoundImg} alt="not-found" />
    <Text>{label}</Text>
  </Wrapper>
);

export default NotFoundData;
