/* eslint-disable react/destructuring-assignment */
import { t } from 'i18next';

import { ShortcutProductType } from '../../../../../models/enums/ShortcutProductType';
import { IRenewalContractRecord } from '../../../../../models/interfaces/RenewalFilters/RenewalContractRecord';

import {
  DateRenderer,
  NameRenderer,
} from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { EditableStatusRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/EditableStatusRenderer/EditableStatusRenderer';
import {
  StyledNameRenderer,
  StyledNameRendererLong,
  StyledProductsRenderer,
  StyledSingleItemWithNumberRenderer,
} from './renewalContractsTable.styles';

export const renewalContractTableCellRenderer =
  (
    key: keyof IRenewalContractRecord,
    options?: ICellOptions,
    onEditRenewalStatus?: (row: IRenewalContractRecord) => void,
  ) =>
  (dataRow: IRenewalContractRecord) => {
    switch (key) {
      case 'organizationName':
        return <StyledNameRenderer value={dataRow[key]} options={options} />;
      case 'contractName':
        return (
          <StyledNameRendererLong value={dataRow[key]} options={options} />
        );
      case 'contractType':
        return (
          <NameRenderer
            value={t(`enums.contract-type-shortcut.${dataRow[key]}`)}
            options={options}
          />
        );
      case 'productAbbreviations':
        return (
          <StyledProductsRenderer
            data={dataRow[key] as ShortcutProductType[]}
            options={options}
          />
        );
      case 'accountManagers':
        return (
          <StyledSingleItemWithNumberRenderer
            items={dataRow[key]}
            options={options}
          />
        );
      case 'bdRepresentatives':
        return (
          <StyledSingleItemWithNumberRenderer
            items={dataRow[key]}
            options={options}
          />
        );
      case 'expirationDate':
        return <DateRenderer date={dataRow[key]} options={options} />;
      case 'renewalStatus':
        return (
          <EditableStatusRenderer
            enumName="renewal-status"
            status={dataRow[key]}
            options={options}
          />
        );
      default:
        return null;
    }
  };
