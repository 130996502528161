import { MemberType } from '../../../../models/enums/MemberType';
import { RenewStatus } from '../../../../models/RenewStatus';
import { getOptionsFromEnum } from '../../../../utils/getOptionsFromEnum';
import { Status } from '../../../UI/Status/Status';

export const membersTypesOptions = getOptionsFromEnum(
  MemberType,
  'member-type',
);

export const renewStatusOptions = Object.values(RenewStatus).map((type) => ({
  label: <Status status={type} enumName="renew-status" />,
  value: (type === RenewStatus.Yes).toString(),
}));
