import { useCallback } from 'react';

import { useFilters } from '../../../../hooks/useFilters';
import { SelectOption } from '../../../../models/SelectOption';

import { MembersGeneralListFiltersType } from './membersGeneralListFilters.models';

export const useMembersFilters = () => {
  const { addFilter, filters } = useFilters<MembersGeneralListFiltersType>();

  const onFilterChangeHandler = useCallback(
    (evt: any, filterType: MembersGeneralListFiltersType) => {
      switch (filterType) {
        case 'search':
          addFilter('search', evt.target.value);
          return;
        case 'productType':
          addFilter(
            'productType',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
          return;
        case 'status':
          addFilter(
            'status',
            evt ? evt.map((x: SelectOption) => x.value) : null,
          );
      }
    },
    [addFilter],
  );

  return {
    filters,
    onFilterChangeHandler,
  };
};
