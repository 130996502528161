import { ProductType } from '../../../models/ProductType';

export const SubscriptionSummaryItemName = 'summary-form';

export enum SubscriptionSummaryItemFields {
  OverwriteAmount = 'overwriteAmount',
  OverwrittenAmount = 'overwrittenAmount',
}
export interface ISummaryItemDataModel {
  amount?: number;
  capacitySummary?: IPlanSummaryItemModel[];
  subscriptionType?: ProductType;
}

export interface IPlanSummaryItemModel {
  amount: number;
  capacity?: number;
  name: string;
  price?: number;
}
