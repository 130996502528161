import WarningsConfirmationProvider from '../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';

export const withWarningsConfirmationProvider =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WarningsConfirmationProvider>
        <Component {...(props as P)} />
      </WarningsConfirmationProvider>
    );
