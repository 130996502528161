import styled from '@emotion/styled';

import SummaryItemContent from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemContent/SummaryItemContent';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSummaryItemContent = styled(SummaryItemContent)`
  margin-top: 1rem;
`;
