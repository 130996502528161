/* eslint-disable no-prototype-builtins */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Processes {
  [x: string]: boolean;
}

const initState: Processes = {};

const processesSlice = createSlice({
  name: 'processes',
  initialState: initState,
  reducers: {
    updateProcessState: (state, action: PayloadAction<string>) => {
      if (!state.hasOwnProperty(action.payload)) {
        state[action.payload] = true;
      } else {
        state[action.payload] = !state[action.payload];
      }
    },
  },
});

export const { updateProcessState } = processesSlice.actions;
export default processesSlice.reducer;
