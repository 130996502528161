import { useTranslation } from 'react-i18next';

import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil-icon.svg';

import Button from '../../UI/Button/Button';
import OverviewOption from './overviewOption/OverviewOption';
import { useOverviewOptions } from './overviewOptions.hooks';

import {
  Header,
  Wrapper,
  Title,
  OptionsContainer,
} from './overviewOptions.styles';

type OverviewOptionProps = {
  value: string;
  label?: string;
};

type OverviewOptionsProps<T> = {
  data: T;
  options: OverviewOptionProps[];
  onEditOptionsClick?: (options: any) => void;
  canEditOptionsPermissions?: string[];
};

const OverviewOptions = <T,>({
  data,
  onEditOptionsClick,
  options,
  canEditOptionsPermissions,
}: OverviewOptionsProps<T>) => {
  const { t } = useTranslation();

  const { onEditOptionsClickHandler } = useOverviewOptions({
    options,
    data,
    onEditOptionsClick,
  });

  return (
    <Wrapper>
      <Header>
        <Title>{t('headers.options')}</Title>
        {onEditOptionsClick && (
          <Button
            permissions={canEditOptionsPermissions}
            onClick={onEditOptionsClickHandler}
            styleType="icon"
          >
            <PencilIcon />
          </Button>
        )}
      </Header>
      <OptionsContainer>
        {data &&
          options.map((option) => (
            <OverviewOption
              checked={data[option.value]}
              key={option.value}
              name={option.value}
              label={option.label}
            />
          ))}
      </OptionsContainer>
    </Wrapper>
  );
};
export default OverviewOptions;
