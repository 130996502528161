import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { tlSubscriptionPricingFormSchema } from '../../../Forms/TravelLicense/TLSubscriptionPricingForm/tlSubscriptionPricingForm.validation';

import { TLSubscriptionFormFields } from './travelLicenseSubscriptionForm.models';

export const tlSubscriptionFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [TLSubscriptionFormFields.PricingTiers]: {
      type: 'array',
      items: tlSubscriptionPricingFormSchema,
    },
  },
  required: [],
};

export const validateTLSubscriptionForm = (
  data: { pricing: ITLOrganizationSubscriptionPricingTierModel[] },
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, tlSubscriptionFormSchema);
