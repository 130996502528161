import { t } from 'i18next';

import { ShortcutProductType } from '../../../../models/enums/ShortcutProductType';
import { TableTooltip } from '../../materialTable.styles';

export const prepareProductsTooltip = (products: ShortcutProductType[]) =>
  products?.map((x: ShortcutProductType) => (
    <TableTooltip key={x}>
      {`${t(`enums.shortcut-to-product-type.${x}`)}`}
    </TableTooltip>
  ));
