import { useParams } from 'react-router';

import { EditProductSubscriptionPageParamsType } from '../../models/types/RoutePath';
import ProductViewContent from '../../styledComponents/ProductViewContent/ProductViewContent';
import {
  EditSubscriptionPages,
  EditSubscriptionViewsPermissions,
} from './editProductSubscriptionPage.utils';

const EditProductSubscriptionPage = () => {
  const { subscriptionType } =
    useParams<EditProductSubscriptionPageParamsType>();

  return (
    <ProductViewContent
      productType={subscriptionType}
      viewsPages={EditSubscriptionPages}
      viewsPermissions={EditSubscriptionViewsPermissions}
    />
  );
};

export default EditProductSubscriptionPage;
