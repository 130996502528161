import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import BaseInput from '../../BaseInput/BaseInput';

import { Input } from '../../Input/Input';

type StyledFormInputType = {
  error?: boolean;
};

export const StyledFormInput = styled(Input)<StyledFormInputType>`
  width: 100%;

  ${({ error, theme }) =>
    error &&
    css`
      ${BaseInput} {
        border-color: ${theme.color.error};
      }
    `}
`;
