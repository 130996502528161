import { useAlert } from 'react-alert';

import {
  useApiResponseError,
  UseApiResponseErrorType,
} from '../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { IValidationErrorResponse } from '../models/ValidationErrorResponse';
import { IWarningRatification } from '../models/interfaces/WarningRatification';
import { useApiResponseWarnings } from '../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';

type UseApiResponseFail = UseApiResponseErrorType;

type HandleResponseProps = {
  err?: IValidationErrorResponse;
  values?: any;
  ratifications?: IWarningRatification[];
  skipErrors?: string[];
  alertErrorMessage?: string;
};

export function useApiResponseFail(props: UseApiResponseFail = {}) {
  const { handleResponseError } = useApiResponseError(props);
  const { handleResponseWarning } = useApiResponseWarnings();
  const alert = useAlert();

  const handleResponse = ({
    err,
    values,
    ratifications = [],
    skipErrors = [],
    alertErrorMessage = null,
  }: HandleResponseProps) => {
    if (handleResponseError(err, values, ratifications, skipErrors)) {
      !props.withAlert && alertErrorMessage && alert.error(alertErrorMessage);
      return true;
    }

    if (handleResponseWarning(err, values)) return true;

    alertErrorMessage && alert.error(alertErrorMessage);
    return false;
  };

  return { handleResponse };
}
