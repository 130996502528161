import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';

type UseMenuList = {
  children?: ReactNode | ReactElement;
  options?: any;
  setValue?: (values: any) => void;
};

export const useMenuList = ({ children, options, setValue }: UseMenuList) => {
  const childrenToArray = React.Children.toArray(children);
  const isNotAll = useMemo(
    () =>
      childrenToArray.some((x) => {
        if (React.isValidElement(x)) {
          return x.props.isSelected === false;
        }
        return false;
      }),
    [childrenToArray],
  );
  const allClickHandler = useCallback(() => {
    if (isNotAll) {
      setValue(options);
      return;
    }
    setValue([]);
  }, [isNotAll, options, setValue]);
  return { isNotAll, allClickHandler };
};
