import { t } from 'i18next';
import { generatePath } from 'react-router-dom';

import { RoutePath } from '../../../../models/enums/RoutePath';
import { INavigationPath } from '../../../../styledComponents/NavigationPath/NavigationPath';

export const getNavigationPaths = (
  id: string,
  contractName: string,
): INavigationPath[] => [
  {
    label: t('headers.contracts'),
    url: generatePath(
      `${RoutePath.ClientOverview}/${RoutePath.ClientOverviewContractsPage}`,
      { id },
    ),
  },
  { label: contractName },
];
