import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import CorpPointOfContactFrom from '../../../../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/CorpPointOfContactForm';
import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import ModalFormPage from '../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useAddingContactModal } from './addPointOfContactModal.hooks';
import FormErrorActivator from '../../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { withErrorProvider } from '../../../../../../hoc/withErrorProvider';
import { ADD_POINT_OF_CONTACT_MODAL } from './addPointOfContactModal.const';

const AddPointOfContactModal = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    organizationUpdating,
    onCancelHandler,
    validateForm,
    rolesLoading,
    rolesList,
  } = useAddingContactModal();

  return (
    <Modal
      disabled={organizationUpdating || rolesLoading}
      name={ADD_POINT_OF_CONTACT_MODAL}
    >
      <Form
        initialValues={{}}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ ...renderProps }) => (
          <FormErrorActivator>
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={organizationUpdating}
              onCancel={onCancelHandler}
              title={t('headers.adding-new-contact')}
            >
              <FormErrorActivator>
                <CorpPointOfContactFrom
                  disabled={organizationUpdating}
                  rolesList={rolesList}
                />
              </FormErrorActivator>
            </ModalFormPage>
          </FormErrorActivator>
        )}
      </Form>
    </Modal>
  );
};

export default withErrorProvider(AddPointOfContactModal);
