import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckMark } from '../../../../assets/icons/Tick.svg';
import { ReactComponent as XIcon } from '../../../../assets/icons/x-icon.svg';
import { OptionLabel, Option } from '../overviewOptions.styles';
import { IconContainer } from './overviewOption.styles';

type OverviewOptionType = {
  checked: boolean;
  name: string;
  label?: string;
};
const OverviewOption = ({ checked, name, label }: OverviewOptionType) => {
  const { t } = useTranslation();
  return (
    <Option checked={checked}>
      <IconContainer>{checked ? <CheckMark /> : <XIcon />}</IconContainer>
      <OptionLabel>{label || t(`properties.${name}`)}</OptionLabel>
    </Option>
  );
};

export default OverviewOption;
