import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIcon } from 'assets/icons/remove-icon.svg';

import { FormSelect } from 'styledComponents/UI/Form/FormSelect/FormSelect';
import FormDatePicker from 'styledComponents/UI/Form/FormDatePicker/FormDatePicker';

import { FormColumn } from '../../../UI/Form/FormColumn/FormColumn';
import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../../utils/getFormGroupPath';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../../utils/phoneNumberHelpers';
import {
  FamilyMemberBasicDetailsFields,
  FamilyMemberBasicDetailsFormName,
  GenderOptions,
  IFamilyMemberBasicDetailsFormProps,
} from './familyMemberBasicDetails.models';
import { RemoveButton, StyledFormRow } from './familyMemberBasicDetails.styles';
import { useFamilyMemberBasicDetails } from './familyMemberBasicDetails.hooks';
import { FormRow } from '../../../UI/Form/FormRow/FormRow';
import { FormNumberInput } from '../../../UI/Form/FormNumberInput/FormNumberInput';
import { FormInput } from '../../../UI/Form/FormInput/FormInput';
import FormSearchEmail from '../../../UI/Form/FormSearchPerson/FormSearchPerson';
import FormAgeInfo from '../../../UI/Form/FormAgeInfo/FormAgeInfo';
import FormValue from '../../../UI/Form/FormValue/FormValue';

const FamilyMemberBasicDetailsForm = ({
  className,
  disabled,
  readOnly,
  name,
  onRemoveContact,
  dateOfBirth,
  memberTypesAgeRanges,
  memberTypes,
}: IFamilyMemberBasicDetailsFormProps) => {
  const { t } = useTranslation();

  const {
    memberTypeOptions,
    ageRange,
    formDateOfBirth,
    memberLoaded,
    dependence,
  } = useFamilyMemberBasicDetails({
    name,
    memberTypesAgeRanges,
    memberTypes,
  });

  const fieldDisabled = memberLoaded || disabled || readOnly;

  return (
    <div className={className}>
      <FormColumn>
        <FormRow isSubRow>
          <Field
            component={FormValue}
            name={getFormGroupPath(
              name,
              FamilyMemberBasicDetailsFields.PersonId,
            )}
          />
          <Field
            component={FormValue}
            name={getFormGroupPath(
              name,
              FamilyMemberBasicDetailsFields.SearchPerson,
            )}
          />
          <Field
            component={FormSearchEmail}
            disabled={disabled || readOnly}
            group={name}
            {...getFieldPropertiesForGroup({
              inputId: FamilyMemberBasicDetailsFormName,
              name: FamilyMemberBasicDetailsFields.EmailAddress,
              groupName: name,
            })}
          />
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={fieldDisabled}
              {...getFieldPropertiesForGroup({
                inputId: FamilyMemberBasicDetailsFormName,
                name: FamilyMemberBasicDetailsFields.DateOfBirth,
                groupName: name,
              })}
            />
            <StyledFormRow>
              <FormAgeInfo
                maxAge={ageRange.max}
                minAge={ageRange.min}
                memberType={dependence}
                date={dateOfBirth || formDateOfBirth}
              />

              {onRemoveContact && (
                <RemoveButton
                  disabled={disabled}
                  onClick={onRemoveContact}
                  styleType="link"
                >
                  <span>
                    <RemoveIcon />
                  </span>
                </RemoveButton>
              )}
            </StyledFormRow>
          </FormRow>
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormInput}
            disabled={fieldDisabled}
            {...getFieldPropertiesForGroup({
              inputId: FamilyMemberBasicDetailsFormName,
              name: FamilyMemberBasicDetailsFields.FirstName,
              groupName: name,
            })}
          />
          <Field
            component={FormInput}
            disabled={fieldDisabled}
            {...getFieldPropertiesForGroup({
              inputId: FamilyMemberBasicDetailsFormName,
              name: FamilyMemberBasicDetailsFields.LastName,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormNumberInput}
            disabled={fieldDisabled}
            {...getFieldPropertiesForGroup({
              inputId: FamilyMemberBasicDetailsFormName,
              name: FamilyMemberBasicDetailsFields.PhoneNumber,
              groupName: name,
            })}
            parseValue={parsePhoneNumber}
            formatValue={formatPhoneNumber}
            maxLength={PHONE_NUMBER_MAX_LENGTH}
          />
          <FormRow subRowAdjustWidth isSubRow>
            <Field
              component={FormSelect}
              disabled={fieldDisabled}
              isClearable
              label={t('fields.gender.label')}
              {...getFieldPropertiesForGroup({
                inputId: FamilyMemberBasicDetailsFormName,
                name: FamilyMemberBasicDetailsFields.Gender,
                groupName: name,
              })}
              options={GenderOptions}
            />
            <Field
              component={FormSelect}
              disabled={disabled}
              label={t('fields.dependence.label')}
              {...getFieldPropertiesForGroup({
                inputId: FamilyMemberBasicDetailsFormName,
                name: FamilyMemberBasicDetailsFields.Dependence,
                groupName: name,
              })}
              options={memberTypeOptions}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    </div>
  );
};

export default FamilyMemberBasicDetailsForm;
