import styled from '@emotion/styled/macro';

import { ReactComponent as PencilIcon } from '../../../assets/icons/pen-icon-secondary.svg';

export const ChildPencilIcon = styled(PencilIcon)`
  visibility: hidden;
`;

export const RenewalStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  &:hover {
    ${ChildPencilIcon} {
      visibility: visible;
    }
  }
`;
