import { t } from 'i18next';

import { KidnapAndExtortionMembershipOptions } from '../../../../../../../models/enums/KidnapAndExtortionMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const KEMembershipOptions: IFormCheckboxGroupOptionProps[] = [
  {
    name: KidnapAndExtortionMembershipOptions.SendWelcomeEmail,
  },
  {
    name: KidnapAndExtortionMembershipOptions.AutoRenewal,
    label: t('fields.renewWithSubscription.label'),
  },
];
