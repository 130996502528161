import { ToolsWrapper } from '../../../SimplePage/Tools';
import AAMembersFilters from '../AAMembersFilters/AAMembersFilters';
import { AAMembersFiltersProps } from '../AAMembersFilters/aaMembersFilters.models';

export type AAMembersToolsProps = AAMembersFiltersProps;

const AAMembersTools = ({
  availableFilters,
  standardPricing,
}: AAMembersToolsProps) => (
  <ToolsWrapper>
    <AAMembersFilters
      availableFilters={availableFilters}
      standardPricing={standardPricing}
    />
  </ToolsWrapper>
);

export default AAMembersTools;
