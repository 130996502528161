import { useField } from 'react-final-form';

import { useMemo } from 'react';

import { MembershipType } from '../../../../../../../models/MembershipType';
import MembershipRetailsDetailsForm from '../AAMembershipRetailsDetailsForm/AAMembershipRetailsDetailsForm';
import MembershipOrganizationDetailsForm from '../AAMembershipOrganizationDetailsForm/AAMembershipOrganizationDetailsForm';
import { MembershipTypeFormFields } from '../AAMembershipTypeForm/aaMembershipType.models';
import { CustomForm } from '../../../../../../../models/types/CustomForm';

const MembershipDetailsForm = (props: CustomForm) => {
  const {
    input: { value: membershipType },
  } = useField(MembershipTypeFormFields.MembershipType);

  const membershipDetailsComponent = useMemo(() => {
    switch (membershipType) {
      case MembershipType.Retail:
        return <MembershipRetailsDetailsForm {...props} />;
      case MembershipType.Organization:
        return <MembershipOrganizationDetailsForm {...props} />;
      default:
        return null;
    }
  }, [membershipType, props]);

  return membershipDetailsComponent;
};

export default MembershipDetailsForm;
