import { useTranslation } from 'react-i18next';

import { IMembershipPlanPriceInfo } from '../../../models/MembershipPlanPriceInfo';
import { IPricingMatrixInfo } from '../../../models/PricingMatrixInfo';
import {
  NameRenderer,
  PricingRenderer,
} from '../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import MaterialTable from '../../../styledComponents/MaterialTable/MaterialTable';

export interface IPromoCodeOverviewTableProps {
  data?: IPricingMatrixInfo;
}

const PromoCodeOverviewTable = ({ data }: IPromoCodeOverviewTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (key: keyof IMembershipPlanPriceInfo, options?: ICellOptions) =>
    ({ price, membershipPlanName }: IMembershipPlanPriceInfo) => {
      switch (key) {
        case 'membershipPlanName':
          return (
            <NameRenderer value={membershipPlanName || '-'} options={options} />
          );
        case 'price':
          return <PricingRenderer options={options} price={price} readonly />;
      }
    };
  return (
    <MaterialTable
      columns={[
        {
          title: t('grid-columns.membership-plan'),
          field: 'memberName',
          cellRenderer: cellRenderer('membershipPlanName', { textBold: true }),
        },
        {
          title: t('grid-columns.retail-price'),
          field: 'price',
          alignment: 'center',
          cellRenderer: cellRenderer('price', { alignment: 'center' }),
        },
      ]}
      isPaginationHidden
      data={(data && data.membershipPlansPrices) || []}
    />
  );
};

export default PromoCodeOverviewTable;
