import { AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { AA_PRICING_LOADING_PROCESSING } from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  ILoadStandardPricingPayload,
  IStandardPricingQueryResponse,
} from '../common/models';
import { getAAStandardPricingQuery } from './api';
import { setAAMembershipPlansPrices } from '../../../redux-slices/pricing/airAmbulance';

const aaPricingSlice = createSliceSaga({
  name: 'aa-pricing-saga',
  caseSagas: {
    *aaLoadStandardPricing({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadStandardPricingPayload>) {
      try {
        yield put(updateProcessState(AA_PRICING_LOADING_PROCESSING));

        const response: AxiosResponse<IStandardPricingQueryResponse> =
          yield* call(getAAStandardPricingQuery, cancellationToken);

        yield put(
          setAAMembershipPlansPrices(response.data.membershipPlansPrices),
        );

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(AA_PRICING_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default aaPricingSlice.saga;
export const { aaLoadStandardPricing } = aaPricingSlice.actions;
export const { actions } = aaPricingSlice;
