import styled from '@emotion/styled/macro';

import { FormSubmitButton } from '../../../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';
import { Line } from '../../../../../styledComponents/UI/Line/Line';

export const StyledFormSubmitButton = styled(FormSubmitButton)`
  margin: 1.5rem 0 0;
`;

export const StyledLine = styled(Line)`
  margin-top: 0.5rem;
`;
