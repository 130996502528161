import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFormErrorsActions } from '../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../hooks/useActions';
import { useApiResponseFail } from '../../../hooks/useApiResponseFail';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../hooks/useProcessing';
import {
  AddSubscriptionAvailableSearchParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { IWarningRatification } from '../../../models/interfaces/WarningRatification';
import { organizationContractsSelector } from '../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../store/redux-slices/modals';
import { organizationNameSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_CONTRACTS_LOADING_PROCESSING,
  ORGANIZATION_LOADING_PROCESSING,
  AA_PRICING_LOADING_PROCESSING,
  KE_PRICING_LOADING_PROCESSING,
  SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING,
  SUBSCRIPTION_ADDING_PROCESSING,
  SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING,
  SR_PRICING_LOADING_PROCESSING,
  SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING,
  DOC_PRICING_LOADING_PROCESSING,
  SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING,
  SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { ICreateNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/airAmbulance/models';
import { validateAddSubscriptionForm } from './addProductSubscriptionForm.validation';
import { ICreateOrganizationSubscriptionsModel } from '../../../models/interfaces/Subscription/CreateOrganizationSubscriptionsModel';
import { IValidationErrorResponse } from '../../../models/ValidationErrorResponse';
import { useRouteParams } from '../../../hooks/useRouteParams';
import { createNewSubscriptions } from '../../../store/saga-slices/subscriptions/common';
import { AddContractModalFormDetails } from '../../../styledComponents/Modals/AddContractModal/AddContractModalForm/addContractModalForm.models';
import { ADD_CONTRACT_MODAL_NAME } from '../../../styledComponents/Modals/AddContractModal/addContractModal.const';

export const useAddProductSubscriptionForm = () => {
  const navigate = useNavigateSearch();
  const alert = useAlert();

  const { id: organizationId } = useParams();
  const { contractId } = useRouteParams<AddSubscriptionAvailableSearchParams>();
  const { t } = useTranslation();

  const CreateNewSubscriptions = useActions(createNewSubscriptions);
  const UpdateModalState = useActions(updateModalState);

  const organizationName = useSelector(organizationNameSelector);
  const organizationContracts = useSelector(organizationContractsSelector);

  const subscriptionAdding = useProcessing(SUBSCRIPTION_ADDING_PROCESSING);

  const dataLoading = useProcessing([
    ORGANIZATION_CONTRACTS_LOADING_PROCESSING,
    AA_PRICING_LOADING_PROCESSING,
    KE_PRICING_LOADING_PROCESSING,
    SR_PRICING_LOADING_PROCESSING,
    DOC_PRICING_LOADING_PROCESSING,
    SUBSCRIPTIONS_AA_DATES_LOADING_PROCESSING,
    SUBSCRIPTIONS_KE_DATES_LOADING_PROCESSING,
    SUBSCRIPTIONS_SR_DATES_LOADING_PROCESSING,
    SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING,
    SUBSCRIPTIONS_TL_DATES_LOADING_PROCESSING,
    ORGANIZATION_LOADING_PROCESSING,
  ]);

  const { validateErrors } = useFormErrorsActions();

  const { handleResponse } = useApiResponseFail();

  const handleSubmit = (values: ICreateOrganizationSubscriptionsModel) => {
    createOrganizationSubscription(values);
  };

  const createOrganizationSubscription = useCallback(
    (
      values: ICreateOrganizationSubscriptionsModel,
      ratifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: ICreateOrganizationSubscriptionsModel = {
        ...values,
        ratifications,
      };

      const createNewSubscriptionPayload: ICreateNewSubscriptionPayload = {
        organizationId,
        data: extendedValues,
        error: (err: IValidationErrorResponse) => {
          handleResponse({
            err,
            values,
            ratifications,
            alertErrorMessage: t(
              `alerts.${
                values.isDraft ? 'draft-' : ''
              }subscription-create-fail`,
              {
                value: organizationName,
              },
            ),
          });
        },
        success: () => {
          navigate(
            RoutePath.ClientOverviewProductSubscriptionListPageFullPath,
            {
              params: {
                id: organizationId,
              },
            },
          );
          alert.success(
            t(
              `alerts.${
                values.isDraft ? 'draft-' : ''
              }subscription-create-success`,
              {
                value: organizationName,
              },
            ),
          );
        },
      };
      CreateNewSubscriptions(createNewSubscriptionPayload);
    },
    [
      CreateNewSubscriptions,
      alert,
      handleResponse,
      navigate,
      organizationId,
      organizationName,
      t,
    ],
  );

  const validateForm = (data: ICreateOrganizationSubscriptionsModel) =>
    validateAddSubscriptionForm(data, validateErrors(data));

  const onAddContractHandler = () => {
    const details: AddContractModalFormDetails = {
      organizationId,
    };
    UpdateModalState([ADD_CONTRACT_MODAL_NAME, details]);
  };

  const initData = {
    contractId,
    subscriptions: [{}],
  };

  return {
    validateForm,
    handleSubmit,
    UpdateModalState,
    organizationContracts,
    subscriptionAdding,
    dataLoading,
    createOrganizationSubscription,
    initData,
    organizationId,
    onAddContractHandler,
  };
};
