import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FormTitle } from '../Typography/Typography';
import Button from '../UI/Button/Button';
import { FormModalMode } from './modalFormPage.models';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  min-height: 12vh;
`;

export const Title = styled(FormTitle)`
  font-weight: 500;
  margin: 0.5rem 0 0;
  color: ${({ theme }) => theme.color.black};
`;

export const Form = styled.form`
  overflow: auto;
  scroll-padding-top: 1.5rem;
`;

export const FormContent = styled.div`
  padding: 0rem 0 0.5rem;
`;

type FormContentWrapperProps = {
  mode?: FormModalMode;
};

export const FormContentWrapper = styled.div<FormContentWrapperProps>`
  ${({ mode }) =>
    mode === 'normal' &&
    css`
      min-width: 54rem;
    `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1.5rem;
`;

export const StyledButton = styled(Button)`
  width: 9.375rem;
  height: 3rem;
`;
