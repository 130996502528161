import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RenewalContractsResult } from '../../../models/types/RenewalFilters/RenewalContractsResult';
import { RenewalSubscriptionsResult } from '../../../models/types/RenewalFilters/RenewalSubscriptionsResult';
import { UpdateRenewalStatusModel } from './models';

export interface IRenewalFiltersState {
  renewalsContracts: RenewalContractsResult;
  renewalsSubscriptions: RenewalSubscriptionsResult;
}

const initialState: IRenewalFiltersState = {
  renewalsContracts: null,
  renewalsSubscriptions: null,
};

const renewalFilterSlice = createSlice({
  name: 'renewal-filters',
  initialState,
  reducers: {
    setRenewalsContracts: (
      state,
      { payload }: PayloadAction<RenewalContractsResult>,
    ) => {
      state.renewalsContracts = payload;
    },
    setRenewalsSubscriptions: (
      state,
      { payload }: PayloadAction<RenewalSubscriptionsResult>,
    ) => {
      state.renewalsSubscriptions = payload;
    },
    updateRenewalStatusSubscriptions: (
      state,
      { payload }: PayloadAction<UpdateRenewalStatusModel>,
    ) => {
      state.renewalsSubscriptions?.forEach((group) => {
        group.records.forEach((record) => {
          if (
            record.organizationId === payload.organizationId &&
            record.subscriptionId === payload.subscriptionId
          ) {
            record.renewalStatus = payload.renewalStatus;
          }
        });
      });
    },
    clearRenewalsSubscriptions: (state) => {
      state.renewalsSubscriptions = initialState.renewalsSubscriptions;
    },
    clearRenewalsContracts: (state) => {
      state.renewalsContracts = initialState.renewalsContracts;
    },
  },
});

export const {
  clearRenewalsContracts,
  clearRenewalsSubscriptions,
  setRenewalsContracts,
  setRenewalsSubscriptions,
  updateRenewalStatusSubscriptions,
} = renewalFilterSlice.actions;
export default renewalFilterSlice.reducer;
export const promoCodesReducerName = renewalFilterSlice.name;
