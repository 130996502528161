import { useEffect, useState } from 'react';

import { useComponentIsMounted } from '../../../../hooks/useComponentIsMounted';
import { useDebounce } from '../../../../hooks/useDebounce';
import { getAge } from '../../../../store/saga-slices/people/api';
import { getAge as getLocalAge } from '../../../../utils/dateHelpers';

type UseFormAgeInfoProps = {
  date: string;
  localCalculateAge?: boolean;
};

export const useFormAgeInfo = ({
  date,
  localCalculateAge,
}: UseFormAgeInfoProps) => {
  const [currentAge, setCurrentAge] = useState(null);
  const [ageLoading, setAgeLoading] = useState(false);
  const { debounce } = useDebounce();
  const isMount = useComponentIsMounted();

  useEffect(() => {
    if (!getLocalAge(date)) {
      setCurrentAge(null);
      return;
    }

    if (localCalculateAge) {
      setCurrentAge(getLocalAge(date));
      return;
    }
    debounce(() => {
      setAgeLoading(true);
      getAge(date)
        .then((result) => {
          isMount() && setCurrentAge(result.data.age);
        })
        .finally(() => isMount() && setAgeLoading(false));
    }, 500);
  }, [date, debounce, isMount, localCalculateAge]);

  return { currentAge, ageLoading };
};
