import { RenewalStatus } from '../../../models/enums/RenewalStatus';
import { getOptionsFromEnumValues } from '../../../utils/getOptionsFromEnum';
import { Status } from '../../UI/Status/Status';
import { SelectOption } from '../../UI/MainSelect/mainSelect.types';

export const renewalStatusOptions = getOptionsFromEnumValues(
  Object.values(RenewalStatus),
  'renewal-status',
);

export const formatOptionLabel = ({ value }: SelectOption) => (
  <Status enumName="renewal-status" status={value} />
);
