import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IKEMembersState } from '.';

export const keMembershipSelector = (state: RootState) => state.keMembership;

export const membershipSummarySelector = createSelector(
  keMembershipSelector,
  (members: IKEMembersState) => members.summary,
);

export const pageListKEMembersSelector = createSelector(
  keMembershipSelector,
  (members: IKEMembersState) => members.members,
);

export const keMembershipDatesSelector = createSelector(
  keMembershipSelector,
  (members: IKEMembersState) => members.newMembershipDates,
);

export const keMembershipDetailsByIdSelector = (membershipId: string) =>
  createSelector(
    keMembershipSelector,
    (members: IKEMembersState) =>
      members.membershipDetails && members.membershipDetails[membershipId],
  );
