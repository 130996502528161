import { useCallback } from 'react';

import {
  goBackRoute,
  goBackMainPageRoute,
} from '../store/saga-slices/routesHistory';
import { useActions } from './useActions';
import { useNavigateSearch } from './useNavigateSearch';

export const useNavigateBack = (defaultUrl?: string) => {
  const navigate = useNavigateSearch();
  const GoBack = useActions(goBackRoute);
  const GoBackMainPage = useActions(goBackMainPageRoute);

  const goBack = useCallback(
    (routesCount?: number) => {
      GoBack({
        routesCount,
        success: (route: string) => {
          const currentUrl = route || defaultUrl;
          currentUrl ? navigate(currentUrl) : navigate(-1);
        },
      });
    },
    [GoBack, defaultUrl, navigate],
  );

  const goBackMainPage = useCallback(() => {
    GoBackMainPage({
      success: (route: string) => {
        const currentUrl = route || defaultUrl;
        currentUrl ? navigate(currentUrl) : navigate(-1);
      },
    });
  }, [GoBackMainPage, defaultUrl, navigate]);

  return { goBack, goBackMainPage };
};
