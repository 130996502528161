import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../../Typography/Typography';

import { MainSelect } from '../../MainSelect/MainSelect';
import { MainSelectPropType } from '../../MainSelect/mainSelect.types';
import { useFormSelect } from './formSelect.hooks';

interface IFormSelectProps<T>
  extends FieldRenderProps<T>,
    MainSelectPropType<T> {
  setDefaultValue?: boolean;
  onItemChanged?: (value: string[] | string) => void;
}

export const FormSelect = <T,>({
  className,
  disabled,
  input: { onChange, value, name, onBlur },
  inputId,
  isLabelHidden,
  label,
  meta: { error, touched },
  options,
  placeholder,
  setDefaultValue,
  labelAnimation = true,
  onItemChanged,
  multiInputType,
  isMulti,
  isCheckAllOption,
  formatOptionLabel,
  ...props
}: IFormSelectProps<T>) => {
  const { t } = useTranslation();

  const { onChangeHandler, preparedPlaceholder } = useFormSelect({
    onChange,
    options,
    labelAnimation,
    name,
    placeholder,
    promiseOptions: props.promiseOptions,
    setDefaultValue,
    value,
    onItemChanged,
    isMulti,
  });
  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <MainSelect
        currentValue={value}
        isDisabled={disabled}
        onChange={onChangeHandler as any}
        options={options}
        label={label || t(`fields.${getPropertyName(name)}.label`)}
        labelAnimation
        isError={touched && error}
        placeholder={preparedPlaceholder}
        onBlur={onBlur}
        name={name}
        multiInputType={multiInputType}
        isMulti={isMulti}
        isCheckAllOption={isCheckAllOption}
        formatOptionLabel={formatOptionLabel}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
