import styled from '@emotion/styled';

import OverviewProperty from '../../../../styledComponents/Overview/OverviewProperty/OverviewProperty';
import Button from '../../../../styledComponents/UI/Button/Button';

export const PromoCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
`;

type StyledOverviewPropertyType = {
  width?: string;
};

export const StyledOverviewProperty = styled(
  OverviewProperty,
)<StyledOverviewPropertyType>`
  min-width: ${({ width }) => width || '12rem'};
`;

export const StyledRenewButton = styled(Button)`
  margin-right: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 7.5rem;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  gap: 6rem;
`;
