import { useCallback } from 'react';
import { Params } from 'react-router';
import {
  useNavigate,
  generatePath,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const preparedNavigation = useCallback(
    (
      pathname: string | -1,
      options?: {
        params?: Params;
        searchParams?: Params;
        redirectSearchParams?: boolean;
      },
      state?: any,
    ) => {
      if (pathname === -1) {
        navigate(-1);
        return;
      }

      const searchParamsTmp = [
        !!options && options.redirectSearchParams
          ? searchParams.toString()
          : '',
        !!options && options.searchParams
          ? createSearchParams(options.searchParams)
          : '',
      ]
        .filter((value) => value)
        .join('&');

      navigate(
        {
          pathname: generatePath(pathname, !!options && options.params),
          search: searchParamsTmp && `?${searchParamsTmp}`,
        },
        { state },
      );
    },
    [navigate, searchParams],
  );

  return preparedNavigation;
};
