import { CustomForm } from '../../models/types/CustomForm';
import { CheckboxContentInput } from './CheckboxContentInput/CheckboxContentInput';
import { SummaryContainerFields } from './summaryContainer.models';

type CheckboxContentRendererType = CustomForm & {
  disabled?: boolean;
  name?: string;
};

export const checkboxContentRenderer = ({
  disabled,
  name,
}: CheckboxContentRendererType) => (
  <CheckboxContentInput
    disabled={disabled}
    inputId="summaryFormDefineTotalPrice"
    name={name || SummaryContainerFields.OverwrittenAmount}
  />
);
