import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export interface ISRPricingState {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

const initialState: ISRPricingState = {
  membershipPlansPrices: [],
};

const srPricingSlice = createSlice({
  name: 'srPricing',
  initialState,
  reducers: {
    setSRMembershipPlansPrices: (
      state,
      { payload }: PayloadAction<IMembershipPlanPriceModel[]>,
    ) => {
      state.membershipPlansPrices = payload;
    },
    clearSRMembershipPlansPrices: (state) => {
      state.membershipPlansPrices = initialState.membershipPlansPrices;
    },
  },
});

export const { setSRMembershipPlansPrices, clearSRMembershipPlansPrices } =
  srPricingSlice.actions;
export default srPricingSlice.reducer;
export const pricingReducerName = srPricingSlice.name;
