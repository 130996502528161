import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';
import { SRRenewSubscriptionDetails } from './SRRenewSubscriptionDetails/SRRenewSubscriptionDetails';
import { SRRenewSubscriptionPricing } from './SRRenewSubscriptionPricing/SRRenewSubscriptionPricing';
import RenewSubscriptionItemForm from '../../RenewSubscriptionItemForm/RenewSubscriptionItemForm';
import { Wrapper } from './securityResponseRenewSubscriptionForm.styles';
import { RenewSubscriptionItemBaseProps } from '../../RenewSubscriptionItemForm/renewSubscriptionItemForm.models';
import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { SRSubscriptionOptions } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';

const SecurityResponseRenewSubscriptionForm = ({
  name,
  disabled,
  className,
  isEditMode,
}: RenewSubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const productType = ProductType.SecurityResponse;
  return (
    <RenewSubscriptionItemForm collapsible={false} productType={productType}>
      <Wrapper className={className}>
        <SRRenewSubscriptionDetails disabled={disabled} />
        <SRRenewSubscriptionPricing
          disabled={disabled}
          productType={productType}
        />
        <SubscriptionOptions
          subscriptionOptions={SRSubscriptionOptions}
          layout="column"
          disabled={disabled}
          title={t('headers.options')}
        />
      </Wrapper>
    </RenewSubscriptionItemForm>
  );
};

export default SecurityResponseRenewSubscriptionForm;
