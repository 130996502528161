import { DutyOfCareMembershipOptions } from '../../../../../models/enums/DutyOfCareMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL =
  'EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL';

export const docMembershipOptionsList: IFormCheckboxGroupOptionProps[] = [
  {
    name: DutyOfCareMembershipOptions.SendWelcomeEmail,
    optionDisabled: true,
  },
  {
    name: DutyOfCareMembershipOptions.EmailOptOut,
  },
];
