import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../../../models/FieldErrorMessage';
import { IAAOrganizationSubscriptionOptionsModel } from '../../../../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOptionsModel';

import { subscriptionOptionsSchema } from '../../../../../../../styledComponents/Forms/SubscriptionOptions/subscriptionOptions.validation';
import { validateFormData } from '../../../../../../../utils/validations/validateFormData';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...subscriptionOptionsSchema.properties,
  },
  dependencies: {
    ...subscriptionOptionsSchema.dependencies,
  },
  required: [...subscriptionOptionsSchema.required],
};

export const validateEditSubscriptionDetailsForm = (
  data: IAAOrganizationSubscriptionOptionsModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
