import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import TextField from '@mui/material/TextField';

import { transientOptions } from '../../../../utils/transientOptions';

type StyledTextFieldProps = {
  $isError?: boolean;
  disabled?: boolean;
};

export const StyledTextField = styled(
  TextField,
  transientOptions,
)<StyledTextFieldProps>`
  height: 100%;

  .MuiInputBase-root {
    height: 3rem;
    padding: 0rem 1rem !important;
    font-size: 0.875rem;

    fieldset {
      border: 1px solid ${({ theme }) => theme.color.input_border};

      &.disabled {
        color: ${({ theme }) => theme.color.dark2};
      }
    }

    &:hover,
    &.Mui-focused {
      fieldset {
        border-width: 1px;
        border-color: ${({ theme }) => theme.color.input_border};

        &.disabled {
          color: ${({ theme }) => theme.color.dark2};
        }
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: ${({ theme }) => theme.color.input_border};
      }
    }
  }
  .MuiInputBase-input {
    font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.dark2 : theme.color.black};
  }
  .MuiInputLabel-root {
    font: normal 400 0.875rem/0.875rem ${({ theme }) => theme.font.gotham};
    color: ${({ theme }) => theme.color.dark1};
    background: ${({ theme }) => theme.color.white};
    cursor: text;
    outline: none;
    transform: translate(1rem, 1.1rem);

    &.Mui-focused,
    &.MuiFormLabel-filled {
      color: ${({ theme }) => theme.color.dark2};
      font: normal 700 0.75rem/0.75rem ${({ theme }) => theme.font.gotham};
      transform: translate(0.8rem, -0.4rem);
    }
  }

  ${({ theme, $isError }) =>
    $isError &&
    css`
      .MuiInputBase-root {
        fieldset {
          border-color: ${theme.color.error};
        }

        &:hover,
        &.Mui-focused {
          fieldset {
            border-color: ${theme.color.error};

            &.disabled {
              color: ${theme.color.error};
            }
          }
        }
      }

      .MuiInputLabel-root,
      .MuiInputLabel-root.Mui-focused,
      .MuiInputLabel-root.MuiInputLabel-root {
        color: ${theme.color.error};
      }
    `}
`;
