import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteProps } from 'react-router';

import Button from '../../styledComponents/UI/Button/Button';
import { useLogoutCallback } from './logoutCallback.hooks';
import { Title, Wrapper } from './logoutCallback.styles';

const HomePage: FC<RouteProps> = () => {
  const { onLoginHandler } = useLogoutCallback();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('phrase.logged-out')}</Title>
      <Button onClick={onLoginHandler}>{t('buttons.login')}</Button>
    </Wrapper>
  );
};

export default HomePage;
