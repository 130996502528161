import styled from '@emotion/styled/macro';

export const ClientOverviewHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ClientOverviewHeaderTitleContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
`;

export const ClientOverviewHeaderTitle = styled.span`
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.color.black};
`;

export const ClientOverviewHeaderTitleIcon = styled.span`
  margin: 0 0.5rem;
  display: flex;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
