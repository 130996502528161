import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const CardNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ExpiredCardText = styled.p`
  color: ${({ theme }) => theme.color.error};
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

type CardNumberProps = {
  disabled?: boolean;
};

export const CardNumber = styled.p<CardNumberProps>`
  white-space: nowrap;
  margin-left: 0.5rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}
`;
