import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useFormErrorsActions } from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../../../hooks/useActions';
import { useRouteParams } from '../../../../../../../hooks/useRouteParams';
import { IApiValidationError } from '../../../../../../../models/ApiValidationError';

import { ICreateSecondaryMemberModel } from '../../../../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { MembershipType } from '../../../../../../../models/MembershipType';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';
import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { updateProcessState } from '../../../../../../../store/redux-slices/processes';
import { MEMBERSHIP_PROMO_CODE_LOADING } from '../../../../../../../store/redux-slices/processes/constants';
import { loadAAMembershipSummary } from '../../../../../../../store/saga-slices/membership/airAmbulance';
import { ILoadAAMembershipSummaryPayload } from '../../../../../../../store/saga-slices/membership/airAmbulance/models';
import {
  searchAAOrganization,
  searchAAPromoCodesInfo,
} from '../../../../../../../store/saga-slices/optionsSearch/airAmbulance/api';
import { getAASubscriptionPlans } from '../../../../../../../store/saga-slices/subscriptions/airAmbulance/api';
import {
  formatDate,
  parseDate,
  parseExpirationDateOneMonth,
  parseExpirationDateTwoWeeks,
} from '../../../../../../../utils/dateHelpers';
import { useApiWarning } from '../../../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';
import { MembershipOrganizationDetailsFormFields } from './aaMembershipOrganizationDetailsForm.models';
import { MembershipFamilyMembersFormFields } from '../../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { primaryMemberSelector } from '../../../../../../../store/redux-slices/membership/common/selectors';
import { getDatesForNewAAMemberships } from '../../../../../../../store/saga-slices/membership/airAmbulance/api';
import { clearMembershipSummary } from '../../../../../../../store/redux-slices/membership/common';
import { useComponentIsMounted } from '../../../../../../../hooks/useComponentIsMounted';
import { MembershipPlanPeriodsType } from '../../../../../../../models/enums/MembershipPlanPeriodsType';
import { mapToTermType } from '../../addMemberMembershipPage.utils';
import { AirAmbulanceMembershipOptions } from '../../../../../../../models/enums/AirAmbulanceMembershipOptions';

export function useMembershipOrganizationDetails() {
  const [lastPromoCode, setLastPromoCode] = useState(null);
  const primaryMember = useSelector(primaryMemberSelector);
  const cancellationToken = useCancellationToken();
  const isMounted = useComponentIsMounted();

  const {
    organizationId: initOrganizationId,
    subscriptionId: initSubscriptionId,
  } = useRouteParams();

  const { removeErrors, addError, clearErrors } = useFormErrorsActions();
  const { setRatifications } = useApiWarning();

  const UpdateProcessState = useActions(updateProcessState);
  const LoadMembershipSummary = useActions(loadAAMembershipSummary);
  const ClearMembershipSummary = useActions(clearMembershipSummary);

  const {
    input: { value: promoCode, onChange: promoCodeChange },
    meta: { error: promoCodeError },
  }: FieldRenderProps<string> = useField(
    MembershipOrganizationDetailsFormFields.PromoCode,
  );

  const {
    input: {
      value: selectedOrganization,
      onChange: selectedOrganizationChange,
    },
  }: FieldRenderProps<IOrganizationSearch> = useField(
    MembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { value: selectedPlan, onChange: selectedPlanChange },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    MembershipOrganizationDetailsFormFields.MembershipPlanId,
  );

  const {
    input: { value: subscriptionType, onChange: subscriptionTypeChange },
  }: FieldRenderProps<string> = useField(
    MembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  const {
    input: { onChange: expirationDateChange },
  }: FieldRenderProps<string> = useField(
    MembershipOrganizationDetailsFormFields.ExpirationDate,
  );
  const {
    input: { onChange: secondaryMembersChange },
  }: FieldRenderProps<ICreateSecondaryMemberModel[]> = useField(
    MembershipFamilyMembersFormFields.SecondaryMembers,
  );

  const {
    input: { value: effectiveDate, onChange: effectiveDateChange },
  }: FieldRenderProps<string> = useField(
    MembershipOrganizationDetailsFormFields.EffectiveDate,
  );

  const {
    input: { onChange: autoRenewalChange },
  }: FieldRenderProps<boolean> = useField(
    AirAmbulanceMembershipOptions.AutoRenewal,
  );

  const clearFields = useCallback(() => {
    if (isMounted()) {
      return;
    }
    promoCodeChange(null);
    selectedOrganizationChange(null);
    selectedPlanChange(null);
    subscriptionTypeChange(null);
    expirationDateChange(null);
    effectiveDateChange(null);
    secondaryMembersChange(null);
  }, [
    isMounted,
    promoCodeChange,
    selectedOrganizationChange,
    selectedPlanChange,
    subscriptionTypeChange,
    expirationDateChange,
    effectiveDateChange,
    secondaryMembersChange,
  ]);

  const resetData = useCallback(() => {
    clearErrors();
    setTimeout(() => clearFields(), 0);
    setRatifications([]);
  }, [clearErrors, clearFields, setRatifications]);

  const loadOrganizationById = useCallback(
    (organizationId: string) => {
      searchAAOrganization(null, organizationId, cancellationToken).then(
        ({ data: { organizations } }) => {
          selectedOrganizationChange(
            organizations.length > 0 ? organizations[0] : null,
          );
        },
      );
    },
    [cancellationToken, selectedOrganizationChange],
  );

  useEffect(() => {
    if (initOrganizationId) {
      loadOrganizationById(initOrganizationId);
    }
    return () => resetData();
  }, [initOrganizationId, loadOrganizationById, resetData]);

  const organizationsPromiseOptions = useCallback(
    (inputValue: string) =>
      searchAAOrganization(inputValue, null, cancellationToken).then((x) =>
        x.data.organizations.map((organization) => ({
          label: organization.name,
          value: organization,
        })),
      ),
    [cancellationToken],
  );

  const {
    input: {
      value: { membershipPlanTermType },
    },
  }: {
    input: {
      value: { membershipPlanTermType: MembershipPlanPeriodsType };
    };
  } = useField(MembershipOrganizationDetailsFormFields.MembershipPlanId);

  const loadDates = useCallback(() => {
    getDatesForNewAAMemberships({
      termType: mapToTermType(membershipPlanTermType),
      subscriptionId: subscriptionType,
      cancellationToken,
    }).then((response) => {
      effectiveDateChange(parseDate(response.data.effectiveDate));
      expirationDateChange(parseDate(response.data.expirationDate));
    });
  }, [
    cancellationToken,
    effectiveDateChange,
    expirationDateChange,
    subscriptionType,
    membershipPlanTermType,
  ]);

  useEffect(() => {
    effectiveDateChange(null);
    expirationDateChange(null);

    if (subscriptionType) {
      loadDates();
    }
  }, [
    effectiveDateChange,
    expirationDateChange,
    loadDates,
    subscriptionType,
    membershipPlanTermType,
  ]);

  useEffect(() => {
    switch (membershipPlanTermType) {
      case MembershipPlanPeriodsType.Days_14:
        autoRenewalChange(false);
        expirationDateChange(parseExpirationDateTwoWeeks(effectiveDate));
        break;
      case MembershipPlanPeriodsType.Days_30:
        autoRenewalChange(false);
        expirationDateChange(parseExpirationDateOneMonth(effectiveDate));
        break;
      default:
        autoRenewalChange(true);
        break;
    }
  }, [
    autoRenewalChange,
    effectiveDate,
    expirationDateChange,
    membershipPlanTermType,
  ]);

  const addPromoCodeError = useCallback(
    (promoCodeValue: string, errorCode: string) => {
      const error: IApiValidationError = {
        PropertyName: MembershipOrganizationDetailsFormFields.PromoCode,
        ErrorCode: errorCode,
        InternalError: true,
      };

      addError(error, promoCodeValue);
    },
    [addError],
  );

  const loadPromoCode = useCallback(() => {
    if (promoCodeError || !promoCode) {
      setLastPromoCode(null);
      return null;
    }

    if (promoCode === lastPromoCode) {
      return;
    }

    UpdateProcessState(MEMBERSHIP_PROMO_CODE_LOADING);
    searchAAPromoCodesInfo(promoCode, cancellationToken)
      .then((promoCodeInfo) => {
        if (
          promoCodeInfo.data.membershipType.toLocaleLowerCase() !==
          MembershipType.Organization.toLocaleLowerCase()
        ) {
          addPromoCodeError(promoCode, 'promo-code-for-retail');
        }
        setLastPromoCode(promoCode);
        loadOrganizationById(promoCodeInfo.data.organizationId);
      })
      .catch(() => {
        addPromoCodeError(promoCode, 'promo-code-not-found');
      })
      .finally(() => UpdateProcessState(MEMBERSHIP_PROMO_CODE_LOADING));
  }, [
    promoCodeError,
    promoCode,
    lastPromoCode,
    UpdateProcessState,
    cancellationToken,
    loadOrganizationById,
    addPromoCodeError,
  ]);

  useEffect(() => {
    if (
      !selectedPlan ||
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      ClearMembershipSummary();
      return;
    }
    const payload: ILoadAAMembershipSummaryPayload = {
      membershipPlanId: selectedPlan.membershipPlanId,
      organizationId: selectedOrganization.organizationId,
      subscriptionId: subscriptionType,
      cancellationToken,
    };

    LoadMembershipSummary(payload);
  }, [
    ClearMembershipSummary,
    LoadMembershipSummary,
    cancellationToken,
    selectedOrganization.organizationId,
    selectedOrganization.subscriptions,
    selectedPlan,
    subscriptionType,
  ]);

  useEffect(() => {
    if (
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      subscriptionTypeChange(null);
      selectedPlanChange(null);
    }
  }, [
    selectedOrganization,
    selectedPlanChange,
    subscriptionType,
    subscriptionTypeChange,
  ]);

  useEffect(() => {
    secondaryMembersChange(null);
  }, [selectedPlan, secondaryMembersChange]);

  const organizationSubscriptionPlansPromiseOptions = useCallback(() => {
    if (
      !selectedOrganization.organizationId ||
      !subscriptionType ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionType,
      ) === -1
    ) {
      return Promise.resolve([]);
    }

    return getAASubscriptionPlans(
      selectedOrganization.organizationId,
      subscriptionType,
      primaryMember?.dateOfBirth,
      effectiveDate,
      cancellationToken,
    ).then(({ data }) =>
      data.plans.map((plan) => ({
        label: plan.membershipPlanName,
        value: plan,
      })),
    );
  }, [
    selectedOrganization.organizationId,
    selectedOrganization.subscriptions,
    subscriptionType,
    primaryMember?.dateOfBirth,
    effectiveDate,
    cancellationToken,
  ]);

  const clearPromoCode = useCallback(() => {
    promoCodeChange(null);
    setLastPromoCode(null);
    removeErrors([MembershipOrganizationDetailsFormFields.PromoCode]);
  }, [promoCodeChange, removeErrors, setLastPromoCode]);

  const subscriptionsOptions = useMemo(
    () =>
      selectedOrganization
        ? selectedOrganization.subscriptions.map((subscription) => ({
            label: `${formatDate(subscription.effectiveDateFrom)}-${formatDate(
              subscription.effectiveDateTo,
            )}${
              !!subscription.promoCode ? ` - ${subscription.promoCode}` : ''
            }`,
            value: subscription.id,
          }))
        : [],
    [selectedOrganization],
  );

  const getExtensionPrice = useCallback(() => {
    if (
      !selectedPlan.membershipPlanIsEnabled ||
      selectedPlan.availableSeats > 0
    ) {
      return null;
    }

    return `${selectedPlan.price}$`;
  }, [selectedPlan]);

  useEffect(() => {
    if (initSubscriptionId && subscriptionsOptions) {
      subscriptionTypeChange(initSubscriptionId);
    }
  }, [subscriptionsOptions, initSubscriptionId, subscriptionTypeChange]);

  return {
    selectedPlan,
    organizationsPromiseOptions,
    organizationSubscriptionPlansPromiseOptions,
    subscriptionsOptions,
    getExtensionPrice,
    clearPromoCode,
    loadPromoCode,
  };
}
