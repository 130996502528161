import {
  canEditAASubscriptionPermissions,
  canEditDOCSubscriptionPermissions,
  canEditKESubscriptionPermissions,
  canEditSRSubscriptionPermissions,
  canEditTLSubscriptionPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../models/ProductType';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';
import ExtendAASubscriptionPage from './ExtendAASubscriptionPage/ExtendAASubscriptionPage';
import ExtendDOCSubscriptionPage from './ExtendDOCSubscriptionPage/ExtendDOCSubscriptionPage';
import ExtendKESubscriptionPage from './ExtendKESubscriptionPage/ExtendKESubscriptionPage';
import ExtendSRSubscriptionPage from './ExtendSRSubscriptionPage/ExtendSRSubscriptionPage';
import ExtendTLSubscriptionPage from './ExtendTLSubscriptionPage/ExtendTLSubscriptionPage';

export const ExtendSubscriptionPages: Partial<
  Record<ProductType, () => JSX.Element>
> = {
  [ProductType.AirAmbulance]: ExtendAASubscriptionPage,
  [ProductType.DutyOfCare]: ExtendDOCSubscriptionPage,
  [ProductType.KidnapAndExtortion]: ExtendKESubscriptionPage,
  [ProductType.SecurityResponse]: ExtendSRSubscriptionPage,
  [ProductType.TravelLicense]: ExtendTLSubscriptionPage,
};

export const ExtendSubscriptionViewsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canEditAASubscriptionPermissions,
    KidnapAndExtortion: canEditKESubscriptionPermissions,
    SecurityResponse: canEditSRSubscriptionPermissions,
    DutyOfCare: canEditDOCSubscriptionPermissions,
    TravelLicense: canEditTLSubscriptionPermissions,
  };
