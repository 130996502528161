import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Label = styled.p`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
  margin-top: 0.75rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const AndOperator = styled.p`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark2};
`;
