import { StyledInput } from './iconInput.styles';
import { IconInputProps } from './iconInput.models';
import IconInputWrapper from './IconInputWrapper';
import { isPropertyEmpty } from '../../../utils/propertyHelpers';

const IconInput = ({
  defaultIcon,
  icon,
  iconPosition = 'end',
  className,
  isLabelHidden,
  label,
  labelAnimation,
  disabled,
  min,
  max,
  onChange,
  value,
  ...props
}: IconInputProps) => (
  <IconInputWrapper
    defaultIcon={defaultIcon}
    icon={icon}
    iconPosition={iconPosition}
    className={className}
    isLabelHidden={isLabelHidden}
    label={label}
    labelAnimation={labelAnimation}
    disabled={disabled}
  >
    <StyledInput
      {...props}
      defaultIcon={defaultIcon}
      onChange={onChange}
      disabled={disabled}
      value={isPropertyEmpty(value) ? '' : value}
    />
  </IconInputWrapper>
);

export default IconInput;
