import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  DetailsWrapper,
  StyledDeleteButton,
  StyledLeftContainer,
  StyledRightContainer,
} from '../../Common/memberOverviewMembers.styles';
import {
  canEditKEMembershipsOptionsPermissions,
  canRemoveKEMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { AirAmbulanceMembershipOptions } from '../../../../models/enums/AirAmbulanceMembershipOptions';
import { useKEMembershipDetails } from '../Common/keMembershipOverview.hooks';
import { ProductType } from '../../../../models/ProductType';
import DeleteKEMembershipModal from '../Modals/DeleteKEMembershipModal/DeleteKEMembershipModal';
import { keOverviewTablePermissions } from '../Common/keMembershipOverview.const';
import EditKEMembershipOptionsModal from '../Modals/EditKEMembershipOptionsModal/EditKEMembershipOptionsModal';

interface IKEMembershipExpiredDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const KEMembershipExpiredDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: IKEMembershipExpiredDetailsProps) => {
  const { t } = useTranslation();
  const {
    dataLoading,
    membershipDetails,
    changeRenewFlagHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useKEMembershipDetails(membershipId, productType);

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }

  return (
    <>
      <EditKEMembershipOptionsModal id={membershipId} />
      <DetailsWrapper>
        <DeleteKEMembershipModal id={membershipId} />
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            isExpiredMembershipsTable
            onRowClick={onRowClickHandler}
            permissions={keOverviewTablePermissions}
          />
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditKEMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: AirAmbulanceMembershipOptions.SendWelcomeEmail },
              { value: AirAmbulanceMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            canBeRenewed={false}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveKEMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default KEMembershipExpiredDetails;
