import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 21.625rem;
  height: 12.5rem;
  text-align: center;
  margin: 5rem auto;
`;

export const Header = styled.h3`
  font: normal 700 1.125rem/1.6875rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 1rem;
`;

export const Phrase = styled.p`
  font: normal 400 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.core_black};
  margin: 0;
`;
