import { CustomForm } from '../../../../../../../models/types/CustomForm';
import {
  FormSectionTitle,
  SectionWrapper,
} from '../../../../../../../styledComponents/Form/Form';

import FormCheckboxGroup from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import { FormColumn } from '../../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { DOCMembershipOptions } from './docMembershipOptionsForm.const';
import { useDOCMembershipOptions } from './docMembershipOptionsForm.hooks';

interface IMembershipOptionsFormProps extends CustomForm {
  title?: string;
}

const DOCMembershipOptionsForm = ({
  className,
  disabled,
  title,
}: IMembershipOptionsFormProps) => {
  useDOCMembershipOptions();
  return (
    <SectionWrapper className={className}>
      {title && <FormSectionTitle>{title}</FormSectionTitle>}
      <FormColumn>
        <FormCheckboxGroup
          layout="column"
          disabled={disabled}
          options={DOCMembershipOptions}
        />
      </FormColumn>
    </SectionWrapper>
  );
};

export default DOCMembershipOptionsForm;
