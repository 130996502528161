import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ReactModal from 'react-modal';

import { transparentize } from 'polished';

import { THEME } from '../../config/theme';
import Button from '../UI/Button/Button';

export const modalOverlayClass = css`
  align-items: center;
  background-color: ${transparentize(0.2, THEME.color.primary)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`;

export const StyledModal = styled(ReactModal)`
  background: ${THEME.color.white};
  border-radius: 0.25rem;
  margin: 1.5rem;
  min-width: 21rem;
  outline: none;
  position: relative;
  width: 80%;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
