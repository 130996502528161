import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;
