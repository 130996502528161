import { t } from 'i18next';

import { ISelectFilter } from '../../../../../../../models/interfaces/RenewalFilters/SelectFilter';

import {
  allFilterValue,
  CustomFilter,
  FilterDefinition,
} from '../../../renewalFilters.models';
import { SelectFilterOptionsModel } from './selectFilter.models';

export const getSelectFilterTag = (
  filter: CustomFilter<void>,
  filterDefinition: FilterDefinition<SelectFilterOptionsModel>,
) => {
  if (!Array.isArray(filter.value)) {
    return `${t(`filters.tags.${filter?.field?.fieldName}`)} = ${getEnumValue(
      filterDefinition,
    )}`;
  }
  const values = (filter.value as string[]).map((x) =>
    getEnumValue(filterDefinition, x),
  );

  if (values.length < 3) {
    return `${t(`filters.tags.${filter?.field?.fieldName}`)} = ${values.join(
      ` ${t('filters.or')} `,
    )}`;
  }

  return `${t(`filters.tags.${filter?.field?.fieldName}`)} = ${values
    .slice(0, -1)
    .join(', ')} ${t('filters.or')} ${values[values.length - 1]}`;
};

const getEnumValue = (
  filterDefinition: FilterDefinition<SelectFilterOptionsModel>,
  value = '',
) =>
  value === allFilterValue
    ? t(`filters.tags.${value}`)
    : !filterDefinition?.filterOptions?.valueTagLabelPath
    ? value
    : t(`${filterDefinition?.filterOptions?.valueTagLabelPath}.${value}`);

export const getSelectFilterApiModel = (
  filter: CustomFilter<void>,
): ISelectFilter => ({ selectedItems: filter.value as string[] });
