import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export interface IDOCPricingState {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

const initialState: IDOCPricingState = {
  membershipPlansPrices: [],
};

const docPricingSlice = createSlice({
  name: 'docPricing',
  initialState,
  reducers: {
    setDOCMembershipPlansPrices: (
      state,
      { payload }: PayloadAction<IMembershipPlanPriceModel[]>,
    ) => {
      state.membershipPlansPrices = payload;
    },
    clearDOCMembershipPlansPrices: (state) => {
      state.membershipPlansPrices = initialState.membershipPlansPrices;
    },
  },
});

export const { setDOCMembershipPlansPrices, clearDOCMembershipPlansPrices } =
  docPricingSlice.actions;
export default docPricingSlice.reducer;
export const pricingReducerName = docPricingSlice.name;
