import { AnySchema } from 'ajv';

import { ICreateOrganizationModel } from '../../../models/CreateOrganizationModel';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { addressSchema } from '../FormAddress/formAddress.validation';
import { CorporationInformationsFormFields } from './corporationInformationsForm.models';
import { CorporationNameFields } from './CorporationNameForm/corporationNameForm.model';

export const corporationInformationsValidationSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [CorporationNameFields.Name]: {
      type: 'string',
      maxLength: 50,
    },
    [CorporationInformationsFormFields.BillingAddress]: {
      ...addressSchema,
      required: [],
    },
    [CorporationInformationsFormFields.CorrespondenceAddress]: {
      ...addressSchema,
      required: [],
    },
  },
  required: [CorporationNameFields.Name],
};

export const validateOrganizationInformationsForm = (
  data: ICreateOrganizationModel,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(
    data,
    additionalErrors,
    corporationInformationsValidationSchema,
  );
