import { useTranslation } from 'react-i18next';

import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { WrappedPage } from './addClientPage.style';
import { AddClientForm } from './AddClientForm/AddClientForm';
import AddClientModal from './AddClientModal/AddClientModal';
import FormErrorProvider from '../../styledComponents/FormErrorProvider/FormErrorProvider';

const AddClientPage = () => {
  const { t } = useTranslation();

  return (
    <WrappedPage>
      <FormErrorProvider>
        <Header title={t('headers.add-client')} />
        <AddClientForm />
        <AddClientModal />
      </FormErrorProvider>
    </WrappedPage>
  );
};

export default AddClientPage;
