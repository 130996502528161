import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';
import { ErrorLabel } from '../../Field/Field';

import Switch, { ISwitchProps } from '../../Switch/Switch';
import { useFormSwitch } from './formSwitch.hooks';
import { StyledFormFieldWrapper } from './formSwitch.styles';

interface IFormSwitchProps extends FieldRenderProps<boolean>, ISwitchProps {
  disabled?: boolean;
  id?: string;
  label?: string;
}

const FormSwitch = ({
  disabled,
  input,
  meta,
  label,
  className,
  ...props
}: IFormSwitchProps) => {
  const { t } = useTranslation();
  const { onChangeHandler, checked } = useFormSwitch({ input });
  return (
    <StyledFormFieldWrapper
      className={className}
      error={meta.touched && meta.error}
    >
      <Switch
        {...props}
        onChange={onChangeHandler}
        checked={checked}
        disabled={disabled}
        label={t(`fields.${getPropertyName(input.name)}.label`)}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </StyledFormFieldWrapper>
  );
};

export default FormSwitch;
