import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../../../models/types/CustomComponent';
import { StyledButton, Wrapper } from './memberExistsMessage.styles';

interface IFamilyMemberFormMessageProps extends CustomComponent {
  onEditMember?: () => void;
}

const MemberExistsMessage = ({
  disabled,
  onEditMember,
}: IFamilyMemberFormMessageProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <p>{t('phrase.member-already-exists')}</p>
      <StyledButton disabled={disabled} styleType="link" onClick={onEditMember}>
        {t('buttons.edit-member-details')}
      </StyledButton>
    </Wrapper>
  );
};

export default MemberExistsMessage;
