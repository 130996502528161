/* eslint-disable react/no-array-index-key */
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { refreshPage } from '../../utils/refreshPage';
import {
  Title,
  Wrapper,
  Text,
  StyledButton,
  Image,
  Circle,
} from './errorPage.styles';

type ErrorPageProps = {
  title: ReactNode;
  description?: ReactNode[];
  buttonLabel?: string;
  onClick?: () => void;
  iconPath?: string;
};

const ErrorPage = ({
  onClick,
  buttonLabel,
  title,
  description,
  iconPath,
}: ErrorPageProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {iconPath ? <Image src={iconPath} alt="error" /> : <Circle />}
      <Title>{title}</Title>
      {description.map((item, key) => (
        <Text key={key}>{item}</Text>
      ))}
      <StyledButton styleType="lighter-border" onClick={onClick || refreshPage}>
        {buttonLabel || t('buttons.refresh-page')}
      </StyledButton>
    </Wrapper>
  );
};

export default ErrorPage;
