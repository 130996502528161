import styled from '@emotion/styled/macro';

import Button from '../../../../../styledComponents/UI/Button/Button';

export const ButtonContent = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  min-width: 7.375rem;
`;
