import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Props = {
  isSelected: boolean;
  disabled?: boolean;
};

export const LabelContainer = styled('div', {
  shouldForwardProp: isPropValid,
})<Props>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: 0.75rem;
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.dark2}!important;
    `};
`;
