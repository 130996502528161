import { AnySchema } from 'ajv';

import { ValidationCustomFormat } from '../../../../../../../../../utils/validations/customFormats';

import { DateFilterFields } from '../../dateFilter.models';

export const monthsRangeFilterSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [DateFilterFields.From]: {
      type: 'string',
      format: ValidationCustomFormat.MonthsDate,
    },
    [DateFilterFields.To]: {
      type: 'string',
      format: ValidationCustomFormat.MonthsDate,
    },
  },
  required: [DateFilterFields.To, DateFilterFields.From],
};
