import { t } from 'i18next';

import { ProductType } from '../../../../../models/ProductType';
import AirAmbulanceMembership from '../MembershipSectionsForms/AirAmbulanceMembership/AirAmbulanceMembership';
import DutyOfCareMembership from '../MembershipSectionsForms/DutyOfCareMembership/DutyOfCareMembership';
import KidnapAndExtortionMembership from '../MembershipSectionsForms/KidnapAndExtortionMembership/KidnapAndExtortionMembership';
import SecurityResponseMembership from '../MembershipSectionsForms/SecurityResponseMembership/SecurityResponseMembership';
import { MembershipItemBaseProps } from './membershipItemForm.models';

export const getMembershipTitle = (productType: ProductType, number = 0) =>
  `${t('phrase.membership-item-title', {
    context: productType ? 'filled' : 'empty',
    count: number,
    productType,
  })}`;

export const MembershipTypeContent: Partial<
  Record<
    ProductType,
    ({ name, disabled }: MembershipItemBaseProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AirAmbulanceMembership,
  [ProductType.DutyOfCare]: DutyOfCareMembership,
  [ProductType.KidnapAndExtortion]: KidnapAndExtortionMembership,
  [ProductType.SecurityResponse]: SecurityResponseMembership,
  [ProductType.TravelLicense]: null,
};
