import { call } from 'typed-redux-saga/macro';

import { IMembershipPlanModel } from '../../../../models/interfaces/Membership/MembershipPlanModel';

import { getRegularAAPlansQuery } from './api';

export function* getAAPlanById(id: string) {
  let result: IMembershipPlanModel = null;
  if (!id) {
    return result;
  }

  const response = yield* call(getRegularAAPlansQuery);
  const index = response.data.plans.findIndex(
    (plan) => plan.membershipPlanId === id,
  );
  if (index !== -1) {
    result = response.data.plans[index];
  }

  return result;
}
