import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { FractionRenderer } from '../../../../MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../MaterialTable/CellRenderers/cellRenderers.models';

export const cellRenderer =
  (
    key: keyof IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel,
    options?: ICellOptions,
  ) =>
  ({
    previousCapacity,
    capacitySuggestion,
    previousMembershipsInUse,
  }: IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel) => {
    switch (key) {
      case 'previousCapacity': {
        return (
          <FractionRenderer
            maxValue={previousCapacity}
            options={options}
            value={previousMembershipsInUse}
          />
        );
      }
      case 'capacitySuggestion': {
        return capacitySuggestion || 0;
      }
    }
  };
