import {
  canViewAAMembersPermissions,
  canViewDOCMembersPermissions,
  canViewGeneralMembersPermissions,
  canViewKEMembersPermissions,
  canViewSRMembersPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../../../../models/enums/RoutePath';
import { ProductType } from '../../../../../models/ProductType';
import {
  AvailableProductPermissionsType,
  AvailableProductsType,
} from '../../../../../models/types/AvailableProductViewsPermissionsType';
import { AvailableQueryParams } from '../../../../../styledComponents/MaterialTable/materialTable.models';

export const Views: AvailableProductsType = {
  General: RoutePath.ProductGeneralPage,
  [ProductType.AirAmbulance]: RoutePath.ProductAirAmbulancePage,
  [ProductType.KidnapAndExtortion]: RoutePath.ProductKidnapAndExtortionPage,
  [ProductType.SecurityResponse]: RoutePath.ProductSecurityResponsePage,
  [ProductType.DutyOfCare]: RoutePath.ProductDutyOfCarePage,
};

export const RequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];

export const MembersPagesPermissions: AvailableProductPermissionsType = {
  General: canViewGeneralMembersPermissions,
  AirAmbulance: canViewAAMembersPermissions,
  KidnapAndExtortion: canViewKEMembersPermissions,
  SecurityResponse: canViewSRMembersPermissions,
  DutyOfCare: canViewDOCMembersPermissions,
};
