import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { ActionsWrapper } from '../../../../../styledComponents/OverviewPage/OverviewPage';
import Button from '../../../../../styledComponents/UI/Button/Button';
import { ProductSubscriptionActionsPermissions } from './productSubscriptionActions.models';
import { StyledButton } from './productSubscriptionActions.styles';

type ProductSubscriptionActionsProps = CustomComponent & {
  onDeleteProductSubscription?: () => void;
  onExtendProductSubscription?: () => void;
  onAddMember?: () => void;
  onEditSubscription?: () => void;
  canAddMember?: boolean;
  canEditSubscription?: boolean;
  canExtend?: boolean;
  canDelete?: boolean;
  permissions?: ProductSubscriptionActionsPermissions;
};

export const ProductSubscriptionActions = ({
  onDeleteProductSubscription,
  canAddMember,
  canEditSubscription,
  canExtend,
  canDelete,
  className,
  onAddMember,
  onExtendProductSubscription,
  onEditSubscription,
  permissions,
}: ProductSubscriptionActionsProps) => {
  const { t } = useTranslation();

  return (
    <ActionsWrapper className={className}>
      {canAddMember && (
        <StyledButton
          permissions={permissions?.canAddMemberPermissions}
          onClick={onAddMember}
          styleType="lighter-blue"
        >
          {t('buttons.add-member')}
        </StyledButton>
      )}
      {canExtend && (
        <StyledButton
          permissions={permissions?.canAddSeatsSubscriptionPermissions}
          onClick={onExtendProductSubscription}
          styleType="light1"
        >
          {t('buttons.add-seats')}
        </StyledButton>
      )}
      {canEditSubscription && (
        <StyledButton
          permissions={permissions?.canEditSubscriptionPermissions}
          styleType="light1"
          onClick={onEditSubscription}
        >
          {t('buttons.edit')}
        </StyledButton>
      )}
      {canDelete && (
        <Button
          permissions={permissions?.canRemoveSubscriptionPermissions}
          styleType="delete"
          onClick={onDeleteProductSubscription}
        >
          {t('buttons.delete')}
        </Button>
      )}
    </ActionsWrapper>
  );
};
