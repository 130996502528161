import { AnySchema } from 'ajv';

import { FieldErrorMessage } from 'models/FieldErrorMessage';

import { IGetFinanceReport } from '../../../../../models/interfaces/GetFinanceReport';

import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { FinanceReportFields } from './financeReportsForm.models';

export const reportsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [FinanceReportFields.Date]: {
      type: 'string',
    },
  },
  required: [FinanceReportFields.Date],
};

export const validateFinanceReportSchemaForm = (
  data: IGetFinanceReport,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, reportsSchema);
