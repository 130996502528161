import styled from '@emotion/styled/macro';

import ArrowRight from '../../../assets/icons/arrow-right.svg';
import ArrowDown from '../../../assets/icons/Arrow-Down.svg';

export const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
`;

export const Header = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
`;

export const Title = styled.div`
  font: 500 normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.secondary1};
  cursor: pointer;
`;

type CollapseIndicatorType = {
  isOpen?: boolean;
};

export const CollapseIndicator = styled.div<CollapseIndicatorType>`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  transition: all 0.2s ease;
  ${({ isOpen }) =>
    isOpen
      ? `background: url(${ArrowDown}) 50% no-repeat;`
      : `background: url(${ArrowRight}) 50% no-repeat;`};
`;
