import { useTranslation } from 'react-i18next';

import { IPersonMembershipItem } from '../../../../../models/PersonMembershipItem';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import MembershipRelation from '../../../../../styledComponents/MembershipRelation/MembershipRelation';
import { StandardText } from '../../../../../styledComponents/Typography/Typography';
import { formatDate } from '../../../../../utils/dateHelpers';
import {
  ContentContainer,
  ProductName,
  PropertyContainer,
  PropertyName,
  StyledCard,
  Title,
} from './subscriptionItem.styles';

type SubscriptionItemProps = CustomComponent & {
  membership: IPersonMembershipItem;
};

const SubscriptionItem = ({ membership }: SubscriptionItemProps) => {
  const { t } = useTranslation();
  return (
    <StyledCard>
      <Title>
        <ProductName>{membership.productSubscription}</ProductName>
        <StandardText>/</StandardText>
        <StandardText>{membership.membershipPlan}</StandardText>
      </Title>
      <ContentContainer>
        <PropertyContainer>
          <PropertyName>{`${t(
            'properties.membershipRelationship',
          )}:`}</PropertyName>
          <MembershipRelation
            organizationName={membership.organizationName}
            organizationId={membership.organizationId}
            isRetail={membership.isRetail}
          />
        </PropertyContainer>
        <PropertyContainer>
          <PropertyName>{`${t('properties.expirationDate')}:`}</PropertyName>
          <StandardText>{formatDate(membership.expirationDate)}</StandardText>
        </PropertyContainer>
      </ContentContainer>
    </StyledCard>
  );
};

export default SubscriptionItem;
