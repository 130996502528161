import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { ProductSubscriptionRenewPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import { organizationSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import {
  clearDOCCurrentSubscriptionEditing,
  clearDOCSubscriptionHelperData,
} from '../../../store/redux-slices/subscriptions/dutyOfCare';
import {
  docSubscriptionRenewalSuggestionSelector,
  editingDOCSubscriptionDataSelector,
} from '../../../store/redux-slices/subscriptions/dutyOfCare/selectors';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';
import {
  loadDatesForNewDOCSubscription,
  loadDOCSubscriptionRenewalSuggestion,
  loadEditingDOCSubscription,
} from '../../../store/saga-slices/subscriptions/dutyOfCare';
import {
  ILoadDOCSubscriptionRenewalSuggestionPayload,
  ILoadEditingDOCSubscriptionPayload,
} from '../../../store/saga-slices/subscriptions/dutyOfCare/models';

export const useDOCEditSubscriptionRenewPage = () => {
  const { organizationId, subscriptionId, productType } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const LoadOrganization = useActions(loadOrganization);
  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(
    loadDatesForNewDOCSubscription,
  );
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadEditingSubscription = useActions(loadEditingDOCSubscription);

  const ClearDOCSubscriptionHelperData = useActions(
    clearDOCSubscriptionHelperData,
  );
  const subscriptionData = useSelector(editingDOCSubscriptionDataSelector);

  const ClearCurrentSubscriptionEditing = useActions(
    clearDOCCurrentSubscriptionEditing,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);
  const LoadSubscriptionRenewalSuggestion = useActions(
    loadDOCSubscriptionRenewalSuggestion,
  );

  const subscriptionRenewalSuggestion = useSelector(
    docSubscriptionRenewalSuggestionSelector,
  );

  const isOrganizationDataLoading = useProcessing(
    ORGANIZATION_LOADING_PROCESSING,
  );

  const isEditingDataLoading = useProcessing(
    SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  );

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const organization = useSelector(organizationSelector);

  const loadSubscriptionRenewalSuggestionHandler = useCallback(() => {
    const payload: ILoadDOCSubscriptionRenewalSuggestionPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionRenewalSuggestion(payload);
  }, [LoadSubscriptionRenewalSuggestion, organizationId, subscriptionId]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    const payload: ILoadEditingDOCSubscriptionPayload = {
      organizationId,
      subscriptionId,
      subscriptionType: productType,
    };

    LoadEditingSubscription(payload);
  }, [LoadEditingSubscription, organizationId, productType, subscriptionId]);

  useEffect(() => {
    LoadOrganizationContracts({ organizationId });
    LoadOrganization(organizationId);
    loadSubscriptionRenewalSuggestionHandler();
  }, [
    LoadOrganization,
    LoadOrganizationContracts,
    organizationId,
    subscriptionId,

    loadSubscriptionRenewalSuggestionHandler,
  ]);

  useEffect(
    () => () => {
      ClearDOCSubscriptionHelperData();
      ClearOrganizationContracts();
      ClearSubscriptionHelperData();
      ClearCurrentSubscriptionEditing();
    },
    [
      ClearOrganizationContracts,
      ClearDOCSubscriptionHelperData,
      ClearSubscriptionHelperData,
      ClearCurrentSubscriptionEditing,
    ],
  );

  const dataLoading =
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionData ||
    (!subscriptionData && isEditingDataLoading) ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading);

  return {
    dataLoading,
    organization,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  };
};
