import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import {
  AddMemberAvailableSearchParams,
  EditProductSubscriptionPageParams,
  EditProductSubscriptionRenewPageParams,
  ExtendProductSubscriptionPageParams,
  OrganizationMembersListPageParams,
  RoutePath,
} from '../../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { clearAACurrentSubscription } from '../../../../../store/redux-slices/subscriptions/airAmbulance';
import { aaSubscriptionOverviewSelector } from '../../../../../store/redux-slices/subscriptions/airAmbulance/selectors';
import { loadAASubscriptionOverview } from '../../../../../store/saga-slices/subscriptions/airAmbulance';
import { ILoadSubscriptionOverviewPayload } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME } from './Modals/DeleteSubscriptionModal/deleteSubscriptionModal.const';
import { organizationNameSelector } from '../../../../../store/redux-slices/organizations/selectors';
import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import { EDITING_SUBSCRIPTION_DETAILS_MODAL_NAME } from './Modals/SubscriptionEditDetailsModal/subscriptionEditDetailsModal.consts';
import { AAMembersListFilters } from '../../../../../styledComponents/ProductMembersList/AAMembersList/AAMembersFilters/aaMembersFilters.models';
import { MemberType } from '../../../../../models/enums/MemberType';
import { ProductType } from '../../../../../models/ProductType';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { EditRenewalSubscriptionStatusModalDetails } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.models';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.const';

export const useProductSubscriptionOverviewTabPage = () => {
  const { id, subscriptionId } = useParams();
  const navigate = useNavigateSearch();

  const LoadSubscriptionOverview = useActions(loadAASubscriptionOverview);
  const ClearCurrentSubscription = useActions(clearAACurrentSubscription);

  const subscriptionOverviewLoading = useProcessing(
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  );
  const subscriptionOverview = useSelector(aaSubscriptionOverviewSelector);

  const loadSubscriptionOverviewHandler = useCallback(() => {
    const payload: ILoadSubscriptionOverviewPayload = {
      organizationId: id,
      subscriptionId,
    };
    LoadSubscriptionOverview(payload);
  }, [LoadSubscriptionOverview, id, subscriptionId]);

  useEffect(() => {
    loadSubscriptionOverviewHandler();
  }, [loadSubscriptionOverviewHandler]);

  useEffect(
    () => () => {
      ClearCurrentSubscription();
    },
    [ClearCurrentSubscription],
  );

  return {
    id,
    subscriptionId,
    navigate,
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  };
};

export const useSubscriptionOverviewActions = () => {
  const { t } = useTranslation();
  const { id, subscriptionId, subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();
  const organizationName = useSelector(organizationNameSelector);
  const UpdateModalState = useActions(updateModalState);
  const navigate = useNavigateSearch();
  const subscriptionOverview = useSelector(aaSubscriptionOverviewSelector);

  const deleteProductSubscriptionHandler = () => {
    UpdateModalState([
      DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME,
      {
        name: t('phrase.air-ambulance-for', {
          value: organizationName,
        }),
        organizationId: id,
        subscriptionId,
      },
    ]);
  };

  const addMemberHandler = () => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.OrganizationId]: id,
        [AddMemberAvailableSearchParams.SubscriptionId]: subscriptionId,
        [AddMemberAvailableSearchParams.ProductType]: ProductType.AirAmbulance,
      },
    });
  };

  const editSubscriptionHandler = () => {
    if (subscriptionOverview?.isRenewation) {
      navigate(RoutePath.EditClientSubscriptionRenewPage, {
        params: {
          [EditProductSubscriptionRenewPageParams.OrganizationId]: id,
          [EditProductSubscriptionRenewPageParams.SubscriptionId]:
            subscriptionId,
          [EditProductSubscriptionRenewPageParams.ProductType]:
            ProductType.AirAmbulance,
        },
      });
      return;
    }

    navigate(RoutePath.EditProductSubscription, {
      params: {
        [EditProductSubscriptionPageParams.OrganizationId]: id,
        [EditProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [EditProductSubscriptionPageParams.SubscriptionType]:
          ProductType.AirAmbulance,
      },
    });
  };

  const extendProductSubscriptionHandler = () => {
    navigate(RoutePath.ExtendProductSubscription, {
      params: {
        [ExtendProductSubscriptionPageParams.OrganizationId]: id,
        [ExtendProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [ExtendProductSubscriptionPageParams.ProductType]:
          ProductType.AirAmbulance,
      },
    });
  };

  const onRowClickHandler = (
    data?: IOrganizationSubscriptionOverviewMembershipPlan,
  ) => {
    const searchParams = {
      [AAMembersListFilters.SubscriptionId]: subscriptionId,
      [AAMembersListFilters.MemberType]: MemberType.Primary,
    };

    if (data) {
      searchParams[AAMembersListFilters.Plan] = data.membershipPlanName;
    }
    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const onSeeOversubscribedMembersHandler = (
    data?: IOrganizationSubscriptionOverviewMembershipPlan,
  ) => {
    const searchParams = {
      [AAMembersListFilters.SubscriptionId]: subscriptionId,
      [AAMembersListFilters.OversubscribedOnly]: 'true',
      [AAMembersListFilters.MemberType]: MemberType.Primary,
    };

    if (data) {
      searchParams[AAMembersListFilters.Plan] = data.membershipPlanName;
    }

    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const showEditSubscriptionDetailsModal = () => {
    UpdateModalState([
      EDITING_SUBSCRIPTION_DETAILS_MODAL_NAME,
      { data: subscriptionOverview },
    ]);
  };

  const editRenewalStatusHandler = () => {
    const details: EditRenewalSubscriptionStatusModalDetails = {
      organizationId: id,
      subscriptionId,
      renewalStatus: subscriptionOverview?.renewalStatus,
      contractName: subscriptionOverview?.organizationContractName,
      organizationName,
      expirationDate: subscriptionOverview?.effectiveDateTo,
      productType: subscriptionType,
    };

    UpdateModalState([EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME, details]);
  };

  return {
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    addMemberHandler,
    onRowClickHandler,
    showEditSubscriptionDetailsModal,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    editRenewalStatusHandler,
  };
};
