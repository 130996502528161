import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IDOCPackagesState } from './index';
import { DutyOfCareSubscriptionPackageType } from '../../../../models/enums/DutyOfCareSubscriptionPackageType';

export const docPackagesStateSelector = (state: RootState) => state.docPackages;

export const docPackagesSelector = createSelector(
  docPackagesStateSelector,
  (packagesState: IDOCPackagesState): DutyOfCareSubscriptionPackageType[] =>
    packagesState.packages,
);
