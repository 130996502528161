import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { putResolve } from '@redux-saga/core/effects';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  DELETE_MEMBERSHIP_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
  MEMBERSHIP_CREATING_PROCESSING,
  MEMBERSHIP_SUMMARY_LOADING_PROCESSING,
  MEMBERS_LOADING_PROCESSING,
  NEW_MEMBERSHIP_DATES_LOAD_PROCESSING,
  RESEND_WELCOME_EMAIL_PROCESSING,
  SECONDARY_MEMBER_ADDING_PROCESSING,
  SECONDARY_MEMBER_DELETE_PROCESSING,
  UPDATE_MEMBERSHIP_OPTIONS_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  getKEExistingMembershipSummary,
  getKEMembershipSummary,
  createKEOrganizationMembership,
  getDatesForNewKEMemberships,
  getKEMembershipDetails,
  putKEMemberToBeRenewed,
  updateKEMembershipOptionsRequest,
  addKESecondaryMemberRequest,
  deleteKESecondaryMemberRequest,
  postResendKEWelcomeEmail,
  deleteKEMembershipRequest,
  getKEMembershipsMembers,
} from './api';
import { Log } from '../../../../utils/logger';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';

import { prepareCreateKEOrganizationMembershipRequestData } from '../common/utils';
import {
  setMembership,
  setMembershipSummary,
} from '../../../redux-slices/membership/common';
import {
  IAddSecondaryKEMemberPayload,
  IChangeKEMemberRenewFlagPayload,
  IDeleteKEMembershipPayload,
  IDeleteSecondaryKEMemberPayload,
  ILoadExistingKEMembershipSummaryPayload,
  ILoadKEMembershipDetailsPayload,
  ILoadKEMembershipSummaryPayload,
  ILoadKEMembersPayload,
  ILoadNewKEMembershipDatesPayload,
  IResendKEWelcomeEmail,
  ISubmitCreateKEMembershipPayload,
  IUpdateKEMembershipOptionsPayload,
} from './models';
import {
  setKEMembers,
  setKEMembershipDates,
  setKEMembershipDetails,
  setKEMembershipSummary,
} from '../../../redux-slices/membership/kidnapAndExtortion';
import { removePersonMembership } from '../../../redux-slices/people';

const keMembershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateKEMembership({
      payload: { data, success, error },
    }: PayloadAction<ISubmitCreateKEMembershipPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
        yield putResolve(setMembership(data));

        const {
          data: requestData,
          organizationId,
          subscriptionId,
        } = yield* call(prepareCreateKEOrganizationMembershipRequestData);
        yield call(
          createKEOrganizationMembership,
          organizationId,
          subscriptionId,
          requestData,
        );

        success?.();
      } catch (err: any) {
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
      }
    },
    *loadKEMembershipSummary({
      payload: {
        organizationId,
        subscriptionId,
        membershipPlanId,
        success,
        error,
        cancellationToken,
      },
    }: PayloadAction<ILoadKEMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getKEMembershipSummary,
          organizationId,
          subscriptionId,
          membershipPlanId,
          cancellationToken,
        );
        yield put(setMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadExistingKEMembershipSummary({
      payload: { membershipId, success, error },
    }: PayloadAction<ILoadExistingKEMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getKEExistingMembershipSummary,
          membershipId,
        );
        yield put(setKEMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadNewKEMembershipDates({
      payload: { subscriptionId, cancellationToken, success, error },
    }: PayloadAction<ILoadNewKEMembershipDatesPayload>) {
      try {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
        const response = yield* call(
          getDatesForNewKEMemberships,
          subscriptionId,
          cancellationToken,
        );
        yield put(setKEMembershipDates(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
      }
    },
    *loadKEMembershipDetails({
      payload: { success, error, membershipId },
    }: PayloadAction<ILoadKEMembershipDetailsPayload>) {
      try {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
        const response = yield* call(getKEMembershipDetails, membershipId);
        yield put(setKEMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
      }
    },
    *changeKEMemberRenewFlag({
      payload: { membershipId, memberId, toBeRenewed, success, error },
    }: PayloadAction<IChangeKEMemberRenewFlagPayload>) {
      try {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
        yield call(putKEMemberToBeRenewed, membershipId, memberId, toBeRenewed);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
      }
    },
    *updateKEMembershipOptions({
      payload: { membershipId, cancellationToken, options, success, error },
    }: PayloadAction<IUpdateKEMembershipOptionsPayload>) {
      try {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
        const response = yield* call(
          updateKEMembershipOptionsRequest,
          membershipId,
          options,
          cancellationToken,
        );
        yield put(setKEMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
      }
    },
    *addSecondaryKEMember({
      payload: { success, error, data, membershipId },
    }: PayloadAction<IAddSecondaryKEMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
        yield call(addKESecondaryMemberRequest, membershipId, data);

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
      }
    },
    *deleteSecondaryKEMember({
      payload: { membershipId, memberId, success, error, cancellationToken },
    }: PayloadAction<IDeleteSecondaryKEMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
        yield call(
          deleteKESecondaryMemberRequest,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
      }
    },
    *resendKEWelcomeEmail({
      payload: {
        cancellationToken,
        membershipId,
        personId,
        data,
        success,
        error,
      },
    }: PayloadAction<IResendKEWelcomeEmail>) {
      try {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
        yield call(
          postResendKEWelcomeEmail,
          membershipId,
          personId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
      }
    },
    *deleteKEMembership({
      payload: { membershipId, cancellationToken, isActive, success, error },
    }: PayloadAction<IDeleteKEMembershipPayload>) {
      try {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
        yield call(
          deleteKEMembershipRequest,
          membershipId,
          isActive,
          cancellationToken,
        );

        yield put(removePersonMembership({ membershipId }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
      }
    },
    *loadKEMembers({
      payload: { success, error, params, organizationId },
    }: PayloadAction<ILoadKEMembersPayload>) {
      try {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
        const response = yield* call(
          getKEMembershipsMembers,
          organizationId,
          params,
        );
        yield put(setKEMembers(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
      }
    },
  },
  name: 'ke-members-saga',
  sagaType: SagaType.TakeLatest,
});

export default keMembershipSlice.saga;
export const {
  submitCreateKEMembership,
  loadExistingKEMembershipSummary,
  loadKEMembershipSummary,
  loadNewKEMembershipDates,
  loadKEMembershipDetails,
  changeKEMemberRenewFlag,
  updateKEMembershipOptions,
  addSecondaryKEMember,
  deleteSecondaryKEMember,
  resendKEWelcomeEmail,
  deleteKEMembership,
  loadKEMembers,
} = keMembershipSlice.actions;
export const { actions } = keMembershipSlice;
