import { ICreatePrimaryMemberModel } from '../models/CreatePrimaryMemberModel';
import { IDOCMemberListItem } from '../models/interfaces/Membership/DutyOfCare/IDOCMemberListItem';
import { IMemberListItem } from '../models/MemberListItem';
import { IPerson } from '../models/Person';
import { ISearchPerson } from '../models/SearchPerson';
import { parseDate } from './dateHelpers';

export const personToSearchPerson = (person: IPerson): ISearchPerson => ({
  contactEmailAddress: person.contactEmailAddress,
  correspondenceAddress: person.correspondenceAddress,
  correspondenceAddressSameAsResidenceAddress:
    person.correspondenceAddressIsSameAsResidenceAddress,
  firstName: person.firstName,
  gender: person.gender,
  lastName: person.lastName,
  personId: person.personId,
  phone: person.phone,
  residenceAddress: person.residenceAddress,
  dateOfBirth: person.dateOfBirth,
  prefix: person.prefix,
});

export function searchPersonToCreatePrimaryMemberModel(
  personItem: ISearchPerson,
): ICreatePrimaryMemberModel {
  return (
    personItem && {
      correspondenceAddress: personItem.correspondenceAddress,
      correspondenceAddressSameAsResidenceAddress:
        personItem.correspondenceAddressSameAsResidenceAddress,
      residenceAddress: personItem.residenceAddress,
      dateOfBirth: parseDate(personItem.dateOfBirth),
      emailAddress: personItem.contactEmailAddress,
      firstName: personItem.firstName,
      lastName: personItem.lastName,
      gender: personItem.gender,
      personId: personItem.personId,
      personPrefix: personItem.prefix,
      phoneNumber: personItem.phone,
    }
  );
}

export const personToMemberListItem = (
  person: IPerson,
  member: IMemberListItem,
): IMemberListItem => ({
  ...member,
  dateOfBirth: person?.dateOfBirth,
  name: `${person?.firstName} ${person?.lastName}`,
});

export const personToDOCMemberListItem = (
  person: IPerson,
  member: IDOCMemberListItem,
): IDOCMemberListItem => ({
  ...member,
  dateOfBirth: person?.dateOfBirth,
  name: `${person?.firstName} ${person?.lastName}`,
});
