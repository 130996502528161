import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import BaseInput from '../../BaseInput/BaseInput';
import { Textarea } from '../../Textarea/Textarea';

type StyledTextareaType = {
  error?: boolean;
};

export const StyledTextarea = styled(Textarea)<StyledTextareaType>`
  width: 100%;

  ${({ error, theme }) =>
    error &&
    css`
      ${BaseInput} {
        border-color: ${theme.color.error};
      }
    `}
`;
