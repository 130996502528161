import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { StandardText } from '../../../Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type PriceProps = {
  overridden: boolean;
};
export const Price = styled(StandardText)<PriceProps>`
  ${({ overridden, theme }) => css`
    ${overridden &&
    css`
      color: ${theme.color.dark2};
      text-decoration: line-through;
    `}
  `}
`;
