import { getId } from '../../../../utils/idHelpers';
import { FilterType } from './FiltersForm/filterForm.models';
import {
  FilterAdditionalValuesModel,
  FiltersOptions,
} from './FiltersForm/FilterTypes/filtersTypes.models';
import {
  CustomFilter,
  CustomFilterField,
  CustomFilterValue,
  FilterDefinition,
} from './renewalFilters.models';

const ADDITIONAL_VALUES_CHAR_START = '[';
const ADDITIONAL_VALUES_CHAR_END = ']';
const ADDITIONAL_VALUES_CHAR_SEPARATOR = ';';
const TYPE_SEPARATOR = '|';
const EQUAL_SEPARATOR = '=';
const MULTI_VALUES_CHAT_START = '(';
const MULTI_VALUES_CHAT_END = ')';
const MULTI_VALUE_SEPARATOR = '|';

export const encodeFilterValue = (filter: CustomFilter) => {
  const additionalValues = encodeAdditionalValues(filter.additionalValues);
  const basicValues = encodeBasicValues(filter);

  if (!additionalValues) {
    return basicValues;
  }

  return `${basicValues}${additionalValues}`;
};

const encodeAdditionalValues = (data: FilterAdditionalValuesModel) => {
  if (!data || JSON.stringify(data) === '{}') {
    return null;
  }

  const additionalValuesString = Object.keys(data)
    .map((x) => `${x}${EQUAL_SEPARATOR}${encodeMultiValue(data[x])}`)
    .join(ADDITIONAL_VALUES_CHAR_SEPARATOR);
  return `${ADDITIONAL_VALUES_CHAR_START}${additionalValuesString}${ADDITIONAL_VALUES_CHAR_END}`;
};

export const encodeMultiValue = (value: CustomFilterValue) => {
  if (Array.isArray(value)) {
    return `${MULTI_VALUES_CHAT_START}${value
      .map((x) => JSON.stringify(x))
      .join(MULTI_VALUE_SEPARATOR)}${MULTI_VALUES_CHAT_END}`;
  }

  return JSON.stringify(value);
};

const encodeBasicValues = (filter: CustomFilter) =>
  `${encodeMultiValue(filter.value)}${TYPE_SEPARATOR}${
    filter.field.filterType
  }`;

export const decodeCustomFilter = (
  filterValue: string,
  fieldName: string,
): CustomFilter => {
  const additionalValues = getAdditionalValuesFromStringFilter(
    getAdditionalValuesString(filterValue),
  );
  const basicDetails = getCustomFilterBasicDetails(
    getBasicValuesString(filterValue),
  );
  const field: CustomFilterField = {
    fieldName,
    filterType: basicDetails.filterType,
  };

  return { field, value: basicDetails.value, id: getId(), additionalValues };
};

const getCustomFilterBasicDetails = (
  basicDetailsString: string,
): { value: CustomFilterValue; filterType: FilterType } => {
  const basicValues = basicDetailsString.split(TYPE_SEPARATOR);
  return {
    value: decodeMultiValue(basicValues[0]),
    filterType: basicValues[1] as FilterType,
  };
};

const hasAdditionalValues = (value: string) =>
  value.indexOf(ADDITIONAL_VALUES_CHAR_START) !== -1 &&
  value.indexOf(ADDITIONAL_VALUES_CHAR_END) !== -1;

const getAdditionalValuesString = (value: string) =>
  value.substring(
    value.indexOf(ADDITIONAL_VALUES_CHAR_START) + 1,
    value.lastIndexOf(ADDITIONAL_VALUES_CHAR_END),
  );

const getBasicValuesString = (value: string) => {
  if (hasAdditionalValues(value)) {
    return value.substring(0, value.lastIndexOf(ADDITIONAL_VALUES_CHAR_START));
  }

  return value;
};

const getAdditionalValuesFromStringFilter = (
  additionalValuesString: string,
) => {
  if (!additionalValuesString) {
    return null;
  }

  const values = additionalValuesString
    .split(ADDITIONAL_VALUES_CHAR_SEPARATOR)
    .map((x) => x.split(EQUAL_SEPARATOR));

  const additionalValues = {};
  values.forEach(([property, value]) => {
    additionalValues[property] = decodeMultiValue(value);
  });

  return additionalValues;
};

export const decodeMultiValue = (value: string) => {
  if (!value || value[0] !== MULTI_VALUES_CHAT_START) {
    return JSON.parse(value);
  }

  const values = value
    .substring(
      value.indexOf(MULTI_VALUES_CHAT_START) + 1,
      value.lastIndexOf(MULTI_VALUES_CHAT_END),
    )
    .split(MULTI_VALUE_SEPARATOR)
    .map((x) => JSON.parse(x));

  return values;
};

export const mergeDefinitionsWithFilter = (
  filter: CustomFilter,
  filtersDefinitions: FilterDefinition<FiltersOptions>[],
): CustomFilter => ({
  ...filter,
  field: {
    ...filter.field,
    ...findFilterDefinition(filtersDefinitions, filter.field.fieldName),
  },
});

export const findFilterDefinition = (
  filtersDefinitions: FilterDefinition<FiltersOptions>[],
  field: string,
) => {
  const index = filtersDefinitions.findIndex((x) => x.field === field);
  if (index === -1) {
    return null;
  }

  return filtersDefinitions[index];
};
