/* eslint-disable no-undefined */
import { useCallback, useContext, useRef, useState } from 'react';
import { getIn, setIn } from 'final-form';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';

import { preparePathFromResponse } from '../../utils/pathHelpers';

import {
  FormErrorActionableContext,
  FormErrorInfoContext,
} from './FormErrorProvider';
import { IApiValidationError } from '../../models/ApiValidationError';
import { FieldErrorMessage } from '../../models/FieldErrorMessage';
import { IValidationErrorResponse } from '../../models/ValidationErrorResponse';
import { IWarningRatification } from '../../models/interfaces/WarningRatification';

type UseFormErrorType = {
  initErrors?: IApiValidationError[];
  initErrorGroups?: string[];
};

export function useFormErrors<T>({
  initErrors,
  initErrorGroups,
}: UseFormErrorType = {}) {
  const prevValues = useRef({});
  const { t } = useTranslation();

  const prepareFieldErrorMessages = useCallback(
    (additionalErrors?: IApiValidationError[]): FieldErrorMessage[] => {
      if (!additionalErrors || additionalErrors.length === 0) {
        return [];
      }
      return additionalErrors.map((err) => ({
        field: preparePathFromResponse(err.PropertyName),
        message: t(`api-validation.${err.ErrorCode}`),
        internalError: err.InternalError,
      }));
    },
    [t],
  );

  const [errorGroups] = useState<string[]>(initErrorGroups || []);

  const [errors, setErrors] = useState<FieldErrorMessage[]>(
    prepareFieldErrorMessages(initErrors),
  );

  const validateErrors = useCallback(
    (values: T): FieldErrorMessage[] => {
      const newErrors: FieldErrorMessage[] = [];

      if (errors === null || prevValues.current === null) {
        return null;
      }

      errors.forEach((error: FieldErrorMessage) => {
        if (
          getIn(prevValues.current, error.field) ===
          getIn(values as Record<string, unknown>, error.field)
        ) {
          newErrors.push(error);
        }
      });

      return newErrors;
    },
    [errors],
  );

  const updateValue = (value: any, propertyPath: string) => {
    prevValues.current = setIn(prevValues.current, propertyPath, value) || {};
  };

  const addError = useCallback(
    (apiError: IApiValidationError, value: T) => {
      const error: IApiValidationError = {
        ...apiError,
        PropertyName: preparePathFromResponse(apiError.PropertyName),
      };
      updateValue(value, error.PropertyName);

      setErrors((state) => {
        const newState = state.filter((x) => error.PropertyName !== x.field);
        return [...newState, ...prepareFieldErrorMessages([error])];
      });
    },
    [prepareFieldErrorMessages, setErrors],
  );

  const mapErrorsToGroup = useCallback(
    (apiErrors: IApiValidationError[]) => {
      const resultErrors: IApiValidationError[] = [];
      if (!errorGroups || errorGroups.length === 0) {
        return apiErrors;
      }
      apiErrors.forEach((error) => {
        const groupIndex = errorGroups.findIndex((group) =>
          error.PropertyName.toLocaleLowerCase().includes(
            group.toLocaleLowerCase(),
          ),
        );
        if (groupIndex === -1) {
          resultErrors.push(error);
          return;
        }
        const currentErrorIndex = resultErrors.findIndex(
          (currentError) =>
            currentError.PropertyName === errorGroups[groupIndex],
        );

        if (currentErrorIndex !== -1) {
          return;
        }

        resultErrors.push({ ...error, PropertyName: errorGroups[groupIndex] });
      });

      return resultErrors;
    },
    [errorGroups],
  );

  const addErrors = useCallback(
    (apiErrors: IApiValidationError[], values: any) => {
      mapErrorsToGroup(apiErrors).forEach((error) => {
        const value = getIn(
          values,
          preparePathFromResponse(error.PropertyName),
        );
        addError(error, value);
      });
    },
    [addError, mapErrorsToGroup],
  );

  const removeErrors = useCallback(
    (propertiesNames: string[]) => {
      setErrors((state) => {
        const newState = state.filter((x) =>
          propertiesNames.some((y) => y !== x.field),
        );
        return [...newState];
      });
    },
    [setErrors],
  );

  const clearErrors = useCallback(() => setErrors([]), [setErrors]);

  return {
    validateErrors,
    addErrors,
    addError,
    removeErrors,
    errors,
    clearErrors,
  };
}

export function useFormErrorsActions() {
  const { addErrors, addError, removeErrors, validateErrors, clearErrors } =
    useContext(FormErrorActionableContext);

  return { addErrors, removeErrors, validateErrors, addError, clearErrors };
}

export function useFormErrorsValues() {
  const { errors } = useContext(FormErrorInfoContext);

  return { errors };
}

export type UseApiResponseErrorType = {
  withAlert?: boolean;
  externalAddErrors?: (apiErrors?: IApiValidationError[], values?: any) => void;
};

export function useApiResponseError({
  withAlert,
  externalAddErrors,
}: UseApiResponseErrorType = {}) {
  const { addErrors } = useFormErrorsActions();
  const alert = useAlert();
  const { t } = useTranslation();

  const filterErrorsBySkipErrors = (
    errors: IApiValidationError[],
    skipErrors?: string[],
  ) => {
    if (!skipErrors || skipErrors.length === 0) {
      return errors;
    }

    return errors.filter(
      (error: IApiValidationError) =>
        !skipErrors?.some((skipError) => skipError === error.ErrorCode),
    );
  };

  const handleResponseError = (
    err?: IValidationErrorResponse,
    values?: any,
    ratifications: IWarningRatification[] = [],
    skipErrors?: string[],
  ) => {
    if (!err || !err.ValidationErrors || err.ValidationErrors.length === 0) {
      return false;
    }

    const errors = filterErrorsBySkipErrors(err.ValidationErrors, skipErrors);

    if (errors.length === 0) {
      return false;
    }

    const addErrorFunction = externalAddErrors || addErrors;

    addErrorFunction(errors, {
      ...values,
      ratifications,
    });

    withAlert &&
      alert.error(
        <>
          {err.ValidationErrors.map(
            (error: IApiValidationError, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={error.ErrorCode + index}>
                {t(`api-validation.${error.ErrorCode}`)}
              </div>
            ),
          )}
        </>,
      );

    return true;
  };

  return { handleResponseError };
}
