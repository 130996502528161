import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { ICreateOrganizationContractModel } from '../../../../models/CreateOrganizationContractModel';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { ORGANIZATION_CONTRACT_ADDING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { createOrganizationContract } from '../../../../store/saga-slices/contracts';
import { ADD_CONTRACT_MODAL_NAME } from '../addContractModal.const';
import { validateContractDetailsForm } from '../../../Forms/ContractForm/ContractDetailsForm/contractDetailsForm.validation';
import { ICreateOrganizationContractPayload } from '../../../../store/saga-slices/contracts/models';
import { IWarningRatification } from '../../../../models/interfaces/WarningRatification';
import {
  useApiWarningActions,
  useApiWarningValues,
} from '../../../WarningsConfirmation/warningsConfirmation.hooks';
import { IValidationErrorResponse } from '../../../../models/ValidationErrorResponse';
import { prepareConfirmedWarnings } from '../../../WarningsConfirmation/warningsConfirmation.utils';
import { OverlappedContractsWarningCode } from './addContractModalForm.const';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { AddContractModalFormDetails } from './addContractModalForm.models';

type UseAddContractModalType = {
  onContractAdded?: () => void;
  onCancel?: () => void;
};

export const useAddContractModalForm = ({
  onContractAdded,
  onCancel,
}: UseAddContractModalType) => {
  const cancellationToken = useCancellationToken();
  const { t } = useTranslation();
  const UpdateModalState = useActions(updateModalState);
  const CreateOrganizationContract = useActions(createOrganizationContract);
  const contractAdding = useProcessing(ORGANIZATION_CONTRACT_ADDING_PROCESSING);

  const alert = useAlert();

  const { organizationId } = useModalDetails<AddContractModalFormDetails>(
    ADD_CONTRACT_MODAL_NAME,
  );

  const { handleResponseError } = useApiResponseError();
  const { validateErrors } = useFormErrorsActions();
  const { addRatifications, clearRatifications } = useApiWarningActions();
  const { ratifications } = useApiWarningValues();

  const handleOverlappingContractWarning = useCallback(
    (err: IValidationErrorResponse) => {
      if (!err?.ValidationWarnings?.length) {
        return;
      }
      clearRatifications();
      addRatifications(prepareConfirmedWarnings(err.ValidationWarnings));
    },
    [addRatifications, clearRatifications],
  );

  const createContract = useCallback(
    (
      values: ICreateOrganizationContractModel,
      newRatifications: IWarningRatification[] = [],
    ) => {
      const currentValues = { ...values, ratifications: newRatifications };

      const payload: ICreateOrganizationContractPayload = {
        organizationId,
        contract: currentValues,
        cancellationToken,
        success: () => {
          UpdateModalState(ADD_CONTRACT_MODAL_NAME);
          alert.success(
            t('alerts.contract-create-success', {
              value: values.name,
            }),
          );
          onContractAdded && onContractAdded();
        },
        error: (err) => {
          handleResponseError(err, values);
          handleOverlappingContractWarning(err);
          alert.error(t('alerts.contract-create-fail'));
        },
      };
      CreateOrganizationContract(payload);
    },
    [
      organizationId,
      cancellationToken,
      CreateOrganizationContract,
      UpdateModalState,
      alert,
      t,
      onContractAdded,
      handleResponseError,
      handleOverlappingContractWarning,
    ],
  );

  const onCloseHandler = useCallback(() => {
    UpdateModalState(ADD_CONTRACT_MODAL_NAME);
    onCancel && onCancel();
  }, [UpdateModalState, onCancel]);

  const validateForm = (data: ICreateOrganizationContractModel) =>
    validateContractDetailsForm(data, validateErrors(data));

  const handleSubmit = useCallback(
    (values: ICreateOrganizationContractModel) => {
      createContract(values, ratifications);
    },
    [createContract, ratifications],
  );

  const needShowOverlapMessage = useMemo(
    () => ratifications.some((x) => x.code === OverlappedContractsWarningCode),
    [ratifications],
  );

  return {
    contractAdding,
    handleSubmit,
    onCloseHandler,
    validateForm,
    needShowOverlapMessage,
  };
};
