import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';
import { PricingInput } from './pricingRenderer.styles';

export interface IPricingRenderer extends ICellRenderer {
  onChange?: (value: number) => void;
  price?: number;
  readonly?: boolean;
  minValue?: number;
  allowNegative?: boolean;
  isError?: boolean;
}

export const PricingRenderer = ({
  onChange,
  options,
  price,
  readonly,
  minValue = 0,
  allowNegative,
  isError,
}: IPricingRenderer) => (
  <TableCell bold={options?.textBold} alignment={options?.alignment}>
    <PricingInput
      isError={isError}
      alignment={options?.alignment}
      prefix="$"
      allowNegative={allowNegative}
      min={minValue}
      defaultValue={0}
      decimalScale={2}
      disabled={options?.disabled}
      onChangeValue={(value: any) => onChange?.(value)}
      readOnly={readonly}
      value={price}
    />
  </TableCell>
);
