import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ICreateDOCOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/CreateDOCOrganizationSubscriptionExtensionModel';
import { ExtendProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadDOCSubscriptionExtensionSummary } from '../../../../store/saga-slices/subscriptions/dutyOfCare';
import { ILoadDOCSubscriptionExtensionSummary } from '../../../../store/saga-slices/subscriptions/dutyOfCare/models';

type ExtendDOCSubscriptionSummaryLoaderProps = {
  values?: ICreateDOCOrganizationSubscriptionExtensionModel;
};

const ExtendDOCSubscriptionSummaryLoader = ({
  values,
}: ExtendDOCSubscriptionSummaryLoaderProps) => {
  const LoadSubscriptionExtensionSummary = useActions(
    loadDOCSubscriptionExtensionSummary,
  );
  const { organizationId, subscriptionId } =
    useParams<ExtendProductSubscriptionPageParamsType>();

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values.changeSubscriptionPackageModel) {
      return;
    }

    debounce(() => {
      const payload: ILoadDOCSubscriptionExtensionSummary = {
        data: values.changeSubscriptionPackageModel,
        organizationId,
        subscriptionId,
      };

      LoadSubscriptionExtensionSummary(payload);
    }, 500);
  }, [
    LoadSubscriptionExtensionSummary,
    debounce,
    organizationId,
    subscriptionId,
    values,
  ]);
  return <></>;
};

export default ExtendDOCSubscriptionSummaryLoader;
