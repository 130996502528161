import { Outlet, RouteProps } from 'react-router';

import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';

import { CustomPermissions } from '../../models/types/CustomPermissions';

import ProductPageHeader, {
  IProductPageHeaderProps,
} from './ProductPageHeader/ProductPageHeader';

interface IProductPage
  extends RouteProps,
    IProductPageHeaderProps,
    CustomPermissions {
  pagesPermissions?: AvailableProductPermissionsType;
}

const ProductPage = ({ children, ...props }: IProductPage) => (
  <>
    <ProductPageHeader {...props} />
    {children || <Outlet />}
  </>
);

export default ProductPage;
