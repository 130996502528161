import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { IPerson } from '../../../../models/Person';
import { personToMemberListItem } from '../../../../utils/personHelpers';
import { Dictionary } from '../../../../models/Dictionary';
import { ISRMembershipModel } from '../../../../models/interfaces/Membership/SecurityResponse/SRMembershipModel';

export interface ISRMembersState {
  summary?: IAddMembershipSummaryModel;
  newMembershipDates: IMembershipDates;
  members?: IPageListModel<IMemberListItem>;
  membershipDetails: Dictionary<ISRMembershipModel>;
}

const initialState: ISRMembersState = {
  summary: null,
  newMembershipDates: null,
  members: null,
  membershipDetails: {},
};

const srMembershipSlice = createSlice({
  initialState,
  name: 'srMembership',
  reducers: {
    setSRMembershipSummary: (
      state,
      { payload }: PayloadAction<IAddMembershipSummaryModel>,
    ) => {
      state.summary = payload;
    },
    setSRMembershipDates: (
      state,
      { payload }: PayloadAction<IMembershipDates>,
    ) => {
      state.newMembershipDates = {
        effectiveDate: parseDate(payload.effectiveDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setSRMembers: (
      state,
      { payload }: PayloadAction<IPageListModel<IMemberListItem>>,
    ) => {
      state.members = payload;
    },
    updateSRMembersList: (state, { payload }: PayloadAction<IPerson>) => {
      const currentPersonIndex = state.members?.items.findIndex(
        (member) => member.personId === payload.personId,
      );

      if (currentPersonIndex !== -1) {
        state.members.items[currentPersonIndex] = personToMemberListItem(
          payload,
          state.members.items[currentPersonIndex],
        );
      }
    },
    setSRMembershipDetails: (
      state,
      { payload }: PayloadAction<ISRMembershipModel>,
    ) => {
      state.membershipDetails[payload.id] = payload;
    },
    clearSRMembershipDates: (state) => {
      state.newMembershipDates = initialState.newMembershipDates;
    },
    clearSRCreateMemberData: () => initialState,
    clearSRMembershipSummary: (state) => {
      state.summary = initialState.summary;
    },
    clearSRMembershipDetails: (state) => {
      state.membershipDetails = initialState.membershipDetails;
    },
    clearSRMembers: (state) => {
      state.members = initialState.members;
    },
  },
});

export const {
  clearSRCreateMemberData,
  clearSRMembers,
  clearSRMembershipDates,
  clearSRMembershipSummary,
  setSRMembers,
  setSRMembershipDates,
  setSRMembershipSummary,
  updateSRMembersList,
  clearSRMembershipDetails,
  setSRMembershipDetails,
} = srMembershipSlice.actions;
export default srMembershipSlice.reducer;
export const membersReducerName = srMembershipSlice.name;
