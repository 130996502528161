import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICreatePrimaryMemberModel } from '../../../../models/CreatePrimaryMemberModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { CreateMembershipPageModel } from '../../../../models/types/CreateMembershipPageModel';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { ProductType } from '../../../../models/ProductType';

export interface IMembersState {
  createMembership?: CreateMembershipPageModel;
  availableProductTypes?: ProductType[];
  createPrimaryMember?: ICreatePrimaryMemberModel;
  createdMembershipId?: string;
  summary?: IAddMembershipSummaryModel;
}

const initialState: IMembersState = {
  createMembership: null,
  createPrimaryMember: null,
  createdMembershipId: null,
  summary: null,
  availableProductTypes: [],
};

const membershipSlice = createSlice({
  initialState,
  name: 'membership',
  reducers: {
    setMembership: (
      state,
      { payload }: PayloadAction<CreateMembershipPageModel>,
    ) => {
      state.createMembership = {
        ...payload,
        effectiveDate: parseDate(payload.effectiveDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setAvailableProductTypes: (
      state,
      { payload }: PayloadAction<ProductType[]>,
    ) => {
      state.availableProductTypes = payload;
    },
    setPrimaryMember: (
      state,
      { payload }: PayloadAction<ICreatePrimaryMemberModel>,
    ) => {
      state.createPrimaryMember = payload;
    },
    setCreatedMembershipId: (state, { payload }: PayloadAction<string>) => {
      state.createdMembershipId = payload;
    },
    setMembershipSummary: (
      state,
      { payload }: PayloadAction<IAddMembershipSummaryModel>,
    ) => {
      state.summary = payload;
    },
    clearMembershipSummary: (state) => {
      state.summary = initialState.summary;
    },
    clearCreatedMembershipId: (state) => {
      state.createdMembershipId = initialState.createdMembershipId;
    },
    clearCreateMemberData: () => initialState,
  },
});

export const {
  setMembership,
  setPrimaryMember,
  clearCreateMemberData,
  setCreatedMembershipId,
  clearCreatedMembershipId,
  clearMembershipSummary,
  setMembershipSummary,
  setAvailableProductTypes,
} = membershipSlice.actions;
export default membershipSlice.reducer;
export const membersReducerName = membershipSlice.name;
