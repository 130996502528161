import styled from '@emotion/styled/macro';

import OverviewProperty from '../../Overview/OverviewProperty/OverviewProperty';
export const ButtonsContainer = styled.div`
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PromoCode = styled.div`
  font: normal 500 0.875rem/2rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const Package = styled.div`
  font: normal 500 0.875rem/2rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

type OverviewPropertyProps = {
  minWidth?: number;
};

export const ProductOverviewProperty = styled(
  OverviewProperty,
)<OverviewPropertyProps>`
  min-width: ${({ minWidth }) => `${minWidth || 11.25}rem`};
`;

export const DateOverviewProperty = styled(
  OverviewProperty,
)<OverviewPropertyProps>`
  min-width: ${({ minWidth }) => `${minWidth || 11.875}rem`};
`;

export const CapacityOverviewProperty = styled(
  OverviewProperty,
)<OverviewPropertyProps>`
  min-width: ${({ minWidth }) => `${minWidth || 10.625}rem`};
`;

export const TotalOverviewProperty = styled(
  OverviewProperty,
)<OverviewPropertyProps>`
  min-width: ${({ minWidth }) => `${minWidth || 9.375}rem`};
`;

export const StyledOverviewProperty = styled(
  OverviewProperty,
)<OverviewPropertyProps>`
  min-width: ${({ minWidth }) => `${minWidth || 11.25}rem`};
`;
