import { ICallbackSaga } from '../../../models/ICallbackSaga';
import { ICancellationTokenSaga } from '../../../models/interfaces/CancellationTokenSaga';
import { IRenewalsFiltersModel } from '../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';

export interface ILoadRenewalContractsPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  filters?: IRenewalsFiltersModel;
}
export interface IExportRenewalContractsPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  filters?: IRenewalsFiltersModel;
}

export interface ILoadRenewalSubscriptionsPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  filters?: IRenewalsFiltersModel;
}

export interface IExportRenewalSubscriptionsPayload
  extends ICallbackSaga,
    ICancellationTokenSaga {
  filters?: IRenewalsFiltersModel;
}

export enum RenewalFilenamePrefix {
  RenewalContracts = 'RenewalContracts_',
  RenewalSubscriptions = 'RenewalSubscriptions_',
}
