import { useTranslation } from 'react-i18next';

import { IPersonMembershipItem } from '../../../../models/PersonMembershipItem';

import { formatDate } from '../../../../utils/dateHelpers';
import MembershipRelation from '../../../../styledComponents/MembershipRelation/MembershipRelation';

import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';
import {
  StyledOverviewProperty,
  SummaryWrapper,
  Wrapper,
} from '../../Common/MembershipSummary/membershipSummary.styles';

interface IDOCMembershipExpiredSummaryProps {
  data: IPersonMembershipItem;
}

const DOCMembershipExpiredSummary = ({
  data,
}: IDOCMembershipExpiredSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SummaryWrapper>
        <StyledOverviewProperty
          title={t('properties.product-subscription')}
          value={ProductTypeShortcut[data.productSubscription]}
          bold
          width="6rem"
        />
        <StyledOverviewProperty
          title={t('properties.package-type')}
          value={data.packageTypeName}
          width="12rem"
        />
        <StyledOverviewProperty
          title={t('properties.membership-relation')}
          width="12rem"
        >
          <MembershipRelation
            organizationName={data.organizationName}
            organizationId={data.organizationId}
            isRetail={data.isRetail}
          />
        </StyledOverviewProperty>
        <StyledOverviewProperty title={t('properties.expiredOn')}>
          {formatDate(data.expirationDate)}
        </StyledOverviewProperty>
      </SummaryWrapper>
    </Wrapper>
  );
};

export default DOCMembershipExpiredSummary;
