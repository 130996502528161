import { useTranslation } from 'react-i18next';

import {
  FiltersWrapper,
  InputContainer,
  SearchInputContainer,
} from '../../SimplePage/Filters';
import IconInput from '../../UI/IconInput/IconInput';
import { MainSelect } from '../../UI/MainSelect/MainSelect';
import {
  statusOptions,
  productOptions,
  typeOptions,
} from './promoCodesFilters.const';
import { usePromoCodesFilters } from './promoCodesFilters.hooks';
import { formatOptionLabel } from './promoCodesFilters.utils';

const PromoCodesFilters = () => {
  const { t } = useTranslation();
  const { filters, onFilterChangeHandler } = usePromoCodesFilters();

  return (
    <FiltersWrapper>
      <SearchInputContainer>
        <IconInput
          defaultIcon="search"
          onChange={(evt) => onFilterChangeHandler(evt, 'search')}
          placeholder={t('filters.search-by')}
          value={filters.search}
        />
      </SearchInputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.status}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'status')}
          options={statusOptions}
          placeholder={t('filters.status')}
          formatOptionLabel={formatOptionLabel}
        />
      </InputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.type}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'type')}
          options={typeOptions}
          placeholder={t('filters.type')}
        />
      </InputContainer>
      <InputContainer>
        <MainSelect
          currentValue={filters.product}
          isClearable
          isMulti
          onChange={(evt: any) => onFilterChangeHandler(evt, 'product')}
          options={productOptions}
          placeholder={t('filters.product')}
        />
      </InputContainer>
    </FiltersWrapper>
  );
};

export default PromoCodesFilters;
