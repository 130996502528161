import { SimplePageLine } from '../../SimplePage/SimplePageLine';
import SRMembersTable, {
  ISRMembersTableProps,
} from './SRMembersTable/SRMembersTable';
import SRMembersTools, {
  SRMembersToolsProps,
} from './SRMembersTools/SRMembersTools';

type ISRMembersListProps = {
  tableProps: ISRMembersTableProps;
  toolsProps: SRMembersToolsProps;
};

const SRMembersList = ({ tableProps, toolsProps }: ISRMembersListProps) => (
  <>
    <SRMembersTools {...toolsProps} />
    <SimplePageLine />
    <SRMembersTable {...tableProps} />
  </>
);

export default SRMembersList;
