import { CustomComponent } from '../../../models/types/CustomComponent';
import { getLastCharsOfString } from '../../../utils/textHelpers';
import CreditCardType from '../CreditCardType/CreditCardType';
import {
  CreditCardNumber,
  CreditCardTypeContainer,
} from './creditCardTypeNumber.styles';

type CreditCardTypeNumberProps = CustomComponent & {
  creditCardType?: string;
  creditCardNumber?: string;
  isBoldNumber?: boolean;
};

const CreditCardTypeNumber = ({
  creditCardNumber,
  creditCardType,
  className,
  isBoldNumber,
}: CreditCardTypeNumberProps) => (
  <CreditCardTypeContainer className={className}>
    <CreditCardType creditCardType={creditCardType} />
    <CreditCardNumber isBold={isBoldNumber}>{`....${getLastCharsOfString(
      creditCardNumber,
      4,
    )}`}</CreditCardNumber>
  </CreditCardTypeContainer>
);
export default CreditCardTypeNumber;
