import { IAddress } from '../models/Address';

export const compareAddresses = (address1: IAddress, address2: IAddress) =>
  address1.addressLine1 === address2.addressLine1 &&
  address1.addressLine2 === address2.addressLine2 &&
  address1.city === address2.city &&
  address1.country === address2.country &&
  address1.zipCode === address2.zipCode &&
  address1.stateRegion === address2.stateRegion;

export const prepareAddressForView = (address: IAddress) =>
  Object.keys(address).length > 1 &&
  [
    [address.addressLine1, address.addressLine2, address.addressLine3]
      .filter((x) => x)
      .join(' '),
    [
      address.zipCode,
      `${address.city || ''}${address.country ? ',' : ''}`,
      address.country,
    ]
      .filter((x) => x)
      .join(' '),
  ].filter((x) => x);
