import { useTranslation } from 'react-i18next';

import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';

type UseTLSubscriptionPricingTableProps = {
  errors: ITLOrganizationSubscriptionPricingTierModel[];
};

export const useTLSubscriptionPricingTable = ({
  errors,
}: UseTLSubscriptionPricingTableProps) => {
  const { t } = useTranslation();
  const preparedErrors =
    errors?.length &&
    errors.reduce(
      (
        accumulator: string[],
        currentValue: ITLOrganizationSubscriptionPricingTierModel,
        currentIndex: number,
      ) => {
        if (!currentValue) {
          return accumulator;
        }
        return accumulator.concat(
          Object.keys(currentValue).map(
            (key) =>
              `${`Item number ${currentIndex + 1} - ${t(
                `fields.${key}.label`,
              )}`} -  ${currentValue[key]}`,
          ),
        );
      },
      [],
    );

  return { preparedErrors };
};
