import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import KEMembersList from '../../../styledComponents/ProductMembersList/KEMembersList/KEMembersList';
import { kePageFilters } from './membersKidnapAndExtortion.const';
import { useMembersKidnapAndExtortion } from './membersKidnapAndExtortion.hooks';

const MembersKidnapAndExtortionPage = () => {
  const {
    membersLoading,
    pageListMembers,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    keMembershipPricing,
  } = useMembersKidnapAndExtortion();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <KEMembersList
        tableProps={{
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          isLoading: membersLoading,
          data: pageListMembers,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: kePageFilters,
          standardPricing: keMembershipPricing,
        }}
      />
    </>
  );
};

export default MembersKidnapAndExtortionPage;
