import {
  canViewAAMembersPermissions,
  canViewDOCMembersPermissions,
  canViewGeneralMembersPermissions,
  canViewKEMembersPermissions,
  canViewSRMembersPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';

export const MembersPagesPermissions: AvailableProductPermissionsType = {
  General: canViewGeneralMembersPermissions,
  AirAmbulance: canViewAAMembersPermissions,
  KidnapAndExtortion: canViewKEMembersPermissions,
  SecurityResponse: canViewSRMembersPermissions,
  DutyOfCare: canViewDOCMembersPermissions,
};
