import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { canAddMembershipPermissions } from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import ProductPage from '../../styledComponents/ProductPage/ProductPage';
import { MembersPagesPermissions } from './membersPage.const';
import MembersRoutes from './membersPage.routes';

const MembersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <ProductPage
      title={t('headers.person-list')}
      buttonTitle={t('buttons.add-member')}
      onButtonClick={() => navigate(RoutePath.AddMemberWizardPrimaryMember)}
      actionPermissions={canAddMembershipPermissions}
      pagesPermissions={MembersPagesPermissions}
    >
      <MembersRoutes />
    </ProductPage>
  );
};

export default MembersPage;
