import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../hooks/useActions';
import { aaMembershipPlansPricesSelector } from '../../../../../store/redux-slices/pricing/airAmbulance/selectors';
import { aaLoadStandardPricing } from '../../../../../store/saga-slices/pricing/airAmbulance';
import { getFormGroupPath } from '../../../../../utils/getFormGroupPath';
import { BasicSubscriptionFormFields } from '../../BasicSubscriptionForm/basicSubscriptionForm.models';

type UseSubscriptionPricingForm = {
  name?: string;
  isEditMode?: boolean;
};

export const useSubscriptionPricingForm = ({
  name,
  isEditMode,
}: UseSubscriptionPricingForm) => {
  const {
    input: { value: subscriptionType },
  } = useField(getFormGroupPath(name, BasicSubscriptionFormFields.ProductType));

  const membershipPlansPrices = useSelector(aaMembershipPlansPricesSelector);
  const LoadStandardPricing = useActions(aaLoadStandardPricing);

  useEffect(() => {
    if (subscriptionType) {
      LoadStandardPricing({ productType: subscriptionType });
    }
  }, [subscriptionType, LoadStandardPricing]);

  return {
    membershipPlansPrices,
    subscriptionType,
  };
};
