import { FieldRenderProps } from 'react-final-form';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../Field/Field';
import { IInputProps } from '../../Input/Input';
import { useFormField } from '../../../../hooks/useFormField';
import { StyledNumberInput } from './formNumberInput.styles';
import { useBaseFormInput } from '../../../../hooks/useBaseFormInput';

export interface IFormNumberInput
  extends FieldRenderProps<string>,
    IInputProps {
  onBlur?: () => void;
  prefix?: string;
}

export const FormNumberInput = ({
  className,
  disabled,
  input,
  inputId,
  label,
  meta,
  labelAnimation = true,
  onBlur,
  maxLength,
  prefix,
  ...props
}: IFormNumberInput) => {
  const { onBlurHandler, preparedPlaceholder, preparedLabel } = useFormField({
    input,
    onBlur,
    labelAnimation,
    placeholder: props.placeholder,
    label,
  });

  const { inputValue, onChangeHandler } = useBaseFormInput({ input });
  return (
    <FormFieldWrapper className={className} error={meta.touched && meta.error}>
      <StyledNumberInput
        prefix={prefix}
        format={props.formatValue}
        name={input.name}
        error={meta.error && meta.touched}
        disabled={disabled}
        placeholder={preparedPlaceholder}
        labelAnimation={labelAnimation}
        onChangeValue={onChangeHandler}
        onBlur={onBlurHandler}
        label={preparedLabel}
        value={inputValue}
        maxLength={maxLength}
      />
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
