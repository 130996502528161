import {
  AASubscriptionOptions as defaultSubscriptionOptions,
  SubscriptionOptionsType,
} from './subscriptionOptions.utils';
import { StyledTitle, Wrapper } from './subscriptionOptions.styles';
import { CustomForm } from '../../../models/types/CustomForm';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import FormCheckboxGroup from '../../UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import { LayoutGroupOptions } from '../../UI/Form/FormCheckboxGroup/formCheckboxGroup.models';
import { OrganizationContractType } from '../../../models/enums/ContractType';

type SubscriptionOptionsFormProps = CustomForm & {
  title?: string;
  subscriptionOptions?: SubscriptionOptionsType[];
  layout?: LayoutGroupOptions;
  contractType?: OrganizationContractType;
};

export const SubscriptionOptions = ({
  disabled,
  title = '',
  className,
  children,
  name,
  subscriptionOptions,
  layout = 'row',
}: SubscriptionOptionsFormProps) => (
  <Wrapper className={className}>
    {children || (title && <StyledTitle>{title}</StyledTitle>)}
    <FormColumn>
      <FormCheckboxGroup
        layout={layout}
        namePrefix={name}
        disabled={disabled}
        options={subscriptionOptions || defaultSubscriptionOptions}
      />
    </FormColumn>
  </Wrapper>
);
