import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { putResolve } from '@redux-saga/core/effects';

import { AxiosResponse } from 'axios';

import {
  setAAMembers,
  setAAMembershipDates,
  setAAMembershipDetails,
  setAAMembershipSummary,
} from '../../../redux-slices/membership/airAmbulance';
import { changeToNextStep } from '../../wizard';
import { updateProcessState } from '../../../redux-slices/processes';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  SECONDARY_MEMBER_DELETE_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
  LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING,
  MEMBERSHIP_CREATING_PROCESSING,
  MEMBERSHIP_SUMMARY_LOADING_PROCESSING,
  MEMBERS_LOADING_PROCESSING,
  RESEND_WELCOME_EMAIL_PROCESSING,
  SECONDARY_MEMBER_ADDING_PROCESSING,
  DELETE_MEMBERSHIP_PROCESSING,
  UPDATE_MEMBERSHIP_OPTIONS_PROCESSING,
  MEMBERSHIP_RETAIL_CREATING_PROCESSING,
  NEW_MEMBERSHIP_DATES_LOAD_PROCESSING,
} from '../../../redux-slices/processes/constants';
import {
  addAASecondaryMemberRequest,
  createAAOrganizationMembership,
  createAARetailMembershipRequest,
  deleteAASecondaryMemberRequest,
  getAAExistingMembershipSummary,
  getAAMembershipDetails,
  getAAMembershipRenewalSuggestion,
  getAAMembershipSummary,
  getAARetailMembershipPlanSummary,
  putAAMemberToBeRenewed,
  postAAResendWelcomeEmail,
  deleteAAMembershipRequest,
  updateAAMembershipOptionsRequest,
  analyzeAACreateRetailMembership,
  getAAMembershipsMembers,
  getDatesForNewAAMemberships,
} from './api';
import { Log } from '../../../../utils/logger';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { MembershipType } from '../../../../models/MembershipType';
import {
  IAddSecondaryAAMemberPayload,
  IChangeAAMemberRenewFlagPayload,
  ICreateRetailAAMembershipPayload,
  IDeleteAAMembershipPayload,
  IDeleteSecondaryAAMemberPayload,
  ILoadAAMembershipDetailsPayload,
  ILoadAAMembershipRenewalSuggestionsPayload,
  ILoadAAMembershipSummaryPayload,
  ILoadAAMembersPayload,
  ILoadExistingAAMembershipSummaryPayload,
  ILoadNewAAMembershipDatesPayload,
  ILoadRetailAAMembershipSummaryPayload,
  IResendAAWelcomeEmail,
  ISubmitCreateAAMembershipPayload,
  IUpdateAAMembershipOptionsPayload,
} from './models';
import {
  prepareCreateAARetailMembershipRequestData,
  prepareCreateOrganizationAAMembershipRequestData,
} from '../common/utils';
import { addOrReplaceCreditCard } from '../../payment/utils';
import {
  setMembership,
  setMembershipSummary,
  setPrimaryMember,
} from '../../../redux-slices/membership/common';
import { prepareCreateMemberPageData } from './utils';
import { CreateAARetailMembershipModel } from '../../../../models/types/AirAmbulance/CreateAARetailMembershipModel';
import { ICreateAAMembershipPageModel } from '../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import { removePersonMembership } from '../../../redux-slices/people';
import { ICardSetupIntentModel } from '../../../../models/ICardSetupIntentModel';
import { IPaymentIntentModel } from '../../../../models/IPaymentIntentModel';
import { getPaymentIntent } from '../../payment/api';
import { IAAMembershipModel } from '../../../../models/interfaces/Membership/AirAmbulance/AAMembershipModel';
import { IMembershipDates } from '../../../../models/MembershipDates';

const aaMembershipSlice = createSliceSaga({
  caseSagas: {
    *submitCreateAAMembership({
      payload: { data, success, error },
    }: PayloadAction<ISubmitCreateAAMembershipPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
        yield putResolve(setMembership(data));

        switch (data.membershipType) {
          case MembershipType.Organization:
            const {
              data: requestData,
              organizationId,
              subscriptionId,
            } = yield* call(prepareCreateOrganizationAAMembershipRequestData);
            yield call(
              createAAOrganizationMembership,
              organizationId,
              subscriptionId,
              requestData,
            );

            success?.();
            break;
          case MembershipType.Retail:
            const { data: retailRequestData } = yield* call(
              prepareCreateAARetailMembershipRequestData,
            );
            yield call(analyzeAACreateRetailMembership, retailRequestData);
            yield put(changeToNextStep());
            break;
        }
      } catch (err: any) {
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_CREATING_PROCESSING));
      }
    },
    *createRetailAAMembership({
      payload: {
        setAlreadyPayed,
        creditCardDetails,
        handlePayment,
        success,
        error,
        cancellationToken,
      },
    }: PayloadAction<ICreateRetailAAMembershipPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_RETAIL_CREATING_PROCESSING));
        const { data: retailRequestData } = yield* call(
          prepareCreateAARetailMembershipRequestData,
        );
        const response = yield* call(
          createAARetailMembershipRequest,
          retailRequestData,
          setAlreadyPayed,
          handlePayment,
        );

        let cardSetupIntent: ICardSetupIntentModel;
        let paymentIntent: IPaymentIntentModel;

        if (response.data.isPaymentCaptureRequired) {
          const paymentIntentResponse = yield* call(
            getPaymentIntent,
            response.data.paymentId,
          );
          paymentIntent = paymentIntentResponse.data;
        }
        if (response.data.isCreditCardRequired) {
          cardSetupIntent = yield* call(addOrReplaceCreditCard, {
            personId: response.data.primaryMemberId,
            creditCardDetails,
            cancellationToken,
          });
        }
        success?.({
          ...response.data,
          paymentIntent,
          cardSetupIntent,
        });
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_RETAIL_CREATING_PROCESSING));
      }
    },
    *loadAAMembershipSummary({
      payload: {
        organizationId,
        subscriptionId,
        membershipPlanId,
        success,
        error,
        cancellationToken,
      },
    }: PayloadAction<ILoadAAMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getAAMembershipSummary,
          organizationId,
          subscriptionId,
          membershipPlanId,
          cancellationToken,
        );
        yield put(setMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadRetailAAMembershipSummary({
      payload: {
        success,
        error,
        membershipPlanId,
        promoCode,
        effectiveDate,
        autoRenewal,
        cancellationToken,
      },
    }: PayloadAction<ILoadRetailAAMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getAARetailMembershipPlanSummary,
          membershipPlanId,
          promoCode,
          effectiveDate,
          autoRenewal,
          cancellationToken,
        );
        yield put(setMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *loadAAMembers({
      payload: { success, error, params, organizationId },
    }: PayloadAction<ILoadAAMembersPayload>) {
      try {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
        const response = yield* call(
          getAAMembershipsMembers,
          organizationId,
          params,
        );
        yield put(setAAMembers(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERS_LOADING_PROCESSING));
      }
    },
    *addSecondaryAAMember({
      payload: { success, error, data, membershipId },
    }: PayloadAction<IAddSecondaryAAMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
        yield call(addAASecondaryMemberRequest, membershipId, data);

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_ADDING_PROCESSING));
      }
    },
    *loadAAMembershipDetails({
      payload: { success, error, membershipId },
    }: PayloadAction<ILoadAAMembershipDetailsPayload>) {
      try {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
        const response = yield* call(getAAMembershipDetails, membershipId);
        yield put(setAAMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(LOAD_MEMBERSHIP_DETAILS + membershipId));
      }
    },
    *loadAAMembershipRenewalSuggestions({
      payload: { success, error, membershipId },
    }: PayloadAction<ILoadAAMembershipRenewalSuggestionsPayload>) {
      try {
        yield put(updateProcessState(LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING));
        const response: AxiosResponse<CreateAARetailMembershipModel> =
          yield* call(getAAMembershipRenewalSuggestion, membershipId);
        yield put(setPrimaryMember(response.data.primaryMember));
        const memberData: ICreateAAMembershipPageModel = yield* call(
          prepareCreateMemberPageData,
          response.data,
        );
        yield put(setMembership(memberData));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(LOAD_MEMBERSHIP_SUGGESTIONS_PROCESSING));
      }
    },
    *loadExistingAAMembershipSummary({
      payload: { membershipId, success, error },
    }: PayloadAction<ILoadExistingAAMembershipSummaryPayload>) {
      try {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<IAddMembershipSummaryModel> = yield* call(
          getAAExistingMembershipSummary,
          membershipId,
        );
        yield put(setAAMembershipSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(MEMBERSHIP_SUMMARY_LOADING_PROCESSING));
      }
    },
    *changeAAMemberRenewFlag({
      payload: { membershipId, memberId, toBeRenewed, success, error },
    }: PayloadAction<IChangeAAMemberRenewFlagPayload>) {
      try {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
        yield call(putAAMemberToBeRenewed, membershipId, memberId, toBeRenewed);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
          ),
        );
      }
    },
    *resendAAWelcomeEmail({
      payload: {
        cancellationToken,
        membershipId,
        personId,
        data,
        success,
        error,
      },
    }: PayloadAction<IResendAAWelcomeEmail>) {
      try {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
        yield call(
          postAAResendWelcomeEmail,
          membershipId,
          personId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(RESEND_WELCOME_EMAIL_PROCESSING));
      }
    },
    *deleteSecondaryAAMember({
      payload: { membershipId, memberId, success, error, cancellationToken },
    }: PayloadAction<IDeleteSecondaryAAMemberPayload>) {
      try {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
        yield call(
          deleteAASecondaryMemberRequest,
          membershipId,
          memberId,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SECONDARY_MEMBER_DELETE_PROCESSING));
      }
    },
    *deleteAAMembership({
      payload: { membershipId, cancellationToken, isActive, success, error },
    }: PayloadAction<IDeleteAAMembershipPayload>) {
      try {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
        yield call(
          deleteAAMembershipRequest,
          membershipId,
          isActive,
          cancellationToken,
        );

        yield put(removePersonMembership({ membershipId }));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(DELETE_MEMBERSHIP_PROCESSING));
      }
    },
    *updateAAMembershipOptions({
      payload: { membershipId, cancellationToken, options, success, error },
    }: PayloadAction<IUpdateAAMembershipOptionsPayload>) {
      try {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
        const response: AxiosResponse<IAAMembershipModel> = yield* call(
          updateAAMembershipOptionsRequest,
          membershipId,
          options,
          cancellationToken,
        );
        yield put(setAAMembershipDetails(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING));
      }
    },
    *loadNewAAMembershipDates({
      payload: { termType, subscriptionId, cancellationToken, success, error },
    }: PayloadAction<ILoadNewAAMembershipDatesPayload>) {
      try {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
        const response: AxiosResponse<IMembershipDates> = yield* call(
          getDatesForNewAAMemberships,
          {
            termType,
            subscriptionId,
            cancellationToken,
          },
        );
        yield put(setAAMembershipDates(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(NEW_MEMBERSHIP_DATES_LOAD_PROCESSING));
      }
    },
  },
  name: 'members-saga',
  sagaType: SagaType.TakeLatest,
});

export default aaMembershipSlice.saga;
export const {
  addSecondaryAAMember,
  changeAAMemberRenewFlag,
  createRetailAAMembership,
  deleteAAMembership,
  deleteSecondaryAAMember,
  loadAAMembers,
  loadAAMembershipDetails,
  loadAAMembershipRenewalSuggestions,
  loadAAMembershipSummary,
  loadExistingAAMembershipSummary,
  loadNewAAMembershipDates,
  loadRetailAAMembershipSummary,
  resendAAWelcomeEmail,
  submitCreateAAMembership,
  updateAAMembershipOptions,
} = aaMembershipSlice.actions;
export const { actions } = aaMembershipSlice;
