import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../../../../../models/types/CustomForm';
import FormDatePicker from '../../../../../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { getFormGroupPath } from '../../../../../../../../../utils/getFormGroupPath';
import {
  FilterInputContainer,
  FilterRow,
} from '../../../../filtersForm.styles';

import { DateFilterFields } from '../../dateFilter.models';

const DatesRangeFilter = ({ name }: CustomForm) => (
  <FilterRow>
    <FilterInputContainer small>
      <Field
        component={FormDatePicker}
        name={getFormGroupPath(name, DateFilterFields.From)}
      />
    </FilterInputContainer>
    <FilterInputContainer small>
      <Field
        component={FormDatePicker}
        name={getFormGroupPath(name, DateFilterFields.To)}
      />
    </FilterInputContainer>
  </FilterRow>
);

export default DatesRangeFilter;
