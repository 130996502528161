import styled from '@emotion/styled/macro';

export const StepperWrapper = styled.nav`
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.color.background2};
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const StepperList = styled.ol`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
