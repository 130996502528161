import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';

import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import { RoutePath } from '../../../../models/enums/RoutePath';
import {
  CHANGE_MEMBER_RENEW_FLAG_PROCESSING,
  LOAD_MEMBERSHIP_DETAILS,
} from '../../../../store/redux-slices/processes/constants';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { IMemberInfoModel } from '../../../../models/MemberInfoModel';
import { personNameSelector } from '../../../../store/redux-slices/people/selectors';

import { MemberOverviewPageParamsType } from '../../../../models/types/RoutePath';
import { ProductType } from '../../../../models/ProductType';
import {
  changeSRMemberRenewFlag,
  loadSRMembershipDetails,
} from '../../../../store/saga-slices/membership/securityResponse';
import { srMembershipDetailsByIdSelector } from '../../../../store/redux-slices/membership/securityResponse/selectors';
import {
  IChangeSRMemberRenewFlagPayload,
  ILoadSRMembershipDetailsPayload,
} from '../../../../store/saga-slices/membership/securityResponse/models';
import { DeleteSRFamilyMemberModalDetails } from '../Modals/DeleteSRFamilyMemberModal/deleteSRFamilyMemberModal.models';
import { DELETE_SR_FAMILY_MEMBER_MODAL_NAME } from '../Modals/DeleteSRFamilyMemberModal/deleteSRFamilyMemberModal.const';
import { DeleteSRMembershipModalDetails } from '../Modals/DeleteSRMembershipModal/deleteSRMembershipModal.models';
import { DELETE_SR_MEMBERSHIP_MODAL_NAME } from '../Modals/DeleteSRMembershipModal/deleteSRMembershipModal.const';
import { ResendSRWelcomeEmailModalDetails } from '../Modals/ResendSRWelcomeEmailModal/resendSRWelcomeEmailModal.models';
import { RESEND_SR_WELCOME_EMAIL_MODAL_NAME } from '../Modals/ResendSRWelcomeEmailModal/resendSRWelcomeEmailModal.const';
import { ISRMembershipOptions } from '../../../../models/interfaces/Membership/SecurityResponse/SRMembershipModel';
import { EditSRMembershipOptionsModalDetails } from '../Modals/EditSRMembershipOptionsModal/editSRMembershipOptionsModal.models';
import { EDIT_SR_MEMBERSHIP_OPTIONS_MODAL } from '../Modals/EditSRMembershipOptionsModal/editSRMembershipOptionsModal.const';

export const useSRMembershipDetails = (
  membershipId?: string,
  productType?: ProductType,
) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigateSearch();

  const LoadMembershipDetails = useActions(loadSRMembershipDetails);
  const ChangeMemberRenewFlag = useActions(changeSRMemberRenewFlag);
  const UpdateModalState = useActions(updateModalState);

  const dataLoading = useProcessing([
    LOAD_MEMBERSHIP_DETAILS + membershipId,
    CHANGE_MEMBER_RENEW_FLAG_PROCESSING + membershipId,
  ]);
  const membershipDetails = useSelector(
    srMembershipDetailsByIdSelector(membershipId),
  );
  const personName = useSelector(personNameSelector);

  const canBeRenewed = membershipDetails?.metadata?.canRenew;

  const getMembershipDetails = useCallback(() => {
    const payload: ILoadSRMembershipDetailsPayload = {
      membershipId,
    };
    LoadMembershipDetails(payload);
  }, [LoadMembershipDetails, membershipId]);

  useEffect(() => {
    if (membershipDetails) {
      return;
    }
    getMembershipDetails();
  }, [getMembershipDetails, membershipDetails]);

  const deleteFamilyMemberHandler = useCallback(
    (member: IMemberInfoModel) => {
      const details: DeleteSRFamilyMemberModalDetails = {
        member,
        membershipId,
      };
      UpdateModalState([
        DELETE_SR_FAMILY_MEMBER_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const deleteMembershipHandler = useCallback(() => {
    const details: DeleteSRMembershipModalDetails = {
      membershipId,
      productType,
      personName,
    };
    UpdateModalState([DELETE_SR_MEMBERSHIP_MODAL_NAME + membershipId, details]);
  }, [UpdateModalState, membershipId, personName, productType]);

  const changeRenewFlagHandler = useCallback(
    (memberId: string, toBeRenewed: boolean) => {
      const payload: IChangeSRMemberRenewFlagPayload = {
        membershipId,
        memberId,
        toBeRenewed,
        success: () => {
          getMembershipDetails();

          alert.success(t('alerts.renew-flag-updated'));
        },
      };
      ChangeMemberRenewFlag(payload);
    },
    [ChangeMemberRenewFlag, alert, getMembershipDetails, membershipId, t],
  );

  const resendWelcomeEmailHandler = useCallback(
    (personId: string) => {
      const details: ResendSRWelcomeEmailModalDetails = {
        membershipId,
        personId,
      };
      UpdateModalState([
        RESEND_SR_WELCOME_EMAIL_MODAL_NAME + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberInfoModel) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, {
        params,
      });
    },
    [navigate],
  );

  const onEditMembershipOptionsHandler = useCallback(
    (membershipOptions: ISRMembershipOptions) => {
      const details: EditSRMembershipOptionsModalDetails = {
        membershipId,
        membershipOptions,
      };
      UpdateModalState([
        EDIT_SR_MEMBERSHIP_OPTIONS_MODAL + membershipId,
        details,
      ]);
    },
    [UpdateModalState, membershipId],
  );

  return {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    deleteFamilyMemberHandler,
    canBeRenewed,
    resendWelcomeEmailHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  };
};
