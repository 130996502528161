import { Route, Routes } from 'react-router';

import {
  canViewAAMembersPermissions,
  canViewDOCMembersPermissions,
  canViewGeneralMembersPermissions,
  canViewKEMembersPermissions,
  canViewSRMembersPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import AuthRoute from '../../styledComponents/AuthRoute/AuthRoute';
import MembersAirAmbulancePage from './MembersAirAmbulancePage/MembersAirAmbulancePage';
import MembersDutyOfCarePage from './MembersDutyOfCarePage/MembersDutyOfCarePage';
import MembersGeneralPage from './MembersGeneralPage/MembersGeneralPage';
import MembersKidnapAndExtortionPage from './MembersKidnapAndExtortionPage/MembersKidnapAndExtortionPage';
import MembersSecurityResponsePage from './MembersSecurityResponsePage/MembersSecurityResponsePage';

const MembersRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.ProductGeneralPage}
      element={
        <AuthRoute permissions={canViewGeneralMembersPermissions}>
          <MembersGeneralPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductAirAmbulancePage}
      element={
        <AuthRoute permissions={canViewAAMembersPermissions}>
          <MembersAirAmbulancePage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductKidnapAndExtortionPage}
      element={
        <AuthRoute permissions={canViewKEMembersPermissions}>
          <MembersKidnapAndExtortionPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductSecurityResponsePage}
      element={
        <AuthRoute permissions={canViewSRMembersPermissions}>
          <MembersSecurityResponsePage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductDutyOfCarePage}
      element={
        <AuthRoute permissions={canViewDOCMembersPermissions}>
          <MembersDutyOfCarePage />
        </AuthRoute>
      }
    />
  </Routes>
);

export default MembersRoutes;
