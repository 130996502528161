import { Collapse, Table, TableRow as MUITableRow } from '@mui/material';
import { ChangeEvent } from 'react';

import { IRenewOrganizationSubscriptionMemberModel } from '../../../../../models/IRenewOrganizationSubscriptionMemberModel';
import { SubscriptionMember } from '../../subscriptionAnalyse.enums';
import { TableRow } from './TableRow';
import { CollapsibleCell, StyledTableBody } from './tableRow.styles';

type Props = {
  members: IRenewOrganizationSubscriptionMemberModel[];
  membershipPlanName: string | null;
  isOpen: boolean;
  handleChange?: (
    e: ChangeEvent<HTMLInputElement>,
    fieldIndex: number,
    fieldType: SubscriptionMember,
  ) => void;
};

export const CollapsibleRow = ({
  members,
  membershipPlanName,
  isOpen,
  handleChange,
}: Props) => (
  <MUITableRow>
    <CollapsibleCell isOpen={isOpen} colSpan={7}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Table aria-label="secondary members">
          <StyledTableBody>
            {members.map((member, index) => (
              <TableRow
                key={member.memberId}
                isOpen={isOpen}
                member={member}
                membershipPlanName={membershipPlanName}
                handleChange={handleChange}
                fieldType={SubscriptionMember.SECONDARY_MEMBER}
                fieldIndex={index}
              />
            ))}
          </StyledTableBody>
        </Table>
      </Collapse>
    </CollapsibleCell>
  </MUITableRow>
);
