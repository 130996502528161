export enum RoutePath {
  AddClient = '/add-client',
  AddMemberWithId = '/:id/extend-subscription/:subscriptionId/add-member',
  AddMember = '/add-member',
  AddMemberWizard = '/add-member/wizard',
  AddMemberWizardPrimaryMember = '/add-member/wizard/primary-member',
  AddProductSubscription = '/:id/add-subscription',
  EditProductSubscription = `/:organizationId/edit-subscription/:subscriptionType/:subscriptionId`,
  Callback = '/callback',
  LogoutCallback = '/logout-callback',
  ClientOverview = '/client-overview/:id',
  ClientOverviewGeneralPage = 'organization-general',
  ClientOverviewGeneralPageFullPath = '/client-overview/:id/organization-general',
  ClientOverviewProductSubscriptionListPage = 'subscriptions',
  ClientOverviewMembersPage = 'members',
  ClientOverviewPromoCodesPage = 'promo-codes',
  ClientOverviewContractsPage = 'contracts',
  ClientOverviewContractsPageFullPath = '/client-overview/:id/contracts',
  ClientOverviewContractOverviewPage = 'overview/:contractId',
  ClientOverviewContractOverviewPageFullPath = '/client-overview/:id/contracts/overview/:contractId',
  MembersPage = '/members',
  ClientOverviewMemberOverviewPage = '/client-overview/:id/members/overview/:memberId',
  ClientOverviewProductSubscriptionListPageFullPath = '/client-overview/:id/subscriptions',
  ClientOverviewMembersListPageFullPath = '/client-overview/:id/members/:productType',
  ClientOverviewProductSubscriptionOverviewPage = 'overview/:subscriptionType/:subscriptionId',
  ClientOverviewProductSubscriptionOverviewPageFullPath = '/client-overview/:id/subscriptions/overview/:subscriptionType/:subscriptionId',
  ClientSubscriptionRenewPage = '/client-overview/:organizationId/subscriptions/overview/:productType/:subscriptionId/subscription-renew',
  EditClientSubscriptionRenewPage = '/client-overview/:organizationId/subscriptions/overview/:productType/:subscriptionId/edit-subscription-renew',
  MemberOverviewPage = '/members/overview/:memberId',
  CorporateClientAirAmbulancePage = 'AirAmbulance',
  CorporateClientGeneralPage = 'General',
  ProductAirAmbulancePage = 'AirAmbulance',
  ProductKidnapAndExtortionPage = 'KidnapAndExtortion',
  ProductSecurityResponsePage = 'SecurityResponse',
  ProductDutyOfCarePage = 'DutyOfCare',
  ProductGeneralPage = 'General',
  CorporateClientsPage = '/corporate-clients',
  ExtendProductSubscription = '/:organizationId/extend-subscription/:productType/:subscriptionId',
  Home = '/',
  Login = '/login',
  MembershipPage = 'membership',
  PaymentPage = 'payment',
  PrimaryMemberPage = 'primary-member',
  Wizard = 'wizard',
  PromoCodes = '/promo-codes',
  PromoCodeOverview = '/promo-codes/:promoCodeId',
  AddPromoCode = '/promo-codes/add-promo-code',
  Reports = '/reports',
  ReportsFinance = 'finance',
  ReportsRawData = 'raw-data',
  ReportsRetailData = 'retail-data',
  Renewals = 'renewals',
  RenewalsContracts = 'contracts',
  RenewalsSubscriptions = 'subscriptions',
  NotFoundPage = '404',
}

// Routes params
export enum AddSubscriptionParams {
  OrganizationId = 'id',
}

export enum ExtendProductSubscriptionPageParams {
  OrganizationId = 'organizationId',
  SubscriptionId = 'subscriptionId',
  ProductType = 'productType',
}

export enum EditProductSubscriptionPageParams {
  OrganizationId = 'organizationId',
  SubscriptionId = 'subscriptionId',
  SubscriptionType = 'subscriptionType',
}

export enum ProductSubscriptionOverviewPageParams {
  OrganizationId = 'id',
  SubscriptionId = 'subscriptionId',
  SubscriptionType = 'subscriptionType',
}

export enum ProductSubscriptionRenewPageParams {
  OrganizationId = 'organizationId',
  SubscriptionId = 'subscriptionId',
  ProductType = 'productType',
}

export enum EditProductSubscriptionRenewPageParams {
  OrganizationId = 'organizationId',
  SubscriptionId = 'subscriptionId',
  ProductType = 'productType',
}

export enum ClientOverviewGeneralPageParams {
  OrganizationId = 'id',
}

export enum PromoCodeOverviewPageParams {
  PromoCodeId = 'promoCodeId',
}

export enum ContractOverviewPageParams {
  OrganizationId = 'id',
  ContractId = 'contractId',
}

export enum MemberOverviewPageParams {
  MemberId = 'memberId',
}

export enum OrganizationMembersListPageParams {
  ProductType = 'productType',
  OrganizationId = 'id',
}

export enum OrganizationContractsListPageParams {
  OrganizationId = 'id',
}

// Routes search params
export enum AddMemberAvailableSearchParams {
  OrganizationId = 'organizationId',
  SubscriptionId = 'subscriptionId',
  PersonId = 'personId',
  MembershipId = 'membershipId',
  ProductType = 'productType',
}

export enum AddSubscriptionAvailableSearchParams {
  ContractId = 'contractId',
}

export enum AddPromoCodeAvailableSearchParams {
  OrganizationId = 'organizationId',
}
