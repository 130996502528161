import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { personMembershipIsActiveByMembershipIdSelector } from '../../../../../store/redux-slices/people/selectors';
import { DELETE_MEMBERSHIP_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { DELETE_SR_MEMBERSHIP_MODAL_NAME } from './deleteSRMembershipModal.const';
import { DeleteSRMembershipModalDetails } from './deleteSRMembershipModal.models';
import { IDeleteSRMembershipPayload } from '../../../../../store/saga-slices/membership/securityResponse/models';
import { deleteSRMembership } from '../../../../../store/saga-slices/membership/securityResponse';

type UseDeleteSRMembershipModalProps = {
  id?: string;
};

export const useDeleteSRMembershipModal = ({
  id,
}: UseDeleteSRMembershipModalProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const cancellationToken = useCancellationToken();
  const isDeleting = useProcessing(DELETE_MEMBERSHIP_PROCESSING);
  const UpdateModalState = useActions(updateModalState);
  const DeleteMembership = useActions(deleteSRMembership);

  const {
    membershipId,
    productType,
    personName,
  }: DeleteSRMembershipModalDetails = useModalDetails(
    DELETE_SR_MEMBERSHIP_MODAL_NAME + id,
  );

  const personMembershipIsActive = useSelector(
    personMembershipIsActiveByMembershipIdSelector(membershipId),
  );

  const onBackHandler = () => {
    UpdateModalState(DELETE_SR_MEMBERSHIP_MODAL_NAME + id);
  };

  const deleteMembershipHandler = useCallback(() => {
    const payload: IDeleteSRMembershipPayload = {
      membershipId,
      cancellationToken,
      isActive: personMembershipIsActive,
      success: () => {
        alert.success(t('alerts.membership-delete-success'));
        UpdateModalState(DELETE_SR_MEMBERSHIP_MODAL_NAME + id);
      },
      error: () => {
        alert.error(t('alerts.membership-delete-error'));
      },
    };
    DeleteMembership(payload);
  }, [
    membershipId,
    cancellationToken,
    personMembershipIsActive,
    DeleteMembership,
    alert,
    t,
    UpdateModalState,
    id,
  ]);

  return {
    onBackHandler,
    productType,
    personName,
    deleteMembershipHandler,
    isDeleting,
    personMembershipIsActive,
  };
};
