/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import './axios/axios';
import { persistStore } from 'redux-persist';
import { Provider as AlertProvider } from 'react-alert';

import { Global, ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { MsalProvider } from '@azure/msal-react';

import { configureStore } from './store';
import reportWebVitals from './reportWebVitals';
import Main from './Main';
import AlertTemplate from './styledComponents/AlertTemplate/AlertTemplate';
import { THEME, muiTheme } from './config/theme';
import { alertOptions } from './config/alert';
import { globalStyles } from './index.styles';
import { PCA } from './common/auth/initAuth';

export const store = configureStore();

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={THEME}>
          <Global styles={globalStyles} />
          <MsalProvider instance={PCA}>
            <PersistGate loading={null} persistor={persistStore(store)}>
              <AlertProvider template={AlertTemplate} {...alertOptions}>
                <Router>
                  <Main />
                </Router>
              </AlertProvider>
            </PersistGate>
          </MsalProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
