/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../models/types/CustomForm';
import MaterialTable from '../../../../MaterialTable/MaterialTable';
import { ICellOptions } from '../../../../MaterialTable/CellRenderers/cellRenderers.models';

import { FormPricingRenderer } from '../../../../MaterialTable/CellRenderers/Form/FormPricingRenderer/FormPricingRenderer';

import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';
import {
  TableDataType,
  TableFormColumns,
  TableFormName,
} from './tlSubscriptionPricingTable.models';
import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';
import { FormNumericRenderer } from '../../../../MaterialTable/CellRenderers/Form/FormNumericRenderer/FormNumericRenderer';
import { DeleteRowAction } from '../../../../MaterialTable/CellActionsRenderers/CellActionsRenderers';
import {
  ErrorsWrapper,
  ErrorWrapper,
  StyledErrorLabel,
  Wrapper,
} from './tlSubscriptionPricingTable.styles';
import { useTLSubscriptionPricingTable } from './tlSubscriptionPricingTable.hooks';
import { InfoStatus } from '../../../../UI/InfoStatus';
import { Status } from '../../../../../models/enums/Status';
import TableCell from '../../../../MaterialTable/CellRenderers/TableCell/TableCell';

type TLSubscriptionPricingFormProps = CustomForm & {
  fieldsData: TableDataType[];
  onDelete?: (id: number) => void;
  errors: ITLOrganizationSubscriptionPricingTierModel[];
  touched?: boolean;
  canRemove?: boolean;
};

const TLSubscriptionPricingTableForm = ({
  className,
  disabled,
  fieldsData,
  onDelete,
  errors,
  touched,
  canRemove,
}: TLSubscriptionPricingFormProps) => {
  const { t } = useTranslation();
  const { preparedErrors } = useTLSubscriptionPricingTable({ errors });
  const cellRenderer = useCallback(
    (
        key: keyof ITLOrganizationSubscriptionPricingTierModel,
        options?: ICellOptions,
      ) =>
      ({ name }: TableDataType) => {
        switch (key) {
          case 'price': {
            return (
              <Field
                component={FormPricingRenderer}
                options={options}
                minValue={0}
                allowNegative
                {...getFieldPropertiesForGroup({
                  inputId: TableFormName,
                  groupName: name,
                  name: TableFormColumns.price,
                })}
              />
            );
          }
          case 'seatsAmount': {
            return (
              <FormNumericRenderer
                options={options}
                disabled={disabled}
                minValue={0}
                showError={false}
                name={TableFormColumns.seatsAmount}
                {...getFieldPropertiesForGroup({
                  inputId: TableFormName,
                  groupName: name,
                  name: TableFormColumns.seatsAmount,
                })}
              />
            );
          }
        }
      },
    [disabled],
  );

  const actionsRenderer = ({ index }: TableDataType) => (
    <TableCell alignment="center">
      <DeleteRowAction
        data={index}
        onClick={onDelete}
        disabled={canRemove || disabled}
      />
    </TableCell>
  );

  return (
    <Wrapper>
      <MaterialTable
        className={className}
        columns={[
          {
            title: t('grid-columns.price-per-license'),
            cellRenderer: cellRenderer('price', {
              disabled,
            }),
          },
          {
            title: t('grid-columns.add-seats'),
            alignment: 'center',
            cellRenderer: cellRenderer('seatsAmount', {
              textBold: true,
              alignment: 'center',
              disabled,
            }),
          },
          {
            title: t('grid-columns.actions'),
            alignment: 'center',
            cellRenderer: actionsRenderer,
          },
        ]}
        data={fieldsData}
        showNotFoundComponent={false}
        dataLoading={false}
        isPaginationHidden
      />
      {touched && preparedErrors && (
        <ErrorsWrapper>
          {preparedErrors.map((error, index) => (
            <ErrorWrapper key={error + index}>
              <InfoStatus status={Status.ERROR} />
              <StyledErrorLabel>{error}</StyledErrorLabel>
            </ErrorWrapper>
          ))}
        </ErrorsWrapper>
      )}
    </Wrapper>
  );
};

export default TLSubscriptionPricingTableForm;
