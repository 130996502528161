import { t } from 'i18next';
import { AnySchema } from 'ajv';

import { IOrganizationSubscriptionCommonModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionCommonModel';
import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { BasicSubscriptionFormFields } from './basicSubscriptionForm.models';

export const subscriptionBasicSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [BasicSubscriptionFormFields.ProductType]: {
      type: 'string',
    },
    [BasicSubscriptionFormFields.EffectiveDateTo]: {
      type: 'string',
      format: 'date',
      formatMinimum: {
        $data: `1/${BasicSubscriptionFormFields.EffectiveDateFrom}`,
      },
      errorMessage: {
        formatMinimum: t('validation.expiration-date-earlier-signed-date'),
        format: t('validation.invalid-date'),
      },
    },
    [BasicSubscriptionFormFields.EffectiveDateFrom]: {
      type: 'string',
      format: 'date',
    },
  },
  required: [
    BasicSubscriptionFormFields.EffectiveDateFrom,
    BasicSubscriptionFormFields.EffectiveDateTo,
    BasicSubscriptionFormFields.ProductType,
  ],
};

export const validateBasicSubscriptionForm = (
  data: IOrganizationSubscriptionCommonModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionBasicSchema);
