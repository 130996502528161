import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { IEditPromoCodeValidDatesModel } from '../../../../../models/interfaces/EditPromoCodeValidDatesModel';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { EditPromoCodeDatesFields } from './editPromoCodeDatesForm.models';

export const validatePromoCodeDates: AnySchema | any = {
  type: 'object',
  properties: {
    [EditPromoCodeDatesFields.ValidTo]: {
      type: 'string',
      format: 'date',
      errorMessage: {
        format: t('validation.invalid-date'),
      },
    },
  },
  dependencies: {
    [EditPromoCodeDatesFields.ValidFrom]: {
      if: {
        properties: {
          [EditPromoCodeDatesFields.ValidTo]: { const: null },
        },
      },
      then: {
        properties: {
          [EditPromoCodeDatesFields.ValidFrom]: {
            type: 'string',
            format: 'date',
            errorMessage: {
              format: t('validation.invalid-date'),
            },
          },
        },
      },
      else: {
        properties: {
          [EditPromoCodeDatesFields.ValidFrom]: {
            type: 'string',
            format: 'date',
            formatMaximum: { $data: `1/${EditPromoCodeDatesFields.ValidTo}` },
            errorMessage: {
              formatMaximum: t(
                'validation.date-later-valid-to-date-and-earlier-now-date',
              ),
              format: t('validation.invalid-date'),
            },
          },
        },
      },
    },
  },
  required: [],
};

export const validatePromoCodeDatesForm = (
  data: IEditPromoCodeValidDatesModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, validatePromoCodeDates);
