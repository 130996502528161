import { ChangeEvent, useCallback } from 'react';
import { FieldInputProps } from 'react-final-form';

import { useBaseFormInput } from '../../../../hooks/useBaseFormInput';

type UseFormTextarea = {
  input: FieldInputProps<string, HTMLElement>;
  parse?: (value: string) => string;
  format?: (value: string) => string;
};

export const useFormTextarea = ({ input, parse, format }: UseFormTextarea) => {
  const { inputValue, onChangeHandler } = useBaseFormInput({ input, parse });

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeHandler(e.target.value);
    },
    [onChangeHandler],
  );

  const onClearInput = useCallback(() => {
    onChangeHandler('');
  }, [onChangeHandler]);

  return {
    onChangeInput,
    onClearInput,
    inputValue: format ? format(inputValue) : inputValue,
  };
};
