import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { PromoCodeType } from '../../../../models/enums/PromoCodeType';
import IconAgent from '../../../../assets/icons/agent-type-icon.svg';
import IconGroup from '../../../../assets/icons/group-type-icon.svg';
import IconGeneral from '../../../../assets/icons/general-type-icon.svg';
import IconSubscription from '../../../../assets/icons/subscription-type-icon.svg';
import { CellText } from '../../../Typography/Typography';

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const NameValue = styled(CellText)`
  font-weight: bold;
`;

type IconTypeProps = {
  type: PromoCodeType;
};

export const IconType = styled.div<IconTypeProps>`
  ${({ type }) => {
    switch (type) {
      case PromoCodeType.Agent:
        return css`
          background: url(${IconAgent}) 50% no-repeat;
        `;
      case PromoCodeType.Group:
        return css`
          background: url(${IconGroup}) 50% no-repeat;
        `;
      case PromoCodeType.Corporation:
        return css`
          background: url(${IconSubscription}) 50% no-repeat;
        `;
      case PromoCodeType.General:
        return css`
          background: url(${IconGeneral}) 50% no-repeat;
        `;
    }
  }}

  display: block;
  height: 1.5rem;
  width: 1.2rem;
`;
