import { SelectProps } from '@mui/material/Select';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { getPropertyName } from '../../../../utils/getPropertyName';

import { FormFieldWrapper } from '../../../Form/Form';
import { ErrorLabel } from '../../../Typography/Typography';
import MainSelectAsync from '../../MainSelectAsync/MainSelectAsync';
import { useFormSelectAsync } from './formSelectAsync.hooks';

interface IFormSelectAsyncProps<T> extends FieldRenderProps<SelectProps<T>> {
  additionalClassName?: string;
  disabled?: boolean;
  inputId?: string;
  isLabelHidden?: boolean;
  label?: string;
  labelSelector?: (value: any) => string;
  placeholder?: string;
  promiseOptions?: (text: string) => Promise<ReadonlyArray<T>>;
  onOptionSelected?: () => void;
  selectAdditionalClassName?: string;
}

export const FormSelectAsync = <T,>({
  className,
  disabled,
  input: { onChange, value, name, onBlur, onFocus },
  inputId,
  isLabelHidden,
  label,
  meta: { error, touched },
  options,
  placeholder,
  selectAdditionalClassName,
  setDefaultValue,
  labelAnimation = true,
  onOptionSelected,
  labelSelector,
  ...props
}: IFormSelectAsyncProps<T>) => {
  const { t } = useTranslation();

  const { preparedPlaceholder, onChangeHandler } = useFormSelectAsync({
    labelAnimation,
    name,
    placeholder,
    onChange,
    onOptionSelected,
  });

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <MainSelectAsync
        value={
          labelSelector && value
            ? { label: labelSelector(value), value }
            : (value as any)
        }
        isDisabled={disabled}
        onChange={onChangeHandler}
        options={options}
        label={label || t(`fields.${getPropertyName(name)}.label`)}
        labelAnimation
        isError={touched && error}
        placeholder={preparedPlaceholder}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
