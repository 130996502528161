import { useTranslation } from 'react-i18next';

import { OrganizationContractType } from '../../../../models/enums/ContractType';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';

import TLSubscriptionPricingForm from '../../../Forms/TravelLicense/TLSubscriptionPricingForm/TLSubscriptionPricingForm';
import { SubscriptionItemBaseProps } from '../../SubscriptionItemForm/subscriptionItemForm.models';
import { TLSubscriptionFormFields } from './travelLicenseSubscriptionForm.models';
import { Wrapper } from './travelLicenseSubscriptionForm.styles';
import { tlSubscriptionOptions } from './travelLicenseSubscriptionForm.utils';

const TravelSubscriptionForm = ({
  disabled,
  className,
  name,
  contractType,
}: SubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper className={className}>
      <TLSubscriptionPricingForm
        disabled={disabled}
        title={t('headers.pricing-and-current-capacity')}
        name={getFormGroupPath(name, TLSubscriptionFormFields.PricingTiers)}
      />
      {contractType === OrganizationContractType.Multiple && (
        <SubscriptionOptions
          disabled={disabled}
          name={name}
          title={t('headers.options')}
          subscriptionOptions={tlSubscriptionOptions}
          layout="column"
        />
      )}
    </Wrapper>
  );
};

export default TravelSubscriptionForm;
