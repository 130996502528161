import { CustomComponent } from '../../../../models/types/CustomComponent';

interface ITabViewProps extends CustomComponent {
  currentTab: string;
  value: string;
}

const TabView = ({ children, currentTab, value }: ITabViewProps) => (
  <div hidden={value !== currentTab} role="tabpanel">
    {value === currentTab && <>{children}</>}
  </div>
);

export default TabView;
