import { FieldRenderProps } from 'react-final-form';

import { Fields } from '../../../../hooks/usePersonFields';
import { ISearchPerson } from '../../../../models/SearchPerson';
import { ISearchInputOption } from '../../SearchInput/searchInput.models';
import { FormSearchInput } from '../FormSearchInput/FormSearchInput';

import { useFormSearchPerson } from './formSearchPerson.hooks';

export interface IFormSearchPersonProps
  extends HTMLInputElement,
    FieldRenderProps<string> {
  group?: string;
  inputId?: string;
  name: string;
  fields: Partial<Record<Fields, string>>;
  promiseOptions?: (
    inputValue: string,
  ) => Promise<ISearchInputOption<ISearchPerson>[]>;
}

const FormSearchPerson = ({
  disabled,
  group,
  fields = {},
  promiseOptions,
  ...props
}: IFormSearchPersonProps) => {
  const { onChange, internalPromiseOptions } = useFormSearchPerson({
    fields,
    group,
    input: props.input,
  });
  return (
    <FormSearchInput
      {...props}
      disabled={disabled}
      onChange={onChange}
      promiseOptions={promiseOptions || internalPromiseOptions}
      children={null}
    />
  );
};

export default FormSearchPerson;
