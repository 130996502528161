import {
  canViewAASubscriptionDetailsPermissions,
  canViewKESubscriptionDetailsPermissions,
  canViewSRSubscriptionDetailsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';

export const SubscriptionsListViewDetailsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canViewAASubscriptionDetailsPermissions,
    KidnapAndExtortion: canViewKESubscriptionDetailsPermissions,
    SecurityResponse: canViewSRSubscriptionDetailsPermissions,
  };
