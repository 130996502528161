import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';

export interface IAAPricingState {
  membershipPlansPrices: IMembershipPlanPriceModel[];
}

const initialState: IAAPricingState = {
  membershipPlansPrices: [],
};

const aaPricingSlice = createSlice({
  name: 'aaPricing',
  initialState,
  reducers: {
    setAAMembershipPlansPrices: (
      state,
      { payload }: PayloadAction<IMembershipPlanPriceModel[]>,
    ) => {
      state.membershipPlansPrices = payload;
    },
    clearAAMembershipPlansPrices: (state) => {
      state.membershipPlansPrices = initialState.membershipPlansPrices;
    },
  },
});

export const { setAAMembershipPlansPrices, clearAAMembershipPlansPrices } =
  aaPricingSlice.actions;
export default aaPricingSlice.reducer;
export const pricingReducerName = aaPricingSlice.name;
