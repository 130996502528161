import styled from '@emotion/styled/macro';

import { ReactComponent as NoteIcon } from '../../assets/icons/note-icon.svg';
import { SectionSubTitle } from '../Typography/Typography';

export const StyledNoteIcon = styled(NoteIcon)`
  fill: ${({ theme }) => theme.color.dark2};
  &:hover {
    fill: ${({ theme }) => theme.color.lighter_blue};
  }
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const StyledSubTitle = styled(SectionSubTitle)`
  font-weight: 500;
  margin-bottom: 0;
`;
