import { useTranslation } from 'react-i18next';

import { useDeleteGGPointOfContactModal } from './deleteGGPointOfContactModal.hooks';
import { DELETE_GG_POINT_OF_CONTACT_MODAL_NAME } from './deleteGGPointOfContactModal.consts';
import ConfirmModal from '../../../../../../styledComponents/ConfirmModal/ConfirmModal';
import {
  Description,
  Name,
} from '../../../../../MemberOverviewPage/Modals/Common/memberOverviewModals.styles';

const DeleteGGPointOfContactModal = () => {
  const { t } = useTranslation();
  const {
    deleteOrganizationPointOfContactHandler,
    organizationUpdating,
    pointOfContact,
    onCancelHandler,
  } = useDeleteGGPointOfContactModal();

  return (
    <ConfirmModal
      buttons={[
        {
          action: onCancelHandler,
          type: 'back',
          styleType: 'light1',
        },
        {
          action: deleteOrganizationPointOfContactHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: organizationUpdating,
        },
      ]}
      disabled={organizationUpdating}
      name={DELETE_GG_POINT_OF_CONTACT_MODAL_NAME}
      title={t('headers.delete-gg-contact')}
    >
      <Description>{t('phrase.confirm-delete')}</Description>
      <Name>
        {pointOfContact &&
          pointOfContact.person &&
          `${pointOfContact.person.firstName} ${pointOfContact.person.lastName}`}
      </Name>
    </ConfirmModal>
  );
};

export default DeleteGGPointOfContactModal;
