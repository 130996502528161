import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useFilters } from '../../../../hooks/useFilters';
import { useRunOnce } from '../../../../hooks/useRunOnce';
import { RenewalApiFilters } from '../../../../models/types/RenewalFilters/RenewalApiFilter';
import { FiltersOptions } from './FiltersForm/FilterTypes/filtersTypes.models';
import { getFilterApiModel } from './FiltersForm/FilterTypes/filterTypes.utils';

import {
  CustomFilter,
  FilterDefinition,
  FilterWithDefinition,
} from './renewalFilters.models';
import {
  decodeCustomFilter,
  encodeFilterValue,
  findFilterDefinition,
} from './renewalFilters.utils';
import { RenewalStaticFilter } from './StaticFilters/renewalStaticFilters.models';
import { RenewalTopFilter } from './TopFilters/renewalTopFilters.models';

type UseRenewalFiltersProps = {
  filtersDefinitions: FilterDefinition<FiltersOptions>[];
  onFilterChanged?: (filters: Record<string, RenewalApiFilters>) => void;
  onExportClick?: (filters: Record<string, RenewalApiFilters>) => void;
  staticFilters?: RenewalStaticFilter[];
  topFilters?: RenewalTopFilter[];
};

export const useRenewalFilters = ({
  filtersDefinitions,
  onFilterChanged,
  onExportClick,
  staticFilters,
  topFilters,
}: UseRenewalFiltersProps) => {
  const [filters, setFilters] = useState<CustomFilter[]>([]);
  const { addFilter, filters: searchFilters } = useFilters();

  const filtersWithDefinitions = useMemo(
    (): FilterWithDefinition[] =>
      filters.map((filter) => ({
        filter,
        filterDefinition: findFilterDefinition(
          filtersDefinitions,
          filter.field.fieldName,
        ),
      })),
    [filters, filtersDefinitions],
  );

  const onAddFilterHandler = (filter: CustomFilter) => {
    const value = encodeFilterValue(filter);
    addFilter(filter.field.fieldName, [value]);
    setFilters((prevSate) => {
      const alls = prevSate
        .filter((x) => x.field.fieldName === filter.field.fieldName)
        .map((x) => encodeFilterValue(x));
      addFilter(filter.field.fieldName, [...alls, encodeFilterValue(filter)]);
      return [...prevSate, filter];
    });
  };

  const onRemoveFilterHandler = (filter: CustomFilter) => {
    setFilters((prevSate) => {
      const newFilters = prevSate.filter((x) => x.id !== filter.id);

      const newSearchFilters = newFilters
        .filter((x) => x.field.fieldName === filter.field.fieldName)
        .map((x) => encodeFilterValue(x));

      addFilter(filter.field.fieldName, [...newSearchFilters]);
      return [...newFilters];
    });
  };

  useRunOnce(() => {
    if (!filters.length) {
      const initialFilters: CustomFilter[] = [];

      Object.keys(searchFilters)
        .filter((searchFilter) =>
          filtersDefinitions.some(
            (filterDefinition) => filterDefinition.field === searchFilter,
          ),
        )
        .forEach((propertyFilters: string) => {
          const value = searchFilters[propertyFilters];
          if (!Array.isArray(value)) {
            initialFilters.push(decodeCustomFilter(value, propertyFilters));
            return;
          }

          value.forEach((element: string) => {
            initialFilters.push(decodeCustomFilter(element, propertyFilters));
          });
        });

      setFilters([...initialFilters]);

      onFilterChanged?.(prepareApiFilters(initialFilters));
    }
  }, !!Object.keys(searchFilters).length);

  const prepareApiFilters = useCallback(
    (currentFilters: CustomFilter[]) => {
      const result = {};
      filtersDefinitions.forEach((filterDefinition) => {
        result[filterDefinition.field] = currentFilters
          .filter((filter) => filter.field.fieldName === filterDefinition.field)
          .map((filter) => getFilterApiModel(filter, filterDefinition));
      });

      staticFilters?.forEach((staticFilter) => {
        if (searchFilters[staticFilter]) {
          result[staticFilter] = searchFilters[staticFilter];
        }
      });

      topFilters?.forEach((topFilter) => {
        if (searchFilters[topFilter]) {
          result[topFilter] = searchFilters[topFilter];
        }
      });
      return result;
    },
    [filtersDefinitions, searchFilters, staticFilters, topFilters],
  );

  useEffect(() => {
    onFilterChanged?.(prepareApiFilters(filters));
  }, [filters, onFilterChanged, prepareApiFilters]);

  const handleExportClick = () => {
    onExportClick?.(prepareApiFilters(filters));
  };

  const onStaticFilterChangeHandler = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, filterType: RenewalStaticFilter) => {
      switch (filterType) {
        case RenewalStaticFilter.HideExpired:
          addFilter(RenewalStaticFilter.HideExpired, evt.target.checked);
      }
    },
    [addFilter],
  );

  const onTopFilterChangeHandler = useCallback(
    (value: string, filterType: RenewalTopFilter) => {
      switch (filterType) {
        case RenewalTopFilter.SearchFilter:
          addFilter(RenewalTopFilter.SearchFilter, value);
      }
    },
    [addFilter],
  );

  return {
    onAddFilterHandler,
    onRemoveFilterHandler,
    filters,
    filtersWithDefinitions,
    onStaticFilterChangeHandler,
    onTopFilterChangeHandler,
    searchFilters,
    handleExportClick,
  };
};
