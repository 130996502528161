import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import ErrorImage from '../../assets/images/500.png';
import ErrorPage from '../../styledComponents/ErrorPage/ErrorPage';

const SomethingWentWrongPage = (props: FallbackProps) => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      iconPath={ErrorImage}
      title={t('headers.something-went-wrong')}
      description={[t('phrase.working-on-fix-problem'), t('phrase.try-again')]}
    />
  );
};

export default SomethingWentWrongPage;
