import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import { SectionWrapper } from '../../Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import {
  ResendWelcomeEmailFormFields,
  ResendWelcomeEmailFormName,
} from './resendWelcomeEmailForm.models';
import { Text } from './resendWelcomeEmailForm.styles';

const ResendWelcomeEmailForm = ({ disabled, className, name }: CustomForm) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper className={className}>
      <Text>{t('phrase.confirm-sending-welcome-email')}</Text>
      <FormColumn>
        <Field
          component={FormInput}
          name={ResendWelcomeEmailFormFields.emailAddress}
          inputId="resend-email-email-input"
          {...getFieldPropertiesForGroup({
            inputId: ResendWelcomeEmailFormName,
            name: ResendWelcomeEmailFormFields.emailAddress,
            groupName: name,
          })}
          disabled={disabled}
          autoComplete="off"
          autoCorrect="off"
        />
      </FormColumn>
    </SectionWrapper>
  );
};
export default ResendWelcomeEmailForm;
