import { useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { AirAmbulanceMembershipOptions } from '../../../../../../../models/enums/AirAmbulanceMembershipOptions';
import { MembershipType } from '../../../../../../../models/MembershipType';
import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { MembershipOrganizationDetailsFormFields } from '../AAMembershipOrganizationDetailsForm/aaMembershipOrganizationDetailsForm.models';
import { MembershipTypeFormFields } from '../AAMembershipTypeForm/aaMembershipType.models';
import {
  OrganizationOptions,
  OrganizationOptionsRenewable,
  RetailOptions,
  RetailOptionsRenewable,
} from './aaMembershipOptionsForm.model';
import { MembershipRetailDetailsFormFields } from '../AAMembershipRetailsDetailsForm/aaMembershipRetailsDetailsForm.models';
import { MembershipPlanPeriodsType } from '../../../../../../../models/enums/MembershipPlanPeriodsType';
import { isAutoRenewal } from '../../addMemberMembershipPage.utils';

export const useMembershipOptions = () => {
  const {
    input: { onChange: sendWelcomeEmailChange },
  }: FieldRenderProps<boolean> = useField(
    AirAmbulanceMembershipOptions.SendWelcomeEmail,
  );
  const {
    input: { onChange: autoRenewalChange },
  }: FieldRenderProps<boolean> = useField(
    AirAmbulanceMembershipOptions.AutoRenewal,
  );

  const {
    input: { value: selectedOrganization },
  }: FieldRenderProps<IOrganizationSearch> = useField(
    MembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { value: subscriptionType },
  }: FieldRenderProps<string> = useField(
    MembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  const {
    input: { value: membershipType },
  }: FieldRenderProps<MembershipType> = useField(
    MembershipTypeFormFields.MembershipType,
  );

  useEffect(() => {
    if (membershipType === MembershipType.Retail) {
      sendWelcomeEmailChange(true);
    }
  }, [autoRenewalChange, membershipType, sendWelcomeEmailChange]);

  useEffect(() => {
    if (membershipType === MembershipType.Retail) {
      return;
    }

    if (!subscriptionType || !selectedOrganization) {
      sendWelcomeEmailChange(false);
      autoRenewalChange(false);
      return;
    }

    const subscriptionIndex = selectedOrganization.subscriptions.findIndex(
      (x) => x.id === subscriptionType,
    );

    if (subscriptionIndex === -1) {
      sendWelcomeEmailChange(false);
      autoRenewalChange(false);
      return;
    }

    sendWelcomeEmailChange(
      selectedOrganization.subscriptions[subscriptionIndex].sendWelcomeEmail,
    );
    autoRenewalChange(
      selectedOrganization.subscriptions[subscriptionIndex]
        .autoRenewalMemberships,
    );
  }, [
    autoRenewalChange,
    membershipType,
    selectedOrganization,
    sendWelcomeEmailChange,
    subscriptionType,
  ]);

  const {
    input: {
      value: { termType },
    },
  }: {
    input: {
      value: { termType: MembershipPlanPeriodsType };
    };
  } = useField(MembershipRetailDetailsFormFields.MembershipPlanId);

  const {
    input: {
      value: { membershipPlanTermType },
    },
  }: {
    input: {
      value: { membershipPlanTermType: MembershipPlanPeriodsType };
    };
  } = useField(MembershipRetailDetailsFormFields.MembershipPlanId);
  const membershipOptions = useMemo(() => {
    const activeTermType = termType || membershipPlanTermType;

    if (!activeTermType) {
      return membershipType === MembershipType.Organization
        ? OrganizationOptionsRenewable
        : RetailOptionsRenewable;
    }

    const isRenewable = isAutoRenewal(activeTermType);

    if (membershipType === MembershipType.Organization) {
      return isRenewable ? OrganizationOptionsRenewable : OrganizationOptions;
    }
    return isRenewable ? RetailOptionsRenewable : RetailOptions;
  }, [membershipPlanTermType, membershipType, termType]);

  return { membershipOptions };
};
