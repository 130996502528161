import styled from '@emotion/styled';

export const Page = styled.div`
  width: 100%;
  height: 100%;
`;

export const RootPage = styled.div`
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 7rem 1fr;
  background-color: ${({ theme }) => theme.color.white};
`;

export const PageRootColumns = styled.section`
  margin: 2.5rem;
  grid-template-columns: 1fr 26.5rem;
  display: grid;
  flex: 1;
  grid-gap: 2.5rem;
`;

export const PageOverviewRootColumns = styled.section`
  grid-template-columns: 1fr 22.5rem;
  display: grid;
  flex: 1;
  grid-gap: 2.5rem;
`;

export const PageFormContainer = styled.div`
  padding: 2.5rem;
  border-radius: 0.5rem;
  border: 0.063rem solid ${({ theme }) => theme.color.light1};
`;

export const LeftContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const RightContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.light1};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem;
`;

export const PageSection = styled.div`
  margin: 0 2.5rem;
`;

export const FullLayoutPage = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const MiddleLayoutPage = styled(FullLayoutPage)`
  padding: 0 2.25rem;
  max-width: 48.375rem;
`;
