import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import WarningsConfirmationModal from '../../../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';

import { withErrorProvider } from '../../../../../../../hoc/withErrorProvider';
import { withWarningsConfirmationProvider } from '../../../../../../../hoc/withWarningsConfirmationProvider';
import { SmallModal } from '../../../../../../../styledComponents/ModalCommon/ModalCommon';
import { EDITING_KE_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './keSubscriptionEditOptionsModal.const';
import { StyledSubscriptionOptions } from './keSubscriptionEditOptionsModal.styles';
import { useKESubscriptionEditDetailsModal } from './keSubscriptionEditOptionsModal.hooks';

interface IKESubscriptionEditDetailsModalProps {
  onModalSuccess: () => void;
}

const KESubscriptionEditOptionsModal = ({
  onModalSuccess,
}: IKESubscriptionEditDetailsModalProps) => {
  const { t } = useTranslation();

  const {
    handleWarningsConfirm,
    handleSubmit,
    subscriptionEditing,
    initData,
    onCancelHandler,
  } = useKESubscriptionEditDetailsModal({ onModalSuccess });

  return (
    <>
      <WarningsConfirmationModal onWarningsConfirm={handleWarningsConfirm} />
      <SmallModal name={EDITING_KE_SUBSCRIPTION_OPTIONS_MODAL_NAME}>
        <Form
          initialValues={initData}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
        >
          {({ ...renderProps }) => (
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={subscriptionEditing}
              onCancel={onCancelHandler}
              mode="small"
              title={t('headers.edit-options')}
            >
              <StyledSubscriptionOptions
                layout="column"
                disabled={subscriptionEditing}
              />
            </ModalFormPage>
          )}
        </Form>
      </SmallModal>
    </>
  );
};

export default withErrorProvider(
  withWarningsConfirmationProvider(KESubscriptionEditOptionsModal),
);
