import {
  SystemGroupPermissions,
  SecurityResponsePermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canAddSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanCreateSubscription,
  ),
];

export const canRemoveSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanDeleteSubscription,
  ),
];

export const canEditSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanUpdateSubscription,
  ),
];

export const canAddSeatsSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanUpdateSubscription,
  ),
];

export const canViewSRSubscriptionDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanViewSubscriptionDetails,
  ),
];

export const canUpdateSRSubscriptionsDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanUpdateSubscription,
  ),
];

export const canRenewSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanCreateSubscription,
  ),
];

export const canExtendsSRSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanUpdateSubscription,
  ),
];

export const canRemoveSRMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanDeleteMembership,
  ),
];

export const canAddSRMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanCreateMembership,
  ),
];

export const canRenewSRMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanCreateMembership,
  ),
];

export const canResendSRWelcomeEmailPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanResendWelcomeEmail,
  ),
];

export const canManageSRMembershipsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanManageMemberships,
  ),
];

export const canEditSRMembershipsOptionsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanManageMemberships,
  ),
];

export const canRemoveSRSecondaryMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanManageMemberships,
  ),
];

export const canChangeSRToBeRenewedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanManageMemberships,
  ),
];

export const canSetSRMembershipAlreadyPayedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanSetMembershipAlreadyPayed,
  ),
];

export const canAddSRMembershipFamilyMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanManageMemberships,
  ),
];

export const canGenerateSRRawDataReportPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanGenerateMembershipReport,
  ),
];

export const canAddSRMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanCreateMembership,
  ),
];

export const canViewSRMembersPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.SecurityResponse,
    SecurityResponsePermissions.CanSearchMembers,
  ),
];
