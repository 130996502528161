import { useTranslation } from 'react-i18next';

import {
  CapacityOverviewProperty,
  DateOverviewProperty,
  Package,
  ProductOverviewProperty,
  PromoCode,
  StyledOverviewProperty,
  TotalOverviewProperty,
} from './subscriptionsListItem.styles';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { IOrganizationSubscription } from '../../../models/interfaces/Subscription/OrganizationSubscription';
import { formatDate } from '../../../utils/dateHelpers';
import CardListItem from '../../UI/CardListItem/CardListItem';
import Price from '../../UI/Price/Price';
import SubscriptionCapacity from './SubscriptionCapacity/SubscriptionCapacity';
import { ProductType } from '../../../models/ProductType';
import { usePermissions } from '../../../hooks/usePermissions';
import { AvailableProductPermissionsType } from '../../../models/types/AvailableProductViewsPermissionsType';
import { AutoCreatedLabel } from '../../Overview/OverviewDetails/OverviewDetails';
import { ProductTypeToShortcut } from '../../../models/records/ProductTypeToShortcut';

interface IOrganizationsListItemProps extends CustomComponent {
  data: IOrganizationSubscription;
  onItemClick?: (subscription: IOrganizationSubscription) => void;
  propertiesWidth?: Partial<Record<keyof IOrganizationSubscription, number>>;
  productViewSubscriptionPermissions?: AvailableProductPermissionsType;
}

const SubscriptionsListItem = ({
  className,
  data,
  onItemClick,
  propertiesWidth = {},
  productViewSubscriptionPermissions,
}: IOrganizationsListItemProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions(
    productViewSubscriptionPermissions[data?.productType],
  );

  return (
    <CardListItem
      className={className}
      onClick={hasPermissions ? () => onItemClick?.(data) : null}
      buttonsContainerComponent={
        data?.isAutoRenewed ? <AutoCreatedLabel /> : null
      }
    >
      <ProductOverviewProperty
        title={t('properties.product')}
        bold
        value={data && ProductTypeToShortcut[data.productType]}
        minWidth={propertiesWidth.productType}
      />
      <DateOverviewProperty
        title={t('properties.effective-date')}
        value={formatDate(data?.effectiveDateFrom)}
        minWidth={propertiesWidth.effectiveDateFrom}
      />
      <DateOverviewProperty
        title={t('properties.expiration-date')}
        value={formatDate(data?.effectiveDateTo)}
        minWidth={propertiesWidth.effectiveDateTo}
      />
      <CapacityOverviewProperty
        title={t('properties.capacity')}
        minWidth={propertiesWidth.capacity}
      >
        <SubscriptionCapacity
          value={data?.capacity?.toString()}
          isOversubscribed={data?.isOversubscribed}
        />
      </CapacityOverviewProperty>
      <TotalOverviewProperty
        minWidth={propertiesWidth.amount}
        title={t('properties.total')}
      >
        <Price price={data?.amount} />
      </TotalOverviewProperty>
      {data.productType === ProductType.AirAmbulance && (
        <StyledOverviewProperty
          minWidth={propertiesWidth.promoCode}
          title={t('properties.promo-code')}
        >
          <PromoCode>{data?.promoCode}</PromoCode>
        </StyledOverviewProperty>
      )}
      {!!data?.packageType && (
        <StyledOverviewProperty
          minWidth={propertiesWidth.packageType}
          title={t('properties.package-type')}
          value={data?.packageType}
        >
          <Package>{data?.packageType}</Package>
        </StyledOverviewProperty>
      )}
    </CardListItem>
  );
};

export default SubscriptionsListItem;
