import { t } from 'i18next';
import { FC } from 'react';

import { AirAmbulanceSubscriptionOptions } from '../../../models/enums/AirAmbulanceSubscriptionOptions';
import { DutyOfCareSubscriptionOptions } from '../../../models/enums/DutyOfCareSubscriptionOptions';
import { KidnapAndExtortionSubscriptionOptions } from '../../../models/enums/KidnapAndExtortionSubscriptionOptions';
import { SecurityResponseSubscriptionOptions } from '../../../models/enums/SecurityResponseSubscriptionOptions';
import { TravelLicenseSubscriptionOptions } from '../../../models/enums/TravelLicenseSubscriptionOptions';

export type SubscriptionOptionsType = {
  checkboxContentRenderer?: FC<{ disabled: boolean }>;
  name: string;
  label?: string;
};

export const AASubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: AirAmbulanceSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];

export const KESubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: KidnapAndExtortionSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];

export const SRSubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: SecurityResponseSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];

export const DOCSubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: DutyOfCareSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];

export const TLSubscriptionOptions: SubscriptionOptionsType[] = [
  {
    name: TravelLicenseSubscriptionOptions.AutoRenewal,
    label: t('fields.autoRenewSubscription.label'),
  },
];
