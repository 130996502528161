import { useTranslation } from 'react-i18next';

import AddContractModal from '../../../styledComponents/Modals/AddContractModal/AddContractModal';
import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { SubscriptionPricingFormFields } from '../../../styledComponents/SubscriptionFormPage/FormSections/AirAmbulanceSubscription/SubscriptionPricingForm/subscriptionPricingForm.models';
import { useEditTLSubscriptionPage } from './editTLSubscriptionPage.hooks';
import EditTLSubscriptionForm from './EditTLSubscriptionForm/EditTLSubscriptionForm';

const EditTLSubscriptionPage = () => {
  const { t } = useTranslation();
  const {
    organizationName,
    subscriptionType,
    loadDatesForNewSubscriptionHandler,
  } = useEditTLSubscriptionPage();

  return (
    <FormErrorProvider
      initErrorGroups={[SubscriptionPricingFormFields.Pricing]}
    >
      <AddContractModal />
      <Header
        title={t('headers.edit-product-subscription', {
          organizationName,
          subscriptionType,
        })}
      />

      <EditTLSubscriptionForm
        onContractChanged={loadDatesForNewSubscriptionHandler}
      />
    </FormErrorProvider>
  );
};

export default EditTLSubscriptionPage;
