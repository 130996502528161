import { useCallback } from 'react';

import { useActions } from '../../../../hooks/useActions';
import { MemberType } from '../../../../models/enums/MemberType';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { ADD_SR_FAMILY_MEMBER_MODAL_NAME } from '../Modals/AddSRFamilyMemberModal/addSRFamilyMemberModal.const';
import { AddSRFamilyMemberModalDetails } from '../Modals/AddSRFamilyMemberModal/addSRFamilyMemberModal.models';

type UseSRMembershipActiveDetailsProps = {
  membershipId?: string;
  canAddMemberTypes?: MemberType[];
};

export const useSRMembershipActiveDetails = ({
  canAddMemberTypes,
  membershipId,
}: UseSRMembershipActiveDetailsProps) => {
  const UpdateModalState = useActions(updateModalState);

  const addFamilyMemberHandler = useCallback(() => {
    const details: AddSRFamilyMemberModalDetails = {
      canAddMemberTypes,
      membershipId,
    };
    UpdateModalState([ADD_SR_FAMILY_MEMBER_MODAL_NAME + membershipId, details]);
  }, [UpdateModalState, canAddMemberTypes, membershipId]);

  return {
    addFamilyMemberHandler,
  };
};
