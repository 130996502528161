import { SectionTitle } from '../../Typography/Typography';
import {
  Content,
  StyledCollapseItem,
} from './renewSubscriptionItemForm.styles';
import { getSubscriptionTitle } from './renewSubscriptionItemForm.utils';
import { RenewSubscriptionItemBaseProps } from './renewSubscriptionItemForm.models';
import { ProductType } from '../../../models/ProductType';

type RenewSubscriptionItemFormProps = RenewSubscriptionItemBaseProps & {
  number?: number;
  collapsible?: boolean;
  productType?: ProductType;
};

const RenewSubscriptionItemForm = ({
  number,
  collapsible = true,
  children,
  productType,
}: RenewSubscriptionItemFormProps) => (
  <StyledCollapseItem
    defaultOpen
    collapseButtonHidden={!collapsible}
    title={
      <SectionTitle>{getSubscriptionTitle(productType, number)}</SectionTitle>
    }
  >
    <Content>{children}</Content>
  </StyledCollapseItem>
);

export default RenewSubscriptionItemForm;
