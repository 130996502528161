import moment from 'moment';

import { DEFAULT_EXPIRATION_DATE_CARD_FORMAT } from '../creditCardHelpers';
import {
  PHONE_NUMBER_MAX_LENGTH,
  PHONE_NUMBER_MIN_LENGTH,
} from '../phoneNumberHelpers';

export enum ValidationCustomFormat {
  Phone = 'phone',
  CreditCardExpirationDate = 'creditCardExpirationDate',
  MonthsDate = 'monthsDate',
}

export const addCustomFormats = (avj: any) => {
  avj.addFormat(ValidationCustomFormat.Phone, preparePhoneFormat());
  avj.addFormat(
    ValidationCustomFormat.CreditCardExpirationDate,
    prepareCreditCardExpirationDateFormat(),
  );
  avj.addFormat(ValidationCustomFormat.MonthsDate, prepareMothsDateFormat());
};

const preparePhoneFormat = () => ({
  type: 'string',
  validate: (x: any) =>
    x.length >= PHONE_NUMBER_MIN_LENGTH && x.length <= PHONE_NUMBER_MAX_LENGTH,
});

const prepareCreditCardExpirationDateFormat = () => ({
  type: 'string',
  validate: (x: string) => x.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/),
  compare: (currentDate: string, compareDate: string) => {
    if (
      moment(currentDate, DEFAULT_EXPIRATION_DATE_CARD_FORMAT).isBefore(
        moment(compareDate),
        'month',
      )
    )
      return -1;

    if (
      moment(currentDate, DEFAULT_EXPIRATION_DATE_CARD_FORMAT).isAfter(
        moment(compareDate),
        'month',
      )
    )
      return 1;
    return 0;
  },
});

const prepareMothsDateFormat = () => ({
  type: 'string',
  validate: (x: string) => x.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/),
});
