import { AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { KE_PRICING_LOADING_PROCESSING } from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import {
  ILoadStandardPricingPayload,
  IStandardPricingQueryResponse,
} from '../common/models';
import { getKEStandardPricingQuery } from './api';
import { setKEMembershipPlansPrices } from '../../../redux-slices/pricing/kidnapAndExtortion';

const kePricingSlice = createSliceSaga({
  name: 'ke-pricing-saga',
  caseSagas: {
    *keLoadStandardPricing({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadStandardPricingPayload>) {
      try {
        yield put(updateProcessState(KE_PRICING_LOADING_PROCESSING));

        const response: AxiosResponse<IStandardPricingQueryResponse> =
          yield* call(getKEStandardPricingQuery, cancellationToken);

        yield put(
          setKEMembershipPlansPrices(response.data.membershipPlansPrices),
        );

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(KE_PRICING_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default kePricingSlice.saga;
export const { keLoadStandardPricing } = kePricingSlice.actions;
export const { actions } = kePricingSlice;
