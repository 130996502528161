import styled from '@emotion/styled/macro';

import Button from '../../../UI/Button/Button';

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
`;
