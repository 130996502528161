import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IAAMembersState } from '.';

export const aaMembershipSelector = (state: RootState) => state.aaMembership;

export const aaMembershipSummarySelector = createSelector(
  aaMembershipSelector,
  (members: IAAMembersState) => members.summary,
);

export const pageListAAMembersSelector = createSelector(
  aaMembershipSelector,
  (members: IAAMembersState) => members.members,
);

export const aaMembershipDatesSelector = createSelector(
  aaMembershipSelector,
  (members: IAAMembersState) => members.newMembershipDates,
);

export const aaMembershipDetailsByIdSelector = (membershipId: string) =>
  createSelector(
    aaMembershipSelector,
    (members: IAAMembersState) =>
      members.membershipDetails && members.membershipDetails[membershipId],
  );
