import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SectionWrapper } from '../../../../styledComponents/Form/Form';
import { FormColumn } from '../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../styledComponents/UI/Form/FormRow/FormRow';
import { useAddPromoCodeCommissionForm } from './addPromoCodeCommissionForm.hooks';
import { AddPromoCodeCommissionFormFields } from './addPromoCodeCommissionForm.models';
import {
  StyledFormRadioGroupContent,
  StyledSectionTitle,
} from './addPromoCodeCommissionForm.styles';
import { CommissionTypeItems } from './addPromoCodeCommissionForm.utils';

interface IAddPromoCodeDetailsFormProps {
  isDisabled?: boolean;
}

const AddPromoCodeCommissionForm = ({
  isDisabled,
}: IAddPromoCodeDetailsFormProps) => {
  const { t } = useTranslation();
  const { onChangeCommissionTypeHandler } = useAddPromoCodeCommissionForm();
  return (
    <SectionWrapper>
      <StyledSectionTitle>{t('headers.commission-type')}</StyledSectionTitle>
      <FormColumn>
        <FormRow isSubRow>
          <Field
            component={StyledFormRadioGroupContent}
            disabled={isDisabled}
            name={AddPromoCodeCommissionFormFields.CommissionType}
            inputId="commission-type-input"
            items={CommissionTypeItems}
            onChange={onChangeCommissionTypeHandler}
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default AddPromoCodeCommissionForm;
