import styled from '@emotion/styled/macro';

import { SectionWrapper } from '../../../Form/Form';

import FormDatePicker from '../../../UI/Form/FormDatePicker/FormDatePicker';

export const StyledFormDatePicker = styled(FormDatePicker)`
  min-width: 15rem;
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
  margin-top: 0;
`;
