import { customDateFormat, CUSTOM_DATE_FORMAT } from './dateHelpers';
import { getCurrentDate } from './getCurrentDate';

export const generateFilenameWithDate = (
  filenamePrefix: string,
  dateFormat = CUSTOM_DATE_FORMAT,
  date?: string,
) => {
  let fileDate: string = date;

  if (!date) {
    fileDate = getCurrentDate();
  }

  return `${filenamePrefix}${customDateFormat(fileDate, dateFormat)}`;
};
