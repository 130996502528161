import { SelectOption } from '../mainSelect.types';
import { LabelContainer } from './optionLabel.styles';

type Props<T> = {
  currentValue: string | string[] | T;
  data: unknown;
  isIconVisible: boolean;
  disabled?: boolean;
};

export const OptionLabel = <T,>({
  currentValue,
  data,
  isIconVisible,
  disabled,
}: Props<T>) => {
  const selectedData = data as SelectOption;
  const { value, label } = selectedData;

  const optionValue = Array.isArray(currentValue)
    ? currentValue
    : [currentValue];
  const isSelected = isIconVisible && (optionValue || []).includes(value);

  return (
    <LabelContainer disabled={disabled} isSelected={isSelected}>
      <>{label}</>
    </LabelContainer>
  );
};
