import {
  canEditAASubscriptionPermissions,
  canEditDOCSubscriptionPermissions,
  canEditKESubscriptionPermissions,
  canEditSRSubscriptionPermissions,
  canEditTLSubscriptionPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../models/ProductType';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';
import EditAASubscriptionPage from './EditAASubscriptionPage/EditAASubscriptionPage';
import EditDOCSubscriptionPage from './EditDOCSubscriptionPage/EditDOCSubscriptionPage';
import EditKESubscriptionPage from './EditKESubscriptionPage/EditKESubscriptionPage';
import EditSRSubscriptionPage from './EditSRSubscriptionPage/EditSRSubscriptionPage';
import EditTLSubscriptionPage from './EditTLSubscriptionPage/EditTLSubscriptionPage';

export const EditSubscriptionPages: Partial<
  Record<ProductType, () => JSX.Element>
> = {
  [ProductType.AirAmbulance]: EditAASubscriptionPage,
  [ProductType.DutyOfCare]: EditDOCSubscriptionPage,
  [ProductType.KidnapAndExtortion]: EditKESubscriptionPage,
  [ProductType.SecurityResponse]: EditSRSubscriptionPage,
  [ProductType.TravelLicense]: EditTLSubscriptionPage,
};

export const EditSubscriptionViewsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canEditAASubscriptionPermissions,
    KidnapAndExtortion: canEditKESubscriptionPermissions,
    SecurityResponse: canEditSRSubscriptionPermissions,
    DutyOfCare: canEditDOCSubscriptionPermissions,
    TravelLicense: canEditTLSubscriptionPermissions,
  };
