import { t } from 'i18next';
import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { ContractDetailsFormFields } from './contractDetailsForm.models';
import { ICreateOrganizationContractModel } from '../../../../models/CreateOrganizationContractModel';
import { OrganizationContractType } from '../../../../models/enums/ContractType';

export const organizationContractSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [ContractDetailsFormFields.Name]: {
      type: 'string',
      maxLength: 100,
    },
    [ContractDetailsFormFields.ExpirationDate]: {
      type: 'string',
      format: 'date',
      formatMinimum: { $data: `1/${ContractDetailsFormFields.StartDate}` },
      errorMessage: {
        formatMinimum: t('validation.expiration-date-earlier-signed-date'),
        format: t('validation.invalid-date'),
      },
    },
    [ContractDetailsFormFields.StartDate]: {
      type: 'string',
      format: 'date',
    },
  },

  if: {
    properties: {
      [ContractDetailsFormFields.ContractType]: {
        enum: [OrganizationContractType.Multiple],
      },
    },
  },
  then: {
    required: [ContractDetailsFormFields.ExpirationDate],
  },
  required: [
    ContractDetailsFormFields.Name,
    ContractDetailsFormFields.ContractType,
    ContractDetailsFormFields.StartDate,
  ],
};

export const validateContractDetailsForm = (
  data: ICreateOrganizationContractModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, organizationContractSchema);
