/* eslint-disable react/no-array-index-key */
import { Fragment, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../../models/types/CustomComponent';
import Button from '../../../../../../styledComponents/UI/Button/Button';
import {
  FilterListItemWrapper,
  Label,
  LabelContainer,
} from './filtersListItem.styles';
import { ReactComponent as RemoveIcon } from '../../../../../../assets/icons/remove-icon.svg';

type FiltersListItemProps = CustomComponent & {
  label: string | string[];
  onRemove?: () => void;
};

const FiltersListItem = ({
  className,
  label,
  onRemove,
}: FiltersListItemProps) => {
  const { t } = useTranslation();
  let labelComponent: ReactNode = <Label>{label}</Label>;

  if (Array.isArray(label)) {
    labelComponent = label.map((item, index) => (
      <Fragment key={item + index}>
        <Label>{item}</Label>
        {index < label.length - 1 && <Label>{t('filters.or')}</Label>}
      </Fragment>
    ));
  }

  return (
    <FilterListItemWrapper className={className}>
      <LabelContainer>{labelComponent}</LabelContainer>
      <Button onClick={onRemove} styleType="icon">
        <RemoveIcon />
      </Button>
    </FilterListItemWrapper>
  );
};

export default FiltersListItem;
