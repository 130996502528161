import styled from '@emotion/styled/macro';

import {
  ProductTypeBackgroundSubColors,
  ProductTypeSubColors,
} from './productsRenderer.models';
import { ShortcutProductType } from '../../../../models/enums/ShortcutProductType';

export const SubscriptionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubscriptionsShortcuts = styled.div`
  display: flex;
`;

type SubscriptionsShortcutProps = {
  productType: ShortcutProductType;
};

export const SubscriptionsShortcut = styled.div<SubscriptionsShortcutProps>`
  padding: 0.2rem;
  margin-left: -0.25rem;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 0.4rem;
  color: ${({ productType }) => ProductTypeSubColors[productType]};
  background-color: ${({ productType }) =>
    ProductTypeBackgroundSubColors[productType]};
  font: normal 700 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};

  &:first-of-type {
    margin-left: 0;
  }
`;
