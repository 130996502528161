import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../../../../utils/dateHelpers';
import { isPropertyEmpty } from '../../../../../../../utils/propertyHelpers';
import { Status } from '../../../../../../../styledComponents/UI/Status/Status';

import {
  Header,
  Wrapper,
  Title,
  DetailsContainer,
  StyledButton,
  Detail,
  DetailLabel,
  DetailValue,
  DetailBreak,
  DetailValueBold,
} from '../../../../../../../styledComponents/Overview/OverviewDetails/OverviewDetails';
import { IDOCOrganizationSubscriptionOverview } from '../../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { EditableStatus } from '../../../../../../../styledComponents/UI/EditableStatus/EditableStatus';

type DOCOverviewDetailsProps = {
  data: IDOCOrganizationSubscriptionOverview;
  onRenewClick?: () => void;
  onEditRenewalStatus?: () => void;
  canBeRenewed?: boolean;
  canRenewMembershipPermissions?: string[];
  renewButtonVisible?: boolean;
};

const DOCOverviewDetails = ({
  data,
  onRenewClick,
  onEditRenewalStatus,
  canBeRenewed,
  canRenewMembershipPermissions,
}: DOCOverviewDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Title>{t('headers.details')}</Title>
        {onRenewClick && (
          <StyledButton
            permissions={canRenewMembershipPermissions}
            styleType="link"
            onClick={onRenewClick}
            disabled={!isPropertyEmpty(canBeRenewed) ? !canBeRenewed : false}
          >
            {t('buttons.renew')}
          </StyledButton>
        )}
      </Header>
      <DetailsContainer>
        <Detail>
          <DetailLabel>{`${t('properties.status')}:`}</DetailLabel>
          <DetailValue>
            {data && (
              <Status status={data.status} enumName="subscription-status" />
            )}
          </DetailValue>
        </Detail>
        <DetailBreak />
        <Detail>
          <DetailLabel>{`${t('properties.effective-date')}:`}</DetailLabel>
          <DetailValue>
            {data && formatDate(data.effectiveDateFrom)}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.expiration-date')}:`} </DetailLabel>
          <DetailValue>{data && formatDate(data.effectiveDateTo)}</DetailValue>
        </Detail>
        {data && data.organizationContractName && (
          <Detail>
            <DetailLabel>{`${t('properties.relatedContract')}:`} </DetailLabel>
            <DetailValueBold>
              {data &&
                `${data.organizationContractName} - ${t(
                  `enums.contract-type-shortcut.${data.organizationContractType}`,
                )}`}
            </DetailValueBold>
          </Detail>
        )}
        <Detail>
          <DetailLabel>{`${t('properties.renewalStatus')}:`}</DetailLabel>
          <EditableStatus
            enumName="renewal-status"
            status={data?.renewalStatus}
            onClick={onEditRenewalStatus}
          />
        </Detail>
      </DetailsContainer>
    </Wrapper>
  );
};
export default DOCOverviewDetails;
