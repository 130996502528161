import { useTranslation } from 'react-i18next';

import { PricingMode } from '../../../../../models/enums/PricingMode';

import MembershipPlanDiscount from '../MembershipPlanDiscount/MembershipPlanDiscount';

import {
  useMembershipPlanPricesActions,
  useMembershipPlanPricesValues,
} from '../useMembershipPlanPrices/useMembershipPlanPrices';
import { StyledRadioGroup } from './memberPlanPriceTypes.styles';

interface IMemberPlanPriceTypesProps {
  isDisabled: boolean;
  onPlanPricingModeChange?: (pricingMode: PricingMode) => void;
  availablePricingModes?: PricingMode[];
}

const MemberPlanPriceTypes = ({
  isDisabled,
  onPlanPricingModeChange,
  availablePricingModes,
}: IMemberPlanPriceTypesProps) => {
  const { t } = useTranslation();

  const {
    state: { pricingMode, discountType, discountValue },
  } = useMembershipPlanPricesValues();

  const { changeDiscountType, changeDiscountValue, changePricingMode } =
    useMembershipPlanPricesActions();

  const changePricingModeHandler = (pricingModeValue: PricingMode) => {
    changePricingMode?.(pricingModeValue);
    onPlanPricingModeChange?.(pricingModeValue);
  };

  return (
    <div>
      <StyledRadioGroup
        disabled={isDisabled}
        name="pricingMode"
        onChange={changePricingModeHandler}
        options={availablePricingModes.map((product) => ({
          label: t(`enums.pricing-mode.${product}`),
          value: product,
        }))}
        value={pricingMode}
      />

      {pricingMode === PricingMode.Discount && (
        <MembershipPlanDiscount
          disabled={isDisabled}
          onChangeDiscountType={changeDiscountType}
          onChangeDiscountValue={changeDiscountValue}
          value={{
            discountType,
            discountValue,
          }}
        />
      )}
    </div>
  );
};

export default MemberPlanPriceTypes;
