import { t } from 'i18next';

import { DateFilterSingleType } from '../../../../../../../../../models/enums/RenewalFilters/DateFilterSingleType';

import { SelectOption } from '../../../../../../../../../models/SelectOption';

export const singleDateFieldsOptions: SelectOption[] = Object.values(
  DateFilterSingleType,
).map((field) => ({
  value: field,
  label: t(`filters.${field}`),
}));
