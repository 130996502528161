import styled from '@emotion/styled/macro';
import TextField from '@mui/material/TextField';

import { transientOptions } from '../../../../utils/transientOptions';

type StyledTextFieldProps = {
  $isError?: boolean;
  $isFocused?: boolean;
};

export const StyledTextField = styled(
  TextField,
  transientOptions,
)<StyledTextFieldProps>`
  height: 100%;
  z-index: 1;

  .MuiInputBase-root {
    height: 100%;
    font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
    &:hover {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0.063rem solid
        ${({ theme, $isError, $isFocused }) =>
          $isError ? theme.color.error : theme.color.input_border};
    }
  }
`;
