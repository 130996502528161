import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays';

import AddMemberWizardPage from '../../AddMemberWizardPage/AddMemberWizardPage';
import AddMemberSummaryForm from '../../AddMemberSummaryForm/AddMemberSummaryForm';

import { useAddMemberMembershipForm } from './addMemberMembership.hooks';
import { MembershipType } from '../../../../../models/MembershipType';
import FormErrorActivator from '../../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { focusOnErrorDecorator } from '../../../../../utils/focusOnErrorDecorator';
import { AddMemberWizardPageContainersKeys } from '../../AddMemberWizardPage/addMemberWizardPage.models';
import RepaymentNotPaymentModal from '../../Modals/RepaymentNotPaymentModal/RepaymentNotPossibleModal';
import WarningsConfirmationModal from '../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { StyledFormSubmitButton } from './addMemberMembership.styles';
import AddMembershipItemForm from '../MembershipItemForm/MembershipItemForm';
import { ProductType } from '../../../../../models/ProductType';
import { ICreateAAMembershipPageModel } from '../../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import MemberSubscriptionsList from '../../MemberSubscriptionsList/MemberSubscriptionsList';

const AddMemberMembershipForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    dataLoading,
    memberCreating,
    formInitState,
    validateForm,
    createMembership,
    disabledProductType,
    primaryMemberId,
    isRenewalMode,
  } = useAddMemberMembershipForm();

  return (
    <>
      <WarningsConfirmationModal
        message={t('phrase.member-has-incorrect-data')}
        onWarningsConfirm={createMembership}
      />
      <RepaymentNotPaymentModal onWarningsConfirm={createMembership} />
      <Form
        decorators={[focusOnErrorDecorator]}
        initialValues={formInitState}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ dirty, values, ...renderProps }) => (
          <FormErrorActivator>
            <form onSubmit={renderProps.handleSubmit}>
              <AddMemberWizardPage
                isDirty={dirty}
                isLoading={dataLoading}
                disabled={memberCreating}
              >
                <div key={AddMemberWizardPageContainersKeys.LeftContainer}>
                  <AddMembershipItemForm
                    isRenewalMode={isRenewalMode}
                    isProcessing={memberCreating}
                    values={values}
                    disabledProductType={disabledProductType}
                  />
                </div>
                <div key={AddMemberWizardPageContainersKeys.RightContainer}>
                  <AddMemberSummaryForm
                    disabled={
                      memberCreating ||
                      !(
                        values.productType === ProductType.AirAmbulance &&
                        (values as ICreateAAMembershipPageModel)
                          .membershipType === MembershipType.Retail
                      )
                    }
                    title={t('headers.summary')}
                    isPriceOverridden={values.overwriteAmount}
                  />
                  <StyledFormSubmitButton
                    isLoading={memberCreating}
                    type="submit"
                  >
                    {t('buttons.confirm').toLocaleUpperCase()}
                  </StyledFormSubmitButton>
                  {!!primaryMemberId && (
                    <MemberSubscriptionsList personId={primaryMemberId} />
                  )}
                </div>
              </AddMemberWizardPage>
            </form>
          </FormErrorActivator>
        )}
      </Form>
    </>
  );
};

export default AddMemberMembershipForm;
