import { t } from 'i18next';

import { ISelectCategoryFilter } from '../../../../../../../models/interfaces/RenewalFilters/SelectCategoryFilter';

import { SelectOption } from '../../../../../../../models/SelectOption';

import { CustomFilter, FilterDefinition } from '../../../renewalFilters.models';
import {
  SelectCategoryFilterAdditionalValuesModel,
  SelectCategoryFilterOptionsModel,
} from './selectCategoryFilter.models';

export const getSelectCategoryFilterTag = (
  filter: CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>,
) =>
  `${getDefaultValueTag(filter, filterDefinition)} = ${getCategoryValuesTag(
    filter,
    filterDefinition,
  )}`;

export const getDefaultValueTag = (
  filter: CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>,
) => {
  const value = getValue(filter, filterDefinition);
  return `${t(`filters.tags.${filter?.field?.fieldName}`)} = ${
    !filterDefinition?.filterOptions?.valueTagLabelPath
      ? value
      : t(`${filterDefinition?.filterOptions?.valueTagLabelPath}.${value}`)
  }`;
};

export const getCategoryValuesTag = (
  filter: CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>,
) => {
  if (!Array.isArray(filter.additionalValues?.categoryValues)) {
    return !!filterDefinition?.filterOptions.categoryPromiseOptions
      ? (filter.additionalValues?.categoryValues as SelectOption<string>).label
      : filter.additionalValues?.categoryValues;
  }
  const values = !!filterDefinition?.filterOptions.categoryPromiseOptions
    ? (filter.additionalValues?.categoryValues as SelectOption<string>[]).map(
        (value) => value.label,
      )
    : (filter.additionalValues?.categoryValues as string[]);

  if (values.length < 3) {
    return `${values.join(` ${t('filters.or')} `)}`;
  }

  return `${values.slice(0, -1).join(', ')} ${t('filters.or')} ${
    values[values.length - 1]
  }`;
};

const getValue = (
  filter: CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>,
) =>
  !!filterDefinition.filterOptions.promiseOptions
    ? (filter?.value as SelectOption<string>).value
    : (filter.value as string);

export const getSelectCategoryFilterApiModel = (
  filter: CustomFilter<SelectCategoryFilterAdditionalValuesModel>,
  filterDefinition: FilterDefinition<SelectCategoryFilterOptionsModel>,
): ISelectCategoryFilter => {
  const value = getValue(filter, filterDefinition);
  const categoryValues = !!filterDefinition?.filterOptions
    .categoryPromiseOptions
    ? (filter.additionalValues?.categoryValues as SelectOption[]).map(
        (x: SelectOption) => x.value,
      )
    : (filter.additionalValues?.categoryValues as string[]);
  return { selectedCategory: value, selectedItems: categoryValues };
};
