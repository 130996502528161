import { useTranslation } from 'react-i18next';

import {
  canAddAAPromoCodePermissions,
  canViewAAPromoCodeDetailsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import PromoCodesListPage from '../../styledComponents/PromoCodesListPage/PromoCodesListPage';
import SimplePageHeader from '../../styledComponents/SimplePage/SimplePageHeader/SimplePageHeader';
import { usePromoCodes } from './promoCodesPage.hooks';

const PromoCodesPage = () => {
  const { t } = useTranslation();
  const {
    dataLoading,
    promoCodesList,
    rowClickHandler,
    onAddPromoCodeHandler,
    onCellClickHandler,
  } = usePromoCodes();
  return (
    <>
      <SimplePageHeader
        title={t('headers.promo-codes')}
        buttonTitle={t('buttons.add-promo-code')}
        onButtonClick={onAddPromoCodeHandler}
        actionPermissions={canAddAAPromoCodePermissions}
      />
      <PromoCodesListPage
        data={promoCodesList}
        isLoading={dataLoading}
        onRowClick={rowClickHandler}
        canViewDetailsPermissions={canViewAAPromoCodeDetailsPermissions}
        onCellClick={onCellClickHandler}
      />
    </>
  );
};

export default PromoCodesPage;
