import { useState, MouseEvent, useRef } from 'react';

export const useMenuOptionsRenderer = (
  onElementClick?: (option: string) => void,
) => {
  const ref = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const onMenuElementClickHandler = (
    option: string,
    evt?: MouseEvent<HTMLElement>,
  ) => {
    evt?.stopPropagation();
    evt?.preventDefault();
    setMenuVisible(false);
    onElementClick?.(option);
  };

  const onMenuShowHandler = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setMenuVisible(true);
  };

  const onMenuCloseHandler = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setMenuVisible(false);
  };

  return {
    ref,
    onMenuCloseHandler,
    onMenuElementClickHandler,
    onMenuShowHandler,
    menuVisible,
  };
};
