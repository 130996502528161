import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { SUBSCRIPTIONS_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';

import { ILoadSubscriptionsPayload } from '../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { loadSubscriptions } from '../../../../store/saga-slices/subscriptions/common';
import { clearSubscriptionsList } from '../../../../store/redux-slices/subscriptions/common';
import {
  subscriptionsAvailableSelector,
  subscriptionsGroupsListSelector,
} from '../../../../store/redux-slices/subscriptions/common/selectors';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../models/types/RoutePath';

export const useProductSubscriptionsTabPage = () => {
  const { id } = useParams();
  const navigate = useNavigateSearch();
  const LoadSubscriptions = useActions(loadSubscriptions);
  const ClearSubscriptionsList = useActions(clearSubscriptionsList);
  const subscriptionIsLoading = useProcessing(SUBSCRIPTIONS_LOADING_PROCESSING);
  const subscriptionsList = useSelector(subscriptionsGroupsListSelector);
  const subscriptionsAvailable = useSelector(subscriptionsAvailableSelector);

  useEffect(() => {
    const payload: ILoadSubscriptionsPayload = {
      organizationId: id,
    };
    LoadSubscriptions(payload);
  }, [LoadSubscriptions, id]);

  useEffect(() => () => ClearSubscriptionsList(), [ClearSubscriptionsList]);

  const handleAddSubscriptionNavigate = useCallback(() => {
    navigate(RoutePath.AddProductSubscription, { params: { id } });
  }, [id, navigate]);

  const handleItemClickNavigate = useCallback(
    (subscription: IOrganizationSubscription) => {
      const params: ProductSubscriptionOverviewPageParamsType = {
        subscriptionId: subscription?.id,
        subscriptionType: subscription?.productType,
      };
      navigate(RoutePath.ClientOverviewProductSubscriptionOverviewPage, {
        params,
      });
    },
    [navigate],
  );

  return {
    subscriptionsList,
    subscriptionIsLoading,
    id,
    handleAddSubscriptionNavigate,
    handleItemClickNavigate,
    subscriptionsAvailable,
  };
};
