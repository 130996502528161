import { useSelector } from 'react-redux';

import { useProcessing } from 'hooks/useProcessing';

import { SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import SummaryContainer from '../../../../../styledComponents/SummaryContainer/SummaryContainer';
import SummaryContent from './ExtendSubscriptionSummaryContent/ExtendSubscriptionSummaryContent';
import { subscriptionExtensionSummarySelector } from '../../../../../store/redux-slices/subscriptions/common/selectors';
import { ProductType } from '../../../../../models/ProductType';

interface IExtendSubscriptionSummaryFormProps {
  disabled?: boolean;
  title?: string;
  productType: ProductType;
  isPriceOverridden?: boolean;
}

const ExtendSubscriptionSummaryForm = ({
  disabled,
  title,
  productType,
  isPriceOverridden,
}: IExtendSubscriptionSummaryFormProps) => {
  const summaryData = useSelector(subscriptionExtensionSummarySelector);
  const summaryIsLoading = useProcessing(
    SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  );

  return (
    <SummaryContainer
      content={() =>
        summaryData && (
          <SummaryContent
            isPriceOverridden={isPriceOverridden}
            summaryData={summaryData}
            productType={productType}
            disabled={disabled}
            subtotalValue={summaryData.totalAmount}
          />
        )
      }
      data={{ totalAmount: summaryData ? summaryData.totalAmount : 0 }}
      disabled={disabled}
      isLoading={summaryIsLoading}
      title={title}
      canOverwriteAmount={false}
    />
  );
};

export default ExtendSubscriptionSummaryForm;
