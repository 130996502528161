/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IDOCSubscriptionPackageOptionsHistoryModel } from '../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { IDOCOrganizationSubscriptionOverviewHistoryPackage } from '../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewHistoryPackage';
import {
  PricingRenderer,
  DateRenderer,
  NameRenderer,
} from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { NumberRenderer } from '../../../../../../styledComponents/MaterialTable/CellRenderers/NumberRenderer/NumberRenderer';
import MaterialTable from '../../../../../../styledComponents/MaterialTable/MaterialTable';


interface IProductSubscriptionOverviewHistoryPackagesTableProps {
  dataLoading?: boolean;
  packageOptionsHistory: IDOCSubscriptionPackageOptionsHistoryModel[];
  onRowClick?: (data: IDOCOrganizationSubscriptionOverviewHistoryPackage) => void;
  canClickRowPermissions?: string[];
}

const DOCSubscriptionOverviewHistoryPackagesTable = ({
  dataLoading,
  packageOptionsHistory,
  onRowClick,
  canClickRowPermissions,
}: IProductSubscriptionOverviewHistoryPackagesTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (
      key: keyof IDOCSubscriptionPackageOptionsHistoryModel,
      options?: ICellOptions,
    ) =>
    (data: IDOCSubscriptionPackageOptionsHistoryModel) => {
      switch (key) {
        case 'packageType': {
          return (
            <NameRenderer options={options} value={data.packageType} />
          );
        }
        case 'totalPrice': {
          return (
            <PricingRenderer options={options} price={data.totalPrice} readonly />
          );
        }
        case 'seatsAmount': {
          return (
            <NumberRenderer
              options={options}
              number={data.seatsAmount}
            />
          );
        }
        case 'editDate': {
          return (
            <DateRenderer
              date={data.editDate}
              options={options}
            />
          );
        }
      }
    };


  return (
    <MaterialTable
      onRowClick={onRowClick}
      columns={[
        {
          title: t('grid-columns.package'),
          field: 'packageType',
          cellRenderer: cellRenderer('packageType', { alignment: 'left', textBold: true }),
        },
        {
          title: t('grid-columns.price'),
          field: 'totalPrice',
          alignment: 'left',
          cellRenderer: cellRenderer('totalPrice', { alignment: 'left' }),
        },
        {
          title: t('grid-columns.seats'),
          alignment: 'left',
          field: 'seatsAmount',
          cellRenderer: cellRenderer('seatsAmount', { alignment: 'left' }),
        },
        {
          title: t('grid-columns.date'),
          alignment: 'left',
          field: 'editDate',
          cellRenderer: cellRenderer('editDate'),
        },
      ]}
      data={packageOptionsHistory ? packageOptionsHistory : []}
      dataLoading={dataLoading}
      isPaginationHidden
      canClickRowPermissions={canClickRowPermissions}
    />
  );
};

export default DOCSubscriptionOverviewHistoryPackagesTable;
