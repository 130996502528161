import styled from '@emotion/styled';

import { SectionSubTitle } from '../../Typography/Typography';
import { InputCheckbox } from './InputCheckbox/InputCheckbox';
import { FieldLabel } from '../../UI/Field/Field';
import BaseInput from '../../UI/BaseInput/BaseInput';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled(SectionSubTitle)`
  margin-bottom: 1rem;
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
  ${BaseInput} {
    border-color: ${({ theme }) => theme.color.lighter_blue};
  }

  ${FieldLabel} {
    color: ${({ theme }) => theme.color.lighter_blue};
  }
`;
