/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IRenewalContractRecord } from '../../../../../models/interfaces/RenewalFilters/RenewalContractRecord';

import { CustomComponent } from '../../../../../models/types/CustomComponent';
import MaterialTable from '../../../../../styledComponents/MaterialTable/MaterialTable';
import { renewalContractTableCellRenderer as cellRenderer } from './renewalsContractsTable.utils';

type RenewalsContractsTableProps = CustomComponent & {
  data: IRenewalContractRecord[];
  dataLoading?: boolean;
  onCellClick?: (
    row: IRenewalContractRecord,
    key?: keyof IRenewalContractRecord,
  ) => void;
  onRowClick?: (row: IRenewalContractRecord) => void;
};

const RenewalsContractsTable = ({
  data,
  dataLoading,
  onCellClick,
  onRowClick,
}: RenewalsContractsTableProps) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      columns={[
        {
          title: t('grid-columns.organization'),
          field: 'organizationName',
          alignment: 'left',
          sortable: true,
          cellRenderer: cellRenderer('organizationName', { textBold: true }),
          onClick: onCellClick,
        },
        {
          title: t('grid-columns.renewal-status'),
          alignment: 'left',
          sortable: true,
          field: 'renewalStatus',
          cellRenderer: cellRenderer('renewalStatus'),
          onClick: onCellClick,
        },
        {
          title: t('grid-columns.contract-name'),
          alignment: 'left',
          field: 'contractName',
          cellRenderer: cellRenderer('contractName', { textBold: true }),
        },
        {
          title: t('grid-columns.expiration-date'),
          alignment: 'left',
          sortable: true,
          field: 'expirationDate',
          cellRenderer: cellRenderer('expirationDate'),
        },
        {
          title: t('grid-columns.role-am'),
          alignment: 'left',
          field: 'accountManagers',
          cellRenderer: cellRenderer('accountManagers'),
        },
        {
          title: t('grid-columns.role-bd'),
          alignment: 'left',
          field: 'bdRepresentatives',
          cellRenderer: cellRenderer('bdRepresentatives'),
        },
        {
          title: t('grid-columns.product'),
          alignment: 'left',
          field: 'productAbbreviations',
          cellRenderer: cellRenderer('productAbbreviations'),
        },
        {
          title: t('grid-columns.contract-type'),
          alignment: 'left',
          field: 'contractType',
          cellRenderer: cellRenderer('contractType'),
        },
      ]}
      data={data}
      dataLoading={dataLoading}
      onRowClick={onRowClick}
      isPaginationHidden
    />
  );
};

export default RenewalsContractsTable;
