import { IPersonMembershipItem } from '../../../../models/PersonMembershipItem';
import { ProductType } from '../../../../models/ProductType';
import AAMembershipActiveSummary from '../../AAMemberOverview/AAMembershipActiveSummary/AAMembershipActiveSummary';
import DOCMembershipActiveSummary from '../../DOCMemberOverview/DOCMembershipActiveSummary/DOCMembershipActiveSummary';
import KEMembershipActiveSummary from '../../KEMemberOverview/KEMembershipActiveSummary/KEMembershipActiveSummary';
import SRMembershipActiveSummary from '../../SRMemberOverview/SRMembershipActiveSummary/SRMembershipActiveSummary';

export interface IMemberOverviewActiveMemberSummaryProps {
  data: IPersonMembershipItem;
  productType?: ProductType;
}

export const MemberOverviewActiveMemberSummaryByProductType: Partial<
  Record<
    ProductType,
    (props: IMemberOverviewActiveMemberSummaryProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AAMembershipActiveSummary,
  [ProductType.DutyOfCare]: DOCMembershipActiveSummary,
  [ProductType.KidnapAndExtortion]: KEMembershipActiveSummary,
  [ProductType.SecurityResponse]: SRMembershipActiveSummary,
  [ProductType.TravelLicense]: null,
};
