import { AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga/macro';

import { SystemGroupPermissions } from '../../../models/enums/UserPermissions';
import {
  Permissions,
  SystemGroupPermissionsType,
} from '../../../models/types/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';
import { updateProcessState } from '../../redux-slices/processes';
import {
  USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING,
  USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING,
  USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING,
  USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING,
  USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING,
  USER_PERMISSIONS_REPORTS_LOAD_PROCESSING,
  USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING,
  USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  getAirAmbulanceUserPermissions,
  getDutyOfCareUserPermissions,
  getKidnapAndExtortionUserPermissions,
  getPaymentUserPermissions,
  getPeopleManagementUserPermissions,
  getReportsUserPermissions,
  getSecurityResponseUserPermissions,
  getTravelLicenseUserPermissions,
} from './api';

export function prepareUserPermissionsFromResponse(
  response: Partial<
    Record<SystemGroupPermissionsType, AxiosResponse<Permissions[]>>
  >,
): string[] {
  let globalPermissions: string[] = [];
  Object.keys(response).forEach((systemType) => {
    const systemPermissions = prepareUserPermissions(
      systemType as SystemGroupPermissions,
      response[systemType].data,
    );
    globalPermissions = globalPermissions.concat(systemPermissions);
  });

  return globalPermissions;
}

export function prepareUserPermissions(
  systemGroupPermissions: SystemGroupPermissions,
  permissions: Permissions[],
) {
  return permissions.map((permission) =>
    getGroupPermission(systemGroupPermissions, permission),
  );
}

export function* loadAirAmbulancePermissions(cancellationToken: AbortSignal) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getAirAmbulanceUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.AirAmbulance,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_AIR_AMBULANCE_LOAD_PROCESSING),
    );
  }
}

export function* loadKidnapAndExtortionPermissions(
  cancellationToken: AbortSignal,
) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getKidnapAndExtortionUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.KidnapAndExtortion,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_KIDNAP_AND_EXTORTION_LOAD_PROCESSING),
    );
  }
}

export function* loadSecurityResponsePermissions(
  cancellationToken: AbortSignal,
) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getSecurityResponseUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.SecurityResponse,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_SECURITY_RESPONSE_LOAD_PROCESSING),
    );
  }
}

export function* loadDutyOfCarePermissions(cancellationToken: AbortSignal) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getDutyOfCareUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.DutyOfCare,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_DUTY_OF_CARE_LOAD_PROCESSING),
    );
  }
}

export function* loadTravelLicensePermissions(cancellationToken: AbortSignal) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getTravelLicenseUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.TravelLicense,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_TRAVEL_LICENSE_LOAD_PROCESSING),
    );
  }
}

export function* loadReportsPermissions(cancellationToken: AbortSignal) {
  try {
    yield put(updateProcessState(USER_PERMISSIONS_REPORTS_LOAD_PROCESSING));
    const permissionsResponse = yield* call(
      getReportsUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.Reports,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(updateProcessState(USER_PERMISSIONS_REPORTS_LOAD_PROCESSING));
  }
}

export function* loadPeopleManagementPermissions(
  cancellationToken: AbortSignal,
) {
  try {
    yield put(
      updateProcessState(USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING),
    );
    const permissionsResponse = yield* call(
      getPeopleManagementUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.PeopleManagement,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(
      updateProcessState(USER_PERMISSIONS_PEOPLE_MANAGEMENT_LOAD_PROCESSING),
    );
  }
}

export function* loadPaymentPermissions(cancellationToken: AbortSignal) {
  try {
    yield put(updateProcessState(USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING));
    const permissionsResponse = yield* call(
      getPaymentUserPermissions,
      cancellationToken,
    );

    return prepareUserPermissions(
      SystemGroupPermissions.Payment,
      permissionsResponse.data,
    );
  } catch {
    return [];
  } finally {
    yield put(updateProcessState(USER_PERMISSIONS_PAYMENT_LOAD_PROCESSING));
  }
}
