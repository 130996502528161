import { useEffect, useMemo, useState, ChangeEvent } from 'react';

import { useComponentIsMounted } from '../../../hooks/useComponentIsMounted';

import { SelectOption } from './mainSelect.types';

type Props<T> = {
  onScrollClose: boolean;
  promiseOptions: () => Promise<any>;
  currentValue?: string | string[] | T;
  options?: SelectOption | SelectOption[] | any;
  isMulti?: boolean;
  valueIdSelector?: (value: any) => string;
};

type EventType = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

export const useMainSelect = <T>({
  onScrollClose,
  promiseOptions,
  currentValue,
  options,
  isMulti,
  valueIdSelector,
}: Props<T>) => {
  const [selectOptions, setOptions] = useState<any>(options);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useComponentIsMounted();

  useEffect(() => {
    if (!promiseOptions || !isMounted()) {
      return;
    }
    setIsLoading(true);
    promiseOptions()
      .then((responseOptions: SelectOption[]) => {
        if (isMounted()) {
          setOptions(responseOptions);
          setIsLoading(false);
        }
      })
      .finally(() => isMounted() && setIsLoading(false));
  }, [promiseOptions, setIsLoading, setOptions, isMounted]);

  useEffect(() => {
    if (promiseOptions) return;

    setOptions(options);
  }, [promiseOptions, options, setOptions]);

  const valueToRender = useMemo((): SelectOption | SelectOption[] => {
    if (!currentValue || !selectOptions) return [];

    if (isMulti) {
      const multiCurrentValue = Array.isArray(currentValue)
        ? currentValue
        : [currentValue];

      return selectOptions.filter(({ value }: SelectOption) =>
        multiCurrentValue.includes(value),
      );
    }

    const selectOption = selectOptions.find(({ value }: SelectOption) =>
      valueIdSelector
        ? valueIdSelector(value) === valueIdSelector(currentValue)
        : value === currentValue,
    );

    return selectOption || null;
  }, [currentValue, isMulti, selectOptions, valueIdSelector]);

  const closeOnScroll = (event: EventType) => {
    const isTargetDocument = event?.target.nodeName === '#document';
    // eslint-disable-next-line no-undefined
    const isTargetDefined = event?.target?.className === undefined;
    const isClassNameDefined =
      !!event?.target?.className?.includes('mainSelect');

    const canCloseFirstCondition =
      !isTargetDocument && !isTargetDefined && isClassNameDefined;

    return !canCloseFirstCondition;
  };

  return {
    handleOnscrollClose: onScrollClose && closeOnScroll,
    valueToRender,
    selectOptions,
    isLoading,
  };
};
