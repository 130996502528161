import { createSelector } from 'reselect';

import { ISubscriptionDates } from 'models/SubscriptionDates';
import { IAAOrganizationSubscriptionOverview } from 'models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';

import { RootState } from '../../index';
import { IAASubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AASubscriptionRenewalSuggestionModel';
import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { EditOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/EditOrganizationSubscriptionModel';
import { IAASubscriptionsState } from '.';

export const subscriptionsSelector = (state: RootState) =>
  state.aaSubscriptions;

export const aaSubscriptionDatesSelector = createSelector(
  subscriptionsSelector,
  (subscriptionState: IAASubscriptionsState): ISubscriptionDates =>
    subscriptionState.subscriptionDates,
);

export const aaSubscriptionOverviewSelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: IAASubscriptionsState,
  ): IAAOrganizationSubscriptionOverview =>
    subscriptionState.currentSubscriptionOverview,
);

export const aaSubscriptionRenewalSuggestionSelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: IAASubscriptionsState,
  ): IAASubscriptionRenewalSuggestionModel =>
    subscriptionState.subscriptionRenewalSuggestion,
);

export const aaSubscriptionRenewalSuggestionPlansSelector = createSelector(
  aaSubscriptionRenewalSuggestionSelector,
  (
    subscriptionRenewalSuggestion: IAASubscriptionRenewalSuggestionModel,
  ): IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[] =>
    subscriptionRenewalSuggestion && subscriptionRenewalSuggestion.plans,
);

export const aaSubscriptionRenewalAnalyseSelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: IAASubscriptionsState,
  ): IRenewOrganizationSubscriptionAnalyseModel =>
    subscriptionState.subscriptionRenewalAnalyse,
);

export const editingAASubscriptionDataSelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: IAASubscriptionsState,
  ): EditOrganizationSubscriptionModel =>
    subscriptionState.currentSubscriptionEditing,
);

export const editingAASubscriptionPricingDataSelector = createSelector(
  subscriptionsSelector,
  (
    subscriptionState: IAASubscriptionsState,
  ): Record<string, { capacity: number; price: number }> => {
    const result = {};
    subscriptionState?.currentSubscriptionEditing?.pricing?.membershipPlans?.forEach(
      (plan) =>
        (result[plan.membershipPlanId] = {
          capacity: plan.capacity,
          price: plan.price,
        }),
    );
    return result;
  },
);
