import { useAlert } from 'react-alert';
import { t } from 'i18next';

import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import { updateModalState } from '../../../../store/redux-slices/modals';
import { useActions } from '../../../../hooks/useActions';
import { DELETE_PROMO_CODE_MODAL_NAME } from './deletePromoCodeModal.consts';
import { deletePromoCode } from '../../../../store/saga-slices/promoCodes';
import { useProcessing } from '../../../../hooks/useProcessing';
import { PROMO_CODE_DELETING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { useModalDetails } from '../../../../hooks/useModalDetails';

export const useDeletePromoCode = () => {
  const alert = useAlert();
  const UpdateModalState = useActions(updateModalState);
  const DeletePromoCode = useActions(deletePromoCode);
  const isRemoving = useProcessing(PROMO_CODE_DELETING_PROCESSING);
  const cancellationToken = useCancellationToken();
  const navigate = useNavigate();

  const { promoCodeId, promoCodeName } = useModalDetails(
    DELETE_PROMO_CODE_MODAL_NAME,
  );

  const onBackHandler = () => {
    UpdateModalState(DELETE_PROMO_CODE_MODAL_NAME);
  };

  const deletePromoCodeHandler = useCallback(() => {
    const payload = {
      cancellationToken,
      promoCodeId,
      success: () => {
        alert.success(`${t('alerts.promo-code-delete-success')}`);
        UpdateModalState(DELETE_PROMO_CODE_MODAL_NAME);
        navigate(RoutePath.PromoCodes);
      },
      error: (err: any) => {
        alert.error(`${t('alerts.promo-code-delete-fail')}`);
      },
    };

    DeletePromoCode(payload);
  }, [
    DeletePromoCode,
    UpdateModalState,
    alert,
    cancellationToken,
    navigate,
    promoCodeId,
  ]);

  return {
    onBackHandler,
    isRemoving,
    deletePromoCodeHandler,
    promoCodeName,
  };
};
