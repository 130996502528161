import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import Spinner from '../../../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  PageRootColumns,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import { FormSubmitButton } from '../../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';
import { useExtendDOCSubscriptionForm } from './extendDOCSubscriptionForm.hooks';
import { validateExtendDOCSubscriptionForm } from './extendDOCSubscriptionForm.validation';
import ExtendDOCSubscriptionExtensionsForm from '../ExtendDOCSubscriptionExtensionsForm/ExtendDOCSubscriptionExtensionsForm';
import ExtendDOCSubscriptionSummaryForm from '../ExtendDOCSubscriptionSummary/ExtendDOCSubscriptionSummary';
import ExtendDOCSubscriptionSummaryLoader from '../ExtendDoCSubscriptionSummaryLoader/ExtendDoCSubscriptionSummaryLoader';

const ExtendDOCSubscriptionForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    validateErrors,
    subscriptionEditing,
    dataLoading,
    productType,
    subscriptionData,
    initData,
  } = useExtendDOCSubscriptionForm();

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      initialValues={initData}
      validate={(data) =>
        validateExtendDOCSubscriptionForm(data, validateErrors(data))
      }
    >
      {({ values, ...renderProps }) => (
        <FormErrorActivator>
          <Spinner isVisible={dataLoading} size="40%">
            <form onSubmit={renderProps.handleSubmit}>
              <PageRootColumns>
                <LeftContainer>
                  <ExtendDOCSubscriptionExtensionsForm
                    disabled={subscriptionEditing}
                    data={subscriptionData}
                    productType={productType}
                  />
                </LeftContainer>
                <RightContainer>
                  <ExtendDOCSubscriptionSummaryLoader values={values} />
                  <ExtendDOCSubscriptionSummaryForm
                    title={t('headers.summary')}
                    disabled={subscriptionEditing}
                    isPriceOverridden={values.overwriteAmount}
                  />
                  <FormSubmitButton
                    isLoading={subscriptionEditing}
                    type="submit"
                  >
                    {t('buttons.save-subscription')}
                  </FormSubmitButton>
                </RightContainer>
              </PageRootColumns>
            </form>
          </Spinner>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default ExtendDOCSubscriptionForm;
