import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import { IRequestCreditCardInfo } from '../../../models/interfaces/RequestCreditCardInfo';
import { ISetMembershipPayedResult } from '../../../models/interfaces/SetMembershipPayedResult';
import { IPaymentInfo } from '../../../models/PaymentInfo';
import { IWarningRatification } from '../../../models/interfaces/WarningRatification';
import { ICreditCardsResponse } from './model';
import { IPaymentIntentModel } from '../../../models/IPaymentIntentModel';
import { ICardSetupIntentModel } from '../../../models/ICardSetupIntentModel';

export const capturePaymentRequest = (
  paymentId: string,
  creditCardInfo: IRequestCreditCardInfo,
): Promise<AxiosResponse<void>> =>
  axios.post(`api/payments/${paymentId}/capture`, creditCardInfo || {}, {
    baseURL: config.paymentApi.baseUrl,
  });

export const getPaymentIntent = (
  paymentId: string,
): Promise<AxiosResponse<IPaymentIntentModel>> =>
  axios.get(`api/payments/${paymentId}`, {
    baseURL: config.paymentApi.baseUrl,
  });

export const beginPaymentRequest = (
  membershipId: string,
  ratifications?: IWarningRatification[],
): Promise<AxiosResponse<IPaymentInfo>> =>
  axios.post(
    `/api/memberships/${membershipId}/payment`,
    { ratifications },
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );
export const setAlreadyPayed = (
  membershipId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ISetMembershipPayedResult>> =>
  axios.post(
    `/api/memberships/${membershipId}/statuspayed`,
    {},
    {
      baseURL: config.airAmbulanceApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getCreditCards = (
  personId: string,
): Promise<AxiosResponse<ICreditCardsResponse>> =>
  axios.get(`api/people/${personId}/creditcards`);

export const addNewCreditCard = (
  personId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ICardSetupIntentModel>> =>
  axios.post(`api/people/${personId}/creditcards`, null, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });

export const replaceCreditCard = (
  personId: string,
  creditCardId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<ICardSetupIntentModel>> =>
  axios.put(`api/people/${personId}/creditcards/${creditCardId}`, null, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });

export const deleteCreditCardRequest = (
  personId: string,
  creditCardId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`api/people/${personId}/creditcards/${creditCardId}`, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });
