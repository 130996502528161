import { useTranslation } from 'react-i18next';

import { MembershipItemBaseProps } from '../../MembershipItemForm/membershipItemForm.models';

import { checkFamilyMembersFormVisible } from './airAmbulanceMembership.utils';
import MembershipDetailsForm from './AAMembershipDetailsForm/AAMembershipDetailsForm';
import MembershipFamilyMembersForm from '../MembershipCommon/MembershipFamilyMembersForm/MembershipFamilyMembersForm';
import MembershipOptionsForm from './AAMembershipOptionsForm/AAMembershipOptionsForm';
import MembershipTypeForm from './AAMembershipTypeForm/AAMembershipTypeForm';
import { useAirAmbulanceMembership } from './airAmbulanceMembership.hooks';

type AirAmbulanceMembershipProps = MembershipItemBaseProps;

const AirAmbulanceMembership = ({
  isProcessing,
  values,
  disabled,
  isRenewalMode,
}: AirAmbulanceMembershipProps) => {
  const { t } = useTranslation();

  const { selectedPlan } = useAirAmbulanceMembership();
  const isDisabled = disabled || isProcessing;
  return (
    <div>
      <MembershipTypeForm disabled={isDisabled} />
      <MembershipDetailsForm disabled={isDisabled} />
      <MembershipOptionsForm
        title={t('headers.options')}
        disabled={isDisabled}
      />
      {checkFamilyMembersFormVisible(values) && (
        <MembershipFamilyMembersForm
          isRenewalMode={isRenewalMode}
          title={t('headers.family-members')}
          disabled={isDisabled}
          maxMembersPerPlan={selectedPlan.maxMembersPerPlan}
          membershipPlanType={selectedPlan.membershipPlanType}
        />
      )}
    </div>
  );
};

export default AirAmbulanceMembership;
