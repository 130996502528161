import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import ProductSubscriptionsNotFound from './ProductSubscriptionsNotFound/ProductSubscriptionsNotFound';
import { useProductSubscriptionsTabPage } from './productSubscriptionsTabPage.hooks';
import { StyledOverviewTabPage } from './productSubscriptionsTabPage.styles';
import GroupedSubscriptionsList from '../../../../styledComponents/SubscriptionsList/GroupedSubscriptionsList';
import ProductSubscriptionsTabPageHeader from './ProductSubscriptionsTabPageHeader/ProductSubscriptionsTabPageHeader';

const ProductSubscriptionsTabPage = () => {
  const { t } = useTranslation();

  const {
    subscriptionsList,
    subscriptionIsLoading,
    id,
    handleAddSubscriptionNavigate,
    handleItemClickNavigate,
    subscriptionsAvailable,
  } = useProductSubscriptionsTabPage();

  if (!subscriptionsAvailable) {
    return (
      <Spinner isVisible={subscriptionIsLoading} size="10rem">
        <ProductSubscriptionsNotFound
          id={id}
          title={t('phrase.no-active-subscriptions')}
        />
      </Spinner>
    );
  }

  return (
    <div>
      <Spinner isVisible={subscriptionIsLoading} size="30%">
        <StyledOverviewTabPage>
          <ProductSubscriptionsTabPageHeader
            onAddSubscription={handleAddSubscriptionNavigate}
          />
          <GroupedSubscriptionsList
            subscriptions={subscriptionsList}
            onItemClick={handleItemClickNavigate}
          />
        </StyledOverviewTabPage>
      </Spinner>
    </div>
  );
};

export default ProductSubscriptionsTabPage;
