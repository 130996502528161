import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { IOrganizationContractModel } from '../../../models/OrganizationContractModel';

import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import {
  Wrapper,
  ContractSectionTitle,
  StyledFormMessage,
} from './selectContractForm.styles';
import { CustomForm } from '../../../models/types/CustomForm';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import {
  FormSelectContractName,
  SelectContractFormFields,
} from './selectContractForm.models';
import { getFormGroupId } from '../../../utils/getFormGroupPath';
import { getContractsOptions } from './selectContractForm.utils';
import MainTooltip from '../../UI/MainTooltip/MainTooltip';
import { CanCreateOrganizationContractPermissions } from '../../../config/accessPolicies/accessPolicies';

type SelectContractProps = CustomForm & {
  contracts: IOrganizationContractModel[];
  onAddNewContract: () => void;
  onContractChanged?: (value: string) => void;
  message?: string;
  isClearable?: boolean;
  setDefaultValue?: boolean;
  canNotChangeTooltip?: string;
  canChange?: boolean;
};

export const SelectContractForm = ({
  contracts,
  disabled,
  onAddNewContract,
  onContractChanged,
  message,
  isClearable,
  setDefaultValue = true,
  canNotChangeTooltip,
  canChange = true,
}: SelectContractProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContractSectionTitle>
        {t('headers.select-contract')}
      </ContractSectionTitle>
      <MainTooltip isShowing={!canChange} title={canNotChangeTooltip}>
        <FormColumn>
          <FormRow>
            <Field
              isClearable={isClearable}
              addNewButtonLabel={t('buttons.add-new-contract')}
              component={FormSelect}
              onItemChanged={onContractChanged}
              disabled={disabled || !canChange}
              inputId={getFormGroupId(
                FormSelectContractName,
                SelectContractFormFields.ContractId,
              )}
              name={SelectContractFormFields.ContractId}
              onAddButtonClick={onAddNewContract}
              options={getContractsOptions(contracts)}
              setDefaultValue={setDefaultValue}
              addActionPermissions={CanCreateOrganizationContractPermissions}
            />
          </FormRow>
        </FormColumn>
      </MainTooltip>
      {message && <StyledFormMessage message={message} status="warning" />}
    </Wrapper>
  );
};
