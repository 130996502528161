import styled from '@emotion/styled/macro';

import { StandardText } from '../../../../../styledComponents/Typography/Typography';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1.375rem;
`;

export const NotesText = styled(StandardText)`
  word-break: break-word;
`;
