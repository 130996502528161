import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IDOCOrganizationSubscriptionPackageOptionsModel } from '../../../../../models/interfaces/Subscription/DutyOfCare/OrganizationSubscriptionPackageOptionsModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { loadDOCSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/dutyOfCare';
import { ILoadDOCSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/dutyOfCare/models';

type DOCRenewSubscriptionSummaryLoaderProps = CustomComponent & {
  packageOptions: IDOCOrganizationSubscriptionPackageOptionsModel;
};

export const DOCRenewSubscriptionSummaryLoader = ({
  packageOptions,
}: DOCRenewSubscriptionSummaryLoaderProps) => {
  const { organizationId } = useParams();

  const LoadSubscriptionSummary = useActions(loadDOCSubscriptionSummary);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!packageOptions) {
      return;
    }

    const payload: ILoadDOCSubscriptionSummary = {
      data: packageOptions,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, debounce, organizationId, packageOptions]);

  return <></>;
};
