import { Navigate, useLocation } from 'react-router';

import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';

import { InteractionType } from '@azure/msal-browser';

import { usePermissions } from '../../hooks/usePermissions';

import { RoutePath } from '../../models/enums/RoutePath';

import { CustomComponent } from '../../models/types/CustomComponent';
import { CustomPermissions } from '../../models/types/CustomPermissions';

import { loginRequest } from '../../config/authConfig';

type AuthRouteProps = CustomComponent & CustomPermissions;

const AuthRoute = ({ children, permissions }: AuthRouteProps) => {
  const { hasPermissions } = usePermissions(permissions);
  const location = useLocation();
  const isAuth = useIsAuthenticated();

  if (isAuth && !hasPermissions) {
    return (
      <Navigate
        to={RoutePath.Home}
        replace
        state={{ path: location.pathname }}
      />
    );
  }

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Silent}
        loadingComponent={() => <div />}
        authenticationRequest={loginRequest}
      >
        {children}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Navigate
          to={RoutePath.Home}
          replace
          state={{ path: location.pathname }}
        />
      </UnauthenticatedTemplate>
    </>
  );
};

export default AuthRoute;
