import { createSelector } from 'reselect';
import { t } from 'i18next';

import { RootState } from '../index';
import { IRolesState } from '.';
import { SelectOption } from '../../../models/SelectOption';
import { PointOfContactRoles } from '../../../models/enums/PointOfContactRoles';
import { GlobalGuardianPointOfContactRoles } from '../../../models/enums/GlobalGuardianPointOfContactRoles';

export const rolesSelector = (state: RootState) => state.roles;

export const rolesListSelector = createSelector(
  rolesSelector,
  (items: IRolesState): PointOfContactRoles[] => items.poc,
);

export const ggRolesListSelector = createSelector(
  rolesSelector,
  (items: IRolesState): GlobalGuardianPointOfContactRoles[] => items.ggpoc,
);

export const rolesOptionsSelector = createSelector(
  rolesListSelector,
  (roles: PointOfContactRoles[]): SelectOption[] =>
    roles.map((x) => ({
      label: t(`enums.point-of-contact-roles.${PointOfContactRoles[x]}`),
      value: x,
    })),
);

export const ggRolesOptionsSelector = createSelector(
  ggRolesListSelector,
  (roles: GlobalGuardianPointOfContactRoles[]): SelectOption[] =>
    roles.map((x) => ({
      label: t(
        `enums.gg-point-of-contact-roles.${GlobalGuardianPointOfContactRoles[x]}`,
      ),
      value: x,
    })),
);
