import styled from '@emotion/styled/macro';

export const HeaderTitle = styled.div`
  text-align: center;
  text-decoration: none;
  margin: 0;
  font: 700 2.5rem/2rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const HeaderDescription = styled.div`
  font: normal 500 1.25rem/1.25rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
`;
