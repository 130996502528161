import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { ICreateOrganizationSubscriptionsModel } from '../../../../models/interfaces/Subscription/CreateOrganizationSubscriptionsModel';
import { IGetSubscriptionsSummaryModel } from '../../../../models/interfaces/Subscription/GetSubscriptionsSummaryModel';
import { IGetSubscriptionsResponse } from './models';

export const createSubscriptions = (
  organizationId: string,
  data: ICreateOrganizationSubscriptionsModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscription[]>> =>
  axios.post(`api/organizations/${organizationId}/subscriptions`, data, {
    baseURL: config.organizationSagaApi.baseUrl,
    signal: cancellationToken,
  });

export const getSubscriptions = (
  organizationId: string,
  contractId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IGetSubscriptionsResponse>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'contractId',
      value: contractId,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions${params}`,
    { signal: cancellationToken },
  );
};

export const getSubscriptionsSummary = (
  organizationId: string,
  data: IGetSubscriptionsSummaryModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ISubscriptionSummaryModel[]>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/summary`,
    data,
    {
      signal: cancellationToken,
      baseURL: config.organizationSagaApi.baseUrl,
    },
  );
