import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Property = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.color.core_black};
`;
