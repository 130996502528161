import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MultiInputType } from '../../../../../../../../../models/enums/MultiInputType';
import { CustomForm } from '../../../../../../../../../models/types/CustomForm';
import { FormSelect } from '../../../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { getFormGroupPath } from '../../../../../../../../../utils/getFormGroupPath';
import { FilterDefinition } from '../../../../../renewalFilters.models';
import {
  FilterInputContainer,
  FilterRow,
} from '../../../../filtersForm.styles';

import { DateFilterFields } from '../../dateFilter.models';
import { singleDateFieldsOptions } from './singleDateFilter.utils';

type SingleDateFilterProps = CustomForm & {
  filterDefinition: FilterDefinition<void>;
};

const SingleDateFilter = ({
  name,
  filterDefinition,
}: SingleDateFilterProps) => {
  const { t } = useTranslation();
  return (
    <FilterRow>
      <FilterInputContainer>
        <Field
          component={FormSelect}
          isMulti
          multiInputType={MultiInputType.InputSelect}
          options={singleDateFieldsOptions}
          label={t(`filters.${filterDefinition.field}`)}
          name={getFormGroupPath(name, DateFilterFields.Dates)}
        />
      </FilterInputContainer>
    </FilterRow>
  );
};

export default SingleDateFilter;
