import { useCallback, useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { useRouteParams } from '../../../../../../../hooks/useRouteParams';

import { formatDate, parseDate } from '../../../../../../../utils/dateHelpers';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { DOCMembershipOrganizationDetailsFormFields } from './docMembershipDetailsForm.models';
import { searchDOCOrganization } from '../../../../../../../store/saga-slices/optionsSearch/dutyOfCare/api';
import { getDatesForNewDOCMemberships } from '../../../../../../../store/saga-slices/membership/dutyOfCare/api';
import { IDOCOrganizationSearch } from '../../../../../../../models/interfaces/Organization/DutyOfCare/DOCOrganizationSearch';
import { isPropertyEmpty } from '../../../../../../../utils/propertyHelpers';

export function useDOCMembershipDetails() {
  const cancellationToken = useCancellationToken();

  const {
    organizationId: initOrganizationId,
    subscriptionId: initSubscriptionId,
  } = useRouteParams();

  const {
    input: {
      value: selectedOrganization,
      onChange: selectedOrganizationChange,
    },
  }: FieldRenderProps<IDOCOrganizationSearch> = useField(
    DOCMembershipOrganizationDetailsFormFields.Organization,
  );

  const {
    input: { onChange: packageChange },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.PackageType,
  );

  const {
    input: { value: subscriptionId, onChange: subscriptionTypeChange },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.SubscriptionType,
  );

  const {
    input: { onChange: expirationDateChange },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.ExpirationDate,
  );

  const {
    input: { onChange: effectiveDateChange },
  }: FieldRenderProps<string> = useField(
    DOCMembershipOrganizationDetailsFormFields.EffectiveDate,
  );
  const loadOrganizationById = useCallback(
    (organizationId: string) => {
      searchDOCOrganization(null, organizationId, cancellationToken).then(
        ({ data: { organizations } }) => {
          selectedOrganizationChange(
            organizations.length > 0 ? organizations[0] : null,
          );
        },
      );
    },
    [cancellationToken, selectedOrganizationChange],
  );

  const clearFields = useCallback(() => {
    selectedOrganizationChange(null);
    packageChange(null);
    subscriptionTypeChange(null);
    expirationDateChange(null);
    effectiveDateChange(null);
  }, [
    expirationDateChange,
    effectiveDateChange,
    selectedOrganizationChange,
    packageChange,
    subscriptionTypeChange,
  ]);

  useEffect(() => {
    if (initOrganizationId) {
      loadOrganizationById(initOrganizationId);
    }
    return () => clearFields();
  }, [initOrganizationId, loadOrganizationById, clearFields]);

  const organizationsPromiseOptions = useCallback(
    (inputValue: string) =>
      searchDOCOrganization(inputValue, null, cancellationToken).then((x) =>
        x.data.organizations.map((organization) => ({
          label: organization.name,
          value: organization,
        })),
      ),
    [cancellationToken],
  );

  const loadDates = useCallback(() => {
    getDatesForNewDOCMemberships(subscriptionId, cancellationToken).then(
      (response) => {
        effectiveDateChange(parseDate(response.data.effectiveDate));
        expirationDateChange(parseDate(response.data.expirationDate));
      },
    );
  }, [
    cancellationToken,
    effectiveDateChange,
    expirationDateChange,
    subscriptionId,
  ]);

  useEffect(() => {
    effectiveDateChange(null);
    expirationDateChange(null);

    if (subscriptionId) {
      loadDates();
    }
  }, [effectiveDateChange, expirationDateChange, loadDates, subscriptionId]);

  useEffect(() => {
    if (
      !selectedOrganization.organizationId ||
      !subscriptionId ||
      selectedOrganization.subscriptions.findIndex(
        ({ id }) => id === subscriptionId,
      ) === -1
    ) {
      subscriptionTypeChange(null);
      packageChange(null);
    }
  }, [
    selectedOrganization,
    packageChange,
    subscriptionId,
    subscriptionTypeChange,
  ]);

  const subscriptionsOptions = useMemo(
    () =>
      selectedOrganization
        ? selectedOrganization.subscriptions.map((subscription) => ({
            label: `${formatDate(subscription.effectiveDateFrom)}-${formatDate(
              subscription.effectiveDateTo,
            )}`,
            value: subscription.id,
          }))
        : [],
    [selectedOrganization],
  );

  useEffect(() => {
    if (initSubscriptionId && subscriptionsOptions) {
      subscriptionTypeChange(initSubscriptionId);
    }
  }, [subscriptionsOptions, initSubscriptionId, subscriptionTypeChange]);

  const currentSubscriptionId = selectedOrganization?.subscriptions?.findIndex(
    (subscription) => subscription.id === subscriptionId,
  );

  const currentPackage =
    !isPropertyEmpty(currentSubscriptionId) && currentSubscriptionId !== -1
      ? selectedOrganization?.subscriptions[currentSubscriptionId].packageType
      : null;

  const availableSeats =
    !isPropertyEmpty(currentSubscriptionId) && currentSubscriptionId !== -1
      ? selectedOrganization?.subscriptions[currentSubscriptionId]
          .availableSeats
      : null;

  return {
    organizationsPromiseOptions,
    subscriptionsOptions,
    currentPackage,
    availableSeats,
  };
}
