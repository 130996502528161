import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import { FormSectionTitle, SectionWrapper } from '../../Form/Form';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import { FormTextarea } from '../../UI/Form/FormTextarea/FormTextarea';
import { MaxCountOfChars } from './notesForm.const';
import { NotesFormFields, NotesFormName } from './notesForm.models';

interface INotesFormProps extends CustomForm {
  titleHidden?: boolean;
  label?: string;
  notesFieldName?: string;
  inputId?: string;
}

const NotesForm = ({
  disabled,
  inputId = NotesFormName,
  notesFieldName = NotesFormFields.Notes,
  name,
  titleHidden,
  label,
}: INotesFormProps) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper>
      {!titleHidden && (
        <FormSectionTitle>{t('headers.notes')}</FormSectionTitle>
      )}
      <FormColumn>
        <FormRow>
          <Field
            component={FormTextarea}
            label={label || t('fields.noteOptional.label')}
            disabled={disabled}
            maxLength={MaxCountOfChars}
            {...getFieldPropertiesForGroup({
              inputId,
              name: notesFieldName,
              groupName: name,
            })}
          />
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default NotesForm;
