import styled from '@emotion/styled/macro';

import {
  ProductSubscription,
  ProductSubscriptionBackgroundColors,
  ProductSubscriptionSubColors,
} from './productsSubscriptionInfoRenderer.models';
import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';

export const SubscriptionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubscriptionsShortcuts = styled.div`
  display: flex;
`;

type SubscriptionsShortcutProps = {
  productType: ProductTypeShortcut;
};

export const SubscriptionsShortcut = styled.div<SubscriptionsShortcutProps>`
  position: relative;
  padding: 0.2rem;
  margin-left: -0.25rem;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 0.4rem;
  color: ${({ productType }) => ProductSubscriptionSubColors[productType]};
  background-color: ${({ productType }) =>
    ProductSubscriptionBackgroundColors[productType]};
  font: normal 700 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};

  &:first-of-type {
    margin-left: 0;
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: 83%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 0.687rem;
  height: 0.687rem;
  border-radius: 1.375rem;
  border: 0.125rem solid white;
  background-color: ${ProductSubscription.circle};
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`;
