import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { ITLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { extendTLSubscriptionPricingItemFormSchema } from '../ExtendTLSubscriptionPackageFormTable/extendTLSubscriptionPackageFormTable.validation';
import { ExtendTLSubscriptionExtensionsFormFields } from './extendTLSubscriptionExtensionsForm.models';

export const extendTLSubscriptionExtensionsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [ExtendTLSubscriptionExtensionsFormFields.ChangeSubscriptionPricingTierModel]:
      {
        type: 'array',
        items: extendTLSubscriptionPricingItemFormSchema,
      },
  },
  required: [],
};

export const validateExtendTLSubscriptionExtensionsForm = (
  data: ITLOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(
    data,
    additionalErrors,
    extendTLSubscriptionExtensionsFormSchema,
  );
