import FormErrorProvider from '../../../../styledComponents/FormErrorProvider/FormErrorProvider';
import { Modal } from '../../../../styledComponents/Modal/Modal';
import { ADD_CREDIT_CARD_MODAL_NAME } from './addCreditCardModal.const';
import AddCreditCardModalForm from './AddCreditCardModalForm/AddCreditCardModalForm';

interface IAddCreditCardModalProps {
  onCreditCardAdded?: () => void;
}

const AddCreditCardModal = ({
  onCreditCardAdded,
}: IAddCreditCardModalProps) => (
  <FormErrorProvider>
    <Modal name={ADD_CREDIT_CARD_MODAL_NAME}>
      <AddCreditCardModalForm onAddedCreditCard={onCreditCardAdded} />
    </Modal>
  </FormErrorProvider>
);

export default AddCreditCardModal;
