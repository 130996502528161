/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';
import { MouseEvent } from 'react';

import { ReactComponent as PenIconSvg } from '../../../../assets/icons/pen-icon-secondary.svg';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { TableCellAction } from '../../../MaterialTable/CellRenderers/cellRenderers.styles';
import {
  DateRenderer,
  OrganizationNameRenderer,
  ProductsRenderer,
} from '../../../MaterialTable/CellRenderers/CellRenderers';

import { CustomTablePermissions } from '../../../../models/types/CustomTablePermissions';
import { PageSection } from '../../../Page/Page';
import MaterialTable from '../../../MaterialTable/MaterialTable';
import { IGeneralPersonModel } from '../../../../models/interfaces/GeneralPersonModel';
import { ICellOptions } from '../../../MaterialTable/CellRenderers/cellRenderers.models';
import { StatusRenderer } from '../../../MaterialTable/CellRenderers/StatusRenderer/StatusRenderer';
import TableCell from '../../../MaterialTable/CellRenderers/TableCell/TableCell';

export interface IMembersGeneralListTableProps extends CustomTablePermissions {
  onRowEdit?: (memberId: string) => void;
  data?: IPageListModel<IGeneralPersonModel>;
  isLoading?: boolean;
  onRowClick?: (row: IGeneralPersonModel) => void;
  onCellClick?: (
    row: IGeneralPersonModel,
    key: keyof IGeneralPersonModel,
  ) => void;
}

const MembersGeneralListTable = ({
  onRowEdit,
  data,
  isLoading,
  onRowClick,
  canViewDetailsPermissions,
  canEditPermissions,
  onCellClick,
}: IMembersGeneralListTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (key: keyof IGeneralPersonModel, options?: ICellOptions) =>
    (rowData: IGeneralPersonModel) => {
      switch (key) {
        case 'dateOfBirth':
          return <DateRenderer date={rowData[key]} options={options} />;
        case 'productsAbbreviation':
          return (
            <ProductsRenderer
              data={rowData.productsAbbreviation}
              options={options}
            />
          );
        case 'status':
          return (
            <StatusRenderer
              status={rowData.status}
              enumName="general-person-status"
              options={options}
            />
          );
        case 'association':
          return (
            <OrganizationNameRenderer
              organizationName={rowData.association}
              organizationType={rowData.organizationType}
              options={options}
            />
          );
        case 'name':
        default:
          return (
            <TableCell bold={options && options.textBold}>
              {rowData.name}
            </TableCell>
          );
      }
    };

  const actionsRenderer = (rowData: IMemberListItem) => (
    <TableCell alignment="right" minWidth>
      <TableCellAction permissions={canEditPermissions}>
        <PenIconSvg
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
            onRowEdit && onRowEdit(rowData.personId);
          }}
        />
      </TableCellAction>
    </TableCell>
  );

  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.member-name'),
            field: 'name',
            sortable: true,
            cellRenderer: cellRenderer('name', {
              textBold: true,
            }),
          },
          {
            title: t('grid-columns.date-of-birth'),
            field: 'dateOfBirth',
            cellRenderer: cellRenderer('dateOfBirth', {
              alignment: 'center',
            }),
            alignment: 'center',
          },
          {
            title: t('grid-columns.email'),
            field: 'email',
          },
          {
            title: t('grid-columns.phone-number'),
            field: 'phoneNumber',
          },
          {
            title: t('grid-columns.relation'),
            field: 'association',
            cellRenderer: cellRenderer('association'),
            onClick: onCellClick,
          },
          {
            title: t('grid-columns.products'),
            field: 'productsAbbreviation',
            cellRenderer: cellRenderer('productsAbbreviation', {
              alignment: 'right',
            }),
            alignment: 'right',
          },
          {
            title: t('grid-columns.status'),
            field: 'status',
            cellRenderer: cellRenderer('status', {
              alignment: 'left',
            }),
            alignment: 'left',
          },
          {
            cellRenderer: actionsRenderer,
            alignment: 'center',
            permissions: canViewDetailsPermissions,
          },
        ]}
        data={(data && data.items) || []}
        dataLoading={isLoading}
        itemsCount={(data && data.totalCount) || 0}
        onRowClick={onRowClick}
        canClickRowPermissions={canViewDetailsPermissions}
      />
    </PageSection>
  );
};

export default MembersGeneralListTable;
