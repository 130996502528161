import styled from '@emotion/styled/macro';

import StatusMessageWithIcon from '../../../../styledComponents/StatusMessageWithIcon/StatusMessageWithIcon';

import { StandardText } from '../../../../styledComponents/Typography/Typography';

export const Description = styled(StandardText)`
  text-align: center;
`;

export const Name = styled(StandardText)`
  margin-top: 0.5rem;
  font-weight: bold;
`;

export const StyledStatusMessageWithIcon = styled(StatusMessageWithIcon)`
  margin-top: 1.5rem;
`;
