import { AnySchema } from 'ajv';

import { validateFormData } from '../../../../../../../utils/validations/validateFormData';
import { SelectFilterFields, SelectFilterModel } from './selectFilter.models';

export const selectFilterValidationSchema: AnySchema | any = {
  type: 'object',
  properties: {},
  required: [SelectFilterFields.Value],
};

export const validateSelectFilter = (data: SelectFilterModel) =>
  validateFormData(data, null, selectFilterValidationSchema);
