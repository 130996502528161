import { useTranslation } from 'react-i18next';

import { ReactComponent as NotFoundIcon } from '../../../assets/icons/not-found-icon.svg';
import { Header, Phrase, Wrapper } from './materialTableNotFound.styles';
import { CustomComponent } from '../../../models/types/CustomComponent';

const MaterialTableNotFound = ({ className }: CustomComponent) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <NotFoundIcon />
      <Header>{t('headers.no-results-header')}</Header>
      <Phrase>{t('phrase.no-results-phrase')}</Phrase>
    </Wrapper>
  );
};

export default MaterialTableNotFound;
