import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../../styledComponents/UI/Button/Button';
import FamilyMemberForm from '../../../../../../../styledComponents/Forms/FamilyMember/FamilyMember';
import { useMembershipFamilyMembersForm } from './membershipFamilyMembers.hooks';

import FormMessage from '../../../../../../../styledComponents/UI/Form/FormMessage/FormMessage';
import {
  FormSectionTitle,
  SectionWrapper,
} from '../../../../../../../styledComponents/Form/Form';
import { CustomForm } from '../../../../../../../models/types/CustomForm';
import { ButtonsContainer, StyledLine } from './membershipFamilyMembers.styles';
import { MembershipFamilyMembersFormFields } from './membershipFamilyMembers.models';
import { familyMembersAddressInformationsInitState } from '../../../../../../../styledComponents/Forms/FamilyMember/familyMember.consts';
import { IMembershipPlanTypeModel } from '../../../../../../../models/MembershipPlanTypeModel';

interface IMembershipFamilyMembersFormProps extends CustomForm {
  title?: string;
  membershipPlanType?: IMembershipPlanTypeModel;
  maxMembersPerPlan?: number;
  isRenewalMode?: boolean;
}

const MembershipFamilyMembersForm = ({
  className,
  disabled,
  title,
  maxMembersPerPlan,
  membershipPlanType,
  isRenewalMode,
}: IMembershipFamilyMembersFormProps) => {
  const { t } = useTranslation();
  const {
    getAvailablePlanSeats,
    primaryMember,
    ageRanges,
    prepareMemberTypeOptions,
  } = useMembershipFamilyMembersForm({
    maxMembersPerPlan,
    membershipPlanType,
    isRenewalMode,
  });

  return (
    <SectionWrapper className={className}>
      {title && <FormSectionTitle>{title}</FormSectionTitle>}
      <FieldArray name={MembershipFamilyMembersFormFields.SecondaryMembers}>
        {({ fields }) => (
          <div>
            {fields.map((fieldNameItem, index) => (
              <div key={fieldNameItem}>
                {index > 0 && <StyledLine />}
                <FamilyMemberForm
                  disabled={disabled}
                  name={fieldNameItem}
                  key={fieldNameItem}
                  onRemoveContact={() => fields.remove(index)}
                  // eslint-disable-next-line no-console
                  onEditMember={(id) => {}}
                  memberTypesAgeRanges={ageRanges}
                  residenceAddress={primaryMember.residenceAddress}
                  memberTypes={prepareMemberTypeOptions(fields, index)}
                />
              </div>
            ))}
            <ButtonsContainer>
              <Button
                disabled={fields.length === maxMembersPerPlan - 1 || disabled}
                onClick={() =>
                  fields.push(familyMembersAddressInformationsInitState)
                }
                styleType="link"
              >
                {t('buttons.add-family-member')}
              </Button>
              <FormMessage
                message={t('phrase.seats_remaining', {
                  value: getAvailablePlanSeats(fields.length),
                })}
                status={
                  getAvailablePlanSeats(fields.length) > 0
                    ? 'success'
                    : 'warning'
                }
              />
            </ButtonsContainer>
          </div>
        )}
      </FieldArray>
    </SectionWrapper>
  );
};

export default MembershipFamilyMembersForm;
