import { useMemo, useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';

import { useFormErrorsActions } from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../../../hooks/useActions';
import { useApiResponseFail } from '../../../../../hooks/useApiResponseFail';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../hooks/useRouteParams';
import {
  OrganizationMembersListPageParams,
  RoutePath,
} from '../../../../../models/enums/RoutePath';
import { IWarningRatification } from '../../../../../models/interfaces/WarningRatification';
import {
  MEMBERSHIP_PROMO_CODE_LOADING,
  MEMBERSHIP_CREATING_PROCESSING,
} from '../../../../../store/redux-slices/processes/constants';
import { useApiWarningValues } from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';
import { validateMembershipsForm } from './addMemberMembership.validation';
import {
  membershipSelector,
  primaryMemberSelector,
} from '../../../../../store/redux-slices/membership/common/selectors';
import { submitCreateMembership } from '../../../../../store/saga-slices/membership/common';
import { CreateMembershipPageModel } from '../../../../../models/types/CreateMembershipPageModel';
import { ISubmitCreateMembershipPayload } from '../../../../../store/saga-slices/membership/common/models';
import { AddMemberAvailableSearchParamsType } from '../../../../../models/types/RoutePath';
import { BasicMembershipFormFields } from '../MembershipSectionsForms/BasicMembershipForm/basicMembershipForm.models';
import { ProductType } from '../../../../../models/ProductType';

export const useAddMemberMembershipForm = () => {
  const { t } = useTranslation();
  const SubmitCreateMembership = useActions(submitCreateMembership);
  const navigate = useNavigateSearch();
  const membership = useSelector(membershipSelector);
  const member = useSelector(primaryMemberSelector);
  const dataLoading = useProcessing([MEMBERSHIP_PROMO_CODE_LOADING]);
  const memberCreating = useProcessing(MEMBERSHIP_CREATING_PROCESSING);
  const { organizationId, productType, membershipId } =
    useRouteParams<AddMemberAvailableSearchParamsType>();
  const alert = useAlert();

  const { validateErrors } = useFormErrorsActions();
  const { ratifications } = useApiWarningValues();
  const { handleResponse } = useApiResponseFail();

  const prepareRedirectPath = useCallback(
    (currentProductType: ProductType) =>
      organizationId
        ? generatePath(RoutePath.ClientOverviewMembersListPageFullPath, {
            [OrganizationMembersListPageParams.OrganizationId]: organizationId,
            [OrganizationMembersListPageParams.ProductType]:
              productType || currentProductType,
          })
        : RoutePath.MembersPage,
    [organizationId, productType],
  );

  const createMembership = useCallback(
    (
      values: CreateMembershipPageModel,
      newRatifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: CreateMembershipPageModel = {
        ...values,
        ratifications: newRatifications,
      };

      const payload: ISubmitCreateMembershipPayload = {
        data: extendedValues,
        success: () => {
          navigate(prepareRedirectPath(values.productType));
          alert.success(
            t('alerts.add-member-success', {
              personName: member.firstName,
            }),
          );
        },
        error: (err) => {
          handleResponse({ err, values, ratifications: newRatifications });
        },
      };

      SubmitCreateMembership(payload);
    },

    [
      SubmitCreateMembership,
      navigate,
      prepareRedirectPath,
      alert,
      t,
      member.firstName,
      handleResponse,
    ],
  );

  const handleSubmit = useCallback(
    (values: CreateMembershipPageModel) => {
      createMembership(values, ratifications);
    },
    [createMembership, ratifications],
  );

  const formInitState = useMemo(
    () => ({
      [BasicMembershipFormFields.ProductType]: productType,
      ...membership,
    }),
    [membership, productType],
  );

  const validateForm = useCallback(
    (data: CreateMembershipPageModel) =>
      validateMembershipsForm(data, validateErrors(data)),
    [validateErrors],
  );

  return {
    handleSubmit,
    dataLoading,
    memberCreating,
    formInitState,
    validateForm,
    createMembership,
    disabledProductType: !!productType,
    primaryMemberId: member?.personId,
    isRenewalMode: !!membershipId,
  };
};
