export const TableFormName = 'tl-subscription-pricing-table';

export enum TableFormColumns {
  seatsAmount = 'seatsAmount',
  price = 'price',
}

export type TableDataType = {
  name: string;
  index: number;
};
