import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { RoutePath } from '../../models/enums/RoutePath';
import { ReactComponent as Logo } from '../../assets/icons/main-logo-icon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout-icon.svg';
import CorporateClient from '../../assets/icons/organizations-page-icon.svg';
import DiscountCodes from '../../assets/icons/promo-codes-page-icon.svg';
import Enroll from '../../assets/icons/add-member-page-icon.svg';
import Reports from '../../assets/icons/reports-page-icon.svg';
import Members from '../../assets/icons/members-page-icon.svg';
import Renewals from '../../assets/icons/renewals-page-icon.svg';
import Button from '../UI/Button/Button';
import NavItem from './NavItem/NavItem';
import { useSidebar } from './sidebar.hooks';
import {
  Line,
  LogoWrapper,
  NavList,
  Wrapper,
  LogoutButtonWrapper,
  ItemsContainer,
  ListWrapper,
} from './sidebar.styles';
import {
  canAddAAMembershipPermissions,
  canViewMembersPagePermissions,
  canViewOrganizationsPermissions,
  canViewAAPromoCodesPermissions,
  canViewReportsPermissions,
  CanViewRenewalsPagePermissions,
} from '../../config/accessPolicies/accessPolicies';
import { Description } from './NavItem/navItem.styles';

const Sidebar = () => {
  const { t } = useTranslation();
  const { onLogoutHandler, isAuth } = useSidebar();

  return (
    <Wrapper>
      <ItemsContainer>
        <LogoWrapper>
          <NavLink to={RoutePath.Home}>
            <Logo />
          </NavLink>
          <Line />
        </LogoWrapper>
        <ListWrapper>
          <NavList>
            <NavItem
              description={t('headers.members')}
              permissions={canViewMembersPagePermissions}
              image={Members}
              to={`${RoutePath.MembersPage}`}
            />
            <NavItem
              description={t('headers.organizations')}
              permissions={canViewOrganizationsPermissions}
              image={CorporateClient}
              to={RoutePath.CorporateClientsPage}
              additionalRoutes={[RoutePath.ClientOverview]}
            />
            <NavItem
              description={t('headers.add-member')}
              permissions={canAddAAMembershipPermissions}
              image={Enroll}
              to={RoutePath.AddMemberWizardPrimaryMember}
            />
            <NavItem
              description={t('headers.promo-codes')}
              permissions={canViewAAPromoCodesPermissions}
              image={DiscountCodes}
              to={RoutePath.PromoCodes}
            />
            <NavItem
              description={t('headers.reports')}
              permissions={canViewReportsPermissions}
              image={Reports}
              to={`${RoutePath.Reports}`}
            />
            <NavItem
              description={t('headers.renewals')}
              image={Renewals}
              permissions={CanViewRenewalsPagePermissions}
              to={`${RoutePath.Renewals}`}
            />
          </NavList>
          {isAuth && (
            <LogoutButtonWrapper>
              <Button styleType="icon" onClick={onLogoutHandler}>
                <LogoutIcon />
                <Description>{t('buttons.logout')}</Description>
              </Button>
            </LogoutButtonWrapper>
          )}
        </ListWrapper>
      </ItemsContainer>
    </Wrapper>
  );
};

export default Sidebar;
