import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../../../../../models/types/CustomForm';
import FormDatePicker from '../../../../../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { getFormGroupPath } from '../../../../../../../../../utils/getFormGroupPath';
import {
  FilterInputContainer,
  FilterRow,
} from '../../../../filtersForm.styles';

import { DateFilterFields } from '../../dateFilter.models';

import { DATE_FORMAT, DATE_MASK, DATE_PARSE } from './monthsRangeFilter.utils';

const MonthsRangeFilter = ({ name }: CustomForm) => (
  <FilterRow>
    <FilterInputContainer small>
      <Field
        component={FormDatePicker}
        name={getFormGroupPath(name, DateFilterFields.From)}
        inputFormat={DATE_FORMAT}
        outputFormat={DATE_PARSE}
        mask={DATE_MASK}
        autoComplete="off"
        autoCorrect="off"
        views={['year', 'month']}
        formatMask={DATE_FORMAT}
      />
    </FilterInputContainer>
    <FilterInputContainer small>
      <Field
        component={FormDatePicker}
        name={getFormGroupPath(name, DateFilterFields.To)}
        inputFormat={DATE_FORMAT}
        outputFormat={DATE_PARSE}
        mask={DATE_MASK}
        autoComplete="off"
        autoCorrect="off"
        views={['year', 'month']}
        formatMask={DATE_FORMAT}
      />
    </FilterInputContainer>
  </FilterRow>
);

export default MonthsRangeFilter;
