import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import WarningsConfirmationModal from '../../../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';

import { withErrorProvider } from '../../../../../../../hoc/withErrorProvider';
import { withWarningsConfirmationProvider } from '../../../../../../../hoc/withWarningsConfirmationProvider';
import { SmallModal } from '../../../../../../../styledComponents/ModalCommon/ModalCommon';
import { useDOCSubscriptionEditDetailsModal } from './DOCSubscriptionEditOptionsModal.hooks';
import { EDITING_DOC_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './DOCSubscriptionEditOptionsModal.const';
import { StyledSubscriptionOptions } from './DOCSubscriptionEditOptionsModal.styles';

interface IDOCSubscriptionEditDetailsModalProps {
  onModalSuccess: () => void;
}

const DOCSubscriptionEditOptionsModal = ({
  onModalSuccess,
}: IDOCSubscriptionEditDetailsModalProps) => {
  const { t } = useTranslation();

  const {
    handleWarningsConfirm,
    handleSubmit,
    subscriptionEditing,
    initData,
    onCancelHandler,
  } = useDOCSubscriptionEditDetailsModal({ onModalSuccess });

  return (
    <>
      <WarningsConfirmationModal onWarningsConfirm={handleWarningsConfirm} />
      <SmallModal name={EDITING_DOC_SUBSCRIPTION_OPTIONS_MODAL_NAME}>
        <Form
          initialValues={initData}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
        >
          {({ ...renderProps }) => (
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={subscriptionEditing}
              onCancel={onCancelHandler}
              mode="small"
              title={t('headers.edit-options')}
            >
              <StyledSubscriptionOptions
                layout="column"
                disabled={subscriptionEditing}
              />
            </ModalFormPage>
          )}
        </Form>
      </SmallModal>
    </>
  );
};

export default withErrorProvider(
  withWarningsConfirmationProvider(DOCSubscriptionEditOptionsModal),
);
