import styled from '@emotion/styled/macro';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

export const TableTooltip = styled.p`
  font: normal 400 0.875rem/1rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledPaper = styled(Paper)`
  box-shadow: none;
  background-color: transparent;
`;

export const StyledTable = styled(Table)`
  min-width: 25rem !important;
  border-collapse: unset;

  .MTableHeader-header-6 {
    color: ${({ theme }) => theme.color.dark2};
  }
`;
