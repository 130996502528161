import { createSelector } from '@reduxjs/toolkit';

import { IPaymentState } from './index';
import { RootState } from '../index';
import { ICreditCard } from '../../../models/CreditCardModel';

export const paymentSelector = (state: RootState) => state.payments;

export const creditCardSelector = createSelector(
  paymentSelector,
  (creditCardsState: IPaymentState) => creditCardsState.creditCards,
);

export const creditCardsLoadedSelector = createSelector(
  paymentSelector,
  (creditCardsState: IPaymentState) => creditCardsState.creditCardsLoaded,
);

export const firstCreditCardSelector = createSelector(
  creditCardSelector,
  (creditCards: ICreditCard[]): ICreditCard =>
    creditCards && creditCards.length > 0 && creditCards[0],
);

export const creditCardExistsSelector = createSelector(
  creditCardSelector,
  (creditCards: ICreditCard[]): boolean =>
    creditCards && creditCards.length > 0,
);
