import { useCallback } from 'react';

import { useActions } from '../../../hooks/useActions';
import { useModalDetails } from '../../../hooks/useModalDetails';
import { useProcessing } from '../../../hooks/useProcessing';
import { PERSON_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { loadPerson } from '../../../store/saga-slices/people';
import { ILoadPersonPayload } from '../../../store/saga-slices/people/models';
import { EDIT_MEMBER_MODAL_NAME } from './editMemberModal.const';

export const useEditMemberModal = () => {
  const LoadPerson = useActions(loadPerson);

  const personId = useModalDetails(EDIT_MEMBER_MODAL_NAME);

  const personLoading = useProcessing(PERSON_LOADING_PROCESSING);

  const loadPersonData = useCallback(() => {
    if (!personId) {
      return;
    }

    const payload: ILoadPersonPayload = {
      personId,
    };

    LoadPerson(payload);
  }, [LoadPerson, personId]);

  return {
    loadPersonData,
    personLoading,
  };
};
