import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';

import RenewSubscriptionItemForm from '../../RenewSubscriptionItemForm/RenewSubscriptionItemForm';

import { RenewSubscriptionItemBaseProps } from '../../RenewSubscriptionItemForm/renewSubscriptionItemForm.models';
import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { TLRenewSubscriptionDetails } from './TLRenewSubscriptionDetails/TLRenewSubscriptionDetails';
import TLSubscriptionPricingForm from '../../../Forms/TravelLicense/TLSubscriptionPricingForm/TLSubscriptionPricingForm';
import { TLRenewSubscriptionPricingTiersFormFields } from './travelLicenseRenewSubscriptionForm.models';
import { Wrapper } from './travelLicenseRenewSubscriptionForm.styles';
import { getFormGroupPath } from '../../../../utils/getFormGroupPath';
import { TLSubscriptionOptions } from '../../../Forms/SubscriptionOptions/subscriptionOptions.utils';

const TravelLicenseRenewSubscriptionForm = ({
  name,
  disabled,
  className,
}: RenewSubscriptionItemBaseProps) => {
  const { t } = useTranslation();
  const productType = ProductType.TravelLicense;
  return (
    <RenewSubscriptionItemForm collapsible={false} productType={productType}>
      <Wrapper className={className}>
        <TLRenewSubscriptionDetails disabled={disabled} />
        <TLSubscriptionPricingForm
          disabled={disabled}
          title={t('headers.pricing-and-current-capacity')}
          name={getFormGroupPath(
            name,
            TLRenewSubscriptionPricingTiersFormFields.PricingTiers,
          )}
        />
        <SubscriptionOptions
          subscriptionOptions={TLSubscriptionOptions}
          layout="column"
          disabled={disabled}
          title={t('headers.options')}
        />
      </Wrapper>
    </RenewSubscriptionItemForm>
  );
};

export default TravelLicenseRenewSubscriptionForm;
