import {
  canCloneAAPromoCodePermissions,
  canDeleteAAPromoCodePermissions,
  canEnableAAPromoCodePermissions,
} from '../../../config/accessPolicies/accessPolicies';

export const canViewActionColumn: string[] = [
  ...canEnableAAPromoCodePermissions,
  ...canDeleteAAPromoCodePermissions,
  ...canCloneAAPromoCodePermissions,
];
