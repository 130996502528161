import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { IMemberListItem } from '../../../../../models/MemberListItem';
import { IPerson } from '../../../../../models/Person';
import { RoutePath } from '../../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { MEMBERS_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { ILoadSubscriptionsPayload } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { EDIT_MEMBER_MODAL_NAME } from '../../../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { textContainsWords } from '../../../../../utils/textHelpers';
import { clearSubscriptionsList } from '../../../../../store/redux-slices/subscriptions/common';
import { loadSubscriptions } from '../../../../../store/saga-slices/subscriptions/common';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { MemberOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { loadKEMembers } from '../../../../../store/saga-slices/membership/kidnapAndExtortion';
import {
  clearKEMembers,
  updateKEMembersList,
} from '../../../../../store/redux-slices/membership/kidnapAndExtortion';
import { pageListKEMembersSelector } from '../../../../../store/redux-slices/membership/kidnapAndExtortion/selectors';
import { keLoadStandardPricing } from '../../../../../store/saga-slices/pricing/kidnapAndExtortion';
import { clearKEMembershipPlansPrices } from '../../../../../store/redux-slices/pricing/kidnapAndExtortion';
import { RequiredQueryParams } from './keMembers.const';
import { ILoadKEMembersPayload } from '../../../../../store/saga-slices/membership/kidnapAndExtortion/models';
import { keMembershipPlansPricesSelector } from '../../../../../store/redux-slices/pricing/kidnapAndExtortion/selectors';

export const useKEMembers = () => {
  const LoadMembers = useActions(loadKEMembers);
  const ClearMembers = useActions(clearKEMembers);
  const location = useLocation();
  const navigate = useNavigateSearch();
  const pageListMembers = useSelector(pageListKEMembersSelector);
  const keMembershipPricing = useSelector(keMembershipPlansPricesSelector);

  const membersLoading = useProcessing(MEMBERS_LOADING_PROCESSING);
  const LoadStandardPricing = useActions(keLoadStandardPricing);
  const LoadSubscriptions = useActions(loadSubscriptions);
  const ClearSubscriptionsList = useActions(clearSubscriptionsList);
  const ClearStandardPricing = useActions(clearKEMembershipPlansPrices);

  const UpdateModalState = useActions(updateModalState);
  const UpdateMembersList = useActions(updateKEMembersList);

  const { id } = useParams();

  useEffect(() => {
    const payload: ILoadSubscriptionsPayload = {
      organizationId: id,
    };
    LoadSubscriptions(payload);
  }, [LoadSubscriptions, id]);

  useEffect(() => {
    LoadStandardPricing();
  }, [LoadStandardPricing]);

  useEffect(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadKEMembersPayload = {
      params,
      organizationId: id,
    };

    LoadMembers(payload);
  }, [LoadMembers, id, location.search]);

  useEffect(
    () => () => {
      ClearMembers();
      ClearStandardPricing();
      ClearSubscriptionsList();
    },
    [ClearMembers, ClearStandardPricing, ClearSubscriptionsList],
  );

  const onRowClickHandler = useCallback(
    (row: IMemberListItem) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, { params });
    },
    [navigate],
  );

  const rowEditHandler = useCallback(
    (personId: string) => {
      UpdateModalState([EDIT_MEMBER_MODAL_NAME, personId]);
    },
    [UpdateModalState],
  );

  const onMemberEditedHandler = useCallback(
    (person: IPerson) => {
      UpdateMembersList(person);
    },
    [UpdateMembersList],
  );

  return {
    pageListMembers,
    membersLoading,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    keMembershipPricing,
  };
};
