import { ReactComponent as FamilyIcon } from '../../../assets/icons/family-icon.svg';
import { ReactComponent as CorpIcon } from '../../../assets/icons/corporation-icon.svg';
import { ReactComponent as GroupIcon } from '../../../assets/icons/group-type-icon.svg';
import { ReactComponent as AgentIcon } from '../../../assets/icons/agent-type-icon.svg';
import { ReactComponent as RetailIcon } from '../../../assets/icons/retail-type-icon.svg';
import { OrganizationType } from '../../../models/enums/OrganizationType';

export const getOrganizationTypeIcon = (organizationType: OrganizationType) => {
  switch (organizationType) {
    case OrganizationType.Corporation:
      return <CorpIcon />;
    case OrganizationType.FamilyOffice:
      return <FamilyIcon />;
    case OrganizationType.Agent:
      return <AgentIcon />;
    case OrganizationType.Group:
      return <GroupIcon />;
    default:
      return <RetailIcon />;
  }
};
