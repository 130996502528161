import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import config from '../../config/config';

const stripePromise = loadStripe(config.paymentApi.stripePublishableKey);

export const withStripeElements =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <Elements stripe={stripePromise}>
        <Component {...(props as P)} />
      </Elements>
    );
