import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import NotesForm from '../../../../../../../styledComponents/Forms/NotesForm/NotesForm';
import ModalFormPage from '../../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { useAddContractNotes } from './addContractNotesModalForm.hooks';

export interface IAddContractModalFormProps {
  onNotesAdded?: () => void;
}

const AddContractModalForm = ({ onNotesAdded }: IAddContractModalFormProps) => {
  const { t } = useTranslation();
  const { closeModalHandler, handleSubmit, isProcessing, validateData } =
    useAddContractNotes({ onNotesAdded });
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={validateData}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          title={t('headers.add-notes')}
          onCancel={closeModalHandler}
          isProcessing={isProcessing}
        >
          <NotesForm titleHidden disabled={isProcessing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default AddContractModalForm;
