import { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import RadioGroupContent from '../../RadioGroupContent/RadioGroupContent';
import { RadioGroupContentProps } from '../../RadioGroupContent/radioGroupContent.models';

type IFormRadioGroupContent = FieldRenderProps<any> &
  RadioGroupContentProps & {
    onChange?: (id: string) => void;
  };

const FormRadioGroupContent = ({
  input,
  onChange,
  ...props
}: IFormRadioGroupContent) => {
  const onValueChange = useCallback(
    (id: string) => {
      input.onChange(id);
      onChange?.(id);
    },
    [input, onChange],
  );

  return (
    <RadioGroupContent
      {...props}
      onChange={onValueChange}
      checked={input.value}
    />
  );
};

export default FormRadioGroupContent;
