import { Route, Routes } from 'react-router';

import {
  canViewMembersPagePermissions,
  CanViewOrganizationContractsPermissions,
  canViewOrganizationDetailsPagePermissions,
  canViewAAPromoCodesPermissions,
  canViewSubscriptionsPermissions,
  canViewSubscriptionsDetailsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import AuthRoute from '../../styledComponents/AuthRoute/AuthRoute';
import ContractOverviewTabPage from './ClientOverviewTabPages/ContractOverviewTabPage/ContractOverviewTabPage';
import ContractsTabPage from './ClientOverviewTabPages/ContractsTabPage/ContractsTabPage';
import MembersTabPage from './ClientOverviewTabPages/MembersTabPage/MembersTabPage';
import OverviewTabPage from './ClientOverviewTabPages/OverviewTabPage/OverviewTabPage';
import ProductSubscriptionOverviewTabPage from './ClientOverviewTabPages/ProductSubscriptionOverviewTabPage/ProductSubscriptionOverviewTabPage';
import ProductSubscriptionsTabPage from './ClientOverviewTabPages/ProductSubscriptionsTabPage/ProductSubscriptionsTabPage';
import PromoCodesTabPage from './ClientOverviewTabPages/PromoCodesTabPage/PromoCodesTabPage';

const ClientOverviewRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.ClientOverviewGeneralPage}
      element={
        <AuthRoute permissions={canViewOrganizationDetailsPagePermissions}>
          <OverviewTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ClientOverviewProductSubscriptionListPage}
      element={
        <AuthRoute permissions={canViewSubscriptionsPermissions}>
          <ProductSubscriptionsTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.ClientOverviewProductSubscriptionListPage}/${RoutePath.ClientOverviewProductSubscriptionOverviewPage}`}
      element={
        <AuthRoute permissions={canViewSubscriptionsDetailsPermissions}>
          <ProductSubscriptionOverviewTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.ClientOverviewMembersPage}/*`}
      element={
        <AuthRoute permissions={canViewMembersPagePermissions}>
          <MembersTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ClientOverviewPromoCodesPage}
      element={
        <AuthRoute permissions={canViewAAPromoCodesPermissions}>
          <PromoCodesTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ClientOverviewContractsPage}
      element={
        <AuthRoute permissions={CanViewOrganizationContractsPermissions}>
          <ContractsTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={`${RoutePath.ClientOverviewContractsPage}/${RoutePath.ClientOverviewContractOverviewPage}`}
      element={
        <AuthRoute permissions={CanViewOrganizationContractsPermissions}>
          <ContractOverviewTabPage />
        </AuthRoute>
      }
    />
  </Routes>
);

export default ClientOverviewRoutes;
