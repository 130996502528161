import { useTranslation } from 'react-i18next';

import { Wrapper } from './overlapMessage.styles';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg';

export const OverlapMessage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WarningIcon />
      {t('phrase.selected-dates-overlap-with-multi-product-subscription')}
    </Wrapper>
  );
};
