import styled from '@emotion/styled/macro';

import { FormSubmitButton } from '../FormSubmitButton/FormSubmitButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledFormSubmitButton = styled(FormSubmitButton)`
  margin: 0;
`;
