import aaPricing from './airAmbulance';
import kePricing from './kidnapAndExtortion';
import srPricing from './securityResponse';
import docPricing from './dutyOfCare';
import tlPricing from './travelLicense';

export const pricingReducers = {
  aaPricing,
  kePricing,
  srPricing,
  docPricing,
  tlPricing,
};
