import {
  canAddSeatsTLSubscriptionPermissions,
  canEditTLSubscriptionPermissions,
  canRemoveTLSubscriptionPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

export const tlSubscriptionOverviewPermissions: ProductSubscriptionActionsPermissions =
  {
    canAddSeatsSubscriptionPermissions: canAddSeatsTLSubscriptionPermissions,
    canEditSubscriptionPermissions: canEditTLSubscriptionPermissions,
    canRemoveSubscriptionPermissions: canRemoveTLSubscriptionPermissions,
  };
