import { CustomActionPermissions } from '../../../models/types/CustomActionPermissions';
import { CustomComponent } from '../../../models/types/CustomComponent';

import { SectionTitle } from '../../Typography/Typography';

import {
  HeaderLeftContainer,
  StyledButton,
  Wrapper,
} from './simplePageHeader.styles';

export interface ISimplePageHeaderProps
  extends CustomComponent,
    CustomActionPermissions {
  title: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

const SimplePageHeader = ({
  title,
  buttonTitle,
  onButtonClick,
  children,
  actionPermissions,
}: ISimplePageHeaderProps) => (
  <Wrapper bottomLineIsVisible>
    <HeaderLeftContainer>
      <SectionTitle>{title}</SectionTitle>
      {buttonTitle && (
        <StyledButton
          permissions={actionPermissions}
          onClick={onButtonClick}
          styleType="lighter-blue"
        >
          {buttonTitle}
        </StyledButton>
      )}
    </HeaderLeftContainer>
    {children}
  </Wrapper>
);

export default SimplePageHeader;
