import { Field } from 'react-final-form';

import { CustomForm } from '../../../../models/types/CustomForm';

import { StyledFormInput } from './inputCheckbox.styles';

type CheckboxContentRendererProps = CustomForm & {
  inputId: string;
};

export const InputCheckbox = ({
  className,
  inputId,
  disabled,
  name,
}: CheckboxContentRendererProps) => (
  <Field
    className={className}
    component={StyledFormInput}
    disabled={disabled}
    inputId={inputId}
    name={name}
  />
);
