import styled from '@emotion/styled/macro';

import { StandardText } from '../../../../../styledComponents/Typography/Typography';

import { Card } from '../../../../../styledComponents/UI/Card/Card';

export const StyledCard = styled(Card)`
  border-radius: 0.25rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ProductName = styled.p`
  font: normal bold 1.125rem/2rem ${({ theme }) => theme.font.gotham};
`;

export const PropertyName = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark2};
`;

export const PropertyContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
`;
