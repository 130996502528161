import { StyledInput } from './iconInput.styles';
import { IconNumberInputProps } from './iconInput.models';
import IconInputWrapper from './IconInputWrapper';
import BaseNumberInput from '../BaseNumberInput/BaseNumberInput';

const IconNumberInput = ({
  defaultIcon,
  icon,
  iconPosition = 'end',
  className,
  isLabelHidden,
  label,
  labelAnimation,
  disabled,
  isError,
  ...props
}: IconNumberInputProps) => (
  <IconInputWrapper
    {...props}
    defaultIcon={defaultIcon}
    isError={isError}
    icon={icon}
    iconPosition={iconPosition}
    className={className}
    isLabelHidden={isLabelHidden}
    label={label}
    labelAnimation={labelAnimation}
    disabled={disabled}
  >
    <BaseNumberInput {...props} disabled={disabled} customInput={StyledInput} />
  </IconInputWrapper>
);

export default IconNumberInput;
