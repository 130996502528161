import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { IKESubscriptionsState } from '.';
import { IKEOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionOverview';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { IEditKEOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/EditKEOrganizationSubscriptionModel';
import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IKESubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/KESubscriptionRenewalSuggestionModel';

export const keSubscriptionsSelector = (state: RootState) =>
  state.keSubscriptions;

export const keSubscriptionOverviewSelector = createSelector(
  keSubscriptionsSelector,
  (
    subscriptionState: IKESubscriptionsState,
  ): IKEOrganizationSubscriptionOverview =>
    subscriptionState.currentSubscriptionOverview,
);

export const keSubscriptionDatesSelector = createSelector(
  keSubscriptionsSelector,
  (subscriptionState: IKESubscriptionsState): ISubscriptionDates =>
    subscriptionState.subscriptionDates,
);

export const editingKESubscriptionDataSelector = createSelector(
  keSubscriptionsSelector,
  (
    subscriptionState: IKESubscriptionsState,
  ): IEditKEOrganizationSubscriptionModel =>
    subscriptionState.currentSubscriptionEditing,
);

export const keSubscriptionRenewalSuggestionSelector = createSelector(
  keSubscriptionsSelector,
  (
    subscriptionState: IKESubscriptionsState,
  ): IKESubscriptionRenewalSuggestionModel =>
    subscriptionState.subscriptionRenewalSuggestion,
);

export const keSubscriptionRenewalSuggestionPlansSelector = createSelector(
  keSubscriptionRenewalSuggestionSelector,
  (
    subscriptionRenewalSuggestion: IKESubscriptionRenewalSuggestionModel,
  ): IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[] =>
    subscriptionRenewalSuggestion && subscriptionRenewalSuggestion.plans,
);

export const keSubscriptionRenewalAnalyseSelector = createSelector(
  keSubscriptionsSelector,
  (
    subscriptionState: IKESubscriptionsState,
  ): IRenewOrganizationSubscriptionAnalyseModel =>
    subscriptionState.subscriptionRenewalAnalyse,
);
