import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
} from 'react';

import { getCountOfItemsToShow } from './multiSelectContainer.utils';

const defaultWidthForChar = 10;
const defaultParentWith = 30;

export const useMultiSelectContainer = (
  values: ReactElement<any, string | JSXElementConstructor<any>>[],
  valueRenderer?: (value: any) => ReactNode,
) => {
  const ref = useRef<HTMLDivElement>();

  const joinedValues = useMemo(() => {
    const val = values.map((item) =>
      valueRenderer
        ? valueRenderer(item?.props.data.value)
        : item?.props.data.label,
    );

    const possibleWidth =
      ref?.current?.offsetWidth / defaultWidthForChar || defaultParentWith;

    return getCountOfItemsToShow(val, possibleWidth);
  }, [values, valueRenderer]);

  return { joinedValues, ref };
};
