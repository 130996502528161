import { Enum, IEnumProps } from '../../../UI/Enum/Enum';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';

interface IEnumRenderer extends ICellRenderer, IEnumProps {}

export const EnumRenderer = ({ value, enumName, options }: IEnumRenderer) => (
  <TableCell bold={options?.textBold} alignment={options?.alignment}>
    <Enum value={value} enumName={enumName} />
  </TableCell>
);
