import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../../hooks/useActions';

import { useProcessing } from '../../../../../../hooks/useProcessing';
import { IOrganizationGGPointOfContactModel } from '../../../../../../models/CreateOrganizationGGPointOfContact';
import { employeeListSelector } from '../../../../../../store/redux-slices/employees/selectors';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import { organizationIdSelector } from '../../../../../../store/redux-slices/organizations/selectors';
import {
  EMPLOYEES_LOADING_PROCESSING,
  GG_ROLES_LOADING_PROCESSING,
  ORGANIZATION_EDITING_PROCESSING,
} from '../../../../../../store/redux-slices/processes/constants';
import { loadEmployees } from '../../../../../../store/saga-slices/employees';
import {
  addGGContact,
  IGGContactPayload,
} from '../../../../../../store/saga-slices/organizations';
import { useFormErrorsActions } from '../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateGGPointOfContactForm } from '../../../../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactForm/ggPointOfContact.validation';
import { ADD_GG_CONTACT_MODAL_NAME } from './addGGContactModal.const';
import { loadGGRoles } from '../../../../../../store/saga-slices/roles';
import { ggRolesOptionsSelector } from '../../../../../../store/redux-slices/roles/selectors';
import { ILoadGGRolesPayload } from '../../../../../../store/saga-slices/roles/models';
import { useCancellationToken } from '../../../../../../hooks/useTokenCancellation';
import { clearGGRoles } from '../../../../../../store/redux-slices/roles';

export const useAddGGContactModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);
  const AddGGContact = useActions(addGGContact);
  const LoadEmployees = useActions(loadEmployees);
  const LoadGGRoles = useActions(loadGGRoles);
  const ClearGGRoles = useActions(clearGGRoles);

  const organizationUpdating = useProcessing(ORGANIZATION_EDITING_PROCESSING);
  const employeesLoading = useProcessing(EMPLOYEES_LOADING_PROCESSING);

  const employeesList = useSelector(employeeListSelector);
  const organizationId = useSelector(organizationIdSelector);

  const cancellationToken = useCancellationToken();

  const { addErrors, validateErrors } = useFormErrorsActions();

  const modalShowHandler = useCallback(() => {
    LoadEmployees();
  }, [LoadEmployees]);

  useEffect(() => {
    const payload: ILoadGGRolesPayload = {
      cancellationToken,
    };
    LoadGGRoles(payload);
    return () => {
      ClearGGRoles();
    };
  }, [LoadGGRoles, cancellationToken, ClearGGRoles]);

  const rolesGGLoading = useProcessing(GG_ROLES_LOADING_PROCESSING);
  const rolesGGList = useSelector(ggRolesOptionsSelector);

  const handleSubmit = (values: IOrganizationGGPointOfContactModel) => {
    const payload: IGGContactPayload = {
      contact: values,
      organizationId,
      success: () => {
        alert.success(t('alerts.poc-create-success'));

        UpdateModalState(ADD_GG_CONTACT_MODAL_NAME);
      },
      error: (err) => {
        addErrors(err.ValidationErrors, values);

        alert.error(t('alerts.poc-create-fail'));
      },
    };

    AddGGContact(payload);
  };

  const validateForm = (values: IOrganizationGGPointOfContactModel) =>
    validateGGPointOfContactForm(values, validateErrors(values));

  const closeModalHandler = () => UpdateModalState(ADD_GG_CONTACT_MODAL_NAME);

  return {
    modalShowHandler,
    handleSubmit,
    validateForm,
    organizationUpdating,
    employeesList,
    employeesLoading,
    closeModalHandler,
    rolesGGLoading,
    rolesGGList,
  };
};
