import { AnySchema } from 'ajv';

import { validateFormData } from '../../../utils/validations/validateFormData';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../models/IRenewOrganizationSubscriptionAnalyseModel';

const member: AnySchema | any = {
  type: 'object',
  properties: {
    memberId: {
      type: 'string',
    },
    personId: {
      type: 'string',
    },
    firstName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    dateOfBirth: {
      type: 'string',
    },
    toBeRenewed: {
      type: 'boolean',
    },
    canBeRenewed: {
      type: 'boolean',
    },
    memberType: {
      type: 'string',
    },
  },

  if: {
    properties: {
      canBeRenewed: { enum: [false] },
      toBeRenewed: { enum: [true] },
    },
  },
  then: {
    properties: {
      toBeRenewed: { enum: [false] },
    },
  },
};

const membership: AnySchema | any = {
  type: 'object',
  properties: {
    membershipId: {
      type: 'string',
    },
    membershipPlanId: {
      type: 'string',
    },
    membershipPlanName: {
      type: 'string',
    },
    primaryMember: {
      type: 'object',
      ...member,
    },
    secondaryMembers: {
      type: 'array',
      items: member,
    },
  },
};

export const membershipsFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    memberships: {
      type: 'array',
      items: membership,
    },
  },
};

export const validateForm = (
  data: IRenewOrganizationSubscriptionAnalyseModel,
  // eslint-disable-next-line no-undefined
) => validateFormData(data, undefined, membershipsFormSchema);
