import styled from '@emotion/styled';

import { PageSection } from '../../../../../styledComponents/Page/Page';
import Button from '../../../../../styledComponents/UI/Button/Button';

export const Header = styled(PageSection)`
  margin-bottom: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRightContainer = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-right: 1.5rem;
`;

export const Title = styled.h2`
  margin-bottom: 2.5rem;
`;
