import {
  SystemGroupPermissions,
  AirAmbulancePermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canAddAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanCreateSubscription,
  ),
];

export const canRemoveAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanDeleteSubscription,
  ),
];

export const canEditAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanUpdateSubscription,
  ),
];

export const canAddSeatsAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanUpdateSubscription,
  ),
];

export const canViewAASubscriptionDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanViewSubscriptionDetails,
  ),
];

export const canUpdateAASubscriptionsDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanUpdateSubscription,
  ),
];

export const canRenewAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanCreateSubscription,
  ),
];

export const canExtendsAASubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanUpdateSubscription,
  ),
];

export const canRemoveAAMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanDeleteMembership,
  ),
];

export const canAddAAMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanCreateMembership,
  ),
];

export const canRenewAAMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanCreateMembership,
  ),
];

export const canResendAAWelcomeEmailPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanResendWelcomeEmail,
  ),
];

export const canEditAAMembershipsOptionsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManageMemberships,
  ),
];

export const canRemoveAASecondaryMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManageMemberships,
  ),
];

export const canChangeAAToBeRenewedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManageMemberships,
  ),
];

export const canSetAAMembershipAlreadyPayedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanSetMembershipAlreadyPayed,
  ),
];

export const canAddAAMembershipFamilyMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManageMemberships,
  ),
];

export const canAddAAMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanCreateMembership,
  ),
];

export const canViewAAMembersPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanSearchMembers,
  ),
];

export const canViewAAOrganizationsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanSearchOrganization,
  ),
];

export const canEnableAAPromoCodePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManagePromoCode,
  ),
];
export const canAddAAPromoCodePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManagePromoCode,
  ),
];
export const canDeleteAAPromoCodePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanDeletePromoCode,
  ),
];

export const canCloneAAPromoCodePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManagePromoCode,
  ),
];

export const canEditAAPromoCodeDatesPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManagePromoCode,
  ),
];

export const canViewAAPromoCodeDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanViewPromoCodeDetails,
  ),
];

export const canViewAAPromoCodesPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanSearchPromoCode,
  ),
];

export const canGenerateAARawDataReportPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanGenerateMembershipReport,
  ),
];

export const canGenerateAARetailAttritionReport: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanGenerateMembershipAttritionReport,
  ),
];

export const canManageAAMembershipsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanManageMemberships,
  ),
];

export const canGenerateAARetailEnrollmentReport: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanGenerateMembershipEnrollmentReport,
  ),
];

export const canGenerateAARetailStatisticsReport: string[] = [
  getGroupPermission(
    SystemGroupPermissions.AirAmbulance,
    AirAmbulancePermissions.CanGenerateRetailStatisticsReport,
  ),
];
