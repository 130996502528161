import styled from '@emotion/styled/macro';

import { ISwitchProps, SwitchNoStyled } from './Switch';

export const StyledSwitch = styled((props: ISwitchProps) => (
  <SwitchNoStyled
    {...props}
    classesSwitch={{
      disabled: 'custom-switch-disabled',
      root: 'custom-switch-root',
      thumb: 'custom-switch-thumb',
      checked: 'custom-switch-checked',
      switchBase: 'custom-switch-base',
    }}
    classesWrapper={{
      root: 'custom-switch-wrapper',
      label: 'custom-switch-label',
    }}
  />
))`
  & .custom-switch-disabled {
    .custom-switch-thumb {
      background-color: ${({ theme }) => theme.color.light1};
    }
  }

  & .custom-switch-root {
    width: 3rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 6.25rem;
    background-color: ${({ theme, checked, disabled }) =>
      disabled
        ? theme.color.dark2
        : checked
        ? theme.color.secondary1
        : theme.color.main_background};
  }

  & .custom-switch-thumb {
    width: 1.4rem;
    height: 1.3rem;
    margin-top: 0.1rem;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.main_background};
  }

  & .custom-switch-checked {
    color: ${({ theme }) => theme.color.white} !important;
    transform: translateX(1.5rem) !important;
  }

  & .custom-switch-base {
    padding: 0;
  }

  &.custom-switch-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  & .custom-switch-label {
    font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
    color: ${({ theme }) => theme.color.dark1};
  }
`;
