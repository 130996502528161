import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';
import { ITLSubscriptionPricingTierOverviewModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import {
  TableDataType,
  TableFieldsDataType,
} from './extendTLSubscriptionPackageFormTable.models';

export const prepareTableData = (
  data: ITLSubscriptionPricingTierOverviewModel[],
  newValues: IChangeTLSubscriptionPricingTierModel[],
  fieldsData: TableFieldsDataType[],
): TableDataType[] =>
  fieldsData.map((item) => ({
    name: item.name,
    index: item.index,
    currentSeatsAmount:
      data && data[item.index] ? data[item.index]?.seatsAmount : 0,
    newSeatsAmount:
      data && data[item.index]
        ? data[item.index]?.seatsAmount + newValues[item.index]?.seatsAmount
        : newValues[item.index]?.seatsAmount,
  }));
