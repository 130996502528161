import { ICellOptions } from '../cellRenderers.models';
import { EmptyTableCell } from '../EmptyTableCell/EmptyTableCell';
import TableCell from '../TableCell/TableCell';
import { MaxValue, Separator, Value, Wrapper } from './fractionRenderer.styles';

interface IFractionRenderer {
  isSummary?: boolean;
  maxValue?: number | string;
  value: number | string;
  options?: ICellOptions;
}

export const FractionRenderer = ({
  isSummary,
  maxValue,
  options,
  value,
}: IFractionRenderer) => {
  if (!maxValue && !value) {
    return <EmptyTableCell options={options} />;
  }
  return (
    <TableCell bold={options?.textBold} alignment={options?.alignment}>
      <Wrapper>
        <Value isSummary={isSummary}>{value}</Value>
        <Separator>/</Separator>
        <MaxValue>{maxValue}</MaxValue>
      </Wrapper>
    </TableCell>
  );
};
