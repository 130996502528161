import { useIsAuthenticated, useMsal } from '@azure/msal-react';

export const useHomePage = () => {
  const { instance, accounts } = useMsal();
  const isAuth = useIsAuthenticated();

  const onLoginHandler = (event: any) => {
    event.preventDefault();
    instance.loginRedirect();
  };

  const onLogoutHandler = (event: any) => {
    event.preventDefault();
    instance.logoutRedirect();
  };

  return {
    isAuth,
    userName: accounts[0]?.name,
    onLoginHandler,
    onLogoutHandler,
  };
};
