import {
  canGenerateAARawDataReportPermissions,
  canGenerateDOCRawDataReportPermissions,
  canGenerateKERawDataReportPermissions,
  canGenerateSRRawDataReportPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../../../../models/ProductType';
import { getOptionsFromEnum } from '../../../../../utils/getOptionsFromEnum';

export const ProductTypeOptions = getOptionsFromEnum(
  ProductType,
  'productType',
  [ProductType.Unknown, ProductType.TravelLicense],
);

export const RawDataReportsPermissions = {
  [ProductType.AirAmbulance]: canGenerateAARawDataReportPermissions,
  [ProductType.KidnapAndExtortion]: canGenerateKERawDataReportPermissions,
  [ProductType.SecurityResponse]: canGenerateSRRawDataReportPermissions,
  [ProductType.DutyOfCare]: canGenerateDOCRawDataReportPermissions,
};
