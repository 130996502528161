import { call, select } from 'typed-redux-saga/macro';

import { ICreatePrimaryMemberModel } from '../../../../models/CreatePrimaryMemberModel';

import { ICreateSecondaryMemberModel } from '../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import { IWarningRatification } from '../../../../models/interfaces/WarningRatification';
import { parseDate } from '../../../../utils/dateHelpers';
import {
  primaryMemberSelector,
  membershipSelector,
} from '../../../redux-slices/membership/common/selectors';
import { ICreateAAMembershipPageModel } from '../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import { CreateAAMembershipModel } from '../../../../models/types/AirAmbulance/CreateAAMembershipModel';
import { ICreateKEMembershipPageModel } from '../../../../models/interfaces/Membership/KidnapAndExtortion/CreateKEMembershipPageModel';
import { CreateKEMembershipModel } from '../../../../models/types/KidnapAndExtortion/CreateKEMembershipModel';
import { ICreateAARetailMembershipPageModel } from '../../../../models/interfaces/Membership/AirAmbulance/CreateAARetailMembershipPageModel';
import { ICreateSRMembershipPageModel } from '../../../../models/interfaces/Membership/SecurityResponse/CreateSRMembershipPageModel';
import { CreateSRMembershipModel } from '../../../../models/types/SecurityResponse/CreateSRMembershipModel';
import { ICreateDOCMembershipPageModel } from '../../../../models/interfaces/Membership/DutyOfCare/CreateDOCMembershipPageModel';
import { CreateDOCMembershipModel } from '../../../../models/types/DutyOfCare/CreateDOCMembershipModel';
import { mapToTermType } from '../../../../pages/AddMemberPage/AddMemberWizardPages/AddMemberMembershipPage/MembershipSectionsForms/addMemberMembershipPage.utils';

export function* prepareCreateAARetailMembershipRequestData() {
  const primaryMember = yield* select(primaryMemberSelector);
  const membership: ICreateAARetailMembershipPageModel = yield* select(
    membershipSelector,
  );

  const primaryMemberData = yield* call(
    preparePrimaryMemberData,
    primaryMember,
  );

  const ratifications = yield* call(
    prepareRatificationsForCreateMembership,
    membership.ratifications,
    primaryMemberData.ratifications,
  );

  const secondaryMembers = yield* call(
    prepareSecondaryMembersForCreateMembership,
    membership.secondaryMembers,
  );

  const data: CreateAAMembershipModel = {
    primaryMember: primaryMemberData,
    autoRenewal: membership.autoRenewal,
    effectiveDate: membership.effectiveDate,
    membershipPlanId: membership.membershipPlanId.membershipPlanId,
    sendWelcomeEmail: !!membership.sendWelcomeEmail,
    secondaryMembers,
    expirationDate: parseDate(membership.expirationDate),
    overwriteAmount: membership.overwriteAmount,
    overwrittenAmount: membership.overwrittenAmount,
    ratifications,
    renewedMembershipId: membership.renewedMembershipId,
    promoCode: membership.promoCode,
    MembershipPlanTermType: mapToTermType(membership.membershipPlanId.termType),
  };

  return { data };
}

export function* prepareCreateOrganizationAAMembershipRequestData() {
  const primaryMember = yield* select(primaryMemberSelector);
  const membership: ICreateAAMembershipPageModel = yield* select(
    membershipSelector,
  );

  const primaryMemberData = yield* call(
    preparePrimaryMemberData,
    primaryMember,
  );

  const ratifications = yield* call(
    prepareRatificationsForCreateMembership,
    membership.ratifications,
    primaryMemberData.ratifications,
  );

  const secondaryMembers = yield* call(
    prepareSecondaryMembersForCreateMembership,
    membership.secondaryMembers,
  );

  const data: CreateAAMembershipModel = {
    primaryMember: primaryMemberData,
    effectiveDate: membership.effectiveDate,
    membershipPlanId: membership.membershipPlanId.membershipPlanId,
    sendWelcomeEmail: !!membership.sendWelcomeEmail,
    autoRenewal: !!membership.autoRenewal,
    secondaryMembers,
    expirationDate: parseDate(membership.expirationDate),
    promoCode: membership.promoCode,
    ratifications,
    MembershipPlanTermType: mapToTermType(membership.membershipPlanId.termType),
  };

  const organizationId = membership.organization?.organizationId;
  const subscriptionId = membership.subscriptionType;

  return { data, organizationId, subscriptionId };
}

export function* prepareCreateKEOrganizationMembershipRequestData() {
  const primaryMember = yield* select(primaryMemberSelector);
  const membership: ICreateKEMembershipPageModel = yield* select(
    membershipSelector,
  );

  const primaryMemberData = yield* call(
    preparePrimaryMemberData,
    primaryMember,
  );

  const ratifications = yield* call(
    prepareRatificationsForCreateMembership,
    membership.ratifications,
    primaryMemberData.ratifications,
  );

  const secondaryMembers = yield* call(
    prepareSecondaryMembersForCreateMembership,
    membership.secondaryMembers,
  );

  const data: CreateKEMembershipModel = {
    primaryMember: primaryMemberData,
    effectiveDate: membership.effectiveDate,
    membershipPlanId: membership.membershipPlanId.membershipPlanId,
    sendWelcomeEmail: !!membership.sendWelcomeEmail,
    autoRenewal: !!membership.autoRenewal,
    secondaryMembers,
    expirationDate: parseDate(membership.expirationDate),
    ratifications,
  };

  const organizationId = membership.organization?.organizationId;
  const subscriptionId = membership.subscriptionType;

  return { data, organizationId, subscriptionId };
}

export function* prepareCreateSROrganizationMembershipRequestData() {
  const primaryMember = yield* select(primaryMemberSelector);
  const membership: ICreateSRMembershipPageModel = yield* select(
    membershipSelector,
  );

  const primaryMemberData = yield* call(
    preparePrimaryMemberData,
    primaryMember,
  );

  const ratifications = yield* call(
    prepareRatificationsForCreateMembership,
    membership.ratifications,
    primaryMemberData.ratifications,
  );

  const secondaryMembers = yield* call(
    prepareSecondaryMembersForCreateMembership,
    membership.secondaryMembers,
  );

  const data: CreateSRMembershipModel = {
    primaryMember: primaryMemberData,
    effectiveDate: membership.effectiveDate,
    membershipPlanId: membership.membershipPlanId.membershipPlanId,
    sendWelcomeEmail: !!membership.sendWelcomeEmail,
    autoRenewal: !!membership.autoRenewal,
    secondaryMembers,
    expirationDate: parseDate(membership.expirationDate),
    ratifications,
  };

  const organizationId = membership.organization?.organizationId;
  const subscriptionId = membership.subscriptionType;

  return { data, organizationId, subscriptionId };
}

export function* prepareCreateDOCOrganizationMembershipRequestData() {
  const primaryMember = yield* select(primaryMemberSelector);
  const membership: ICreateDOCMembershipPageModel = yield* select(
    membershipSelector,
  );

  const primaryMemberData = yield* call(
    preparePrimaryMemberData,
    primaryMember,
  );

  const ratifications = yield* call(
    prepareRatificationsForCreateMembership,
    membership.ratifications,
    primaryMemberData.ratifications,
  );

  const data: CreateDOCMembershipModel = {
    primaryMember: primaryMemberData,
    effectiveDate: membership.effectiveDate,
    sendWelcomeEmail: !!membership.sendWelcomeEmail,
    autoRenewal: !!membership.autoRenewal,
    expirationDate: parseDate(membership.expirationDate),
    ratifications,
  };

  const organizationId = membership.organization?.organizationId;
  const subscriptionId = membership.subscriptionType;

  return { data, organizationId, subscriptionId };
}

export function preparePrimaryMemberData(data: ICreatePrimaryMemberModel) {
  if (data.personId) {
    return {
      personId: data.personId,
      ratifications: data.ratifications,
    };
  }

  return data;
}

export function prepareRatificationsForCreateMembership(
  membershipRatification: IWarningRatification[],
  primaryMemberRatification: IWarningRatification[],
) {
  if (!primaryMemberRatification) {
    return membershipRatification;
  }

  const currentPrimaryMemberRatification: IWarningRatification[] =
    primaryMemberRatification.map((ratification) => ({
      ...ratification,
      propertyName: `PrimaryMember.${ratification.propertyName}`,
    }));
  return [...membershipRatification, ...currentPrimaryMemberRatification];
}

export function prepareSecondaryMembersForCreateMembership(
  secondaryMembers: ICreateSecondaryMemberModel[],
) {
  return secondaryMembers?.map((member) =>
    member.personId
      ? { personId: member.personId, dependence: member.dependence }
      : member,
  );
}
