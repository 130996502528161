import { Field } from 'react-final-form';

import { canSetAAMembershipAlreadyPayedPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import PermissionsGuard from '../../../../../styledComponents/PermissionsGuard/PermissionsGuard';
import { Line } from '../../../../../styledComponents/UI/Line/Line';
import AddMemberSummaryForm from '../../AddMemberSummaryForm/AddMemberSummaryForm';
import { PaymentSummaryFields } from './addMemberPaymentSummaryForm.models';
import {
  StyledFormCheckbox,
  Wrapper,
} from './addMemberPaymentSummaryForm.styles';

interface IAddMemberPaymentSummaryFormProps {
  className?: string;
  disabled?: boolean;
  title: string;
  isPriceOverridden: boolean;
}

const AddMemberPaymentSummaryForm = ({
  disabled,
  title,
  className,
  isPriceOverridden,
}: IAddMemberPaymentSummaryFormProps) => (
  <AddMemberSummaryForm
    isPriceOverridden={isPriceOverridden}
    title={title}
    disabled={disabled}
    className={className}
  >
    <PermissionsGuard permissions={canSetAAMembershipAlreadyPayedPermissions}>
      <Wrapper>
        <Line />
        <Field
          component={StyledFormCheckbox}
          disabled={disabled}
          inputId="summaryForm.summaryOverrideSubtotal"
          name={PaymentSummaryFields.AlreadyPaid}
          type="checkbox"
        />
      </Wrapper>
    </PermissionsGuard>
  </AddMemberSummaryForm>
);
export default AddMemberPaymentSummaryForm;
