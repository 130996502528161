import styled from '@emotion/styled/macro';

import IconNumberInput from '../../../IconInput/IconNumberInput';
import TabOptions from '../../../TabOptions/TabOptions';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
`;

export const DiscountInput = styled(IconNumberInput)`
  width: 10rem;
`;

export const StyledTabOptions = styled(TabOptions)`
  margin-left: 2.125rem;
`;
