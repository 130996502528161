import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IDOCSubscriptionsState } from '.';
import {
  IDOCOrganizationSubscriptionOverview,
  IDOCSubscriptionPackageOptionsHistoryModel,
} from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { IEditDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/EditDOCOrganizationSubscriptionModel';
import { IDOCSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCSubscriptionRenewalSuggestionModel';
import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import { IDOCOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionExtensionSummaryModel';

export const docSubscriptionsSelector = (state: RootState) =>
  state.docSubscriptions;

export const docSubscriptionOverviewSelector = createSelector(
  docSubscriptionsSelector,
  (
    subscriptionState: IDOCSubscriptionsState,
  ): IDOCOrganizationSubscriptionOverview =>
    subscriptionState.currentSubscriptionOverview,
);

export const docSubscriptionOverviewCurrentPackagesSelector = createSelector(
  docSubscriptionOverviewSelector,
  (
    subscription: IDOCOrganizationSubscriptionOverview,
  ): IDOCSubscriptionPackageOptionsHistoryModel[] =>
    subscription?.currentPackage && [subscription?.currentPackage],
);

export const docSubscriptionOverviewHistoryPackagesSelector = createSelector(
  docSubscriptionOverviewSelector,
  (
    subscription: IDOCOrganizationSubscriptionOverview,
  ): IDOCSubscriptionPackageOptionsHistoryModel[] =>
    subscription?.packageOptionsHistory,
);

export const docSubscriptionExtensionDataSelector = createSelector(
  docSubscriptionOverviewSelector,
  (
    subscription: IDOCOrganizationSubscriptionOverview,
  ): IDOCOrganizationSubscriptionExtensionData => ({
    ...subscription?.currentPackage,
    totalMembershipsInUse: subscription?.totalMembershipsInUse,
  }),
);

export const docSubscriptionDatesSelector = createSelector(
  docSubscriptionsSelector,
  (subscriptionState: IDOCSubscriptionsState): ISubscriptionDates =>
    subscriptionState.subscriptionDates,
);

export const editingDOCSubscriptionDataSelector = createSelector(
  docSubscriptionsSelector,
  (
    subscriptionState: IDOCSubscriptionsState,
  ): IEditDOCOrganizationSubscriptionModel =>
    subscriptionState.currentSubscriptionEditing,
);

export const docSubscriptionRenewalSuggestionSelector = createSelector(
  docSubscriptionsSelector,
  (
    subscriptionState: IDOCSubscriptionsState,
  ): IDOCSubscriptionRenewalSuggestionModel =>
    subscriptionState.subscriptionRenewalSuggestion,
);

export const docSubscriptionRenewalSuggestionPlansSelector = createSelector(
  docSubscriptionRenewalSuggestionSelector,
  (
    subscriptionRenewalSuggestion: IDOCSubscriptionRenewalSuggestionModel,
  ): IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[] =>
    subscriptionRenewalSuggestion && subscriptionRenewalSuggestion.plans,
);

export const docSubscriptionRenewalAnalyseSelector = createSelector(
  docSubscriptionsSelector,
  (
    subscriptionState: IDOCSubscriptionsState,
  ): IRenewOrganizationSubscriptionAnalyseModel =>
    subscriptionState.subscriptionRenewalAnalyse,
);

export const docSubscriptionExtensionSummarySelector = createSelector(
  docSubscriptionsSelector,
  (
    subscriptionState: IDOCSubscriptionsState,
  ): IDOCOrganizationSubscriptionExtensionSummaryModel =>
    subscriptionState.subscriptionExtensionSummary,
);
