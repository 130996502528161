import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { ORGANIZATION_NOTES_EDITING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { IEditOrganizationContractNotesPayload } from '../../../../../../../store/saga-slices/contracts/models';
import {
  useApiResponseError,
  useFormErrors,
} from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateNotesForm } from '../../../../../../../styledComponents/Forms/NotesForm/notesForm.validation';
import { EDIT_ORG_NOTES_MODAL_NAME } from '../editNotesModal.const';
import { EditOrganizationNotesModalDetails } from './editNotesModalForm.models';
import { IEditOrganizationNotesModelPayload } from '../../../../../../../store/saga-slices/organizations/models';
import { editOrganizationNotes } from '../../../../../../../store/saga-slices/organizations';

interface IUseEditOrganizationNotesProps {
  onNotesEdited?: () => void;
}

export const useEditNotes = ({
  onNotesEdited,
}: IUseEditOrganizationNotesProps) => {
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const EditOrganizationNotes = useActions(editOrganizationNotes);

  const isProcessing = useProcessing(ORGANIZATION_NOTES_EDITING_PROCESSING);

  const { organizationId, notes }: EditOrganizationNotesModalDetails =
    useModalDetails(EDIT_ORG_NOTES_MODAL_NAME);

  const { validateErrors, addErrors } = useFormErrors();

  const { handleResponseError } = useApiResponseError({
    externalAddErrors: addErrors,
  });

  const closeModalHandler = useCallback(() => {
    UpdateModalState(EDIT_ORG_NOTES_MODAL_NAME);
  }, [UpdateModalState]);

  const handleSubmit = useCallback(
    (values: { notes: string }) => {
      const payload: IEditOrganizationNotesModelPayload = {
        organizationId,
        notes: values.notes,
        cancellationToken,
        success: () => {
          alert.success(t('alerts.contract-notes-updated-success'));
          closeModalHandler();
          onNotesEdited?.();
        },
        error: (err: any) => {
          handleResponseError(err, values);
          alert.error(t('alerts.contract-notes-updated-fail'));
        },
      };
      EditOrganizationNotes(payload);
    },
    [
      EditOrganizationNotes,
      alert,
      cancellationToken,
      closeModalHandler,
      handleResponseError,
      onNotesEdited,
      organizationId,
      t,
    ],
  );

  const validateData = useCallback(
    (data: IEditOrganizationContractNotesPayload) =>
      validateNotesForm(data, validateErrors(data)),
    [validateErrors],
  );

  return {
    closeModalHandler,
    handleSubmit,
    notes,
    isProcessing,
    validateData,
  };
};
