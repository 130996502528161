export enum DateFilterSingleType {
  PreviousMonth = 'previousMonth',
  ThisMonth = 'thisMonth',
  NextMonth = 'nextMonth',
  InTwoMonths = 'inTwoMonths',
  InThreeMonths = 'inThreeMonths',
  PreviousYear = 'previousYear',
  ThisYear = 'thisYear',
  NextYear = 'nextYear',
  InTwoYears = 'inTwoYears',
}
