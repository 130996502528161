import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type WrapperType = {
  showFullText?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ showFullText }) =>
    !showFullText &&
    css`
      width: 12rem;
    `}
`;

export const OrganizationContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  white-space: nowrap;
`;

type OrganizationNameProps = {
  isClickable?: boolean;
  showFullText?: boolean;
};

export const OrganizationName = styled.div<OrganizationNameProps>`
  ${({ showFullText }) =>
    !showFullText &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  ${({ isClickable }) =>
    !!isClickable &&
    css`
      font-weight: bold;
      cursor: pointer;
    `}
`;
