import styled from '@emotion/styled/macro';

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ItemOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
