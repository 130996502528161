import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../models/types/CustomComponent';
import Spinner from '../../../../Spinner/Spinner';
import TLPricingRow from './tlPricingRow';
import { useTLPricingTooltip } from './tlPricingTooltip.hooks';
import {
  Wrapper,
  Title,
  Table,
  PriceTitle,
  SeatsAmountTitle,
} from './tlPricingTooltip.styles';

const TLPricingTooltip = ({ className }: CustomComponent) => {
  const { t } = useTranslation();
  const { tlPricing, isLoading } = useTLPricingTooltip();

  let content = (
    <Table>
      <SeatsAmountTitle>{t('grid-columns.seats-amount')}</SeatsAmountTitle>
      <PriceTitle>{t('grid-columns.price')}</PriceTitle>
      {tlPricing.map((x) => (
        <TLPricingRow key={x.price} data={x} />
      ))}
    </Table>
  );

  if (!tlPricing.length || (!tlPricing.length && isLoading)) {
    content = <Spinner mode="light" size="5rem" />;
  }

  return (
    <Wrapper className={className}>
      <Title>{t('headers.tl-pricing')}</Title>
      {content}
    </Wrapper>
  );
};

export default TLPricingTooltip;
