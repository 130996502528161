import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { useProductSubscriptionEditDetailsModal } from './subscriptionEditDetailsModal.hooks';
import { EDITING_SUBSCRIPTION_DETAILS_MODAL_NAME } from './subscriptionEditDetailsModal.consts';
import ModalFormPage from '../../../../../../../styledComponents/ModalFormPage/ModalFormPage';
import WarningsConfirmationModal from '../../../../../../../styledComponents/WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { StyledSubscriptionOptions } from './subscriptionEditDetailsModal.styles';
import { withErrorProvider } from '../../../../../../../hoc/withErrorProvider';
import { withWarningsConfirmationProvider } from '../../../../../../../hoc/withWarningsConfirmationProvider';
import { validateEditSubscriptionDetailsForm } from './subscriptionEditDetailsModal.validation';
import { SmallModal } from '../../../../../../../styledComponents/ModalCommon/ModalCommon';

interface IProductSubscriptionEditDetailsModalProps {
  onModalSuccess: () => void;
}

const ProductSubscriptionEditDetailsModal = ({
  onModalSuccess,
}: IProductSubscriptionEditDetailsModalProps) => {
  const { t } = useTranslation();

  const {
    handleWarningsConfirm,
    handleSubmit,
    validateErrors,
    subscriptionEditing,
    initData,
    onCancelHandler,
  } = useProductSubscriptionEditDetailsModal({ onModalSuccess });

  return (
    <>
      <WarningsConfirmationModal onWarningsConfirm={handleWarningsConfirm} />
      <SmallModal name={EDITING_SUBSCRIPTION_DETAILS_MODAL_NAME}>
        <Form
          initialValues={initData}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          validate={(values) =>
            validateEditSubscriptionDetailsForm(values, validateErrors(values))
          }
        >
          {({ ...renderProps }) => (
            <ModalFormPage
              handleSubmit={renderProps.handleSubmit}
              isProcessing={subscriptionEditing}
              onCancel={onCancelHandler}
              mode="small"
              title={t('headers.edit-options')}
            >
              <StyledSubscriptionOptions
                layout="column"
                disabled={subscriptionEditing}
              />
            </ModalFormPage>
          )}
        </Form>
      </SmallModal>
    </>
  );
};

export default withErrorProvider(
  withWarningsConfirmationProvider(ProductSubscriptionEditDetailsModal),
);
