import FormErrorProvider from '../../../../styledComponents/FormErrorProvider/FormErrorProvider';
import AddMemberPaymentForm from './AddMemberPaymentForm/AddMemberPaymentForm';
import {
  MEMBERSHIP_ALREADY_RENEWED_WARNING_CODE,
  REPAYMENT_NOT_POSSIBLE_MODAL,
} from '../Modals/RepaymentNotPaymentModal/repaymentNotPossibleModal.const';
import WarningsConfirmationProvider from '../../../../styledComponents/WarningsConfirmation/WarningsConfirmationProvider';
import { withStripeElements } from '../../../../styledComponents/Stripe/withStripeElements';

const AddMemberPaymentPage = () => (
  <FormErrorProvider>
    <WarningsConfirmationProvider
      customWarningsModals={[
        {
          errorCode: MEMBERSHIP_ALREADY_RENEWED_WARNING_CODE,
          modalName: REPAYMENT_NOT_POSSIBLE_MODAL,
        },
      ]}
    >
      <AddMemberPaymentForm />
    </WarningsConfirmationProvider>
  </FormErrorProvider>
);

export default withStripeElements(AddMemberPaymentPage);
