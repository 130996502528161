import { IDOCOrganizationSubscriptionExtensionSummaryModel } from '../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionExtensionSummaryModel';

import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import SummaryItemSubtotal from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemSubtotal/SummaryItemSubtotal';
import ExtendDOCSubscriptionSummaryItemContent from '../ExtendDOCSubscriptionSummaryItemContent/ExtendDOCSubscriptionSummaryItemContent';
import { Wrapper } from './extendDOCSubscriptionSummaryContent.styles';

interface IExtendSubscriptionSummaryContentProps extends CustomComponent {
  summaryData?: IDOCOrganizationSubscriptionExtensionSummaryModel;
  productType?: ProductType;
  name?: string;
  subtotalValue?: number;
  isPriceOverridden?: boolean;
}

const ExtendDOCSubscriptionSummaryContent = ({
  summaryData,
  productType,
  disabled,
  name,
  subtotalValue,
  isPriceOverridden,
}: IExtendSubscriptionSummaryContentProps) => (
  <Wrapper>
    {summaryData && (
      <ExtendDOCSubscriptionSummaryItemContent
        data={summaryData.packageCapacityChangeModel}
        isPriceOverridden={isPriceOverridden}
        productType={productType}
      />
    )}

    <SummaryItemSubtotal
      isPriceOverridden={isPriceOverridden}
      disabled={disabled}
      name={name}
      subtotalValue={subtotalValue}
    />
  </Wrapper>
);

export default ExtendDOCSubscriptionSummaryContent;
