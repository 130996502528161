import { useCallback, useMemo } from 'react';

import { useActions } from '../../../../../hooks/useActions';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { IGetRawDataReport } from '../../../../../models/interfaces/GetRawDataReport';
import { ProductType } from '../../../../../models/ProductType';
import { downloadRawDataReport } from '../../../../../store/saga-slices/reports/common';

import { IGetRawDataReportPayload } from '../../../../../store/saga-slices/reports/common/models';
import {
  ProductTypeOptions,
  RawDataReportsPermissions,
} from './rawDataReportsForm.utils';

export const useRawDataReports = () => {
  const { checkUserPermissions } = usePermissions();

  const DownloadRawDataReport = useActions(downloadRawDataReport);

  const DownloadRawDataReportHandler = useCallback(
    (productType: ProductType) => {
      const payload: IGetRawDataReportPayload = {
        productType,
      };

      DownloadRawDataReport(payload);
    },
    [DownloadRawDataReport],
  );

  const generateRetailDataReportFile = useCallback(
    (values: IGetRawDataReport) => {
      DownloadRawDataReportHandler(values.productType);
    },
    [DownloadRawDataReportHandler],
  );

  const filteredReportProductTypes = useMemo(
    () =>
      ProductTypeOptions.filter((x) =>
        checkUserPermissions(RawDataReportsPermissions[x.value]),
      ),
    [checkUserPermissions],
  );

  return {
    generateRetailDataReportFile,
    filteredReportProductTypes,
  };
};
