import { t } from 'i18next';
import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { AARenewSubscriptionDetailsFormFields } from './aaRenewSubscriptionDetails.models';
import { subscriptionRenewDetailsCommonFormSchema } from '../../Common/SubscriptionRenewDetailsCommonForm/subscriptionRenewDetailsCommonForm.validation';

interface IValidateAARenewSubscriptionDetailsForm {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  promoCode: string;
  subscriptionType: string;
}

export const aaRenewSubscriptionDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [AARenewSubscriptionDetailsFormFields.PromoCode]: {
      errorMessage: t('validation.should-not-be-shorter'),
      type: 'string',
      maxLength: 30,
    },
    ...subscriptionRenewDetailsCommonFormSchema.properties,
  },
  required: [
    AARenewSubscriptionDetailsFormFields.PromoCode,
    ...subscriptionRenewDetailsCommonFormSchema.required,
  ],
};

export const validateAARenewSubscriptionDetailsForm = (
  data: IValidateAARenewSubscriptionDetailsForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, aaRenewSubscriptionDetailsSchema);
