import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommissionType } from '../../../../models/enums/CommissionType';

type UsePromoCodeOverviewDetailsProps = {
  commissionType: CommissionType;
  commissionValue: number;
  commissionPercentage: number;
};

export const usePromoCodeOverviewDetails = ({
  commissionPercentage,
  commissionType,
  commissionValue,
}: UsePromoCodeOverviewDetailsProps) => {
  const { t } = useTranslation();
  const currentCommissionValue = useMemo(() => {
    switch (commissionType) {
      case CommissionType.PercentageFinalFee:
      case CommissionType.PercentageProfit:
        return t(`enums.commission-type-overview.${commissionType}`, {
          value: commissionPercentage,
        });
      case CommissionType.FixedFee:
        return t(`enums.commission-type-overview.${commissionType}`, {
          value: commissionValue,
        });
      default:
        return '';
    }
  }, [commissionPercentage, commissionType, commissionValue, t]);

  return { currentCommissionValue };
};
