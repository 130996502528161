import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import Button from '../Button/Button';

export const FieldsDeleteButton = styled(Button, {
  shouldForwardProp: isPropValid,
})`
  background: none;
  height: auto;
  font-size: 0.625rem;
  line-height: 0;
  padding: 0;
`;
