import styled from '@emotion/styled/macro';

export const Warnings = styled.div`
  padding: 0 2.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextWrapper = styled.div`
  letter-spacing: 0.013rem;
  font: 400 normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
`;
