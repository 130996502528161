import styled from '@emotion/styled/macro';

export const ValueContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1.875rem;
`;

export const Value = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
`;
