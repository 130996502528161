import styled from '@emotion/styled';

import {
  LeftContainer,
  RightContainer,
} from '../../../styledComponents/Page/Page';
import Button from '../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  margin: 1.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  grid-template-columns: 1fr 18.5rem;
  display: grid;
  flex: 1;
  grid-gap: 5rem;
`;

export const StyledRightContainer = styled(RightContainer)`
  gap: 2.5rem;
`;

export const StyledLeftContainer = styled(LeftContainer)`
  gap: 0;
`;

export const StyledDeleteButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  margin-top: 1.875rem;
  line-height: 2rem;
  align-self: flex-start;
`;
