import { useTranslation } from 'react-i18next';

import { IMemberInfoModel } from '../../../../models/MemberInfoModel';
import MaterialTable from '../../../../styledComponents/MaterialTable/MaterialTable';
import {
  useMemberTable,
  useMenuOptions,
} from '../../MemberOverviewMemberTable/memberOverviewMemberTable.hooks';
import { MemberOverviewMemberTablePermissions } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.models';
import { TableTitle } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.styles';

interface IDOCMembershipMemberTableProps {
  data?: IMemberInfoModel[];
  onRenewFlagChange?: (memberId: string, toBeRenewed: boolean) => void;
  onDeleteMember?: (member: IMemberInfoModel) => void;
  dataLoading?: boolean;
  isExpiredMembershipsTable?: boolean;
  onResendEmailClick?: (personId: string) => void;
  onRowClick?: (row: IMemberInfoModel) => void;
  permissions?: MemberOverviewMemberTablePermissions;
}

const DOCMembershipMemberTable = ({
  data,
  onRenewFlagChange,
  onDeleteMember,
  dataLoading,
  isExpiredMembershipsTable,
  onResendEmailClick,
  onRowClick,
  permissions,
}: IDOCMembershipMemberTableProps) => {
  const { t } = useTranslation();

  const { cellRenderer } = useMemberTable();
  const { actionCellRenderer } = useMenuOptions({
    onRenewFlagChange,
    isExpiredMembershipsTable,
    onResendEmailClick,
    onDeleteMember,
    permissions,
  });

  return (
    <div>
      <TableTitle>{t('headers.members')}</TableTitle>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.member-name'),
            field: 'memberName',
            cellRenderer: cellRenderer('memberName', {
              textBold: true,
            }),
          },
          {
            title: t('grid-columns.email-address'),
            field: 'contactEmailAddress',
            alignment: 'center',
          },
          {
            title: t('grid-columns.renew'),
            field: 'toBeRenewed',
            cellRenderer: cellRenderer('toBeRenewed'),
          },
          {
            cellRenderer: actionCellRenderer,
            permissions: permissions?.canViewActionColumnPermissions,
          },
        ]}
        data={data || []}
        isPaginationHidden
        onRowClick={onRowClick}
        dataLoading={dataLoading}
      />
    </div>
  );
};

export default DOCMembershipMemberTable;
