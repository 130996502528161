import { call } from 'typed-redux-saga/macro';

import { ICreateAAMembershipPageModel } from '../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import { MembershipType } from '../../../../models/MembershipType';
import { getAAPlanById } from '../../plans/airAmbulance/utils';
import { IMembershipPlanModel } from '../../../../models/interfaces/Membership/MembershipPlanModel';
import { CreateAARetailMembershipModel } from '../../../../models/types/AirAmbulance/CreateAARetailMembershipModel';

export function* prepareCreateMemberPageData(
  loadedData: CreateAARetailMembershipModel,
) {
  const plan: IMembershipPlanModel = yield* call(
    getAAPlanById,
    loadedData.membershipPlanId,
  );

  const data: ICreateAAMembershipPageModel = {
    autoRenewal: loadedData.autoRenewal,
    effectiveDate: loadedData.effectiveDate,
    membershipPlanId: plan,
    sendWelcomeEmail: loadedData.sendWelcomeEmail,
    secondaryMembers: loadedData.secondaryMembers,
    expirationDate: loadedData.expirationDate,
    overwriteAmount: loadedData.overwriteAmount,
    overwrittenAmount: loadedData.overwrittenAmount,
    promoCode: loadedData.promoCode,
    membershipType: MembershipType.Retail,
  };

  return data;
}
