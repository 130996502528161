import CorporateClientsAirAmbulanceTools from './CorporateClientsAirAmbulanceTools/CorporateClientsAirAmbulanceTools';
import CorporateClientsAirAmbulanceTable from './CorporateClientsAirAmbulanceTable/CorporateClientsAirAmbulanceTable';
import { SimplePageLine } from '../../../styledComponents/SimplePage/SimplePageLine';
import { useCorporateCLientsAirAmbulancePage } from './corporateCLientsAirAmbulancePage.hooks';
import { canViewOrganizationDetailsPagePermissions } from '../../../config/accessPolicies/accessPolicies';

const CorporateClientsAirAmbulancePage = () => {
  const {
    airAmbulanceOrganizations,
    dataLoading,
    onRowClick,
    organizationsTotalCount,
  } = useCorporateCLientsAirAmbulancePage();

  return (
    <div>
      <CorporateClientsAirAmbulanceTools />
      <SimplePageLine />
      <CorporateClientsAirAmbulanceTable
        onRowClick={onRowClick}
        dataLoading={dataLoading}
        totalCount={organizationsTotalCount}
        canViewDetailsPermissions={canViewOrganizationDetailsPagePermissions}
        data={airAmbulanceOrganizations}
      />
    </div>
  );
};

export default CorporateClientsAirAmbulancePage;
