import { AnySchema } from 'ajv';

import { FieldErrorMessage } from 'models/FieldErrorMessage';

import { IGetRawDataReport } from '../../../../../models/interfaces/GetRawDataReport';

import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { RawDataReportsFields } from './rawDataReportsForm.models';

export const reportsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [RawDataReportsFields.ProductType]: {
      type: 'string',
    },
  },
  required: [RawDataReportsFields.ProductType],
};

export const validateRawDataReportSchemaForm = (
  data: IGetRawDataReport,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, reportsSchema);
