import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { FormFieldWrapper } from '../../../Form/Form';
import FormCheckbox from '../FormCheckbox/FormCheckbox';
import { LayoutGroupOptions } from './formCheckboxGroup.models';

type WrapperProps = {
  layout?: LayoutGroupOptions;
};

export const Wrapper = styled(FormFieldWrapper)<WrapperProps>`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  ${({ layout }) => {
    switch (layout) {
      case 'column':
        return css`
          flex-direction: column;
        `;
      case 'row':
        return css`
          flex-direction: row;
        `;
    }
  }}
`;

type StyledFormCheckboxProps = {
  layout?: LayoutGroupOptions;
};

export const StyledFormCheckbox = styled(FormCheckbox)<StyledFormCheckboxProps>`
  ${({ layout }) =>
    layout === 'row' &&
    css`
      width: 50%;
    `};

  flex-wrap: wrap;
`;
