import {
  canRenewAASubscriptionPermissions,
  canRenewDOCSubscriptionPermissions,
  canRenewKESubscriptionPermissions,
  canRenewSRSubscriptionPermissions,
  canRenewTLSubscriptionPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../models/ProductType';
import { AvailableProductPermissionsType } from '../../models/types/AvailableProductViewsPermissionsType';
import { AASubscriptionRenewPage } from './AASubscriptionRenewPage/AASubscriptionRenewPage';
import { DOCSubscriptionRenewPage } from './DOCSubscriptionRenewPage/DOCSubscriptionRenewPage';
import { KESubscriptionRenewPage } from './KESubscriptionRenewPage/KESubscriptionRenewPage';
import { SRSubscriptionRenewPage } from './SRSubscriptionRenewPage/SRSubscriptionRenewPage';
import { TLSubscriptionRenewPage } from './TLSubscriptionRenewPage/TLSubscriptionRenewPage';

export const RenewSubscriptionPages: Partial<
  Record<ProductType, () => JSX.Element>
> = {
  [ProductType.AirAmbulance]: AASubscriptionRenewPage,
  [ProductType.DutyOfCare]: DOCSubscriptionRenewPage,
  [ProductType.KidnapAndExtortion]: KESubscriptionRenewPage,
  [ProductType.SecurityResponse]: SRSubscriptionRenewPage,
  [ProductType.TravelLicense]: TLSubscriptionRenewPage,
};

export const RenewSubscriptionViewsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canRenewAASubscriptionPermissions,
    KidnapAndExtortion: canRenewKESubscriptionPermissions,
    SecurityResponse: canRenewSRSubscriptionPermissions,
    DutyOfCare: canRenewDOCSubscriptionPermissions,
    TravelLicense: canRenewTLSubscriptionPermissions,
  };
