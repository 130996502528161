import styled from '@emotion/styled';

import FormCheckbox from '../../../../UI/Form/FormCheckbox/FormCheckbox';

export const Header = styled.div`
  margin-bottom: 2rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.color.light1};
`;

export const TransferAllEligibleMembershipsCheckbox = styled(FormCheckbox)`
  padding: 1.5rem 0;
`;
