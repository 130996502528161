import { FieldRenderProps, useField } from 'react-final-form';

import { ISubscriptionSummaryModel } from '../../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { ProductType } from '../../../../../models/ProductType';
import { SubscriptionSummaryItemFields } from '../../../../SummaryContainer/SummaryItem/summaryItem.models';

type UseSubscriptionSummaryItemProps = {
  name: string;
  data: ISubscriptionSummaryModel[];
  index?: number;
};

export const useSubscriptionsSummaryItem = ({
  data,
  name,
  index,
}: UseSubscriptionSummaryItemProps) => {
  const {
    input: { value: subscriptionValues },
  }: FieldRenderProps<{ productType: ProductType }> = useField(name);

  const currentData = data[index];

  const subTotalValue = currentData ? currentData.amount : 0;

  const isPriceOverridden =
    !!subscriptionValues[SubscriptionSummaryItemFields.OverwriteAmount];

  return {
    subTotalValue,
    isPriceOverridden,
    currentData,
    subscriptionValues,
  };
};
