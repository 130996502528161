import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  DetailsWrapper,
  StyledDeleteButton,
  StyledLeftContainer,
  StyledRightContainer,
} from '../../Common/memberOverviewMembers.styles';
import {
  canEditSRMembershipsOptionsPermissions,
  canRemoveSRMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { AirAmbulanceMembershipOptions } from '../../../../models/enums/AirAmbulanceMembershipOptions';
import { ProductType } from '../../../../models/ProductType';
import { srOverviewTablePermissions } from '../Common/srMembershipOverview.const';
import DeleteSRMembershipModal from '../Modals/DeleteSRMembershipModal/DeleteSRMembershipModal';
import { useSRMembershipDetails } from '../Common/srMembershipOverview.hooks';
import EditSRMembershipOptionsModal from '../Modals/EditSRMembershipOptionsModal/EditSRMembershipOptionsModal';

interface ISRMembershipExpiredDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const SRMembershipExpiredDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: ISRMembershipExpiredDetailsProps) => {
  const { t } = useTranslation();
  const {
    dataLoading,
    membershipDetails,
    changeRenewFlagHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useSRMembershipDetails(membershipId, productType);

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }

  return (
    <>
      <EditSRMembershipOptionsModal id={membershipId} />
      <DetailsWrapper>
        <DeleteSRMembershipModal id={membershipId} />
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            isExpiredMembershipsTable
            onRowClick={onRowClickHandler}
            permissions={srOverviewTablePermissions}
          />
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditSRMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: AirAmbulanceMembershipOptions.SendWelcomeEmail },
              { value: AirAmbulanceMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            canBeRenewed={false}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveSRMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default SRMembershipExpiredDetails;
