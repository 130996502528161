/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import {
  CanDeleteOrganizationContractPermissions,
  CanEditOrganizationContractPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';

import { IContractListItem } from '../../../../../models/interfaces/ContractListItem';
import { OrganizationContractStatus } from '../../../../../models/OrganizationContractStatus';
import { CustomTablePermissions } from '../../../../../models/types/CustomTablePermissions';
import {
  DeleteRowAction,
  EditRowAction,
} from '../../../../../styledComponents/MaterialTable/CellActionsRenderers/CellActionsRenderers';
import {
  NameRenderer,
  CurrencyRenderer,
  DateRenderer,
  EnumRenderer,
  ProductsSubscriptionInfoRenderer,
} from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { StatusRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/StatusRenderer/StatusRenderer';
import TableCell from '../../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';
import MaterialTable from '../../../../../styledComponents/MaterialTable/MaterialTable';
import { PageSection } from '../../../../../styledComponents/Page/Page';
import { ExpiredDateRenderer } from './ColumnsRenderers/ExpirationDateRenderer/ExpirationDateRenderer';
import { canViewActionColumn } from './contractsTable.consts';

export interface IContractsTableProps extends CustomTablePermissions {
  onRowEdit?: (row: IContractListItem) => void;
  onRowDelete?: (row: IContractListItem) => void;
  data?: IContractListItem[];
  isLoading?: boolean;
  onRowClick?: (row: IContractListItem) => void;
}

const ContractsTable = ({
  onRowEdit,
  onRowDelete,
  data,
  isLoading,
  onRowClick,
  canViewDetailsPermissions,
}: IContractsTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (key: keyof IContractListItem, options?: ICellOptions) =>
    (rowData: IContractListItem) => {
      switch (key) {
        case 'name':
          return <NameRenderer value={rowData[key]} options={options} />;
        case 'contractType':
          return (
            <EnumRenderer
              value={rowData[key]}
              enumName="contract-type"
              options={options}
            />
          );
        case 'total':
          return <CurrencyRenderer options={options} price={rowData[key]} />;
        case 'startDate':
          return <DateRenderer date={rowData[key]} options={options} />;
        case 'expirationDate':
          return (
            <ExpiredDateRenderer
              date={rowData[key]}
              options={options}
              expirationNotification={rowData.expirationNotification}
              isExpired={rowData.status === OrganizationContractStatus.Expired}
            />
          );
        case 'status':
          return (
            <StatusRenderer
              status={rowData[key]}
              enumName="organization-contract-status"
              options={options}
            />
          );
        case 'productSubscriptionInfo':
          return (
            <ProductsSubscriptionInfoRenderer
              data={rowData[key]}
              options={options}
            />
          );
      }
    };
  const actionsRenderer = (rowData: IContractListItem) => (
    <TableCell alignment="center">
      <EditRowAction
        permissions={CanEditOrganizationContractPermissions}
        data={rowData}
        onClick={onRowEdit}
      />
      {rowData.metadata?.canBeDeleted && (
        <DeleteRowAction
          permissions={CanDeleteOrganizationContractPermissions}
          data={rowData}
          onClick={onRowDelete}
        />
      )}
    </TableCell>
  );

  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.contract-name'),
            field: 'name',
            cellRenderer: cellRenderer('name', { textBold: true }),
            sortable: true,
          },
          {
            title: t('grid-columns.contract-type'),
            field: 'contractType',
            cellRenderer: cellRenderer('contractType'),
            sortable: true,
          },
          {
            title: t('grid-columns.total'),
            field: 'total',
            alignment: 'left',
            cellRenderer: cellRenderer('total'),
            sortable: true,
          },
          {
            title: t('grid-columns.start-date'),
            field: 'startDate',
            alignment: 'center',
            cellRenderer: cellRenderer('startDate', { alignment: 'center' }),
            sortable: true,
          },
          {
            title: t('grid-columns.expiration-date'),
            field: 'expirationDate',
            alignment: 'center',
            cellRenderer: cellRenderer('expirationDate', {
              alignment: 'center',
            }),
            sortable: true,
          },
          {
            title: t('grid-columns.status'),
            field: 'status',
            cellRenderer: cellRenderer('status'),
            sortable: true,
          },
          {
            title: t('grid-columns.subscription'),
            field: 'subscription',
            alignment: 'right',
            cellRenderer: cellRenderer('productSubscriptionInfo', {
              alignment: 'right',
            }),
          },
          {
            title: t('grid-columns.actions'),
            cellRenderer: actionsRenderer,
            alignment: 'center',
            permissions: canViewActionColumn,
          },
        ]}
        defaultColumnSort="expirationDate"
        isPaginationHidden
        data={data}
        dataLoading={isLoading}
        onRowClick={onRowClick}
        canClickRowPermissions={canViewDetailsPermissions}
      />
    </PageSection>
  );
};

export default ContractsTable;
