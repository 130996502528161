import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IDOCOrganizationSubscriptionPackageOptionsModel } from '../../../../models/interfaces/Subscription/DutyOfCare/OrganizationSubscriptionPackageOptionsModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { DOCSubscriptionPricingFormFields } from './docSubscriptionPricingForm.models';

export const docSubscriptionPricingSchema: AnySchema | any = {
  properties: {},
  dependencies: {
    [DOCSubscriptionPricingFormFields.FirmWide]: {
      if: {
        properties: {
          [DOCSubscriptionPricingFormFields.FirmWide]: {
            enum: [false],
          },
        },
      },
      then: {
        required: [DOCSubscriptionPricingFormFields.SeatsAmount],
      },
    },
  },
  required: [
    DOCSubscriptionPricingFormFields.PackageType,
    DOCSubscriptionPricingFormFields.TotalPrice,
  ],
  type: 'object',
};

export const validateDOCSubscriptionPricingForm = (
  data: IDOCOrganizationSubscriptionPackageOptionsModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, docSubscriptionPricingSchema);
