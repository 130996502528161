import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { IMemberListItem } from '../../../models/MemberListItem';
import { IPerson } from '../../../models/Person';
import { RoutePath } from '../../../models/enums/RoutePath';
import {
  clearAAMembers,
  updateAAMembersList,
} from '../../../store/redux-slices/membership/airAmbulance';
import { pageListAAMembersSelector } from '../../../store/redux-slices/membership/airAmbulance/selectors';
import { updateModalState } from '../../../store/redux-slices/modals';
import { MEMBERS_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { EDIT_MEMBER_MODAL_NAME } from '../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { textContainsWords } from '../../../utils/textHelpers';
import { RequiredQueryParams } from './membersAirAmbulance.models';
import { clearAAMembershipPlansPrices } from '../../../store/redux-slices/pricing/airAmbulance';
import { aaLoadStandardPricing } from '../../../store/saga-slices/pricing/airAmbulance';
import { loadAAMembers } from '../../../store/saga-slices/membership/airAmbulance';
import { ILoadAAMembersPayload } from '../../../store/saga-slices/membership/airAmbulance/models';
import { MemberOverviewPageParamsType } from '../../../models/types/RoutePath';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { aaMembershipPlansPricesSelector } from '../../../store/redux-slices/pricing/airAmbulance/selectors';

export const useMembersAirAmbulance = () => {
  const LoadMembers = useActions(loadAAMembers);
  const ClearMembers = useActions(clearAAMembers);
  const location = useLocation();
  const navigate = useNavigateSearch();
  const pageListMembers = useSelector(pageListAAMembersSelector);
  const aaMembershipPricing = useSelector(aaMembershipPlansPricesSelector);

  const membersLoading = useProcessing(MEMBERS_LOADING_PROCESSING);

  const LoadStandardPricing = useActions(aaLoadStandardPricing);
  const ClearStandardPricing = useActions(clearAAMembershipPlansPrices);

  const UpdateModalState = useActions(updateModalState);
  const UpdateMembersList = useActions(updateAAMembersList);

  useEffect(() => {
    LoadStandardPricing();
  }, [LoadStandardPricing]);

  const loadMembersHandler = useCallback(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadAAMembersPayload = {
      params,
    };

    LoadMembers(payload);
  }, [LoadMembers, location.search]);

  useEffect(() => loadMembersHandler(), [loadMembersHandler]);

  useEffect(
    () => () => {
      ClearMembers();
      ClearStandardPricing();
    },
    [ClearMembers, ClearStandardPricing],
  );
  useEffect(() => () => ClearMembers(), [ClearMembers]);

  const onRowClickHandler = useCallback(
    (row: IMemberListItem) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, { params });
    },
    [navigate],
  );

  const rowEditHandler = useCallback(
    (id: string) => {
      UpdateModalState([EDIT_MEMBER_MODAL_NAME, id]);
    },
    [UpdateModalState],
  );

  const onMemberEditedHandler = useCallback(
    (person: IPerson) => {
      UpdateMembersList(person);
    },
    [UpdateMembersList],
  );

  return {
    membersLoading,
    pageListMembers,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    aaMembershipPricing,
  };
};
