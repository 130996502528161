import styled from '@emotion/styled/macro';

import { StandardText } from '../../../Typography/Typography';

export const Wrapper = styled.div`
  display: flex;
`;

export const StyledStandardText = styled(StandardText)`
  color: ${({ theme }) => theme.color.dark2};
`;
