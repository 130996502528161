/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { ShortcutProductType } from '../../../models/enums/ShortcutProductType';

import { IPageListModel } from '../../../models/PageListModel';
import { IPromoCodeItem } from '../../../models/PromoCodeItem';
import { CustomTablePermissions } from '../../../models/types/CustomTablePermissions';
import {
  NameRenderer,
  PromoCodeStatusRenderer,
  DateRenderer,
  ProductsRenderer,
} from '../../MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../MaterialTable/CellRenderers/cellRenderers.models';
import MaterialTable from '../../MaterialTable/MaterialTable';
import { PageSection } from '../../Page/Page';
import { canViewActionColumn } from './promoCodesTable.const';
import { useTableActions } from './promoCodesTable.hooks';
import { PromoCodeNameRenderer } from './TableRenderers/PromoCodesCellRenderers';

export interface IPromoCodesTableProps extends CustomTablePermissions {
  onRowEdit?: (memberId: string) => void;
  data?: IPageListModel<IPromoCodeItem>;
  isLoading?: boolean;
  onRowClick?: (row: IPromoCodeItem) => void;
  onCellClick?: (row: IPromoCodeItem, key: keyof IPromoCodeItem) => void;
}

const PromoCodesTable = ({
  data,
  isLoading,
  onRowClick,
  canViewDetailsPermissions,
  onCellClick,
}: IPromoCodesTableProps) => {
  const { t } = useTranslation();

  const { actionCellRenderer } = useTableActions();

  const cellRenderer =
    (key: keyof IPromoCodeItem, options?: ICellOptions) =>
    (rowData: IPromoCodeItem) => {
      switch (key) {
        case 'name':
          return (
            <PromoCodeNameRenderer
              value={rowData[key]}
              promoCodeType={rowData.type}
            />
          );
        case 'ownerName':
          return <NameRenderer value={rowData[key]} options={options} />;
        case 'validTo':
          return <DateRenderer date={rowData[key]} options={options} />;

        case 'products':
          return (
            <ProductsRenderer
              data={rowData[key] as ShortcutProductType[]}
              options={options}
            />
          );
        case 'status':
          return (
            <PromoCodeStatusRenderer status={rowData[key]} options={options} />
          );
      }
    };

  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.name'),
            field: 'name',
            cellRenderer: cellRenderer('name', { textBold: true }),
            sortable: true,
          },
          {
            title: t('grid-columns.owner'),
            field: 'ownerName',
            cellRenderer: cellRenderer('ownerName'),
            onClick: onCellClick,
          },
          {
            title: t('grid-columns.valid-to'),
            field: 'validTo',
            alignment: 'left',
            sortable: true,
            cellRenderer: cellRenderer('validTo'),
          },
          {
            title: t('grid-columns.used'),
            field: 'used',
            sortable: true,
          },
          {
            title: t('grid-columns.status'),
            field: 'status',
            alignment: 'center',
            cellRenderer: cellRenderer('status', { alignment: 'center' }),
          },
          {
            title: t('grid-columns.products'),
            field: 'products',
            alignment: 'right',
            cellRenderer: cellRenderer('products', { alignment: 'right' }),
          },
          {
            cellRenderer: actionCellRenderer,
            alignment: 'center',
            permissions: canViewActionColumn,
          },
        ]}
        defaultColumnSort="name"
        data={(data && data.items) || []}
        dataLoading={isLoading}
        itemsCount={(data && data.totalCount) || 0}
        onRowClick={onRowClick}
        canClickRowPermissions={canViewDetailsPermissions}
      />
    </PageSection>
  );
};

export default PromoCodesTable;
