import styled from '@emotion/styled/macro';

import { SmallBoldSectionTitle } from '../../../../../styledComponents/Typography/Typography';

export const Wrapper = styled.div`
  width: 24.188rem;
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled(SmallBoldSectionTitle)`
  margin-bottom: 1rem;
`;
