import styled from '@emotion/styled/macro';

import Button from '../../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  margin-top: 1.25rem;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsContent = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 3.875rem;
`;

export const AddCreditCardButton = styled(Button)`
  margin-left: 1.75rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;
  padding-bottom: 0.6rem;
`;
