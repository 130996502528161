import { forwardRef } from 'react';

import { IMainTooltipProps } from './mainTooltip.models';
import { StyledTooltip } from './mainTooltip.styles';

const MainTooltip = forwardRef(
  (
    {
      children,
      className,
      isShowing = true,
      placement = 'top',
      arrow = true,
      disabled,
      ...props
    }: IMainTooltipProps,
    ref,
  ) => {
    if (!isShowing || !props.title) {
      return children;
    }

    return (
      <StyledTooltip
        ref={ref}
        className={className}
        placement={placement}
        arrow={arrow}
        disableFocusListener={disabled}
        disableHoverListener={disabled}
        disableTouchListener={disabled}
        disableInteractive={disabled}
        {...props}
      >
        <span>{children}</span>
      </StyledTooltip>
    );
  },
);

export default MainTooltip;
