import { TextFieldProps } from '@mui/material/TextField';

import { CustomField } from '../../../../models/types/CustomField';
import { CustomFieldLabel } from '../../../../models/types/CustomFieldLabel';
import { FieldLabel, FieldWrapper } from '../../Field/Field';
import { useDatePickerInput } from './datePickerInput.hooks';
import { StyledTextField } from './datePickerInput.styles';

type DatePickerInputProps = TextFieldProps &
  CustomFieldLabel &
  CustomField & {
    isError?: boolean;
  };

const DatePickerInput = ({
  labelAnimation = true,
  isLabelHidden,
  placeholder,
  disabled,
  readOnly,
  label,
  isError,
  ...props
}: DatePickerInputProps) => {
  const { isFocused, onFocusInputHandler } = useDatePickerInput();

  return (
    <FieldWrapper>
      <StyledTextField
        {...props}
        disabled={disabled || readOnly}
        type="date"
        $isError={isError}
        $isFocused={isFocused}
        inputProps={{
          ...props.inputProps,
          onFocus: () => onFocusInputHandler(true),
          onBlur: () => onFocusInputHandler(false),
          placeholder: labelAnimation
            ? (isFocused && props.inputProps.placeholder) || ''
            : props.inputProps.placeholder,
        }}
      />
      {!isLabelHidden && (
        <FieldLabel
          disabled={disabled}
          labelActive={!labelAnimation || !!props.inputProps.value || isFocused}
          inputLabelOffset={2}
        >
          {label}
        </FieldLabel>
      )}
    </FieldWrapper>
  );
};

export default DatePickerInput;
