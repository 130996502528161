/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { MouseEvent } from 'react';

import { PageSection } from '../../../Page/Page';

import { ReactComponent as PenIconSvg } from '../../../../assets/icons/pen-icon-secondary.svg';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { TableCellAction } from '../../../MaterialTable/CellRenderers/cellRenderers.styles';
import MaterialTable from '../../../MaterialTable/MaterialTable';
import {
  DateRenderer,
  ProductsRenderer,
  RenewStatusRenderer,
} from '../../../MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../MaterialTable/CellRenderers/cellRenderers.models';
import { CustomTablePermissions } from '../../../../models/types/CustomTablePermissions';
import TableCell from '../../../MaterialTable/CellRenderers/TableCell/TableCell';

export interface IKEMembersTableProps extends CustomTablePermissions {
  onRowEdit?: (memberId: string) => void;
  data?: IPageListModel<IMemberListItem>;
  isLoading?: boolean;
  onRowClick?: (row: IMemberListItem) => void;
}

const KEMembersTable = ({
  onRowEdit,
  data,
  isLoading,
  onRowClick,
  canViewDetailsPermissions,
  canEditPermissions,
}: IKEMembersTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (key: keyof IMemberListItem, options?: ICellOptions) =>
    (rowData: IMemberListItem) => {
      switch (key) {
        case 'dateOfBirth':
        case 'expirationDate':
          return <DateRenderer date={rowData[key]} options={options} />;

        case 'productsAbbreviation':
          return <ProductsRenderer data={rowData[key]} options={options} />;
          return null;
        case 'renew':
          return (
            <RenewStatusRenderer status={rowData[key]} options={options} />
          );
      }
    };

  const actionsRenderer = (rowData: IMemberListItem) => (
    <TableCell>
      <TableCellAction permissions={canEditPermissions}>
        <PenIconSvg
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
            onRowEdit && onRowEdit(rowData.personId);
          }}
        />
      </TableCellAction>
    </TableCell>
  );

  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.member-name'),
            field: 'name',
            sortable: true,
          },
          {
            title: t('grid-columns.date-of-birth'),
            field: 'dateOfBirth',
            cellRenderer: cellRenderer('dateOfBirth', {
              alignment: 'center',
            }),
            alignment: 'center',
          },
          {
            title: t('grid-columns.products'),
            field: 'products',
            cellRenderer: cellRenderer('productsAbbreviation', {
              alignment: 'right',
            }),
            alignment: 'right',
          },
          {
            title: t('grid-columns.membership-plan'),
            field: 'activePlan',
            alignment: 'left',
          },
          {
            title: t('grid-columns.member-type'),
            field: 'memberType',
            propertySelector: (value: IMemberListItem) =>
              t(`enums.member-type.${value.memberType}`),
          },
          {
            title: t('grid-columns.expiration-date'),
            field: 'expirationDate',
            cellRenderer: cellRenderer('expirationDate'),
            sortable: true,
          },
          {
            title: t('grid-columns.renew'),
            field: 'renew',
            cellRenderer: cellRenderer('renew'),
          },
          {
            cellRenderer: actionsRenderer,
            alignment: 'center',
            permissions: canViewDetailsPermissions,
          },
        ]}
        defaultColumnSort="name"
        data={(data && data.items) || []}
        dataLoading={isLoading}
        itemsCount={(data && data.totalCount) || 0}
        onRowClick={onRowClick}
        canClickRowPermissions={canViewDetailsPermissions}
      />
    </PageSection>
  );
};

export default KEMembersTable;
