/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import { RoutePath } from '../../../models/enums/RoutePath';
import { ProductType } from '../../../models/ProductType';
import { getRegexForEnum, GuidRegex } from '../../../utils/regexHelpers';

export const blackListPaths = [
  RoutePath.AddMember,
  RoutePath.AddMemberWizard,
  `${RoutePath.CorporateClientsPage}/${RoutePath.ProductAirAmbulancePage}`,
  RoutePath.Reports,
];

export const mainPagesPaths = [
  `add-client`,
  `corporate-clients\/general`,
  `members\/air-ambulance`,
  `members\/overview\/${GuidRegex}`,
  `corporate-clients\/air-ambulance`,
  `add-member\/wizard`,
  `promo-codes\/${GuidRegex}`,
  'promo-codes\/add-promo-code',
  'promo-codes',
  `${GuidRegex}\/add-subscription`,
  `${GuidRegex}\/extend-subscription\/${getRegexForEnum(
    ProductType,
  )}\/${GuidRegex}`,
  `client-overview\/${GuidRegex}\/subscriptions\/overview\/${getRegexForEnum(
    ProductType,
  )}\/${GuidRegex}\/subscription-renew`,
  `client-overview\/${GuidRegex}\/subscriptions\/overview\/${getRegexForEnum(
    ProductType,
  )}\/${GuidRegex}\/edit-subscription-renew`,
  `client-overview\/${GuidRegex}\/subscriptions\/overview\/${getRegexForEnum(
    ProductType,
  )}\/${GuidRegex}`,
  `${GuidRegex}\/edit-subscription\/${getRegexForEnum(ProductType)}\/${GuidRegex}`,
  `client-overview\/${GuidRegex}`,
  'reports',
];
