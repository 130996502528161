import { useTranslation } from 'react-i18next';

import {
  SubscriptionsContainer,
  Title,
  TitleContainer,
  Wrapper,
} from './memberSubscriptionsList.styles';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg';
import SubscriptionItem from './SubscriptionItem/SubscriptionItem';
import { useMemberSubscriptionsList } from './memberSubscriptionsList.hooks';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import Spinner from '../../../../styledComponents/Spinner/Spinner';

type MemberSubscriptionsListProps = CustomComponent & {
  personId: string;
};

const MemberSubscriptionsList = ({
  personId,
}: MemberSubscriptionsListProps) => {
  const { t } = useTranslation();
  const { memberships, dataLoading } = useMemberSubscriptionsList({ personId });

  if (!memberships.length) {
    return null;
  }

  if (dataLoading) {
    return (
      <Wrapper>
        <Spinner size="20%" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TitleContainer>
        <WarningIcon />
        <Title>{t('phrase.member-has-active-subscriptions')}</Title>
      </TitleContainer>
      <SubscriptionsContainer>
        {memberships?.map((membership) => (
          <SubscriptionItem
            key={membership.membershipId}
            membership={membership}
          />
        ))}
      </SubscriptionsContainer>
    </Wrapper>
  );
};

export default MemberSubscriptionsList;
