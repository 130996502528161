import { ReactNode } from 'react';
import { ClassNames } from '@emotion/react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-modal-icon.svg';

import {
  ModalContainer,
  modalOverlayClass,
  StyledButton,
  StyledModal,
} from './modal.styles';
import { useModal } from './modal.hooks';
import Spinner from '../Spinner/Spinner';

type Props = {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  name: string;
  onModalShow?: () => void;
  overlayClassName?: string;
  onModalClose?: () => void;
  children: ReactNode;
};

export const Modal = ({
  children,
  className,
  disabled,
  isLoading,
  name,
  onModalShow,
  overlayClassName,
  onModalClose,
}: Props) => {
  const { closeModalHandler, modalsState } = useModal({ name, onModalClose });
  return (
    <ClassNames>
      {({ css }) => (
        <StyledModal
          ariaHideApp={false}
          className={className}
          isOpen={modalsState}
          onAfterOpen={() => {
            onModalShow && onModalShow();
          }}
          overlayClassName={css([modalOverlayClass, overlayClassName])}
          preventScroll
        >
          <Spinner isVisible={isLoading} size="50%">
            <ModalContainer>{children}</ModalContainer>
            <StyledButton
              onClick={closeModalHandler}
              disabled={disabled}
              styleType="icon"
            >
              <CloseIcon />
            </StyledButton>
          </Spinner>
        </StyledModal>
      )}
    </ClassNames>
  );
};
