import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import {
  FormMemberDetailsFields,
  FormMemberDetailsFieldsType,
} from './formMemberDetails.models';

export const memberDetailsSchema = (
  fields: Record<FormMemberDetailsFieldsType, string> = FormMemberDetailsFields,
): AnySchema | any => ({
  type: 'object',
  properties: {
    [fields.FirstName]: {
      type: 'string',
    },
    [fields.LastName]: {
      type: 'string',
    },
    [fields.EmailAddress]: {
      type: 'string',
      format: 'email',
    },
    [fields.PhoneNumber]: {
      type: 'string',
      format: 'phone',
    },
    [fields.Gender]: {
      type: 'string',
    },
  },
  dependencies: {
    isSecondaryMember: {
      if: { properties: { isSecondaryMember: { enum: [false] } } },
      then: { required: [fields.EmailAddress] },
    },
  },
  required: [fields.DateOfBirth, fields.FirstName, fields.LastName],
});

export const validateMemberForm = (
  data: any,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, memberDetailsSchema());
