import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { InputHTMLAttributes } from 'react';

import { Alignment } from '../../../models/Alignment';
import { CustomField } from '../../../models/types/CustomField';
import Button from '../Button/Button';
import { FieldWrapper } from '../Field/Field';
import CharsNumber from './CharsNumber/CharsNumber';

export interface ITextareaProps
  extends InputHTMLAttributes<HTMLTextAreaElement>,
    CustomField {
  name?: string;
  placeholder?: string;
  id?: string;
  alignment?: Alignment;
}

export const BaseTextarea = styled.textarea<ITextareaProps>`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  padding: 1rem;
  border: 0.063rem solid ${({ theme }) => theme.color.input_border};
  border-radius: 0.25rem;
  box-sizing: border-box;
  z-index: 1;
  background: none;
  height: 100%;
  width: 100%;
  opacity: 1;
  -webkit-text-fill-color: ${({ theme }) => theme.color.black};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.lighter_blue};
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
      -webkit-text-fill-color: ${theme.color.dark2};
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      padding: 0;
    `}

    ${({ alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}
`;

export const Wrapper = styled.div`
  ${FieldWrapper} {
    height: auto;
  }
`;

export const ClearButton = styled(Button)`
  position: absolute;
  right: 1.375rem;
  top: 1.375rem;
  z-index: 2;
`;

export const StyledCharsNumber = styled(CharsNumber)`
  margin-top: 0.5rem;
  justify-content: flex-end;
`;
