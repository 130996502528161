import { GeneralPersonStatus } from '../../../../models/enums/GeneralPersonStatus';
import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';
import {
  getOptionsFromEnum,
  getOptionsFromEnumValues,
} from '../../../../utils/getOptionsFromEnum';
import { SelectOption } from '../../../UI/MainSelect/mainSelect.types';

import { StyledStatus } from './membersGeneralListFilters.styles';

export const productOptions = getOptionsFromEnumValues(
  Object.values(ProductTypeShortcut),
  'shortcut-to-product-type',
);

export const statusOptions = getOptionsFromEnum(
  GeneralPersonStatus,
  'general-person-status',
);

export const formatOptionLabel = ({ value }: SelectOption) => (
  <StyledStatus enumName="general-person-status" status={value} />
);
