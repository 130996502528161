import { useTranslation } from 'react-i18next';

import { IPersonMembershipItem } from '../../../../models/PersonMembershipItem';
import MembershipRelation from '../../../../styledComponents/MembershipRelation/MembershipRelation';
import { ProductTypeShortcut } from '../../../../models/enums/ProductTypeShortcut';
import {
  StyledOverviewProperty,
  SummaryWrapper,
} from '../../Common/MembershipSummary/membershipSummary.styles';
import Price from '../../../../styledComponents/UI/Price/Price';

interface IKEMembershipActiveSummaryProps {
  data: IPersonMembershipItem;
}

const KEMembershipActiveSummary = ({
  data,
}: IKEMembershipActiveSummaryProps) => {
  const { t } = useTranslation();
  return (
    <SummaryWrapper>
      <StyledOverviewProperty
        title={t('properties.product')}
        value={ProductTypeShortcut[data.productSubscription]}
        bold
        width="6rem"
      />
      <StyledOverviewProperty
        title={t('properties.membership-plan')}
        value={data.membershipPlan}
        width="12rem"
      />
      <StyledOverviewProperty
        title={t('properties.membership-relation')}
        width="12rem"
      >
        <MembershipRelation
          organizationName={data.organizationName}
          organizationId={data.organizationId}
          isRetail={data.isRetail}
        />
      </StyledOverviewProperty>
      <StyledOverviewProperty title={t('properties.price')} width="6rem">
        <Price price={data.displayPrice} />
      </StyledOverviewProperty>
    </SummaryWrapper>
  );
};

export default KEMembershipActiveSummary;
