import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../../../styledComponents/ConfirmModal/ConfirmModal';
import {
  Description,
  Name,
} from '../../../Modals/Common/memberOverviewModals.styles';
import { DELETE_KE_FAMILY_MEMBER_MODAL_NAME } from './deleteKEFamilyMemberModal.const';
import { useDeleteKEFamilyMemberModal } from './deleteKEFamilyMemberModal.hooks';

type DeleteKEFamilyMemberModalProps = {
  onFamilyMemberRemoved?: () => void;
  id?: string;
};

const DeleteKEFamilyMemberModal = ({
  onFamilyMemberRemoved,
  id,
}: DeleteKEFamilyMemberModalProps) => {
  const { t } = useTranslation();
  const { onBackHandler, name, isDeleting, deleteSecondaryMemberHandler } =
    useDeleteKEFamilyMemberModal({ onFamilyMemberRemoved, id });
  return (
    <ConfirmModal
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: isDeleting,
        },
        {
          action: deleteSecondaryMemberHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: isDeleting,
        },
      ]}
      name={DELETE_KE_FAMILY_MEMBER_MODAL_NAME + id}
      disabled={isDeleting}
      title={t('headers.remove-family-member')}
    >
      <Description>{t('phrase.confirm-remove-family-member')}</Description>
      <Name>{name}</Name>
    </ConfirmModal>
  );
};

export default DeleteKEFamilyMemberModal;
