import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { loadAASubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/airAmbulance';
import { ILoadAASubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';

type AARenewSubscriptionSummaryLoaderProps = CustomComponent & {
  pricing: IOrganizationSubscriptionPricingModel;
};

export const AARenewSubscriptionSummaryLoader = ({
  pricing,
}: AARenewSubscriptionSummaryLoaderProps) => {
  const { organizationId } = useParams();

  const LoadSubscriptionSummary = useActions(loadAASubscriptionSummary);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!pricing) {
      return;
    }

    const payload: ILoadAASubscriptionSummary = {
      data: pricing.membershipPlans,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, debounce, organizationId, pricing]);

  return <></>;
};
