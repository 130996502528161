import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { TLPricingTierDefinition } from '../../../../models/interfaces/Subscription/TravelLicense/TLPricingDefinition';

export const getTLPricingTiersQuery = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<TLPricingTierDefinition[]>> =>
  axios.get(`api/PricingTier`, {
    baseURL: config.travelLicenseApi.baseUrl,
    signal: cancellationToken,
  });
