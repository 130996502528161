import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';

import { useReplaceCreditCardModalForm } from './replaceCreditCardModalForm.hooks';
import { withStripeElements } from '../../../../../styledComponents/Stripe/withStripeElements';
import CreditCardDetailsForm from '../../../../../styledComponents/Forms/CreditCardDetailsForm/CreditCardDetailsForm';

type ReplaceCreditCardModalFormProps = {
  onReplacedCreditCard?: () => void;
};

const ReplaceCreditCardModalForm = ({
  onReplacedCreditCard,
}: ReplaceCreditCardModalFormProps) => {
  const { t } = useTranslation();
  const { onCancelHandler, submitHandler, creditCardReplacing } =
    useReplaceCreditCardModalForm({
      onReplacedCreditCard,
    });

  return (
    <Form keepDirtyOnReinitialize onSubmit={submitHandler}>
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          onCancel={onCancelHandler}
          title={t('headers.replace-credit-card')}
          isProcessing={creditCardReplacing}
        >
          <CreditCardDetailsForm isModal disabled={creditCardReplacing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withStripeElements(ReplaceCreditCardModalForm);
