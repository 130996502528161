import { CustomField } from '../../../models/types/CustomField';
import { CustomFieldLabel } from '../../../models/types/CustomFieldLabel';
import BaseNumberInput, {
  IBaseNumberInputProps,
} from '../BaseNumberInput/BaseNumberInput';
import { FieldLabel, FieldWrapper } from '../Field/Field';

export interface INumberInputProps
  extends IBaseNumberInputProps,
    CustomField,
    CustomFieldLabel {
  onChangeValue?: (value: string) => void;
}

const NumberInput = ({
  label,
  labelAnimation,
  isLabelHidden,
  className,
  disabled,
  ...props
}: INumberInputProps) => (
  <FieldWrapper className={className}>
    <BaseNumberInput disabled={disabled} {...props} />
    {!isLabelHidden && (
      <FieldLabel disabled={disabled} autoLabelAnimation={labelAnimation}>
        {label}
      </FieldLabel>
    )}
  </FieldWrapper>
);

export default NumberInput;
