import {
  canChangeDOCToBeRenewedPermissions,
  canManageDOCMembershipsPermissions,
  canRemoveDOCSecondaryMemberPermissions,
  canResendDOCWelcomeEmailPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { MemberOverviewMemberTablePermissions } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.models';

export const canViewActionColumnPermissions = [
  ...canManageDOCMembershipsPermissions,
  ...canResendDOCWelcomeEmailPermissions,
];

export const docOverviewTablePermissions: MemberOverviewMemberTablePermissions =
  {
    canViewActionColumnPermissions,
    canChangeToBeRenewedPermissions: canChangeDOCToBeRenewedPermissions,
    canRemoveSecondaryMemberPermissions: canRemoveDOCSecondaryMemberPermissions,
    canResendWelcomeEmailPermissions: canResendDOCWelcomeEmailPermissions,
  };
