import { isPropertyEmpty } from '../../../../utils/propertyHelpers';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';
import { NumberWrapper } from './numberRenderer.styles';

export type NumberRendererProps = ICellRenderer & {
  number: number;
};

export const NumberRenderer = ({ number, options }: NumberRendererProps) => {
  let currValue = null;
  if (!isPropertyEmpty(number)) {
    const currentChar = number > 0 ? '+' : number < 0 ? '-' : '';
    currValue = `${currentChar} ${number}`;
  }

  return (
    <TableCell>
      <NumberWrapper bold={options?.textBold}>{currValue || '-'}</NumberWrapper>
    </TableCell>
  );
};
