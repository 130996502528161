import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { MainSelect } from '../MainSelect/MainSelect';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 18rem;
  height: 3rem;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  height: 100%;
  ${({ theme }) => css`
    box-shadow: inset ${theme.color.input_border} 0 0.045rem 0 0,
      inset 0 -0.045rem 0 0 ${theme.color.input_border},
      inset 0.045rem 0 0 0 ${theme.color.input_border};
  `}

  border-radius: 0.25rem 0 0 0.25rem !important;
  color: ${({ theme }) => theme.color.dark2};
`;

export const StyledMainSelect = styled(MainSelect)`
  div {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .main-select {
    &__control {
      .option__option-container {
        font-weight: bold;
      }
    }

    &__dropdown-indicator {
      color: ${({ theme }) => theme.color.secondary1} !important;
    }
  }
`;
