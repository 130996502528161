import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import {
  useFormErrorsActions,
  useApiResponseError,
} from '../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../hooks/useProcessing';
import { ICreatePromoCodePageModel } from '../../../models/CreatePromoCodePageModel';
import { RoutePath } from '../../../models/enums/RoutePath';
import {
  AA_PRICING_LOADING_PROCESSING,
  PROMO_CODE_ADDING_PROCESSING,
  PROMO_CODE_SUGGESTION_LOAD_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { addPromoCode } from '../../../store/saga-slices/promoCodes';
import { IAddPromoCodePayload } from '../../../store/saga-slices/promoCodes/models';
import { validatePromoCodeForm } from '../../../utils/validations/AddPromoCode/validatePromoCode';
import { promoCodeCreateInitSelector } from '../../../store/redux-slices/promoCodes/selectors';

export const useAddPromoCodeForm = () => {
  const navigate = useNavigateSearch();
  const alert = useAlert();
  const { t } = useTranslation();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError({ withAlert: true });

  const promoCodeSuggestion = useSelector(promoCodeCreateInitSelector);

  const promoCodeAdding = useProcessing(PROMO_CODE_ADDING_PROCESSING);
  const dataLoading = useProcessing([
    AA_PRICING_LOADING_PROCESSING,
    PROMO_CODE_SUGGESTION_LOAD_PROCESSING,
  ]);

  const AddPromoCode = useActions(addPromoCode);

  const handleSubmit = useCallback(
    (values: ICreatePromoCodePageModel) => {
      const payload: IAddPromoCodePayload = {
        data: values,
        success: (promoCodeId: string) => {
          navigate(RoutePath.PromoCodeOverview, { params: { promoCodeId } });
          alert.success(
            <div>
              <b>{values.value} </b>
              {t('phrase.promo-code-added')}
            </div>,
          );
        },
        error: (err: any) => {
          handleResponseError(err, values);
        },
      };
      AddPromoCode(payload);
    },
    [AddPromoCode, alert, handleResponseError, navigate, t],
  );

  const validateData = useCallback(
    (data: any) => validatePromoCodeForm(data, validateErrors(data)),
    [validateErrors],
  );

  return {
    handleSubmit,
    validateData,
    promoCodeAdding,
    dataLoading,
    promoCodeSuggestion,
  };
};
