import { t } from 'i18next';
import { Field } from 'react-final-form';

import { CommissionType } from '../../../../models/enums/CommissionType';
import { RadioGroupContentItem } from '../../../../styledComponents/UI/RadioGroupContent/radioGroupContent.models';
import { AddPromoCodeCommissionFormFields } from './addPromoCodeCommissionForm.models';
import { StyledFormNumberInputIcon } from './addPromoCodeCommissionForm.styles';

export const CommissionTypeItems: RadioGroupContentItem[] = [
  {
    label: t(`enums.commission-type.${CommissionType.FixedFee}`),
    id: CommissionType.FixedFee,
    contentHiddenIfNoActive: true,
    contentRenderer: ({ disabled }) => (
      <Field
        component={StyledFormNumberInputIcon}
        disabled={disabled}
        iconPosition="start"
        defaultIcon="dollar"
        min={0}
        allowNegative={false}
        decimalScale={2}
        name={AddPromoCodeCommissionFormFields.CommissionValue}
        label={t('fields.amount.label')}
        inputId="commission-value-input"
      />
    ),
  },
  {
    label: t(`enums.commission-type.${CommissionType.PercentageFinalFee}`),
    id: CommissionType.PercentageFinalFee,
    contentHiddenIfNoActive: true,
    contentRenderer: ({ disabled }) => (
      <Field
        component={StyledFormNumberInputIcon}
        disabled={disabled}
        defaultIcon="percentage"
        name={AddPromoCodeCommissionFormFields.CommissionPercentage}
        label={t('fields.amount.label')}
        allowNegative={false}
        decimalScale={0}
        max={100}
        min={0}
        inputId="commission-percentage-input"
      />
    ),
  },
  {
    label: t(`enums.commission-type.${CommissionType.PercentageProfit}`),
    id: CommissionType.PercentageProfit,
    contentHiddenIfNoActive: true,
    contentRenderer: ({ disabled }) => (
      <Field
        component={StyledFormNumberInputIcon}
        disabled={disabled}
        defaultIcon="percentage"
        allowNegative={false}
        name={AddPromoCodeCommissionFormFields.CommissionPercentage}
        label={t('fields.amount.label')}
        decimalScale={0}
        max={100}
        min={0}
        inputId="commission-percentage-input"
      />
    ),
  },
  {
    label: t(`enums.commission-type.${CommissionType.None}`),
    id: CommissionType.None,
  },
];
