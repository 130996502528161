import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { transparentize } from 'polished';

import { Alignment } from '../../../../models/Alignment';

type TableCellType = {
  bold?: boolean;
  alignment?: Alignment;
  minWidth?: boolean;
  isClickable?: boolean;
};

export const TableCellWrapper = styled.div<TableCellType>`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-family: ${({ theme }) => theme.font.gotham};
  gap: 1.25rem;

  ${({ isClickable, theme }) =>
    isClickable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${transparentize(0.8, theme.color.secondary2)};
      }
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: 6.25rem;
    `};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${({ alignment }) => {
    switch (alignment) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
      case 'center':
        return css`
          justify-content: center;
        `;
    }
  }}
`;
