import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { select } from 'typed-redux-saga/macro';
import { putResolve } from 'redux-saga/effects';

import { ICallbackSaga } from '../../../models/ICallbackSaga';
import {
  lastMainPageRouteSelector,
  lastRouteSelector,
} from '../../redux-slices/routesHistory/selectors';
import {
  removeCurrentRouteHistory,
  removeRouteAfterCurrentPath,
} from '../../redux-slices/routesHistory';

type GoBackRouteType = ICallbackSaga & {
  routesCount: number;
};

const routesHistorySlice = createSliceSaga({
  caseSagas: {
    *goBackRoute({
      payload: { success, routesCount = 1 },
    }: PayloadAction<GoBackRouteType>) {
      const lastRoute = yield* select(lastRouteSelector(routesCount));
      yield putResolve(removeCurrentRouteHistory(routesCount));

      success?.(lastRoute);
    },
    *goBackMainPageRoute({
      payload: { success },
    }: PayloadAction<ICallbackSaga>) {
      const lastRoute = yield* select(lastMainPageRouteSelector);
      yield putResolve(removeRouteAfterCurrentPath(lastRoute));

      success?.(lastRoute);
    },
  },
  name: 'routes-history-saga',
  sagaType: SagaType.TakeLatest,
});

export default routesHistorySlice.saga;
export const { goBackRoute, goBackMainPageRoute } = routesHistorySlice.actions;
export const { actions } = routesHistorySlice;
