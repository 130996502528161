import { useSearchParams } from 'react-router-dom';

import { AvailableQueryParams } from '../../../../styledComponents/MaterialTable/materialTable.models';

export const useTablesSort = () => {
  const [searchParams] = useSearchParams();

  const order = searchParams.get(AvailableQueryParams.Order);
  const orderBy = searchParams.get(AvailableQueryParams.OrderBy);

  return { order, orderBy };
};
