import { IStatusProps, Status } from '../../../UI/Status/Status';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';

interface IStatusRenderer extends ICellRenderer, IStatusProps {}

export const StatusRenderer = ({
  status,
  enumName,
  options,
}: IStatusRenderer) => (
  <TableCell bold={options?.textBold} alignment={options?.alignment}>
    <Status status={status} enumName={enumName} />
  </TableCell>
);
