import { Trans, useTranslation } from 'react-i18next';

import MainTooltip from '../UI/MainTooltip/MainTooltip';
import { useMembershipRelation } from './membershipRelation.hooks';

import {
  OrganizationContainer,
  OrganizationName,
  Wrapper,
} from './membershipRelation.styles';

interface IMembershipRelationProps {
  isRetail?: boolean;
  organizationName: string;
  showFullText?: boolean;
  organizationId?: string;
}

const MembershipRelation = ({
  isRetail,
  organizationName,
  showFullText,
  organizationId,
}: IMembershipRelationProps) => {
  const { t } = useTranslation();

  const { onClickHandler } = useMembershipRelation(organizationId);

  if (isRetail && !organizationName) {
    return <>{t('phrase.retail')}</>;
  }
  let textContent = null;

  if (isRetail && organizationName) {
    textContent = (
      <Trans>{t('phrase.retail-name', { name: organizationName })}</Trans>
    );
  }
  if (!isRetail && organizationName) {
    textContent = (
      <OrganizationContainer>
        {t('phrase.organizational-name')}
        <OrganizationName
          showFullText={showFullText}
          onClick={organizationId ? onClickHandler : null}
          isClickable={!!organizationId}
        >
          {organizationName}
        </OrganizationName>
      </OrganizationContainer>
    );
  }

  return (
    <MainTooltip
      arrow
      placement="top"
      isShowing={!showFullText}
      title={textContent}
    >
      <Wrapper showFullText={showFullText}>{textContent}</Wrapper>
    </MainTooltip>
  );
};

export default MembershipRelation;
