import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEditSROrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/EditSROrganizationSubscriptionModel';
import { ISROrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionOverview';
import { ISRSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/SecurityResponse/SRSubscriptionRenewalSuggestionModel';

import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMemberModel } from '../../../../models/IRenewOrganizationSubscriptionMemberModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';

export interface ISRSubscriptionsState {
  currentSubscriptionOverview: ISROrganizationSubscriptionOverview;
  subscriptionDates: ISubscriptionDates;
  currentSubscriptionEditing: IEditSROrganizationSubscriptionModel;
  subscriptionRenewalSuggestion: ISRSubscriptionRenewalSuggestionModel;
  subscriptionRenewalAnalyse: IRenewOrganizationSubscriptionAnalyseModel;
}

const initialState: ISRSubscriptionsState = {
  currentSubscriptionOverview: null,
  subscriptionDates: null,
  currentSubscriptionEditing: null,
  subscriptionRenewalSuggestion: null,
  subscriptionRenewalAnalyse: {
    memberships: [],
    analysisResultForDate: null,
    totalMemberships: { membershipsCount: 0, withErrors: 0 },
  },
};

const srSubscriptionsSlice = createSlice({
  name: 'srSubscriptions',
  initialState,
  reducers: {
    setSRSubscriptionOverview: (
      state,
      { payload }: PayloadAction<ISROrganizationSubscriptionOverview>,
    ) => {
      const subscription: ISROrganizationSubscriptionOverview = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
      };
      state.currentSubscriptionOverview = subscription;
    },
    setSRSubscriptionDates: (
      state,
      { payload }: PayloadAction<ISubscriptionDates>,
    ) => {
      state.subscriptionDates = payload;
    },
    setSRCurrentEditingSubscription: (
      state,
      { payload }: PayloadAction<IEditSROrganizationSubscriptionModel>,
    ) => {
      state.currentSubscriptionEditing = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        overwrittenAmount: payload.overwrittenAmount?.toString(),
      };
    },
    setSRSubscriptionRenewalSuggestion(
      state,
      { payload }: PayloadAction<ISRSubscriptionRenewalSuggestionModel>,
    ) {
      state.subscriptionRenewalSuggestion = payload;
    },
    setSRSubscriptionRenewalAnalyse(
      state,
      { payload }: PayloadAction<IRenewOrganizationSubscriptionAnalyseModel>,
    ) {
      const mapMembers = (
        members: IRenewOrganizationSubscriptionMemberModel[],
      ) =>
        members.map((member, index) => ({
          ...member,
          dateOfBirth: parseDate(member.dateOfBirth),
        }));

      const subscriptionAnalyse = {
        analysisResultForDate: parseDate(payload.analysisResultForDate),
        memberships: payload.memberships.map((membership) => ({
          ...membership,
          primaryMember: {
            ...membership.primaryMember,
            dateOfBirth: parseDate(membership.primaryMember.dateOfBirth),
          },
          secondaryMembers: mapMembers(membership.secondaryMembers),
        })),
        totalMemberships: payload.memberships.reduce(
          (acc, { primaryMember, secondaryMembers }) => {
            const members = [primaryMember, ...secondaryMembers];
            acc.membershipsCount = acc.membershipsCount + 1;
            const hasErrors =
              members.filter(
                ({ canBeRenewed, toBeRenewed }) => !canBeRenewed && toBeRenewed,
              ).length > 0;
            acc.withErrors = acc.withErrors + (hasErrors ? 1 : 0);
            return acc;
          },
          { membershipsCount: 0, withErrors: 0 },
        ),
      };
      state.subscriptionRenewalAnalyse = subscriptionAnalyse;
    },
    clearSRSubscriptionRenewalAnalyse: (state) => {
      state.subscriptionRenewalAnalyse =
        initialState.subscriptionRenewalAnalyse;
    },
    clearSRSubscriptionOverview: (state) => {
      state.currentSubscriptionOverview =
        initialState.currentSubscriptionOverview;
    },
    clearSRSubscriptionHelperData: (state) => {
      state.subscriptionDates = null;
      state.subscriptionRenewalSuggestion = null;
    },
    clearSRCurrentSubscriptionEditing: (state) => {
      state.currentSubscriptionEditing =
        initialState.currentSubscriptionEditing;
    },
  },
});

export const {
  clearSRSubscriptionOverview,
  setSRSubscriptionOverview,
  clearSRSubscriptionHelperData,
  setSRSubscriptionDates,
  setSRCurrentEditingSubscription,
  clearSRCurrentSubscriptionEditing,
  setSRSubscriptionRenewalSuggestion,
  clearSRSubscriptionRenewalAnalyse,
  setSRSubscriptionRenewalAnalyse,
} = srSubscriptionsSlice.actions;
export default srSubscriptionsSlice.reducer;
export const subscriptionsReducerName = srSubscriptionsSlice.name;
