import { t } from 'i18next';

import { RenewalStatus as RenewalStatusEnum } from '../../../../models/enums/RenewalStatus';
import { ProductType } from '../../../../models/ProductType';
import { getOptionsFromEnum } from '../../../../utils/getOptionsFromEnum';
import { Status } from '../../../../styledComponents/UI/Status/Status';
import { FilterType } from '../../Common/RenewalsFilters/FiltersForm/filterForm.models';
import { FilterDefinition } from '../../Common/RenewalsFilters/renewalFilters.models';
import { FiltersOptions } from '../../Common/RenewalsFilters/FiltersForm/FilterTypes/filtersTypes.models';
import { SelectCategoryFilterOptionsModel } from '../../Common/RenewalsFilters/FiltersForm/FilterTypes/SelectCategoryFilter/selectCategoryFilter.models';
import { SelectFilterOptionsModel } from '../../Common/RenewalsFilters/FiltersForm/FilterTypes/SelectFilter/selectFilter.models';
import { RenewalStaticFilter } from '../../Common/RenewalsFilters/StaticFilters/renewalStaticFilters.models';
import { getGGRolesList } from '../../../../store/saga-slices/roles/api';
import { getEmployeeList } from '../../../../store/saga-slices/employees/api';
import { RenewalTopFilter } from '../../Common/RenewalsFilters/TopFilters/renewalTopFilters.models';

export enum RenewalContractsFields {
  ExpirationDate = 'expirationDate',
  GgPocRole = 'ggPocRole',
  Product = 'product',
  RenewalStatus = 'renewalStatus',
}

export const staticFilters: RenewalStaticFilter[] = [
  RenewalStaticFilter.HideExpired,
];

export const topFilters: RenewalTopFilter[] = [RenewalTopFilter.SearchFilter];

const getRolesOptions = () =>
  getGGRolesList().then((x) =>
    x.data.map((option) => ({
      label: t(`enums.gg-point-of-contact-roles.${option}`),
      value: {
        label: t(`enums.gg-point-of-contact-roles.${option}`),
        value: option,
      },
    })),
  );

const getEmployeeOptions = (role: string) => () =>
  getEmployeeList(role).then((x) =>
    x.data.items.map((option) => ({
      label: `${option.firstName} ${option.lastName}`,
      value: {
        value: option.id,
        label: `${option.firstName} ${option.lastName}`,
      },
    })),
  );

export const fieldsList: FilterDefinition<FiltersOptions>[] = [
  {
    field: RenewalContractsFields.ExpirationDate,
    filterType: FilterType.Date,
  },
  {
    field: RenewalContractsFields.GgPocRole,
    filterType: FilterType.SelectCategory,
    filterOptions: {
      promiseOptions: getRolesOptions,
      categoryPromiseOptions: getEmployeeOptions,
      valueTagLabelPath: 'enums.gg-point-of-contact-roles-shortcut',
    } as SelectCategoryFilterOptionsModel,
  },
  {
    field: RenewalContractsFields.Product,
    filterType: FilterType.Select,
    filterOptions: {
      options: [
        ...getOptionsFromEnum(ProductType, 'productType', [
          ProductType.Unknown,
        ]),
      ],
      valueTagLabelPath: 'enums.product-type-to-shortcut',
      checkAllButton: true,
    } as SelectFilterOptionsModel,
  },
  {
    field: RenewalContractsFields.RenewalStatus,
    filterType: FilterType.Select,
    filterOptions: {
      options: Object.keys(RenewalStatusEnum).map((x) => ({
        label: <Status status={x} enumName="renewal-status" />,
        value: x,
      })),
      valueTagLabelPath: 'enums.renewal-status',
      valueRenderer: (value: string) =>
        t(`enums.renewal-status.${value}`).toString(),
    } as SelectFilterOptionsModel,
  },
];
