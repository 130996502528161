import { useTranslation } from 'react-i18next';

import { ITLOrganizationSubscriptionExtensionSummaryModel } from '../../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionSummaryModel';

import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import SummaryItemContent from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemContent/SummaryItemContent';
import SummaryItemSubtotal from '../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemSubtotal/SummaryItemSubtotal';
import { Wrapper } from './extendTLSubscriptionSummaryContent.styles';

interface IExtendSubscriptionSummaryContentProps extends CustomComponent {
  summaryData?: ITLOrganizationSubscriptionExtensionSummaryModel;
  productType?: ProductType;
  name?: string;
  subtotalValue?: number;
  isPriceOverridden?: boolean;
}

const ExtendTLSubscriptionSummaryContent = ({
  summaryData,
  productType,
  disabled,
  name,
  subtotalValue,
  isPriceOverridden,
}: IExtendSubscriptionSummaryContentProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {summaryData && (
        <SummaryItemContent
          data={summaryData?.pricingTierCapacityChangesModel.map((x) => ({
            amount: x.amount,
            capacity: x.capacityChange,
            name: t('phrase.tl-extension-summary-row', { price: x.price }),
          }))}
          title={t(`enums.productType.${productType}`, {
            productType,
          })}
          isPriceOverridden={isPriceOverridden}
        />
      )}

      <SummaryItemSubtotal
        isPriceOverridden={isPriceOverridden}
        disabled={disabled}
        name={name}
        subtotalValue={subtotalValue}
      />
    </Wrapper>
  );
};

export default ExtendTLSubscriptionSummaryContent;
