import styled from '@emotion/styled';

import Button from '../UI/Button/Button';

export const OverviewTabPage = styled.div`
  padding: 1.5rem 0;
  min-height: 10rem;
`;

export const OverviewFullTabPage = styled(OverviewTabPage)`
  margin: 0 2.5rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
export const ActionButton = styled(Button)`
  min-width: 6.25rem;
`;
