import { t } from 'i18next';

import { AirAmbulanceMembershipOptions } from '../../../../../../../models/enums/AirAmbulanceMembershipOptions';
import { IFormCheckboxGroupOptionProps } from '../../../../../../../styledComponents/UI/Form/FormCheckboxGroup/formCheckboxGroup.models';

export const OrganizationOptions: IFormCheckboxGroupOptionProps[] = [
  {
    name: AirAmbulanceMembershipOptions.SendWelcomeEmail,
  },
];

export const OrganizationOptionsRenewable: IFormCheckboxGroupOptionProps[] = [
  {
    name: AirAmbulanceMembershipOptions.SendWelcomeEmail,
  },
  {
    name: AirAmbulanceMembershipOptions.AutoRenewal,
    label: t('fields.renewWithSubscription.label'),
  },
];

export const RetailOptions: IFormCheckboxGroupOptionProps[] = [
  {
    name: AirAmbulanceMembershipOptions.SendWelcomeEmail,
    optionDisabled: true,
  },
];

export const RetailOptionsRenewable: IFormCheckboxGroupOptionProps[] = [
  {
    name: AirAmbulanceMembershipOptions.SendWelcomeEmail,
    optionDisabled: true,
  },
  {
    name: AirAmbulanceMembershipOptions.AutoRenewal,
  },
];
