import axios, { AxiosResponse } from 'axios';

import { IAAOrganizationSubscriptionOverview } from 'models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';

import config from '../../../../config/config';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { ISubscriptionMembershipPlan } from '../../../../models/interfaces/CreateSubscriptionMembershipPlan';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { ICreateAAOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/CreateAAOrganizationSubscriptionExtensionModel';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { IOrganizationSubscriptionPlanModel } from '../../../../models/OrganizationSubscriptionPlanModel';
import { IAASubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AASubscriptionRenewalSuggestionModel';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMembershipsModel } from '../../../../models/IRenewOrganizationSubscriptionMembershipsModel';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { EditOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/EditOrganizationSubscriptionModel';
import { IAAOrganizationSubscriptionOptionsModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOptionsModel';
import { RenewAASubscriptionModel } from '../../../../models/types/AirAmbulance/RenewAASubscriptionModel';
import { IEditAAOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/EditAAOrganizationSubscriptionModel';
import { RenewalStatus } from '../../../../models/enums/RenewalStatus';

export interface IGetSubscriptionsResponse {
  subscriptions: IOrganizationSubscription[];
}

export interface IOrganizationSubscriptionPlansModel {
  plans: IOrganizationSubscriptionPlanModel[];
}
export interface IGetSubscriptionSummaryModel {
  membershipPlans: ISubscriptionSummaryModel[];
}

export const getAASubscriptionSuggestion = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IEditAAOrganizationSubscriptionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/suggestion`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const updateAASubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: EditOrganizationSubscriptionModel,
): Promise<AxiosResponse> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    data,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const getAASubscriptionSummary = (
  organizationId: string,
  data: ISubscriptionMembershipPlan[],
): Promise<AxiosResponse<ISubscriptionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/summary`,
    { membershipPlans: data },
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const getDatesForNewAASubscription = (
  organizationId: string,
  contractId: string,
): Promise<AxiosResponse<ISubscriptionDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'contractId',
      value: contractId,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/newDates${params}`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );
};

export const getAASubscription = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IAAOrganizationSubscriptionOverview>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const updateAASubscriptionDetails = (
  organizationId: string,
  subscriptionId: string,
  subscriptionDetails: IAAOrganizationSubscriptionOptionsModel,
): Promise<AxiosResponse<IAAOrganizationSubscriptionOverview>> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/details`,
    subscriptionDetails,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const extendAASubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  subscriptionExtension: ICreateAAOrganizationSubscriptionExtensionModel,
): Promise<AxiosResponse<IAAOrganizationSubscriptionOverview>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update`,
    subscriptionExtension,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const getAASubscriptionExtensionSummary = (
  organizationId: string,
  subscriptionId: string,
  data: IChangeSubscriptionPlanCapacityModel[],
): Promise<AxiosResponse<IOrganizationSubscriptionExtensionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update/summary`,
    data,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const getAASubscriptionPlans = (
  organizationId: string,
  subscriptionId: string,
  primaryMemberBirthDate?: string,
  membershipEffectiveDate?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscriptionPlansModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'primaryMemberBirthDate',
      value: primaryMemberBirthDate,
    },
    {
      name: 'membershipEffectiveDate',
      value: membershipEffectiveDate,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/plans${params}`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );
};

export const getAASubscriptionRenewalSuggestion = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IAASubscriptionRenewalSuggestionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalsuggestion`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const renewAASubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: RenewAASubscriptionModel,
): Promise<AxiosResponse<IOrganizationSubscription>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renew`,
    data,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const getAASubscriptionRenewalAnalyseRequest = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/renewalanalysis`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const sendAASubscriptionRenewalMembershipsRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IRenewOrganizationSubscriptionMembershipsModel,
): Promise<AxiosResponse<IRenewOrganizationSubscriptionMembershipsModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships/toberenewed`,
    data,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );

export const deleteAASubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.airAmbulanceApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const editAASubscriptionRenewalStatusRequest = (
  organizationId: string,
  subscriptionId: string,
  renewalStatus: RenewalStatus,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(
    `/api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalstatus`,
    { renewalStatus },
    {
      signal: cancellationToken,
      baseURL: config.airAmbulanceApi.baseUrl,
    },
  );
