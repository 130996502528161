import {
  IMemberOverviewExpiredMemberDetailsProps,
  MemberOverviewExpiredMemberDetailsByProductType,
} from './memberOverviewExpiredMemberDetails.utils';

const MemberOverviewExpiredMemberDetails = ({
  productType,
  ...props
}: IMemberOverviewExpiredMemberDetailsProps) => {
  const Content = MemberOverviewExpiredMemberDetailsByProductType[productType];
  return <>{Content && <Content {...props} productType={productType} />}</>;
};

export default MemberOverviewExpiredMemberDetails;
