import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import SRMembersList from '../../../../../styledComponents/ProductMembersList/SRMembersList/SRMembersList';
import { pageFilters } from './srMembers.const';
import { useSRMembers } from './srMembers.hooks';

const SRMembers = () => {
  const {
    pageListMembers,
    membersLoading,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    srMembershipPricing,
  } = useSRMembers();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <SRMembersList
        tableProps={{
          data: pageListMembers,
          isLoading: membersLoading,
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: pageFilters,
          standardPricing: srMembershipPricing,
        }}
      />
    </>
  );
};

export default SRMembers;
