import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { SR_RAW_DATA_REPORTS_PROCESSING } from '../../../redux-slices/processes/constants';

import { Log } from '../../../../utils/logger';
import downloadFile from '../../../../utils/downloadFile';

import { ICallbackSaga } from '../../../../models/ICallbackSaga';
import { ReportsFilenamePrefixEnum } from '../common/models';
import { generateFilenameWithDate } from '../../../../utils/generateFilenameWithDate';
import { getSRRawDataReports } from './api';

const srReportsSlice = createSliceSaga({
  caseSagas: {
    *downloadSRRawDataReport({ payload }: PayloadAction<ICallbackSaga>) {
      try {
        yield put(updateProcessState(SR_RAW_DATA_REPORTS_PROCESSING));
        const name = generateFilenameWithDate(
          ReportsFilenamePrefixEnum.RawDataReport,
        );
        const response = yield* call(getSRRawDataReports);
        yield downloadFile(response.data, name);
        payload?.success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        payload?.error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SR_RAW_DATA_REPORTS_PROCESSING));
      }
    },
  },
  name: 'reports-saga',
});

export default srReportsSlice.saga;
export const { downloadSRRawDataReport } = srReportsSlice.actions;
export const { actions } = srReportsSlice;
