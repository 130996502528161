import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../ConfirmModal/ConfirmModal';

import { DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME } from './deleteSubscriptionModal.const';
import { useDeleteProductSubscriptionModal } from './deleteSubscriptionModal.hooks';
import { Description, Name } from './deleteSubscriptionModal.styles';

type DeleteProductSubscriptionModalProps = {
  onProductSubscriptionDeleted?: () => void;
};

const DeleteProductSubscriptionModal = ({
  onProductSubscriptionDeleted,
}: DeleteProductSubscriptionModalProps) => {
  const { t } = useTranslation();
  const {
    name,
    onBackHandler,
    deleteProductSubscriptionHandler,
    isProcessing,
  } = useDeleteProductSubscriptionModal({ onProductSubscriptionDeleted });
  return (
    <ConfirmModal
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: isProcessing,
        },
        {
          action: deleteProductSubscriptionHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing,
        },
      ]}
      name={DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME}
      disabled={isProcessing}
      title={t('headers.delete-product-subscription')}
    >
      <Description>{t('phrase.confirm-delete-subscription')}</Description>
      <Name>{name}</Name>
    </ConfirmModal>
  );
};

export default DeleteProductSubscriptionModal;
