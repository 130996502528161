import { Trans, useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../models/types/CustomComponent';

import { SectionSubTitle } from '../../../../../styledComponents/Typography/Typography';
import Button from '../../../../../styledComponents/UI/Button/Button';
import { ReactComponent as PenIconSvg } from '../../../../../assets/icons/pen-icon-secondary.svg';

import {
  ActionsContainer,
  Header,
  NotesText,
} from './contractOverviewNotes.styles';
import { isEmpty } from '../../../../../utils/textHelpers';
import ExpandContentButton from '../../../../../styledComponents/UI/ExpandContent/ExpandContentButton';
import { ExpandContentWrapper } from '../../../../../styledComponents/UI/ExpandContent/ExpandContent';
import { useExpandContent } from '../../../../../styledComponents/UI/ExpandContent/expandContent.hooks';
import {
  CanAddOrganizationContractNotesPermissions,
  CanEditOrganizationContractNotesPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';

interface IContractOverviewNotesProps extends CustomComponent {
  onEditContractNotes?: () => void;
  onAddContractNotes?: () => void;
  notes: string;
}

const ContractOverviewNotes = ({
  className,
  onAddContractNotes,
  onEditContractNotes,
  notes,
}: IContractOverviewNotesProps) => {
  const { t } = useTranslation();
  const { expandContent, hideContent, isExpanded } = useExpandContent();

  return (
    <div className={className}>
      <Header>
        <SectionSubTitle>{t('headers.notes')}</SectionSubTitle>
        <ActionsContainer>
          {!isEmpty(notes) && (
            <ExpandContentButton
              expandContent={expandContent}
              hideContent={hideContent}
              isExpanded={isExpanded}
            />
          )}
          {isEmpty(notes) && (
            <Button
              permissions={CanAddOrganizationContractNotesPermissions}
              onClick={onAddContractNotes}
              styleType="link"
            >
              {t('buttons.add-note')}
            </Button>
          )}
          {!isEmpty(notes) && (
            <Button
              permissions={CanEditOrganizationContractNotesPermissions}
              onClick={onEditContractNotes}
              styleType="icon"
            >
              <PenIconSvg />
            </Button>
          )}
        </ActionsContainer>
      </Header>
      <ExpandContentWrapper isExpanded={isExpanded}>
        <NotesText>
          <Trans>{notes}</Trans>
        </NotesText>
      </ExpandContentWrapper>
    </div>
  );
};

export default ContractOverviewNotes;
