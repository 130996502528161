import { useTranslation } from 'react-i18next';

import {
  FiltersWrapper,
  InputContainer,
  SearchInputContainer,
} from '../../../SimplePage/Filters';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import IconInput from '../../../UI/IconInput/IconInput';
import { MainSelect } from '../../../UI/MainSelect/MainSelect';
import { renewStatusOptions } from './docMembersFilters.const';

import {
  DOCMembersFiltersProps,
  DOCMembersListFilters,
} from './docMembersFilters.models';
import { useDOCMembersFilters } from './docMembersFilters.hooks';

const DOCMembersFilters = ({
  availableFilters,
  packages,
}: DOCMembersFiltersProps) => {
  const { t } = useTranslation();
  const {
    filters,
    onFilterChangeHandler,
    packagesOptions,
    subscriptionsListOptions,
  } = useDOCMembersFilters({ packages });

  const filtersComponents = availableFilters.map((filter) => {
    switch (filter) {
      case DOCMembersListFilters.Search:
        return (
          <SearchInputContainer key={DOCMembersListFilters.Search}>
            <IconInput
              defaultIcon="search"
              onChange={(evt) =>
                onFilterChangeHandler(evt, DOCMembersListFilters.Search)
              }
              placeholder={t('filters.search-by')}
              value={filters.search}
            />
          </SearchInputContainer>
        );
      case DOCMembersListFilters.Package:
        return (
          <InputContainer key={DOCMembersListFilters.Package}>
            <MainSelect
              currentValue={filters.package}
              isClearable
              isMulti
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, DOCMembersListFilters.Package)
              }
              options={packagesOptions}
              placeholder={t('filters.package')}
            />
          </InputContainer>
        );
      case DOCMembersListFilters.RenewStatus:
        return (
          <InputContainer key={DOCMembersListFilters.RenewStatus}>
            <MainSelect
              currentValue={filters.renewStatus}
              isClearable
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, DOCMembersListFilters.RenewStatus)
              }
              options={renewStatusOptions}
              placeholder={t('filters.renew-status')}
            />
          </InputContainer>
        );
      case DOCMembersListFilters.SubscriptionId:
        return (
          <InputContainer key={DOCMembersListFilters.SubscriptionId}>
            <MainSelect
              currentValue={filters.subscriptionId}
              isClearable
              isMulti
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, DOCMembersListFilters.SubscriptionId)
              }
              options={subscriptionsListOptions}
              placeholder={t('filters.subscription')}
            />
          </InputContainer>
        );
      case DOCMembersListFilters.OversubscribedOnly:
        return (
          <Checkbox
            key={DOCMembersListFilters.OversubscribedOnly}
            checked={!!filters.oversubscribedOnly}
            onChange={(evt: any) =>
              onFilterChangeHandler(
                evt,
                DOCMembersListFilters.OversubscribedOnly,
              )
            }
            label={t('filters.only-subscribed')}
          />
        );
      default:
        return null;
    }
  });

  return <FiltersWrapper>{filtersComponents}</FiltersWrapper>;
};

export default DOCMembersFilters;
