import { CustomForm } from '../../../models/types/CustomForm';
import { BasicFormWrapper } from '../../Form/Form';
import NotesForm from '../NotesForm/NotesForm';
import { NotesFormFields } from '../NotesForm/notesForm.models';
import ContractDetailsForm from './ContractDetailsForm/ContractDetailsForm';
import { StyledFormMessage } from './contractForm.styles';
import ContractOptionsForm from './ContractOptionsForm/ContractOptionsForm';
import { OverlapMessage } from './OverlapMessage/OverlapMessage';

interface IContractFormPropr extends CustomForm {
  detailsTitleVisible?: boolean;
  overlapMessageVisible?: boolean;
  editableFields?: Record<string, boolean>;
  editableMode?: boolean;
}

const ContractForm = ({
  disabled,
  className,
  detailsTitleVisible,
  overlapMessageVisible,
  editableFields,
  editableMode = false,
  name,
}: IContractFormPropr) => (
  <BasicFormWrapper className={className}>
    <ContractDetailsForm
      editableFields={editableFields}
      titleVisible={detailsTitleVisible}
      disabled={disabled}
      name={name}
    />
    {overlapMessageVisible && (
      <StyledFormMessage message={<OverlapMessage />} status="warning" />
    )}
    <ContractOptionsForm
      name={name}
      editableFields={editableFields}
      disabled={disabled}
    />
    {!editableMode && (
      <NotesForm
        disabled={
          disabled || (editableFields && !editableFields[NotesFormFields.Notes])
        }
        name={name}
      />
    )}
  </BasicFormWrapper>
);

export default ContractForm;
