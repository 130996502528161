import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { IGeneralPersonModel } from '../../../models/interfaces/GeneralPersonModel';
import {
  ClientOverviewGeneralPageParams,
  RoutePath,
} from '../../../models/enums/RoutePath';
import { updateModalState } from '../../../store/redux-slices/modals';
import { clearPersonList } from '../../../store/redux-slices/people';
import { personGeneralListSelector } from '../../../store/redux-slices/people/selectors';
import { PERSON_LIST_LOAD_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { loadGeneralPersonList } from '../../../store/saga-slices/people';
import { EDIT_MEMBER_MODAL_NAME } from '../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { textContainsWords } from '../../../utils/textHelpers';
import { RequiredQueryParams } from './membersGeneralPage.models';
import { ILoadGeneralPersonListPayload } from '../../../store/saga-slices/people/models';
import { MemberOverviewPageParamsType } from '../../../models/types/RoutePath';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';

export const useMembersGeneral = () => {
  const LoadGeneralPersonList = useActions(loadGeneralPersonList);
  const ClearPersonList = useActions(clearPersonList);
  const location = useLocation();
  const navigate = useNavigateSearch();
  const personGeneralList = useSelector(personGeneralListSelector);
  const UpdateModalState = useActions(updateModalState);

  const personListLoading = useProcessing(PERSON_LIST_LOAD_PROCESSING);

  const loadGeneralPersonListHandler = useCallback(() => {
    const params = location.search.slice(1);

    if (!textContainsWords(params, RequiredQueryParams)) {
      return;
    }

    const payload: ILoadGeneralPersonListPayload = {
      params,
    };

    LoadGeneralPersonList(payload);
  }, [LoadGeneralPersonList, location.search]);

  useEffect(
    () => loadGeneralPersonListHandler(),
    [loadGeneralPersonListHandler],
  );

  useEffect(() => () => ClearPersonList(), [ClearPersonList]);

  const onRowClickHandler = useCallback(
    (row: IGeneralPersonModel) => {
      const params: MemberOverviewPageParamsType = {
        memberId: row.personId,
      };
      navigate(RoutePath.MemberOverviewPage, { params });
    },
    [navigate],
  );

  const rowEditHandler = useCallback(
    (id: string) => {
      UpdateModalState([EDIT_MEMBER_MODAL_NAME, id]);
    },
    [UpdateModalState],
  );

  const rowCellClickHandler = useCallback(
    (row: IGeneralPersonModel, key: keyof IGeneralPersonModel) => {
      switch (key) {
        case 'association':
          if (!row?.organizationId) {
            return;
          }
          navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
            params: {
              [ClientOverviewGeneralPageParams.OrganizationId]:
                row.organizationId,
            },
          });
      }
    },
    [navigate],
  );

  return {
    personListLoading,
    personGeneralList,
    onRowClickHandler,
    rowEditHandler,
    loadGeneralPersonListHandler,
    rowCellClickHandler,
  };
};
