import { useTranslation } from 'react-i18next';

import OverviewOptions from '../../../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  canRenewTLSubscriptionPermissions,
  canUpdateTLSubscriptionsDetailsPermissions,
} from '../../../../../../config/accessPolicies/accessPolicies';

import { ITLOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import { useTLSubscriptionOverviewInformation } from './tlSubscriptionOverviewInformation.hooks';
import TLOverviewDetails from './TLOverviewDetails/TLOverviewDetails';
import { TravelLicenseSubscriptionOptions } from '../../../../../../models/enums/TravelLicenseSubscriptionOptions';
import {
  StyledLine,
  Wrapper,
} from './tlSubscriptionOverviewInformation.styles';

interface IProductSubscriptionOverviewInformationProps {
  onEditOptionsClick?: () => void;
  onEditRenewalStatus?: () => void;
  subscriptionOverview: ITLOrganizationSubscriptionOverview;
  canEditOptions?: boolean;
  canRenew?: boolean;
}
const TLSubscriptionOverviewInformation = ({
  onEditOptionsClick,
  onEditRenewalStatus,
  subscriptionOverview,
  canEditOptions,
  canRenew,
}: IProductSubscriptionOverviewInformationProps) => {
  const { t } = useTranslation();
  const { renewClickHandler } = useTLSubscriptionOverviewInformation();

  return (
    <Wrapper>
      <TLOverviewDetails
        data={subscriptionOverview}
        canRenewMembershipPermissions={canRenewTLSubscriptionPermissions}
        onRenewClick={canRenew && renewClickHandler}
        onEditRenewalStatus={onEditRenewalStatus}
      />
      <StyledLine />
      <OverviewOptions
        options={[
          {
            value: TravelLicenseSubscriptionOptions.AutoRenewal,
            label: t('phrase.auto-renew-product-subscription'),
          },
        ]}
        data={subscriptionOverview}
        onEditOptionsClick={canEditOptions && onEditOptionsClick}
        canEditOptionsPermissions={canUpdateTLSubscriptionsDetailsPermissions}
      />
    </Wrapper>
  );
};

export default TLSubscriptionOverviewInformation;
