import styled from '@emotion/styled/macro';

import { StandardText } from '../../Typography/Typography';

export const Description = styled(StandardText)`
  text-align: center;
`;

export const Name = styled(StandardText)`
  margin-top: 0.5rem;
  font-weight: bold;
`;
