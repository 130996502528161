import { DataValidationCxt } from 'ajv/dist/types';
import { get } from 'lodash';

export enum ValidationCustomKeywords {
  UniqueArrayNumberItem = 'uniqueArrayNumberItem',
}

export const addCustomKeywords = (avj: any) => {
  avj.addKeyword({
    keyword: ValidationCustomKeywords.UniqueArrayNumberItem,
    type: 'number',
    schemaType: 'boolean',
    compile: prepareValidationForUniqueNumberArrayItem,
  });
};

const prepareValidationForUniqueNumberArrayItem =
  () => (value: number, ctx: DataValidationCxt<string | number>) => {
    const path = ctx.instancePath
      .split('/')
      .slice(0, -2)
      .join('/')
      .replaceAll('/', '.')
      .replace('.', '');

    const parentArray: any[] = get(ctx.rootData, path);

    if (!parentArray || (parentArray && !Array.isArray(parentArray))) {
      return true;
    }

    const items = parentArray.filter((item) =>
      typeof item === 'object'
        ? item[ctx.parentDataProperty] === value
        : item === value,
    );

    return !!ctx?.parentData?.id || items.length <= 1;
  };
