import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import { useMembersGeneral } from './membersGeneralPage.hooks';
import GeneralMembersList from '../../../styledComponents/ProductMembersList/GeneralMembersList/GeneralMembersList';

const MembersGeneralPage = () => {
  const {
    onRowClickHandler,
    personGeneralList,
    personListLoading,
    rowEditHandler,
    loadGeneralPersonListHandler,
    rowCellClickHandler,
  } = useMembersGeneral();

  return (
    <>
      <EditMemberModal onMemberEdited={loadGeneralPersonListHandler} />
      <GeneralMembersList
        tableProps={{
          data: personGeneralList,
          isLoading: personListLoading,
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          onCellClick: rowCellClickHandler,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
      />
    </>
  );
};

export default MembersGeneralPage;
