import styled from '@emotion/styled';

import MaterialTable from '../../../MaterialTable/MaterialTable';

export const Wrapper = styled.div``;

export const Table = styled(MaterialTable)`
  padding: 0;
`;

export const ErrorLabel = styled.label`
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  font: 0.75rem/1.5rem ${({ theme }) => theme.font.openSans};
  color: ${({ theme }) => theme.color.error};
`;
