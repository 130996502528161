import { t } from 'i18next';

import { ITabNavigationItem } from '../../styledComponents/UI/TabNavigation/tabNavigation.models';
import { RoutePath } from '../../models/enums/RoutePath';
import {
  CanViewContractsRenewalsPagePermissions,
  CanViewSubscriptionsRenewalsPagePermissions,
} from '../../config/accessPolicies/accessPolicies';

export const renewalsTabsNavigationArray: Array<ITabNavigationItem> = [
  {
    label: t('tabsNavigation.contracts'),
    url: RoutePath.RenewalsContracts,
    permissions: CanViewContractsRenewalsPagePermissions,
  },
  {
    label: t('tabsNavigation.product-subscriptions'),
    url: RoutePath.RenewalsSubscriptions,
    permissions: CanViewSubscriptionsRenewalsPagePermissions,
  },
];
