import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { IResendWelcomeEmailData } from '../../../models/interfaces/ResendWelcomeEmailModel';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { ResendWelcomeEmailFormFields } from './resendWelcomeEmailForm.models';

const resendWelcomeEmailFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [ResendWelcomeEmailFormFields.emailAddress]: {
      type: 'string',
      format: 'email',
    },
  },
  required: [ResendWelcomeEmailFormFields.emailAddress],
};

export const validateResendWelcomeEmailForm = (
  data: IResendWelcomeEmailData,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, resendWelcomeEmailFormSchema);
