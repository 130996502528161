import { MouseEvent } from 'react';

import { Alignment } from '../../../models/Alignment';

import { ReactComponent as MoreIcon } from '../../../assets/icons/more.svg';
import { MenuOption } from '../../Menu/Menu';
import { StyledButton, StyledMenu } from './cellActionsRenderers.styles';
import { useMenuOptionsRenderer } from './cellActionsRenderers.hooks';
import { TableCellAction } from '../CellRenderers/cellRenderers.styles';
import { ReactComponent as PenIconSvg } from '../../../assets/icons/pen-icon-secondary.svg';
import { ReactComponent as BasketIcon } from '../../../assets/icons/basket-icon.svg';
import { CustomComponent } from '../../../models/types/CustomComponent';
import Button from '../../UI/Button/Button';

export interface ICellAction {
  alignment?: Alignment;
  disabled?: boolean;
}

export interface IMenuOptionsActionRenderer extends ICellAction {
  onElementClick?: (option: string) => void;
  options?: MenuOption[];
}

export const MenuOptionsRenderer = ({
  onElementClick,
  options,
}: IMenuOptionsActionRenderer) => {
  const {
    menuVisible,
    onMenuCloseHandler,
    onMenuElementClickHandler,
    onMenuShowHandler,
    ref,
  } = useMenuOptionsRenderer(onElementClick);

  return (
    <div>
      <StyledButton styleType="icon" onClick={onMenuShowHandler}>
        <MoreIcon ref={ref} />
      </StyledButton>
      <StyledMenu
        open={menuVisible}
        anchorEl={ref.current}
        onClose={onMenuCloseHandler}
        onElementClick={onMenuElementClickHandler}
        options={options}
      />
    </div>
  );
};
interface ICellActionProps<T> extends CustomComponent {
  permissions?: string[];
  onClick?: (data: T) => void;
  data: T;
}

export const EditRowAction = <T,>({
  onClick,
  permissions,
  data,
}: ICellActionProps<T>) => (
  <TableCellAction permissions={permissions}>
    <PenIconSvg
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
        onClick?.(data);
      }}
    />
  </TableCellAction>
);

export const DeleteRowAction = <T,>({
  onClick,
  permissions,
  data,
  disabled,
}: ICellActionProps<T>) => (
  <TableCellAction permissions={permissions}>
    <Button
      disabled={disabled}
      onClick={(event: MouseEvent) => {
        event.stopPropagation();
        onClick?.(data);
      }}
      styleType="icon"
    >
      <BasketIcon />
    </Button>
  </TableCellAction>
);
