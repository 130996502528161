import { PCA } from '../../common/auth/initAuth';

export const useLogoutCallback = () => {
  const onLoginHandler = (event: any) => {
    event.preventDefault();
    PCA.loginRedirect();
  };

  return {
    onLoginHandler,
  };
};
