import { useTranslation } from 'react-i18next';

import ReportsForm from './ReportsForm/FinanceReportsForm';
import { StyledReportsSubtitle } from '../../ReportsPage.styles';

const FinanceTabPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledReportsSubtitle>
        {t('headers.generate-report')}
      </StyledReportsSubtitle>
      <ReportsForm />
    </div>
  );
};

export default FinanceTabPage;
