import { useParams } from 'react-router';

import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import {
  AddSubscriptionAvailableSearchParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { ADD_CONTRACT_NOTES_MODAL_NAME } from './Modals/AddContractNotesModal/addContractNotesModal.const';
import { AddOrganizationContractNotesModalDetails } from './Modals/AddContractNotesModal/AddContractNotesModalForm/addContractNotesModalForm.models';
import { DELETE_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/DeleteContractModal/deleteContractModal.const';
import { DeleteContractModalDetails } from '../../../../styledComponents/Modals/DeleteContractModal/deleteContractModal.models';
import { EDIT_CONTRACT_NOTES_MODAL_NAME } from './Modals/EditContractNotesModal/editContractNotesModal.const';
import { EditOrganizationContractNotesModalDetails } from './Modals/EditContractNotesModal/EditContractNotesModalForm/editContractNotesModalForm.models';
import { EDIT_CONTRACT_DETAILS_MODAL_NAME } from '../../../../styledComponents/Modals/EditContractDetailsModal/editContractDetailsModal.const';
import { loadOrganizationContract } from '../../../../store/saga-slices/contracts';
import { ILoadOrganizationContractPayload } from '../../../../store/saga-slices/contracts/models';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { clearContractDetails } from '../../../../store/redux-slices/contracts';
import { organizationContractDetailsSelector } from '../../../../store/redux-slices/contracts/selectors';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  ORGANIZATION_CONTRACT_LOADING_PROCESSING,
  SUBSCRIPTIONS_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import { ILoadSubscriptionsPayload } from '../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { contractDetailsModelToUpdateContractModel } from '../../../../utils/contractHelpers';
import { subscriptionsGroupsListSelector } from '../../../../store/redux-slices/subscriptions/common/selectors';
import { clearSubscriptionsList } from '../../../../store/redux-slices/subscriptions/common';
import { loadSubscriptions } from '../../../../store/saga-slices/subscriptions/common';
import {
  AddSubscriptionParamsType,
  ContractOverviewPageParamsType,
  OrganizationContractsListPageParamsType,
  ProductSubscriptionOverviewPageParamsType,
} from '../../../../models/types/RoutePath';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { EditOrganizationContractModalDetails } from '../../../../styledComponents/Modals/EditContractDetailsModal/editContractDetailsModal.models';
import { EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME } from '../../../../styledComponents/Modals/EditRenewalContractStatusModal/editRenewalContractStatusModal.const';
import { EditRenewalContractStatusModalDetails } from '../../../../styledComponents/Modals/EditRenewalContractStatusModal/editRenewalContractStatusModal.models';

export const useContractOverview = () => {
  const UpdateModalState = useActions(updateModalState);
  const { id: organizationId, contractId } =
    useParams<ContractOverviewPageParamsType>();

  const navigate = useNavigateSearch();

  const cancellationToken = useCancellationToken();

  const LoadOrganizationContract = useActions(loadOrganizationContract);
  const LoadSubscriptions = useActions(loadSubscriptions);

  const ClearContractDetails = useActions(clearContractDetails);
  const ClearSubscriptionsList = useActions(clearSubscriptionsList);

  const contractDetails = useSelector(organizationContractDetailsSelector);
  const subscriptions = useSelector(subscriptionsGroupsListSelector);

  const dataLoading = useProcessing([
    ORGANIZATION_CONTRACT_LOADING_PROCESSING,
    SUBSCRIPTIONS_LOADING_PROCESSING,
  ]);

  const loadOrganizationContractDetailsHandler = useCallback(() => {
    const payload: ILoadOrganizationContractPayload = {
      contractId,
      organizationId,
      cancellationToken,
    };
    LoadOrganizationContract(payload);
  }, [LoadOrganizationContract, cancellationToken, contractId, organizationId]);

  const loadSubscriptionsHandler = useCallback(() => {
    const subscriptionsPayload: ILoadSubscriptionsPayload = {
      contractId,
      organizationId,
      cancellationToken,
    };
    LoadSubscriptions(subscriptionsPayload);
  }, [LoadSubscriptions, cancellationToken, contractId, organizationId]);

  useEffect(() => {
    loadOrganizationContractDetailsHandler();
    loadSubscriptionsHandler();
  }, [loadOrganizationContractDetailsHandler, loadSubscriptionsHandler]);

  useEffect(
    () => () => {
      ClearContractDetails();
      ClearSubscriptionsList();
    },
    [ClearContractDetails, ClearSubscriptionsList],
  );

  const contractDeletedHandler = () => {
    const params: OrganizationContractsListPageParamsType = {
      id: organizationId,
    };
    navigate(RoutePath.ClientOverviewContractsPageFullPath, { params });
  };

  const editContractNotesHandler = () => {
    const details: EditOrganizationContractNotesModalDetails = {
      organizationId,
      contractId,
      notes: contractDetails?.notes,
    };
    UpdateModalState([EDIT_CONTRACT_NOTES_MODAL_NAME, details]);
  };

  const editRenewalStatusHandler = () => {
    const details: EditRenewalContractStatusModalDetails = {
      organizationId,
      contractId,
      renewalStatus: contractDetails?.renewalStatus,
      contractName: contractDetails?.name,
      expirationDate: contractDetails?.expirationDate,
    };
    UpdateModalState([EDIT_RENEWAL_CONTRACT_STATUS_MODAL_NAME, details]);
  };

  const addContractNotesHandler = () => {
    const details: AddOrganizationContractNotesModalDetails = {
      organizationId,
      contractId,
    };
    UpdateModalState([ADD_CONTRACT_NOTES_MODAL_NAME, details]);
  };

  const editContractDetailsHandler = () => {
    const details: EditOrganizationContractModalDetails = {
      organizationId,
      contractId,
      data: contractDetailsModelToUpdateContractModel(contractDetails),
    };
    UpdateModalState([EDIT_CONTRACT_DETAILS_MODAL_NAME, details]);
  };

  const deleteContractHandler = () => {
    const details: DeleteContractModalDetails = {
      organizationId,
      contractId,
      contractName: contractDetails?.name,
    };

    UpdateModalState([DELETE_CONTRACT_MODAL_NAME, details]);
  };

  const addSubscriptionHandler = () => {
    const params: AddSubscriptionParamsType = {
      id: organizationId,
    };
    navigate(RoutePath.AddProductSubscription, {
      params,
      searchParams: {
        [AddSubscriptionAvailableSearchParams.ContractId]: contractId,
      },
    });
  };

  const showSubscriptionHandler = (subscription: IOrganizationSubscription) => {
    const params: ProductSubscriptionOverviewPageParamsType = {
      id: organizationId,
      subscriptionType: subscription.productType,
      subscriptionId: subscription.id,
    };
    navigate(RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath, {
      params,
    });
  };

  return {
    editContractNotesHandler,
    editRenewalStatusHandler,
    addContractNotesHandler,
    deleteContractHandler,
    editContractDetailsHandler,
    addSubscriptionHandler,
    showSubscriptionHandler,
    contractDetails,
    dataLoading,
    subscriptions,
    loadOrganizationContractDetailsHandler,
    contractDeletedHandler,
  };
};
