import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../ModalFormPage/ModalFormPage';
import { withErrorProvider } from '../../../../hoc/withErrorProvider';

import RenewalStatusForm from '../../../Forms/RenewalStatusForm/renewalStatusForm';
import { useEditSubscriptionRenewalStatus } from './editRenewalSubscriptionStatusModalForm.hooks';
import { EditRenewalSubscriptionStatusModalOverview } from '../EditRenewalSubscriptionStatusModalOverview/EditRenewalSubscriptionStatusModalOverview';

type EditRenewalSubscriptionStatusModalFormProps = {
  onRenewalStatusEdited?: () => void;
};

const EditRenewalSubscriptionStatusModalForm = ({
  onRenewalStatusEdited,
}: EditRenewalSubscriptionStatusModalFormProps) => {
  const { t } = useTranslation();
  const {
    closeModalHandler,
    handleSubmit,
    isProcessing,
    initialValues,
    overviewData,
    validateData,
  } = useEditSubscriptionRenewalStatus({ onRenewalStatusEdited });
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateData}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          title={t('headers.subscription-renewal-status')}
          onCancel={closeModalHandler}
          isProcessing={isProcessing}
          mode="small"
        >
          <EditRenewalSubscriptionStatusModalOverview data={overviewData} />
          <RenewalStatusForm disabled={isProcessing} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withErrorProvider(EditRenewalSubscriptionStatusModalForm);
