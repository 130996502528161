import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { ICreateKEOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/KidnapAndExtortion/CreateKEOrganizationSubscriptionExtensionModel';

const schema: AnySchema | any = {
  type: 'object',
  properties: {},
  dependencies: {},
  required: [],
};

export const validateExtendKESubscriptionForm = (
  data: ICreateKEOrganizationSubscriptionExtensionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
