/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IKEOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionOverview';
import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import {
  PricingRenderer,
  FractionRenderer,
} from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { overSubscribedRenderer } from '../../../../../../styledComponents/MaterialTable/CellRenderers/OverSubscribedRenderer/OverSubscribedRenderer';
import MaterialTable from '../../../../../../styledComponents/MaterialTable/MaterialTable';
import { getSummaryRow } from './keSubscriptionOverviewPlansTable.utils';

interface IProductSubscriptionOverviewPlansTableProps {
  dataLoading?: boolean;
  subscriptionOverview: IKEOrganizationSubscriptionOverview;
  onRowClick?: (data: IOrganizationSubscriptionOverviewMembershipPlan) => void;
  onSummaryRowClick?: () => void;
  onSeeOversubscriptionClick?: (data?: IOrganizationSubscriptionOverviewMembershipPlan) => void;
  onSummarySeeOversubscriptionClick?: (data?: IOrganizationSubscriptionOverviewMembershipPlan) => void;
  canClickRowPermissions?: string[];
}

const KESubscriptionOverviewPlansTable = ({
  dataLoading,
  subscriptionOverview,
  onRowClick,
  onSummaryRowClick,
  onSeeOversubscriptionClick,
  onSummarySeeOversubscriptionClick,
  canClickRowPermissions,
}: IProductSubscriptionOverviewPlansTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (
      key: keyof IOrganizationSubscriptionOverviewMembershipPlan,
      options?: ICellOptions,
    ) =>
    (data: IOrganizationSubscriptionOverviewMembershipPlan) => {
      switch (key) {
        case 'price': {
          return (
            <PricingRenderer options={options} price={data.price} readonly />
          );
        }
        case 'membershipsInUse': {
          return (
            <FractionRenderer
              maxValue={data.capacity}
              options={options}
              value={data.membershipsInUse}
            />
          );
        }
      }
    };


  return (
    <MaterialTable
      onRowClick={onRowClick}
      onSummaryRowClick={onSummaryRowClick}
      columns={[
        {
          title: t('grid-columns.membership-plan'),
          field: 'membershipPlanName',
        },
        {
          title: t('grid-columns.price'),
          field: 'price',
          alignment: 'left',
          cellRenderer: cellRenderer('price', { alignment: 'left' }),
        },
        {
          title: t('grid-columns.memberships'),
          alignment: 'left',
          field: 'membershipsInUse',
          cellRenderer: cellRenderer('membershipsInUse'),
        },
        {
          field: 'oversubscribed',
          cellRenderer: overSubscribedRenderer(onSeeOversubscriptionClick, canClickRowPermissions),
        },
      ]}
      data={subscriptionOverview ? subscriptionOverview.plans : []}
      dataLoading={dataLoading}
      isPaginationHidden
      canClickRowPermissions={canClickRowPermissions}
      summaryRow={getSummaryRow(subscriptionOverview, onSummarySeeOversubscriptionClick, canClickRowPermissions)}
    />
  );
};

export default KESubscriptionOverviewPlansTable;
