import { useNavigate } from 'react-router';

import { CustomComponent } from '../../models/types/CustomComponent';
import Button from '../UI/Button/Button';
import {
  NavigationContainer,
  NavigationPathPart,
  PathText,
  Separator,
  StyledLeftArrow,
  Wrapper,
} from './navigationPath.styles';

export interface INavigationPath {
  label?: string;
  url?: string;
}

interface INavigationPathProps extends CustomComponent {
  paths: INavigationPath[];
}

const NavigationPath = ({ paths, className }: INavigationPathProps) => {
  const navigate = useNavigate();

  const clickBackButtonHandler = () => {
    if (paths.length < 2) {
      return;
    }

    const { url } = paths[paths.length - 2];
    url && navigate(url);
  };

  return (
    <Wrapper className={className}>
      <Button onClick={clickBackButtonHandler} styleType="icon">
        <StyledLeftArrow />
      </Button>
      <NavigationContainer>
        {paths.map((path, index) => (
          <NavigationPathPart key={path.url + path.label}>
            <PathText
              isActive={index === paths.length - 1}
              onClick={() => path.url && navigate(path.url)}
            >
              {path.label}
            </PathText>
            {index < paths.length - 1 && <Separator>/</Separator>}
          </NavigationPathPart>
        ))}
      </NavigationContainer>
    </Wrapper>
  );
};

export default NavigationPath;
