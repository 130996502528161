import { Route, Routes } from 'react-router';

import {
  canViewRawDataReportPermissions,
  canGenerateRetailReportsPermissions,
  canViewFinancialReportPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import AuthRoute from '../../styledComponents/AuthRoute/AuthRoute';
import FinanceTabPage from './ReportsTabPages/FinanceTabPage/FinanceTabPage';
import RawDataTabPage from './ReportsTabPages/RawDataTabPage/RawDataTabPage';
import RetailDataTabPage from './ReportsTabPages/RetailDataTabPage/RetailDataTabPage';

const ReportsRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.ReportsFinance}
      element={
        <AuthRoute permissions={canViewFinancialReportPermissions}>
          <FinanceTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ReportsRawData}
      element={
        <AuthRoute permissions={canViewRawDataReportPermissions}>
          <RawDataTabPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ReportsRetailData}
      element={
        <AuthRoute permissions={canGenerateRetailReportsPermissions}>
          <RetailDataTabPage />
        </AuthRoute>
      }
    />
  </Routes>
);

export default ReportsRoutes;
