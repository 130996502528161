import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../redux-slices/processes';
import {
  PROMO_CODES_LOADING_PROCESSING,
  PROMO_CODE_ADDING_PROCESSING,
  PROMO_CODE_CHANGE_STATUS_PROCESSING,
  PROMO_CODE_DATE_EDIT_PROCESSING,
  PROMO_CODE_DELETING_PROCESSING,
  PROMO_CODE_OVERVIEW_LOADING_PROCESSING,
  PROMO_CODE_SUGGESTION_LOAD_PROCESSING,
} from '../../redux-slices/processes/constants';
import { Log } from '../../../utils/logger';
import {
  createPromoCode,
  disablePromoCode,
  editValidDates,
  enablePromoCode,
  getCreatePromoCodeSuggestion,
  GetPromoCodeOverview,
  getPromoCodes,
  removePromoCode,
} from './api';
import {
  IAddPromoCodePayload,
  IChangePromoCodeStatusPayload,
  IDeletePromoCodePayload,
  IEditPromoCodeDatesPayload,
  ILoadPromoCodeOverviewPayload,
  ILoadPromoCodesPayload,
  ILoadPromoCodeSuggestion,
} from './models';
import {
  setPromoCode,
  setPromoCodes,
  setPromoCodeSuggestion,
} from '../../redux-slices/promoCodes';
import { preparePromoCodeData } from './utils';

const promoCodesSlice = createSliceSaga({
  name: 'promo-codes-saga',
  caseSagas: {
    *loadPromoCodes({
      payload: { params, organizationId, success, error, cancellationToken },
    }: PayloadAction<ILoadPromoCodesPayload>) {
      try {
        yield put(updateProcessState(PROMO_CODES_LOADING_PROCESSING));
        const { data } = yield* call(
          getPromoCodes,
          cancellationToken,
          organizationId,
          params,
        );
        yield put(setPromoCodes(data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODES_LOADING_PROCESSING));
      }
    },
    *loadPromoCodeOverview({
      payload: { id, success, error },
    }: PayloadAction<ILoadPromoCodeOverviewPayload>) {
      try {
        yield put(updateProcessState(PROMO_CODE_OVERVIEW_LOADING_PROCESSING));
        const { data } = yield* call(GetPromoCodeOverview, id);
        yield put(setPromoCode(data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_OVERVIEW_LOADING_PROCESSING));
      }
    },
    *addPromoCode({
      payload: { data, success, error },
    }: PayloadAction<IAddPromoCodePayload>) {
      try {
        yield put(updateProcessState(PROMO_CODE_ADDING_PROCESSING));
        const reqData = preparePromoCodeData(data);
        const response = yield* call(createPromoCode, reqData);
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_ADDING_PROCESSING));
      }
    },
    *deletePromoCode({
      payload: { promoCodeId, success, error, cancellationToken },
    }: PayloadAction<IDeletePromoCodePayload>) {
      try {
        yield put(updateProcessState(PROMO_CODE_DELETING_PROCESSING));
        yield call(removePromoCode, promoCodeId, cancellationToken);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_DELETING_PROCESSING));
      }
    },
    *changePromoCodeStatus({
      payload: { isEnabled, promoCodeId, success, error, cancellationToken },
    }: PayloadAction<IChangePromoCodeStatusPayload>) {
      try {
        yield put(updateProcessState(PROMO_CODE_CHANGE_STATUS_PROCESSING));
        if (isEnabled) {
          yield call(enablePromoCode, promoCodeId, cancellationToken);
        } else {
          yield call(disablePromoCode, promoCodeId, cancellationToken);
        }

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_CHANGE_STATUS_PROCESSING));
      }
    },
    *loadPromoCodeSuggestion({
      payload: { organizationId, success, error, cancellationToken },
    }: PayloadAction<ILoadPromoCodeSuggestion>) {
      try {
        yield put(updateProcessState(PROMO_CODE_SUGGESTION_LOAD_PROCESSING));

        const response = yield* call(
          getCreatePromoCodeSuggestion,
          organizationId,
          cancellationToken,
        );

        yield put(setPromoCodeSuggestion(response.data));

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_SUGGESTION_LOAD_PROCESSING));
      }
    },
    *editPromoCodeDates({
      payload: { promoCodeId, data, success, error, cancellationToken },
    }: PayloadAction<IEditPromoCodeDatesPayload>) {
      try {
        yield put(updateProcessState(PROMO_CODE_DATE_EDIT_PROCESSING));

        yield call(editValidDates, promoCodeId, data, cancellationToken);

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PROMO_CODE_DATE_EDIT_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default promoCodesSlice.saga;
export const {
  loadPromoCodes,
  loadPromoCodeOverview,
  addPromoCode,
  changePromoCodeStatus,
  deletePromoCode,
  loadPromoCodeSuggestion,
  editPromoCodeDates,
} = promoCodesSlice.actions;
export const { actions } = promoCodesSlice;
