import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { ORGANIZATION_NOTES_ADDING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { IAddOrganizationContractNotesPayload } from '../../../../../../../store/saga-slices/contracts/models';
import {
  useApiResponseError,
  useFormErrors,
} from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validateNotesForm } from '../../../../../../../styledComponents/Forms/NotesForm/notesForm.validation';
import { ADD_ORG_NOTES_MODAL_NAME } from '../addNotesModal.const';
import { AddOrganizationNotesModalDetails } from './addNotesModalForm.models';
import { addOrganizationNotes } from '../../../../../../../store/saga-slices/organizations';
import { IAddOrganizationNotesModelPayload } from '../../../../../../../store/saga-slices/organizations/models';

export interface IUseAddOrgNotesModalFormProps {
  onNotesAdded?: () => void;
}

export const useAddOrganizationNotes = ({
  onNotesAdded,
}: IUseAddOrgNotesModalFormProps) => {
  const cancellationToken = useCancellationToken();
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const AddOrganizationNotes = useActions(addOrganizationNotes);

  const isProcessing = useProcessing(ORGANIZATION_NOTES_ADDING_PROCESSING);

  const { validateErrors, addErrors } = useFormErrors();

  const { handleResponseError } = useApiResponseError({
    externalAddErrors: addErrors,
  });

  const { organizationId }: AddOrganizationNotesModalDetails = useModalDetails(
    ADD_ORG_NOTES_MODAL_NAME,
  );

  const closeModalHandler = useCallback(() => {
    UpdateModalState(ADD_ORG_NOTES_MODAL_NAME);
  }, [UpdateModalState]);

  const handleSubmit = useCallback(
    (values: { notes: string }) => {
      const payload: IAddOrganizationNotesModelPayload = {
        organizationId,
        notes: values.notes,
        cancellationToken,
        success: () => {
          alert.success(t('alerts.contract-notes-added-success'));
          closeModalHandler();
          onNotesAdded?.();
        },
        error: (err: any) => {
          handleResponseError(err, values);
          alert.error(t('alerts.contract-notes-added-fails'));
        },
      };
      AddOrganizationNotes(payload);
    },
    [
      AddOrganizationNotes,
      alert,
      cancellationToken,
      closeModalHandler,
      handleResponseError,
      onNotesAdded,
      organizationId,
      t,
    ],
  );

  const validateData = useCallback(
    (data: IAddOrganizationContractNotesPayload) =>
      validateNotesForm(data, validateErrors(data)),
    [validateErrors],
  );

  return {
    closeModalHandler,
    handleSubmit,
    isProcessing,
    validateData,
  };
};
