import { AnySchema } from 'ajv';

import { ICreateOrganizationModel } from '../../../models/CreateOrganizationModel';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { organizationContractSchema } from '../../../styledComponents/Forms/ContractForm/ContractDetailsForm/contractDetailsForm.validation';
import { corporationInformationsValidationSchema } from '../../../styledComponents/Forms/CorporationInformationsForm/corporationInformationsForm.validation';
import { CorpPointOfContactFieldsModel } from '../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/corpPointOfContact.models';
import { organizationPointOfContactSchema } from '../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/corpPointOfContactForm.validation';
import { ggPointOfContactSchema } from '../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactForm/ggPointOfContact.validation';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { AddClientFormFields } from './addClientForm.models';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...corporationInformationsValidationSchema.properties,
    [AddClientFormFields.OrganizationPoc]: {
      type: 'array',
      items: {
        type: 'object',
        properties: organizationPointOfContactSchema.properties,
        if: {
          anyOf: [
            {
              required: [CorpPointOfContactFieldsModel.firstName],
            },
            {
              required: [CorpPointOfContactFieldsModel.lastName],
            },
            {
              required: [CorpPointOfContactFieldsModel.jobTitle],
            },
          ],
        },
        then: {
          if: {
            properties: {
              [CorpPointOfContactFieldsModel.isOrganizationAdministrator]: {
                enum: [true],
              },
            },
          },
          then: {
            required: [CorpPointOfContactFieldsModel.emailAddress],
          },
          else: {
            anyOf: [
              {
                required: [CorpPointOfContactFieldsModel.mobilePhone],
              },
              {
                required: [CorpPointOfContactFieldsModel.workPhone],
              },
              {
                required: [CorpPointOfContactFieldsModel.emailAddress],
              },
            ],
          },
          required: [
            CorpPointOfContactFieldsModel.firstName,
            CorpPointOfContactFieldsModel.lastName,
          ],
        },
        required: [],
      },
    },
    [AddClientFormFields.GlobalGuardianPoc]: {
      type: 'array',
      items: ggPointOfContactSchema,
    },
    [AddClientFormFields.OrganizationContracts]: {
      type: 'array',
      items: organizationContractSchema,
    },
  },
  required: [...corporationInformationsValidationSchema.required],
};

export const validateAddClientForm = (
  data: ICreateOrganizationModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
