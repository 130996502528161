import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { SubscriptionRenewPricing } from '../../Common/SubscriptionRenewPricing/SubscriptionPricing';
import { useAARenewSubscriptionPricing } from './aaRenewSubscriptionPricing.hooks';

type AARenewSubscriptionPricingMode = CustomComponent & {
  productType?: ProductType;
};

export const AARenewSubscriptionPricing = ({
  disabled,
  productType,
}: AARenewSubscriptionPricingMode) => {
  const { t } = useTranslation();
  const {
    membershipPlansPreviousPrices,
    membershipPlansPrices,
    subscriptionRenewalSuggestionPlans,
    loadCapacityBySuggestions,
    suggestionsCapacity,
  } = useAARenewSubscriptionPricing();

  return (
    <SubscriptionRenewPricing
      disabled={disabled}
      onTransferAllEligibleMembershipsChange={loadCapacityBySuggestions}
      previousMembershipPlansPrices={membershipPlansPreviousPrices}
      regularMembershipPlansPrices={membershipPlansPrices}
      subscriptionRenewalSuggestionPlans={subscriptionRenewalSuggestionPlans}
      defaultPlansData={suggestionsCapacity}
      productType={productType}
      title={t('headers.retail-pricing-and-current-capacity')}
    />
  );
};
