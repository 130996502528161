import { ChangeEvent } from 'react';

import TableCell from '@mui/material/TableCell';

import { useTranslation } from 'react-i18next';

import { InfoStatus } from '../../../../UI/InfoStatus';
import { CollapseIndicator } from '../../../../UI/CollapseIndicator';

import { Status } from '../../../../../models/enums/Status';
import { ToggleSwitch } from '../../../../UI/ToggleSwitch/ToggleSwitch';

import { IRenewOrganizationSubscriptionMemberModel } from '../../../../../models/IRenewOrganizationSubscriptionMemberModel';

import {
  DateTableCell,
  EmptyCollapseCell,
  EmptyStatusCell,
  NameTableCell,
  StyledCell,
  StyledRow,
  StyledTableCell,
} from './tableRow.styles';

import { SubscriptionMember } from '../../subscriptionAnalyse.enums';
import DateOfBirthCell from './DateOfBirthCell/DateOfBirthCell';
import Button from '../../../../UI/Button/Button';

type Props = {
  member: IRenewOrganizationSubscriptionMemberModel;
  isCollapsible?: boolean;
  membershipPlanName: string | null;
  isOpen: boolean;
  toggle?: () => void;
  fieldType: SubscriptionMember;
  infoStatus?: Status;
  handleChange?: (
    e: ChangeEvent<HTMLInputElement>,
    fieldIndex: number,
    fieldType: SubscriptionMember,
  ) => void;
  fieldIndex?: number;
};

export const TableRow = ({
  membershipPlanName,
  member: {
    firstName,
    lastName,
    dateOfBirth,
    memberType,
    canBeRenewed,
    toBeRenewed,
    ageRange,
  },
  fieldType,
  fieldIndex,
  isCollapsible = false,
  isOpen,
  infoStatus,
  handleChange,
  toggle,
}: Props) => {
  const { t } = useTranslation();
  return (
    <StyledRow isOpen={isOpen}>
      <StyledCell width={50} isOpen={isOpen} rowType={fieldType}>
        {infoStatus ? <InfoStatus status={infoStatus} /> : <EmptyStatusCell />}
      </StyledCell>
      <NameTableCell width={165}>
        {firstName} {lastName}
      </NameTableCell>
      <DateTableCell width={160} canBeRenewed={canBeRenewed}>
        <DateOfBirthCell
          value={dateOfBirth}
          tooltip={t(
            ageRange?.maxAge
              ? 'phrase.age_must_be_min_max'
              : 'phrase.age_must_be_min',
            {
              maxValue: ageRange?.maxAge,
              minValue: ageRange?.minAge,
              type: memberType,
            },
          )}
          tooltipShowing={!canBeRenewed}
        />
      </DateTableCell>
      <StyledTableCell width={220}>{membershipPlanName}</StyledTableCell>
      <StyledTableCell width={155}>{memberType}</StyledTableCell>
      <TableCell width={155}>
        <ToggleSwitch
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange(e, fieldIndex, fieldType)
          }
          isChecked={toBeRenewed}
          name={fieldType}
          onLabel="Yes"
          offLabel="no"
        />
      </TableCell>

      <TableCell width={45}>
        {isCollapsible && toggle ? (
          <Button styleType="icon" onClick={toggle}>
            <CollapseIndicator isOpen={isOpen} />
          </Button>
        ) : (
          <EmptyCollapseCell />
        )}
      </TableCell>
    </StyledRow>
  );
};
