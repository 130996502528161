import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';

export const TableFormName = 'tl-extend-subscription-pricing-table';

export enum TableFormColumns {
  seatsAmount = 'seatsAmount',
  price = 'price',
}

export type TableFieldsDataType = {
  name: string;
  index: number;
};

export type TableDataType = IChangeTLSubscriptionPricingTierModel &
  TableFieldsDataType & {
    newSeatsAmount?: number;
    currentSeatsAmount?: number;
  };
