import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { Status } from './formMessage.models';

export const Wrapper = styled.div`
  display: flex;
`;

type MessageProps = {
  status: Status;
};

export const Message = styled.label<MessageProps>`
  color: ${({ theme }) => theme.color.dark1};
  font: normal bold 0.875rem/1rem ${({ theme }) => theme.font.gotham};

  ${({ status, theme }) => {
    switch (status) {
      case 'error':
        return css`
          color: ${theme.color.error};
        `;
      case 'success':
        return css`
          color: ${theme.color.success};
        `;
      case 'warning':
        return css`
          color: ${theme.color.warning};
        `;
    }
  }}
`;
