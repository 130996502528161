import { ToolsWrapper } from '../../../SimplePage/Tools';
import DOCMembersFilters from '../DOCMembersFilters/DOCMembersFilters';
import { DOCMembersFiltersProps } from '../DOCMembersFilters/docMembersFilters.models';

export type DOCMembersToolsProps = DOCMembersFiltersProps;

const DOCMembersTools = ({
  availableFilters,
  packages,
}: DOCMembersToolsProps) => (
  <ToolsWrapper>
    <DOCMembersFilters
      availableFilters={availableFilters}
      packages={packages}
    />
  </ToolsWrapper>
);

export default DOCMembersTools;
