import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../../hooks/useProcessing';
import {
  AddMemberAvailableSearchParams,
  EditProductSubscriptionPageParams,
  EditProductSubscriptionRenewPageParams,
  ExtendProductSubscriptionPageParams,
  OrganizationMembersListPageParams,
  RoutePath,
} from '../../../../../models/enums/RoutePath';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { ILoadSubscriptionOverviewPayload } from '../../../../../store/saga-slices/subscriptions/airAmbulance/models';
import { organizationNameSelector } from '../../../../../store/redux-slices/organizations/selectors';
import { ProductType } from '../../../../../models/ProductType';
import { DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME } from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/deleteSubscriptionModal.const';
import { DeleteSubscriptionModalDetails } from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/deleteSubscriptionModal.model';
import { ProductSubscriptionOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { loadDOCSubscriptionOverview } from '../../../../../store/saga-slices/subscriptions/dutyOfCare';
import { clearDOCSubscriptionOverview } from '../../../../../store/redux-slices/subscriptions/dutyOfCare';
import {
  docSubscriptionOverviewCurrentPackagesSelector,
  docSubscriptionOverviewHistoryPackagesSelector,
  docSubscriptionOverviewSelector,
} from '../../../../../store/redux-slices/subscriptions/dutyOfCare/selectors';
import { DOCMembersListFilters } from '../../../../../styledComponents/ProductMembersList/DOCMembersList/DOCMembersFilters/docMembersFilters.models';
import { EDITING_DOC_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './Modals/DOCSubscriptionEditOptionsModal/DOCSubscriptionEditOptionsModal.const';
import { EditRenewalSubscriptionStatusModalDetails } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.models';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/editRenewalSubscriptionStatusModal.const';

export const useDOCSubscriptionOverviewTabPage = () => {
  const { id, subscriptionId } = useParams();
  const navigate = useNavigateSearch();

  const LoadSubscriptionOverview = useActions(loadDOCSubscriptionOverview);
  const ClearCurrentSubscription = useActions(clearDOCSubscriptionOverview);

  const subscriptionOverviewLoading = useProcessing(
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  );
  const subscriptionOverview = useSelector(docSubscriptionOverviewSelector);
  const currentPackages = useSelector(
    docSubscriptionOverviewCurrentPackagesSelector,
  );
  const historyPackages = useSelector(
    docSubscriptionOverviewHistoryPackagesSelector,
  );

  const loadSubscriptionOverviewHandler = useCallback(() => {
    const payload: ILoadSubscriptionOverviewPayload = {
      organizationId: id,
      subscriptionId,
    };
    LoadSubscriptionOverview(payload);
  }, [LoadSubscriptionOverview, id, subscriptionId]);

  useEffect(() => {
    loadSubscriptionOverviewHandler();
  }, [loadSubscriptionOverviewHandler]);

  useEffect(
    () => () => {
      ClearCurrentSubscription();
    },
    [ClearCurrentSubscription],
  );

  return {
    id,
    subscriptionId,
    navigate,
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
    historyPackages,
    currentPackages,
  };
};

export const useDOCSubscriptionOverviewActions = () => {
  const { t } = useTranslation();
  const { id, subscriptionId, subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();
  const organizationName = useSelector(organizationNameSelector);
  const UpdateModalState = useActions(updateModalState);
  const navigate = useNavigateSearch();
  const subscriptionOverview = useSelector(docSubscriptionOverviewSelector);

  const deleteProductSubscriptionHandler = () => {
    const details: DeleteSubscriptionModalDetails = {
      name: t('phrase.duty-of-care-for', {
        value: organizationName,
      }),
      organizationId: id,
      subscriptionId,
      productType: ProductType.DutyOfCare,
    };

    UpdateModalState([DELETE_PRODUCT_SUBSCRIPTION_MODAL_NAME, details]);
  };

  const addMemberHandler = () => {
    navigate(RoutePath.AddMemberWizardPrimaryMember, {
      searchParams: {
        [AddMemberAvailableSearchParams.OrganizationId]: id,
        [AddMemberAvailableSearchParams.SubscriptionId]: subscriptionId,
        [AddMemberAvailableSearchParams.ProductType]: ProductType.DutyOfCare,
      },
    });
  };

  const editSubscriptionHandler = () => {
    if (subscriptionOverview?.isRenewation) {
      navigate(RoutePath.EditClientSubscriptionRenewPage, {
        params: {
          [EditProductSubscriptionRenewPageParams.OrganizationId]: id,
          [EditProductSubscriptionRenewPageParams.SubscriptionId]:
            subscriptionId,
          [EditProductSubscriptionRenewPageParams.ProductType]:
            ProductType.DutyOfCare,
        },
      });
      return;
    }

    navigate(RoutePath.EditProductSubscription, {
      params: {
        [EditProductSubscriptionPageParams.OrganizationId]: id,
        [EditProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [EditProductSubscriptionPageParams.SubscriptionType]:
          ProductType.DutyOfCare,
      },
    });
  };

  const extendProductSubscriptionHandler = () => {
    navigate(RoutePath.ExtendProductSubscription, {
      params: {
        [ExtendProductSubscriptionPageParams.OrganizationId]: id,
        [ExtendProductSubscriptionPageParams.SubscriptionId]: subscriptionId,
        [ExtendProductSubscriptionPageParams.ProductType]:
          ProductType.DutyOfCare,
      },
    });
  };

  const onRowClickHandler = () => {
    const searchParams = {
      [DOCMembersListFilters.SubscriptionId]: subscriptionId,
    };

    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const onSeeOversubscribedMembersHandler = () => {
    const searchParams = {
      [DOCMembersListFilters.SubscriptionId]: subscriptionId,
      [DOCMembersListFilters.OversubscribedOnly]: 'true',
    };

    navigate(RoutePath.ClientOverviewMembersListPageFullPath, {
      params: {
        [OrganizationMembersListPageParams.OrganizationId]: id,
        [OrganizationMembersListPageParams.ProductType]: subscriptionType,
      },
      searchParams,
    });
  };

  const showEditSubscriptionDetailsModal = () => {
    UpdateModalState([
      EDITING_DOC_SUBSCRIPTION_OPTIONS_MODAL_NAME,
      { data: subscriptionOverview },
    ]);
  };

  const editRenewalStatusHandler = () => {
    const details: EditRenewalSubscriptionStatusModalDetails = {
      organizationId: id,
      subscriptionId,
      renewalStatus: subscriptionOverview?.renewalStatus,
      contractName: subscriptionOverview?.organizationContractName,
      organizationName,
      expirationDate: subscriptionOverview?.effectiveDateTo,
      productType: subscriptionType,
    };

    UpdateModalState([EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME, details]);
  };

  return {
    extendProductSubscriptionHandler,
    addMemberHandler,
    onRowClickHandler,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    deleteProductSubscriptionHandler,
    showEditSubscriptionDetailsModal,
    editRenewalStatusHandler,
  };
};
