import { ChangeEvent } from 'react';

export enum RenewalStaticFilter {
  HideExpired = 'hideExpired',
}

export type RenewalStaticFiltersProps = {
  availableFilters: RenewalStaticFilter[];
  onFilterChangeHandler: (
    evt: ChangeEvent<HTMLInputElement>,
    filterType: RenewalStaticFilter,
  ) => void;
  filters: Record<RenewalStaticFilter, boolean>;
};
