import { useCallback, useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { IMembershipPlanTypeModel } from '../../../../../../../models/MembershipPlanTypeModel';

import { MemberType } from '../../../../../../../models/enums/MemberType';
import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';
import { primaryMemberSelector } from '../../../../../../../store/redux-slices/membership/common/selectors';
import {
  MemberTypeForAge,
  MemberTypeAgeRange,
} from '../../../../../../../styledComponents/Forms/FamilyMember/FamilyMemberBasicDetails/familyMemberBasicDetails.models';
import { getCountOfItems } from '../../../../../../../utils/arrayHelpers';
import { MembershipFamilyMembersFormFields } from './membershipFamilyMembers.models';

type UseMembershipFamilyMembersFormProps = {
  membershipPlanType?: IMembershipPlanTypeModel;
  maxMembersPerPlan?: number;
  isRenewalMode?: boolean;
};

export const useMembershipFamilyMembersForm = ({
  membershipPlanType,
  maxMembersPerPlan,
  isRenewalMode,
}: UseMembershipFamilyMembersFormProps) => {
  const primaryMember = useSelector(primaryMemberSelector);

  const {
    input: { onChange: onFamilyMembersChange },
  }: FieldRenderProps<IOrganizationSubscriptionPlanModel> = useField(
    MembershipFamilyMembersFormFields.SecondaryMembers,
  );

  const maxDependentMembersCount = membershipPlanType?.maxDependentMembersCount;
  const maxSpouseMembersCount = membershipPlanType?.maxSpouseMembersCount;

  const getAvailablePlanSeats = useCallback(
    (fieldsLength: number) => {
      const primaryMemberCount = 1;
      const currentPlanSeats =
        maxMembersPerPlan - fieldsLength - primaryMemberCount;
      return currentPlanSeats > 0 ? currentPlanSeats : 0;
    },
    [maxMembersPerPlan],
  );

  const ageRanges: Record<MemberTypeForAge, MemberTypeAgeRange> = useMemo(
    () => ({
      [MemberType.Dependent]: {
        maxAge: membershipPlanType?.dependentMaxAge,
        minAge: membershipPlanType?.dependentMinAge,
      },
      [MemberType.Spouse]: {
        maxAge: membershipPlanType?.spouseMaxAge,
        minAge: membershipPlanType?.spouseMinAge,
      },
    }),
    [membershipPlanType],
  );

  useEffect(
    () => () => !isRenewalMode && onFamilyMembersChange([]),
    [isRenewalMode, onFamilyMembersChange],
  );

  const prepareMemberTypeOptions = useCallback(
    (fields: any, index: number) => {
      if (!fields.value) {
        return [MemberType.Dependent, MemberType.Spouse];
      }
      const resultMemberTypes: MemberType[] = [];
      const currentItem = fields.value[index].dependence;

      if (
        getCountOfItems(fields.value, MemberType.Dependent, 'dependence') <
          maxDependentMembersCount ||
        currentItem === MemberType.Dependent
      ) {
        resultMemberTypes.push(MemberType.Dependent);
      }

      if (
        getCountOfItems(fields.value, MemberType.Spouse, 'dependence') <
          maxSpouseMembersCount ||
        currentItem === MemberType.Spouse
      ) {
        resultMemberTypes.push(MemberType.Spouse);
      }
      return resultMemberTypes;
    },
    [maxDependentMembersCount, maxSpouseMembersCount],
  );

  return {
    primaryMember,
    ageRanges,
    getAvailablePlanSeats,
    prepareMemberTypeOptions,
  };
};
