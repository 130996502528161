import { JSXElementConstructor, ReactElement, ReactNode } from 'react';

import {
  SelectedCounter,
  SelectedItemValue,
  SelectWrapper,
} from './valueContainer.styles';

import { MultiInputType } from '../../../../../models/enums/MultiInputType';
import { useMultiSelectContainer } from './MultiSelectContainer.hooks';

interface ISelectContainerWrapper {
  placeholder: string;
  values: ReactElement<any, string | JSXElementConstructor<any>>[];
  type: MultiInputType;
  valueRenderer?: (value: any) => ReactNode;
}

export const MultiSelectContainer = ({
  placeholder,
  values,
  type,
  valueRenderer,
}: ISelectContainerWrapper) => {
  const { joinedValues, ref } = useMultiSelectContainer(values, valueRenderer);

  switch (type) {
    case MultiInputType.Default:
      return (
        <SelectWrapper>
          <SelectedItemValue>{placeholder}</SelectedItemValue>
          <SelectedCounter>{values.length}</SelectedCounter>
        </SelectWrapper>
      );
    case MultiInputType.InputSelect:
      return (
        <SelectWrapper isMultiInputType ref={ref}>
          <SelectedItemValue>{joinedValues}</SelectedItemValue>
          <SelectedCounter>{values.length}</SelectedCounter>
        </SelectWrapper>
      );

    default:
      return <>values</>;
  }
};
