import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import BaseInput from '../../BaseInput/BaseInput';
import NumberInput from '../../NumberInput/NumberInput';

type StyledFormInputType = {
  error?: boolean;
};

export const StyledNumberInput = styled(NumberInput)<StyledFormInputType>`
  width: 100%;

  ${({ error, theme }) =>
    error &&
    css`
      ${BaseInput} {
        border-color: ${theme.color.error};
      }
    `}
`;
