import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormSelect } from '../../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { getFieldPropertiesForGroup } from '../../../../../../../utils/getFormGroupPath';

import { IOrganizationSubscriptionPlanModel } from '../../../../../../../models/OrganizationSubscriptionPlanModel';

import { IOrganizationSearch } from '../../../../../../../models/interfaces/SearchOrganization';
import { formatDate } from '../../../../../../../utils/dateHelpers';
import { FormColumn } from '../../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../../styledComponents/UI/Form/FormRow/FormRow';
import FormDatePicker from '../../../../../../../styledComponents/UI/Form/FormDatePicker/FormDatePicker';
import { FormSelectAsync } from '../../../../../../../styledComponents/UI/Form/FormSelectAsync/FormSelectAsync';

import FormValue from '../../../../../../../styledComponents/UI/Form/FormValue/FormValue';

import { CustomForm } from '../../../../../../../models/types/CustomForm';
import {
  formName,
  SRMembershipOrganizationDetailsFormFields,
} from './srMembershipDetailsForm.models';
import { useSRMembershipDetails } from './srMembershipDetailsForm.hooks';
import {
  StyledFormMessage,
  StyledSectionWrapper,
} from './srMembershipDetailsForm.styles';

const SRMembershipDetailsForm = ({ className, disabled }: CustomForm) => {
  const { t } = useTranslation();
  const {
    subscriptionsOptions,
    organizationSubscriptionPlansPromiseOptions,
    selectedPlan,
    organizationsPromiseOptions,
  } = useSRMembershipDetails();

  return (
    <StyledSectionWrapper className={className}>
      <FormColumn>
        <FormRow isSubRow>
          <Field
            component={FormSelectAsync}
            isClearable
            disabled={disabled}
            label={t('fields.organizationName.label')}
            labelSelector={(value: IOrganizationSearch) => value.name}
            {...getFieldPropertiesForGroup({
              inputId: `${formName}`,
              name: SRMembershipOrganizationDetailsFormFields.Organization,
            })}
            placeholder={t('fields.organizationName.placeholder')}
            promiseOptions={organizationsPromiseOptions}
          />
          <Field
            component={FormSelect}
            disabled={disabled}
            isLabelHidden
            {...getFieldPropertiesForGroup({
              inputId: `${formName}`,
              name: SRMembershipOrganizationDetailsFormFields.SubscriptionType,
            })}
            options={subscriptionsOptions}
            placeholder={t('fields.subscriptionType.placeholder')}
            setDefaultValue
          />
        </FormRow>
        <FormRow isSubRow>
          <FormRow isSubRow>
            <Field
              component={FormSelect}
              disabled={disabled}
              isLabelHidden
              {...getFieldPropertiesForGroup({
                inputId: `${formName}`,
                name: SRMembershipOrganizationDetailsFormFields.MembershipPlanId,
              })}
              promiseOptions={organizationSubscriptionPlansPromiseOptions}
              placeholder={t('fields.membershipPlanId.placeholder')}
              valueIdSelector={(value: IOrganizationSubscriptionPlanModel) =>
                value.membershipPlanId
              }
            />
          </FormRow>
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: `${formName}`,
                name: SRMembershipOrganizationDetailsFormFields.EffectiveDate,
              })}
            />
            <Field
              label={t('fields.expirationDate.label')}
              component={FormValue}
              contentVisible
              {...getFieldPropertiesForGroup({
                inputId: `${formName}`,
                name: SRMembershipOrganizationDetailsFormFields.ExpirationDate,
              })}
              valueRenderer={(value: string) => formatDate(value)}
              value={null}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
      {selectedPlan && (
        <StyledFormMessage
          message={t(
            selectedPlan.availableSeats > 0
              ? 'phrase.seats_remaining'
              : 'phrase.seats_remaining_add_required',
            {
              value: selectedPlan.availableSeats,
            },
          )}
          status={selectedPlan.availableSeats > 0 ? 'success' : 'warning'}
        />
      )}
    </StyledSectionWrapper>
  );
};

export default SRMembershipDetailsForm;
