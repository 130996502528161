import {
  IMemberOverviewActiveMemberDetailProps,
  MemberOverviewActiveMemberDetailsByProductType,
} from './memberOverviewActiveMemberDetails.utils';

const MemberOverviewActiveMemberDetails = ({
  productType,
  ...props
}: IMemberOverviewActiveMemberDetailProps) => {
  const Content = MemberOverviewActiveMemberDetailsByProductType[productType];
  return <>{Content && <Content {...props} productType={productType} />}</>;
};

export default MemberOverviewActiveMemberDetails;
