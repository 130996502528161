import { useTranslation } from 'react-i18next';

import { MemberType } from '../../../../models/enums/MemberType';

import { CustomField } from '../../../../models/types/CustomField';
import { useFormAgeInfo } from './formAgeInfo.hooks';
import { StyledFormValueInfo } from './formAgeInfo.styles';

type FormAgeInfoProps = CustomField & {
  maxAge?: number;
  minAge?: number;
  memberType?: MemberType;
  date: string;
  localCalculateAge?: boolean;
};

const FormAgeInfo = ({
  maxAge,
  minAge,
  date,
  localCalculateAge,
  memberType,
  className,
}: FormAgeInfoProps) => {
  const { t } = useTranslation();
  const { ageLoading, currentAge } = useFormAgeInfo({
    date,
    localCalculateAge,
  });

  return (
    <StyledFormValueInfo
      className={className}
      isLoading={ageLoading}
      status={
        ((maxAge || minAge) && currentAge > maxAge) || currentAge < minAge
          ? 'error'
          : 'default'
      }
      label={t('fields.age.label')}
      tooltip={t(
        maxAge ? 'phrase.age_must_be_min_max' : 'phrase.age_must_be_min',
        {
          minValue: minAge,
          maxValue: maxAge,
          type: memberType,
        },
      )}
      value={currentAge ? currentAge.toString() : '-'}
    />
  );
};

export default FormAgeInfo;
