import { useTranslation } from 'react-i18next';

import { IPerson } from '../../../../models/Person';

import OverviewAddress from '../../../../styledComponents/Overview/OverviewAddress/OverviewAddress';

import OverviewProperty from '../../../../styledComponents/Overview/OverviewProperty/OverviewProperty';
import { SectionTitle } from '../../../../styledComponents/Typography/Typography';
import { formatDate } from '../../../../utils/dateHelpers';
import {
  Wrapper,
  DetailsContent,
} from './memberOverviewProfileBasicDetails.styles';

interface IMemberOverviewProfileBasicDetailsProps {
  person: IPerson;
}

const MemberOverviewProfileBasicDetails = ({
  person,
}: IMemberOverviewProfileBasicDetailsProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SectionTitle>{t('headers.profile-details')}</SectionTitle>
      <DetailsContent>
        <OverviewProperty
          title={t('properties.dateOfBirth')}
          value={person.dateOfBirth && formatDate(person.dateOfBirth)}
        />
        <OverviewProperty
          title={t('properties.age')}
          value={person.age?.toString()}
        />
        <OverviewProperty
          title={t('properties.gender')}
          value={person.gender && t(`enums.gender.${person.gender}`)}
        />
        <OverviewProperty
          title={t('properties.emailAddress')}
          value={person.contactEmailAddress}
        />
        <OverviewProperty
          title={t('properties.phoneNumber')}
          value={person.phone}
        />
        <OverviewProperty title={t('properties.residenceAddress')}>
          <OverviewAddress address={person.residenceAddress} />
        </OverviewProperty>
        <OverviewProperty title={t('properties.billingAddress')}>
          <OverviewAddress address={person.correspondenceAddress} />
        </OverviewProperty>
        <OverviewProperty
          title={t('properties.joinDate')}
          value={person.joinDate && formatDate(person.joinDate)}
        />
      </DetailsContent>
    </Wrapper>
  );
};

export default MemberOverviewProfileBasicDetails;
