import { ChangeEvent, useCallback, useMemo } from 'react';

import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { ISubscriptionMembershipPlan } from '../../../../../models/interfaces/CreateSubscriptionMembershipPlan';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { aaMembershipPlansPricesSelector } from '../../../../../store/redux-slices/pricing/airAmbulance/selectors';
import { aaSubscriptionRenewalSuggestionPlansSelector } from '../../../../../store/redux-slices/subscriptions/airAmbulance/selectors';
import { SubscriptionPricingFormFields } from '../../Common/SubscriptionRenewPricing/subscriptionPricing.models';
import { calculateCapacity } from '../../Common/SubscriptionRenewPricing/subscriptionPricing.utils';

export const useAARenewSubscriptionPricing = () => {
  const {
    input: { value: pricing, onChange: changeMembershipPricing },
  }: FieldRenderProps<IOrganizationSubscriptionPricingModel> = useField(
    SubscriptionPricingFormFields.Pricing,
  );

  const membershipPlansPrices = useSelector(aaMembershipPlansPricesSelector);
  const subscriptionRenewalSuggestionPlans = useSelector(
    aaSubscriptionRenewalSuggestionPlansSelector,
  );

  const {
    input: { value: transferAllEligibleMembershipsValue },
  } = useField(SubscriptionPricingFormFields.TransferAllEligibleMemberships);

  const membershipPlansPricesBySuggestion = useMemo(
    () =>
      membershipPlansPrices.map((plan) => {
        const currentSuggestionPlan = subscriptionRenewalSuggestionPlans?.find(
          (suggestionPlan) =>
            suggestionPlan.membershipPlanId === plan.membershipPlanId,
        );

        return {
          ...plan,
          minSeats: transferAllEligibleMembershipsValue
            ? currentSuggestionPlan?.capacitySuggestion
            : 0,
        };
      }),
    [
      membershipPlansPrices,
      subscriptionRenewalSuggestionPlans,
      transferAllEligibleMembershipsValue,
    ],
  );

  const suggestionsCapacity = useMemo(() => {
    const result = {};
    subscriptionRenewalSuggestionPlans.forEach((suggestion) => {
      result[suggestion.membershipPlanId] = {
        capacity: calculateCapacity(
          suggestion,
          transferAllEligibleMembershipsValue,
        ),
        price: 0,
      };
    });
    return result;
  }, [subscriptionRenewalSuggestionPlans, transferAllEligibleMembershipsValue]);

  const loadCapacityBySuggestions = useCallback(
    (transferAllEligibleMemberships: ChangeEvent<HTMLInputElement>) => {
      if (!pricing?.membershipPlans || !subscriptionRenewalSuggestionPlans) {
        return;
      }

      const newPlans: ISubscriptionMembershipPlan[] = [];

      pricing.membershipPlans.forEach((currentPlan) => {
        const currentSuggestionPlan = subscriptionRenewalSuggestionPlans.find(
          (suggestionPlan) =>
            suggestionPlan.membershipPlanId === currentPlan.membershipPlanId,
        );

        newPlans.push({
          ...currentPlan,
          capacity: calculateCapacity(
            currentSuggestionPlan,
            transferAllEligibleMemberships.target.checked,
          ),
        });
      });
      changeMembershipPricing({ ...pricing, membershipPlans: newPlans });
    },
    [pricing, subscriptionRenewalSuggestionPlans, changeMembershipPricing],
  );

  const membershipPlansPreviousPrices = useMemo(
    () =>
      subscriptionRenewalSuggestionPlans &&
      subscriptionRenewalSuggestionPlans.map((plan) => ({
        membershipPlanId: plan.membershipPlanId,
        price: plan.previousPrice,
      })),
    [subscriptionRenewalSuggestionPlans],
  );
  return {
    membershipPlansPreviousPrices,
    membershipPlansPrices: membershipPlansPricesBySuggestion,
    subscriptionRenewalSuggestionPlans,
    loadCapacityBySuggestions,
    suggestionsCapacity,
  };
};
