import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useApiResponseFail } from '../../../hooks/useApiResponseFail';
import { useModalDetails } from '../../../hooks/useModalDetails';
import { useProcessing } from '../../../hooks/useProcessing';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';

import { IUpdateOrganizationContractModel } from '../../../models/interfaces/UpdateOrganizationContractModel';
import { IWarningRatification } from '../../../models/interfaces/WarningRatification';
import {
  clearContractEditableFields,
  clearEditContractData,
} from '../../../store/redux-slices/contracts';
import {
  organizationContractEditableFieldsSelector,
  organizationContractEditDataSelector,
} from '../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../store/redux-slices/modals';
import { modalStateSelector } from '../../../store/redux-slices/modals/selectors';
import {
  ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING,
  ORGANIZATION_CONTRACT_UPDATING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import {
  loadContractEditableFields,
  loadOrganizationContractDataForModal,
  updateOrganizationContract,
} from '../../../store/saga-slices/contracts';
import {
  ILoadContractEditableFieldsPayload,
  ILoadOrganizationContractPayload,
  IUpdateOrganizationContractPayload,
} from '../../../store/saga-slices/contracts/models';
import { useFormErrorsActions } from '../../FormErrorProvider/FormErrorProvider.hooks';
import { validateContractDetailsForm } from '../../Forms/ContractForm/ContractDetailsForm/contractDetailsForm.validation';

import { EDIT_CONTRACT_DETAILS_MODAL_NAME } from './editContractDetailsModal.const';
import { EditOrganizationContractModalDetails } from './editContractDetailsModal.models';

type UseEditContractModalType = {
  onContractEdited?: () => void;
  onCancel?: () => void;
};

export const useEditContractModal = ({
  onContractEdited,
  onCancel,
}: UseEditContractModalType) => {
  const { t } = useTranslation();
  const cancellationToken = useCancellationToken();
  const UpdateModalState = useActions(updateModalState);
  const UpdateOrganizationContract = useActions(updateOrganizationContract);
  const LoadContractEditableFields = useActions(loadContractEditableFields);
  const LoadOrganizationContract = useActions(
    loadOrganizationContractDataForModal,
  );

  const modalIsOpen = useSelector(
    modalStateSelector(EDIT_CONTRACT_DETAILS_MODAL_NAME),
  );

  const initData = useSelector(organizationContractEditDataSelector);

  const ClearContractEditableFields = useActions(clearContractEditableFields);
  const ClearEditContractData = useActions(clearEditContractData);

  const editableFields = useSelector(
    organizationContractEditableFieldsSelector,
  );

  const {
    contractId,
    organizationId,
    data,
  }: EditOrganizationContractModalDetails = useModalDetails(
    EDIT_CONTRACT_DETAILS_MODAL_NAME,
  );

  const isProcessing = useProcessing(ORGANIZATION_CONTRACT_UPDATING_PROCESSING);
  const dataLoading = useProcessing([
    ORGANIZATION_CONTRACT_DATA_FOR_MODAL_LOADING_PROCESSING,
    ORGANIZATION_CONTRACT_EDITABLE_FIELDS_LOADING_PROCESSING,
  ]);

  const alert = useAlert();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponse } = useApiResponseFail();

  const loadOrganizationContractDetailsHandler = useCallback(() => {
    const payload: ILoadOrganizationContractPayload = {
      contractId,
      organizationId,
      cancellationToken,
    };
    LoadOrganizationContract(payload);
  }, [LoadOrganizationContract, cancellationToken, contractId, organizationId]);

  const loadContractEditableFieldsHandler = useCallback(() => {
    const payload: ILoadContractEditableFieldsPayload = {
      contractId,
      organizationId,
      cancellationToken,
    };

    LoadContractEditableFields(payload);
  }, [
    LoadContractEditableFields,
    cancellationToken,
    contractId,
    organizationId,
  ]);

  useEffect(() => {
    if (modalIsOpen) {
      loadOrganizationContractDetailsHandler();
      loadContractEditableFieldsHandler();
    }
  }, [
    loadContractEditableFieldsHandler,
    loadOrganizationContractDetailsHandler,
    modalIsOpen,
  ]);

  useEffect(
    () => () => {
      ClearContractEditableFields();
      ClearEditContractData();
    },
    [ClearEditContractData, ClearContractEditableFields],
  );

  const handleSubmit = (values: IUpdateOrganizationContractModel) => {
    updateContractHandler(values);
  };

  const confirmWarningsHandler = (
    values: IUpdateOrganizationContractModel,
    ratifications: IWarningRatification[] = [],
  ) => {
    const details: EditOrganizationContractModalDetails = {
      contractId,
      organizationId,
      data: values,
    };
    UpdateModalState([EDIT_CONTRACT_DETAILS_MODAL_NAME, details]);
    updateContractHandler(values, ratifications);
  };

  const cancelConfirmWarningsHandler = (
    values: IUpdateOrganizationContractModel,
  ) => {
    const details: EditOrganizationContractModalDetails = {
      contractId,
      organizationId,
      data: values,
    };
    UpdateModalState([EDIT_CONTRACT_DETAILS_MODAL_NAME, details]);
  };

  const updateContractHandler = (
    values: IUpdateOrganizationContractModel,
    ratifications: IWarningRatification[] = [],
  ) => {
    const extendedValues: IUpdateOrganizationContractModel = {
      ...values,
      ratifications,
    };
    const payload: IUpdateOrganizationContractPayload = {
      organizationId,
      contractId,
      contract: extendedValues,
      success: () => {
        UpdateModalState(EDIT_CONTRACT_DETAILS_MODAL_NAME);
        alert.success(
          t('alerts.contract-updated-success', { value: values.name }),
        );
        onContractEdited?.();
      },
      error: (err) => {
        handleResponse({
          err,
          values,
          ratifications,
          alertErrorMessage: t(`alerts.contract-updated-fail`),
        });
      },
    };

    UpdateOrganizationContract(payload);
  };

  const onCloseHandler = useCallback(() => {
    UpdateModalState(EDIT_CONTRACT_DETAILS_MODAL_NAME);
    onCancel && onCancel();
  }, [UpdateModalState, onCancel]);

  const validateForm = (values: IUpdateOrganizationContractModel) =>
    validateContractDetailsForm(values, validateErrors(values));

  return {
    isProcessing,
    handleSubmit,
    onCloseHandler,
    validateForm,
    editableFields,
    initData: data || initData,
    dataLoading,
    confirmWarningsHandler,
    cancelConfirmWarningsHandler,
  };
};
