import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import MaterialMenuItem from '@mui/material/MenuItem';

export const StyledMenu = styled(Menu)`
  ${({ theme }) => ({
    '& .MuiPaper-root': {
      minWidth: '14rem',
    },
  })}
`;

export const StyledMaterialMenuItem = styled(MaterialMenuItem)`
  font-family: ${({ theme }) => theme.font.gotham};
`;
