import styled from '@emotion/styled/macro';
import TableCell from '@mui/material/TableCell';

export const HeaderTableCell = styled(TableCell)`
  font: normal 700 0.75rem/1rem ${({ theme }) => theme.font.gotham};
  border-color: ${({ theme }) => theme.color.dark2};
  letter-spacing: 0.0125rem;
  color: ${({ theme }) => theme.color.dark2};

  .Mui-active {
    color: inherit !important;
  }
`;
