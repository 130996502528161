import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GroupedContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
