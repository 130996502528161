import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../models/ProductType';

import FormErrorProvider from '../../../styledComponents/FormErrorProvider/FormErrorProvider';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { ExtendSubscriptionExtensionsFormFields } from '../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/extendSubscriptionExtensionsForm.models';
import ExtendKESubscriptionForm from './ExtendKESubscriptionForm/ExtendKESubscriptionForm';
import { useExtendKESubscriptionPage } from './extendKESubscriptionPage.hooks';

const ExtendKESubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName } = useExtendKESubscriptionPage();
  return (
    <FormErrorProvider
      initErrorGroups={[
        ExtendSubscriptionExtensionsFormFields.MembershipPlanChanges,
      ]}
    >
      <>
        <Header
          title={t('headers.edit-seats-for-subscription', {
            organizationName,
            productType: ProductType.KidnapAndExtortion,
          })}
        />
        <ExtendKESubscriptionForm />
      </>
    </FormErrorProvider>
  );
};

export default ExtendKESubscriptionPage;
