import styled from '@emotion/styled';

import { HeaderWrapper } from '../../Header/Header';

import Button from '../../UI/Button/Button';

export const Wrapper = styled(HeaderWrapper)`
  display: flex;
  justify-content: space-between;
  padding: 0 2.5rem;
  margin-bottom: 2rem;
`;

export const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin-left: 2.5rem;
`;
