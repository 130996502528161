import { useEffect } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useDebounce } from '../../../hooks/useDebounce';
import { useProducts } from '../../../hooks/useProducts';
import { CreateOrganizationSubscriptionsType } from '../../../models/types/CreateOrganizationSubscriptionsType';
import { GetSubscriptionSummaryType } from '../../../models/types/GetSubscriptionSummaryType';
import { ILoadSubscriptionsSummary } from '../../../store/saga-slices/subscriptions/airAmbulance/models';
import { loadSubscriptionsSummary } from '../../../store/saga-slices/subscriptions/common';
import { SubscriptionsFormFields } from './subscriptionsForm.models';
import {
  AddSubscriptionsPagePermissions,
  prepareSummaryItem,
} from './subscriptionsForm.utils';

export const useSubscriptionsForm = () => {
  const { id: organizationId } = useParams();

  const LoadSubscriptionsSummary = useActions(loadSubscriptionsSummary);
  const { debounce } = useDebounce();

  const {
    input: { value: subscriptions },
  }: FieldRenderProps<CreateOrganizationSubscriptionsType> = useField(
    SubscriptionsFormFields.Subscriptions,
  );

  const { filteredProductList } = useProducts({
    pagesPermissions: AddSubscriptionsPagePermissions,
    excludedProducts: subscriptions.map((x) => x.productType).filter((x) => x),
  });

  useEffect(() => {
    if (!subscriptions?.length) {
      return;
    }

    const summarySubscriptions: GetSubscriptionSummaryType[] = subscriptions
      .map((subscription) => prepareSummaryItem(subscription))
      .filter((x) => x);

    const payload: ILoadSubscriptionsSummary = {
      data: { subscriptions: summarySubscriptions },
      organizationId,
    };

    debounce(() => LoadSubscriptionsSummary(payload), 300);
  }, [LoadSubscriptionsSummary, debounce, organizationId, subscriptions]);

  return { filteredProductList };
};
