import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { IUpdatePersonModel } from '../../../../models/interfaces/UpdatePersonModel';
import { IPerson } from '../../../../models/Person';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { updatePersonSelector } from '../../../../store/redux-slices/people/selectors';
import {
  PERSON_LOADING_PROCESSING,
  PERSON_UPDATE_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';
import {
  loadPerson,
  updatePersonData,
} from '../../../../store/saga-slices/people';
import {
  ILoadPersonPayload,
  IUpdatePersonDataPayload,
} from '../../../../store/saga-slices/people/models';
import { includeRemovedProperties } from '../../../../utils/formInitValuesHelpers';
import { formAddressInformationsInitState } from '../../../Forms/FormAddressInformations/formAddressInformations.models';
import { EDIT_MEMBER_MODAL_NAME } from '../editMemberModal.const';
import { FormAddressInformationsFields } from './editMemberModalForm.models';
import { validateEditMemberForm } from './editMemberModalForm.validation';

type UseEditMemberModalFormProps = {
  onMemberEdited?: (person: IPerson) => void;
};

export const useEditMemberModalForm = ({
  onMemberEdited,
}: UseEditMemberModalFormProps) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const LoadPerson = useActions(loadPerson);
  const UpdatePersonData = useActions(updatePersonData);
  const UpdateModalState = useActions(updateModalState);

  const person = useSelector(updatePersonSelector);
  const personId = useModalDetails(EDIT_MEMBER_MODAL_NAME);

  const personLoading = useProcessing(PERSON_LOADING_PROCESSING);
  const dataProcessing = useProcessing(PERSON_UPDATE_PROCESSING);

  const cancellationToken = useCancellationToken();

  const { validateErrors, clearErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError();

  const loadPersonData = useCallback(() => {
    if (!personId) {
      return;
    }

    const payload: ILoadPersonPayload = {
      personId,
    };

    LoadPerson(payload);
  }, [LoadPerson, personId]);

  const submitHandler = (values: IUpdatePersonModel) => {
    const payload: IUpdatePersonDataPayload = {
      personId,
      data: values,
      cancellationToken,
      success: (responsePerson: IPerson) => {
        UpdateModalState(EDIT_MEMBER_MODAL_NAME);
        onMemberEdited && onMemberEdited({ ...responsePerson, personId });
        alert.success(t('phrase.person-updated-success'));
      },
      error: (err) => {
        handleResponseError(err, values);
        alert.error(t('phrase.person-updated-fail'));
      },
    };

    UpdatePersonData(payload);
  };

  const onCancelHandler = () => {
    UpdateModalState(EDIT_MEMBER_MODAL_NAME);
    clearErrors();
  };

  const isSecondaryMember = !person?.metadata.isEmailRequired;

  const initState = {
    ...formAddressInformationsInitState(FormAddressInformationsFields),
    isSecondaryMember,
  };

  const formInitState = person ? { ...person, isSecondaryMember } : initState;

  const validateForm = (data: IUpdatePersonModel) => {
    const currentData = includeRemovedProperties(initState, data);
    return validateEditMemberForm(currentData, validateErrors(currentData));
  };

  const personName = [person?.firstName, person?.lastName].join(' ');

  return {
    person,
    loadPersonData,
    personLoading,
    submitHandler,
    dataProcessing,
    formInitState,
    validateForm,
    onCancelHandler,
    personName,
  };
};
