import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type WrapperProps = {
  isClickable?: boolean;
};

export const CardListItemWrapper = styled.div<WrapperProps>`
  display: flex;
  padding: 1.5rem 2.25rem 1.5rem 1.5rem;
  border: 0.063rem solid ${({ theme }) => theme.color.light1};
  border-radius: 0.5rem;

  ${({ isClickable, theme }) =>
    isClickable &&
    css`
      &:hover {
        border: 0.063rem solid ${theme.color.secondary2};
        background-color: ${theme.color.hover};
        cursor: pointer;
      }
    `}
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
`;
