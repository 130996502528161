import { RoutePath } from '../../../models/enums/RoutePath';
import { AvailableProductsType } from '../../../models/types/AvailableProductViewsPermissionsType';

export const Views: AvailableProductsType = {
  General: RoutePath.ProductGeneralPage,
  AirAmbulance: RoutePath.ProductAirAmbulancePage,
  KidnapAndExtortion: RoutePath.ProductKidnapAndExtortionPage,
  SecurityResponse: RoutePath.ProductSecurityResponsePage,
  DutyOfCare: RoutePath.ProductDutyOfCarePage,
};
