import { css } from '@emotion/react';

import styled from '@emotion/styled';

import { PromoCodeStatus } from '../../../models/enums/PromoCodeStatus';
import { DotBefore } from '../Dot/Dot';

type PromoCodeItemStatusContainerType = {
  status: PromoCodeStatus;
};

export const PromoCodeItemStatusContainer = styled(
  DotBefore,
)<PromoCodeItemStatusContainerType>`
  font: 400 normal 0.875rem/0.875rem ${({ theme }) => theme.font.gotham};
  text-transform: lowercase;
  margin-left: 1rem;
  position: relative;

  ${({ status, theme }) => {
    switch (status) {
      case PromoCodeStatus.Enabled:
        return css`
          color: ${theme.color.success};
          &::before {
            background-color: ${theme.color.success};
          }
        `;
      case PromoCodeStatus.Disabled:
        return css`
          color: ${theme.color.error};
          &::before {
            background-color: ${theme.color.error};
          }
        `;
      case PromoCodeStatus.Expired:
        return css`
          color: ${theme.color.dark2};
          &::before {
            background-color: ${theme.color.dark2};
          }
        `;
    }
  }}
`;
