import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { withErrorProvider } from '../../../hoc/withErrorProvider';
import { withWarningsConfirmationProvider } from '../../../hoc/withWarningsConfirmationProvider';

import FormErrorActivator from '../../FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { Modal } from '../../Modal/Modal';
import ModalFormPage from '../../ModalFormPage/ModalFormPage';
import WarningsConfirmationModal from '../../WarningsConfirmation/WarningsConfirmationModal/WarningsConfirmationModal';
import { EDIT_CONTRACT_DETAILS_MODAL_NAME } from './editContractDetailsModal.const';

import { useEditContractModal } from './editContractDetailsModal.hooks';
import { StyledContractForm } from './editContractDetailsModal.styles';

interface IEditContractDetailsModalProps {
  onContractEdited?: () => void;
  onModalCancel?: () => void;
}

const EditContractDetailsModal = ({
  onContractEdited,
  onModalCancel,
}: IEditContractDetailsModalProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    isProcessing,
    onCloseHandler,
    validateForm,
    editableFields,
    initData,
    dataLoading,
    confirmWarningsHandler,
    cancelConfirmWarningsHandler,
  } = useEditContractModal({ onCancel: onModalCancel, onContractEdited });

  return (
    <>
      <WarningsConfirmationModal
        onWarningsConfirm={confirmWarningsHandler}
        onCancel={cancelConfirmWarningsHandler}
      />
      <Modal
        disabled={isProcessing || dataLoading}
        name={EDIT_CONTRACT_DETAILS_MODAL_NAME}
        onModalClose={onModalCancel}
      >
        <Form
          initialValues={initData}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {({ ...renderProps }) => (
            <FormErrorActivator>
              <ModalFormPage
                handleSubmit={renderProps.handleSubmit}
                isProcessing={isProcessing}
                disabled={dataLoading}
                canSaveDraft={initData?.isDraft}
                onCancel={onCloseHandler}
                title={t('headers.edit-contract')}
              >
                <StyledContractForm
                  editableFields={editableFields}
                  disabled={isProcessing || dataLoading}
                  editableMode
                />
              </ModalFormPage>
            </FormErrorActivator>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default withErrorProvider(
  withWarningsConfirmationProvider(EditContractDetailsModal),
);
