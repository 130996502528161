import { useTranslation } from 'react-i18next';

import NotFoundImg from '../../../../../assets/images/contracts-not-found.png';
import Button from '../../../../../styledComponents/UI/Button/Button';
import { Wrapper, Text } from './contractsNotFound.styles';

interface IContractNotFoundProps {
  onAddContract?: () => void;
}

const ContractNotFound = ({ onAddContract }: IContractNotFoundProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <img src={NotFoundImg} alt="" />
      <Text>{t('phrase.no-contracts-found')}</Text>
      <Button onClick={onAddContract} styleType="lighter-blue">
        {t('buttons.add-contract')}
      </Button>
    </Wrapper>
  );
};

export default ContractNotFound;
