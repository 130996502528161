import Price from '../../../UI/Price/Price';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';

export interface ICurrencyRenderer extends ICellRenderer {
  price?: number;
}

export const CurrencyRenderer = ({ options, price }: ICurrencyRenderer) => (
  <TableCell bold={options?.textBold} alignment={options?.alignment}>
    <Price price={price} />
  </TableCell>
);
