import { usePermissions } from '../../hooks/usePermissions';
import { CustomComponent } from '../../models/types/CustomComponent';

type PermissionsGuardProps = CustomComponent & {
  permissions?: string[];
};

const PermissionsGuard = ({ children, permissions }: PermissionsGuardProps) => {
  const { hasPermissions } = usePermissions(permissions);

  if (!hasPermissions) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionsGuard;
