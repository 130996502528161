import aaPricingSaga from './airAmbulance';
import kePricingSaga from './kidnapAndExtortion';
import srPricingSaga from './securityResponse';
import tlPricingSaga from './travelLicense';

const pricingSagasRoot = [
  aaPricingSaga(),
  kePricingSaga(),
  srPricingSaga(),
  tlPricingSaga(),
];
export default pricingSagasRoot;
