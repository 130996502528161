import { useTranslation } from 'react-i18next';

import AddContractModal from '../../styledComponents/Modals/AddContractModal/AddContractModal';
import AddProductSubscriptionForm from './AddProductSubscriptionForm/AddProductSubscriptionForm';
import FormErrorProvider from '../../styledComponents/FormErrorProvider/FormErrorProvider';
import { useAddProductSubscription } from './addProductSubscription.hooks';
import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { SubscriptionPricingFormFields } from '../../styledComponents/SubscriptionFormPage/FormSections/AirAmbulanceSubscription/SubscriptionPricingForm/subscriptionPricingForm.models';

const AddProductSubscriptionPage = () => {
  const { t } = useTranslation();
  const { organizationName, loadDatesForNewSubscriptionHandler } =
    useAddProductSubscription();

  return (
    <FormErrorProvider
      initErrorGroups={[SubscriptionPricingFormFields.Pricing]}
    >
      <AddContractModal />
      <Header
        title={t('headers.add-product-subscription', {
          organizationName,
        })}
      />

      <AddProductSubscriptionForm
        onContractChanged={loadDatesForNewSubscriptionHandler}
      />
    </FormErrorProvider>
  );
};

export default AddProductSubscriptionPage;
