import { useTranslation } from 'react-i18next';

import { IOrganizationSubscriptionExtensionSummaryModel } from '../../../../../../models/interfaces/Subscription/OrganizationSubscriptionExtensionSummaryModel';
import { ProductType } from '../../../../../../models/ProductType';
import { CustomComponent } from '../../../../../../models/types/CustomComponent';
import SummaryItemContent from '../../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemContent/SummaryItemContent';
import SummaryItemSubtotal from '../../../../../../styledComponents/SummaryContainer/SummaryItem/SummaryItemSubtotal/SummaryItemSubtotal';
import {
  StyledSummaryItemContent,
  Wrapper,
} from './extendSubscriptionSummaryContent.styles';

interface IExtendSubscriptionSummaryContentProps extends CustomComponent {
  summaryData?: IOrganizationSubscriptionExtensionSummaryModel;
  productType?: ProductType;
  name?: string;
  subtotalValue?: number;
  isPriceOverridden?: boolean;
}

const SummaryContent = ({
  summaryData,
  productType,
  disabled,
  name,
  subtotalValue,
  isPriceOverridden,
}: IExtendSubscriptionSummaryContentProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {summaryData &&
        summaryData.addedPlans &&
        summaryData.addedPlans.length > 0 && (
          <SummaryItemContent
            data={summaryData?.addedPlans.map((x) => ({
              amount: x.amount,
              capacity: x.capacityChange,
              name: x.membershipName,
              price: x.price,
            }))}
            title={t('headers.add-subscription-extensions', {
              productType,
            })}
            isPriceOverridden={isPriceOverridden}
          />
        )}
      {summaryData &&
        summaryData.removedPlans &&
        summaryData.removedPlans.length > 0 && (
          <StyledSummaryItemContent
            data={summaryData?.removedPlans.map((x) => ({
              amount: x.amount,
              capacity: x.capacityChange,
              name: x.membershipName,
              price: x.price,
            }))}
            title={t('headers.delete-subscription-extensions', {
              productType,
            })}
            isPriceOverridden={isPriceOverridden}
          />
        )}
      <SummaryItemSubtotal
        isPriceOverridden={isPriceOverridden}
        disabled={disabled}
        name={name}
        subtotalValue={subtotalValue}
      />
    </Wrapper>
  );
};

export default SummaryContent;
