import styled from '@emotion/styled/macro';

import { ErrorLabel } from '../../../../UI/Field/Field';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledErrorLabel = styled(ErrorLabel)`
  margin: 0;
`;
