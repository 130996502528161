import { IApiValidationWarning } from '../../models/ValidationApiWarning';
import { IWarningRatification } from '../../models/interfaces/WarningRatification';

export const prepareConfirmedWarnings = (
  warnings: IApiValidationWarning[],
): IWarningRatification[] => {
  if (!warnings) {
    return [];
  }

  return warnings.map(prepareConfirmedWarning);
};

export const prepareConfirmedWarning = (
  warning: IApiValidationWarning,
): IWarningRatification => ({
  code: warning.Code,
  propertyName: warning.PropertyName,
});

export const checkIfPropertyIsDateByName = (propertyName: string) =>
  propertyName.toLowerCase().includes('date');
