import { createSelector } from '@reduxjs/toolkit';

import { IContractsState } from '.';
import { OrganizationContractType } from '../../../models/enums/ContractType';
import { IContractItem } from '../../../models/interfaces/ContractItem';
import { IContractListItem } from '../../../models/interfaces/ContractListItem';
import { IOrganizationContractDetailsModel } from '../../../models/interfaces/OrganizationContractDetailsModel';
import { IUpdateOrganizationContractModel } from '../../../models/interfaces/UpdateOrganizationContractModel';
import { RootState } from '../index';

export const contractsSelector = (state: RootState) => state.contracts;

export const organizationContractsSelector = createSelector(
  contractsSelector,
  (contracts: IContractsState): IContractItem[] =>
    contracts.availableOrganizationContracts,
);

export const organizationContractTypeByIdSelector = (contractId?: string) =>
  createSelector(
    contractsSelector,
    (contracts: IContractsState): OrganizationContractType => {
      const index = contracts?.availableOrganizationContracts.findIndex(
        (x) => x.id === contractId,
      );

      return index && index !== -1
        ? contracts?.availableOrganizationContracts[index].contractType
        : null;
    },
  );

export const organizationContractsListSelector = createSelector(
  contractsSelector,
  (contracts: IContractsState): IContractListItem[] =>
    contracts.availableOrganizationContractsList,
);

export const organizationContractDetailsSelector = createSelector(
  contractsSelector,
  (contracts: IContractsState): IOrganizationContractDetailsModel =>
    contracts.contractDetails,
);

export const organizationContractEditableFieldsSelector = createSelector(
  contractsSelector,
  (contracts: IContractsState): Record<string, boolean> =>
    contracts.contractEditableFields,
);

export const organizationContractEditDataSelector = createSelector(
  contractsSelector,
  (contracts: IContractsState): IUpdateOrganizationContractModel =>
    contracts.contractEditData,
);
