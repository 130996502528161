import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useNavigateBack } from '../../../hooks/useNavigateBack';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { isLastMainPageRouteSelector } from '../../../store/redux-slices/routesHistory/selectors';
import { SideBarNavigate } from '../../Sidebar/sidebar.const';

export const useHeaderPageDetails = (url: string) => {
  const { goBackMainPage } = useNavigateBack();
  const navigate = useNavigateSearch();
  const isBackRoute = useSelector(isLastMainPageRouteSelector);
  const location = useLocation();
  const state = location.state as { from: string };

  const backButtonNavigate = useCallback(() => {
    url ? navigate(url) : goBackMainPage();
  }, [goBackMainPage, navigate, url]);

  const isBackButtonVisible = isBackRoute && state?.from !== SideBarNavigate;

  return {
    backButtonNavigate,
    isBackButtonVisible,
  };
};
