import { Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useProducts } from '../../../../../../hooks/useProducts';

import { CustomForm } from '../../../../../../models/types/CustomForm';
import { membershipAvailableProductsSelector } from '../../../../../../store/redux-slices/membership/common/selectors';
import {
  BasicFormWrapper,
  SecondSectionTitle,
} from '../../../../../../styledComponents/Form/Form';
import { FormColumn } from '../../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../../styledComponents/UI/Form/FormRow/FormRow';
import { FormSelect } from '../../../../../../styledComponents/UI/Form/FormSelect/FormSelect';
import { getFieldPropertiesForGroup } from '../../../../../../utils/getFormGroupPath';
import { AddMembershipPagePermissions } from './basicMembershipForm.const';
import {
  BasicMembershipFormFields,
  BasicMembershipFormName,
} from './basicMembershipForm.models';

interface IBasicSubscriptionFormProps extends CustomForm {
  title?: string;
  onProductTypeChanged?: () => void;
}

const BasicSubscriptionForm = ({
  className,
  disabled,
  title,
  name,
  onProductTypeChanged,
}: IBasicSubscriptionFormProps) => {
  const { t } = useTranslation();
  const products = useSelector(membershipAvailableProductsSelector);
  const { filteredProductOptions } = useProducts({
    pagesPermissions: AddMembershipPagePermissions,
    products,
  });
  return (
    <BasicFormWrapper className={className}>
      {title && <SecondSectionTitle>{title}</SecondSectionTitle>}
      <FormColumn>
        <FormRow>
          <Field
            component={FormSelect}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: BasicMembershipFormName,
              groupName: name,
              name: BasicMembershipFormFields.ProductType,
            })}
            label={t('fields.subscriptionType.label')}
            options={filteredProductOptions}
            setDefaultValue
            onItemChanged={onProductTypeChanged}
          />
        </FormRow>
      </FormColumn>
    </BasicFormWrapper>
  );
};

export default BasicSubscriptionForm;
