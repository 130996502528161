/* eslint-disable react/no-array-index-key */

import { Trans, useTranslation } from 'react-i18next';

import {
  ButtonsContainer,
  ModalTitle,
  SimpleModal,
  SimpleModalButton,
} from '../../ModalCommon/ModalCommon';
import { useWarningsConfirmationModal } from '../warningsConfirmation.hooks';
import { WarningsModalProps } from '../warningsConfirmation.models';
import ModalWarning from './ModalWarning/ModalWarning';
import { WARNINGS_CONFIRMATION_MODAL_NAME } from './warningsConfirmationModal.const';
import { TextWrapper, Warnings } from './warningsConfirmationModal.styles';

interface IWarningsConfirmationModalProps extends WarningsModalProps {
  message?: string;
}

const WarningsConfirmationModal = ({
  onWarningsConfirm,
  message,
  onCancel,
}: IWarningsConfirmationModalProps) => {
  const { t } = useTranslation();
  const { data, warnings, confirmActionHandler, cancelActionHandler } =
    useWarningsConfirmationModal({ onWarningsConfirm, onCancel });

  return (
    <SimpleModal name={WARNINGS_CONFIRMATION_MODAL_NAME}>
      <ModalTitle>{t('headers.confirmation-required')}</ModalTitle>
      <TextWrapper>
        {message ? (
          <Trans>{message}</Trans>
        ) : (
          <Warnings>
            {warnings?.map((warning, index) => (
              <ModalWarning data={data} warning={warning} key={index} />
            ))}
          </Warnings>
        )}
      </TextWrapper>
      <ButtonsContainer>
        <SimpleModalButton styleType="light1" onClick={confirmActionHandler}>
          {t(`buttons.confirm`).toLocaleUpperCase()}
        </SimpleModalButton>
        <SimpleModalButton onClick={cancelActionHandler}>
          {t(`buttons.cancel`)}
        </SimpleModalButton>
      </ButtonsContainer>
    </SimpleModal>
  );
};

export default WarningsConfirmationModal;
