import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ORGANIZATION_NOTES_EDITING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import { EDIT_CONTRACT_NOTES_MODAL_NAME } from './editContractNotesModal.const';
import EditContractModalForm from './EditContractNotesModalForm/EditContractNotesModalForm';

interface IEditContractNotesModalProps {
  onNotesEdited?: () => void;
}

const EditContractNotesModal = ({
  onNotesEdited,
}: IEditContractNotesModalProps) => {
  const isProcessing = useProcessing(ORGANIZATION_NOTES_EDITING_PROCESSING);
  return (
    <Modal name={EDIT_CONTRACT_NOTES_MODAL_NAME} disabled={isProcessing}>
      <EditContractModalForm onNotesEdited={onNotesEdited} />
    </Modal>
  );
};

export default EditContractNotesModal;
