import { ToolsWrapper } from '../../../SimplePage/Tools';
import SRMembersFilters from '../SRMembersFilters/SRMembersFilters';
import { SRMembersFiltersProps } from '../SRMembersFilters/srMembersFilters.models';

export type SRMembersToolsProps = SRMembersFiltersProps;

const SRMembersTools = ({
  availableFilters,
  standardPricing,
}: SRMembersToolsProps) => (
  <ToolsWrapper>
    <SRMembersFilters
      availableFilters={availableFilters}
      standardPricing={standardPricing}
    />
  </ToolsWrapper>
);

export default SRMembersTools;
