import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';

import Spinner from '../../../styledComponents/Spinner/Spinner';
import CorporationInformations from '../../../styledComponents/Forms/CorporationInformationsForm/CorporationInformationsForm';
import CorpPointOfContactForm from '../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactsForm';
import GGPointOfContactForm from '../../../styledComponents/Forms/GGPointOfContactsForm/GGPointOfContactsForm';
import { initialValues } from './addClientForm.const';
import { useClientForm } from './addClientForm.hooks';
import {
  ClientForm,
  StyledButton,
  StyledOrganizationTypeForm,
} from './addClientForm.styles';
import FormErrorActivator from '../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';
import { focusOnErrorDecorator } from '../../../utils/focusOnErrorDecorator';
import { Line } from '../../../styledComponents/UI/Line/Line';
import AdditionalInformation from '../AddClientForms/AdditionalInformation/AdditionalInformation';
import { AddClientFormFields } from './addClientForm.models';
import ContractsForm from '../../../styledComponents/Forms/ContractsForm/ContractsForm';

export const AddClientForm = () => {
  const { t } = useTranslation();

  const {
    employees,
    rolesList,
    rolesLoading,
    ggRolesList,
    ggRolesLoading,
    isAddingOrganization,
    isLoadingEmployees,
    submitForm,
    validateForm,
  } = useClientForm();

  return (
    <Form
      decorators={[focusOnErrorDecorator]}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={submitForm}
      validate={validateForm}
    >
      {({ handleSubmit }) => (
        <FormErrorActivator>
          <Spinner
            isVisible={isLoadingEmployees || rolesLoading || ggRolesLoading}
            size="10%"
          >
            <ClientForm onSubmit={handleSubmit}>
              <StyledOrganizationTypeForm disabled={isAddingOrganization} />
              <Line />
              <CorporationInformations
                disabled={isAddingOrganization}
                title={t('headers.corporate-information-title')}
              />
              <CorpPointOfContactForm
                name={AddClientFormFields.OrganizationPoc}
                disabled={isAddingOrganization}
                title={t('headers.point-of-contact-title')}
                rolesList={rolesList}
              />
              <AdditionalInformation disabled={isAddingOrganization} />
              <Line />
              <GGPointOfContactForm
                disabled={isAddingOrganization}
                name={AddClientFormFields.GlobalGuardianPoc}
                employeeList={employees}
                ggRolesList={ggRolesList}
                title={t('headers.gg-point-of-contact-title')}
              />
              <Line />
              <ContractsForm
                name={AddClientFormFields.OrganizationContracts}
                disabled={isAddingOrganization}
                title={t('headers.contract-information-title')}
              />
              <StyledButton isLoading={isAddingOrganization} type="submit">
                {t('buttons.add-organization')}
              </StyledButton>
            </ClientForm>
          </Spinner>
        </FormErrorActivator>
      )}
    </Form>
  );
};
