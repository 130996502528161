import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  organizationBillingAddressSelector,
  organizationCorrespondenceAddressSelector,
  organizationSelector,
} from '../../../../../store/redux-slices/organizations/selectors';
import OverviewAddress from '../../../../../styledComponents/Overview/OverviewAddress/OverviewAddress';
import OverviewProperty from '../../../../../styledComponents/Overview/OverviewProperty/OverviewProperty';
import {
  ButtonsContainer,
  StyledButton,
  Wrapper,
} from './overviewBasicDetails.styles';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import {
  canDeleteOrganizationPermissions,
  canEditOrganizationPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';

interface IOverviewBasicDetailsProps extends CustomComponent {
  onDelete?: () => void;
  onEdit?: () => void;
}

const OverviewBasicDetails = ({
  className,
  onDelete,
  onEdit,
}: IOverviewBasicDetailsProps) => {
  const { t } = useTranslation();
  const organization = useSelector(organizationSelector);
  const organizationBillingAddress = useSelector(
    organizationBillingAddressSelector,
  );

  const organizationCorrespondenceAddress = useSelector(
    organizationCorrespondenceAddressSelector,
  );

  return (
    <Wrapper className={className}>
      <OverviewProperty
        title={t('headers.category-name')}
        value={organization.name}
      />
      <OverviewProperty title={t('headers.category-billing-address')}>
        <OverviewAddress address={organizationBillingAddress} />
      </OverviewProperty>
      <OverviewProperty title={t('headers.category-correspondence-address')}>
        <OverviewAddress address={organizationCorrespondenceAddress} />
      </OverviewProperty>
      <ButtonsContainer>
        <StyledButton
          permissions={canEditOrganizationPermissions}
          styleType="light1"
          onClick={onEdit}
        >
          {t('buttons.edit')}
        </StyledButton>

        {organization.actionsConsent.canDelete && (
          <StyledButton
            permissions={canDeleteOrganizationPermissions}
            onClick={onDelete}
            styleType="delete"
          >
            {t('buttons.delete')}
          </StyledButton>
        )}
      </ButtonsContainer>
    </Wrapper>
  );
};

export default OverviewBasicDetails;
