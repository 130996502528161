import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { ReactComponent as IconDateExpired } from '../../../../assets/icons/date-expired-icon.svg';
import TableCell from '../TableCell/TableCell';

type WrapperProps = {
  isExpired?: boolean;
};

export const StyledTableCell = styled(TableCell)`
  gap: 0rem;
`;

export const DateExpiredWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  ${({ isExpired, theme }) =>
    isExpired &&
    css`
      color: ${theme.color.dark2};
    `}
`;

export const Text = styled.div`
  font-size: 0.825rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.color.dark2};
  padding-left: 0.75rem;
`;

export const ClockIcon = styled(IconDateExpired)`
  fill: ${({ theme }) => theme.color.dark2};
`;
