import { useEffect, useMemo } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { PromoCodeType } from '../../../../models/enums/PromoCodeType';

export const useAddPromoCodeDetails = () => {
  const {
    input: { value: type },
  }: FieldRenderProps<string> = useField('type');

  const {
    input: { onChange: ownerChange },
  }: FieldRenderProps<string> = useField('owner');

  useEffect(() => {
    if (type === PromoCodeType.General) {
      ownerChange(null);
    }
  }, [ownerChange, type]);

  const ownerDisabled = useMemo(() => type === PromoCodeType.General, [type]);

  return { ownerDisabled };
};
