export const getCurrentDate = (oneMoreYear?: boolean) => {
  let currentDate = new Date();

  const offset = currentDate.getTimezoneOffset();
  currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

  if (oneMoreYear) {
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }

  return currentDate.toISOString().split('T')[0];
};
