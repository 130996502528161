import { useTranslation } from 'react-i18next';

import { canAddSubscriptionsPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import {
  OverviewHeaderRightContainer,
  OverviewButton,
} from '../../../../../styledComponents/OverviewPage/OverviewPageHeader';
import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';
import { StyledOverviewHeader } from './productSubscriptionsTabPageHeader.styles';

type ProductSubscriptionsTabPageHeaderProps = {
  onAddSubscription?: () => void;
};

const ProductSubscriptionsTabPageHeader = ({
  onAddSubscription,
}: ProductSubscriptionsTabPageHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledOverviewHeader>
      <SectionTitle>{t('headers.subscriptions')}</SectionTitle>
      <OverviewHeaderRightContainer>
        <OverviewButton
          permissions={canAddSubscriptionsPermissions}
          onClick={onAddSubscription}
          styleType="lighter-blue"
        >
          {t('buttons.add-subscription')}
        </OverviewButton>
      </OverviewHeaderRightContainer>
    </StyledOverviewHeader>
  );
};

export default ProductSubscriptionsTabPageHeader;
