import axios, { AxiosResponse } from 'axios';

import config from '../../../config/config';
import {
  AirAmbulancePermissions,
  KidnapAndExtortionPermissions,
  ReportsPermissions,
  PaymentPermissions,
  PeopleManagementPermissions,
  SecurityResponsePermissions,
  TravelLicensePermissions,
} from '../../../models/enums/UserPermissions';

export const getAirAmbulanceUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<AirAmbulancePermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.airAmbulanceApi.baseUrl,
    signal: cancellationToken,
  });

export const getKidnapAndExtortionUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<KidnapAndExtortionPermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.kidnapAndExtortionApi.baseUrl,
    signal: cancellationToken,
  });

export const getSecurityResponseUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<SecurityResponsePermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.securityResponseApi.baseUrl,
    signal: cancellationToken,
  });

export const getDutyOfCareUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<SecurityResponsePermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });

export const getTravelLicenseUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<TravelLicensePermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.travelLicenseApi.baseUrl,
    signal: cancellationToken,
  });

export const getReportsUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ReportsPermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.reportsApi.baseUrl,
    signal: cancellationToken,
  });

export const getPeopleManagementUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<PeopleManagementPermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    signal: cancellationToken,
  });

export const getPaymentUserPermissions = (
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<PaymentPermissions[]>> =>
  axios.get(`/api/currentUser/permissions`, {
    baseURL: config.paymentApi.baseUrl,
    signal: cancellationToken,
  });
