/* eslint-disable react/no-array-index-key */
import { ReactNode } from 'react';

import { Wrapper } from './itemsList.styles';
import ItemList from './ListItem/ItemList';

type ItemsListProps<T> = {
  items: T[];
  itemSummary: (data: T) => ReactNode;
  itemDetails?: (data: T) => ReactNode;
  onItemClick?: (data: T) => void;
};

const ItemsList = <T,>({
  items,
  itemSummary,
  onItemClick,
  itemDetails,
}: ItemsListProps<T>) => (
  <Wrapper>
    {items &&
      items.map((item: T, index: number) => (
        <ItemList
          data={item}
          key={index}
          onItemClick={onItemClick}
          itemSummary={itemSummary && itemSummary(item)}
          itemDetails={itemDetails && itemDetails(item)}
        />
      ))}
  </Wrapper>
);

export default ItemsList;
