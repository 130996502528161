import { useTranslation } from 'react-i18next';

import { SubscriptionOptions } from '../../../Forms/SubscriptionOptions/SubscriptionOptions';
import { SubscriptionItemBaseProps } from '../../SubscriptionItemForm/subscriptionItemForm.models';
import { useAirAmbulanceSubscriptionForm } from './airAmbulanceSubscriptionForm.hooks';
import { Wrapper } from './airAmbulanceSubscriptionForm.styles';
import { subscriptionOptions } from './airAmbulanceSubscriptionForm.utils';
import SubscriptionDetailsForm from './SubscriptionDetailsForm/SubscriptionDetailsForm';
import SubscriptionPricingForm from './SubscriptionPricingForm/SubscriptionPricingForm';

const AirAmbulanceSubscriptionForm = ({
  name,
  disabled,
  className,
  isEditMode,
  contractType,
}: SubscriptionItemBaseProps) => {
  const { t } = useTranslation();

  useAirAmbulanceSubscriptionForm({ name, contractType });

  const options = subscriptionOptions(contractType);
  return (
    <Wrapper className={className}>
      <SubscriptionDetailsForm name={name} disabled={disabled} />
      <SubscriptionPricingForm
        name={name}
        disabled={disabled}
        isEditMode={isEditMode}
      />
      {options?.length !== 0 && (
        <SubscriptionOptions
          disabled={disabled}
          name={name}
          title={t('headers.options')}
          subscriptionOptions={options}
          layout="column"
        />
      )}
    </Wrapper>
  );
};

export default AirAmbulanceSubscriptionForm;
