import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../hooks/useActions';
import { useProcessing } from '../../../hooks/useProcessing';
import { ProductType } from '../../../models/ProductType';
import { ProductSubscriptionRenewPageParamsType } from '../../../models/types/RoutePath';
import { clearOrganizationContracts } from '../../../store/redux-slices/contracts';
import { organizationSelector } from '../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SR_PRICING_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { clearSubscriptionHelperData } from '../../../store/redux-slices/subscriptions/common';
import {
  clearSRCurrentSubscriptionEditing,
  clearSRSubscriptionHelperData,
} from '../../../store/redux-slices/subscriptions/securityResponse';
import {
  editingSRSubscriptionDataSelector,
  srSubscriptionRenewalSuggestionSelector,
} from '../../../store/redux-slices/subscriptions/securityResponse/selectors';
import { loadOrganizationContracts } from '../../../store/saga-slices/contracts';
import { loadOrganization } from '../../../store/saga-slices/organizations';
import { srLoadStandardPricing } from '../../../store/saga-slices/pricing/securityResponse';
import { ILoadDatesForNewSubscriptionPayload } from '../../../store/saga-slices/subscriptions/common/models';
import {
  loadDatesForNewSRSubscription,
  loadEditingSRSubscription,
  loadSRSubscriptionRenewalSuggestion,
} from '../../../store/saga-slices/subscriptions/securityResponse';
import {
  ILoadEditingSRSubscriptionPayload,
  ILoadSRSubscriptionRenewalSuggestionPayload,
} from '../../../store/saga-slices/subscriptions/securityResponse/models';

export const useSREditSubscriptionRenewPage = () => {
  const { organizationId, subscriptionId, productType } =
    useParams<ProductSubscriptionRenewPageParamsType>();

  const LoadOrganization = useActions(loadOrganization);
  const LoadOrganizationContracts = useActions(loadOrganizationContracts);
  const LoadDatesForNewSubscription = useActions(loadDatesForNewSRSubscription);
  const ClearOrganizationContracts = useActions(clearOrganizationContracts);
  const LoadEditingSubscription = useActions(loadEditingSRSubscription);
  const LoadStandardPricing = useActions(srLoadStandardPricing);
  const ClearSRSubscriptionHelperData = useActions(
    clearSRSubscriptionHelperData,
  );
  const subscriptionData = useSelector(editingSRSubscriptionDataSelector);

  const ClearCurrentSubscriptionEditing = useActions(
    clearSRCurrentSubscriptionEditing,
  );
  const ClearSubscriptionHelperData = useActions(clearSubscriptionHelperData);
  const LoadSubscriptionRenewalSuggestion = useActions(
    loadSRSubscriptionRenewalSuggestion,
  );

  const subscriptionRenewalSuggestion = useSelector(
    srSubscriptionRenewalSuggestionSelector,
  );

  const isOrganizationDataLoading = useProcessing(
    ORGANIZATION_LOADING_PROCESSING,
  );

  const isEditingDataLoading = useProcessing(
    SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  );

  const isSuggestionDataLoading = useProcessing(
    SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  );

  const isPricingDataLoading = useProcessing(SR_PRICING_LOADING_PROCESSING);
  const organization = useSelector(organizationSelector);

  const loadSubscriptionRenewalSuggestionHandler = useCallback(() => {
    const payload: ILoadSRSubscriptionRenewalSuggestionPayload = {
      organizationId,
      subscriptionId,
    };
    LoadSubscriptionRenewalSuggestion(payload);
  }, [LoadSubscriptionRenewalSuggestion, organizationId, subscriptionId]);

  const loadDatesForNewSubscriptionHandler = useCallback(
    (value: string, forceLoad = false) => {
      if (!value && !forceLoad) {
        return;
      }

      const loadDatesForNewSubscriptionPayload: ILoadDatesForNewSubscriptionPayload =
        {
          organizationId,
          contractId: value,
        };
      LoadDatesForNewSubscription(loadDatesForNewSubscriptionPayload);
    },
    [LoadDatesForNewSubscription, organizationId],
  );

  useEffect(() => {
    const payload: ILoadEditingSRSubscriptionPayload = {
      organizationId,
      subscriptionId,
      subscriptionType: productType,
    };

    LoadEditingSubscription(payload);
  }, [LoadEditingSubscription, organizationId, productType, subscriptionId]);

  useEffect(() => {
    LoadOrganizationContracts({ organizationId });
    LoadOrganization(organizationId);
    loadSubscriptionRenewalSuggestionHandler();

    LoadStandardPricing({ productType: ProductType.KidnapAndExtortion });
  }, [
    LoadOrganization,
    LoadOrganizationContracts,
    organizationId,
    subscriptionId,
    LoadStandardPricing,
    loadSubscriptionRenewalSuggestionHandler,
  ]);

  useEffect(
    () => () => {
      ClearSRSubscriptionHelperData();
      ClearOrganizationContracts();
      ClearSubscriptionHelperData();
      ClearCurrentSubscriptionEditing();
    },
    [
      ClearOrganizationContracts,
      ClearSRSubscriptionHelperData,
      ClearSubscriptionHelperData,
      ClearCurrentSubscriptionEditing,
    ],
  );

  const dataLoading =
    !organization ||
    isOrganizationDataLoading ||
    !subscriptionData ||
    (!subscriptionData && isEditingDataLoading) ||
    !subscriptionRenewalSuggestion ||
    (!subscriptionRenewalSuggestion && isSuggestionDataLoading) ||
    isPricingDataLoading;

  return {
    dataLoading,
    organization,
    loadDatesForNewSubscriptionHandler,
    loadSubscriptionRenewalSuggestionHandler,
  };
};
