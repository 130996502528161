import { AnySchema } from 'ajv';

import { ICreateMemberModel } from '../../../../../../models/CreateMemberModel';
import { FieldErrorMessage } from '../../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../../utils/validations/validateFormData';
import { PrimaryMemberDetailsFormFields } from './primaryMemberDetailsForm.models';

export const validatePrimaryMemberDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [PrimaryMemberDetailsFormFields.FirstName]: {
      type: 'string',
    },
    [PrimaryMemberDetailsFormFields.LastName]: {
      type: 'string',
    },
    [PrimaryMemberDetailsFormFields.EmailAddress]: {
      type: 'string',
      format: 'email',
    },
    [PrimaryMemberDetailsFormFields.PhoneNumber]: {
      type: 'string',
      format: 'phone',
    },
    [PrimaryMemberDetailsFormFields.Gender]: {
      type: 'string',
    },
  },
  required: [
    PrimaryMemberDetailsFormFields.EmailAddress,
    PrimaryMemberDetailsFormFields.DateOfBirth,
    PrimaryMemberDetailsFormFields.FirstName,
    PrimaryMemberDetailsFormFields.LastName,
  ],
};

export const validatePrimaryMemberForm = (
  data: ICreateMemberModel,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(data, additionalErrors, validatePrimaryMemberDetailsSchema);
