import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import { useProcessing } from '../../../../hooks/useProcessing';
import { MembershipStatusFilter } from '../../../../models/enums/MembershipStatusFilter';
import { clearPersonMemberships } from '../../../../store/redux-slices/people';
import { personMembershipsSelector } from '../../../../store/redux-slices/people/selectors';
import { PERSON_MEMBERSHIPS_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { loadPersonMemberships } from '../../../../store/saga-slices/people';
import { ILoadPersonMembershipsPayload } from '../../../../store/saga-slices/people/models';

type UseMemberSubscriptionsListProps = {
  personId: string;
};

export const useMemberSubscriptionsList = ({
  personId,
}: UseMemberSubscriptionsListProps) => {
  const LoadPersonMemberships = useActions(loadPersonMemberships);
  const ClearPersonMemberships = useActions(clearPersonMemberships);

  const dataLoading = useProcessing(PERSON_MEMBERSHIPS_LOADING_PROCESSING);

  const memberships = useSelector(personMembershipsSelector);

  useEffect(() => {
    if (!personId) {
      return;
    }
    const payload: ILoadPersonMembershipsPayload = {
      personId,
      filter: MembershipStatusFilter.ActiveAndFuture,
    };

    LoadPersonMemberships(payload);
  }, [LoadPersonMemberships, personId]);

  useEffect(() => () => ClearPersonMemberships(), [ClearPersonMemberships]);

  return { memberships, dataLoading };
};
