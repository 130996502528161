import { useCallback, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { IOrganizationSubscriptionCommonModel } from '../../../models/interfaces/Subscription/OrganizationSubscriptionCommonModel';

import { ProductType } from '../../../models/ProductType';
import { subscriptionDatesSelector } from '../../../store/redux-slices/subscriptions/common/selectors';

import { getFormGroupPath } from '../../../utils/getFormGroupPath';

import { BasicSubscriptionFormFields } from '../FormSections/BasicSubscriptionForm/basicSubscriptionForm.models';
import { SubscriptionItemBaseProps } from './subscriptionItemForm.models';

export const useSubscriptionItemForm = ({
  name,
  isEditMode,
}: SubscriptionItemBaseProps) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const {
    input: { value: subscriptionType },
  }: FieldRenderProps<ProductType> = useField(
    getFormGroupPath(name, BasicSubscriptionFormFields.ProductType),
  );

  const subscriptionDates = useSelector(
    subscriptionDatesSelector(subscriptionType),
  );

  const {
    input: { onChange },
  }: FieldRenderProps<IOrganizationSubscriptionCommonModel> = useField(name);

  const clearElement = useCallback(
    (productType: ProductType) => {
      if (isEditMode) {
        return;
      }
      const baseData: IOrganizationSubscriptionCommonModel = {
        productType,
        effectiveDateFrom: subscriptionDates?.effectiveDateFrom,
        effectiveDateTo: subscriptionDates?.effectiveDateTo,
        overwriteAmount: false,
      };
      onChange(baseData);
    },
    [
      isEditMode,
      subscriptionDates?.effectiveDateFrom,
      subscriptionDates?.effectiveDateTo,
      onChange,
    ],
  );

  const productTypeChangedHandler = useCallback(
    (productType: ProductType) => {
      setSpinnerVisible(true);
      clearElement(productType);
      setTimeout(() => {
        setSpinnerVisible(false);
      }, 1000);
    },
    [clearElement],
  );

  return { subscriptionType, productTypeChangedHandler, spinnerVisible };
};
