import { useTranslation } from 'react-i18next';

import ModalEditingContact from './Modals/EditingContactModal/EditContactModal';
import ModalAddingContact from './Modals/AddPointOfContactModal/AddPointOfContactModal';
import ModalEditingGGPoc from './Modals/EditingGGPointOfContactModal/EditGGPointOfContactModal';
import DeleteOrganizationModal from './Modals/DeleteOrganizationModal/DeleteOrganizationModal';
import DeleteOrganizationPointOfContactModal from './Modals/DeleteOrganizationPointOfContactModal/DeleteOrganizationPointOfContactModal';
import DeleteGGPointOfContactModal from './Modals/DeleteGGPointOfContactModal/DeleteGGPointOfContactModal';

import ContactsTable from '../../../../styledComponents/ContactsTable/ContactsTable';
import OverviewBasicDetails from './OverviewBasicDetails/OverviewBasicDetails';

import ModalClientDetails from './Modals/EditingClientDetailsModal/EditClientDetailsModal';
import { useOverviewTabPage } from './overviewTabPage.hooks';
import {
  canAddGGPOCPermissions,
  canAddPOCPermissions,
  canDeleteGGPOCPermissions,
  canDeletePOCPermissions,
  canEditGGPOCPermissions,
  canEditPOCPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { OverviewTabPage as OverviewTabPageWrapper } from '../../../../styledComponents/OverviewPage/OverviewPage';
import { PageSection } from '../../../../styledComponents/Page/Page';
import { StyledPageSection, TableContainer } from './overviewTabPage.styles';
import ModalAddGGContact from './Modals/AddGGContactModal/AddGGContactModal';
import {
  adminCellRenderer,
  ggPocRolesRenderer,
  pocRolesRenderer,
} from './overviewTabPage.utils';
import OverviewNotes from './OverviewNotes/OverviewNotes';
import EditNotesModal from './Modals/EditNotesModal/EditNotesModal';
import AddNotesModal from './Modals/AddNotesModal/AddNotesModal';

const OverviewTabPage = () => {
  const { t } = useTranslation();
  const {
    deleteGGPointOfContact,
    editGGPointOfContactHandler,
    addGGContact,
    deleteOrganizationPointOfContact,
    editOrganizationPointOfContactHandler,
    addOrganizationContact,
    organization,
    deleteOrganizationHandler,
    editClientDetailsHandler,
    onEditOrganizationNotes,
    onAddOrganizationNotes,
    loadOrganizationHandler,
  } = useOverviewTabPage();

  return (
    <>
      <EditNotesModal onNotesEdited={loadOrganizationHandler} />
      <AddNotesModal onNotesAdded={loadOrganizationHandler} />
      <ModalClientDetails />
      <ModalEditingContact />
      <ModalAddGGContact />
      <ModalAddingContact />
      <ModalEditingGGPoc />
      <DeleteOrganizationModal />
      <DeleteOrganizationPointOfContactModal />
      <DeleteGGPointOfContactModal />
      <OverviewTabPageWrapper>
        <PageSection>
          <OverviewBasicDetails
            onDelete={deleteOrganizationHandler}
            onEdit={editClientDetailsHandler}
          />
        </PageSection>

        <PageSection>
          <OverviewNotes
            notes={organization.notes}
            onAddOrganizationNotes={onAddOrganizationNotes}
            onEditOrganizationNotes={onEditOrganizationNotes}
          />
        </PageSection>

        <TableContainer>
          <StyledPageSection>
            <ContactsTable
              additionalColumns={[
                {
                  title: t('grid-columns.poc-roles'),
                  cellRenderer: pocRolesRenderer,
                  alignment: 'left',
                  width: '12rem',
                },
                {
                  cellRenderer: adminCellRenderer,
                  alignment: 'left',
                  width: '5rem',
                },
              ]}
              isPaginationHidden
              items={organization.organizationPointOfContacts}
              onAddContact={addOrganizationContact}
              onDeleteContact={deleteOrganizationPointOfContact}
              onEditContact={editOrganizationPointOfContactHandler}
              title={t('headers.corporate-contacts-and-admins')}
              canAddContactPermissions={canAddPOCPermissions}
              canDeleteContactPermissions={canDeletePOCPermissions}
              canEditContactPermissions={canEditPOCPermissions}
            />
          </StyledPageSection>
        </TableContainer>
        <TableContainer>
          <StyledPageSection>
            <ContactsTable
              additionalColumns={[
                {
                  title: t('grid-columns.gg-poc-roles'),
                  cellRenderer: ggPocRolesRenderer,
                  alignment: 'left',
                  width: '17rem',
                },
              ]}
              isPaginationHidden
              items={organization.ggPointOfContacts}
              minItems={1}
              onAddContact={addGGContact}
              onDeleteContact={deleteGGPointOfContact}
              onEditContact={editGGPointOfContactHandler}
              title={t('headers.global-guardian-point-of-contact')}
              canAddContactPermissions={canAddGGPOCPermissions}
              canDeleteContactPermissions={canDeleteGGPOCPermissions}
              canEditContactPermissions={canEditGGPOCPermissions}
            />
          </StyledPageSection>
        </TableContainer>
      </OverviewTabPageWrapper>
    </>
  );
};

export default OverviewTabPage;
