import { t } from 'i18next';

import { ITabNavigationItem } from '../../styledComponents/UI/TabNavigation/tabNavigation.models';

import { RoutePath } from '../../models/enums/RoutePath';
import {
  canViewFinancialReportPermissions,
  canViewRawDataReportPermissions,
  canGenerateRetailReportsPermissions,
} from '../../config/accessPolicies/accessPolicies';

export const tabsNavigation: Array<ITabNavigationItem> = [
  {
    label: t('tabsNavigation.finance'),
    url: RoutePath.ReportsFinance,
    permissions: canViewFinancialReportPermissions,
  },
  {
    label: t('tabsNavigation.raw-data'),
    url: RoutePath.ReportsRawData,
    permissions: canViewRawDataReportPermissions,
  },
  {
    label: t('tabsNavigation.retail-data'),
    url: RoutePath.ReportsRetailData,
    permissions: canGenerateRetailReportsPermissions,
  },
];
