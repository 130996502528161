import { useTranslation } from 'react-i18next';

import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { useAddMember } from './addMemberPage.hooks';
import AddMemberRoutes from './addMemberPage.routes';

const AddMemberPage = () => {
  const { t } = useTranslation();
  useAddMember();
  return (
    <div>
      <Header title={t('headers.add-member')} />
      <AddMemberRoutes />
    </div>
  );
};

export default AddMemberPage;
