import { Field, useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomForm } from '../../../models/types/CustomForm';
import { getFieldPropertiesForGroup } from '../../../utils/getFormGroupPath';
import {
  formatPhoneNumber,
  parsePhoneNumber,
  PHONE_NUMBER_MAX_LENGTH,
} from '../../../utils/phoneNumberHelpers';
import { FormSectionTitle, SectionWrapper } from '../../Form/Form';
import FormAgeInfo from '../../UI/Form/FormAgeInfo/FormAgeInfo';
import { FormColumn } from '../../UI/Form/FormColumn/FormColumn';
import FormDatePicker from '../../UI/Form/FormDatePicker/FormDatePicker';
import { FormInput } from '../../UI/Form/FormInput/FormInput';
import { FormNumberInput } from '../../UI/Form/FormNumberInput/FormNumberInput';
import { FormRow } from '../../UI/Form/FormRow/FormRow';
import FormSearchEmail from '../../UI/Form/FormSearchPerson/FormSearchPerson';
import { FormSelect } from '../../UI/Form/FormSelect/FormSelect';
import {
  genderOptions,
  FormMemberDetailsFields,
  MemberTitleOptions,
  FormMemberDetailsFieldsType,
  FormMemberDetailsName,
} from './formMemberDetails.models';

type FormMemberDetailsProps = CustomForm & {
  fields?: Record<FormMemberDetailsFieldsType, any>;
  isSearchingByEmail?: boolean;
};

const FormMemberDetails = ({
  className,
  disabled,
  name,
  fields = FormMemberDetailsFields,
  isSearchingByEmail,
}: FormMemberDetailsProps) => {
  const { t } = useTranslation();
  const birthOfDate = useField(fields.DateOfBirth);

  return (
    <SectionWrapper className={className}>
      <FormSectionTitle>{t('headers.details')}</FormSectionTitle>
      <FormColumn>
        <FormRow isSubRow>
          {isSearchingByEmail ? (
            <Field
              component={FormSearchEmail}
              fields={{ phone: fields.PhoneNumber }}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormMemberDetailsName,
                name: fields.EmailAddress,
                groupName: name,
              })}
            />
          ) : (
            <Field
              component={FormInput}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormMemberDetailsName,
                name: fields.EmailAddress,
                groupName: name,
              })}
            />
          )}
          <FormRow isSubRow>
            <Field
              component={FormDatePicker}
              disabled={disabled}
              {...getFieldPropertiesForGroup({
                inputId: FormMemberDetailsName,
                name: fields.DateOfBirth,
                groupName: name,
              })}
            />

            <FormAgeInfo date={birthOfDate.input.value} />
          </FormRow>
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormInput}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: FormMemberDetailsName,
              name: fields.FirstName,
              groupName: name,
            })}
          />

          <Field
            component={FormInput}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: FormMemberDetailsName,
              name: fields.LastName,
              groupName: name,
            })}
          />
        </FormRow>
        <FormRow isSubRow>
          <Field
            component={FormNumberInput}
            disabled={disabled}
            {...getFieldPropertiesForGroup({
              inputId: FormMemberDetailsName,
              name: fields.PhoneNumber,
              groupName: name,
            })}
            parseValue={parsePhoneNumber}
            formatValue={formatPhoneNumber}
            maxLength={PHONE_NUMBER_MAX_LENGTH}
          />
          <FormRow isSubRow>
            <Field
              component={FormSelect}
              disabled={disabled}
              isClearable
              options={MemberTitleOptions}
              {...getFieldPropertiesForGroup({
                inputId: FormMemberDetailsName,
                name: fields.PersonPrefix,
                groupName: name,
              })}
            />
            <Field
              component={FormSelect}
              disabled={disabled}
              isClearable
              label={t('fields.gender.label')}
              {...getFieldPropertiesForGroup({
                inputId: FormMemberDetailsName,
                name: fields.Gender,
                groupName: name,
              })}
              options={genderOptions}
            />
          </FormRow>
        </FormRow>
      </FormColumn>
    </SectionWrapper>
  );
};

export default FormMemberDetails;
