import { AnySchema } from 'ajv';

import { IOrganizationGGPointOfContactModel } from '../../../../models/CreateOrganizationGGPointOfContact';
import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { GgPointOfContactFormFields } from './ggPointOfContactForm.models';

export const ggPointOfContactSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [GgPointOfContactFormFields.EmployeeId]: {
      type: 'string',
    },
    [GgPointOfContactFormFields.InternalNotes]: {
      type: 'string',
    },
    [GgPointOfContactFormFields.roles]: {
      type: 'array',
      items: { type: 'string' },
    },
  },
  required: [GgPointOfContactFormFields.EmployeeId],
};

export const validateGGPointOfContactForm = (
  data: IOrganizationGGPointOfContactModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, ggPointOfContactSchema);
