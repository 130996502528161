import { useSelector } from 'react-redux';

import { useProcessing } from 'hooks/useProcessing';

import { SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import SummaryContainer from '../../SummaryContainer/SummaryContainer';
import SummaryItem from '../../SummaryContainer/SummaryItem/SummaryItem';
import { ProductType } from '../../../models/ProductType';
import { subscriptionSummarySelector } from '../../../store/redux-slices/subscriptions/common/selectors';

interface ISubscriptionSummaryFormProps {
  disabled?: boolean;
  title?: string;
  subscriptionType: ProductType;
  isPriceOverridden?: boolean;
}

const SubscriptionSummaryForm = ({
  disabled,
  title,
  subscriptionType,
  isPriceOverridden,
}: ISubscriptionSummaryFormProps) => {
  const summaryData = useSelector(subscriptionSummarySelector);
  const summaryIsLoading = useProcessing(
    SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  );
  return (
    <SummaryContainer
      content={(overridden: boolean) =>
        summaryData && (
          <SummaryItem
            isPriceOverridden={isPriceOverridden}
            disabled={disabled}
            subscriptionType={subscriptionType}
            subtotalValue={summaryData.amount}
            data={summaryData}
          />
        )
      }
      data={{ totalAmount: summaryData ? summaryData.amount : 0 }}
      disabled={disabled}
      isLoading={summaryIsLoading}
      title={title}
      canOverwriteAmount={false}
    />
  );
};

export default SubscriptionSummaryForm;
