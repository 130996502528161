import { useIsAuthenticated } from '@azure/msal-react';

import { PCA } from '../../common/auth/initAuth';

export const useSidebar = () => {
  const isAuth = useIsAuthenticated();

  const onLogoutHandler = (event: any) => {
    event.preventDefault();
    PCA.logoutRedirect();
  };

  return {
    onLogoutHandler,
    isAuth,
  };
};
