import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../models/FieldErrorMessage';
import { validateFormData } from '../../../utils/validations/validateFormData';
import { SummaryContainerFields } from '../../SummaryContainer/summaryContainer.models';

interface IValidateSubscriptionSummaryForm {
  overwriteAmount: boolean;
  overwrittenAmount: string;
}

export const subscriptionSummarySchema: AnySchema | any = {
  dependencies: {
    [SummaryContainerFields.OverwriteAmount]: {
      if: {
        properties: {
          [SummaryContainerFields.OverwriteAmount]: { enum: [true] },
        },
      },
      then: { required: [SummaryContainerFields.OverwrittenAmount] },
    },
  },
  properties: {
    [SummaryContainerFields.OverwriteAmount]: {
      type: 'boolean',
    },
    [SummaryContainerFields.OverwrittenAmount]: {
      type: 'string',
    },
  },
  required: [],
  type: 'object',
};

export const validateSubscriptionSummaryForm = (
  data: IValidateSubscriptionSummaryForm,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, subscriptionSummarySchema);
