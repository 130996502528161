import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';
import { ValidationCustomKeywords } from '../../../../utils/validations/customKeywords';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { TableFormColumns } from './extendTLSubscriptionPackageFormTable.models';

export const extendTLSubscriptionPricingItemFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [TableFormColumns.price]: {
      type: 'number',
      minimum: 0,
      [ValidationCustomKeywords.UniqueArrayNumberItem]: true,
    },
  },
  required: [TableFormColumns.price],
};

export const validateExtendTLSubscriptionPricingForm = (
  data: IChangeTLSubscriptionPricingTierModel,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(
    data,
    additionalErrors,
    extendTLSubscriptionPricingItemFormSchema,
  );
