import { useTranslation } from 'react-i18next';

import { useDeleteOrganizationPointOfContactModal } from './deleteOrganizationPointOfContactModal.hooks';
import { DELETE_ORGANIZATION_POINT_OF_CONTACT_MODAL_NAME } from './deleteOrganizationPointOfContactModal.consts';
import ConfirmModal from '../../../../../../styledComponents/ConfirmModal/ConfirmModal';
import {
  Description,
  Name,
} from '../../../../../MemberOverviewPage/Modals/Common/memberOverviewModals.styles';

const DeleteOrganizationPointOfContactModal = () => {
  const { t } = useTranslation();
  const {
    deleteOrganizationPointOfContactHandler,
    onCancelHandler,
    organizationUpdating,
    pointOfContact,
  } = useDeleteOrganizationPointOfContactModal();

  return (
    <ConfirmModal
      buttons={[
        {
          action: onCancelHandler,
          type: 'back',
          styleType: 'light1',
        },
        {
          action: deleteOrganizationPointOfContactHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: organizationUpdating,
        },
      ]}
      disabled={organizationUpdating}
      name={DELETE_ORGANIZATION_POINT_OF_CONTACT_MODAL_NAME}
      title={t('headers.delete-organization-contact')}
    >
      {pointOfContact?.isOrganizationAdministrator && (
        <Description>{t('phrase.contact-is-also-admin')}</Description>
      )}
      <Description>{t('phrase.confirm-delete')}</Description>
      {pointOfContact?.person && (
        <Name>
          {`${pointOfContact?.person?.firstName} ${pointOfContact?.person?.lastName}`}
        </Name>
      )}
    </ConfirmModal>
  );
};

export default DeleteOrganizationPointOfContactModal;
