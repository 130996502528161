import { ICellOptions } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';

interface IEmptyTableCell {
  options?: ICellOptions;
}

export const EmptyTableCell = ({ options }: IEmptyTableCell) => (
  <TableCell alignment={options?.alignment} bold={options?.textBold}>
    -
  </TableCell>
);
