/* eslint-disable react/destructuring-assignment */
import { t } from 'i18next';

import { IAAOrganizationSubscriptionOverview } from '../../../../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';
import { FractionRenderer } from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { overSubscribedSummaryRenderer } from '../../../../../../styledComponents/MaterialTable/CellRenderers/OverSubscribedSummaryRenderer/OverSubscribedSummaryRenderer';
import { Total } from './aASubscriptionOverviewPlansTable.styles';

export const getSummaryRow = (
  subscriptionOverview: IAAOrganizationSubscriptionOverview,
  onSeeOversubscribedMembers?: () => void,
  permissions?: string[],
) => {
  if (!subscriptionOverview) {
    return null;
  }

  return {
    price: <Total>{`${t('phrase.total')}`}</Total>,
    membershipsInUse: (
      <FractionRenderer
        isSummary
        maxValue={subscriptionOverview.totalCapacity}
        value={subscriptionOverview.totalMembershipsInUse}
      />
    ),
    oversubscribed: overSubscribedSummaryRenderer(
      subscriptionOverview?.totalIsOversubscribed,
      onSeeOversubscribedMembers,
      permissions,
    ),
  };
};
