import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import BaseInput from '../../../UI/BaseInput/BaseInput';
import NumberInput from '../../../UI/NumberInput/NumberInput';

type PricingInputProps = {
  isError?: boolean;
};

export const PricingInput = styled(NumberInput)<PricingInputProps>`
  width: auto;
  height: auto;
  ${BaseInput} {
    border: none;
    background-color: transparent;
    border-bottom: 0.063rem solid ${({ theme }) => theme.color.secondary1};
    border-radius: unset;
    text-align: ${({ alignment }) => alignment || 'center'};
    width: 5rem;
    height: 2rem;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error : theme.color.black};
    -webkit-text-fill-color: ${({ theme, isError }) =>
      isError ? theme.color.error : theme.color.black};

    ${({ readOnly }) =>
      readOnly &&
      css`
        border-bottom: none;
      `}
  }
`;
