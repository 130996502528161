import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOrganizationContractModel } from 'models/OrganizationContractModel';

import { IContractItem } from '../../../models/interfaces/ContractItem';
import { IContractListItem } from '../../../models/interfaces/ContractListItem';
import { IOrganizationContractDetailsModel } from '../../../models/interfaces/OrganizationContractDetailsModel';
import { IUpdateOrganizationContractModel } from '../../../models/interfaces/UpdateOrganizationContractModel';
import { parseDate } from '../../../utils/dateHelpers';
import { firstLetterToLowercase } from '../../../utils/textHelpers';
import { contractDetailsModelToUpdateContractModel } from '../../../utils/contractHelpers';

export interface IContractsState {
  availableOrganizationContracts: IContractItem[];
  availableOrganizationContractsList: IContractListItem[];
  contractDetails: IOrganizationContractDetailsModel;
  contractEditData: IUpdateOrganizationContractModel;
  contractEditableFields: Record<string, boolean>;
}

const initialState: IContractsState = {
  availableOrganizationContracts: [],
  availableOrganizationContractsList: null,
  contractDetails: null,
  contractEditData: null,
  contractEditableFields: null,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setOrganizationContracts: (
      state,
      { payload }: PayloadAction<IContractItem[]>,
    ) => {
      state.availableOrganizationContracts = payload;
    },
    setOrganizationContractsList: (
      state,
      { payload }: PayloadAction<IContractListItem[]>,
    ) => {
      state.availableOrganizationContractsList = payload;
    },
    addContract: (
      state,
      { payload }: PayloadAction<IOrganizationContractModel>,
    ) => {
      state.availableOrganizationContracts.unshift(payload);
    },
    setContractDetails: (
      state,
      { payload }: PayloadAction<IOrganizationContractDetailsModel>,
    ) => {
      state.contractDetails = {
        ...payload,
        startDate: parseDate(payload.startDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setEditContractData: (
      state,
      { payload }: PayloadAction<IOrganizationContractDetailsModel>,
    ) => {
      state.contractEditData = contractDetailsModelToUpdateContractModel({
        ...payload,
        startDate: parseDate(payload.startDate),
        expirationDate: parseDate(payload.expirationDate),
      });
    },
    setContractEditableFields: (
      state,
      { payload }: PayloadAction<Record<string, boolean>>,
    ) => {
      const newFields = {};
      Object.keys(payload).forEach((fieldName) => {
        newFields[firstLetterToLowercase(fieldName)] = payload[fieldName];
      });
      state.contractEditableFields = newFields;
    },
    clearContractEditableFields: (state) => {
      state.contractEditableFields = initialState.contractEditableFields;
    },
    clearOrganizationContracts: (state) => {
      state.availableOrganizationContracts =
        initialState.availableOrganizationContracts;
    },
    clearOrganizationContractsList: (state) => {
      state.availableOrganizationContractsList =
        initialState.availableOrganizationContractsList;
    },
    clearContractDetails: (state) => {
      state.contractDetails = initialState.contractDetails;
    },
    clearEditContractData: (state) => {
      state.contractEditData = initialState.contractEditData;
    },
  },
});

export const {
  addContract,
  setOrganizationContracts,
  setOrganizationContractsList,
  clearOrganizationContracts,
  clearOrganizationContractsList,
  setContractDetails,
  clearContractDetails,
  setContractEditableFields,
  setEditContractData,
  clearContractEditableFields,
  clearEditContractData,
} = contractsSlice.actions;
export default contractsSlice.reducer;
export const contractsReducerName = contractsSlice.name;
