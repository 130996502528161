import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAAOrganizationSubscriptionOverview } from 'models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionOverview';
import { ISubscriptionDates } from 'models/SubscriptionDates';

import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import { IRenewOrganizationSubscriptionMemberModel } from '../../../../models/IRenewOrganizationSubscriptionMemberModel';

import { IAASubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/AASubscriptionRenewalSuggestionModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { IEditAAOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/EditAAOrganizationSubscriptionModel';

export interface IAASubscriptionsState {
  currentSubscriptionOverview: IAAOrganizationSubscriptionOverview;
  currentSubscriptionEditing: IEditAAOrganizationSubscriptionModel;
  subscriptionDates: ISubscriptionDates;

  subscriptionRenewalSuggestion: IAASubscriptionRenewalSuggestionModel;
  subscriptionRenewalAnalyse: IRenewOrganizationSubscriptionAnalyseModel;
}

const initialState: IAASubscriptionsState = {
  subscriptionDates: null,
  currentSubscriptionOverview: null,
  subscriptionRenewalSuggestion: null,
  subscriptionRenewalAnalyse: {
    memberships: [],
    analysisResultForDate: null,
    totalMemberships: { membershipsCount: 0, withErrors: 0 },
  },
  currentSubscriptionEditing: null,
};

const aaSubscriptionsSlice = createSlice({
  name: 'aaSubscriptions',
  initialState,
  reducers: {
    setAASubscriptionDates: (
      state,
      { payload }: PayloadAction<ISubscriptionDates>,
    ) => {
      state.subscriptionDates = payload;
    },
    setAACurrentEditingSubscription: (
      state,
      { payload }: PayloadAction<IEditAAOrganizationSubscriptionModel>,
    ) => {
      state.currentSubscriptionEditing = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        overwrittenAmount: payload.overwrittenAmount?.toString(),
      };
    },
    clearAASubscriptionHelperData: (state) => {
      state.subscriptionDates = null;
      state.subscriptionRenewalSuggestion = null;
    },
    setAASubscription: (
      state,
      { payload }: PayloadAction<IAAOrganizationSubscriptionOverview>,
    ) => {
      const subscription: IAAOrganizationSubscriptionOverview = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
      };
      state.currentSubscriptionOverview = subscription;
    },
    setAASubscriptionRenewalSuggestion(
      state,
      { payload }: PayloadAction<IAASubscriptionRenewalSuggestionModel>,
    ) {
      state.subscriptionRenewalSuggestion = payload;
    },
    setAASubscriptionRenewalAnalyse(
      state,
      { payload }: PayloadAction<IRenewOrganizationSubscriptionAnalyseModel>,
    ) {
      const mapMembers = (
        members: IRenewOrganizationSubscriptionMemberModel[],
      ) =>
        members.map((member, index) => ({
          ...member,
          dateOfBirth: parseDate(member.dateOfBirth),
        }));

      const subscriptionAnalyse = {
        analysisResultForDate: parseDate(payload.analysisResultForDate),
        memberships: payload.memberships.map((membership) => ({
          ...membership,
          primaryMember: {
            ...membership.primaryMember,
            dateOfBirth: parseDate(membership.primaryMember.dateOfBirth),
          },
          secondaryMembers: mapMembers(membership.secondaryMembers),
        })),
        totalMemberships: payload.memberships.reduce(
          (acc, { primaryMember, secondaryMembers }) => {
            const members = [primaryMember, ...secondaryMembers];
            acc.membershipsCount = acc.membershipsCount + 1;
            const hasErrors =
              members.filter(
                ({ canBeRenewed, toBeRenewed }) => !canBeRenewed && toBeRenewed,
              ).length > 0;
            acc.withErrors = acc.withErrors + (hasErrors ? 1 : 0);
            return acc;
          },
          { membershipsCount: 0, withErrors: 0 },
        ),
      };
      state.subscriptionRenewalAnalyse = subscriptionAnalyse;
    },
    clearAASubscriptionRenewalAnalyse: (state) => {
      state.subscriptionRenewalAnalyse = {
        memberships: [],
        analysisResultForDate: null,
        totalMemberships: { membershipsCount: 0, withErrors: 0 },
      };
    },
    clearAACurrentSubscription: (state) => {
      state.currentSubscriptionOverview =
        initialState.currentSubscriptionOverview;
    },
    clearAACurrentSubscriptionEditing: (state) => {
      state.currentSubscriptionEditing =
        initialState.currentSubscriptionEditing;
    },
  },
});

export const {
  clearAASubscriptionHelperData,
  setAASubscription,
  setAASubscriptionDates,
  setAASubscriptionRenewalSuggestion,
  setAASubscriptionRenewalAnalyse,
  clearAASubscriptionRenewalAnalyse,
  clearAACurrentSubscription,
  clearAACurrentSubscriptionEditing,
  setAACurrentEditingSubscription,
} = aaSubscriptionsSlice.actions;
export default aaSubscriptionsSlice.reducer;
export const subscriptionsReducerName = aaSubscriptionsSlice.name;
