import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';
import { ITLOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionSummaryModel';
import { ITLOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import { ITLSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLSubscriptionRenewalSuggestionModel';

import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';

export interface ITLSubscriptionsState {
  currentSubscriptionOverview: ITLOrganizationSubscriptionOverview;
  subscriptionDates: ISubscriptionDates;
  currentSubscriptionEditing: IEditTLOrganizationSubscriptionModel;
  subscriptionRenewalSuggestion: ITLSubscriptionRenewalSuggestionModel;
  subscriptionExtensionSummary: ITLOrganizationSubscriptionExtensionSummaryModel;
}

const initialState: ITLSubscriptionsState = {
  currentSubscriptionOverview: null,
  subscriptionDates: null,
  currentSubscriptionEditing: null,
  subscriptionRenewalSuggestion: null,
  subscriptionExtensionSummary: null,
};

const tlSubscriptionsSlice = createSlice({
  name: 'tlSubscriptions',
  initialState,
  reducers: {
    setTLSubscriptionOverview: (
      state,
      { payload }: PayloadAction<ITLOrganizationSubscriptionOverview>,
    ) => {
      const pricingTiers = payload.pricingTiers.map((x) => ({
        ...x,
        editDate: parseDate(x.editDate),
      }));
      const subscription: ITLOrganizationSubscriptionOverview = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        pricingTiers,
      };
      state.currentSubscriptionOverview = subscription;
    },
    setTLSubscriptionDates: (
      state,
      { payload }: PayloadAction<ISubscriptionDates>,
    ) => {
      state.subscriptionDates = payload;
    },
    setTLCurrentEditingSubscription: (
      state,
      { payload }: PayloadAction<IEditTLOrganizationSubscriptionModel>,
    ) => {
      state.currentSubscriptionEditing = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
        overwrittenAmount: payload.overwrittenAmount?.toString(),
      };
    },
    setTLSubscriptionRenewalSuggestion(
      state,
      { payload }: PayloadAction<ITLSubscriptionRenewalSuggestionModel>,
    ) {
      state.subscriptionRenewalSuggestion = {
        ...payload,
        effectiveDateFrom: parseDate(payload.effectiveDateFrom),
        effectiveDateTo: parseDate(payload.effectiveDateTo),
      };
    },
    setTLSubscriptionExtensionSummary: (
      state,
      {
        payload,
      }: PayloadAction<ITLOrganizationSubscriptionExtensionSummaryModel>,
    ) => {
      state.subscriptionExtensionSummary = payload;
    },
    clearTLSubscriptionExtensionHelperData: (state) => {
      state.subscriptionExtensionSummary = null;
    },
    clearTLSubscriptionOverview: (state) => {
      state.currentSubscriptionOverview =
        initialState.currentSubscriptionOverview;
    },
    clearTLSubscriptionHelperData: (state) => {
      state.subscriptionDates = null;
      state.subscriptionRenewalSuggestion = null;
    },
    clearTLCurrentSubscriptionEditing: (state) => {
      state.currentSubscriptionEditing =
        initialState.currentSubscriptionEditing;
    },
  },
});

export const {
  clearTLSubscriptionOverview,
  setTLSubscriptionOverview,
  clearTLSubscriptionHelperData,
  setTLSubscriptionDates,
  setTLCurrentEditingSubscription,
  clearTLCurrentSubscriptionEditing,
  setTLSubscriptionRenewalSuggestion,
  clearTLSubscriptionExtensionHelperData,
  setTLSubscriptionExtensionSummary,
} = tlSubscriptionsSlice.actions;
export default tlSubscriptionsSlice.reducer;
export const subscriptionsReducerName = tlSubscriptionsSlice.name;
