/* eslint-disable react/no-array-index-key */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { IMaterialColumn } from '../materialTable.models';
import MaterialTableNotFound from '../MaterialTableNotFound/MaterialTableNotFound';
import CustomTableCell from './TableCell';
import { StyledTableRow } from './tableContent.styles';

type TableContentProps = {
  data?: any[];
  columns: IMaterialColumn[];
  onRowClick?: (x: any) => void;
  showNotFoundComponent?: boolean;
};

const TableContent = ({
  data,
  columns,
  onRowClick,
  showNotFoundComponent,
}: TableContentProps) => {
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return showNotFoundComponent ? (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <MaterialTableNotFound />
        </TableCell>
      </TableRow>
    ) : null;
  }

  return (
    <>
      {data.map((row, index) => (
        <StyledTableRow
          key={index}
          onClick={() => onRowClick && onRowClick(row)}
          $isClickable={!!onRowClick}
        >
          {columns.map((column, i) => (
            <CustomTableCell key={column.title + i} column={column} row={row} />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

export default TableContent;
