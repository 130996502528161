import { AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { updateProcessState } from '../../../redux-slices/processes';

import { TL_PRICING_LOADING_PROCESSING } from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';
import { ILoadStandardPricingPayload } from '../common/models';
import { getTLPricingTiersQuery } from './api';
import { TLPricingTierDefinition } from '../../../../models/interfaces/Subscription/TravelLicense/TLPricingDefinition';
import { setTLPricingTiers } from '../../../redux-slices/pricing/travelLicense';

const tlPricingSlice = createSliceSaga({
  name: 'tl-pricing-saga',
  caseSagas: {
    *tlLoadPricingTiers({
      payload: { cancellationToken, error, success } = {},
    }: PayloadAction<ILoadStandardPricingPayload>) {
      try {
        yield put(updateProcessState(TL_PRICING_LOADING_PROCESSING));

        const response: AxiosResponse<TLPricingTierDefinition[]> = yield* call(
          getTLPricingTiersQuery,
          cancellationToken,
        );

        yield put(setTLPricingTiers(response.data));

        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err);
      } finally {
        yield put(updateProcessState(TL_PRICING_LOADING_PROCESSING));
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default tlPricingSlice.saga;
export const { tlLoadPricingTiers } = tlPricingSlice.actions;
export const { actions } = tlPricingSlice;
