import { useTranslation } from 'react-i18next';

import { PromoCodeStatus } from '../../../models/enums/PromoCodeStatus';
import { PromoCodeItemStatusContainer } from './promoCodeStatus.styles';

interface IPromoCodeItemStatusProps {
  status: PromoCodeStatus;
}

const PromoCodeItemStatus = ({ status }: IPromoCodeItemStatusProps) => {
  const { t } = useTranslation();
  return (
    <PromoCodeItemStatusContainer status={status}>
      {t(`enums.promo-code-status.${status}`)}
    </PromoCodeItemStatusContainer>
  );
};

export default PromoCodeItemStatus;
