import { useParams } from 'react-router';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IWarningRatification } from '../../../../../../../models/interfaces/WarningRatification';
import { useProcessing } from '../../../../../../../hooks/useProcessing';
import { useActions } from '../../../../../../../hooks/useActions';
import { updateModalState } from '../../../../../../../store/redux-slices/modals';
import { SUBSCRIPTION_DETAILS_EDITING_PROCESSING } from '../../../../../../../store/redux-slices/processes/constants';
import { organizationNameSelector } from '../../../../../../../store/redux-slices/organizations/selectors';
import { useFormErrorsActions } from '../../../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useApiResponseFail } from '../../../../../../../hooks/useApiResponseFail';

import { editTLSubscriptionOptions } from '../../../../../../../store/saga-slices/subscriptions/travelLicense';
import { tlSubscriptionOverviewSelector } from '../../../../../../../store/redux-slices/subscriptions/travelLicense/selectors';
import { ITLOrganizationSubscriptionOptionsModel } from '../../../../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOptionsModel';
import { IEditTLSubscriptionOptionsPayload } from '../../../../../../../store/saga-slices/subscriptions/travelLicense/models';
import { EDIT_TL_SUBSCRIPTION_OPTIONS_MODAL_NAME } from './tlSubscriptionEditOptionsModal.const';
import { TravelLicenseSubscriptionOptions } from '../../../../../../../models/enums/TravelLicenseSubscriptionOptions';

type UseTLSubscriptionEditDetailsModalType = {
  onModalSuccess: () => void;
};

export const useTLSubscriptionEditDetailsModal = ({
  onModalSuccess,
}: UseTLSubscriptionEditDetailsModalType) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const UpdateModalState = useActions(updateModalState);
  const EditSubscriptionDetails = useActions(editTLSubscriptionOptions);

  const subscriptionEditing = useProcessing(
    SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  );

  const subscriptionOverview = useSelector(tlSubscriptionOverviewSelector);
  const organizationName = useSelector(organizationNameSelector);

  const { id: organizationId, subscriptionId } = useParams();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponse } = useApiResponseFail();

  const handleSubmit = (values: ITLOrganizationSubscriptionOptionsModel) => {
    editOrganizationSubscription(values);
  };

  const editOrganizationSubscription = (
    values: ITLOrganizationSubscriptionOptionsModel,
    ratifications: IWarningRatification[] = [],
  ) => {
    const payload: IEditTLSubscriptionOptionsPayload = {
      organizationId,
      subscriptionId,
      data: values,
      success: () => {
        alert.success(
          t('alerts.subscription-edit-success', {
            value: organizationName,
          }),
        );
        onModalSuccess && onModalSuccess();
        UpdateModalState(EDIT_TL_SUBSCRIPTION_OPTIONS_MODAL_NAME);
      },
      error: (err) => {
        handleResponse({
          err,
          values,
          ratifications,
          alertErrorMessage: t('alerts.subscription-edit-fail', {
            value: organizationName,
          }),
        });
      },
    };
    EditSubscriptionDetails(payload);
  };

  const handleWarningsConfirm = (
    data: ITLOrganizationSubscriptionOptionsModel,
    warnings: IWarningRatification[],
  ) => {
    UpdateModalState([EDIT_TL_SUBSCRIPTION_OPTIONS_MODAL_NAME, { data }]);
    editOrganizationSubscription(data, warnings);
  };

  const onCancelHandler = () =>
    UpdateModalState(EDIT_TL_SUBSCRIPTION_OPTIONS_MODAL_NAME);

  const initData: ITLOrganizationSubscriptionOptionsModel =
    subscriptionOverview && {
      [TravelLicenseSubscriptionOptions.AutoRenewal]:
        subscriptionOverview.autoRenewal,
    };

  return {
    handleWarningsConfirm,
    handleSubmit,
    validateErrors,
    UpdateModalState,
    subscriptionEditing,
    initData,
    onCancelHandler,
  };
};
