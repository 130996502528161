import { PromoCodeStatus } from '../../../models/enums/PromoCodeStatus';
import { PromoCodeType } from '../../../models/enums/PromoCodeType';
import { ShortcutProductType } from '../../../models/enums/ShortcutProductType';
import {
  getOptionsFromEnum,
  getOptionsFromEnumValues,
} from '../../../utils/getOptionsFromEnum';

export const typeOptions = getOptionsFromEnum(PromoCodeType, 'promo-code-type');
export const statusOptions = getOptionsFromEnum(
  PromoCodeStatus,
  'promo-code-status',
);
export const productOptions = getOptionsFromEnumValues(
  [ShortcutProductType.AA],
  'shortcut-to-product-type',
);
