import {
  SystemGroupPermissions,
  DutyOfCarePermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canAddDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanCreateSubscription,
  ),
];

export const canRemoveDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanDeleteSubscription,
  ),
];

export const canEditDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanUpdateSubscription,
  ),
];

export const canAddSeatsDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanUpdateSubscription,
  ),
];

export const canViewDOCSubscriptionDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanViewSubscriptionDetails,
  ),
];

export const canUpdateDOCSubscriptionsDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanUpdateSubscription,
  ),
];

export const canRenewDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanCreateSubscription,
  ),
];

export const canExtendsDOCSubscriptionPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanUpdateSubscription,
  ),
];

export const canRemoveDOCMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanDeleteMembership,
  ),
];

export const canRenewDOCMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanCreateMembership,
  ),
];

export const canResendDOCWelcomeEmailPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanResendWelcomeEmail,
  ),
];

export const canManageDOCMembershipsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanManageMemberships,
  ),
];

export const canEditDOCMembershipsOptionsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanManageMemberships,
  ),
];

export const canChangeDOCToBeRenewedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanManageMemberships,
  ),
];

export const canSetDOCMembershipAlreadyPayedPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanSetMembershipAlreadyPayed,
  ),
];

export const canAddDOCMembershipFamilyMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanManageMemberships,
  ),
];

export const canAddDOCMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanCreateMembership,
  ),
];

export const canViewDOCMembersPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanSearchMembers,
  ),
];

export const canGenerateDOCRawDataReportPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanGenerateMembershipReport,
  ),
];
export const canAddDOCMembershipPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanCreateMembership,
  ),
];

export const canRemoveDOCSecondaryMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.DutyOfCare,
    DutyOfCarePermissions.CanManageMemberships,
  ),
];
