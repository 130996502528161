import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SmallModal } from '../ModalCommon/ModalCommon';

import { ConfirmModalProps } from './confirmModal.models';
import {
  ButtonsContainer,
  Description,
  Header,
  StyledButton,
} from './confirmModal.styles';

const ConfirmModal = ({
  buttons,
  children,
  disabled,
  className,
  isLoading,
  name,
  title,
  onModalClose,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <SmallModal
      className={className}
      isLoading={isLoading}
      disabled={disabled}
      name={name}
      onModalClose={onModalClose}
    >
      <Header>{title}</Header>
      <Description>
        <Trans>{React.Children.toArray(children).filter((x) => x)}</Trans>
      </Description>
      <ButtonsContainer>
        {buttons.map(
          (
            {
              action,
              type,
              styleType,
              label,
              isProcessing,
              disabled: buttonDisabled,
            },
            index,
          ) => (
            <StyledButton
              buttonType={type}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={action}
              styleType={styleType}
              isLoading={isProcessing}
              disabled={buttonDisabled}
            >
              {label || t(`buttons.${type}`).toUpperCase()}
            </StyledButton>
          ),
        )}
      </ButtonsContainer>
    </SmallModal>
  );
};

export default ConfirmModal;
