import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useActions } from '../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { IContractListItem } from '../../../../models/interfaces/ContractListItem';
import { RoutePath } from '../../../../models/enums/RoutePath';
import { clearOrganizationContractsList } from '../../../../store/redux-slices/contracts';
import { organizationContractsListSelector } from '../../../../store/redux-slices/contracts/selectors';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { loadOrganizationContractsList } from '../../../../store/saga-slices/contracts';
import { ILoadOrganizationContractsPayload } from '../../../../store/saga-slices/contracts/models';
import { useMaterialTableParams } from '../../../../styledComponents/MaterialTable/MaterialTableTools/materialTableTools.hooks';
import { ADD_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/AddContractModal/addContractModal.const';
import { DELETE_CONTRACT_MODAL_NAME } from '../../../../styledComponents/Modals/DeleteContractModal/deleteContractModal.const';
import { DeleteContractModalDetails } from '../../../../styledComponents/Modals/DeleteContractModal/deleteContractModal.models';
import { EDIT_CONTRACT_DETAILS_MODAL_NAME } from '../../../../styledComponents/Modals/EditContractDetailsModal/editContractDetailsModal.const';
import { RequiredQueryParams } from './contractsTabPage.models';
import { EditOrganizationContractModalDetails } from '../../../../styledComponents/Modals/EditContractDetailsModal/editContractDetailsModal.models';
import { AddContractModalFormDetails } from '../../../../styledComponents/Modals/AddContractModal/AddContractModalForm/addContractModalForm.models';

export const useContractsTabPage = () => {
  const contractsIsLoading = useProcessing(
    ORGANIZATION_CONTRACTS_LIST_LOADING_PROCESSING,
  );
  const contractsList = useSelector(organizationContractsListSelector);
  const cancellationToken = useCancellationToken();
  const UpdateModalState = useActions(updateModalState);
  const navigate = useNavigateSearch();
  const tableParams = useMaterialTableParams(RequiredQueryParams);
  const { id: organizationId } = useParams();

  const LoadOrganizationContractsList = useActions(
    loadOrganizationContractsList,
  );
  const ClearOrganizationContractsList = useActions(
    clearOrganizationContractsList,
  );

  const loadOrganizationContractsListHandler = useCallback(() => {
    if (!tableParams) {
      return;
    }

    const payload: ILoadOrganizationContractsPayload = {
      organizationId,
      cancellationToken,
      params: tableParams,
    };
    LoadOrganizationContractsList(payload);
  }, [
    LoadOrganizationContractsList,
    cancellationToken,
    tableParams,
    organizationId,
  ]);

  useEffect(() => {
    loadOrganizationContractsListHandler();
  }, [loadOrganizationContractsListHandler]);

  useEffect(
    () => () => ClearOrganizationContractsList(),
    [ClearOrganizationContractsList],
  );

  const addContractHandler = () => {
    const details: AddContractModalFormDetails = {
      organizationId,
    };
    UpdateModalState([ADD_CONTRACT_MODAL_NAME, details]);
  };

  const rowClickHandler = (row: IContractListItem) => {
    navigate(RoutePath.ClientOverviewContractOverviewPage, {
      params: { contractId: row.id },
    });
  };

  const editContractDetailsHandler = (row: IContractListItem) => {
    const details: EditOrganizationContractModalDetails = {
      organizationId,
      contractId: row.id,
      data: null,
    };
    UpdateModalState([EDIT_CONTRACT_DETAILS_MODAL_NAME, details]);
  };

  const deleteContractHandler = (row: IContractListItem) => {
    const details: DeleteContractModalDetails = {
      organizationId,
      contractId: row.id,
      contractName: row.name,
    };

    UpdateModalState([DELETE_CONTRACT_MODAL_NAME, details]);
  };

  return {
    contractsIsLoading,
    contractsList,
    addContractHandler,
    rowClickHandler,
    deleteContractHandler,
    editContractDetailsHandler,
    loadOrganizationContractsListHandler,
  };
};
