import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useFormErrorsActions } from '../../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { addressInformationsInitState } from '../../../../../styledComponents/Forms/MemberAddressInformationForm/memberAddressInformationsForm.consts';
import { useActions } from '../../../../../hooks/useActions';
import { useApiResponseFail } from '../../../../../hooks/useApiResponseFail';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useRouteParams } from '../../../../../hooks/useRouteParams';
import { ICreatePrimaryMemberModel } from '../../../../../models/CreatePrimaryMemberModel';
import { IWarningRatification } from '../../../../../models/interfaces/WarningRatification';
import { PRIMARY_MEMBER_VALIDATING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { includeRemovedProperties } from '../../../../../utils/formInitValuesHelpers';
import { validateFormApiErrors } from '../../../../../utils/validations/validateFormApiErrors';
import { validatePrimaryMemberDetailsForm } from './addMemberPrimaryMemberForm.validation';
import { submitCreatePrimaryMember } from '../../../../../store/saga-slices/membership/common';
import { primaryMemberSelector } from '../../../../../store/redux-slices/membership/common/selectors';
import { ISubmitPrimaryMemberPayload } from '../../../../../store/saga-slices/membership/common/models';
import { AddMemberAvailableSearchParamsType } from '../../../../../models/types/RoutePath';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';

export const useAddMemberPrimaryMember = () => {
  const SubmitCreatePrimaryMember = useActions(submitCreatePrimaryMember);
  const primaryMemberData = useSelector(primaryMemberSelector);

  const cancellationToken = useCancellationToken();

  const isValidating = useProcessing(PRIMARY_MEMBER_VALIDATING_PROCESSING);

  const { membershipId, personId } = useRouteParams();

  const { productType } = useRouteParams<AddMemberAvailableSearchParamsType>();

  const { validateErrors } = useFormErrorsActions();
  const { handleResponse } = useApiResponseFail();

  const validateForm = useCallback(
    (data: ICreatePrimaryMemberModel) => {
      const currentData = includeRemovedProperties(
        {
          ...addressInformationsInitState,
        },
        data,
      );
      if (data.personId) {
        return validateFormApiErrors(validateErrors(currentData));
      }
      return validatePrimaryMemberDetailsForm(
        currentData,
        validateErrors(currentData),
      );
    },
    [validateErrors],
  );

  const selectPrimaryMember = useCallback(
    (
      values: ICreatePrimaryMemberModel,
      ratifications: IWarningRatification[] = [],
    ) => {
      const extendedValues: ICreatePrimaryMemberModel = {
        ...values,
        ratifications,
      };

      const payload: ISubmitPrimaryMemberPayload = {
        data: extendedValues,
        productType,
        cancellationToken,
        error: (err) => {
          handleResponse({ err, values, ratifications });
        },
      };

      SubmitCreatePrimaryMember(payload);
    },
    [SubmitCreatePrimaryMember, handleResponse, productType, cancellationToken],
  );

  const handleSubmit = useCallback(
    (values: ICreatePrimaryMemberModel) => {
      selectPrimaryMember(values);
    },
    [selectPrimaryMember],
  );

  const initialData = primaryMemberData || addressInformationsInitState;

  return {
    validateForm,
    primaryMemberData,
    isValidating,
    handleSubmit,
    fieldsReadOnly: !!membershipId || !!personId,
    selectPrimaryMember,
    initialData,
  };
};
