import { canViewSRMembersPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import DeleteProductSubscriptionModal from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/DeleteSubscriptionModal';
import EditRenewalSubscriptionStatusModal from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';
import {
  LeftContainer,
  RightContainer,
} from '../../../../../styledComponents/Page/Page';
import ProductSubscriptionOverviewTemplatePage from '../ProductSubscriptionOverviewTemplatePage/ProductSubscriptionOverviewTemplatePage';
import SRSubscriptionEditOptionsModal from './Modals/SRSubscriptionEditOptionsModal/SRSubscriptionEditOptionsModal';
import { srSubscriptionOverviewPermissions } from './srSubscriptionOverview.const';
import {
  useSRSubscriptionOverviewActions,
  useSRSubscriptionOverviewTabPage,
} from './srSubscriptionOverview.hooks';
import SRSubscriptionOverviewInformation from './SRSubscriptionOverviewInformation/SRSubscriptionOverviewInformation';
import SRSubscriptionOverviewPlansTable from './SRSubscriptionOverviewPlansTable/SRSubscriptionOverviewPlansTable';

const SRSubscriptionOverview = () => {
  const {
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  } = useSRSubscriptionOverviewTabPage();

  const {
    addMemberHandler,
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    onRowClickHandler,
    showEditSubscriptionDetailsModal,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    editRenewalStatusHandler,
  } = useSRSubscriptionOverviewActions();

  return (
    <>
      <SRSubscriptionEditOptionsModal
        onModalSuccess={() => loadSubscriptionOverviewHandler()}
      />
      <EditRenewalSubscriptionStatusModal
        onRenewalStatusEdited={loadSubscriptionOverviewHandler}
      />
      <DeleteProductSubscriptionModal />
      <ProductSubscriptionOverviewTemplatePage
        canAddMember={subscriptionOverview?.metadata?.canAddMember}
        canExtend={subscriptionOverview?.metadata?.canAddExtension}
        canDelete={subscriptionOverview?.metadata?.canDelete}
        onDeleteProductSubscription={deleteProductSubscriptionHandler}
        onAddMember={addMemberHandler}
        onExtendProductSubscription={extendProductSubscriptionHandler}
        onEditSubscription={editSubscriptionHandler}
        isLoading={subscriptionOverviewLoading}
        canEditSubscription={subscriptionOverview?.metadata?.canEdit}
        permissions={srSubscriptionOverviewPermissions}
      >
        <LeftContainer>
          <SRSubscriptionOverviewPlansTable
            subscriptionOverview={subscriptionOverview}
            onRowClick={onRowClickHandler}
            onSummaryRowClick={onRowClickHandler}
            onSeeOversubscriptionClick={onSeeOversubscribedMembersHandler}
            onSummarySeeOversubscriptionClick={
              onSeeOversubscribedMembersHandler
            }
            canClickRowPermissions={canViewSRMembersPermissions}
          />
        </LeftContainer>
        <RightContainer>
          <SRSubscriptionOverviewInformation
            onEditOptionsClick={showEditSubscriptionDetailsModal}
            subscriptionOverview={subscriptionOverview}
            canEditOptions={!!subscriptionOverview?.metadata?.canEditOptions}
            canRenew={!!subscriptionOverview?.metadata?.canRenew}
            onEditRenewalStatus={editRenewalStatusHandler}
          />
        </RightContainer>
      </ProductSubscriptionOverviewTemplatePage>
    </>
  );
};

export default SRSubscriptionOverview;
