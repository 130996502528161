import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../models/types/CustomComponent';
import {
  getFieldPropertiesForGroup,
  getFormGroupPath,
} from '../../../../utils/getFormGroupPath';
import { checkboxContentRenderer } from '../../summaryContainer.utils';
import {
  SubscriptionSummaryItemFields,
  SubscriptionSummaryItemName,
} from '../summaryItem.models';
import {
  StyledOverwriteAmountCheckbox,
  SubtotalContainer,
  SubtotalLabel,
  SubtotalValue,
} from './summaryItemSubtotal.styles';

type SubscriptionSummaryItemSubtotalProps = CustomComponent & {
  name?: string;
  isPriceOverridden?: boolean;
  subtotalValue?: number;
};

const SubscriptionSummaryItemSubtotal = ({
  className,
  disabled,
  isPriceOverridden,
  name,
  subtotalValue,
}: SubscriptionSummaryItemSubtotalProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <SubtotalContainer>
        <SubtotalLabel>{t('headers.summarySubtotal')}</SubtotalLabel>
        <SubtotalValue overridden={isPriceOverridden}>
          ${subtotalValue}
        </SubtotalValue>
      </SubtotalContainer>
      <Field
        component={StyledOverwriteAmountCheckbox}
        {...getFieldPropertiesForGroup({
          inputId: SubscriptionSummaryItemName,
          name: SubscriptionSummaryItemFields.OverwriteAmount,
          groupName: name,
        })}
        type="checkbox"
        disabled={disabled}
        label={t('fields.overwriteAmountSubtotal.label')}
        checkboxContentName={getFormGroupPath(
          name,
          SubscriptionSummaryItemFields.OverwrittenAmount,
        )}
        checkboxContentRenderer={checkboxContentRenderer}
      />
    </div>
  );
};

export default SubscriptionSummaryItemSubtotal;
