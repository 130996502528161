import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

type NameWrapperType = {
  disabled?: boolean;
  bold?: boolean;
};

export const NameWrapper = styled.div<NameWrapperType>`
  font: normal inherit 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
  white-space: nowrap;
  ${({ disabled, bold, theme }) => css`
    ${disabled &&
    css`
      color: ${theme.color.dark2};
    `}

    ${bold &&
    css`
      font-weight: bold;
    `}
  `}
`;
