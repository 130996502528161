import {
  SystemGroupPermissions,
  PeopleManagementPermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canEditMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdatePerson,
  ),
];
export const canRemoveMemberPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanDeletePerson,
  ),
];

export const canViewGeneralMembersPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewPeople,
  ),
];

export const canViewSubscriptionsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizations,
  ),
];

export const canViewMemberDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewPeople,
  ),
];

export const canEditOrganizationPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];
export const canDeleteOrganizationPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanDeleteOrganization,
  ),
];
export const canAddOrganizationPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanCreateOrganization,
  ),
];
export const canViewGeneralOrganizationsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizations,
  ),
];

export const canViewOrganizationDetailsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizations,
  ),
];

export const canAddPOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];
export const canEditPOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];
export const canDeletePOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];

export const canAddGGPOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];
export const canEditGGPOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];
export const canDeleteGGPOCPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanUpdateOrganization,
  ),
];

export const CanViewOrganizationContractsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizationContracts,
  ),
];

export const CanCreateOrganizationContractPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanCreateOrganizationContract,
  ),
];

export const CanEditOrganizationContractPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanEditOrganizationContract,
  ),
];

export const CanDeleteOrganizationContractPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanDeleteOrganizationContract,
  ),
];

export const CanRenewOrganizationContractPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanRenewOrganizationContract,
  ),
];

export const CanEditOrganizationContractNotesPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanEditOrganizationContract,
  ),
];

export const CanAddOrganizationContractNotesPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanEditOrganizationContract,
  ),
];

export const CanViewContractsRenewalsPagePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizationContractsRenewals,
  ),
];

export const CanViewSubscriptionsRenewalsPagePermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanViewOrganizationSubscriptionsRenewals,
  ),
];

export const CanExportSubscriptionsRenewalsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanExportOrganizationSubscriptionsRenewals,
  ),
];

export const CanExportContractsRenewalsPermissions: string[] = [
  getGroupPermission(
    SystemGroupPermissions.PeopleManagement,
    PeopleManagementPermissions.CanExportOrganizationContractsRenewals,
  ),
];

export const CanViewRenewalsPagePermissions: string[] = [
  ...CanViewContractsRenewalsPagePermissions,
  ...CanViewSubscriptionsRenewalsPagePermissions,
];
