import {
  canAddKEMemberPermissions,
  canAddSeatsKESubscriptionPermissions,
  canEditKESubscriptionPermissions,
  canRemoveKESubscriptionPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

export const keSubscriptionOverviewPermissions: ProductSubscriptionActionsPermissions =
  {
    canAddMemberPermissions: canAddKEMemberPermissions,
    canAddSeatsSubscriptionPermissions: canAddSeatsKESubscriptionPermissions,
    canEditSubscriptionPermissions: canEditKESubscriptionPermissions,
    canRemoveSubscriptionPermissions: canRemoveKESubscriptionPermissions,
  };
