import { useTranslation } from 'react-i18next';

import {
  FiltersWrapper,
  InputContainer,
  SearchInputContainer,
} from '../../../SimplePage/Filters';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import IconInput from '../../../UI/IconInput/IconInput';
import { MainSelect } from '../../../UI/MainSelect/MainSelect';
import {
  membersTypesOptions,
  renewStatusOptions,
} from './srMembersFilters.const';

import {
  SRMembersFiltersProps,
  SRMembersListFilters,
} from './srMembersFilters.models';
import { useSRMembersFilters } from './srMembersFilters.hooks';

const SRMembersFilters = ({
  availableFilters,
  standardPricing,
}: SRMembersFiltersProps) => {
  const { t } = useTranslation();
  const {
    filters,
    onFilterChangeHandler,
    plansOptions,
    subscriptionsListOptions,
  } = useSRMembersFilters({ standardPricing });

  const filtersComponents = availableFilters.map((filter) => {
    switch (filter) {
      case SRMembersListFilters.Search:
        return (
          <SearchInputContainer key={SRMembersListFilters.Search}>
            <IconInput
              defaultIcon="search"
              onChange={(evt) =>
                onFilterChangeHandler(evt, SRMembersListFilters.Search)
              }
              placeholder={t('filters.search-by')}
              value={filters.search}
            />
          </SearchInputContainer>
        );
      case SRMembersListFilters.Plan:
        return (
          <InputContainer key={SRMembersListFilters.Plan}>
            <MainSelect
              currentValue={filters.plan}
              isClearable
              isMulti
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, SRMembersListFilters.Plan)
              }
              options={plansOptions}
              placeholder={t('filters.plan')}
            />
          </InputContainer>
        );
      case SRMembersListFilters.MemberType:
        return (
          <InputContainer key={SRMembersListFilters.MemberType}>
            <MainSelect
              currentValue={filters.memberType}
              isClearable
              isMulti
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, SRMembersListFilters.MemberType)
              }
              options={membersTypesOptions}
              placeholder={t('filters.member')}
            />
          </InputContainer>
        );
      case SRMembersListFilters.RenewStatus:
        return (
          <InputContainer key={SRMembersListFilters.RenewStatus}>
            <MainSelect
              currentValue={filters.renewStatus}
              isClearable
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, SRMembersListFilters.RenewStatus)
              }
              options={renewStatusOptions}
              placeholder={t('filters.renew-status')}
            />
          </InputContainer>
        );
      case SRMembersListFilters.SubscriptionId:
        return (
          <InputContainer key={SRMembersListFilters.SubscriptionId}>
            <MainSelect
              currentValue={filters.subscriptionId}
              isClearable
              isMulti
              onChange={(evt: any) =>
                onFilterChangeHandler(evt, SRMembersListFilters.SubscriptionId)
              }
              options={subscriptionsListOptions}
              placeholder={t('filters.subscription')}
            />
          </InputContainer>
        );
      case SRMembersListFilters.OversubscribedOnly:
        return (
          <Checkbox
            key={SRMembersListFilters.OversubscribedOnly}
            checked={!!filters.oversubscribedOnly}
            onChange={(evt: any) =>
              onFilterChangeHandler(
                evt,
                SRMembersListFilters.OversubscribedOnly,
              )
            }
            label={t('filters.only-subscribed')}
          />
        );
      default:
        return null;
    }
  });

  return <FiltersWrapper>{filtersComponents}</FiltersWrapper>;
};

export default SRMembersFilters;
