import { useCallback } from 'react';
import { FieldRenderProps, useField, useForm } from 'react-final-form';

import { SubmitButtonType } from '../styledComponents/ModalFormPage/modalFormPage.models';

interface IUseFormSubmitProps {
  handleSubmit: any;
  canSaveDraft?: boolean;
}

export const useFormSubmit = ({
  handleSubmit,
  canSaveDraft,
}: IUseFormSubmitProps) => {
  const form = useForm();
  const {
    input: { value: isDraft },
  }: FieldRenderProps<boolean> = useField('isDraft');

  const submitHandler = useCallback(
    (buttonType: SubmitButtonType) => {
      canSaveDraft && form.change('isDraft', buttonType === 'draft');
      handleSubmit?.();
    },
    [canSaveDraft, form, handleSubmit],
  );

  const submitDraft = useCallback(() => {
    submitHandler('draft');
  }, [submitHandler]);

  const submitSave = useCallback(() => {
    submitHandler('save');
  }, [submitHandler]);

  return { submitDraft, submitSave, isDraft };
};
