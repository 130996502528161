import { Trans, useTranslation } from 'react-i18next';

import {
  ModalTitle,
  SimpleModal,
  ButtonsContainer,
  SimpleModalButton,
} from '../../../../../styledComponents/ModalCommon/ModalCommon';
import { StandardText } from '../../../../../styledComponents/Typography/Typography';
import { WarningsModalProps } from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.models';
import { REPAYMENT_NOT_POSSIBLE_MODAL } from './repaymentNotPossibleModal.const';
import { useRepaymentNotPossibleModal } from './repaymentNotPossibleModal.hooks';

const RepaymentNotPaymentModal = ({
  onWarningsConfirm,
}: WarningsModalProps) => {
  const { t } = useTranslation();
  const { handleConfirm, seeMembershipHandler } = useRepaymentNotPossibleModal({
    onWarningsConfirm,
  });

  return (
    <SimpleModal name={REPAYMENT_NOT_POSSIBLE_MODAL}>
      <ModalTitle>{t('headers.confirmation-required')}</ModalTitle>
      <StandardText>
        <Trans>{t('phrase.membership-already-renewed-and-paid')}</Trans>
      </StandardText>
      <ButtonsContainer>
        <SimpleModalButton styleType="light1" onClick={handleConfirm}>
          {t(`buttons.confirm`).toLocaleUpperCase()}
        </SimpleModalButton>
        <SimpleModalButton onClick={seeMembershipHandler}>
          {t(`buttons.see-membership`)}
        </SimpleModalButton>
      </ButtonsContainer>
    </SimpleModal>
  );
};

export default RepaymentNotPaymentModal;
