import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';

import { useActions } from '../../../../hooks/useActions';
import { useNavigateSearch } from '../../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../../hooks/useProcessing';
import {
  ProductSubscriptionOverviewPageParams,
  RoutePath,
} from '../../../../models/enums/RoutePath';
import { IChangeSubscriptionPlanCapacityModel } from '../../../../models/interfaces/Subscription/UpdateSubscriptionPlanCapacityModel';
import { organizationNameSelector } from '../../../../store/redux-slices/organizations/selectors';
import {
  ORGANIZATION_LOADING_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
} from '../../../../store/redux-slices/processes/constants';

import { ExtendProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { docSubscriptionExtensionDataSelector } from '../../../../store/redux-slices/subscriptions/dutyOfCare/selectors';
import { ICreateDOCOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/CreateDOCOrganizationSubscriptionExtensionModel';
import { IExtendDOCSubscriptionPayload } from '../../../../store/saga-slices/subscriptions/dutyOfCare/models';
import { extendDOCSubscription } from '../../../../store/saga-slices/subscriptions/dutyOfCare';

export const useExtendDOCSubscriptionForm = () => {
  const { organizationId, subscriptionId, productType } =
    useParams<ExtendProductSubscriptionPageParamsType>();
  const navigate = useNavigateSearch();
  const alert = useAlert();

  const { t } = useTranslation();

  const organizationName = useSelector(organizationNameSelector);
  const subscriptionData = useSelector(docSubscriptionExtensionDataSelector);

  const dataLoading = useProcessing([
    ORGANIZATION_LOADING_PROCESSING,
    SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  ]);

  const subscriptionEditing = useProcessing(
    SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  );
  const summaryLoading = useProcessing(
    SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  );

  const { validateErrors } = useFormErrorsActions();
  const { handleResponseError } = useApiResponseError();

  const ExtendSubscription = useActions(extendDOCSubscription);

  const handleSubmit = (
    values: ICreateDOCOrganizationSubscriptionExtensionModel,
  ) => {
    const payload: IExtendDOCSubscriptionPayload = {
      data: values,
      organizationId,
      subscriptionId,
      success: () => {
        navigate(
          RoutePath.ClientOverviewProductSubscriptionOverviewPageFullPath,
          {
            params: {
              [ProductSubscriptionOverviewPageParams.OrganizationId]:
                organizationId,
              [ProductSubscriptionOverviewPageParams.SubscriptionId]:
                subscriptionId,
              [ProductSubscriptionOverviewPageParams.SubscriptionType]:
                productType,
            },
          },
        );
        alert.success(
          t('alerts.subscription-extension-added', { organizationName }),
        );
      },
      error: (err: any) => {
        handleResponseError(err, values);
        alert.error(
          t('alerts.subscription-extension-fail', { organizationName }),
        );
      },
    };
    ExtendSubscription(payload);
  };

  const checkIfCanSubmit = useCallback(
    (values: IChangeSubscriptionPlanCapacityModel[]) => {
      if (!values || !summaryLoading) {
        return true;
      }
    },
    [summaryLoading],
  );

  const initData: ICreateDOCOrganizationSubscriptionExtensionModel = useMemo(
    () => ({
      changeSubscriptionPackageModel: {
        firmWide: !!subscriptionData?.firmWide,
        totalPrice: 0,
        seatsAmount: !!subscriptionData?.firmWide ? null : 0,
        packageType: subscriptionData?.packageType,
      },
    }),
    [subscriptionData],
  );

  return {
    handleSubmit,
    checkIfCanSubmit,
    validateErrors,
    subscriptionEditing,
    dataLoading,
    subscriptionData,
    productType,
    initData,
  };
};
