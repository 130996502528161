/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { ITLSubscriptionPricingTierOverviewModel } from '../../../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import {
  PricingRenderer,
  DateRenderer,
} from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { getSummaryRow } from './tlSubscriptionOverviewPlansTable.utils';
import { StyledMaterialTable } from './tlSubscriptionOverviewPricingTable.styles';


interface IProductSubscriptionOverviewPackagesTableProps {
  dataLoading?: boolean;
  pricingData: ITLSubscriptionPricingTierOverviewModel[];
  totalSeatsAmount?: number;
}

const TLSubscriptionOverviewPricingTable = ({
  dataLoading,
  pricingData,
  totalSeatsAmount,
}: IProductSubscriptionOverviewPackagesTableProps) => {
  const { t } = useTranslation();
  
  const cellRenderer =
    (
      key: keyof ITLSubscriptionPricingTierOverviewModel,
      options?: ICellOptions,
    ) =>
    (data: ITLSubscriptionPricingTierOverviewModel) => {
      switch (key) {
        case 'price': {
          return (
            <PricingRenderer options={options} price={data.price} readonly />
          );
        }
        case 'editDate': {
          return (
            <DateRenderer
              date={data.editDate}
              options={options}
            />
          );
        }
      }
    };


  return (
    <StyledMaterialTable
      columns={[
        {
          title: t('grid-columns.price-per-license'),
          field: 'price',
          alignment: 'left',
          sortable: true,
          cellRenderer: cellRenderer('price', { alignment: 'left' }),
        },
        {
          title: t('grid-columns.seats'),
          alignment: 'left',
          sortable: true,
          field: 'seatsAmount',
        },
        {
          title: t('grid-columns.date'),
          alignment: 'left',
          sortable: true,
          field: 'editDate',
          cellRenderer: cellRenderer('editDate'),
        },
      ]}
      data={pricingData}
      dataLoading={dataLoading}
      isPaginationHidden
      summaryRow={getSummaryRow(totalSeatsAmount)}
    />
  );
};

export default TLSubscriptionOverviewPricingTable;
