import { Fragment } from 'react';

import { getFilterTagLabels } from '../FiltersForm/FilterTypes/filterTypes.utils';

import { CustomFilter, FilterWithDefinition } from '../renewalFilters.models';
import {
  AndOperator,
  FiltersContainer,
  Label,
  Wrapper,
} from './filtersList.styles';
import FiltersListItem from './FiltersListItem/FiltersListItem';
type FiltersListProps = {
  label: string;
  filtersWithDefinitions?: FilterWithDefinition[];
  onRemoveFilter?: (filter: CustomFilter) => void;
};
const FiltersList = ({
  label,
  filtersWithDefinitions,
  onRemoveFilter,
}: FiltersListProps) => {
  if (!filtersWithDefinitions?.length) {
    return null;
  }
  return (
    <Wrapper>
      {label && <Label>{`${label}:`}</Label>}
      <FiltersContainer>
        {filtersWithDefinitions?.map(({ filter, filterDefinition }, index) => (
          <Fragment key={filter.id}>
            <FiltersListItem
              label={getFilterTagLabels(filter, filterDefinition)}
              onRemove={() => onRemoveFilter?.(filter)}
            />
            {index < filtersWithDefinitions.length - 1 && (
              <AndOperator>&</AndOperator>
            )}
          </Fragment>
        ))}
      </FiltersContainer>
    </Wrapper>
  );
};

export default FiltersList;
