import { useCallback } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { getCountries } from '../../../store/saga-slices/optionsSearch/common/api';
import { getFormGroupPath } from '../../../utils/getFormGroupPath';
import { FormAddressFields } from './formAddress.models';

type UseAddressFormProp = {
  name?: string;
};

export const useAddressForm = ({ name }: UseAddressFormProp) => {
  const {
    input: { value },
  }: FieldRenderProps<string> = useField(
    getFormGroupPath(name, FormAddressFields.Country),
  );

  const countriesPromiseOptions = useCallback(
    () =>
      getCountries().then(({ data }) => {
        const result = data.map((country) => ({
          label: country,
          value: country,
        }));

        if (!result.some((x) => x.value === value)) {
          result.push({ label: value, value });
        }
        return result;
      }),
    [value],
  );

  return {
    countriesPromiseOptions,
  };
};
