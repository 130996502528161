import styled from '@emotion/styled/macro';

import { SectionTitle } from '../Typography/Typography';
import Button from '../UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10.875rem;
`;

export const Circle = styled.div`
  width: 11rem;
  height: 11rem;
  border-radius: 6rem;
  background-color: ${({ theme }) => theme.color.hover};
`;

export const Title = styled(SectionTitle)`
  margin: 2.5rem 2rem;
  color: ${({ theme }) => theme.color.dark_blue};
`;

export const StyledButton = styled(Button)`
  margin-top: 3rem;
`;

export const Text = styled.p`
  font: 400 normal 1rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.black};
  width: 25rem;
  text-align: center;
`;

export const Image = styled.img`
  height: 15rem;
  width: 27.5rem;
`;
