import { useProcessing } from '../../../hooks/useProcessing';
import { SmallModal } from '../../ModalCommon/ModalCommon';

import { SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import EditRenewalSubscriptionStatusModalForm from './EditRenewalSubscriptionStatusModalForm/EditRenewalSubscriptionStatusModalForm';
import { EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME } from './editRenewalSubscriptionStatusModal.const';

interface IEditRenewalSubscriptionStatusModalProps {
  onRenewalStatusEdited?: () => void;
}

const EditRenewalSubscriptionStatusModal = ({
  onRenewalStatusEdited,
}: IEditRenewalSubscriptionStatusModalProps) => {
  const isProcessing = useProcessing(
    SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
  );
  return (
    <SmallModal
      name={EDIT_RENEWAL_SUBSCRIPTION_STATUS_MODAL_NAME}
      disabled={isProcessing}
    >
      <EditRenewalSubscriptionStatusModalForm
        onRenewalStatusEdited={onRenewalStatusEdited}
      />
    </SmallModal>
  );
};

export default EditRenewalSubscriptionStatusModal;
