import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
  userPermissions: string[];
  permissionLoaded: boolean;
}

const initState: IAuthState = {
  userPermissions: [],
  permissionLoaded: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setUserPermissions: (state, { payload }: PayloadAction<string[]>) => {
      state.userPermissions = payload;
    },
    setUserPermissionsLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.permissionLoaded = payload;
    },
    clearAuthData: () => initState,
  },
});

export const { setUserPermissions, clearAuthData, setUserPermissionsLoaded } =
  authSlice.actions;
export default authSlice.reducer;
