import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success-icon.svg';

import {
  Wrapper,
  MessageIcon,
  StyledButton,
} from './familyMemberMessage.styles';

interface IFamilyMemberFormMessageProps {
  addressIsSame?: boolean;
  onEditMember?: () => void;
}

const FamilyMemberFormMessage = ({
  addressIsSame,
  onEditMember,
}: IFamilyMemberFormMessageProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <MessageIcon>
        {addressIsSame ? <SuccessIcon /> : <WarningIcon />}
      </MessageIcon>
      {addressIsSame ? (
        t('phrase.member_address_same_family_residence_address')
      ) : (
        <div>
          {t('phrase.member_address_not_same_family_residence_address')}
          {onEditMember && (
            <StyledButton styleType="link" onClick={onEditMember}>
              {t('buttons.edit-member-details')}
            </StyledButton>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default FamilyMemberFormMessage;
