import MembershipPlanForm, {
  IMemberShipPlanFormProps,
} from '../../../../styledComponents/UI/Form/MembershipPlanForm/membershipPlanForm';
import { DefaultColumn } from '../../../../styledComponents/UI/Form/MembershipPlanForm/membershipPlanForm.models';

const AddPromoCodePlansForm = ({
  disabled,
  ...props
}: IMemberShipPlanFormProps) => (
  <MembershipPlanForm
    {...props}
    disabled={disabled}
    notUsedDefaultColumns={[DefaultColumn.Seats]}
  />
);

export default AddPromoCodePlansForm;
