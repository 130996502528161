import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { ICreateAAMembershipPageModel } from '../../../../../../models/interfaces/Membership/AirAmbulance/CreateAAMembershipPageModel';
import { FieldErrorMessage } from '../../../../../../models/FieldErrorMessage';
import { MembershipType } from '../../../../../../models/MembershipType';
import { validateFormData } from '../../../../../../utils/validations/validateFormData';
import { MembershipOrganizationDetailsFormFields } from './AAMembershipOrganizationDetailsForm/aaMembershipOrganizationDetailsForm.models';
import { MembershipTypeFormFields } from './AAMembershipTypeForm/aaMembershipType.models';
import { validateFamilyMemberFormSchema } from '../../../../../../styledComponents/Forms/FamilyMember/familyMember.validation';
import { MembershipFamilyMembersFormFields } from '../MembershipCommon/MembershipFamilyMembersForm/membershipFamilyMembers.models';

export const aaMembershipDetailsSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [MembershipOrganizationDetailsFormFields.PromoCode]: {
      errorMessage: t('validation.promo-code-format-incorrect'),
      type: 'string',
      maxLength: 30,
    },
    [MembershipOrganizationDetailsFormFields.EffectiveDate]: {
      errorMessage: t('validation.effective-date-later-expiration-date'),
      type: 'string',
      format: 'date',
      formatMaximum: {
        $data: `1/${MembershipOrganizationDetailsFormFields.ExpirationDate}`,
      },
    },
    [MembershipFamilyMembersFormFields.SecondaryMembers]: {
      type: 'array',
      items: validateFamilyMemberFormSchema,
    },
  },
  dependencies: {
    membershipType: {
      if: {
        properties: {
          [MembershipTypeFormFields.MembershipType]: {
            enum: [MembershipType.Organization],
          },
        },
      },
      then: {
        required: [
          MembershipOrganizationDetailsFormFields.Organization,
          MembershipOrganizationDetailsFormFields.SubscriptionType,
        ],
      },
    },
  },
  required: [
    MembershipOrganizationDetailsFormFields.MembershipPlanId,
    MembershipOrganizationDetailsFormFields.EffectiveDate,
  ],
};

export const validateAAMembershipDetailsForm = (
  data: ICreateAAMembershipPageModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, aaMembershipDetailsSchema);
