import { ReactNode } from 'react';

import { RootPage } from '../Page/Page';
import Sidebar from '../Sidebar/Sidebar';

type LayoutType = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutType) => (
  <RootPage>
    <Sidebar />
    <main>{children}</main>
  </RootPage>
);

export default Layout;
