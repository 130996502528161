export const ContactFormName = 'corp-point-of-contact';

export enum CorpPointOfContactFieldsModel {
  emailAddress = 'emailAddress',
  firstName = 'firstName',
  lastName = 'lastName',
  workPhone = 'workPhone',
  mobilePhone = 'mobilePhone',
  internalNotes = 'internalNotes',
  jobTitle = 'jobTitle',
  isOrganizationAdministrator = 'isOrganizationAdministrator',
  roles = 'roles',
}
