import { call, put, putResolve } from 'typed-redux-saga/macro';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { PayloadAction } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { updateProcessState } from '../../../redux-slices/processes';
import {
  SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING,
  SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING,
  SUBSCRIPTION_DELETE_PROCESSING,
  SUBSCRIPTION_DETAILS_EDITING_PROCESSING,
  SUBSCRIPTION_EDITING_LOADING_PROCESSING,
  SUBSCRIPTION_EDITING_PROCESSING,
  SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING,
  SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING,
  SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
  SUBSCRIPTION_RENEW_PROCESSING,
  SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING,
} from '../../../redux-slices/processes/constants';
import { Log } from '../../../../utils/logger';

import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { parseDate } from '../../../../utils/dateHelpers';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import {
  setDeletedSubscriptionId,
  setSubscriptionSummary,
  updateSubscription,
} from '../../../redux-slices/subscriptions/common';
import { IRenewOrganizationSubscriptionAnalyseModel } from '../../../../models/IRenewOrganizationSubscriptionAnalyseModel';
import {
  IDeleteDOCSubscriptionPayload,
  IEditDOCSubscriptionOptionsPayload,
  IExtendDOCSubscriptionPayload,
  ILoadDatesForNewDOCSubscriptionPayload,
  ILoadEditingDOCSubscriptionPayload,
  ILoadDOCSubscriptionExtensionSummary,
  ILoadDOCSubscriptionOverviewPayload,
  ILoadDOCSubscriptionRenewalAnalysePayload,
  ILoadDOCSubscriptionRenewalSuggestionPayload,
  ILoadDOCSubscriptionSummary,
  IRenewDOCSubscriptionPayload,
  ISendDOCSubscriptionRenewalMembershipsRequest,
  IUpdateDOCSubscriptionPayload,
  IEditDoCSubscriptionRenewalStatusPayload,
} from './models';
import {
  deleteDOCSubscriptionRequest,
  editDoCSubscriptionRenewalStatusRequest,
  extendDOCSubscriptionRequest,
  getDatesForNewDOCSubscription,
  getDOCSubscription,
  getDOCSubscriptionExtensionSummary,
  getDOCSubscriptionRenewalAnalyseRequest,
  getDOCSubscriptionRenewalSuggestion,
  getDOCSubscriptionSuggestion,
  getDOCSubscriptionSummary,
  renewDOCSubscriptionRequest,
  sendDOCSubscriptionRenewalMembershipsRequest,
  updateDOCSubscriptionDetails,
  updateDOCSubscriptionRequest,
} from './api';

import { IDOCOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import { IDOCSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCSubscriptionRenewalSuggestionModel';
import {
  clearDOCSubscriptionRenewalAnalyse,
  setDOCCurrentEditingSubscription,
  setDOCSubscriptionDates,
  setDOCSubscriptionExtensionSummary,
  setDOCSubscriptionOverview,
  setDOCSubscriptionRenewalAnalyse,
  setDOCSubscriptionRenewalSuggestion,
} from '../../../redux-slices/subscriptions/dutyOfCare';
import { IDOCOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionExtensionSummaryModel';
import { updateRenewalStatusSubscriptions } from '../../../redux-slices/renewalFilter';

const docSubscriptionsSlice = createSliceSaga({
  name: 'doc-subscriptions-saga',
  caseSagas: {
    *loadDOCSubscriptionOverview({
      payload: { error, organizationId, subscriptionId, success },
    }: PayloadAction<ILoadDOCSubscriptionOverviewPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
        const response: AxiosResponse<IDOCOrganizationSubscriptionOverview> =
          yield* call(getDOCSubscription, organizationId, subscriptionId);

        yield put(setDOCSubscriptionOverview(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_OVERVIEW_LOADING_PROCESSING));
      }
    },

    *loadDatesForNewDOCSubscription({
      payload: { error, organizationId, contractId, success },
    }: PayloadAction<ILoadDatesForNewDOCSubscriptionPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING),
        );
        const response: AxiosResponse<ISubscriptionDates> = yield* call(
          getDatesForNewDOCSubscription,
          organizationId,
          contractId,
        );

        const parsedDates: ISubscriptionDates = {
          ...response.data,
          effectiveDateFrom: parseDate(response.data.effectiveDateFrom),
          effectiveDateTo: parseDate(response.data.effectiveDateTo),
        };
        yield put(setDOCSubscriptionDates(parsedDates));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTIONS_DOC_DATES_LOADING_PROCESSING),
        );
      }
    },
    *loadEditingDOCSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<ILoadEditingDOCSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
        const response = yield* call(
          getDOCSubscriptionSuggestion,
          organizationId,
          subscriptionId,
          cancellationToken,
        );

        yield put(setDOCCurrentEditingSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_LOADING_PROCESSING));
      }
    },
    *loadDOCSubscriptionRenewalSuggestion({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadDOCSubscriptionRenewalSuggestionPayload>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IDOCSubscriptionRenewalSuggestionModel> =
          yield* call(
            getDOCSubscriptionRenewalSuggestion,
            organizationId,
            subscriptionId,
          );

        yield put(setDOCSubscriptionRenewalSuggestion(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTION_RENEWAL_SUGGESTION_LOADING_PROCESSING,
          ),
        );
      }
    },
    *loadDOCSubscriptionSummary({
      payload: { data, error, organizationId, success },
    }: PayloadAction<ILoadDOCSubscriptionSummary>) {
      try {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
        const response: AxiosResponse<ISubscriptionSummaryModel> = yield* call(
          getDOCSubscriptionSummary,
          organizationId,
          data,
        );
        const summaryResponse = {
          ...response.data,
        };
        yield put(setSubscriptionSummary(summaryResponse));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTIONS_SUMMARY_LOADING_PROCESSING));
      }
    },
    *updateProductDOCSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        data,
        success,
        cancellationToken,
      },
    }: PayloadAction<IUpdateDOCSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
        yield call(
          updateDOCSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
          cancellationToken,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_EDITING_PROCESSING));
      }
    },
    *deleteProductDOCSubscription({
      payload: {
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IDeleteDOCSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
        yield* call(
          deleteDOCSubscriptionRequest,
          organizationId,
          subscriptionId,
          cancellationToken,
        );
        yield putResolve(setDeletedSubscriptionId([subscriptionId]));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DELETE_PROCESSING));
      }
    },
    *editDOCSubscriptionOptions({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IEditDOCSubscriptionOptionsPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          updateDOCSubscriptionDetails,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *extendDOCSubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IExtendDOCSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
        yield* call(
          extendDOCSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_DETAILS_EDITING_PROCESSING));
      }
    },
    *loadDOCSubscriptionExtensionSummary({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<ILoadDOCSubscriptionExtensionSummary>) {
      try {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
        const response: AxiosResponse<IDOCOrganizationSubscriptionExtensionSummaryModel> =
          yield* call(
            getDOCSubscriptionExtensionSummary,
            organizationId,
            subscriptionId,
            data,
          );

        yield put(setDOCSubscriptionExtensionSummary(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(
            SUBSCRIPTIONS_EXTENSION_SUMMARY_LOADING_PROCESSING,
          ),
        );
      }
    },
    *renewDOCSubscription({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<IRenewDOCSubscriptionPayload>) {
      try {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
        const response = yield* call(
          renewDOCSubscriptionRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(updateSubscription(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(SUBSCRIPTION_RENEW_PROCESSING));
      }
    },
    *loadDOCSubscriptionRenewalAnalyse({
      payload: { error, success, subscriptionId, organizationId },
    }: PayloadAction<ILoadDOCSubscriptionRenewalAnalysePayload>) {
      try {
        yield put(clearDOCSubscriptionRenewalAnalyse());
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
        const response: AxiosResponse<IRenewOrganizationSubscriptionAnalyseModel> =
          yield* call(
            getDOCSubscriptionRenewalAnalyseRequest,
            organizationId,
            subscriptionId,
          );
        yield put(setDOCSubscriptionRenewalAnalyse(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_ANALYSE_LOADING_PROCESSING),
        );
      }
    },
    *sendDOCSubscriptionRenewalMemberships({
      payload: { data, error, organizationId, subscriptionId, success },
    }: PayloadAction<ISendDOCSubscriptionRenewalMembershipsRequest>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
        const response = yield* call(
          sendDOCSubscriptionRenewalMembershipsRequest,
          organizationId,
          subscriptionId,
          data,
        );
        yield put(clearDOCSubscriptionRenewalAnalyse());
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_MEMBERSHIPS_SAVE_PROCESSING),
        );
      }
    },
    *editDoCSubscriptionRenewalStatus({
      payload: {
        renewalStatus,
        error,
        organizationId,
        subscriptionId,
        success,
        cancellationToken,
      },
    }: PayloadAction<IEditDoCSubscriptionRenewalStatusPayload>) {
      try {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
        yield call(
          editDoCSubscriptionRenewalStatusRequest,
          organizationId,
          subscriptionId,
          renewalStatus,
          cancellationToken,
        );

        yield put(
          updateRenewalStatusSubscriptions({
            organizationId,
            subscriptionId,
            renewalStatus,
          }),
        );
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(
          updateProcessState(SUBSCRIPTION_RENEWAL_STATUS_EDITING_PROCESSING),
        );
      }
    },
  },
  sagaType: SagaType.TakeLatest,
});

export default docSubscriptionsSlice.saga;
export const {
  loadDOCSubscriptionOverview,
  loadDatesForNewDOCSubscription,
  loadEditingDOCSubscription,
  loadDOCSubscriptionSummary,
  updateProductDOCSubscription,
  deleteProductDOCSubscription,
  editDOCSubscriptionOptions,
  extendDOCSubscription,
  loadDOCSubscriptionExtensionSummary,
  loadDOCSubscriptionRenewalSuggestion,
  renewDOCSubscription,
  loadDOCSubscriptionRenewalAnalyse,
  sendDOCSubscriptionRenewalMemberships,
  editDoCSubscriptionRenewalStatus,
} = docSubscriptionsSlice.actions;
export const { actions } = docSubscriptionsSlice;
