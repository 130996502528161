import styled from '@emotion/styled';

import FormCheckbox from '../../../../../styledComponents/UI/Form/FormCheckbox/FormCheckbox';

export const Wrapper = styled.div`
  margin-top: 1rem;
`;

export const StyledFormCheckbox = styled(FormCheckbox)`
  margin-top: 1.5rem;
`;
