import { CustomComponent } from '../../../../models/types/CustomComponent';
import { IWizardStep } from '../../../../store/redux-slices/wizard';
import {
  Dot,
  DotContainer,
  Line,
  LineContainer,
  Link,
  StepLabel,
  Wrapper,
} from './wizardStep.styles';

type WizardStepType = CustomComponent & {
  step: IWizardStep;
  isActive?: boolean;
  isLineVisible?: boolean;
  isDisabled?: boolean;
  isAvailable?: boolean;
  onClick?: (step: string) => void;
};

const WizardStep = ({
  step,
  isActive,
  isDisabled,
  isLineVisible,
  onClick,
  isAvailable,
  className,
}: WizardStepType) => (
  <Wrapper className={className}>
    {isLineVisible && (
      <LineContainer>
        <Line isDisabled={isDisabled} />
      </LineContainer>
    )}
    <StepLabel
      isAvailable={isAvailable}
      isDisabled={isDisabled}
      onClick={() => onClick && onClick(step.canonicalName)}
    >
      <DotContainer
        isDisabled={isDisabled}
        isAvailable={isAvailable}
        isActive={isActive}
      >
        <Dot />
      </DotContainer>
      <Link>{step.caption}</Link>
    </StepLabel>
  </Wrapper>
);

export default WizardStep;
