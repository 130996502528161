import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../hooks/useActions';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { clearTLPricesTiers } from '../../../../../store/redux-slices/pricing/travelLicense';
import { tlPricingTiersSelector } from '../../../../../store/redux-slices/pricing/travelLicense/selectors';
import { TL_PRICING_LOADING_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { tlLoadPricingTiers } from '../../../../../store/saga-slices/pricing/travelLicense';

export const useTLPricingTooltip = () => {
  const LoadTLPricing = useActions(tlLoadPricingTiers);
  const ClearTLPricing = useActions(clearTLPricesTiers);
  const isLoading = useProcessing(TL_PRICING_LOADING_PROCESSING);

  const tlPricing = useSelector(tlPricingTiersSelector);

  useEffect(() => {
    if (!tlPricing.length) {
      LoadTLPricing();
    }
  }, [LoadTLPricing, tlPricing.length]);

  useEffect(() => () => ClearTLPricing(), [ClearTLPricing]);

  return { tlPricing, isLoading };
};
