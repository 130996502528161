import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { personSelector } from '../../../../../store/redux-slices/people/selectors';

import FamilyMemberForm from '../../../../../styledComponents/Forms/FamilyMember/FamilyMember';

import { Modal } from '../../../../../styledComponents/Modal/Modal';
import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';
import { ADD_AA_FAMILY_MEMBER_MODAL_NAME } from './addAAFamilyMemberModal.const';
import { useAddAAFamilyMemberModal } from './addAAFamilyMemberModal.hooks';

interface IAddAAFamilyMemberModalProps {
  onModalCancel?: () => void;
  onFamilyMemberAdded?: () => void;
  id?: string;
}

const AddAAFamilyMemberModal = ({
  onModalCancel,
  onFamilyMemberAdded,
  id,
}: IAddAAFamilyMemberModalProps) => {
  const { t } = useTranslation();
  const {
    onCloseHandler,
    handleSubmit,
    secondaryMemberAdding,
    validateForm,
    canAddMemberTypes,
  } = useAddAAFamilyMemberModal({
    onFamilyMemberAdded,
    id,
  });

  const person = useSelector(personSelector);

  return (
    <Modal
      disabled={secondaryMemberAdding}
      name={ADD_AA_FAMILY_MEMBER_MODAL_NAME + id}
      onModalClose={onModalCancel}
    >
      <Form
        initialValues={{
          residenceAddress: {},
          addressSameAsPrimaryMemberResidenceAddress: true,
        }}
        validate={validateForm}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            handleSubmit={renderProps.handleSubmit}
            isProcessing={secondaryMemberAdding}
            onCancel={onCloseHandler}
            title={t('headers.add-family-member')}
          >
            <FamilyMemberForm
              disabled={secondaryMemberAdding}
              residenceAddress={person.residenceAddress}
              titlesVisible
              memberTypes={canAddMemberTypes}
            />
          </ModalFormPage>
        )}
      </Form>
    </Modal>
  );
};

export default AddAAFamilyMemberModal;
