import { ReactNode } from 'react';

import { Status } from '../../models/enums/Status';
import { CustomComponent } from '../../models/types/CustomComponent';
import { InfoStatus } from '../UI/InfoStatus';
import {
  StatusWrapper,
  StatusText,
  IconWrapper,
} from './statusMessageWithIcon.styles';

interface IStatusMessageProps extends CustomComponent {
  status?: Status;
  message?: ReactNode | string;
  isBold?: boolean;
}

const StatusMessageWithIcon = ({
  status = Status.WARNING,
  message,
  className,
  isBold,
  children,
}: IStatusMessageProps) => (
  <StatusWrapper className={className}>
    <IconWrapper>
      <InfoStatus status={status} />
    </IconWrapper>
    <StatusText isBold={isBold} status={status}>
      {message || children}
    </StatusText>
  </StatusWrapper>
);

export default StatusMessageWithIcon;
