import { useSelector } from 'react-redux';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useNavigateSearch } from '../../../../../hooks/useNavigateSearch';
import { RoutePath } from '../../../../../models/enums/RoutePath';
import { MemberOverviewPageParamsType } from '../../../../../models/types/RoutePath';
import { primaryMemberSelector } from '../../../../../store/redux-slices/membership/common/selectors';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { useApiWarningActions } from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.hooks';
import {
  WarningModalData,
  WarningsModalProps,
} from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.models';
import { prepareConfirmedWarning } from '../../../../../styledComponents/WarningsConfirmation/warningsConfirmation.utils';
import { REPAYMENT_NOT_POSSIBLE_MODAL } from './repaymentNotPossibleModal.const';

export const useRepaymentNotPossibleModal = ({
  onWarningsConfirm,
}: WarningsModalProps) => {
  const { addRatification } = useApiWarningActions();
  const navigate = useNavigateSearch();
  const UpdateModalState = useActions(updateModalState);
  const { warning, data }: WarningModalData = useModalDetails(
    REPAYMENT_NOT_POSSIBLE_MODAL,
  );
  const member = useSelector(primaryMemberSelector);

  const handleConfirm = () => {
    const ratifications = addRatification(prepareConfirmedWarning(warning));
    onWarningsConfirm?.(data, ratifications);
    UpdateModalState(REPAYMENT_NOT_POSSIBLE_MODAL);
  };

  const seeMembershipHandler = () => {
    UpdateModalState(REPAYMENT_NOT_POSSIBLE_MODAL);
    const params: MemberOverviewPageParamsType = {
      memberId: member?.personId,
    };
    navigate(RoutePath.MemberOverviewPage, {
      params,
    });
  };

  return { handleConfirm, seeMembershipHandler };
};
