import { useEffect } from 'react';
import { Navigate, RouteProps, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export type LinkProps = RouteProps & {
  state?: any;
  to?: string;
};

const Redirect = ({ state, to }: LinkProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!to) {
      navigate(-1);
    }
  }, [to, navigate]);

  return to ? (
    <Navigate
      state={{ redirected: true, ...state }}
      to={{
        pathname: to,
        search: searchParams.toString() && `?${searchParams.toString()}`,
      }}
    />
  ) : null;
};

export default Redirect;
