/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IDOCSubscriptionPackageOptionsHistoryModel } from '../../../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverview';
import {
  PricingRenderer,
  DateRenderer,
  NameRenderer,
} from '../../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { ICellOptions } from '../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { docOverSubscribedRenderer } from '../TableRenderers/DOCSubscriptionOverviewTableRenderers';
import { SeatsAmountRenderer } from './docSubscriptionOverviewPackagesTable.renderers';
import { StyledMaterialTable } from './docSubscriptionOverviewPackagesTable.styles';


interface IProductSubscriptionOverviewPackagesTableProps {
  dataLoading?: boolean;
  packagesOptions: IDOCSubscriptionPackageOptionsHistoryModel[];
  seatsInUse: number;
  onRowClick?: (data: IDOCSubscriptionPackageOptionsHistoryModel) => void;
  canClickRowPermissions?: string[];
  onSeeOversubscriptionClick?: (data?: IDOCSubscriptionPackageOptionsHistoryModel) => void;
  isOversubscribed?: boolean;

}

const DOCSubscriptionOverviewPackagesTable = ({
  dataLoading,
  packagesOptions,
  onRowClick,
  canClickRowPermissions,
  seatsInUse,
  onSeeOversubscriptionClick,
  isOversubscribed,
}: IProductSubscriptionOverviewPackagesTableProps) => {
  const { t } = useTranslation();
  
  const cellRenderer =
    (
      key: keyof IDOCSubscriptionPackageOptionsHistoryModel,
      options?: ICellOptions,
    ) =>
    (data: IDOCSubscriptionPackageOptionsHistoryModel) => {
      switch (key) {
        case 'packageType': {
          return (
            <NameRenderer options={options} value={data.packageType} />
          );
        }
        case 'totalPrice': {
          return (
            <PricingRenderer options={options} price={data.totalPrice} readonly />
          );
        }
        case 'seatsAmount': {
          return (
            <SeatsAmountRenderer
              seatsAmount={data.seatsAmount}
              seatsInUse={seatsInUse}
              options={options}
              firmWide={data.firmWide}
            />
          );
        }
        case 'editDate': {
          return (
            <DateRenderer
              date={data.editDate}
              options={options}
            />
          );
        }
      }
    };


  return (
    <StyledMaterialTable
      onRowClick={onRowClick}
      columns={[
        {
          title: t('grid-columns.package'),
          field: 'packageType',
          cellRenderer: cellRenderer('packageType', { alignment: 'left', textBold: true }),
        },
        {
          title: t('grid-columns.total-price'),
          field: 'totalPrice',
          alignment: 'left',
          cellRenderer: cellRenderer('totalPrice', { alignment: 'left' }),
        },
        {
          title: t('grid-columns.seats'),
          alignment: 'left',
          field: 'seatsAmount',
          cellRenderer: cellRenderer('seatsAmount'),
        },
        {
          title: t('grid-columns.date'),
          alignment: 'left',
          field: 'editDate',
          cellRenderer: cellRenderer('editDate'),
        },
         {
          field: 'oversubscribed',
          cellRenderer: docOverSubscribedRenderer(onSeeOversubscriptionClick, canClickRowPermissions, isOversubscribed),
        },
      ]}
      data={packagesOptions ? packagesOptions : []}
      dataLoading={dataLoading}
      isPaginationHidden
      canClickRowPermissions={canClickRowPermissions}
    />
  );
};

export default DOCSubscriptionOverviewPackagesTable;
