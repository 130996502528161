import { FieldRenderProps, useField } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import { ProductType } from '../../../../models/ProductType';
import { IDOCOrganizationSubscriptionExtensionData } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionOverviewPackage';
import { IChangeSubscriptionPackageModel } from '../../../../models/interfaces/Subscription/DutyOfCare/ChangeDOCSubscriptionCapacityModel ';

type UseExtendDOCSubscriptionPackageFormTableType = {
  productType?: ProductType;
  name?: string;
  packageData?: IDOCOrganizationSubscriptionExtensionData;
};

export const useExtendDOCSubscriptionPackageFormTable = ({
  name,
  packageData,
}: UseExtendDOCSubscriptionPackageFormTableType) => {
  const { t } = useTranslation();

  const {
    input,
    meta: { error },
  }: FieldRenderProps<IChangeSubscriptionPackageModel> = useField(name);

  const preparedErrors =
    error &&
    Object.keys(error).map(
      (key) => `${t(`fields.${key}.label`)} -  ${error[key]}`,
    );

  return {
    currentValue: input.value,
    errors: preparedErrors,
  };
};
