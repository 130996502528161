import { useTranslation } from 'react-i18next';

import { AvailableStatus } from '../../../models/enums/AvailableStatus';
import Header from '../../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import {
  HeaderDescription,
  HeaderTitleContainer,
  HeaderTitle,
} from './memberOverviewPageHeader.styles';

type MemberOverviewHeaderProps = {
  status: AvailableStatus;
  title: string;
  memberNumber?: string;
};

const MemberOverviewHeader = ({
  title,
  status,
  memberNumber,
}: MemberOverviewHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Header status={status}>
      <HeaderTitleContainer>
        <HeaderTitle>{title}</HeaderTitle>
        {memberNumber && (
          <HeaderDescription>
            {`/${t('phrase.member-no', { value: memberNumber })}`}
          </HeaderDescription>
        )}
      </HeaderTitleContainer>
    </Header>
  );
};

export default MemberOverviewHeader;
