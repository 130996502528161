import { FormApi } from 'final-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../models/SelectOption';

import {
  CustomFilter,
  CustomFilterField,
  FilterDefinition,
} from '../renewalFilters.models';
import { CustomFiltersFields } from './filterForm.models';

import { FiltersOptions } from './FilterTypes/filtersTypes.models';

type UseFiltersFormProps = {
  filtersDefinitions: FilterDefinition<FiltersOptions>[];
  onAddFilter?: (filter: CustomFilter) => void;
};

export const useFiltersForm = ({
  filtersDefinitions,
  onAddFilter,
}: UseFiltersFormProps) => {
  const { t } = useTranslation();
  const fieldsOptions = useMemo(
    (): SelectOption<CustomFilterField>[] =>
      filtersDefinitions.map(({ field, filterType }) => ({
        value: { fieldName: field, filterType },
        label: t(`filters.${field}`),
      })),
    [filtersDefinitions, t],
  );

  const handleSubmitFilter = (
    values: CustomFilter,
    form: FormApi<CustomFilter>,
  ) => {
    form.reset();
    form.resetFieldState(CustomFiltersFields.Field);

    const id = Date.now().toString();
    onAddFilter?.({ id, ...values });
  };

  return { fieldsOptions, handleSubmitFilter };
};
