import { useTranslation } from 'react-i18next';

import IconInput from '../../../../../styledComponents/UI/IconInput/IconInput';

import {
  RenewalTopFiltersProps,
  RenewalTopFilter,
} from './renewalTopFilters.models';
import { FiltersWrapper } from './renewalTopFilters.styles';
import { SearchInputContainer } from '../../../../../styledComponents/SimplePage/Filters';

const RenewalTopFilters = ({
  availableFilters,
  filters,
  onFilterChangeHandler,
}: RenewalTopFiltersProps) => {
  const { t } = useTranslation();
  if (!availableFilters?.length) {
    return null;
  }

  const filtersComponents = availableFilters?.map((filter) => {
    switch (filter) {
      case RenewalTopFilter.SearchFilter:
        return (
          <SearchInputContainer>
            <IconInput
              id={RenewalTopFilter.SearchFilter}
              key={RenewalTopFilter.SearchFilter}
              defaultIcon="search"
              onChange={(evt) =>
                onFilterChangeHandler(
                  evt.target.value,
                  RenewalTopFilter.SearchFilter,
                )
              }
              placeholder={t('filters.search-by')}
              value={filters.searchFilter}
            />
          </SearchInputContainer>
        );
      default:
        return null;
    }
  });

  return <FiltersWrapper>{filtersComponents}</FiltersWrapper>;
};

export default RenewalTopFilters;
