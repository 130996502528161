import { useMemo } from 'react';

import { usePermissions } from '../../hooks/usePermissions';
import { IMaterialColumn } from './materialTable.models';

type UseMaterialTableProps = {
  columns: IMaterialColumn[];
  canClickRowPermissions?: string[];
};

export const useMaterialTable = ({
  columns,
  canClickRowPermissions,
}: UseMaterialTableProps) => {
  const { checkUserPermissions } = usePermissions();
  const { hasPermissions: canClickRow } = usePermissions(
    canClickRowPermissions,
  );

  const filteredColumns = useMemo(
    () => columns.filter((x) => checkUserPermissions(x.permissions)),
    [checkUserPermissions, columns],
  );

  return { filteredColumns, canClickRow };
};
