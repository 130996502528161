import { InputIconWrapper, StyledFieldLabel } from './iconInput.styles';
import { IconInputWrapperProps } from './iconInput.models';
import { findIcon } from './iconInput.utils';

const IconInputWrapper = ({
  defaultIcon,
  icon,
  iconPosition = 'end',
  className,
  isLabelHidden,
  label,
  labelAnimation,
  disabled,
  children,
  isError,
}: IconInputWrapperProps) => (
  <InputIconWrapper
    isError={isError}
    className={className}
    iconPosition={iconPosition}
  >
    {children}
    {!isLabelHidden && (
      <StyledFieldLabel disabled={disabled} autoLabelAnimation={labelAnimation}>
        {label}
      </StyledFieldLabel>
    )}
    {iconPosition === 'start' && (icon || findIcon(defaultIcon, disabled))}
    {iconPosition === 'end' && (icon || findIcon(defaultIcon, disabled))}
  </InputIconWrapper>
);

export default IconInputWrapper;
