import styled from '@emotion/styled/macro';

import { SubscriptionOptions } from '../../../../../../../styledComponents/Forms/SubscriptionOptions/SubscriptionOptions';

import { ModalFormSectionTitle } from '../../../../../../../styledComponents/Typography/Typography';

export const OptionsTitle = styled(ModalFormSectionTitle)`
  margin-bottom: 1rem;
  margin-top: 1.5rem;
`;

export const StyledSubscriptionOptions = styled(SubscriptionOptions)`
  margin-top: 2rem;
`;
