import { ToolsWrapper } from '../../../../styledComponents/SimplePage/Tools';
import CorporateClientGeneralFilters from '../CorporateClientGeneralFilters/CorporateClientGeneralFilters';

const CorporateClientGeneralTools = () => (
  <ToolsWrapper>
    <CorporateClientGeneralFilters />
  </ToolsWrapper>
);

export default CorporateClientGeneralTools;
