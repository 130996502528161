import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as ErrorIcon } from '../../../../../assets/icons/error-icon.svg';
import { CustomForm } from '../../../../../models/types/CustomForm';
import { firstCreditCardSelector } from '../../../../../store/redux-slices/payment/selectors';
import CreditCardType from '../../../../../styledComponents/CreditCard/CreditCardType/CreditCardType';
import { FormColumn } from '../../../../../styledComponents/UI/Form/FormColumn/FormColumn';
import { FormRow } from '../../../../../styledComponents/UI/Form/FormRow/FormRow';
import {
  CardNumber,
  ExpiredCardText,
  CardNumberWrapper,
} from './paymentUseExistingCardForm.styles';

const PaymentExistingCardForm = ({ disabled }: CustomForm) => {
  const { t } = useTranslation();
  const creditCard = useSelector(firstCreditCardSelector);

  if (!creditCard) {
    return null;
  }

  return (
    <FormColumn>
      <FormRow>
        <CardNumberWrapper>
          <CreditCardType creditCardType={creditCard?.creditCardType} />
          <CardNumber disabled={disabled || creditCard?.isExpired}>
            {creditCard?.creditCardNumber}
          </CardNumber>
        </CardNumberWrapper>
        {creditCard?.isExpired && (
          <ExpiredCardText>
            <ErrorIcon />
            {t('phrase.this-credit-card-is-expired')}
          </ExpiredCardText>
        )}
      </FormRow>
    </FormColumn>
  );
};

export default PaymentExistingCardForm;
