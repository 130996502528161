import styled from '@emotion/styled/macro';

import Button from '../../../../../styledComponents/UI/Button/Button';
import { ButtonProps } from '../../../../../styledComponents/UI/Button/Button.types';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus-icon.svg';

type FilterInputContainerProps = {
  small?: boolean;
};

export const FilterInputContainer = styled.div<FilterInputContainerProps>`
  width: ${({ small }) => (small ? '9.75rem' : '16.25rem')};
`;

export const FilterRow = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 3rem;
`;

export const AddFilterButton = styled((props: ButtonProps) => (
  <Button styleType="icon" {...props}>
    <PlusIcon />
  </Button>
))`
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid ${({ theme }) => theme.color.lighter_blue};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
