import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IEditTLOrganizationSubscriptionModel } from '../../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { loadTLSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/travelLicense';
import { ILoadTLSubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/travelLicense/models';

type TLRenewSubscriptionSummaryLoaderProps = CustomComponent & {
  data: IEditTLOrganizationSubscriptionModel;
};

export const TLRenewSubscriptionSummaryLoader = ({
  data,
}: TLRenewSubscriptionSummaryLoaderProps) => {
  const { organizationId } = useParams();

  const LoadSubscriptionSummary = useActions(loadTLSubscriptionSummary);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!data.pricingTiers) {
      return;
    }

    const payload: ILoadTLSubscriptionSummary = {
      data: { pricingTiers: data.pricingTiers },
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, data.pricingTiers, debounce, organizationId]);

  return <></>;
};
