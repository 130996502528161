import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useActions } from '../../../../../hooks/useActions';
import { keMembershipPlansPricesSelector } from '../../../../../store/redux-slices/pricing/kidnapAndExtortion/selectors';
import { keLoadStandardPricing } from '../../../../../store/saga-slices/pricing/kidnapAndExtortion';
import { getFormGroupPath } from '../../../../../utils/getFormGroupPath';
import { BasicSubscriptionFormFields } from '../../BasicSubscriptionForm/basicSubscriptionForm.models';

type UseSubscriptionPricingForm = {
  name?: string;
  isEditMode?: boolean;
};

export const useSubscriptionPricingForm = ({
  name,
  isEditMode,
}: UseSubscriptionPricingForm) => {
  const {
    input: { value: subscriptionType },
  } = useField(getFormGroupPath(name, BasicSubscriptionFormFields.ProductType));

  const membershipPlansPrices = useSelector(keMembershipPlansPricesSelector);
  const KELoadStandardPricing = useActions(keLoadStandardPricing);

  useEffect(() => {
    if (subscriptionType) {
      KELoadStandardPricing();
    }
  }, [subscriptionType, KELoadStandardPricing]);

  return {
    membershipPlansPrices,
    subscriptionType,
  };
};
