import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ORGANIZATION_NOTES_EDITING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import { EDIT_ORG_NOTES_MODAL_NAME } from './editNotesModal.const';
import EditNotesModalForm from './EditNotesModalForm/EditNotesModalForm';

interface IEditOrganizationNotesModalProps {
  onNotesEdited?: () => void;
}

const EditContractNotesModal = ({
  onNotesEdited,
}: IEditOrganizationNotesModalProps) => {
  const isProcessing = useProcessing(ORGANIZATION_NOTES_EDITING_PROCESSING);
  return (
    <Modal name={EDIT_ORG_NOTES_MODAL_NAME} disabled={isProcessing}>
      <EditNotesModalForm onNotesEdited={onNotesEdited} />
    </Modal>
  );
};

export default EditContractNotesModal;
