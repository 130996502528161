import styled from '@emotion/styled/macro';

import { StyledTableCell } from '../../../../styledComponents/MaterialTable/TableContent/tableContent.styles';

import MaterialTable from '../../../../styledComponents/MaterialTable/MaterialTable';

export const StyledMaterialTable = styled(MaterialTable)`
  ${StyledTableCell} {
    padding: 0.5rem;
  } ;
`;
