import NavigationPath from 'styledComponents/NavigationPath/NavigationPath';

import { useSubscriptionOverviewNavigate } from './productSubscriptionOverviewTemplatePage.hooks';
import { ProductSubscriptionActions } from '../ProductSubscriptionActions/ProductSubscriptionActions';
import { Header } from './productSubscriptionOverviewTemplatePage.styles';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { OverviewFullTabPage } from '../../../../../styledComponents/OverviewPage/OverviewPage';
import Spinner from '../../../../../styledComponents/Spinner/Spinner';
import { PageOverviewRootColumns } from '../../../../../styledComponents/Page/Page';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

type ProductSubscriptionOverviewTemplatePageProps = CustomComponent & {
  canAddMember?: boolean;
  canEditSubscription?: boolean;
  canExtend?: boolean;
  canDelete?: boolean;
  onDeleteProductSubscription?: () => void;
  onAddMember?: () => void;
  onExtendProductSubscription?: () => void;
  onEditSubscription?: () => void;
  isLoading?: boolean;
  permissions?: ProductSubscriptionActionsPermissions;
};

const ProductSubscriptionOverviewTemplatePage = ({
  children,
  canAddMember,
  canExtend,
  canDelete,
  className,
  onAddMember,
  onDeleteProductSubscription,
  onEditSubscription,
  onExtendProductSubscription,
  isLoading,
  canEditSubscription,
  permissions,
}: ProductSubscriptionOverviewTemplatePageProps) => {
  const { navigationPaths } = useSubscriptionOverviewNavigate();

  if (isLoading) {
    return (
      <OverviewFullTabPage className={className}>
        <Spinner />
      </OverviewFullTabPage>
    );
  }

  return (
    <>
      <OverviewFullTabPage className={className}>
        <Header>
          <NavigationPath paths={navigationPaths} />
          <ProductSubscriptionActions
            canAddMember={canAddMember}
            canExtend={canExtend}
            canDelete={canDelete}
            onDeleteProductSubscription={onDeleteProductSubscription}
            onAddMember={onAddMember}
            onExtendProductSubscription={onExtendProductSubscription}
            onEditSubscription={onEditSubscription}
            canEditSubscription={canEditSubscription}
            permissions={permissions}
          />
        </Header>
        <PageOverviewRootColumns>{children}</PageOverviewRootColumns>
      </OverviewFullTabPage>
    </>
  );
};

export default ProductSubscriptionOverviewTemplatePage;
