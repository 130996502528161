import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import BaseInput from '../../BaseInput/BaseInput';

import NumericInput from '../../NumericInput/NumericInput';

type StyledFormInputType = {
  error?: boolean;
};

export const StyledNumericInput = styled(NumericInput)<StyledFormInputType>`
  width: 100%;

  ${({ error, theme }) =>
    error &&
    css`
      ${BaseInput} {
        border-color: ${theme.color.error};
    color:
       ${theme.color.error}; 
      -webkit-text-fill-color: ${theme.color.error}
    `}
`;
