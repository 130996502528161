import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TLPricingTierDefinition } from '../../../../models/interfaces/Subscription/TravelLicense/TLPricingDefinition';

export interface ITLPricingState {
  pricingTiers: TLPricingTierDefinition[];
}

const initialState: ITLPricingState = {
  pricingTiers: [],
};

const tlPricingSlice = createSlice({
  name: 'tlPricing',
  initialState,
  reducers: {
    setTLPricingTiers: (
      state,
      { payload }: PayloadAction<TLPricingTierDefinition[]>,
    ) => {
      state.pricingTiers = payload;
    },
    clearTLPricesTiers: (state) => {
      state.pricingTiers = initialState.pricingTiers;
    },
  },
});

export const { setTLPricingTiers, clearTLPricesTiers } = tlPricingSlice.actions;
export default tlPricingSlice.reducer;
export const pricingReducerName = tlPricingSlice.name;
