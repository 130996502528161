import Ajv, { AnySchema, ErrorObject } from 'ajv';
import { t } from 'i18next';
import { merge, set } from 'lodash';
import addFormats from 'ajv-formats';
import addErrors from 'ajv-errors';

import { Dictionary } from 'models/Dictionary';
import { FieldErrorMessage } from 'models/FieldErrorMessage';

import { removeBlankProperties } from '../removeBlankProperties';
import { addCustomFormats, ValidationCustomFormat } from './customFormats';
import { addCustomKeywords } from './customKeywords';

export const validateFormData = (
  data: Dictionary<any> | any[],
  additionalErrors?: FieldErrorMessage[],
  ...schemas: AnySchema[]
) => {
  const outsideErrorArray: FieldErrorMessage[] | undefined = additionalErrors;
  let errorArray: FieldErrorMessage[] | undefined = [];
  const errors = {};
  const outsideErrors = {};
  const results = removeBlankProperties(data);

  const ajv = createAjv();

  const validate = addSchemas(ajv, schemas);

  if (!validate(results)) {
    errorArray = prepareFieldErrorMessages(validate?.errors);
  }

  errorArray?.forEach((error: any) => set(errors, error.field, error.message));
  outsideErrorArray?.forEach((error: any) =>
    set(outsideErrors, error.field, error.message),
  );
  return merge(outsideErrors, errors);
};

const createAjv = () => {
  const ajv = new Ajv({ allErrors: true, useDefaults: true, $data: true });
  addErrors(ajv);
  addFormats(ajv);
  addCustomFormats(ajv);
  addCustomKeywords(ajv);
  return ajv;
};

const addSchemas = (ajv: Ajv, schemas: AnySchema[]) => {
  if (schemas.length === 0) {
    ajv.compile({});
  }

  if (schemas.length === 1) {
    return ajv.compile(schemas[0]);
  }

  ajv.addSchema(schemas.slice(0, -1));
  return ajv.compile(schemas[schemas.length - 1]);
};

const prepareFieldErrorMessages = (
  jsonErrorArray: ErrorObject[] | null | undefined,
): FieldErrorMessage[] | undefined =>
  jsonErrorArray?.map((jsonError: any) => {
    const path = jsonError.instancePath.replaceAll('/', '.').replace('.', '');
    switch (jsonError.keyword) {
      case 'additionalProperties':
        if (jsonError.instancePath.length > 0) {
          return {
            field: `${path}.${jsonError.params.additionalProperty}`,
            message: t('validation.invalid-additional-attribute'),
          };
        }
        return {
          field: `${jsonError.params.additionalProperty}`,
          message: t('validation.invalid-additional-attribute'),
        };

      case 'required':
        if (jsonError.instancePath.length > 0) {
          return {
            field: `${path}.${jsonError.params.missingProperty}`,
            message: t('validation.required'),
          };
        }
        return {
          field: `${jsonError.params.missingProperty}`,
          message: t('validation.required'),
        };

      case 'type':
        return {
          field: path,
          message: t(`validation.must-be-${jsonError.params.type}`),
        };
      case 'maxLength':
        return {
          field: path,
          message: t(`validation.should-not-be-shorter`, {
            limit: jsonError.params.limit,
          }),
        };
      case 'minLength':
        return {
          field: path,
          message: t(`validation.should-not-be-shorter`, {
            limit: jsonError.params.limit,
          }),
        };
      case 'enum':
        return {
          field: path,
          message: jsonError.message,
        };
      case 'minimum':
        return {
          field: path,
          message: t(`validation.value-must-be-greater`, {
            limit: jsonError?.params?.limit - 1,
          }),
        };
      case 'exclusiveMinimum':
        return {
          field: path,
          message: t(`validation.value-must-be-greater`, {
            limit: jsonError.params.limit,
          }),
        };
      case 'uniqueArrayNumberItem':
        return {
          field: path,
          message: t(`validation.value-must-be-unique`),
        };
      case 'pattern':
        return {
          field: path,
          message: jsonError.message,
        };
      case 'format':
        return prepareMessageForFormat(jsonError, path);
      case 'oneOf':
      case 'anyOf':
      case 'if':
        return {
          field: '',
          message: '',
        };
      default:
        return {
          field: path,
          message: jsonError.message,
        };
    }
  });

const prepareMessageForFormat = (jsonError: any, path: string) => {
  switch (jsonError.params.format) {
    case 'email':
      return {
        field: path,
        message: t(`validation.invalid-email-address`),
      };
    case 'phone':
      return {
        field: path,
        message: t(`validation.invalid-phone`),
      };
    case 'date':
    case ValidationCustomFormat.CreditCardExpirationDate:
    case ValidationCustomFormat.MonthsDate:
      return {
        field: path,
        message: t(`validation.invalid-date`),
      };
    default:
      return {
        field: path,
        message: jsonError.message,
      };
  }
};
