import { useTranslation } from 'react-i18next';

import { CustomComponent } from '../../../../../../models/types/CustomComponent';
import CopyToClipboardButton from '../../../../../../styledComponents/CopyToClipboardButton/CopyToClipboardButton';
import { SectionSubTitleBold } from '../../../../../../styledComponents/Typography/Typography';
import { Card } from '../../../../../../styledComponents/UI/Card/Card';
import {
  Value,
  ValueContainer,
} from './aASubscriptionOverviewPromoCode.styles';

interface IProductSubscriptionOverviewPromoCodeProps extends CustomComponent {
  promoCode?: string;
}

const ProductSubscriptionOverviewPromoCode = ({
  className,
  promoCode,
}: IProductSubscriptionOverviewPromoCodeProps) => {
  const { t } = useTranslation();
  return (
    <Card className={className}>
      <SectionSubTitleBold>{t('headers.promo-code')}</SectionSubTitleBold>
      <ValueContainer>
        <Value>{promoCode}</Value>
        <CopyToClipboardButton value={promoCode} />
      </ValueContainer>
    </Card>
  );
};

export default ProductSubscriptionOverviewPromoCode;
