import {
  canEditMemberPermissions,
  canViewMemberDetailsPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import EditMemberModal from '../../../../../styledComponents/Modals/EditMemberModal/EditMemberModal';
import DOCMembersList from '../../../../../styledComponents/ProductMembersList/DOCMembersList/DOCMembersList';
import { pageFilters } from './docMembers.const';
import { useDOCMembers } from './docMembers.hooks';

const DOCMembers = () => {
  const {
    pageListMembers,
    membersLoading,
    onRowClickHandler,
    rowEditHandler,
    onMemberEditedHandler,
    docPackages,
  } = useDOCMembers();

  return (
    <>
      <EditMemberModal onMemberEdited={onMemberEditedHandler} />
      <DOCMembersList
        tableProps={{
          data: pageListMembers,
          isLoading: membersLoading,
          onRowClick: onRowClickHandler,
          onRowEdit: rowEditHandler,
          canViewDetailsPermissions: canViewMemberDetailsPermissions,
          canEditPermissions: canEditMemberPermissions,
        }}
        toolsProps={{
          availableFilters: pageFilters,
          packages: docPackages,
        }}
      />
    </>
  );
};

export default DOCMembers;
