import { css } from '@emotion/react';
import styled from '@emotion/styled';

type ContainerProps = {
  isArray?: boolean;
};

type WrapperProps = {
  isMultiInputType?: boolean;
};

export const Container = styled.div<ContainerProps>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0%;
  font-size: 0.875rem;
  overflow: hidden;
  position: relative;

  width: 100%;

  ${({ isArray }) =>
    isArray &&
    css`
      justify-content: space-between;
    `}
`;

export const SelectWrapper = styled.div<WrapperProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 95%;
  ${({ isMultiInputType }) =>
    isMultiInputType &&
    css`
      justify-content: start;
    `}
`;

export const SearchWrapper = styled.div`
  display: flex;
`;

export const SelectedItem = styled.div`
  color: ${({ theme }) => theme.color.dark1};
`;

export const SelectedItemValue = styled.div`
  color: ${({ theme }) => theme.color.dark1};
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectedCounter = styled.div`
  background-color: ${({ theme }) => theme.color.light1};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.color.lighter_blue};
  font-weight: bold;
  height: 1.25rem;
  margin-left: 0.5rem;
  text-align: center;
  width: 1.25rem;
`;
