import { useTranslation } from 'react-i18next';

import {
  CanDeleteOrganizationContractPermissions,
  CanEditOrganizationContractPermissions,
  CanRenewOrganizationContractPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';

import { CustomComponent } from '../../../../../models/types/CustomComponent';
import {
  ActionButton,
  ActionsWrapper,
} from '../../../../../styledComponents/OverviewPage/OverviewPage';

type ContractOverviewActionsProps = CustomComponent & {
  onContractDelete?: () => void;
  onContractRenew?: () => void;
  onContractEdit?: () => void;
  canDeleteContract?: boolean;
};

export const ContractOverviewActions = ({
  onContractDelete,
  onContractEdit,
  onContractRenew,
  className,
  canDeleteContract,
}: ContractOverviewActionsProps) => {
  const { t } = useTranslation();

  return (
    <ActionsWrapper className={className}>
      <ActionButton
        permissions={CanEditOrganizationContractPermissions}
        onClick={onContractEdit}
        styleType="light1"
      >
        {t('buttons.edit')}
      </ActionButton>
      <ActionButton
        permissions={CanRenewOrganizationContractPermissions}
        onClick={onContractRenew}
        styleType="lighter-blue"
      >
        {t('buttons.renew')}
      </ActionButton>
      {canDeleteContract && (
        <ActionButton
          permissions={CanDeleteOrganizationContractPermissions}
          styleType="delete"
          onClick={onContractDelete}
        >
          {t('buttons.delete')}
        </ActionButton>
      )}
    </ActionsWrapper>
  );
};
