import styled from '@emotion/styled/macro';

import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow-icon.svg';
import Button from '../Button/Button';

export const BackButtonWrapper = styled(Button)`
  background-color: ${({ theme }) => theme.color.light1};
  border-radius: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.lighter_blue};
  }
`;

export const StyledLeftArrow = styled(LeftArrow)`
  stroke: ${({ theme }) => theme.color.secondary1};

  &:hover {
    stroke: ${({ theme }) => theme.color.white};
  }
`;
