import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { FormFieldWrapper } from '../../../Form/Form';

import Checkbox from '../../Checkbox/Checkbox';
import { VisibleCheckbox } from '../../Checkbox/checkbox.styles';

type StyledCheckboxProps = {
  isError?: boolean;
};

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  ${({ isError, theme }) =>
    isError &&
    css`
      ${VisibleCheckbox} {
        border-color: ${theme.color.error};
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  column-gap: 1.5rem;
  row-gap: 1rem;
  display: flex;
`;
