import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { IUpdateAAMembershipOptionsModel } from '../../../../../models/interfaces/Membership/AirAmbulance/UpdateAAMembershipOptionsModel';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { UPDATE_MEMBERSHIP_OPTIONS_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { updateAAMembershipOptions } from '../../../../../store/saga-slices/membership/airAmbulance';
import { IUpdateAAMembershipOptionsPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { EDIT_AA_MEMBERSHIP_OPTIONS_MODAL } from './editAAMembershipOptionsModal.const';
import { EditAAMembershipOptionsModalDetails } from './editAAMembershipOptionsModal.models';

type UseEditAAMembershipOptionsModalProps = {
  onMembershipOptionsUpdated?: () => void;
  id: string;
};

export const useEditAAMembershipOptionsModal = ({
  id,
  onMembershipOptionsUpdated,
}: UseEditAAMembershipOptionsModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const UpdateMembershipOptions = useActions(updateAAMembershipOptions);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const isProcessing = useProcessing(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING);

  const {
    membershipOptions,
    membershipId,
  }: EditAAMembershipOptionsModalDetails = useModalDetails(
    EDIT_AA_MEMBERSHIP_OPTIONS_MODAL + id,
  );

  const updateMembershipOptionsHandler = useCallback(
    (values: IUpdateAAMembershipOptionsModel) => {
      const payload: IUpdateAAMembershipOptionsPayload = {
        membershipId,
        options: values,
        cancellationToken,
        success: () => {
          UpdateModalState(EDIT_AA_MEMBERSHIP_OPTIONS_MODAL + id);
          onMembershipOptionsUpdated?.();
          alert.success(t('alerts.membership-options-update-success'));
        },
        error: () => alert.error(t('alerts.membership-options-update-fail')),
      };

      UpdateMembershipOptions(payload);
    },
    [
      UpdateMembershipOptions,
      UpdateModalState,
      alert,
      cancellationToken,
      id,
      membershipId,
      onMembershipOptionsUpdated,
      t,
    ],
  );

  const onCloseHandler = () => {
    UpdateModalState(EDIT_AA_MEMBERSHIP_OPTIONS_MODAL + id);
  };
  return {
    updateMembershipOptionsHandler,
    membershipOptions,
    isProcessing,
    onCloseHandler,
  };
};
