import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { SECONDARY_MEMBER_DELETE_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { deleteSecondaryAAMember } from '../../../../../store/saga-slices/membership/airAmbulance';
import { IDeleteSecondaryAAMemberPayload } from '../../../../../store/saga-slices/membership/airAmbulance/models';
import { DELETE_AA_FAMILY_MEMBER_MODAL_NAME } from './deleteAAFamilyMemberModal.const';
import { DeleteAAFamilyMemberModalDetails } from './deleteAAFamilyMemberModal.models';

type UsDeleteAAFamilyMemberModalProps = {
  onFamilyMemberRemoved?: () => void;
  id?: string;
};

export const useDeleteAAFamilyMemberModal = ({
  onFamilyMemberRemoved,
  id,
}: UsDeleteAAFamilyMemberModalProps = {}) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const UpdateModalState = useActions(updateModalState);
  const DeleteSecondaryMember = useActions(deleteSecondaryAAMember);

  const { member, membershipId }: DeleteAAFamilyMemberModalDetails =
    useModalDetails(DELETE_AA_FAMILY_MEMBER_MODAL_NAME + id);

  const cancellationToken = useCancellationToken();

  const isDeleting = useProcessing(SECONDARY_MEMBER_DELETE_PROCESSING);

  const name = `${member?.firstName} ${member?.lastName}`;

  const onBackHandler = () => {
    UpdateModalState(DELETE_AA_FAMILY_MEMBER_MODAL_NAME + id);
  };

  const deleteSecondaryMemberHandler = () => {
    const payload: IDeleteSecondaryAAMemberPayload = {
      cancellationToken,
      memberId: member?.memberId,
      membershipId,
      success: () => {
        onFamilyMemberRemoved?.();
        alert.success(t('phrase.family-member-deleted-success'));
        UpdateModalState(DELETE_AA_FAMILY_MEMBER_MODAL_NAME + id);
      },
      error: () => {
        alert.error(t('phrase.family-member-deleted-fail'));
      },
    };

    DeleteSecondaryMember(payload);
  };

  return {
    onBackHandler,
    name,
    deleteSecondaryMemberHandler,
    isDeleting,
  };
};
