import { ToolsWrapper } from '../../../SimplePage/Tools';
import KEMembersFilters from '../KEMembersFilters/KEMembersFilters';
import { KEMembersFiltersProps } from '../KEMembersFilters/keMembersFilters.models';

export type KEMembersToolsProps = KEMembersFiltersProps;

const KEMembersTools = ({
  availableFilters,
  standardPricing,
}: KEMembersToolsProps) => (
  <ToolsWrapper>
    <KEMembersFilters
      availableFilters={availableFilters}
      standardPricing={standardPricing}
    />
  </ToolsWrapper>
);

export default KEMembersTools;
