import {
  canViewAASubscriptionDetailsPermissions,
  canViewDOCSubscriptionDetailsPermissions,
  canViewKESubscriptionDetailsPermissions,
  canViewSRSubscriptionDetailsPermissions,
  canViewTLSubscriptionDetailsPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { ProductType } from '../../../../models/ProductType';
import { AvailableProductComponentType } from '../../../../models/types/AvailableProductComponentType';
import { AvailableProductPermissionsType } from '../../../../models/types/AvailableProductViewsPermissionsType';
import AASubscriptionOverview from './AASubscriptionOverview/AASubscriptionOverview';
import DOCSubscriptionOverview from './DOCSubscriptionOverview/DOCSubscriptionOverview';
import KESubscriptionOverview from './KESubscriptionOverview/KESubscriptionOverview';
import SRSubscriptionOverview from './SRSubscriptionOverview/SRSubscriptionOverview';
import TLSubscriptionOverview from './TLSubscriptionOverview/TLSubscriptionOverview';

export const SubscriptionOverviewPages: AvailableProductComponentType = {
  [ProductType.AirAmbulance]: AASubscriptionOverview,
  [ProductType.DutyOfCare]: DOCSubscriptionOverview,
  [ProductType.KidnapAndExtortion]: KESubscriptionOverview,
  [ProductType.SecurityResponse]: SRSubscriptionOverview,
  [ProductType.TravelLicense]: TLSubscriptionOverview,
};

export const SubscriptionsListViewDetailsPermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canViewAASubscriptionDetailsPermissions,
    KidnapAndExtortion: canViewKESubscriptionDetailsPermissions,
    SecurityResponse: canViewSRSubscriptionDetailsPermissions,
    DutyOfCare: canViewDOCSubscriptionDetailsPermissions,
    TravelLicense: canViewTLSubscriptionDetailsPermissions,
  };
