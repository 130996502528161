import { FallbackProps } from 'react-error-boundary';

import { ApiErrorType } from '../../../models/enums/ApiErrorType';
import { ApiError } from '../../../models/types/ApiError';

import AccessForbiddenPage from '../../../pages/AccessForbiddenPage/AccessForbiddenPage';
import SomethingWentWrongPage from '../../../pages/SomethingWentWrongPage/SomethingWentWrongPage';

const FallbackComponent = (props: FallbackProps | any) => {
  const { error } = props;
  if ((error as ApiError).type === ApiErrorType.AccessForbidden) {
    return <AccessForbiddenPage {...props} />;
  }

  return <SomethingWentWrongPage {...props} />;
};

export default FallbackComponent;
