import { t } from 'i18next';

import { DateFilterType } from '../../../../../../../models/enums/RenewalFilters/DateFilterType';

import { IDateFilter } from '../../../../../../../models/interfaces/RenewalFilters/DateFilters';

import { SelectOption } from '../../../../../../../models/SelectOption';
import { formatDate } from '../../../../../../../utils/dateHelpers';
import { CustomFilter } from '../../../renewalFilters.models';
import { DateFilterAdditionalValuesModel } from './dateFilter.models';

export const dateTypeFieldsOptions: SelectOption[] = Object.values(
  DateFilterType,
).map((field) => ({
  value: field,
  label: t(`filters.${field}`),
}));

export const getDateFilterTag = (
  filterType: DateFilterType,
  data: DateFilterAdditionalValuesModel,
) => {
  switch (filterType) {
    case DateFilterType.FixedPeriod:
      return data.dates.map((x) => t(`filters.tags.${x}`));
    case DateFilterType.MonthsRange:
      return t(`filters.tags.${filterType}`, {
        from: data?.from,
        to: data?.to,
      });
    case DateFilterType.DatesRange:
      return t(`filters.tags.${filterType}`, {
        from: formatDate(data?.from),
        to: formatDate(data?.to),
      });
  }
};

export const getDateFilterApiModel = (
  filter: CustomFilter<DateFilterAdditionalValuesModel>,
): IDateFilter => {
  const result: IDateFilter = {
    rangeType: filter?.value as DateFilterType,
  };
  switch (filter.value) {
    case DateFilterType.DatesRange:
      result.from = filter?.additionalValues?.from;
      result.to = filter?.additionalValues?.to;
      break;
    case DateFilterType.MonthsRange:
      result.yearMonthFrom = filter?.additionalValues?.from;
      result.yearMonthTo = filter?.additionalValues?.to;
      break;
    case DateFilterType.FixedPeriod:
      result.dateFilterTypes = filter?.additionalValues?.dates;

      break;
  }
  return result;
};
