import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';

import { usePermissions } from '../../../hooks/usePermissions';

import { useRunOnce } from '../../../hooks/useRunOnce';
import { ITabNavigationItem } from './tabNavigation.models';

type UseTabNavigationProps = {
  className?: string;
  routeMode?: boolean;
  inputTabs: Array<ITabNavigationItem>;
};

export const useTabNavigation = ({
  inputTabs,
  routeMode,
}: UseTabNavigationProps) => {
  const { checkUserPermissions } = usePermissions();

  const tabs = useMemo(
    () =>
      inputTabs.filter(
        (tab) => checkUserPermissions(tab.permissions) && !tab.notAvailable,
      ),
    [checkUserPermissions, inputTabs],
  );

  const firstElement = useMemo(
    () => (tabs.length > 0 ? (routeMode ? tabs[0].url : tabs[0].label) : null),
    [routeMode, tabs],
  );

  const [value, setValue] = useState<string>(tabs.length > 0 && firstElement);

  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentIndex = useCallback(
    (pathname: string) =>
      tabs.findIndex((x) =>
        x.url ? pathname.toLocaleLowerCase().includes(x.url) : false,
      ),
    [tabs],
  );

  const handleValueChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      if (routeMode) {
        navigate(newValue);
      }
      setValue(newValue);
    },
    [navigate, routeMode],
  );

  useEffect(() => {
    const currentTabIndex = getCurrentIndex(location.pathname);
    if (!!tabs?.length && currentTabIndex === -1) {
      handleValueChange(null, tabs[0].url);
      return;
    }

    if (
      tabs.length === 0 ||
      currentTabIndex === -1 ||
      value === tabs[currentTabIndex].url
    ) {
      return;
    }

    setValue(tabs[currentTabIndex].url);
  }, [getCurrentIndex, tabs, location.pathname, value, handleValueChange]);

  useRunOnce(() => {
    const currentTabIndex = getCurrentIndex(document.URL);

    if (currentTabIndex !== -1 || !firstElement) {
      return;
    }
    navigate(firstElement);
  }, value !== null);

  return {
    handleValueChange,
    value,
    getCurrentIndex,
    filteredTab: tabs,
  };
};
