import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import { call, put } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { putResolve } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import { Log } from 'utils/logger';

import {
  getPerson,
  getPersonMemberships,
  IPersonMembershipsResult,
  updatePerson,
  deletePersonRequest,
  getPersonRoles,
  getPersonList,
} from './api';
import { updateProcessState } from '../../redux-slices/processes';
import {
  PERSON_DELETE_PROCESSING,
  PERSON_LIST_LOAD_PROCESSING,
  PERSON_LOADING_PROCESSING,
  PERSON_MEMBERSHIPS_LOADING_PROCESSING,
  PERSON_ROLES_LOADING_PROCESSING,
  PERSON_UPDATE_PROCESSING,
} from '../../redux-slices/processes/constants';
import {
  IDeletePersonPayload,
  ILoadGeneralPersonListPayload,
  ILoadPersonMembershipsPayload,
  ILoadPersonPayload,
  ILoadPersonRolesPayload,
  IUpdatePersonDataPayload,
} from './models';
import {
  setPerson,
  setPersonList,
  setPersonMemberships,
  setPersonRoles,
} from '../../redux-slices/people';
import { prepareUpdatePersonData } from './utils';

const peopleSlice = createSliceSaga({
  caseSagas: {
    *loadPerson({
      payload: { personId, success, error },
    }: PayloadAction<ILoadPersonPayload>) {
      try {
        yield put(updateProcessState(PERSON_LOADING_PROCESSING));
        const response = yield* call(getPerson, personId);
        yield putResolve(setPerson(response.data));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_LOADING_PROCESSING));
      }
    },
    *loadPersonRoles({
      payload: { personId, success, error, cancellationToken },
    }: PayloadAction<ILoadPersonRolesPayload>) {
      try {
        yield put(updateProcessState(PERSON_ROLES_LOADING_PROCESSING));
        const response = yield* call(
          getPersonRoles,
          personId,
          cancellationToken,
        );
        yield putResolve(setPersonRoles(response.data?.personRoles));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_ROLES_LOADING_PROCESSING));
      }
    },
    *loadPersonMemberships({
      payload: { personId, success, error, cancellationToken, filter },
    }: PayloadAction<ILoadPersonMembershipsPayload>) {
      try {
        yield put(updateProcessState(PERSON_MEMBERSHIPS_LOADING_PROCESSING));
        const response: AxiosResponse<IPersonMembershipsResult> = yield* call(
          getPersonMemberships,
          personId,
          cancellationToken,
          filter,
        );
        yield putResolve(setPersonMemberships(response.data.personMemberships));
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_MEMBERSHIPS_LOADING_PROCESSING));
      }
    },
    *deletePerson({
      payload: { personId, success, error, cancellationToken },
    }: PayloadAction<IDeletePersonPayload>) {
      try {
        yield put(updateProcessState(PERSON_DELETE_PROCESSING));
        yield* call(deletePersonRequest, personId, cancellationToken);
        success?.();
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_DELETE_PROCESSING));
      }
    },
    *updatePersonData({
      payload: { personId, data, success, error, cancellationToken },
    }: PayloadAction<IUpdatePersonDataPayload>) {
      try {
        yield put(updateProcessState(PERSON_UPDATE_PROCESSING));
        const preparedData = yield* call(prepareUpdatePersonData, data);
        const response = yield* call(
          updatePerson,
          personId,
          preparedData,
          cancellationToken,
        );
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_UPDATE_PROCESSING));
      }
    },
    *loadGeneralPersonList({
      payload: { params, organizationId, success, error, cancellationToken },
    }: PayloadAction<ILoadGeneralPersonListPayload>) {
      try {
        yield put(updateProcessState(PERSON_LIST_LOAD_PROCESSING));
        const response = yield* call(
          getPersonList,
          params,
          organizationId,
          cancellationToken,
        );
        yield putResolve(setPersonList(response.data));
        success?.(response.data);
      } catch (err: any) {
        Log.errorException(err);
        error?.(err && err.response && err.response.data);
      } finally {
        yield put(updateProcessState(PERSON_LIST_LOAD_PROCESSING));
      }
    },
  },
  name: 'people-saga',
  sagaType: SagaType.TakeLatest,
});

export default peopleSlice.saga;
export const {
  loadPerson,
  loadPersonMemberships,
  updatePersonData,
  deletePerson,
  loadPersonRoles,
  loadGeneralPersonList,
} = peopleSlice.actions;
export const { actions } = peopleSlice;
