import { t } from 'i18next';

export const getOptionsFromEnum = (
  e: { [x: string]: string },
  enumName?: string,
  skipOptions?: string[],
  valueSelector?: (type: string) => any,
): { label: string; value: string }[] =>
  Object.values(e)
    .filter((type) => !skipOptions || !skipOptions.includes(type))
    .map((type) => ({
      label: enumName ? t(`enums.${enumName}.${type}`) : type,
      value: valueSelector ? valueSelector(type) : type,
    }));

export const getOptionsFromEnumValues = (
  e: string[],
  enumName?: string,
  valueSelector?: (type: string) => any,
): { label: string; value: string }[] =>
  e.map((type) => ({
    label: enumName ? t(`enums.${enumName}.${type}`) : type,
    value: valueSelector ? valueSelector(type) : type,
  }));
