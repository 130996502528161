import { useTranslation } from 'react-i18next';

import { ICellRenderer } from '../../../../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { ClockIcon, DateExpiredWrapper } from './expirationDateRenderer.styles';

import { formatDate } from '../../../../../../../utils/dateHelpers';
import { ExpirationNotificationEnum } from '../../../../../../../models/ExpirationNotificationEnum';
import MainTooltip from '../../../../../../../styledComponents/UI/MainTooltip/MainTooltip';
import { isPropertyEmpty } from '../../../../../../../utils/propertyHelpers';
import TableCell from '../../../../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

export interface IDateCellRenderer extends ICellRenderer {
  date?: string;
  isExpired?: boolean;
  expirationNotification?: ExpirationNotificationEnum;
}

export const ExpiredDateRenderer = ({
  date,
  options,
  isExpired,
  expirationNotification,
}: IDateCellRenderer) => {
  const { t } = useTranslation();
  return (
    <TableCell bold={options?.textBold} alignment={options?.alignment}>
      <DateExpiredWrapper isExpired={isExpired}>
        {date ? formatDate(date) : '-'}
        {date && !isPropertyEmpty(expirationNotification) && (
          <MainTooltip
            title={t(
              `enums.expiration-notification-enum.${expirationNotification}`,
            )}
          >
            <ClockIcon $expirationNotification={expirationNotification} />
          </MainTooltip>
        )}
      </DateExpiredWrapper>
    </TableCell>
  );
};
