import { createSelector } from 'reselect';

import { RootState } from '../../index';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import { ITLSubscriptionsState } from '.';
import { ITLOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';
import { ITLSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLSubscriptionRenewalSuggestionModel';
import { ITLOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionSummaryModel';
import { ITLOrganizationSubscriptionExtensionSuggestionData } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionData';

export const tlSubscriptionsSelector = (state: RootState) =>
  state.tlSubscriptions;

export const tlSubscriptionOverviewSelector = createSelector(
  tlSubscriptionsSelector,
  (
    subscriptionState: ITLSubscriptionsState,
  ): ITLOrganizationSubscriptionOverview =>
    subscriptionState.currentSubscriptionOverview,
);

export const tlSubscriptionExtensionDataSelector = createSelector(
  tlSubscriptionOverviewSelector,
  (
    subscription: ITLOrganizationSubscriptionOverview,
  ): ITLOrganizationSubscriptionExtensionSuggestionData => ({
    pricingTiers: subscription?.pricingTiers,
  }),
);

export const tlSubscriptionDatesSelector = createSelector(
  tlSubscriptionsSelector,
  (subscriptionState: ITLSubscriptionsState): ISubscriptionDates =>
    subscriptionState.subscriptionDates,
);

export const editingTLSubscriptionDataSelector = createSelector(
  tlSubscriptionsSelector,
  (
    subscriptionState: ITLSubscriptionsState,
  ): IEditTLOrganizationSubscriptionModel =>
    subscriptionState.currentSubscriptionEditing,
);

export const tlSubscriptionRenewalSuggestionSelector = createSelector(
  tlSubscriptionsSelector,
  (
    subscriptionState: ITLSubscriptionsState,
  ): ITLSubscriptionRenewalSuggestionModel =>
    subscriptionState.subscriptionRenewalSuggestion,
);

export const tlSubscriptionExtensionSummarySelector = createSelector(
  tlSubscriptionsSelector,
  (
    subscriptionState: ITLSubscriptionsState,
  ): ITLOrganizationSubscriptionExtensionSummaryModel =>
    subscriptionState.subscriptionExtensionSummary,
);
