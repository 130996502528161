import styled from '@emotion/styled';

import Button from '../../../styledComponents/UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.75rem;
`;

export const DataContainer = styled.div``;

export const ActionsContainer = styled.div``;

export const DetailsContent = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 3.875rem;
`;

export const StyledButton = styled(Button)`
  padding: 0.75rem 1rem;
`;
