import { Form, Field } from 'react-final-form';

import { FormSelect } from '../../../../../styledComponents/UI/Form/FormSelect/FormSelect';

import ReportsGenerateButton from '../../../../../styledComponents/UI/ReportsGenerateButton/ReportsGenerateButton';
import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';
import { useRawDataReports } from './rawDataReportsForm.hooks';
import { RawDataReportsFields } from './rawDataReportsForm.models';
import { Wrapper } from './rawDataReportsForm.styles';
import { validateRawDataReportSchemaForm } from './rawDataReportsForm.validation';

const RetailDataReportsForm = () => {
  const { generateRetailDataReportFile, filteredReportProductTypes } =
    useRawDataReports();

  return (
    <div>
      <Form
        keepDirtyOnReinitialize
        onSubmit={generateRetailDataReportFile}
        validate={validateRawDataReportSchemaForm}
      >
        {({ dirty, values, ...renderProps }) => (
          <Wrapper onSubmit={renderProps.handleSubmit}>
            <Field
              component={FormSelect}
              {...getFieldPropertiesForGroup({
                name: RawDataReportsFields.ProductType,
                inputId: RawDataReportsFields.ProductType,
              })}
              options={filteredReportProductTypes}
            />
            <ReportsGenerateButton />
          </Wrapper>
        )}
      </Form>
    </div>
  );
};

export default RetailDataReportsForm;
