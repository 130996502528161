import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IEditAAOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/AirAmbulance/EditAAOrganizationSubscriptionModel';
import { EditProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadAASubscriptionSummary } from '../../../../store/saga-slices/subscriptions/airAmbulance';
import { ILoadAASubscriptionSummary } from '../../../../store/saga-slices/subscriptions/airAmbulance/models';

type SummaryLoaderProps = {
  values: IEditAAOrganizationSubscriptionModel;
};

const SummaryLoader = ({ values }: SummaryLoaderProps) => {
  const { organizationId } = useParams<EditProductSubscriptionPageParamsType>();

  const LoadSubscriptionSummary = useActions(loadAASubscriptionSummary);
  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values?.pricing?.membershipPlans) {
      return;
    }

    const payload: ILoadAASubscriptionSummary = {
      data: values?.pricing?.membershipPlans,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [
    LoadSubscriptionSummary,
    debounce,
    organizationId,
    values?.pricing?.membershipPlans,
  ]);

  return <></>;
};

export default SummaryLoader;
