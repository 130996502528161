import { useTranslation } from 'react-i18next';

import { canAddAAMemberPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import { SectionTitle } from '../../../../../styledComponents/Typography/Typography';
import {
  OverviewHeader,
  OverviewHeaderRightContainer,
  OverviewButton,
} from '../../../../../styledComponents/OverviewPage/OverviewPageHeader';

type PromoCodeHeaderProps = {
  onAddPromoCode?: () => void;
};

const PromoCodesHeader = ({ onAddPromoCode }: PromoCodeHeaderProps) => {
  const { t } = useTranslation();

  return (
    <OverviewHeader>
      <SectionTitle>{t('headers.promo-codes')}</SectionTitle>
      <OverviewHeaderRightContainer>
        <OverviewButton
          permissions={canAddAAMemberPermissions}
          onClick={onAddPromoCode}
          styleType="lighter-blue"
        >
          {t('buttons.add-promo-code')}
        </OverviewButton>
      </OverviewHeaderRightContainer>
    </OverviewHeader>
  );
};

export default PromoCodesHeader;
