import { ToolsWrapper } from '../../../../styledComponents/SimplePage/Tools';
import CorporateClientAirAmbulanceFilters from '../CorporateClientsAirAmbulanceFilters/CorporateClientsAirAmbulanceFilters';

const CorporateClientsAirAmbulanceTools = () => (
  <ToolsWrapper>
    <CorporateClientAirAmbulanceFilters />
  </ToolsWrapper>
);

export default CorporateClientsAirAmbulanceTools;
