import { useTranslation } from 'react-i18next';

import { useProductRoute } from '../../../hooks/useProductRoute';

import { SelectOption } from '../../../models/SelectOption';
import { ProductType } from '../../../models/ProductType';
import {
  AvailableProductPermissionsType,
  AvailableProductsType,
} from '../../../models/types/AvailableProductViewsPermissionsType';
import { CustomActionPermissions } from '../../../models/types/CustomActionPermissions';
import SimplePageHeader from '../../SimplePage/SimplePageHeader/SimplePageHeader';
import DescriptionSelect from '../../UI/DescriptionSelect/DescriptionSelect';
import { Views } from './productPageHeader.models';

export interface IProductPageHeaderProps extends CustomActionPermissions {
  title: string;
  buttonTitle: string;
  onButtonClick?: () => void;
  defaultPage?: ProductType;
  pagesPermissions?: AvailableProductPermissionsType;
  views?: AvailableProductsType;
}

const ProductPageHeader = ({
  title,
  buttonTitle,
  onButtonClick,
  defaultPage,
  actionPermissions,
  pagesPermissions,
  views,
}: IProductPageHeaderProps) => {
  const { t } = useTranslation();

  const { viewChangeHandler, currentView, productOptions } = useProductRoute({
    defaultPage,
    pagesPermissions,
    views: views || Views,
  });

  return (
    <SimplePageHeader
      onButtonClick={onButtonClick}
      buttonTitle={buttonTitle}
      title={title}
      actionPermissions={actionPermissions}
    >
      <DescriptionSelect
        currentValue={currentView}
        description={t('phrase.view')}
        onChange={(option: SelectOption | any) =>
          viewChangeHandler(option.value)
        }
        options={productOptions}
        placeholder={t('phrase.select-product')}
      />
    </SimplePageHeader>
  );
};

export default ProductPageHeader;
