import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormErrorActivator from '../../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import Spinner from '../../../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  PageRootColumns,
  RightContainer,
} from '../../../../styledComponents/Page/Page';
import { FormSubmitButton } from '../../../../styledComponents/UI/FormSubmitButton/FormSubmitButton';
import ExtendSubscriptionExtensionsForm from '../../Common/ExtendSubscriptionForms/ExtendSubscriptionExtensionsForm/ExtendSubscriptionExtensionsForm';
import { useExtendKESubscriptionForm } from './extendKESubscriptionForm.hooks';
import { validateExtendKESubscriptionForm } from './extendKESubscriptionForm.validation';
import ExtendSubscriptionSummaryForm from '../../Common/ExtendSubscriptionForms/ExtendSubscriptionSummaryForm/ExtendSubscriptionSummaryForm';

const ExtendKESubscriptionForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    checkIfCanSubmit,
    validateErrors,
    subscriptionEditing,
    dataLoading,
    subscriptionOverview,
    productType,
  } = useExtendKESubscriptionForm();
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={(data) =>
        validateExtendKESubscriptionForm(data, validateErrors(data))
      }
    >
      {({ values, ...renderProps }) => (
        <FormErrorActivator>
          <Spinner isVisible={dataLoading} size="40%">
            <form onSubmit={renderProps.handleSubmit}>
              <PageRootColumns>
                <LeftContainer>
                  <ExtendSubscriptionExtensionsForm
                    disabled={subscriptionEditing}
                    plans={
                      subscriptionOverview ? subscriptionOverview.plans : []
                    }
                    productType={productType}
                  />
                </LeftContainer>
                <RightContainer>
                  <ExtendSubscriptionSummaryForm
                    title={t('headers.summary')}
                    disabled={subscriptionEditing}
                    productType={productType}
                    isPriceOverridden={values.overwriteAmount}
                  />
                  <FormSubmitButton
                    isLoading={subscriptionEditing}
                    disabled={!checkIfCanSubmit(values.membershipPlanChanges)}
                    type="submit"
                  >
                    {t('buttons.confirm')}
                  </FormSubmitButton>
                </RightContainer>
              </PageRootColumns>
            </form>
          </Spinner>
        </FormErrorActivator>
      )}
    </Form>
  );
};

export default ExtendKESubscriptionForm;
