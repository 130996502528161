import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import { OverwriteAmountCheckbox } from '../../summaryContainer.styles';

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SubtotalLabel = styled.div`
  color: ${({ theme }) => theme.color.dark1};
  margin-top: 1rem;
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
`;

type SubtotalValueProps = {
  overridden?: boolean;
};

export const SubtotalValue = styled.div<SubtotalValueProps>`
  color: ${({ theme }) => theme.color.black};
  margin-top: 1rem;
  font: normal bold 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};

  ${({ overridden, theme }) => css`
    ${overridden &&
    css`
      color: ${theme.color.dark2};
      font-weight: 700;
      text-decoration: line-through;
    `}
  `}
`;

export const StyledOverwriteAmountCheckbox = styled(OverwriteAmountCheckbox)`
  margin-top: 1.5rem;
  justify-content: space-between;
  gap: 0;
`;
