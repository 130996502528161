import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { CustomComponent } from '../../../models/types/CustomComponent';
import { isEmpty } from '../../../utils/textHelpers';
import { useFormErrorsValues } from '../FormErrorProvider.hooks';

const FormErrorActivator = ({ children }: CustomComponent) => {
  const { errors } = useFormErrorsValues();
  const form = useForm();

  useEffect(() => {
    const internalErrors = errors?.filter((error) => error.internalError);
    if (
      !!errors?.length &&
      errors.some((x) => !isEmpty(x.field)) &&
      !internalErrors?.length
    ) {
      setTimeout(() => form.submit(), 0);
    }
  }, [errors, form]);

  return <>{children}</>;
};

export default FormErrorActivator;
