import { useTranslation } from 'react-i18next';

import Spinner from '../../../../styledComponents/Spinner/Spinner';

import OverviewOptions from '../../../../styledComponents/Overview/OverviewOptions/OverviewOptions';

import {
  StyledLeftContainer,
  StyledRightContainer,
  DetailsWrapper,
  StyledDeleteButton,
} from '../../Common/memberOverviewMembers.styles';
import MemberOverviewMemberTable from '../../MemberOverviewMemberTable/MemberOverviewMemberTable';

import {
  canAddAAMembershipFamilyMemberPermissions,
  canEditAAMembershipsOptionsPermissions,
  canRemoveAAMembershipPermissions,
  canRenewAAMembershipPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import MemberOverviewDetails from '../../Common/MemberOverviewDetails/MemberOverviewDetails';
import { AirAmbulanceMembershipOptions } from '../../../../models/enums/AirAmbulanceMembershipOptions';
import { ProductType } from '../../../../models/ProductType';
import { useAAMembershipActiveDetails } from './aaMembershipActiveDetails.hooks';
import { StyledButton } from './aaMembershipActiveDetails.styles';
import {
  useAAMembershipDetails,
  useAAMembershipRenew,
} from '../Common/aaMembershipOverview.hooks';
import AddAAFamilyMemberModal from '../Modals/AddAAFamilyMemberModal/AddAAFamilyMemberModal';
import EditAAMembershipOptionsModal from '../Modals/EditAAMembershipOptionsModal/EditAAMembershipOptionsModal';
import DeleteAAFamilyMemberModal from '../Modals/DeleteAAFamilyMemberModal/DeleteAAFamilyMemberModal';
import ResendAAWelcomeEmailModal from '../Modals/ResendAAWelcomeEmailModal/ResendAAWelcomeEmailModal';
import DeleteAAMembershipModal from '../Modals/DeleteAAMembershipModal/DeleteAAMembershipModal';
import { aaOverviewTablePermissions } from '../Common/aaMembershipOverview.const';

interface IAAMembershipActiveDetailsProps {
  membershipId: string;
  productType?: ProductType;
  canBeRemoved?: boolean;
}

const AAMembershipActiveDetails = ({
  membershipId,
  productType,
  canBeRemoved,
}: IAAMembershipActiveDetailsProps) => {
  const { t } = useTranslation();

  const {
    dataLoading,
    membershipDetails,
    getMembershipDetails,
    changeRenewFlagHandler,
    resendWelcomeEmailHandler,
    deleteFamilyMemberHandler,
    deleteMembershipHandler,
    onRowClickHandler,
    onEditMembershipOptionsHandler,
  } = useAAMembershipDetails(membershipId, productType);

  const { addFamilyMemberHandler } = useAAMembershipActiveDetails({
    membershipId,
    canAddMemberTypes: membershipDetails?.metadata?.canAddMemberTypes,
  });

  const { renewMembershipHandler } = useAAMembershipRenew({
    membershipId,
    productType,
  });

  if (dataLoading && !membershipDetails) {
    return <Spinner />;
  }
  return (
    <>
      <AddAAFamilyMemberModal
        id={membershipId}
        onFamilyMemberAdded={getMembershipDetails}
      />
      <DeleteAAFamilyMemberModal
        id={membershipId}
        onFamilyMemberRemoved={getMembershipDetails}
      />
      <DeleteAAMembershipModal id={membershipId} />
      <EditAAMembershipOptionsModal id={membershipId} />
      <ResendAAWelcomeEmailModal id={membershipId} />
      <DetailsWrapper>
        <StyledLeftContainer>
          <MemberOverviewMemberTable
            data={membershipDetails?.members}
            dataLoading={dataLoading}
            onRenewFlagChange={changeRenewFlagHandler}
            onResendEmailClick={resendWelcomeEmailHandler}
            onDeleteMember={deleteFamilyMemberHandler}
            onRowClick={onRowClickHandler}
            isExpiredMembershipsTable={false}
            permissions={aaOverviewTablePermissions}
          />
          {membershipDetails?.metadata?.canAddMemberTypes.length > 0 && (
            <StyledButton
              permissions={canAddAAMembershipFamilyMemberPermissions}
              onClick={addFamilyMemberHandler}
              styleType="link"
            >
              {t('buttons.add-family-member')}
            </StyledButton>
          )}
        </StyledLeftContainer>
        <StyledRightContainer>
          <OverviewOptions
            onEditOptionsClick={onEditMembershipOptionsHandler}
            canEditOptionsPermissions={canEditAAMembershipsOptionsPermissions}
            data={membershipDetails}
            options={[
              { value: AirAmbulanceMembershipOptions.SendWelcomeEmail },
              { value: AirAmbulanceMembershipOptions.EmailOptOut },
            ]}
          />
          <MemberOverviewDetails
            data={{
              effectiveDateFrom: membershipDetails?.effectiveDate,
              effectiveDateTo: membershipDetails?.expirationDate,
              organizationContractName:
                membershipDetails?.organizationContractName,
            }}
            onRenewClick={renewMembershipHandler}
            canBeRenewed={membershipDetails?.metadata?.canRenew}
            canRenewMembershipPermissions={canRenewAAMembershipPermissions}
          />
          {canBeRemoved && (
            <StyledDeleteButton
              permissions={canRemoveAAMembershipPermissions}
              styleType="delete"
              onClick={deleteMembershipHandler}
            >
              {t('buttons.delete-membership')}
            </StyledDeleteButton>
          )}
        </StyledRightContainer>
      </DetailsWrapper>
    </>
  );
};

export default AAMembershipActiveDetails;
