import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../models/types/CustomForm';

import FormDatePicker from '../../../../UI/Form/FormDatePicker/FormDatePicker';
import { FormRow } from '../../../../UI/Form/FormRow/FormRow';
import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';
import {
  SubscriptionRenewDetailsCommonFormFields,
  SubscriptionRenewDetailsCommonFormName,
} from './subscriptionRenewDetailsCommonForm.models';
import { useSubscriptionRenewDetailsCommon } from './subscriptionRenewDetailsCommonForm.hooks';
import { ProductType } from '../../../../../models/ProductType';

type SubscriptionRenewDetailsCommonProps = CustomForm & {
  productType: ProductType;
};

export const SubscriptionRenewDetailsCommonForm = ({
  disabled,
  name,
  productType,
}: SubscriptionRenewDetailsCommonProps) => {
  useSubscriptionRenewDetailsCommon({ name, productType });

  return (
    <FormRow>
      <Field
        component={FormDatePicker}
        disabled={disabled}
        {...getFieldPropertiesForGroup({
          inputId: SubscriptionRenewDetailsCommonFormName,
          name: SubscriptionRenewDetailsCommonFormFields.EffectiveDateFrom,
        })}
      />

      <Field
        component={FormDatePicker}
        disabled={disabled}
        {...getFieldPropertiesForGroup({
          inputId: SubscriptionRenewDetailsCommonFormName,
          name: SubscriptionRenewDetailsCommonFormFields.EffectiveDateTo,
        })}
      />
    </FormRow>
  );
};
