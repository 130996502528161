import StripeCardDetailsForm from '../../../../../styledComponents/Stripe/StripeCardDetailsForm';

interface IPaymentCardDetailsFormProps {
  disabled?: boolean;
}

const PaymentCardDetailsForm = ({ disabled }: IPaymentCardDetailsFormProps) => (
  <StripeCardDetailsForm disabled={disabled} />
);

export default PaymentCardDetailsForm;
