import { Route, Routes } from 'react-router';

import {
  canViewAAOrganizationsPermissions,
  canViewGeneralOrganizationsPermissions,
} from '../../config/accessPolicies/accessPolicies';
import { RoutePath } from '../../models/enums/RoutePath';
import AuthRoute from '../../styledComponents/AuthRoute/AuthRoute';
import Redirect from '../../styledComponents/Shared/Redirect';
import CorporateCLientsAirAmbulancePage from './CorporateClientsAirAmbulancePage/CorporateCLientsAirAmbulancePage';
import CorporateClientsGeneralPage from './CorporateClientsGeneralPage/CorporateClientsGeneralPage';

const CorporateClientsRoutes = () => (
  <Routes>
    <Route
      path={RoutePath.ProductGeneralPage}
      element={
        <AuthRoute permissions={canViewGeneralOrganizationsPermissions}>
          <CorporateClientsGeneralPage />
        </AuthRoute>
      }
    />
    <Route
      path={RoutePath.ProductAirAmbulancePage}
      element={
        <AuthRoute permissions={canViewAAOrganizationsPermissions}>
          <CorporateCLientsAirAmbulancePage />
        </AuthRoute>
      }
    />
    <Route path="*" element={<Redirect to={RoutePath.ProductGeneralPage} />} />
  </Routes>
);

export default CorporateClientsRoutes;
