import aaReportsSaga from './airAmbulance';
import keReportsSaga from './kidnapAndExtortion';
import srReportsSaga from './securityResponse';
import docReportsSaga from './dutyOfCare';
import reportsSaga from './common';

const reportsSagasRoot = [
  aaReportsSaga(),
  keReportsSaga(),
  reportsSaga(),
  srReportsSaga(),
  docReportsSaga(),
];
export default reportsSagasRoot;
