import { TableRow } from './TableRow/TableRow';
import { CollapsibleRow } from './TableRow/CollapsibleRow';

import { useSubscriptionRow } from './subscriptionAnalyseRow.hooks';
import { SubscriptionMember } from '../subscriptionAnalyse.enums';

type Props = {
  input: {
    name: string;
  };
};

export const SubscriptionAnalyseTableRow = ({ input }: Props) => {
  const fieldName = input.name;
  const {
    toggle,
    isOpen,
    isCollapsible,
    secondaryMembers,
    primaryMember,
    membershipPlanName,
    infoStatus,
    handleChange,
  } = useSubscriptionRow({ fieldName });

  return (
    <>
      <TableRow
        isOpen={isOpen}
        toggle={toggle}
        member={primaryMember}
        isCollapsible={isCollapsible}
        membershipPlanName={membershipPlanName}
        fieldType={SubscriptionMember.PRIMARY_MEMBER}
        infoStatus={infoStatus}
        handleChange={handleChange}
      />
      {isCollapsible && (
        <CollapsibleRow
          members={secondaryMembers}
          isOpen={isOpen}
          membershipPlanName={membershipPlanName}
          handleChange={handleChange}
        />
      )}
    </>
  );
};
