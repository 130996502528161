import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { IDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionModel';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { docSubscriptionPricingSchema } from '../../../Forms/DutyOfCare/DOCSubscriptionPackageOptionsForm/docSubscriptionPricingForm.validation';
import { DOCRenewSubscriptionPricingFormFields } from './DOCRenewSubscriptionPricing/docRenewSubscriptionPricing.models';

export const docRenewSubscriptionSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [DOCRenewSubscriptionPricingFormFields.PackageOptions]:
      docSubscriptionPricingSchema,
  },
  required: [],
};

export const validateRenewDOCSubscriptionForm = (
  data: IDOCOrganizationSubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, docRenewSubscriptionSchema);
