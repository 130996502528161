import { useTranslation } from 'react-i18next';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import config from '../../../../config/config';
import { Dictionary } from '../../../../models/Dictionary';
import { customStyles } from '../../MainSelect/mainSelect.styles';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import { useFormGooglePlacesAutocomplete } from './formGooglePlacesAutocomplete.hooks';
import { StyledFormFieldWrapper } from './formGooglePlacesAutocomplete.styles';

interface IFormGooglePlacesAutocompleteProps extends CustomComponent {
  fields: Dictionary<string>;
}

export const FormGooglePlacesAutocomplete = ({
  disabled,
  fields,
}: IFormGooglePlacesAutocompleteProps) => {
  const { t } = useTranslation();
  const { isLoading, onChange } = useFormGooglePlacesAutocomplete(fields);

  return (
    <StyledFormFieldWrapper>
      <GooglePlacesAutocomplete
        apiKey={config.googleApiKey}
        selectProps={{
          placeholder: t('phrase.find-address'),
          value: null,
          isDisabled: disabled,
          isLoading,
          onChange,
          styles: customStyles as any,
          classNamePrefix: 'mainSelect',
        }}
      />
    </StyledFormFieldWrapper>
  );
};
