import {
  canAddSRMemberPermissions,
  canAddSeatsSRSubscriptionPermissions,
  canEditSRSubscriptionPermissions,
  canRemoveSRSubscriptionPermissions,
} from '../../../../../config/accessPolicies/accessPolicies';
import { ProductSubscriptionActionsPermissions } from '../ProductSubscriptionActions/productSubscriptionActions.models';

export const srSubscriptionOverviewPermissions: ProductSubscriptionActionsPermissions =
  {
    canAddMemberPermissions: canAddSRMemberPermissions,
    canAddSeatsSubscriptionPermissions: canAddSeatsSRSubscriptionPermissions,
    canEditSubscriptionPermissions: canEditSRSubscriptionPermissions,
    canRemoveSubscriptionPermissions: canRemoveSRSubscriptionPermissions,
  };
