import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import SearchInput from '../../SearchInput/SearchInput';
import { getPropertyName } from '../../../../utils/getPropertyName';
import { CustomComponent } from '../../../../models/types/CustomComponent';
import { FormFieldWrapper } from '../../../Form/Form';
import { ISearchInputOption } from '../../SearchInput/searchInput.models';
import { ErrorLabel } from '../../Field/Field';

interface IFormSearchInputProps<T>
  extends CustomComponent,
    FieldRenderProps<any> {
  className?: string;
  inputAdditionalClassName?: string;
  inputId?: string;
  isLabelHidden?: boolean;
  label?: string;
  onChange?: (option: ISearchInputOption<T> | string) => void;
  onlyNumber?: boolean;
  onlyNumberAndSpace?: boolean;
  promiseOptions?: (text: string) => Promise<ISearchInputOption<T>[]>;
  setDefaultValue?: boolean;
}

export const FormSearchInput = <T,>({
  className,
  disabled,
  input,
  label,
  meta: { error, touched },
  onChange,
  options,
  placeholder,
  promiseOptions,
  setDefaultValue,
  value,
  ...props
}: IFormSearchInputProps<T>) => {
  const { t } = useTranslation();

  const onChangeLocal = (option: ISearchInputOption<T> | string) => {
    input.onChange(option);
  };

  return (
    <FormFieldWrapper className={className} error={touched && error}>
      <SearchInput
        disabled={disabled}
        isError={error && touched}
        onChange={onChange || onChangeLocal}
        placeholder={placeholder}
        label={label || t(`fields.${getPropertyName(input.name)}.label`)}
        promiseOptions={promiseOptions}
        value={input.value}
        onBlur={input.onBlur}
        {...props}
      />
      {touched && error && <ErrorLabel>{error}</ErrorLabel>}
    </FormFieldWrapper>
  );
};
