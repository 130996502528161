import styled from '@emotion/styled/macro';

import Button from '../Button/Button';

export const CollapseItemWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.light1};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.hover};
  padding: 0.5rem;
  border-radius: 1rem;
`;
