import styled from '@emotion/styled/macro';

import { Line } from '../../../../../../../styledComponents/UI/Line/Line';

export const StyledLine = styled(Line)`
  margin: 1.5rem 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin: 1.75rem 0;
  align-items: center;
  gap: 1.5rem;
`;
