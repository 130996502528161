import { useParams } from 'react-router';

import { ExtendProductSubscriptionPageParamsType } from '../../models/types/RoutePath';
import ProductViewContent from '../../styledComponents/ProductViewContent/ProductViewContent';
import {
  ExtendSubscriptionPages,
  ExtendSubscriptionViewsPermissions,
} from './extendProductSubscriptionPage.utils';

const ExtendProductSubscriptionPage = () => {
  const { productType } = useParams<ExtendProductSubscriptionPageParamsType>();

  return (
    <ProductViewContent
      productType={productType}
      viewsPages={ExtendSubscriptionPages}
      viewsPermissions={ExtendSubscriptionViewsPermissions}
    />
  );
};

export default ExtendProductSubscriptionPage;
