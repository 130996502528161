import styled from '@emotion/styled';

import Button from '../../../UI/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.875rem;
`;

export const MessageIcon = styled.div`
  margin-right: 0.5rem;
`;

export const StyledButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: normal;
  color: ${({ theme }) => theme.color.lighter_blue};
  padding-left: 0.2rem;
`;
