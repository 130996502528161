import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { useProcessing } from '../../../hooks/useProcessing';
import { RoutePath } from '../../../models/enums/RoutePath';
import {
  organizationsListSelector,
  organizationsTotalCountSelector,
} from '../../../store/redux-slices/organizations/selectors';
import { ORGANIZATIONS_LOADING_PROCESSING } from '../../../store/redux-slices/processes/constants';
import { loadOrganizations } from '../../../store/saga-slices/organizations';

export const useCorporateClientsGeneralPage = () => {
  const navigation = useNavigateSearch();
  const dataLoading = useProcessing(ORGANIZATIONS_LOADING_PROCESSING);

  const location = useLocation();
  const LoadCorporations = useActions(loadOrganizations);
  const organizations = useSelector(organizationsListSelector);
  const organizationsTotalCount = useSelector(organizationsTotalCountSelector);

  const onRowClick = useCallback(
    ({ id }: { id: string }) =>
      navigation(RoutePath.ClientOverviewGeneralPageFullPath, {
        params: { id },
      }),
    [navigation],
  );

  useEffect(() => {
    const params = location.search.slice(1);
    if (params !== '') {
      LoadCorporations({ params: location.search.slice(1) });
    }
  }, [LoadCorporations, location.search]);

  return {
    onRowClick,
    organizations,
    organizationsTotalCount,
    dataLoading,
  };
};
