/* eslint-disable no-undefined */
import { useCallback, useMemo } from 'react';

type UseNumericInputType = {
  value: number;
  maxValue?: number;
  minValue?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

export const useNumericInput = ({
  minValue,
  maxValue,
  value,
  onChange,
  disabled,
}: UseNumericInputType) => {
  const incrementValueHandler = useCallback(() => {
    if (
      value === null ||
      value === undefined ||
      (maxValue !== null && maxValue !== undefined && value >= maxValue)
    ) {
      return;
    }
    onChange(value + 1);
  }, [maxValue, onChange, value]);

  const decrementValueHandler = useCallback(() => {
    if (
      value === null ||
      value === undefined ||
      (minValue !== null && minValue !== undefined && value <= minValue)
    ) {
      return;
    }
    onChange(value - 1);
  }, [minValue, onChange, value]);

  const inputChangeHandler = useCallback(
    (currentValue: any): any => {
      if (currentValue === '') {
        onChange(0);
        return;
      }

      if (
        minValue !== null &&
        minValue !== undefined &&
        currentValue < minValue
      ) {
        onChange(minValue);
        return;
      }

      if (
        maxValue !== null &&
        maxValue !== undefined &&
        currentValue > maxValue
      ) {
        onChange(maxValue);
        return;
      }

      if (value === 0 && +currentValue !== 0) {
        onChange(+currentValue.replaceAll('0', ''));
        return;
      }

      onChange(+currentValue);
    },
    [maxValue, minValue, onChange, value],
  );

  const charBeforeNumber = useMemo(() => (value > 0 ? '+' : ''), [value]);

  const incrementButtonDisabled = useMemo(
    () =>
      (maxValue !== null && maxValue !== undefined && value === maxValue) ||
      disabled,
    [disabled, maxValue, value],
  );

  const decrementButtonDisabled = useMemo(
    () =>
      (minValue !== null && minValue !== undefined && value === minValue) ||
      disabled,
    [disabled, minValue, value],
  );

  return {
    incrementValueHandler,
    decrementValueHandler,
    charBeforeNumber,
    inputChangeHandler,
    incrementButtonDisabled,
    decrementButtonDisabled,
  };
};
