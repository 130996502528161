import { useParams } from 'react-router';

import { ProductSubscriptionOverviewPageParamsType } from '../../../../models/types/RoutePath';
import ProductViewContent from '../../../../styledComponents/ProductViewContent/ProductViewContent';

import {
  SubscriptionOverviewPages,
  SubscriptionsListViewDetailsPermissions,
} from './productSubscriptionOverviewTabPage.utils';

const ProductSubscriptionOverviewTabPage = () => {
  const { subscriptionType } =
    useParams<ProductSubscriptionOverviewPageParamsType>();

  return (
    <ProductViewContent
      productType={subscriptionType}
      viewsPages={SubscriptionOverviewPages}
      viewsPermissions={SubscriptionsListViewDetailsPermissions}
    />
  );
};

export default ProductSubscriptionOverviewTabPage;
