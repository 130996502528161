import { createContext, ReactNode, useMemo } from 'react';

import { DiscountType } from '../../../../models/enums/DiscountType';
import { PricingMode } from '../../../../models/enums/PricingMode';
import { IOrganizationSubscriptionPricingModel } from '../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';

import { IMembershipPlanPriceModel } from '../../../../models/MembershipPlanPriceModel';
import { useMembershipPlanPrices } from './useMembershipPlanPrices/useMembershipPlanPrices';
import { IMembershipPlanPreviousPriceModel } from './useMembershipPlanPrices/useMembershipPlanPrices.models';

type MembershipPlanFormInfoState = {
  state: IOrganizationSubscriptionPricingModel & {
    supportPreviousPrices: boolean;
    membershipPlans?: IMembershipPlanPriceModel[];
  };
};

type MembershipPlanFormActionableState = {
  changeDiscountType: (value: DiscountType) => void;
  changeDiscountValue: (value: number) => void;
  changeMembershipPlanPrice: (
    price: number,
    row: IMembershipPlanPriceModel,
  ) => void;
  changeNumberOfSeats: (value: number, row: IMembershipPlanPriceModel) => void;
  changePricingMode: (value: PricingMode) => void;
  getSelectedPlanCapacity: (id: string) => number;
};

export const MembershipPlanFormInfoContext =
  createContext<MembershipPlanFormInfoState>({
    state: null,
  });

export const MembershipPlanFormActionableContext =
  createContext<MembershipPlanFormActionableState>({
    changeDiscountType: null,
    changeDiscountValue: null,
    changeMembershipPlanPrice: null,
    changeNumberOfSeats: null,
    changePricingMode: null,
    getSelectedPlanCapacity: null,
  });

type Props = {
  children: ReactNode;
  regularMembershipPlansPrices: IMembershipPlanPriceModel[];
  previousMembershipPlansPrices?: IMembershipPlanPreviousPriceModel[];
  defaultPricingMode?: PricingMode;
  useDefaultPlansData?: boolean;
  defaultPlansData?: Record<string, { capacity: number; price: number }>;
  name?: string;
  usePreviousMembershipPlansPrices?: boolean;
  onValueChanged?: (value: IOrganizationSubscriptionPricingModel) => void;
  createInitValues?: boolean;
};

const MembershipPlanFormProvider = ({
  children,
  regularMembershipPlansPrices,
  previousMembershipPlansPrices,
  defaultPricingMode,
  useDefaultPlansData,
  defaultPlansData,
  name,
  usePreviousMembershipPlansPrices,
  onValueChanged,
  createInitValues,
}: Props) => {
  const {
    changeDiscountType,
    changeDiscountValue,
    changeMembershipPlanPrice,
    changeNumberOfSeats,
    changePricingMode,
    getSelectedPlanCapacity,
    state,
  } = useMembershipPlanPrices(
    regularMembershipPlansPrices,
    previousMembershipPlansPrices,
    defaultPricingMode,
    useDefaultPlansData,
    defaultPlansData,
    name,
    usePreviousMembershipPlansPrices,
    onValueChanged,
    createInitValues,
  );

  const actionableValue = {
    changeDiscountType,
    changeDiscountValue,
    changeMembershipPlanPrice,
    changeNumberOfSeats,
    changePricingMode,
    getSelectedPlanCapacity,
  };

  const infoValue = useMemo(
    () => ({
      state,
    }),
    [state],
  );

  return (
    <MembershipPlanFormInfoContext.Provider value={infoValue}>
      <MembershipPlanFormActionableContext.Provider value={actionableValue}>
        {children}
      </MembershipPlanFormActionableContext.Provider>
    </MembershipPlanFormInfoContext.Provider>
  );
};

export default MembershipPlanFormProvider;
