import { canViewKEMembersPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import DeleteProductSubscriptionModal from '../../../../../styledComponents/Modals/DeleteSubscriptionModal/DeleteSubscriptionModal';
import EditRenewalSubscriptionStatusModal from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';
import {
  LeftContainer,
  RightContainer,
} from '../../../../../styledComponents/Page/Page';
import ProductSubscriptionOverviewTemplatePage from '../ProductSubscriptionOverviewTemplatePage/ProductSubscriptionOverviewTemplatePage';
import { keSubscriptionOverviewPermissions } from './keSubscriptionOverview.const';
import {
  useKESubscriptionOverviewActions,
  useKESubscriptionOverviewTabPage,
} from './keSubscriptionOverview.hooks';
import KEPSubscriptionOverviewInformation from './KESubscriptionOverviewInformation/KESubscriptionOverviewInformation';
import KESubscriptionOverviewPlansTable from './KESubscriptionOverviewPlansTable/KESubscriptionOverviewPlansTable';
import KESubscriptionEditOptionsModal from './Modals/KESubscriptionEditOptionsModal/KESubscriptionEditOptionsModal';

const KESubscriptionOverview = () => {
  const {
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  } = useKESubscriptionOverviewTabPage();

  const {
    addMemberHandler,
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    onRowClickHandler,
    showEditSubscriptionDetailsModal,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    editRenewalStatusHandler,
  } = useKESubscriptionOverviewActions();

  return (
    <>
      <KESubscriptionEditOptionsModal
        onModalSuccess={() => loadSubscriptionOverviewHandler()}
      />
      <EditRenewalSubscriptionStatusModal
        onRenewalStatusEdited={loadSubscriptionOverviewHandler}
      />
      <DeleteProductSubscriptionModal />
      <ProductSubscriptionOverviewTemplatePage
        canAddMember={subscriptionOverview?.metadata?.canAddMember}
        canExtend={subscriptionOverview?.metadata?.canAddExtension}
        canDelete={subscriptionOverview?.metadata?.canDelete}
        onDeleteProductSubscription={deleteProductSubscriptionHandler}
        onAddMember={addMemberHandler}
        onExtendProductSubscription={extendProductSubscriptionHandler}
        onEditSubscription={editSubscriptionHandler}
        isLoading={subscriptionOverviewLoading}
        canEditSubscription={subscriptionOverview?.metadata?.canEdit}
        permissions={keSubscriptionOverviewPermissions}
      >
        <LeftContainer>
          <KESubscriptionOverviewPlansTable
            subscriptionOverview={subscriptionOverview}
            onRowClick={onRowClickHandler}
            onSummaryRowClick={onRowClickHandler}
            onSeeOversubscriptionClick={onSeeOversubscribedMembersHandler}
            onSummarySeeOversubscriptionClick={
              onSeeOversubscribedMembersHandler
            }
            canClickRowPermissions={canViewKEMembersPermissions}
          />
        </LeftContainer>
        <RightContainer>
          <KEPSubscriptionOverviewInformation
            onEditOptionsClick={showEditSubscriptionDetailsModal}
            subscriptionOverview={subscriptionOverview}
            canEditOptions={!!subscriptionOverview?.metadata?.canEditOptions}
            canRenew={!!subscriptionOverview?.metadata?.canRenew}
            onEditRenewalStatus={editRenewalStatusHandler}
          />
        </RightContainer>
      </ProductSubscriptionOverviewTemplatePage>
    </>
  );
};

export default KESubscriptionOverview;
