import { useTranslation } from 'react-i18next';

import { ReactNode } from 'react';

import Button from '../../../UI/Button/Button';
import { Header, Title } from './summaryItemHeader.styles';
import { ReactComponent as BasketIcon } from '../../../../assets/icons/basket-icon.svg';
import { ProductType } from '../../../../models/ProductType';
import { isPropertyEmpty } from '../../../../utils/propertyHelpers';

type SubscriptionSummaryItemHeaderProps = {
  onRemove?: (index: number) => void;
  index?: number;
  subscriptionType?: ProductType;
  title?: ReactNode;
};

const SubscriptionSummaryItemHeader = ({
  index,
  onRemove,
  subscriptionType,
  title,
}: SubscriptionSummaryItemHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Header>
      <Title>
        {title ||
          `${!isPropertyEmpty(index) ? `#${index + 1}` : ''} ${t(
            `enums.productType.${subscriptionType}`,
          )}`}
      </Title>
      {onRemove && (
        <Button onClick={() => onRemove?.(index)} styleType="icon">
          <BasketIcon />
        </Button>
      )}
    </Header>
  );
};

export default SubscriptionSummaryItemHeader;
