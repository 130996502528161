import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RetailIcon } from '../../../../../../../assets/icons/retail-icon.svg';
import { ReactComponent as CorpIcon } from '../../../../../../../assets/icons/corporation-icon.svg';

import { MembershipType } from '../../../../../../../models/MembershipType';
import { getFieldPropertiesForGroup } from '../../../../../../../utils/getFormGroupPath';
import { useMembershipTypeForm } from './aaMembershipType.hooks';
import {
  MembershipTypeFormFields,
  MembershipTypeFormName,
} from './aaMembershipType.models';
import { StyledFormTabOptions } from './aaMembershipType.styles';
import { CustomForm } from '../../../../../../../models/types/CustomForm';

const MembershipTypeForm = ({ className, disabled, name }: CustomForm) => {
  const { t } = useTranslation();
  const { memberTypeReadOnly } = useMembershipTypeForm(name);

  return (
    <div className={className}>
      <Field
        component={StyledFormTabOptions}
        defaultValue={MembershipType.Organization}
        disabled={disabled}
        readonly={memberTypeReadOnly}
        {...getFieldPropertiesForGroup({
          inputId: MembershipTypeFormName,
          name: MembershipTypeFormFields.MembershipType,
          groupName: name,
        })}
        options={[
          {
            value: MembershipType.Organization,
            label: t('fields.typeOrganization.label'),
            icon: <CorpIcon />,
          },
          {
            value: MembershipType.Retail,
            label: t('fields.typeRetail.label'),
            icon: <RetailIcon />,
          },
        ]}
      />
    </div>
  );
};

export default MembershipTypeForm;
