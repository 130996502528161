import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { ICheckboxProps } from '../../Checkbox/Checkbox';
import { ErrorLabel } from '../../Field/Field';
import { useFormCheckbox } from './formCheckbox.hooks';
import {
  StyledCheckbox,
  StyledFormFieldWrapper,
  Wrapper,
} from './formCheckbox.styles';

export interface IFormCheckboxProps
  extends FieldRenderProps<boolean>,
    ICheckboxProps {
  label: string;
  checkboxContentRenderer?: FC<{ disabled: boolean; name: string }>;
  checkboxContentName?: string;
}

const FormCheckbox = ({
  input,
  checkboxContentRenderer: CheckboxContentRenderer,
  meta: { error, touched },
  inputId,
  onChange,
  label,
  disabled,
  className,
  checkboxContentName,
  ...props
}: IFormCheckboxProps) => {
  const { onChangeHandler, currentLabel } = useFormCheckbox({
    onChange,
    input,
    label,
  });
  return (
    <StyledFormFieldWrapper className={className}>
      <Wrapper>
        <StyledCheckbox
          {...props}
          disabled={disabled}
          id={inputId}
          isError={touched && error}
          checked={input.checked}
          onChange={onChangeHandler}
          label={currentLabel}
        />
        {touched && error && <ErrorLabel>{error}</ErrorLabel>}
      </Wrapper>
      {CheckboxContentRenderer && input.checked ? (
        <CheckboxContentRenderer
          name={checkboxContentName}
          disabled={disabled}
        />
      ) : null}
    </StyledFormFieldWrapper>
  );
};

export default FormCheckbox;
