import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
`;

export const LineContainer = styled.div`
  cursor: default;
  width: 10rem;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

type LineType = {
  isDisabled?: boolean;
};
export const Line = styled.div<LineType>`
  position: relative;
  top: 50%;
  border-bottom: 0.063rem solid ${({ theme }) => theme.color.secondary2};
  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      border-color: ${theme.color.light1};
    `}
`;

type StepLabelType = {
  isDisabled?: boolean;
  isAvailable?: boolean;
  isActive?: boolean;
};

export const StepLabel = styled.div<StepLabelType>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary1};
  font-weight: bold;

  ${({ isAvailable, theme }) =>
    !isAvailable &&
    css`
      color: ${theme.color.light1};
      pointer-events: none;
    `}

  ${({ isDisabled, isAvailable, theme }) =>
    isDisabled &&
    isAvailable &&
    css`
      color: ${theme.color.secondary2};
      pointer-events: none;
    `}
`;

export const DotContainer = styled.div<StepLabelType>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isAvailable, theme }) =>
    !isAvailable &&
    css`
      border-color: ${theme.color.light1};

      ${Dot} {
        background-color: ${theme.color.light1};
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border: 0.125rem solid ${theme.color.secondary1};
      border-radius: 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    `}

    ${({ isDisabled, isAvailable, theme }) =>
    isDisabled &&
    isAvailable &&
    css`
      border-color: ${theme.color.secondary2};

      ${Dot} {
        background-color: ${theme.color.secondary2};
      }
    `}
`;

export const Dot = styled.div<StepLabelType>`
  height: 1rem;
  width: 1rem;
  background-color: ${({ theme }) => theme.color.secondary1};
  border-radius: 1rem;
`;

export const Link = styled.div<StepLabelType>`
  font-size: 0.75rem;
  padding-left: 0.5rem;
  white-space: nowrap;
`;
