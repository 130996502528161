import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import { parseDate } from '../../../../utils/dateHelpers';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IMemberListItem } from '../../../../models/MemberListItem';
import { IPageListModel } from '../../../../models/PageListModel';
import { IPerson } from '../../../../models/Person';
import { personToMemberListItem } from '../../../../utils/personHelpers';
import { IKEMembershipModel } from '../../../../models/interfaces/Membership/KidnapAndExtortion/KEMembershipModel';
import { Dictionary } from '../../../../models/Dictionary';

export interface IKEMembersState {
  summary?: IAddMembershipSummaryModel;
  newMembershipDates: IMembershipDates;
  members?: IPageListModel<IMemberListItem>;
  membershipDetails: Dictionary<IKEMembershipModel>;
}

const initialState: IKEMembersState = {
  summary: null,
  newMembershipDates: null,
  members: null,
  membershipDetails: {},
};

const keMembershipSlice = createSlice({
  initialState,
  name: 'keMembership',
  reducers: {
    setKEMembershipSummary: (
      state,
      { payload }: PayloadAction<IAddMembershipSummaryModel>,
    ) => {
      state.summary = payload;
    },
    setKEMembershipDates: (
      state,
      { payload }: PayloadAction<IMembershipDates>,
    ) => {
      state.newMembershipDates = {
        effectiveDate: parseDate(payload.effectiveDate),
        expirationDate: parseDate(payload.expirationDate),
      };
    },
    setKEMembers: (
      state,
      { payload }: PayloadAction<IPageListModel<IMemberListItem>>,
    ) => {
      state.members = payload;
    },
    updateKEMembersList: (state, { payload }: PayloadAction<IPerson>) => {
      const currentPersonIndex = state.members?.items.findIndex(
        (member) => member.personId === payload.personId,
      );

      if (currentPersonIndex !== -1) {
        state.members.items[currentPersonIndex] = personToMemberListItem(
          payload,
          state.members.items[currentPersonIndex],
        );
      }
    },
    setKEMembershipDetails: (
      state,
      { payload }: PayloadAction<IKEMembershipModel>,
    ) => {
      state.membershipDetails[payload.id] = payload;
    },
    clearKEMembershipDates: (state) => {
      state.newMembershipDates = initialState.newMembershipDates;
    },
    clearKECreateMemberData: () => initialState,
    clearKEMembershipSummary: (state) => {
      state.summary = initialState.summary;
    },
    clearKEMembershipDetails: (state) => {
      state.membershipDetails = initialState.membershipDetails;
    },
    clearKEMembers: (state) => {
      state.members = initialState.members;
    },
  },
});

export const {
  clearKECreateMemberData,
  clearKEMembers,
  clearKEMembershipDates,
  clearKEMembershipSummary,
  setKEMembers,
  setKEMembershipDates,
  setKEMembershipSummary,
  updateKEMembersList,
  clearKEMembershipDetails,
  setKEMembershipDetails,
} = keMembershipSlice.actions;
export default keMembershipSlice.reducer;
export const membersReducerName = keMembershipSlice.name;
