import { SectionWrapper } from '../../Form/Form';
import { CustomForm } from '../../../models/types/CustomForm';

import StripeCardDetailsModalForm from '../../Stripe/StripeCardDetailsModalForm';
import StripeCardDetailsForm from '../../Stripe/StripeCardDetailsForm';

export type CreditCardsDetailsFormProps = CustomForm & {
  isModal?: boolean;
};

const CreditCardsDetailsForm = ({
  className,
  disabled,
  isModal = false,
}: CreditCardsDetailsFormProps) => (
  <SectionWrapper className={className}>
    {isModal ? (
      <StripeCardDetailsModalForm disabled={disabled} />
    ) : (
      <StripeCardDetailsForm disabled={disabled} />
    )}
  </SectionWrapper>
);

export default CreditCardsDetailsForm;
