import { EditableStatusProps } from '../../../UI/EditableStatus/EditableStatus';
import { ICellRenderer } from '../cellRenderers.models';
import TableCell from '../TableCell/TableCell';
import { StyledEditableStatusRenderer } from './editableStatusRenderer.styles';

interface IEditableStatusRenderer extends ICellRenderer, EditableStatusProps {}

export const EditableStatusRenderer = ({
  options,
  onClick,
  className,
  ...props
}: IEditableStatusRenderer) => (
  <TableCell
    onClick={onClick}
    bold={options?.textBold}
    alignment={options?.alignment}
    className={className}
  >
    <StyledEditableStatusRenderer {...props} />
  </TableCell>
);
