import Spinner from '../../styledComponents/Spinner/Spinner';
import ClientOverviewHeader from './ClientOverviewHeader/ClientOverviewHeader';
import { useClientOverviewPage } from './clientOverviewPage.hooks';
import Header from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { StyledTabNavigation } from './clientOverviewPage.styles';
import ClientOverviewRoutes from './clientOverviewPage.routes';

const ClientOverviewPage = () => {
  const { organizationIsLoading, organization, tabPages } =
    useClientOverviewPage();

  if (organizationIsLoading || !organization) {
    return <Spinner size="20%" />;
  }

  return (
    <div>
      <Header
        title={
          <ClientOverviewHeader
            status={organization.status}
            title={organization.name}
            type={organization.organizationType}
          />
        }
        bottomLineIsHidden
      />
      <StyledTabNavigation routeMode tabs={tabPages}>
        <ClientOverviewRoutes />
      </StyledTabNavigation>
    </div>
  );
};

export default ClientOverviewPage;
