import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SectionWrapper } from '../../../../../styledComponents/Form/Form';
import { SmallModal } from '../../../../../styledComponents/ModalCommon/ModalCommon';
import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';
import FormCheckboxGroup from '../../../../../styledComponents/UI/Form/FormCheckboxGroup/FormCheckboxGroup';
import {
  docMembershipOptionsList,
  EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL,
} from './editDOCMembershipOptionsModal.const';
import { useEditDOCMembershipOptionsModal } from './editDOCMembershipOptionsModal.hooks';

interface IEditDOCMembershipOptionsModalProps {
  onModalCancel?: () => void;
  onMembershipOptionsUpdated?: () => void;
  id?: string;
}

const EditDOCMembershipOptionsModal = ({
  onModalCancel,
  onMembershipOptionsUpdated,
  id,
}: IEditDOCMembershipOptionsModalProps) => {
  const { t } = useTranslation();
  const {
    updateMembershipOptionsHandler,
    membershipOptions,
    isProcessing,
    onCloseHandler,
  } = useEditDOCMembershipOptionsModal({ id });

  return (
    <SmallModal
      disabled={isProcessing}
      name={EDIT_DOC_MEMBERSHIP_OPTIONS_MODAL + id}
      onModalClose={onModalCancel}
    >
      <Form
        keepDirtyOnReinitialize
        onSubmit={updateMembershipOptionsHandler}
        initialValues={membershipOptions}
      >
        {({ ...renderProps }) => (
          <ModalFormPage
            mode="small"
            handleSubmit={renderProps.handleSubmit}
            isProcessing={isProcessing}
            onCancel={onCloseHandler}
            title={t('headers.edit-options')}
          >
            <SectionWrapper>
              <FormCheckboxGroup
                layout="column"
                options={docMembershipOptionsList}
              />
            </SectionWrapper>
          </ModalFormPage>
        )}
      </Form>
    </SmallModal>
  );
};

export default EditDOCMembershipOptionsModal;
