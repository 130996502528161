import { AvailableQueryParams } from '../../../../../styledComponents/MaterialTable/materialTable.models';
import { KEMembersListFilters } from '../../../../../styledComponents/ProductMembersList/KEMembersList/KEMembersFilters/keMembersFilters.models';

export const pageFilters = [
  KEMembersListFilters.Search,
  KEMembersListFilters.Plan,
  KEMembersListFilters.MemberType,
  KEMembersListFilters.RenewStatus,
  KEMembersListFilters.SubscriptionId,
  KEMembersListFilters.OversubscribedOnly,
];

export const RequiredQueryParams = [
  AvailableQueryParams.Order,
  AvailableQueryParams.OrderBy,
  AvailableQueryParams.PageNumber,
  AvailableQueryParams.PageSize,
];
