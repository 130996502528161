import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { reactPlugin } from 'utils/appInsights';

import ErrorHandler from './styledComponents/ErrorHandler/ErrorHandler';
import AppRoutes from './AppRoutes';
import AppInit from './styledComponents/AppInit/AppInit';
import Layout from './styledComponents/Layout/Layout';
import { FullLayoutPage } from './styledComponents/Page/Page';

const Main = () => (
  <Layout>
    <ErrorHandler>
      <AppInit>
        <FullLayoutPage>
          <AppRoutes />
        </FullLayoutPage>
      </AppInit>
    </ErrorHandler>
  </Layout>
);
export default withAITracking(reactPlugin, Main);
