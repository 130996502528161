import { useTranslation } from 'react-i18next';

import { FieldArray } from 'react-final-form-arrays';

import { FieldRenderProps, useField } from 'react-final-form';

import { BasicFormWrapper } from '../../../Form/Form';

import { CustomForm } from '../../../../models/types/CustomForm';
import { Header, StyledButton } from './tlSubscriptionPricingForm.styles';

import TLSubscriptionPricingTableForm from './TLSubscriptionPricingTable/TLSubscriptionPricingTable';

import {
  TLSubscriptionDefaultValue,
  TLSubscriptionDefaultValueArray,
} from './tlSubscriptionPricingForm.const';
import InfoComponent from '../../../UI/InfoComponent/InfoComponent';
import { SectionSubTitle } from '../../../Typography/Typography';
import TLPricingTooltip from './TLPricingTooltip/TLPricingTooltip';
import { ITLOrganizationSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ITLOrganizationSubscriptionPricingTierModel';

type TLSubscriptionPricingFormProps = CustomForm & {
  title?: string;
};

const TLSubscriptionPricingForm = ({
  className,
  disabled,
  name,
  title,
}: TLSubscriptionPricingFormProps) => {
  const { t } = useTranslation();
  const {
    meta: { error, touched },
  }: FieldRenderProps<ITLOrganizationSubscriptionPricingTierModel[]> =
    useField(name);
  return (
    <BasicFormWrapper className={className}>
      <Header>
        {title && <SectionSubTitle>{title}</SectionSubTitle>}
        <InfoComponent
          disabled={disabled}
          tooltipPlacement="left-end"
          tooltip={<TLPricingTooltip />}
        />
      </Header>
      <FieldArray defaultValue={TLSubscriptionDefaultValueArray} name={name}>
        {({ fields }) => (
          <div>
            <TLSubscriptionPricingTableForm
              fieldsData={fields.map((x, index) => ({ name: x, index }))}
              onDelete={(id) => fields.remove(id)}
              errors={error}
              canRemove={fields.length < 2}
              touched={touched}
              disabled={disabled}
            />
            <StyledButton
              disabled={disabled}
              onClick={() => fields.push(TLSubscriptionDefaultValue)}
              styleType="link"
            >
              {t('buttons.add-new-tier')}
            </StyledButton>
          </div>
        )}
      </FieldArray>
    </BasicFormWrapper>
  );
};

export default TLSubscriptionPricingForm;
