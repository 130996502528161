import { useCallback, useState } from 'react';
import { FieldRenderProps, useField } from 'react-final-form';

import { ProductType } from '../../../../../models/ProductType';

import { getFormGroupPath } from '../../../../../utils/getFormGroupPath';
import { BasicMembershipFormFields } from '../MembershipSectionsForms/BasicMembershipForm/basicMembershipForm.models';

export const useMembershipItemForm = (name: string) => {
  const [spinnerVisible, setSpinnerVIsible] = useState(false);

  const {
    input: { value: productType },
  }: FieldRenderProps<ProductType> = useField(
    getFormGroupPath(name, BasicMembershipFormFields.ProductType),
  );

  const productTypeChangedHandler = useCallback(() => {
    setSpinnerVIsible(true);
    setTimeout(() => {
      setSpinnerVIsible(false);
    }, 500);
  }, []);

  return { productType, spinnerVisible, productTypeChangedHandler };
};
