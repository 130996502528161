import { Field } from 'react-final-form';

import { CustomForm } from '../../../../../models/types/CustomForm';

import { ICellOptions } from '../../cellRenderers.models';
import TableCell from '../../TableCell/TableCell';
import { StyledFormNumericInput } from './formNumericRenderer.styles';

export interface IFormNumericRenderer extends CustomForm {
  options?: ICellOptions;
  showError?: boolean;
  maxValue?: number;
  minValue?: number;
}

export const FormNumericRenderer = ({
  options,
  disabled,
  name,
  ...props
}: IFormNumericRenderer) => (
  <TableCell {...options}>
    <Field
      {...props}
      name={name}
      component={StyledFormNumericInput}
      disabled={disabled}
    />
  </TableCell>
);
