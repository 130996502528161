import { ProductType } from '../../../../../models/ProductType';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { SubscriptionRenewPricing } from '../../Common/SubscriptionRenewPricing/SubscriptionPricing';

import { useSRRenewSubscriptionPricing } from './srRenewSubscriptionPricing.hooks';

type SRRenewSubscriptionPricingMode = CustomComponent & {
  productType?: ProductType;
};

export const SRRenewSubscriptionPricing = ({
  disabled,
  productType,
}: SRRenewSubscriptionPricingMode) => {
  const {
    membershipPlansPreviousPrices,
    membershipPlansPrices,
    subscriptionRenewalSuggestionPlans,
    loadCapacityBySuggestions,
    suggestionsCapacity,
  } = useSRRenewSubscriptionPricing();

  return (
    <SubscriptionRenewPricing
      disabled={disabled}
      onTransferAllEligibleMembershipsChange={loadCapacityBySuggestions}
      previousMembershipPlansPrices={membershipPlansPreviousPrices}
      regularMembershipPlansPrices={membershipPlansPrices}
      subscriptionRenewalSuggestionPlans={subscriptionRenewalSuggestionPlans}
      defaultPlansData={suggestionsCapacity}
      productType={productType}
    />
  );
};
