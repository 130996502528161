import { Field } from 'react-final-form';

import { getFieldPropertiesForGroup } from '../../../../../utils/getFormGroupPath';
import {
  ChangesColumnRendererProps,
  TableFormColumns,
  TableFormName,
} from '../extendDOCSubscriptionPackageFormTable.models';
import { packagesOptions } from '../extendDOCSubscriptionPackageFormTable.utils';
import { FormPricingRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/Form/FormPricingRenderer/FormPricingRenderer';
import { FormSelectRenderer } from './FormSelectRenderer/FormSelectRenderer';
import { FormNumericRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/Form/FormNumericRenderer/FormNumericRenderer';

export const ChangesColumnRenderer = ({
  columnType,
  options,
  disabled,
  name,
  data,
}: ChangesColumnRendererProps) => {
  switch (columnType) {
    case TableFormColumns.packageType: {
      return (
        <Field
          component={FormSelectRenderer}
          options={options}
          disabled={disabled}
          selectOptions={packagesOptions}
          {...getFieldPropertiesForGroup({
            inputId: TableFormName,
            groupName: name,
            name: TableFormColumns.packageType,
          })}
        />
      );
    }
    case TableFormColumns.totalPrice: {
      return (
        <Field
          component={FormPricingRenderer}
          options={options}
          minValue={-data?.totalPrice}
          allowNegative
          {...getFieldPropertiesForGroup({
            inputId: TableFormName,
            groupName: name,
            name: TableFormColumns.totalPrice,
          })}
        />
      );
    }

    case TableFormColumns.seatsAmount: {
      return (
        <FormNumericRenderer
          options={options}
          disabled={data?.firmWide || disabled}
          minValue={data?.totalMembershipsInUse - data?.seatsAmount}
          showError={false}
          name={TableFormColumns.seatsAmount}
          {...getFieldPropertiesForGroup({
            inputId: TableFormName,
            groupName: name,
            name: TableFormColumns.seatsAmount,
          })}
        />
      );
    }
  }
};
