import { css } from '@emotion/react';
import styled from '@emotion/styled';

type FormRowProps = {
  isSubRow?: boolean;
  subRowAdjustWidth?: boolean;
};

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  width: 100%;

  & > :not(:first-of-type) {
    margin-left: 2.5rem;
  }

  ${({ isSubRow }) =>
    isSubRow &&
    css`
      & > :not(:first-of-type) {
        margin-left: 1.5rem;
      }
    `}

  ${({ subRowAdjustWidth, isSubRow }) =>
    subRowAdjustWidth &&
    css`
      > div {
        width: ${isSubRow ? `calc(50% - 0.75rem)` : `calc(50% - 1.25rem)`};
      }
    `}
`;
