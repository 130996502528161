export enum AirAmbulancePermissions {
  CanViewOrganizationData = 'CanViewOrganizationData',
  CanCreateSubscription = 'CanCreateSubscription',
  CanUpdateSubscription = 'CanUpdateSubscription',
  CanDeleteSubscription = 'CanDeleteSubscription',
  CanViewRegularPricing = 'CanViewRegularPricing',
  CanSearchPeople = 'CanSearchPeople',
  CanSearchOrganization = 'CanSearchOrganization',
  CanViewSubscriptionDetails = 'CanViewSubscriptionDetails',
  CanViewSubscriptionPlans = 'CanViewSubscriptionPlans',
  CanViewRegularPlans = 'CanViewRegularPlans',
  CanSearchPromoCode = 'CanSearchPromoCode',
  CanManagePromoCode = 'CanManagePromoCode',
  CanGenerateMembershipReport = 'CanGenerateMembershipReport',

  CanGeneratePromoCodesReport = 'CanGeneratePromoCodesReport',
  CanResendWelcomeEmail = 'CanResendWelcomeEmail',
  CanViewPersonDetails = 'CanViewPersonDetails',
  CanSearchMembers = 'CanSearchMembers',
  CanCreateMembership = 'CanCreateMembership',
  CanManageMemberships = 'CanManageMemberships',
  CanSetMembershipAlreadyPayed = 'CanSetMembershipAlreadyPayed',
  CanViewMembershipDetails = 'CanViewMembershipDetails',
  CanSyncMemberships = 'CanSyncMemberships',
  CanDeleteMembership = 'CanDeleteMembership',
  CanDeletePromoCode = 'CanDeletePromoCode',
  CanViewPromoCodeDetails = 'CanViewPromoCodeDetails',
  CanGenerateMembershipEnrollmentReport = 'CanGenerateMembershipEnrollmentReport',
  CanGenerateMembershipAttritionReport = 'CanGenerateMembershipAttritionReport',
  CanGenerateRetailStatisticsReport = 'CanGenerateRetailStatisticsReport',
}

export enum KidnapAndExtortionPermissions {
  CanViewOrganizationData = 'CanViewOrganizationData',
  CanCreateSubscription = 'CanCreateSubscription',
  CanUpdateSubscription = 'CanUpdateSubscription',
  CanDeleteSubscription = 'CanDeleteSubscription',
  CanViewRegularPricing = 'CanViewRegularPricing',
  CanSearchPeople = 'CanSearchPeople',
  CanSearchOrganization = 'CanSearchOrganization',
  CanViewSubscriptionDetails = 'CanViewSubscriptionDetails',
  CanViewSubscriptionPlans = 'CanViewSubscriptionPlans',
  CanViewRegularPlans = 'CanViewRegularPlans',
  CanSearchPromoCode = 'CanSearchPromoCode',
  CanManagePromoCode = 'CanManagePromoCode',
  CanGenerateMembershipReport = 'CanGenerateMembershipReport',
  CanGenerateSubscriptionFinanceReport = 'CanGenerateSubscriptionFinanceReport',
  CanGeneratePromoCodesReport = 'CanGeneratePromoCodesReport',
  CanResendWelcomeEmail = 'CanResendWelcomeEmail',
  CanViewPersonDetails = 'CanViewPersonDetails',
  CanSearchMembers = 'CanSearchMembers',
  CanCreateMembership = 'CanCreateMembership',
  CanManageMemberships = 'CanManageMemberships',
  CanSetMembershipAlreadyPayed = 'CanSetMembershipAlreadyPayed',
  CanViewMembershipDetails = 'CanViewMembershipDetails',
  CanSyncMemberships = 'CanSyncMemberships',
  CanDeleteMembership = 'CanDeleteMembership',
  CanDeletePromoCode = 'CanDeletePromoCode',
  CanViewPromoCodeDetails = 'CanViewPromoCodeDetails',
  CanGenerateMembershipEnrollmentReport = 'CanGenerateMembershipEnrollmentReport',
  CanGenerateMembershipAttritionReport = 'CanGenerateMembershipAttritionReport',
  CanGenerateRetailStatisticsReport = 'CanGenerateRetailStatisticsReport',
}

export enum DutyOfCarePermissions {
  CanViewOrganizationData = 'CanViewOrganizationData',
  CanCreateSubscription = 'CanCreateSubscription',
  CanUpdateSubscription = 'CanUpdateSubscription',
  CanDeleteSubscription = 'CanDeleteSubscription',
  CanViewRegularPricing = 'CanViewRegularPricing',
  CanSearchPeople = 'CanSearchPeople',
  CanSearchOrganization = 'CanSearchOrganization',
  CanViewSubscriptionDetails = 'CanViewSubscriptionDetails',
  CanViewSubscriptionPlans = 'CanViewSubscriptionPlans',
  CanViewRegularPlans = 'CanViewRegularPlans',
  CanSearchPromoCode = 'CanSearchPromoCode',
  CanManagePromoCode = 'CanManagePromoCode',
  CanGenerateMembershipReport = 'CanGenerateMembershipReport',
  CanGenerateSubscriptionFinanceReport = 'CanGenerateSubscriptionFinanceReport',
  CanGeneratePromoCodesReport = 'CanGeneratePromoCodesReport',
  CanResendWelcomeEmail = 'CanResendWelcomeEmail',
  CanViewPersonDetails = 'CanViewPersonDetails',
  CanSearchMembers = 'CanSearchMembers',
  CanCreateMembership = 'CanCreateMembership',
  CanManageMemberships = 'CanManageMemberships',
  CanSetMembershipAlreadyPayed = 'CanSetMembershipAlreadyPayed',
  CanViewMembershipDetails = 'CanViewMembershipDetails',
  CanSyncMemberships = 'CanSyncMemberships',
  CanDeleteMembership = 'CanDeleteMembership',
  CanDeletePromoCode = 'CanDeletePromoCode',
  CanViewPromoCodeDetails = 'CanViewPromoCodeDetails',
  CanGenerateMembershipEnrollmentReport = 'CanGenerateMembershipEnrollmentReport',
  CanGenerateMembershipAttritionReport = 'CanGenerateMembershipAttritionReport',
  CanGenerateRetailStatisticsReport = 'CanGenerateRetailStatisticsReport',
}

export enum TravelLicensePermissions {
  CanViewOrganizationData = 'CanViewOrganizationData',
  CanCreateSubscription = 'CanCreateSubscription',
  CanUpdateSubscription = 'CanUpdateSubscription',
  CanDeleteSubscription = 'CanDeleteSubscription',
  CanSearchOrganization = 'CanSearchOrganization',
  CanViewSubscriptionDetails = 'CanViewSubscriptionDetails',
  CanGenerateSubscriptionFinanceReport = 'CanGenerateSubscriptionFinanceReport',
}

export enum SecurityResponsePermissions {
  CanViewOrganizationData = 'CanViewOrganizationData',
  CanCreateSubscription = 'CanCreateSubscription',
  CanUpdateSubscription = 'CanUpdateSubscription',
  CanDeleteSubscription = 'CanDeleteSubscription',
  CanViewRegularPricing = 'CanViewRegularPricing',
  CanSearchPeople = 'CanSearchPeople',
  CanSearchOrganization = 'CanSearchOrganization',
  CanViewSubscriptionDetails = 'CanViewSubscriptionDetails',
  CanViewSubscriptionPlans = 'CanViewSubscriptionPlans',
  CanViewRegularPlans = 'CanViewRegularPlans',
  CanSearchPromoCode = 'CanSearchPromoCode',
  CanManagePromoCode = 'CanManagePromoCode',
  CanGenerateMembershipReport = 'CanGenerateMembershipReport',
  CanGenerateSubscriptionFinanceReport = 'CanGenerateSubscriptionFinanceReport',
  CanGeneratePromoCodesReport = 'CanGeneratePromoCodesReport',
  CanResendWelcomeEmail = 'CanResendWelcomeEmail',
  CanViewPersonDetails = 'CanViewPersonDetails',
  CanSearchMembers = 'CanSearchMembers',
  CanCreateMembership = 'CanCreateMembership',
  CanManageMemberships = 'CanManageMemberships',
  CanSetMembershipAlreadyPayed = 'CanSetMembershipAlreadyPayed',
  CanViewMembershipDetails = 'CanViewMembershipDetails',
  CanSyncMemberships = 'CanSyncMemberships',
  CanDeleteMembership = 'CanDeleteMembership',
  CanDeletePromoCode = 'CanDeletePromoCode',
  CanViewPromoCodeDetails = 'CanViewPromoCodeDetails',
  CanGenerateMembershipEnrollmentReport = 'CanGenerateMembershipEnrollmentReport',
  CanGenerateMembershipAttritionReport = 'CanGenerateMembershipAttritionReport',
  CanGenerateRetailStatisticsReport = 'CanGenerateRetailStatisticsReport',
}

export enum PeopleManagementPermissions {
  CanViewPeople = 'CanViewPeople',
  CanAddPerson = 'CanAddPerson',
  CanDeletePerson = 'CanDeletePerson',
  CanManageEmployees = 'CanManageEmployees',
  CanViewOrganizations = 'CanViewOrganizations',
  CanCreateOrganization = 'CanCreateOrganization',
  CanUpdateOrganization = 'CanUpdateOrganization',
  CanDeleteOrganization = 'CanDeleteOrganization',
  CanViewPeopleCreditCards = 'CanViewPeopleCreditCards',
  CanCreatePerson = 'CanCreatePerson',
  CanUpdatePerson = 'CanUpdatePerson',
  CanViewOrganizationContracts = 'CanViewOrganizationContracts',
  CanCreateOrganizationContract = 'CanCreateOrganizationContract',
  CanEditOrganizationContract = 'CanEditOrganizationContract',
  CanDeleteOrganizationContract = 'CanDeleteOrganizationContract',
  CanRenewOrganizationContract = 'CanRenewOrganizationContract',
  CanViewOrganizationSubscriptions = 'CanViewOrganizationSubscriptions',
  CanViewOrganizationContractsRenewals = 'CanViewOrganizationContractsRenewals',
  CanExportOrganizationContractsRenewals = 'CanExportOrganizationContractsRenewals',
  CanViewOrganizationSubscriptionsRenewals = 'CanViewOrganizationSubscriptionsRenewals',
  CanExportOrganizationSubscriptionsRenewals = 'CanExportOrganizationSubscriptionsRenewals',
}

export enum PaymentPermissions {
  CanViewPersonCreditCards = 'CanViewPersonCreditCards',
  CanAddPersonCreditCard = 'CanAddPersonCreditCard',
  CanReplacePersonCreditCard = 'CanReplacePersonCreditCard',
  CanDeletePersonCreditCard = 'CanDeletePersonCreditCard',
}

export enum ReportsPermissions {
  CanGenerateSubscriptionFinanceReport = 'CanGenerateSubscriptionFinanceReport',
  CanGenerateMembershipRetailFinanceReport = 'CanGenerateMembershipRetailFinanceReport',
}

export enum SystemGroupPermissions {
  AirAmbulance = 'air_ambulance',
  PeopleManagement = 'people_management',
  SecurityResponse = 'security_response',
  Payment = 'payment',
  KidnapAndExtortion = 'kidnap_and_extortion',
  DutyOfCare = 'duty_of_care',
  Reports = 'reports',
  TravelLicense = 'travel_license',
}
