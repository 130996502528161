import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../../hooks/useActions';
import { useModalDetails } from '../../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../../hooks/useTokenCancellation';
import { updateModalState } from '../../../../../store/redux-slices/modals';
import { UPDATE_MEMBERSHIP_OPTIONS_PROCESSING } from '../../../../../store/redux-slices/processes/constants';
import { EDIT_SR_MEMBERSHIP_OPTIONS_MODAL } from './editSRMembershipOptionsModal.const';
import { updateSRMembershipOptions } from '../../../../../store/saga-slices/membership/securityResponse';
import { EditSRMembershipOptionsModalDetails } from './editSRMembershipOptionsModal.models';
import { IUpdateSRMembershipOptionsModel } from '../../../../../models/interfaces/Membership/SecurityResponse/UpdateSRMembershipOptionsModel';
import { IUpdateSRMembershipOptionsPayload } from '../../../../../store/saga-slices/membership/securityResponse/models';

type UseEditSRMembershipOptionsModalProps = {
  id?: string;
  onMembershipOptionsUpdated?: () => void;
};

export const useEditSRMembershipOptionsModal = ({
  id,
  onMembershipOptionsUpdated,
}: UseEditSRMembershipOptionsModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const UpdateMembershipOptions = useActions(updateSRMembershipOptions);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const isProcessing = useProcessing(UPDATE_MEMBERSHIP_OPTIONS_PROCESSING);

  const {
    membershipOptions,
    membershipId,
  }: EditSRMembershipOptionsModalDetails = useModalDetails(
    EDIT_SR_MEMBERSHIP_OPTIONS_MODAL + id,
  );

  const updateMembershipOptionsHandler = useCallback(
    (values: IUpdateSRMembershipOptionsModel) => {
      const payload: IUpdateSRMembershipOptionsPayload = {
        membershipId,
        options: values,
        cancellationToken,
        success: () => {
          UpdateModalState(EDIT_SR_MEMBERSHIP_OPTIONS_MODAL + id);
          onMembershipOptionsUpdated?.();
          alert.success(t('alerts.membership-options-update-success'));
        },
        error: () => alert.error(t('alerts.membership-options-update-fail')),
      };

      UpdateMembershipOptions(payload);
    },
    [
      UpdateMembershipOptions,
      UpdateModalState,
      alert,
      cancellationToken,
      id,
      membershipId,
      onMembershipOptionsUpdated,
      t,
    ],
  );

  const onCloseHandler = () => {
    UpdateModalState(EDIT_SR_MEMBERSHIP_OPTIONS_MODAL + id);
  };
  return {
    updateMembershipOptionsHandler,
    membershipOptions,
    isProcessing,
    onCloseHandler,
  };
};
