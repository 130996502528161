import { useTranslation } from 'react-i18next';

import { IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel } from '../../../../../models/interfaces/Subscription/OrganizationSubscriptionRenewalSuggestionMembershipPlanModel';
import MembershipPlanForm, {
  IMemberShipPlanFormProps,
} from '../../../../UI/Form/MembershipPlanForm/membershipPlanForm';
import { cellRenderer } from './subscriptionMembershipRenewPlan.utils';

interface ISubscriptionMembershipRenewPlanProps
  extends IMemberShipPlanFormProps<
    IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[]
  > {
  subscriptionRenewalSuggestionPlans: IOrganizationSubscriptionRenewalSuggestionMembershipPlanModel[];
}

const SubscriptionMembershipRenewPlan = ({
  disabled,
  subscriptionRenewalSuggestionPlans,
  ...props
}: ISubscriptionMembershipRenewPlanProps) => {
  const { t } = useTranslation();

  return (
    <MembershipPlanForm
      {...props}
      disabled={disabled}
      additionalData={subscriptionRenewalSuggestionPlans}
      columns={[
        {
          title: t('grid-columns.previous-capacity'),
          alignment: 'center',
          cellRenderer: cellRenderer('previousCapacity', {
            disabled,
            alignment: 'center',
          }),
        },
        {
          title: t('grid-columns.to-be-renewed'),
          alignment: 'center',
          field: 'capacitySuggestion',
          cellRenderer: cellRenderer('capacitySuggestion', {
            alignment: 'center',
          }),
        },
      ]}
    />
  );
};

export default SubscriptionMembershipRenewPlan;
