import { AnySchema } from 'ajv';
import { t } from 'i18next';

import { IAddress } from '../../../models/Address';
import { FieldErrorMessage } from '../../../models/FieldErrorMessage';

import { validateFormData } from '../../../utils/validations/validateFormData';
import { FormAddressFields } from './formAddress.models';

export const addressSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [FormAddressFields.AddressLine1]: {
      type: 'string',
      maxLength: 50,
    },
    [FormAddressFields.AddressLine2]: {
      type: 'string',
      maxLength: 50,
    },
    [FormAddressFields.ZipCode]: {
      type: 'string',
      maxLength: 10,
      errorMessage: t('validation.invalid-zip-code'),
    },
    [FormAddressFields.City]: {
      type: 'string',
      maxLength: 50,
    },
    [FormAddressFields.Country]: {
      type: 'string',
      maxLength: 50,
    },
    [FormAddressFields.StateRegion]: {
      type: 'string',
      maxLength: 50,
    },
  },

  required: [
    FormAddressFields.AddressLine1,
    FormAddressFields.ZipCode,
    FormAddressFields.City,
    FormAddressFields.StateRegion,
    FormAddressFields.Country,
  ],
};

export const validateAddressForm = (
  data: IAddress,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, addressSchema);
