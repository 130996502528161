import Spinner from '../../Spinner/Spinner';

import { ButtonProps } from './Button.types';
import { ButtonWrapper } from './Button.style';
import { withPermissions } from '../../../hoc/withPermissions';
import { getSpinnerMode } from './button.utils';
import MainTooltip from '../MainTooltip/MainTooltip';

const Button = ({
  children,
  className,
  disabled,
  isLoading = false,
  onClick,
  styleType = 'standard',
  type = 'button',
  tooltip,
}: ButtonProps) => (
  <MainTooltip title={tooltip}>
    <ButtonWrapper
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      styleType={styleType}
      className={className}
      withTooltip={!!tooltip}
    >
      {isLoading ? <Spinner mode={getSpinnerMode(styleType)} /> : children}
    </ButtonWrapper>
  </MainTooltip>
);

export default withPermissions(Button);
