import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import ModalFormPage from '../../../../../styledComponents/ModalFormPage/ModalFormPage';
import CreditCardDetailsForm from '../../../../../styledComponents/Forms/CreditCardDetailsForm/CreditCardDetailsForm';
import { useAddCreditCardModalForm } from './addCreditCardModalForm.hooks';
import { withStripeElements } from '../../../../../styledComponents/Stripe/withStripeElements';

type AddCreditCardModalFormProps = {
  onAddedCreditCard?: () => void;
};

const AddCreditCardModalForm = ({
  onAddedCreditCard,
}: AddCreditCardModalFormProps) => {
  const { t } = useTranslation();
  const { onCancelHandler, submitHandler, creditCardAdding, validateErrors } =
    useAddCreditCardModalForm({
      onAddedCreditCard,
    });

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={submitHandler}
      validate={validateErrors}
    >
      {({ ...renderProps }) => (
        <ModalFormPage
          handleSubmit={renderProps.handleSubmit}
          onCancel={onCancelHandler}
          title={t('headers.add-credit-card')}
          isProcessing={creditCardAdding}
        >
          <CreditCardDetailsForm isModal disabled={creditCardAdding} />
        </ModalFormPage>
      )}
    </Form>
  );
};

export default withStripeElements(AddCreditCardModalForm);
