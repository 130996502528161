import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { PromoCodeType } from '../../../models/enums/PromoCodeType';

import FormErrorActivator from '../../../styledComponents/FormErrorProvider/FormErrorActivator/FormErrorActivator';

import Spinner from '../../../styledComponents/Spinner/Spinner';
import { focusOnErrorDecorator } from '../../../utils/focusOnErrorDecorator';
import AddPromoCodeCommissionForm from '../AddPromoCodeForms/AddPromoCodeCommissionForm/AddPromoCodeCommissionForm';
import AddPromoCodeDetailsForm from '../AddPromoCodeForms/AddPromoCodeDetailsForm/AddPromoCodeDetailsForm';
import { AddPromoCodePricingForm } from '../AddPromoCodeForms/AddPromoCodePricingForm/AddPromoCodePricingForm';
import { useAddPromoCodeForm } from './addPromoCodeForm.hooks';
import { PageRoot, StyledSubmitButton } from './addPromoCodeForm.styles';

const AddPromoCodeForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    validateData,
    promoCodeAdding,
    dataLoading,
    promoCodeSuggestion,
  } = useAddPromoCodeForm();
  return (
    <Form
      initialValues={{ ...promoCodeSuggestion }}
      decorators={[focusOnErrorDecorator]}
      keepDirtyOnReinitialize
      onSubmit={handleSubmit}
      validate={validateData}
    >
      {({ values, ...renderProps }) => (
        <Spinner isVisible={dataLoading} size="10%">
          <FormErrorActivator>
            <form onSubmit={renderProps.handleSubmit}>
              <PageRoot>
                <AddPromoCodeDetailsForm isDisabled={promoCodeAdding} />
                {values.type === PromoCodeType.Agent && (
                  <AddPromoCodeCommissionForm />
                )}
                <AddPromoCodePricingForm isDisabled={promoCodeAdding} />
                <StyledSubmitButton isLoading={promoCodeAdding} type="submit">
                  {t('buttons.save')}
                </StyledSubmitButton>
              </PageRoot>
            </form>
          </FormErrorActivator>
        </Spinner>
      )}
    </Form>
  );
};

export default AddPromoCodeForm;
