import { useTranslation } from 'react-i18next';

import { SOMETHING_WENT_WRONG_MODAL_NAME } from './somethingWentWrongModal.const';
import { SmallModal } from '../../ModalCommon/ModalCommon';
import {
  InformationText,
  StyledButton,
  StyledStandardText,
  Title,
  Wrapper,
  Image,
} from './somethingWentWrongModal.styles';
import config from '../../../config/config';
import { refreshPage } from '../../../utils/refreshPage';
import ErrorImage from '../../../assets/images/500.png';

const SomethingWentWrongModal = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SmallModal name={SOMETHING_WENT_WRONG_MODAL_NAME}>
        <Wrapper>
          <Image src={ErrorImage} alt="error" />
          <Title>{t('headers.something-went-wrong')}</Title>
          <StyledStandardText>
            {t('phrase.working-on-fix-problem')}
          </StyledStandardText>
          <StyledStandardText>{t('phrase.try-again')}</StyledStandardText>
          <StyledButton styleType="lighter-border" onClick={refreshPage}>
            {t('buttons.refresh-page')}
          </StyledButton>
          <InformationText>{t('phrase.refresh-page-erase')}</InformationText>
          <StyledStandardText>
            {t('phrase.need-help-call-us', {
              phone: config.contact.phone,
            })}
          </StyledStandardText>
        </Wrapper>
      </SmallModal>
    </div>
  );
};

export default SomethingWentWrongModal;
