import styled from '@emotion/styled/macro';

import MaterialTable from '../../../../../../styledComponents/MaterialTable/MaterialTable';
import { StyledTableCell } from '../../../../../../styledComponents/MaterialTable/TableContent/tableContent.styles';

export const StyledMaterialTable = styled(MaterialTable)`
  ${StyledTableCell} {
    width: 9rem;
  }
`;
