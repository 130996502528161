import { useTranslation } from 'react-i18next';

import Spinner from '../../styledComponents/Spinner/Spinner';
import {
  LeftContainer,
  RightContainer,
  PageOverviewRootColumns,
} from '../../styledComponents/Page/Page';
import HeaderPageDetails from '../../styledComponents/PageDetails/HeaderPageDetails/HeaderPageDetails';
import { SectionTitle } from '../../styledComponents/Typography/Typography';
import PromoCodeItemStatus from '../../styledComponents/UI/PromoCodeStatus/PromoCodeStatus';
import { usePromoCodeOverview } from './promoCodeOverview.hooks';
import PromoCodeOverviewDetails from './PromoCodeOverviewDetailsCard/PromoCodeOverviewDetailsCard';
import PromoCodeOverviewTable from './PromoCodeOverviewTable/PromoCodeOverviewTable';
import DeletePromoCodeModal from './Modals/DeletePromoCodeModal/DeletePromoCodeModal';
import { OverviewFullTabPage } from '../../styledComponents/OverviewPage/OverviewPage';
import EditPromoCodeDatesModal from './Modals/EditPromoCodeDatesModal/EditPromoCodeDatesModal';

const PromoCodeOverviewPage = () => {
  const { t } = useTranslation();
  const {
    promoCodeOverviewData,
    dataLoading,
    changePromoCodeStatusHandler,
    deletePromoCode,
    canBeDeleted,
    editPromoCodeDatesHandler,
    loadPromoCodeHandler,
    onClickOwnerNameHandler,
  } = usePromoCodeOverview();

  if (!promoCodeOverviewData) {
    return <Spinner size="20%" />;
  }

  return (
    <>
      <DeletePromoCodeModal />
      <EditPromoCodeDatesModal onPromoCodeDatesEdited={loadPromoCodeHandler} />
      <Spinner size="30%" isVisible={dataLoading}>
        <HeaderPageDetails
          title={promoCodeOverviewData?.name}
          statusComponent={
            <PromoCodeItemStatus status={promoCodeOverviewData?.status} />
          }
        />
        <OverviewFullTabPage>
          <PageOverviewRootColumns>
            <LeftContainer>
              <SectionTitle>
                {t('headers.retail-pricing', {
                  type: promoCodeOverviewData?.paidByType,
                })}
              </SectionTitle>
              <PromoCodeOverviewTable
                data={promoCodeOverviewData?.pricingMatrix}
              />
            </LeftContainer>
            <RightContainer>
              <PromoCodeOverviewDetails
                onChangeStatus={changePromoCodeStatusHandler}
                data={promoCodeOverviewData}
                onDeleteClick={deletePromoCode}
                canBeDeleted={canBeDeleted}
                onEditDates={editPromoCodeDatesHandler}
                onOwnerNameClick={onClickOwnerNameHandler}
              />
            </RightContainer>
          </PageOverviewRootColumns>
        </OverviewFullTabPage>
      </Spinner>
    </>
  );
};

export default PromoCodeOverviewPage;
