import styled from '@emotion/styled';
import { transparentize } from 'polished';

import TableCell from '../../../../styledComponents/MaterialTable/CellRenderers/TableCell/TableCell';

import { PageSection } from '../../../../styledComponents/Page/Page';

export const TableContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.light1};
`;

export const AdminCell = styled.div`
  background-color: ${({ theme }) => transparentize(0.6, theme.color.primary)};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.color.light1};
  font-size: 0.75rem;
  line-height: 1rem;
  justify-content: center;
  padding: 0.25rem;
  font-weight: bold;
  width: 3rem;
  float: left;
`;

export const StyledPageSection = styled(PageSection)`
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`;

export const StyledTableCell = styled(TableCell)`
  width: 1.25rem;
`;
