import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import TableCell from '../TableCell/TableCell';

type WrapperType = {
  disabled?: boolean;
  bold?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
`;

export const TitleWrapper = styled.div<WrapperType>`
  padding: 0.25rem;
`;

export const LongText = styled.div<WrapperType>`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  color: ${({ theme }) => theme.color.dark1};
  ${({ disabled, bold, theme }) => css`
    ${disabled &&
    css`
      color: ${theme.color.dark2};
    `}

    ${bold &&
    css`
      font-weight: bold;
    `}
  `}
`;

export const Text = styled.div`
  margin-left: 0.5rem;
  font-size: 0.825rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.color.dark2};
`;

export const StyledTableCell = styled(TableCell)`
  width: auto;
  gap: 0rem;
`;
