import ProductSubscriptionOverviewAdditionalInformation from './AASubscriptionOverviewInformation/AASubscriptionOverviewInformation';
import ProductSubscriptionOverviewPlansTable from './AASubscriptionOverviewPlansTable/AASubscriptionOverviewPlansTable';
import ProductSubscriptionOverviewPromoCode from './AASubscriptionOverviewPromoCode/AASubscriptionOverviewPromoCode';
import ContractModal from '../../../../../styledComponents/Modals/AddContractModal/AddContractModal';
import ProductSubscriptionEditDetailsModal from './Modals/SubscriptionEditDetailsModal/SubscriptionEditDetailsModal';
import {
  useProductSubscriptionOverviewTabPage,
  useSubscriptionOverviewActions,
} from './aASubscriptionOverview.hooks';

import {
  LeftContainer,
  RightContainer,
} from '../../../../../styledComponents/Page/Page';
import ProductSubscriptionOverviewTemplatePage from '../ProductSubscriptionOverviewTemplatePage/ProductSubscriptionOverviewTemplatePage';
import DeleteProductSubscriptionModal from './Modals/DeleteSubscriptionModal/DeleteSubscriptionModal';
import { canViewAAMembersPermissions } from '../../../../../config/accessPolicies/accessPolicies';
import { aaSubscriptionOverviewPermissions } from './aASubscriptionOverview.const';
import EditRenewalSubscriptionStatusModal from '../../../../../styledComponents/Modals/EditRenewalSubscriptionStatusModal/EditRenewalSubscriptionStatusModal';

const AASubscriptionOverview = () => {
  const {
    subscriptionOverview,
    subscriptionOverviewLoading,
    loadSubscriptionOverviewHandler,
  } = useProductSubscriptionOverviewTabPage();

  const {
    addMemberHandler,
    deleteProductSubscriptionHandler,
    extendProductSubscriptionHandler,
    onRowClickHandler,
    showEditSubscriptionDetailsModal,
    onSeeOversubscribedMembersHandler,
    editSubscriptionHandler,
    editRenewalStatusHandler,
  } = useSubscriptionOverviewActions();

  return (
    <>
      <ProductSubscriptionEditDetailsModal
        onModalSuccess={loadSubscriptionOverviewHandler}
      />
      <ContractModal
        onContractAdded={showEditSubscriptionDetailsModal}
        onModalCancel={showEditSubscriptionDetailsModal}
      />
      <EditRenewalSubscriptionStatusModal
        onRenewalStatusEdited={loadSubscriptionOverviewHandler}
      />
      <DeleteProductSubscriptionModal />
      <ProductSubscriptionOverviewTemplatePage
        canAddMember={subscriptionOverview?.metadata?.canAddMember}
        canExtend={subscriptionOverview?.metadata?.canAddExtension}
        canDelete={subscriptionOverview?.metadata?.canDelete}
        onDeleteProductSubscription={deleteProductSubscriptionHandler}
        onAddMember={addMemberHandler}
        onExtendProductSubscription={extendProductSubscriptionHandler}
        onEditSubscription={editSubscriptionHandler}
        isLoading={subscriptionOverviewLoading}
        canEditSubscription={subscriptionOverview?.metadata?.canEdit}
        permissions={aaSubscriptionOverviewPermissions}
      >
        <LeftContainer>
          <ProductSubscriptionOverviewPlansTable
            subscriptionOverview={subscriptionOverview}
            onRowClick={onRowClickHandler}
            onSummaryRowClick={onRowClickHandler}
            onSeeOversubscriptionClick={onSeeOversubscribedMembersHandler}
            onSummarySeeOversubscriptionClick={
              onSeeOversubscribedMembersHandler
            }
            canClickRowPermissions={canViewAAMembersPermissions}
          />
        </LeftContainer>
        <RightContainer>
          <ProductSubscriptionOverviewPromoCode
            promoCode={subscriptionOverview && subscriptionOverview.promoCode}
          />
          <ProductSubscriptionOverviewAdditionalInformation
            onEditOptionsClick={
              subscriptionOverview?.metadata?.canEditOptions &&
              showEditSubscriptionDetailsModal
            }
            subscriptionOverview={subscriptionOverview}
            canEditOptions={!!subscriptionOverview?.metadata?.canEditOptions}
            canRenew={!!subscriptionOverview?.metadata?.canRenew}
            onEditRenewalStatus={editRenewalStatusHandler}
          />
        </RightContainer>
      </ProductSubscriptionOverviewTemplatePage>
    </>
  );
};

export default AASubscriptionOverview;
