import { useTranslation } from 'react-i18next';

import { CanExportContractsRenewalsPermissions } from '../../../../config/accessPolicies/accessPolicies';

import { IRenewalsFiltersModel } from '../../../../models/interfaces/RenewalFilters/RenewalsFiltersModel';

import RenewalFilters from '../../Common/RenewalsFilters/RenewalFilters';
import { useRenewalsContractsFilters } from './renewalsContractsFilters.hooks';
import {
  fieldsList,
  staticFilters,
  topFilters,
} from './renewalsContractsFilters.utils';

type RenewalsContractsFiltersProps = {
  onFilterChanged?: (filters: IRenewalsFiltersModel) => void;
  onExportClick?: (filters: IRenewalsFiltersModel) => void;
  isExporting?: boolean;
};

const RenewalsContractsFilters = ({
  onFilterChanged,
  onExportClick,
  isExporting,
}: RenewalsContractsFiltersProps) => {
  const { t } = useTranslation();

  const { handleFilterChanged, handleExportClick } =
    useRenewalsContractsFilters(onFilterChanged, onExportClick);

  return (
    <RenewalFilters
      filtersListLabel={t('phrase.show-all-contracts-which')}
      filtersDefinitions={fieldsList}
      staticFilters={staticFilters}
      topFilters={topFilters}
      onFilterChanged={handleFilterChanged}
      onExportClick={handleExportClick}
      isExporting={isExporting}
      exportPermissions={CanExportContractsRenewalsPermissions}
    />
  );
};

export default RenewalsContractsFilters;
