import { IPersonMembershipItem } from '../../../../models/PersonMembershipItem';
import { ProductType } from '../../../../models/ProductType';
import AAMembershipExpiredSummary from '../../AAMemberOverview/AAMembershipExpiredSummary/AAMembershipExpiredSummary';
import DOCMembershipExpiredSummary from '../../DOCMemberOverview/DOCMembershipExpiredSummary/DOCMembershipExpiredSummary';
import KEMembershipExpiredSummary from '../../KEMemberOverview/KEMembershipExpiredSummary/KEMembershipExpiredSummary';
import SRMembershipExpiredSummary from '../../SRMemberOverview/SRMembershipExpiredSummary/SRMembershipExpiredSummary';

export interface IMemberOverviewExpiredMemberSummaryProps {
  data: IPersonMembershipItem;
  productType?: ProductType;
}

export const MemberOverviewExpiredMemberSummaryByProductType: Partial<
  Record<
    ProductType,
    (props: IMemberOverviewExpiredMemberSummaryProps) => JSX.Element
  >
> = {
  [ProductType.AirAmbulance]: AAMembershipExpiredSummary,
  [ProductType.DutyOfCare]: DOCMembershipExpiredSummary,
  [ProductType.KidnapAndExtortion]: KEMembershipExpiredSummary,
  [ProductType.SecurityResponse]: SRMembershipExpiredSummary,
  [ProductType.TravelLicense]: null,
};
