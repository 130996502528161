import { ChangeEvent } from 'react';

import { ToggleCheckbox, ToggleLabel } from './toggleSwitch.styles';

type Props = {
  onLabel?: string;
  offLabel?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
};

export const ToggleSwitch = ({
  onLabel = 'Yes',
  offLabel = 'No',
  name,
  onChange,
  isChecked,
}: Props) => (
  <ToggleLabel labelOn={onLabel} labelOff={offLabel} isChecked={isChecked}>
    <ToggleCheckbox
      name={name}
      onChange={onChange}
      checked={isChecked}
      type="checkbox"
    />
  </ToggleLabel>
);
