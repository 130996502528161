import { ReactNode } from 'react';

import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { CustomComponent } from '../../../models/types/CustomComponent';
import { ButtonsContainer, CardListItemWrapper } from './cardListItem.styles';

interface ICardListItemProps extends CustomComponent {
  onClick?: () => void;
  buttonsContainerComponent?: ReactNode;
}

const CardListItem = ({
  className,
  children,
  onClick,
  buttonsContainerComponent,
}: ICardListItemProps) => (
  <CardListItemWrapper
    className={className}
    isClickable={!!onClick}
    onClick={onClick}
  >
    {children}
    <ButtonsContainer>
      {buttonsContainerComponent}
      <ArrowRight />
    </ButtonsContainer>
  </CardListItemWrapper>
);

export default CardListItem;
