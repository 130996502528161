/* eslint-disable react/destructuring-assignment */
import { t } from 'i18next';

import { IOrganizationSubscriptionOverviewMembershipPlan } from '../../../../models/interfaces/Subscription/OrganizationSubscriptionOverviewMembershipPlan';
import Button from '../../../UI/Button/Button';
import TableCell from '../TableCell/TableCell';

export const overSubscribedRenderer =
  (
    action?: (data: IOrganizationSubscriptionOverviewMembershipPlan) => void,
    permissions?: string[],
  ) =>
  (data: IOrganizationSubscriptionOverviewMembershipPlan) => {
    if (!data.isOversubscribed) {
      return null;
    }
    return (
      <TableCell alignment="right">
        <Button
          permissions={permissions}
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
            action?.(data);
          }}
          styleType="link"
        >
          {`${t('buttons.see-oversubscribed')}`}
        </Button>
      </TableCell>
    );
  };
