import { CustomComponent } from '../../../models/types/CustomComponent';
import CopyToClipboardButton from '../../CopyToClipboardButton/CopyToClipboardButton';
import { usePromoCodeValue } from './promoCodeValue.hooks';
import { PromoCodeText, PromoCodeWrapper } from './promoCodeValue.styles';

type PromoCodeValueProps = CustomComponent & {
  promoCode?: string;
  promoCodeId?: string;
};

const PromoCodeValue = ({ promoCode, promoCodeId }: PromoCodeValueProps) => {
  const { onClickHandler } = usePromoCodeValue(promoCodeId);

  if (!promoCode) {
    return <PromoCodeWrapper>-</PromoCodeWrapper>;
  }
  return (
    <PromoCodeWrapper>
      <PromoCodeText
        onClick={promoCodeId && onClickHandler}
        isClickable={!!promoCodeId}
      >
        {promoCode}
      </PromoCodeText>
      <CopyToClipboardButton value={promoCode} />
    </PromoCodeWrapper>
  );
};

export default PromoCodeValue;
