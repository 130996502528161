import styled from '@emotion/styled/macro';

export const FilterListItemWrapper = styled.div`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.color.hover};
  display: flex;
  gap: 1.125rem;
  border-radius: 0.5rem;
`;

export const Label = styled.p`
  font: normal normal 0.875rem/1.5rem ${({ theme }) => theme.font.gotham};
  white-space: nowrap;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
`;
