import {
  SystemGroupPermissions,
  ReportsPermissions,
} from '../../../models/enums/UserPermissions';
import { getGroupPermission } from '../../../utils/permissionsHelpers';

export const canGenerateMembershipRetailFinanceReport: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Reports,
    ReportsPermissions.CanGenerateMembershipRetailFinanceReport,
  ),
];

export const canGenerateSubscriptionFinanceReport: string[] = [
  getGroupPermission(
    SystemGroupPermissions.Reports,
    ReportsPermissions.CanGenerateSubscriptionFinanceReport,
  ),
];
