import { useTranslation } from 'react-i18next';

import { IOrganizationContractDetailsModel } from '../../../../../models/interfaces/OrganizationContractDetailsModel';

import {
  Detail,
  DetailLabel,
  DetailsContainer,
  DetailValue,
} from '../../../../../styledComponents/Overview/OverviewDetails/OverviewDetails';

import { SectionSubTitle } from '../../../../../styledComponents/Typography/Typography';
import { Card } from '../../../../../styledComponents/UI/Card/Card';
import Price from '../../../../../styledComponents/UI/Price/Price';
import { Status } from '../../../../../styledComponents/UI/Status/Status';
import { formatDate } from '../../../../../utils/dateHelpers';
import { isPropertyEmpty } from '../../../../../utils/propertyHelpers';
import { DetailBreak } from '../../../../PromoCodeOverviewPage/PromoCodeOverviewDetailsCard/PromoCodeOverviewDetails/promoCodeOverviewDetails.styles';
import { DetailsLine } from './contractOverviewDetails.styles';
import { EditableStatus } from '../../../../../styledComponents/UI/EditableStatus/EditableStatus';

interface IContractOverviewDetailsProps {
  data?: IOrganizationContractDetailsModel;
  onEditRenewalStatus: () => void;
}

const ContractOverviewDetails = ({
  data,
  onEditRenewalStatus,
}: IContractOverviewDetailsProps) => {
  const { t } = useTranslation();
  return (
    <Card>
      <SectionSubTitle>{t('headers.details')}</SectionSubTitle>
      <DetailsContainer>
        <Detail>
          <DetailLabel>{`${t('properties.type')}:`}</DetailLabel>
          <DetailValue>
            {t(`enums.contract-type.${data?.contractType}`)}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.total')}:`} </DetailLabel>
          <DetailValue>
            <Price price={data?.amount} />
          </DetailValue>
        </Detail>
        <DetailBreak />
        <Detail>
          <DetailLabel>{`${t('properties.status')}:`} </DetailLabel>
          <DetailValue>
            <Status
              enumName="organization-contract-status"
              status={data?.status}
            />
          </DetailValue>
        </Detail>
      </DetailsContainer>
      <DetailsLine />
      <DetailsContainer>
        <Detail>
          <DetailLabel>{`${t('properties.startDate')}:`} </DetailLabel>
          <DetailValue>{formatDate(data?.startDate)}</DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.expirationDate')}:`} </DetailLabel>
          <DetailValue>{formatDate(data?.expirationDate)}</DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.contractLength')}:`} </DetailLabel>
          <DetailValue>
            {!isPropertyEmpty(data?.lengthYears) &&
              `${data?.lengthYears} ${t('properties.years').toLowerCase()}`}
          </DetailValue>
        </Detail>
        <Detail>
          <DetailLabel>{`${t('properties.multiyearcontract')}:`} </DetailLabel>
          <DetailValue>
            {data?.isMultiYearContract
              ? t(`enums.yes-no.Yes`).toString()
              : t(`enums.yes-no.No`).toString()}
          </DetailValue>
        </Detail>
        <DetailBreak />
        <Detail>
          <DetailLabel>{`${t('properties.renewalStatus')}:`}</DetailLabel>
          <EditableStatus
            enumName="renewal-status"
            status={data?.renewalStatus}
            onClick={onEditRenewalStatus}
          />
        </Detail>
      </DetailsContainer>
      <DetailsLine />
    </Card>
  );
};

export default ContractOverviewDetails;
