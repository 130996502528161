export const formName = 'membership-details-form';

export enum MembershipOrganizationDetailsFormFields {
  PromoCode = 'promoCode',
  SearchedPromoCode = 'searchedPromoCode',
  Organization = 'organization',
  SubscriptionType = 'subscriptionType',
  MembershipPlanId = 'membershipPlanId',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
}
