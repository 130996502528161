import { CustomComponent } from '../../../../models/types/CustomComponent';
import { SelectOption } from '../../../UI/MainSelect/mainSelect.types';
import { ICellRenderer } from '../cellRenderers.models';
import { StyledMainSelect, StyledTableCell } from './selectRenderer.styles';

export type SelectRendererProps<T> = CustomComponent &
  ICellRenderer & {
    status?: boolean;
    onChange?: (value: T) => void;
    selectOptions: SelectOption | SelectOption[];
    currentValue: T;
  };

export const SelectRenderer = <T,>({
  options,
  onChange,
  selectOptions,
  currentValue,
  disabled,
}: SelectRendererProps<T>) => (
  <StyledTableCell {...options}>
    <StyledMainSelect
      isDisabled={disabled}
      onChange={(option: any) => onChange(option.value)}
      options={selectOptions}
      currentValue={currentValue}
    />
  </StyledTableCell>
);
