import { useEffect } from 'react';

import { useActions } from '../../hooks/useActions';
import { useRouteParams } from '../../hooks/useRouteParams';
import { useCancellationToken } from '../../hooks/useTokenCancellation';
import { AddPromoCodeAvailableSearchParamsType } from '../../models/types/RoutePath';
import { clearAAMembershipPlansPrices } from '../../store/redux-slices/pricing/airAmbulance';
import { clearPromoCodeSuggestion } from '../../store/redux-slices/promoCodes';
import { aaLoadStandardPricing } from '../../store/saga-slices/pricing/airAmbulance';
import { loadPromoCodeSuggestion } from '../../store/saga-slices/promoCodes';
import { ILoadPromoCodeSuggestion } from '../../store/saga-slices/promoCodes/models';
import { isPropertyEmpty } from '../../utils/propertyHelpers';

export const useAddPromoCodePage = () => {
  const cancellationToken = useCancellationToken();

  const LoadStandardPricing = useActions(aaLoadStandardPricing);
  const LoadPromoCodeSuggestion = useActions(loadPromoCodeSuggestion);
  const ClearPromoCodeSuggestion = useActions(clearPromoCodeSuggestion);
  const ClearMembershipPlansPrices = useActions(clearAAMembershipPlansPrices);

  const { organizationId } =
    useRouteParams<AddPromoCodeAvailableSearchParamsType>();

  useEffect(() => {
    LoadStandardPricing();
  }, [LoadStandardPricing]);

  useEffect(() => {
    if (isPropertyEmpty(organizationId)) {
      return;
    }
    const payload: ILoadPromoCodeSuggestion = {
      organizationId,
      cancellationToken,
    };
    LoadPromoCodeSuggestion(payload);
  }, [LoadPromoCodeSuggestion, cancellationToken, organizationId]);

  useEffect(
    () => () => {
      ClearPromoCodeSuggestion();
      ClearMembershipPlansPrices();
    },
    [ClearPromoCodeSuggestion, ClearMembershipPlansPrices],
  );
};
