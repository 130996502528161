import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { CreateMembershipModel } from '../../../../models/types/CreateMembershipModel';
import { IMembershipDates } from '../../../../models/MembershipDates';
import { IAddMembershipSummaryModel } from '../../../../models/interfaces/Membership/AddMembershipSummaryModel';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { ICreateSecondaryMemberModel } from '../../../../models/interfaces/Membership/ICreateSecondaryMemberModel';
import {
  IResendWelcomeEmailData,
  IResendWelcomeEmailModel,
} from '../../../../models/interfaces/ResendWelcomeEmailModel';
import { MEMBERSHIP_ACTIVE_RATIFICATION_PARAM } from './const';
import { IPageListModel } from '../../../../models/PageListModel';
import { IDOCMembershipModel } from '../../../../models/interfaces/Membership/DutyOfCare/DOCMembershipModel';
import { IUpdateDOCMembershipOptionsModel } from '../../../../models/interfaces/Membership/DutyOfCare/UpdateDOCMembershipOptionsModel';
import { IDOCMemberListItem } from '../../../../models/interfaces/Membership/DutyOfCare/IDOCMemberListItem';

export const createDOCOrganizationMembership = (
  organizationId: string,
  subscriptionId: string,
  data: CreateMembershipModel,
): Promise<AxiosResponse<string>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/memberships`,
    data,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const analyzeDOCCreateMembership = (
  data: CreateMembershipModel,
): Promise<AxiosResponse> =>
  axios.post(`api/memberships/newmembershipanalysis`, data, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });

export const getDOCMembershipSummary = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/membershipadd/summary`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const getDatesForNewDOCMemberships = (
  subscriptionId?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IMembershipDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'subscriptionId',
      value: subscriptionId,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`api/memberships/newmembershipdates${params}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });
};

export const getDOCRetailMembershipPlanSummary = (
  packageId: string,
  effectiveDate: string,
  autoRenewal?: boolean,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'packageId',
      value: packageId,
    },
    {
      name: 'effectiveDate',
      value: effectiveDate,
    },
    {
      name: 'autoRenewal',
      value: autoRenewal,
    },
  ];
  const params = prepareQueryParams({ queryParams });
  return axios.get(`api/memberships/summary${params}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });
};

export const getDOCExistingMembershipSummary = (
  membershipId: string,
): Promise<AxiosResponse<IAddMembershipSummaryModel>> =>
  axios.get(`api/memberships/${membershipId}/summary`, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });

export const getDOCMembershipDetails = (
  membershipId: string,
): Promise<AxiosResponse<IDOCMembershipModel>> =>
  axios.get(`api/memberships/${membershipId}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });

export const putDOCMemberToBeRenewed = (
  membershipId: string,
  memberId: string,
  toBeRenewed: boolean,
): Promise<AxiosResponse> =>
  axios.put(
    `api/memberships/${membershipId}/members/${memberId}`,
    { toBeRenewed },
    {
      baseURL: config.dutyOfCareApi.baseUrl,
    },
  );

export const updateDOCMembershipOptionsRequest = (
  membershipId: string,
  options: IUpdateDOCMembershipOptionsModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IDOCMembershipModel>> =>
  axios.put(`api/memberships/${membershipId}/options`, options, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });

export const addDOCSecondaryMemberRequest = (
  membershipId: string,
  data: ICreateSecondaryMemberModel,
): Promise<AxiosResponse> =>
  axios.post(`api/memberships/${membershipId}/members`, data, {
    baseURL: config.dutyOfCareApi.baseUrl,
  });

export const deleteDOCSecondaryMemberRequest = (
  membershipId: string,
  memberId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(`api/memberships/${membershipId}/secondarymember/${memberId}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });

export const postResendDOCWelcomeEmail = (
  membershipId: string,
  data: IResendWelcomeEmailData,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IResendWelcomeEmailModel>> =>
  axios.post(`api/memberships/${membershipId}/welcomeEmail`, data, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });

export const deleteDOCMembershipRequest = (
  membershipId: string,
  isActive: boolean,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/memberships/${membershipId}${
      isActive ? MEMBERSHIP_ACTIVE_RATIFICATION_PARAM : ''
    }`,
    {
      baseURL: config.dutyOfCareApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getDOCMembershipsMembers = (
  organizationId: string,
  params?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IPageListModel<IDOCMemberListItem>>> => {
  const queryParams: QueryParamItem[] = [
    { name: 'organizationId', value: organizationId },
  ];

  const currentParams = prepareQueryParams({
    initQueryString: params,
    queryParams,
  });

  return axios.get(`api/members${currentParams}`, {
    baseURL: config.dutyOfCareApi.baseUrl,
    signal: cancellationToken,
  });
};
