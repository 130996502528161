import { set } from 'lodash';

import { FieldErrorMessage } from '../../models/FieldErrorMessage';

export const validateFormApiErrors = (errors: FieldErrorMessage[]) => {
  const result = {};
  errors?.forEach((error: any) => {
    set(result, error.field, error.message);
  });
  return result;
};
