import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Radio, { IRadioProps } from '../Radio/Radio';
import { Label } from '../Radio/radio.styles';

import { Layout } from './radioGroup.models';

type WrapperProps = {
  layout?: Layout;
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;

  ${({ layout }) => {
    switch (layout) {
      case 'horizontal':
        return css`
          & > :not(:last-child) {
            margin-right: 2.25rem;
          }
        `;
      case 'vertical':
        return css`
          flex-direction: column;

          & > :not(:last-child) {
            margin-bottom: 2.25rem;
          }
        `;
    }
  }}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}
`;

export const StyledRadio = styled(Radio)<IRadioProps>`
  ${Label} {
    font: 400 0.875rem/1rem ${({ theme }) => theme.font.gotham};
    ${({ checked, theme }) =>
      checked &&
      css`
        color: ${theme.color.black};
      `}
  }
`;
