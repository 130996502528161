/* eslint-disable react/destructuring-assignment */
import { useTranslation } from 'react-i18next';

import { IOrganizationList } from '../../../../models/OrganizationList';
import { ICellOptions } from '../../../../styledComponents/MaterialTable/CellRenderers/cellRenderers.models';
import { PageSection } from '../../../../styledComponents/Page/Page';
import { getOldestDate } from '../../../../utils/dateHelpers';
import { prepareExpiredDateDescription } from './corporateClientGeneralTable.utils';
import MaterialTable from '../../../../styledComponents/MaterialTable/MaterialTable';
import {
  OrganizationNameRenderer,
  ExpiredDateRenderer,
  ProductsOverviewRenderer,
} from '../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { StatusRenderer } from '../../../../styledComponents/MaterialTable/CellRenderers/StatusRenderer/StatusRenderer';

interface ICorporateClientGeneralTableProps {
  data: IOrganizationList[];
  dataLoading?: boolean;
  totalCount?: number;
  onRowClick?: (row: IOrganizationList) => void;
  canViewDetailsPermissions?: string[];
}

const CorporateClientGeneralTable = ({
  data,
  dataLoading,
  onRowClick,
  totalCount,
  canViewDetailsPermissions,
}: ICorporateClientGeneralTableProps) => {
  const { t } = useTranslation();

  const cellRenderer =
    (key: keyof IOrganizationList, options?: ICellOptions) =>
    (dataRow: IOrganizationList) => {
      switch (key) {
        case 'status':
          return <StatusRenderer status={dataRow.status} options={options} />;
        case 'products':
          return (
            <ProductsOverviewRenderer
              data={dataRow.products}
              options={options}
            />
          );
        case 'name':
          return (
            <div>
              <OrganizationNameRenderer
                organizationName={dataRow.name}
                organizationType={dataRow.organizationType}
                options={options}
              />
            </div>
          );
        case 'contractsExpirationDates':
          return (
            <ExpiredDateRenderer
              date={getOldestDate(dataRow[key])}
              description={prepareExpiredDateDescription(dataRow[key])}
              options={options}
            />
          );
      }
    };

  return (
    <PageSection>
      <MaterialTable
        columns={[
          {
            title: t('grid-columns.name'),
            field: 'name',
            cellRenderer: cellRenderer('name', { textBold: true }),
            sortable: true,
          },
          {
            title: t('grid-columns.tenant-group-code'),
            field: 'externalTenantCode',
          },
          {
            title: t('grid-columns.status'),
            field: 'status',
            cellRenderer: cellRenderer('status', { textBold: true }),
          },
          {
            title: t('grid-columns.contract-end-date'),
            field: 'contractsExpirationDates',
            cellRenderer: cellRenderer('contractsExpirationDates', {
              alignment: 'left',
            }),
            alignment: 'left',
          },
          {
            title: t('grid-columns.product-subscription'),
            field: 'products',
            cellRenderer: cellRenderer('products', { alignment: 'right' }),
            alignment: 'right',
          },
        ]}
        data={data}
        dataLoading={dataLoading}
        itemsCount={totalCount}
        onRowClick={onRowClick}
        canClickRowPermissions={canViewDetailsPermissions}
      />
    </PageSection>
  );
};

export default CorporateClientGeneralTable;
