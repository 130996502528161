import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/useActions';
import { useDebounce } from '../../../../hooks/useDebounce';
import { IEditDOCOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/DutyOfCare/EditDOCOrganizationSubscriptionModel';
import { EditProductSubscriptionPageParamsType } from '../../../../models/types/RoutePath';
import { loadDOCSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/dutyOfCare';
import { ILoadDOCSubscriptionSummary } from '../../../../store/saga-slices/subscriptions/dutyOfCare/models';

type SummaryLoaderProps = {
  values: IEditDOCOrganizationSubscriptionModel;
};

const DOCSummaryLoader = ({ values }: SummaryLoaderProps) => {
  const { organizationId } = useParams<EditProductSubscriptionPageParamsType>();

  const LoadSubscriptionSummary = useActions(loadDOCSubscriptionSummary);
  const { debounce } = useDebounce();

  useEffect(() => {
    if (!values?.packageOptions) {
      return;
    }

    const payload: ILoadDOCSubscriptionSummary = {
      data: values?.packageOptions,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [
    LoadSubscriptionSummary,
    debounce,
    organizationId,
    values?.packageOptions,
  ]);

  return <></>;
};

export default DOCSummaryLoader;
