import { AnySchema } from 'ajv';

import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { CustomFilter } from '../renewalFilters.models';
import { CustomFiltersFields, FilterType } from './filterForm.models';

import { dateFilterValidationSchema } from './FilterTypes/DateFilter/dateFilter.validation';
import { selectCategoryFilterValidationSchema } from './FilterTypes/SelectCategoryFilter/selectCategoryFilter.validation';
import { selectFilterValidationSchema } from './FilterTypes/SelectFilter/selectFilter.validation';

export const filterValidationSchema: AnySchema = {
  type: 'object',
  properties: {
    [CustomFiltersFields.Field]: {
      type: 'object',
    },
  },
  allOf: [
    {
      if: {
        properties: {
          filterType: {
            enum: [FilterType.Date],
          },
        },
      },
      then: {
        ...dateFilterValidationSchema,
      },
    },
    {
      if: {
        properties: {
          filterType: {
            enum: [FilterType.Select],
          },
        },
      },
      then: {
        ...selectFilterValidationSchema,
      },
    },
    {
      if: {
        properties: {
          filterType: {
            enum: [FilterType.SelectCategory],
          },
        },
      },
      then: {
        ...selectCategoryFilterValidationSchema,
      },
    },
  ],
  required: [CustomFiltersFields.Field],
};

export const validateFilter = (data: CustomFilter) =>
  validateFormData(
    { ...data, filterType: data?.field?.filterType },
    null,
    filterValidationSchema,
  );
