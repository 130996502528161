import { useTranslation } from 'react-i18next';

import { Status } from '../../../../models/enums/Status';

import ConfirmModal from '../../../../styledComponents/ConfirmModal/ConfirmModal';
import StatusMessageWithIcon from '../../../../styledComponents/StatusMessageWithIcon/StatusMessageWithIcon';
import { Description, Name } from '../Common/memberOverviewModals.styles';
import { DELETE_PERSON_MODAL_NAME } from './deletePersonModal.const';
import { useDeletePersonModal } from './deletePersonModal.hooks';

const DeletePersonModal = () => {
  const { t } = useTranslation();
  const {
    onBackHandler,
    personName,
    onDeleteHandler,
    shouldShowWarningMessage,
  } = useDeletePersonModal();

  return (
    <ConfirmModal
      buttons={[
        {
          action: onBackHandler,
          type: 'back',
          styleType: 'light1',
          disabled: false,
        },
        {
          action: onDeleteHandler,
          type: 'delete',
          styleType: 'background-delete',
          isProcessing: false,
        },
      ]}
      name={DELETE_PERSON_MODAL_NAME}
      disabled={false}
      title={t('headers.remove-person')}
    >
      <Description>{t('phrase.confirm-delete-person')}</Description>
      <Name>{personName}</Name>
      {shouldShowWarningMessage && (
        <StatusMessageWithIcon
          status={Status.WARNING}
          message={t('phrase.delete-admin-warning-message')}
        />
      )}
    </ConfirmModal>
  );
};

export default DeletePersonModal;
