import { useCallback, MouseEvent } from 'react';

import { useNavigateSearch } from '../../hooks/useNavigateSearch';
import {
  ClientOverviewGeneralPageParams,
  RoutePath,
} from '../../models/enums/RoutePath';

export const useMembershipRelation = (organizationId?: string) => {
  const navigate = useNavigateSearch();

  const onClickHandler = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      navigate(RoutePath.ClientOverviewGeneralPageFullPath, {
        params: {
          [ClientOverviewGeneralPageParams.OrganizationId]: organizationId,
        },
      });
    },
    [navigate, organizationId],
  );

  return { onClickHandler };
};
