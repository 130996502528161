import axios, { AxiosResponse } from 'axios';

import { ICreateOrganizationContractModel } from '../../../models/CreateOrganizationContractModel';
import { IContractListItem } from '../../../models/interfaces/ContractListItem';
import { IOrganizationContractDetailsModel } from '../../../models/interfaces/OrganizationContractDetailsModel';
import { IUpdateOrganizationContractModel } from '../../../models/interfaces/UpdateOrganizationContractModel';
import { IOrganizationContractModel } from '../../../models/OrganizationContractModel';
import { prepareQueryParams } from '../../../utils/validations/queryParamsHelpers';
import {
  IDeleteOrganizationContractResponse,
  IOrganizationContractsEditableFieldsResponse,
} from './models';
import { RenewalStatus } from '../../../models/enums/RenewalStatus';

interface IOrganizationContractsResponse {
  contracts: IOrganizationContractModel[];
}
interface IOrganizationContractsListResponse {
  contracts: IContractListItem[];
}

export const getOrganizationContracts = (
  organizationId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractsResponse>> =>
  axios.get(`/api/organizations/${organizationId}/contracts`, {
    signal: cancellationToken,
  });

export const getOrganizationContractsList = (
  organizationId: string,
  params?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractsListResponse>> => {
  const currentParams = prepareQueryParams({
    initQueryString: params,
  });
  return axios.get(
    `/api/organizations/${organizationId}/contracts-list${currentParams}`,
    {
      signal: cancellationToken,
    },
  );
};

export const createContract = (
  organizationId: string,
  contract: ICreateOrganizationContractModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractModel>> =>
  axios.post(`/api/organizations/${organizationId}/contracts`, contract, {
    signal: cancellationToken,
  });

export const updateContract = (
  organizationId: string,
  contractId: string,
  contract: IUpdateOrganizationContractModel,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse> =>
  axios.put(
    `/api/organizations/${organizationId}/contracts/${contractId}`,
    contract,
    {
      signal: cancellationToken,
    },
  );

export const addContractNotes = (
  organizationId: string,
  contractId: string,
  notes: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractModel>> =>
  axios.post(
    `/api/organizations/${organizationId}/contracts/${contractId}`,
    notes,
    {
      signal: cancellationToken,
    },
  );

export const editContractNotes = (
  organizationId: string,
  contractId: string,
  notes: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<string>> =>
  axios.put(
    `/api/organizations/${organizationId}/contracts/${contractId}/notes`,
    { notes },
    {
      signal: cancellationToken,
    },
  );

export const editContractRenewalStatusRequest = (
  organizationId: string,
  contractId: string,
  newRenewalStatus: RenewalStatus,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.put(
    `/api/organizations/${organizationId}/contracts/${contractId}/renewalstatus`,
    { newRenewalStatus },
    {
      signal: cancellationToken,
    },
  );

export const deleteContract = (
  organizationId: string,
  contractId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IDeleteOrganizationContractResponse>> =>
  axios.delete(`/api/organizations/${organizationId}/contracts/${contractId}`, {
    signal: cancellationToken,
  });

export const getContract = (
  organizationId: string,
  contractId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractDetailsModel>> =>
  axios.get(`/api/organizations/${organizationId}/contracts/${contractId}`, {
    signal: cancellationToken,
  });

export const getEditableFields = (
  organizationId: string,
  contractId: string,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<IOrganizationContractsEditableFieldsResponse>> =>
  axios.get(
    `/api/organizations/${organizationId}/contracts/${contractId}/editable-fields`,
    {
      signal: cancellationToken,
    },
  );
