import styled from '@emotion/styled/macro';

import {
  NameRenderer,
  ProductsRenderer,
} from '../../../../../styledComponents/MaterialTable/CellRenderers/CellRenderers';
import { SingleItemWithNumberRenderer } from '../../../../../styledComponents/MaterialTable/CellRenderers/SingleItemWithNumberRenderer/SingleItemWithNumberRenderer';

export const StyledNameRenderer = styled(NameRenderer)`
  width: 16rem;
  @media (min-width: 2100px) {
    width: 20rem;
  }
  @media (min-width: 2250px) {
    width: 24rem;
  }
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const StyledNameRendererLong = styled(NameRenderer)`
  width: 16rem;

  @media (min-width: 1950px) {
    width: 20rem;
  }
  @media (min-width: 2000px) {
    width: 24rem;
  }
  @media (min-width: 2100px) {
    width: 27rem;
  }
  @media (min-width: 2200px) {
    width: 30rem;
  }
  @media (min-width: 2400px) {
    width: 100%;
  }
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const StyledProductsRenderer = styled(ProductsRenderer)`
  width: 10rem;
`;

export const StyledSingleItemWithNumberRenderer = styled(
  SingleItemWithNumberRenderer,
)`
  min-width: 10rem;
`;
