import styled from '@emotion/styled/macro';

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 0.825rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.color.dark2};
  padding-left: 0.75rem;
`;
