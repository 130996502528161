import { useEffect, useRef } from 'react';

export function useRunOnce(callbackFunction: () => void, condition = false) {
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      callbackFunction();
    }
  }, [callbackFunction, condition]);
}
