import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { ICreateOrganizationModel } from '../../../models/CreateOrganizationModel';
import { removeEmptyObjectFromArrays } from '../../../utils/removeEmptyObjectFromArrays';
import {
  createNewOrganization,
  ICreateNewOrganizationPayload,
} from '../../../store/saga-slices/organizations';
import { useActions } from '../../../hooks/useActions';
import { updateModalState } from '../../../store/redux-slices/modals';
import { loadEmployees } from '../../../store/saga-slices/employees';
import { useProcessing } from '../../../hooks/useProcessing';
import {
  EMPLOYEES_LOADING_PROCESSING,
  GG_ROLES_LOADING_PROCESSING,
  ORGANIZATION_ADDING_PROCESSING,
  ROLES_LOADING_PROCESSING,
} from '../../../store/redux-slices/processes/constants';
import { employeeListSelector } from '../../../store/redux-slices/employees/selectors';
import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { ADD_CLIENT_MODAL_NAME } from '../AddClientModal/addClientModal.consts';
import { ICreateOrganizationResultModel } from '../../../models/interfaces/CreateOrganizationResultModel';
import { CorpPointOfContactFieldsModel } from '../../../styledComponents/Forms/CorpPointOfContactsForm/CorpPointOfContactForm/corpPointOfContact.models';
import { validateAddClientForm } from './addClientForm.validation';
import { loadGGRoles, loadRoles } from '../../../store/saga-slices/roles';
import { useCancellationToken } from '../../../hooks/useTokenCancellation';
import {
  ILoadGGRolesPayload,
  ILoadRolesPayload,
} from '../../../store/saga-slices/roles/models';
import {
  ggRolesOptionsSelector,
  rolesOptionsSelector,
} from '../../../store/redux-slices/roles/selectors';
import { clearGGRoles, clearRoles } from '../../../store/redux-slices/roles';

export const useClientForm = () => {
  const { t } = useTranslation();

  const LoadEmployees = useActions(loadEmployees);
  const UpdateModalState = useActions(updateModalState);
  const CreateNewOrganization = useActions(createNewOrganization);
  const LoadRoles = useActions(loadRoles);
  const ClearRoles = useActions(clearRoles);
  const LoadGGRoles = useActions(loadGGRoles);
  const ClearGGRoles = useActions(clearGGRoles);

  const isAddingOrganization = useProcessing(ORGANIZATION_ADDING_PROCESSING);
  const isLoadingEmployees = useProcessing(EMPLOYEES_LOADING_PROCESSING);
  const employees = useSelector(employeeListSelector);

  const cancellationToken = useCancellationToken();

  useEffect(() => {
    LoadEmployees();
  }, [LoadEmployees]);

  useEffect(() => {
    const payload: ILoadRolesPayload = {
      cancellationToken,
    };
    LoadRoles(payload);
    return () => ClearRoles();
  }, [LoadRoles, cancellationToken, ClearRoles]);

  useEffect(() => {
    const payload: ILoadGGRolesPayload = {
      cancellationToken,
    };
    LoadGGRoles(payload);
    return () => ClearGGRoles();
  }, [LoadGGRoles, cancellationToken, ClearGGRoles]);

  const rolesLoading = useProcessing(ROLES_LOADING_PROCESSING);
  const rolesList = useSelector(rolesOptionsSelector);
  const ggRolesLoading = useProcessing(GG_ROLES_LOADING_PROCESSING);
  const ggRolesList = useSelector(ggRolesOptionsSelector);

  const { validateErrors } = useFormErrorsActions();

  const { handleResponseError } = useApiResponseError();

  const alert = useAlert();

  const submitForm = (values: ICreateOrganizationModel) => {
    const newValues = removeEmptyObjectFromArrays(
      { ...values },
      CorpPointOfContactFieldsModel.isOrganizationAdministrator,
    );
    const createNewOrganizationPayload: ICreateNewOrganizationPayload = {
      error: (err) => {
        handleResponseError(err, { ...newValues });
        alert.error(t('alerts.organization-create-fail'));
      },
      organization: { ...newValues },
      success: (data: ICreateOrganizationResultModel) => {
        UpdateModalState([
          ADD_CLIENT_MODAL_NAME,
          { ...data, corporationName: values.name },
        ]);

        alert.success(
          t('alerts.organization-create-success', { value: values.name }),
        );
      },
    };

    CreateNewOrganization(createNewOrganizationPayload);
  };

  const validateForm = (data: any) =>
    validateAddClientForm(data, validateErrors(data));

  return {
    employees,
    rolesLoading,
    rolesList,
    ggRolesLoading,
    ggRolesList,
    isAddingOrganization,
    isLoadingEmployees,
    submitForm,
    validateForm,
  };
};
