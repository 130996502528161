import { useTranslation } from 'react-i18next';

import { ReactComponent as ReportsIcon } from '../../../../../assets/icons/report-icon.svg';
import { ButtonContent, StyledButton } from './exportButton.styles';

type ExportButtonProps = {
  onClick?: () => void;
  isLoading?: boolean;
  permissions?: string[];
};

const ExportButton = ({
  onClick,
  isLoading,
  permissions,
}: ExportButtonProps) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      permissions={permissions}
      onClick={onClick}
      styleType="light1"
      isLoading={isLoading}
    >
      <ButtonContent>
        <ReportsIcon />
        {t('buttons.export')}
      </ButtonContent>
    </StyledButton>
  );
};

export default ExportButton;
