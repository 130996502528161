export const FormAddressName = 'address';

export enum FormAddressFields {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  ZipCode = 'zipCode',
  City = 'city',
  StateRegion = 'stateRegion',
  Country = 'country',
  Longitude = 'longitude',
  Latitude = 'latitude',
}
