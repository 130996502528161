import { ReactNode } from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icons/info-icon.svg';
import { CustomComponent } from '../../../models/types/CustomComponent';
import MainTooltip from '../MainTooltip/MainTooltip';
import { TooltipPlacement } from '../MainTooltip/mainTooltip.models';

export type InfoComponentType = CustomComponent & {
  tooltip?: ReactNode;
  tooltipWidth?: string;
  tooltipPlacement?: TooltipPlacement;
};

const InfoComponent = ({
  tooltip,
  tooltipPlacement = 'left',
  disabled,
}: InfoComponentType) => (
  <MainTooltip title={tooltip} placement={tooltipPlacement} disabled={disabled}>
    <InfoIcon />
  </MainTooltip>
);

export default InfoComponent;
