import {
  canAddAASubscriptionPermissions,
  canAddDOCSubscriptionPermissions,
  canAddKESubscriptionPermissions,
  canAddSRSubscriptionPermissions,
  canAddTLSubscriptionPermissions,
} from '../../../config/accessPolicies/accessPolicies';
import { IAAOrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/AirAmbulance/AAOrganizationSubscriptionModel';
import { IDOCOrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/DutyOfCare/DOCOrganizationSubscriptionModel';
import { IKEOrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/KidnapAndExtortion/KEOrganizationSubscriptionModel';
import { ISROrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/SecurityResponse/SROrganizationSubscriptionModel';
import { ITLOrganizationSubscriptionModel } from '../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionModel';
import { ProductType } from '../../../models/ProductType';
import { AvailableProductPermissionsType } from '../../../models/types/AvailableProductViewsPermissionsType';
import { CreateOrganizationSubscriptionType } from '../../../models/types/CreateOrganizationSubscriptionsType';
import { GetSubscriptionSummaryType } from '../../../models/types/GetSubscriptionSummaryType';

export const AddSubscriptionsPagePermissions: AvailableProductPermissionsType =
  {
    AirAmbulance: canAddAASubscriptionPermissions,
    KidnapAndExtortion: canAddKESubscriptionPermissions,
    SecurityResponse: canAddSRSubscriptionPermissions,
    DutyOfCare: canAddDOCSubscriptionPermissions,
    TravelLicense: canAddTLSubscriptionPermissions,
  };

export const prepareSummaryItem = (
  subscription: CreateOrganizationSubscriptionType,
): GetSubscriptionSummaryType => {
  switch (subscription.productType) {
    case ProductType.AirAmbulance:
    case ProductType.SecurityResponse:
    case ProductType.KidnapAndExtortion: {
      const currentSubscription = { ...subscription } as
        | IAAOrganizationSubscriptionModel
        | IKEOrganizationSubscriptionModel
        | ISROrganizationSubscriptionModel;

      if (!currentSubscription?.pricing?.membershipPlans) {
        return null;
      }
      return {
        membershipPlans: currentSubscription?.pricing?.membershipPlans,
        productType: currentSubscription.productType,
      };
    }

    case ProductType.DutyOfCare: {
      const currentSubscription = {
        ...subscription,
      } as IDOCOrganizationSubscriptionModel;

      if (
        !currentSubscription?.packageOptions ||
        !currentSubscription?.packageOptions?.packageType
      ) {
        return null;
      }
      return {
        packageType: currentSubscription?.packageOptions?.packageType,
        seatsAmount: currentSubscription?.packageOptions?.seatsAmount,
        totalPrice: currentSubscription?.packageOptions?.totalPrice,
        productType: currentSubscription.productType,
      };
    }
    case ProductType.TravelLicense:
      const currentSubscription = {
        ...subscription,
      } as ITLOrganizationSubscriptionModel;

      if (!currentSubscription?.pricingTiers) {
        return null;
      }

      return {
        productType: currentSubscription.productType,
        pricingTiers: currentSubscription?.pricingTiers,
      };
    default:
      return null;
  }
};
