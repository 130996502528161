import moment from 'moment';

import { formatDate } from '../../../../utils/dateHelpers';
import { ICellRenderer } from '../cellRenderers.models';
import {
  ClockIcon,
  DateExpiredWrapper,
  StyledTableCell,
  Text,
} from './expiredDateRenderer.styles';

export interface IDateCellRenderer extends ICellRenderer {
  date?: string;
  description?: string;
}

export const ExpiredDateRenderer = ({
  date,
  description,
  options,
}: IDateCellRenderer) => {
  const isActive = moment().isBefore(moment(date));

  return (
    <StyledTableCell bold={options?.textBold} alignment={options?.alignment}>
      <DateExpiredWrapper isExpired={!isActive}>
        {date ? formatDate(date) : '-'}
        {date && !isActive && <ClockIcon />}
      </DateExpiredWrapper>
      <Text>{description}</Text>
    </StyledTableCell>
  );
};
