import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { useActions } from '../../../../hooks/useActions';
import { useModalDetails } from '../../../../hooks/useModalDetails';
import { useProcessing } from '../../../../hooks/useProcessing';
import { useCancellationToken } from '../../../../hooks/useTokenCancellation';
import { IEditPromoCodeValidDatesModel } from '../../../../models/interfaces/EditPromoCodeValidDatesModel';
import { updateModalState } from '../../../../store/redux-slices/modals';
import { PROMO_CODE_DATE_EDIT_PROCESSING } from '../../../../store/redux-slices/processes/constants';
import { editPromoCodeDates } from '../../../../store/saga-slices/promoCodes';
import { IEditPromoCodeDatesPayload } from '../../../../store/saga-slices/promoCodes/models';
import {
  useApiResponseError,
  useFormErrorsActions,
} from '../../../../styledComponents/FormErrorProvider/FormErrorProvider.hooks';
import { validatePromoCodeDatesForm } from './EditPromoCodeDatesForm/editPromoCodeDatesForm.validation';
import { EDIT_PROMO_CODE_DATES_MODAL_NAME } from './editPromoCodeDatesModal.const';
import { EditPromoCodeDatesModalData } from './editPromoCodeDatesModal.models';

type UseEditPromoCodeDatesModalType = {
  onPromoCodeDatesEdited?: () => void;
};

export const useEditPromoCodeDatesModal = ({
  onPromoCodeDatesEdited,
}: UseEditPromoCodeDatesModalType) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const EditPromoCodeDates = useActions(editPromoCodeDates);
  const UpdateModalState = useActions(updateModalState);
  const cancellationToken = useCancellationToken();

  const isProcessing = useProcessing(PROMO_CODE_DATE_EDIT_PROCESSING);

  const { handleResponseError } = useApiResponseError();
  const { validateErrors } = useFormErrorsActions();

  const { validFrom, validTo, promoCodeId } =
    useModalDetails<EditPromoCodeDatesModalData>(
      EDIT_PROMO_CODE_DATES_MODAL_NAME,
    );

  const editPromoCodeDatesHandler = useCallback(
    (values: IEditPromoCodeValidDatesModel) => {
      const payload: IEditPromoCodeDatesPayload = {
        promoCodeId,
        data: values,
        cancellationToken,
        success: () => {
          UpdateModalState(EDIT_PROMO_CODE_DATES_MODAL_NAME);
          onPromoCodeDatesEdited?.();
          alert.success(t('alerts.promo-code-dates-update-success'));
        },
        error: (err) => {
          handleResponseError(err, values);
          alert.error(t('alerts.promo-code-dates-update-fail'));
        },
      };

      EditPromoCodeDates(payload);
    },
    [
      EditPromoCodeDates,
      UpdateModalState,
      alert,
      cancellationToken,
      handleResponseError,
      onPromoCodeDatesEdited,
      promoCodeId,
      t,
    ],
  );

  const initData = {
    validFrom,
    validTo,
  };

  const onCloseHandler = () => {
    UpdateModalState(EDIT_PROMO_CODE_DATES_MODAL_NAME);
  };

  const validateForm = (data: IEditPromoCodeValidDatesModel) =>
    validatePromoCodeDatesForm(data, validateErrors(data));

  return {
    editPromoCodeDatesHandler,
    onCloseHandler,
    initData,
    isProcessing,
    validateForm,
  };
};
