import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  flex-direction: column;
`;

export const ErrorLabel = styled.div`
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  font: 0.875rem/1.5rem ${({ theme }) => theme.font.openSans};
  color: ${({ theme }) => theme.color.error};
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
`;

type FieldLabelType = {
  disabled?: boolean;
  autoLabelAnimation?: boolean;
  labelActive?: boolean;
  inputLabelOffset?: number;
  isError?: boolean;
};

type FieldLabelStyleProps = FieldLabelType & {
  theme: Theme;
};

export const fieldLabelInActiveStyle = ({
  theme,
  inputLabelOffset,
  isError,
}: FieldLabelStyleProps) => css`
  top: 1rem;
  left: ${inputLabelOffset + 1}rem;
  z-index: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${isError ? theme.color.error : theme.color.placeholder};
`;

export const fieldLabelActiveStyle = ({
  theme,
  isError,
}: FieldLabelStyleProps) => css`
  top: -0.7rem;
  left: 0.75rem;
  z-index: 2;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${isError ? theme.color.error : theme.color.dark2};
`;

export const FieldLabel = styled.label<FieldLabelType>`
  font-family: ${({ theme }) => theme.font.gotham};
  position: absolute;
  ${fieldLabelActiveStyle}
  transition: top 200ms ease-in-out, left 200ms ease-in-out, color 200ms ease-in-out;
  cursor: text;
  background: ${({ theme }) => theme.color.white};
  outline: none;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error : theme.color.dark1};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.dark2};
    `}

  ${({ autoLabelAnimation, labelActive, ...props }) =>
    (autoLabelAnimation || !labelActive) && fieldLabelInActiveStyle(props)}
  
  ${({ theme, labelActive, isError }) =>
    labelActive &&
    css`
      color: ${isError ? theme.color.error : theme.color.dark2};
    `}

    ${({ autoLabelAnimation, ...props }) =>
    autoLabelAnimation &&
    css`
      ${FieldWrapper}:focus-within > &,
      ${FieldWrapper}:not(:focus-within) > :not(:placeholder-shown) ~ & {
        ${fieldLabelActiveStyle(props)}
      }
    `}
`;
