import {
  canChangeAAToBeRenewedPermissions,
  canManageAAMembershipsPermissions,
  canRemoveAASecondaryMemberPermissions,
  canResendAAWelcomeEmailPermissions,
} from '../../../../config/accessPolicies/accessPolicies';
import { MemberOverviewMemberTablePermissions } from '../../MemberOverviewMemberTable/memberOverviewMemberTable.models';

export const canViewActionColumnPermissions = [
  ...canManageAAMembershipsPermissions,
  ...canResendAAWelcomeEmailPermissions,
];

export const aaOverviewTablePermissions: MemberOverviewMemberTablePermissions =
  {
    canViewActionColumnPermissions,
    canChangeToBeRenewedPermissions: canChangeAAToBeRenewedPermissions,
    canRemoveSecondaryMemberPermissions: canRemoveAASecondaryMemberPermissions,
    canResendWelcomeEmailPermissions: canResendAAWelcomeEmailPermissions,
  };
