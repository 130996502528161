import { ShortcutProductType } from '../enums/ShortcutProductType';
import { ProductType } from '../ProductType';

export const ProductShortcutToProductType: Record<
  ShortcutProductType,
  ProductType
> = {
  [ShortcutProductType.AA]: ProductType.AirAmbulance,
  [ShortcutProductType.DoC]: ProductType.DutyOfCare,
  [ShortcutProductType.KE]: ProductType.KidnapAndExtortion,
  [ShortcutProductType.SR]: ProductType.SecurityResponse,
  [ShortcutProductType.TL]: ProductType.TravelLicense,
};
