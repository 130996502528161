import styled from '@emotion/styled/macro';

import { Description } from './NavItem/navItem.styles';

export const Wrapper = styled.div`
  height: 100%;
  z-index: 5;
`;

export const ItemsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  position: fixed;
  height: 100%;
  width: 7rem;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 9rem);
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
`;

export const Line = styled.div`
  position: absolute;
  height: 0.0625rem;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.secondary1};
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  ${Description} {
    margin-top: 0.75rem;
  }

  &:hover {
    ${Description} {
      color: ${({ theme }) => theme.color.logoutHover};
    }
    svg {
      path {
        fill: ${({ theme }) => theme.color.logoutHover};
      }
    }
  }
`;
