import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../models/FieldErrorMessage';
import { contractFormSchema } from '../../../../styledComponents/Forms/SelectContractForm/selectContractForm.validation';
import { subscriptionOptionsSchema } from '../../../../styledComponents/Forms/SubscriptionOptions/subscriptionOptions.validation';
import { subscriptionSummarySchema } from '../../../../styledComponents/FormSummary/SubscriptionSummary/subscriptionSummary.validation';
import { validateFormData } from '../../../../utils/validations/validateFormData';
import { aaRenewSubscriptionDetailsSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/AirAmbulanceRenewSubscription/AARenewSubscriptionDetails/aaRenewSubscriptionDetails.validation';
import { subscriptionPricingSchema } from '../../../../styledComponents/RenewSubscriptionFormPage/FormSections/Common/SubscriptionRenewPricing/subscriptionPricing.validation';
import { RenewAASubscriptionModel } from '../../../../models/types/AirAmbulance/RenewAASubscriptionModel';

const schema: AnySchema | any = {
  type: 'object',
  properties: {
    ...aaRenewSubscriptionDetailsSchema.properties,
    ...subscriptionOptionsSchema.properties,
    ...subscriptionSummarySchema.properties,
    ...subscriptionPricingSchema.properties,
  },
  dependencies: {
    ...subscriptionOptionsSchema.dependencies,
    ...subscriptionSummarySchema.dependencies,
  },
  required: [
    ...contractFormSchema.required,
    ...aaRenewSubscriptionDetailsSchema.required,
    ...subscriptionOptionsSchema.required,
    ...subscriptionSummarySchema.required,
    ...subscriptionPricingSchema.required,
  ],
};

export const validateAARenewSubscriptionForm = (
  data: RenewAASubscriptionModel,
  additionalErrors?: FieldErrorMessage[],
) => validateFormData(data, additionalErrors, schema);
