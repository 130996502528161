import { t } from 'i18next';

import { formatDate } from '../../../../utils/dateHelpers';
import {
  Detail,
  DetailLabel,
  DetailValue,
} from '../../../Overview/OverviewDetails/OverviewDetails';
import { EditRenewalSubscriptionStatusOverview } from './editRenewalSubscriptionStatusModalOverview.models';
import { StyledDetailsContainer } from './editRenewalSubscriptionStatusModalOverview.styles';

type RenewalSubscriptionStatusOverviewProps = {
  data: EditRenewalSubscriptionStatusOverview;
};

export const EditRenewalSubscriptionStatusModalOverview = ({
  data,
}: RenewalSubscriptionStatusOverviewProps) => (
  <StyledDetailsContainer>
    <Detail>
      <DetailLabel>{`${t('properties.organization')}: `}</DetailLabel>
      <DetailValue>{data?.organizationName}</DetailValue>
    </Detail>
    <Detail>
      <DetailLabel>{`${t('properties.subscription')}: `}</DetailLabel>
      <DetailValue>{`${t(
        `enums.productType.${data?.productType}`,
      )}`}</DetailValue>
    </Detail>
    <Detail>
      <DetailLabel>{`${t('properties.contract-name')}: `} </DetailLabel>
      <DetailValue>{data?.contractName || '-'}</DetailValue>
    </Detail>
    <Detail>
      <DetailLabel>{`${t('properties.expirationDate')}: `} </DetailLabel>
      <DetailValue>{formatDate(data?.expirationDate)}</DetailValue>
    </Detail>
  </StyledDetailsContainer>
);
