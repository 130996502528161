import { useMemo } from 'react';

import { ProductType } from '../models/ProductType';
import { AvailableProductPermissionsType } from '../models/types/AvailableProductViewsPermissionsType';
import { getOptionsFromEnum } from '../utils/getOptionsFromEnum';
import { prepareProductsOptions } from '../utils/productsHelpers';
import { usePermissions } from './usePermissions';

type UseProductsProps = {
  pagesPermissions?: AvailableProductPermissionsType;
  products?: ProductType[];
  excludedProducts?: ProductType[];
};

export const defaultProductsList = Object.values(ProductType).filter(
  (type) => ![ProductType.Unknown].includes(type),
);

export const defaultProductsOptions = getOptionsFromEnum(
  ProductType,
  'productType',
  [ProductType.Unknown],
);

export const useProducts = ({
  pagesPermissions,
  products,
  excludedProducts,
}: UseProductsProps) => {
  const { checkUserPermissions } = usePermissions();

  const filteredProductList = useMemo(
    () =>
      (products || defaultProductsList).filter(
        (x) =>
          (!pagesPermissions || checkUserPermissions(pagesPermissions[x])) &&
          (!excludedProducts ||
            excludedProducts?.findIndex((element) => element === x) === -1),
      ),
    [checkUserPermissions, excludedProducts, pagesPermissions, products],
  );

  const productsOptions = useMemo(
    () => products && prepareProductsOptions(products),
    [products],
  );

  const filteredProductOptions = useMemo(
    () => prepareProductsOptions(filteredProductList),
    [filteredProductList],
  );

  return { productsOptions, filteredProductOptions, filteredProductList };
};
