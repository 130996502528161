import FormControlLabel, {
  FormControlLabelClassKey,
} from '@mui/material/FormControlLabel';
import ReactSwitch, { SwitchClassKey, SwitchProps } from '@mui/material/Switch';

import { withPermissions } from '../../../hoc/withPermissions';

import { StyledSwitch as Switch } from './switch.styles';

export interface ISwitchProps extends SwitchProps {
  classesSwitch?: Record<SwitchClassKey | any, string>;
  classesWrapper?: Record<FormControlLabelClassKey | any, string>;
  className?: string;
  disabled?: boolean;
  label?: string;
}

export const SwitchNoStyled = ({
  classesWrapper,
  checked,
  disabled,
  onChange,
  classesSwitch,
  label,
  className,
}: ISwitchProps) => (
  <FormControlLabel
    className={className}
    classes={{
      ...classesWrapper,
    }}
    control={
      <ReactSwitch
        checked={checked}
        classes={{
          ...classesSwitch,
        }}
        disabled={disabled}
        onChange={onChange}
      />
    }
    label={label}
  />
);

export default withPermissions(Switch);
