import axios, { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { ISubscriptionSummaryModel } from '../../../../models/interfaces/Subscription/SubscriptionSummaryModel';
import { ISubscriptionDates } from '../../../../models/SubscriptionDates';
import {
  prepareQueryParams,
  QueryParamItem,
} from '../../../../utils/validations/queryParamsHelpers';
import { IOrganizationSubscriptionPlansModel } from '../common/models';
import { IOrganizationSubscription } from '../../../../models/interfaces/Subscription/OrganizationSubscription';
import { ITLOrganizationSubscriptionOverview } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOverview';
import { IEditTLOrganizationSubscriptionModel } from '../../../../models/interfaces/Subscription/TravelLicense/EditTLOrganizationSubscriptionModel';
import { ITLOrganizationSubscriptionOptionsModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionOptionsModel';
import { RenewTLSubscriptionModel } from '../../../../models/types/TravelLicense/RenewTLSubscriptionModel';
import { ITLSubscriptionRenewalSuggestionModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLSubscriptionRenewalSuggestionModel';
import { ICreateTLOrganizationSubscriptionExtensionModel } from '../../../../models/interfaces/Subscription/TravelLicense/CreateTLOrganizationSubscriptionExtensionModel';
import { ITLOrganizationSubscriptionExtensionSummaryModel } from '../../../../models/interfaces/Subscription/TravelLicense/TLOrganizationSubscriptionExtensionSummaryModel';
import { IChangeTLSubscriptionPricingTierModel } from '../../../../models/interfaces/Subscription/TravelLicense/ChangeTLSubscriptionPricingTierModel';
import { RenewalStatus } from '../../../../models/enums/RenewalStatus';

export const getTLSubscription = (
  organizationId: string,
  subscriptionId: string,
  params?: string,
): Promise<AxiosResponse<ITLOrganizationSubscriptionOverview>> => {
  const currentParams = prepareQueryParams({
    initQueryString: params,
  });
  return axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}${currentParams}`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );
};

export const getDatesForNewTLSubscription = (
  organizationId: string,
  contractId: string,
): Promise<AxiosResponse<ISubscriptionDates>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'contractId',
      value: contractId,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/newDates${params}`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );
};

export const getTLSubscriptionSuggestion = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IEditTLOrganizationSubscriptionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/suggestion`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getTLSubscriptionSummary = (
  organizationId: string,
  data: any,
): Promise<AxiosResponse<ISubscriptionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/summary`,
    data,
    {
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );

export const updateTLSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: IEditTLOrganizationSubscriptionModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    data,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const deleteTLSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.delete(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const updateTLSubscriptionDetails = (
  organizationId: string,
  subscriptionId: string,
  subscriptionDetails: ITLOrganizationSubscriptionOptionsModel,
): Promise<AxiosResponse<ITLOrganizationSubscriptionOverview>> =>
  axios.put(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/details`,
    subscriptionDetails,
    {
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );

export const extendTLSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  subscriptionExtension: ICreateTLOrganizationSubscriptionExtensionModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ITLOrganizationSubscriptionOverview>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/pricingtiers`,
    subscriptionExtension,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getTLSubscriptionExtensionSummary = (
  organizationId: string,
  subscriptionId: string,
  data: IChangeTLSubscriptionPricingTierModel[],
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<ITLOrganizationSubscriptionExtensionSummaryModel>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/capacity/update/summary`,
    data,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const getTLSubscriptionPlans = (
  organizationId: string,
  subscriptionId: string,
  primaryMemberBirthDate?: string,
  membershipEffectiveDate?: string,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscriptionPlansModel>> => {
  const queryParams: QueryParamItem[] = [
    {
      name: 'primaryMemberBirthDate',
      value: primaryMemberBirthDate,
    },
    {
      name: 'membershipEffectiveDate',
      value: membershipEffectiveDate,
    },
  ];

  const params = prepareQueryParams({ queryParams });

  return axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/plans${params}`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );
};

export const getTLSubscriptionRenewalSuggestion = (
  organizationId: string,
  subscriptionId: string,
): Promise<AxiosResponse<ITLSubscriptionRenewalSuggestionModel>> =>
  axios.get(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalsuggestion`,
    {
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );

export const renewTLSubscriptionRequest = (
  organizationId: string,
  subscriptionId: string,
  data: RenewTLSubscriptionModel,
  cancellationToken?: AbortSignal,
): Promise<AxiosResponse<IOrganizationSubscription>> =>
  axios.post(
    `api/organizations/${organizationId}/subscriptions/${subscriptionId}/renew`,
    data,
    {
      baseURL: config.travelLicenseApi.baseUrl,
      signal: cancellationToken,
    },
  );

export const editTLSubscriptionRenewalStatusRequest = (
  organizationId: string,
  subscriptionId: string,
  renewalStatus: RenewalStatus,
  cancellationToken: AbortSignal,
): Promise<AxiosResponse<void>> =>
  axios.post(
    `/api/organizations/${organizationId}/subscriptions/${subscriptionId}/renewalstatus`,
    { renewalStatus },
    {
      signal: cancellationToken,
      baseURL: config.travelLicenseApi.baseUrl,
    },
  );
