import { useCallback, useMemo } from 'react';

import { usePermissions } from '../../../hooks/usePermissions';
import { RadioGroupContentItem } from './radioGroupContent.models';

type UseRadioGroupContentProps = {
  checked: string;
  items: RadioGroupContentItem[];
};

export const useRadioGroupContent = ({
  checked,
  items,
}: UseRadioGroupContentProps) => {
  const { checkUserPermissions } = usePermissions();

  const isChecked = useCallback((id: string) => id === checked, [checked]);

  const filteredItems = useMemo(
    () => items.filter((x) => checkUserPermissions(x.permissions)),
    [checkUserPermissions, items],
  );

  const shouldContentVisible = useCallback(
    (id: string, contentHiddenIfNoActive: boolean) =>
      isChecked(id) || (!isChecked(id) && !contentHiddenIfNoActive),
    [isChecked],
  );

  return { isChecked, shouldContentVisible, filteredItems };
};
