import { useMemo } from 'react';

import { isEmpty } from '../../../utils/textHelpers';

export const useSummaryContainerTotalPrice = (currentTotalPrice: string) => {
  const totalPriceContent = useMemo(() => {
    const totalPrice: string = currentTotalPrice;
    if (isEmpty(totalPrice as string)) {
      return totalPrice;
    }

    const isNegative = currentTotalPrice[0] === '-';
    if (isNegative) {
      return `-$${totalPrice.substring(1, totalPrice.length)}`;
    }
    return `$${totalPrice}`;
  }, [currentTotalPrice]);

  return { totalPriceContent };
};
