import { useProcessing } from '../../../../../../hooks/useProcessing';
import { ORGANIZATION_NOTES_ADDING_PROCESSING } from '../../../../../../store/redux-slices/processes/constants';
import { Modal } from '../../../../../../styledComponents/Modal/Modal';
import { ADD_ORG_NOTES_MODAL_NAME } from './addNotesModal.const';
import AddNotesModalForm from './AddNotesModalForm/AddNotesModalForm';

interface IAddOrganizationNotesModalProps {
  onNotesAdded?: () => void;
}

const AddContractNotesModal = ({
  onNotesAdded,
}: IAddOrganizationNotesModalProps) => {
  const isProcessing = useProcessing(ORGANIZATION_NOTES_ADDING_PROCESSING);
  return (
    <Modal name={ADD_ORG_NOTES_MODAL_NAME} disabled={isProcessing}>
      <AddNotesModalForm onNotesAdded={onNotesAdded} />
    </Modal>
  );
};

export default AddContractNotesModal;
