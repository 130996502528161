import { call, select } from 'typed-redux-saga/macro';

import { ICancellationTokenSaga } from '../../../models/interfaces/CancellationTokenSaga';
import {
  creditCardExistsSelector,
  firstCreditCardSelector,
} from '../../redux-slices/payment/selectors';
import { addNewCreditCard, replaceCreditCard } from './api';

type AddOrReplaceCreditCardType = ICancellationTokenSaga & {
  personId: string;
};

export function* addOrReplaceCreditCard({
  cancellationToken,
  personId,
}: AddOrReplaceCreditCardType) {
  const creditCardExists = yield* select(creditCardExistsSelector);
  const creditCard = yield* select(firstCreditCardSelector);

  if (creditCardExists) {
    const replaceResponse = yield* call(
      replaceCreditCard,
      personId,
      creditCard.creditCardId,
      cancellationToken,
    );

    return replaceResponse.data;
  }
  const addResponse = yield* call(
    addNewCreditCard,
    personId,
    cancellationToken,
  );

  return addResponse.data;
}
