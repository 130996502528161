import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useSelector } from 'react-redux';

import { useActions } from '../../../hooks/useActions';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { ApiErrorType } from '../../../models/enums/ApiErrorType';
import { RoutePath } from '../../../models/enums/RoutePath';
import { resetError } from '../../../store/redux-slices/errors';

import { errorSelector } from '../../../store/redux-slices/errors/selectors';
import { updateModalState } from '../../../store/redux-slices/modals';
import { SOMETHING_WENT_WRONG_MODAL_NAME } from '../../Modals/SomethingWentWrongModal/somethingWentWrongModal.const';

export const useActionErrorHandler = () => {
  const error = useSelector(errorSelector);

  const ResetError = useActions(resetError);
  const UpdateModalState = useActions(updateModalState);
  const errorHandler = useErrorHandler();
  const navigate = useNavigateSearch();

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error.type === ApiErrorType.NotFound) {
      navigate(RoutePath.NotFoundPage, null, error);
      ResetError();
      return;
    }

    if (error.type === ApiErrorType.AccessForbidden) {
      errorHandler(error);
      ResetError();
      return;
    }

    if (error) {
      UpdateModalState(SOMETHING_WENT_WRONG_MODAL_NAME);
      ResetError();
    }
  }, [ResetError, UpdateModalState, error, errorHandler, navigate]);
};
