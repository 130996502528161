import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { IOrganizationSubscriptionPricingModel } from '../../../../../models/interfaces/Subscription/IOrganizationSubscriptionPricingModel';
import { CustomComponent } from '../../../../../models/types/CustomComponent';
import { loadKESubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/kidnapAndExtortion';
import { ILoadKESubscriptionSummary } from '../../../../../store/saga-slices/subscriptions/kidnapAndExtortion/models';

type KERenewSubscriptionSummaryLoaderProps = CustomComponent & {
  pricing: IOrganizationSubscriptionPricingModel;
};

export const KERenewSubscriptionSummaryLoader = ({
  pricing,
}: KERenewSubscriptionSummaryLoaderProps) => {
  const { organizationId } = useParams();

  const LoadSubscriptionSummary = useActions(loadKESubscriptionSummary);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (!pricing) {
      return;
    }

    const payload: ILoadKESubscriptionSummary = {
      data: pricing.membershipPlans,
      organizationId,
    };

    debounce(() => LoadSubscriptionSummary(payload), 300);
  }, [LoadSubscriptionSummary, debounce, organizationId, pricing]);

  return <></>;
};
