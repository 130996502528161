import { t } from 'i18next';
import { AnySchema } from 'ajv';

import { FieldErrorMessage } from '../../../../../models/FieldErrorMessage';
import { validateFormData } from '../../../../../utils/validations/validateFormData';
import { SubscriptionRenewDetailsCommonFormFields } from './subscriptionRenewDetailsCommonForm.models';

interface IValidateAARenewSubscriptionDetailsForm {
  effectiveDateFrom: string;
  effectiveDateTo: string;
  promoCode: string;
  subscriptionType: string;
}

export const subscriptionRenewDetailsCommonFormSchema: AnySchema | any = {
  type: 'object',
  properties: {
    [SubscriptionRenewDetailsCommonFormFields.EffectiveDateTo]: {
      type: 'string',
      format: 'date',
      formatMinimum: {
        $data: `1/${SubscriptionRenewDetailsCommonFormFields.EffectiveDateFrom}`,
      },
      errorMessage: {
        formatMinimum: t('validation.expiration-date-earlier-signed-date'),
        format: t('validation.invalid-date'),
      },
    },
    [SubscriptionRenewDetailsCommonFormFields.EffectiveDateFrom]: {
      type: 'string',
      format: 'date',
    },
  },
  required: [
    SubscriptionRenewDetailsCommonFormFields.EffectiveDateFrom,
    SubscriptionRenewDetailsCommonFormFields.EffectiveDateTo,
  ],
};

export const validateSubscriptionRenewDetailsCommonFor = (
  data: IValidateAARenewSubscriptionDetailsForm,
  additionalErrors?: FieldErrorMessage[],
) =>
  validateFormData(
    data,
    additionalErrors,
    subscriptionRenewDetailsCommonFormSchema,
  );
