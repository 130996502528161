import { useField } from 'react-final-form';

import { useActions } from '../../../../../../hooks/useActions';
import { usePersonFields } from '../../../../../../hooks/usePersonFields';
import { IPerson } from '../../../../../../models/Person';
import { updateModalState } from '../../../../../../store/redux-slices/modals';
import { EDIT_MEMBER_MODAL_NAME } from '../../../../../../styledComponents/Modals/EditMemberModal/editMemberModal.const';
import { personToSearchPerson } from '../../../../../../utils/personHelpers';
import { PrimaryMemberDetailsFormFields } from '../PrimaryMemberDetailsForm/primaryMemberDetailsForm.models';

export const usePrimaryMemberDetailsSection = () => {
  const UpdateModalState = useActions(updateModalState);

  const { changeFields } = usePersonFields({
    fields: {
      Phone: PrimaryMemberDetailsFormFields.PhoneNumber,
      Prefix: PrimaryMemberDetailsFormFields.PersonPrefix,
    },
  });

  const {
    input: { value: personId },
  } = useField(PrimaryMemberDetailsFormFields.PersonId);

  const onEditMemberHandler = () => {
    if (!personId) {
      return;
    }
    UpdateModalState([EDIT_MEMBER_MODAL_NAME, personId]);
  };

  const onEditedMemberHandler = (person: IPerson) => {
    changeFields(personToSearchPerson(person));
  };

  return {
    onEditMemberHandler,
    onEditedMemberHandler,
  };
};
