import { createSelector } from 'reselect';

import { IMembersState } from '.';
import { RootState } from '../..';

export const membershipStateSelector = (state: RootState) => state.membership;

export const primaryMemberSelector = createSelector(
  membershipStateSelector,
  (membership: IMembersState) => membership.createPrimaryMember,
);

export const primaryMemberIdSelector = createSelector(
  membershipStateSelector,
  (membership: IMembersState) => membership.createPrimaryMember.personId,
);

export const primaryMemberNameSelector = createSelector(
  membershipStateSelector,
  (membership: IMembersState) =>
    `${membership.createPrimaryMember.firstName} ${membership.createPrimaryMember.lastName}`,
);

export const membershipSelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => members.createMembership,
);

export const createdMembershipIdSelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => members.createdMembershipId,
);

export const createdMembershipAutoRenewFlagSelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => !!members?.createMembership?.autoRenewal,
);

export const createdMembershipOverviewAmountSelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => ({
    overwriteAmount: members?.createMembership?.overwriteAmount,
    overwrittenAmount: members?.createMembership?.overwrittenAmount,
  }),
);

export const membershipSummarySelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => members.summary,
);

export const membershipAvailableProductsSelector = createSelector(
  membershipStateSelector,
  (members: IMembersState) => members.availableProductTypes,
);
